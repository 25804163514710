import React, { Component } from "react";
import { useSelector } from "react-redux";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import { useIntl } from "react-intl";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import { format } from "@cortexglobal/cortex-utilities";

import {
    SpacedRow,
    DetailWrapper,
    DetailsLabel,
    DetailsContent,
    DetailsColumn,
    DescriptionContainer,
} from "./overviewComponents";

const TaskDetails = ({ filters, task, user, groups }) => {
    // const goals = useSelector(
    //     (state) => state.planner.config.data.options.goals
    // );
    // const goal_metrics = useSelector(
    //     (state) => state.planner.config.data.options.goal_metrics
    // );
    // const goal = goals.filter(
    //     (goalOption) => goalOption.value === task.goal
    // )[0];
    //Pull out categories and channels from the filters as we need them
    //to create nice i18n lists, as we only have the aliases
    const categories = filters.filter(
        (category) => category.name === "primary_category_alias"
    )[0];

    const intl = useIntl();

    let amendedOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: "currency",
        currency: user.currency,
    };

    return (
        <DetailWrapper>
            <Row>
                {task.description && (
                    <DetailsColumn style={{ marginTop: "20px" }}>
                        <DescriptionContainer>
                            {task.description}
                        </DescriptionContainer>
                    </DetailsColumn>
                )}

                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Date Range")}</DetailsLabel>
                    <DetailsContent>
                        {format(new Date(task.start_date))} -{" "}
                        {format(new Date(task.end_date))}
                    </DetailsContent>
                </SpacedRow>

                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Status")}</DetailsLabel>
                    <DetailsContent>{task.status_wording}</DetailsContent>
                </SpacedRow>

                <SpacedRow className="detailRow">
                    <DetailsLabel>
                        {trans("Estimated Campaign Budget")}
                    </DetailsLabel>
                    <DetailsContent>
                        {isNaN(task?.estimated_campaign_budget?.value) ? (
                            <>{trans("N/A")}</>
                        ) : (
                            <FormattedCurrency
                                value={
                                    task?.estimated_campaign_budget?.value || 0
                                }
                                currency={
                                    task?.estimated_campaign_budget?.currency ||
                                    "GBP"
                                }
                            />
                        )}
                    </DetailsContent>
                </SpacedRow>

                {task.external_ref && (
                    <SpacedRow className="detailRow">
                        <DetailsLabel>{trans("Job Number")}</DetailsLabel>
                        <DetailsContent>
                            {task?.external_ref || "-"}
                        </DetailsContent>
                    </SpacedRow>
                )}

                {task.goal && (
                    <SpacedRow className="detailRow">
                        <DetailsLabel>{trans("Goal")}</DetailsLabel>

                        <DetailsContent>
                            <h4>{task.goal.name}</h4>
                            {task.metrics.map((metric) => {
                                if (metric.type === "currency") {
                                    return (
                                        <p key={metric.metric_uuid}>
                                            {metric.name}:" "}
                                            {metric.estimated_value !== null
                                                ? intl.formatNumber(
                                                      metric.estimated_value,
                                                      amendedOptions
                                                  )
                                                : trans("Not Set")}
                                        </p>
                                    );
                                }
                                return (
                                    <p key={metric.metric_uuid}>
                                        {metric.name}:{" "}
                                        {metric.estimated_value !== null
                                            ? metric.estimated_value
                                            : trans("Not Set")}
                                    </p>
                                );
                            })}
                        </DetailsContent>
                    </SpacedRow>
                )}

                {task.related_groups && task.related_groups.length > 0 && (
                    <SpacedRow className="detailRow">
                        <DetailsLabel>{trans("Related Groups")}</DetailsLabel>

                        <DetailsContent>
                            {task.related_groups.map((group, index) => {
                                return `${index > 0 ? ", " : ""}${group.name}`;
                            })}
                        </DetailsContent>
                    </SpacedRow>
                )}

                {task && !task.owner && task.group && (
                    <SpacedRow className="detailRow">
                        <DetailsLabel>{trans("Campaign Owner")}</DetailsLabel>

                        <DetailsContent>{task.group.name}</DetailsContent>
                    </SpacedRow>
                )}

                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Lead Stakeholder")}</DetailsLabel>

                    <DetailsContent>
                        <h4>{task.user.name}</h4>
                        {task.user.phone && <p>{task.user.phone}</p>}
                        {task.user.email && <p>{task.user.email}</p>}
                    </DetailsContent>
                </SpacedRow>

                {task && task.group && (
                    <SpacedRow className="detailRow">
                        <DetailsLabel>{trans("Group")}</DetailsLabel>

                        <DetailsContent>
                            <strong>{task.group.name}</strong>
                            {task.group.contact && (
                                <>
                                    <div>
                                        {task.group.contact.address_1}{" "}
                                        {task.group.contact.address_1 && <br />}
                                        {task.group.contact.address_2}{" "}
                                        {task.group.contact.address_2 && <br />}
                                        {task.group.contact.address_3}{" "}
                                        {task.group.contact.address_3 && <br />}
                                        {task.group.contact.address_4}{" "}
                                        {task.group.contact.address_4 && <br />}
                                        {task.group.contact.address_5}{" "}
                                        {task.group.contact.address_5 && <br />}
                                        {task.group.contact.country}
                                    </div>

                                    {task.group.contact.phone && (
                                        <strong>
                                            {task.group.contact.phone}
                                        </strong>
                                    )}
                                    {task.group.contact.email && (
                                        <strong>
                                            {task.group.contact.email}
                                        </strong>
                                    )}
                                </>
                            )}
                        </DetailsContent>
                    </SpacedRow>
                )}

                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Other Categories")}</DetailsLabel>

                    <DetailsContent>
                        {categories.options
                            .filter((category) => {
                                return (
                                    task.categories.indexOf(category.value) !==
                                    -1
                                );
                            })
                            .map((category, index) => {
                                return `${index > 0 ? ", " : ""}${
                                    category.text
                                }`;
                            })}
                    </DetailsContent>
                </SpacedRow>
                {task.products.length > 0 && (
                    <SpacedRow className="detailRow">
                        <DetailsLabel>{trans("Focus Product(s)")}</DetailsLabel>

                        <DetailsContent>
                            {task.products.map((product, index) => {
                                return `${index > 0 ? ", " : ""}${
                                    product.text
                                }`;
                            })}
                        </DetailsContent>
                    </SpacedRow>
                )}
            </Row>
        </DetailWrapper>
    );
};

export default TaskDetails;
