import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";

let Card = styled.div`
    border: 1px solid grey;
    font-family: inherit;
    margin-top: ${props => props.theme.spacing.margin}em;
    text-align: ${props => props.align};
    overflow-wrap: break-word;
    overflow: hidden;
    border-radius: ${props =>
        props.rounded ? props.theme.card.borderRadius : 0}em;
    ${props =>
        props.flex
            ? css`
                  display: flex;
                  flex-direction: column;
              `
            : css`
                              ""
                          `};
`;

Card = withTheme(Card);
Card.displayName = "Card";

Card.propTypes = {
    rounded: PropTypes.bool,
    spacecontent: PropTypes.bool
};

Card.defaultProps = {
    rounded: false,
    spacecontent: "",
    type: "lightGray"
};

export const CardImg = styled.img`
    width: 100%;
    height: auto;
`;

CardImg.displayName = "CardImg";

CardImg.propTypes = {
    src: PropTypes.string
};

CardImg.defaultProps = {
    src:
        "https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180"
};

export const CardBody = styled.div`
    padding: ${props => props.theme.spacing.padding}em;
    ${props =>
        props.spacecontent === "Equally space all elements"
            ? "flex:1;justify-content:space-between;flex:1;display:flex; flex-direction:column;"
            : "flex:1;display:flex; flex-direction:column; *:last-child {margin-top:auto;}"};
`;

CardBody.displayName = "CardBody";

CardBody.propTypes = {
    align: PropTypes.oneOf(["left", "right", "center", undefined])
};

CardBody.defaultProps = {
    align: "left"
};

export default Card;
