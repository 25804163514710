import { get } from "lodash";
import axios from "axios";
import moment from "moment";

const convertToMoment = (value) => {
    if (moment.isMoment(value)) {
        return value;
    }

    return !value ? moment().add(1, "day") : moment(value);
};

class EventObject {
    constructor(props) {
        this.populate(props);
        this.errors = {};
    }

    populate(props) {
        this.raw = {
            // used to populate the form so things like items, and budgets aren't needed
            ...props,
            bookableInventoryItems: [],
            bookingItems: [],
            predicted_budget_breakdown: [],
        };

        this.ref = get(props, "ref", "");
        this.uuid = get(props, "uuid", "");
        this.smartRef = get(props, "smartRef", "");
        this.name = get(props, "name", "");
        this.description = get(props, "description", "");
        this.event_type = get(props, "event_type", "");

        this.start_date = convertToMoment(get(props, "start_date", ""));
        this.end_date = convertToMoment(get(props, "end_date", ""));

        this.predicted_attendee_count = get(
            props,
            "predicted_attendee_count",
            ""
        );
        this.staff_attendee_count = get(props, "staff_attendee_count", "");
        this.target_audience = get(props, "target_audience", "");
        this.total_budget = get(props, "total_budget", "");
        this.actual_budget = get(props, "actual_budget", "");
        this.cost_per_head = get(props, "cost_per_head", "");
        this.room_drop_costs = get(props, "room_drop_costs", "");
        this.sustainability_element = get(props, "sustainability_element", "");
        this.date_status = get(props, "date_status", "");
        this.pr_requirements = get(props, "pr_requirements", "");

        this.created = convertToMoment(get(props, "created", ""));
        this.updated = convertToMoment(get(props, "updated", ""));
        this.createdBy = get(props, "createdBy", "");

        this.draft_status = parseInt(get(props, "draft_status", 0)); // initially set to 0 - goes to 1 when saved, 2 when complete

        this.bookableInventoryItems = get(props, "bookableInventoryItems", []);

        this.venue_reference = get(props, "venue_reference", {});
        this.venue_name = get(props, "venue_name", "");
        this.venue_primary = get(props, "venue_primary", "");
        this.venue_phone = get(props, "venue_phone", "");
        this.venue_fax = get(props, "venue_fax", "");
        this.venue_email = get(props, "venue_email", "");

        this.address_building = get(props, "address_building", "");
        this.address_street = get(props, "address_street", "");
        this.address_neighbourhood = get(props, "address_neighbourhood", "");
        this.address_suburb = get(props, "address_suburb", "");

        this.address_city = get(props, "address_city", "");
        this.address_state = get(props, "address_state", "");
        this.postcode = get(props, "postcode", "");
        this.venue_country = get(props, "venue_country", "");
        this.venue_cost = get(props, "venue_cost", "");
        this.actual_venue_cost = get(props, "actual_venue_cost", "");
        this.venue_notes = get(props, "venue_notes", "");

        this.indoor = get(props, "indoor");

        this.bookingItems = get(props, "bookingItems", []);
        this.workflow = get(props, "workflow", {});
        this.state = get(props, "state", {});
        this.request = get(props, "request", {});
        this.workflowable = get(props, "workflowable", {});

        this.budget_confirmed = get(props, "budget_confirmed");

        this.primary_category_id = get(props, "primary_category_id", "");
        this.planner_task_id = get(props, "planner_task_id", "");
        this.planner_activity_id = get(props, "planner_activity_id", "");
        this.meeting_element = get(props, "meeting_element");
        this.contract_issued = !!get(props, "contract_issued", "");
        this.contract_signed_off = !!get(props, "contract_signed", "");

        this.predicted_budget_breakdown = get(
            props,
            "predicted_budget_breakdown",
            []
        );
        this.total_cost = get(props, "total_cost", 0);
        this.actual_total_cost = get(props, "actual_total_cost", 0);
        this.total_equipment_cost = get(props, "total_equipment_cost", 0);
        this.actual_total_equipment_cost = get(
            props,
            "actual_total_equipment_cost",
            0
        );

        this.budgetary_comments = get(props, "budgetary_comments", "");

        return this;
    }

    getSignOffs() {
        const signoffs = get(this, "workflow.sign_offs", []);

        return Array.isArray(signoffs) ? signoffs : [];
    }

    setWorkflow(newWorkflow) {
        this.workflowable = newWorkflow;

        return this;
    }

    static create = (params) => {
        return axios
            .post(`/api/v1/events`, params)
            .then(({ data }) => {
                const eventData = data.data;

                const event = new Event(eventData);

                return event;
            })
            .catch((e) => {
                if (e.response && e.response.status === 422) {
                    return e.response.data.errors;
                }

                throw e;
            });
    };

    isComplete() {
        return get(this, "workflowable.isComplete") === true;
    }

    isClosed() {
        return get(this, "workflowable.isClosed") === true;
    }

    assignedTo() {
        let assignedTo = [];

        const group = get(this, "workflowable.assigned_to_group");
        const team = get(this, "workflowable.assigned_to_team");
        const role = get(this, "workflowable.assigned_to_role");
        const user = get(this, "workflowable.assigned_to_user");

        if (group) {
            assignedTo.push(group);
        }

        if (team) {
            assignedTo.push(team);
        }

        if (role) {
            assignedTo.push(role);
        }

        if (user) {
            assignedTo.push(user);
        }

        return assignedTo.join(", ");
    }
}

export default EventObject;
