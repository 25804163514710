import React, { useEffect, useState } from "react";
import axios from "axios";
import { FileType } from "../FileType";

export const useFileListWithState = (
    smartRef: string,
    include: string[],
    exclude: string[]
) => {
    const [files, setFiles] = useState([]);
    const [state, setState] = useState("loading");

    const addFile = (file) => {
        setFiles([file, ...files]);
    };

    const removeFile = (uuid) => {
        setFiles([...files.filter((file) => file.uuid !== uuid)]);
    };

    useEffect(() => {
        setState("loading");

        axios
            .get(`/api/v1/files/${smartRef}`, {
                params: { include: include, exclude: exclude },
            })
            .then((res) => {
                setFiles(res.data.data);
                setState("loaded");
            })
            .catch((e) => {
                setState("error");
            });
    }, [smartRef]);

    return { files, setFiles, state, addFile, removeFile };
};
