import React, { useContext, useEffect } from "react";

import styled from "styled-components";

const RangeContainer = styled.div`
    margin-bottom: ${(props) => `${props.theme.spacing.margin}rem`};
    padding-top: ${(props) => `${props.theme.spacing.padding}rem`};
    padding-bottom: ${(props) => `${props.theme.spacing.padding}rem`};
    max-width: 50%;
`;

import { trans } from "@cortexglobal/rla-intl";
import { DatePicker, InputError } from "@cortexglobal/rla-components";

import SurveySubmissionContext from "../SurveySubmissionContext";

const AnswerDate = ({ answer, options, readonly }) => {
    const { surveyAnswersData, surveySystem, handleAnswerChange } = useContext(
        SurveySubmissionContext
    );

    const { isSubmitting, isSubmitted, questionErrors } = surveySystem;

    // When this loads for the first time use the default as the answer
    useEffect(() => {
        const index = surveyAnswersData.findIndex(
            (answerData) => answerData.question_uuid === answer.question_uuid
        );
        if (index < 0) {
            handleAnswerChange(answer.question_uuid, answer.answer || null);
        }
    }, [surveyAnswersData]);

    const getDate = (option) => {
        let maxDate = undefined;
        if (option) {
            try {
                //The max_value defaults to 10 so if it is 10 we want to set it to null
                if (typeof option === "number") {
                    return undefined;
                }
                if (typeof option === "string") {
                    maxDate = new Date(option);
                } else {
                    maxDate = option;
                }
            } catch (e) {
                maxDate = undefined;
            }
        }
        return maxDate;
    };

    return (
        <RangeContainer>
            <DatePicker
                name={answer.question_uuid}
                value={answer.answer || null}
                minDate={getDate(options?.range?.min_value)}
                maxDate={getDate(options?.range?.max_value)}
                onChange={(e) => handleAnswerChange(e.name, e.value)}
                disabled={!!readonly}
                error={questionErrors[answer.question_uuid]}
            />
        </RangeContainer>
    );
};

export default AnswerDate;
