import React from "react";
import PropTypes from "prop-types";
import { Zoomable } from "../../index";

const ZoomableImage = ({ style: styleProps, src, ...rest }) => {
    return (
        <Zoomable {...rest}>
            {({ style }) => {
                return (
                    <img
                        src={src}
                        style={{ ...style, ...styleProps }}
                        draggable="false"
                    />
                );
            }}
        </Zoomable>
    );
};
ZoomableImage.displayName = "ZoomableImage";

ZoomableImage.propTypes = {
    src: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    transitionMilliseconds: PropTypes.number,
    updating: PropTypes.bool,
    resetZoom: PropTypes.bool
};
ZoomableImage.defaultProps = {
    transitionMilliseconds: 500,
    updating: false,
    resetZoom: false
};
export default ZoomableImage;
