import React, { useContext } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { DraftJSOutput } from "@cortexglobal/rla-components";

import { Layout } from "../interfaces/layout";
import {
    SlideContainer,
    Subtitle,
    SlideTitle,
    SlideBody,
} from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";

const StyledSlideTitle = styled(SlideTitle)<SlideTitleProps>`
    flex: 1;

    ${({ hidden }) =>
        hidden &&
        `
        display: none;
    `}
`;

const FullscreenContent = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);

    if (editing || disableSequencing) {
        currentTransitionIndex = 1;
    }

    // filter transitions to only show ones with content
    const transitionsComplete = getCompletedTransitionNames(
        transitions.filter((transition) => {
            return Object.values(content).reduce(
                (accumulator: any, currentValue: any) => {
                    if (currentValue.uuid === transition.uuid) {
                        return currentValue.value;
                    } else {
                        return accumulator.value;
                    }
                }
            );
        }),
        currentTransitionIndex
    );

    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                hidden={!content.title || !content.title.value}
                style={useSpring({
                    opacity: transitionsComplete.includes("title") ? 1 : 0,
                })}
            >
                <RenderContentBlock {...content.title} />
            </StyledSlideTitle>
            <SlideBody
                style={useSpring({
                    opacity: transitionsComplete.includes("content") ? 1 : 0,
                })}
            >
                {!!content.content && !!content.content.value && (
                    <DraftJSOutput style={{ width: "100%" }}>
                        {content.content.value}
                    </DraftJSOutput>
                )}
            </SlideBody>
        </SlideContainer>
    );
};

interface SlideTitleProps {
    hidden?: boolean;
}

export default FullscreenContent;
