import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    Row,
    Column,
    Button,
    SubmitButton,
    IconTextButton,
    Panel,
    useAddToast,
    PageTitle,
} from "@cortexglobal/rla-components";
import GeneralDetailsSection from "../forms/GeneralDetailsSection";

export const CreateCampaign = ({ basePath, setRefreshSearch }) => {
    const addToast = useAddToast();
    const history = useHistory();
    const [campaign, setCampaign] = useState({
        name: "",
        description: "",
        start_date: "",
        end_date: "",
    });
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const createCampaign = (event) => {
        event.preventDefault();
        axios
            .post(`/api/v1/campaigns/admin`, campaign)
            .then(({ data: { data } }) => {
                addToast({
                    type: "success",
                    content: trans("Campaign created successfully"),
                    showFor: 5000,
                });
                history.push(`${basePath}/${data.uuid}/edit`);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    setSubmitting(false);
                });

                console.log(e.response);
                const message =
                    e?.response?.status === 422
                        ? trans("Please check the form for errors")
                        : trans("An error occurred creating the campaign");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            });
    };

    const handleChange = ({ name, value }) => {
        setCampaign({ ...campaign, [name]: value });
    };

    return (
        <React.Fragment>
            <PageTitle title={<h2>{trans("Create New Campaign")}</h2>}>
                <IconTextButton
                    rotation={180}
                    showCircle={false}
                    as={Link}
                    to={`${basePath}`}
                >
                    {trans("Back to Campaigns")}
                </IconTextButton>
            </PageTitle>
            <form onSubmit={createCampaign}>
                <Row>
                    <Column>
                        <GeneralDetailsSection
                            campaign={campaign}
                            onChange={handleChange}
                            errors={errors}
                            submitting={submitting}
                        />
                    </Column>
                </Row>{" "}
                <Panel style={{ position: "sticky", bottom: 0 }}>
                    <Row
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <Button as={Link} to={`${basePath}`} type={"alert"}>
                            {trans("Cancel")}
                        </Button>

                        <SubmitButton
                            style={{ marginLeft: "1rem" }}
                            disabled={submitting}
                            submitting={submitting}
                        >
                            {trans("Save and continue")}
                        </SubmitButton>
                    </Row>
                </Panel>
            </form>
        </React.Fragment>
    );
};

export default CreateCampaign;
