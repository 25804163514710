import React, { useState } from "react";
import {
    Loading,
    Modal,
    Row,
    Column,
    Button,
    SubmitButton,
    CustomFormFields,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { objectToFormData } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const TransitionForm = ({
    transition,
    form,
    visible,
    onClose,
    errors,
    onSubmit,
    smartRef,
}) => {
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);

    const onChange = ({ name, value }) => {
        if (value instanceof FileList) {
            value = Array.from(value);
        }

        setValues({
            ...values,
            [name]: value,
        });
    };

    const submit = () => {
        if (loading === true) {
            return;
        }

        setLoading(true);

        let formData = values;

        const { custom_form_uuids = null } = form;

        if (custom_form_uuids) {
            formData["custom_form_uuids"] = custom_form_uuids;
        }

        formData = objectToFormData(formData);

        onSubmit(smartRef, transition, formData).then(() => {
            setLoading(false);
        });
    };

    // if (loading) {
    //     return <Loading />;
    // }

    return (
        <div className="transition-form">
            <Modal maxWidth="800px" onClose={onClose} visible={!!visible}>
                <Row equaliseByRow equaliseChildHeight={false} expanded={false}>
                    <Column>
                        <h2>{transition.name}</h2>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        {form && (
                            <CustomFormFields
                                form={form}
                                values={values}
                                errors={errors}
                                onChange={onChange}
                                disabled={loading}
                            />
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column medium={6}>
                        <Button
                            type="alert"
                            value="Cancel"
                            onClick={() => onClose()}
                            $expanded
                            disabled={loading}
                        >
                            {trans("Cancel")}
                        </Button>
                    </Column>

                    <Column medium={6}>
                        <SubmitButton
                            type="submit"
                            value="Submit"
                            onClick={submit}
                            $expanded
                            submitting={loading}
                        >
                            {trans("Submit")}
                        </SubmitButton>
                    </Column>
                </Row>
            </Modal>
        </div>
    );
};

export default TransitionForm;
