import React, { Component } from "react";
import withPanelData from "./withPanelData";
import styled, { css } from "styled-components";
import { trans } from "@cortexglobal/rla-intl";

const TableRow = styled.tr`
    ${(props) =>
        props.selected &&
        css`
            background-color: ${({ colors }) => colors.primary} !important;
        `};
`;

class VisitReportSummary extends Component {
    render() {
        const { data } = this.props;
        return (
            <div>
                <table className="dashboard-table">
                    <tbody>
                        {data &&
                            data.slice(0, 3).map((report, index) => {
                                return [
                                    <TableRow
                                        key={index + "_appointment"}
                                        index={index}
                                    >
                                        <td>
                                            <h5>{trans("Customer")}</h5>
                                            {report.customer}
                                        </td>
                                        <td>
                                            <h5>{trans("Account")}</h5>
                                            {report.externalIdentifier}
                                        </td>
                                        <td>
                                            <h5>{trans("Last Appointment")}</h5>
                                            {report.lastAppointmentDate}
                                        </td>
                                        <td>
                                            <h5>{trans("Next Appointment")}</h5>
                                            {report.nextAppointmentDate}
                                        </td>
                                    </TableRow>,
                                    <TableRow
                                        key={index + "_objectives"}
                                        index={index}
                                    >
                                        <td colSpan="100%">
                                            <h5>{trans("Objectives")}</h5>
                                            {report.objectives.map(
                                                (objective) => {
                                                    return (
                                                        <p key={objective}>
                                                            {objective}
                                                        </p>
                                                    );
                                                }
                                            )}
                                            <hr />
                                        </td>
                                    </TableRow>,
                                ];
                            })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withPanelData(
    VisitReportSummary,
    "/api/v1/visit-report/summary"
);
