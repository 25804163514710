import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { calculateOffset, calculateWidth } from "./helpers";
import CalendarContext from "./CalendarContext";
import { transparentize, darken } from "@cortexglobal/cortex-utilities";

import { Tooltip } from "@cortexglobal/rla-components";
import TaskTooltipContent from "./components/TaskTooltipContent";

import { StyledTask } from "./components/StyledTask";
class CalculatedTask extends Component {
    // activityClick(activity, task, category, left, width, event) {
    //     this.props.activityClick(activity, task, category, left, width, event);
    // }

    shouldComponentUpdate(nextProps) {
        if (
            this.props.widths.item !== nextProps.widths.item ||
            this.props.start !== nextProps.start ||
            this.props.task !== nextProps.task
        ) {
            return true;
        }
        return false;
    }

    render() {
        const {
            category,
            task,
            colors,
            widths,
            heights,
            units,
            start,
            end,
            taskClick,
            taskContent,
        } = this.props;
        const left = calculateOffset(
            task.calculated_start_date,
            widths,
            units,
            start
        );

        const width = calculateWidth(
            start,
            end,
            task.calculated_start_date,
            task.calculated_end_date,
            widths,
            units
        );
        const type = task.owner_type;

        //Work out if the task is showing based on the set start and end dates
        //Starts in the month
        const mainTaskIsShowing =
            (task.start_date >= start && task.start_date <= end) ||
            //Ends in the month
            (task.end_date >= start && task.end_date < end) ||
            //Starts before and ends after (spans this month)
            (task.start_date < start && task.end_date > end);
        // console.log(task.name, mainTaskIsShowing);
        return (
            <StyledTask
                colors={colors}
                height={heights.task}
                spacing={heights.spacing}
                left={left}
                width={width}
                type={type}
                className={`task ${type}`}
                calculated={true}
                onClick={(event) =>
                    taskClick({ category, task, left, width, event })
                }
            >
                {mainTaskIsShowing ? (
                    <span>&nbsp;</span>
                ) : (
                    <Tooltip
                        tagName="div"
                        text={<TaskTooltipContent task={task} />}
                        zIndex={10000}
                        showIcon={false}
                        setMaxWidth={false}
                        direction="bottom"
                    >
                        {React.createElement(taskContent, {
                            task,
                            taskStartDate: task.calculated_start_date,
                            type,
                            colors,
                            widths,
                            heights,
                            units,
                            start,
                            width,
                        })}
                    </Tooltip>
                )}
            </StyledTask>
        );
    }
}

CalculatedTask.propTypes = {
    category: PropTypes.object,
    task: PropTypes.object,
};

export default CalculatedTask;
