import React, { useContext } from "react";

import { Layout } from "../interfaces/layout";
import { SlideWrapper, Subtitle } from "../components/slideComponents";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";
import styled from "styled-components";

const ImageContainer = styled(SlideWrapper)`
    display: flex;
    padding: 2vh 0 4em 0;
    align-items: center;
`;

const FullscreenImage = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);

    if (editing || disableSequencing) {
        currentTransitionIndex = 1;
    }
    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;
    // console.log({ content, settings });
    return (
        <SlideWrapper
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <header
                    style={{
                        padding: "5vh 5vw 0",
                    }}
                >
                    <Subtitle
                        highlightColor={highlightColor}
                        groupName={groupName}
                    >
                        {pageName}
                    </Subtitle>
                </header>
            )}
            <ImageContainer
                backgroundImage={globalSettings?.backgroundImage}
                settings={settings}
                slideWidth={slideSize?.width}
                slideHeight={slideSize?.height}
                fontSize={slideSize?.fontSize}
            >
                {!!content.fullscreen_image &&
                    !!content.fullscreen_image.value && (
                        <img
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                            src={content.fullscreen_image.value}
                        />
                    )}
            </ImageContainer>
        </SlideWrapper>
    );
};

interface SlideTitleProps {
    hidden?: boolean;
}

export default FullscreenImage;
