import React from "react";
import { trans } from "@cortexglobal/rla-intl";
//TODO - Replace this with the new context menu
// import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FolderLine extends React.Component {
    openFolder = (item) => {
        this.props.openFolder(item);
    };

    editFolder = (item) => {
        this.props.editFolder(item);
    };

    updateExpandList = (item) => {
        this.props.updateExpandList(item);
    };

    isExpanded = (uuid) => {
        return this.props.expandList.indexOf(uuid) < 0 ? false : true;
    };

    folderIcon = (f) => {
        if (this.isExpanded(f.uuid)) {
            return <FontAwesomeIcon icon="folder-open" />;
        } else {
            return <FontAwesomeIcon icon="folder" />;
        }
    };

    thisFolder(f) {
        let cf = this.props.currentFolder;
        return cf && f.uuid === cf.uuid ? true : false;
    }
    createNewFolder = () => {
        this.props.createNewFolder();
    };

    deleteFolder = (item) => {};

    render() {
        const { folder } = this.props;
        const current = this.thisFolder(folder);

        return (
            <div>
                This section needs reworking using the context menu if it's
                used, if you see this error please contact support.
            </div>
        );
        // return (
        //     <div>
        //         <ContextMenu
        //             id={folder.uuid}
        //             style={{
        //                 background: "#cccccc",
        //                 padding: "5px",
        //                 border: "1px solid #777777",
        //                 width: "200px",
        //             }}
        //         >
        //             <MenuItem
        //                 data={{ folder: folder }}
        //                 onClick={this.editFolder.bind(this, folder)}
        //             >
        //                 {trans("edit folder")} ({folder.name})
        //             </MenuItem>
        //             <MenuItem
        //                 data={{ folder: folder }}
        //                 onClick={this.createNewFolder}
        //             >
        //                 {trans("create new folder")}
        //             </MenuItem>
        //             <MenuItem divider />
        //             <MenuItem
        //                 data={{ folder: folder }}
        //                 onClick={this.deleteFolder.bind(this, folder)}
        //             >
        //                 {trans("delete this folder")}
        //             </MenuItem>
        //         </ContextMenu>

        //         <ContextMenuTrigger id={folder.uuid}>
        //             {folder.hasFolders > 0 && (
        //                 <a
        //                     style={{
        //                         cursor: "pointer",
        //                     }}
        //                     onClick={this.updateExpandList.bind(this, folder)}
        //                 >
        //                     {this.folderIcon(folder)}
        //                 </a>
        //             )}
        //             {!folder.hasFolders && (
        //                 <FontAwesomeIcon icon="ellipsis-h" />
        //             )}{" "}
        //             {!current && (
        //                 <a
        //                     style={{
        //                         cursor: "pointer",
        //                     }}
        //                     onClick={this.openFolder.bind(this, folder)}
        //                 >
        //                     {folder.name}
        //                 </a>
        //             )}
        //             {current && (
        //                 <strong
        //                     style={{
        //                         color: "#d74540",
        //                     }}
        //                 >
        //                     {folder.name}
        //                 </strong>
        //             )}
        //         </ContextMenuTrigger>
        //     </div>
        // );
    }
}

export default FolderLine;
