import React, { Component } from "react";
import styled, { css, useTheme } from "styled-components";
import Row from "./row";
import Column from "./column";
import Panel from "./panel";

const Wrapper = styled.section`
    padding-top: ${(props) =>
        props.padding != undefined
            ? props.padding
            : props.theme.spacing.padding}rem;
    padding-bottom: ${(props) =>
        props.padding != undefined
            ? props.padding
            : props.theme.spacing.padding}rem;
    margin-bottom: ${(props) =>
        props.margin != undefined
            ? props.margin
            : props.theme.spacing.margin}rem;
    background-color: ${(props) =>
        props.background != undefined
            ? props.background
            : props.theme.pageTitle.background};
    color: ${(props) =>
        props.color != undefined ? props.color : props.theme.pageTitle.color};
`;

const Title = styled.div`
    display: flex;
    margin: 0 auto 0 0;
    font-size: ${({ theme, size }) =>
        size === "small"
            ? theme.pageTitle.smallFontSize
            : theme.pageTitle.fontSize};
    font-family: ${({ theme }) => theme.pageTitle.fontFamily};
    flex-grow: 1;
    align-items: center;
`;

const Flex = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const PageTitle = ({ title, children, size, ...rest }) => {
    const theme = useTheme();

    return (
        <Wrapper {...rest}>
            <Row
                expanded={theme.body.expanded ? true : false}
                style={{ marginBottom: 0 }}
                {...rest}
            >
                <Column>
                    <Flex>
                        <Title size={size}>{title}</Title>
                        {children}
                    </Flex>
                </Column>
            </Row>
        </Wrapper>
    );
};

export default PageTitle;
