import { combineReducers } from "redux";

import { assetReducer as assets } from "@cortexglobal/cortex-dam";
import { mediaLibraryReducer as mediaLibrary } from "@cortexglobal/cortex-dam";
import { plannerReducer as planner } from "@cortexglobal/marketing-planner";
import { authReducer as auth } from "@cortexglobal/cortex-auth-react-router-dom";
import { requestsReducer as requests } from "@cortexglobal/requests";
import { dashboardReducer as dashboards } from "@cortexglobal/dashboard";
import { panelReducer as panels } from "@cortexglobal/dashboard";

import modal from "../reducers/popupReducer";

const rootReducer = combineReducers({
    assets,
    auth,
    dashboards,
    mediaLibrary,
    modal,
    panels,
    planner,
    requests,
});

export default rootReducer;
