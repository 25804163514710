import React from "react";
import { Row, Column } from "@cortexglobal/rla-components";
import styled from "styled-components";

const DetailContainer = styled.div`
    text-align: center;
`;
const DetailSummary = ({ title, description }) => {
    return (
        <Row>
            <Column>
                <DetailContainer>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </DetailContainer>
            </Column>
        </Row>
    );
};

export default DetailSummary;
