import React, { Component } from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Hero,
    StyledLink as CortexStyledLink,
    ShowMore,
} from "@cortexglobal/rla-components";
import withPanelData from "../withPanelData";

const StyledRow = styled(Row)`
    // height: 100%;
`;

const StyledColumn = styled(Column)`
    //height: 100%;
    padding-bottom: ${({ theme }) => theme.spacing.margin}em;
`;

const StyledHero = styled(Hero)`
    // height: 100%;
`;

const StyledLink = styled(CortexStyledLink)`
    display: block;
    margin-top: 0.5rem;
`;

const Container = styled.div`
    height: 100%;
    padding-top: ${({ theme }) => theme.spacing.padding}em;
`;

const NewsStory = styled.div`
    height: 100%;
    padding-bottom: 1em;
    position: relative;
`;

const Content = styled.div`
    height: 100%;
    overflow: hidden;
`;

class NewsListOutput extends Component {
    render() {
        return (
            <Container>
                {this.props.data.map((story, index) => {
                    return (
                        <StyledRow key={index}>
                            <StyledColumn small={4}>
                                <StyledHero
                                    height={150}
                                    backgroundImage={story.thumbnail}
                                />
                            </StyledColumn>
                            <StyledColumn small={8}>
                                <NewsStory key={index}>
                                    <Content>
                                        <p>
                                            <strong>{story.title}</strong>
                                        </p>
                                        <ShowMore lines={5}>
                                            {story.excerpt}
                                        </ShowMore>
                                        {story.source && (
                                            <p>
                                                <small>
                                                    Source: {story.source}
                                                </small>
                                            </p>
                                        )}
                                        {story.uuid !== undefined && (
                                            <StyledLink
                                                to={`/news/${story.uuid}`}
                                            >
                                                {trans("Read full story...")}
                                            </StyledLink>
                                        )}
                                        {story.url !== undefined && (
                                            <a href={story.url} target="_blank">
                                                {trans("Read full story...")}
                                            </a>
                                        )}
                                    </Content>
                                </NewsStory>
                            </StyledColumn>
                        </StyledRow>
                    );
                })}
            </Container>
        );
    }
}

export default withPanelData(NewsListOutput, "/api/v1/news", ["feed"]);
