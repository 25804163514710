import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import {
    Row,
    Column,
    IconTextButton,
    Modal,
    useAddToast,
    PageTitle,
    Loading,
    LoadingIconSmall,
    Progress,
    Image,
} from "@cortexglobal/rla-components";

import UserAssetResultItem from "../../userAssets/components/UserAssetResultItem";

export const ViewUserCampaign = ({
    basePath,
    campaignUuid,
    selectedGroupCampaign,
    assetsPath,
}) => {
    const addToast = useAddToast();
    const history = useHistory();
    const { can } = usePermissions();
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);

    const [groupCampaign, setGroupCampaign] = useState({
        loading: true,
        loaded: false,
        data: {},
    });

    useEffect(() => {
        axios
            .get(`/api/v1/campaigns/group-campaigns/${campaignUuid}`)
            .then(({ data: { data } }) => {
                setGroupCampaign({
                    loaded: true,
                    loading: false,
                    data,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error loading campaign"),
                    showFor: 5000,
                });
            });
    }, [campaignUuid]);

    const downloadUserAssets = () => {
        axios
            .get(`api/v1/user-assets/download`, {
                params: {
                    uuids: groupCampaign.data.user_assets.map((a) => a.uuid),
                },
                responseType: "blob",
                timeout: 0,
                onDownloadProgress: (progressEvent) => {
                    setDownloadProgress(
                        Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                    );
                },
            })
            .then((response) => {
                setShowDownloadModal(false);
                addToast({
                    type: "success",
                    content: trans("Assets downloaded"),
                    showFor: 5000,
                });
                saveAs(
                    new Blob([response.data]),
                    `${
                        groupCampaign?.data?.campaign?.name
                            ? groupCampaign.data.campaign.name
                                  .replace(/[^a-z0-9]/gi, "_")
                                  .toLowerCase()
                            : "download"
                    }.zip`
                );
            })
            .catch((e) => {
                setIsDownloading(false);
                addToast({
                    type: "alert",
                    content: "Asset download failed, please try again later",
                    showFor: 5000,
                });
            });
    };

    const getTitle = () => {
        //Best to use the name loaded fresh from the API
        if (groupCampaign.loaded) {
            return groupCampaign.data.campaign.name;
        }
        //If we have a selected campaign, use that
        if (selectedGroupCampaign) {
            return selectedGroupCampaign.campaign.name;
        }
        //We're still loading
        if (groupCampaign.loading) {
            return trans("Loading...");
        }
        //We've finished loading but don't have a campaign
        return trans("Campaign not found");
    };

    const renderContent = () => {
        if (groupCampaign.loading) {
            return <Loading />;
        }
        if (!groupCampaign.loaded || !groupCampaign.data) {
            return <h3>{trans("Campaign not found")}</h3>;
        }
        const { campaign, files_ready, user_assets } = groupCampaign.data;

        return (
            <>
                {campaign.hero && (
                    <Column>
                        <Image src={campaign.hero} />
                    </Column>
                )}

                {campaign.description && (
                    <Column
                        style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                    >
                        <p>{campaign.description}</p>
                    </Column>
                )}
                {user_assets && user_assets.length > 0 && (
                    <Column>
                        {user_assets.map((asset) => (
                            <UserAssetResultItem
                                key={asset.uuid}
                                asset={asset}
                                loaded={true}
                            />
                        ))}
                    </Column>
                )}
            </>
        );
    };
    const { files_ready } = groupCampaign.data;

    return (
        <>
            <PageTitle title={<h2>{getTitle()}</h2>}>
                {groupCampaign.loaded && (
                    <>
                        {files_ready ? (
                            <IconTextButton
                                type="primary"
                                onClick={() => {
                                    downloadUserAssets();
                                    setShowDownloadModal(true);
                                }}
                                icon="plus"
                                showCircle={false}
                            >
                                {trans("Download Assets")}
                            </IconTextButton>
                        ) : (
                            <aside>{trans("Files not ready")}</aside>
                        )}
                    </>
                )}

                <IconTextButton
                    as={Link}
                    to={`${basePath}`}
                    showCircle={false}
                    rotation={180}
                >
                    {trans("Back to Campaigns")}
                </IconTextButton>
            </PageTitle>
            <Row>{renderContent()}</Row>{" "}
            {groupCampaign.loaded && (
                <Modal
                    visible={showDownloadModal}
                    onClose={() => setShowDownloadModal(false)}
                >
                    {downloadProgress > 0 ? (
                        <>
                            <h2>{trans("Downloading Campaign Assets")}</h2>
                            <Progress current={downloadProgress} />
                            <p>
                                {trans("{downloadProgress}% downloaded", {
                                    downloadProgress,
                                })}
                            </p>
                        </>
                    ) : (
                        <>
                            <h2>{trans("Preparing download ZIP")}</h2>
                            <LoadingIconSmall />{" "}
                            <p>
                                {trans(
                                    "{count, plural, one {Preparing # asset} other {Zipping # assets, please wait}}",
                                    {
                                        count: groupCampaign.data.user_assets
                                            .length,
                                    }
                                )}
                            </p>
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

export default ViewUserCampaign;
