import React, {useContext} from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

//@ts-ignore cortex
import { Image as CortexImage } from "@cortexglobal/rla-components";
import MicrositeContext from "../MicrositeContext";

export const Image = styled(CortexImage)`
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
export const Video = styled.video`
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;
export const Captions = styled(animated.div)`
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    align-items: flex-start;
`;

const BulletPoint = styled.div<BulletPointProps>`
    width: 0.7em;
    height: 0.7em;
    background: ${props => props.highlightColor};
    padding: 0;
    margin-right: 1em;
    margin-top: 0.3em;
`;

const FlexDiv = styled.div<FlexDivProps>`
    display: flex;
    margin-bottom: 1em;
    
    ${({ centerAlign }) => centerAlign && `
        justify-content: center;
        text-align: center;
        padding: 0 0.5rem;
    `}
`;

interface FlexDivProps {
    centerAlign?: boolean;
}

export const Caption = ({
    children,
    showing,
    highlightColor,
    bulletPoint,
    centerAlign
}: {
    children: React.ReactNode;
    showing: boolean;
    highlightColor: string;
    bulletPoint: boolean;
    centerAlign?: boolean
}) => {
    const { globalTheming } = useContext(MicrositeContext);

    const props = useSpring({ opacity: showing ? 1 : 0 });
    return (
        <animated.div style={props}>
            <FlexDiv centerAlign={centerAlign}>
                {bulletPoint && (
                    <div>
                        <BulletPoint
                            highlightColor={highlightColor ? highlightColor : globalTheming?.highlightColor}
                        />
                    </div>
                )}
                <div>{children}</div>
            </FlexDiv>
        </animated.div>
    );
};

interface BulletPointProps {
    highlightColor: string;
}
