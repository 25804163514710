import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { useTheme } from "styled-components";
import Kalend, { CalendarView, OnEventDragFinish } from "kalend";
import {
    Loading,
    TableList,
    Centred,
    Button,
    DatePicker,
    Row,
    Column,
    IconTextButton,
    Modal,
    FormikDatePicker,
    SubmitButton,
    DraftJSOutput,
} from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";
import { useUser } from "@cortexglobal/cortex-auth-redux";
import "kalend/dist/styles/index.css";

const ja = {
    common: {
        noEvents: "イベントなし",
    },
    buttons: {
        today: "本日",
        agenda: "アジェンダ",
        day: "日",
        threeDays: "3日間",
        week: "週",
        month: "月",
        showMore: "もっと表示",
        newEvent: "新しいイベント",
    },
    months: {
        january: "1月",
        february: "2月",
        march: "3月",
        april: "4月",
        may: "5月",
        june: "6月",
        july: "7月",
        august: "8月",
        september: "9月",
        october: "10月",
        november: "11月",
        december: "12月",
    },
    weekDays: {
        Mon: "月曜日",
        Tue: "火曜日",
        Wed: "水曜日",
        Thu: "木曜日",
        Fri: "金曜日",
        Sat: "土曜日",
        Sun: "日曜日",
    },
    weekShort: "週",
};

const locales = {
    ja,
};

const CalenderWrapper = styled.div`
    & * {
        font-family: inherit;
    }
    height: 100%;
    min-height: 540px;
`;

const Calendar = ({
    panel,
    panelId,
    filterBy,
    fromDate,
    toDate,
    perPage = 10,
}) => {
    const [events, setEvents] = useState([]);
    const [eventModalVisible, setEventModalVisible] = useState(false);
    const [eventModalInfo, setEventModalInfo] = useState(false);
    const globalTheme = useTheme();
    const { user } = useUser();

    useEffect(() => {
        getEvents();
    }, []);

    const getEvents = (date) => {
        if (!date) {
            date = "";
        }

        return axios
            .get(`/api/v1/dashboard/calendar-event`, {
                params: {
                    date: String(date),
                },
            })
            .then(({ data }) => {
                //NOTE: the startAt and endAt fields have doctored times to make the events appear in the calendar
                //on the correct day. This is a temporary fix until we work out how to handle timezones properly.
                setEvents(data.data);
            });
    };

    const onEventClick = (data) => {
        setEventModalInfo(data);
        setEventModalVisible(true);
    };

    const onNewEventClick = (data) => {
        //todo
    };

    const onStateChange = (data) => {
        if (data.selectedView === CalendarView.MONTH) {
            getEvents(data.selectedDate);
        }
    };

    const language = user.language;

    return (
        <>
            {eventModalVisible && (
                <Modal
                    visible={eventModalVisible}
                    onClose={() => setEventModalVisible(false)}
                >
                    <Row>
                        <Column>
                            <h3>{eventModalInfo.title}</h3>
                        </Column>
                        <Column>
                            <DraftJSOutput>
                                {eventModalInfo.description}
                            </DraftJSOutput>
                        </Column>
                        <Column>
                            <p>
                                {format(eventModalInfo.start_date, "PP hh:mm")}{" "}
                                - {format(eventModalInfo.end_date, "PP hh:mm")}
                            </p>
                        </Column>
                    </Row>
                </Modal>
            )}

            {/* <Row style={{ height: "340px" }}> */}
            {events && (
                <CalenderWrapper>
                    <style>
                        {`
                            .Kalend__CalendarHeader, .Kalend__CalendarHeader-dark, .Kalend__CalendarHeader-tall, .Kalend__CalendarHeader-tall-dark, .Kalend__CalendarHeader-small, .Kalend__CalendarHeader-day, .Kalend__CalendarHeader-tall-day {
                                margin-bottom: 0.5rem;
                            }
                            .Kalend__header_calendar_button-text-selected, .Kalend__header_calendar_button-text-selected-dark {
                                color: ${globalTheme.colors.accent} !important;
                            }
                            .Kalend__Event-month {
                                padding: 10px 1px;
                            }
                        `}
                    </style>
                    <Kalend
                        // kalendRef={props.kalendRef}
                        onNewEventClick={onNewEventClick}
                        initialView={CalendarView.MONTH}
                        disabledViews={[
                            CalendarView.AGENDA,
                            CalendarView.THREE_DAYS,
                            CalendarView.WEEK,
                            CalendarView.DAY,
                        ]}
                        onEventClick={onEventClick}
                        //NOTE: the startAt and endAt fields have doctored times to make the events appear in the calendar
                        //on the correct day. This is a temporary fix until we work out how to handle timezones properly.
                        events={events}
                        initialDate={new Date().toISOString()}
                        hourHeight={60}
                        showWeekNumbers={false}
                        // selectedView={selectedView}
                        // timezone={"Europe/Berlin"}
                        // draggingDisabledConditions={{
                        //   summary: 'Computers',
                        //   allDay: false,
                        //   color: 'pink',
                        // }}
                        onEventDragFinish={OnEventDragFinish}
                        // onStateChange={props.onStateChange}
                        // selectedView={props.selectedView}
                        showTimeLine={true}
                        isDark={false}
                        autoScroll={true}
                        onStateChange={onStateChange}
                        disabledDragging={true}
                        style={{
                            primaryColor: globalTheme.colors.primary,
                            baseColor: globalTheme.colors.secondary,
                            inverseBaseColor: globalTheme.colors.primary,
                        }}
                        colors={{
                            light: {
                                primaryColor: globalTheme.colors.accent,
                            },
                        }}
                        language={language}
                        customLanguage={locales[language]}
                    />
                </CalenderWrapper>
            )}
            {/* </Row> */}
        </>
    );
};

export default Calendar;
