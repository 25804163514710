import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { TableList } from "@cortexglobal/rla-components";
import { DeleteFileButton } from "./components/DeleteFileButton";
import { DownloadFileButton } from "./components/DownloadFileButton";
import { FileType } from "./FileType";

interface FileTableProps {
    smartRef: string;
    files: FileType[];
    user: {
        uuid: string;
    };
    deleteFile: Function;
    canAdmin: boolean;
    deletable: boolean;
    singleType: {
        key: string;
        name: string;
    } | null;
}

export const FileTable = ({
    smartRef,
    files,
    user,
    deleteFile,
    canAdmin,
    deletable,
    singleType = null,
}: FileTableProps) => {
    return (
        <TableList>
            <thead>
                <tr>
                    <th
                        style={{
                            width: "50%",
                        }}
                    >
                        {trans("Filename")}
                    </th>
                    <th
                        style={{
                            width: "25%",
                        }}
                    >
                        {trans("Date Uploaded")}
                    </th>
                    {!singleType && (
                        <th
                            style={{
                                width: "25%",
                            }}
                        >
                            {trans("Type")}
                        </th>
                    )}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {files.length <= 0 ? (
                    <tr>
                        <td colSpan="100%">{trans("No files")}</td>
                    </tr>
                ) : (
                    files.map((file) => {
                        return (
                            <tr key={file.uuid}>
                                <td>{file.original_filename}</td>
                                <td>{moment(file.created_at).format("lll")}</td>
                                {!singleType && <td>{file.readable_type}</td>}
                                <td
                                    style={{
                                        display: "flex",
                                    }}
                                >
                                    {deletable && canAdmin && (
                                        <DeleteFileButton
                                            file={file}
                                            deleteFile={deleteFile}
                                        />
                                    )}
                                    <DownloadFileButton
                                        file={file}
                                        smartRef={smartRef}
                                    />
                                </td>
                            </tr>
                        );
                    })
                )}
            </tbody>
        </TableList>
    );
};
