import React from "react";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//@ts-ignore cortex
import { useIntl } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { Button } from "@cortexglobal/rla-components";
import axios from "axios";
import { FileType } from "../FileType";

interface FileManagementButtonsProps {
    smartRef: string;
    file: FileType;
    children?: any;
}

export const DownloadFileButton = ({
    smartRef,
    file,
    children
}: FileManagementButtonsProps) => {
    const intl = useIntl();

    const downloadFile = (event: MouseEvent) => {
        event.preventDefault();
        return axios
            .get(`/api/v1/files/${smartRef}/file/${file.uuid}/download`, {
                responseType: "blob",
                timeout: 30000
            })
            .then(response => {
                saveAs(new Blob([response.data]), file.original_filename);
            })
            .catch(e => {
                console.log(e.message);
            });
    };

    return (
        <Button
            type="secondary"
            onClick={downloadFile}
            title={intl.formatMessage({ id: "Download" })}
        >
            {children || <FontAwesomeIcon icon="download" />}
        </Button>
    );
};
