import React from "react";
import { Img as ReactImage } from "react-image";
import LoadingIconSmall from "./loaders/LoadingIconSmall";
import styled, { css } from "styled-components";

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
`;

const StyledReactImage = styled(ReactImage)`
    ${({ showCheckerboard }) =>
        showCheckerboard &&
        css`
            background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
                linear-gradient(-45deg, #ccc 25%, transparent 25%),
                linear-gradient(45deg, transparent 75%, #ccc 75%),
                linear-gradient(-45deg, transparent 75%, #ccc 75%);
            background-size: 20px 20px;
            background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
        `}
`;
const Image = ({
    src,
    fallback = "/img/placeholder.png",
    showCheckerboard = false,
    ...rest
}) => {
    return (
        <StyledReactImage
            src={src}
            loader={
                <LoadingWrapper>
                    <LoadingIconSmall style={{ margin: "auto" }} />
                </LoadingWrapper>
            }
            unloader={
                typeof fallback === "string" ? (
                    <img {...rest} src={fallback} />
                ) : (
                    fallback
                )
            }
            // showCheckerboard={showCheckerboard}
            {...rest}
        />
    );
};

export default Image;
