import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";
import {
    Row,
    Column,
    Panel,
    IconTextButton,
    Loading,
    Modal,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { Audit, TransitionButtons } from "@cortexglobal/workflow";
import EventSummaryDetails from "./summary/EventSummaryDetails";
import EventCompletionForm from "../../events/forms/EventCompletionForm";
import MarketingPlannerSummary from "./summary/MarketingPlannerSummary";
import StatusSummary from "./summary/StatusSummary";

const EventSummary = ({
    loading = "loaded",
    event,
    workflowLoading,
    editing,
    settings,
    workflowLoadingCallback = () => {},
    workflowUpdatedCallback = () => {},
    plannerPath,
    getEvent,
}) => {
    const addToast = useAddToast();
    const history = useHistory();
    // const [event, setEvent] = useState(new EventObject());

    // useEffect(() => {
    //     setEvent(event);
    // }, [event]);

    // const updateEvent = (data) => {
    //     const newEvent = new EventObject({ ...event, ...data });
    //     setEvent(newEvent);
    // };

    const [isActionSummaryModelShowing, setIsActionSummaryModelShowing] =
        useState(false);

    const [isActualSpendModelShowing, setIsActualSpendModelShowing] =
        useState(false);

    const { user } = useSelector((state) => {
        return state.auth;
    });

    const render = (loading) => {
        if (loading === "loading") {
            return (
                <Column medium={12}>
                    <Loading />
                </Column>
            );
        }

        return (
            <Row>
                <Column medium={9}>
                    <EventSummaryDetails
                        event={event}
                        settings={settings}
                        // loading={loading}
                        workflowLoading={workflowLoading}
                        user={user}
                    />
                </Column>

                <Column medium={3}>
                    <h3>{trans("Available Actions")}</h3>

                    <Panel>
                        <TransitionButtons
                            item={{ workflow: event.workflowable }}
                            callback={workflowUpdatedCallback}
                            loadingCallback={workflowLoadingCallback}
                            childrenPosition="bottom"
                        >
                            {!editing &&
                                (!event.workflowable ||
                                    get(event, "workflowable.allow_edits") ===
                                        true) && (
                                    <>
                                        <IconTextButton
                                            as={Link}
                                            to={`/events/planner/${event.uuid}`}
                                            expanded={true}
                                            icon={faEdit}
                                            showCircle={false}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            themeColor="alert"
                                            expanded={true}
                                            icon={faTrash}
                                            showCircle={false}
                                            onClick={() =>
                                                axios
                                                    .delete(
                                                        `/api/v1/events/${event.uuid}`
                                                    )
                                                    .then(() => {
                                                        addToast({
                                                            type: "success",
                                                            content: trans(
                                                                "Event successfully deleted"
                                                            ),
                                                            showFor: 5000,
                                                        });
                                                        history.push("/events");
                                                    })
                                            }
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </>
                                )}

                            {event.isComplete() && (
                                <>
                                    <IconTextButton
                                        expanded={true}
                                        onClick={() =>
                                            setIsActualSpendModelShowing(true)
                                        }
                                    >
                                        {trans("Event Completion Form")}
                                    </IconTextButton>
                                </>
                            )}
                        </TransitionButtons>
                    </Panel>

                    <h3>{trans("Status")}</h3>
                    <StatusSummary heading="status" event={event} />

                    {settings.useMarketingPlanner && (
                        <MarketingPlannerSummary
                            event={event}
                            settings={settings}
                            plannerPath={plannerPath}
                            getEvent={getEvent}
                        />
                    )}

                    {/** TODO - Add event request status here with the title "Approval" */}
                </Column>
            </Row>
        );
    };

    return (
        <>
            {render(loading)}
            {event && (
                <Modal
                    key="actionSummaryModal"
                    onClose={() => setIsActionSummaryModelShowing(false)}
                    visible={isActionSummaryModelShowing}
                    maxWidth="800px"
                >
                    <Audit
                        smartRef={event.smartRef}
                        status={event.workflow ? event.workflow.status : ""}
                    />
                </Modal>
            )}

            {event && (
                <Modal
                    key="actualSpendModal"
                    onClose={() => setIsActualSpendModelShowing(false)}
                    visible={isActualSpendModelShowing}
                    maxWidth="800px"
                >
                    <EventCompletionForm
                        event={event}
                        cancel={() => setIsActualSpendModelShowing(false)}
                        // callback={(data) => {
                        //     event.budgetary_comments = data.budgetary_comments;
                        //     event.actual_venue_cost = data.actual_venue_cost;
                        //     event.actual_budget = data.actual_budget;
                        //     event.bookingItems = data.bookingItems;
                        // }}
                        callback={(data) => {
                            // updateEvent(data);
                            getEvent(event.uuid);
                        }}
                    />
                </Modal>
            )}
        </>
    );
};

export default EventSummary;
