import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { css, withTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

import {
    faCheck,
    faCog,
    faPencilAlt,
    faSave,
    faTimes,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
    parseValidationErrors,
    getItem,
    setItem,
    transparentize,
} from "@cortexglobal/cortex-utilities";
import { trans, useIntl } from "@cortexglobal/rla-intl";

/* @ts-ignore cortex */
import {
    StyledSelectAdvanced,
    FormRow,
    Row,
    Column,
    IconTextButton,
    PlainButton,
    Tooltip,
    SubmitButton,
    Modal,
    FormikInput,
    InputField,
    useAddToast,
    Table,
    FormikCheckbox,
} from "../../index";
import { Separator } from "./StandardFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../loaders/Loading";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";

const Wrapper = styled.aside`
    margin-bottom: 0.4rem;
`;

const TitleRow = styled.aside`
    margin-top: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Title = styled.h4`
    flex-grow: 1;
    font-size: 1rem;
`;
const AdminButton = styled(PlainButton)`
    font-size: 16px;
    margin-left: 0.5rem;
`;
const InputWrapper = styled.div`
    display: flex;
`;

const FilterTableRow = styled.tr`
    ${(props) =>
        props.default &&
        css`
            td {
                font-weight: bold;
                color: ${({ theme }) => theme.table.types.default.thColor};
                background-color: ${({ theme }) =>
                    transparentize(
                        theme.table.types.default.thBackground,
                        0.2
                    )} !important;

                button {
                    /* color: ${(props) => props.theme.colors.white}; */

                    color: ${({ theme }) => theme.table.types.default.thColor};

                    font-weight: bold;
                }
            }
        `};
    .edit-button {
        opacity: 0;
        transition: opacity 400ms;
    }
    .edit-button:focus {
        opacity: 1;
    }

    &:hover {
        .edit-button {
            opacity: 1;
        }
    }
`;
const saveSchema = Yup.object().shape({
    name: Yup.string().required(),
});

// export type SavedFilter = {
//     value: string;
//     text: string;
//     filters: { [key: string]: string[] };
//     default: boolean;
// };

export const SavedFilters = ({
    onApply,
    filters,
    selectedFilters,
    filterAreaSlug,
    placeholder = "",
    singleColumn = true,
    currentSavedFilter = null,
    // handleDefaultFilterLoaded = (filter: SavedFilter): any => {}
}) => {
    const intl = useIntl();
    const [savedFilters, setSavedFilters] = useState({
        loaded: false,
        data: [],
    });
    const addToast = useAddToast();
    const dispatch = useDispatch();

    const [selectedFilter, setSelectedFilter] = useState("");
    const [manageModalVisible, setManageModalVisible] = useState(false);
    const [saveModalVisible, setSaveModalVisible] = useState(false);
    const [editingFilter, setEditingFilter] = useState(null);
    const [currentFiltersMatchExisting, setCurrentFiltersMatchExisting] =
        useState(false);

    const localStorageKey = `${filterAreaSlug}_saved_filters`;
    useEffect(() => {
        const localSavedFilters = getItem(localStorageKey);
        // console.log(localSavedFilters);
        if (localSavedFilters[localStorageKey]) {
            const filtersFromStorage = JSON.parse(
                localSavedFilters[localStorageKey]
            );
            setSavedFilters({
                loaded: true,
                data: filtersFromStorage,
            });
            if (currentSavedFilter === null) {
                processDefault(filtersFromStorage);
            }
        }
        loadSavedFilters();
    }, []);
    useEffect(() => {
        const matchingSavedFilter = savedFilters.data.find((savedFilter) => {
            // console.log(savedFilter.filters, selectedFilters);
            return isEqual(savedFilter.filters, selectedFilters);
        });
        if (matchingSavedFilter) {
            setSelectedFilter(matchingSavedFilter.value);
            setCurrentFiltersMatchExisting(true);
        } else {
            setSelectedFilter("");
            setCurrentFiltersMatchExisting(false);
        }
    }, [selectedFilters]);

    const selectSavedFilter = ({ value }) => {
        const selectedFilter = savedFilters.data.find((savedFilter) => {
            // console.log(savedFilter);
            return savedFilter.value === value;
        });
        if (selectedFilter) {
            setSelectedFilter(selectedFilter.value);
            onApply(selectedFilter.filters);
        }
    };
    const processDefault = (savedFilters /*: SavedFilter[]*/) => {
        const defaultFilter = savedFilters.find((filter) => filter.default);
        if (defaultFilter) {
            setSelectedFilter(defaultFilter.value);
            onApply(defaultFilter.filters);
            dispatch({ type: `${localStorageKey}`, data: defaultFilter });
        }
    };
    const storeSavedFilters = (savedFilters /*: SavedFilter[]*/) => {
        setItem(localStorageKey, savedFilters);
    };
    const loadSavedFilters = (processDefaultFilter = true) => {
        return axios
            .get("/api/v1/saved-filters", { params: { slug: filterAreaSlug } })
            .then(({ data }) => {
                const savedFiltersFromServer = data.data;
                setSavedFilters({ loaded: true, data: savedFiltersFromServer });
                storeSavedFilters(savedFiltersFromServer);
                if (processDefaultFilter) {
                    processDefault(savedFiltersFromServer);
                }
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: e.message,
                    showFor: 5000,
                });
            });
    };

    const handleSaveSubmit = (
        values,
        { setErrors, setSubmitting, ...rest }
    ) => {
        axios
            .post("/api/v1/saved-filters", values)
            .then(({ data }) => {
                loadSavedFilters(false);
                setSelectedFilter(data.data.value);

                setSaveModalVisible(false);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    setSubmitting(false);
                });
            });
    };

    const deleteSavedFilter = (filterToDelete) => {
        axios
            .delete(`/api/v1/saved-filters/${filterToDelete.value}`)
            .then(({ data }) => {
                const updatedFilters = savedFilters.data.filter(
                    (savedFilter) => {
                        return savedFilter.value !== filterToDelete.value;
                    }
                );
                setSavedFilters({
                    loaded: true,
                    data: updatedFilters,
                });
                storeSavedFilters(updatedFilters);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: e.message,
                    showFor: 5000,
                });
            });
    };

    const updateSavedFilter = () => {
        if (!editingFilter) {
            return;
        }
        if (
            typeof editingFilter.text !== "string" ||
            (typeof editingFilter.text === "string" &&
                editingFilter.text.length === 0)
        ) {
            setEditingFilter(null);
            return;
        }
        axios
            .put(`/api/v1/saved-filters/${editingFilter.value}`, {
                name: editingFilter.text,
                slug: filterAreaSlug,
                filters: JSON.stringify(editingFilter.filters),
                default: editingFilter.default,
            })
            .then(({ data }) => {
                const updatedFilters = savedFilters.data.map((savedFilter) => {
                    if (savedFilter.value === editingFilter.value) {
                        savedFilter.text = data.data.text;
                    }
                    return savedFilter;
                });
                setSavedFilters({
                    loaded: true,
                    data: updatedFilters,
                });
                storeSavedFilters(updatedFilters);

                setEditingFilter(null);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: e.message,
                    showFor: 5000,
                });
            });
    };
    const toggleDefault = (savedFilterToToggle) => {
        setSavedFilters({ ...savedFilters, loaded: false });

        return axios
            .put(
                `/api/v1/saved-filters/${savedFilterToToggle.value}/toggle-default`
            )
            .then(({ data }) => {
                const savedFiltersFromServer = data.data;
                setSavedFilters({ loaded: true, data: savedFiltersFromServer });
                storeSavedFilters(savedFiltersFromServer);

                setEditingFilter(null);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: e.message,
                    showFor: 5000,
                });
            });
    };

    if (placeholder === "") {
        placeholder = intl.formatMessage({
            id: "Select Saved Filter",
        });
    }

    return (
        <>
            <Wrapper>
                {false && singleColumn && <Separator />}
                <TitleRow>
                    <Title>{trans("Saved Filters")}</Title>
                    <AdminButton
                        onClick={() => setSaveModalVisible(true)}
                        disabled={currentFiltersMatchExisting}
                    >
                        {currentFiltersMatchExisting ? (
                            <Tooltip
                                text={intl.formatMessage({
                                    id: "Saved filter already exists",
                                })}
                                showIcon={false}
                            >
                                <FontAwesomeIcon icon={faSave} />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                text={intl.formatMessage({
                                    id: "Save current filters",
                                })}
                                showIcon={false}
                            >
                                <FontAwesomeIcon icon={faSave} />
                            </Tooltip>
                        )}
                    </AdminButton>
                    <AdminButton onClick={() => setManageModalVisible(true)}>
                        <Tooltip
                            text={intl.formatMessage({
                                id: "Manage saved filters",
                            })}
                            showIcon={false}
                        >
                            <FontAwesomeIcon icon={faCog} />
                        </Tooltip>
                    </AdminButton>
                </TitleRow>
                <StyledSelectAdvanced
                    placeholder={placeholder}
                    name="savedFilters"
                    value={selectedFilter}
                    options={savedFilters.data}
                    onChange={selectSavedFilter}
                    marginBottom="0.4rem"
                />
            </Wrapper>
            <Modal
                maxWidth="50rem"
                visible={manageModalVisible}
                onClose={() => setManageModalVisible(false)}
            >
                <h3>{trans("Manage Your Saved Filters")}</h3>
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: "80%" }}>{trans("Filter")}</th>
                            <th style={{ width: "20%" }}>{trans("Default")}</th>
                            <th>{trans("Delete")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {savedFilters.loaded ? (
                            savedFilters.data
                                .sort(function (x, y) {
                                    // true values first
                                    return x.default === y.default
                                        ? 0
                                        : x.default
                                        ? -1
                                        : 1;
                                })
                                .map((savedFilter) => {
                                    return (
                                        <FilterTableRow
                                            key={savedFilter.value}
                                            default={savedFilter.default}
                                        >
                                            <td
                                                onClick={() => {
                                                    if (!editingFilter) {
                                                        setEditingFilter(
                                                            savedFilter
                                                        );
                                                    }
                                                }}
                                            >
                                                {editingFilter &&
                                                editingFilter.value ===
                                                    savedFilter.value ? (
                                                    <InputWrapper>
                                                        <InputField
                                                            style={{
                                                                flexGrow: "1",
                                                                marginBottom: 0,
                                                            }}
                                                            value={
                                                                editingFilter.text
                                                            }
                                                            onChange={({
                                                                value,
                                                            }) =>
                                                                setEditingFilter(
                                                                    {
                                                                        ...editingFilter,
                                                                        text: value,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                        <IconTextButton
                                                            showCircle={false}
                                                            icon={faCheck}
                                                            onClick={
                                                                updateSavedFilter
                                                            }
                                                        />
                                                        <IconTextButton
                                                            showCircle={false}
                                                            icon={faTimes}
                                                            onClick={() =>
                                                                setEditingFilter(
                                                                    null
                                                                )
                                                            }
                                                        />
                                                    </InputWrapper>
                                                ) : (
                                                    <>
                                                        {savedFilter.default && (
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    display:
                                                                        "inline-block",
                                                                    marginRight:
                                                                        "0.5rem",
                                                                }}
                                                                icon={faStar}
                                                                color="gold"
                                                            />
                                                        )}
                                                        {savedFilter.text}
                                                        {/* @ts-ignore cortex */}
                                                        <IconTextButton
                                                            className="edit-button"
                                                            showCircle={false}
                                                            icon={faPencilAlt}
                                                            onClick={() =>
                                                                setEditingFilter(
                                                                    savedFilter
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </td>
                                            <td className="default-button">
                                                {/* @ts-ignore cortex */}
                                                <PlainButton
                                                    showCircle={false}
                                                    icon={
                                                        savedFilter.default
                                                            ? faTimes
                                                            : faCheck
                                                    }
                                                    onClick={() =>
                                                        toggleDefault(
                                                            savedFilter
                                                        )
                                                    }
                                                >
                                                    {savedFilter.default
                                                        ? trans("Remove ")
                                                        : trans(
                                                              "Set as Default"
                                                          )}
                                                </PlainButton>
                                            </td>
                                            <td>
                                                {/* @ts-ignore cortex */}
                                                <IconTextButton
                                                    showCircle={false}
                                                    icon={faTrash}
                                                    onClick={() =>
                                                        deleteSavedFilter(
                                                            savedFilter
                                                        )
                                                    }
                                                />
                                            </td>
                                        </FilterTableRow>
                                    );
                                })
                        ) : (
                            <Loading />
                        )}
                    </tbody>
                </Table>
            </Modal>
            <Modal
                maxWidth="80%"
                visible={saveModalVisible}
                onClose={() => setSaveModalVisible(false)}
            >
                <h3>{trans("Save Current Filters")}</h3>
                <Formik
                    initialValues={{
                        name: "",
                        default: false,
                        filters: JSON.stringify(selectedFilters),
                        slug: filterAreaSlug,
                    }}
                    onSubmit={handleSaveSubmit}
                    validationSchema={saveSchema}
                >
                    {({ isSubmitting, values, errors }) => {
                        return (
                            <Form className="form-group">
                                <FormRow
                                    name="name"
                                    label={trans("Filter name")}
                                    helpText={trans(
                                        "Use a descriptive name to recognise this filter selection in the future"
                                    )}
                                    error={errors.name}
                                >
                                    {/* @ts-ignore cortex */}
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                        showError={false}
                                    />
                                </FormRow>
                                <FormRow
                                    name="default"
                                    label={trans("Make default")}
                                    helpText={trans(
                                        "Use this set of filters as your default view"
                                    )}
                                    error={errors.default}
                                >
                                    {/* @ts-ignore cortex */}
                                    <FormikCheckbox
                                        name="default"
                                        value={values.default}
                                        showError={false}
                                    />
                                </FormRow>
                                <Row>
                                    <Column medium={6}>&nbsp;</Column>
                                    <Column
                                        medium={3}
                                        style={{ paddingBottom: "1rem" }}
                                    >
                                        {/* @ts-ignore cortex */}
                                        <SubmitButton
                                            expanded
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </Column>
                                </Row>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};

export default SavedFilters;
