import merge from "lodash/merge";
import {
    defaultThemeOptions,
    generateTheme,
} from "@cortexglobal/rla-components";
import { transform } from "lodash";

export const colors = {
    ...defaultThemeOptions.colors,
    primary: "#dddddd",
    secondary: "#0065B1",
    accent: "#055C71",
    bmwBlue: "#0065B1",

    //neutralDarker:
    neutralDark: "#707070",
    neutral: "#DFE0E1",
    neutralLight: "#F5F5F5",
    //neutralLighter:
    //neutralLightest:

    lightGray: "#f8f8f8",
    mediumGray: "#9CA1A4",
    darkGray: "#6f6f6f",

    white: "#ffffff",
    gray100: "#f5f5f5",
    gray200: "#e8e8e8",
    gray300: "#cbc9c7",
    gray400: "#bbc0c7",
    gray500: "#aaafb7",
    gray600: "#9a9fa7",
    gray700: "#53565a",
    gray800: "#404346",
    gray900: "#2b2d2f",
    black: "#262626",

    alert: "#D54644",
    // warning: "#ff9800",
    warning: "#EE7424",
    success: "#4caf50",
    info: "#00bcd4",
    background: "#f8f8f8",
    platinum: "#a2b8e4",
    gold: "#ffdb04",
    silver: "#dadada",
    bronze: "#ff8604",
    highlight: "#e6c2c1",
};

/**
 * Fonts
 *
 * Can be used to inject @font-face rules into the global css of the application
 *
 * options: string containing the @font-face rules
 * package: Core
 * customisation: maybe
 */
const fonts = `
    @font-face { font-family: 'BMWGroupTNProTT-Regular'; src: url('/fonts/BMWGroupTNProTT-Regular.eot'); src: url('/fonts/BMWGroupTNProTT-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/BMWGroupTNProTT-Regular.woff2') format('woff2'), url('/fonts/BMWGroupTNProTT-Regular.woff') format('woff'), url('/fonts/BMWGroupTNProTT-Regular.ttf') format('truetype'); }
    @font-face { font-family: 'BMWGroupTNCondensedProTT-Reg'; src: url('/fonts/BMWGroupTNCondensedProTT-Reg.eot'); src: url('/fonts/BMWGroupTNCondensedProTT-Reg.eot?#iefix') format('embedded-opentype'), url('/fonts/BMWGroupTNCondensedProTT-Reg.woff2') format('woff2'), url('/fonts/BMWGroupTNCondensedProTT-Reg.woff') format('woff'), url('/fonts/BMWGroupTNCondensedProTT-Reg.ttf') format('truetype'); }
    @font-face { font-family: 'BMWGroupTNCondensedProTT-Bd'; src: url('/fonts/BMWGroupTNCondensedProTT-Bd.eot'); src: url('/fonts/BMWGroupTNCondensedProTT-Bd.eot?#iefix') format('embedded-opentype'), url('/fonts/BMWGroupTNCondensedProTT-Bd.woff2') format('woff2'), url('/fonts/BMWGroupTNCondensedProTT-Bd.woff') format('woff'), url('/fonts/BMWGroupTNCondensedProTT-Bd.ttf') format('truetype'); }
`;

/**
 * Font
 *
 * Used within the theme to specify fonts of different weights, using the options injected by the
 * @font-face rules in the globalCss
 *
 * options: see the default setup below
 * package: Core
 * customisation: maybe
 */
const font = {
    fontLight: "'BMWGroupTNProTT-Regular', sans-serif",
    fontBody: "'BMWGroupTNProTT-Regular', sans-serif",
    fontMedium: "'BMWGroupTNCondensedProTT-Reg', sans-serif",
    fontSemibold: "'BMWGroupTNCondensedProTT-Reg', sans-serif",
    fontBold: "'BMWGroupTNCondensedProTT-Bd', sans-serif",
    fontHeading: "'BMWGroupTNCondensedProTT-Bd', sans-serif",
};

const siteTheme = {
    body: {
        background: colors.background,
        expanded: false,
    },
    colors: colors,

    checkbox: {
        color: colors.accent,
        background: colors.white,
        stroke: colors.black,
    },
    dashboard: {
        pageTitle: {
            background: "none",
        },
        panel: {
            wrapper: {
                radius: "5",
                boxShadow: "none",
            },
            bar: {
                background: colors.white,
                fontSize: "1.1rem",
                height: 60,
                textTransform: "none",
                padding: `1.5rem 1.2rem`,
                borderBottom: `1px solid ${colors.gray200}`,
            },
            content: {
                background: colors.white,
            },
        },
    },
    filters: {
        panel: {
            backgroundColor: colors.gray200,
        },
        row: {
            shadow: "none",
        },
        showLabelOnActiveFilters: true,
    },
    header: {
        color: colors.black,
        background: colors.white,
    },
    loaders: {
        color: colors.black,
    },
    input: {
        color: colors.black,
        background: colors.neutral,
    },
    logo: {
        src: `/img/Logo_BMW_GROUP.svg`,
        height: "80px",
    },
    navigation: {
        admin: {
            background: colors.white,
        },
        background: colors.white,
        maxWidth: "100%",
        border: {
            width: "0 0 4px",
            color: colors.mediumGray,
        },
        navLink: {
            margin: 1.4,
            padding: 0.8,
            textTransform: "capitalise",
            fontWeight: "normal",
            fontSize: 14,
            activeColor: colors.gray800,
            activeTextDecoration: "none",
            hoverTextDecoration: "none",
            activeFontWeight: "bold",
            hoverFontWeight: "bold",
            navUnderline: {
                enabled: true,
                height: "4px",
                activeHeight: "8px",
                hoverHeight: "10px",
                bottomOffset: "-4px",
                sideOffset: "-14px",
                color: colors.black,
            },
        },
        mobileNav: {
            buttonColor: colors.black,
        },
        subNavLink: {
            fontSize: 12,
            color: colors.black,
            activeColor: colors.black,
            activeTextDecoration: "none",
            textTransform: "capitalize",
        },
    },

    modal: {
        color: colors.black,
    },
    pagination: {
        activeBackground: colors.secondary,
    },
    pageTitle: {
        background: colors.white,
    },
    panel: {
        boxShadow: "0 0 7px 2px rgba(37,37,37,.05)",
    },
    planner: {
        colors: {
            kpiCategory: {
                color: colors.white,
                background: colors.secondary,
            },
        },
    },
    search: {
        boxShadow: "none", //"rgba(0, 18, 27, 0.2) 0px 2px 6px",
        boxShadowHover: "none", //rgba(0, 18, 27, 0.2) 0px 2px 6px",
        badge: {
            show: false,
        },
        icons: {
            searchIcon: null,
            showCircle: false,
        },
        image: {
            padding: 0,
            objectFit: "cover",
            background: colors.gray200,
        },
    },
    spacing: {
        radius: "0.2",
        padding: "1.2",
        margin: "0.8",
    },
    select: {
        backgroundColor: colors.neutral,
        color: colors.black,
        focus: colors.bmwBlue,
    },
    footer: {
        color: colors.black,
        background: colors.background,
    },
    table: {
        types: {
            default: {
                background: colors.lightGray,
                backgroundAlt: colors.lightGray,
                thBackground: colors.accent,
            },
            planner: {
                background: colors.lightGray,
                backgroundAlt: colors.lightGray,
                thBackground: colors.black,
            },
        },
    },
    tabordion: {
        borderColor: colors.gray200,
        tabStyle: "linetabs",
        default: {
            color: colors.black,
            background: "none",
            hover: colors.gray100,
        },
        active: {
            color: colors.secondary,
            background: "none",
            borderColor: colors.accent,
        },
    },
    tooltip: {
        color: colors.gray900,
        background: colors.white,
        opacity: 1,
    },
    userInfo: {
        wrapper: {
            marginRight: "1rem",
            paddingRight: "1rem",
            borderRight: "1px solid " + colors.mediumGray,
        },
        messageIconButton: {
            style: {
                margin: "0 0 0.5rem",
            },
        },
    },
};

const theme = generateTheme({ colors: colors, font, fonts }, siteTheme);
export default theme;
