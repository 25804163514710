import React, { useState } from "react";
import { Modal } from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";
import Composer from "../Composer";
import { useEffect } from "react";

export const ShareComposer = ({
    onClose,
    urls = [],
    title = "",
    subject = "",
    prependedMessage = "",
}) => {
    const emptyMessage = {
        format: "new",
        to: null,
        user_uuid: null,
        subject: subject,
        message: "",
        send_now: true,
        send_date: "",
        send_time: "",
    };
    const [composerMessage, setComposerMessage] = useState({ ...emptyMessage });

    useEffect(() => {
        const newMessage = processUrlsToMessage(urls);

        setComposerMessage({
            ...composerMessage,
            message: newMessage,
        });
    }, [urls]);

    const processUrlsToMessage = (urls) => {
        const quillUrls = urls.map((url) => {
            return {
                insert: `\n${url.title}`,
                attributes: { link: url.url },
            };
        });

        return { ops: [{ insert: prependedMessage }, ...quillUrls] };
    };
    const messageSentCallback = () => {
        setComposerMessage(emptyMessage);
        onClose();
    };

    //Don't render the composer if a message hasn't been set, this is to prevent the composer
    //from rendering with an empty message, which meant the generated message was empty and didn't update
    if (!composerMessage || composerMessage.message === "") return null;

    return (
        <Composer
            title={title}
            settings={{}}
            message={composerMessage}
            successCallback={messageSentCallback}
            hideComposer={messageSentCallback}
        />
    );
};
export default ShareComposer;

// export const ShareComposer = ({
//     onClose,
//     urls = [],
//     title = "",
//     subject = "",
//     prependedMessage = "",
// }) => {
//     const emptyMessage = {
//         format: "new",
//         to: null,
//         user_uuid: null,
//         subject: subject,
//         message: "",
//         send_now: true,
//         send_date: format(new Date(), "dd/MM/yyyy"),
//         send_time: "",
//     };
//     const [composerMessage, setComposerMessage] = useState(emptyMessage);

//     useEffect(() => {
//         const newMessage = processUrlsToMessage(urls);
//         console.log({ newMessage });
//         setComposerMessage({
//             ...composerMessage,
//             message: newMessage,
//         });
//     }, [urls]);

//     const processUrlsToMessage = (urls) => {
//         const quillUrls = urls.map((url) => {
//             return {
//                 insert: `\n${url.title}\n`,
//                 attributes: { link: url.url },
//             };
//         });

//         return { ops: [{ insert: prependedMessage }, ...quillUrls] };
//     };
//     const messageSentCallback = () => {
//         setComposerMessage(emptyMessage);
//         onClose();
//     };
//     console.log({ composerMessage });
//     return (
//         <Composer
//             title={title}
//             settings={{}}
//             message={composerMessage}
//             successCallback={() => messageSentCallback()}
//             hideComposer={() => onClose()}
//         />
//     );
// };

// export default ShareComposer;
