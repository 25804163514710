import React from "react";
import PropTypes from "prop-types";
import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import StyledSelectSimple from "../styledElements/StyledSelectSimple";
import { trans } from "@cortexglobal/rla-intl";

const SelectField = ({
    options,
    type,
    name,
    onChange,
    label,
    inlineLabel,
    emptyOption,
    readOnly,
    error,
    labelWidth,
    labelAlign,
    wrapperProps,
    loading,
    ...rest
}) => {
    const handleChange = (event) => {
        onChange(
            {
                name: name,
                value: event.target.value,
            },
            event
        );
    };
    return (
        <span {...wrapperProps}>
            {label && (
                <FormLabel
                    name={name}
                    width={labelWidth}
                    align={labelAlign}
                    inlineLabel={inlineLabel}
                    {...rest}
                >
                    {label}
                </FormLabel>
            )}
            <StyledSelectSimple
                id={name}
                name={name}
                onChange={handleChange}
                labelWidth={labelWidth}
                labelAlign={labelAlign}
                options={loading ? [] : options}
                emptyOption={emptyOption}
                error={error}
                {...rest}
            />

            <InputError error={error} />
        </span>
    );
};

SelectField.displayName = "SelectField";

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired,
            type: PropTypes.any,
        })
    ),
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inlineLabel: PropTypes.bool,
    emptyOption: PropTypes.string,
    error: PropTypes.string,
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
};
SelectField.defaultProps = {
    size: "default",
    error: "",
    height: 30,
};
export default SelectField;
