import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGhost } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";
import {
    usePermissions,
    useUser,
    resetGroupRole,
} from "@cortexglobal/cortex-auth-redux";

import {
    Row,
    Column,
    Menu,
    MenuItem,
    StyledLink,
    Button,
    SidePanel,
    CloseButton,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import GroupSwitcher from "./GroupSwitcher";
import RoleSwitcher from "./RoleSwitcher";
import BrandSwitcher from "./BrandSwitcher";
import LogoutWrapper from "./LogoutWrapper";

import CortexAuthSiteSwitcher from "./CortexAuthSiteSwitcher";

const StyledPlainButton = styled.a`
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: ${(props) => props.theme.userInfo.icon.color};
    font-size: ${(props) => props.theme.userInfo.icon.size}em;
`;
export const SideMenu = ({
    handleNavClick,
    icon = faBars,
    zIndex = 2000,
    fixed = true,
}) => {
    const { can } = usePermissions();
    const { user } = useUser();
    const [menuVisible, setMenuVisible] = useState(false);
    const dispatch = useDispatch();

    if (!user.loaded || user.isGuest === true) {
        return null;
    }

    const handleClick = () => {
        setMenuVisible(false);
        handleNavClick();
    };

    const unghost = () => {
        return axios
            .post(`/api/v1/admin/ghost/reset`)
            .then(({ data }) => {
                window.location.href = "/";
            })
            .catch((error) => {});
    };

    return (
        <>
            <StyledPlainButton onClick={() => setMenuVisible(!menuVisible)}>
                <FontAwesomeIcon icon={icon} size="lg" />
            </StyledPlainButton>

            <SidePanel
                visible={menuVisible}
                onClose={() => setMenuVisible(false)}
                width="350px"
                height="100%"
                zIndex={zIndex}
                fixed={fixed}
            >
                <Row style={{ paddingTop: "1rem" }}>
                    <Column medium={9}>
                        <p>&nbsp;</p>
                    </Column>
                    <Column medium={3}>
                        <CloseButton onClick={() => setMenuVisible(false)} />
                    </Column>
                    <Column>
                        <GroupSwitcher />
                    </Column>
                    <Column>
                        <RoleSwitcher />
                    </Column>

                    <Column>
                        <CortexAuthSiteSwitcher
                            style={{
                                marginBottom: ".5rem",
                            }}
                        />
                    </Column>

                    {can("brands-can-switch") && (
                        <Column>
                            <BrandSwitcher user={user} />
                        </Column>
                    )}

                    {user.isActing === true && (
                        <Column>
                            <p
                                style={{
                                    textAlign: "right",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={() => dispatch(resetGroupRole())}
                            >
                                {trans("Reset")}
                            </p>
                        </Column>
                    )}
                </Row>
                <Menu>
                    {can("manage-users") && (
                        <MenuItem>
                            <StyledLink
                                to={`/management`}
                                onClick={handleClick}
                            >
                                {trans("User Management")}
                            </StyledLink>
                        </MenuItem>
                    )}
                    {can("manage-imports") && (
                        <MenuItem>
                            <StyledLink to={`/import`} onClick={handleClick}>
                                {trans("Manage Imports")}
                            </StyledLink>
                        </MenuItem>
                    )}
                    {can("surveys-view") && (
                        <MenuItem>
                            <StyledLink to={`/forms`} onClick={handleClick}>
                                {trans("My Form Entries")}
                            </StyledLink>
                        </MenuItem>
                    )}
                    {can("my-profile-edit") && (
                        <MenuItem>
                            <StyledLink to={`/profile`} onClick={handleClick}>
                                {trans("My Profile")}
                            </StyledLink>
                        </MenuItem>
                    )}
                    {can("group-profile-edit") && (
                        <MenuItem>
                            <StyledLink
                                to={`/group-profile`}
                                onClick={handleClick}
                            >
                                {trans("My Company Details")}
                            </StyledLink>
                        </MenuItem>
                    )}
                    {can("admin") && (
                        <MenuItem style={{ padding: "17px" }}>
                            <Button
                                type="secondary"
                                style={{
                                    padding: "0 1rem",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                $expanded
                                as={Link}
                                to={`/admin`}
                                onClick={handleClick}
                            >
                                {trans("Admin")}
                            </Button>
                        </MenuItem>
                    )}
                    <MenuItem>
                        <LogoutWrapper loginUrl="/">
                            {({ logout }) => (
                                <Button
                                    type="secondary"
                                    href="/"
                                    onClick={logout}
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        margin: "auto",
                                        maxWidth: "317px",
                                        padding: "0 1em",
                                        lineHeight: "1",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon="sign-out-alt"
                                        transform="grow-3"
                                    />{" "}
                                    {trans("Logout")}
                                </Button>
                            )}
                        </LogoutWrapper>
                    </MenuItem>

                    {user.ghosted === true && (
                        <MenuItem>
                            <Button
                                type="secondary"
                                href="/"
                                onClick={unghost}
                                style={{
                                    display: "block",
                                    width: "100%",
                                    margin: "auto",
                                    maxWidth: "317px",
                                    padding: "0 1em",
                                    lineHeight: "1",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faGhost}
                                    transform="grow-3"
                                />{" "}
                                {trans("Unghost")}
                            </Button>
                        </MenuItem>
                    )}
                </Menu>
            </SidePanel>
        </>
    );
};

export default SideMenu;
