import CampaignSummary from "./campaignSummary/CampaignSummary";
import CentralComms from "./centralComms/CentralComms";
import CampaignReport from "./campaignReport/CampaignReport";
import PerformanceSummary from "./performanceSummary/PerformanceSummary";
import PageViewTotal from "./pageViewTotal/PageViewTotal";
import AssetListing from "./assetListing/AssetListing";
import AssetCategories from "./assetCategories/AssetCategories";
import AssetCarousel from "./assetCarousel/AssetCarousel";
import PointsSummary from "./PointsSummary";
import CustomerTypeSummary from "./CustomerTypeSummary";
import FeaturedAssets from "./FeaturedAssets";
import NewsList from "./newsList/NewsList";
import EventCalendar from "./Calendar/EventCalendar";
import RSSNewsList from "./newsList/RSSNewsList";
import TopPerformers from "./TopPerformers/TopPerformers";
import NotificationsTotal from "./NotificationsTotal";
import BudgetSummary from "./BudgetSummary";
import PlannerMediaSummary from "./PlannerMediaSummary";
import VisitReportSummary from "./VisitReportSummary";
// import RecentReports from "./RecentReports";
import AssetUsageChart from "./assetUsageChart/AssetUsageChart";
import AssetDownloadsListing from "./assetDownloadsListing/AssetDownloadsListing";
import ActiveUsersListing from "./activeUsersListing/ActiveUsersListing";
import ActiveUsersChart from "./activeUsersChart/ActiveUsersChart";
import MarketingPlannerSummary from "./MarketingPlannerSummary";
import MarketingPlannerMonthSummary from "./MarketingPlannerMonthSummary";
import DrillDownChart from "./DrillDownChart/DrillDownChart";
import DrillDown from "./DrillDownChart/DrillDown";
import UserTotals from "./userTotals/UserTotals";
import AssetTotals from "./assetTotals/AssetTotals";
import Wysiwyg from "./wysiwyg/Wysiwyg";
import Links from "./links/Links";
import MarketingKpiBreakdown from "./marketingKpiBreakdown/MarketingKpiBreakdown";
import MarketingKpiVsBudget from "./marketingKpiVsBudget/MarketingKpiVsBudget";
import DashboardCharts from "./dashboardCharts/DashboardCharts";

export default {
    ActiveUsersListing,
    ActiveUsersChart,
    AssetCategories,
    AssetListing,
    AssetCarousel,
    AssetDownloadsListing,
    AssetUsageChart,
    AssetTotals,
    BudgetSummary,
    CampaignReport,
    CampaignSummary,
    CentralComms,
    CustomerTypeSummary,
    DrillDownChart,
    DrillDown,
    EventCalendar,
    PointsSummary,
    PerformanceSummary,
    FeaturedAssets,
    MarketingPlannerSummary,
    MarketingPlannerMonthSummary,
    NewsList,
    RSSNewsList,
    NotificationsTotal,
    PageViewTotal,
    PlannerMediaSummary,
    // RecentReports,
    Links,
    TopPerformers,
    UserTotals,
    VisitReportSummary,
    Wysiwyg,
    MarketingKpiBreakdown,
    MarketingKpiVsBudget,
    DashboardCharts,
};
