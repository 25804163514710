import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Switch, Route, useHistory } from "react-router-dom";

// import startOfMonth from "date-fns/startOfMonth";
// import endOfMonth from "date-fns/endOfMonth";
// import startOfQuarter from "date-fns/startOfQuarter";
// import endOfQuarter from "date-fns/endOfQuarter";
// import endOfYear from "date-fns/endOfYear";
// import startOfYear from "date-fns/startOfYear";
import {
    startOfMonth,
    endOfMonth,
    startOfQuarter,
    endOfQuarter,
    endOfYear,
    startOfYear,
} from "date-fns";

//import Equalizer from "react-equalizer";
import { Loading, Modal, useAddToast } from "@cortexglobal/rla-components";

import {
    filterObjects,
    formatISO,
    getItem,
    setItem,
    format,
} from "@cortexglobal/cortex-utilities";

import {
    getPlan,
    generateDateRange,
    getPlannerFilters,
    getPlannerCategories,
    getPlannerChildGroups,
    getPlannerConfigOptions,
    selectCategory,
    selectTask,
    selectActivity,
    showActivityOrder,
    addOrderToPlan,
    deleteTask,
    deleteActivity,
} from "./plannerActions";
import Planner from "./screens/Planner";
import AddTaskCategory from "./screens/AddTaskCategory";
import EditTaskCategory from "./screens/EditTaskCategory";
import AddTask from "./screens/AddTask";
import ViewTask from "./screens/ViewTask";
import AddActivity from "./screens/AddActivity";
import ViewActivity from "./screens/ViewActivity";
import ActivityAdded from "./screens/ActivityAdded";
import MyPlans from "./screens/MyPlans";
import PlanDetails from "./screens/PlanDetails";

const queryClient = new QueryClient();

const MarketingPlanner = ({
    selections: { task, category },

    startDate,
    endDate,

    categories,
    theme,
    auth: { user, group, groups: allUserGroups },
    filters,
    match,
    // history,
    location,
    summaryType,
    groups,
    config,
    tasks: allTasks,

    getPlannerFilters,
    getPlannerCategories,
    getPlannerConfigOptions,

    getPlannerChildGroups,

    generateDateRange,
    getPlan,

    loaded,

    deleteTask: deleteTaskAction,
    deleteActivity: deleteActivityAction,
}) => {
    const [tasks, setTasks] = useState([]);
    const [kpiData, setKpiData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const history = useHistory();

    const addToast = useAddToast();
    /**
     * A bit like Bruce Wayne, orphaned Activities are created outside the context of a Task
     * which means they have no home, nowhere to live until a task is created and they are adopted.
     */
    const [orphanedActivities, setOrphanedActivities] = useState([]);

    const initialActivityModalState = {
        showing: false,
        task: null,
        activity: null,
    };

    const [activityModalState, setActivityModalState] = useState(
        initialActivityModalState
    );

    const showActivityModal = (task, optionalCallback) => {
        let newModalState = {};
        if (task) {
            newModalState["task"] = task;
        }

        if (optionalCallback) {
            newModalState["callback"] = optionalCallback;
        }
        newModalState["showing"] = true;

        setActivityModalState(newModalState);
    };

    const closeActivityModal = () => {
        setActivityModalState(initialActivityModalState);
    };

    useEffect(() => {
        //If the start and end dates are in the query string, use them
        const urlParams = new URLSearchParams(location.search);
        let initialStart = urlParams.get("start");
        let initialEnd = urlParams.get("end");

        getPlannerFilters();
        getPlannerCategories();
        getPlannerConfigOptions().then((config) => {
            if (!loaded) {
                if (!initialStart || !initialEnd) {
                    if (config?.options?.plan_period === "quarter") {
                        initialStart = format(
                            startOfQuarter(new Date()),
                            "yyyy-MM-dd"
                        );
                        initialEnd = format(
                            endOfQuarter(new Date()),
                            "yyyy-MM-dd"
                        );
                    } else if (config?.options?.plan_period === "year") {
                        initialStart = format(
                            startOfYear(new Date()),
                            "yyyy-MM-dd"
                        );
                        initialEnd = format(
                            endOfYear(new Date()),
                            "yyyy-MM-dd"
                        );
                    } else {
                        initialStart = format(
                            startOfMonth(new Date()),
                            "yyyy-MM-dd"
                        );
                        initialEnd = format(
                            endOfMonth(new Date()),
                            "yyyy-MM-dd"
                        );
                    }
                }
                //Handle units
                let unitsFromStorage = getItem("planner_units");
                let units = unitsFromStorage["planner_units"];
                if (!units) {
                    units = config?.options?.default_units || "days";
                    setItem("planner_units", units);
                }

                if (config?.options?.use_kpi_data) {
                    getKpiData(initialStart, initialEnd);
                }
                generateDateRange(initialStart, initialEnd, units);
                getPlan(initialStart, initialEnd);
            }
        });

        if (!allUserGroups.loaded) {
            getPlannerChildGroups();
        }
    }, []);

    useEffect(() => {
        filterTasks(selectedFilters);
    }, [allTasks]);

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            //Update the URL with the new start and end dates by pushing the new start and end values
            let urlParams = new URLSearchParams(location.search);

            urlParams.set(
                "start",
                startDate instanceof Date
                    ? format(startDate, "yyyy-MM-dd")
                    : format(new Date(startDate), "yyyy-MM-dd")
            );

            urlParams.set(
                "end",
                endDate instanceof Date
                    ? format(endDate, "yyyy-MM-dd")
                    : format(new Date(endDate), "yyyy-MM-dd")
            );
            //Update the URL with the new start and end dates, but don't push a new history state
            history.replace({
                search: urlParams.toString(),
            });
            setOrphanedActivities([]);
        } catch (e) {
            console.log(e);
        }
    }, [startDate, endDate, location.search, setOrphanedActivities]);

    const changeDates = (startDate, endDate) => {
        const units = getItem("planner_units", "days")["planner_units"];
        generateDateRange(startDate, endDate, units);
        getPlan(startDate, endDate);
        getKpiData(startDate, endDate);
    };

    const getKpiData = (startDate, endDate) => {
        axios
            .get(`api/v1/planner/kpi`, {
                params: {
                    startDate,
                    endDate,
                },
            })
            .then((result) => {
                setKpiData(result.data.data);
            });
    };

    const refreshPlan = () => {
        if (startDate && endDate) {
            try {
                const units = getItem("planner_units", "days")["planner_units"];

                generateDateRange(startDate, endDate, units);

                getPlan(
                    format(startOfMonth(new Date(startDate)), "yyyy-MM-dd"),
                    format(endOfMonth(new Date(endDate)), "yyyy-MM-dd")
                );
                getKpiData(startDate, endDate);
            } catch (e) {
                console.log(e);
            }
        }
    };

    /**
     * Filter the tasks based upon the selectedFilters
     */
    const filterTasks = (newSelectedFilters) => {
        setSelectedFilters(newSelectedFilters);
        setTasks(
            filterObjects(allTasks.data, filters.data, newSelectedFilters)
        );
    };

    const viewPlanner = () => {
        // setOrphanedActivities([]);
        history.push(`${match.path}`);
    };

    const viewTask = (task) => {
        history.push(`${match.path}/task/${task.uuid}`);
    };

    const addTaskCategory = () => {
        history.push(`${match.path}/task-category/add`);
    };

    const editTaskCategory = (category) => {
        history.push(`${match.path}/task-category/${category.uuid}`);
    };

    const addTask = () => {
        history.push(`${match.path}/add`);
    };

    const addActivity = (task, optionalCallback = null) => {
        showActivityModal(task, optionalCallback);
    };

    const viewActivity = (task, activity) => {
        history.push(
            `${match.path}/task/${task.uuid}/activity/${activity.uuid}`
        );
    };

    const deleteTask = (values) => {
        deleteTaskAction(values).then(viewPlanner);
    };

    const deleteActivity = (activity_uuid, task_uuid) => {
        return deleteActivityAction(activity_uuid, task_uuid).then(viewPlanner);
    };

    if (!categories.loaded || !config.loaded || !allTasks.loaded) {
        return (
            <div
                style={{
                    minHeight: "60vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Loading color="rgba(0,0,0,0.3)" align="center" />
            </div>
        );
    }

    const addTaskPath = `${match.path}/add`;

    return (
        <QueryClientProvider client={queryClient}>
            <Switch>
                <Route
                    exact
                    path={`${match.path}/task/:task_uuid/activity/add`}
                    render={() => (
                        <AddActivity
                            task={task}
                            onGoBack={viewTask}
                            plannerPath={`${match.path}/`}
                            match={match}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/task/:task_uuid/activity/:activity_uuid/added`}
                    render={() => (
                        <ActivityAdded
                            task={task}
                            viewTask={viewTask}
                            plannerPath={`${match.path}/`}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/task/:task_uuid/activity/:activity_uuid`}
                    render={() => (
                        <ViewActivity
                            user={user}
                            task={task}
                            viewTask={viewTask}
                            viewPlanner={viewPlanner}
                            plannerPath={`${match.path}/`}
                            deleteActivity={deleteActivity}
                            addActivity={addActivity}
                            theme={theme}
                            config={config}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/task-category/add`}
                    render={() => (
                        <AddTaskCategory
                            category={category}
                            viewPlanner={viewPlanner}
                            plannerPath={`${match.path}/`}
                            location={location}
                            user={user}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/task-category/:category_uuid`}
                    render={({ match }) => (
                        <EditTaskCategory
                            categoryUuid={match.params.category_uuid}
                            categories={categories}
                            viewPlanner={viewPlanner}
                            match={match}
                            location={location}
                            user={user}
                        />
                    )}
                />
                <Route
                    exact
                    path={addTaskPath}
                    render={() => (
                        <AddTask
                            category={category}
                            viewPlanner={viewPlanner}
                            groups={groups}
                            location={location}
                            user={user}
                            group={group}
                            allUserGroups={allUserGroups}
                            viewTask={viewTask}
                            match={match}
                            addActivity={addActivity}
                            orphanedActivities={orphanedActivities}
                            setOrphanedActivities={setOrphanedActivities}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/task/:task_uuid`}
                    render={() => {
                        return (
                            <ViewTask
                                task={task}
                                deleteTask={deleteTask}
                                category={category}
                                viewPlanner={viewPlanner}
                                viewTask={viewTask}
                                user={user}
                                group={group}
                                allUserGroups={allUserGroups}
                                groups={groups}
                                filters={filters}
                                plannerPath={`${match.path}/`}
                                addActivity={addActivity}
                                deleteActivity={deleteActivity}
                                viewActivity={viewActivity}
                                theme={theme}
                                addTaskPath={addTaskPath}
                            />
                        );
                    }}
                />
                <Route
                    path={`${match.path}/my-plans/:plan_uuid`}
                    render={() => (
                        <PlanDetails
                            theme={theme}
                            user={user}
                            group={group}
                            match={match}
                        />
                    )}
                />
                <Route
                    path={`${match.path}/my-plans`}
                    render={() => (
                        <MyPlans theme={theme} user={user} match={match} />
                    )}
                />
                <Route
                    path={`${match.path}/`}
                    render={() => (
                        <Planner
                            changeDates={changeDates}
                            refreshPlan={refreshPlan}
                            tasks={tasks}
                            tasksLoading={allTasks.loading}
                            viewTask={viewTask}
                            viewActivity={viewActivity}
                            addTaskCategory={addTaskCategory}
                            editTaskCategory={editTaskCategory}
                            addTask={addTask}
                            addActivity={addActivity}
                            toast={addToast}
                            theme={theme}
                            user={user}
                            summaryType={summaryType}
                            filters={filters}
                            filterTasks={filterTasks}
                            match={match}
                            config={config}
                            kpiData={kpiData}
                            selectedFilters={selectedFilters}
                        />
                    )}
                />
            </Switch>

            {activityModalState.showing === true && (
                <Modal
                    visible={true}
                    onClose={() => closeActivityModal()}
                    minWidth="70%"
                >
                    <AddActivity
                        task={activityModalState.task}
                        match={match}
                        onGoBack={closeActivityModal}
                        successCallback={(activity) => {
                            setOrphanedActivities([
                                ...orphanedActivities,
                                activity,
                            ]);
                            closeActivityModal();
                            if (activityModalState.callback) {
                                activityModalState.callback(activity);
                            }
                        }}
                    />
                </Modal>
            )}
        </QueryClientProvider>
    );
};

MarketingPlanner.displayName = "MarketingPlanner";

MarketingPlanner.propTypes = {
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            activities: PropTypes.shape({
                national: PropTypes.string,
                plan: PropTypes.string,
                group: PropTypes.string,
            }),
            header: PropTypes.shape({
                label: PropTypes.string,
                button: PropTypes.string,
            }),
            micro: PropTypes.shape({
                label: PropTypes.string,
                current: PropTypes.string,
                currentBackground: PropTypes.string,
                line: PropTypes.string,
            }),
            campaignGroup: PropTypes.shape({
                label: PropTypes.string,
                button: PropTypes.string,
                background: PropTypes.string,
            }),
            taskRow: PropTypes.shape({
                label: PropTypes.string,
                labelHover: PropTypes.string,
                button: PropTypes.string,
                background: PropTypes.string,
                sidebar: PropTypes.string,
            }),
        }),
        heights: PropTypes.shape({
            row: PropTypes.number,
            task: PropTypes.number,
            activity: PropTypes.number,
        }),
    }),
    selectedFilters: PropTypes.object,
    national: PropTypes.bool,
    summaryType: PropTypes.oneOf(["tooltip", "sidePanel"]),
};

MarketingPlanner.defaultProps = {
    selectedFilters: {},
    theme: {
        colors: {
            activities: {
                national: "#d74540",
                plan: "#e6c2c1",
                group: "#b1ab87",
            },
            header: {
                label: "#EEEEED",
                button: "#EEEEED",
            },
            micro: {
                label: "#EEEEED",
                current: "#ffffff",
                currentBackground: "#171e26",
                line: "rgba(255, 255, 255, 0.04)",
            },
            taskCategory: {
                label: "#171e26",
                button: "#d74540",
                background: "#EEEEED",
                color: "#000",
            },
            taskRow: {
                label: "#EEEEED",
                labelHover: "#EEEEED",
                buttonHover: "#80848a",
                background: "#171e26",
                sidebar: "#171e26",
            },
        },
        heights: {
            row: 40,
            task: 20,
            activity: 14,
        },
    },
    national: false,
    summaryType: "sidePanel",
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPlan,
            generateDateRange,
            getPlannerFilters,
            getPlannerCategories,
            getPlannerConfigOptions,
            getPlannerChildGroups,
            selectCategory,
            selectTask,
            selectActivity,
            showActivityOrder,
            addOrderToPlan,
            deleteTask,
            deleteActivity,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const {
        filters,
        startDate,
        endDate,
        dates,
        categories,
        tasks,
        selections,
        loaded,
        groups,
        config,
    } = state.planner;
    return {
        filters,
        startDate,
        endDate,
        dates,
        categories,
        tasks,
        selections,
        loaded,
        groups,
        config,
    };
}

export default withRouter(
    compose(connect(mapStateToProps, mapDispatchToProps))(MarketingPlanner)
);
