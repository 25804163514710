import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    LoadingIconSmall,
    Select,
    useAddToast,
} from "@cortexglobal/rla-components";

const AssetTypeSelect = ({ extension, assetTypes, ...props }) => {
    const addToast = useAddToast();
    const [loaded, setLoaded] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!assetTypes) {
            getOptions(extension);
        } else {
            const assetTypesForExtension = extension
                ? assetTypes.filter(
                      (assetType) =>
                          assetType.allowed_extensions.includes("*") ||
                          assetType.allowed_extensions.indexOf(extension) > -1
                  )
                : assetTypes;
            setOptions(assetTypesForExtension);
            setLoaded(true);
            if (assetTypesForExtension.length === 1) {
                props.onChange({
                    name: props.name,
                    value: assetTypesForExtension[0].value,
                });
            }
        }
    }, [extension]);

    const getOptions = (extension) => {
        setLoaded(false);

        axios
            .get("/api/v1/assets/asset-types", { params: { extension } })
            .then(({ data }) => {
                setOptions(data.data);

                // if there is only one, default to this
                if (data.data.length === 1) {
                    const picked = data.data[0];
                    props.onChange({ name: props.name, value: picked.value });
                }
            })
            .catch((e) => {
                addToast({ type: "alert", content: e.message, showFor: 5000 });
            })
            .finally(() => setLoaded(true));
    };

    return loaded ? (
        <Select {...props} options={options} />
    ) : (
        <LoadingIconSmall />
    );
};

export default AssetTypeSelect;
