import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Modal,
    useAddToast,
    Loading,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import AssetContext from "../AssetContext";
import ShareModal from "../components/ShareModal";
import DownloadModal from "../components/DownloadModal";
// import CreateLocalVersion from "../components/CreateLocalVersion";
import CloneAsset from "../components/CloneAsset";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import PrintOrderModal from "./Print/PrintOrderModalBulkPricing";
import BulkEditModal from "./BulkEditModal";
import OrderModal from "./OrderModal";
import PersonaliseModal from "./PersonaliseModal";
import AssetLinkActions from "./AssetLinkActions";
import axios from "axios";
import MoveFavouriteModal from "./MoveFavouriteModal";

const ActionsPanel = ({
    createUserAsset,
    messages,
    configLoaded,
    hasMutipleFavouritesEnabled,
    isActive,
    loaded,
    campaignsPath,
}) => {
    const { can } = usePermissions();
    const {
        user,
        group,
        groups,
        asset,
        canAdmin,
        canEdit,
        updatePresentation,
        addFavorite,
        removeFavorite,
        assetsPath,
        setRefreshSearch,
    } = useContext(AssetContext);

    const [showShareModal, setShowShareModal] = useState(false);
    const [showMoveToFavouriteModal, setShowMoveToFavouriteModal] =
        useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [showOrderPrintModal, setShowOrderPrintModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [showPersonaliseModal, setShowPersonaliseModal] = useState(false);
    const [showBulkEditModal, setShowBulkEditModal] = useState(false);

    const addToast = useAddToast();

    const printOrderEnabled =
        !!asset.printable && !asset.editable && user.canPrint;
    const isOrderable =
        Array.isArray(asset?.order_flows) && asset.order_flows.length > 0;

    if (!loaded) {
        return <Loading />;
    }
    if (!isActive) {
        return null;
    }
    const showGroupSwitcher =
        groups.length > 1 &&
        ((isOrderable && can("dam-asset-order")) ||
            (asset.editable && can("dam-asset-use")));

    const assetType =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset?.asset_type?.slug;
    return (
        <React.Fragment>
            <h3>{trans("Available Actions")}</h3>
            <Panel>
                <React.Fragment>
                    {assetType === "link" && <AssetLinkActions asset={asset} />}
                    {asset.shareable && can("dam-asset-share") && (
                        <Row>
                            <IconTextButton
                                type="primary"
                                expanded
                                onClick={() => setShowShareModal(true)}
                            >
                                {trans("Create Sharing Link")}
                            </IconTextButton>
                        </Row>
                    )}
                    {isOrderable && can("dam-asset-order") && (
                        <Row>
                            <IconTextButton
                                type="primary"
                                expanded
                                onClick={() => setShowOrderModal(true)}
                            >
                                {trans("Order this asset")}
                            </IconTextButton>
                        </Row>
                    )}
                    {asset.editable && can("dam-asset-use") && (
                        <Row>
                            <IconTextButton
                                type="primary"
                                expanded
                                onClick={() =>
                                    showGroupSwitcher
                                        ? setShowPersonaliseModal(true)
                                        : createUserAsset()
                                }
                            >
                                {trans("Personalise asset")}
                            </IconTextButton>
                        </Row>
                    )}
                    {/* {asset.editable && can("dam-asset-edit") && (
                        <Row>
                            <IconTextButton
                                type="primary"
                                expanded
                                as={Link}
                                to={`${assetsPath}/${asset.uuid}/edit`}
                            >
                                {trans("Edit Template")}
                            </IconTextButton>
                        </Row>
                    )} */}
                    {asset.multi_group_editable && can("dam-asset-use") && (
                        <Row>
                            <IconTextButton
                                onClick={() => setShowBulkEditModal(true)}
                            >
                                {trans("Bulk personalise asset")}
                            </IconTextButton>
                        </Row>
                    )}
                    {can("dam-asset-clone") && (
                        <Row>
                            <CloneAsset assetsPath={assetsPath} asset={asset} />
                        </Row>
                    )}
                    {/* {can("dam-asset-locale-create") && (
                            <Row>
                                <IconTextButton
                                    type="primary"
                                    expanded
                                    onClick={event => {
                                        setShowCreateLocalVersionModal(true);
                                    }}
                                >
                                    {trans("Clone Asset")}
                                </IconTextButton>
                            </Row>
                        )} */}
                    {assetType === "presentation" && (
                        <React.Fragment>
                            <Row>
                                <IconTextButton
                                    type="primary"
                                    as={"a"}
                                    target="_blank"
                                    href={`/presentations/${asset.uuid}`}
                                    expanded
                                >
                                    {trans("Launch Preview")}
                                </IconTextButton>
                            </Row>
                        </React.Fragment>
                    )}
                    {/* 
{(asset.downloadable ||
                        asset.pdfable ||
                        asset.imageZippable) && ( */}
                    {((!asset.editable && asset.downloadable) ||
                        asset.pdfable ||
                        asset.imageZippable) && (
                        <Row>
                            <IconTextButton
                                type="secondary"
                                expanded
                                onClick={() => setShowDownloadModal(true)}
                            >
                                {trans("Download")}
                            </IconTextButton>
                        </Row>
                    )}

                    {false && asset.available_to && (
                        <Row>
                            <IconTextButton
                                type="secondary"
                                as={Link}
                                expanded
                                to={`${assetsPath}/view/${asset.uuid}`}
                            >
                                {trans("Notify Me Of Expiry")}
                            </IconTextButton>
                        </Row>
                    )}
                    {false && (
                        <Row>
                            <IconTextButton
                                type="secondary"
                                as={Link}
                                expanded
                                to={"/request/amendment/" + asset.smartRef}
                            >
                                {trans("Request Amendment")}
                            </IconTextButton>
                        </Row>
                    )}

                    {!asset.isFavorite ? (
                        <Row>
                            <IconTextButton
                                type="secondary"
                                expanded
                                onClick={(event) => {
                                    {
                                        hasMutipleFavouritesEnabled
                                            ? setShowMoveToFavouriteModal(true)
                                            : addFavorite([asset.uuid]);
                                    }
                                }}
                            >
                                {trans("Add As Favourite")}
                            </IconTextButton>
                        </Row>
                    ) : (
                        <Row>
                            <IconTextButton
                                type="secondary"
                                expanded
                                onClick={(event) => {
                                    removeFavorite([asset.uuid]);
                                }}
                            >
                                {trans("Remove Favourite")}
                            </IconTextButton>
                        </Row>
                    )}

                    {printOrderEnabled && (
                        <Row>
                            <IconTextButton
                                onClick={() => setShowOrderPrintModal(true)}
                            >
                                {trans("Order Print")}
                            </IconTextButton>
                        </Row>
                    )}
                    {can("dam-campaign-view") && asset.campaign && (
                        <Row>
                            <IconTextButton
                                type="secondary"
                                as={Link}
                                expanded
                                to={`${campaignsPath}/view/${asset.campaign.uuid}`}
                            >
                                {trans("View {campaign} Campaign", {
                                    campaign: asset.campaign.name,
                                })}
                            </IconTextButton>
                        </Row>
                    )}
                </React.Fragment>
            </Panel>
            {showShareModal && (
                <ShareModal
                    onClose={() => setShowShareModal(false)}
                    smartRef={asset.smartRef}
                    visible={showShareModal}
                />
            )}
            {showDownloadModal && (
                <DownloadModal
                    messages={messages}
                    configLoaded={configLoaded}
                    onClose={() => setShowDownloadModal(false)}
                    asset={asset}
                    visible={showDownloadModal}
                />
            )}
            {showOrderModal && (
                <OrderModal
                    onClose={() => setShowOrderModal(false)}
                    asset={asset}
                    visible={showOrderModal}
                    showGroupSwitcher={showGroupSwitcher}
                />
            )}
            {showPersonaliseModal && (
                <PersonaliseModal
                    onClose={() => setShowPersonaliseModal(false)}
                    asset={asset}
                    createUserAsset={createUserAsset}
                    visible={showPersonaliseModal}
                />
            )}

            {showOrderPrintModal && (
                <PrintOrderModal
                    onClose={() => setShowOrderPrintModal(false)}
                    asset={asset}
                    visible={showOrderPrintModal}
                />
            )}
            {showBulkEditModal && (
                <BulkEditModal
                    group={group}
                    onClose={() => setShowBulkEditModal(false)}
                    asset={asset}
                    visible={showBulkEditModal}
                />
            )}
            {showMoveToFavouriteModal && (
                <MoveFavouriteModal
                    onClose={() => setShowMoveToFavouriteModal(false)}
                    asset={asset}
                    isCurrentlySelectedAsset={true}
                    visible={showMoveToFavouriteModal}
                    setRefreshSearch={setRefreshSearch}
                />
            )}
        </React.Fragment>
    );
};

export default ActionsPanel;

/*
//OLD DOWNLOAD BUTTON
<Row>
    <IconTextButton
        type="primary"
        onClick={() =>
            updatePresentation(asset.uuid)
                .then(data => {
                    addToast({
                        type: "success",
                        content:
                            "Presentation saved",
                        showFor: 5000
                    });
                })
                .catch(e => {
                    addToast({
                        type: "alert",
                        content:
                            e.response &&
                            e.response.data
                                ? e.response
                                        .data
                                        .message
                                : trans(
                                        "An error has occurred"
                                    ),
                        showFor: 5000
                    });
                })
        }
    >
        {trans("Save for Offline Usage")}{" "}
    </IconTextButton>
</Row>

*/
