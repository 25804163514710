import React from "react";
import ReactDOM from "react-dom";

import ReactTooltip from "react-tooltip-lite";

import styled, { withTheme, css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";

const Icon = () => {
    return <FontAwesomeIcon icon={faInfoCircle} />;
};

const StyledTooltip = styled(ReactTooltip).attrs({
    role: "tooltip",
})`
    opacity: ${({ theme }) => theme.tooltip.opacity};
    width: 100%;
    .react-tooltip-lite {
        box-shadow: 5px 5px 6px -3px rgba(0, 0, 0, 0.2),
            0px 0px 6px -3px rgba(0, 0, 0, 0.5);
        border-radius: ${({ theme }) => theme.tooltip.radius};
    }
    .react-tooltip-lite-arrow {
    }
`;

const Tooltip = ({
    text,
    children,
    theme,
    showIcon = true,
    zIndex = 1000,
    tagName = "span",
    hoverDelay = 100,
    ...rest
}) => {
    //If no text is provided, just return the children
    if (!text) return children;

    return (
        <StyledTooltip
            tagName={tagName}
            content={text}
            background={theme.tooltip.background}
            color={theme.tooltip.color}
            hoverDelay={hoverDelay}
            showIcon={showIcon}
            zIndex={zIndex}
            {...rest}
        >
            {children} {showIcon && <Icon />}
        </StyledTooltip>
    );
};

Tooltip.displayName = "Tooltip";

export default withTheme(Tooltip);
