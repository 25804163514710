import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikCheckboxTree,
    Panel,
    Loading,
    Tabordion,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    FormikColorField,
    Tooltip,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import {
    PreviewImage,
    TabordionGroup,
    LocaleSwitcher,
} from "../components/index";

const schema = Yup.object().shape({
    locale: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    slug: Yup.string().required("Required"),
    path: Yup.string().required("Required"),
    position: Yup.number().required("Required"),
    show_in_nav: Yup.boolean(),
    level: Yup.number().required("Required"),
    parent_id: Yup.number().nullable(),
    // parents: Yup.array(),
    permissions: Yup.array().nullable(),
    permissions_application: Yup.string().nullable(),
    guest_permission: Yup.boolean(),
});

export type Navigation = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    slug?: string;
    locale?: string;
    name?: string;
    parent_id?: number;
    underline_color?: string;
    position?: number;
    parent_name?: string;
    path?: string;
    permissions?: [];
    permissions_application?: string;
    guest_permission?: boolean;
};

export const NavigationForm = ({ item, submitForm }) => {
    const [locale, setLocale] = useState(item.locale);
    const [parents, setParents] = useState([]);
    const [parentsLoaded, setParentsLoaded] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [tab, setTab] = useState(0);
    const domain = window.location.origin || "https://your-cortex.com";

    useEffect(() => {
        loadParents();

        axios
            .get(
                `/api/v1/admin/permissions/permissions-tree?alias_in_value=true`
            )
            .then(({ data }) => {
                setPermissions(data.data);
                setPermissionsLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!parents.length) {
            loadParents();
        } else {
            setParentsLanguage(parents);
        }
    }, [locale]);

    const setParentsLanguage = (data) => {
        let parentsCopy = cloneDeep(data);
        const currentLocale = locale || data[0].locale;

        parentsCopy.forEach((item) => {
            const il8n_item = item.i18n.find(
                (il8n) => il8n.locale === currentLocale
            );

            item.text = il8n_item ? il8n_item.name : "";
        });

        setParents(parentsCopy);
    };

    const loadParents = () => {
        const param = item.uuid ? `?uuid=${item.uuid}` : ``;
        return axios
            .get(`/api/v1/admin/navigation/parents${param}`)
            .then(({ data }) => {
                setParentsLoaded(true);
                setParentsLanguage(data.data);
            });
    };

    const handleSubmit = (
        values: Navigation,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                locale: item.locale,
                name: item.name,
                slug: item.slug,
                parent_id: item.parent_id,
                path: item.path,
                position: item.position || 0,
                underline_color: item.underline_color,
                show_in_nav: item.show_in_nav,
                level: item.level || 0,
                permissions: item.permissions,
                permissions_application: item.permissions_application,
                guest_permission: item.permissions?.includes("guest") || false,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <FormRow
                                    name="locale"
                                    label={trans("Locale")}
                                    helpText={trans("Required")}
                                    error={errors.locale}
                                >
                                    <LocaleSwitcher
                                        item={item}
                                        setFieldValue={setFieldValue}
                                        //@ts-ignore
                                        values={values}
                                        // onChange={(locale) => {
                                        //     setLocale(locale);
                                        // }}
                                    />
                                </FormRow>
                                <FormRow
                                    name="name"
                                    label={trans("Name")}
                                    helpText={trans("Required")}
                                    error={errors.name}
                                >
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                    />
                                </FormRow>
                                <FormRow
                                    name="slug"
                                    label={trans("Slug")}
                                    helpText={trans("Required")}
                                    error={errors.slug}
                                >
                                    <FormikInput
                                        name="slug"
                                        value={values.slug}
                                    />
                                </FormRow>
                                <FormRow
                                    name="path"
                                    label={
                                        <Tooltip
                                            text={trans(
                                                "This is the path from the root/home page that will be used for this navigation item.  For example, if you enter '/resources' here, the URL will be 'your-domain.com/about'"
                                            )}
                                        >
                                            {trans("Path")}
                                        </Tooltip>
                                    }
                                    helpText={trans("Required")}
                                    error={errors.path}
                                >
                                    <FormikInput
                                        name="path"
                                        value={values.path}
                                    />
                                </FormRow>

                                <FormRow
                                    name="underline_color"
                                    label={trans("Underline Color")}
                                    helpText={trans(
                                        "Optional, only applies to sites with separated underline navigation"
                                    )}
                                    error={errors.underline_color}
                                >
                                    <FormikColorField
                                        name="underline_color"
                                        value={values.underline_color}
                                        showError={false}
                                    />{" "}
                                </FormRow>

                                <FormRow
                                    name="show_in_nav"
                                    label={trans("Show in Navigation")}
                                    error={errors.show_in_nav}
                                >
                                    <FormikCheckbox
                                        name="show_in_nav"
                                        value={values.show_in_nav}
                                        showError={false}
                                    />{" "}
                                </FormRow>

                                <FormRow
                                    name="level"
                                    label={trans("Navigation Level")}
                                    helpText={trans(
                                        "0 will be in main navigation, 1 is for sub navigation"
                                    )}
                                    error={errors.level}
                                >
                                    <FormikInput
                                        type="number"
                                        name="level"
                                        value={values.level}
                                        min={0}
                                        max={1}
                                        showError={false}
                                    />{" "}
                                </FormRow>

                                <FormRow
                                    name="position"
                                    label={trans("Position")}
                                    helpText={trans("Required")}
                                    error={errors.position}
                                >
                                    <FormikInput
                                        name="position"
                                        value={values.position}
                                    />
                                </FormRow>

                                <FormRow
                                    name="parent_id"
                                    label={trans("Parent")}
                                    helpText={trans("Optional")}
                                    error={errors.parent_id}
                                >
                                    <ContentBlockLoader loaded={parentsLoaded}>
                                        <FormikSelect
                                            name="parent_id"
                                            value={values.parent_id}
                                            options={parents}
                                        />
                                    </ContentBlockLoader>
                                </FormRow>
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Permissions")}</span>}
                                onTabClick={() => setTab(1)}
                            >
                                <FormRow
                                    name="permissions_application"
                                    label={trans("Exclude or Include")}
                                    helpText={trans("Optional")}
                                    error={errors.permissions_application}
                                >
                                    <FormikSelect
                                        name="permissions_application"
                                        value={values.permissions_application}
                                        options={[
                                            {
                                                value: "include",
                                                text: "Include",
                                            },
                                            {
                                                value: "exclude",
                                                text: "Exclude",
                                            },
                                        ]}
                                    />
                                </FormRow>

                                <FormRow
                                    name="guest_permission"
                                    label={trans("Guests")}
                                    error={errors.guest_permission}
                                >
                                    <FormikCheckbox
                                        name="guest_permission"
                                        value={values.guest_permission}
                                        showError={false}
                                    />{" "}
                                </FormRow>

                                <FormRow
                                    name="permissions"
                                    label={trans("Application Permissions")}
                                    helpText={trans(
                                        "Please select the permissions for the role"
                                    )}
                                    error={errors.permissions}
                                >
                                    <ContentBlockLoader
                                        loaded={permissionsLoaded}
                                    >
                                        <FormikCheckboxTree
                                            name="permissions"
                                            value={values.permissions}
                                            options={permissions}
                                            selectChildren={true}
                                            allowListView={true}
                                            showWrapper={true}
                                        />
                                    </ContentBlockLoader>
                                </FormRow>

                                <FormRow></FormRow>
                            </TabordionGroup>
                        </Tabordion>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default NavigationForm;
