import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    LoadingIconSmall,
    FileIcon,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    SearchResultSummary,
    SearchResultBadge,
    ShowMore,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const CampaignResultItem = ({
    campaign,
    groupCampaign,
    canEdit,
    onClick = null,
    assetsPath,
    margin,
    showAssetCount = false,
}) => {
    const theme = useTheme();
    const getImageSrc = () => {
        let imageSrc = `${campaign.thumbnail}`;
        return imageSrc;
    };

    return (
        <SearchResultWrapper
            textAlign="center"
            style={{ padding: 0, margin: margin || "0rem", height: "100%" }}
        >
            <Link onClick={(event) => onClick(event, campaign, groupCampaign)}>
                <SearchResultImageWrapper background={campaign.color}>
                    {campaign.thumbnail === null ? (
                        <FileIcon colorBehind={theme.colors.gray200}></FileIcon>
                    ) : (
                        <SearchResultImage
                            src={getImageSrc()}
                            alt={campaign.name}
                            objectFit={"cover"}
                        />
                    )}
                </SearchResultImageWrapper>
            </Link>

            <SearchResultButton
                onClick={(event) => onClick(event, campaign, groupCampaign)}
                showCircle={theme.search.icons.showCircle}
                iconBefore={false}
                doHover={false}
                icon={theme.search.icons.searchIcon}
                iconColor={theme.search.icons.color}
                iconSize={theme.search.icons.size}
            >
                <SearchResultTitle title={campaign.name} width="95%">
                    <ShowMore more=" " lines={3}>
                        {campaign.name}
                    </ShowMore>
                </SearchResultTitle>
            </SearchResultButton>
        </SearchResultWrapper>
    );
};
export default CampaignResultItem;
