import { useState, useEffect, MutableRefObject } from "react";
import { NavigationDispatch } from "../interfaces/microsite";

import {
    SUB_TRANSITION_IN_PROGRESS,
    MOVE_BACKWARD,
    MOVE_FORWARD
} from "./useSlideshowNavigation";
import { Footnote } from "../interfaces/layout";

export type Caption = {
    enterAt: number;
    pauseVideo: boolean;
    bulletPoint: boolean;
    uuid: string;
    value: string;
    footnotes: Footnote[];
};
export type Setup = {
    videoSrc: string;
    imageSrc: string;
    captions: Caption[];
    arrowsControlVideo: boolean;
    autoplay: boolean;
};

export const useSequencedVideo = (
    videoElement: MutableRefObject<HTMLVideoElement | null>,
    setup: Setup,
    navigationDispatch: NavigationDispatch,
    isCurrent: boolean,
    disableSequencing: boolean
) => {
    const { videoSrc, captions = [], arrowsControlVideo = true } = setup;

    //Count the transitions
    const [currentTransition, setCurrentTransition] = useState(-1);
    const [lastUpdatedElapsed, setLastUpdatedElapsed] = useState(0);

    const [elapsed, setElapsed] = useState(0);
    // console.log(currentTransition, lastUpdatedElapsed, elapsed);
    const handleTimeChange = () => {
        const currentTime = videoElement?.current?.currentTime || 0;
        setElapsed(currentTime);
        //Find out if there's a caption with an enterAt value set at the current second
        const captionEnteringIndex = captions.findIndex(caption => {
            return Math.floor(caption.enterAt) === Math.floor(currentTime);
        });

        if (
            captionEnteringIndex !== -1 &&
            Math.floor(elapsed) !== Math.floor(lastUpdatedElapsed)
        ) {
            setCurrentTransition(captionEnteringIndex);
            setLastUpdatedElapsed(elapsed);
            if (captions[captionEnteringIndex].pauseVideo) {
                if (videoElement?.current) {
                    //TODO - THis commented code ensures that microsecond is respected
                    //but causes a potential jump.  We could investigate incrementing or
                    //decrementing the current time to the get to the required value?
                    // videoElement.current.currentTime =
                    //     captions[captionEnteringIndex].enterAt;
                    videoElement.current.pause();
                }
            }
        }
    };

    useEffect(() => {
        if (videoElement.current) {
            videoElement?.current?.addEventListener(
                "timeupdate",
                handleTimeChange
            );
        }
        return () => {
            videoElement?.current?.removeEventListener(
                "timeupdate",
                handleTimeChange
            );
        };
    }, [videoElement, elapsed, lastUpdatedElapsed]);

    useEffect(() => {
        if (isCurrent) {
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: true
            });
        }
        return () => {
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: false
            });
        };
    }, [isCurrent]);

    const handleTransition = (
        newTransitionIndex: number,
        playAfterUpdate = true
    ) => {
        setCurrentTransition(newTransitionIndex);
        if (arrowsControlVideo && videoElement.current) {
            if (captions[newTransitionIndex]) {
                videoElement.current.currentTime =
                    captions[newTransitionIndex].enterAt;
            }
        }
        if (playAfterUpdate) {
            videoElement?.current?.play();
        }
    };

    const navigateForward = () => {
        // if video is paused and not finished, play the video
        if (videoElement?.current?.paused && !videoElement?.current?.ended) {
            // console.log("Should play");
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: true
            });
            setLastUpdatedElapsed(elapsed);
            videoElement?.current?.play();

            // if every caption is entered at 0 seconds, move to next slide
        } else if (
            captions &&
            captions.every(caption => caption && caption.enterAt === 0)
        ) {
            // console.log("All captions show at 0");
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: false
            });
            navigationDispatch({ type: MOVE_FORWARD });
        } else if (currentTransition + 1 < captions.length) {
            // console.log("There's more captions");
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: true
            });

            // if current caption is entered at 0 seconds, move to next non 0 second caption
            const newTransition =
                captions[currentTransition + 1].enterAt === 0
                    ? captions.findIndex(caption => {
                          return caption.enterAt > 0;
                      })
                    : currentTransition + 1;

            handleTransition(newTransition);
        } else {
            // console.log("No captions left");
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: false
            });
            navigationDispatch({ type: MOVE_FORWARD });
        }
    };

    const navigateBackward = () => {
        if (currentTransition > 0) {
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: true
            });
            handleTransition(currentTransition - 1, false);
        } else {
            navigationDispatch({
                type: SUB_TRANSITION_IN_PROGRESS,
                subTransitionInProgress: false
            });
            navigationDispatch({ type: MOVE_BACKWARD });
        }
    };

    const handleSlideshowKeyboardNavigation = (event: KeyboardEvent) => {
        // console.log("Key pressed", isCurrent, event.key);
        if (!isCurrent || disableSequencing) {
            return;
        }
        switch (event.key) {
            case "ArrowRight":
                navigateForward();
                break;
            case "ArrowLeft":
                navigateBackward();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener(
            "keydown",
            handleSlideshowKeyboardNavigation,
            true
        );

        return () => {
            document.removeEventListener(
                "keydown",
                handleSlideshowKeyboardNavigation,
                true
            );
        };
    }, [handleSlideshowKeyboardNavigation]);

    // console.log(currentTransition, lastUpdatedElapsed, elapsed);
    return { currentTransition };
};
