import React, { useState, useEffect } from "react";
import axios from "axios";
import { get } from "lodash";

import {
    Modal,
    SubmitButton,
    Row,
    Column,
    FormLabel,
    TableList,
    StyledInput,
    useAddToast,
    Loading,
    Error,
    StyledSelectSimple,
} from "@cortexglobal/rla-components";

import { trans } from "@cortexglobal/rla-intl";

const PrintOrderModal = ({ userAsset, asset, visible, onClose }) => {
    const addToast = useAddToast();

    const [params, setParams] = useState({
        quantity: 0,
    });

    const [formErrors, setFormErrors] = useState({});

    const [calculationValues, setCalculationValues] = useState({
        cost: 0,
        individual: 0,
    });

    const [loadingState, setLoadingState] = useState("loaded");

    const [pricingMatrix, setPricingMatrix] = useState([]);
    const [minQty, setMinQty] = useState(0);
    const [maxQty, setMaxQty] = useState(null);

    useEffect(() => {
        getPrices();
    }, []);

    const getMinQuantity = (pricingMatrix) => {
        return pricingMatrix.reduce((minQty, c) =>
            c.min_quantity < minQty.min_quantity ? c : minQty
        ).min_quantity;
    };

    const getMaxQuantity = (pricingMatrix) => {
        let maxQty = null;

        for (let c of pricingMatrix) {
            if (c.max_quantity === null) {
                // if one of the max's is null assume there is no max
                return null;
            }

            maxQty = c.max_quantity > maxQty ? c.max_quantity : maxQty;
        }

        return maxQty;
    };

    const getPriceBasedOnQty = (qty) => {
        let unit_price = 0;

        pricingMatrix.forEach((c) => {
            if (
                qty >= c.min_quantity &&
                (qty <= c.max_quantity || c.max_quantity === null)
            ) {
                unit_price = c.unit_price;
            }
        });

        return unit_price;
    };

    const setQuantity = (qty) => {
        let newValues = {
            cost: 0,
        };

        if (!isNaN(qty) && qty >= minQty) {
            setParams({
                ...params,
                quantity: qty,
            });

            const individualPrice = getPriceBasedOnQty(qty);
            const totalPrice = individualPrice;

            newValues = {
                individual: individualPrice,
                cost: parseFloat(totalPrice).toFixed(2),
            };
        }

        setCalculationValues(newValues);
    };

    const invalidQuantity = () => {
        const qty = get(params, "quantity", 0);

        if (isNaN(qty)) {
            return true;
        }

        if (qty < minQty) {
            return true;
        }

        if (maxQty && qty > maxQty) {
            return true;
        }

        return false;
    };

    const getPrices = () => {
        setLoadingState("loading");

        const uuid =
            userAsset === undefined ? asset.uuid : userAsset.asset.uuid;

        axios
            .get(`/api/v1/assets/${uuid}/print/prices`)
            .then(({ data }) => {
                const pricingMatrix = data.data;

                setPricingMatrix(pricingMatrix);
                setMinQty(getMinQuantity(pricingMatrix));
                setMaxQty(getMaxQuantity(pricingMatrix));

                setLoadingState("loaded");
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    addToast({
                        type: "alert",
                        content: trans(
                            "Supplier print pricing is not available"
                        ),
                        showFor: 5000,
                    });
                }

                setLoadingState("error");
            });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        setLoadingState("submitting");

        const smartRef =
            userAsset === undefined ? asset.smartRef : userAsset.smartRef;
        const uuid = userAsset === undefined ? asset.uuid : userAsset.uuid;
        const url = userAsset === undefined ? "assets" : "user-assets";

        const formParams = {
            ...params,
            smart_ref: smartRef,
            unit_price: calculationValues.individual,
            quoted_price: calculationValues.cost,
        };

        axios
            .post(`/api/v1/${url}/${uuid}/print/order`, formParams)
            .then(({ data }) => {
                setLoadingState("loaded");

                const request = data.data;

                addToast({
                    type: "success",
                    content: trans("Order Placed"),
                    showFor: 5000,
                });

                onClose();

                // once complete, load up the resulting request
                window.location.replace(`/requests/view/${request.uuid}`);
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setFormErrors(data.errors);
                    }

                    addToast({
                        type: "alert",
                        content:
                            e.response && e.response.data
                                ? e.response.data.message
                                : trans("An error has occurred"),
                        showFor: 5000,
                    });
                }

                setLoadingState("loaded");
            });
    };

    const render = (loadingState) => {
        if (loadingState === "loading") {
            return (
                <>
                    <Row>
                        <Column>
                            <Loading />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <p
                                style={{
                                    textAlign: "center",
                                    marginTop: "20px",
                                }}
                            >
                                Loading your preferred print supplier prices...
                            </p>
                        </Column>
                    </Row>
                </>
            );
        }

        if (loadingState === "loaded" || loadingState === "submitting") {
            return (
                <>
                    <Row>
                        <Column>
                            <h3>Order Print</h3>
                            <h4>Delivered To You</h4>
                            <TableList>
                                <thead>
                                    <tr>
                                        <th>{trans("Quantity")}</th>
                                        <th>{trans("Price")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pricingMatrix.map((m) => {
                                        return (
                                            <tr key={m.unit_price}>
                                                <td>{m.min_quantity}</td>
                                                <td>
                                                    {trans("£")}{" "}
                                                    {parseFloat(
                                                        m.unit_price
                                                    ).toFixed(2)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </TableList>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <Row collapse>
                                    <Column medium={6}>
                                        <FormLabel name="quantity">
                                            {trans("Quantity* required")}
                                        </FormLabel>

                                        <StyledSelectSimple
                                            name="quantity"
                                            min={minQty}
                                            max={maxQty}
                                            onChange={(e) =>
                                                setQuantity(e.target.value)
                                            }
                                            error={get(formErrors, "quantity")}
                                            disabled={
                                                loadingState === "loading" ||
                                                loadingState === "submitting"
                                            }
                                            options={pricingMatrix.map((m) => {
                                                return {
                                                    text: `${m.min_quantity}`,
                                                    value: m.min_quantity,
                                                };
                                            })}
                                        />
                                    </Column>
                                    <Column medium={6}>
                                        <FormLabel name="cost">
                                            {trans("Cost to you")}
                                        </FormLabel>

                                        <StyledInput
                                            name="cost"
                                            value={
                                                "£ " +
                                                get(calculationValues, "cost")
                                            }
                                            disabled={true}
                                            onChange={() => {}}
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <p>*Minimum order quantity is {minQty}</p>
                                </Row>
                                <Row collapse>
                                    <Column medium={4}>
                                        <SubmitButton
                                            submitting={
                                                loadingState === "submitting"
                                            }
                                            style={{ textAlign: "center" }}
                                            disabled={
                                                loadingState === "loading" ||
                                                loadingState === "submitting" ||
                                                invalidQuantity()
                                            }
                                            expanded
                                        >
                                            {trans("Place Order")}
                                        </SubmitButton>
                                    </Column>
                                </Row>
                            </form>
                        </Column>
                    </Row>
                </>
            );
        }

        return (
            <>
                <Row>
                    <Column>
                        <Error>
                            Sorry, something went wrong, please try again later.
                        </Error>
                    </Column>
                </Row>
            </>
        );
    };

    return (
        <Modal visible={visible} onClose={onClose}>
            {render(loadingState)}
        </Modal>
    );
};

export default PrintOrderModal;
