import React, { useRef, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import {
    Loading,
    Panel,
    Image,
    FileViewer,
    PDFViewer,
    Iframe,
    Error,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import EditableVideoPreview from "../forms/components/EditableVideoPreview";
import InDesignPdf from "../forms/components/InDesignPdf";

const AssetPanel = styled(Panel)`
    position: relative;
    padding: 0.8rem 0.8rem 0.4rem;
    margin-bottom: 0;
    background: ${({ theme }) => theme.asset.preview.background};
    color: ${({ theme }) => theme.asset.preview.color};
    ${({ hideShadow }) => hideShadow && `box-shadow: none;`};
`;

const renderAssetTypePreview = (asset, userAsset, theme) => {
    //Some items can be previewed using the userAsset rather than the asset, this sets up
    //these options to be used where required
    const uuid = userAsset ? userAsset.uuid : asset.uuid;
    const updated_at = userAsset ? userAsset.updated_at : asset.updated_at;
    const videoRef = useRef();

    useEffect(() => {
        videoRef.current?.load();
    }, [uuid]);

    const assetType =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset?.asset_type?.slug;

    switch (assetType) {
        case "inDesign":
            if (typeof asset.pdf === "undefined") {
                return <Loading color={theme.asset.preview.color} />;
            }
            if (asset.pdf) {
                return <InDesignPdf asset={asset} userAsset={userAsset} />;
            }
            return <Error>{trans("PDF Preview could not be loaded")}</Error>;
        // return (
        //     <Image
        //         alt={asset.title}
        //         src={`${process.env.REACT_APP_IN_DESIGN_URL}/output/${asset.uuid}/${asset.uuid}.png?${asset.updated_at}`}
        //         style={{ maxWidth: "95%" }}
        //         showCheckerboard={true}
        //     />
        // );

        case "email":
        case "digital":
        case "static_email":
        case "static_digital":
            // console.log("preview user asset", asset);
            let path = userAsset
                ? `${process.env.REACT_APP_BASE_URL}/api/v1/user-assets/html-preview/${uuid}/index.html?${updated_at}`
                : `${process.env.REACT_APP_BASE_URL}/api/v1/assets/html-preview/${uuid}/index.html?${updated_at}`;
            return (
                <Iframe
                    src={path}
                    style={{
                        height: "100%",
                        width: "100%",
                        minHeight: "90vh",
                    }}
                />
            );
        case "editableVideo":
            return <EditableVideoPreview asset={asset} userAsset={userAsset} />;

        case "video":
            return (
                <video
                    controls
                    style={{ maxWidth: "95%" }}
                    ref={videoRef}
                    src={asset.main_file_url}
                >
                    Your browser does not support the
                    <code>video</code> element.
                </video>
            );
        case "audio":
            return (
                <audio controls src={asset.main_file_url}>
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            );

        default:
            if (
                // asset.main_file_extension === "pdf" ||
                asset.main_file_extension === "docx" ||
                asset.main_file_extension === "xlsx" ||
                asset.main_file_extension === "pptx"
            ) {
                return (
                    <FileViewer
                        filePath={encodeURIComponent(asset.main_file_url)}
                        type={asset.main_file_extension}
                        style={{ maxWidth: "95%" }}
                    />
                );
            } else if (asset.main_file_extension === "pdf") {
                return (
                    <PDFViewer
                        filePath={asset.main_file_url}
                        style={{ maxWidth: "95%" }}
                    />
                );
            } else {
                return (
                    <Image
                        alt={asset.title}
                        src={asset.thumbnail}
                        style={{ maxWidth: "95%" }}
                        showCheckerboard={true}
                    />
                );
            }
    }
};

const renderEmailLink = (asset, assetTypeConfig) => {
    const email = asset?.meta_data?.contact
        ? asset.meta_data.contact
        : asset.user && asset.user.email
        ? asset.user.email
        : null;
    if (!email && !assetTypeConfig?.support_url) {
        return null;
    }

    return (
        <h4 style={{ textAlign: "center" }}>
            {trans("If you cannot access this link, please contact ")}
            {email && (
                <>
                    <a href={`mailto:${email}`} style={{ color: "inherit" }}>
                        {email}
                    </a>
                </>
            )}

            {assetTypeConfig?.support_url && (
                <>
                    {" "}
                    {email && trans(" or your ")}
                    <Link
                        to={assetTypeConfig.support_url}
                        style={{ color: "inherit" }}
                    >
                        {trans("Support Contact")}
                    </Link>
                </>
            )}
        </h4>
    );
};

const AssetDetailsPreviewPanel = ({
    asset,
    userAsset,
    hideShadow,
    // assetType = null,
}) => {
    const theme = useTheme();

    const assetType =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset?.asset_type?.slug;

    const assetTypeConfig = asset?.asset_type_config;

    return (
        <AssetPanel hideShadow={hideShadow}>
            {assetType === "link" && (
                <aside>{renderEmailLink(asset, assetTypeConfig)}</aside>
            )}
            {/* <center>{assetTypePreview}</center> */}
            <center>{renderAssetTypePreview(asset, userAsset, theme)}</center>
        </AssetPanel>
    );
};

export default AssetDetailsPreviewPanel;
