import React, { useState } from "react";
import axios from "axios";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

import {
    FormLabel,
    Row,
    IconTextButton,
    Modal,
    useAddToast,
    TextareaField,
    SubmitButton,
    Error,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const AssetLinkActions = ({ asset }) => {
    const [showAssetIssueModal, setShowAssetIssueModal] = useState(false);
    const [comment, setComment] = useState("");

    const addToast = useAddToast();

    const handleLinkVisit = () => {
        //Post visit to the server and allow the default behaviour
        axios.post(`/api/v1/assets/${asset.uuid}/visit`);
    };
    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        //Post visit to the server and allow the default behaviour
        axios
            .post(`/api/v1/assets/${asset.uuid}/issue`, {
                comment,
                issue_type: "broken-link",
            })
            .then(() => {
                addToast({
                    content: trans("Issue reported"),
                    type: "success",
                    showFor: 5000,
                });
                setShowAssetIssueModal(false);
                setComment("");
            });
    };

    if (asset.configuration === null) {
        // setComment("Configuration is null");
        // handleSubmit();
        return (
            <>
                <Error>
                    <p>{trans("Link configuration is missing")}</p>
                </Error>
                <Row>
                    <IconTextButton
                        type="primary"
                        onClick={() => setShowAssetIssueModal(true)}
                        expanded
                    >
                        {trans("Report Broken Link")}
                    </IconTextButton>
                </Row>
            </>
        );
    }

    return (
        <React.Fragment>
            <Row>
                <IconTextButton
                    type="primary"
                    as={"a"}
                    target="_blank"
                    href={asset.configuration.url.value}
                    onClick={handleLinkVisit}
                    expanded
                    icon={faExternalLinkAlt}
                    showCircle={false}
                >
                    {trans("Visit Link")}
                </IconTextButton>
            </Row>
            <Row>
                <IconTextButton
                    type="primary"
                    onClick={() => setShowAssetIssueModal(true)}
                    expanded
                >
                    {trans("Report Broken Link")}
                </IconTextButton>
            </Row>
            <Modal
                visible={showAssetIssueModal}
                onClose={() => setShowAssetIssueModal(false)}
            >
                <h2>{trans("Report Broken Link")}</h2>
                <form onSubmit={handleSubmit}>
                    <FormLabel name="comment">{trans("Comment")}</FormLabel>
                    <TextareaField
                        name="comment"
                        value={comment}
                        onChange={({ value }) => setComment(value)}
                    />
                    <SubmitButton>{trans("Submit Report")}</SubmitButton>
                </form>
            </Modal>
        </React.Fragment>
    );
};

export default AssetLinkActions;
