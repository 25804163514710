import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikTextarea,
    FormikCheckboxTree,
    IconTextButton,
    Tabordion,
    Row,
    Column,
    SimpleDropzone,
    FormikColorField,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import {
    TabordionGroup,
    LocaleSwitcher,
    PreviewImage,
} from "../components/index";

const FILE_SIZE = 1160 * 1024 * 5;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = Yup.object().shape({
    name: Yup.string()
        .typeError("Name needs to be a string")
        .required("Required"),
    url: Yup.string()
        .typeError("URL needs to be a string")
        .required("Required"),
    description: Yup.string().nullable(),
    color: Yup.string().nullable(),
    thumbnail: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                if (!value) {
                    return Yup.mixed();
                }
                return (
                    Yup.mixed()
                        // .required("A thumbnail is required")
                        .test(
                            "fileSize",
                            "File too large",
                            (value) => value.size <= FILE_SIZE
                        )
                        .test("fileFormat", "Unsupported Format", (value) =>
                            SUPPORTED_FORMATS.includes(value.type)
                        )
                ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
});

export type Link = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    locale?: string;
};

export const LinkForm = ({ item, submitForm }) => {
    const [tab, setTab] = useState(0);
    const [newPreviewFile, setNewPreviewFile] = useState(null);

    const handleSubmit = (
        values: Link,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();
        Object.keys(values).forEach((key) => fd.append(key, values[key]));

        submitForm(fd, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };
    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );
    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("thumbnail", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);

    return (
        <Formik
            initialValues={{
                name: item.name,
                url: item.url,
                locale: item.locale,
                description: item.description,
                color: item.color,
                thumbnail: item.thumbnail,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                // console.log(values);
                return (
                    <Form className="form-group">
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <FormRow
                                    name="locale"
                                    label={trans("Locale")}
                                    helpText={trans("Optional")}
                                    error={errors.locale}
                                >
                                    <LocaleSwitcher
                                        item={item}
                                        setFieldValue={setFieldValue}
                                        //@ts-ignore
                                        values={values}
                                    />
                                </FormRow>
                                <FormRow
                                    name="name"
                                    label={trans("Name")}
                                    helpText={trans("Required")}
                                    error={errors.name}
                                >
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="url"
                                    label={trans("URL")}
                                    helpText={trans("Required")}
                                    error={errors.url}
                                >
                                    <FormikInput
                                        name="url"
                                        value={values.url}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="description"
                                    label={trans("Description")}
                                    helpText={trans("Required")}
                                    error={errors.description}
                                >
                                    <FormikTextarea
                                        name="description"
                                        value={values.description}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="thumbnail"
                                    label={trans("Thumbnail")}
                                    helpText={trans("Optional")}
                                    error={errors.thumbnail}
                                >
                                    <SimpleDropzone
                                        style={{ marginBottom: "2.2rem" }}
                                        onDrop={(acceptedFiles) =>
                                            onDrop(acceptedFiles, setFieldValue)
                                        }
                                        accept="image/*"
                                        multiple={false}
                                    >
                                        <IconTextButton
                                            icon="upload"
                                            showCircle={false}
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                        >
                                            {values.thumbnail &&
                                            values.thumbnail !== ""
                                                ? trans("Update thumbnail")
                                                : trans("Add thumbnail")}
                                        </IconTextButton>
                                    </SimpleDropzone>

                                    {typeof values.thumbnail === "string" &&
                                        values.thumbnail !== "" && (
                                            <PreviewImage
                                                src={values.thumbnail}
                                            />
                                        )}
                                    {newPreviewFile && (
                                        <PreviewImage src={newPreviewFile} />
                                    )}
                                </FormRow>
                                <FormRow
                                    name="color"
                                    label={trans("Color")}
                                    helpText={trans("Optional")}
                                    error={errors.color}
                                >
                                    <FormikColorField
                                        name="color"
                                        value={values.color}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                            </TabordionGroup>
                        </Tabordion>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default LinkForm;
