import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMinus,
    faPlus,
    faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";

// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { pdfjs, Document, Page } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.min.mjs`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     "pdfjs-dist/build/pdf.worker.min.mjs",
//     import.meta.url
// ).toString();

//TODO - Breaking builds so removed for now, need to investigate why
// import "react-pdf/dist/Page/AnnotationLayer.css";
// import "react-pdf/dist/Page/TextLayer.css";

import { Error, Button, Pagination, Loading } from "../index";
import { trans } from "@cortexglobal/rla-intl";
import useDebounce from "../hooks/debounce";

const options = {
    cMapUrl: "/cmaps/",
    cMapPacked: true,
};

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: ${({ height }) => height};
`;

const PDFWrapper = styled.div`
    margin: 0 0 1rem 0;
    width: 100%;
    min-width: 100%;
    max-height: 100%;
    overflow: auto;
    .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .react-pdf__Page {
        max-width: calc(100% - 2em);
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
        margin: 1em;

        canvas {
            width: 100%;
            max-width: 100%;
            height: auto !important;
        }
    }
`;

const PDFZoomControls = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
`;

export const PDFViewer = ({
    filePath,
    height = "100%",
    hasFullSizeButton = true,
    paginate = true,
    showControls = false,
}) => {
    const [page, setPage] = useState(1);
    const [pageWidth, setPageWidth] = useState(1);
    const [error, setError] = useState(false);
    const wrapperRef = useRef(null);
    const [debounce] = useDebounce();

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = (settings) => {
        console.log("settings", settings);
        const { numPages: nextNumPages } = settings;
        setNumPages(nextNumPages);
    };

    useEffect(() => {
        const handleResize = debounce(() => {
            if (wrapperRef && wrapperRef.current) {
                setPageWidth(wrapperRef.current.offsetWidth);
            }
        }, 500);
        // if (wrapperRef && wrapperRef.current) {
        handleResize();
        // }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const LoadingContainer = styled.div`
        padding-top: 2rem;
    `;
    console.log("PDF viewer error", error);
    if (error) {
        return <Error>{trans("Error loading PDF")}</Error>;
    }

    return (
        <Wrapper height={height}>
            {showControls && (
                <PDFZoomControls>
                    {hasFullSizeButton && (
                        <Button
                            style={{ marginRight: ".5rem" }}
                            onClick={() => {
                                console.log("TODO");
                            }}
                        >
                            100%
                        </Button>
                    )}
                    <Button
                        style={{ marginRight: ".5rem" }}
                        onClick={() => {
                            console.log("TODO");
                        }}
                    >
                        <FontAwesomeIcon icon={faExpandArrowsAlt} />
                    </Button>
                    <Button
                        style={{ marginRight: ".5rem" }}
                        onClick={() => {
                            console.log("TODO");
                        }}
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                    <Button
                        onClick={() => {
                            console.log("TODO");
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </PDFZoomControls>
            )}
            <PDFWrapper ref={wrapperRef}>
                <Document
                    file={filePath}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => setError(error)}
                    onSourceError={(error) => setError(error)}
                    options={options}
                    loading={
                        <LoadingContainer>
                            <Loading />
                        </LoadingContainer>
                    }
                >
                    {paginate ? (
                        <Page pageNumber={page} width={pageWidth} />
                    ) : (
                        Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={pageWidth}
                                loading={
                                    <LoadingContainer>
                                        <Loading />
                                    </LoadingContainer>
                                }
                            />
                        ))
                    )}
                </Document>
            </PDFWrapper>
            {paginate && Boolean(numPages) && (
                <Pagination
                    alwaysShow={true}
                    showSummary={false}
                    style={{ clear: "both" }}
                    currentPage={page - 1}
                    pageCount={numPages}
                    onPageChange={({ selected }) => setPage(selected + 1)}
                />
            )}
        </Wrapper>
    );
};

export default PDFViewer;
