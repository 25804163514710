import React, { Component } from "react";

import AssetDownloadsListingConfiguration from "./AssetDownloadsListingConfiguration";
import AssetDownloadsListingOutput from "./AssetDownloadsListingOutput";

class AssetDownloadsListing extends Component {
    render() {
        if (this.props.configuring) {
            return <AssetDownloadsListingConfiguration {...this.props} />;
        }
        return <AssetDownloadsListingOutput {...this.props} />;
    }
}

export default AssetDownloadsListing;
