import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
    SelectField,
    StyledSelectAdvanced,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import { setUserRole, getUserRoles } from "@cortexglobal/cortex-auth-redux";
import { get } from "lodash";

class RoleSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            activeRole: "",
        };
    }

    componentDidMount() {
        this.setState({
            activeRole: this.props.role.uuid,
        });
    }

    componentDidUpdate(props) {
        if (this.state.activeRole !== props.role.uuid) {
            this.setState({
                activeRole: props.role.uuid,
            });
        }
    }

    userRoleChanged = ({ value: role }) => {
        if (!role || this.state.activeRole === role) {
            return false;
        }

        this.setState({ activeRole: role });

        this.props
            .setUserRole({ role })
            .then((role) => {
                // console.warn(role);
            })
            .catch((e) => e);
    };

    render() {
        const { roles, user, primary_role } = this.props;

        if (!primary_role || !roles || roles.length <= 1) {
            return null;
        }

        const primaryPermissions = (get(primary_role, "permissions") || []).map(
            (p) => p.alias
        );

        if (!primaryPermissions.includes("roles-can-switch")) {
            return null;
        }

        return (
            <React.Fragment>
                {user.group_structure_type === "flat" ? (
                    <StyledSelectAdvanced
                        label={trans("Change Role:")}
                        style={{
                            marginBottom: ".5rem",
                        }}
                        name="user-role"
                        onChange={this.userRoleChanged}
                        options={roles.map((e) => ({
                            value: e.uuid,
                            text: e.name,
                        }))}
                        value={this.state.activeRole}
                    />
                ) : (
                    <SelectField
                        label={trans("Change Role:")}
                        style={{
                            marginBottom: ".5rem",
                        }}
                        name="user-role"
                        onChange={this.userRoleChanged}
                        options={roles.map((e) => ({
                            value: e.uuid,
                            text: e.name,
                        }))}
                        value={this.state.activeRole}
                    />
                )}

                {/* {this.props.label && <label>{this.props.label}</label>}
                <Select
                    name="user-role"
                    onChange={this.userRoleChanged}
                    options={this.props.roles.map(role => ({
                        value: role.uuid,
                        text: role.name
                    }))}
                    value={this.state.activeRole}
                    clearable={false}
                /> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, role, roles, primary_role } = state.auth;

    return { user, role, roles, primary_role };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUserRole,
            getUserRoles,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RoleSwitcher);
