import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import {
    PageTitle,
    Row,
    Column,
    Loading,
    Error,
    Panel,
} from "@cortexglobal/rla-components";
import User from "./objects/User";

import EditUserForm from "./components/EditUserForm";
import ResetPasswordForm from "./components/ResetPasswordForm";

const Profile = (props) => {
    const user = useSelector((state) => {
        return new User(state.auth.user);
    });

    const group = useSelector((state) => {
        user.setPrimaryGroup(state.auth.group);

        return state.auth.group;
    });

    const role = useSelector((state) => {
        user.setPrimaryRole(state.auth.role);

        return state.auth.role;
    });

    const loadingState = useSelector((state) => {
        if (
            state.auth.user.loading === true ||
            state.auth.group.loading === true ||
            state.auth.role.loading === true
        ) {
            return "loading";
        }
        return "loaded";
    });

    const submitPasswordReset = (params) => {
        console.log(params);
    };

    const getContent = (props) => {
        if (loadingState === "loading") {
            return (
                <Row>
                    <Column medium={12}>
                        <Loading />
                    </Column>
                </Row>
            );
        }

        if (loadingState === "error") {
            return (
                <Row>
                    <Column medium={12}>
                        <Error />
                    </Column>
                </Row>
            );
        }

        return (
            <>
                <Row>
                    <Column medium={6}>
                        <Panel>
                            <Row>
                                <Column medium={12}>
                                    <h2>About Me</h2>
                                    <p>
                                        <img src={user.avatar} />
                                    </p>

                                    <p>
                                        <strong>First name:</strong>{" "}
                                        {user.first_name}
                                    </p>
                                    <p>
                                        <strong>Last name:</strong>{" "}
                                        {user.last_name}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {user.email}
                                    </p>
                                    <p>
                                        <strong>Group:</strong>{" "}
                                        {user.group_name}
                                    </p>
                                    <p>
                                        <strong>Role:</strong> {user.role_name}
                                    </p>
                                </Column>
                            </Row>
                        </Panel>
                    </Column>
                </Row>
            </>
        );
    };

    return (
        <div id="user-profile" style={{ marginBottom: "20px" }}>
            <PageTitle title={trans("My Profile")} />

            {getContent()}
        </div>
    );
};

export default Profile;
