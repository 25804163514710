import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import axios from "axios";
import { Collapse } from "react-collapse";
import { trans, useIntl } from "@cortexglobal/rla-intl";

import { StyledSelectAsync } from "@cortexglobal/rla-components";
import { isEmpty } from "lodash";

const UserSelector = ({
    onChange,
    name = "user",
    value = [],
    multi = false,
    ...rest
}) => {
    const loadOptions = (search, loadedOptions, { page = 1 }) => {
        return axios
            .get(`/api/v1/user/options`, {
                params: { searchText: search, page },
            })
            .then(({ data }) => {
                const { current_page, last_page } = data.meta;
                return {
                    options: data.data.map((userOption) => {
                        return { ...userOption, label: userOption.text };
                    }),
                    hasMore: current_page < last_page,
                    additional: {
                        page: page + 1,
                    },
                };
            });
    };

    const onUserChange = (value) => {
        // console.log({ user_selector_value: value });
        if (multi) {
            onChange({ name, value: value });
        } else {
            onChange({ name, value: [value] });
        }
    };
    // console.log({ value });
    return (
        <StyledSelectAsync
            name={name}
            value={value}
            onChange={onUserChange}
            placeholder={trans("Select a User...")}
            maxMenuHeight={150}
            loadOptions={loadOptions}
            additional={{ page: 1 }}
            multi={multi}
            {...rest}
        />
    );
};

export default UserSelector;
