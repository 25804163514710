import React, { useState } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import Collapse from "react-collapse";

import {
    Modal,
    Row,
    Column,
    SelectField,
    SaveButton,
    Button,
    DatePicker,
    LoadingIconSmall,
    RadioField,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { getDates } from "@cortexglobal/linear-calendar";

import { format, getLocaleDateFormat } from "@cortexglobal/cortex-utilities";

import { duplicatePlan } from "../plannerActions";
import { useEffect } from "react";

const CheckingWrapper = styled.aside`
    display: flex;
    padding-bottom: 1.2rem;
    align-items: center;
`;
const DuplicatePlanner = ({
    start,
    end,
    hideModal,
    modalName,
    duplicatePlan,
}) => {
    const intl = useIntl();
    const [startDate, setStartDate] = useState("");
    const [saving, setSaving] = useState(false);
    const [isMonthly, setIsMonthly] = useState(true);
    const [checkingExistingTaskCount, setCheckingExistingTaskCount] =
        useState(false);
    const [existingTaskCount, setExistingTaskCount] = useState(null);
    const [existingTaskChoice, setExistingTaskChoice] = useState(null);

    useEffect(() => {
        //If the start date is in the same month as the end date,
        //we can deal with the dates in a monthly manner.
        if (moment(start).format("MMMM") === moment(end).format("MMMM")) {
            setIsMonthly(true);
        } else {
            setIsMonthly(false);
        }
    }, [start, end]);

    const submitForm = (e) => {
        e.preventDefault();
        setSaving(true);
        let values = {};
        values["existingTaskChoice"] = existingTaskChoice;
        values["startDate"] = moment(start);
        values["endDate"] = moment(end);
        values["newStartDate"] = startDate;
        values["newEndDate"] = moment(startDate).add(
            values["endDate"].diff(values["startDate"], "days"),
            "days"
        );

        duplicatePlan(values).then(() => {
            setSaving(false);
            hideModal();
        });
    };

    const handleStartDateChange = ({ value }) => {
        setStartDate(value);
        setCheckingExistingTaskCount(true);
        setExistingTaskCount(null);

        //Check if there are dates already in the date range
        const duration = moment(end).diff(start, "days");
        axios
            .get(`api/v1/planner/count-tasks`, {
                params: {
                    startDate: moment(value).format(),
                    endDate: moment(value).add(duration, "days").format(),
                },
            })
            .then((res) => {
                setCheckingExistingTaskCount(false);
                setExistingTaskCount(res.data);

                //TODO - This is being automatically set to 'keep' as we need to work out how
                //to handle the 'delete' option with regards to the tasks that are already in an approved plan.
                setExistingTaskChoice("keep");
                // if (res.data.count > 0) {
                //     alert(
                //         "There are already tasks in this date range. Please select a different date range."
                //     );
                //     setStartDate("");
                // }
            });
    };

    const generateOptions = (startDate) => {
        const dates = getDates(
            moment(startDate).add(1, "months").format(),
            moment(startDate).add(1, "years").format(),
            1,
            "months"
        );
        return dates.map((date) => {
            return {
                value: date.startOf("month").format(),
                text: date.format("MMMM YYYY"),
            };
        });
    };

    return (
        <Modal visible={modalName === "duplicatePlan"} onClose={hideModal}>
            <Row>
                <Column>
                    <h2 className="modalTitle">
                        {trans("Duplicate {start} - {end} Tasks to:", {
                            start: format(new Date(start)),
                            end: format(new Date(end)),
                        })}
                    </h2>
                </Column>
            </Row>

            <div>
                <form onSubmit={submitForm}>
                    <fieldset>
                        <Row>
                            <Column>
                                {isMonthly ? (
                                    <SelectField
                                        value={startDate}
                                        name="startDate"
                                        options={generateOptions(start)}
                                        label="Select a Month"
                                        placeholder="Month"
                                        onChange={handleStartDateChange}
                                    />
                                ) : (
                                    <DatePicker
                                        value={startDate}
                                        name="startDate"
                                        label="Select a Start Date"
                                        placeholder="Date"
                                        onChange={handleStartDateChange}
                                    />
                                )}
                            </Column>
                        </Row>
                    </fieldset>
                    <Row>
                        <Column>
                            <Collapse isOpened={existingTaskCount > 0}>
                                {existingTaskCount && (
                                    <div>
                                        <p>
                                            {trans(
                                                "There {existingTaskCount, plural, one {# task} other {# tasks}} in the destination date range.",
                                                {
                                                    existingTaskCount,
                                                }
                                            )}
                                        </p>
                                        <p>
                                            {trans(
                                                "Please be aware that the tasks in the current range will be added to the ones in the destination date range."
                                            )}
                                        </p>
                                        {/* TODO - Reinstate the code below when we've worked out how to handle
                                        the 'delete' option with regards to the tasks that are already in an approved plan. */}
                                        {/* <RadioField
                                            label={trans(
                                                "Please choose what you would like to do with the existing {existingTaskCount} tasks in this date range",
                                                {
                                                    existingTaskCount,
                                                }
                                            )}
                                            name="existingTaskChoice"
                                            value={existingTaskChoice}
                                            options={[
                                                {
                                                    text: intl.formatMessage({
                                                        id: "Keep existing tasks",
                                                    }),
                                                    value: "keep",
                                                },
                                                {
                                                    text: intl.formatMessage({
                                                        id: "Delete existing tasks",
                                                    }),
                                                    value: "delete",
                                                },
                                            ]}
                                            onChange={(event) => {
                                                setExistingTaskChoice(
                                                    event.value
                                                );
                                            }}
                                        /> */}
                                    </div>
                                )}
                            </Collapse>
                            <Collapse isOpened={checkingExistingTaskCount}>
                                {checkingExistingTaskCount && (
                                    <CheckingWrapper>
                                        <LoadingIconSmall />
                                        {trans(
                                            "Checking tasks in the new date range"
                                        )}
                                    </CheckingWrapper>
                                )}
                            </Collapse>
                        </Column>
                    </Row>
                    <Row>
                        <Column medium={6}>
                            <Button
                                type="alert"
                                expanded
                                disabled={saving}
                                onClick={(event) => {
                                    event.preventDefault();
                                    hideModal();
                                }}
                            >
                                {trans("Cancel")}
                            </Button>
                        </Column>
                        <Column medium={6}>
                            <SaveButton
                                color="primary"
                                expanded
                                disabled={
                                    saving ||
                                    startDate === "" ||
                                    checkingExistingTaskCount ||
                                    (existingTaskCount &&
                                        existingTaskChoice === null)
                                }
                                label={trans("Save")}
                                saving={saving}
                            />
                        </Column>
                    </Row>
                </form>
            </div>
        </Modal>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            duplicatePlan,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    DuplicatePlanner
);
