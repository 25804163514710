import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledSelectAdvanced from "../styledElements/StyledSelectAdvanced";
import AsyncSelect from "react-select/async";

const FormikAdvancedSelect = ({
    type,
    name,
    label,
    disabled,
    options,
    async,
    loadFunction,
    showError,
    limit,
    ...rest
}) => {
    return (
        <React.Fragment>
            <Field name={name}>
                {({
                    field: { name, onChange },
                    form: {
                        values,
                        touched,
                        errors,
                        setFieldTouched,
                        setFieldValue,
                    },
                }) => {
                    const error = !!touched[name] && errors[name];
                    return (
                        <div style={{ marginBottom: "1.2em" }}>
                            {label && (
                                <FormLabel name={name}>{label}</FormLabel>
                            )}{" "}
                            {async ? (
                                <AsyncSelect
                                    {...rest}
                                    name={name}
                                    id={name}
                                    loadOptions={loadFunction}
                                    onChange={(selected) => {
                                        //If the limit is set, check if the limit is reached
                                        if (
                                            limit &&
                                            values[name].length >= limit &&
                                            selected.length >
                                                values[name].length
                                        ) {
                                            return;
                                        }
                                        let value = "";
                                        if (Array.isArray(selected)) {
                                            value = selected.map(
                                                (selected) => selected.value
                                            );
                                        } else if (selected && selected.value) {
                                            value = selected.value;
                                        }

                                        setFieldValue(name, {
                                            value: value,
                                            label: selected.label,
                                        });
                                        setFieldTouched(name, true);
                                    }}
                                    value={values[name]}
                                    error={error}
                                    optionIsDisabled={(option) =>
                                        limit && values[name].length >= limit
                                    }
                                />
                            ) : (
                                <StyledSelectAdvanced
                                    {...rest}
                                    async={async}
                                    name={name}
                                    loadOptions={loadFunction}
                                    options={options}
                                    id={name}
                                    value={values[name]}
                                    limit={limit}
                                    onChange={(selected) => {
                                        let value = "";
                                        if (Array.isArray(selected)) {
                                            value = selected.map(
                                                (selected) => selected.value
                                            );
                                        } else if (selected && selected.value) {
                                            value = selected.value;
                                        }

                                        setFieldValue(name, value);
                                        setFieldTouched(name, true);
                                        if (
                                            typeof rest.onChange === "function"
                                        ) {
                                            rest.onChange({ name, value });
                                        }
                                    }}
                                    onBlur={() => {
                                        setFieldTouched(name, true);
                                    }}
                                    error={error}
                                    disabled={disabled}
                                />
                            )}
                        </div>
                    );
                }}
            </Field>
            {showError && (
                <ErrorMessage name={name}>
                    {(msg) => <InputError error={msg} />}
                </ErrorMessage>
            )}
        </React.Fragment>
    );
};

FormikAdvancedSelect.displayName = "FormikAdvancedSelect";

FormikAdvancedSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired,
        })
    ),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.string,
};

FormikAdvancedSelect.defaultProps = {
    label: "",
    showError: true,
};

export default FormikAdvancedSelect;
