import React from "react";
import { trans } from "@cortexglobal/rla-intl";
export const FileViewer = ({
    type = "pdf",
    filePath = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
}) => {
    if (type === "xlsx" || type === "docx" || type === "pptx") {
        if (process.env.REACT_APP_ENV === "local") {
            return "Office previews not available in local environments";
        }
        return (
            <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${filePath}`}
                width="100%"
                height="100%"
                frameborder="0"
                style={{ minHeight: "50vh" }}
            >
                This is an embedded{" "}
                <a target="_blank" href="http://office.com">
                    Microsoft Office
                </a>{" "}
                document, powered by{" "}
                <a target="_blank" href="http://office.com/webapps">
                    Office Online
                </a>
                .
            </iframe>
        );
    }
    //If it's not an office file, just open it in a new tab or download it
    return (
        <a href={filePath} target="_blank">
            {trans("View File")}
        </a>
    );
};

export default FileViewer;
