import React from "react";
import styled, { useTheme } from "styled-components";

import { SideMenu } from "./SideMenu";
import UserInfo from "./UserInfo";
import { MessageIconButton } from "@cortexglobal/communication";

const HeaderUtilityAreaWrapper = styled.div`
    background: ${({ theme }) => theme.userInfo.backgroundColor};
    display: flex;
    width: 100%;
    align-items: center;
`;

const UserInfoWrapper = styled.div`
    flex-grow: 1;
    text-align: right;
    margin-right: ${({ theme }) => theme.userInfo.wrapper.marginRight};
    padding-right: ${({ theme }) => theme.userInfo.wrapper.paddingRight};
    border-right: ${({ theme }) => theme.userInfo.wrapper.borderRight};
`;

const HeaderUtilityButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HeaderUtilityArea = ({ handleNavClick }) => {
    const theme = useTheme();

    return (
        <HeaderUtilityAreaWrapper>
            <UserInfoWrapper>
                <UserInfo />
            </UserInfoWrapper>
            <HeaderUtilityButtonsWrapper>
                <MessageIconButton handleClick={handleNavClick} style={theme.userInfo.messageIconButton.style}  />
                <SideMenu handleNavClick={handleNavClick} />
            </HeaderUtilityButtonsWrapper>
        </HeaderUtilityAreaWrapper>
    );
};

export default HeaderUtilityArea;
