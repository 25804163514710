import React from "react";
import {
    Row,
    Column,
    IconTextButton,
    PageTitle,
    Loading,
} from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { Link } from "react-router-dom";
import { trans } from "@cortexglobal/rla-intl";

const SurveyLoading = ({ showLinks, path }) => {
    const { can } = usePermissions();

    return (
        <>
            <PageTitle title={trans("Loading, please wait")}>
                {showLinks && can("surveys-manage-list") && (
                    <IconTextButton
                        as={Link}
                        to={`${path}`}
                        rotation={180}
                        showCircle={false}
                    >
                        {trans("Back to Listing")}
                    </IconTextButton>
                )}
            </PageTitle>
            <Row>
                <Column medium={12}>
                    <Loading />
                </Column>
            </Row>
        </>
    );
};

export default SurveyLoading;
