import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Button } from "@cortexglobal/rla-components";
import moment from "moment";

import Activity from "./Activity";
import CalendarContext from "./CalendarContext";
import Task from "./Task";
import { SummaryColumnContent } from "./SummaryColumn";
import CalculatedTask from "./CalculatedTask";

const TaskRowWrapper = styled.div`
    position: relative;
    /* background: ${({ colors }) => colors.taskRow.background}; */
    height: ${({ height }) => height}px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
`;

const TaskSidebar = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
    position: absolute;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /* z-index: 5; */
    background: ${({ colors }) => colors.taskRow.sidebar};
    height: 100%;
    line-height: ${({ heights }) => heights.task}px;
    width: ${({ widths }) => widths.sidebar + widths.addButton}px;
`;

const TaskLabel = styled.button`
    padding: 0 1.2rem;
    font-family: inherit;
    font-size: 0.8rem;
    line-height: 1.15;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    border: 0;
    background-color: ${({ colors }) => colors.taskRow.sidebar};
    text-align: left;

    /* z-index: 7; */
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    color: ${({ colors }) => colors.taskRow.label};
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                background-color: ${({ colors }) => colors.taskRow.buttonHover};
                color: ${({ colors }) => colors.taskRow.labelHover};
            }
        `};

    transition: all 300ms;
`;

const AddButtonWrapper = styled.div`
    width: ${({ widths }) => widths.addButton}px;
    position: absolute;
    left: ${({ widths }) => widths.sidebar}px;
    top: 0;
    /* z-index: 4; */
    background: ${({ colors }) => colors.taskRow.sidebar};
    text-align: center;
    height: ${({ heights }) => heights.task}px;
    line-height: ${({ heights }) => heights.task}px;
`;

const AddButton = styled(Button)`
    font-size: 1.3em;
    cursor: pointer;
    padding: 0 0.5rem;
    background: ${({ colors }) => colors.taskCategory.button};
    box-shadow: none;
    color: ${({ colors }) => colors.taskRow.label};

    ${(props) =>
        props.small &&
        css`
            font-size: 0.7rem;
        `};
`;

const ActivitiesWrapper = styled.div`
    position: absolute;

    /* z-index: 4; */
    left: ${({ widths }) => widths.sidebar + widths.addButton}px;
    height: ${({ height }) => height}px;
    overflow: hidden;
    width: ${({ widths }) => widths.calendar}px;
`;

const TaskRow = (props) => {
    const { category, task } = props;
    const {
        heights,
        widths,
        labels,
        colors,
        addActivity,
        viewTask,
        taskClick,
        activityClick,
        units,
        start,
        end,
        activityDateFormat,
        activityContent,
        taskContent,
        summaryColumn,
        user,
    } = useContext(CalendarContext);
    const filteredActivities = task.activities
        ? task.activities.filter((activity) => {
              if (activity.show_on_planner === "hide") {
                  return false;
              }
              return (
                  //Starts in the month
                  (activity.start_date >= start &&
                      activity.start_date <= end) ||
                  //Ends in the month
                  (activity.end_date >= start && activity.end_date < end) ||
                  //Starts before and ends after (spans this month)
                  (activity.start_date < start && activity.end_date > end)
              );
          })
        : [];
    //We have to calculate the height of the container for the task and activities as
    //these are absolutely positioned in the container
    const activityWrapperHeight =
        heights.spacing * 2 +
        heights.task +
        (heights.activity + heights.spacing) * filteredActivities.length;
    // console.log(widths.calendar, widths.sidebar, widths.addButton);
    return (
        <TaskRowWrapper
            colors={colors}
            height={activityWrapperHeight}
            className={`linearCalendar__StyledTaskRow ${task.type}`}
        >
            <TaskSidebar
                colors={colors}
                heights={heights}
                widths={widths}
                className="linearCalendar__taskSidebar"
            >
                <TaskLabel
                    disabled={!task.can_view_details}
                    colors={colors}
                    title={`${labels.viewTask.title} - ${task.name}`}
                    onClick={(event) => {
                        // console.log("PRESSING", category, task);
                        return viewTask(task, category, event);
                    }}
                >
                    <span
                        className={
                            "linearCalendar__viewTaskButton " +
                            labels.viewTask.className
                        }
                    >
                        {labels.viewTask.content}
                    </span>{" "}
                    {task.name}
                </TaskLabel>
            </TaskSidebar>

            {false && (
                <AddButtonWrapper
                    className="linearCalendar__addButton"
                    colors={colors}
                    heights={heights}
                    widths={widths}
                >
                    <AddButton
                        colors={colors}
                        onClick={(event) => addActivity(task)}
                        className={
                            "linearCalendar__addActivityButton " +
                            labels.addActivity.className
                        }
                        title={labels.addActivity.title}
                    >
                        {labels.addActivity.content}
                    </AddButton>
                </AddButtonWrapper>
            )}

            <ActivitiesWrapper
                // heights={heights}
                height={activityWrapperHeight}
                widths={widths}
                className="linearCalendar__taskData"
            >
                <CalculatedTask
                    category={category}
                    task={task}
                    {...{
                        widths,
                        heights,
                        labels,
                        colors,
                        addActivity,
                        taskClick,
                        units,
                        start,
                        end,
                        activityDateFormat,
                        taskContent,
                    }}
                />
                {moment.parseZone(task.end_date).isAfter(start) && (
                    <Task
                        category={category}
                        task={task}
                        {...{
                            widths,
                            heights,
                            labels,
                            colors,
                            addActivity,
                            taskClick,
                            units,
                            start,
                            end,
                            activityDateFormat,
                            taskContent,
                        }}
                    />
                )}
                {filteredActivities
                    .sort((a, b) => {
                        if (a.start_date > b.start_date) {
                            return 1;
                        }
                        if (a.start_date < b.start_date) {
                            return -1;
                        }
                        return 0;
                    })
                    .map((activity, index) => {
                        return (
                            <Activity
                                key={activity.uuid}
                                index={index}
                                category={category}
                                task={task}
                                activity={activity}
                                {...{
                                    widths,
                                    heights,
                                    labels,
                                    colors,
                                    activityClick,
                                    units,
                                    start,
                                    end,
                                    activityDateFormat,
                                    activityContent,
                                }}
                            />
                        );
                    })}
            </ActivitiesWrapper>
            {summaryColumn && (
                <SummaryColumnContent
                    summaryColumn={summaryColumn}
                    colors={colors}
                    task={task}
                    user={user}
                />
            )}
        </TaskRowWrapper>
    );
};

TaskRow.propTypes = {
    category: PropTypes.object,
    task: PropTypes.object,
    // widths: PropTypes.object,
    heights: PropTypes.object,
    units: PropTypes.object,
    labels: PropTypes.object,
};

export default TaskRow;
