import React, { useEffect, useState } from "react";
import {
    Row,
    Column,
    Panel,
    Tabordion,
    ConditionalWrapper,
} from "@cortexglobal/rla-components";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { Loading } from "@cortexglobal/rla-components";
import { Comments } from "@cortexglobal/comments";
import { Files } from "@cortexglobal/files";
import { useUser } from "@cortexglobal/cortex-auth-redux";
import styled from "styled-components";
import VenueSummary from "./VenueSummary";
import BudgetSummary from "./BudgetSummary";
import AttendeesSummary from "./AttendeesSummary";
import DetailsSummary from "./DetailsSummary";
import EventObject from "../../../objects/EventObject";

const EventDetails = styled.div``;
const EventSummaryDetails = ({
    event: passedEvent,
    eventUuid = null,
    settings: passedSettings = null,
    showingInSummary = false,
}) => {
    const { user } = useUser();
    const [eventLoading, setEventLoading] = useState(false);
    const [event, setEvent] = useState(passedEvent);
    const [settingsLoading, setLocalSettingsLoading] = useState(false);
    const [settings, setLocalSettings] = useState(passedSettings);
    useEffect(() => {
        if (!passedEvent && eventUuid) {
            getEvent(eventUuid);
        }
    }, [eventUuid, passedEvent]);

    useEffect(() => {
        if (!passedSettings) {
            getSettings();
        }
    }, [passedSettings]);
    const getEvent = (uuid) => {
        setEventLoading(true);

        axios
            .get(`/api/v1/events/${uuid}`)
            .then(({ data }) => {
                const eventData = data.data;

                setEvent(new EventObject(eventData));

                setEventLoading(false);
            })
            .catch((e) => {});

        return;
    };
    const getSettings = () => {
        setLocalSettingsLoading(true);
        axios
            .get(`/api/v1/events/settings`)
            .then(({ data }) => {
                console.log(data.data);
                setLocalSettings(data.data);
                setLocalSettingsLoading(false);
            })
            .catch((e) => {
                setLocalSettingsLoading(false);
            });

        return;
    };
    const renderTabs = () => {
        let tabs = [
            <DetailsSummary
                key="Details"
                heading={trans("Details")}
                event={event}
                settings={settings}
            />,
        ];
        if (
            settings.excludedOptionalSections &&
            !settings.excludedOptionalSections.includes("overview")
        ) {
            tabs.push(
                <AttendeesSummary
                    key="Attendees"
                    heading={trans("Attendees")}
                    event={event}
                    settings={settings}
                />
            );
        }
        if (event.venue_name) {
            tabs.push(
                <VenueSummary
                    key="Venue"
                    heading={trans("Venue")}
                    event={event}
                />
            );
        }

        if (event && event.smartRef && user) {
            tabs.push(
                <Files
                    key="files"
                    heading={trans("Files")}
                    smartRef={event.smartRef}
                    user={user}
                    showHeading={true}
                />
            );
        }
        if (event && user) {
            tabs.push(
                <div heading={trans("Comments")} key="comments">
                    <Row>
                        <Column medium={12}>
                            <Comments smartRef={event.smartRef} user={user} />
                        </Column>
                    </Row>
                </div>
            );
        }
        return tabs;
    };

    if (!settings || !event || eventLoading || settingsLoading) {
        return <Loading />;
    }

    return (
        <EventDetails>
            <Tabordion type="tabs" unmounts={false} current={0}>
                {renderTabs()}
            </Tabordion>
            {!showingInSummary && (
                <h3 style={{ marginTop: "20px" }}>
                    {trans("Budget and costings")}
                </h3>
            )}
            <ConditionalWrapper
                condition={!showingInSummary}
                wrapper={(children) => <Panel>{children}</Panel>}
            >
                <BudgetSummary
                    event={event}
                    settings={settings}
                    showingInSummary={showingInSummary}
                />
            </ConditionalWrapper>{" "}
        </EventDetails>
    );
};

export default EventSummaryDetails;
