import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import { ShareComposer } from "@cortexglobal/communication";

const BulkShare = ({ selectedAssets, onCancel }) => {
    const intl = useIntl();

    const [urls, setUrls] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        axios
            .get(`/api/v1/assets`, {
                params: { uuids: selectedAssets, per_page: 10000 },
            })
            .then(({ data }) => {
                setUrls(
                    data.data.map((asset) => {
                        return {
                            title: asset.name,
                            url: `${window.origin}/resources/view/${asset.uuid}`,
                        };
                    })
                );
                setLoaded(true);
            });
    }, [selectedAssets]);

    return (
        <>
            <Row>
                <Column>
                    <h2>{trans("Share Assets")}</h2>
                </Column>
            </Row>
            {loaded ? (
                <ShareComposer
                    urls={urls}
                    onClose={onCancel}
                    title={trans("Share Assets")}
                    subject={intl.formatMessage({
                        id: "Assets shared with you",
                    })}
                    prependedMessage={intl.formatMessage({
                        id: "Please view these assets:",
                    })}
                />
            ) : (
                <Row>
                    <Column>
                        <Loading />
                    </Column>
                </Row>
            )}
        </>
    );
};

export default BulkShare;
