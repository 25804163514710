import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import RoleForm, { Role } from "../../forms/RoleForm";

export const RoleCreate = ({ path }) => {
    const history = useHistory();
    //parent_id will be set if it's in the URL otherwise it's null which is what we want
    const parent_id = useQueryString().get("parent_id");

    const item: Role = {
        team_id: null,
        name: "",
        type: null,
        parent_role_id: null,
        permissions: [],
        can_send_communication_to: 1,
        dashboard_panels: [],
    };

    const submitForm = (values) => {
        return axios.post(`/api/v1/admin/role`, values).then(({ data }) => {
            if (parent_id !== null) {
                history.goBack();
            }
            //As creation was successful move them on to the appropriate listing page
            history.push(`${path}`);
        });
    };
    return (
        <div>
            <PageTitle title={<>{trans("New Role")}</>}>
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <RoleForm item={item} submitForm={submitForm} />
                </Panel>
            </Row>
        </div>
    );
};

export default RoleCreate;
