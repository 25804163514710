import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { isJSON } from "@cortexglobal/cortex-utilities";

const EditorContainer = styled.div``;

const quillGetHTML = inputDelta => {
    // hack to display Quill delta as HTML - creates a temp element, parses the delta into it then gets the output
    let temp = document.createElement("div");
    temp.setAttribute("id", "tmp-quill");
    new Quill(temp).setContents(inputDelta);
    return temp.getElementsByClassName("ql-editor")[0].innerHTML;
};

const cleanUp = () => {
    // remove temp element here?
};

const StyledOutput = styled(ReactQuill)``;

const QuillOutput = ({ value, ...props }) => {
    useEffect(() => {
        return cleanUp();
    });

    const getDelta = () => {
        if (!value) {
            return { ops: [] };
        }

        const parsedValue = isJSON(value) ? JSON.parse(value) : value;

        return parsedValue;
    };

    const getHtml = () => {
        const delta = getDelta();

        const html = quillGetHTML(delta);

        return { __html: html };
    };

    return <EditorContainer dangerouslySetInnerHTML={getHtml()} />;
};

export default QuillOutput;
