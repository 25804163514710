import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faPen,
    faEye,
    faTrash,
    faCaretUp,
    faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "@cortexglobal/cortex-utilities";
import { usePermissions, useUser } from "@cortexglobal/cortex-auth-redux";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    TableList,
    Error,
    IconTextButton,
    Loading,
    PageTitle,
    Pagination,
    Toggle,
    ButtonDropdown,
    Tooltip,
} from "@cortexglobal/rla-components";
import { NotifyModal } from "@cortexglobal/communication";

import {
    SurveyPanel,
    SurveyButtons,
} from "../components/SurveyFormStyledComponents.js";
import SurveyManagementContext from "../SurveyManagementContext";

const SortableTh = styled.th`
    cursor: pointer;
`;

const SortableTableHeading = ({
    name,
    orderBy,
    selected,
    direction,
    onClick,
    style,
}) => {
    return (
        <SortableTh
            onClick={() => {
                onClick(orderBy);
            }}
            style={style}
        >
            {name}{" "}
            {selected &&
                (direction === "asc" ? (
                    <FontAwesomeIcon icon={faCaretUp} size="lg" />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown} size="lg" />
                ))}
        </SortableTh>
    );
};

const SurveyDashboard = ({ path, loadSurvey }) => {
    const intl = useIntl();
    const history = useHistory();
    const { user } = useUser();
    const { can } = usePermissions();

    const { deleteSurvey } = useContext(SurveyManagementContext);

    const defaultError = null;

    const [notifyAboutSurvey, setNotifyAboutSurvey] = useState(null);
    const [surveys, setSurveys] = useState([]);
    const [showArchived, setShowArchived] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(defaultError);

    useEffect(() => {
        const page = surveys.meta?.current_page || 1;
        const surveysData = surveys.data;

        if (!Array.isArray(surveysData) || !surveysData.length) {
            loadSurveys(page);
        }
        //Always load in an empty survey when the page loads, to ensure fresh data
        //TODO: This should be removed when we have a better way of refreshing the data (remove a lot of this context
        //and use simple props instead)
        // loadSurvey();
    }, []);

    useEffect(() => {
        const page = surveys.meta?.current_page || 1;
        loadSurveys(page);
    }, [showArchived]);

    /**
     * Load all the surveys for the lising page
     *
     * @param {*} page
     * @param {*} perPage
     * @param {*} orderByKey
     * @param {*} orderByDirection
     */
    const loadSurveys = (page, perPage, orderByKey, orderByDirection) => {
        setLoading(true);

        const preloadedPerPage = surveys.meta?.per_page;
        const preloadedOrderBy = surveys.meta?.order_by;
        const preloadedOrderByDir = surveys.meta?.order_by_direction;

        const params = {
            page: page || 1,
            per_page: perPage || preloadedPerPage || 20,
            order_by: orderByKey || preloadedOrderBy || "id",
            order_by_direction:
                orderByDirection || preloadedOrderByDir || "desc",
            show_archived: showArchived || false,
        };

        axios
            .get(`/api/v1/surveys`, { params: params })
            .then(({ data }) => {
                setError(defaultError);
                setSurveys(data);
            })
            .catch((e) => {
                setError(e.response?.statusText);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const changeOrderBy = (orderBy) => {
        const perPage = surveys.meta?.per_page || 20;
        const direction =
            surveys.meta?.order_by_direction === "asc" ? "desc" : "asc";

        loadSurveys(1, perPage, orderBy, direction);
    };

    const getPrimaryAction = (survey) => {
        //If the survey is a draft and the user has the permission to edit drafts,
        //show the edit button as the primary action
        if (
            survey.status_alias &&
            survey.status_alias === "draft" &&
            can("surveys-manage-edit")
        ) {
            return {
                name: intl.formatMessage({ id: "Edit" }),
                onClick: () => {
                    history.push(`${path}/${survey.uuid}/edit`);
                },
            };
        }

        //If the survey is active or archived and the user has the permission to view surveys,
        //show the view button as the primary action
        if (
            survey.status_alias &&
            survey.status_alias !== "draft" &&
            can("surveys-manage-view")
        ) {
            return {
                name: intl.formatMessage({ id: "View" }),
                onClick: () => {
                    history.push(`${path}/${survey.uuid}`);
                },
            };
        }

        //Return null if the user does not have the permission to view or edit the survey,
        //or if the survey is in an unknown status.  This will hide the action button.
        return null;
    };

    const getActions = (survey) => {
        let actions = [];
        //If the survey is a active and the user has the permission to edit,
        //show the edit button as an action in the dropdown (as it's not the primary action)
        const canEditAndIsActive =
            can("surveys-manage-edit") && survey.status_alias === "active";

        const canEditAndIsFullCapacity =
            can("surveys-manage-edit") &&
            survey.status_alias === "full-capacity" &&
            survey.created_by_user.uuid === user.uuid;

        if (canEditAndIsActive || canEditAndIsFullCapacity) {
            actions.push({
                name: intl.formatMessage({ id: "Edit" }),
                onClick: () => {
                    history.push(`${path}/${survey.uuid}/edit`);
                },
                icon: faPen,
            });
        }

        if (can("surveys-manage-view") && survey.status_alias !== "draft") {
            actions.push({
                name: intl.formatMessage({ id: "Notify" }),
                onClick: () => {
                    setNotifyAboutSurvey(survey);
                },
                icon: faPen,
            });
        }

        //If the survey is a draft and the user has the permission to view,
        //show the view button as an action in the dropdown (as it's not the primary action)
        if (can("surveys-manage-view") && survey.status_alias === "draft") {
            actions.push({
                name: intl.formatMessage({ id: "View" }),
                onClick: () => {
                    history.push(`${path}/${survey.uuid}`);
                },
                // url: `${path}/${survey.uuid}`,
                icon: faEye,
            });
        }

        //If the user has the permission to create, show the clone button as an action in the dropdown
        if (can("surveys-manage-create")) {
            actions.push({
                name: intl.formatMessage({ id: "Clone" }),
                onClick: () => {
                    history.push(`${path}/create?clone=${survey.uuid}`);
                },
                icon: faPlus,
            });
        }

        //If the user has the permission to delete, show the delete button as an action in the dropdown
        if (can("surveys-manage-delete")) {
            actions.push({
                name: intl.formatMessage({ id: "Delete" }),
                onClick: () => {
                    deleteSurvey(survey.uuid);
                },
                icon: faTrash,
            });
        }

        return actions;
    };

    const renderTableContent = (surveys) => {
        if (loading) {
            return (
                <tr>
                    <td colSpan="100%">
                        <Loading />
                    </td>
                </tr>
            );
        }

        if (error) {
            return (
                <tr>
                    <td colSpan="100%">
                        <Error>{error}</Error>
                    </td>
                </tr>
            );
        }

        if (!surveys || !Array.isArray(surveys) || !surveys.length) {
            return (
                <tr>
                    <td colSpan="100%">{trans("No surveys available")}</td>
                </tr>
            );
        }

        return surveys.map((survey) => {
            const primaryAction = getPrimaryAction(survey);
            const actions = getActions(survey);

            return (
                <tr key={survey.uuid}>
                    <td style={{ textAlign: "center" }}>
                        {/* <Link to={`${path}/${survey.uuid}/edit`}> */}
                        {survey.id}
                        {/* </Link> */}
                    </td>
                    <td>{survey.type?.name}</td>
                    <td>
                        {/* <Link to={`${path}/${survey.uuid}/edit`}> */}
                        {survey.name}
                        {/* </Link> */}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {survey.publish_at &&
                            format(new Date(survey.publish_at), "Pp")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {survey.archived_at &&
                            format(new Date(survey.archived_at), "Pp")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {survey.total_finished_entries > 0 &&
                        can("surveys-manage-view") ? (
                            <Link to={`${path}/${survey.uuid}`}>
                                <Tooltip
                                    text={trans("View responses")}
                                    showIcon={false}
                                >
                                    {survey.total_finished_entries}
                                </Tooltip>
                            </Link>
                        ) : (
                            survey.total_finished_entries
                        )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {format(new Date(survey.created_at), "PP")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {survey.status || "Unknown"}
                    </td>
                    <td style={{ textAlign: "right" }}>
                        {primaryAction && (
                            <ButtonDropdown
                                onClick={primaryAction.onClick}
                                name="primary"
                                expanded={true}
                                actions={actions}
                            >
                                {primaryAction.name}
                            </ButtonDropdown>
                        )}

                        {/* {getActions(survey).map(({ url, name, icon }) => (
                                <IconTextButton
                                    as={Link}
                                    to={url}
                                    showCircle={false}
                                    icon={icon}
                                >
                                    {name || ""}
                                </IconTextButton>
                            ))}
                            {can("surveys-manage-delete") && (
                                <IconTextButton
                                    as={Link}
                                    showCircle={false}
                                    icon={faTrash}
                                    onClick={() => deleteSurvey(survey.uuid)}
                                >
                                    {trans("Delete")}
                                </IconTextButton>
                            )} */}
                    </td>
                </tr>
            );
        });
    };

    return (
        <>
            <PageTitle title={trans("Form Management")}>
                {can("surveys-manage-create") && (
                    <IconTextButton
                        as={Link}
                        to={`${path}/create`}
                        showCircle={false}
                        icon={faPlus}
                    >
                        {trans("Create a new form")}
                    </IconTextButton>
                )}
            </PageTitle>
            <Row>
                <Column medium={12}>
                    <SurveyButtons>
                        <span>{trans("Show closed surveys")}</span>
                        <Toggle
                            name="show_archived"
                            onChange={(e) => setShowArchived(!showArchived)}
                            checked={showArchived}
                            size="small"
                        />
                    </SurveyButtons>
                    <SurveyPanel padding={0}>
                        <TableList style={{ marginBottom: "0" }}>
                            <thead>
                                <tr>
                                    <SortableTableHeading
                                        name="#"
                                        orderBy="id"
                                        selected={
                                            surveys.meta?.order_by === "id"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                        style={{ textAlign: "center" }}
                                    />
                                    <th>{trans("Type")}</th>
                                    <SortableTableHeading
                                        name={trans("Title")}
                                        orderBy="name"
                                        selected={
                                            surveys.meta?.order_by === "name"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                    />
                                    <SortableTableHeading
                                        name={trans("Start Date")}
                                        orderBy="publish_at"
                                        selected={
                                            surveys.meta?.order_by ===
                                            "publish_at"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                        style={{ textAlign: "center" }}
                                    />
                                    <SortableTableHeading
                                        name={trans("End Date")}
                                        orderBy="archived_at"
                                        selected={
                                            surveys.meta?.order_by ===
                                            "archived_at"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                        style={{ textAlign: "center" }}
                                    />
                                    <SortableTableHeading
                                        name={trans("Responses")}
                                        orderBy="submitted_entries_count"
                                        selected={
                                            surveys.meta?.order_by ===
                                            "submitted_entries_count"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                        style={{ textAlign: "center" }}
                                    />
                                    <SortableTableHeading
                                        name={trans("Created")}
                                        orderBy="created_at"
                                        selected={
                                            surveys.meta?.order_by ===
                                            "created_at"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                        style={{ textAlign: "center" }}
                                    />
                                    <SortableTableHeading
                                        name={trans("Status")}
                                        orderBy="workflow_states.alias"
                                        selected={
                                            surveys.meta?.order_by ===
                                            "workflow_states.alias"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                        style={{ textAlign: "center" }}
                                    />
                                    <th style={{ textAlign: "center" }}>
                                        {trans("Actions")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{renderTableContent(surveys.data)}</tbody>
                        </TableList>
                    </SurveyPanel>
                    {!loading && surveys.meta?.current_page !== undefined && (
                        <Pagination
                            onPerPageChange={({ value }) => {
                                loadSurveys(surveys.meta.current_page, value);
                            }}
                            style={{ marginBottom: "1rem" }}
                            currentPage={surveys.meta.current_page - 1}
                            perPage={surveys.meta.per_page}
                            pageCount={surveys.meta.last_page}
                            onPageChange={({ selected }) =>
                                loadSurveys(++selected)
                            }
                            showSummary={true}
                            alwaysShow={true}
                            {...surveys.meta}
                        />
                    )}
                </Column>
            </Row>

            {notifyAboutSurvey && (
                <NotifyModal
                    title={trans("Notify users about: {surveyName}", {
                        surveyName: notifyAboutSurvey.name,
                    })}
                    visible={notifyAboutSurvey}
                    onClose={() => setNotifyAboutSurvey(null)}
                    smartRef={notifyAboutSurvey.smartRef}
                />
            )}
        </>
    );
};

export default SurveyDashboard;
