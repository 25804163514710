import React from "react";
import { trans } from "@cortexglobal/rla-intl";
import { Row, Column } from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import FulfilmentResultForm from "../forms/FulfilmentResultForm";
import { FulfilmentResult } from "./FulfilmentResult";

const OrderFulfilmentResult = ({ order, getOrder }) => {
    const { can } = usePermissions();
    if (!order.fulfilment_result && can("dam-order-add-results")) {
        return (
            <FulfilmentResultForm
                mediaType={order.type}
                assetOrderUuid={order.uuid}
                onComplete={getOrder}
            />
        );
    }

    if (!order.fulfilment_result) {
        return (
            <Row>
                <Column style={{ margin: "2rem auto", paddingBottom: "2rem" }}>
                    <h2>{trans("No Results Available")}</h2>
                    <p>
                        {trans(
                            "If this is a recent order you will need for it to be fulfilled, otherwise contact your supplier to add the results"
                        )}
                    </p>
                </Column>
            </Row>
        );
    }
    return (
        <FulfilmentResult
            result={order.fulfilment_result}
            mediaType={order.type}
        />
    );
};

export default OrderFulfilmentResult;
