import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { withRouter, Redirect } from "react-router-dom";
import { faTrash, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";

import {
    Panel,
    Row,
    Column,
    IconTextButton as CortexIconTextButton,
    Loading,
    Modal,
    Badge,
    Tabordion,
    PageTitle,
    ContentBlockLoader,
    Button,
} from "@cortexglobal/rla-components";

import { OrderDetails } from "@cortexglobal/cortex-dam";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { Comments } from "@cortexglobal/comments";
import { Files } from "@cortexglobal/files";
import { RelatedRequests } from "@cortexglobal/requests";
import { TransitionButtons } from "@cortexglobal/workflow";

import { startOrderProcess } from "../plannerActions";
import ActivityDetails from "../components/ActivityDetails";
import PlannerActivityForm from "../forms/PlannerActivityForm";
import CampaignBudgetDetails from "../components/CampaignBudgetDetails";
import OrderListing from "../components/OrderListing";

import ActivityApprovalDetails from "../components/ActivityApprovalDetails";

const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: column;
`;

const ExpandedPanel = styled(Panel)`
    flex: 1;
    overflow: auto;
    width: 100%;
`;

const IconTextButton = styled(CortexIconTextButton)`
    padding-left: 0;
`;
const ViewActivity = ({
    filters,
    config,
    viewTask,
    viewPlanner,
    deleteActivity,
    user,
    plannerPath,
    category,
    history,
    theme: plannerTheme,
    match,
}) => {
    const intl = useIntl();
    const theme = useTheme();

    const [activity, setActivity] = useState({ loaded: false, data: null });
    const [task, setTask] = useState({ loaded: false, data: null });
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        loadActivity();
        loadTask();
    }, []);

    const loadActivity = (activityUuid) => {
        //Load the activity details
        activityUuid = activityUuid || match.params.activity_uuid;

        axios
            .get(`api/v1/planner/activities/${activityUuid}`)
            .then((result) => {
                setActivity({
                    data: result.data.data,
                    loaded: true,
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    history.push(plannerPath);
                }
            });
    };

    const loadTask = () => {
        //Load the task details
        const taskUuid = match.params.task_uuid;
        axios
            .get(`api/v1/planner/tasks/${taskUuid}`)
            .then((result) => {
                return setTask({
                    data: result.data.data,
                    loaded: true,
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    history.push(plannerPath);
                }
            });
    };

    const addAnOrder = () => {
        // dispatch(
        //     startOrderProcess({
        //         task: task.data,
        //         activity: activity.data,
        //     })
        // );
        history.push(
            `/resources?filters={"media-channel":["${activity.data.media_channel}"],"features":["orderable"]}&hideFilterSummary=true&activityUuid=${activity.data.uuid}&hideHeader=true`
        );
    };

    const renderDetailsOrEdit = () => {
        // console.log(config, task);
        if (!activity.loaded || !config || !task.loaded) {
            return (
                <div style={{ paddingTop: "1.2rem" }}>
                    <Loading />
                </div>
            );
        }

        if (editing) {
            return (
                <div style={{ paddingTop: "1.2rem" }}>
                    <PlannerActivityForm
                        activity={activity.data}
                        user={user}
                        task={task.data}
                        filters={filters}
                        category={category}
                        action="editActivity"
                        onGoBack={(category, task, activity) => {
                            if (activity) {
                                return stopEditing(activity);
                            }
                            return stopEditing();
                        }}
                    />
                </div>
            );
        } else {
            return (
                <Tabordion unmounts={false} current={0}>
                    <ActivityDetails
                        heading={<span>{trans("Info")}</span>}
                        filters={filters}
                        activity={activity.data}
                        task={task.data}
                        user={user}
                    />
                    {(config.data.options
                        ? config.data.options.enable_requests
                        : false) && (
                        <RelatedRequests
                            heading={
                                <span>
                                    {trans("Requests")}{" "}
                                    <Badge small={true}>
                                        {activity.data.total_requests}
                                    </Badge>
                                </span>
                            }
                            smartRef={activity.data.smart_ref}
                            canAdmin={task.can_admin_details}
                            requestPath="/requests"
                        />
                    )}
                    <Files
                        smartRef={activity.data.smart_ref}
                        user={user}
                        showHeading={false}
                        canAdmin={task.can_admin_details}
                        heading={
                            <span>
                                {trans("Files")}{" "}
                                <Badge small={true}>
                                    {activity.data.total_files}
                                </Badge>
                            </span>
                        }
                        exclude={["evidence"]}
                        callback={reloadWorkflow}
                    />
                    {/* {activity.data.fulfilment_type === "platform" && ( */}
                    <OrderListing
                        activity={activity.data}
                        task={task.data}
                        user={user}
                        addAnOrder={addAnOrder}
                        heading={
                            <span>
                                {trans("Order")}{" "}
                                <Badge small={true}>
                                    {activity.data.total_orders}
                                </Badge>
                            </span>
                        }
                    />
                    {/* )} */}
                    {/* <ActivityFulfilmentResult
                        activity={activity.data}
                        task={task.data}
                        user={user}
                        heading={<span>{trans("Results")}</span>}
                        loadActivity={loadActivity}
                    /> */}

                    {/** DON'T DELETE -  NEEDED IF WE'RE USING THE ACTIVITY APPROVAL WORKFLOW */}
                    {false && activity.data.fulfilment_type === "external" && (
                        <Files
                            smartRef={activity.data.smart_ref}
                            user={user}
                            showHeading={false}
                            canAdmin={task.can_admin_details}
                            heading={
                                <span>
                                    {trans("Evidence")}{" "}
                                    <Badge small={true}>
                                        {activity.data.total_evidence}
                                    </Badge>
                                </span>
                            }
                            singleType={{
                                key: "evidence",
                                name: "Evidence",
                            }}
                            include={["evidence"]}
                            callback={reloadWorkflow}
                        />
                    )}

                    <Comments
                        heading={
                            <span>
                                {trans("Discussion")}{" "}
                                <Badge small={true}>
                                    {activity.data.total_comments}
                                </Badge>
                            </span>
                        }
                        smartRef={activity.data.smart_ref}
                        user={user}
                        alwaysShowForm={false}
                    />
                </Tabordion>
            );
        }
    };

    const stopEditing = (activity) => {
        if (activity) {
            setActivity({ data: activity, loaded: true });
        }
        setEditing(false);
    };

    const deleteThisActivity = () => {
        deleteActivity(activity.data.uuid, task.data.uuid).then(() => {
            viewTask(task.data, category);
        });
    };

    const workflowUpdatedCallback = (smartRef, newWorkflow) => {
        setActivity({
            ...activity,
            data: { ...activity.data, workflow: { ...newWorkflow } },
        });
    };

    const reloadWorkflow = (smartRef) => {
        axios.get(`api/v1/workflow/${smartRef}`).then((result) => {
            const newWorkflow = result.data.data;

            workflowUpdatedCallback(smartRef, newWorkflow);
        });
    };
    // console.log("task", task);
    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {process.env.REACT_APP_NAME} -{" "}
                    {intl.formatMessage({ id: "Marketing Planner" })} -{" "}
                    {activity.loaded ? activity.data.name : ""}
                    {/* {dates[0] && dates[0].format("MMMM YYYY")} */}
                    {/* {startDate.format("ll")}-{endDate.format("ll")} */}
                </title>
            </Helmet>
            <PageTitle
                title={
                    activity.loaded ? (
                        <React.Fragment>
                            <Badge
                                type="secondary"
                                style={{ margin: "0.2rem 0.5rem 0 0" }}
                            >
                                {activity.data.ref}
                            </Badge>{" "}
                            {activity.data.name}
                        </React.Fragment>
                    ) : (
                        ""
                    )
                }
            >
                <div>
                    {activity.loaded && (
                        <Badge>
                            {activity.data.status_wording || trans("Unknown")}
                        </Badge>
                    )}

                    <IconTextButton
                        onClick={() => viewTask(task.data, category)}
                        rotation={180}
                        showCircle={false}
                    >
                        {trans("Back to Campaign")}
                    </IconTextButton>

                    <IconTextButton
                        onClick={() => viewPlanner()}
                        rotation={180}
                        showCircle={false}
                    >
                        {trans("Back to Planner")}
                    </IconTextButton>
                </div>
            </PageTitle>

            {activity.loaded && activity.data.status === "draft" && (
                <Row>
                    <Column>
                        <p
                            style={{
                                background: theme.colors.warning,
                                color: "white",
                                padding: "15px 20px",
                            }}
                        >
                            {trans(
                                "Please note: An activity in Draft status will not affect any processes or data."
                            )}
                        </p>
                    </Column>
                </Row>
            )}

            <Row>
                <FlexColumn large={9}>
                    <h3>{trans("Activity Details")}</h3>
                    <ExpandedPanel
                        style={{ paddingTop: 0, paddingBottom: "0" }}
                    >
                        {renderDetailsOrEdit()}
                    </ExpandedPanel>
                </FlexColumn>

                <FlexColumn large={3}>
                    <h3>{trans("Available Actions")}</h3>
                    <ExpandedPanel>
                        {activity.loaded && task.loaded && (
                            <Column>
                                {task.data.can_admin_details ? (
                                    <>
                                        <IconTextButton
                                            style={{ paddingLeft: "1rem" }}
                                            icon={faEdit}
                                            expanded
                                            // themeColor="alert"
                                            onClick={() => setEditing(true)}
                                            disabled={
                                                !activity.loaded ||
                                                !activity.data.uuid
                                            }
                                            showCircle={false}
                                        >
                                            {trans("Edit Activity")}
                                        </IconTextButton>
                                        <IconTextButton
                                            style={{ paddingLeft: "1rem" }}
                                            icon={faTrash}
                                            expanded
                                            themeColor="alert"
                                            onClick={() => setDeleting(true)}
                                            disabled={
                                                !activity.loaded ||
                                                !activity.data.uuid
                                            }
                                            showCircle={false}
                                        >
                                            {trans("Delete Activity")}
                                        </IconTextButton>
                                    </>
                                ) : (
                                    <p>{trans("No actions available.")}</p>
                                )}
                            </Column>
                        )}
                    </ExpandedPanel>
                    {task.loaded && !task.data.owner && task.data.group && (
                        <React.Fragment>
                            <h3>{trans("Campaign Owner")}</h3>
                            <Panel>
                                <Row>
                                    <Column>{task.data.group.name}</Column>
                                </Row>
                            </Panel>
                        </React.Fragment>
                    )}

                    <h3>{trans("Approval Status")}</h3>
                    <Panel style={{ padding: "1rem" }}>
                        <Row style={{ padding: "0" }}>
                            <Column style={{ padding: "0" }}>
                                <ActivityApprovalDetails
                                    task={task.data}
                                    activity={activity.data}
                                    onUpdate={(activity) =>
                                        loadActivity(activity.uuid)
                                    }
                                />
                            </Column>
                        </Row>
                    </Panel>

                    <h3>{trans("Campaign")}</h3>
                    <Panel style={{ padding: "1rem" }}>
                        <Row style={{ padding: "0" }}>
                            <Column style={{ padding: "0" }}>
                                {activity.loaded && task.loaded && (
                                    <CampaignBudgetDetails
                                        activity={activity.data}
                                        task={task.data}
                                        theme={plannerTheme}
                                        viewTask={viewTask}
                                        category={category}
                                        user={user}
                                    />
                                )}
                            </Column>
                        </Row>
                    </Panel>
                </FlexColumn>
            </Row>

            {task.loaded && task.data.can_admin_details && (
                <Modal visible={deleting} onClose={() => setDeleting(false)}>
                    <Row>
                        <Column>
                            <h2>
                                {trans(
                                    "Are you sure you want to delete this activity?"
                                )}
                            </h2>
                        </Column>
                        <Column large={6}>
                            <IconTextButton
                                expanded
                                onClick={() => setDeleting(false)}
                            >
                                {trans("Cancel")}
                            </IconTextButton>
                        </Column>
                        <Column large={6}>
                            {activity.loaded && (
                                <IconTextButton
                                    icon={faTimes}
                                    themeColor="alert"
                                    expanded
                                    onClick={deleteThisActivity}
                                >
                                    {trans("Delete")}
                                </IconTextButton>
                            )}
                        </Column>
                    </Row>
                </Modal>
            )}
        </React.Fragment>
    );
};

ViewActivity.displayName = "ViewActivity";

ViewActivity.propTypes = {
    task: PropTypes.shape({
        name: PropTypes.string,
    }),
    activity: PropTypes.shape({
        name: PropTypes.string,
    }),
    filters: PropTypes.array,
    user: PropTypes.object,
    viewPlanner: PropTypes.func,
};

ViewActivity.defaultProps = {};

export default withRouter(ViewActivity);
