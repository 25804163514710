import React, { useState, useEffect } from "react";
import axios from "axios";
import { StyledSelectAsync } from "@cortexglobal/rla-components";
import FormLabel from "../styledElements/label";
import { components } from "react-select";

const Option = props => {
    return (
        <components.Option {...props}>
            <div>{props.data.label}</div>
            {props.data.sub_label && (
                <div style={{ fontSize: 12 }} dangerouslySetInnerHTML={{__html: props.data.sub_label}}></div>
            )}
        </components.Option>
    );
};

const TypeAhead = ({
    onChange,
    name = "user",
    value = [],
    label,
    url,
    labelWidth,
    labelAlign,
    inlineLabel,
    labelStyle,
    placeholder,
    multi = false,
    ...rest
}) => {
    const loadOptions = (query, loadedOptions, { page = 1 }) => {
        return axios
            .get(url, {
                params: { query, page },
            })
            .then(({ data }) => {
                return {
                    options: data.data,
                    additional: {
                        page: page + 1,
                    },
                };
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    const onTypeAheadChange = (value) => {
        if (multi) {
            onChange({ name, value: value });
        } else {
            onChange({ name, value: [value] });
        }
    };

    return (
        <div>
            {label && (
                <FormLabel
                    name={name}
                    width={labelWidth}
                    align={labelAlign}
                    inlineLabel={inlineLabel}
                    style={labelStyle}
                >
                    {label}
                </FormLabel>
            )}

        <StyledSelectAsync
            name={name}
            value={value}
            onChange={onTypeAheadChange}
            placeholder={placeholder}
            components={{Option}}
            maxMenuHeight={250}
            loadOptions={loadOptions}
            additional={{ page: 1 }}
            multi={multi}
        />
    </div>
    );
};

export default TypeAhead;
