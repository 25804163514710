import React, { useState, useEffect } from "react";
import { get } from "lodash";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    Button,
    SubmitButton,
    InputField,
    Select,
    FormRow,
    SelectField,
} from "@cortexglobal/rla-components";

import RoleSelect from "./RoleSelect";
import GroupSelect from "./GroupSelect";

const EditUserForm = (props) => {
    const { onCancel, errors, user, loadingStatus = "loaded" } = props;
    const [languages, setLanguages] = useState({ loaded: false, data: [] });

    const [params, setParams] = useState({});

    useEffect(() => {
        axios.get(`/api/v1/language`).then(({ data }) =>
            setLanguages({
                loaded: true,
                data: data.data.map((language) => {
                    return { text: language.name, value: language.iso_code };
                }),
            })
        );
    }, []);

    useEffect(() => {
        setParams({
            first_name: get(props, "user.first_name", ""),
            last_name: get(props, "user.last_name", ""),
            email: get(props, "user.email", ""),
            primary_role_uuid: get(props, "user.primary_role.uuid", ""),
            primary_group_uuid: get(props, "user.primary_group.uuid", ""),
            language: get(props, "user.language", ""),
        });
    }, [props.user]);

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
    };

    const onSubmit = () => {
        props.onSubmit(user, params);
    };

    return (
        <>
            <FormRow
                name="first_name"
                label={trans("First Name")}
                error={errors.first_name}
                helpText={trans("Required")}
            >
                <InputField
                    name="first_name"
                    value={get(params, "first_name", "")}
                    onChange={(e) => onChange(e.name, e.value)}
                    showError={false}
                />
            </FormRow>

            <FormRow
                name="last_name"
                label={trans("Last Name")}
                error={errors.last_name}
                helpText={trans("Required")}
            >
                <InputField
                    name="last_name"
                    value={get(params, "last_name", "")}
                    onChange={(e) => onChange(e.name, e.value)}
                    showError={false}
                />
            </FormRow>
            <FormRow
                name="email"
                label={trans("Email")}
                error={errors.email}
                helpText={trans("Required")}
            >
                <InputField
                    name="email"
                    value={get(params, "email", "")}
                    onChange={(e) => onChange(e.name, e.value)}
                    showError={false}
                />
            </FormRow>

            <FormRow
                name="primary_role_uuid"
                label={trans("Role")}
                error={errors.primary_role_uuid}
                helpText={trans("Required")}
            >
                <RoleSelect
                    name="primary_role_uuid"
                    value={get(params, "primary_role_uuid", "")}
                    onChange={(e) => {
                        const { value } = e;
                        onChange("primary_role_uuid", value);
                    }}
                    showError={false}
                />
            </FormRow>

            <FormRow
                name="primary_group_uuid"
                label={trans("Group")}
                error={errors.primary_group_uuid}
                helpText={trans("Required")}
            >
                <GroupSelect
                    name="primary_group_uuid"
                    value={get(params, "primary_group_uuid", "")}
                    onChange={(e) => {
                        const { value } = e;
                        onChange("primary_group_uuid", value);
                    }}
                    showError={false}
                />
            </FormRow>

            <FormRow
                name="language"
                label={trans("Language")}
                error={errors.language}
                helpText={trans("Required")}
            >
                <SelectField
                    name="language"
                    value={get(params, "language", "")}
                    options={languages.data}
                    onChange={(e) => {
                        const { value } = e;
                        onChange("language", value);
                    }}
                    showError={false}
                />
            </FormRow>

            <Row collapse>
                <Column medium={6}>
                    {onCancel !== undefined && typeof onCancel === "function" && (
                        <Button
                            onClick={() => onCancel()}
                            disabled={loadingStatus == "loading"}
                            type="alert"
                        >
                            {trans("Cancel")}
                        </Button>
                    )}
                </Column>
                <Column medium={6} flex style={{ justifyContent: "flex-end" }}>
                    <SubmitButton
                        onClick={() => onSubmit()}
                        disabled={loadingStatus == "loading"}
                        submitting={loadingStatus === "loading"}
                    >
                        {trans("Save")}
                    </SubmitButton>
                </Column>
            </Row>
        </>
    );
};

export default EditUserForm;
