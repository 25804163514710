import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import ProductForm, { Product } from "../../forms/ProductForm";
export const ProductCreate = ({ path }) => {
    const history = useHistory();

    const item: Product = {
        name: "",
        external_identifier: "",
        description: "",
        thumbnail: null,
        // order: 0,
        locale: "",
        category_id: "",
    };

    const submitForm = (values) => {
        return axios.post(`/api/v1/admin/product`, values).then(({ data }) => {
            //As creation was successful move them on to the appropriate listing page
            history.push(`${path}`);

            return data;
        });
    };
    return (
        <div>
            <PageTitle
                title={<React.Fragment>{trans("New Product")} </React.Fragment>}
            >
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <ProductForm item={item} submitForm={submitForm} />
                </Panel>
            </Row>
        </div>
    );
};

export default ProductCreate;
