import React, { useCallback } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { StyledDropzone } from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileUploader = (props) => {
    const {
        loadingCallback = () => console.log("loading"),
        loadedCallback = () => console.log("loaded"),
        successCallback = (data) => console.log("loaded", data),
        errorCallback = (e) => console.log("upload error:", e),
    } = props;

    const onDrop = useCallback((files) => {
        loadingCallback();

        const fd = new FormData();
        fd.append("file", files[0]);

        axios
            .post(`/api/v1/data-imports`, fd)
            .then(({ data: { data } }) => {
                successCallback(data);
            })
            .catch((e) => {
                errorCallback(e);
            })
            .finally(() => {
                loadedCallback();
            });
    }, []);

    return (
        <StyledDropzone
            name="upload"
            onDrop={(files) => onDrop(files)}
            style={{ textAlign: "center" }}
        >
            <div
                style={{
                    fontSize: "3em",
                    margin: "20px",
                }}
            >
                <FontAwesomeIcon icon="upload" />
            </div>
            <p>{trans("Drop a file here to upload.")}</p>
        </StyledDropzone>
    );
};

export default FileUploader;
