import React, { Component } from "react";
import withPanelData from "../withPanelData";
import { Centred, GreedyCarousel, TableList } from "@cortexglobal/rla-components";
import { VictoryChart, VictoryBar, VictoryLabel, VictoryAxis } from "victory";
import { primary } from "../../../config/charts";

class TopPerformersOutput extends Component {
    chartData(data) {
        var result = [];
        for (var i = data.length - 1; i >= 0; i--) {
            var item = {};
            item.x = data[i].name;
            item.y = data[i].stat1;
            result.push(item);
        }
        return result;
    }
    render() {
        const dataSorted = this.props.data.sort((a, b) => {
            if (a.stat1 < b.stat1) {
                return 1;
            }
            if (a.stat1 > b.stat1) {
                return -1;
            }
            return 0;
        });
        const data = this.chartData(dataSorted);

        const { height, layout } = this.props;
        return (
            <GreedyCarousel layout={layout}>
                <Centred style={{ width: "100%", maxWidth: height * 1.7 }}>
                    <VictoryChart
                        height={260}
                        padding={{
                            top: 34,
                            left: 230,
                            right: 40,
                            bottom: 46
                        }}
                    >
                        <VictoryLabel
                            y={5}
                            x={225}
                            style={primary.title}
                            textAnchor="middle"
                            text={this.props.customTitle}
                        />
                        <VictoryBar
                            data={data}
                            barRatio={1}
                            style={primary}
                            horizontal={true}
                            cornerRadius={primary.radius}
                            sort="ascending"
                            animate={{
                                duration: 2000,
                                onLoad: { duration: 1000 }
                            }}
                        />
                        <VictoryAxis
                            offsetX={220}
                            style={primary}
                            dependentAxis
                        />
                    </VictoryChart>
                </Centred>
                <div style={{ paddingTop: "3rem" }}>
                    <TableList>
                        <thead>
                            <th style={{ textAlign: "left" }}>Name</th>
                            <th style={{ textAlign: "left" }}>Sales</th>
                        </thead>
                        <tbody
                            style={{
                                fontSize: "16px",
                                fontFamily:
                                    '"Gill Sans", "Gill Sans MT", Ser­avek, "Trebuchet MS", sans-serif'
                            }}
                        >
                            {dataSorted.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{item.name}</td>

                                        <td>{item.stat1}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </TableList>
                </div>
            </GreedyCarousel>
        );
    }
}

// Broken - commented out for demo
export default withPanelData(
    TopPerformersOutput,
    "/api/v1/performance/top-list",
    ["title", "metric", "total"]
);

// export default withPanelData(
//     TopPerformersOutput,
//     "/api/v1/performance/top/sales",
//     ["title", "metric", "total"]
// );
