import React from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { isJSON } from "@cortexglobal/cortex-utilities";

const EditorContainer = styled.div`
    .ql-container {
        height: 150px;
    }
`;

const StyledEditor = styled(ReactQuill)`
    border: 1px solid
        ${({ theme, error }) =>
            error ? theme.colors.alert : "rgba(0, 0, 0, 0)"};
    border-radius: ${({ theme }) => theme.input.radius};
`;

const QuillField = ({ name, value, onChange, ...props }) => {
    const onEditorChange = (content, delta, source, editor) => {
        if (!onChange || source !== "user") {
            return;
        }

        const newValue = editor.getContents();

        onChange(name, newValue);
    };

    const getValue = () => {
        if (!value) {
            return { ops: [] };
        }

        const parsedValue = isJSON(value) ? JSON.parse(value) : value;

        return parsedValue;
    };

    const formats = props.formats || [
        "header",
        "bold",
        "italic",
        "underline",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "horizontal-",
    ];

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            [
                "bold",
                "italic",
                "underline",
                { list: "ordered" },
                { list: "bullet" },
                "link",
                "image",
                "clean",
            ],
        ],
    };

    return (
        <EditorContainer>
            <StyledEditor
                value={getValue()}
                onChange={onEditorChange}
                modules={modules}
                formats={formats}
                {...props}
            />
            <span
                style={{
                    fontSize: "0.7rem",
                    marginTop: "0.5rem",
                    display: "block",
                    color: "#DB3724",
                    textAlign: "right",
                }}
            >
                {props.error}
            </span>
        </EditorContainer>
    );
};

export default QuillField;
