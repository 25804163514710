import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import styled, { useTheme } from "styled-components";
import { useParams, useHistory, Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    Loading,
    Row,
    Column,
    Badge,
    PageTitle,
    Button,
    IconTextButton,
    Modal,
    useAddToast,
    Panel,
} from "@cortexglobal/rla-components";
import { removeDomainFromUrl } from "@cortexglobal/cortex-utilities";
import { TransitionButtons } from "@cortexglobal/workflow";

import AddTasksToPlan from "../components/AddTasksToPlan";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import PlanDetailSummary from "../components/PlanDetailSummary";
// import TaskView from "./TaskView";

const SummaryColumn = styled(Column)`
    margin-bottom: 1rem;
    > div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        height: 100%;
        /* align-items: center; */
        padding: 1rem;
        /* background: ${({ theme }) => theme.colors.gray200}; */

        p {
            margin-bottom: 0;
            font-weight: bold;
        }
        h3 {
            margin-bottom: 0;
            font-weight: normal;
        }
    }
`;

const RequestHeading = styled.h2`
    margin-bottom: 0.6rem;
    font-weight: bold;
    font-size: 1.3rem;
`;

const PlanDetails = ({
    match,
    user,
    group,
    fullPage = true,
    planUuid,
    planProp = null,
}) => {
    const queryClient = useQueryClient();

    // const [plan, setPlan] = useState(null);
    // const [planLoaded, setPlanLoaded] = useState(false);
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    let { plan_uuid } = useParams();
    const history = useHistory();
    const addToast = useAddToast();
    const theme = useTheme();
    const { can } = usePermissions();
    //If match is unavailable use a hardcoded url
    //TODO - We ought to use a more dynamic way of getting the task view url, but it's pretty standard for now
    const taskViewUrl = match ? `${match.url}/task` : "/plan/task";
    const myPlansUrl = match ? `${match.url}/my-plans` : "/plan/my-plans";

    //When viewing a plan summary, the planUuid is passed in as a prop
    if (!plan_uuid || !fullPage) {
        plan_uuid = planUuid;
    }

    const {
        isLoading,
        error,
        data: plan,
    } = useQuery({
        queryKey: ["plan", plan_uuid],
        queryFn: () =>
            axios
                .get("/api/v1/planner/plan/my-plans/" + plan_uuid)
                .then(({ data }) => data.data),
    });

    const refreshPlan = () => {
        queryClient.invalidateQueries(["plan", plan_uuid]);
    };

    const removeTask = (task) => {
        //Remove the task from the plan
        axios
            .delete(
                `/api/v1/planner/plan/my-plans/${plan.uuid}/task/${task.uuid}`
            )
            .then(() => {
                refreshPlan();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return (
            <p>
                {trans("This plan could not be loaded, please try again later")}
            </p>
        );
    }

    //TODO Import the group from the auth context (as the user doesn't have the group in it)
    const ownedByUserGroup = group && plan.group.uuid === group.uuid;
    const hasApprovalRequest = plan.approval_request;

    const allowEdits =
        fullPage &&
        ownedByUserGroup &&
        (!hasApprovalRequest ||
            (hasApprovalRequest &&
                plan?.approval_request?.workflow?.allow_edits));

    return (
        <>
            <PageTitle
                title={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <h3 style={{ margin: 0 }}>{plan.name}</h3>
                    </div>
                }
            >
                <Badge
                    style={{ margin: "0.5rem 1rem" }}
                    type={plan.status === "Approved" ? "success" : "default"}
                >
                    {plan.status}
                </Badge>
                {can("planner-submit-plans") && allowEdits && (
                    <IconTextButton
                        type="alert"
                        onClick={() => {
                            setShowAddTaskModal(true);
                        }}
                        icon={faPlus}
                        showCircle={false}
                    >
                        {trans("Add Campaign")}
                    </IconTextButton>
                )}

                <IconTextButton
                    as={Link}
                    to={myPlansUrl}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to Plans")}
                </IconTextButton>
            </PageTitle>{" "}
            <Row collapse equaliseChildHeight={true}>
                <SummaryColumn medium={6}>
                    <Panel>
                        <RequestHeading>
                            {trans("Approval Request")}
                        </RequestHeading>
                        {plan.approval_request ? (
                            <Row expanded collapse>
                                <Column large={6} collapse>
                                    <p>{plan.approval_status}</p>
                                    {!plan?.approval_request?.workflow
                                        ?.isComplete && (
                                        <p>
                                            {trans("Assigned To")}
                                            {": "}
                                            {
                                                plan.approval_request.workflow
                                                    .assigned_to
                                            }
                                        </p>
                                    )}
                                </Column>
                                <Column large={6}>
                                    <TransitionButtons
                                        item={plan.approval_request}
                                        callback={() => refreshPlan()}
                                        errorCallback={(smartRef, error) => {
                                            addToast({
                                                type: "alert",
                                                content:
                                                    error.response.data.message,
                                                showFor: 5000,
                                            });
                                        }}
                                    >
                                        <IconTextButton
                                            expanded
                                            onClick={() => {
                                                history.push(
                                                    removeDomainFromUrl(
                                                        plan.approval_request
                                                            .canonical_link
                                                    )
                                                );
                                            }}
                                        >
                                            {trans("View")}{" "}
                                            {plan.approval_request.title}
                                        </IconTextButton>
                                    </TransitionButtons>
                                </Column>
                            </Row>
                        ) : ownedByUserGroup && can("planner-submit-plans") ? (
                            <Button
                                onClick={() => {
                                    //Submit an approval request to the server
                                    axios
                                        .post(
                                            `/api/v1/planner/plan/${plan.uuid}/approval`
                                        )
                                        .then(({ data }) => {
                                            refreshPlan();
                                        })
                                        .catch((e) => {
                                            addToast({
                                                type: "alert",
                                                content:
                                                    e?.response?.data
                                                        ?.message ||
                                                    trans(
                                                        "Error creating approval request"
                                                    ),
                                                showFor: 5000,
                                            });
                                        });
                                }}
                            >
                                {trans("Create Approval Request")}
                            </Button>
                        ) : (
                            <p>
                                {trans(
                                    "Requests can only be submitted by the group owner"
                                )}
                            </p>
                        )}
                    </Panel>
                </SummaryColumn>
                <SummaryColumn medium={6}>
                    <Panel>
                        <RequestHeading>
                            {trans("Results Request")}
                        </RequestHeading>
                        {plan?.is_approved ? (
                            <>
                                {plan.results_request ? (
                                    <Row expanded collapse>
                                        <Column large={6} collapse>
                                            <p>{plan.results_status}</p>
                                            {!plan?.results_request?.workflow
                                                ?.isComplete && (
                                                <p>
                                                    {trans("Assigned To")}
                                                    {": "}
                                                    {
                                                        plan.results_request
                                                            .workflow
                                                            .assigned_to
                                                    }
                                                </p>
                                            )}
                                        </Column>
                                        <Column large={6}>
                                            <TransitionButtons
                                                item={plan.results_request}
                                                callback={() => refreshPlan()}
                                                errorCallback={(
                                                    smartRef,
                                                    error
                                                ) => {
                                                    addToast({
                                                        type: "alert",
                                                        content:
                                                            error.response.data
                                                                .message,
                                                        showFor: 5000,
                                                    });
                                                }}
                                            >
                                                <IconTextButton
                                                    expanded
                                                    onClick={() => {
                                                        history.push(
                                                            removeDomainFromUrl(
                                                                plan
                                                                    .results_request
                                                                    .canonical_link
                                                            )
                                                        );
                                                    }}
                                                >
                                                    {trans("View")}{" "}
                                                    {plan.results_request.title}
                                                </IconTextButton>
                                            </TransitionButtons>
                                        </Column>
                                    </Row>
                                ) : ownedByUserGroup &&
                                  can("planner-submit-plans") ? (
                                    <Button
                                        onClick={() => {
                                            //Submit an results request to the server
                                            axios
                                                .post(
                                                    `/api/v1/planner/plan/${plan.uuid}/results`
                                                )
                                                .then(({ data }) => {
                                                    refreshPlan();
                                                })
                                                .catch((e) => {
                                                    addToast({
                                                        type: "alert",
                                                        content:
                                                            e?.response?.data
                                                                ?.message ||
                                                            trans(
                                                                "Error creating results request"
                                                            ),
                                                        showFor: 5000,
                                                    });
                                                });
                                        }}
                                    >
                                        {trans("Create Results Request")}
                                    </Button>
                                ) : (
                                    <p>
                                        {trans(
                                            "Requests can only be submitted by the group owner"
                                        )}
                                    </p>
                                )}
                            </>
                        ) : (
                            <p>
                                {trans(
                                    "Results can be submitted once the plan is approved"
                                )}
                            </p>
                        )}
                    </Panel>
                </SummaryColumn>
            </Row>
            <PlanDetailSummary
                plan={plan}
                user={user}
                theme={theme}
                taskViewUrl={taskViewUrl}
                allowEdits={allowEdits}
                removeTask={removeTask}
            />
            {showAddTaskModal && (
                <Modal
                    visible={showAddTaskModal}
                    onClose={() => setShowAddTaskModal(false)}
                >
                    <AddTasksToPlan
                        plan={plan}
                        onClose={() => setShowAddTaskModal(false)}
                        onTasksAdded={() => {
                            setShowAddTaskModal(false);
                            refreshPlan();
                        }}
                    />
                </Modal>
            )}
        </>
    );
};

export default PlanDetails;
