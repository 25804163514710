import React, { useEffect, useState } from "react";

import { Column, Row } from "@cortexglobal/rla-components";
export const PlannerAssetOrderForm = ({ order }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        console.log("options", options);
    });

    return (
        <Row>
            <h3>Planner summary and linking</h3>
        </Row>
    );
};
