import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { isBrowser } from "@cortexglobal/cortex-utilities";

const DatePickerWrapper = styled.div`
    z-index: 10000;

    .react-datepicker {
        border-radius: ${({ theme }) => theme.input.radius};
    }
    .react-datepicker__day--selected {
        background-color: ${(props) => props.theme.colors.accent};
    }
    .showInFront {
        z-index: 1000;
    }
`;
class CalendarContainer extends Component {
    constructor(props) {
        super(props);
        if (isBrowser()) {
            this.el = document.createElement("div");
        }
    }

    componentDidMount() {
        // Lock body scroll if is visible
        if (isBrowser()) {
            try {
                const bodyElement = document.getElementsByTagName("BODY")[0];
                bodyElement.appendChild(this.el);
            } catch (err) {}
        }
    }

    componentWillUnmount() {
        // Lock body scroll if is visible
        if (isBrowser()) {
            try {
                const bodyElement = document.getElementsByTagName("BODY")[0];

                bodyElement.removeChild(this.el);
            } catch (err) {}
        }
    }

    renderCalendar = () => {
        return <DatePickerWrapper>{this.props.children}</DatePickerWrapper>;
    };

    render = () => {
        if (isBrowser()) {
            return ReactDOM.createPortal(this.renderCalendar(), this.el);
        } else {
            return null;
        }
    };
}

export default CalendarContainer;
