import React from "react";
const AssetContext = React.createContext({
    asset: false,
    filters: false,
    searchableMeta: false,
    canEdit: false,
    assetPath: "",
    customForm: false,
    assetForEditing: false
});
export default AssetContext;
