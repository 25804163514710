import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

class NewsListConfiguration extends Component {
    constructor(props) {
        super(props);
    }

    submitForm = (event) => {
        event.preventDefault();
        this.props.submitPanel(this.props.panel, this.state);
    };

    render() {
        return (
            <form onSubmit={this.submitForm}>
                <Row>
                    <Column>
                        <Button type="submit">{trans("Save Panel")}</Button>
                    </Column>
                </Row>
            </form>
        );
    }
}

NewsListConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default NewsListConfiguration;
