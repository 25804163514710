import React, { Component } from "react";
import PropTypes from "prop-types";
import { Column, IconTextButton, Badge } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    SpacedRow,
    Header,
    Description,
} from "../components/overviewComponents";
import TaskSummary from "../components/TaskSummary";

const TaskOverview = ({
    task,
    category,
    viewTask,
    addActivity,
    start,
    end,
    user,
    theme,
    match,
    hideSummary,
    ...props
}) => {
    return (
        <>
            <Header>
                <h4>
                    <Badge
                        backgroundColor={theme.colors.task[task.owner_type]}
                        style={{ marginRight: "0.4rem" }}
                    >
                        {task.ref}
                    </Badge>{" "}
                    <strong>{task.name}</strong>
                </h4>
            </Header>

            {task.description && <Description>{task.description}</Description>}

            <TaskSummary
                task={task}
                category={category}
                user={user}
                match={match}
            />

            {task && task.can_view_details && (
                <SpacedRow>
                    <Column medium={6}>
                        <IconTextButton
                            expanded
                            onClick={(event) => {
                                return viewTask(task, category, event);
                            }}
                        >
                            {trans("View Campaign")}
                        </IconTextButton>
                    </Column>
                    {task.can_admin_details && (
                        <Column medium={6}>
                            <IconTextButton
                                expanded
                                onClick={(event) => {
                                    // console.log("PRESSING", category, task);
                                    hideSummary();
                                    return addActivity(task);
                                }}
                            >
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                {trans("Add Activity")}
                            </IconTextButton>
                        </Column>
                    )}
                </SpacedRow>
            )}
        </>
    );
};

export default TaskOverview;
