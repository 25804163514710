import React, { useState, useEffect } from "react";
import { useTheme } from "styled-components";
import axios from "axios";
import { trans, useIntl } from "@cortexglobal/rla-intl";

import {
    Select,
    RadioField,
    FormRow,
    CheckboxTree,
    ContentBlockLoader,
} from "@cortexglobal/rla-components";
import UserSelector from "./UserSelector";
import GroupSelector from "./GroupSelector";

const GroupAndUserSelector = ({
    onChange,
    groups,
    groupsInHierarchy = null,
    singleColumn = false,
    collapse = false,
    errors = {},
    helpText = "",
    view_type = "",
    allowed_groups = [],
    allowed_groups_individual = [],
    allowed_users = [],
}) => {
    const intl = useIntl();
    const theme = useTheme();

    const [groupsInHierarchyState, setGroupsInHierarchyState] = useState({
        loaded: groupsInHierarchy !== null,
        data: groupsInHierarchy,
    });

    useEffect(() => {
        if (groupsInHierarchyState.data === null) {
            axios.get(`/api/v1/admin/group/structure`).then(({ data }) => {
                setGroupsInHierarchyState({ loaded: true, data: data.data });
            });
        }
    }, [groupsInHierarchyState]);

    return (
        <>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="view_type"
                label={trans("Who can see this")}
                error={errors.view_type}
                helpText={(helpText === '') ? trans(
                    "The groups that will be able to see this asset"
                ): helpText}
                required={true}
            >
                <RadioField
                    name="view_type"
                    value={view_type}
                    options={[
                        {
                            value: "all",
                            text: intl.formatMessage({ id: "All" }),
                        },

                        {
                            value: "users_and_groups",
                            text: intl.formatMessage({
                                id: "Specific Users and Groups",
                            }),
                        },
                        {
                            value: "self",
                            text: intl.formatMessage({ id: "Only Me" }),
                        },
                    ]}
                    onChange={onChange}
                />
            </FormRow>
            {view_type === "users_and_groups" && (
                <>
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="allowed_groups"
                        label={trans("Available To Group")}
                        error={errors.allowed_groups}
                        helpText={trans(
                            "The groups that will be able to see this "
                        )}
                    >
                        <ContentBlockLoader
                            loaded={groupsInHierarchyState.loaded}
                            // style={{
                            //     maxHeight:
                            //         theme.checkboxTreeContainer.maxHeight,
                            //     overflow: "auto",
                            //     border: theme.input.borderColor,
                            //     borderRadius: theme.input.radius,
                            //     boxShadow:
                            //         "inset 0 -10px 10px -10px rgba(0,0,0,0.1)", //, inset 0 10px 10px -10px rgba(0,0,0,0.1)",
                            // }}
                        >
                            <CheckboxTree
                                name="allowed_groups"
                                value={allowed_groups}
                                options={groupsInHierarchyState.data}
                                onChange={onChange}
                                selectChildren={true}
                                allowListView={true}
                                showWrapper={true}
                                style={{
                                    marginBottom: "1rem",
                                }}
                            />
                        </ContentBlockLoader>
                        {/* <div style={{ height: "1rem" }}></div> */}
                    </FormRow>
                    {/* <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="allowed_groups_individual"
                        label={trans("Available To Individual Group(s)")}
                        error={errors.allowed_groups_individual}
                        helpText={trans(
                            "The individual group(s) that will be able to see this"
                        )}
                    >
                        <GroupSelector
                            name="allowed_groups_individual"
                            value={allowed_groups_individual}
                            onChange={onChange}
                            multi={true}
                            placeholder={trans("Select a Group...")}
                        />
                    </FormRow> */}
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="allowed_users"
                        label={trans("Available To Individual User(s)")}
                        error={errors.allowed_users}
                        helpText={trans(
                            "The individual user(s) that will be able to see this"
                        )}
                    >
                        <UserSelector
                            name="allowed_users"
                            value={allowed_users}
                            onChange={onChange}
                            multi={true}
                            placeholder={trans("Select a User...")}
                        />
                    </FormRow>
                </>
            )}
        </>
    );
};

export default GroupAndUserSelector;
