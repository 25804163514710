import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//@ts-ignore cortex
import { format } from "@cortexglobal/cortex-utilities";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    PageTitle,
    IconTextButton,
    Loading,
    Row,
    Table,
    useAddToast,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import NewsContext from "./NewsContext";

import { EmptyRow, PreviewImage } from "../../components/index";

export const NewsList = ({ path, getNewsSummaries }) => {
    const { news: items } = useContext(NewsContext);
    const addToast = useAddToast();

    useEffect(() => {
        getNewsSummaries();
    }, []);

    const deleteItem = (uuid, loadItems, page = 1) => {
        axios
            .delete(`/api/v1/admin/news/${uuid}`)
            .then(({ data }) => {
                addToast({
                    content: trans("Successfully deleted news item"),
                    type: "success",
                    showFor: 3000,
                });
                // console.log(data);
                loadItems(page);
            })
            .catch();
    };

    return (
        <div>
            <PageTitle
                title={<React.Fragment>{trans("News Stories")}</React.Fragment>}
            >
                <IconTextButton
                    as={Link}
                    to={`${path}/create`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    icon={faPlus}
                >
                    {trans("Create news item")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>{trans("Image")}</th>
                            <th>{trans("Headline")}</th>
                            <th>{trans("Status")}</th>
                            <th>{trans("Created")}</th>
                            <th>{trans("Last Updated")}</th>
                            <th>{trans("Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!items.loaded ? (
                            <EmptyRow colSpan={6}>
                                <Loading />
                            </EmptyRow>
                        ) : items.data.length === 0 ? (
                            <EmptyRow colSpan={6}>
                                {trans("No news items found.")}{" "}
                                <Link to={`${path}/create`}>
                                    {trans("Why not create one?")}
                                </Link>
                            </EmptyRow>
                        ) : (
                            items.data.map((item) => {
                                return (
                                    <tr>
                                        <td>
                                            {item.thumbnail ? (
                                                <PreviewImage
                                                    src={item.thumbnail}
                                                />
                                            ) : (
                                                trans("No image provided")
                                            )}
                                        </td>
                                        <td>
                                            <Link to={`${path}/${item.uuid}`}>
                                                {item.title}
                                            </Link>
                                        </td>
                                        <td>{item.readable_status}</td>
                                        <td>
                                            {item.created
                                                ? format(
                                                      new Date(item.created),
                                                      "Pp"
                                                  )
                                                : ""}
                                        </td>
                                        <td>
                                            {item.updated
                                                ? format(
                                                      new Date(item.updated),
                                                      "Pp"
                                                  )
                                                : ""}
                                        </td>
                                        <td>
                                            <IconTextButton
                                                as={Link}
                                                to={`${path}/${item.uuid}/edit`}
                                                showCircle={false}
                                                expanded={false}
                                                icon={faEdit}
                                            >
                                                {trans("Edit")}
                                            </IconTextButton>

                                            <IconTextButton
                                                type="alert"
                                                showCircle={false}
                                                expanded={false}
                                                icon={faTrash}
                                                onClick={(event) => {
                                                    confirm(
                                                        "Are you sure you want to delete this news item?"
                                                    )
                                                        ? deleteItem(
                                                              item.uuid,
                                                              getNewsSummaries
                                                          )
                                                        : event.preventDefault();
                                                }}
                                            >
                                                {trans("Delete")}
                                            </IconTextButton>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </Row>
        </div>
    );
};

export default NewsList;
