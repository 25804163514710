import React, { useContext, useState } from "react";
import { trans } from "@cortexglobal/rla-intl";
import {
    FormRow,
    StyledCheckbox,
    StyledInput,
    StyledTextarea,
} from "@cortexglobal/rla-components";
import SurveyManagementContext from "../SurveyManagementContext";

const SurveyNotificationsForm = ({
    survey,
    setSurveyData,
    handleChange,
    errors,
    singleColumn = false,
    collapse = false,
}) => {
    const { surveySystem } = useContext(SurveyManagementContext);
    const { isSubmitting, disableEdit, loading, loaded } = surveySystem;

    return (
        <>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                label={trans("Enable Thank You Notification")}
                required={false}
                name="completed_communication_enabled"
            >
                <StyledCheckbox
                    checked={survey?.completed_communication_enabled === true}
                    onChange={(e) =>
                        handleChange(
                            "completed_communication_enabled",
                            !survey?.completed_communication_enabled
                        )
                    }
                    name="completed_communication_enabled"
                    disabled={isSubmitting}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                label={trans("Subject")}
                required={false}
                name="completed_communication_subject"
            >
                <StyledInput
                    value={survey?.completed_communication_subject || ""}
                    onChange={(e) =>
                        handleChange(
                            "completed_communication_subject",
                            e.target.value
                        )
                    }
                    name="completed_communication_subject"
                    disabled={
                        survey?.completed_communication_enabled === false ||
                        isSubmitting
                    }
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                label={trans("Message")}
                required={false}
                name="completed_communication_message"
            >
                <StyledTextarea
                    name="completed_communication_message"
                    onChange={(e) =>
                        handleChange(
                            "completed_communication_message",
                            e.target.value
                        )
                    }
                    loading={false}
                    disabled={
                        survey?.completed_communication_enabled === false ||
                        isSubmitting
                    }
                >
                    {survey?.completed_communication_message}
                </StyledTextarea>
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                label={trans("Include email")}
                required={false}
                name="completed_communication_email_enabled"
            >
                <StyledCheckbox
                    checked={
                        survey?.completed_communication_email_enabled === true
                    }
                    onChange={(e) =>
                        handleChange(
                            "completed_communication_email_enabled",
                            !survey?.completed_communication_email_enabled
                        )
                    }
                    name="completed_communication_email_enabled"
                    disabled={
                        survey?.completed_communication_enabled === false ||
                        isSubmitting
                    }
                />
            </FormRow>
        </>
    );
};

export default SurveyNotificationsForm;
