import React, { useState, useEffect } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Row,
    Column,
    Panel,
    Tabordion,
} from "@cortexglobal/rla-components";

import {
    renderSettingInputGroupRecursive,
    onConfigurationChange,
} from "./components/sharedEditorFunctions";

const checkOptionShouldShow = (tag, sectionConfiguration) => {
    //Handle the various option dependencies

    //Only show colour options if the background type is set to color
    if (tag.name === "backgroundColor1" || tag.name === "backgroundColor2") {
        return (
            sectionConfiguration.find(
                (option) =>
                    option.name === "backgroundType" && option.value === "color"
            ) !== undefined
        );
    }
    //Only show image options if the background type is set to image
    if (tag.name === "backgroundImage") {
        return (
            sectionConfiguration.find(
                (option) =>
                    option.name === "backgroundType" && option.value === "image"
            ) !== undefined
        );
    }
    //Only show slant options if the background type is not set to none
    if (tag.name === "backgroundSlant") {
        return (
            sectionConfiguration.find(
                (option) =>
                    option.name === "backgroundType" && option.value === "none"
            ) === undefined
        );
    }

    //Only show the logo alignment option if the logo is set
    if (tag.name === "logoAlignment") {
        return (
            sectionConfiguration.find(
                (option) => option.name === "logo" && option.value === true
            ) !== undefined
        );
    }

    return true;
};

export const SectionThemeEditor = ({
    asset,
    sectionConfiguration,
    sectionPath,
    standardOnChange,
}) => {
    return (
        <section style={{ marginTop: "2rem" }}>
            {sectionConfiguration.map((tag, elementIndex) => {
                if (tag.type === "heading") {
                    return (
                        <Row>
                            <Column medium={3}>
                                <h3 style={{ textAlign: "right" }}>
                                    {tag.value}
                                </h3>
                            </Column>
                        </Row>
                    );
                }

                if (checkOptionShouldShow(tag, sectionConfiguration)) {
                    // console.log(tag);
                    //Add the code to check if the tag is an array, and do the looping,
                    //moving the below out into it's own component would be good
                    return renderSettingInputGroupRecursive({
                        tag,
                        onChange: (value, path, event) => {
                            onConfigurationChange(
                                standardOnChange,
                                asset,
                                value,
                                event,
                                path
                            );
                        },
                        chooseImage: (path, tag, event) => {
                            //TODO - Set the correct image/tag in state to be used on the
                            //update method
                            return setChoosingImage(!choosingImage);
                        },
                        path: sectionPath,
                        index: `${elementIndex}`,
                        asset,
                    });
                }
            })}
        </section>
    );
};

export default SectionThemeEditor;
