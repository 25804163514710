import React, { useEffect, useState, MouseEvent } from "react";
import axios, { AxiosResponse, AxiosError } from "axios";

import { Formik, Form } from "formik";
import {
    Row,
    Column,
    Button,
    SubmitButton,
    FormikInput,
    StyledDropzone,
    Select,
    FormLabel,
    Error,
    useAddToast,
    ContentBlockLoader,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import { FileSchema } from "./FileSchema";

interface FileFormProps {
    smartRef: string;
    addFile: any;
    setShowForm: any;
    singleType: {
        key: string;
        name: string;
    } | null;
}

export const FileForm = ({
    smartRef,
    addFile,
    setShowForm,
    singleType = null,
}: FileFormProps) => {
    const [type, setType] = useState(
        singleType && singleType["key"] ? singleType["key"] : ""
    );
    const [error, setError] = useState("");
    const [fileTypesOptions, setFileTypes] = useState([]);
    const [fileTypesLoaded, setFileTypesLoaded] = useState(false);
    const addToast = useAddToast();

    const onDrop = (files: any) => {
        setError("");

        const fd = new FormData();
        fd.append("files", files[0]);
        fd.append("type", type);
        addFile(fd, smartRef)
            .then((response: AxiosResponse) => {
                // setSubmitted(true);
                //Add a toast and close the form
                addToast({
                    type: "success",
                    content: trans("File successfully added"),
                    showFor: 5000,
                });
                setShowForm(false);
            })
            .catch((e: AxiosError | any) => {
                console.log(e);
                //Add a toast and close the form
                setError(e.response?.data.message);
            });
    };

    useEffect(() => {
        if (!singleType) {
            axios
                .get(`/api/v1/files/file-types`)
                .then((res) => {
                    setFileTypes(res.data);
                    setFileTypesLoaded(true);
                })
                .catch((e) => {
                    console.log(e.message);
                });
        } else {
            setType(singleType["key"]);
        }
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Column>
                    <h4>
                        {singleType ? (
                            <>
                                {trans("Add")} {singleType["name"]}
                            </>
                        ) : (
                            trans("Add a File")
                        )}
                    </h4>
                </Column>
                {error !== "" && (
                    <Column>
                        <Error>{error}</Error>
                    </Column>
                )}
                {!singleType && (
                    <Column>
                        <ContentBlockLoader
                            loaded={fileTypesLoaded}
                            hideOverflow={false}
                        >
                            <Select
                                name="type"
                                label={trans("Type")}
                                onChange={({ value }: { value: string }) => {
                                    setError("");
                                    setType(value);
                                }}
                                options={fileTypesOptions}
                                value={type}
                            />
                        </ContentBlockLoader>
                    </Column>
                )}
            </Row>

            {type && (
                <Row style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                    <Column>
                        <React.Fragment>
                            <FormLabel>{trans("File")}</FormLabel>
                            <StyledDropzone
                                name="files"
                                onDrop={onDrop}
                                type="default"
                                style={{ textAlign: "center" }}
                            >
                                <span>
                                    <small>
                                        {trans(
                                            "Drop a file here, or click to browse"
                                        )}
                                    </small>
                                </span>
                            </StyledDropzone>
                        </React.Fragment>
                    </Column>
                </Row>
            )}

            <Row style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                <Column medium={6}>
                    <Button
                        $expanded
                        onClick={(event: MouseEvent) => {
                            setShowForm(false);
                            event.preventDefault;
                        }}
                        type="alert"
                    >
                        {trans("Cancel")}
                    </Button>
                </Column>
            </Row>
        </React.Fragment>
    );
};
