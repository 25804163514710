import React, { Component } from "react";

class CalendarFooter extends Component {
    render() {
        return (
            <div
                className="linearCalendar__footer"
                style={{ position: "relative" }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default CalendarFooter;
