import axios from "axios";
import {
    getItem,
    setItem,
    clearStorage,
    isJSON,
} from "@cortexglobal/cortex-utilities";
import ReduxThunk from "redux-thunk";
import { get, omit } from "lodash";

export const SET_LOGGED_IN_STATE = "SET_LOGGED_IN_STATE";

export const setupAuth = (redirect = false) => {
    return (dispatch) =>
        Promise.all([
            getItem("access_token"),
            getItem("user"),
            getItem("group"),
            getItem("role"),
            getItem("groups"),
            getItem("navigation"),
            // getItem("sitemap")
        ]).then((values) => {
            //console.log(values);
            //Values is an array of the results of each of the getItem promises return values
            //Each item is in the form of {key: value}, so we'll reduce this to a single object
            //to make it easier to work with
            const auth = values.reduce((authObj, value) => {
                const key = Object.keys(value)[0];
                return {
                    ...authObj,
                    [key]: isJSON(value[key])
                        ? JSON.parse(value[key])
                        : value[key],
                };
            }, {});

            const {
                access_token: token,
                user,
                group,
                role,
                groups,
                navigation,
                // sitemap
            } = auth;

            //Setting the Authorization header to null should remove it (e.g. if token === null)
            axios.defaults.headers.common["Authorization"] = token;

            dispatch({
                type: SET_LOGGED_IN_STATE,
                loggedIn: !!token,
            });

            // if (!token) {
            //     dispatch({ type: GUEST_STATUS, status: "processing" });
            //     dispatch(loginAsGuest());
            // }

            // If we have the token and the user in storage just set the user in state
            if (token && user) {
                dispatch({
                    type: SET_AUTH_USER_DETAILS,
                    user,
                });
            }

            if (token && !user) {
                // if (token) {
                dispatch(getUser());
            }

            if (navigation) {
                dispatch({
                    type: SET_NAVIGATION,
                    data: navigation,
                });
            } else if (token && user) {
                dispatch(getUserNavigation());
            }

            // if (sitemap) {
            //     dispatch({
            //         type: SET_SITEMAP,
            //         data: sitemap
            //     });
            // } else if (token && user) {
            //     dispatch(getSitemap());
            // }

            if (role) {
                dispatch({
                    type: SET_AUTH_USER_ROLE,
                    role: role,
                });
            }

            if (group || groups) {
                dispatch({
                    type: SET_AUTH_USER_GROUPS,
                    groups,
                });

                dispatch({
                    type: SET_AUTH_USER_GROUP,
                    group: group,
                });
            }
            // else if (token && user) {
            //     dispatch(getUserGroups());
            // }

            dispatch({ type: SET_REDIRECT_STATE, redirect });
        });
};

export const SET_AUTH_USER_DETAILS = "SET_AUTH_USER_DETAILS";
export const SET_AUTH_USER_LOADING = "SET_AUTH_USER_LOADING";
export const SET_AUTH_USER_ROLE = "SET_AUTH_USER_ROLE";
export const SET_AUTH_USER_PRIMARY_ROLE = "SET_AUTH_USER_PRIMARY_ROLE";
export const SET_AUTH_USER_GROUP = "SET_AUTH_USER_GROUP";

export const SET_AUTH_USER_MODULES = "SET_AUTH_USER_MODULES";

export const getUser = () => {
    return (dispatch) => {
        dispatch({
            type: SET_AUTH_USER_LOADING,
        });
        axios
            .get(`/api/v1/current-user`)
            .then((response) => {
                let user = get(response, "data.data", {
                    group: {},
                    role: {},
                    primary_role: {},
                    modules: {}
                });

                const group = get(user, "group", {});
                const role = get(user, "role", {});
                const primary_role = get(user, "primary_role", {});
                const modules = get(user, "modules", {});
                user = omit(user, ["group", "role", "primary_role", 'modules']);
                setItem("language", user.language);
                setItem("date_format", user.date_format);
                setItem("currency", user.currency);

                dispatch({
                    type: SET_AUTH_USER_MODULES,
                    modules: modules,
                });

                dispatch({
                    type: SET_AUTH_USER_DETAILS,
                    user: user,
                });

                dispatch({
                    type: SET_AUTH_USER_GROUP,
                    group: group,
                });

                dispatch({
                    type: SET_AUTH_USER_ROLE,
                    role: role,
                });

                dispatch({
                    type: SET_AUTH_USER_PRIMARY_ROLE,
                    role: primary_role,
                });

                dispatch(getUserGroups());
                dispatch(getUserRoles());
                dispatch(getUserNavigation());
                // dispatch(getSitemap());
            })
            .catch((e) => e);
    };
};

export const SET_REDIRECT_STATE = "SET_REDIRECT_STATE";

export function ssoLogin(token) {
    return (dispatch) => {
        setItem("access_token", `Bearer ${token}`);

        dispatch(setupAuth(true));
    };
}

// export function refreshLogin(token) {
//     console.log("refresh Login");
//     //    storage.clear();
//     return dispatch => {
//         //clearStorage();
//         console.log("dispatch");
//         setItem("access_token", `Bearer ${token}`);

//         dispatch(setupAuth(true));

//         console.log("setupAuth run");
//     };
// }

export const GUEST_SIGN_IN = "GUEST_SIGN_IN";

export function login(loginDetails) {
    return (dispatch) => {
        return axios
            .post(`/oauth/token`, {
                ...loginDetails,
                grant_type: "password",
                scope: "*",
            })
            .then(({ data }) => {
                setItem("token", data.access_token);
                setItem(
                    "refresh_token",
                    `${data.token_type} ${data.refresh_token}`
                );
                setItem(
                    "access_token",
                    `${data.token_type} ${data.access_token}`
                );

                dispatch(setupAuth(true));
            });
    };
}

export const setupAuthWithGuest = (redirect = false) => {
    return (dispatch) => {
        Promise.all([getItem("access_token")]).then((values) => {
            const auth = values.reduce((authObj, value) => {
                const key = Object.keys(value)[0];
                return {
                    ...authObj,
                    [key]: isJSON(value[key])
                        ? JSON.parse(value[key])
                        : value[key],
                };
            }, {});
            axios.defaults.headers.common["Authorization"] = token;

            const { access_token: token } = auth;

            if (!token && !/^\/auth\//.test(window.location.pathname)) {
                dispatch(loginAsGuest(redirect));
            } else {
                dispatch(setupAuth());
            }
        });
    };
};

export function loginAsGuest() {
    return (dispatch) => {
        return axios
            .post(`/guest/token`, {
                grant_type: "password",
                scope: "*",
            })
            .then(({ data }) => {
                setItem("token", data.access_token);
                // setItem(
                //     "refresh_token",
                //     `${data.token_type} ${data.refresh_token}`
                // );
                setItem(
                    "access_token",
                    `${data.token_type} ${data.access_token}`
                );

                dispatch(setupAuth(true));
            });
    };
}

export function logout() {
    return (dispatch) => {
        axios.delete("/api/v1/token").catch((e) => e);
        clearStorage();
        dispatch({ type: SET_REDIRECT_STATE, redirect: false });
        dispatch({ type: SET_LOGGED_IN_STATE, loggedIn: false });
    };
}

export function forgotten(details) {
    return (dispatch) => {
        return axios
            .post(`/api/v1/password/forgotten`, details)
            .then((response) => {
                console.log(response.data);

                dispatch({ type: SET_REDIRECT_STATE, redirect: true });
                return response.data;
            });
    };
}

export function resetPassword(details) {
    //console.log(details);
    return (dispatch) => {
        return axios
            .post(`/api/v1/password/reset`, details)
            .then((response) => {
                //console.log(response.data);
                dispatch({ type: SET_REDIRECT_STATE, redirect: true });
                return response.data.data;
            });
    };
}

export const SET_AUTH_USER_GROUPS = "SET_AUTH_USER_GROUPS";

export const getUserGroups = () => {
    return (dispatch) => {
        axios
            .get(`/api/v1/user-groups`)
            .then(({ data: { data: groups } }) => {
                setItem("groups", groups);

                dispatch({
                    type: SET_AUTH_USER_GROUPS,
                    groups,
                });

                // groups.forEach(group => {
                //     if (group.active) {
                //         setItem("group", group);
                //
                //         dispatch({
                //             type: SET_AUTH_USER_GROUP,
                //             group
                //         });
                //     }
                // });
            })
            .catch((e) => e);
    };
};

// export const SET_AUTH_USER_GROUP = "SET_AUTH_USER_GROUP";
export const setUserGroup = ({ group }) => {
    return (dispatch) => {
        return axios
            .post(`/api/v1/user-groups`, { group })
            .then(({ data: { data: group } }) => {
                dispatch({
                    type: SET_AUTH_USER_GROUP,
                    group: group,
                });

                return group;
            });
    };
};

export const SET_NAVIGATION = "SET_NAVIGATION";

export const getUserNavigation = () => {
    return (dispatch) => {
        axios.get(`/api/v1/user-navigation`).then((response) => {
            let { data } = response.data;
            setItem("navigation", data);

            dispatch({
                type: SET_NAVIGATION,
                data,
            });
        });
    };
};

// export const SET_SITEMAP = "SET_SITEMAP";

// export const getSitemap = () => {
//     return dispatch => {
//         axios.get(`/api/v1/sitemap`).then(response => {
//             let { data } = response;
//             setItem("sitemap", data);

//             dispatch({
//                 type: SET_SITEMAP,
//                 data
//             });
//         });
//     };
// };

export const SET_BREADCRUMBS = "SET_BREADCRUMBS";

export const updateBreadcrumbs = (key, pageTitle = null) => {
    return (dispatch, getState) => {
        const { data } = getState().auth.navigation;
        let breadcrumbs = [];

        // if (data.length == 0) {
        //     return [];
        // }
        let item = data.find(({ slug }) => slug === key);
        if (item) {
            do {
                breadcrumbs = [item, ...breadcrumbs];
                // breadcrumbs.unshift(item);
                item = data.find(({ id }) => id === item.parent_id);
            } while (item);

            if (pageTitle) {
                breadcrumbs.push({
                    title: pageTitle,
                    path: "",
                    icon: null,
                    current: true,
                });
            }
        }

        dispatch({
            type: SET_BREADCRUMBS,
            breadcrumbs,
        });
    };
};

export const SET_AUTH_USER_META_DATA = "SET_AUTH_USER_META_DATA";
export const setAuthMetaData = ({ data }) => {
    return (dispatch) => {
        dispatch({
            type: SET_AUTH_USER_META_DATA,
            data,
        });
    };
};

export const DECREMENT_UNREAD_COUNTER = "DECREMENT_UNREAD_COUNTER";
export const decrementReadCounter = () => {
    return (dispatch) => {
        dispatch({
            type: DECREMENT_UNREAD_COUNTER,
        });
    };
};

export const SET_AUTH_USER_ROLES = "SET_AUTH_USER_ROLES";
export const getUserRoles = () => {
    return (dispatch) => {
        axios
            .get(`/api/v1/user-roles`)
            .then(({ data: { data: roles } }) => {
                setItem("roles", roles);

                dispatch({
                    type: SET_AUTH_USER_ROLES,
                    roles,
                });

                // roles.forEach(role => {
                //     if (role.active) {
                //         setItem("role", role);
                //
                //         dispatch({
                //             type: SET_AUTH_USER_ROLE,
                //             role
                //         });
                //     }
                // });
            })
            .catch((e) => e);
    };
};

export const setUserRole = ({ role }) => {
    return (dispatch) => {
        return axios
            .post(`/api/v1/user-roles`, { role })
            .then(({ data: { data: role } }) => {
                dispatch({
                    type: SET_AUTH_USER_ROLE,
                    role: role,
                });

                //Redirect to the home page, as the whole app will need refreshing
                //and the user may not have access to the current page
                window.location.href = "/";
                //Reload the whole app, as all the data will need refreshing
                // window.location.reload(true);
            });
    };
};

export const resetGroupRole = () => {
    return (dispatch) => {
        return axios
            .post(`/api/v1/user-reset`)
            .then(({ data: { data: user } }) => {
                const { role, group } = user;

                dispatch({
                    type: SET_AUTH_USER_GROUP,
                    role: group,
                });

                dispatch({
                    type: SET_AUTH_USER_ROLE,
                    role: role,
                });

                //Reload the whole app, as all the data will need refreshing
                window.location.reload(true);
            });
    };
};
