import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { SurveyFormLabel } from "../components/SurveyFormStyledComponents.js";

import {
    Row,
    Column,
    FormRow,
    DatePicker,
    Toggle,
    SelectField,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const SurveyDateOptions = ({
    options,
    index,
    isSubmitting,
    disableEdit,
    updateOptionsData,
    singleColumn = false,
    collapse = false,
}) => {
    const updateDateOptions = (name, value) => {
        let updatedOptions = { ...options };
        updatedOptions[name] = value;
        updateOptionsData("range", updatedOptions, index);
    };
    const getDate = (option) => {
        let maxDate = undefined;
        if (option) {
            try {
                //The max_value defaults to 10 so if it is 10 we want to set it to null
                if (typeof option === "number") {
                    return undefined;
                }
                if (typeof option === "string") {
                    maxDate = new Date(option);
                } else {
                    maxDate = option;
                }
            } catch (e) {
                maxDate = undefined;
            }
        }
        return maxDate;
    };

    return (
        <>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                label={trans("Date Settings")}
            ></FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="min_value"
                label={trans("Min Date")}
            >
                <DatePicker
                    name="min_value"
                    onChange={(e) => updateDateOptions(e.name, e.value)}
                    disabled={isSubmitting || disableEdit}
                    value={options.min_value}
                    selected={options.min_value}
                    maxDate={getDate(options.max_value)}
                />
            </FormRow>

            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="max_value"
                label={trans("Max Date")}
            >
                <DatePicker
                    name="max_value"
                    onChange={(e) => updateDateOptions(e.name, e.value)}
                    disabled={isSubmitting || disableEdit}
                    value={options.max_value}
                    selected={options.max_value}
                    minDate={
                        options.min_value
                            ? new Date(options.min_value)
                            : undefined
                    }
                />
            </FormRow>
        </>
    );
};

export default SurveyDateOptions;
