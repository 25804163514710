import React, {
    useEffect,
    useState,
    useCallback,
    useContext,
    useRef,
    useLayoutEffect,
} from "react";
import axios from "axios";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle as faCheckCircleEmpty } from "@fortawesome/free-regular-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    useAddToast,
    useToasts,
    Row,
    Column,
    Tooltip,
    Panel,
    PageTitle,
    Tabordion,
    Loading,
} from "@cortexglobal/rla-components";
import useResizeObserver from "@react-hook/resize-observer";

import { useDropzone } from "react-dropzone";

import { faUpload } from "@fortawesome/free-solid-svg-icons";
import MetaDetails from "./MetaDetails";
import NamingAndGeneralSetup from "./NamingAndGeneralSetup";
import AssetContext from "../AssetContext";
import Availability from "./Availability";
import CategoryStructure from "./CategoryStructure";
import { BulkItem } from "./components/bulkEditor/BulkItem";

import { useBulkAssets } from "../hooks/useBulkAssets";
import { ButtonRow, Grid } from "./BulkUploader";

const BulkEditor = ({ onCancel, onSaved, uuids }) => {
    const addToast = useAddToast();
    // const [_, dispatch] = useToasts();
    // const [files, setFiles] = useState([]);
    const [assetTypes, setAssetTypes] = useState({
        loaded: false,
        data: [],
    });
    const [mediaChannels, setMediaChannels] = useState({
        loaded: false,
        data: [],
    });
    const [assetAdmin, setAssetAdmin] = useState({
        assetStatusOptionsLoaded: true,
        assetStatusOptions: [],
        assetDeclarations: [],
    });
    const [languages, setLanguages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [assetHierarchyAvailable, setAssetHierarchyAvailable] =
        useState(false);
    const [categoryHierarchy, setCategoryHierarchy] = useState([]);
    const [errors, setErrors] = useState({});
    const [openSectionIndex, setOpenSectionIndex] = useState(0);
    const [groupsInHierarchy, setGroupsInHierarchy] = useState([]);
    const { filters, searchableMeta, config, setRefreshSearch, groups } =
        useContext(AssetContext);
    const target = useRef(null);

    const [size, setSize] = useState({});

    const {
        state: { items, sharedItem },
        loadItems,
        reset,
        updateItem,
        updateItems,
        removeItem,
        selectAll,
        deselectAll,
    } = useBulkAssets({
        assetTypes: assetTypes.data,
        mediaChannels: mediaChannels.data,
    });
    useLayoutEffect(() => {
        // console.log({ target });
        if (target.current) {
            setSize(target.current.getBoundingClientRect());
        }
    }, [target.current, loading, mediaChannels, assetTypes]);

    useResizeObserver(target, (entry) => {
        if (entry.contentRect.width !== size.width) {
            setSize(entry.contentRect);
        }
    });

    useEffect(() => {
        setLoading(true);

        //Load up the detail for each of the selected assets and pass them
        //into Bulk file editor
        axios
            .get(`api/v1/assets/multiple`, {
                params: { uuids },
            })
            .then(({ data }) => {
                loadItems(
                    data.data.map((item) => {
                        return { ...item, selected: true };
                    })
                );
                setLoading(false);
            });
        //If the locale_setting is set to "language" then preload the languages to stop them
        //being loaded in each overview form
        if (config.options && config.options.locale_setting === "language") {
            axios
                .get(`/api/v1/language`)
                .then(({ data: { data: languages } }) => {
                    setLanguages(languages);
                });
        }
        //Load the bulk asset status options
        axios.get(`/api/v1/assets/bulk-status-options`).then(({ data }) => {
            setAssetAdmin({
                assetStatusOptionsLoaded: true,
                assetStatusOptions: data.availableStatusList,
                assetDeclarations: data.declarations,
            });
        });
        axios
            .get("/api/v1/assets/asset-types")
            .then(({ data }) => {
                setAssetTypes({
                    loaded: true,
                    data: data.data,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: e.message,
                    showFor: 5000,
                });
            });

        axios
            .get("/api/v1/assets/media-channels")
            .then(({ data }) => {
                setMediaChannels({
                    loaded: true,
                    data: data.data,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: e.message,
                    showFor: 5000,
                });
            });
        axios.get(`/api/v1/admin/group/structure`).then(({ data }) => {
            setGroupsInHierarchy(data.data);
        });
        //Add in the categories section but only if it's an available option
        const hasAssetHierarchyAvailable =
            filters.loaded &&
            filters.data.findIndex(
                (filter) => filter.name === "category-hierarchy"
            ) !== -1;
        setAssetHierarchyAvailable(hasAssetHierarchyAvailable);
        if (hasAssetHierarchyAvailable) {
            axios.get(`/api/v1/admin/asset-hierarchy`).then(({ data }) => {
                setCategoryHierarchy(data.data);
            });
        }

        // Make sure to revoke the data uris to avoid memory leaks
        return () => items.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);

    useEffect(() => {
        if (!loading && items.length === 0) {
            onSaved();
            addToast({
                type: "success",
                content: trans("Bulk editing complete"),
                showFor: 5000,
            });
        }
    }, [items, loading]);

    const onSubmit = () => {
        items
            .filter((item) => item.selected)
            .forEach((item) => {
                saveAsset(item);
            });

        if (items.length === 0) {
            addToast({
                type: "success",
                content: trans("Assets updated"),
                showFor: 5000,
            });

            reset();
            setRefreshSearch();
            onSaved();
        }
    };

    const saveAsset = useCallback((item) => {
        axios
            .put(`/api/v1/admin/assets/${item.uuid}`, item)
            .then(({ data }) => {
                removeItem(item.uuid);
            })
            .catch((e) => {
                if (e.response && e.response.data) {
                    if (e.response.status === 422) {
                        setErrors((errors) => {
                            return {
                                ...errors,
                                [item.uuid]: e.response.data.errors,
                            };
                        });
                    } else {
                        addToast({
                            type: "alert",
                            content:
                                e.response && e.response.data
                                    ? e.response.data.message
                                    : trans("An error has occurred"),
                            showFor: 5000,
                        });
                    }
                }
            });
    });

    const filesSelected = items.filter((file) => file.selected).length;
    // console.log({ size, items });
    return (
        <>
            <Row expanded={true} collapse={true}>
                <Column collapse={true}>
                    <PageTitle
                        title={
                            <Tooltip
                                text={trans(
                                    "Please note that not all attributes are available in the bulk editor.  If you cannot find the option you require please edit that asset individually"
                                )}
                            >
                                {trans("Edit Assets", {})}{" "}
                            </Tooltip>
                        }
                    >
                        <IconTextButton
                            onClick={onCancel}
                            rotation={180}
                            showCircle={false}
                            expanded={false}
                        >
                            {trans("Cancel")}
                        </IconTextButton>
                        {/* {items.length > 1 && (
                            <>
                                {filesSelected > 0 && (
                                    <IconTextButton
                                        onClick={deselectAll}
                                        rotation={180}
                                        showCircle={false}
                                        expanded={false}
                                    >
                                        {trans("Deselect All")}
                                    </IconTextButton>
                                )}
                                {filesSelected !== items.length && (
                                    <IconTextButton
                                        onClick={selectAll}
                                        rotation={180}
                                        showCircle={false}
                                        expanded={false}
                                    >
                                        {trans("Select All")}
                                    </IconTextButton>
                                )}
                            </>
                        )} */}
                        <Button
                            onClick={onSubmit}
                            disabled={filesSelected === 0}
                        >
                            {trans(
                                "{filesSelected, plural, =0 {No assets} one {Save # asset} other {Save # assets}}",
                                {
                                    filesSelected,
                                }
                            )}
                        </Button>
                    </PageTitle>
                </Column>
            </Row>
            {assetTypes.loaded && mediaChannels.loaded ? (
                <Row
                    // expanded={true}
                    collapse={true}
                    style={{
                        display: "flex",
                        height: "85vh",
                    }}
                >
                    <Column
                        medium={4}
                        large={3}
                        xlarge={2}
                        style={{
                            height: "100%",
                            overflowY: "scroll",
                        }}
                        collapse={true}
                    >
                        <form>
                            <Panel>
                                <Tabordion
                                    type="accordion"
                                    allowMultiple={true}
                                    current={openSectionIndex}
                                    onTabClick={(key) => {
                                        // console.log(key);
                                        setOpenSectionIndex(key);
                                    }}
                                    allowFullyClosed={false}
                                >
                                    <NamingAndGeneralSetup
                                        heading={trans("Overview")}
                                        assetAdmin={assetAdmin}
                                        config={config}
                                        asset={sharedItem}
                                        standardOnChange={updateItems}
                                        errors={{}}
                                        singleColumn={true}
                                        showSectionHeading={false}
                                        excludedFields={["name", "description"]}
                                        preloadedLanguages={languages}
                                        assetTypes={assetTypes.data}
                                        mediaChannels={mediaChannels.data}
                                    />

                                    <MetaDetails
                                        heading={trans("Meta Data")}
                                        asset={sharedItem}
                                        standardOnChange={updateItems}
                                        filters={filters}
                                        searchableMeta={searchableMeta}
                                        errors={{}}
                                        singleColumn={true}
                                        showSectionHeading={false}
                                        excludedFields={["version"]}
                                    />

                                    {assetHierarchyAvailable && (
                                        <CategoryStructure
                                            heading={trans("Categories")}
                                            preLoadedCategories={
                                                categoryHierarchy
                                            }
                                            asset={sharedItem}
                                            standardOnChange={updateItems}
                                            errors={errors}
                                            singleColumn={true}
                                            showSectionHeading={false}
                                            collapse={true}
                                        />
                                    )}
                                    <Availability
                                        heading={trans("Availability")}
                                        asset={sharedItem}
                                        standardOnChange={updateItems}
                                        filters={filters}
                                        searchableMeta={searchableMeta}
                                        errors={{}}
                                        assetAdmin={assetAdmin}
                                        groups={groups}
                                        groupsInHierarchy={groupsInHierarchy}
                                        singleColumn={true}
                                        showSectionHeading={false}
                                        excludedFields={[]}
                                    />
                                </Tabordion>
                            </Panel>
                        </form>
                    </Column>
                    <Column
                        medium={8}
                        large={9}
                        xlarge={10}
                        style={{
                            position: "relative",
                            overflowY: "scroll",
                        }}
                        collapse={true}
                    >
                        <ButtonRow>
                            <IconTextButton
                                onClick={selectAll}
                                showCircle={false}
                                expanded={false}
                                icon={faCheckCircle}
                            >
                                {trans("Select All")}
                            </IconTextButton>
                            <IconTextButton
                                onClick={deselectAll}
                                showCircle={false}
                                expanded={false}
                                icon={faCheckCircleEmpty}
                            >
                                {trans("Deselect All")}
                            </IconTextButton>
                        </ButtonRow>
                        <Grid size={size} ref={target}>
                            {loading ? (
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "2rem",
                                        position: "sticky",
                                        top: 0,
                                    }}
                                >
                                    <Loading align="center" />
                                </div>
                            ) : (
                                <>
                                    {items.map((asset) => {
                                        // console.log(asset);
                                        return (
                                            <BulkItem
                                                key={asset.uuid}
                                                item={asset}
                                                onChange={updateItem}
                                                config={config}
                                                filters={filters}
                                                searchableMeta={searchableMeta}
                                                openSectionIndex={
                                                    openSectionIndex
                                                }
                                                setOpenSectionIndex={
                                                    setOpenSectionIndex
                                                }
                                                groups={groups}
                                                groupsInHierarchy={
                                                    groupsInHierarchy
                                                }
                                                preloadedLanguages={languages}
                                                preLoadedCategories={
                                                    categoryHierarchy
                                                }
                                                assetHierarchyAvailable={
                                                    assetHierarchyAvailable
                                                }
                                                assetAdmin={assetAdmin}
                                                errors={errors[asset.uuid]}
                                                removeItem={removeItem}
                                                assetTypes={assetTypes.data}
                                                mediaChannels={
                                                    mediaChannels.data
                                                }
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </Grid>
                    </Column>
                </Row>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default BulkEditor;
