import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { get, isEmpty } from "lodash";
import {
    Column,
    IconTextButton,
    InputField,
    Loading,
    PageTitle,
    Pagination,
    Row,
    Select,
    Table,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import InventoryListingItem from "./InventoryListingItem";

const SearchWrapper = styled.div`
    /* width: 100%; */
    margin: ${({ margin }) => margin};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    input {
        margin: 0;
        min-width: 100%;
        min-height: 38px;
    }
`;
const StyledInputField = styled(InputField)`
    /* margin: 0; */
    flex: 1;
    min-width: 150px;
`;
const InventoryListing = ({ path, settings }) => {
    const intl = useIntl();
    const [params, setParams] = useState({});
    const [inventoryItems, setInventoryItems] = useState([]);
    const [loadingState, setLoadingState] = useState("loading");
    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
    });
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        getInventoryItems();
    }, []);

    const onPageChange = (page) => {
        getInventoryItems(page);
    };

    const onPerPageChange = ({ value }) => {
        getInventoryItems(pagination.current_page, value);
    };

    const getInventoryItems = (page = 1, perPage = 20, params) => {
        setLoadingState("loading");
        setInventoryItems([]);

        axios
            .get(`/api/v1/events/inventory`, {
                params: { page: page, perPage: perPage, ...params },
            })
            .then(({ data }) => {
                const inventoryItems = data.data;
                const pagination = data.meta;

                setInventoryItems(inventoryItems);
                setPagination(pagination);

                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });
    };

    const removeItem = (itemToRemove) => {
        setInventoryItems(
            inventoryItems.filter((item) => item.uuid !== itemToRemove.uuid)
        );
    };

    const inventoryCategories = settings?.inventoryCategories || [];

    const searchInventory = (params) => {
        getInventoryItems(pagination.page, pagination.per_page, params);
    };

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
    };

    const render = () => {
        if (loadingState === "loading") {
            return (
                <tr>
                    <td colSpan="100%">
                        <Loading />
                    </td>
                </tr>
            );
        }

        if (loadingState === "loaded") {
            return inventoryItems.map((item) => {
                return (
                    <InventoryListingItem
                        key={item.uuid}
                        item={item}
                        path={path}
                        removeItem={removeItem}
                        settings={settings}
                    />
                );
            });
        }
    };

    return (
        <>
            <PageTitle title={trans("Inventory Management")}>
                <IconTextButton
                    onClick={() => {}}
                    as={Link}
                    to={`${path}/create`}
                    showCircle={false}
                    icon={faPlus}
                >
                    {trans("Create new")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Column medium={6}>
                    {!isEmpty(inventoryCategories) && (
                        <Select
                            height={38}
                            name="category"
                            onChange={(e) => {
                                onChange("category", e.value);
                                searchInventory({
                                    ...params,
                                    category: e.value,
                                });
                                setSelectedCategory(e.value);
                            }}
                            value={selectedCategory}
                            disabled={loadingState === "loading"}
                            options={[
                                {
                                    value: "",
                                    text: intl.formatMessage({
                                        id: "All Categories",
                                    }),
                                },
                                ...inventoryCategories,
                            ]}
                            // placeholder={intl.formatMessage({ id: "Category" })}
                            showEmptyOption={true}
                        />
                    )}
                </Column>
                <Column medium={6}>
                    <SearchWrapper>
                        <StyledInputField
                            name="search"
                            onChange={(e) => onChange(e.name, e.value)}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    searchInventory(params);
                                }
                            }}
                            placeholder={intl.formatMessage({ id: "Search" })}
                            disabled={loadingState === "loading"}
                        />
                        <IconTextButton
                            onClick={() => searchInventory(params)}
                            showCircle={false}
                            expanded={false}
                            icon={faSearch}
                        />
                    </SearchWrapper>
                </Column>
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>{trans("ID")}</th>
                            <th>{trans("Name")}</th>
                            <th>{trans("Category")}</th>
                            {/* <th>{trans("Cost")}</th>
                            <th>{trans("Cost Basis")}</th> */}
                            <th>{trans("Status")}</th>
                            <th>{trans("Bookings")}</th>
                            <th>{trans("Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>{render()}</tbody>
                </Table>
                {!isEmpty(pagination) && (
                    <Pagination
                        onPerPageChange={onPerPageChange}
                        style={{ clear: "both" }}
                        currentPage={pagination.current_page - 1}
                        perPage={pagination.per_page}
                        pageCount={pagination.last_page}
                        onPageChange={({ selected }) =>
                            onPageChange(selected + 1)
                        }
                        {...pagination}
                    />
                )}
            </Row>
        </>
    );
};

export default InventoryListing;
