import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledCurrencyInput from "../styledElements/styledCurrencyInput";
import storage from "local-storage-fallback";

const FormikCurrencyInput = ({
    type,
    name,
    label,
    readOnly,
    meta,
    onChange,
    error,
    labelWidth,
    labelAlign,
    labelStyle,
    placeholder,
    defaultValue,
    allowDecimal,
    decimalsLimit,
    showError,
    ...rest
}) => {
    let fieldOptions = {};

    const [currency, setCurrency] = useState([]);

    useEffect(() => {
        const localCurrency = storage.getItem("currency");
        setCurrency(JSON.parse(localCurrency));
    }, []);

    if (readOnly) {
        fieldOptions["readOnly"] = "readOnly";
    }

    if (error) {
        fieldOptions["error"] = "error";
    }
    return (
        <React.Fragment>
            <Field name={name} {...rest}>
                {({ field, form: { touched, errors, setFieldValue } }) => {
                    const error = !!touched[field.name] && errors[field.name];

                    return (
                        <React.Fragment>
                            {label && (
                                <FormLabel
                                    align={labelAlign}
                                    width={labelWidth}
                                    name={name}
                                    {...rest}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <StyledCurrencyInput
                                name={name}
                                id={name}
                                prefix={
                                    currency.symbol_string
                                        ? currency.symbol_string
                                        : ""
                                }
                                placeholder={placeholder}
                                intlConfig={{
                                    currency: currency.iso_code,
                                }}
                                allowDecimals={allowDecimal}
                                step={currency.step ? currency.step : 1}
                                decimalsLimit={
                                    currency.decimal_places
                                        ? currency.decimal_places
                                        : 2
                                }
                                onChange={(e) => {
                                    setFieldValue(name, e.value);
                                }}
                                error={error}
                                labelWidth={labelWidth}
                                {...fieldOptions}
                                {...rest}
                            />
                        </React.Fragment>
                    );
                }}
            </Field>
            {showError && (
                <ErrorMessage name={name}>
                    {(msg) => {
                        return <InputError error={msg} />;
                    }}
                </ErrorMessage>
            )}
        </React.Fragment>
    );
};

FormikCurrencyInput.displayName = "FormikCurrencyInput";

FormikCurrencyInput.propTypes = {
    name: PropTypes.string.isRequired,
    // onChange: PropTypes.func.isRequired,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    //error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.number,
    allowDecimal: PropTypes.bool,
    decimalsLimit: PropTypes.number,
};

FormikCurrencyInput.defaultProps = {
    size: "default",
    type: "number",
    readOnly: false,
    //error: "",
    placeholder: "",
    defaultValue: 0,
    allowDecimal: true,
    decimalsLimit: 2,
};

export default FormikCurrencyInput;
