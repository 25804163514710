import React, { JSXElementConstructor } from "react";
import { FormatNumberOptions } from "react-intl";
import { FormattedNumberWithLoader } from "./FormattedNumberWithLoader";
import storage from "local-storage-fallback";

export const FormattedCurrency = ({
    value,
    loaded = true,
    currency = "GBP",
    options
}: {
    value: number;
    loaded: boolean;
    currency: string;
    options?: FormatNumberOptions;
}) => {
    const localCurrency = JSON.parse(storage.getItem('currency'))

    let minimumFractionDigits = 2
    let maximumFractionDigits = 2

    if (currency === localCurrency?.iso_code) {
        maximumFractionDigits = localCurrency.decimal_places
        minimumFractionDigits = localCurrency.decimal_places
    }

    let amendedOptions = {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits,
        ...options,
        style: "currency",
        currency
    };

    return (
        <FormattedNumberWithLoader
            value={value}
            loaded={loaded}
            options={amendedOptions}
        />
    );
};
