import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledColorField from "../styledElements/StyledColorField";

const FormikColorField = ({
    type,
    name,
    label,
    labelWidth,
    labelAlign,
    showError,
    onChange,
    ...rest
}) => {
    return (
        <React.Fragment>
            <Field name={name} {...rest}>
                {({ form: { setFieldValue } }) => {
                    return (
                        <React.Fragment>
                            {label && (
                                <FormLabel
                                    align={labelAlign}
                                    width={labelWidth}
                                    name={name}
                                    {...rest}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <StyledColorField
                                {...rest}
                                name={name}
                                id={name}
                                onChange={value => {
                                    setFieldValue(name, value);
                                }}
                            />
                        </React.Fragment>
                    );
                }}
            </Field>
            {showError && (
                <ErrorMessage name={name}>
                    {msg => {
                        return <InputError error={msg} />;
                    }}
                </ErrorMessage>
            )}
        </React.Fragment>
    );
};

FormikColorField.displayName = "FormikColorField";

FormikColorField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelWidth: PropTypes.string,
    labelAlign: PropTypes.string
};

FormikColorField.defaultProps = {
    showError: true,
    labelWidth: "100%"
};

export default FormikColorField;
