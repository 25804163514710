import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import LinkListing from "./LinkListing";
import LinkCreate from "./LinkCreate";
import { LinkEdit } from "./LinkEdit";

export const LinkAdmin = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <LinkListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <LinkCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={props => {
                    const {
                        match: {
                            params: { uuid }
                        }
                    } = props;
                    return <LinkEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default LinkAdmin;
