import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";

import {
    PageTitle,
    Select,
    Loading,
    Panel,
    FormRow,
    StyledSelectSimple,
} from "@cortexglobal/rla-components";

import { Row, Column } from "@cortexglobal/rla-components";
import RequestForm from "../forms/RequestForm";
import { getRequestTypes } from "../requestsActions";
import { trans, useIntl } from "@cortexglobal/rla-intl";

class CreateRequest extends Component {
    state = {
        selectedTypeUuid: "",
        selectedTypeConfirmed: false,
        showSelect: true,
    };


    componentDidMount() {
        const { relatedRef, requestTypeUuid, alias } = queryString.parse(
            this.props.location.search
        );

        this.setState({
            relatedRef,
            selectedTypeUuid: requestTypeUuid ? requestTypeUuid : "",
            alias: alias ? alias : "",
            selectedTypeConfirmed: requestTypeUuid ? true : false,
        });

        if (!this.props.types.loaded) {
            this.props.getRequestTypes();
        }
    }

    renderCreationStage = () => {


        const { onGoBack, types, requestsPath } = this.props;
        const {
            selectedTypeUuid,
            showSelect,
            selectedTypeConfirmed,
            alias,
            relatedRef,
        } = this.state;

        if (!types.loaded) {
            return <Loading />;
        }

        let renderedTypes = types.data;
        let renderedShowSelect = showSelect;
        let requestName = trans("Request");
        let selectedRequestType;

        if (selectedTypeUuid !== "" && selectedTypeConfirmed) {
            selectedRequestType = types.data.filter((type) => {
                return type.uuid === selectedTypeUuid;
            })[0];
        }

        if (alias) {
            selectedRequestType = types.data.filter((type) => {
                return type.alias === alias;
            })[0];
            if (selectedRequestType) {
                renderedTypes = [selectedRequestType];
                renderedShowSelect = false;
                requestName = selectedRequestType.name;
            }
        }

        return (
            <>
                <PageTitle title={
                    trans("Create a new {requestName}", {
                        requestName: requestName,
                    })
                } />

                {renderedShowSelect && (
                    <Row>
                        <Panel>
                            <FormRow
                                name="selectedTypeUuid"
                                label={trans("Select a request type")}
                                helpText={trans(
                                    "Please select a request type, then complete the form"
                                )}
                            >
                                <StyledSelectSimple
                                    name="selectedTypeUuid"
                                    onChange={({ target: input }) => {
                                        this.setState({
                                            selectedTypeUuid: input.value,
                                        });
                                        this.setState({
                                            selectedTypeConfirmed: true,
                                        });
                                    }}
                                    options={renderedTypes
                                        .filter((type) => {
                                            return (
                                                type.automatic_creation_only ===
                                                false
                                            );
                                        })
                                        .map((type) => {
                                            return {
                                                text: type.name,
                                                value: type.uuid,
                                                optGroup: type.category?.text,
                                            };
                                        })}
                                    value={this.state.selectedTypeUuid}
                                />
                            </FormRow>
                        </Panel>
                    </Row>
                )}
                {selectedRequestType && (
                    <RequestForm
                        onGoBack={onGoBack}
                        requestTypeUuid={selectedTypeUuid}
                        selectedRequestType={selectedRequestType}
                        relatedRef={relatedRef}
                        requestsPath={requestsPath}
                    />
                )}
            </>
        );
    };
    render() {
        return <>{this.renderCreationStage()}</>;
    }
}

const mapStateToProps = (state) => {
    const { types } = state.requests;
    return {
        types,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRequestTypes,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateRequest);
