import React, { lazy, FunctionComponent } from "react";
//@ts-ignore cortex
import { trans, useIntl } from "@cortexglobal/rla-intl";

//@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const ContentNewsAdmin = lazy(
    () => import("../screens/newsItem/ContentNewsAdmin")
);
const ContentFeaturedItemAdmin = lazy(
    () => import("../screens/contentFeaturedItem/FeaturedItemAdmin")
);
const LinkAdmin = lazy(() => import("../screens/link/LinkAdmin"));
const ExternalDashboardAdmin = lazy(
    () => import("../screens/externalDashboards/ExternalDashboardAdmin")
);
const GroupAdmin = lazy(() => import("../screens/group/GroupAdmin"));
const CalendarEventAdmin = lazy(
    () => import("../screens/calendarEvents/CalendarEventAdmin")
);
const CategoryAdmin = lazy(() => import("../screens/category/CategoryAdmin"));
const RoleAdmin = lazy(() => import("../screens/role/RoleAdmin"));
const TeamAdmin = lazy(() => import("../screens/team/TeamAdmin"));
const NavigationAdmin = lazy(
    () => import("../screens/navigation/NavigationAdmin")
);
const DashboardPanelAdmin = lazy(
    () => import("../screens/dashboardPanel/DashboardPanelAdmin")
);
// const UserAdmin = lazy(() => import("../screens/user/UserAdmin"));
const UserAdmin = lazy(() => import("../screens/users/UserAdmin"));
const RequestTypeAdmin = lazy(
    () => import("../screens/requestTypes/RequestTypeAdmin")
);
const WorkflowAdmin = lazy(() => import("../screens/workflow/WorkflowAdmin"));

const GroupProfileAdmin = lazy(
    () => import("../screens/groupProfile/GroupProfileAdmin")
);
//@ts-ignore cortex
import { SurveyManagementApp } from "@cortexglobal/surveys";
const InventoryCategoryAdmin = lazy(
    () => import("../screens/events/inventoryCategory/InventoryCategoryAdmin")
);
const EventCategoryAdmin = lazy(
    () => import("../screens/events/eventCategory/EventCategoryAdmin")
);
const CommunicationRuleAdmin = lazy(
    () => import("../screens/communicationRules/CommunicationRuleAdmin")
);

const AssetAdmin = lazy(() => import("../screens/assets/AssetAdmin"));
const CampaignAdmin = lazy(() => import("../screens/assets/CampaignAdmin"));
const MediaChannelAdmin = lazy(
    () => import("../screens/mediaChannels/MediaChannelAdmin")
);
const CustomFormAdmin = lazy(
    () => import("../screens/customForms/CustomFormAdmin")
);
const ProductAdmin = lazy(() => import("../screens/products/ProductAdmin"));

//@ts-ignore cortex
import { InventoryManager } from "@cortexglobal/events";
import TranslationsListing from "../screens/translations/TranslationsListing";

type PermissionType = { alias: string; name: string };
type RouteType = {
    path: string;
    name: string;
    component: FunctionComponent;
    permissions: string[];
};
export type Routes = {
    [key: string]: RouteType[];
};

//TODO - Move this into context?
const generateRoutes = (intl) => {
    return {
        users: [
            {
                path: "/users",
                name: intl.formatMessage({ id: "Users" }),
                component: UserAdmin,
                permissions: ["admin-user-edit"],
            },
        ],
        group: [
            {
                path: "/groups",
                name: intl.formatMessage({ id: "Groups" }),
                component: GroupAdmin,
                permissions: ["admin-group-edit"],
            },
        ],
        roles: [
            {
                path: "/roles",
                name: intl.formatMessage({ id: "Roles" }),
                component: RoleAdmin,
                permissions: ["admin-role-edit"],
            },
        ],
        teams: [
            {
                path: "/teams",
                name: intl.formatMessage({ id: "Teams" }),
                component: TeamAdmin,
                permissions: ["admin-team-edit"],
            },
        ],
        categories: [
            {
                path: "/categories/assets",
                name: intl.formatMessage({ id: "Asset Categories" }),
                component: CategoryAdmin,
                permissions: ["admin-categories-asset"],
            },
            {
                path: "/categories/media",
                name: intl.formatMessage({ id: "Media Categories" }),
                component: CategoryAdmin,
                permissions: ["admin-categories-media"],
            },
            {
                path: "/categories/planner",
                name: intl.formatMessage({ id: "Planner Categories" }),
                component: CategoryAdmin,
                permissions: ["admin-categories-planner"],
            },
            {
                path: "/categories/content",
                name: intl.formatMessage({ id: "Content Categories" }),
                component: CategoryAdmin,
                permissions: ["admin-categories-content"],
            },
            {
                path: "/categories/calendar",
                name: intl.formatMessage({ id: "Calendar Event Categories" }),
                component: CategoryAdmin,
                permissions: ["admin-categories-calendar"],
            },
            {
                path: "/categories/faq",
                name: intl.formatMessage({ id: "FAQ Categories" }),
                component: CategoryAdmin,
                permissions: ["faq-manage"],
            },
        ],
        content: [
            {
                path: "/content/news",
                name: intl.formatMessage({ id: "News" }),
                component: ContentNewsAdmin,
                permissions: ["content-news-edit"],
            },
            {
                path: "/content/featured-items",
                name: intl.formatMessage({ id: "Featured Items" }),
                component: ContentFeaturedItemAdmin,
                permissions: ["content-featured-items-edit"],
            },
            {
                path: "/content/links",
                name: intl.formatMessage({ id: "Links" }),
                component: LinkAdmin,
                permissions: ["content-pages-edit"],
            },
            {
                path: "/content/external-dashboards",
                name: intl.formatMessage({ id: "External Dashboards" }),
                component: ExternalDashboardAdmin,
                permissions: ["content-external-dashboards-edit"],
            },
        ],
        customForms: [
            {
                path: "/custom-forms",
                name: intl.formatMessage({ id: "Custom Forms" }),
                component: CustomFormAdmin,
                permissions: ["admin-custom-forms-edit"],
            },
        ],
        assets: [
            {
                path: "/assets",
                name: intl.formatMessage({ id: "Assets" }),
                component: AssetAdmin,
                permissions: ["dam-asset-admin"],
            },
            {
                path: "/campaigns",
                name: intl.formatMessage({ id: "Campaigns" }),
                component: CampaignAdmin,
                permissions: ["dam-campaign-create", "dam-campaign-edit"],
            },
        ],
        mediaChannels: [
            {
                path: "/media-channels",
                name: intl.formatMessage({ id: "Media Channels" }),
                component: MediaChannelAdmin,
                permissions: ["dam-media-channel-edit"],
            },
        ],
        products: [
            {
                path: "/products",
                name: intl.formatMessage({ id: "Products" }),
                component: ProductAdmin,
                permissions: ["products-edit"],
            },
        ],
        calendarEvent: [
            {
                path: "/calendar-events",
                name: intl.formatMessage({ id: "Calendar Events" }),
                component: CalendarEventAdmin,
                permissions: ["admin-calendar-events-edit"],
            },
        ],
        communicationRules: [
            {
                path: "/communication-rules",
                name: intl.formatMessage({ id: "Communication Rules" }),
                component: CommunicationRuleAdmin,
                permissions: ["admin-automated-communication-rules-edit"],
            },
        ],
        // groupProfile: [
        //     {
        //         path: "/group-profile",
        //         name: intl.formatMessage({ id: "Group Profile" }),
        //         component: GroupProfileAdmin,
        //         permissions: ["group-profile-edit"],
        //     },
        // ],
        // requests: [
        //     {
        //         path: "/request-types",
        //         name: intl.formatMessage({ id: "Request Types" }),
        //         component: RequestTypeAdmin,
        //         permissions: ["admin-request-types-edit"],
        //     },
        // ],
        // workflow: [
        //     {
        //         path: "/workflows",
        //         name: intl.formatMessage({ id: "Workflows" }),
        //         component: WorkflowAdmin,
        //         permissions: ["admin-workflow"],
        //     },
        // ],
        requestAndWorkflow: [
            {
                path: "/request-types",
                name: intl.formatMessage({ id: "Request Types" }),
                component: RequestTypeAdmin,
                permissions: ["admin-request-types-edit"],
            },

            {
                path: "/workflows",
                name: intl.formatMessage({ id: "Workflows" }),
                component: WorkflowAdmin,
                permissions: ["admin-workflow"],
            },
        ],
        navigation: [
            {
                path: "/navigation",
                name: intl.formatMessage({ id: "Navigation" }),
                component: NavigationAdmin,
                permissions: ["admin-navigation-edit"],
            },
        ],
        dashboardPanels: [
            {
                path: "/dashboard-panels",
                name: intl.formatMessage({ id: "Dashboard Panels" }),
                component: DashboardPanelAdmin,
                permissions: ["admin-dashboard-panels-edit"],
            },
        ],
        forms: [
            {
                path: "/forms",
                name: intl.formatMessage({ id: "Forms/Surveys" }),
                component: SurveyManagementApp,
                permissions: ["surveys-manage"],
            },
        ],
        events: [
            {
                path: "/event-categories",
                name: intl.formatMessage({ id: "Event Categories" }),
                component: EventCategoryAdmin,
                permissions: ["events-booking"],
            },
            {
                path: "/inventory-categories",
                name: intl.formatMessage({ id: "Inventory Categories" }),
                component: InventoryCategoryAdmin,
                permissions: ["events-booking"],
            },
            {
                path: "/inventory",
                name: intl.formatMessage({ id: "Inventory Management" }),
                component: InventoryManager,
                permissions: ["events-booking"],
            },
        ],
        translations: [
            {
                path: "/translations",
                name: intl.formatMessage({ id: "Translations" }),
                component: TranslationsListing,
                permissions: ["admin"],
            },
        ],
    };
};
const flattenPermissionsCollection = (permissions: PermissionType[]) =>
    permissions.reduce((permissionAliases, permission) => {
        return [...permissionAliases, permission.alias];
    }, []);
const filterAvailableRoutes = (routes, permissionAliases) => {
    return routes.filter((route) =>
        route.permissions.some((item) => permissionAliases.includes(item))
    );
};
const getAdminRoutesForPermissions = (permissions: PermissionType[]) => {
    const permissionAliases = flattenPermissionsCollection(permissions);
    const intl = useIntl();

    const allAdminRoutes = generateRoutes(intl);
    // console.log(permissionAliases);
    return Object.entries(allAdminRoutes).reduce(
        (adminRoutes, [routeGroup, routes]) => {
            // console.log(routeGroup, routes);
            return {
                ...adminRoutes,
                [routeGroup]: filterAvailableRoutes(routes, permissionAliases),
            };
        },
        {}
    );
};

export default () => {
    const { permissions }: { permissions: PermissionType[] } = usePermissions();
    // console.log(permissions);
    const availableAdminRoutes: Routes =
        getAdminRoutesForPermissions(permissions);

    return { availableAdminRoutes };
};
