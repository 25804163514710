import React from "react";
import { trans } from "@cortexglobal/rla-intl";
import { Link } from "react-router-dom";
import {
    TableList,
    Button,
    Loading,
    Badge,
} from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";

const OrderRow = ({ order, path }) => {
    const renderAssignedTo = (workflow) => {
        let assignedTo = [];

        if (workflow) {
            if (workflow.assigned_to_group) {
                assignedTo.push(workflow.assigned_to_group);
            }

            if (workflow.assigned_to_team) {
                assignedTo.push(workflow.assigned_to_team);
            }

            if (workflow.assigned_to_role) {
                assignedTo.push(workflow.assigned_to_role);
            }

            if (workflow.assigned_to_user) {
                assignedTo.push(workflow.assigned_to_user);
            }
        }

        return assignedTo.join(", ");
    };
    console.log("order", order);
    return (
        <tr>
            <td>
                <Badge type="warning">{order.ref}</Badge>
            </td>
            <td>
                {order.user_asset
                    ? order.user_asset.name
                    : order.asset
                    ? order.asset.name
                    : "-"}
            </td>
            <td>
                <span style={{ textTransform: "capitalize" }}>
                    {order.type}
                </span>
            </td>
            <td>{order.user ? order.user.name : "-"}</td>
            <td>{renderAssignedTo(order.workflow) || "Unknown"}</td>
            <td>{order.workflow?.status || "Unknown"}</td>
            <td>{format(new Date(order.created_at), "PP")}</td>
            <td>{format(new Date(order.updated_at), "PP")}</td>
            <td>
                <Link to={`${path}/${order.uuid}`}>
                    <Button>{trans("View Order")}</Button>
                </Link>
            </td>
        </tr>
    );
};

const OrderTable = ({ orders, path, loading, heading }) => {
    return (
        <TableList className="orders-table" heading={heading}>
            <thead>
                <tr>
                    <th>{trans("Ref")}</th>
                    <th>{trans("Asset Name")}</th>
                    <th>{trans("Type")}</th>
                    <th>{trans("Owner")}</th>
                    <th>{trans("Assigned To")}</th>
                    <th>{trans("Status")}</th>
                    <th>{trans("Created")}</th>
                    <th>{trans("Last Updated")}</th>
                    <th />
                </tr>
            </thead>

            {loading ? (
                <Loading />
            ) : orders.length > 0 ? (
                <tbody>
                    {orders.map((order) => {
                        return <OrderRow path={path} order={order} />;
                    })}
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan="100%">
                            <p>{trans("No orders found")}</p>
                        </td>
                    </tr>
                </tbody>
            )}
        </TableList>
    );
};

export default OrderTable;
