import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { get, isEmpty } from "lodash";
import {
    Row,
    Column,
    InputField,
    SelectField,
    Button,
    DatePicker,
    ContentBlockLoader,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const DrillDownConfiguration = ({
    title,
    timeFrame,
    timeInterval,
    journey,
    fromDate,
    toDate,
    panel,
    panelId,
    submitPanel,
}) => {
    const intl = useIntl();
    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Drill down table" }),
        timeFrame: timeFrame ? timeFrame : "",
        journey: journey ? journey : "",
        timeInterval: timeInterval ? timeInterval : "",
        fromDate: fromDate ? fromDate : "",
        toDate: toDate ? toDate : "",
    });

    const [journeyOptions, setJourneyOptions] = useState([]);

    useEffect(() => {
        axios
            .get(`/api/v1/drilldown-journeys`)
            .then(({ data }) => {
                setJourneyOptions(data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    const timeFrameOptions = [
        { value: "all", text: intl.formatMessage({ id: "All Time" }) },
        {
            value: "rolling-days",
            text: intl.formatMessage({ id: "Rolling (days)" }),
        },
        {
            value: "rolling-qtr",
            text: intl.formatMessage({ id: "Rolling (qtr)" }),
        },
        { value: "date-range", text: intl.formatMessage({ id: "Date range" }) },
        //      { value: "date-range-picker", text: intl.formatMessage({ id: "Date range Picker})" }
    ];

    const rollingDaysOptions = [
        { value: "7-days", text: intl.formatMessage({ id: "Last 7 days" }) },
        { value: "14-days", text: intl.formatMessage({ id: "Last 14 days" }) },
        { value: "30-days", text: intl.formatMessage({ id: "Last 30 days" }) },
    ];

    const rollingQtrOptions = [
        { value: "this-qtr", text: intl.formatMessage({ id: "This Quarter" }) },
        // { value: "this-1-year", text: intl.formatMessage({ id: "Year to date"}) },
        {
            value: "last-qtr",
            text: intl.formatMessage({ id: "Previous Quarter" }),
        },
        // { value: "last-2-qtr", text: intl.formatMessage({ id: "Last 2 Quarters"}) },
        // { value: "last-year-qtr", text: intl.formatMessage({ id: "Same quarter last year})" }
    ];

    const timeOptionsAvailable = () => {
        if (isEmpty(journeyOptions)) {
            return false;
        }

        let res = false;
        journeyOptions.forEach((item) => {
            if (item.value === state.journey) {
                res = item.date_options;
            }
        });

        return res;
    };

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                submitPanel(panel, state, panelId);
            }}
        >
            <Row>
                <Column>
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder="Custom Title"
                        onChange={onChange}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    {" "}
                    <ContentBlockLoader loaded={!isEmpty(journeyOptions)}>
                        <SelectField
                            name="journey"
                            label={trans("Journey")}
                            value={state.journey}
                            options={journeyOptions}
                            onChange={onChange}
                            expanded
                        />
                    </ContentBlockLoader>
                </Column>
            </Row>
            {timeOptionsAvailable() && (
                <Row>
                    <Column large={6}>
                        <SelectField
                            name="timeFrame"
                            label={trans("Time Frame")}
                            value={state.timeFrame}
                            options={timeFrameOptions}
                            onChange={onChange}
                            expanded
                        />
                    </Column>
                    {state.timeFrame === "rolling-days" && (
                        <Column large={6}>
                            <SelectField
                                name="timeInterval"
                                label={trans("Time interval")}
                                value={state.timeInterval}
                                options={rollingDaysOptions}
                                onChange={onChange}
                                expanded
                            />
                        </Column>
                    )}
                    {state.timeFrame === "rolling-qtr" && (
                        <Column large={6}>
                            <SelectField
                                name="timeInterval"
                                label={trans("Time interval")}
                                value={state.timeInterval}
                                options={rollingQtrOptions}
                                onChange={onChange}
                                expanded
                            />
                        </Column>
                    )}
                    {state.timeFrame === "date-range" && (
                        <Column large={6}>
                            <DatePicker
                                name="fromDate"
                                label={trans("From Date")}
                                style={{ display: "block" }}
                                value={state.fromDate}
                                onChange={onChange}
                                allowEmpty={true}
                            />
                            <DatePicker
                                name="toDate"
                                label={trans("To Date")}
                                style={{ display: "block" }}
                                value={state.toDate}
                                onChange={onChange}
                                allowEmpty={true}
                            />
                        </Column>
                    )}
                </Row>
            )}
            <Row>
                <Column>
                    <Button type="submit">{trans("Save Panel")}</Button>
                </Column>
            </Row>
        </form>
    );
};

DrillDownConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default DrillDownConfiguration;
