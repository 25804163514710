import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { format } from "@cortexglobal/cortex-utilities";

import { EmptyRow } from "../../components/EmptyRow";
import { CalendarEvent } from "../../forms/CalendarEventForm";

const PaddedDiv = styled.div`
    padding: 3em;
`;
const TableHeader = styled.div`
    cursor: pointer;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`;
export const CalendarEventTable = ({
    items,
    loaded,
    path,
    pagination = null,
    deleteItem,
    onPageChange,
    onOrderBy,
    onPerPageChange,
}: CalendarEventsTableProps) => {
    const [
        deleteCalendarEventModalShowing,
        setDeleteCalendarEventModalShowing,
    ] = useState(false);
    const [deleteCalendarEventName, setCalendarEventName] = useState("");
    const [deleteCalendarEventId, setCalendarEventId] = useState(null);

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>{trans("Title")}</th>
                        <th>{trans("Category")}</th>
                        <th>
                            <TableHeader
                                onClick={() => onOrderBy("start_date")}
                            >
                                {trans("Start")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("end_date")}>
                                {trans("End")}
                            </TableHeader>
                        </th>

                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <EmptyRow colSpan={3}>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow colSpan={3}>
                            {trans("No items found")}
                        </EmptyRow>
                    ) : (
                        items.map((item) => {
                            return (
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{item?.event_category?.text}</td>
                                    <td>{format(new Date(item.start_date))}</td>
                                    <td>{format(new Date(item.end_date))}</td>
                                    <td>
                                        <IconTextButton
                                            as={Link}
                                            to={`${path}/edit/${item.uuid}`}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEdit}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            type="alert"
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                            onClick={(event) => {
                                                setCalendarEventId(item.uuid);
                                                setDeleteCalendarEventModalShowing(
                                                    true
                                                );
                                                setCalendarEventName(
                                                    item.title
                                                );
                                            }}
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
            {deleteCalendarEventModalShowing && (
                <Modal
                    visible={deleteCalendarEventModalShowing}
                    onClose={() => {
                        setDeleteCalendarEventModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h1>
                            {trans("You are about delete calendar event:")}{" "}
                            {deleteCalendarEventName}
                        </h1>

                        <p>{trans("Are you sure you want to proceed?")}</p>
                        <Button
                            type="hollow"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeleteCalendarEventModalShowing(false);
                            }}
                        >
                            {trans("Cancel")}
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                deleteItem(deleteCalendarEventId).catch((e) =>
                                    setDeleteCalendarEventModalShowing(false)
                                );
                            }}
                        >
                            {trans("Delete")}
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}
        </React.Fragment>
    );
};

interface CalendarEventsTableProps {
    items: CalendarEvent[];
    loaded: boolean;
    path: string;
    pagination?: any;
    deleteItem: (number) => Promise<void>;
    onPageChange?: (number) => void;
    onOrderBy?: (string) => void;
    onPerPageChange?: ({ value }) => void;
}

export default CalendarEventTable;
