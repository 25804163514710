import React, { useState } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { Link } from "react-router-dom";
import {
    DetailsContent,
    DetailsLabel,
    DetailWrapper,
    SpacedRow,
} from "./overviewComponents";
import {
    Button,
    Icon,
    Loading,
    Tooltip,
    Column,
} from "@cortexglobal/rla-components";

const ActivityApprovalDetails = ({ task, activity, onUpdate, children }) => {
    const [loading, setLoading] = useState(false);

    const requestPayment = (activity) => {
        if (activity.uuid === undefined) {
            return;
        }

        setLoading(true);

        axios
            .post(`/api/v1/planner/activities/${activity.uuid}/request-payment`)
            .then(({ data }) => {
                onUpdate(activity);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!task || !activity || loading) {
        return <Loading />;
    }

    const userCanRequestPayment =
        activity?.available_user_actions?.includes("request-payment");

    return (
        <DetailWrapper>
            {!task ? (
                <Tooltip
                    text={trans(
                        "This activity is not yet associated with a campaign."
                    )}
                >
                    <Icon name="cross" color="alert" />
                </Tooltip>
            ) : (
                <>
                    <SpacedRow className="detailRow">
                        <Column style={{ paddingLeft: "0" }} small={6}>
                            <b>{trans("Plan Approval")}</b>
                        </Column>
                        <Column small={6} style={{ textAlign: "right" }}>
                            {task.plan?.approval_request?.uuid ? (
                                <Link
                                    to={
                                        "/requests/view/" +
                                        task.plan.approval_request.uuid
                                    }
                                >
                                    {task.plan.approval_status}
                                </Link>
                            ) : (
                                <Tooltip
                                    text={
                                        task.plan?.approval_status ||
                                        trans("Not requested")
                                    }
                                    showIcon={false}
                                >
                                    <Icon name="cross" color="alert" />
                                </Tooltip>
                            )}
                        </Column>
                    </SpacedRow>
                </>
            )}
        </DetailWrapper>
    );
};

export default ActivityApprovalDetails;
