import React, { useState } from "react";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
    Row,
    Column,
    InputField,
    FormRow,
    SimpleDropzone,
    IconTextButton,
    CsvDownload,
    useAddToast,
    RadioField,
    HelpText,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const DirectMailOrderForm = ({ order, standardOnChange, errors }) => {
    const [recipientsUploaded, setRecipientsUploaded] = useState(null);
    const addToast = useAddToast();
    const intl = useIntl();
    //Get the direct_mail_details from the order. If it doesn't exist, create it
    //can't use destructing because it can be null
    let { direct_mail_details } = order;
    if (!direct_mail_details) {
        direct_mail_details = { proof_required: false, delivery_method: "" };
    }
    const onChange = ({ name, value }) => {
        standardOnChange({
            name: "direct_mail_details",
            value: { ...direct_mail_details, [name]: value },
        });
    };

    const onDrop = (acceptedFiles, key) => {
        standardOnChange({
            name: "direct_mail_details",
            value: { ...direct_mail_details, [key]: acceptedFiles[0] },
        });

        let fd = new FormData();
        fd.append("file", acceptedFiles[0]);

        axios
            .post(`/api/v1/orders/${order.uuid}/recipients`, fd)
            .then(({ data }) => {
                const { recipients_count } = data;
                setRecipientsUploaded(recipients_count);
                addToast({
                    content: trans(
                        "{count, plural, =0 {no recipients uploaded} one {# recipient uploaded} other {# recipients uploaded}}",
                        {
                            count: recipients_count,
                        }
                    ),
                    showFor: 5000,
                });
            });
    };

    const csvHeaders = [
        { label: "Name", key: "name" },
        { label: "Address 1", key: "address_1" },
        { label: "Address 2", key: "address_2" },
        { label: "Town/City", key: "town_city" },
        { label: "County/State", key: "county" },
        { label: "Postcode", key: "postcode" },
    ];

    return (
        <>
            <FormRow
                name="recipients"
                label={trans("Recipient Data")}
                helpText={trans(
                    "Please upload a list of all contacts, using the template provided"
                )}
                error={errors.recipients}
            >
                <Column medium={6}>
                    <SimpleDropzone
                        onDrop={(acceptedFiles) =>
                            onDrop(acceptedFiles, "recipients")
                        }
                        multiple={false}
                    >
                        <IconTextButton
                            onClick={() => {
                                return false;
                            }}
                            icon={faUpload}
                            showCircle={false}
                            expanded={false}
                        >
                            <div
                                style={{
                                    fontWeight: "400",
                                }}
                            >
                                {recipientsUploaded
                                    ? trans(
                                          "{count, plural, =0 {no recipients uploaded} one {# recipient uploaded} other {# recipients uploaded}}",
                                          {
                                              count: recipientsUploaded,
                                          }
                                      )
                                    : trans("Upload")}
                            </div>
                        </IconTextButton>
                    </SimpleDropzone>
                </Column>
                <Column medium={6}>
                    <CsvDownload headers={csvHeaders} filename="recipients.csv">
                        <IconTextButton
                            onClick={() => {
                                return false;
                            }}
                            icon={faDownload}
                            showCircle={false}
                            expanded={false}
                        >
                            <span
                                style={{
                                    textDecoration: "none",
                                    border: "none !important",
                                }}
                            >
                                {trans("Download Template")}
                            </span>
                        </IconTextButton>
                    </CsvDownload>
                </Column>
            </FormRow>
            <FormRow
                name="proof_required"
                label={trans("Proof Required")}
                error={errors.proof_required}
            >
                <RadioField
                    name="proof_required"
                    value={direct_mail_details.proof_required}
                    showError={false}
                    inlineRadioButtons={true}
                    onChange={onChange}
                    options={[
                        {
                            text: intl.formatMessage({ id: "Yes" }),
                            value: true,
                        },
                        {
                            text: intl.formatMessage({ id: "No" }),
                            value: false,
                        },
                    ]}
                />
            </FormRow>
            <FormRow
                name="delivery_method"
                label={trans("Delivery Method")}
                helpText={trans(
                    "Please enter a delivery method, first class etc."
                )}
                error={errors.delivery_method}
            >
                <InputField
                    name="delivery_method"
                    value={direct_mail_details.delivery_method}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <Row>
                <Column medium={3} style={{ textAlign: "right" }}>
                    <h3 style={{ margin: "1rem 0 0 0" }}>
                        {trans("Gone Away Address")}
                    </h3>
                    <HelpText>
                        {trans("The address to send any unsent messages")}
                    </HelpText>
                </Column>
            </Row>
            <FormRow
                name="address_1"
                label={trans("Address 1")}
                error={errors.address_1}
            >
                <InputField
                    name="address_1"
                    value={direct_mail_details.address_1}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="address_2"
                label={trans("Address 2")}
                error={errors.address_2}
            >
                <InputField
                    name="address_2"
                    value={direct_mail_details.address_2}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="town_city"
                label={trans("Town/City")}
                error={errors.town_city}
            >
                <InputField
                    name="town_city"
                    value={direct_mail_details.town_city}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="county_state"
                label={trans("County/State")}
                error={errors.county}
            >
                <InputField
                    name="county_state"
                    value={direct_mail_details.county_state}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="postcode"
                label={trans("Postcode")}
                error={errors.postcode}
            >
                <InputField
                    name="postcode"
                    value={direct_mail_details.postcode}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="country"
                label={trans("Country")}
                error={errors.country}
            >
                <InputField
                    name="country"
                    value={direct_mail_details.country}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
        </>
    );
};

export default DirectMailOrderForm;
