import React from "react";
import styled, { css, withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableList } from "@cortexglobal/rla-components";
import { trans } from"@cortexglobal/rla-intl";

const TableRow = styled.tr`
    ${props =>
        props.selected &&
        css`
            background-color: ${props.theme.table.rowHighlight} !important;
        `};
`;

const CampaignTable = props => {
    return (
        <div>
            {/* <h3>{trans("Live Campaigns")}</h3> */}
            <div>
                <TableList className="dashboard-table">
                    <thead>
                        <tr>
                            <th />
                            <th>{trans("Name")}</th>
                            <th>{trans("Send date")}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {props.campaigns.map((campaign, index) => {
                            return (
                                <TableRow
                                    theme={props.theme}
                                    key={index}
                                    index={index}
                                    selected={
                                        props.selectedCampaign &&
                                        campaign.uuid ===
                                            props.selectedCampaign.uuid
                                    }
                                >
                                    <td>
                                        {campaign.status === "green" ? (
                                            <span
                                                style={{
                                                    color:
                                                        props.theme.colors
                                                            .success
                                                }}
                                            >
                                                <FontAwesomeIcon icon="check-circle" />
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    color:
                                                        props.theme.colors.alert
                                                }}
                                            >
                                                <FontAwesomeIcon icon="times-circle" />
                                            </span>
                                        )}
                                    </td>
                                    <td>{campaign.name}</td>
                                    <td>{campaign.sendDate}</td>
                                    <td>
                                        <div
                                            onClick={props.selectCampaign.bind(
                                                this,
                                                campaign
                                            )}
                                        >
                                            <FontAwesomeIcon icon="chart-line" />
                                        </div>
                                    </td>
                                </TableRow>
                            );
                        })}
                    </tbody>
                </TableList>
            </div>
        </div>
    );
};

export default withTheme(CampaignTable);
