import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
// import styled from "styled-components";
import {
    // Button,
    // ContentBlockLoader,
    SubmitButton,
    // HelpText,
    // FormLabel,
    // FormikSelect,
    FormikInput,
    FormikTextarea,
    // FormikDatePicker,
    // Panel,
    // Loading,
    Row,
    Column,
    FormRow,
    // SimpleDropzone
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    name: Yup.string(),
    primary: Yup.string(),
    // description: Yup.string(),
    phone: Yup.string(),
    fax: Yup.string(),
    email: Yup.string(),
    website: Yup.string(),
    address_building: Yup.string().nullable(),
    address_street: Yup.string().nullable(),
    // address_neighbourhood: Yup.string().nullable(),
    address_suburb: Yup.string().nullable(),
    address_city: Yup.string().nullable(),
    // address_county: Yup.string().nullable(),
    address_state: Yup.string().nullable(),
    postcode: Yup.string(),
    country: Yup.string(),
    notes: Yup.string(),
});
export type Contact = ReturnType<typeof schema.validateSync>;

export const ContactForm = ({ item, submitForm }) => {
    if (item === null) {
        item = {
            name: "",
            primary: "",
            phone: "",
            fax: "",
            email: "",
            website: "",
            address_building: "",
            address_street: "",
            // address_neighbourhood: "",
            address_suburb: "",
            address_city: "",
            // address_county: "",
            address_state: "",
            postcode: "",
            country: "",
            notes: "",
        };
    }
    const handleSubmit = (
        values: Contact,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting, "contact").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                name: item.name,
                primary: item.primary,
                phone: item.phone,
                fax: item.fax,
                email: item.email,
                website: item.website,
                address_building: item.address_building,
                address_street: item.address_street,
                // address_neighbourhood: item.address_neighbourhood,
                address_suburb: item.address_suburb,
                address_city: item.address_city,
                // address_county: item.address_county,
                address_state: item.address_state,
                postcode: item.postcode,
                country: item.country,
                notes: item.notes,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Optional")}
                            error={errors.name}
                        >
                            <FormikInput name="name" value={values.name} />{" "}
                        </FormRow>

                        <FormRow
                            name="primary"
                            label={trans("Primary contact")}
                            helpText={trans("Optional")}
                            error={errors.primary}
                        >
                            <FormikInput
                                name="primary"
                                value={values.primary}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="phone"
                            label={trans("Phone")}
                            helpText={trans("Optional")}
                            error={errors.phone}
                        >
                            <FormikInput name="phone" value={values.phone} />{" "}
                        </FormRow>
                        <FormRow
                            name="fax"
                            label={trans("Fax")}
                            helpText={trans("Optional")}
                            error={errors.fax}
                        >
                            <FormikInput name="fax" value={values.fax} />{" "}
                        </FormRow>
                        <FormRow
                            name="email"
                            label={trans("Email")}
                            helpText={trans("Optional")}
                            error={errors.email}
                        >
                            <FormikInput name="email" value={values.email} />{" "}
                        </FormRow>
                        <FormRow
                            name="website"
                            label={trans("Website")}
                            helpText={trans("Optional")}
                            error={errors.website}
                        >
                            <FormikInput
                                name="website"
                                value={values.website}
                            />{" "}
                        </FormRow>

                        <FormRow>
                            <h3>{trans("Address")}</h3>
                        </FormRow>

                        <FormRow
                            name="address_building"
                            label={trans("Building Name/Number")}
                            helpText={trans("Optional")}
                            error={errors.address_building}
                        >
                            <FormikInput
                                name="address_building"
                                value={values.address_building}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="address_street"
                            label={trans("Street")}
                            helpText={trans("Optional")}
                            error={errors.address_street}
                        >
                            <FormikInput
                                name="address_street"
                                value={values.address_street}
                            />{" "}
                        </FormRow>

                        {/*
                        <FormRow
                            name="address_neighbourhood"
                            label={trans("Neighbourhood")}
                            helpText={trans("Optional")}
                            error={errors.address_neighbourhood}
                        >
                            <FormikInput
                                name="address_neighbourhood"
                                value={values.address_neighbourhood}
                            />{" "}
                        </FormRow>
                        */}

                        <FormRow
                            name="address_suburb"
                            label={trans("Suburb")}
                            helpText={trans("Optional")}
                            error={errors.address_suburb}
                        >
                            <FormikInput
                                name="address_suburb"
                                value={values.address_suburb}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="address_city"
                            label={trans("Town/City")}
                            helpText={trans("Optional")}
                            error={errors.address_city}
                        >
                            <FormikInput
                                name="address_city"
                                value={values.address_city}
                            />{" "}
                        </FormRow>

                        {/*
                        <FormRow
                            name="address_county"
                            label={trans("County")}
                            helpText={trans("Optional")}
                            error={errors.address_county}
                        >
                            <FormikInput
                                name="address_county"
                                value={values.address_county}
                            />{" "}
                        </FormRow>
                        */}

                        <FormRow
                            name="address_state"
                            label={trans("State")}
                            helpText={trans("Optional")}
                            error={errors.address_state}
                        >
                            <FormikInput
                                name="address_state"
                                value={values.address_state}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="postcode"
                            label={trans("Postcode")}
                            helpText={trans("Optional")}
                            error={errors.postcode}
                        >
                            <FormikInput
                                name="postcode"
                                value={values.postcode}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="country"
                            label={trans("Country")}
                            helpText={trans("Optional")}
                            error={errors.country}
                        >
                            <FormikInput
                                name="country"
                                value={values.country}
                            />{" "}
                        </FormRow>

                        <Row>
                            <Column medium={6}>&nbsp;</Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ContactForm;
