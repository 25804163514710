import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import {
    PageTitle,
    IconTextButton,
    Column,
    Tabordion,
    Panel,
    Row,
    Modal,
    Badge,
} from "@cortexglobal/rla-components";

import MessageList from "./components/MessageList";
import ViewPane from "./components/ViewPane";
import Message from "./objects/Message";
import Composer from "./components/Composer";

import MessagesContext from "./MessagesContext";
import { isEmpty } from "lodash";

const MinHeightPanel = styled(Panel)`
    min-height: 50vh;
`;

const Wrapper = styled(Row)`
    height: 100%;
    margin: auto;
`;

const Communications = (props) => {
    const { can } = usePermissions();

    const [showComposer, toggleComposer] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [composerMessage, setComposerMessage] = useState({
        format: "new",
        to: null,
        user_uuid: null,
        subject: "",
        message: { ops: [] },
        send_now: true,
        send_date: "",
        send_time: "",
    });
    const [deletedMessageUuid, setDeletedMessageUuid] = useState(null);
    const [triggerSentRefresh, setTriggerSentRefresh] = useState(0);
    const [settings, setSettings] = useState({
        state: "loading",
        groups: [],
        config: [],
        mailing_lists: [],
        tags: [],
    });
    const [boxTotal, setBoxTotal] = useState(0);
    const [inboxTotal, setInboxTotal] = useState(0);
    const [outboxTotal, setOutboxTotal] = useState(0);
    const [notificationsTotal, setNotificationsTotal] = useState(0);

    // const [unreadTotals, setUnreadTotals] = useState({
    //     inbox: 0,
    //     outbox: 0,
    //     notifications: 0,
    // });

    const [emptyViewPaneMessage, setEmptyViewPaneMessage] =
        "Please select a message";

    useEffect(() => {
        axios
            .get(`/api/v1/communication/settings`)
            .then(({ data }) => {
                console.log(data);

                setSettings({
                    state: "loaded",
                    ...data,
                });
            })
            .catch((e) => {
                setSettings({ ...settings, state: "error" });
            });
    }, []);

    const setShowComposer = (composerMessage) => {
        setComposerMessage(composerMessage);
        toggleComposer(true);
    };

    const setBoxUnreadTotal = (box, total) => {
        switch (box) {
            case "inbox":
                setInboxTotal(total);
                break;
            case "outbox":
                setOutboxTotal(total);
                break;
            case "notifications":
                setNotificationsTotal(total);
                break;
        }
    };

    const compose = () => {
        setShowComposer({
            format: "new",
            to: null,
            user_uuid: null,
            subject: "",
            message: "",
            send_now: true,
            send_date: "",
            send_time: "",
        });
    };

    const reply = (message) => {
        if (message.can_reply) {
            setShowComposer({
                format: "reply",
                to: message.sender.name,
                user_uuid: message.sender.uuid,
                subject: "Re: " + message.subject,
                message: message.messageWithHeader(),
                send_now: true,
                send_date: "",
                send_time: "",
            });
        }
    };

    const forward = (message) => {
        if (message.can_forward) {
            setShowComposer({
                format: "forward",
                to: null,
                user_uuid: null,
                subject: "FW: " + message.subject,
                message: message.messageWithHeader(),
                send_now: true,
                send_date: "",
                send_time: "",
            });
        }
    };

    const destroy = (message) => {
        if (!message.destroyed) {
            message.destroy();
            setDeletedMessageUuid(message.uuid);
            setSelectedMessage(null);
        }
    };

    const markAsDeleted = (message) => {
        if (!message.destroyed) {
        }
    };

    const messageSentCallback = () => {
        toggleComposer(false);
        setTriggerSentRefresh(triggerSentRefresh + 1);
    };

    return (
        <MessagesContext.Provider
            value={{
                selectedMessage,
                setSelectedMessage,
                boxTotal,
                setBoxTotal,
                // unreadTotals,
                // setBoxUnreadTotal,
                emptyViewPaneMessage,
                setEmptyViewPaneMessage,
            }}
        >
            <div id="communications" style={{ marginBottom: "20px" }}>
                <PageTitle title={trans("Communications")}>
                    {can("communication-send-messages") && (
                        <IconTextButton
                            style={{ textDecoration: "none" }}
                            icon={faPlus}
                            showCircle={false}
                            expanded={false}
                            onClick={() => compose()}
                        >
                            {trans("Compose Message")}
                        </IconTextButton>
                    )}
                </PageTitle>

                <Wrapper>
                    <Column medium={4}>
                        <MinHeightPanel padding={0}>
                            <Tabordion current={0} unmounts={false}>
                                {can("communication-view-messages") && (
                                    <MessageList
                                        key="inbox"
                                        heading={
                                            <span>
                                                {trans("Inbox")}{" "}
                                                {inboxTotal > 0 && (
                                                    <Badge
                                                        style={{
                                                            padding:
                                                                "0.2rem  0.6rem 0.2rem 0.6rem",
                                                        }}
                                                        type={"alert"}
                                                        fontSize={10}
                                                    >
                                                        {inboxTotal}
                                                    </Badge>
                                                )}
                                            </span>
                                        }
                                        box="inbox"
                                        affectReadStatus={true}
                                        deletedMessageUuid={deletedMessageUuid}
                                        setBoxUnreadTotal={setBoxUnreadTotal}
                                        unreadTotal={inboxTotal}
                                    />
                                )}
                                {can("communication-view-messages") && (
                                    <MessageList
                                        key="notifications"
                                        heading={
                                            <span>
                                                {trans("Notifications")}{" "}
                                                {notificationsTotal > 0 && (
                                                    <Badge
                                                        style={{
                                                            padding:
                                                                "0.2rem  0.6rem 0.2rem 0.6rem",
                                                        }}
                                                        type={"alert"}
                                                        fontSize={10}
                                                    >
                                                        {notificationsTotal}
                                                    </Badge>
                                                )}
                                            </span>
                                        }
                                        box="notifications"
                                        affectReadStatus={true}
                                        deletedMessageUuid={deletedMessageUuid}
                                        setBoxUnreadTotal={setBoxUnreadTotal}
                                        unreadTotal={notificationsTotal}
                                    />
                                )}
                                {can("communication-send-messages") && (
                                    <MessageList
                                        key="sent"
                                        heading={trans("Sent")}
                                        box="outbox"
                                        affectReadStatus={false}
                                        deletedMessageUuid={deletedMessageUuid}
                                        triggerRefresh={triggerSentRefresh}
                                        setBoxUnreadTotal={setBoxUnreadTotal}
                                        unreadTotal={outboxTotal}
                                    />
                                )}
                            </Tabordion>
                        </MinHeightPanel>
                    </Column>

                    <Column medium={8}>
                        <MinHeightPanel>
                            <Row>
                                <Column medium={12}>
                                    {selectedMessage instanceof Message && (
                                        <>
                                            <ViewPane
                                                selectedMessage={
                                                    selectedMessage
                                                }
                                                reply={reply}
                                                forward={forward}
                                                destroy={destroy}
                                            />
                                        </>
                                    )}
                                </Column>
                            </Row>

                            <Row>
                                <Column medium={12}>
                                    {!selectedMessage && (
                                        <div style={{ textAlign: "center" }}>
                                            {boxTotal > 0
                                                ? trans(
                                                      "Please select a message."
                                                  )
                                                : trans("No messages found.")}
                                        </div>
                                    )}
                                </Column>
                            </Row>
                        </MinHeightPanel>
                    </Column>
                </Wrapper>

                {showComposer && (
                    <Modal
                        key="composeMessage"
                        onClose={() => toggleComposer(false)}
                        visible={showComposer}
                        closeOnWrapperClick={false}
                    >
                        <Composer
                            settings={settings}
                            message={composerMessage}
                            successCallback={() => messageSentCallback()}
                            hideComposer={() => toggleComposer(false)}
                            mailingLists={settings?.mailing_lists}
                            allowRecipientSwitching={
                                !isEmpty(settings?.mailing_lists)
                            }
                        />
                    </Modal>
                )}
            </div>
        </MessagesContext.Provider>
    );
};

export default Communications;
