import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Row, Column } from "../../index";
import FilterSection from "./FilterSection";

const WrapperRow = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const Separator = styled.hr`
    margin: 1rem 0;
`;

let StandardFilters = ({
    filters,
    selectedFilters,
    onUpdate,
    onApply,
    singleColumn = false,
    // planner,
}) => {
    const Wrapper = singleColumn ? Row : WrapperRow;

    // const newFilters =
    //     planner && planner.order
    //         ? filters.filter((filter) => filter.name !== "media-channel")
    //         : filters;
    const newFilters = filters;

    return (
        <Wrapper>
            {newFilters.map((filter) => {
                return (
                    <React.Fragment key={filter.name}>
                        {singleColumn && <Separator />}
                        <FilterSection
                            filter={filter}
                            selectedFilters={selectedFilters}
                            onUpdate={onUpdate}
                            onApply={onApply}
                            singleColumn={singleColumn}
                        />
                    </React.Fragment>
                );
            })}
        </Wrapper>
    );
};

StandardFilters.displayName = "StandardFilters";

StandardFilters.propTypes = {
    /** Filters: Need to be of a certain shape, but this needs to be worked out.*/
    filters: PropTypes.array.isRequired,
    // filters: PropTypes.object.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
};

export default withTheme(StandardFilters);
// const mapStateToProps = (state) => {
//     return { planner: state.planner };
// };

// export default withTheme(connect(mapStateToProps, null)(StandardFilters));
