import React from "react";
import { Table, ContentBlockLoader } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const FurtherInformation = ({ asset }) => {
    const readableBytes = (bytes) => {
        var i = Math.floor(Math.log(bytes) / Math.log(1024)),
            sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        return (bytes / Math.pow(1024, i)).toFixed(1) * 1 + " " + sizes[i];
    };

    return (
        <Table tableType="noStripe">
            <tbody>
                <tr>
                    <th style={{ width: "33%" }}>{trans("Version")}</th>
                    <td>
                        <ContentBlockLoader loaded={asset.loaded}>
                            {asset.version
                                ? asset.version
                                : asset.versionNumber}
                        </ContentBlockLoader>
                    </td>
                </tr>

                {process.env.REACT_APP_MULTI_LOCALE_INSTANCE && (
                    <tr>
                        <th>{trans("Locale")}</th>
                        <td>
                            <ContentBlockLoader loaded={asset.loaded}>
                                {asset.locale}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                )}
                <tr>
                    <th>{trans("Author")}</th>
                    <td>
                        <ContentBlockLoader loaded={asset.loaded}>
                            {asset.author}
                        </ContentBlockLoader>
                    </td>
                </tr>

                <tr>
                    <th>{trans("Owner")}</th>
                    <td>
                        <ContentBlockLoader loaded={asset.loaded}>
                            {asset.created_by_group_name}
                        </ContentBlockLoader>
                    </td>
                </tr>

                <tr>
                    <th>{trans("Status")}</th>
                    <td>
                        <ContentBlockLoader loaded={asset.loaded}>
                            {asset.status_display}
                        </ContentBlockLoader>
                    </td>
                </tr>

                {asset.main_file_size && (
                    <tr>
                        <th>{trans("Filesize")}</th>
                        <td>
                            <ContentBlockLoader loaded={asset.loaded}>
                                {readableBytes(asset.main_file_size)}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                )}

                {asset.main_file_extension && !asset.editable && (
                    <tr>
                        <th>{trans("File Extension")}</th>
                        <td>
                            <ContentBlockLoader loaded={asset.loaded}>
                                {asset.main_file_extension.toUpperCase()}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                )}

                {asset.editable && (
                    <tr>
                        <th>{trans("Is Editable")}</th>
                        <td>
                            <ContentBlockLoader loaded={asset.loaded}>
                                {"Yes"}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                )}

                {asset.loaded &&
                    typeof asset.meta_data === "object" &&
                    Object.entries(asset.meta_data).map(
                        ([metaName, metaValue]) => {
                            return (
                                <tr key={metaName}>
                                    <th>{metaName}</th>
                                    <td>{metaValue}</td>
                                </tr>
                            );
                        }
                    )}

                <tr>
                    <th>{trans("Notes")}</th>
                    <td>
                        <ContentBlockLoader loaded={asset.loaded}>
                            {asset.notes}
                        </ContentBlockLoader>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default FurtherInformation;
