import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import GroupListing from "../group/GroupListing";
import GroupCreate from "../group/GroupCreate";
import { GroupEdit } from "../group/GroupEdit";

export const GroupAdmin = () => {
    const { path } = useRouteMatch();
    
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <GroupListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <GroupCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={props => {
                    const {
                        match: {
                            params: { uuid }
                        }
                    } = props;
                    return <GroupEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default GroupAdmin;
