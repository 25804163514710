import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTheme } from "styled-components";
import { useInterval } from "@cortexglobal/cortex-utilities";
import { Loading, Image } from "@cortexglobal/rla-components";

//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import AssetPreviewOverlay from "./AssetPreviewOverlay";

const InDesignPreview = ({ asset, userAsset, page = "page_1" }) => {
    const validAsset = userAsset ? userAsset : asset;

    const [loaded, setLoaded] = useState(false);

    const [remainingTries, setRemainingTries] = useState(45);
    const [url, setUrl] = useState("");
    const theme = useTheme();

    const getPreviewFromApi = () => {
        axios
            .get(`/api/v1/assets/${validAsset.uuid}/preview`, {
                params: { page: page.split("_").pop() },
            })
            .then(({ data }) => {
                if (data.data.url) {
                    setLoaded(true);
                    setUrl(data.data.url);
                    setRemainingTries(0);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (validAsset) {
            getPreviewFromApi();
        }
    }, [asset.updated_at, page]);

    useInterval(
        () => {
            if (
                (remainingTries && userAsset) ||
                (remainingTries && asset.userAsset)
            ) {
                getPreviewFromApi();
            }

            setRemainingTries(remainingTries - 1);
        },
        remainingTries < 1 ? null : 20000
    );

    return (
        <AssetPreviewOverlay>
            {!loaded ? (
                <Loading color={theme.asset.preview.color} />
            ) : (
                <Image
                    style={{
                        maxWidth: "100%",
                        minHeight: "300px",
                        maxHeight: "900px",
                        height: "auto",
                    }}
                    alt="asset thumbnail"
                    src={`${url}`}
                />
            )}
        </AssetPreviewOverlay>
    );
};

export default InDesignPreview;
