import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { faUpload, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoThumbnail from "react-video-thumbnail";

import { trans } from "@cortexglobal/rla-intl";
import {
    SimpleDropzone,
    Button,
    IconTextButton,
    Modal,
    useAddToast,
    useToasts,
    SubmitButton,
} from "@cortexglobal/rla-components";
import SequencedVideoEditor from "./SequencedVideoEditor";

const StyledButton = styled(Button)`
    margin-bottom: 1em;
`;

const EditButton = styled.div`
    display: none;
    position: absolute;
    top: 10px;
    right: -5px;
    background: white;
    padding: 4px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.4);
`;

const VideoThumbnailContainer = styled.div`
    position: relative;
    width: 50%;

    &:hover {
        ${EditButton} {
            display: flex;
        }
    }
`;

const SequencedVideo = ({
    name,
    value,
    onChange,
    originalOnChange,
    asset,
    path,
}) => {
    const [showEditor, setShowEditor] = useState(false);
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [videoUrl, setVideoUrl] = useState(
        value && value.videoSrc ? value.videoSrc : ""
    );
    const [video, setVideo] = useState();

    const dataURLtoBlob = (dataurl) => {
        let byteString = atob(dataurl.split(",")[1]);

        let mimeString = dataurl.split(",")[0].split(":")[1].split(";")[0];

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    };

    const error = (e) => {
        console.log(e.response);
        const message = e?.response?.data?.errors?.file
            ? e.response.data.errors.file
            : trans("An error occurred uploading your file");
        dispatch({ type: "removeToast", uuid: "uploadThumbnailToast" });
        addToast({
            type: "alert",
            content: message,
            showFor: 5000,
        });
    };

    const onThumbnailRendered = (thumbnail) => {
        const thumb = dataURLtoBlob(thumbnail);

        const image = new FormData();
        image.append("file", thumb);
        image.append("type", "image");
        image.append("asset_input_name", name);

        axios
            .post(`/api/v1/asset-file/${asset.uuid}`, image)
            .then(({ data: imgSrc }) => {
                axios
                    .post(`/api/v1/asset-file/${asset.uuid}`, video)
                    .then(({ data }) => {
                        dispatch({
                            type: "removeToast",
                            uuid: "uploadToast",
                        });
                        onChange({
                            name,
                            value: {
                                ...value,
                                videoSrc: data,
                                imageSrc: imgSrc,
                                thumbnailTime: 2,
                            },
                        });
                    })
                    .catch(error);
            })
            .catch(error);
    };

    const onDrop = (acceptedFiles) => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Asset is being uploaded"),
        });

        const fd = new FormData();
        fd.append("file", acceptedFiles[0]);
        fd.append("type", "video");
        fd.append("asset_input_name", name);

        const objectURL = URL.createObjectURL(acceptedFiles[0]);

        setVideoUrl(objectURL);
        setVideo(fd);
    };

    const onDeleteVideo = () => {
        setVideoUrl("");
        setVideo(undefined);

        onChange({
            name,
            value: {
                ...value,
                videoSrc: "",
                imageSrc: "",
                thumbnailTime: 2,
            },
        });
    };

    return (
        <React.Fragment>
            <div style={{ width: "0", height: "0", overflow: "hidden" }}>
                {videoUrl && (
                    <VideoThumbnailContainer>
                        <VideoThumbnail
                            snapshotAtTime={5}
                            videoUrl={videoUrl}
                            thumbnailHandler={(thumbnail) =>
                                value && value.imageSrc
                                    ? () => {}
                                    : onThumbnailRendered(thumbnail)
                            }
                            renderThumbnail={false}
                        />
                    </VideoThumbnailContainer>
                )}
            </div>

            <div>
                {value && value.videoSrc ? (
                    <>
                        <VideoThumbnailContainer>
                            <SimpleDropzone
                                onDrop={(acceptedFiles) => {
                                    onDeleteVideo();
                                    onDrop(acceptedFiles);
                                }}
                            >
                                <EditButton
                                    onClick={() => {
                                        return false;
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </EditButton>
                            </SimpleDropzone>

                            <img
                                src={value.imageSrc}
                                style={{ width: "100%", marginTop: "1em" }}
                            />
                        </VideoThumbnailContainer>
                        <SubmitButton
                            style={{ marginTop: "1em" }}
                            onClick={() => setShowEditor(true)}
                        >
                            {trans("Sub Claims and Video Sequencing")}
                        </SubmitButton>
                    </>
                ) : (
                    <SimpleDropzone onDrop={onDrop}>
                        <IconTextButton
                            style={{
                                textDecoration: "none",
                                paddingTop: "1rem",
                                paddingBottom: "0.8rem",
                            }}
                            onClick={() => {
                                return false;
                            }}
                            icon={faUpload}
                            showCircle={false}
                            expanded={false}
                        >
                            <div style={{ fontWeight: "400" }}>
                                {trans("Upload")}
                            </div>
                        </IconTextButton>
                    </SimpleDropzone>
                )}
            </div>
            <Modal
                visible={value && value.videoSrc && showEditor}
                onClose={() => {
                    setShowEditor(false);
                }}
                maxWidth="90%"
            >
                <SequencedVideoEditor
                    // originalVideo={videoUrl}
                    name={name}
                    asset={asset}
                    value={value}
                    onChange={onChange}
                    originalOnChange={originalOnChange}
                    onClose={() => setShowEditor(false)}
                    path={path}
                />
            </Modal>
        </React.Fragment>
    );
};

export default SequencedVideo;
