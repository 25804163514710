import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import storage from "local-storage-fallback";

import {
    Row,
    Column,
    SelectField,
    Button,
    Loading
} from "@cortexglobal/rla-components";

class PerformanceSummaryConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metric: this.props.metric ? this.props.metric : "",
            loaded: false,
            metrics: []
        };
    }

    componentWillMount() {
        //Get the Performance metrics from storage, or the network
        const metrics = JSON.parse(storage.getItem("performance_metrics_list"));
        //console.log("metrics", metrics);
        if (metrics) {
            this.setState({ metrics, loaded: true });
        } else {
            axios
                .get(`/api/v1/performance/metrics/territory`)
                .then(response => {
                    const loadedMetrics = response.data;
                    storage.setItem(
                        "performance_metrics_list",
                        JSON.stringify(loadedMetrics)
                    );
                    this.setState({
                        metrics: loadedMetrics,
                        loaded: true
                    });
                })
                .catch(e => e);
        }
    }
    onChange = input => {
        this.setState({ [input.name]: input.value });
    };
    submitForm = event => {
        event.preventDefault();
        const selectedMetric = this.state.metrics.filter(metric => {
            return metric.value === this.state.metric;
        })[0];
        this.props.submitPanel(
            this.props.panel,
            {
                metric: selectedMetric.value,
                title: selectedMetric.text
            },
            this.props.panelId
        );
    };
    render() {
        if (!this.state.loaded) {
            return <Loading />;
        }

        if (this.state.metrics.length <= 0) {
            return (
                <Row>
                    <Column>No performance metrics found</Column>
                </Row>
            );
        }
        return (
            <Row>
                <Column>
                    <form onSubmit={this.submitForm}>
                        <SelectField
                            name="metric"
                            label="Metric"
                            value={this.state.metric}
                            emptyOption="--Pick an Option--"
                            options={this.state.metrics}
                            onChange={this.onChange}
                            expanded
                        />
                        <Button type="submit">Save Panel</Button>
                    </form>
                </Column>
            </Row>
        );
    }
}

PerformanceSummaryConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired
};

export default PerformanceSummaryConfiguration;
