import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Carousel, CarouselItem, Loading } from "@cortexglobal/rla-components";

const CarouselImg = styled.img`
    height: 100%;
    margin: auto;
    max-height: 360px;
`;
const CarouselLink = styled(Link)`
    height: 100%;
    /* max-width: 100%; */
`;

const FeaturedItemCarousel = ({ alias, showEmptyCarousel, ...rest }) => {
    const [featuredItems, setItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        axios.get(`/api/v1/featured/${alias}`).then(({ data }) => {
            setItems(data.data);
            setLoaded(true);
        });
    }, [alias]);

    if (featuredItems.length <= 0 && showEmptyCarousel === false) {
        return null;
    }

    return (
        <div>
            {loaded ? (
                <Carousel
                    style={{ maxHeight: "360px", height: "30vw" }}
                    {...rest}
                >
                    {featuredItems.map(item => {
                        const isFullUrl = item.link.indexOf("http") === 0;
                        return (
                            <CarouselItem
                                key={item.uuid}
                                style={{
                                    ...{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden"
                                    },
                                    backgroundColor: item.background_color
                                }}
                            >
                                <CarouselLink
                                    as={isFullUrl ? "a" : Link}
                                    to={item.link}
                                    href={item.link}
                                >
                                    {item.banner ? (
                                        <CarouselImg
                                            src={`${item.banner}`}
                                            alt={`${
                                                item.title
                                                    ? item.title + " - "
                                                    : ""
                                            } ${item.description}`}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            <h2>{item.title}</h2>
                                            <p>{item.description}</p>
                                        </React.Fragment>
                                    )}
                                </CarouselLink>
                            </CarouselItem>
                        );
                    })}
                </Carousel>
            ) : (
                <div style={{ paddingTop: "1.5rem" }}>
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default FeaturedItemCarousel;
