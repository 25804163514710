import React, { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Row,
    Column,
    IconTextButton,
    SubmitButton,
    FormikInput,
    FormikDatePicker,
    FormikAdvancedSelect,
    FormikTextarea,
    FormikRadioArray,
    FormRow,
    FormikCurrencyInput,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans, injectIntl } from "@cortexglobal/rla-intl";
import { parseValidationErrors, format } from "@cortexglobal/cortex-utilities";

import {
    addActivity,
    editActivity,
    startOrderProcess,
} from "../plannerActions";
import PlannerFormikMediaChannelSelect from "../components/PlannerFormikMediaChannelSelect";

const ActivitySchema = Yup.object().shape({
    media_channel: Yup.string().required(
        trans("Please select a media channel")
    ),
    start_date: Yup.date().required(trans("Please pick a start date")),
    end_date: Yup.date()
        .min(
            Yup.ref("start_date"),
            trans("End date must be after the start date")
        )
        .required(trans("Please pick an end date")),

    cost: Yup.number().nullable(),
    planned_cost: Yup.number().nullable(),
    show_on_planner: Yup.string(),
    description: Yup.string().nullable(),
    status: Yup.string().nullable(),
});

const PlannerActivityForm = ({
    task,
    activity,
    history,
    user,
    startDate,
    endDate,
    intl,
    theme,
    editActivity,
    filters,
    onGoBack,
    startOrderProcess,
    category,
}) => {
    const addToast = useAddToast();
    const availableBudget = task
        ? task.planned_spend?.value ||
          0 -
              task.activities.reduce((actualTotal, activity) => {
                  return actualTotal + activity.cost?.value;
              }, 0)
        : 0;

    const availableAmountFormatted = intl.formatNumber(availableBudget, {
        style: "currency",
        currency: user?.currency?.iso_code || "GBP",
        currencyDisplay: "symbol",
        minimumFractionDigits: user.currency.decimal_places,
        maximumFractionDigits: user.currency.decimal_places,
    });

    const isPartOfPlan = task?.plan;
    const isApproved = isPartOfPlan && task.plan.is_approved;

    const submitForm = (values, { setErrors, setSubmitting }) => {
        //Append the task uuid to the data
        values["task_uuid"] = task.uuid;
        values["activity_uuid"] = activity.uuid;

        if (values["start_date"]) {
            values["start_date"] = format(values.start_date, "yyyy-MM-dd");
        }
        if (values["end_date"]) {
            values["end_date"] = format(values.end_date, "yyyy-MM-dd");
        }

        editActivity(values)
            .then((data) => {
                return onGoBack(task, category, data);
            })
            .catch((e) => {
                if (e.response && e.response.status === 422) {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);

                        setSubmitting(false);
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: e?.response?.data?.message
                            ? e.response.data.message
                            : trans("Error saving activity"),
                        showFor: 5000,
                    });
                    setSubmitting(false);
                }
            });
    };

    const initialStartDate = activity
        ? new Date(activity.start_date)
        : task && task.start_date
        ? new Date(task.start_date)
        : new Date(startDate);

    const initialEndDate = activity
        ? new Date(activity.end_date)
        : task && task.end_date
        ? new Date(task.end_date)
        : new Date(endDate);

    let statusOptions = [
        {
            value: "draft",
            text: intl.formatMessage({
                id: "Draft",
            }),
        },
        {
            value: "planned",
            text: intl.formatMessage({
                id: "Planned",
            }),
        },
        {
            value: "complete",
            text: intl.formatMessage({
                id: "Complete",
            }),
            disabled: !isApproved,
        },
        {
            value: "cancelled",
            text: intl.formatMessage({
                id: "Cancelled",
            }),
        },
    ];

    return (
        <>
            <Formik
                initialValues={{
                    start_date: initialStartDate,
                    end_date: initialEndDate,
                    cost: activity?.cost?.value || "",
                    show_on_planner: activity?.show_on_planner || "show",
                    description: activity?.description || "",
                    planned_cost: activity?.planned_cost?.value || "",
                    status: activity?.status || "draft",
                    media_channel: activity?.media_channel || "",
                }}
                onSubmit={submitForm}
                validationSchema={ActivitySchema}
            >
                {({ isSubmitting, values, errors }) => {
                    return (
                        <Form className="form-group">
                            <Row>
                                <Column>
                                    <FormRow
                                        name="media_channel"
                                        label={trans("Media Channel")}
                                        error={errors.media_channel}
                                        required={true}
                                    >
                                        <PlannerFormikMediaChannelSelect
                                            name="media_channel"
                                            value={values.media_channel}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="start_date"
                                        label={trans("Start Date")}
                                        error={errors.start_date}
                                    >
                                        <FormikDatePicker
                                            name="start_date"
                                            value={values.start_date}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="end_date"
                                        label={trans("End Date")}
                                        error={errors.end_date}
                                    >
                                        <FormikDatePicker
                                            name="end_date"
                                            value={values.end_date}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="status"
                                        label={trans("Status")}
                                        error={errors.status}
                                        helpText={trans(
                                            "Complete status is only available when the plan is approved"
                                        )}
                                    >
                                        <FormikRadioArray
                                            style={{ marginBottom: "1rem" }}
                                            name="status"
                                            value={values.status}
                                            options={statusOptions}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="planned_cost"
                                        label={trans("Planned Cost")}
                                        error={errors.planned_cost}
                                    >
                                        <FormikCurrencyInput
                                            name="planned_cost"
                                            value={values.planned_cost}
                                            showError={false}
                                            disabled={
                                                // isPartOfPlan ||
                                                values.status === "complete"
                                            }
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="cost"
                                        helpText={trans(
                                            "Available budget in campaign {availableBudget}.  Only available when status is complete",
                                            {
                                                availableBudget:
                                                    availableAmountFormatted,
                                            }
                                        )}
                                        label={trans("Actual Cost")}
                                        error={errors.cost}
                                    >
                                        <FormikCurrencyInput
                                            disabled={
                                                values.status !== "complete"
                                            }
                                            style={{
                                                color:
                                                    values.cost >
                                                    availableBudget
                                                        ? theme.colors.alert
                                                        : "inherit",
                                            }}
                                            name="cost"
                                            value={values.cost}
                                            showError={false}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="description"
                                        label={trans("Description")}
                                        error={errors.description}
                                    >
                                        <FormikTextarea
                                            name="description"
                                            value={values.description}
                                        />
                                    </FormRow>
                                </Column>
                            </Row>
                            <FormRow
                                name="show_on_planner"
                                label={trans("Show Activity On Planner")}
                                error={errors.show_on_planner}
                            >
                                <FormikRadioArray
                                    style={{ marginBottom: "1rem" }}
                                    name="show_on_planner"
                                    value={values.show_on_planner}
                                    options={[
                                        {
                                            value: "show",
                                            text: intl.formatMessage({
                                                id: "Show",
                                            }),
                                        },
                                        {
                                            value: "hide",
                                            text: intl.formatMessage({
                                                id: "Hide",
                                            }),
                                        },
                                    ]}
                                    inlineRadioButtons
                                    showError={false}
                                />
                            </FormRow>

                            <Row style={{ marginBottom: "1.2rem" }}>
                                <Column medium={6} centered>
                                    <Column
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <IconTextButton
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onGoBack(task, category);
                                            }}
                                            type="alert"
                                            icon="times"
                                            showCircle={false}
                                        >
                                            {trans("Cancel")}
                                        </IconTextButton>
                                        <SubmitButton
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </Column>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { startOrderProcess, addActivity, editActivity },
        dispatch
    );
}

function mapStateToProps(state) {
    const { startDate, endDate, filters } = state.planner;
    const { user } = state.auth;
    return {
        startDate,
        endDate,
        user,
        filters,
    };
}

export default injectIntl(
    withRouter(
        withTheme(
            compose(connect(mapStateToProps, mapDispatchToProps))(
                PlannerActivityForm
            )
        )
    )
);
