import React from "react";
import { InputField, Column, FormRow } from "@cortexglobal/rla-components";
import { trans, useIntl, FormattedCurrency } from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";

const QuantityFormRow = ({
    value,
    order,
    onChange,
    errors,
    availableStock,
    minQty = 0,
    maxQty = 0,
    price,
}) => {
    const intl = useIntl();
    const { user } = useUser();

    const minMessage =
        minQty > 0
            ? intl.formatMessage(
                  {
                      id: "Minimum {minQty}",
                      defaultMessage: "Minimum {minQty}",
                  },
                  { minQty: minQty }
              )
            : "";
    const maxMessage =
        maxQty > 0
            ? intl.formatMessage(
                  {
                      id: ", Maximum {maxQty}",
                      defaultMessage: ", Maximum {maxQty}",
                  },
                  { maxQty: maxQty }
              )
            : "";
    return (
        <>
            <FormRow
                name="quantity"
                label={trans("Quantity Required")}
                helpText={minMessage + maxMessage}
                error={errors.quantity}
            >
                <Column medium={6} collapse>
                    <InputField
                        type="number"
                        name="quantity"
                        value={value}
                        showError={false}
                        onChange={onChange}
                        min={minQty}
                        max={maxQty}
                    />
                </Column>
                {price && (
                    <Column medium={6}>
                        <h4
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                margin: 0,
                                height: "30px",
                            }}
                        >
                            <span
                                style={{
                                    display: "inline-block",
                                    marginRight: "0.5rem",
                                }}
                            >
                                {trans("Total: ")}{" "}
                            </span>
                            <FormattedCurrency
                                value={price * value}
                                currency={user?.currency?.iso_code || "GBP"}
                                style={{ textAlign: "right" }}
                            />
                        </h4>
                    </Column>
                )}
            </FormRow>
        </>
    );
};

export default QuantityFormRow;
