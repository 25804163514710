import axios from "axios";
import moment from "moment";
import { get } from "lodash";

import User from "./User";
import { isJSON } from "@cortexglobal/cortex-utilities";

const defaultSender = {
    name: "System",
};

const defaultRecipient = {
    name: "Unknown",
};

const convertToMoment = (value) => {
    return !value ? "" : moment(value);
};

function Message(props) {
    this.destroyed = false;
    this.uuid = get(props, "uuid", "");
    this.sent_to = get(props, "sent_to", "");
    this.subject = get(props, "subject", "");
    this.message = get(props, "message", "");
    this.thread_id = get(props, "thread_id", "");
    this.batch_id = get(props, "batch_id", "");
    this.status = get(props, "status", "");
    this.type = get(props, "type", "");
    this.priority = get(props, "priority", "");
    this.tags = get(props, "tags", []);

    this.send_now = get(props, "send_now", true);

    this.sent_at = convertToMoment(props.sent_at);
    this.send_at = convertToMoment(props.send_at);

    this.send_date = moment.isMoment(this.send_at)
        ? this.send_at.format("yyyy-MM-dd'T'HH:mm:ssxxx")
        : "";

    this.send_time = moment.isMoment(this.send_at)
        ? this.send_at.format("hh:mm")
        : "";

    this.notified = !!props.notified;
    this.notified_at = convertToMoment(props.notified_at);

    this.received = !!props.received_at;
    this.received_at = convertToMoment(props.received_at);

    this.read = !!props.read;
    this.unread = !props.read;
    this.read_at = convertToMoment(props.received_at);

    this.acknowledged = !!props.acknowledged;
    this.acknowledged_at = convertToMoment(props.acknowledged_at);

    const { sender, recipient } = props;
    this.sender = new User(sender ? sender : defaultSender);
    this.recipient = new User(recipient ? recipient : defaultRecipient);

    this.can_reply = !!props.can_reply;
    this.can_forward = true;
    this.can_acknowledge = false; //!this.acknowledged; -- acknowledge not available yet

    this.box = get(props, "box", "");

    this.is_scheduled = this.received_at && moment().isBefore(this.received_at);

    this.setBox = (box) => {
        this.box = box;
    };

    this.markAsRead = async () => {
        if (this.read) {
            return;
        }

        this.read = true;
        this.unread = false;
        this.read_at = moment();

        await axios.post(`/api/v1/communication/${this.uuid}/read`, {
            async: true,
        });

        this.acknowledged = true;
        this.acknowledged_at = moment();
    };

    this.markAsUnread = async () => {
        if (this.unread) {
            return;
        }

        this.read = false;
        this.unread = true;
        this.read_at = null;

        await axios.post(`/api/v1/communication/${this.uuid}/unread`, {
            async: true,
        });
    };

    this.markAsAcknowledged = async () => {
        await axios.post(`/api/v1/communication/${this.uuid}/acknowledge`, {
            async: true,
        });
    };

    this.destroy = async () => {
        this.destroyed = true;

        await axios.post(
            `/api/v1/communication/${this.box}/${this.uuid}/destroy`,
            {
                async: true,
            }
        );
    };

    /**
     * Returns the message but with added info at the top, like outlook does . i.e...
     *
     * ----------------
     * From: Jimmy TwoShoes
     * Date: 16th May 2021
     * Subject: Urgent Message
     *
     * ...Message text....
     *
     * Please note: this isn't a pleasant hack, we could do with a better method for this moving forward
     *
     * @param message
     */
    this.messageWithHeader = () => {
        const message = this.message;

        if (!isJSON(message)) {
            return message;
        }

        const parsedMessage = JSON.parse(message);

        let newOps = [
            { insert: "\n\n------------------------------------\n" },
            { attributes: { bold: true }, insert: "From: " },
            { insert: this.sender.name + "\n" },
            { attributes: { bold: true }, insert: "Date: " },
            { insert: this.received_at.format("Do MMMM YYYY HH:mm") + "\n" },
            { attributes: { bold: true }, insert: "To: " },
            { insert: this.sent_to + "\n" },
            { attributes: { bold: true }, insert: "Subject: " },
            { insert: this.subject + "\n\n" },
        ];

        const originalOps = get(parsedMessage, "ops", []);

        const expandedMessage = { ops: [...newOps, ...originalOps] };

        const stringifiedMessage = JSON.stringify(expandedMessage);

        return stringifiedMessage;
    };
}

export default Message;
