import React, { useEffect, useState } from "react";
import axios from "axios";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Table,
    Pagination,
    Loading,
    InputField,
    FormRow,
    Button,
    StyledDatePicker,
    StyledSelectSimple,
    Modal,
    IconTextButton,
} from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";
import {
    faCaretUp,
    faCaretDown,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { QuillOutput } from "@cortexglobal/communication";

const SortableTableHeading = ({
    orderBy,
    selected,
    direction,
    onClick,
    style,
    children,
}) => {
    return (
        <th
            onClick={() => {
                onClick(orderBy);
            }}
            style={{ cursor: "pointer", ...style }}
        >
            <span
                style={{
                    marginRight: "0.5rem",
                }}
            >
                {children}{" "}
            </span>
            {selected &&
                (direction === "asc" ? (
                    <FontAwesomeIcon icon={faCaretUp} size="lg" />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown} size="lg" />
                ))}
        </th>
    );
};

export const CommunicationRuleMessagesTable = ({ ruleUuid: uuid }) => {
    const defaultFilters = {};
    const defaultPagination = {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
    };

    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState(defaultFilters);
    const [orderBy, setOrderBy] = useState("id");
    const [orderByDirection, setOrderByDirection] = useState("desc");
    const [pagination, setPagination] = useState(defaultPagination);

    const [currentMessage, setCurrentMessage] = useState(null);

    useEffect(() => {
        const { page, perPage } = pagination;

        loadItems(page, orderBy, orderByDirection, perPage, filters);
    }, [setMessages]);

    const onPerPageChange = ({ value }) => {
        setPagination({ ...pagination, per_page: value });

        loadItems(1, orderBy, orderByDirection, value, filters);
    };

    const onPageChange = (newPage) => {
        const { per_page } = pagination;

        loadItems(newPage, orderBy, orderByDirection, per_page, filters);
    };

    const changeOrderBy = (newOrderBy) => {
        const { per_page } = pagination;

        let direction = "asc";

        if (orderBy === newOrderBy) {
            direction = orderByDirection === "asc" ? "desc" : "asc";
        }

        setOrderBy(newOrderBy);
        setOrderByDirection(direction);

        loadItems(1, newOrderBy, direction, per_page, filters);
    };

    const loadItems = (page, orderBy, orderByDir, perPage, filters) => {
        setLoading(true);

        axios
            .get(`/api/v1/admin/messenger-rules/${uuid}/messages`, {
                params: {
                    page: page,
                    order_by_direction: orderByDir ? orderByDir : null,
                    order_by: orderBy ? orderBy : null,
                    per_page: perPage ? perPage : 20,
                    filters: filters,
                },
            })
            .then(({ data }) => {
                setMessages(data.data);
                setPagination(data.meta);
            })
            .catch()
            .finally(() => {
                setLoading(false);
            });
    };

    const onFilter = () => {
        const { per_page } = pagination;

        loadItems(1, orderBy, orderByDirection, per_page, filters);

        setShowFilters(false);
    };

    const onClearFilters = () => {
        setFilters(defaultFilters);

        const { per_page } = pagination;

        loadItems(1, orderBy, orderByDirection, per_page, defaultFilters);

        setShowFilters(false);
    };

    if (loading === true) {
        return <Loading />;
    }

    return (
        <>
            <Modal
                visible={showFilters}
                onClose={() => {
                    setShowFilters(false);
                }}
            >
                <h2>{trans("Message Filters")}</h2>
                <div style={{ margin: "2rem 0" }}>
                    <FormRow name="recipient" label={trans("Recipient")}>
                        <InputField
                            name="recipient"
                            value={filters.recipient}
                            onChange={(e) =>
                                setFilters({ ...filters, recipient: e.value })
                            }
                            disabled={loading === true}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onFilter();
                                }
                            }}
                        />
                    </FormRow>
                    <FormRow name="sent" label={trans("Sent")}>
                        <StyledDatePicker
                            name="sent"
                            selected={filters.sent}
                            value={filters.sent}
                            onChange={(date) =>
                                setFilters({
                                    ...filters,
                                    sent: format(new Date(date), "yyyy-MM-dd"),
                                })
                            }
                            disabled={loading === true}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onFilter();
                                }
                            }}
                        />
                    </FormRow>
                    <FormRow name="read" label={trans("Read")}>
                        <StyledDatePicker
                            name="sent"
                            selected={filters.read}
                            value={filters.read}
                            onChange={(date) =>
                                setFilters({
                                    ...filters,
                                    read: format(new Date(date), "yyyy-MM-dd"),
                                })
                            }
                            disabled={loading === true}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onFilter();
                                }
                            }}
                        />
                    </FormRow>
                    <FormRow
                        name="popup_displayed"
                        label={trans("Pop-up Displayed")}
                    >
                        <StyledDatePicker
                            name="popup_displayed"
                            selected={filters.popup_displayed}
                            value={filters.popup_displayed}
                            onChange={(date) =>
                                setFilters({
                                    ...filters,
                                    popup_displayed: format(
                                        new Date(date),
                                        "yyyy-MM-dd"
                                    ),
                                })
                            }
                            disabled={loading === true}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onFilter();
                                }
                            }}
                        />
                    </FormRow>
                    <FormRow name="internal" label={trans("Internal")}>
                        <StyledSelectSimple
                            name="internal"
                            selected={filters.internal}
                            value={filters.internal}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    internal: e.target.value,
                                })
                            }
                            disabled={loading === true}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onFilter();
                                }
                            }}
                            options={[
                                { text: "Y", value: "y" },
                                { text: "N", value: "n" },
                            ]}
                        />
                    </FormRow>
                    <FormRow name="popup" label={trans("Pop-up")}>
                        <StyledSelectSimple
                            name="popup"
                            selected={filters.popup}
                            value={filters.popup}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    popup: e.target.value,
                                })
                            }
                            disabled={loading === true}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onFilter();
                                }
                            }}
                            options={[
                                { text: "Y", value: "y" },
                                { text: "N", value: "n" },
                            ]}
                        />
                    </FormRow>
                    <FormRow name="email" label={trans("Email")}>
                        <StyledSelectSimple
                            name="email"
                            selected={filters.email}
                            value={filters.email}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    email: e.target.value,
                                })
                            }
                            disabled={loading === true}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    onFilter();
                                }
                            }}
                            options={[
                                { text: "Y", value: "y" },
                                { text: "N", value: "n" },
                            ]}
                        />
                    </FormRow>
                    <FormRow name="buttons">
                        <Button
                            style={{ marginRight: "1rem" }}
                            onClick={(e) => onFilter()}
                        >
                            {trans("Filter")}
                        </Button>
                        <Button
                            type="hollow"
                            onClick={(e) => setShowFilters(false)}
                        >
                            {trans("Cancel")}
                        </Button>
                    </FormRow>
                </div>
            </Modal>

            {!isEmpty(currentMessage) && (
                <Modal
                    visible={!isEmpty(currentMessage)}
                    onClose={() => {
                        setCurrentMessage(null);
                    }}
                    maxWidth="50%"
                >
                    <div>
                        <div style={{ margin: "1.5em 1.5em 2em" }}>
                            <h1>{currentMessage.subject}</h1>
                        </div>
                        <div style={{ margin: "1.5em" }}>
                            <QuillOutput
                                value={
                                    currentMessage.popup_message ||
                                    currentMessage.message
                                }
                            />
                        </div>
                    </div>
                </Modal>
            )}

            <div style={{ margin: "0 0 1.5rem 1rem" }}>
                <Button
                    onClick={() => setShowFilters(true)}
                    style={{ marginRight: "1rem" }}
                >
                    {trans("Show Filters")}
                </Button>

                {!isEmpty(filters) && (
                    <Button type="hollow" onClick={(e) => onClearFilters()}>
                        {trans("Clear Filters")}
                    </Button>
                )}
            </div>

            <Table>
                <thead>
                    <tr>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "id"}
                            onClick={() => changeOrderBy("id")}
                        >
                            #
                        </SortableTableHeading>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "sent_to_name"}
                            onClick={() => changeOrderBy("sent_to_name")}
                        >
                            {trans("Recipient")}
                        </SortableTableHeading>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "send_at"}
                            onClick={() => changeOrderBy("send_at")}
                        >
                            {trans("Sent")}
                        </SortableTableHeading>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "read_at"}
                            onClick={() => changeOrderBy("read_at")}
                        >
                            {trans("Internal Message Read")}
                        </SortableTableHeading>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "popup_displayed_at"}
                            onClick={() => changeOrderBy("popup_displayed_at")}
                        >
                            {trans("Pop-up Displayed")}
                        </SortableTableHeading>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "internal"}
                            onClick={() => changeOrderBy("internal")}
                        >
                            {trans("Internal")}
                        </SortableTableHeading>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "popup_required"}
                            onClick={() => changeOrderBy("popup_required")}
                        >
                            {trans("Pop-up")}
                        </SortableTableHeading>
                        <SortableTableHeading
                            orderBy={orderBy}
                            direction={orderByDirection}
                            selected={orderBy === "email_alert"}
                            onClick={() => changeOrderBy("email_alert")}
                        >
                            {trans("Email")}
                        </SortableTableHeading>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {loading
                        ? ""
                        : messages.map((message) => {
                              return (
                                  <tr>
                                      <td>{message.id}</td>
                                      <td>{message.sent_to}</td>
                                      <td>
                                          {format(
                                              new Date(message.send_at),
                                              "Pp"
                                          )}
                                      </td>
                                      <td>
                                          {message.read
                                              ? format(
                                                    new Date(message.read_at),
                                                    "Pp"
                                                )
                                              : ""}
                                      </td>
                                      <td>
                                          {message.popup_displayed_at
                                              ? format(
                                                    new Date(
                                                        message.popup_displayed_at
                                                    ),
                                                    "Pp"
                                                )
                                              : ""}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                          {!message.popup_required ? (
                                              <FontAwesomeIcon
                                                  icon={faCheck}
                                                  size="lg"
                                              />
                                          ) : (
                                              ""
                                          )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                          {message.popup_required ? (
                                              <FontAwesomeIcon
                                                  icon={faCheck}
                                                  size="lg"
                                              />
                                          ) : (
                                              ""
                                          )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                          {message.email_alert ? (
                                              <FontAwesomeIcon
                                                  icon={faCheck}
                                                  size="lg"
                                              />
                                          ) : (
                                              ""
                                          )}
                                      </td>
                                      <td>
                                          <IconTextButton
                                              height="15px"
                                              type="info"
                                              onClick={() =>
                                                  setCurrentMessage(message)
                                              }
                                              icon={faEye}
                                              showCircle={false}
                                              expanded={false}
                                          >
                                              {trans("View")}
                                          </IconTextButton>
                                      </td>
                                  </tr>
                              );
                          })}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
        </>
    );
};

export default CommunicationRuleMessagesTable;
