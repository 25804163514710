import React from "react";
import _ from "lodash";
import {
    Button,
    Row,
    Column,
    Panel,
    IconTextButton
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

class GallerySearchResults extends React.Component {
    chooseItem = item => {
        this.props.chooseItem(item);
    };

    render() {
        let collection = this.props.results.data;
        return (
            <div>
                <Panel heading={<span>{trans("Results")} </span>} padding={0}>
                    {!_.isEmpty(collection) && (
                        <table className="dashboard-table">
                            <thead>
                                <tr>
                                    <th />
                                    <th>{trans("Name")}</th>
                                    <th>{trans("Slug")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(collection).map(index => {
                                    let item = collection[index];
                                    return (
                                        <tr key={index}>
                                            <th>
                                                <img
                                                    style={{
                                                        maxWidth: "50px",
                                                        maxhieght: "50px"
                                                    }}
                                                    src={item.thumbnail}
                                                />
                                            </th>
                                            <td>
                                                {item.name ? item.name : "-"}
                                            </td>
                                            <td>
                                                {item.slug ? item.slug : "-"}
                                            </td>
                                            <td>
                                                <IconTextButton
                                                    onClick={this.chooseItem.bind(
                                                        this,
                                                        item
                                                    )}
                                                    showCircle={false}
                                                    expanded={false}
                                                    icon={faCheck}
                                                >
                                                    {trans("Choose")}
                                                </IconTextButton>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}

                    {_.isEmpty(collection) && (
                        <Row>
                            <Column>
                                <p style={{ marginTop: "1.2em" }}>
                                    {trans(
                                        "Please enter a value to find a media"
                                    )}
                                </p>
                            </Column>
                        </Row>
                    )}
                </Panel>
            </div>
        );
    }
}

export default GallerySearchResults;
