const useDebounce = () => {
    const debounce = (fn, ms) => {
        let timer
        return _ => {
            clearTimeout(timer)
            timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
            }, ms)
        };
    };

    return [debounce];
}

export default useDebounce;