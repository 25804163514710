import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Badge,
    Button,
    Column,
    Loading,
    Row,
    Table,
} from "@cortexglobal/rla-components";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import { format } from "@cortexglobal/cortex-utilities";

const AddTasksToPlan = ({ plan, onTasksAdded }) => {
    const [tasks, setTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            loadTasks(plan);
        }
    }, [plan]);

    const loadTasks = (plan) => {
        setLoaded(false);

        axios
            .get("/api/v1/planner/tasks/plan/" + plan.uuid)
            .then(({ data }) => {
                setTasks(data.data);
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    const addTasks = (tasks) => {
        //Post the task(s) to the plan
        axios
            .post(`/api/v1/planner/plan/${plan.uuid}/tasks`, {
                tasks,
            })
            .then(({ data }) => {
                onTasksAdded(data.data);
            });
    };

    if (!loaded) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                <Column>
                    <h3>
                        {trans("Adding Campaign to {plan}", {
                            plan: plan.name,
                        })}
                    </h3>
                </Column>
            </Row>
            <Row>
                <Column>
                    {tasks.length === 0 ? (
                        <p>{trans("No tasks available")}</p>
                    ) : (
                        <Table>
                            <thead>
                                <tr>
                                    <th>{trans("Selected")}</th>
                                    <th>{trans("Name")}</th>
                                    <th>{trans("Group")}</th>
                                    <th>{trans("Start Date")}</th>
                                    <th>{trans("End Date")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task) => {
                                    return (
                                        <tr key={task.uuid}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    onChange={() => {
                                                        if (
                                                            selectedTasks.includes(
                                                                task.uuid
                                                            )
                                                        ) {
                                                            setSelectedTasks(
                                                                selectedTasks.filter(
                                                                    (t) =>
                                                                        t !==
                                                                        task.uuid
                                                                )
                                                            );
                                                        } else {
                                                            setSelectedTasks([
                                                                ...selectedTasks,
                                                                task.uuid,
                                                            ]);
                                                        }
                                                    }}
                                                    checked={selectedTasks.includes(
                                                        task.uuid
                                                    )}
                                                />
                                            </td>
                                            <td>{task.name}</td>
                                            <td>{task.group.name}</td>
                                            <td>{format(task.start_date)}</td>
                                            <td>{format(task.end_date)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </Column>
            </Row>
            {tasks.length > 0 && (
                <Row>
                    <Column>
                        {selectedTasks.length > 0 ? (
                            <Button
                                onClick={() => {
                                    addTasks(selectedTasks);
                                }}
                            >
                                {trans(
                                    "Add {count, plural, one {selected task} other {# selected tasks}}",
                                    {
                                        count: selectedTasks.length,
                                    }
                                )}
                            </Button>
                        ) : (
                            <p>
                                {trans(
                                    "Please select a task(s) to add to the plan"
                                )}
                            </p>
                        )}
                    </Column>
                </Row>
            )}
        </div>
    );
};

export default AddTasksToPlan;
