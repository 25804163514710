import React, { useEffect, useState } from "react";
import { withRouter, Switch, Route } from "react-router-dom";

import { useIntl } from "@cortexglobal/rla-intl";

import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import { ListCampaigns } from "./screens/ListCampaigns";
import { CreateCampaign } from "./screens/CreateCampaign";
import { EditCampaign } from "./screens/EditCampaign";
import { ViewCampaign } from "./screens/ViewCampaign";

const CampaignRouting = ({
    title = "",
    match: { path },
    history,
    assetsPath = "/admin/assets",
}) => {
    const intl = useIntl();

    const { can } = usePermissions();

    //Selected Campaign is used to pass the campaign object to the view screen
    //This is to allow for the basic campaign data to be loaded straight away on the view screen
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const mainTitle =
        title === ""
            ? intl.formatMessage({
                  id: "Campaigns",
              })
            : title;
    const viewCampaign = (event, campaign) => {
        console.log("viewCampaign", campaign);
        setSelectedCampaign(campaign);
        //Campaign can be set to null to clear the selected campaign, so only
        //push to the history if the campaign is not null
        if (campaign && campaign.uuid) {
            history.push(`${path}/view/${campaign.uuid}`);
        }
    };
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return (
                        <ListCampaigns
                            basePath={`${path}`}
                            mainTitle={mainTitle}
                            viewCampaign={viewCampaign}
                            can={can}
                        />
                    );
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <CreateCampaign basePath={`${path}`} />;
                }}
            />
            <Route
                // exact
                path={`${path}/:campaignUuid/edit/:step?/:page?`}
                render={(props) => {
                    const {
                        match: {
                            params: { campaignUuid },
                        },
                    } = props;

                    return (
                        <EditCampaign
                            campaignUuid={campaignUuid}
                            basePath={`${path}`}
                            assetsPath={assetsPath}
                        />
                    );
                }}
            />
            <Route
                exact
                path={`${path}/view/:campaignUuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { campaignUuid },
                        },
                    } = props;

                    return (
                        <ViewCampaign
                            selectedCampaign={selectedCampaign}
                            campaignUuid={campaignUuid}
                            basePath={`${path}/`}
                            mainTitle={mainTitle}
                            can={can}
                            assetsPath={assetsPath}
                        />
                    );
                }}
            />
        </Switch>
    );
};

export default withRouter(CampaignRouting);
