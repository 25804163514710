import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledCurrencyInput from "../styledElements/styledCurrencyInput";
import storage from "local-storage-fallback";

const CurrencyInput = ({
    type,
    name,
    label,
    readOnly,
    meta,
    onChange,
    error,
    labelWidth,
    labelAlign,
    labelStyle,
    placeholder,
    defaultValue,
    allowDecimal,
    decimalsLimit,
    ...rest
}) => {
    let fieldOptions = {};

    const [currency, setCurrency] = useState({});

    useEffect(() => {
        const localCurrency = storage.getItem("currency");
        setCurrency(JSON.parse(localCurrency));
    }, []);

    if (readOnly) {
        fieldOptions["readOnly"] = "readOnly";
    }

    if (error) {
        fieldOptions["error"] = "error";
    }

    return (
        <div>
            {label && (
                <FormLabel
                    align={labelAlign}
                    width={labelWidth}
                    name={name}
                    {...rest}
                    style={labelStyle}
                >
                    {label}
                </FormLabel>
            )}
            <StyledCurrencyInput
                name={name}
                id={name}
                prefix={currency.symbol_string ? currency.symbol_string : ""}
                placeholder={placeholder}
                defaultValue={defaultValue}
                intlConfig={{
                    currency: currency.iso_code ? currency.iso_code : "",
                }}
                allowDecimals={allowDecimal}
                step={currency.step ? currency.step : 1}
                decimalsLimit={
                    currency.decimal_places ? currency.decimal_places : 2
                }
                onChange={onChange}
                labelWidth={labelWidth}
                {...fieldOptions}
                {...rest}
            />
            <InputError error={error} />
        </div>
    );
};

CurrencyInput.displayName = "CurrencyInput";

CurrencyInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    //error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.number,
    allowDecimal: PropTypes.bool,
    decimalsLimit: PropTypes.number,
};

CurrencyInput.defaultProps = {
    size: "default",
    type: "number",
    readOnly: false,
    //error: "",
    placeholder: "",
    defaultValue: 0,
    allowDecimal: true,
    decimalsLimit: 2,
};

export default CurrencyInput;
