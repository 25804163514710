import React, { useState } from "react";
import styled from "styled-components";
import Image from "./Image";
import Modal from "./modal";

const StyledImage = styled(Image)`
    max-height: ${({ maxHeight }) => maxHeight};
    max-width: ${({ maxWidth }) => maxWidth};
    cursor: pointer;
`;
const ImageWrapper = styled.div`
    display: flex;
    /* min-height: 80vh; */
    width: 100%;
    overflow: auto;
`;
export const PreviewImage = ({
    src,
    maxHeight = "80px",
    maxWidth = "100%",
    ...rest
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <React.Fragment>
            <StyledImage
                onClick={() => setModalVisible(true)}
                src={src}
                maxHeight={maxHeight}
                maxWidth={maxWidth}
                {...rest}
            />
            {modalVisible && (
                <>
                    {/* @ts-ignore cortex */}
                    <Modal
                        visible={modalVisible}
                        maxWidth="90%"
                        onClose={() => setModalVisible(false)}
                        showCloseButton={false}
                    >
                        <ImageWrapper onClick={() => setModalVisible(false)}>
                            <Image src={src} />
                        </ImageWrapper>
                    </Modal>
                </>
            )}
        </React.Fragment>
    );
};

export default PreviewImage;
