import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
    ContentBlockLoader,
    SubmitButton,
    Button,
    FormikAdvancedSelect,
    FormikInput,
    FormikTextarea,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    LoadingIconSmall,
    useAddToast,
    useToasts,
    Toggle,
    RepeaterField,
    renderStandReturnInput,
    IconTextButton,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { LocaleSwitcher } from "../components/index";

export const customFieldSchema = Yup.object().shape({
    locale: Yup.string().nullable(),
    field_name: Yup.string().required("Required"),
    label: Yup.string().required("Required"),
    form_builder_field_type: Yup.string().required(trans("Required")),
    order: Yup.number().required("Required"),
    per_row: Yup.number().required("Required"),
    validation_rules: Yup.string().nullable(),
    default_values: Yup.string().nullable(),
    named_fields_required: Yup.string().nullable(),
    display_output_format: Yup.string().nullable(),
});

export type CustomField = ReturnType<typeof customFieldSchema.validateSync> & {
    id?: number;
    uuid?: number;
    i18n?: { [key: string]: any };
    element?: any;
};

export const CustomFormField = ({
    item,
    fieldTypes: fieldTypesProp = [],
    formUuid,
    formLocale,
    onComplete,
}) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();
    const [fieldTypes, setFieldTypes] = useState(fieldTypesProp);
    const [fieldTypesLoaded, setFieldTypesLoaded] = useState(
        fieldTypesProp.length > 0
    );
    const [newPreviewFile, setNewPreviewFile] = useState(null);

    useEffect(() => {
        if (fieldTypesProp.length > 0) return;
        setFieldTypesLoaded(false);
        axios.get(`/api/v1/custom-forms/available-fields`).then(({ data }) => {
            setFieldTypes(data.data);
            setFieldTypesLoaded(true);
        });
    }, [fieldTypesProp]);

    const handleSubmit = (
        values: CustomField,
        { setErrors, setSubmitting, ...rest }
    ) => {
        // submitForm(fd, setSubmitting)
        setSubmitting(true);
        if (item && item.uuid) {
            axios
                .put(
                    `api/v1/custom-forms/field/${item.uuid}`,
                    values,
                    setSubmitting
                )
                .then(({ data }) => {
                    // setSubmitting(false);
                    onComplete(data.data);
                })
                .catch((e) => {
                    if (e.response.data && e.response.data.message) {
                        addToast({
                            type: "alert",
                            content: e.response.data.message,
                            showFor: 5000,
                        });
                    } else {
                        addToast({
                            type: "alert",
                            content: trans(
                                "An error occurred updating the form field"
                            ),
                            showFor: 5000,
                        });
                    }
                    parseValidationErrors(e).then(({ errors }) => {
                        //console.log(setErrors, errors);
                        setErrors(errors);
                        // setSubmitting(false);
                    });
                })
                .finally(() => {
                    dispatch({ type: "removeToast", uuid: "loadToast" });
                    setSubmitting(false);
                });
        } else {
            axios
                .post(
                    `api/v1/custom-forms/${formUuid}/field`,
                    values,
                    setSubmitting
                )
                .then(({ data }) => {
                    // setSubmitting(false);
                    onComplete(data.data);
                })
                .catch((e) => {
                    if (e.response.data && e.response.data.message) {
                        addToast({
                            type: "alert",
                            content: e.response.data.message,
                            showFor: 5000,
                        });
                    } else {
                        addToast({
                            type: "alert",
                            content: trans(
                                "An error occurred creating the form field"
                            ),
                            showFor: 5000,
                        });
                    }
                    parseValidationErrors(e).then(({ errors }) => {
                        //console.log(setErrors, errors);
                        setErrors(errors);
                        // setSubmitting(false);
                    });
                })
                .finally(() => {
                    dispatch({ type: "removeToast", uuid: "loadToast" });
                    setSubmitting(false);
                });
        }
    };

    return (
        <Formik
            initialValues={{
                locale:
                    item && item.locale
                        ? item.locale
                        : formLocale
                        ? formLocale
                        : null,
                field_name: item?.field_name || "",
                label: item?.label || "",
                form_builder_field_type: item?.form_builder_field_type || "",
                order: item?.order || 0,
                per_row: item?.per_row || 0,
                validation_rules: item?.validation_rules || "",
                default_values: item?.default_values || "",
                named_fields_required: item?.named_fields_required || "",
                display_output_format: item?.display_output_format || "",
            }}
            onSubmit={handleSubmit}
            validationSchema={customFieldSchema}
        >
            {({
                isSubmitting,
                values,
                setFieldValue,
                errors,
                submitForm,
                ...rest
            }) => {
                console.log(rest);
                return (
                    <Form className="form-group">
                        <FormRow
                            name="locale"
                            label={trans("Locale")}
                            helpText={trans("Optional")}
                            error={errors.locale}
                        >
                            <LocaleSwitcher
                                item={item}
                                setFieldValue={setFieldValue}
                                //@ts-ignore
                                values={values}
                            />
                        </FormRow>
                        <FormRow
                            name="field_name"
                            label={trans("Field Name")}
                            helpText={trans(
                                "Required, used in the code to identify this field so must be unique and contain only letters, numbers and underscores"
                            )}
                            error={errors.field_name}
                            required={true}
                        >
                            <FormikInput
                                name="field_name"
                                value={values.field_name}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="label"
                            label={trans("Label")}
                            helpText={trans(
                                "Required, used in the frontend to be displayed to the user"
                            )}
                            error={errors.label}
                            required={true}
                        >
                            <FormikInput
                                name="label"
                                value={values.label}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="form_builder_field_type"
                            label={trans("Field Type")}
                            helpText={trans("Required, the type of form field")}
                            error={errors.form_builder_field_type}
                            required={true}
                        >
                            <FormikAdvancedSelect
                                name="form_builder_field_type"
                                value={values.form_builder_field_type}
                                showError={false}
                                options={Object.entries(fieldTypes).map(
                                    ([key, value]) => {
                                        return {
                                            value: key,
                                            text: value.name,
                                        };
                                    }
                                )}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="order"
                            label={trans("Order")}
                            helpText={trans(
                                "Optional, the order in which this field will be displayed in the form"
                            )}
                            error={errors.order}
                        >
                            <FormikInput
                                type="number"
                                name="order"
                                value={values.order}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="per_row"
                            label={trans("Per Row")}
                            helpText={trans(
                                "Optional, the number of fields to display per row in the form"
                            )}
                            error={errors.per_row}
                        >
                            <FormikInput
                                type="number"
                                name="per_row"
                                value={values.per_row}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="validation_rules"
                            label={trans("Validation Rules")}
                            helpText={trans(
                                "Optional, the validation rules to apply to this field. See the Laravel documentation for more information"
                            )}
                            error={errors.validation_rules}
                        >
                            <FormikTextarea
                                rows={2}
                                name="validation_rules"
                                value={values.validation_rules}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="default_values"
                            label={trans("Default Values")}
                            helpText={trans(
                                "Optional, the default values to apply to this field"
                            )}
                            error={errors.default_values}
                        >
                            <FormikTextarea
                                rows={2}
                                name="default_values"
                                value={values.default_values}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="named_fields_required"
                            label={trans("Named Fields Required")}
                            helpText={trans("Optional")}
                            error={errors.named_fields_required}
                        >
                            <FormikTextarea
                                rows={2}
                                name="named_fields_required"
                                value={values.named_fields_required}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="display_output_format"
                            label={trans("Display Output Format")}
                            helpText={trans("Optional")}
                            error={errors.display_output_format}
                        >
                            <FormikTextarea
                                rows={2}
                                name="display_output_format"
                                value={values.display_output_format}
                                showError={false}
                            />{" "}
                        </FormRow>

                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        submitForm();
                                    }}
                                >
                                    {trans("Save")}
                                </Button>
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CustomFormField;
