import React, { Component } from "react";

import AssetWidgetConfiguration from "../assetComponents/AssetWidgetConfiguration";
import AssetCarouselOutput from "./AssetCarouselOutput";

class AssetCarousel extends Component {
    render() {
        if (this.props.configuring) {
            return (
                <AssetWidgetConfiguration
                    displayType="carousel"
                    {...this.props}
                />
            );
        }
        return <AssetCarouselOutput {...this.props} />;
    }
}

export default AssetCarousel;
