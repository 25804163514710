import React, { useContext, useEffect, useState } from "react";
import { get } from "lodash";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import {
    Column,
    Loading,
    Modal,
    Row,
    IconTextButton,
} from "@cortexglobal/rla-components";
import InventoryItemBooking from "./forms/InventoryItemBooking";
import InventoryBasket from "./InventoryBasket";
import { trans } from "@cortexglobal/rla-intl";
import InventoryBrowser from "../../equipment/InventoryBrowser";

const EventInventoryEditor = ({ bookItems, event, settings, loadingState }) => {
    const [isModelShowing, toggleModal] = useState(false);
    const [showBasketModel, setShowBasketModal] = useState(false);
    const [currentInvItem, setCurrentInvItem] = useState({});
    // const [unlockedItems, setUnlockedItems] = useState([]);
    const [basketItems, setBasketItems] = useState([]);

    useEffect(() => {
        setBasketItems(get(event, "bookingItems", []));
    }, [event]);

    const onItemClick = (item) => {
        //TODO - Work out if this item is already in the basket, if so then edit it
        //otherwise add it
        if (basketItems.find((i) => i.uuid === item.uuid)) {
            editBookingItem(item);
        } else {
            addBookingItem(item);
        }
    };

    const updateBookingItem = (quantity, predictedCost, misc, dates) => {
        if (!dates) {
            if (currentInvItem.start_date && currentInvItem.end_date) {
                dates = [currentInvItem.start_date, currentInvItem.end_date];
            } else {
                dates = [event.start_date, event.end_date];
            }
        }
        console.log({ currentInvItem });
        const itemLine = {
            ...currentInvItem,
            custom_choices: misc,
            quantity: quantity,
            predicted_cost: isNaN(predictedCost)
                ? currentInvItem.item_cost * quantity
                : predictedCost,
            start_date: dates[0],
            end_date: dates[1],
        };

        const index = basketItems.findIndex((i) => i.uuid === itemLine.uuid);

        if (index === -1) {
            basketItems.push(itemLine);
        } else {
            basketItems[index] = itemLine;
        }

        bookItems(basketItems);

        toggleModal(false);
    };

    const addBookingItem = (item) => {
        setCurrentInvItem(item);

        toggleModal(true);
    };

    const editBookingItem = (item) => {
        setCurrentInvItem(item);

        toggleModal(true);
    };

    const removeBookingItem = (item) => {
        bookItems(basketItems.filter((i) => i.uuid !== item.uuid));
    };

    if (loadingState === "loading") {
        return <Loading />;
    }

    return (
        <>
            <Row
                collapse
                style={{
                    marginBottom: "1rem",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Column medium={9}>
                    <h3 style={{ marginBottom: 0 }}>
                        {trans("Kit Selection")}
                    </h3>
                </Column>

                <Column medium={3} style={{ textAlign: "right" }}>
                    <IconTextButton
                        icon={faShoppingCart}
                        showCircle={false}
                        onClick={() => setShowBasketModal(true)}
                        style={{ paddingRight: "1rem" }}
                    >
                        {trans("Booked Items")} ({basketItems.length})
                    </IconTextButton>
                </Column>
            </Row>

            <InventoryBrowser
                event={event}
                settings={settings}
                onItemClick={onItemClick}
                date_from={event.start_date}
                date_to={event.end_date}
            />

            <Modal
                key="basketModal"
                onClose={() => setShowBasketModal(false)}
                visible={showBasketModel}
                maxWidth="90%"
            >
                <h2>{trans("Booked Items")}</h2>
                <InventoryBasket
                    itemBookingList={basketItems}
                    editBookingItem={editBookingItem}
                    removeBookingItem={removeBookingItem}
                    editable={true}
                    showImage={true}
                    settings={settings}
                />
            </Modal>

            <Modal
                key="inventoryModal"
                onClose={() => toggleModal(false)}
                visible={isModelShowing}
                maxWidth="800px"
            >
                <InventoryItemBooking
                    item={currentInvItem}
                    updateBookingItem={updateBookingItem}
                    bookedItem={basketItems.find(
                        (i) => i.uuid === currentInvItem.uuid
                    )}
                    event={event}
                />
            </Modal>
        </>
    );
};

export default EventInventoryEditor;
