import React from "react";
import { trans } from "@cortexglobal/rla-intl";
import { TableList } from "@cortexglobal/rla-components";
import UserRow from "./UserRow";

const UserTable = (props) => {
    const { updateUsers = {}, users = [], showEdit = {} } = props;

    return (
        <>
            <TableList expand={true}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{trans("Name")}</th>
                        <th>{trans("Role")}</th>
                        <th>{trans("Group")}</th>
                        <th>{trans("Email")}</th>
                        <th>{trans("Status")}</th>
                        <th style={{ width: "200px" }}>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {users.length === 0 ? (
                        <tr>
                            <td colSpan="100%" style={{ textAlign: "center" }}>
                                {trans("No users found")}
                            </td>
                        </tr>
                    ) : (
                        users.map((user) => {
                            return (
                                <UserRow
                                    key={user.uuid}
                                    user={user}
                                    showEdit={showEdit}
                                    updateUsers={updateUsers}
                                />
                            );
                        })
                    )}
                </tbody>
            </TableList>
        </>
    );
};

export default UserTable;
