import React, { Component } from "react";
import PropTypes from "prop-types";

class Tooltip extends Component {
    constructor(props) {
        super(props);
        //Need to make these measurements dynamic
        this.state = {
            width: 100,
            height: 50
        };
    }

    getStyle() {
        const { style, tooltip } = this.props;
        let left = tooltip.width / 2 - this.state.width / 2;
        if (tooltip.corrected === "left") {
            left = 0;
        }
        if (tooltip.corrected === "right") {
            left = tooltip.width - this.state.width;
        }
        return {
            zIndex: style.zIndex + 2,
            left
        };
    }

    getArrowDirection(tooltipPositionString) {
        switch (tooltipPositionString) {
            case "left":
                return "right";
            case "right":
                return "left";
            case "top":
                return "down";
            case "bottom":
                this.x = this.props.parent.left;
                return "up";
            default:
                return "down";
        }
    }
    render() {
        const { position, placement } = this.props;
        const arrowDirection = this.getArrowDirection(position);
        const style = this.getStyle();
        //console.log(style);
        return (
            <div
                className={`injectableTooltip__arrow injectableTooltip__arrow--${arrowDirection}  injectableTooltip__arrow--${placement} `}
                style={style}
            />
        );
    }
}

Tooltip.propTypes = {
    style: PropTypes.shape({
        zIndex: PropTypes.number.isRequired
    }),
    position: PropTypes.string,
    placement: PropTypes.string,
    parent: PropTypes.instanceOf(Element).isRequired
};

export default Tooltip;
