import React from "react";
import styled from "styled-components";
import { Image } from "@cortexglobal/rla-components";

const StyledImage = styled(Image)`
    max-width: 100%;
    height: auto;
`;

const StyledVideo = styled.video`
    max-width: 100%;
    height: auto;
`;

const AttachmentWrapper = styled.div`
    margin-bottom: 1rem;
`;
export const Attachment = ({ attachment }) => {
    if (!attachment) {
        return null;
    }

    switch (attachment.type) {
        case "image":
            return (
                <AttachmentWrapper>
                    <StyledImage src={attachment.url} alt={attachment.name} />
                </AttachmentWrapper>
            );

        case "video":
            return (
                <AttachmentWrapper>
                    <StyledVideo controls>
                        <source src={attachment.url} type="video/mp4" />
                    </StyledVideo>
                </AttachmentWrapper>
            );

        default:
            return (
                <AttachmentWrapper>
                    <a
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {attachment.name}
                    </a>
                </AttachmentWrapper>
            );
    }
};

export default Attachment;
