import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import {
    ContentBlockLoader,
    CheckboxTree,
    Row,
    Column,
    SubmitButton,
    useAddToast,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

interface UserCategory {
    can_admin_category: boolean;
    can_admin_items: boolean;
    can_view_category: boolean;
    id: number;
    meta: any;
    name: string;
}

interface UserGroupRole {
    user_id: number;
    group_id: number;
    role_id: number;
}

export const UserGroupForm = ({ user, tab }) => {
    const [groups, setGroups] = useState<{
        loaded: boolean;
        data?: UserCategory[];
    }>({
        loaded: false,
        data: [],
    });
    const addToast = useAddToast();

    const [userGroups, setUserGroups] = useState<UserGroupRole[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        axios
            .get(`/api/v1/admin/group/structure?admin=true`)
            .then(({ data }) => {
                setGroups({ loaded: true, data: data.data });
            });
    }, []);
    useEffect(() => {
        axios.get(`/api/v1/admin/user/${user.id}/groups`).then(({ data }) => {
            const groupData = data.data.map((userGroupRole) => {
                return userGroupRole.group_id;
            });
            setUserGroups(groupData);
        });
    }, [user]);
    const updateGroups = (event) => {
        setUserGroups(event.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        axios
            .post(`/api/v1/admin/user/${user.id}/groups`, {
                groups: userGroups,
            })
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: trans("User groups updated"),
                    showFor: 5000,
                });
                const groupData = data.data.map((userGroupRole) => {
                    return userGroupRole.group_id;
                });
                setUserGroups(groupData);
            });
    };
    return (
        <form onSubmit={handleSubmit}>
            <FormRow
                name="groups"
                label={trans("Groups")}
                helpText={trans("Optional")}
            >
                {tab === 2 && (
                    <ContentBlockLoader loaded={groups.loaded}>
                        <CheckboxTree
                            onChange={updateGroups}
                            name="groups"
                            value={userGroups}
                            options={groups.data}
                            selectChildren={true}
                            disableAnimation={true}
                            expandOnRender={false}
                        />
                    </ContentBlockLoader>
                )}
            </FormRow>
            <Row>
                <Column medium={6} style={{ textAlign: "right" }}>
                    &nbsp;
                </Column>
                <Column
                    medium={3}
                    flex
                    style={{ paddingBottom: "1rem", justifyContent: "flex-end" }}
                >
                    <SubmitButton
                        label={trans("Save")}
                        submitting={isSubmitting}
                        type="primary"
                    />
                </Column>
            </Row>
        </form>
    );
};

export default UserGroupForm;
