import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Row, Column, Button } from "@cortexglobal/rla-components";

import TaskRow from "./TaskRow";
import CalendarContext from "./CalendarContext";
import { trans } from "@cortexglobal/rla-intl";

const TaskCategoryWrapper = styled.div`
    position: relative;
    border-top: 1px solid ${({ colors }) => colors.taskCategory.border};
`;

const TaskCategoryHeader = styled(Row)`
    position: relative;
    /* z-index: 4; */
    height: ${({ heights }) =>
        heights ? heights.task + heights.spacing + "px" : "50px"};
    line-height: ${({ heights }) =>
        heights ? heights.task + heights.spacing + "px" : "auto"};
    background: ${({ colors }) => colors.taskCategory.background};
    color: ${props =>
        props.colors.taskCategory && props.colors.taskCategory.color
            ? props.colors.taskCategory.color
            : "#fff"};
`;

const TaskCategoryLabel = styled(Column)`
    padding-left: 1.2rem;
    h5 {
        margin: 0;
    }
`;

const ButtonWrapper = styled(Column)`
    position: absolute;
    text-align: right;
    /* z-index: 4; */
    right: 0;
    /* right: ${({ summaryColumn }) => summaryColumn.width}px; */
`;

const AddButton = styled(Button)`
    cursor: pointer;
    min-height: 10px;
    padding: 0 0.5rem;
    background: none;
    box-shadow: none;
    font-weight: normal;
    color: ${props =>
        props.colors.taskCategory && props.colors.taskCategory.color
            ? props.colors.taskCategory.color
            : "#fff"};
    &:hover {
        background: none;
        color: ${props =>
            props.colors.taskCategory && props.colors.taskCategory.hoverColor
                ? props.colors.taskCategory.hoverColor
                : "#fff"};
    }
    transition: all 300ms;
`;

const TaskRowWrapper = styled.div`
    position: relative;
    height: ${({ height }) => height}px;
`;

const Arrow = styled(FontAwesomeIcon)`
    margin-right: 0.8rem;
    font-size: 0.65rem;
`;
const CalendarTaskCategory = props => {
    const { category, canAdminCategory, canAdminItems } = props;
    const {
        heights,
        units,
        labels,
        colors,
        addTask,
        summaryColumn,
        editTaskCategory,
        onShowTasks
    } = useContext(CalendarContext);
    const [showActivities, setShowActivities] = useState(category.expanded);

    return (
        <TaskCategoryWrapper
            colors={colors}
            className="linearCalendar__calendarGroup"
        >
            <TaskCategoryHeader heights={heights} colors={colors} collapse>
                <TaskCategoryLabel colors={colors} medium={6}>
                    <h5
                        onClick={() => {
                            // setAnimating(true);
                            const showing = !showActivities;
                            setShowActivities(showing);
                            onShowTasks(showing, category);
                        }}
                    >
                        <Arrow
                            icon="chevron-down"
                            transform={{
                                rotate: showActivities ? 180 : 0
                            }}
                        />
                        {category.name}
                        {!showActivities && (
                            <span> ({category.tasks.length})</span>
                        )}
                    </h5>
                </TaskCategoryLabel>
                <ButtonWrapper
                    className="linearCalendar__buttonWrapper"
                    medium={6}
                    summaryColumn={summaryColumn}
                >
                    {canAdminCategory && (
                        <AddButton
                            colors={colors}
                            onClick={event => editTaskCategory(category, event)}
                        >
                            {labels.editCategory.content}
                        </AddButton>
                    )}
                    {canAdminItems && (
                        <AddButton
                            colors={colors}
                            onClick={event => addTask(category, event)}
                            className={
                                "linearCalendar__addTaskButton " +
                                labels.addTask.className
                            }
                            title={labels.addTask.title}
                        >
                            {labels.addTask.content}
                        </AddButton>
                    )}
                </ButtonWrapper>
            </TaskCategoryHeader>

            <Collapse isOpened={showActivities}>
                <TaskRowWrapper>
                    {category.tasks.map((task, index) => {
                        return (
                            <TaskRow
                                key={index}
                                category={category}
                                task={task}
                                canAdminCategory={canAdminCategory}
                            />
                        );
                    })}
                </TaskRowWrapper>
            </Collapse>
        </TaskCategoryWrapper>
    );
};

CalendarTaskCategory.propTypes = {
    groups: PropTypes.array,
    activityContent: PropTypes.func
};

export default CalendarTaskCategory;
