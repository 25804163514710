import React, { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { withRouter, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";

import {
    Button,
    Row,
    Column,
    Panel,
    Image,
    IconTextButton,
    LoadingIconSmall,
    Badge,
    FileIcon,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    SearchResultSummary,
    SearchResultBadge,
    ShowMore,
} from "@cortexglobal/rla-components";

const LinkResultItem = ({
    url,
    name,
    thumbnail,
    color,
    description,
    margin = "0rem",
}) => {
    const theme = useTheme();
    const history = useHistory();
    const isFullUrl = url.indexOf("http") === 0;

    const viewLink = (event) => {
        event.preventDefault();

        //TODO Record that the link has been used

        if (isFullUrl) {
            window.open(url, "_blank").focus();
        } else {
            history.push(`${url}`);
        }
    };

    return (
        <SearchResultWrapper
            textAlign="center"
            style={{
                display: "flex",
                padding: 0,
                margin: margin,
                height: "100%",
            }}
            boxShadow={"none"}
        >
            <Link
                onClick={(event) => viewLink(event)}
                to={`${url}`}
                as={isFullUrl ? "a" : Link}
                href={`${url}`}
            >
                <SearchResultImageWrapper background={color}>
                    {/* <SearchResultImageWrapper> */}
                    {thumbnail === null ? (
                        <FontAwesomeIcon icon={faLink} transform="grow-30" />
                    ) : (
                        <SearchResultImage src={thumbnail} alt={name} />
                    )}
                </SearchResultImageWrapper>
            </Link>

            <SearchResultButton
                onClick={(event) => viewLink(event)}
                showCircle={theme.search.icons.showCircle}
                iconBefore={false}
                doHover={false}
                icon={theme.search.icons.searchIcon}
                iconColor={theme.search.icons.color}
                iconSize={theme.search.icons.size}
            >
                <SearchResultTitle title={name}>
                    <ShowMore more=" " lines={2}>
                        {name}
                    </ShowMore>
                </SearchResultTitle>
                {description && (
                    <SearchResultSummary title={description}>
                        {description}
                    </SearchResultSummary>
                )}
            </SearchResultButton>
        </SearchResultWrapper>
    );
};
export default withRouter(LinkResultItem);
