import React, { useContext } from "react";
import moment from "moment";
import styled, { useTheme } from "styled-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import { Row, Column, Badge } from "@cortexglobal/rla-components";

import CalendarContext from "../CalendarContext";
import { DetailWrapper, Header } from "./tooltipComponents";

export const ActivityTooltipContent = ({ activity, width }) => {
    const { user } = useContext(CalendarContext);
    const theme = useTheme();
    return (
        <DetailWrapper width={width}>
            <Header>
                <h4>
                    <Badge
                        backgroundColor={
                            theme.planner.colors.activities.activity
                        }
                        style={{ marginRight: "0.4rem" }}
                    >
                        {activity.ref}
                    </Badge>{" "}
                    <strong>{activity.name}</strong>
                </h4>
            </Header>
            <Row className="detailRow">
                <Column small={6}>
                    <strong>{trans("Start Date")}</strong>:
                </Column>
                <Column small={6}>
                    {moment.parseZone(activity.start_date).format("LL")}
                </Column>
            </Row>
            <Row className="detailRow">
                <Column small={6}>
                    <strong>{trans("End Date")}</strong>:
                </Column>
                <Column small={6}>
                    {moment.parseZone(activity.end_date).format("LL")}
                </Column>
            </Row>
        </DetailWrapper>
    );
};

export default ActivityTooltipContent;
