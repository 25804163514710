import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTheme } from "styled-components";
import {
    Row,
    Column,
    Centred,
    Tooltip,
    Loading,
    IconTextButton,
    CircularProgress,
} from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
// import withPanelData from "./withPanelData";
import usePanelData from "./usePanelData";
import styled from "styled-components";
import { FlexPanel } from "../layoutComponents";

const BudgetBreakdown = styled.div`
    padding-top: 1rem;
    text-align: center;
`;
const BudgetSummary = ({ panelId, height, user, theme }) => {
    //cortex-api-local.rla.co.uk/api/v1/budget/available/2020
    const panelData = usePanelData(
        panelId,
        `/api/v1/budget/annual-summary/${new Date().getFullYear()}`
    );

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }
    const { data } = panelData;
    const monthNumber = new Date().getMonth() + 1;

    const percentage =
        panelData.loaded && data.total_contribution.value > 0
            ? Math.round(
                  ((data.outgoing_contribution.value +
                      data.overall.planned_spend.value) /
                      data.total_contribution.value) *
                      100
              )
            : 0;
    return (
        <FlexPanel>
            <Row style={{ width: "100%", flex: "1", paddingTop: "1rem" }}>
                <CircularProgress
                    backgroundPadding={5}
                    strokeWidth={12}
                    value={percentage}
                    text={`${percentage}%`}
                    initialAnimation={false}
                    strokeColor={theme.colors.accent}
                    trailColor={theme.colors.background}
                />
                <BudgetBreakdown>
                    <Row
                        collapse
                        style={{
                            paddingTop: "0.4em",
                            paddingBottom: "0.4em",
                            backgroundColor: theme.colors.gray200,
                        }}
                    >
                        <Column large={6} style={{ textAlign: "left" }}>
                            {trans("Total Budget")}
                        </Column>
                        <Column
                            large={6}
                            style={{ textAlign: "right", fontWeight: "bold" }}
                        >
                            <FormattedCurrency
                                value={data ? data.total_contribution.value : 0}
                                currency={user?.currency?.iso_code || "GBP"}
                                loaded={panelData.loaded}
                            />
                        </Column>
                    </Row>
                    <Row
                        collapse
                        style={{ paddingTop: "0.4em", paddingBottom: "0.4em" }}
                    >
                        <Column large={6} style={{ textAlign: "left" }}>
                            {trans("Planned")}
                        </Column>
                        <Column
                            large={6}
                            style={{ textAlign: "right", fontWeight: "bold" }}
                        >
                            <FormattedCurrency
                                value={
                                    data ? data.overall.planned_spend.value : 0
                                }
                                currency={user?.currency?.iso_code || "GBP"}
                                loaded={panelData.loaded}
                            />
                        </Column>
                    </Row>
                    <Row
                        collapse
                        style={{
                            paddingTop: "0.4em",
                            paddingBottom: "0.4em",
                            backgroundColor: theme.colors.gray200,
                        }}
                    >
                        <Column large={6} style={{ textAlign: "left" }}>
                            {trans("Actual")}
                        </Column>
                        <Column
                            large={6}
                            style={{ textAlign: "right", fontWeight: "bold" }}
                        >
                            <FormattedCurrency
                                value={
                                    data ? data.overall.actual_spend.value : 0
                                }
                                currency={user?.currency?.iso_code || "GBP"}
                                loaded={panelData.loaded}
                            />
                        </Column>
                    </Row>
                    <Row
                        collapse
                        style={{
                            paddingTop: "0.4em",
                            paddingBottom: "0.4em",
                            fontStyle: "italic",
                        }}
                    >
                        <Column large={6} style={{ textAlign: "left" }}>
                            {trans("Remaining")}
                        </Column>
                        <Column
                            large={6}
                            style={{ textAlign: "right", fontWeight: "bold" }}
                        >
                            <FormattedCurrency
                                value={
                                    data.total_contribution.value -
                                        data.overall.planned_spend.value || 0
                                }
                                currency={user?.currency?.iso_code || "GBP"}
                                loaded={panelData.loaded}
                            />
                        </Column>
                    </Row>
                </BudgetBreakdown>
            </Row>
            {/* <Link to="/plan/budget-manager"> */}
            <div style={{ paddingBottom: "1em" }}>
                <IconTextButton as={Link} to="/plan/budget" expanded>
                    {trans("Budget Manager")}
                </IconTextButton>
            </div>
            {/* </Link> */}
        </FlexPanel>
    );
};

// export default withPanelData(BudgetSummary, "/api/v1/budget/annual-summary");
export default withTheme(BudgetSummary);
