import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import {
    Row,
    Column,
    Button,
    Select,
    InputField,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

// const StyledLink = styled(Link)`
//     text-indent: none;
// `;

const AddNewAsset = ({
    assetsPath,
    creatableAssetTypes,
    onClose = () => {},
    folderUuid,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const [assetType, setAssetType] = useState("");
    const [name, setName] = useState("");
    useEffect(() => {
        if (creatableAssetTypes.length === 1) {
            setAssetType(creatableAssetTypes[0].alias);
        }
    }, [creatableAssetTypes]);

    const addNewAsset = useCallback(
        (name, assetType) => {
            // console.log(assetType);
            axios
                .post(`/api/v1/assets`, {
                    name,
                    asset_type: assetType,
                    asset_folder_uuid: folderUuid,
                })
                .then(({ data }) => {
                    onClose();
                    history.push(`${assetsPath}/${data.data.uuid}/admin`);
                })
                .catch((e) => console.log(e));
        },
        [name, assetType]
    );
    return (
        <Row>
            <Column>
                <h3>{trans("Create New Asset")}</h3>
            </Column>
            <Column style={{ marginBottom: "1em" }}>
                <p>
                    {trans("To create a new asset, start by giving it a name")}
                </p>
            </Column>

            <Column style={{ marginBottom: "1em" }}>
                <InputField
                    label={trans("Name")}
                    type="text"
                    name="name"
                    placeholder={intl.formatMessage({
                        id: "e.g. New Asset",
                    })}
                    value={name}
                    labelMarginBottom={0.5}
                    onChange={(event) => setName(event.value)}
                />
                {creatableAssetTypes.length > 1 && (
                    <Select
                        name="asset_type"
                        value={assetType}
                        options={creatableAssetTypes.map((assetType) => ({
                            ...assetType,
                            text: assetType.description
                                ? `${assetType.name} - ${assetType.description}`
                                : assetType.name,
                            value: assetType.value || assetType.alias,
                        }))}
                        onChange={(event) => setAssetType(event.value)}
                    />
                )}
            </Column>
            <Column>
                <Row collapse>
                    <Column medium={6} collapse>
                        <Button
                            type="secondary"
                            onClick={onClose}
                            style={{ padding: "1em 2em" }}
                        >
                            {trans("Cancel")}
                        </Button>
                    </Column>
                    <Column medium={6} style={{ textAlign: "right" }} collapse>
                        <Button
                            onClick={(event) => addNewAsset(name, assetType)}
                        >
                            {trans("Create")}
                        </Button>
                    </Column>
                </Row>
            </Column>
        </Row>
    );
};

// const AddNewAsset = ({ onClose }) => {
//     const [assetSettings, setAssetSettings] = useState({
//         asset: {
//             name: {
//                 label: "Name",
//                 name: "title",
//                 type: "text",
//                 value: ""
//             }
//         },
//         editor: {}
//     });
//     useEffect(() => {
//         axios
//             .get(`/api/v1/asset-microsite`)
//             .then(({ data }) => {
//                 setAssetSettings({ ...assetSettings, editor: data });
//             })
//             .catch(e => console.log(e));
//     }, []);

//     return (
//         <div>
//             <h2>{trans("Add Asset")}</h2>
//             <pre>{JSON.stringify(assetSettings, null, 2)}</pre>
//             {assetSettings.editor.globalSettings &&
//                 assetSettings.editor.globalSettings.map(setting => {
//                     return <InputType tag={setting} />;
//                 })}

//             <Button onClick={handleSubmit}>{trans("Create Asset")}</Button>
//         </div>
//     );
// };

export default AddNewAsset;
