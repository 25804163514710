import React, {
    useContext, useEffect,
    useRef,
    useState
} from "react";
import styled from "styled-components";
import { useSpring } from "react-spring";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";

import {
    useSequencedVideo,
    Setup,
    Caption as CaptionType
} from "../hooks/useSequencedVideo";
import { ContentWrapper } from "./slideComponents";
import { Video, Image, Captions, Caption } from "./videoComponents";
import MicrositeContext from "../MicrositeContext";
import { RenderContentBlock } from "./RenderContentBlock";

const StyledCaptions = styled(Captions)<CaptionsProps>`
    position: absolute;
    z-index: 1;
    width: ${props => props.contentWidth};
    font-size: 0.55em;
    
    ${props =>
        props.contentRight
            ? `
        right: ${props.contentRight};
    `
            : `
        left: ${props.contentLeft};
    `}

    ${props =>
        props.contentBottom
            ? `
        bottom: ${props.contentBottom};
    `
            : `
        top: ${props.contentTop};
    `}
      
    ${({ columnWidth }) =>
        columnWidth &&
        `
        display: flex;
        flex-direction: row;
    `};      
`;

export const SequencedVideo = ({
    isCurrent,
    disableSequencing,
    setup,
    navigationDispatch,
    contentTop = "20%",
    contentLeft = "5%",
    contentWidth = "40%",
    contentRight,
    contentBottom,
    highlightColor,
    columnWidth,
    centerAlign
}: {
    isCurrent: boolean;
    disableSequencing: boolean;
    setup: Setup;
    navigationDispatch: any;
    contentTop?: string;
    contentWidth?: string;
    contentAlign?: string;
    contentBottom?: string;
    highlightColor: string;
    columnWidth?: string;
    contentLeft?: string;
    contentRight?: string;
    centerAlign?: boolean;
}) => {
    const [videoPlayed, setVideoPlayed] = useState(false);
    const { videoSrc, imageSrc, captions = [], autoplay = false } = setup;

    const videoElement = useRef<HTMLVideoElement | null>(null);

    let { preview } = useContext(MicrositeContext);

    const { currentTransition } = useSequencedVideo(
        videoElement,
        setup,
        navigationDispatch,
        isCurrent,
        disableSequencing
    );

    const isVideoPlaying = !videoElement?.current?.paused && !!videoElement?.current?.currentTime && !videoElement?.current?.ended;

    if (autoplay && !isVideoPlaying && !videoPlayed) {
        videoElement?.current?.play();
        setTimeout(() => {
            setVideoPlayed(true);
        }, 1000);
    }

    const printing = useQueryString().get("printing");

    return (
        <ContentWrapper>

            {imageSrc && (preview || printing) ? (
                <Image src={imageSrc} style={{ maxWidth: "100%" }} />
            ) : (
                <Video
                    loop={false}
                    ref={videoElement}
                    src={videoSrc}
                >
                    {trans(
                        "Your browser doesn't appear to support the HTML5 video tag"
                    )}
                </Video>
            )}
            <StyledCaptions
                contentLeft={contentLeft}
                contentRight={contentRight}
                contentBottom={contentBottom}
                contentTop={contentTop}
                contentWidth={contentWidth}
                columnWidth={columnWidth}
                style={useSpring({
                    opacity: true ? 1 : 0
                })}
            >
                {captions.map((caption: CaptionType, index: number) => {
                    return (
                        <div
                            style={
                                columnWidth
                                    ? { width: columnWidth, padding: "0 1em" }
                                    : {}
                            }
                        >
                            <Caption
                                highlightColor={highlightColor}
                                key={caption.uuid}
                                showing={
                                    preview || printing || caption.enterAt === 0
                                        ? true
                                        : index <= currentTransition
                                }
                                bulletPoint={caption.bulletPoint}
                                centerAlign={centerAlign}
                            >
                                <RenderContentBlock
                                    value={caption.value}
                                    footnotes={caption.footnotes}
                                />
                            </Caption>
                        </div>
                    );
                })}
            </StyledCaptions>
        </ContentWrapper>
    );
};

interface CaptionsProps {
    contentTop?: string;
    contentWidth?: string;
    contentAlign?: string;
    contentBottom?: string;
    columnWidth?: string;
    contentLeft?: string;
    contentRight?: string;
}

export default SequencedVideo;
