import React, { useState, useEffect } from "react";
import axios from "axios";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trans } from "@cortexglobal/rla-intl";
import {
    PageTitle,
    IconTextButton,
    Modal,
    Row,
    Column,
    InputField,
    Tabordion,
    Tooltip,
    Badge,
} from "@cortexglobal/rla-components";
import UserList from "./components/UserList";
import EditUserForm from "./components/EditUserForm";
import User from "./objects/User";
import { isEmpty } from "lodash";

import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const UserManagement = (props) => {
    const { can } = usePermissions();

    const [loadingState, setLoadingState] = useState("loading");
    const [users, setUserList] = useState([]);
    const [unfilteredUsers, setUnfilteredUsers] = useState([]);

    const [formLoadingState, setFormLoadingState] = useState("loaded");
    const [formErrors, setFormErrors] = useState({});
    const [showHideForm, setShowHideForm] = useState("hide");
    const [editUser, setEditUser] = useState({});

    useEffect(() => {
        getUsers();
    }, []);

    const setUsers = (users) => {
        setUserList(users);
        setUnfilteredUsers(users);
    };

    const getUsers = () => {
        setLoadingState("loading");

        axios
            .get(`/api/v1/manager/users`)
            .then(({ data }) => {
                const users = data.data
                    .map((userData) => {
                        return new User(userData);
                    })
                    .sort((a, b) => a.name.localeCompare(b.name)); // sort by name

                setUsers(users);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });

        return;
    };

    const updateUsers = (updatedUser, destroy) => {
        destroy = !!destroy;

        let newUsers = [...users];

        const index = newUsers.findIndex(
            (user) => user.uuid == updatedUser.uuid
        );

        if (index === -1) {
            // -1 indicates a new user
            newUsers.push(updatedUser);
        } else {
            if (destroy) {
                newUsers = newUsers.filter(
                    (user) => user.uuid !== updatedUser.uuid
                );
            } else {
                newUsers = newUsers.map((user) =>
                    user.uuid == updatedUser.uuid ? updatedUser : user
                );
            }
        }

        newUsers = newUsers.sort((a, b) => a.name.localeCompare(b.name)); // sort by name

        setUsers(newUsers);
    };

    const showForm = (user = {}) => {
        setEditUser(user);
        setShowHideForm("show");
    };

    const hideForm = () => {
        setEditUser({});
        setFormErrors({});
        setFormLoadingState("loaded");
        setShowHideForm("hide");
    };

    const formSubmit = (editUser, params) => {
        setFormLoadingState("loading");

        if (editUser.uuid) {
            editUser.update(params).then((result) => {
                if (result instanceof User) {
                    updateUsers(result);
                    hideForm();
                } else {
                    setShowHideForm("show");
                    setFormErrors(result);
                    setFormLoadingState("loaded");
                }
            });
        } else {
            User.create(params).then((result) => {
                if (result instanceof User) {
                    hideForm();
                    updateUsers(result);
                } else {
                    setShowHideForm("show");
                    setFormErrors(result);
                    setFormLoadingState("loaded");
                }
            });
        }
    };

    const usersAwaitingApproval =
        loadingState === "loaded" && !isEmpty(users)
            ? users.filter((user) => user.status === "awaiting-approval")
            : [];

    const usersAwaitingActivation =
        loadingState === "loaded" && !isEmpty(users)
            ? users.filter((user) => user.status === "awaiting-activation")
            : [];

    const usersActive =
        loadingState === "loaded" && !isEmpty(users)
            ? users.filter(
                  (user) =>
                      user.status === "active" ||
                      user.status === "account-disabled"
              )
            : [];

    const filterUsers = (search) => {
        let usersForSearching = [...unfilteredUsers];

        setUserList(
            usersForSearching.filter((user) => {
                return user.name.toUpperCase().includes(search.toUpperCase()) ||
                user.email.toUpperCase().includes(search.toUpperCase())  ||
                user?.ref.toUpperCase().includes(search.toUpperCase())  ||
                user?.primary_role?.name.toUpperCase().includes(search.toUpperCase()) ||
                user?.primary_group?.name.toUpperCase().includes(search.toUpperCase())
            })
        );
    };

    return (
        <div id="user-management" style={{ marginBottom: "20px" }}>
            <PageTitle title={trans("User Management")}>
                <InputField
                    name="search"
                    onChange={(e) => filterUsers(e.value)}
                    style={{
                        margin: "0",
                        minWidth: "20rem",
                    }}
                />
                {can("manage-users-create") && (
                    <>
                        <FontAwesomeIcon
                            icon={faSearch}
                            style={{ margin: "0 16px" }}
                        />
                        <IconTextButton
                            style={{ textDecoration: "none" }}
                            icon={faPlus}
                            showCircle={false}
                            expanded={false}
                            onClick={() => showForm()}
                        >
                            {trans("Add User")}
                        </IconTextButton>
                    </>
                )}
            </PageTitle>
            <Row>
                <Tabordion current={0}>
                    <Row
                        heading={
                            <div>
                                <Tooltip
                                    text={trans(
                                        "User account accounts that require your attention."
                                    )}
                                    showIcon={false}
                                >
                                    {trans("Attention Required")}
                                </Tooltip>
                                {usersAwaitingApproval.length > 0 && (
                                    <Badge>
                                        {usersAwaitingApproval.length}
                                    </Badge>
                                )}
                            </div>
                        }
                    >
                        <Column medium={12}>
                            <UserList
                                loadingState={loadingState}
                                users={usersAwaitingApproval}
                                updateUsers={updateUsers}
                                showEdit={showForm}
                            />
                        </Column>
                    </Row>

                    <Row
                        heading={
                            <div>
                                <Tooltip
                                    text={trans(
                                        "Users awaiting activation have not yet activated their account by clicking the activation link sent to their email."
                                    )}
                                    showIcon={false}
                                >
                                    {trans("Invited Users")}
                                </Tooltip>
                                {usersAwaitingActivation.length > 0 && (
                                    <Badge>
                                        {usersAwaitingActivation.length}
                                    </Badge>
                                )}
                            </div>
                        }
                    >
                        <Column medium={12}>
                            <UserList
                                loadingState={loadingState}
                                users={usersAwaitingActivation}
                                updateUsers={updateUsers}
                                showEdit={showForm}
                            />
                        </Column>
                    </Row>

                    <Row
                        heading={
                            <div>
                                <Tooltip
                                    text={trans(
                                        "Active users can use the site as normal. Disabled users will not be able to log in."
                                    )}
                                    showIcon={false}
                                >
                                    {trans("All Other Users")}
                                </Tooltip>
                                {usersActive.length > 0 && (
                                    <Badge>{usersActive.length}</Badge>
                                )}
                            </div>
                        }
                    >
                        <Column medium={12}>
                            <UserList
                                loadingState={loadingState}
                                users={usersActive}
                                updateUsers={updateUsers}
                                showEdit={showForm}
                            />
                        </Column>
                    </Row>
                </Tabordion>
            </Row>

            {(can("manage-users-create") || can("manage-users-edit")) &&
                showHideForm === "show" && (
                    <Modal
                        key={"editUser"}
                        onClose={() => hideForm()}
                        visible={showHideForm === "show"}
                        closeOnWrapperClick={false}
                    >
                        <EditUserForm
                            user={editUser}
                            onSubmit={formSubmit}
                            onCancel={hideForm}
                            errors={formErrors}
                            loadingStatus={formLoadingState}
                        />
                    </Modal>
                )}
        </div>
    );
};

export default UserManagement;
