export const filterEmptyFilterOptions = (filters, excludedBlocks = []) => {
    return filters
        .filter((filterBlock) => {
            return !excludedBlocks.includes(filterBlock.name);
        })
        .map((filterBlock) => {
            if (Array.isArray(filterBlock.options)) {
                return {
                    ...filterBlock,
                    options: filterBlock.options.filter((option) => {
                        //Only return options where the live_asset_count is not 0
                        //(handily leaves ones that don't have a live_asset_count)
                        // return true;
                        return option.extra.live_asset_count !== 0;
                    }),
                };
            }
            return filterBlock;
        });
};

export const filterAndAddCountToOptions = (
    filters,
    categoryTotals,
    excludedBlocks = []
) => {
    return filters.map((filterBlock) => {
        let processedFilterBlock = { ...filterBlock };
        let blockName = filterBlock.name;
        if (Array.isArray(filterBlock.options)) {
            processedFilterBlock = {
                ...processedFilterBlock,
                options: filterBlock.options
                    .filter((option) => {
                        //Only return options where the live_asset_count is not 0
                        //(handily leaves ones that don't have a live_asset_count)
                        // return true;
                        return (
                            blockName === "category-hierachy" ||
                            option.extra.live_asset_count >= 0 ||
                            (Array.isArray(option.extra) &&
                                option.extra.length === 0)
                        );
                    })
                    .map((option) => {
                        const children = Array.isArray(option.children)
                            ? processChildren(option, categoryTotals, blockName)
                            : option.children;
                        const count =
                            categoryTotals[blockName] &&
                            categoryTotals[blockName][option.value] > 0
                                ? `(${categoryTotals[blockName][option.value]})`
                                : "";
                        return {
                            ...option,
                            text: `${option.text} ${count}`,
                            name: `${option.text} ${count}`,
                            originalText: option.text,
                            count: count,
                            children: children,
                        };
                    }),
            };
        }

        return processedFilterBlock;
    });
};

export const processChildren = (option, categoryTotals, blockName) => {
    return option.children.map((childOption) => {
        const children = Array.isArray(childOption.children)
            ? processChildren(childOption, categoryTotals, blockName)
            : childOption.children;
        const count =
            categoryTotals[blockName] &&
            categoryTotals[blockName][childOption.value] > 0
                ? `(${categoryTotals[blockName][childOption.value]})`
                : "";
        return {
            ...childOption,
            parent: option,
            text: `${childOption.text} ${count}`,
            name: `${childOption.text} ${count}`,
            originalText: childOption.text,
            count: count,
            children: children,
        };
    });
};
