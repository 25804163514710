import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading, Modal } from "@cortexglobal/rla-components";
import NotifyComposer from "./NotifyComposer";

export const NotifyModal = ({
    visible = false,
    onClose,
    smartRef = "",
    title = "",
    subject = "",
    prependedMessage = "",
}) => {
    const [mailingLists, setMailingLists] = useState([]);
    const [mailingListLoaded, setMailingListLoaded] = useState(false);
    useEffect(() => {
        if (smartRef !== "") {
            axios
                .get(`api/v1/communication/mailing-lists/${smartRef}`)
                .then((response) => {
                    setMailingLists(response.data);
                    setMailingListLoaded(true);
                });
        }
    }, [smartRef]);

    return (
        <Modal
            key="notifyModal"
            onClose={onClose}
            visible={visible}
            closeOnWrapperClick={false}
        >
            {mailingListLoaded ? (
                <NotifyComposer
                    onClose={onClose}
                    mailingLists={mailingLists}
                    title={title}
                    subject={subject}
                    prependedMessage={prependedMessage}
                />
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

export default NotifyModal;

// import React, { useState } from "react";
// import { Modal } from "@cortexglobal/rla-components";
// import { format } from "@cortexglobal/cortex-utilities";
// import Composer from "../Composer";
// import { useEffect } from "react";

// export const ShareModal = ({
//     visible = false,
//     onClose,
//     urls = [],
//     title = "",
//     subject = "",
//     prependedMessage = "",
// }) => {
//     const emptyMessage = {
//         format: "new",
//         to: null,
//         user_uuid: null,
//         subject: subject,
//         message: "",
//         send_now: true,
//         send_date: format(new Date(), "dd/MM/yyyy"),
//         send_time: "",
//     };
//     const [composerMessage, setComposerMessage] = useState(emptyMessage);

//     useEffect(() => {
//         const newMessage = processUrlsToMessage(urls);

//         console.log({ newMessage });
//         setComposerMessage({
//             ...composerMessage,
//             message: newMessage,
//         });
//     }, [urls]);

//     const processUrlsToMessage = (urls) => {
//         const quillUrls = urls.map((url) => {
//             return {
//                 insert: `\n${url.title}\n`,
//                 attributes: { link: url.url },
//             };
//         });

//         return { ops: [{ insert: prependedMessage }, ...quillUrls] };
//     };
//     const messageSentCallback = () => {
//         setComposerMessage(emptyMessage);
//         onClose();
//     };
//     return (
//         <Modal
//             key="shareLink"
//             onClose={onClose}
//             visible={visible}
//             closeOnWrapperClick={false}
//         >
//             <Composer
//                 title={title}
//                 settings={{}}
//                 message={composerMessage}
//                 successCallback={() => messageSentCallback()}
//                 hideComposer={() => toggleComposer(false)}
//             />
//         </Modal>
//     );
// };

// export default ShareModal;
