import React from "react";
import styled, { css } from "styled-components";
import { shade, hexToRgb } from "@cortexglobal/cortex-utilities";

export const baseInputStyle = css`
    padding: 0 5px;
    height: ${(props) => props.height}px;
    width: ${(props) => (props.width ? props.width : "100%")};
    /* width: ${(props) =>
        props.labelWidth ? `calc(100% - ${props.labelWidth}px)` : "100%"}; */
    border: 1px solid
        ${(props) =>
            props.error
                ? props.theme.input.error.borderColor
                : props.theme.input.borderColor};
    /* margin-bottom: ${(props) => props.theme.spacing.margin}rem; */
    margin-bottom: ${(props) => {
        return props.marginBottom ? props.marginBottom : "1.2rem";
    }};
    font: inherit;
    font-size: ${(props) => props.theme.input.fontSize};
    font-weight: ${(props) => props.theme.input.fontWeight};
    background: ${(props) => props.theme.input.background};
    color: ${(props) =>
        props.error ? props.theme.input.error.color : props.theme.input.color};

    // Input group styles
    ${(props) =>
        props.inputGroup
            ? css`
                  border-radius: 0 ${(props) => props.theme.input.radius}
                      ${(props) => props.theme.input.radius} 0;
                  border-left: none;
              `
            : css`
                  border-radius: ${(props) => props.theme.input.radius};
              `};

    // focus
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 3px
            rgba(
                ${(props) => {
                    const rgb = hexToRgb(props.theme.input.focusColor);
                    return `${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2`;
                }}
            );
    }
    // disabled
    &:disabled {
        background: ${(props) => props.theme.input.disabledBackground};
        cursor: not-allowed;
    }
`;

export default styled.input`
    ${baseInputStyle}
`;
