import React from "react";
import styled from "styled-components";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { Panel } from "@cortexglobal/rla-components";
import { CommentUserSummary } from "./CommentUserSummary";
import { Comment } from "./Comment";

const CommentListWrapper = styled.ul`
    margin: 0;
    padding: 0 0.5rem 0 0;
    border: 0;
    list-style: none;
`;
const CommentWrapper = styled.li`
    display: flex;
    flex-direction: row;
`;

interface CommentListProps {
    comments: {
        uuid: string;
        comment: string;
        title: string;
        created_at: string;
        updated_at: string;
        user: {
            uuid: string;
            name: string;
            avatar: string;
            group_name: string;
        };
    }[];
    user: {
        uuid: string;
    };
    editComment: Function;
    deleteComment: Function;
    deletable: boolean;
    showTitle: boolean;
    newestFirst: boolean;
}

export const CommentList = ({
    comments,
    user,
    editComment,
    deleteComment,
    deletable,
    showTitle,
    newestFirst
}: CommentListProps) => {
    return (
        <CommentListWrapper>
            {comments.length <= 0 ? (
                <div style={{ margin: "1rem 0" }}>
                    {trans("No comments found")}
                </div>
            ) : (
                comments
                    .sort((a, b) => {
                        if (a.created_at > b.created_at) {
                            return newestFirst ? -1 : 1;
                        }
                        if (a.created_at < b.created_at) {
                            return newestFirst ? 1 : -1;
                        }
                        return 0;
                    })
                    .map(comment => {
                        return (
                            <CommentWrapper key={comment.uuid}>
                                <CommentUserSummary user={comment.user} />
                                <Comment
                                    comment={comment}
                                    user={user}
                                    editComment={editComment}
                                    deleteComment={deleteComment}
                                    deletable={deletable}
                                    showTitle={showTitle}
                                />
                            </CommentWrapper>
                        );
                    })
            )}
        </CommentListWrapper>
    );
};
