import React, { useContext } from "react";

import { Row, Column, SelectField } from "@cortexglobal/rla-components";
import InputType from "./InputType";
import { ManageFootnotes } from "./ManageFootnotes";
import AssetContext from "../../AssetContext";
import { trans } from "@cortexglobal/rla-intl";
const EditorInputGroup = ({
    asset,
    baseAsset,
    path,
    tag,
    onChange,
    chooseImage,
    useFootnotes,
    editingUserAsset
}) => {
    const requiresEdit = tag.requiresEdit ? tag.requiresEdit : false;
    const locked = tag.locked ? tag.locked : false;
    const hasPreFilledValues = tag.hasPreFilledValues
        ? tag.hasPreFilledValues
        : false;

    if (locked) {
        return null;
    }
    return (
        <Column>
            {!hasPreFilledValues ? (
                <InputType
                    asset={asset}
                    baseAsset={baseAsset}
                    tag={tag}
                    path={path}
                    onChange={onChange}
                    chooseImage={chooseImage}
                    useFootnotes={useFootnotes}
                    editingUserAsset={editingUserAsset}
                />
            ) : (
                <SelectField
                    style={{ height: "40px" }}
                    name={tag.name}
                    value={tag.value}
                    label={tag.label}
                    options={tag.preFilledValues.map(val => {
                        return {
                            text: val["Pre-filled Value"],
                            value: val["Pre-filled Value"]
                        };
                    })}
                    onChange={({ value }) => {
                        onChange("value", path, {
                            name: tag.name,
                            value: value
                        });
                    }}
                />
            )}
            {useFootnotes && (
                <ManageFootnotes
                    asset={asset}
                    tag={tag}
                    path={path}
                    onChange={onChange}
                />
            )}
        </Column>
    );
};

export default EditorInputGroup;
