import React from "react";
import { Row, Column, IconTextButton } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import styled from "styled-components";
import { format } from "@cortexglobal/cortex-utilities";

import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Label = styled.span`
    font-weight: bold;
    padding-right: 20px;
`;

const BulkFileItem = (props) => {
    const { item, downloadModal } = props;

    return (
        <Row
            style={{
                height: "50px",
                background: "white",
                borderBottom: "1px solid gray",
                paddingTop: "10px",
            }}
        >
            <Column medium={6}>
                <Label>{trans("Asset")}</Label>
                {item?.entity?.name}
            </Column>
            <Column medium={3}>
                <Label>{trans("Requested At")}</Label>{" "}
                {format(new Date(item.requested_at), "Pp")}
            </Column>
            <Column medium={2}>
                <Label>{trans("Status")}</Label>{" "}
                {item.status === "complete"
                    ? trans("Complete")
                    : trans("Pending")}
            </Column>
            <Column medium={1}>
                {item.status === "complete" && (
                    <IconTextButton
                        onClick={(e) => downloadModal(item)}
                        showCircle={false}
                        icon={faDownload}
                    />
                )}
            </Column>
        </Row>
    );
};

export default BulkFileItem;
