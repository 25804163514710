export const colors = {
    primary: "#ffffff",
    secondary: "#041e42",
    accent: "#d6001c",

    // lightGray: "##f5f5f5",
    // mediumGray: "#e8e8e8",
    // darkGray: "#53565a",

    white: "#ffffff",
    gray100: "#f5f5f5",
    gray200: "#e8e8e8",
    gray300: "#cbc9c7",
    gray400: "#bbc0c7",
    gray500: "#aaafb7",
    gray600: "#9a9fa7",
    gray700: "#53565a",
    gray800: "#404346",
    gray900: "#2b2d2f",
    black: "#171e26",

    alert: "#FF0000",
    warning: "#ff9800",
    success: "#1d933f",
    info: "#00bcd4",
    background: "#e8e8e8",
    platinum: "#a2b8e4",
    gold: "#ffdb04",
    silver: "#dadada",
    bronze: "#ff8604",
    highlight: "#e6c2c1"
};
export const chartColors = [
    "#d74540",
    "#e6c2c1",
    "#b1ab87",
    "#426149",
    "#546874",
    "#ff6f69"
];

export const primary = {
    title: {
        fill: colors.black,
        fontSize: "16px",
        fontWeight: "bold"
    },
    data: {
        fill: colors.primary
    },
    axis: {
        stroke: colors.black
    },
    axisLabel: {
        fontSize: 18,
        padding: 16,
        fill: colors.black
    },
    ticks: {
        stroke: colors.black,
        size: 5
    },
    tickLabels: {
        fontSize: 16,
        padding: 0,
        fill: colors.black
    },
    labels: {
        fill: colors.black,
        fontSize: 16
    },
    legend: {
        labels: {
            fill: colors.black
        }
    },
    radius: 3,
    colorScale: chartColors
};

export const pie = {
    labels: {
        fill: colors.gray100,
        fontSize: 16
    }
};
