import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Button,
    Tabordion,
    SelectField,
    Panel,
} from "@cortexglobal/rla-components";
import set from "lodash/set";
import get from "lodash/get";

import PageEditor from "./components/PageEditor";
import EditorContext from "./EditorContext";

import { TabordionContentWrapper } from "./components/editorComponents";
import { onConfigurationChange } from "./components/sharedEditorFunctions";

const CenterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 600px;
`;

import { SectionHeading } from "./components/SectionHeading";

const VideoEditor = ({
    assetAdmin,
    asset,
    assetsPath,
    standardOnChange,
    fullAdmin,
    saveUpdatedAsset,
    baseAsset = null,
    autofill = [],
    editingUserAsset = false,
    config,
}) => {
    let history = useHistory();
    const [currentTemplateName, setCurrentTemplateName] = useState("");
    const [currentTemplate, setCurrentTemplate] = useState(null);

    if (!asset || !asset.editable) {
        return null;
    }

    let { configuration } = asset;

    const availableTemplates = !configuration
        ? []
        : Object.values(configuration.pages);
    let basePath = `pages[${currentTemplateName}]`;

    if (availableTemplates.length === 0) {
        return (
            <CenterDiv>
                <h3>
                    {trans("You currently have no selected video templates")}
                </h3>

                <Button
                    onClick={() => {
                        history.push(
                            `${assetsPath}/${asset.uuid}/admin/templates`
                        );
                    }}
                >
                    {trans("Select templates")}
                </Button>
            </CenterDiv>
        );
    }
    return (
        <EditorContext.Provider
            value={{
                assetAdmin: assetAdmin,
                saveUpdatedAsset: saveUpdatedAsset,
            }}
        >
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <SectionHeading>
                        {trans("Editing Video End-Frame")}
                    </SectionHeading>
                    <SelectField
                        name="currentTemplate"
                        label="Template:"
                        value={currentTemplateName}
                        emptyOption="--Pick a Template--"
                        options={availableTemplates.map((template) => {
                            return {
                                text: template.displayName,
                                value: template.name,
                            };
                        })}
                        onChange={({ value }) => {
                            setCurrentTemplateName(value);
                            setCurrentTemplate(configuration.pages[value]);
                        }}
                        // expanded
                    />
                </div>
                {currentTemplateName !== "" ? (
                    <Row>
                        <Column collapse large={6}>
                            <Panel style={{ padding: "0" }}>
                                <Tabordion
                                    current={0}
                                    unmounts={false}
                                    // alwaysShowTabs={false}
                                >
                                    <TabordionContentWrapper
                                        style={{ padding: "0" }}
                                        heading={
                                            <span>{trans("Content")}</span>
                                        }
                                    >
                                        {currentTemplate &&
                                            currentTemplate.content && (
                                                <PageEditor
                                                    basePath={`${basePath}.content`}
                                                    asset={asset}
                                                    baseAsset={baseAsset}
                                                    onChange={(
                                                        property,
                                                        path,
                                                        event
                                                    ) =>
                                                        onConfigurationChange(
                                                            standardOnChange,
                                                            asset,
                                                            property,
                                                            event,
                                                            path
                                                        )
                                                    }
                                                    fullAdmin={fullAdmin}
                                                    editingUserAsset={
                                                        editingUserAsset
                                                    }
                                                    pageElements={
                                                        currentTemplate.content
                                                    }
                                                    useFootnotes={false}
                                                    autofill={autofill}
                                                />
                                            )}
                                    </TabordionContentWrapper>
                                    <TabordionContentWrapper
                                        heading={
                                            <span>{trans("Settings")}</span>
                                        }
                                    >
                                        <h3>{trans("Template Settings")}</h3>

                                        {currentTemplate &&
                                            currentTemplate.settings && (
                                                <PageEditor
                                                    basePath={`${basePath}.settings`}
                                                    asset={asset}
                                                    onChange={(
                                                        property,
                                                        path,
                                                        event
                                                    ) =>
                                                        onConfigurationChange(
                                                            standardOnChange,
                                                            asset,
                                                            property,
                                                            event,
                                                            path
                                                        )
                                                    }
                                                    fullAdmin={fullAdmin}
                                                    editingUserAsset={
                                                        editingUserAsset
                                                    }
                                                    pageElements={
                                                        currentTemplate.settings
                                                    }
                                                    useFootnotes={false}
                                                />
                                            )}
                                    </TabordionContentWrapper>
                                </Tabordion>
                            </Panel>
                        </Column>
                        <Column
                            style={{ padding: "10", margin: "100px auto" }}
                            large={6}
                        >
                            <video
                                key={currentTemplate.thumbnail}
                                controls
                                style={{ maxWidth: "100%" }}
                            >
                                <source
                                    src={currentTemplate.thumbnail}
                                    type="video/mp4"
                                />
                                Your browser does not support the
                                <code>video</code> element.
                            </video>
                        </Column>
                    </Row>
                ) : (
                    <CenterDiv>
                        <Row>
                            <Column>
                                <h2>{trans("Please select a template")}</h2>
                            </Column>
                        </Row>
                    </CenterDiv>
                )}
            </>
        </EditorContext.Provider>
    );
};

export default VideoEditor;
