import React, { useEffect, useState } from "react";
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Column,
    useAddToast,
} from "@cortexglobal/rla-components";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import OrderTable from "./components/OrderTable";

const Orders = ({ path }) => {
    const addToast = useAddToast();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const getOrders = () => {
        axios
            .get("/api/v1/orders")
            .then(({ data }) => {
                setLoading(false);
                setOrders(data.data);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "Error loading orders",
                    showFor: 5000,
                });
            });
    };

    useEffect(() => {
        getOrders();
    }, []);

    return (
        <>
            <PageTitle title={trans("Orders")}>
                {false && (
                    <IconTextButton
                        onClick={() => {}}
                        // as={Link}
                        // to={`${path}/create`}
                        showCircle={false}
                        icon={faPlus}
                    >
                        {trans("Add new Order")}
                    </IconTextButton>
                )}
            </PageTitle>

            <Row>
                <Column>
                    <OrderTable orders={orders} path={path} loading={loading} />
                </Column>
            </Row>
        </>
    );
};

export default Orders;
