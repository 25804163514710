import React from "react";
import styled, { useTheme } from "styled-components";
import StarRatingComponent from "react-star-rating-component";

const StarWrapper = styled.span`
    display: inline-block;
    font-size: ${({ theme, fontSize }) =>
        fontSize ? fontSize : theme.starRating.fontSize};
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : "1.2rem"};
    line-height: ${props => (props.lineHeight ? props.lineHeight : "18px")};
`;
const StyledStarRating = ({
    onChange,
    value,
    name,
    fontSize,
    lineHeight,
    ...rest
}) => {
    const theme = useTheme();
    const { starColor, emptyStarColor } = theme.starRating;
    return (
        <StarWrapper fontSize={fontSize} lineHeight={lineHeight} {...rest}>
            <StarRatingComponent
                onStarClick={onChange}
                value={value}
                name={name}
                starColor={starColor}
                emptyStarColor={emptyStarColor}
                {...rest}
            />
        </StarWrapper>
    );
};

export default StyledStarRating;
