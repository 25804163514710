import React, { MouseEvent, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import MicrositeContext from "../../MicrositeContext";
import { SET_PAGE_INDEX } from "../../hooks/useSlideshowNavigation";

const MenuContainer = styled.div<MenuContainerProps>`
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    justify-content: center;
    align-items: center;
    column-count: 3;

    background: ${(props) => {
        if (props?.globalTheming?.backgroundImage)
            return `url(${props?.globalTheming?.backgroundImage}) no-repeat fixed center`;
        if (props?.globalTheming?.backgroundColor)
            return props.globalTheming.backgroundColor;
        return "white";
    }};
`;

const NavigationButton = styled.button`
    background: none;
    outline: none;
    border: none;
    font-size: 0.7em;
    text-align: left;
    cursor: pointer;
    padding: 0.2em;
    padding-left: 3rem;
    font-weight: bold;
    width: 100%;

    color: ${(props) => (props.color ? props.color : "white")};
`;

const NavigationContainer = styled.div<NavigationButtonProps>`
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const NavigationContainerOuter = styled.div<NavigationOuterProps>`
    width: 70%;
    display: flex;
    margin-top: -${({ marginTop }) => (marginTop ? parseInt(marginTop) * 4 : "0")}px;
`;

const NavigationGroupButton = styled.p`
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.5rem 0 0.5rem 0;
    width: 100%;
    font-size: 1rem;
`;

export const OverlayNavigation = ({
    onClose,
    pages,
    visible,
}: {
    visible: boolean;
    onClose: () => void;
    pages: any[];
}) => {
    const { globalTheming, navigationDispatch, slideSize } =
        useContext(MicrositeContext);

    type GroupPage = {
        groupName: string;
        pages: {
            name: string;
            index: number;
            hidden: boolean;
        }[];
    };

    const newPages: GroupPage[] = [];

    const [rowOne, setRowOne] = useState<GroupPage[]>([]);
    const [rowTwo, setRowTwo] = useState<GroupPage[]>([]);

    const isHiddenFromNavigation = (page: any) => {
        return page[1].settings.filter(
            (setting: any) => setting.name === "hideFromNavigation"
        ).length
            ? !!page[1].settings.filter(
                  (setting: any) => setting.name === "hideFromNavigation"
              )[0].value
            : false;
    };

    useEffect(() => {
        let rowOneInner: GroupPage[] = [];
        let rowTwoInner: GroupPage[] = [];

        let amountDone = 0;

        pages
            .filter((page) => !page[1].hidden)
            .forEach((page, index) => {
                if (
                    !newPages.some(
                        ({ groupName }) => groupName === page[1].group
                    )
                ) {
                    newPages.push({
                        groupName: page[1].group,
                        pages: [
                            {
                                name: page[1].name,
                                index: index,
                                hidden: isHiddenFromNavigation(page),
                            },
                        ],
                    });
                } else {
                    const newPageIndex = newPages.findIndex(
                        ({ groupName }) => groupName === page[1].group
                    );
                    newPages[newPageIndex].pages.push({
                        name: page[1].name,
                        index: index,
                        hidden: isHiddenFromNavigation(page),
                    });
                }
            });

        let rowTwoActivated = false;

        newPages.forEach((newPage) => {
            if (
                newPage.pages.length + 1 + amountDone < 19 &&
                !rowTwoActivated
            ) {
                rowOneInner.push(newPage);
                amountDone += newPage.pages.length + 1;
            } else {
                rowTwoActivated = true;
                rowTwoInner.push(newPage);
            }
        });

        setRowOne(rowOneInner);
        setRowTwo(rowTwoInner);
    }, []);

    if (!visible) return null;

    return (
        <MenuContainer globalTheming={globalTheming}>
            <NavigationContainerOuter marginTop={slideSize?.fontSize}>
                {!!rowOne.length && (
                    <NavigationContainer>
                        {rowOne.map((groupPage, index) => {
                            return (
                                <section key={groupPage.groupName}>
                                    <NavigationGroupButton>
                                        {groupPage.groupName}
                                    </NavigationGroupButton>
                                    {groupPage.pages.map((page) => {
                                        console.log(page);
                                        if (page.hidden) return null;
                                        return (
                                            <NavigationButton
                                                key={page.index}
                                                color={
                                                    globalTheming?.highlightColor
                                                }
                                                onClick={(e: MouseEvent) => {
                                                    if (navigationDispatch)
                                                        navigationDispatch({
                                                            type: SET_PAGE_INDEX,
                                                            pageIndex:
                                                                page.index,
                                                        });
                                                    onClose();
                                                }}
                                            >
                                                {page.name}
                                            </NavigationButton>
                                        );
                                    })}
                                </section>
                            );
                        })}
                    </NavigationContainer>
                )}
                <NavigationContainer>
                    {rowTwo.map((groupPage, index) => {
                        return (
                            <section key={groupPage.groupName}>
                                <NavigationGroupButton>
                                    {groupPage.groupName}
                                </NavigationGroupButton>
                                {groupPage.pages.map((page) => {
                                    if (page.hidden) return null;
                                    return (
                                        <NavigationButton
                                            key={page.index}
                                            color={
                                                globalTheming?.highlightColor
                                            }
                                            onClick={(e: MouseEvent) => {
                                                if (navigationDispatch)
                                                    navigationDispatch({
                                                        type: SET_PAGE_INDEX,
                                                        pageIndex: page.index,
                                                    });
                                                onClose();
                                            }}
                                        >
                                            {page.name}
                                        </NavigationButton>
                                    );
                                })}
                            </section>
                        );
                    })}
                </NavigationContainer>
            </NavigationContainerOuter>
        </MenuContainer>
    );
};

interface NavigationButtonProps {
    color?: string;
}

interface NavigationOuterProps {
    marginTop?: string;
}

interface MenuContainerProps {
    globalTheming?: { [key: string]: any };
}
