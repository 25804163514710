import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Collapse } from "react-collapse";

import { Column, Row } from "@cortexglobal/rla-components";

import { Folder } from "./Folder";
import { FolderBreadcrumb } from "./FolderBreadcrumb";
const FolderColumn = styled(Column)`
    margin-bottom: 0.8rem;
`;
const FolderTitle = styled.h4`
    display: inline-block;
    margin: 0 0.5rem 0 0;
`;

const CategoryBrowser = ({
    category,
    filters,
    selectedFilters,
    onApply,
    // setAssetsVisibility,
    maxColumns = 3
}) => {
    const [current, setCurrent] = useState({ value: "" });
    const [folders, setFolders] = useState([]);
    const [options, setOptions] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [visible, setVisible] = useState(true);
    const [label, setLabel] = useState("");

    useEffect(() => {
        const categoryDataArray = filters.filter(
            option => option.name === category
        );

        if (!categoryDataArray) {
            return null;
        }
        const categoryData = categoryDataArray[0];
        if (categoryData && current.value === "") {
            // console.log("Options", categoryData.options);
            setLabel(categoryData.label);
            setOptions(categoryData.options);
            setFolders(categoryData.options);
            const selectedOption = selectedFilters[category];
            // if (filters && selectedOption && selectedOption.length === 1) {
            //     setAssetsVisibility(false);
            // }
        }
    }, [filters]);
    useEffect(() => {
        const selectedOption = selectedFilters[category];
        //If the selected option has changed outside of here we need to programmatically
        //set the current value, which means searching through all the categories for it
        if (selectedOption && selectedOption.length > 1) {
            setVisible(false);

            return null;
        }
        if (filters && selectedOption && selectedOption[0]) {
            setVisible(true);

            // console.log("Handle selected category");
            const categoryDataArray = filters.filter(
                option => option.name === category
            );

            if (!categoryDataArray) {
                return null;
            }

            const categoryData = categoryDataArray[0];
            // console.log("categoryData", categoryData);

            if (categoryData) {
                //Find this in the options and set it as the current
                const selectedCategory = findSelectedOption(
                    categoryData.options,
                    selectedOption[0]
                );
                if (selectedCategory) {
                    setCurrent(selectedCategory);
                    setFolders(selectedCategory.children);
                    rebuildBreadcrumbs(selectedCategory);
                    // if (
                    //     !selectedCategory.children ||
                    //     selectedCategory.children.length === 0
                    // ) {
                    //     setAssetsVisibility(true);
                    // }
                }
            }
        } else {
            //Reset
            setCurrent({ value: "" });
            setFolders(options);
            setBreadcrumbs([]);
        }
    }, [selectedFilters, filters]);

    const findSelectedOption = (options, value, parent = null) => {
        for (let option of options) {
            //Add in the parent to make it easier to rebuild the breadcrumbs
            option.parent = parent;
            if (option.value === value) {
                return option;
            }
            if (option.children && option.children.length > 0) {
                const selectedOption = findSelectedOption(
                    option.children,
                    value,
                    option
                );
                if (selectedOption) {
                    return selectedOption;
                }
            }
        }
    };

    const rebuildBreadcrumbs = selectedCategory => {
        const breadcrumbArray = generateParentArray(
            selectedCategory.parent
        ).reverse();
        setBreadcrumbs([...breadcrumbArray, selectedCategory]);
    };

    const generateParentArray = (parent = null, parentArray = []) => {
        if (parent) {
            return generateParentArray(parent.parent, [...parentArray, parent]);
        }
        return parentArray;
    };

    const categoryUpdated = (selected, relationship) => {
        //Handle local selections
        setCurrent(selected);
        setFolders(selected.children);
        //Depending on the relationship update the breadcrumbs
        if (relationship === "child") {
            setBreadcrumbs([...breadcrumbs, selected]);
        } else {
            const indexOfSelected = breadcrumbs.findIndex(
                breadcrumb => breadcrumb.value === selected.value
            );
            setBreadcrumbs(breadcrumbs.slice(0, indexOfSelected + 1));
        }
        //Handle the search filtering
        const newSelectedFilters = {
            ...selectedFilters,
            [category]: selected.value ? [selected.value] : []
        };
        // if (!selected.children || selected.children.length === 0) {
        //     setAssetsVisibility(true);
        // } else {
        //     setAssetsVisibility(false);
        // }
        onApply(newSelectedFilters);
    };
    return (
        <Collapse isOpened={visible}>
            <Row style={{ margin: "0 1rem" }}>
                {/* <FolderTitle>{label}</FolderTitle> */}
                {current.value !== "" && (
                    <FolderBreadcrumb
                        folder={{
                            value: "",
                            name: "",
                            children: options
                        }}
                        isCurrent={false}
                        onFolderUpdated={categoryUpdated}
                    />
                )}
                {breadcrumbs.map(breadcrumb => {
                    return (
                        <FolderBreadcrumb
                            key={breadcrumb.value}
                            folder={breadcrumb}
                            isCurrent={breadcrumb.value === current.value}
                            onFolderUpdated={categoryUpdated}
                        />
                    );
                })}
            </Row>
            <Row
                style={{ margin: "1rem 1rem 1rem 0" }}
                equaliseChildHeight={true}
                equaliseByRow={false}
            >
                {folders.map(folder => {
                    return (
                        <FolderColumn
                            small={12}
                            medium={6}
                            large={4}
                            xlarge={maxColumns}
                        >
                            <Folder
                                folder={folder}
                                onFolderUpdated={categoryUpdated}
                                loaded={true}
                            />
                        </FolderColumn>
                    );
                })}
            </Row>
        </Collapse>
    );
};

export default CategoryBrowser;
