import React from "react";
import {
    Row,
    Column,
    HelpText,
    renderStandReturnInput,
    FormLabel,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const MetaMediaType = ({ asset, onChange, mediaForm }) => {
    return (
        <React.Fragment>
            {mediaForm &&
                mediaForm.elements.map((element) => {
                    let initialValue =
                        asset &&
                        asset.meta_data &&
                        asset.meta_data[element.name]
                            ? asset.meta_data[element.name]
                            : "";

                    return (
                        <Row key={element.uuid}>
                            <Column medium={3} style={{ textAlign: "right" }}>
                                <FormLabel name={element.name}>
                                    {element.text}
                                </FormLabel>
                                <HelpText>
                                    {element.description
                                        ? `${element.description} - `
                                        : ""}
                                    {trans("Optional")}
                                </HelpText>
                            </Column>
                            <Column medium={6}>
                                {renderStandReturnInput(
                                    {
                                        ...element,
                                        hideLabel: true,
                                    },
                                    initialValue,
                                    onChange,
                                    () => {},
                                    10,
                                    "admin"
                                )}
                            </Column>
                            <Column medium={3}></Column>
                        </Row>
                    );
                })}
        </React.Fragment>
    );
};

export default MetaMediaType;
