import React, { useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Layout } from "../interfaces/layout";
import {
    SlideContainer,
    SlideTitle,
    Subtitle,
} from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";
import { Caption } from "../components/videoComponents";
import { Content } from "./ContentImageContent";

const StyledSlideTitle = styled(SlideTitle)`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    flex: 1;
`;

const SlideBody = styled.div`
    display: flex;
    flex: 4;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1em;
    padding-bottom: 3em;
    position: relative;
`;

const ImageSlideColumn = styled(animated.div)`
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SlideColumnSection = styled(animated.div)`
    position: relative;
    z-index: 1;
`;

const SlideColumn = styled.div`
    width: 25%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
`;

const Image = styled.img<ImageProps>`
    width: 80%;

    ${({ fullscreen }) =>
        fullscreen &&
        `
        position: absolute;
        top: 0;
        left: -0.8em;
        width: 103%;
    `}
`;

const ContentFourCorners = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);
    if (editing || disableSequencing) {
        currentTransitionIndex = 8;
    }
    const transitionsComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );

    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                hidden={!content.title || !content.title.value}
                style={useSpring({
                    opacity: transitionsComplete.includes("title") ? 1 : 0,
                })}
            >
                <RenderContentBlock {...content.title} />
            </StyledSlideTitle>
            <SlideBody>
                <SlideColumn>
                    <SlideColumnSection
                        style={useSpring({
                            opacity: transitionsComplete.includes(
                                "topLeftCorner"
                            )
                                ? 1
                                : 0,
                        })}
                    >
                        {!!content.topLeftCorner &&
                            Array.isArray(content.topLeftCorner.value) && (
                                <>
                                    {content.topLeftCorner.value.map(
                                        (column: Content, index: number) => {
                                            return (
                                                <Caption
                                                    highlightColor={
                                                        highlightColor
                                                    }
                                                    key={index}
                                                    showing={true}
                                                    bulletPoint={
                                                        !!column.bullet
                                                    }
                                                >
                                                    <RenderContentBlock
                                                        type="text"
                                                        value={column.value}
                                                        footnotes={
                                                            column.footnotes ||
                                                            []
                                                        }
                                                    />
                                                </Caption>
                                            );
                                        }
                                    )}
                                </>
                            )}
                    </SlideColumnSection>
                    <SlideColumnSection
                        style={useSpring({
                            opacity: transitionsComplete.includes(
                                "bottomLeftCorner"
                            )
                                ? 1
                                : 0,
                        })}
                    >
                        {!!content.bottomLeftCorner &&
                            Array.isArray(content.bottomLeftCorner.value) && (
                                <>
                                    {content.bottomLeftCorner.value.map(
                                        (column: Content, index: number) => {
                                            return (
                                                <Caption
                                                    highlightColor={
                                                        highlightColor
                                                    }
                                                    key={index}
                                                    showing={true}
                                                    bulletPoint={
                                                        !!column.bullet
                                                    }
                                                >
                                                    <RenderContentBlock
                                                        type="text"
                                                        value={column.value}
                                                        footnotes={
                                                            column.footnotes ||
                                                            []
                                                        }
                                                    />
                                                </Caption>
                                            );
                                        }
                                    )}
                                </>
                            )}
                    </SlideColumnSection>
                </SlideColumn>
                <ImageSlideColumn
                    style={useSpring({
                        opacity: transitionsComplete.includes("image") ? 1 : 0,
                    })}
                >
                    {content.image && content.image.value && (
                        <Image
                            fullscreen={settings.fullscreenImage}
                            src={content.image.value}
                            alt="image"
                        />
                    )}
                </ImageSlideColumn>
                <SlideColumn>
                    <SlideColumnSection
                        style={useSpring({
                            opacity: transitionsComplete.includes(
                                "topRightCorner"
                            )
                                ? 1
                                : 0,
                        })}
                    >
                        {!!content.topRightCorner &&
                            Array.isArray(content.topRightCorner.value) && (
                                <>
                                    {content.topRightCorner.value.map(
                                        (column: Content, index: number) => {
                                            return (
                                                <Caption
                                                    highlightColor={
                                                        highlightColor
                                                    }
                                                    key={index}
                                                    showing={true}
                                                    bulletPoint={
                                                        !!column.bullet
                                                    }
                                                >
                                                    <RenderContentBlock
                                                        type="text"
                                                        value={column.value}
                                                        footnotes={
                                                            column.footnotes ||
                                                            []
                                                        }
                                                    />
                                                </Caption>
                                            );
                                        }
                                    )}
                                </>
                            )}
                    </SlideColumnSection>
                    <SlideColumnSection
                        style={useSpring({
                            opacity: transitionsComplete.includes(
                                "bottomRightCorner"
                            )
                                ? 1
                                : 0,
                        })}
                    >
                        {!!content.bottomRightCorner &&
                            Array.isArray(content.bottomRightCorner.value) && (
                                <>
                                    {content.bottomRightCorner.value.map(
                                        (column: Content, index: number) => {
                                            return (
                                                <Caption
                                                    highlightColor={
                                                        highlightColor
                                                    }
                                                    key={index}
                                                    showing={true}
                                                    bulletPoint={
                                                        !!column.bullet
                                                    }
                                                >
                                                    <RenderContentBlock
                                                        type="text"
                                                        value={column.value}
                                                        footnotes={
                                                            column.footnotes ||
                                                            []
                                                        }
                                                    />
                                                </Caption>
                                            );
                                        }
                                    )}
                                </>
                            )}
                    </SlideColumnSection>
                </SlideColumn>
            </SlideBody>
        </SlideContainer>
    );
};

interface ImageProps {
    fullscreen?: boolean;
}

export default ContentFourCorners;
