import React, { useState } from "react";
import { IconTextButton } from "@cortexglobal/rla-components";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

const CopyToClipboardButton = (props) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const copyToClipboard = (text) => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = text;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        setCopySuccess(true);
    };

    return (
        <>
            {document.queryCommandSupported("copy") && (
                <IconTextButton
                    style={{
                        textDecoration: "none",
                        color: copySuccess ? "green" : "default",
                    }}
                    icon={faClipboard}
                    showCircle={false}
                    expanded
                    onClick={() => copyToClipboard(props.copy)}
                >
                    {copySuccess ? props.successText : props.text}
                </IconTextButton>
            )}
        </>
    );
};

export default CopyToClipboardButton;
