import React from "react";
import PropTypes from "prop-types";
import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";

import StyledTextarea from "../styledElements/styledTextarea";

const TextareaField = ({
    name,
    onChange,
    label,
    readOnly,
    rows,
    columns,
    error,
    labelStyle,
    ...rest
}) => {
    let fieldOptions = {};

    // if (readOnly) {
    //     fieldOptions["readOnly"] = true;
    // }

    const handleChange = (event) => {
        onChange(
            {
                name: name,
                value: event.target.value,
            },
            event
        );
    };

    return (
        <div>
            {label && (
                <FormLabel
                    name={name}
                    label={label}
                    {...rest}
                    style={labelStyle}
                >
                    {label}
                </FormLabel>
            )}
            <StyledTextarea
                name={name}
                rows={rows}
                cols={columns}
                onChange={handleChange}
                readOnly={readOnly}
                error={error}
                {...fieldOptions}
                {...rest}
            />

            <InputError error={error} />
        </div>
    );
};

TextareaField.displayName = "TextareaField";

TextareaField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    rows: PropTypes.number,
    columns: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.string,
    labelStyle: PropTypes.object,
};
TextareaField.defaultProps = {
    size: "default",
    expanded: false,
    inlineLabel: true,
    rows: 6,
    columns: 20,
    type: "text",
    readOnly: false,
    error: "",
};
export default TextareaField;
