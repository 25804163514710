import styled from "styled-components";

export const CardGrid = styled.section<{ minColumnWidth?: number }>`
    padding: 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(
        auto-fill,
        minmax(${({ minColumnWidth = 200 }) => minColumnWidth}px, 1fr)
    );
`;
