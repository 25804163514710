import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import {
    Row,
    Column,
    Button,
    InputField,
    Select,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const AssetCategoriesConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    filterBlock,
    baseUrl,
    total,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Asset Categories" }),
        total: total ? total : 8,
        filterBlock: filterBlock ? filterBlock : "assets-default",
        baseUrl: baseUrl ? baseUrl : "",
    });
    const [filterBlocks, setFilterBlocks] = useState({
        loaded: false,
        data: [],
    });

    useEffect(() => {
        axios.get("/api/v1/filter/blocks").then((response) => {
            setFilterBlocks({
                loaded: true,
                data: response.data,
            });
        });
    }, []);

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
        //If the user changes the filter block, we need to reset the base url
        if (input.name === "filterBlock") {
            setState({
                ...state,
                [input.name]: input.value,
                baseUrl:
                    filterBlocks.data.find(
                        (filter) => filter.value === input.value
                    ).base_url || "/resources",
            });
        }
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder=""
                        onChange={onChange}
                    />
                    <InputField
                        type="number"
                        name="total"
                        value={state.total}
                        label={trans("Number of Assets")}
                        onChange={onChange}
                        min="1"
                        max="100"
                    />
                    {filterBlocks.loaded && (
                        <Select
                            label={trans("Area")}
                            name="filterBlock"
                            options={filterBlocks.data}
                            value={state.filterBlock}
                            onChange={onChange}
                        />
                    )}
                    <Button type="submit" disabled={!filterBlocks.loaded}>
                        {trans("Save Panel")}
                    </Button>
                </form>
            </Column>
        </Row>
    );
};

AssetCategoriesConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default AssetCategoriesConfiguration;
