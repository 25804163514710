import React, { useState } from "react";
import axios from "axios";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Row,
    Column,
    Panel,
    Tabordion,
} from "@cortexglobal/rla-components";

import {
    renderSettingInputGroupRecursive,
    onConfigurationChange,
} from "./components/sharedEditorFunctions";

import { SectionHeading } from "./components/SectionHeading";
import { SectionThemeEditor } from "./SectionThemeEditor";

export const SiteThemeEditor = ({ asset, standardOnChange, fullAdmin }) => {
    if (!asset || !asset.editable || !asset.microsite) {
        return null;
    }
    const { configuration, extracted_tags, autofill } = asset;

    let currentConfiguration = configuration ? configuration : extracted_tags;
    if (!currentConfiguration) {
        return "No configuration has been extracted from this asset";
    }

    const resetTheming = () => {
        //Load the default theming from the server
        axios.get(`/api/v1/assets/microsite/configuration`).then(({ data }) => {
            //Replace the current configuration globalTheming and footerTheming with the defaults from the configuration
            let newConfiguration = {
                ...currentConfiguration,
                globalTheming: data.globalTheming,
                footerTheming: data.footerTheming,
                headerTheming: data.headerTheming,
            };
            //Update the configuration
            standardOnChange({
                name: "configuration",
                value: newConfiguration,
            });
        });
    };

    // console.log({ currentConfiguration });

    const hideHeader = currentConfiguration?.globalSettings?.find(
        (setting) => setting.name === "hideHeader"
    )?.value;
    const hideFooter = currentConfiguration?.globalSettings?.find(
        (setting) => setting.name === "hideFooter"
    )?.value;

    const headerTheming = currentConfiguration.headerTheming;
    const footerTheming = currentConfiguration.footerTheming;
    //TODO - Move the footer bits to their own component, and add a "filterInputs" function to filter out the options that are dependant on other options
    return (
        <React.Fragment>
            <div style={{ display: "flex" }}>
                <SectionHeading style={{ flexGrow: 1 }}>
                    {trans("Theming")}
                </SectionHeading>
                <IconTextButton
                    icon={faSync}
                    showCircle={false}
                    onClick={resetTheming}
                >
                    {trans("Reset all theme settings to default")}
                </IconTextButton>
            </div>
            <Panel>
                <Row>
                    <Column>
                        <Tabordion current={0}>
                            <section
                                heading={trans("Global Theming")}
                                style={{ marginTop: "2rem" }}
                            >
                                {Array.isArray(
                                    currentConfiguration.globalTheming
                                ) &&
                                currentConfiguration.globalTheming.length >
                                    0 ? (
                                    currentConfiguration.globalTheming.map(
                                        (tag, elementIndex) => {
                                            // console.log(tag);
                                            //Add the code to check if the tag is an array, and do the looping,
                                            //moving the below out into it's own component would be good
                                            return renderSettingInputGroupRecursive(
                                                {
                                                    tag,
                                                    onChange: (
                                                        value,
                                                        path,
                                                        event
                                                    ) => {
                                                        onConfigurationChange(
                                                            standardOnChange,
                                                            asset,
                                                            value,
                                                            event,
                                                            path
                                                        );
                                                    },
                                                    chooseImage: (
                                                        path,
                                                        tag,
                                                        event
                                                    ) => {
                                                        //TODO - Set the correct image/tag in state to be used on the
                                                        //update method
                                                        return setChoosingImage(
                                                            !choosingImage
                                                        );
                                                    },
                                                    autofill,
                                                    path: `globalTheming`,
                                                    index: `${elementIndex}`,
                                                    fullAdmin,
                                                    asset,
                                                }
                                            );
                                        }
                                    )
                                ) : (
                                    <span>{trans("No editable fields")}</span>
                                )}
                            </section>
                            {hideHeader !== true &&
                                Array.isArray(headerTheming) &&
                                headerTheming.length > 0 && (
                                    <SectionThemeEditor
                                        heading={trans("Header Theming")}
                                        sectionConfiguration={headerTheming}
                                        sectionPath="headerTheming"
                                        standardOnChange={standardOnChange}
                                        asset={asset}
                                    />
                                )}
                            {hideFooter !== true &&
                                Array.isArray(footerTheming) &&
                                footerTheming.length > 0 && (
                                    <SectionThemeEditor
                                        heading={trans("Footer Theming")}
                                        sectionConfiguration={footerTheming}
                                        sectionPath="footerTheming"
                                        standardOnChange={standardOnChange}
                                        asset={asset}
                                    />
                                )}
                        </Tabordion>
                    </Column>
                </Row>
            </Panel>
        </React.Fragment>
    );
};

export default SiteThemeEditor;
