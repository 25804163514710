import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
    Row,
    Column,
    PageTitle,
    IconTextButton,
    Error,
    Tabordion,
    Badge,
} from "@cortexglobal/rla-components";
import { trans, injectIntl } from "@cortexglobal/rla-intl";
import { updateBreadcrumbs } from "@cortexglobal/cortex-auth-redux";
import { ShareButton } from "@cortexglobal/communication";
import { getAsset, resetAsset, selectAsset } from "../assetActions";

import ActionsPanel from "../components/ActionsPanel";
import GuestActionsPanel from "../components/GuestActionsPanel";
import AssetDetailsPanel from "../components/AssetDetailsPanel";
import AssetDetailsPreviewPanel from "../components/AssetDetailsPreviewPanel";
import { ConfirmationServiceProvider } from "../ConfirmationService";
import AssetAdminOptions from "../components/AssetAdminOptions";
import Availability from "../components/detail/Availability";
import Certification from "../components/detail/Certification";
import RelatedAssets from "../components/RelatedAssets";
import AssetUsageListing from "../components/AssetUsageListing";
import SharingLinksHistoryPanel from "../components/SharingLinksHistoryPanel";
import VersionsTable from "../components/VersionsTable";

class ViewAsset extends Component {
    state = {
        error: "",
        tab: 0,
        sharingLinksHistoryLoaded: true,
        assetDetailLoaded: false,
        sharingLinksHistory: [],
    };

    componentDidMount(props) {
        if (this.props.breadcrumbKey) {
            this.props.updateBreadcrumbs(
                this.props.breadcrumbKey,
                this.props.asset.name
            );
        }

        // if (!this.props.asset.loaded) {
        this.props
            .getAsset(this.props.assetUuid)
            .then((data) => {
                this.setState({ assetDetailLoaded: true });
            })
            .catch((e) => {
                this.setState({
                    error:
                        e.response && e.response.data
                            ? e.response.data.message
                            : trans("An error has occurred"),
                });
            });
        // }
    }

    componentWillUnmount() {
        this.props.resetAsset();
    }

    componentDidUpdate(props) {
        if (props.assetUuid != this.props.assetUuid) {
            this.setState({ assetDetailLoaded: false });
            this.props.getAsset(this.props.assetUuid).then((data) => {
                this.setState({ assetDetailLoaded: true });
            });
        }
    }

    hasNotExpired(asset) {
        return !asset.isExpired;
    }

    buildNote(asset) {
        if (!this.state.assetDetailLoaded) {
            return null;
        }

        let items = [];

        asset.status == "active" ? null : items.push(asset.status_display);

        this.hasNotExpired(asset) ? null : items.push("expired");

        if (asset.isVersioned) {
            items.push(`v${asset.versionNumber}`);
        }

        return (
            <span style={{ fontStyle: "italic", fontSize: "0.9rem" }}>
                {items.length < 1 ? "" : ` (${items.join(", ")})`}
            </span>
        );
    }

    credentialsRequired = (asset, declarations) => {
        return (
            declarations[asset.status] &&
            declarations[asset.status].credentialsRequired
        );
    };

    createUserAsset = () => {
        const { asset, userAssetPath, history } = this.props;

        history.push(`${userAssetPath}/create/${asset.uuid}`);
    };

    render() {
        if (this.state.error) {
            return <Error>{this.state.error}</Error>;
        }
        const {
            asset,
            assetsPath,
            declarations,
            messages,
            mainTitle,
            configLoaded,
            hasMutipleFavouritesEnabled,
            user,
            asset_types,
            intl,
            campaignsPath,
        } = this.props;
        const { assetDetailLoaded } = this.state;

        let assetType =
            typeof asset.asset_type === "string"
                ? asset.asset_type
                : asset?.asset_type?.slug;

        assetType = asset_types[assetType];

        const title = asset.name ? (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {assetType && (
                    <Badge
                        type="secondary"
                        style={{ margin: "0.2rem 0.5rem auto 0" }}
                    >
                        {assetType.name}
                    </Badge>
                )}{" "}
                {asset.name}{" "}
                <aside style={{ marginLeft: "0.5rem" }}>
                    {this.buildNote(asset)}
                </aside>
            </div>
        ) : (
            "..."
        );

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {process.env.REACT_APP_NAME}{" "}
                        {asset.name ? `- ${asset.name}` : ""}
                    </title>
                </Helmet>
                <ConfirmationServiceProvider>
                    <PageTitle title={title}>
                        {asset.userCanManage && <AssetAdminOptions />}
                        {assetDetailLoaded && (
                            <ShareButton
                                title={trans("Share Asset")}
                                subject={intl.formatMessage({
                                    id: "Asset Share",
                                })}
                                prependedMessage={intl.formatMessage({
                                    id: "Please view this asset:",
                                })}
                                linkText={asset.name}
                            />
                        )}
                        <IconTextButton
                            as={Link}
                            to={assetsPath}
                            rotation={180}
                            showCircle={false}
                            expanded={false}
                        >
                            {trans("Back to ")}
                            {mainTitle}
                        </IconTextButton>
                    </PageTitle>
                    <Row style={{ marginTop: "1rem" }}>
                        <Column medium={8}>
                            {assetDetailLoaded && (
                                <>
                                    {asset.userCanManage ? (
                                        <Tabordion
                                            current={this.state.tab}
                                            unmounts={false}
                                        >
                                            <div heading={trans("Preview")}>
                                                <AssetDetailsPreviewPanel
                                                    hideShadow={true}
                                                    asset={asset}
                                                    loaded={true}
                                                    assetType={assetType}
                                                />
                                            </div>
                                            <div heading={trans("Activity")}>
                                                <AssetUsageListing
                                                    asset={asset}
                                                />
                                            </div>
                                            {asset.shareable && (
                                                <div
                                                    heading={trans(
                                                        "Sharing History"
                                                    )}
                                                >
                                                    <SharingLinksHistoryPanel
                                                        asset={asset}
                                                    />
                                                </div>
                                            )}
                                            {asset.isVersioned &&
                                                asset.userCanManage && (
                                                    <div
                                                        heading={trans(
                                                            "Versions"
                                                        )}
                                                    >
                                                        <VersionsTable
                                                            asset={asset}
                                                            onVersionClick={() => {}}
                                                        />
                                                    </div>
                                                )}
                                        </Tabordion>
                                    ) : (
                                        <AssetDetailsPreviewPanel
                                            asset={asset}
                                            loaded={true}
                                        />
                                    )}
                                </>
                            )}

                            <RelatedAssets
                                asset={asset}
                                selectAsset={this.props.selectAsset}
                                assetDetailLoaded={assetDetailLoaded}
                            />
                        </Column>
                        <Column medium={4}>
                            {user.isGuest ? (
                                <GuestActionsPanel />
                            ) : (
                                <ActionsPanel
                                    user={user}
                                    assetsPath={assetsPath}
                                    messages={messages}
                                    configLoaded={configLoaded}
                                    hasMutipleFavouritesEnabled={
                                        hasMutipleFavouritesEnabled
                                    }
                                    isActive={this.hasNotExpired(asset)}
                                    createUserAsset={this.createUserAsset.bind(
                                        this
                                    )}
                                    loaded={assetDetailLoaded}
                                    campaignsPath={campaignsPath}
                                />
                            )}

                            {this.credentialsRequired(asset, declarations) &&
                                asset.certification_ref && (
                                    <Certification asset={asset} />
                                )}
                            <Availability
                                asset={asset}
                                loaded={assetDetailLoaded}
                            />
                            <AssetDetailsPanel
                                asset={asset}
                                loaded={assetDetailLoaded}
                            />
                        </Column>
                    </Row>
                </ConfirmationServiceProvider>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    // let asset = state.assets.current;
    let {
        filters,
        current: asset,
        config: {
            loaded: configLoaded,
            data: {
                options: {
                    assetStatusDeclarations: declarations,
                    multiple_favourites_enabled: hasMutipleFavouritesEnabled,
                },
                messages,
                asset_types,
            },
        },
    } = state.assets;
    let { permissions } = state.auth.role;
    let { user } = state.auth;
    return {
        user,
        asset,
        filters,
        permissions,
        declarations,
        messages,
        asset_types,
        hasMutipleFavouritesEnabled,
        configLoaded,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getAsset, resetAsset, updateBreadcrumbs, selectAsset },
        dispatch
    );
}

export default injectIntl(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAsset))
);
