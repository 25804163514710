import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";

import SurveyManagementContext from "../SurveyManagementContext";
import SurveyForm from "../forms/SurveyForm";
import {
    IconTextButton,
    Loading,
    PageTitle,
} from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { isEmpty } from "lodash";

const EditSurvey = ({ path, loadSurvey }) => {
    const { can } = usePermissions();

    const { uuid } = useParams();

    const { surveySystem } = useContext(SurveyManagementContext);
    const { loading, loaded, error } = surveySystem;

    const [surveyData, setSurveyData] = useState({});

    useEffect(() => {
        loadSurvey(uuid).then((survey) => {
            setSurveyData(survey);
        });
    }, [uuid]);

    return (
        <>
            <PageTitle
                title={
                    surveyData?.name
                        ? trans("Edit: {name}", surveyData)
                        : trans("Edit")
                }
            >
                {can("surveys-manage-list") && (
                    <IconTextButton
                        as={Link}
                        to={path}
                        rotation={180}
                        showCircle={false}
                    >
                        {trans("Back to Listing")}
                    </IconTextButton>
                )}
            </PageTitle>

            {loading || !surveyData.uuid ? (
                <Loading />
            ) : (
                <SurveyForm
                    error={error}
                    path={path}
                    surveyData={surveyData}
                    setSurveyData={setSurveyData}
                />
            )}
        </>
    );
};

export default EditSurvey;
