import React, { Component } from "react";
import { Link } from "react-router-dom";

import { IconTextButton } from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";

import { TransitionButtons } from "@cortexglobal/workflow";
import { trans } from "@cortexglobal/rla-intl";

class RelatedRequestRow extends Component {
    render = () => {
        const { request } = this.props;

        const created = new Date(request.created);
        const workflow = request.workflow;
        const { status, transitions, smartRef } = workflow;

        const linkToRequest = "/requests/view/" + request.uuid;

        return (
            <tr>
                <td>
                    <Link to={linkToRequest}>{request.ref}</Link>
                </td>
                <td>{request.title}</td>
                <td>{status}</td>
                <td>
                    {(request.withUser ? request.withUser + ", " : "") +
                        request.withGroup}
                </td>
                <td>
                    {format(created, "Pp")} {trans("by")} {request.createdBy}
                </td>
                <td>
                    <IconTextButton
                        expanded
                        type="secondary"
                        height={30}
                        as={Link}
                        to={linkToRequest}
                    >
                        View
                    </IconTextButton>
                </td>
            </tr>
        );
    };
}

export default RelatedRequestRow;
