import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Collapse } from "react-collapse";

import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import StyledCheckbox from "../styledElements/styledCheckbox";
import { trans } from "@cortexglobal/rla-intl";
import PlainButton from "../../buttons/PlainButton";
const Container = styled(Collapse)`
    margin-bottom: ${props => props.theme.spacing.margin - 0.2}em;
`;

const ExpandButton = styled(PlainButton)`
    font-size: 0.8rem;
    font-weight: bold;
`;

class MultiCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.value ? props.value : [],
            expanded: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                values: this.props.value || []
            });
        }
    }

    handleChange(event, value) {
        const { onChange } = this.props;
        let newValues = this.state.values.slice();
        if (event.target.checked) {
            newValues.push(value);
        } else {
            newValues.splice(newValues.indexOf(value), 1);
        }
        this.setState({
            values: newValues
        });
        return onChange({ name: this.props.name, value: newValues }, event);
    }

    render() {
        const {
            options,
            onBlur,
            error,
            inlineRadioButtons,
            name,
            disabled,
            limit = 100,
            ...rest
        } = this.props;
        const { values, expanded } = this.state;

        const limitedOptions = expanded ? options : options.slice(0, limit);

        const checkboxes = limitedOptions.map((option, index) => {
            const isChecked = values.indexOf(option.value) > -1;

            return (
                <StyledCheckbox
                    key={option.value}
                    name={name}
                    onChange={event => this.handleChange(event, option.value)}
                    checked={isChecked}
                    text={option.text}
                    disabled={disabled}
                    inlineRadioButtons={inlineRadioButtons}
                    marginBottom={this.props.marginBottom}
                />
            );
        });

        return (
            <Container isOpened={true}>
                {this.props.label && (
                    <FormLabel {...rest}>{this.props.label}</FormLabel>
                )}
                {checkboxes}
                {options.length > limit && !expanded && (
                    <ExpandButton
                        onClick={() => this.setState({ expanded: true })}
                    >
                        + {trans("Show more")}
                    </ExpandButton>
                )}
                {options.length > limit && expanded && (
                    <ExpandButton
                        onClick={() => this.setState({ expanded: false })}
                    >
                        - {trans("Show less")}
                    </ExpandButton>
                )}
                <InputError error={error} />
            </Container>
        );
    }
}
MultiCheckbox.displayName = "MultiCheckbox";

MultiCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired,
    error: PropTypes.string,
    inlineRadioButtons: PropTypes.bool,
    disabled: PropTypes.bool
};

export default MultiCheckbox;
