import React from "react";

import styled from "styled-components";

import SubmitButton from "./buttons/SubmitButton";
const Wrapper = styled.div<{ margin: string }>`
    position: relative;
    min-height: 100%;
    margin: ${({ margin }) => margin};
`;
const OverlayContainer = styled.div<{ showOverlay: boolean }>`
    pointer-events: ${({ showOverlay }) => (showOverlay ? "all" : "none")};;
    position: absolute;
    /* display: ${({ showOverlay }) => (showOverlay ? "block" : "none")}; */
    /* visibility: ${({ showOverlay }) =>
        showOverlay ? "visible" : "hidden"}; */
    opacity: ${({ showOverlay }) => (showOverlay ? "1" : "0")};
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding-top: ${({ showOverlay }) => (showOverlay ? "2rem" : "0rem")};;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    transition: opacity 1000ms ease, padding-top 300ms ease-out;
`;

const SaveButton = styled(SubmitButton)`
    position: sticky;
    top: 8rem;
    margin-bottom: 1rem;
    /* pointer-events: all; */
`;
const Overlay = ({
    children,
    onClick,
    buttonLabel = "Click to update",
    showOverlay = false,
    submitting = false,
    margin = "0"
}) => {
    return (
        <Wrapper margin={margin}>
            {children}
            <OverlayContainer showOverlay={showOverlay}>
                <SaveButton submitting={submitting} onClick={onClick}>
                    {buttonLabel}{" "}
                </SaveButton>
            </OverlayContainer>
        </Wrapper>
    );
};

export default Overlay;
