import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Crumb from "./Crumb";
import { Row, Column } from "@cortexglobal/rla-components";

const BreadcrumbWrapper = styled.div`
    background: ${props => props.theme.breadcrumb.background};
`;

const Container = styled.ol`
    list-style: none;
    display: flex;
    align-items: left;
    margin: 0;
    padding: 0;
`;


const Breadcrumbs = props => {
    const { trail, ...rest } = props;

    return (
        <BreadcrumbWrapper>
            <Row>
                <Column>
                    <Container {...rest}>
                        {trail &&
                         trail.map((item, index) => {
                                return (
                                    <Crumb
                                        key={index}
                                        title={item.name}
                                        path={item.path}
                                        current={item.current}
                                    />
                                );
                            })}
                    </Container>
                </Column>
            </Row>
        </BreadcrumbWrapper>
    );
};

export default Breadcrumbs;
