import React from "react";
import { FormikErrors } from "formik";
//@ts-ignore cortex
import { Column, ValidationError } from "@cortexglobal/rla-components";

type LabelProps = {
    error?: string | FormikErrors<Date>;
    columns?: number;
};
export const ErrorColumn = ({ error, columns = 3 }: LabelProps) => {
    return (
        <Column medium={columns}>
            <ValidationError>{error}</ValidationError>
        </Column>
    );
};
