import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    FormikInput,
    FormikSelect,
    FormRow,
    Panel,
    Row,
    Column,
    Loading,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { get } from "lodash";

const generateSchema = (groups, roles, languages) => {
    return Yup.object().shape({
        first_name: Yup.string()
            .min(2, "Too Short!")
            .max(255, "Too Long!")
            .required("Required"),
        last_name: Yup.string()
            .min(2, "Too Short!")
            .max(255, "Too Long!")
            .required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        primary_role_uuid: roles
            ? Yup.string().required("Required")
            : Yup.string().nullable(),
        primary_group_uuid: groups
            ? Yup.string().required("Required")
            : Yup.string().nullable(),
        language:
            languages && languages.length > 0
                ? Yup.string().required("Required")
                : Yup.string().nullable(),
        password_required: Yup.bool(),
        password: Yup.string().when("password_required", {
            is: true,
            then: Yup.string().required("Required"),
        }),
        password_confirmation: Yup.string().when("password_required", {
            is: true,
            then: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords must match")
                .required("Required"),
        }),
    });
};
const RegisterForm = ({ user = null, autoActivation = false, ...props }) => {
    const [loadingState, setLoadingState] = useState("loaded");
    const [settingsLoadingState, setSettingsLoadingState] = useState("loading");
    const [settings, setSettings] = useState({});
    const [success, setSuccess] = useState(false);
    const intl = useIntl();
    const [languages, setLanguages] = useState({ loaded: false, data: [] });

    useEffect(() => {
        getSettings();
        axios.get(`/api/v1/language`).then(({ data }) =>
            setLanguages({
                loaded: true,
                data: data.data.map((language) => {
                    return { text: language.name, value: language.iso_code };
                }),
            })
        );
    }, []);

    const getSettings = () => {
        setSettingsLoadingState("loading");

        axios
            .get(`/api/v1/registration/settings`)
            .then((response) => {
                const settings = response.data.data;
                setSettings(settings);
                setSettingsLoadingState("loaded");
            })
            .catch((e) => {
                setSettingsLoadingState("error");
            });
    };

    const onSubmit = (values, { setErrors }) => {
        setLoadingState("loading");

        const sendValues = {
            ...values,
            autoActivation: get(props, "autoActivation", "five"),
        };

        axios
            .post(`/api/v1/registration`, sendValues)
            .then((response) => {
                setLoadingState("loaded");
                setSuccess(true);
                return;
            })
            .catch((e) => {
                setLoadingState("error");
                if (e.response && e.response.status === 422) {
                    setErrors(get(e, "response.data.errors", {}));
                    return;
                }

                throw e;
            });
    };

    if (!languages.loaded) {
        return <Loading />;
    }

    const groups = get(settings, "groups", [
        {
            value: "",
            text: "...",
        },
    ]);
    const roles = get(settings, "roles", [
        {
            value: "",
            text: "...",
        },
    ]);

    return (
        <>
            {success ? (
                <Column>
                    <Panel
                        type="success"
                        style={{
                            padding: "0",
                        }}
                    >
                        <p
                            style={{
                                textAlign: "center",
                                padding: "1rem",
                                margin: "0",
                            }}
                        >
                            {autoActivation
                                ? trans(
                                      "Thank you for registering. You can now login."
                                  )
                                : trans(
                                      "Thank you for registering. Your manager will now need to approve your new account before you are able to log in."
                                  )}
                        </p>
                    </Panel>

                    <Link to="/auth/login">Go to Log in</Link>
                </Column>
            ) : (
                <Row>
                    <Formik
                        initialValues={{
                            first_name: get(user, "first_name", ""),
                            last_name: get(user, "last_name", ""),
                            email: get(user, "email", ""),
                            primary_role_uuid: get(
                                user,
                                "primary_role.uuid",
                                ""
                            ),
                            primary_group_uuid: get(
                                user,
                                "primary_group.uuid",
                                ""
                            ),
                            language: get(user, "language", ""),
                            password: "",
                            password_confirmation: "",
                        }}
                        onSubmit={onSubmit}
                        validationSchema={generateSchema(
                            groups,
                            roles,
                            languages
                        )}
                    >
                        {({ values, errors, submitting }) => {
                            return (
                                <Form className="form-group">
                                    <FormRow
                                        name="first_name"
                                        label={trans("First Name")}
                                        helpText={trans("Required")}
                                        error={errors.first_name}
                                    >
                                        <FormikInput
                                            type="text"
                                            name="first_name"
                                            placeholder="Joe"
                                            value={values.first_name}
                                            showError={false}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="last_name"
                                        label={trans("Last Name")}
                                        helpText={trans("Required")}
                                        error={errors.last_name}
                                    >
                                        <FormikInput
                                            type="text"
                                            name="last_name"
                                            placeholder="Bloggs"
                                            value={values.last_name}
                                            showError={false}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="email"
                                        label={trans("Email")}
                                        helpText={trans("Required")}
                                        error={errors.email}
                                    >
                                        <FormikInput
                                            type="text"
                                            name="email"
                                            placeholder="user@domain.com"
                                            value={values.email}
                                            showError={false}
                                        />
                                    </FormRow>

                                    {languages.data.length > 0 && (
                                        <FormRow
                                            name="language"
                                            label={trans("Language")}
                                            helpText={trans("Required")}
                                            error={errors.language}
                                        >
                                            <FormikSelect
                                                name="language"
                                                value={values.language}
                                                options={languages.data}
                                                showError={false}
                                            />
                                        </FormRow>
                                    )}
                                    {roles && (
                                        <FormRow
                                            name="primary_role_uuid"
                                            label={trans("Role")}
                                            helpText={trans("Required")}
                                            error={errors.primary_role_uuid}
                                        >
                                            <FormikSelect
                                                name="primary_role_uuid"
                                                value={values.primary_role_uuid}
                                                options={roles}
                                                showError={false}
                                            />
                                        </FormRow>
                                    )}

                                    {groups && (
                                        <FormRow
                                            name="primary_group_uuid"
                                            label={trans("Group")}
                                            helpText={trans("Required")}
                                            error={errors.primary_group_uuid}
                                        >
                                            <FormikSelect
                                                name="primary_group_uuid"
                                                value={
                                                    values.primary_group_uuid
                                                }
                                                options={groups}
                                                showError={false}
                                            />
                                        </FormRow>
                                    )}

                                    <FormRow
                                        name="password"
                                        label={trans("Password")}
                                        helpText={trans("Required")}
                                        error={errors.password}
                                    >
                                        <p>
                                            {trans(
                                                "Passwords must be at least 8 characters long and contain at least 1 letter, 1 number and 1 special character."
                                            )}
                                        </p>
                                        <FormikInput
                                            type="password"
                                            name="password"
                                            label={intl.formatMessage({
                                                id: "Password",
                                            })}
                                            value={values.password}
                                        />
                                        <FormikInput
                                            type="password"
                                            name="password_confirmation"
                                            label={intl.formatMessage({
                                                id: "Confirm Password",
                                            })}
                                            value={values.password_confirmation}
                                        />
                                    </FormRow>

                                    <Column small={6} centered>
                                        <SubmitButton
                                            expanded
                                            label={intl.formatMessage({
                                                id: "Register",
                                            })}
                                            submitting={submitting}
                                        />
                                    </Column>
                                </Form>
                            );
                        }}
                    </Formik>
                </Row>
            )}
        </>
    );
};

export default RegisterForm;
