import {
    HIDE_ASSETS_MODAL,
    SHOW_ASSETS_MODAL,
    GET_ASSET,
    DELETE_ASSET,
    ARCHIVE_ASSET,
    RESET_ASSET,
    SET_ASSET_FILTERS,
    CLEAR_ASSET_FILTERS,
    GET_MEDIA_OUTPUT_TYPES,
    GET_ASSET_AUTOFILL_FIELDS,
    LOAD_SEARCH_RESULTS,
    LOADING_SEARCH_RESULTS,
    SET_REFRESH_SEARCH,
    CLEAR_ASSET_SEARCH,
    ASSET_UPDATE_PAGINATION,
    // SET_ASSET_CUSTOM_FORM,
    SET_ASSET_SEARCHABLE_META,
    SET_ASSET_CONFIG_OPTIONS,
    SET_CATEGORY_TOTALS,
    UPDATE_ASSET_FAVORITES,
} from "./assetActions";

const initialState = {
    config: {
        loaded: false,
        data: {
            options: {},
            asset_types: [],
            base_group_alias: "",
            messages: {},
        },
    },
    current: { loaded: false },
    components: { loaded: false, data: [] },
    mediaOutputTypes: { loaded: false, data: [] },
    autofill: { loaded: false, data: [] },
    filters: {},
    categoryTotals: {},
    searchableMeta: {},
    searching: false,
    collection: {
        refresh: false,
        loaded: false,
        selectedFilters: {},
        search: "",
        data: [],
    },
    pagination: {},
    modalVisible: false,
    modalName: "",
    customForm: false,
    savedFilter: null,
};

export default function imports(state = initialState, action) {
    switch (action.type) {
        case HIDE_ASSETS_MODAL:
            return { ...state, modalName: "", modalVisible: false };

        case "asset_saved_filters":
            return {
                ...state,
                savedFilter: action.data,
            };

        case SHOW_ASSETS_MODAL:
            return {
                ...state,
                modalName: action.modalName,
                modalVisible: true,
            };

        case ARCHIVE_ASSET:
        case DELETE_ASSET:
            return {
                ...state,
                collection: {
                    ...state.collection,
                    data: state.collection.data.filter((asset) => {
                        return asset.uuid !== action.data.uuid;
                    }),
                },
            };

        // case SET_ASSET_CUSTOM_FORM:
        //     return {
        //         ...state,
        //         customForm: action.data,
        //     };

        case GET_ASSET:
            return {
                ...state,
                current: action.data,
            };

        case SET_CATEGORY_TOTALS:
            return {
                ...state,
                categoryTotals: action.data,
            };

        case RESET_ASSET:
            return {
                ...state,
                current: initialState.current,
            };

        case SET_ASSET_FILTERS:
            return {
                ...state,
                filters: { data: action.data, loaded: true },
            };
        case CLEAR_ASSET_FILTERS:
            return {
                ...state,
                filters: { data: {}, loaded: false },
                collection: {
                    ...initialState.collection,
                },
            };

        case SET_ASSET_SEARCHABLE_META:
            return {
                ...state,
                searchableMeta: { data: action.data, loaded: true },
            };

        case SET_ASSET_CONFIG_OPTIONS:
            return {
                ...state,
                config: { data: action.data, loaded: true },
            };

        case GET_ASSET_AUTOFILL_FIELDS:
            return {
                ...state,
                autofill: { data: action.data, loaded: action.loaded },
            };

        case GET_MEDIA_OUTPUT_TYPES:
            return {
                ...state,
                mediaOutputTypes: { data: action.data, loaded: action.loaded },
            };

        case LOADING_SEARCH_RESULTS:
            return {
                ...state,
                collection: {
                    ...state.collection,
                    loaded: false,
                },
            };

        case LOAD_SEARCH_RESULTS:
            return {
                ...state,
                collection: {
                    ...state.collection,
                    refresh: false,
                    loaded: true,
                    search: action.search,
                    selectedFilters: action.selectedFilters,
                    data: action.data.records
                        ? action.data.records
                        : action.data,
                },
            };

        case UPDATE_ASSET_FAVORITES:
            return {
                ...state,
                collection: {
                    ...state.collection,
                    data: state.collection.data.map((asset) => {
                        return {
                            ...asset,
                            isFavorite: action.data.uuids.includes(asset.uuid)
                                ? action.data.favorite
                                : asset.isFavorite,
                        };
                    }),
                },
                current: {
                    ...state.current,
                    isFavorite: action.data.uuids.includes(state.current.uuid)
                        ? action.data.favorite
                        : state.current.isFavorite,
                },
            };

        case SET_REFRESH_SEARCH:
            return {
                ...state,
                collection: {
                    ...state.collection,
                    refresh: action.refresh,
                },
            };

        case CLEAR_ASSET_SEARCH:
            return {
                ...state,
                collection: {
                    ...state.collection,
                    search: "",
                },
            };

        case ASSET_UPDATE_PAGINATION:
            return {
                ...state,
                pagination: action.pagination,
            };

        default:
            return state;
    }
}
