import React, { Suspense } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import {
    Loading,
    Row,
    Column,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

//@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import useAdminRoutes from "./hooks/useAdminRoutes";
import AdminNav from "./components/AdminNav";
import { AdminDashboard } from "./screens/AdminDashboard";
import styled, { useTheme } from "styled-components";
const AdminNavWrapper = styled(Column).attrs({
    small: 12,
    medium: 12,
    large: 3,
    xlarge: 2,
})`
    padding-top: 1rem;
    position: sticky;
    top: 0;
    @media (max-width: ${({ theme }) => theme.breakpoints.large}px) {
        position: relative;
        top: 0;
    }
`;
export const AdminRouting = () => {
    const { can } = usePermissions();
    const { availableAdminRoutes } = useAdminRoutes();
    const theme = useTheme();

    const { path: basePath } = useRouteMatch();

    if (!can("admin")) {
        return null;
    }

    return (
        <Row style={{ position: "relative" }} expanded>
            <AdminNavWrapper>
                <AdminNav routes={availableAdminRoutes} />
            </AdminNavWrapper>
            <Column
                style={{ marginTop: "1rem" }}
                medium={9}
                large={9}
                xlarge={10}
            >
                <Switch>
                    <Suspense fallback={<Loading />}>
                        <Route
                            exact
                            path={`${basePath}admin`}
                            render={() =>
                                AdminDashboard(availableAdminRoutes, theme)
                            }
                        />
                        {Object.entries(availableAdminRoutes).map(
                            ([section, routes]) => {
                                // console.log(section, routes);

                                return routes.map(({ path, component }) => {
                                    // console.log(section, path, component);
                                    return (
                                        <Route
                                            key={path}
                                            path={`${basePath}admin${path}`}
                                            component={component}
                                        />
                                    );
                                });
                            }
                        )}
                    </Suspense>
                </Switch>
            </Column>
        </Row>
    );
};
