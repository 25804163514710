import React, { useCallback, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
///@ts-ignore cortex
import { trans, useIntl } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    SubmitButton,
    FormikInput,
    FormikTextarea,
    FormikSelect,
    DraftJSField,
    SimpleDropzone,
    FormRow,
    useAddToast,
    Button,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { PreviewImage } from "../components/index";

const FILE_SIZE = 1160 * 1024 * 12;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = Yup.object().shape({
    uuid: Yup.string(),
    title: Yup.string().required("Required"),
    excerpt: Yup.string(),
    body: Yup.string(),
    thumbnail: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                return Yup.mixed()
                    .required("A thumbnail is required")
                    .test(
                        "fileSize",
                        "File too large",
                        (value) => value && value.size <= FILE_SIZE
                    )
                    .test(
                        "fileFormat",
                        "Unsupported Format",
                        (value) =>
                            value && SUPPORTED_FORMATS.includes(value.type)
                    ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
    status: Yup.string().required("Required"),
});

export type NewsStory = ReturnType<typeof schema.validateSync>;

const NewsForm: React.FC<{
    story: NewsStory;
    onSubmit: (
        values: FormData,
        setSubmitting: (isSubmitting: boolean) => void
    ) => Promise<any>;
}> = ({
    story,
    // errors,
    onSubmit,
}) => {
    const [newPreviewFile, setNewPreviewFile] = useState(null);
    const addToast = useAddToast();
    const intl = useIntl();

    // const handleSubmit = (
    //     values: NewsStory,
    //     { setErrors, setSubmitting, ...rest }
    // ) => {
    //     console.log("here", values);
    // };

    const handleSubmit = (
        values: NewsStory,
        { setErrors, setSubmitting, ...rest }
    ) => {
        let fd = new FormData();
        Object.keys(values).forEach((key) => fd.append(key, values[key]));

        onSubmit(fd, setSubmitting)
            .then(({ data }) => {
                setErrors({});
                setSubmitting(false);
                addToast({
                    type: "success",
                    content: trans("News story saved"),
                    showFor: 5000,
                });
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    setSubmitting(false);
                });
            });
    };

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );

    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("thumbnail", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);

    return (
        <Formik
            initialValues={{ ...story }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, errors, setFieldValue }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="title"
                            label={trans("Headline")}
                            helpText={trans("Optional")}
                            // error={errors.title}
                        >
                            <FormikInput name="title" value={values.title} />
                        </FormRow>

                        <FormRow
                            name="excerpt"
                            label={trans("Excerpt")}
                            helpText={trans("Optional")}
                            // error={errors.excerpt}
                        >
                            <FormikInput
                                name="excerpt"
                                value={values.excerpt}
                            />
                        </FormRow>

                        <FormRow
                            name="body"
                            label={trans("Body")}
                            helpText={trans("Optional")}
                            // error={errors.body}
                        >
                            <DraftJSField
                                name="body"
                                value={values.body}
                                onChange={(input) => {
                                    const { name, value } = input;
                                    setFieldValue(name, value);
                                }}
                                showError={false}
                            />
                        </FormRow>
                        <FormRow
                            name="thumbnail"
                            label={trans("Image")}
                            helpText={trans("Optional")}
                            error={errors.thumbnail}
                        >
                            <SimpleDropzone
                                onDrop={(acceptedFiles) =>
                                    onDrop(acceptedFiles, setFieldValue)
                                }
                                accept={SUPPORTED_FORMATS.join()}
                                multiple={false}
                            >
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault();
                                    }}
                                    style={{ marginBottom: "1rem" }}
                                >
                                    {values.thumbnail && values.thumbnail !== ""
                                        ? trans("Update Image")
                                        : trans("Add Image")}
                                </Button>
                            </SimpleDropzone>

                            {typeof values.thumbnail === "string" &&
                                values.thumbnail !== "" && (
                                    <PreviewImage src={values.thumbnail} />
                                )}

                            {newPreviewFile && (
                                <PreviewImage src={newPreviewFile} />
                            )}
                        </FormRow>

                        <FormRow
                            name="status"
                            label={trans("Status")}
                            helpText={trans("Required")}
                            // error={errors.status}
                        >
                            <FormikSelect
                                name="status"
                                value={values.status}
                                showEmptyOption={false}
                                options={[
                                    {
                                        value: "active",
                                        text: intl.formatMessage({
                                            id: "Active",
                                        }),
                                    },
                                    {
                                        value: "draft",
                                        text: intl.formatMessage({
                                            id: "Draft",
                                        }),
                                    },
                                ]}
                            />
                        </FormRow>

                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default NewsForm;
