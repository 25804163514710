import React from "react";
import styled from "styled-components";

const ErrorContainer = styled.div<ErrorProps>`
    position: relative;
    padding: ${(props) => props.padding || "20px"};
    margin: ${(props) => props.margin || "20px 0"};
    text-align: ${(props) => props.textAlign || "center"};
    background: ${(props) => props.background || props.theme.colors.gray900};
    color: ${(props) => props.color || props.theme.colors.white};
    font-size: ${(props) => props.fontSize || "0.9em"};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorIcon = styled.div`
    width: 19px;
    height: 19px;
    background: red;
    color: white;
    border-radius: 50%;
    margin-right: 8px;
    /* font-weight: normal; */
    display: flex;
    align-items: baseline;
    justify-content: center;
    line-height: 17px;
    font-size: 14px;
`;

const Error = ({ children }: ErrorProps) => {
    return (
        <>
            {/** @ts-ignore cortex */}
            <ErrorContainer>
                <ErrorIcon>!</ErrorIcon>
                {children}
            </ErrorContainer>
        </>
    );
};

export interface ErrorProps {
    padding?: string;
    margin?: string;
    textAlign?: string;
    background?: string;
    fontSize?: string;
    children: any;
}

export default Error;
