import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { saveAs } from "file-saver";

import { trans } from "@cortexglobal/rla-intl";
import {
    Button,
    IconTextButton,
    Loading,
    Panel,
    SimpleDropzone,
    SubmitButton,
    useAddToast,
    useToasts,
    Table,
    LegendItem,
} from "../../index";

import { CellInput } from "./CellInput";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { on } from "events";

const UploadWrapper = styled.div`
    position: relative;
    width: 100%;
    min-width: 800px;
    height: calc(100vh - 4.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const UploadPanel = styled(Panel)`
    max-width: 800px;
    padding: 2rem;
`;
const ImportWrapper = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 4.5rem);
    background-color: #fff;
`;

const TableWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 4.5rem);
    position: relative;
    overflow: auto;
`;
const DataTable = styled(Table)`
    width: 100%;
    max-height: 100%;
    /* position: relative; */
    border-collapse: collapse;
    border: 1px solid #ccc;

    td {
        padding: 0.5rem;
        border: 1px solid #ccc;
        max-width: 200px;
        /* background-color: #fff; */
        vertical-align: top;
        &.sticky {
            /* position: sticky;
            z-index: 1; */
            left: 0;
            background-color: #fff;
            /* border-right: 3px solid rgba(0, 0, 0, 0.3); */

        }
    }
    th {

        padding: 0.5rem;
        border: 1px solid #ccc;
        max-width: 200px;
        position: sticky;
        top: 0;
        /* background-color: #eee; */
        z-index: 2;        
        /* &.sticky {
            z-index: 3;
            left: 0;

        } */
    }
}`;

const ButtonPanel = styled(Panel)`
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 3;
    padding-top: 1rem;
    margin-bottom: -0.3rem;
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    height: 4.5rem;
`;

const Legend = styled.div`
    flex-grow: 1;
    margin-right: 1rem;
    display: flex;
    align-items: center;
`;

export const BulkImporter = ({
    templateUrl,
    fileSubmissionUrl,
    rowSubmissionUrl,
    onCancel,
    onFinish,
    showCurrentDataButton = false,
    fileType = "xlsx",
    helpText = "",
    filename = "import-template",
    blankFilename = "import-template",
}) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();
    const [tableData, setTableData] = useState({
        loaded: false,
        loading: false,
        data: [],
    });

    const [downloading, setDownloading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const downloadTemplate = (blankTemplate = false) => {
        setDownloading(true);
        addToast({
            uuid: "downloadToast",
            type: "loader",
            content: trans("Template is downloading"),
        });

        const templateFilename = blankTemplate
            ? `${blankFilename}.${fileType}`
            : `${filename}.${fileType}`;
        // console.log({ templateFilename });
        axios
            .get(templateUrl, {
                responseType: "blob",
                params: {
                    blankTemplate,
                },
            })
            .then((response) => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });
                saveAs(new Blob([response.data]), templateFilename);
            })
            .catch((e) => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred downloading the template");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            })
            .finally(() => {
                setDownloading(false);
            });
    };

    const uploadTemplate = (acceptedFiles) => {
        setUploading(true);
        setTableData({
            ...tableData,
            loading: true,
        });
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        axios
            .post(fileSubmissionUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(({ data }) => {
                setTableData({
                    loaded: true,
                    loading: false,
                    data: data.data,
                });
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : trans("Error uploading your file"),
                    showFor: 5000,
                });
                setTableData({
                    loaded: false,
                    loading: false,
                    data: [],
                });
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const addRow = () => {
        let newData = { ...tableData.data };

        let lastRowId = Object.keys(newData.rows).pop();

        if (!lastRowId) {
            lastRowId = "row-0";
        }

        const schema = newData.schema;

        const partsOfRow = lastRowId.split("-");
        const rowNumber = partsOfRow[1];
        const newId = partsOfRow[0] + "-" + (parseInt(rowNumber) + 1);

        newData.rows[newId] = schema;

        setTableData({
            ...tableData,
            newData,
        });
    };

    const deleteRow = (rowId) => (e) => {
        // has to always be at least one row
        let newData = { ...tableData.data };

        if (Object.keys(newData.rows).length > 1) {
            delete newData.rows[rowId];

            setTableData({
                ...tableData,
                newData,
            });
        }
    };

    const updateCell = (rowId, key, value) => {
        // const newRows = { ...tableData.data.rows };
        // newRows[rowId].data[key].value = value;
        // setTableData({
        //     ...tableData,
        //     data: { ...tableData.data, rows: newRows },
        // });
        let newData = { ...tableData.data };
        let cell = newData.rows[rowId].data[key];
        if (!cell) {
            cell = {
                value: "",
                error: "",
            };
        }
        if (cell.value === value) {
            return;
        } else {
            cell.value = value;
        }

        newData.rows[rowId].data[key] = cell;
        setTableData({
            ...tableData,
            data: newData,
        });
    };

    const submitRows = (e) => {
        e.preventDefault();
        setSubmitting(true);
        axios
            .post(rowSubmissionUrl, { rows: tableData.data.rows })
            .then(({ data }) => {
                setTableData({
                    loaded: true,
                    loading: false,
                    data: data.data,
                });
            })
            .catch((error) => {
                if (error.status === 422) {
                    setTableData({
                        loaded: true,
                        loading: false,
                        data: error.response.data,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: error?.response?.data?.message
                            ? error.response.data.message
                            : trans("Error submitting your data"),
                        showFor: 5000,
                    });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    if (!tableData.loaded && !tableData.loading) {
        return (
            <UploadWrapper>
                <UploadPanel>
                    <h2>{trans("Bulk Import")}</h2>
                    <p>
                        {trans(
                            "To perform the import please download a template and add/edit your data"
                        )}
                        {": "}
                    </p>
                    <IconTextButton
                        onClick={(e) => {
                            e.preventDefault();
                            downloadTemplate(true);
                        }}
                        icon={"download"}
                        showCircle={false}
                    >
                        {trans("Download Template")}
                    </IconTextButton>
                    {showCurrentDataButton && (
                        <IconTextButton
                            onClick={(e) => {
                                e.preventDefault();
                                downloadTemplate();
                            }}
                            icon={"download"}
                            showCircle={false}
                        >
                            {trans("Download Template with Current Data")}
                        </IconTextButton>
                    )}

                    {helpText && <p>{helpText}</p>}

                    <div style={{ marginTop: "1rem" }}>
                        <SimpleDropzone onDrop={uploadTemplate}>
                            <Button onClick={(e) => e.preventDefault()}>
                                {trans("Upload Your Template")}
                            </Button>
                        </SimpleDropzone>
                    </div>
                    <ButtonPanel>
                        <IconTextButton
                            onClick={onCancel}
                            icon={"times"}
                            showCircle={false}
                        >
                            {trans("Cancel")}
                        </IconTextButton>
                    </ButtonPanel>
                </UploadPanel>
            </UploadWrapper>
        );
    }

    if (tableData.loading) {
        return (
            <UploadWrapper>
                <UploadPanel
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <h3>{trans("Loading Data")}</h3>
                    <Loading />
                </UploadPanel>
            </UploadWrapper>
        );
    }
    const { columns, rows, statuses = {} } = tableData.data;

    const rowCount = Object.keys(rows).length;
    return (
        <ImportWrapper>
            <TableWrapper>
                <DataTable zebraStripe={true}>
                    <thead>
                        {columns.map((column) => {
                            return (
                                <th
                                    key={column.key}
                                    className={`${
                                        column.sticky ? "sticky" : ""
                                    }`}
                                >
                                    {column.value}
                                </th>
                            );
                        })}
                    </thead>
                    <tbody>
                        {Object.entries(rows).map(([rowId, row], rowIndex) => {
                            // console.log({ rowId, row });
                            // console.log(column);

                            const disabled =
                                row.status === "success" ||
                                row.status === "created" ||
                                row.status === "updated";

                            return (
                                <tr
                                    style={{
                                        backgroundColor: row.color,
                                    }}
                                >
                                    {columns.map((column) => {
                                        const cell = row.data[column.key];
                                        // console.log({ key: column.key, cell });
                                        let style = {
                                            minWidth: column.width
                                                ? column.width
                                                : "150px",
                                        };
                                        // if (column.sticky) {
                                        //     style = {
                                        //         ...style,

                                        //         zIndex: rowCount - rowIndex,
                                        //     };
                                        // }

                                        return (
                                            <td
                                                key={column.key}
                                                className={`${
                                                    column.sticky
                                                        ? "sticky"
                                                        : ""
                                                }`}
                                                style={style}
                                            >
                                                <CellInput
                                                    column={column}
                                                    cell={cell}
                                                    rowId={rowId}
                                                    onChange={updateCell}
                                                    // disabled={disabled}
                                                />
                                            </td>
                                        );
                                    })}
                                    <td key={"actions"}>
                                        <IconTextButton
                                            onClick={deleteRow(rowId)}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                        ></IconTextButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </DataTable>
            </TableWrapper>
            <ButtonPanel>
                <Legend>
                    {Object.entries(statuses).map(([key, status]) => {
                        // console.log("Statuses", { key, status });
                        return (
                            <LegendItem
                                key={key}
                                label={
                                    status.value.charAt(0).toUpperCase() +
                                    status.value.slice(1)
                                }
                                tooltip={status.tooltip}
                                color={status.color}
                            />
                        );
                    })}
                </Legend>
                <IconTextButton
                    onClick={addRow}
                    icon={"plus"}
                    showCircle={false}
                    disabled={submitting}
                >
                    {trans("Add Row")}
                </IconTextButton>
                <IconTextButton
                    onClick={onCancel}
                    icon={"times"}
                    showCircle={false}
                    disabled={submitting}
                >
                    {trans("Cancel")}
                </IconTextButton>
                <SubmitButton
                    onClick={submitRows}
                    disabled={submitting}
                    submitting={submitting}
                >
                    {trans("Submit")}
                </SubmitButton>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        onFinish ? onFinish() : onCancel();
                    }}
                    disabled={
                        !Object.values(rows).some(
                            (row) =>
                                row.status === "updated" ||
                                row.status === "created" ||
                                row.status === "success"
                        ) || submitting
                    }
                    style={{ marginLeft: "1rem" }}
                >
                    {trans("Finish")}
                </Button>
            </ButtonPanel>
        </ImportWrapper>
    );
};
