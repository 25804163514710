import styled from "styled-components";

export const DetailWrapper = styled.div`
    width: ${({ width }) => (width ? width : "32rem")};
    overflow: auto;
    font-size: 0.8rem;
    .detailRow {
        margin: 0;
        padding: 0.5rem ${({ theme }) => 1 - theme.spacing.padding / 2}rem;
    }
    .detailRow:nth-child(odd) {
        background: ${({ theme }) => theme.colors.gray200};
        color: ${({ theme }) => theme.colors.gray900};
    }
`;

export const Header = styled.header`
    padding: 1rem;
    background: ${({ theme }) => theme.panel.white};
    color: ${({ theme }) => theme.panel.gray900};
    border-bottom: 2px solid ${({ theme }) => theme.panel.gray800};
    h4 {
        margin: 0 2rem 0 0;
    }
`;
