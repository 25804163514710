import React from "react";
import styled, { withTheme } from "styled-components";
import { Row, FormLabel, Panel } from "@cortexglobal/rla-components";

/**
 * Styled Components here
 *
 * Flex should be in RLA components
 */
export const Flex = styled.div`
    display: flex;
    gap: ${(props) => (props.gap ? props.gap : "0.6em")};
    align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
    ${(props) => props.direction && `flex-direction: ${props.direction}`};
    ${(props) => props.wrap && `flex-wrap: ${props.wrap}`};
    ${(props) =>
        props.justifyContent && `justify-content: ${props.justifyContent}`};
    ${(props) => props.alignContent && `align-content: ${props.alignContent}`};
    margin-bottom: ${(props) =>
        props.marginBottom
            ? props.marginBottom
            : `${props.theme.spacing.margin}rem`};
`;

export const SurveyPanel = styled(Panel)``;

export const SurveyFormLabel = styled(FormLabel)`
    width: 100px;
    flex-shrink: 0;
    margin-bottom: 0;
`;

export const SurveyButtons = styled.div`
    margin-top: ${({ theme, marginTop }) =>
        `${
            typeof marginTop !== "undefined" && marginTop !== null
                ? marginTop
                : theme.spacing.margin / 2
        }rem`};
    margin-bottom: ${(props) => `${props.theme.spacing.margin}rem`};
    display: flex;
    gap: 1.2em;
    justify-content: flex-end;
    align-items: center;
`;
