import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
    Panel,
    Row,
    Column,
    Button,
    IconTextButton
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { faUserPlus, faSignInAlt } from "@fortawesome/free-solid-svg-icons";

import { LogoutWrapper } from "@cortexglobal/cortex-auth-react-router-dom";

const GuestActionsPanel = props => {
    return (
        <React.Fragment>
            <h3>{trans("Available Actions")}</h3>

            <Panel>
                <h4 style={{ paddingLeft: "1.7rem" }}>
                    {trans(
                        "Please login or register if you wish to use this asset."
                    )}
                </h4>

                <Row>
                    <LogoutWrapper loginUrl="/auth/login">
                        {({ logout }) => (
                            <IconTextButton
                                as={Link}
                                to="/auth/login"
                                onClick={logout}
                                icon={faSignInAlt}
                                showCircle={false}
                            >
                                {trans("Login")}
                            </IconTextButton>
                        )}
                    </LogoutWrapper>
                </Row>
                <Row>
                    <IconTextButton
                        as={Link}
                        to="/auth/register"
                        icon={faUserPlus}
                        showCircle={false}
                    >
                        {trans("Register")}
                    </IconTextButton>
                </Row>
            </Panel>
        </React.Fragment>
    );
};

export default GuestActionsPanel;
