import React from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";

import {
    Tabordion,
    Row,
    Column,
    Table,
    Image,
    TranslationButton as CortexTranslationButton
} from "@cortexglobal/rla-components";

const TranslationButton = styled(CortexTranslationButton)`
    font-weight: inherit !important;
`;
import { TableDataValue, ComparisonRow } from "./comparisonComponents";

const processValue = (value, locale, translate = true) => {
    //If it's a string just return if
    if (typeof value === "string" || value === null) {
        return translate ? (
            <TranslationButton text={value} from={locale} />
        ) : (
            value
        );
    }
    //If the value is an array we assume it's a repeater
    //so loop through the objects outputting their values
    if (Array.isArray(value)) {
        return value.map(valueObj => (
            <div>
                {translate ? (
                    <TranslationButton text={valueObj.value} from={locale} />
                ) : (
                    valueObj.value
                )}
            </div>
        ));
    }

    //If the value looks like a sequenced video treat it as such
    if (typeof value === "object" && value.hasOwnProperty("captions")) {
        return (
            <>
                <h5>{trans("Captions")}</h5>
                {value.captions.map(caption => {
                    return (
                        <div>
                            {translate ? (
                                <TranslationButton
                                    text={caption.value}
                                    from={locale}
                                />
                            ) : (
                                caption.value
                            )}
                        </div>
                    );
                })}
                <h5>{trans("Image")}</h5>
                {value.imageSrc}
                {/* <Image src={value.imageSrc} /> */}
                <h5>{trans("Video")}</h5>
                {value.videoSrc}
                {/* <video src={value.videoSrc} /> */}
            </>
        );
    }

    return JSON.stringify(value);
};
const ComparePages = ({ pages, showOnlyChanged, localeObject }) => {
    return (
        <React.Fragment>
            <Row>
                <Column>
                    <h3>{trans("Pages")}</h3>
                </Column>
            </Row>
            <Row>
                <Column>
                    <Tabordion type="accordion" unmounts={false}>
                        {Object.entries(pages).map(
                            ([slug, { changed_count, name, content }]) => {
                                if (showOnlyChanged && changed_count <= 0) {
                                    return null;
                                }
                                return (
                                    <div
                                        key={slug}
                                        heading={
                                            <span>
                                                {name.this_value} - {slug} (
                                                {changed_count}{" "}
                                                {trans("changed")})
                                            </span>
                                        }
                                    >
                                        <Table width="100%">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {trans("Attribute")}
                                                    </th>
                                                    <th>{trans("Current")}</th>
                                                    <th>{trans("Version")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(!showOnlyChanged ||
                                                    name.changed) && (
                                                    <ComparisonRow
                                                        changed={name.changed}
                                                    >
                                                        <td width="20%">
                                                            {trans("Name")}
                                                        </td>
                                                        <TableDataValue width="40%">
                                                            {name.this_value}
                                                        </TableDataValue>
                                                        <TableDataValue width="40%">
                                                            {name.other_value}
                                                        </TableDataValue>
                                                    </ComparisonRow>
                                                )}
                                                {content &&
                                                    content
                                                        .filter(
                                                            ({
                                                                changed,
                                                                footnotes_changed
                                                            }) =>
                                                                !showOnlyChanged
                                                                    ? true
                                                                    : changed ||
                                                                      footnotes_changed
                                                        )
                                                        .map(
                                                            ({
                                                                footnotes,
                                                                name,
                                                                other_value,
                                                                this_value,
                                                                uuid,
                                                                changed,
                                                                footnotes_changed
                                                            }) => {
                                                                return (
                                                                    <React.Fragment
                                                                        key={
                                                                            uuid
                                                                        }
                                                                    >
                                                                        {(!showOnlyChanged ||
                                                                            changed) && (
                                                                            <ComparisonRow
                                                                                changed={
                                                                                    changed
                                                                                }
                                                                            >
                                                                                <td width="20%">
                                                                                    {
                                                                                        name
                                                                                    }
                                                                                </td>
                                                                                <TableDataValue width="40%">
                                                                                    {processValue(
                                                                                        this_value,
                                                                                        localeObject.this_value,
                                                                                        false
                                                                                    )}
                                                                                </TableDataValue>
                                                                                <TableDataValue width="40%">
                                                                                    {processValue(
                                                                                        other_value,
                                                                                        localeObject.other_value
                                                                                    )}
                                                                                </TableDataValue>
                                                                            </ComparisonRow>
                                                                        )}
                                                                        {Array.isArray(
                                                                            footnotes
                                                                        ) &&
                                                                            footnotes
                                                                                .filter(
                                                                                    ({
                                                                                        changed
                                                                                    }) =>
                                                                                        !showOnlyChanged
                                                                                            ? true
                                                                                            : changed
                                                                                )
                                                                                .map(
                                                                                    ({
                                                                                        other_link,
                                                                                        other_text,
                                                                                        this_link,
                                                                                        this_text,
                                                                                        changed
                                                                                    }) => {
                                                                                        return (
                                                                                            <ComparisonRow
                                                                                                changed={
                                                                                                    changed
                                                                                                }
                                                                                            >
                                                                                                <td width="20%">
                                                                                                    {
                                                                                                        name
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {trans(
                                                                                                        "Footnotes"
                                                                                                    )}

                                                                                                    )
                                                                                                </td>
                                                                                                <TableDataValue width="40%">
                                                                                                    {trans(
                                                                                                        "Text"
                                                                                                    )}

                                                                                                    :{" "}
                                                                                                    {
                                                                                                        this_text
                                                                                                    }
                                                                                                    <br />
                                                                                                    {trans(
                                                                                                        "Link"
                                                                                                    )}

                                                                                                    :{" "}
                                                                                                    {
                                                                                                        this_link
                                                                                                    }
                                                                                                </TableDataValue>
                                                                                                <TableDataValue width="40%">
                                                                                                    {trans(
                                                                                                        "Text"
                                                                                                    )}

                                                                                                    :{" "}
                                                                                                    {
                                                                                                        other_text
                                                                                                    }
                                                                                                    <br />
                                                                                                    {trans(
                                                                                                        "Link"
                                                                                                    )}

                                                                                                    :{" "}
                                                                                                    {
                                                                                                        other_link
                                                                                                    }
                                                                                                </TableDataValue>
                                                                                            </ComparisonRow>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                            </tbody>
                                        </Table>
                                    </div>
                                );
                            }
                        )}
                    </Tabordion>{" "}
                </Column>
            </Row>
        </React.Fragment>
    );
};

export default ComparePages;
