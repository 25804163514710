import React, { Component } from "react";
import PropTypes from "prop-types";

import CalendarCategory from "./CalendarCategory";
import { TaskShape, CategoryShape } from "./calendarPropTypes";

class CalendarBody extends Component {
    render() {
        const { categories, tasks } = this.props;
        return (
            <React.Fragment>
                {categories
                    .filter((category) => category.type === "category")
                    .map((category) => {
                        //Load up all the tasks in this category
                        category.tasks = tasks.filter(
                            (task) =>
                                task.primary_planner_category_id === category.id
                        );
                        //Load in all the 'child' categories
                        category.taskCategories = categories.filter(
                            (taskCategory) =>
                                taskCategory.parent_id === category.id
                        );

                        //Don't show categories with no taskCategories as there
                        //would be nothing to display, with no way of adding it
                        // if (category.taskCategories.length === 0) {
                        //     return null;
                        // }

                        return (
                            <CalendarCategory
                                key={category.uuid}
                                category={category}
                            />
                        );
                    })}
            </React.Fragment>
        );
    }
}

CalendarBody.propTypes = {
    categories: PropTypes.arrayOf(CategoryShape),
    tasks: PropTypes.arrayOf(TaskShape),
};

CalendarBody.defaultProps = {};

export default CalendarBody;
