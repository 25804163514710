import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    ContentBlockLoader,
    CheckboxTree,
    InputField,
    HelpText,
    Select,
    FormLabel,
    IconTextButton,
    Panel,
    ValidationError,
} from "@cortexglobal/rla-components";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

import { SectionHeading } from "./components/SectionHeading";

const FolderAdmin = ({ asset, standardOnChange, errors }) => {
    const [folders, setFolders] = useState({ loaded: false, data: [] });

    useEffect(() => {
        axios
            .get(`/api/v1/assets/folders/tree`)
            .then(({ data }) => {
                setFolders({
                    loaded: true,
                    data: data.data,
                });
            })
            .catch((e) => e);
    }, []);

    return (
        <React.Fragment>
            <SectionHeading>{trans("Asset Folder")}</SectionHeading>

            <Panel>
                <Row>
                    <Column medium={3} style={{ textAlign: "right" }}>
                        <FormLabel name="asset_folder_uuid">
                            {trans("Folder")}
                        </FormLabel>
                        <HelpText>{trans("Optional")}</HelpText>
                    </Column>
                    <Column medium={6}>
                        <ContentBlockLoader loaded={folders.loaded}>
                            <CheckboxTree
                                name="asset_folder_uuid"
                                value={asset.asset_folder_uuid}
                                options={folders.data}
                                optionIcon={faFolder}
                                selectChildren={false}
                                onChange={standardOnChange}
                                allowMultiple={false}
                            />
                        </ContentBlockLoader>
                    </Column>
                    <Column medium={3}>
                        {errors && (
                            <ValidationError>{errors.author}</ValidationError>
                        )}
                    </Column>
                </Row>
            </Panel>
        </React.Fragment>
    );
};

export default FolderAdmin;
