import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    Row,
    Column,
    Button,
    SubmitButton,
    IconTextButton,
    Panel,
    useAddToast,
    PageTitle,
    Tabordion,
    HeaderWithErrorIndicated,
    useSectionErrors,
    Loading,
} from "@cortexglobal/rla-components";
import GeneralDetailsSection from "../forms/GeneralDetailsSection";
import AssetSection from "../forms/AssetSection";
import CustomFieldSection from "../forms/CustomFieldSection";
import CampaignFiles from "../forms/CampaignFiles";

export const EditCampaign = ({ basePath, campaignUuid, assetsPath }) => {
    const addToast = useAddToast();
    const history = useHistory();
    const [config, setConfig] = useState({});
    const [campaign, setCampaign] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: ["name", "description", "start_date", "end_date"],
    });

    useEffect(() => {
        axios
            .get("/api/v1/assets/config-options")
            .then(({ data }) => {
                setConfig(data);
            })
            .catch((e) => e);
    }, []);

    useEffect(() => {
        loadCampaign(campaignUuid);
    }, [campaignUuid]);

    const loadCampaign = (campaignUuid) => {
        setLoaded(false);
        return axios
            .get(`/api/v1/campaigns/admin/${campaignUuid}`)
            .then(({ data: { data } }) => {
                setCampaign(data);
                setLoaded(true);
                return data;
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error loading campaign"),
                    showFor: 5000,
                });
            });
    };

    const editCampaign = (event) => {
        event.preventDefault();

        return axios
            .put(`/api/v1/campaigns/admin/${campaign.uuid}`, campaign)
            .then(({ data: { data } }) => {
                addToast({
                    type: "success",
                    content: trans("Campaign updated successfully"),
                    showFor: 5000,
                });
                setErrors({});
                setCampaign(data);
                return data;
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    setSubmitting(false);
                });

                console.log(e.response);
                const message =
                    e?.response?.status === 422
                        ? trans("Please check the form for errors")
                        : trans("An error occurred creating the campaign");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            });
    };

    const finishEditingCampaign = (event) => {
        event.preventDefault();
        editCampaign(event).then((data) => {
            history.push(`${basePath}/view/${data.uuid}`);
        });
    };

    const handleChange = ({ name, value }) => {
        console.log({ name, value });
        setCampaign({ ...campaign, [name]: value });
    };

    return (
        <React.Fragment>
            <PageTitle title={<h2>{trans("Edit Campaign")}</h2>}>
                <IconTextButton
                    as={Link}
                    to={`${basePath}`}
                    showCircle={false}
                    rotation={180}
                >
                    {trans("Back to Campaigns")}
                </IconTextButton>
            </PageTitle>
            {/* <form onSubmit={editCampaign}> */}
            {loaded ? (
                <form onSubmit={(event) => event.preventDefault()}>
                    <Row>
                        <Column>
                            <Tabordion current={0}>
                                <GeneralDetailsSection
                                    heading={
                                        <HeaderWithErrorIndicated
                                            title={trans("General Details")}
                                            section="general"
                                            sectionErrors={sectionErrors}
                                        />
                                    }
                                    campaign={campaign}
                                    onChange={handleChange}
                                    errors={errors}
                                    submitting={submitting}
                                />
                                <CampaignFiles
                                    heading={
                                        <HeaderWithErrorIndicated
                                            title={trans("Files")}
                                            section="files"
                                            sectionErrors={sectionErrors}
                                        />
                                    }
                                    campaign={campaign}
                                    onChange={handleChange}
                                    errors={errors}
                                    submitting={submitting}
                                />
                                <CustomFieldSection
                                    heading={
                                        <HeaderWithErrorIndicated
                                            title={trans("Custom Fields")}
                                            section="custom_fields"
                                            sectionErrors={sectionErrors}
                                        />
                                    }
                                    campaign={campaign}
                                    onChange={handleChange}
                                    errors={errors}
                                    submitting={submitting}
                                />
                                <AssetSection
                                    heading={
                                        <HeaderWithErrorIndicated
                                            title={trans("Assets")}
                                            section="assets"
                                            sectionErrors={sectionErrors}
                                        />
                                    }
                                    campaign={campaign}
                                    onChange={handleChange}
                                    errors={errors}
                                    submitting={submitting}
                                    loadCampaign={loadCampaign}
                                    assetsPath={assetsPath}
                                    config={config}
                                />
                            </Tabordion>
                        </Column>
                    </Row>{" "}
                    <Panel
                        style={{
                            position: "sticky",
                            bottom: 0,
                            marginTop: "1rem",
                        }}
                    >
                        <Row
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                            }}
                        >
                            <Button as={Link} to={`${basePath}`} type={"alert"}>
                                {trans("Cancel")}
                            </Button>

                            <SubmitButton
                                style={{ marginLeft: "1rem" }}
                                disabled={submitting}
                                submitting={submitting}
                                onClick={editCampaign}
                            >
                                {trans("Save and continue")}
                            </SubmitButton>
                            <SubmitButton
                                style={{ marginLeft: "1rem" }}
                                disabled={submitting}
                                submitting={submitting}
                                onClick={finishEditingCampaign}
                            >
                                {trans("Save and finish")}
                            </SubmitButton>
                        </Row>
                    </Panel>
                </form>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    );
};

export default EditCampaign;
