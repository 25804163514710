import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    IconTextButton,
    PageTitle,
    useAddToast,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { NavigationTable } from "./NavigationTable";

const loadItems = (setItems, page, orderBy, orderByDir, perPage) => {
    setItems({
        loaded: false,
    });
    axios
        .get(`/api/v1/admin/navigation`, {
            params: {
                page: page,
                order_by_direction: orderByDir ? orderByDir : null,
                order_by: orderBy ? orderBy : null,
                per_page: perPage ? perPage : 10,
            },
        })
        .then(({ data }) => {
            setItems({
                loaded: true,
                data: data.data,
                pagination: data.meta,
            });
        })
        .catch();
};
const deleteItem = (
    uuid,
    setItems,
    page = 1,
    orderBy,
    orderByDirection,
    perPage,
    addToast
) => {
    return axios
        .delete(`/api/v1/admin/navigation/${uuid}`)
        .then(({ data }) => {
            loadItems(setItems, page, orderBy, orderByDirection, perPage);
        })
        .catch((e) => {
            addToast({
                type: "alert",
                content:
                    e.response && e.response.data
                        ? e.response.data.message
                        : trans("An error has occurred"),
                showFor: 5000,
            });
            throw e;
        });
};
export const NavigationListing = ({ path }) => {
    const history = useHistory();
    const page = useQueryString().get("page") || 1;
    const orderByParam = useQueryString().get("orderBy") || "position";
    const dirParam = useQueryString().get("dir") || "ASC";
    const perPageParam = useQueryString().get("perPage") || 10;

    const [orderBy, setOrderBy] = useState(orderByParam);
    const [orderByDirection, setoOrderByDirection] = useState(dirParam);

    const addToast = useAddToast();

    //@ts-ignore cortex
    const [items, setItems] = useState({
        loaded: false,
        data: [],
        pagination: null,
    });

    useEffect(() => {
        loadItems(setItems, page, orderBy, orderByDirection, perPageParam);
    }, [setItems]);

    const onOrderBy = (data) => {
        let orderDir = orderByDirection;

        if (orderBy === data) {
            setoOrderByDirection(orderByDirection === "ASC" ? "DESC" : "ASC");
            orderDir = orderByDirection === "ASC" ? "DESC" : "ASC";
        } else {
            setOrderBy(data);
            setoOrderByDirection("ASC");
            orderDir = "ASC";
        }

        history.push(
            `?page=${page}&orderBy=${data}&dir=${orderDir}&perPage=${perPageParam}`
        );
        loadItems(setItems, page, data, orderDir, perPageParam);
    };

    const onPerPageChange = ({ value }: { value: string }) => {

        history.push(
            `?page=${page}&orderBy=${orderBy}&dir=${orderByDirection}&perPage=${value}`
        );
        loadItems(setItems, page, orderBy, orderByDirection, value);
    };

    return (
        <>
            <PageTitle
                title={<React.Fragment>{trans("Navigation")} </React.Fragment>}
            >
                <IconTextButton
                    as={Link}
                    to={`${path}/create`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    icon={faPlus}
                >
                    {trans("Create new navigation")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <NavigationTable
                    items={items.data}
                    loaded={items.loaded}
                    path={path}
                    pagination={items.pagination}
                    orderBy={orderBy}
                    orderByDirection={orderByDirection}
                    onPageChange={(selected) => {
                        history.push(
                            `?page=${selected}&orderBy=${orderBy}&dir=${orderByDirection}&perPage=${perPageParam}`
                        );
                        loadItems(
                            setItems,
                            selected,
                            orderBy,
                            orderByDirection,
                            perPageParam
                        );
                    }}
                    deleteItem={(uuid) =>
                        deleteItem(
                            uuid,
                            setItems,
                            items.pagination.current_page,
                            orderBy,
                            orderByDirection,
                            perPageParam,
                            addToast
                        )
                    }
                    onPerPageChange={onPerPageChange}
                    onOrderBy={onOrderBy}
                />
            </Row>
        </>
    );
};

export default NavigationListing;
