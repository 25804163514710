import React, { useContext, useEffect } from "react";

import styled from "styled-components";

const RangeContainer = styled.div`
    margin-bottom: ${(props) => `${props.theme.spacing.margin}rem`};
    padding-top: ${(props) => `${props.theme.spacing.padding}rem`};
    padding-bottom: ${(props) => `${props.theme.spacing.padding}rem`};
    /* max-width: 500px; */
`;

import { trans } from "@cortexglobal/rla-intl";
import { Range, InputError } from "@cortexglobal/rla-components";

import SurveySubmissionContext from "../SurveySubmissionContext";

const AnswerRange = ({ answer, options, readonly }) => {
    const { surveyAnswersData, surveySystem, handleAnswerChange } = useContext(
        SurveySubmissionContext
    );
    const { isSubmitting, isSubmitted, questionErrors } = surveySystem;

    // When this loads for the first time use the default as the answer
    useEffect(() => {
        const index = surveyAnswersData.findIndex(
            (answerdata) => answerdata.question_uuid === answer.question_uuid
        );
        if (index < 0) {
            handleAnswerChange(
                answer.question_uuid,
                answer.answer || options.range.default_value
            );
        }
    }, [surveyAnswersData]);

    return (
        <RangeContainer>
            <Range
                name={answer.question_uuid}
                value={answer.answer || options.range.default_value}
                minValue={options.range.min_value}
                maxValue={options.range.max_value}
                onChange={(e) => handleAnswerChange(e.name, e.value)}
                disabled={!!readonly}
                draggableTrack={true}
                error={questionErrors[answer.question_uuid]}
            />
            {questionErrors[answer.question_uuid] && (
                <InputError error={questionErrors[answer.question_uuid]} />
            )}
        </RangeContainer>
    );
};

export default AnswerRange;
