import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import UserForm, { User } from "../../forms/UserForm";

export const UserCreate = ({ path }) => {
    const history = useHistory();

    const item: User = {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        primary_group_id: 0,
        primary_role_id: 0,
        language: "",
        status: "",
        exclude_from_reporting: 0,
        can_send_communication_to: 1,
        sso_auth_token: null,
    };

    const submitForm = (values) => {
        return axios.post(`/api/v1/admin/user`, values).then(() => {
            //As creation was successful move them on to the appropriate listing page
            history.push(`${path}`);
        });
    };

    return (
        <div>
            <PageTitle title={trans("New User")}>
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <UserForm item={item} submitForm={submitForm} />
                </Panel>
            </Row>
        </div>
    );
};

export default UserCreate;
