// Our translated strings TODO - Look at doing this dynamically with dynamic imports (or ajax?)
import messagesEnGB from "./locales/en-GB.data.json";
import messagesEn from "./locales/en.data.json";
import messagesFr from "./locales/fr.data.json";
import messagesJa from "./locales/ja.data.json";
const localeData = {
    "en-GB": messagesEnGB,
    en: messagesEn,
    fr: messagesFr,
    ja: messagesJa
};

if (!Intl.PluralRules) {
    require("@formatjs/intl-pluralrules/polyfill");
    require("@formatjs/intl-pluralrules/dist/locale-data/de");
    require("@formatjs/intl-pluralrules/dist/locale-data/es");
    require("@formatjs/intl-pluralrules/dist/locale-data/fr");
    require("@formatjs/intl-pluralrules/dist/locale-data/it");
    require("@formatjs/intl-pluralrules/dist/locale-data/ja");
}

if (!Intl.RelativeTimeFormat) {
    require("@formatjs/intl-relativetimeformat/polyfill");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/de");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/es");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/fr");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/it");
    require("@formatjs/intl-relativetimeformat/dist/locale-data/ja");
}
export const getMessages = language => {
    // Split locales with a region code
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

    //Test French
    //return localeData.fr;

    // Try full locale, try locale without region code, fallback to 'en'
    return (
        localeData[language] ||
        localeData[languageWithoutRegionCode] ||
        localeData.en
    );
};
