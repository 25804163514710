import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Table, Loading } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "@cortexglobal/cortex-utilities";

const Panel = styled.div`
    display: flex;
    align-items: center;
    padding: 1em;
`;

const TrueIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.success};
`;
const FalseIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.alert};
`;

const SharingLinksHistoryPanel = ({ asset }) => {
    const [loaded, setLoaded] = useState(false);
    const [history, setHistory] = useState([]);
    const [page, setPage] = useState(1);
    useEffect(() => {
        axios
            .get(`/api/v1/assets/${asset.uuid}/sharing-history`, {
                params: {
                    page: page,
                    per_page: 10,
                },
            })
            .then(({ data }) => {
                setHistory(data.data);
                setLoaded(true);
            });
    }, []);

    if (!loaded) {
        return <Loading />;
    }
    return (
        <Panel>
            <Table>
                <thead>
                    <tr>
                        {/* <th>{trans("Url")}</th> */}
                        <th>{trans("Created By")}</th>
                        <th>{trans("Date Generated")}</th>
                        <th>{trans("Last Accessed")}</th>
                        <th>{trans("Expiry Date")}</th>
                        <th>{trans("One Time Use")}</th>
                        <th>{trans("Password Protected")}</th>
                    </tr>
                </thead>
                <tbody>
                    {history.length > 0 ? (
                        history.map((share) => {
                            return (
                                <tr>
                                    {/* <td>{share.path}</td> */}
                                    <td>
                                        {share.user
                                            ? share.user.name
                                            : trans("Unknown")}
                                    </td>
                                    <td>
                                        {format(
                                            new Date(share.created_at),
                                            "PP"
                                        )}
                                    </td>
                                    <td>
                                        {format(
                                            new Date(share.last_viewed_at),
                                            "PP"
                                        )}
                                    </td>
                                    <td>
                                        {share.expires_at
                                            ? format(
                                                  new Date(share.expires_at),
                                                  "PP"
                                              )
                                            : "-"}
                                    </td>
                                    <td>
                                        {share.one_time_use ? (
                                            <TrueIcon icon="check-circle" />
                                        ) : (
                                            <FalseIcon icon="times-circle" />
                                        )}
                                    </td>
                                    <td>
                                        {share.password_protected ? (
                                            <TrueIcon icon="check-circle" />
                                        ) : (
                                            <FalseIcon icon="times-circle" />
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="100%">
                                {trans("No sharing history found")}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Panel>
    );
};

export default SharingLinksHistoryPanel;
