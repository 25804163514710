import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import {
    SpacedRow,
    DetailWrapper,
    DetailsLabel,
    DetailsContent,
    DetailsColumn,
    DescriptionContainer,
} from "./overviewComponents";

const ActivityDetails = ({ activity, user, task }) => {
    return (
        <DetailWrapper>
            <Row>
                {activity.description && (
                    <DetailsColumn style={{ marginTop: "20px" }}>
                        <DescriptionContainer>
                            {activity.description}
                        </DescriptionContainer>
                    </DetailsColumn>
                )}

                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Date Range")}</DetailsLabel>
                    <DetailsContent>
                        {moment(activity.start_date).format("LL")} -{" "}
                        {moment(activity.end_date).format("LL")}
                    </DetailsContent>
                </SpacedRow>

                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Status")}</DetailsLabel>
                    <DetailsContent>{activity.status_wording}</DetailsContent>
                </SpacedRow>

                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Planned Cost")}</DetailsLabel>

                    <DetailsContent>
                        <FormattedCurrency
                            value={activity.planned_cost?.value || 0}
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </DetailsContent>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Actual Cost")}</DetailsLabel>

                    <DetailsContent>
                        <FormattedCurrency
                            value={activity.cost?.value || 0}
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </DetailsContent>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Media Channel")}</DetailsLabel>

                    <DetailsContent>
                        {activity.media_channel_full?.name || "None selected"}
                    </DetailsContent>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Lead Stakeholder")}</DetailsLabel>

                    <DetailsContent>
                        {task.user.name}
                        {task.user.phone && <p>{task.user.phone}</p>}
                        {task.user.email && <p>{task.user.email}</p>}
                    </DetailsContent>
                </SpacedRow>
                {task && task.group && (
                    <SpacedRow className="detailRow">
                        <DetailsLabel>{trans("Group")}</DetailsLabel>

                        <DetailsContent>
                            {task.group.name}
                            {task.group.contact && (
                                <>
                                    <div>
                                        {task.group.contact.address_1}{" "}
                                        {task.group.contact.address_1 && <br />}
                                        {task.group.contact.address_2}{" "}
                                        {task.group.contact.address_2 && <br />}
                                        {task.group.contact.address_3}{" "}
                                        {task.group.contact.address_3 && <br />}
                                        {task.group.contact.address_4}{" "}
                                        {task.group.contact.address_4 && <br />}
                                        {task.group.contact.address_5}{" "}
                                        {task.group.contact.address_5 && <br />}
                                        {task.group.contact.country}
                                    </div>

                                    {task.group.contact.phone && (
                                        <strong>
                                            {task.group.contact.phone}
                                        </strong>
                                    )}
                                    {task.group.contact.email && (
                                        <strong>
                                            {task.group.contact.email}
                                        </strong>
                                    )}
                                </>
                            )}
                        </DetailsContent>
                    </SpacedRow>
                )}
                <SpacedRow className="detailRow">
                    <DetailsLabel>{trans("Show On Planner")}</DetailsLabel>{" "}
                    <DetailsContent>
                        {activity.show_on_planner === "hide" ? "No" : "Yes"}
                    </DetailsContent>
                </SpacedRow>
            </Row>
        </DetailWrapper>
    );
};

ActivityDetails.propTypes = {
    filters: PropTypes.array,
    activity: PropTypes.object,
    task: PropTypes.object,
    user: PropTypes.object,
};

export default ActivityDetails;
