import React, { useState } from "react";
import axios from "axios";
import { Modal, Row, Column, Button } from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import FolderBrowser from "./FolderBrowser";

const MoveAssetModal = ({ asset, visible, onClose, setRefreshSearch }) => {
    const intl = useIntl();
    const [folder, setFolder] = useState(null);

    const moveAssetToFolder = () => {
        console.log(asset, folder);
        axios
            .put(`api/v1/assets/${asset.uuid}/folder`, {
                folder_uuid: folder ? folder.uuid : ""
            })
            .then(response => {
                setRefreshSearch(true);
                onClose();
            })
            .catch(() => {});
    };
    return (
        <Modal visible={visible} onClose={onClose} zIndex={0} maxWidth="90%">
            <Row>
                <Column>
                    <h3>{trans("Move Asset")}</h3>
                </Column>
            </Row>
            <Row>
                <Column>
                    <FolderBrowser
                        folderUuid={folder ? folder.uuid : ""}
                        showFolderBrowser={true}
                        onFolderUpdated={setFolder}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Button onClick={moveAssetToFolder}>
                        {folder && folder.name
                            ? trans('Move to  "{folderName}"', {
                                  folderName: folder.name
                              })
                            : trans("Move to the top level")}
                    </Button>
                </Column>
            </Row>
        </Modal>
    );
};

export default MoveAssetModal;
