import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    SimpleDropzone,
    Button,
    IconTextButton,
    Modal,
    useAddToast,
    Loading,
    Image as CortexImage,
} from "@cortexglobal/rla-components";

import { UserAssetImageBrowser } from "./UserAssetImageBrowser";
import { AssetImageResult } from "./AssetImageResult";
import { AllAssetImageBrowser } from "./AllAssetImageBrowser";
import { Uploader } from "./Uploader";

const ImagePlaceholderWrapper = styled.div`
    display: inline-block;
    position: relative;
    cursor: pointer;
    min-height: 120px;
    min-width: 120px;
    border: 1px dashed #ccc;
`;
const ImageEditIcon = styled.aside`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.9);
    pointer-events: none;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`;

const AssetImageBrowser = ({
    id,
    name,
    value,
    onChange,
    asset,
    editingUserAsset,
    allowUpload,
    width,
    height,
}) => {
    const [deletedFile, setDeletedFile] = useState("");
    const [showImageBrowser, setShowImageBrowser] = useState(false);
    const [assetFiles, setAssetFiles] = useState({ loaded: false, data: [] });
    const addToast = useAddToast();
    const intl = useIntl();

    //Clean up missing width and height restraints
    width = isNaN(width) ? 0 : width;
    height = isNaN(height) ? 0 : height;

    //When editing a user asset the original/base asset is stored in the asset key
    const baseAsset = editingUserAsset ? asset.asset : asset;

    // console.log("asset", baseAsset);

    const getAssetFiles = () => {
        return axios
            .get(
                `/api/v1/asset-file/${baseAsset.uuid}/${name}?width=${width}&height=${height}`
            )
            .then(({ data }) => {
                setAssetFiles({ loaded: true, data: data.data });
            });
    };

    useEffect(() => {
        getAssetFiles();
    }, [name]);

    const onDrop = (
        acceptedFiles,
        assetFileUrl = "asset-file",
        callBack = getAssetFiles
    ) => {
        const file = acceptedFiles[0];

        const image = new Image();
        image.addEventListener("load", () => {
            // console.log(`${image.width}x${image.height}`);

            //If the image is too small display a message and abort the upload
            if (image.width < width || image.height < height) {
                addToast({
                    type: "alert",
                    content: trans(
                        "Images need to be more than {width}px wide and {height}px high",
                        { width, height }
                    ),
                    showFor: 5000,
                });
                return;
            } else {
                const fd = new FormData();
                fd.append("file", acceptedFiles[0]);
                fd.append("type", "image");
                fd.append("asset_input_name", name);
                axios
                    .post(`/api/v1/${assetFileUrl}/${asset.uuid}`, fd)
                    .then(({ data }) => {
                        // console.log(data);

                        onChange({ name, value: data });

                        callBack();
                    })
                    .catch((e) => {
                        const message = e?.response?.data?.errors?.file
                            ? e.response.data.errors.file
                            : trans("An error occurred uploading your file");
                        addToast({
                            type: "alert",
                            content: message,
                            showFor: 5000,
                        });
                    });
            }
        });
        image.src = URL.createObjectURL(file);
    };

    const deleteAssetFile = (assetFileUuid) => {
        if (editingUserAsset) {
            return false;
        }

        confirm(
            intl.formatMessage({
                id: "Are you sure you want to delete this file, other assets may be making use of it?",
            })
        ) &&
            axios.delete(`/api/v1/asset-file/${assetFileUuid}`).then(() => {
                getAssetFiles();
                setDeletedFile(assetFileUuid);
            });
        return false;
    };

    const detachAssetFile = (assetFileUuid) => {
        if (editingUserAsset) {
            return false;
        }
        axios
            .delete(`/api/v1/asset-file/${asset.uuid}/detach/${assetFileUuid}`)
            .then(() => {
                getAssetFiles();
            });
    };
    const attachAssetFile = (event) => {
        return axios
            .put(`/api/v1/asset-file/${asset.uuid}/attach/${event.uuid}`, {
                asset_input_name: name,
            })
            .then(({ data }) => {
                onChange(event);
                getAssetFiles();
            });
    };
    const selectImage = (event) => {
        onChange(event);
        setShowImageBrowser(false);
    };
    return (
        <React.Fragment>
            <div style={{ width: "100%" }}>
                <ImagePlaceholderWrapper
                    onClick={() => {
                        setShowImageBrowser(true);
                    }}
                >
                    <ImageEditIcon>
                        <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </ImageEditIcon>
                    <CortexImage
                        fallback={null}
                        style={{
                            cursor: "pointer",
                            marginTop: "1rem",
                            display: "block",
                            maxWidth: "100%",
                            maxHeight: "150px",
                        }}
                        src={value}
                    />
                </ImagePlaceholderWrapper>
                {/* <Button onClick={() => setShowImageBrowser(true)} expanded>
                    {trans("Show image settings")}
                </Button> */}
            </div>
            <Modal
                visible={showImageBrowser}
                onClose={() => setShowImageBrowser(false)}
                maxWidth="90%"
            >
                <h2>
                    {editingUserAsset
                        ? trans("Choose a provided image")
                        : trans("Manage Images")}
                </h2>

                <Row equaliseChildHeight={true}>
                    {!editingUserAsset && (
                        <Column medium={3}>
                            <Uploader
                                onDrop={(acceptedFiles) =>
                                    onDrop(
                                        acceptedFiles,
                                        "asset-file",
                                        getAssetFiles
                                    )
                                }
                            >
                                <div>
                                    <FontAwesomeIcon icon={faPlus} size="4x" />
                                </div>
                                <p>
                                    {width > 0 || height > 0
                                        ? trans(
                                              // "Upload an image more than {width}px wide and {height}px high",
                                              // { width, height }
                                              "Upload an image"
                                          )
                                        : trans("Upload an image")}
                                </p>
                            </Uploader>
                        </Column>
                    )}
                    {assetFiles.loaded ? (
                        assetFiles.data.length === 0 ? (
                            <div>{trans("No selected files available")}</div>
                        ) : (
                            assetFiles.data.map((file) => {
                                if (!file.file) {
                                    return;
                                }
                                return (
                                    <AssetImageResult
                                        selected={file.file === value}
                                        key={file.uuid}
                                        file={file}
                                        onChange={selectImage}
                                        onRemove={
                                            editingUserAsset ||
                                            file.file === value
                                                ? null
                                                : detachAssetFile
                                        }
                                    />
                                );
                            })
                        )
                    ) : (
                        <Loading />
                    )}
                </Row>
                {editingUserAsset && allowUpload && (
                    <UserAssetImageBrowser
                        name={name}
                        value={value}
                        onChange={selectImage}
                        onDrop={onDrop}
                        asset={asset}
                        width={width}
                        height={height}
                    />
                )}
                {!editingUserAsset && (
                    <>
                        <Row>
                            <h2>
                                {trans(
                                    "Choose one of the other available images"
                                )}
                            </h2>
                        </Row>
                        <AllAssetImageBrowser
                            name={name}
                            value={value}
                            attachAssetFile={attachAssetFile}
                            deleteAssetFile={deleteAssetFile}
                            onDrop={onDrop}
                            asset={asset}
                            width={width}
                            height={height}
                            selectedAssetFilesCount={assetFiles.data.length}
                            deletedFile={deletedFile}
                        />
                    </>
                )}
                <Row>
                    <Column>
                        <Button
                            style={{ float: "right" }}
                            onClick={() => setShowImageBrowser(false)}
                        >
                            {trans("Save and Close")}
                        </Button>
                    </Column>
                </Row>
            </Modal>
        </React.Fragment>
    );
};

export default AssetImageBrowser;
