import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import merge from "lodash/merge";
import {
    ThemeProvider,
    Theme as CortexTheme,
    generateTheme,
    defaultThemeOptions,
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";

import globalCss from "./globalCss";
import styled from "styled-components";
import HeadContent from "./HeadContent";

import RenderPages from "./RenderPages";
import * as standardComponents from "./layouts/index";

import { MicrositeProps } from "./interfaces/microsite";
import { MicrositeProvider } from "./MicrositeContext";
import { makeProps } from "./micrositeFunctions";
import { AdminHeader } from "./components/AdminHeader";
import { Footer } from "./components/Footer";
import { useNavigation } from "./hooks/useNavigation";
import { useLocation } from "react-router-dom";
import { Header } from "./components/Header";
import { Navigation } from "./components/Navigation";

const MicrositeContainer = styled.div<ContainerProps>`
    background: ${(props) => {
        if (props.backgroundImage) {
            return `url(${props.backgroundImage}) no-repeat fixed center`;
        } else if (props.backgroundColor) {
            return props.backgroundColor;
        } else {
            return "white";
        }
    }};
    background-size: cover;
    color: ${(props) => (props.textColor ? props.textColor : "#000")};
    width: ${(props) => props.slideWidth};
    height: ${(props) => props.slideHeight};
    font-size: ${(props) => props.fontSize};
    margin: 0 auto;
    position: relative;
    overflow: hidden;
`;

const Subtitle = styled.h2`
    color: white;
`;

interface ContainerProps {
    slideWidth: string;
    slideHeight: string;
    fontSize: string;
    backgroundImage: string;
    backgroundColor: string;
    textColor: string;
}

const Microsite: React.FunctionComponent<MicrositeProps> = ({
    components,
    config,
    basePath,
    baseUrl,
    showPage = "",
    editPath,
    showAdminBar = false,
    editing = false,
    preview = false,
}) => {
    const globalSettings = makeProps(config.globalSettings);
    const globalTheming = makeProps(config.globalTheming);
    const footerTheming = makeProps(config.footerTheming);
    const headerTheming = makeProps(config.headerTheming);
    // const navigationSettings = makeProps(config.navigation);
    const configWithSortedPages = {
        ...config,
        pages: Object.entries(config.pages).sort((a, b) => {
            if (a[1].order < b[1].order) {
                return -1;
            }
            if (a[1].order > b[1].order) {
                return 1;
            }
            return 0;
        }),
    };

    const [fullscreen, setFullscreen] = useState(false);
    const [menuShowing, setMenuShowing] = useState(false);

    const [footnotesShowing, setFootnotesShowing] = useState(false);
    const [hasFootnotes, setHasFootnotes] = useState(false);

    const [additionalInformationShowing, setAdditionalInformationShowing] =
        useState(false);
    const [hasAdditionalInformation, setHasAdditionalInformation] =
        useState(false);

    const [prescribingInfoShowing, setPrescribingInfoShowing] = useState(false);

    const { links, state, dispatch } = useNavigation(
        configWithSortedPages,
        baseUrl,
        showPage,
        editing,
        footnotesShowing
    );

    const fullLocationUrl = useLocation().pathname;
    const pagePath = fullLocationUrl.slice(
        fullLocationUrl.lastIndexOf("/") + 1
    );
    // console.log(config.pages);
    const currentPageConfig = config.pages[pagePath]
        ? config.pages[pagePath]
        : Object.entries(config.pages).filter(
              ([page, settings]) => settings.order === 0
          )[0][0];

    const printing = useQueryString().get("printing");

    const getSlideSize = () => {
        const screenWidth = document.body.clientWidth;
        const screenHeight = document.body.clientHeight;

        if (printing)
            return {
                width: "25.33cm",
                height: "19cm",
                fontSize: "17pt",
            };

        if (preview) {
            return {
                width: "100vw",
                height: "75vw",
                fontSize: "2.5vw",
            };
        }

        if (screenWidth * 0.75 > screenHeight) {
            return {
                width: `${(screenHeight / 3) * 4}px`,
                height: `${screenHeight}px`,
                fontSize: `${(screenHeight / 3) * 4 * 0.025}px`,
            };
        } else {
            return {
                width: `${screenWidth}px`,
                height: `${(screenWidth / 4) * 3}px`,
                fontSize: `${screenWidth * 0.025}px`,
            };
        }
    };

    const [slideSize, setSlideSize] = useState(getSlideSize);

    useEffect(() => {
        function handleResize() {
            setSlideSize(getSlideSize);
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    });

    const context = {
        // components: indexComponents(components),
        globalSettings: globalSettings,
        components: { ...standardComponents, ...components },
        links: links,
        config: configWithSortedPages,
        baseUrl: baseUrl,
        basePath: basePath,
        navigationState: state,
        navigationDispatch: dispatch,
        editing,
        fullscreen,
        setFullscreen,
        slideSize: slideSize,
        footnotesShowing,
        setFootnotesShowing,
        hasFootnotes,
        setHasFootnotes,
        additionalInformationShowing,
        setAdditionalInformationShowing,
        hasAdditionalInformation,
        setHasAdditionalInformation,
        prescribingInfoShowing,
        setPrescribingInfoShowing,
        preview,
        globalTheming,
        footerTheming,
        headerTheming,
        // navigationSettings,
        currentPageConfig,
    };
    // console.log(state);
    //Merge the RLA components theme with the one in the config
    const theme = generateTheme(
        {
            colors: config.theme.colors,
            font: globalTheming?.fontFamily
                ? globalTheming.fontFamily
                : config.theme.font,
        },
        config.theme
    );

    console.log({ theme });

    //Add Global CSS
    const GlobalStyle = createGlobalStyle`${globalCss(theme)}`;
    // const GlobalStyle = createGlobalStyle`${theme}`;
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <MicrositeProvider value={context}>
                <MicrositeContainer
                    backgroundColor={globalTheming?.backgroundColor}
                    backgroundImage={globalTheming?.backgroundImage}
                    textColor={globalTheming?.textColor}
                    slideWidth={slideSize.width}
                    slideHeight={slideSize.height}
                    fontSize={slideSize.fontSize}
                >
                    <HeadContent settings={globalSettings} />

                    <Navigation
                        pages={context.config.pages}
                        visible={menuShowing}
                        onClose={() => setMenuShowing(false)}
                    />
                    <AdminHeader
                        editPath={editPath}
                        showAdminBar={showAdminBar}
                    />
                    {!preview && !globalSettings.hideHeader && (
                        <Header
                            // dispatch={dispatch}
                            menuShowing={menuShowing}
                            setMenuShowing={setMenuShowing}
                        />
                    )}
                    <div
                        style={{
                            display: "flex",
                            height: "100%",
                            width: "100%",
                        }}
                        onClick={() => setMenuShowing(false)}
                    >
                        <RenderPages
                            pages={context.config.pages}
                            components={context.components}
                            basePath={context.basePath}
                            globalSettings={globalSettings}
                            globalTheming={globalTheming}
                        />
                    </div>
                    {!(
                        currentPageConfig.component === "References" && printing
                    ) &&
                        !globalSettings.hideFooter && (
                            <Footer
                                dispatch={dispatch}
                                menuShowing={menuShowing}
                                setMenuShowing={setMenuShowing}
                            />
                        )}
                </MicrositeContainer>
            </MicrositeProvider>
        </ThemeProvider>
    );
};

export default Microsite;
/*
    // const spectacleTheme = presentationTheme(cortexTheme);

    // if (!loaded) {
    // return <p>Microsite not loaded</p>;
    // }


    return (
        <React.Fragment>
            <Deck transition={["fade"]} style={{ fontSize: "2em" }}>
                {Object.values(slides).map((slide, key) => {
                    const globalSettings = {};

                    return (
                        <StyledSlide key={key}>
                            {renderSlide(slide, globalSettings, key)}
                        </StyledSlide>
                    );
                })}
            </Deck>
        </React.Fragment>
    );
	 */

/*
    useEffect(() => {
        if (loadFromOrder === true) {
            const {
                loadPresentationOrder,
                order: { content, reusable_components }
            } = props;

            loadPresentationOrder(content, reusable_components);
        } else {
            // Check this
            const {
                loadPresentationLocalStorage,
                presentation: { loaded: presentationLoaded }
            } = props;
            const presentationId = props.match.params.presentationId;

            if (
                presentationLoaded !== undefined &&
                presentationLoaded === false &&
                loadPresentationLocalStorage !== undefined &&
                loadPresentationLocalStorage !== null &&
                loadPresentationLocalStorage.constructor === Function
            ) {
                loadPresentationLocalStorage(presentationId);
            }

            //console.error("Invalid method of loading presentation provided");
        }
    }, [order]);

    if (!loaded === true) {
        return <p>Presentation Asset Not Loaded</p>;
    }

    const reusable_props = data.reusable_component_props;

    if (
        Object.keys(reusable_props).length === 0 ||
        Object.keys(data.data).length === 0
    ) {
        return <p>Presentation format is invalid</p>;
    }
	 */

// Load presentation from localStorage OR from cortex-dam/orders
//
// At present loading from order is intended for primarily for editing
// Whilst load presentation is intended for standalone offline usage
/*
        if (loadFromOrder === true) {

            const {
                loadPresentationOrder,
                order: { content, reusable_components }
            } = props;

            loadPresentationOrder(content, reusable_components);
        } else {
            const {
                loadPresentationLocalStorage,
                presentation: { loaded: presentationLoaded }
            } = props;
            const presentationId = props.match.params.presentationId;

            if (presentationLoaded == false) {
                loadPresentationLocalStorage(presentationId);
            }
        }
		  */

// export default withRouter(Microsite);
