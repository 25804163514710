import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    FormikInput,
    Row,
    Column,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
});
export type GroupContainer = ReturnType<typeof schema.validateSync>;

export const GroupContainerForm = ({ item, submitForm, canFullGroupAdmin }) => {
    const handleSubmit = (
        values: GroupContainer,
        { setErrors, setSubmitting, ...rest }
    ) => {
        values["groupable_type"] = "GroupContainer";
        values["groupable_uuid"] = item.entity.uuid;

        submitForm(values, setSubmitting, "group-container").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                name: item.name,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        {canFullGroupAdmin && (
                            <>
                                <FormRow name="id" label={trans("ID")}>
                                    <FormikInput
                                        disabled
                                        name="id"
                                        value={item.id}
                                    />{" "}
                                </FormRow>
                                <FormRow name="uuid" label={trans("UUID")}>
                                    <FormikInput
                                        disabled
                                        name="uuid"
                                        value={item.uuid}
                                    />
                                </FormRow>
                            </>
                        )}
                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Optional")}
                            error={errors.name}
                        >
                            <FormikInput name="name" value={values.name} />{" "}
                        </FormRow>
                        <Row>
                            <Column medium={6}>&nbsp;</Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default GroupContainerForm;
