import React, { useState, useEffect } from "react";

import GroupBrandingForm, {
    emptyBranding
} from "../../forms/GroupBrandingForm";
export const GroupBranding = ({ item, submitForm }) => {
    //TODO - Switch between a view and edit mode
    const branding = item.data.branding ? item.data.branding : emptyBranding;
    return <GroupBrandingForm item={branding} submitForm={submitForm} />;
};

export default GroupBranding;
