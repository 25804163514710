import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
    faEnvelope,
    faEnvelopeOpen,
    faTrash,
    faSync,
} from "@fortawesome/free-solid-svg-icons";
import {
    faSquare,
    faCheckSquare,
    faCircleDown,
} from "@fortawesome/free-regular-svg-icons";

import Message from "../objects/Message";

import {
    Loading,
    Error,
    IconTextButton,
    Pagination,
    StyledCheckbox,
    Row,
    Column,
    useAddToast,
} from "@cortexglobal/rla-components";
import { transparentize } from "@cortexglobal/cortex-utilities";
import MessagesContext from "../MessagesContext";
import { trans } from "@cortexglobal/rla-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = styled(IconTextButton)`
    text-decoration: none;
    padding: 0;
    margin: 0;
`;

const MessageCheckbox = styled(StyledCheckbox)`
    padding: 0;
    margin-right: 0.8rem;
`;

const StyledMessages = styled.div`
    padding-bottom: 10px;

    & > .message-container {
        border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    }

    & > .message-container ~ .message-container {
        border-top: 0;
    }

    .message-container {
        position: relative;
        padding: 10px 20px 20px 8px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
        background-color: ${({ theme }) => theme.colors.gray100};
        border-left: 5px solid ${({ theme }) => theme.colors.gray300};

        &.selected,
        &:hover {
            background-color: ${({ theme }) =>
                transparentize(theme.colors.primary, 0.9)};
        }

        &.unread {
            border-left: 5px solid ${({ theme }) => theme.colors.primary};
            font-weight: bold;
            background-color: transparent;
            &:hover {
                background-color: ${({ theme }) =>
                    transparentize(theme.colors.primary, 0.9)};
            }
        }

        .received {
            color: ${({ theme }) => theme.colors.gray700};
            float: right;
            margin-bottom: 5px;
            font-style: italic;
            font-size: 0.8rem;
        }

        .senderName {
            margin-bottom: 0.5rem;
        }

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .message-selector {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .message-summary {
                cursor: pointer;
                word-wrap: break-word;
            }
        }
    }

    .pagination {
        margin: 10px 0 0;
    }
`;

const ControlPanel = styled.div``;

const MessageList = (props) => {
    const { deletedMessageUuid, setBoxUnreadTotal, unreadTotal } = props;

    const [loadingState, setLoadingState] = useState("loading");
    const [pagedMessageCache, setPagedMessageCache] = useState({}); // cache of messages sent back from API
    const [messages, setMessages] = useState([]); // the messages to be displayed - move to context?
    const [pagination, setPagination] = useState({});
    const [pageKey, setPageKey] = useState(0);
    const [selectedMessages, setSelectedMessages] = useState([]);

    const { selectedMessage, boxTotal, setBoxTotal, setSelectedMessage } =
        React.useContext(MessagesContext);

    const addToast = useAddToast();

    // const dispatch = useDispatch();

    useEffect(() => {
        if (props.triggerRefresh) {
            refresh();
        } else {
            getMessages(1);
        }
    }, [props.triggerRefresh]);

    const bulkMarkAsUnread = () => {
        const total = selectedMessages.length;

        if (!total) {
            return;
        }

        const messageUuids = selectedMessages.map(
            (currentMessage) => currentMessage.uuid
        );

        const params = {
            message_uuids: messageUuids,
        };

        setMessages(
            messages.map((message) => {
                if (messageUuids.includes(message.uuid)) {
                    message.markAsUnread();
                }

                return message;
            })
        );
    };

    const bulkMarkAsRead = () => {
        const total = selectedMessages.length;

        if (!total) {
            return;
        }

        const messageUuids = selectedMessages.map(
            (currentMessage) => currentMessage.uuid
        );

        setMessages(
            messages.map((message) => {
                if (messageUuids.includes(message.uuid)) {
                    message.markAsRead();

                    // dispatch({
                    //     type: "DECREMENT_UNREAD_COUNTER",
                    // });
                }

                return message;
            })
        );
    };

    const bulkDestroy = () => {
        const total = selectedMessages.length;

        if (!total) {
            return;
        }

        const messageUuids = selectedMessages.map(
            (currentMessage) => currentMessage.uuid
        );

        setMessages(
            messages
                .map((message) => {
                    if (messageUuids.includes(message.uuid)) {
                        message.destroy();
                    }

                    return message;
                })
                .filter((message) => !message.destroyed)
        );
    };

    const refresh = () => {
        setSelectedMessage(null);
        selectNone();
        setPagination({});
        setPagedMessageCache({});
        setMessages({});
        setPageKey(0);

        getMessages(1, true);
    };

    const changePage = (pageKey) => {
        setPageKey(pageKey);
        getMessages(pageKey + 1);
    };

    const selectMessage = (selectedMessage) => {
        let messages = selectedMessages;

        if (messages.includes(selectedMessage)) {
            messages = messages.filter((currentMessage) => {
                return currentMessage.uuid !== selectedMessage.uuid;
            });
        } else {
            messages.push(selectedMessage);
        }

        setSelectedMessages([...messages]);
    };

    const selectAll = () => {
        setSelectedMessages([...selectedMessages, ...messages]);
    };

    const selectNone = () => {
        setSelectedMessages([]);
    };

    const getMessages = (page, refresh) => {
        if (refresh !== true && pagedMessageCache[page] !== undefined) {
            setMessages(pagedMessageCache[page]);

            return;
        }

        setLoadingState("loading");

        axios
            .get(`/api/v1/communication/${props.box}`, {
                params: {
                    page: page,
                },
            })
            .then(({ data }) => {
                const messages = data.data
                    .map((message) => {
                        return new Message({
                            ...message,
                            box: props.box,
                        });
                    })
                    .sort((a, b) => {
                        // sort by received date
                        return (
                            b.received_at.format("YYYYMMDD") -
                            a.received_at.format("YYYYMMDD")
                        );
                    });

                setPagination(data.meta);

                setMessages(messages);

                const serverUnreadTotal = data.meta.totalUnreadMessages || 0;
                setBoxUnreadTotal(props.box, serverUnreadTotal);

                setLoadingState("loaded");

                let pagedCache = refresh === true ? {} : pagedMessageCache;
                pagedCache[page] = messages;
                setPagedMessageCache(pagedCache);
            })
            .catch((e) => {
                setLoadingState("error");
            });

        return;
    };

    const readMessage = (message) => {
        setSelectedMessage(message);

        if (props.affectReadStatus !== false && !message.read) {
            setMessages(
                messages.map((n) => {
                    if (n.uuid === message.uuid) {
                        n.markAsRead();

                        setBoxUnreadTotal(
                            props.box,
                            unreadTotal > 0 ? unreadTotal - 1 : 0
                        );

                        // dispatch({
                        //     type: "DECREMENT_UNREAD_COUNTER",
                        // });
                    }

                    return n;
                })
            );
        }
    };

    const renderContent = (loadingState) => {
        switch (loadingState) {
            case "loading":
                return <Loading />;

            case "loaded":
                return (
                    <>
                        {messages.map((message, key) => {
                            const {
                                uuid,
                                subject,
                                unread,
                                sender,
                                sent_to,
                                priority,
                            } = message;

                            const user =
                                props.box === "inbox" ? sender.name : sent_to;

                            if (uuid === deletedMessageUuid) {
                                return <></>;
                            }

                            const sent_at =
                                message.sent_at.format("Do MMMM YYYY HH:mm");

                            const markAsUnread =
                                !!props.affectReadStatus && unread;

                            return (
                                <div
                                    onClick={() => readMessage(message)}
                                    className={
                                        "message-container" +
                                        (markAsUnread ? " unread" : "") +
                                        (selectedMessage instanceof Message &&
                                        selectedMessage.uuid === message.uuid
                                            ? " selected"
                                            : "")
                                    }
                                    key={uuid}
                                >
                                    <Row className="content">
                                        <Column
                                            medium={1}
                                            className="message-selector"
                                        >
                                            <MessageCheckbox
                                                id={"message-" + uuid}
                                                inline={true}
                                                name="selected-messages"
                                                checked={selectedMessages.includes(
                                                    message
                                                )}
                                                onChange={(event) => {
                                                    event.stopPropagation();
                                                    selectMessage(message);
                                                }}
                                            />
                                        </Column>

                                        <Column
                                            medium={11}
                                            className="message-summary"
                                        >
                                            <div className="received">
                                                {sent_at}
                                            </div>
                                            <div className="senderName">
                                                {user !== null
                                                    ? user
                                                    : trans("System")}
                                            </div>
                                            <div>
                                                <div>
                                                    <>
                                                        {priority ===
                                                            "high" && (
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    color: "#D54644",
                                                                    fontSize:
                                                                        "1.1rem",
                                                                    marginRight:
                                                                        "5px",
                                                                }}
                                                                icon="exclamation-circle"
                                                            />
                                                        )}
                                                        {priority === "low" && (
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    color: "#0065B1",
                                                                    fontSize:
                                                                        "1.1rem",
                                                                    marginRight:
                                                                        "5px",
                                                                }}
                                                                icon={
                                                                    faCircleDown
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                    {subject}
                                                </div>
                                            </div>
                                        </Column>
                                    </Row>
                                </div>
                            );
                        })}
                    </>
                );

            default:
                return (
                    <Error>
                        {trans(
                            "Notifications could not be loaded, please try again later."
                        )}
                    </Error>
                );
        }
    };

    return (
        <StyledMessages>
            <Row style={{ marginBottom: "0.4rem" }}>
                <Column medium={4}>
                    <IconButton
                        style={{ padding: "0" }}
                        icon={faCheckSquare}
                        showCircle={false}
                        expanded={false}
                        onClick={() => selectAll()}
                    >
                        {trans("Select All")}
                    </IconButton>
                </Column>
                <Column medium={4}>
                    {selectedMessages.length > 0 ? (
                        <IconButton
                            style={{ padding: "0" }}
                            icon={faSquare}
                            showCircle={false}
                            expanded={false}
                            onClick={() => selectNone()}
                        >
                            {trans("None")}
                        </IconButton>
                    ) : (
                        <> </>
                    )}
                </Column>
                <Column medium={4}>
                    <IconButton
                        style={{ padding: "0" }}
                        icon={faSync}
                        showCircle={false}
                        expanded={false}
                        onClick={() => refresh()}
                    >
                        {trans("Refresh")}
                    </IconButton>
                </Column>
            </Row>

            {selectedMessages.length > 0 && (
                <Row>
                    <Column medium={4}>
                        <IconButton
                            style={{ padding: "0" }}
                            icon={faEnvelope}
                            showCircle={false}
                            expanded={false}
                            onClick={() => bulkMarkAsUnread()}
                        >
                            {trans("Unread")}
                        </IconButton>
                    </Column>
                    <Column medium={4}>
                        <IconButton
                            style={{ padding: "0" }}
                            icon={faEnvelopeOpen}
                            showCircle={false}
                            expanded={false}
                            onClick={() => bulkMarkAsRead()}
                        >
                            {trans("Read")}
                        </IconButton>
                    </Column>
                    <Column medium={4}>
                        <IconButton
                            style={{ padding: "0" }}
                            icon={faTrash}
                            showCircle={false}
                            expanded={false}
                            onClick={() => bulkDestroy()}
                        >
                            {trans("Delete")}
                        </IconButton>
                    </Column>
                </Row>
            )}

            {renderContent(loadingState)}

            {pagination.last_page !== undefined && (
                <div style={{ margin: "1rem auto", textAlign: "center" }}>
                    <Pagination
                        // pageRangeDisplayed={3}
                        currentPage={pageKey}
                        pageCount={pagination.last_page}
                        onPageChange={(e) => changePage(e.selected)}
                    />
                </div>
            )}
        </StyledMessages>
    );
};

export default MessageList;
