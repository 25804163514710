import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { saveAs } from "file-saver";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
    Row,
    Column,
    PageTitle,
    Button,
    Error,
    Table,
    SelectField,
    Pagination,
    Loading,
    useAddToast,
    useToasts,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

import AssetActivityPanel from "./AssetActivityPanel";

const AssetActivity = styled.div`
    padding: 2rem;
`;

const AssetActivityFilters = styled.div`
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    div:not(:last-child) {
        margin-right: 1rem;
    }
`;

const SelectWrapper = styled.div`
    flex-basis: 0;
    flex-grow: 1;
`;

const AssetUsageListing = ({ asset }) => {
    const assetUuid = asset.uuid;
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();
    const intl = useIntl();
    const [state, setState] = useState({
        loaded: true,
        data: [],
        pagination: {},
    });
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedAction, setSelectedAction] = useState("");
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);

    const getAssetUsage = (uuid, page = 1) => {
        setState({
            loaded: false,
        });

        axios
            .get(`/api/v1/assets-usage/${uuid}`, {
                params: {
                    page: page,
                    per_page: 10,
                    action: selectedAction,
                    group: selectedGroup,
                    user: selectedUser,
                },
            })
            .then(({ data }) => {
                setState({
                    loaded: true,
                    data: data.data === "undefined" ? [] : data.data,
                    pagination: data.meta,
                });
            });
    };
    const downloadAssetUsage = (uuid) => {
        addToast({
            uuid: "downloadToast",
            type: "loader",
            content: trans("Asset usage is downloading"),
        });
        axios
            .get(`/api/v1/assets-usage/${uuid}`, {
                params: {
                    action: selectedAction,
                    group: selectedGroup,
                    user: selectedUser,
                    download: true,
                },
                responseType: "blob",
                timeout: 30000,
            })
            .then((response) => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });
                saveAs(
                    new Blob([response.data]),
                    `${asset.name ? asset.name : "asset"}.xlsx`
                );
            })
            .catch((e) => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred downloading the asset usage");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            });
    };

    const getUsersAndGroups = (uuid) => {
        axios
            .get(`/api/v1/assets-usage/${uuid}/users`, {
                params: {
                    action: selectedAction,
                    group: selectedGroup,
                    user: selectedUser,
                },
            })
            .then(({ data }) => {
                setUsers(data.data);
            });
        axios
            .get(`/api/v1/assets-usage/${uuid}/groups`, {
                params: {
                    action: selectedAction,
                    group: selectedGroup,
                    user: selectedUser,
                },
            })
            .then(({ data }) => {
                setGroups(data.data);
            });
    };
    useEffect(() => {
        getAssetUsage(assetUuid);
        getUsersAndGroups(assetUuid);
    }, [assetUuid, selectedAction, selectedUser, selectedGroup]);

    return (
        <React.Fragment>
            <AssetActivity>
                <AssetActivityFilters>
                    <SelectWrapper>
                        <SelectField
                            label={trans("Action")}
                            inlineLabel={false}
                            name="action"
                            value={selectedAction}
                            emptyOption={intl.formatMessage({ id: "All" })}
                            options={[
                                {
                                    text: intl.formatMessage({ id: "Viewed" }),
                                    value: "viewed",
                                },
                                {
                                    text: intl.formatMessage({
                                        id: "Downloaded",
                                    }),
                                    value: "downloaded",
                                },
                                {
                                    text: intl.formatMessage({
                                        id: "Created",
                                    }),
                                    value: "created",
                                },
                                {
                                    text: intl.formatMessage({
                                        id: "Status Changed",
                                    }),
                                    value: "status-changed",
                                },
                                {
                                    text: intl.formatMessage({
                                        id: "Updated",
                                    }),
                                    value: "updated",
                                },
                            ]}
                            onChange={(selected) => {
                                setSelectedAction(selected.value);
                            }}
                        />
                    </SelectWrapper>
                    <SelectWrapper>
                        <SelectField
                            label={trans("Group")}
                            inlineLabel={false}
                            name="group"
                            value={selectedGroup}
                            emptyOption={intl.formatMessage({ id: "All" })}
                            options={groups}
                            onChange={(selected) => {
                                setSelectedGroup(selected.value);
                            }}
                        />
                    </SelectWrapper>
                    <SelectWrapper>
                        <SelectField
                            label={trans("User")}
                            inlineLabel={false}
                            name="user"
                            value={selectedUser}
                            emptyOption={intl.formatMessage({ id: "All" })}
                            options={users}
                            onChange={(selected) => {
                                setSelectedUser(selected.value);
                            }}
                        />
                    </SelectWrapper>
                    <Button
                        onClick={() => downloadAssetUsage(assetUuid)}
                        title={intl.formatMessage({ id: "Download" })}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </AssetActivityFilters>

                {!state.loaded ? (
                    <div
                        style={{
                            width: "50px",
                            height: "50px",
                            margin: "0 auto",
                        }}
                    >
                        <Loading />
                    </div>
                ) : (
                    <>
                        {state.data.length === 0 && (
                            <p>{trans("No asset activity found")}</p>
                        )}
                        <Table zebraStripe={true}>
                            <tbody>
                                {state.data.map((usage) => {
                                    return (
                                        <AssetActivityPanel
                                            key={
                                                usage?.user?.uuid +
                                                usage.action +
                                                usage.created_at
                                            }
                                            usage={usage}
                                            pagination={state.pagination}
                                        />
                                    );
                                })}
                            </tbody>
                        </Table>

                        {state.pagination.last_page > 1 && (
                            <Pagination
                                showSummary={false}
                                currentPage={state.pagination.current_page - 1}
                                perPage={state.pagination.per_page}
                                pageCount={state.pagination.last_page}
                                onPageChange={({ selected }) =>
                                    getAssetUsage(assetUuid, selected + 1)
                                }
                            />
                        )}
                    </>
                )}
            </AssetActivity>
        </React.Fragment>
    );
};

export default AssetUsageListing;
