import React from "react";

import { Row, Column, DraftJSOutput } from "@cortexglobal/rla-components";

const WysiwygOutput = ({ panelId, layout, content }) => {
    return (
        <Row>
            <Column>
                <DraftJSOutput>{content}</DraftJSOutput>
            </Column>
        </Row>
    );
};

export default WysiwygOutput;
