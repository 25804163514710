import React from "react";
import styled, { css } from "styled-components";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { isJSON } from "@cortexglobal/cortex-utilities/lib/helpers";

export const baseWysiwygStyle = css`
    span[style] {
        background-color: transparent !important;
    }
    font-family: ${({ theme }) => theme.body.fontFamily};
    font-size: ${({ theme }) => theme.content.styles.body.fontSize};
    h1 {
        font-family: ${({ theme }) => theme.content.styles.h1.fontFamily};
        font-size: ${({ theme }) => theme.content.styles.h1.fontSize};
    }
    h2 {
        font-family: ${({ theme }) => theme.content.styles.h2.fontFamily};
        font-size: ${({ theme }) => theme.content.styles.h2.fontSize};
    }
    h3 {
        font-family: ${({ theme }) => theme.content.styles.h3.fontFamily};
        font-size: ${({ theme }) => theme.content.styles.h3.fontSize};
    }
    h4 {
        font-family: ${({ theme }) => theme.content.styles.h4.fontFamily};
        font-size: ${({ theme }) => theme.content.styles.h4.fontSize};
    }
    h5 {
        font-family: ${({ theme }) => theme.content.styles.h5.fontFamily};
        font-size: ${({ theme }) => theme.content.styles.h5.fontSize};
    }
    h6 {
        font-family: ${({ theme }) => theme.content.styles.h6.fontFamily};
        font-size: ${({ theme }) => theme.content.styles.h6.fontSize};
    }
    .public-DraftStyleDefault-block:first-of-type {
        margin-top: 0;
    }
`;

const EditorWrapper = styled.div`
    .rdw-editor-main {
        ${baseWysiwygStyle};
    }
`;

export const PlainTextOutput = (value) => {
    return JSON.parse(value)
        .blocks.map((block) => (!block.text.trim() && "\n") || block.text)
        .join("\n");
};

export const DraftJSOutput = ({ children, ...rest }) => {
    if (!isJSON(children)) {
        return children;
    }
    const parsedChildren = JSON.parse(children);

    if (!parsedChildren) {
        //TODO: could we pass in an empty editor state?  Not sure
        //if it'd add anything?
        /* 
        {
            entityMap: {},
            blocks: [
                {
                text: value.toString(),
                key: name,
                type: 'unstyled',
                depth: 0,
                entityRanges: [],
                inlineStyleRanges: [],
                },
            ],
        }
        */
        return null;
    }

    const storedState = EditorState.createWithContent(
        convertFromRaw(parsedChildren)
    );
    return (
        <EditorWrapper className="readonly-editor" {...rest}>
            <Editor
                editorState={storedState}
                readOnly={true}
                toolbarHidden={true}
            />
        </EditorWrapper>
    );
};

export default DraftJSOutput;
