import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
    Row,
    Column,
    LoadingIconSmall,
    useAddToast,
    Error
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { useQueryString } from "@cortexglobal/cortex-utilities";

const CreateUserAsset = ({
    assetsPath,
    assetUuid,
    selectUserAsset,
    setRefreshSearch
}) => {
    const [error, setError] = useState("");
    const addToast = useAddToast();

    const history = useHistory();
    const activityUuid = useQueryString().get("activityUuid");
    const taskUuid = useQueryString().get("taskUuid");
    useEffect(() => {
        // if (userAsset === null) {
        // userAssetDispatch({
        //     type: LOADING_DATA
        // });
        let queryString = "";
        if (activityUuid) {
            queryString += `?activityUuid=${activityUuid}&taskUuid=${taskUuid}`;
        }
        axios
            .get(`/api/v1/user-assets/create/${assetUuid}`)
            .then(({ data }) => {
                const userAsset = data.data;
                selectUserAsset(userAsset);
                setRefreshSearch(true);
                history.push(
                    `${assetsPath}/${userAsset.uuid}/edit${queryString}`
                );
            })
            .catch(e => {
                const message =
                    e.response && e.response.data
                        ? e.response.data.message
                        : trans("An error has occurred");
                setError(message);
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000
                });
            });

        // }
    }, [assetUuid]);

    if (error !== "") {
        return <Error>{error}</Error>;
    }

    return (
        <div style={{ width: "100%", padding: "50px" }}>
            <Row>
                <Column style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <h2 style={{
                        margin: "0 1rem 0 0"
                    }}>
                        {trans("Generating media")}
                    </h2>

                    <LoadingIconSmall align="center" type={"bars"} />
                </Column>
            </Row>
        </div>
    );
};

export default CreateUserAsset;
