import React, { useEffect, useState } from "react";
import axios from "axios";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Button,
    IconTextButton,
    Column,
    FormLabel,
    Loading,
    Row,
    StyledCheckbox,
    StyledSelectSimple,
    SubmitButton,
    Table,
    useAddToast,
} from "@cortexglobal/rla-components";
import { FormattedCurrency } from "@cortexglobal/rla-intl";

const PlanApprovalCreateForm = ({ user, onCancel, onComplete }) => {
    const intl = useIntl();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(false);
    const [loadingTotals, setLoadingTotals] = useState(true);
    const [periodTotals, setPeriodTotals] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState("");
    const [confirmed, setConfirmed] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    useEffect(() => {
        getPeriodTotals();
    }, []);

    const submitForApproval = () => {
        setLoading(true);

        const { period, year } = periodTotals[selectedPeriod];

        axios
            .post(`/api/v1/planner/plan/my-plans`, {
                year,
                period,
                confirmed,
            })
            .then(({ data }) => {
                onComplete(data.data);
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;
                    if (status === 422 && data.message) {
                        setMessage(data.message);
                    }

                    if (status === 422 && data.errors !== undefined) {
                        setErrors(data.errors);
                    }

                    if (status !== 422 && data.message) {
                        addToast({
                            type: "alert",
                            content: data.message,
                        });
                        onClose();
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getPeriodTotals = () => {
        setLoadingTotals(true);

        axios
            .get(`/api/v1/planner/plan/period-summary`)
            .then(({ data }) => {
                setLoading(false);

                setPeriodTotals(
                    data.map((d, index) => {
                        return {
                            text: "Q" + d.period + " " + d.year,
                            value: index,
                            ...d,
                        };
                    })
                );
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.message) {
                        setMessage(data.message);
                    }

                    if (status === 422 && data.errors !== undefined) {
                        setErrors(data.errors);
                    }
                }
            })
            .finally(() => {
                setLoadingTotals(false);
            });
    };

    if (loadingTotals) {
        return (
            <Row collapse>
                <Column collapse>
                    <Loading />
                </Column>
            </Row>
        );
    }

    if (message) {
        return (
            <>
                <Row collapse>
                    <Column collapse>
                        <p>{message}</p>
                    </Column>
                </Row>

                <Row collapse>
                    <Column medium={6} collapse>
                        <Button
                            $expanded
                            onClick={(event) => {
                                event.preventDefault();
                                onCancel();
                            }}
                            type="alert"
                        >
                            {trans("Close")}
                        </Button>
                    </Column>
                </Row>
            </>
        );
    }

    return (
        <>
            <Row>
                <Column>
                    <FormLabel name="group">{trans("Select period")}</FormLabel>
                    <StyledSelectSimple
                        name="period"
                        loading={loadingTotals}
                        options={
                            loadingTotals
                                ? [
                                      {
                                          text: intl.formatMessage({
                                              id: "Loading, please wait...",
                                          }),
                                          value: "",
                                      },
                                  ]
                                : periodTotals
                        }
                        onChange={(e) => setSelectedPeriod(e.target.value)}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Table>
                        {selectedPeriod !== null && selectedPeriod !== "" ? (
                            <tbody>
                                <tr>
                                    <td>
                                        {trans("Number of Planned Activities")}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {
                                            periodTotals[selectedPeriod]
                                                .total_activities
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>{trans("Total Planned Spend")}</td>
                                    <td style={{ textAlign: "right" }}>
                                        <FormattedCurrency
                                            value={
                                                periodTotals[selectedPeriod]
                                                    .total_cost.value
                                            }
                                            currency={
                                                user?.currency?.iso_code ||
                                                "GBP"
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        {trans("Please select a period")}
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </Column>
            </Row>
            {/* <Row>
                <Column>
                    <StyledCheckbox
                        name="confirmed"
                        checked={!!confirmed}
                        onChange={() => setConfirmed(!confirmed)}
                        disabled={!selectedPeriod || loadingTotals || loading}
                        text={intl.formatMessage({
                            id: "I confirm my plan is complete and ready for submission, any changes after approval will require a resubmission.",
                        })}
                    />
                </Column>
            </Row> */}
            <Row>
                <Column
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                    }}
                >
                    <IconTextButton
                        onClick={(event) => {
                            event.preventDefault();
                            onCancel();
                        }}
                        type="alert"
                        icon="times"
                        showCircle={false}
                    >
                        {trans("Cancel")}
                    </IconTextButton>
                    <SubmitButton
                        label={trans("Create")}
                        disabled={
                            !selectedPeriod || loadingTotals || loading
                            // || !confirmed
                        }
                        submitting={loading}
                        type="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            submitForApproval();
                        }}
                    />
                </Column>
            </Row>
        </>
    );
};

export default PlanApprovalCreateForm;
