import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
    SubmitButton,
    FormikInput,
    InputError,
    Button,
    Loading,
} from "@cortexglobal/rla-components";
import { trans, injectIntl } from "@cortexglobal/rla-intl";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { login } from "@cortexglobal/cortex-auth-redux";

const SsoButton = styled(Button)`
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
`;

const LoginSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
        .min(2, "Too short")
        .max(70, "Too long")
        .required("Required"),
});

class LoginForm extends Component {
    state = {
        loading: false,
        credentialsError: false,
        identityProviders: { loaded: false, data: [] },
    };

    componentDidMount() {
        let ssoUrl = process.env.REACT_APP_CORTEX_AUTH_URL;
        let ssoEnabled = process.env.REACT_APP_CORTEX_AUTH_ENABLED;

        if (ssoEnabled && ssoUrl) {
            window.location.href = ssoUrl + "/login";
        }

        if (!this.state.identityProviders.loaded) {
            this.setState((state) => ({
                loading: true,
            }));

            axios.get(`/api/v1/identity-providers`).then(({ data }) => {
                this.setState((state) => ({
                    identityProviders: { loaded: true, data },
                    loading: false,
                }));
            });
            // .catch(e => e);
        }
    }

    submitForm = (values, { setErrors }) => {
        this.setState((state) => ({
            ...this.state,
            credentialsError: false,
            loading: true,
        }));

        this.props.login(values).catch((e) => {
            parseValidationErrors(e)
                .then(({ errors }) => {
                    setErrors(errors);
                })
                .catch((e) => {
                    this.setState((state) => ({
                        loading: false,
                    }));

                    if (e.response && e.response.status) {
                        if (e.response.status === 401) {
                            this.setState((state) => ({
                                credentialsError: e.response.data.message,
                            }));
                        }
                        if (e.response.status === 400) {
                            const { intl } = this.props;
                            this.setState((state) => ({
                                credentialsError: intl.formatMessage({
                                    id: "Username or password not recognised.",
                                }),
                            }));
                        }
                    }
                });
        });

        return true;
    };

    render() {
        const { redirectToReferer, loggedInChecked } = this.props;

        if (redirectToReferer && loggedInChecked) {
            const { from } = this.props.location.state || {
                from: { pathname: "/" },
            };

            return <Redirect to={from} />;
        }

        const { loading, credentialsError } = this.state;

        if (loading === true) {
            return <Loading />;
        }

        return (
            <>
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                        remember: false,
                        test: "",
                        range: 0,
                        comment: "",
                        time: "",
                    }}
                    onSubmit={this.submitForm}
                    validationSchema={LoginSchema}
                >
                    {({ submitting }) => (
                        <Form className="form-group">
                            <FormikInput
                                type="text"
                                name="username"
                                label={trans("Email")}
                                placeholder="user@domain.com"
                            />
                            <FormikInput
                                type="password"
                                name="password"
                                label={trans("Password")}
                            />

                            {credentialsError && (
                                <InputError error={credentialsError} />
                            )}
                            <Link
                                style={{
                                    display: "block",
                                    margin: "0rem 0 1.2rem 0",
                                    textAlign: "right",
                                }}
                                to="/auth/forgotten"
                            >
                                {trans("Forgotten password")}
                            </Link>

                            <SubmitButton
                                expanded
                                centered
                                label={trans("Login")}
                                submitting={submitting}
                            />
                        </Form>
                    )}
                </Formik>

                {this.state.identityProviders.data.map((provider) => {
                    return (
                        <SsoButton
                            expanded
                            centered
                            as="a"
                            href={provider.url}
                            type="secondary"
                        >
                            {provider.name}
                        </SsoButton>
                    );
                })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { redirectToReferer, loggedInChecked } = state.auth;
    return { redirectToReferer, loggedInChecked };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            login,
        },
        dispatch
    );

LoginForm.propTypes = {
    location: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LoginForm));
