import React, { useState } from "react";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    IconTextButton,
    Modal,
    Panel,
    Loading,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { useFileListWithState } from "./hooks/useFileListWithState";
import { FileTable } from "./FileTable";
import { FileForm } from "./FileForm";

enum DisplayTypes {
    Table = "table",
}

interface FilesProps {
    smartRef: string;
    user: { name: string; uuid: string };
    canAdmin: boolean;
    deletable: boolean;
    showHeading: boolean;
    displayType: DisplayTypes;
    singleType: {
        key: string;
        name: string;
    } | null;
    exclude: string[];
    include: string[];
    callback(smartRef: string): any;
}

export const Files = ({
    smartRef,
    user,
    canAdmin = true,
    deletable = true,
    showHeading = true,
    displayType = DisplayTypes.Table,
    singleType = null,
    exclude = [],
    include = [],
    callback = () => {},
}: FilesProps) => {
    const [showForm, setShowForm] = useState(false);

    const files = useFileListWithState(smartRef, include, exclude);

    const addFile = (formData: {}, smartRef: string) => {
        return axios
            .post(`/api/v1/files/${smartRef}`, formData)
            .then((res) => {
                files.addFile(res.data.data);
            })
            .then(() => {
                if (typeof callback === 'function') {
                    callback(smartRef)
                }
            });
    };

    const deleteFile = (fileUuid: string) => {
        if (!deletable) {
            return null;
        }

        return axios
            .delete(`/api/v1/files/${smartRef}/file/${fileUuid}`)
            .then((res) => {
                files.removeFile(fileUuid);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    if (files.state === "loading") {
        return (
            <>
                <Panel>
                    <Loading />
                </Panel>
            </>
        );
    }

    if (files.state === "error") {
        return (
            <>
                <Panel>
                    <p>{trans("An error occurred, please try again later.")}</p>
                </Panel>
            </>
        );
    }

    return (
        <>
            <Row
                collapse
                style={{
                    marginBottom: 0,
                    display: "flex",
                    alignItems: "flex-end",
                    width: "100%",
                }}
            >
                <Column medium={9} collapse>
                    {showHeading && <h3>{trans("Files")}</h3>}
                </Column>

                {canAdmin && (
                    <Column medium={3} style={{ textAlign: "right" }} collapse>
                        <IconTextButton
                            icon={faPlus}
                            showCircle={false}
                            onClick={() => setShowForm(!showForm)}
                            style={{ paddingRight: "1rem" }}
                        >
                            {singleType ? (
                                <>
                                    {trans("Add")} {singleType["name"]}
                                </>
                            ) : (
                                trans("Add a File")
                            )}
                        </IconTextButton>
                    </Column>
                )}
            </Row>

            {canAdmin && (
                <Modal visible={showForm} onClose={() => setShowForm(false)}>
                    <FileForm
                        smartRef={smartRef}
                        addFile={addFile}
                        setShowForm={setShowForm}
                        singleType={singleType}
                    />
                </Modal>
            )}
            {displayType === "table" && (
                <FileTable
                    files={files.files}
                    user={user}
                    deleteFile={deleteFile}
                    canAdmin={canAdmin}
                    deletable={deletable}
                    smartRef={smartRef}
                    singleType={singleType}
                />
            )}
        </>
    );
};
