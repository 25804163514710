import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Column, Centred, Loading } from "@cortexglobal/rla-components";
import { CategoryResultItem } from "@cortexglobal/cortex-dam";

import {
    AssetGrid,
    AssetResultWrapper,
} from "../assetComponents/AssetResultItem";
import usePanelData from "../usePanelData";
// import CategoryResultItem from "../assetComponents/CategoryResultItem";

const AssetCategoriesOutput = ({
    panelId,
    filterBlock = "assets-default",
    baseUrl = "/resources",
    total = 8,
    panelSize,
}) => {
    const history = useHistory();
    const panelData = usePanelData(panelId, "/api/v1/assets/categories", {
        per_page: total,
        filterConfig: filterBlock,
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }

    const viewAssets = (event, category) => {
        event.preventDefault();
        if (!panelData.loaded) {
            return false;
        }
        // props.history.push(`${assetsPath}/view/${category.uuid}`);
        history.push(
            `${baseUrl}?filters={"${panelData.data.filter.alias}":["${category.slug}"]}`
        );
    };

    return (
        <AssetGrid panelSize={panelSize} minColumnWidth={300}>
            {panelData.data ? (
                panelData.data.options.map((category) => {
                    return (
                        <AssetResultWrapper key={category.slug}>
                            <CategoryResultItem
                                onClick={viewAssets}
                                loaded={panelData.loaded}
                                category={category}
                                filter={panelData.data.filter}
                                // permissions={permissions}
                            />
                        </AssetResultWrapper>
                    );
                })
            ) : (
                <Loading align="center" padding="1rem" />
            )}
        </AssetGrid>
    );
};

export default AssetCategoriesOutput;
