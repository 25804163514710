import React, { useState } from "react";
import Composer from "../Composer";

export const NotifyComposer = ({
    onClose,
    title = "",
    subject = "",
    mailingLists,
}) => {
    const emptyMessage = {
        format: "mailingList",
        mailing_list_uuids: null,
        to: null,
        user_uuid: null,
        subject: subject,
        message: "",
        send_now: true,
        send_date: "",
        send_time: "",
    };

    //TODO - Do something with the mailing lists here and pass them to the composer (will also need an update)

    return (
        <Composer
            title={title}
            settings={{}}
            message={emptyMessage}
            successCallback={onClose}
            hideComposer={onClose}
            mailingLists={mailingLists}
        />
    );
};
export default NotifyComposer;
