import React, { useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { Collapse } from "react-collapse";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Button,
    Loading,
    Pagination,
    Overlay,
} from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import AssetResultItem from "./AssetResultItem";
import AssetListView from "./AssetListView";
import OrderModal from "./OrderModal";

const AssetResults = ({
    selectAsset,
    collection,
    pagination,
    permissions,
    onClick,
    favorites,
    toggleFavorites,
    perPage,
    onPerPageChange,
    toggleSelectedAsset,
    selectedAssets,
    maximumAssetsSelected,
    selectMode,
    breakpointColumns,
    selectedFilters,
    filtersUpdated,
    onApply,
    view,
    theme,
    assetsPath,
    history,
    userAssetPath,
    visible,
    navigate = true,
    activityUuid = null,
    updateSearch,
}) => {
    const { can } = usePermissions();

    const [showOrderModal, setShowOrderModal] = useState(false);

    const [selectedAssetUuid, setSelectedAssetUuid] = useState(null);

    /*
     * Checks if is in select mode, if not returns the normal onClick
     * If in select mode and not maximum selected it will toggle the selection
     * If maximum selected then do nothing
     */
    const assetResultOnClick = (event, asset) => {
        if (toggleSelectedAsset) {
            return !maximumAssetsSelected || selectedAssets.includes(asset.uuid)
                ? toggleSelectedAsset(asset.uuid)
                : () => {};
        }

        if (activityUuid) {
            event.preventDefault();
            setSelectedAssetUuid(asset.uuid);
            setShowOrderModal(true);
            return;
        } else {
            event.preventDefault();
            selectAsset(asset);
            if (navigate) {
                history.push(`${assetsPath}/view/${asset.uuid}`);
            }
        }
    };

    const renderNoResults = () => {
        if (favorites) {
            return (
                <>
                    <h3>{trans("No related favorite results found")}</h3>
                    <Button onClick={toggleFavorites}>
                        {trans("View all")}
                    </Button>
                </>
            );
        }
        if (selectedFilters?.features?.includes("orderable")) {
            return (
                <h3>
                    {trans(
                        "No assets that can be ordered have been found using the selected filters"
                    )}
                </h3>
            );
        }

        return <h3>{trans("No results found")}</h3>;
    };

    return (
        // <Collapse isOpened={visible}>
        <Column
            {...breakpointColumns}
            style={{
                minHeight: "100%",
                marginBottom: "1rem",
                width: "100%",
            }}
        >
            <Overlay
                showOverlay={filtersUpdated}
                onClick={() =>
                    filtersUpdated ? onApply(selectedFilters) : null
                }
                buttonLabel={trans("Click to update search")}
            >
                <Row expanded={theme.body.expanded}>
                    <Column collapse>
                        <Row collapse={true} expanded={theme.body.expanded}>
                            {!collection.loaded ? (
                                <div
                                    style={{
                                        padding: "2rem",
                                        position: "sticky",
                                        top: 0,
                                    }}
                                >
                                    <Loading align="center" />
                                </div>
                            ) : collection.data.length === 0 ? (
                                <div
                                    style={{
                                        padding: "2rem",
                                        textAlign: "center",
                                    }}
                                >
                                    {renderNoResults()}
                                </div>
                            ) : view === "list" ? (
                                <AssetListView
                                    assets={collection.data}
                                    onClick={assetResultOnClick}
                                    selectAsset={selectAsset}
                                    maximumAssetsSelected={
                                        maximumAssetsSelected
                                    }
                                    loaded={collection.loaded}
                                    permissions={permissions}
                                    canEdit={can("dam-asset")}
                                    maxColumns={theme.body.expanded ? 3 : 3}
                                    selectMode={selectMode}
                                    selectedAssets={selectedAssets}
                                    useContextMenu={true}
                                    toggleSelectedAsset={toggleSelectedAsset}
                                />
                            ) : (
                                collection.data.map((asset) => {
                                    return (
                                        <AssetResultItem
                                            view={view}
                                            maximumAssetsSelected={
                                                maximumAssetsSelected
                                            }
                                            loaded={collection.loaded}
                                            asset={asset}
                                            onClick={assetResultOnClick}
                                            selectAsset={selectAsset}
                                            key={asset.uuid}
                                            permissions={permissions}
                                            canEdit={can("dam-asset")}
                                            maxColumns={
                                                theme.body.expanded ? 3 : 3
                                            }
                                            selectMode={selectMode}
                                            isSelected={
                                                selectedAssets &&
                                                selectedAssets.includes(
                                                    asset.uuid
                                                )
                                            }
                                            useContextMenu={true}
                                        />
                                    );
                                })
                            )}
                        </Row>
                    </Column>
                    {!_.isEmpty(pagination) && (
                        <div
                            style={{
                                margin: "1rem auto",
                                textAlign: "center",
                            }}
                        >
                            <Pagination
                                alwaysShow={true}
                                style={{ clear: "both" }}
                                currentPage={pagination.current_page - 1}
                                perPage={perPage}
                                onPerPageChange={onPerPageChange}
                                pageCount={pagination.last_page}
                                onPageChange={({ selected }) =>
                                    updateSearch(selectedFilters, selected + 1)
                                }
                                {...pagination}
                                loaded={collection.loaded}
                            />
                        </div>
                    )}
                </Row>
            </Overlay>
            {showOrderModal && (
                <OrderModal
                    onClose={() => setShowOrderModal(false)}
                    assetUuid={selectedAssetUuid}
                    activityUuid={activityUuid}
                    visible={showOrderModal}
                    showDetails={true}
                />
            )}
        </Column>
        // </Collapse>
    );
};

export default withRouter(withTheme(AssetResults));
