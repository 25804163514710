import React, { useState } from "react";
import uuid from "uuid/v4";
import AnimateHeight from "react-animate-height";

import { trans } from "@cortexglobal/rla-intl";
import { Row, Column, Table, Button } from "@cortexglobal/rla-components";

const SurveyTextResult = ({ result, expanded }) => {
    return (
        <AnimateHeight
            height={expanded ? "auto" : 0}
            style={{ marginBottom: "1rem" }}
        >
            <Table>
                <thead>
                    <tr>
                        <th>{trans("Responses")}</th>
                    </tr>
                </thead>
                <tbody>
                    {result.answers
                        .filter((text) => {
                            return text && text.trim() !== "";
                        })
                        .map((text, index) => (
                            <tr key={text + index}>
                                <td>{text}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </AnimateHeight>
    );
};

export default SurveyTextResult;
