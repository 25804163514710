import React, { useContext } from "react";
import styled, { css } from "styled-components";
import AssetContext from "../AssetContext";
import { withRouter } from "react-router-dom";
import { format } from "@cortexglobal/cortex-utilities";

import {
    TableList,
    ButtonDropdown,
    IconTextButton,
    Tooltip,
} from "@cortexglobal/rla-components";
import { transparentize } from "@cortexglobal/cortex-utilities";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    faEye,
    faExchangeAlt,
    faEdit,
    faStarOfLife,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VersionRow = styled.tr`
    padding: 0.2rem 0;
    font-weight: ${({ isLive, isSelectedAsset }) =>
        isLive || isSelectedAsset ? "bold" : "normal"};

    ${({ isSelectedAsset }) =>
        isSelectedAsset &&
        css`
            color: ${({ theme, tableType }) =>
                tableType
                    ? theme.table.types[tableType].thColor
                    : theme.table.types.default.thColor};
            td {
                background-color: ${({ theme, tableType }) =>
                    transparentize(
                        tableType
                            ? theme.table.types[tableType].thBackground
                            : theme.table.types.default.thBackground,
                        0.2
                    )} !important;
            }
        `}
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const SubText = styled.div`
    font-size: 0.7rem;
    font-style: italic;
    font-weight: normal;
`;

const LiveIconWrapper = styled.span`
    display: inline-block;
    margin-left: 0.4rem;
    color: ${({ theme }) => theme.colors.primary};
`;

const VersionData = styled.td`
    ${({ isSelectedAsset }) =>
        isSelectedAsset &&
        css`
            background: ${({ theme, tableType }) =>
                tableType
                    ? theme.table.types[tableType].thBackground
                    : theme.table.types.default.thBackground} !important;
        `}
`;
const VersionsTable = ({ history, asset, onVersionClick = () => {} }) => {
    const intl = useIntl();
    const { canEdit, canAdmin, assetsPath } = useContext(AssetContext);

    if (!canEdit) {
        return null;
    }

    return (
        <TableList>
            <thead>
                <tr
                    style={{
                        borderBottom: "1px solid lightgray",
                        padding: "3px",
                    }}
                >
                    <th>{trans("Version")}</th>
                    <th>{trans("Created")}</th>
                    <th>{trans("Updated")}</th>
                    <th>{trans("Market")}</th>
                    {(process.env.REACT_APP_MULTI_LOCALE_INSTANCE || true) && (
                        <React.Fragment>
                            {/*<th>{trans("Country")}</th>*/}
                            <th>{trans("Language") /* trans("Locale")*/}</th>
                        </React.Fragment>
                    )}
                    <th>{trans("Status")}</th>
                    <th>{trans("Actions")}</th>
                </tr>
            </thead>
            <tbody>
                {asset.allVersions &&
                    asset.allVersions.map((version) => {
                        const isSelectedAsset = version.uuid == asset.uuid;
                        return (
                            <VersionRow
                                isSelectedAsset={isSelectedAsset}
                                isLive={version.isLive}
                            >
                                <td>
                                    V{version.version}
                                    {version.origin && (
                                        <SubText>{trans("Original")}</SubText>
                                    )}
                                    {version.isLive && (
                                        <LiveIconWrapper>
                                            <Tooltip
                                                text={trans("Live Version")}
                                                showIcon={false}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faStarOfLife}
                                                />
                                            </Tooltip>
                                        </LiveIconWrapper>
                                    )}
                                </td>
                                <td>
                                    {format(new Date(version.createdAt), "PP")}
                                </td>
                                <td>
                                    {format(new Date(version.updatedAt), "PP")}
                                </td>
                                <td>{version.market}</td>
                                {(process.env.REACT_APP_MULTI_LOCALE_INSTANCE ||
                                    true) && (
                                    <React.Fragment>
                                        {/*<td>{version.readableCountry}</td>*/}
                                        <td>{version.language}</td>
                                    </React.Fragment>
                                )}
                                <td>{version.readableStatus}</td>
                                <td>
                                    {isSelectedAsset && trans("This version")}

                                    {!isSelectedAsset && (
                                        <ButtonDropdown
                                            type="secondary"
                                            onClick={() => {
                                                history.push(
                                                    `${assetsPath}/view/${version.uuid}`
                                                );
                                                onVersionClick(false);
                                            }}
                                            name={intl.formatMessage({
                                                id: "View Asset",
                                            })}
                                            actions={[
                                                {
                                                    name: intl.formatMessage({
                                                        id: "Edit Asset",
                                                    }),
                                                    onClick: () => {
                                                        history.push(
                                                            `${assetsPath}/${version.uuid}/admin`
                                                        );
                                                        onVersionClick(false);
                                                    },
                                                },
                                                {
                                                    name: intl.formatMessage({
                                                        id: "Compare",
                                                    }),
                                                    onClick: () => {
                                                        history.push(
                                                            `${assetsPath}/${asset.uuid}/compare/${version.uuid}`
                                                        );
                                                        onVersionClick(false);
                                                    },
                                                },
                                            ]}
                                        >
                                            {trans("View")}
                                        </ButtonDropdown>
                                    )}
                                    {false && !isSelectedAsset && (
                                        <ButtonWrapper>
                                            <IconTextButton
                                                height="15px"
                                                type="info"
                                                onClick={() => {
                                                    history.push(
                                                        "./" + version.uuid
                                                    );
                                                    onVersionClick(false);
                                                }}
                                                icon={faEye}
                                                showCircle={false}
                                                expanded={false}
                                            >
                                                {trans("View")}
                                            </IconTextButton>
                                            {canAdmin && (
                                                <IconTextButton
                                                    height="15px"
                                                    type="info"
                                                    onClick={() => {
                                                        history.push(
                                                            `${assetsPath}/${version.uuid}/admin`
                                                        );
                                                        onVersionClick(false);
                                                    }}
                                                    icon={faEdit}
                                                    showCircle={false}
                                                    expanded={false}
                                                >
                                                    {trans("Edit")}
                                                </IconTextButton>
                                            )}
                                            <IconTextButton
                                                height="15px"
                                                type="info"
                                                onClick={() => {
                                                    history.push(
                                                        `${assetsPath}/${asset.uuid}/compare/${version.uuid}`
                                                    );
                                                    onVersionClick(false);
                                                }}
                                                icon={faExchangeAlt}
                                                showCircle={false}
                                                expanded={false}
                                            >
                                                {trans("Compare")}
                                            </IconTextButton>
                                        </ButtonWrapper>
                                    )}
                                </td>
                            </VersionRow>
                        );
                    })}
            </tbody>
        </TableList>
    );
};

export default withRouter(VersionsTable);
