import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import RoleListing from "./RoleListing";
import RoleCreate from "./RoleCreate";
import { RoleEdit } from "./RoleEdit";

export const RoleAdmin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <RoleListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <RoleCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <RoleEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default RoleAdmin;
