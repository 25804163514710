import React, { useState } from "react";
import styled from "styled-components";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { EmptyRow, PreviewImage } from "../../components/index";
import { Link } from "../../forms/LinkForm";

const PaddedDiv = styled.div`
    padding: 3em;
`;

const TableHeader = styled.div`
    cursor: pointer;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`;

export const ExternalDashboardTable = ({
    items,
    loaded,
    path,
    pagination = null,
    deleteItem,
    onPageChange,
    onOrderBy,
    onPerPageChange,
}: LinkTableProps) => {
    const history = useHistory();
    const currentPage = pagination ? pagination.current_page : 1;

    const [deleteLinkModalShowing, setDeleteLinkModalShowing] = useState(false);
    const [deleteLinkName, setDeleteLinkName] = useState("");
    const [deleteLinkId, setDeleteLinkId] = useState(null);
    const viewLink = (event, url) => {
        event.preventDefault();
        const isFullUrl = url.indexOf("http") === 0;

        //TODO Record that the link has been used

        if (isFullUrl) {
            window.open(url, "_blank").focus();
        } else {
            history.push(`${url}`);
        }
    };
    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <TableHeader onClick={() => onOrderBy("name")}>
                                {trans("Name")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("url")}>
                                {trans("URL")}
                            </TableHeader>
                        </th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <EmptyRow colSpan={"100%"}>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow colSpan={"100%"}>
                            {trans("No items found")}
                        </EmptyRow>
                    ) : (
                        items.map((item) => {
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>
                                        <a
                                            href={item.url}
                                            onClick={(event) =>
                                                viewLink(event, item.url)
                                            }
                                        >
                                            {item.url}
                                        </a>
                                    </td>
                                    <td>
                                        <IconTextButton
                                            as={ReactRouterLink}
                                            to={`/reporting/external/${item.uuid}`}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEye}
                                        >
                                            {trans("View")}
                                        </IconTextButton>
                                        <IconTextButton
                                            as={ReactRouterLink}
                                            to={`${path}/edit/${item.uuid}?page=${currentPage}`}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEdit}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            type="alert"
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                            onClick={(event) => {
                                                setDeleteLinkId(item.uuid);
                                                setDeleteLinkModalShowing(true);
                                                setDeleteLinkName(item.name);
                                            }}
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
            {deleteLinkModalShowing && (
                <Modal
                    visible={deleteLinkModalShowing}
                    onClose={() => {
                        setDeleteLinkModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h3>You are about delete: {deleteLinkName}</h3>

                        <p>Are you sure you want to proceed?</p>
                        <Button
                            type="secondary"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeleteLinkModalShowing(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                deleteItem(deleteLinkId).then(() => {
                                    setDeleteLinkModalShowing(false);
                                });
                            }}
                        >
                            Delete
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}
        </React.Fragment>
    );
};

interface LinkTableProps {
    items: Link[];
    loaded: boolean;
    path: string;
    pagination?: any;
    deleteItem: (number) => Promise<Link[]>;
    onPageChange?: (number) => void;
    onOrderBy?: (string) => void;
    onPerPageChange?: ({ value }) => void;
}

export default ExternalDashboardTable;
