import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Collapse } from "react-collapse";

import { trans } from "@cortexglobal/rla-intl";
import AutofillFieldsTable from "./AutofillFieldsTable";

import {
    Row,
    Column,
    InputField,
    StyledCheckbox,
    FormLabel,
    SelectField,
    TextareaField,
    IconTextButton,
    Toggle,
    RepeaterField,
} from "@cortexglobal/rla-components";
import InputType from "./InputType";
import { ManageFootnotes } from "./ManageFootnotes";

import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

const Img = styled.img`
    max-width: 100%;
    max-height: 300px;
`;
const NameEmphasis = styled.em`
    font-weight: 300;
`;

const AdminEditorInputGroup = ({
    path,
    tag,
    onChange,
    chooseImage,
    autofill,
    asset,
    baseAsset,
    useFootnotes,
}) => {
    //console.log(autofill);
    const [optionsShowing, setOptionsShowing] = useState(false);
    const [editingLabel, setEditingLabel] = useState(false);
    const requiresEdit = tag.requiresEdit ? tag.requiresEdit : false;
    const locked = tag.locked ? tag.locked : false;
    const allowUpload = tag.allowUpload ? tag.allowUpload : false;
    const autofilled = tag.autofilled ? tag.autofilled : false;
    const preFilledValues = tag.preFilledValues ? tag.preFilledValues : [];
    const hasPreFilledValues = tag.hasPreFilledValues
        ? tag.hasPreFilledValues
        : false;
    const href = tag.href ? tag.href : "";
    const autofillMask = tag.autofillMask ? tag.autofillMask : tag.value;
    const isImage = tag.type === "image" || tag.type === "gwd-image";
    const autofillInputType = isImage ? "text" : tag.type;
    // console.log("autofillInputType", autofillInputType, tag);
    return (
        <React.Fragment key={tag.name}>
            <Row style={{ margin: "1.5rem 0" }}>
                <Column>
                    {editingLabel && (
                        <>
                            <IconTextButton
                                style={{
                                    float: "right",
                                    paddingRight: "0",
                                    marginTop: "-5px",
                                }}
                                iconSize={"1rem"}
                                icon={faEdit}
                                showCircle={false}
                                onClick={() => {
                                    setEditingLabel(false);
                                }}
                            />
                            <InputField
                                label={
                                    <span>
                                        {trans("Label for")}{" "}
                                        <NameEmphasis>{tag.name}</NameEmphasis>
                                    </span>
                                }
                                id={tag.name + "_label"}
                                name={tag.name}
                                value={tag.label}
                                onChange={(event) =>
                                    onChange("label", path, event)
                                }
                                placeholder={tag.name}
                            />
                        </>
                    )}
                </Column>
                <Column>
                    {autofilled ? (
                        <>
                            {!isImage ? (
                                <InputType
                                    id={tag.name + "_autofill_mask"}
                                    name={tag.name}
                                    tag={{
                                        ...tag,
                                        value: autofillMask,
                                        name: tag.name + "_autofill_mask",
                                    }}
                                    path={path}
                                    asset={asset}
                                    baseAsset={baseAsset}
                                    onChange={(value, path, event) => {
                                        onChange("autofillMask", path, event);
                                    }}
                                    placeholder="<<available-tags>> here"
                                    value={autofillMask}
                                    chooseImage={chooseImage}
                                />
                            ) : (
                                <InputField
                                    id={tag.name + "_autofill_mask"}
                                    name={tag.name}
                                    path={path}
                                    onChange={(event) =>
                                        onChange("autofillMask", path, event)
                                    }
                                    chooseImage={chooseImage}
                                    placeholder="<<available-tags>> here"
                                    value={autofillMask}
                                    label={tag.label}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {!editingLabel && !tag.titleLocked && (
                                <IconTextButton
                                    style={{
                                        float: "right",
                                        paddingRight: "0",
                                        marginTop: "-5px",
                                    }}
                                    iconSize={"1rem"}
                                    icon={faEdit}
                                    showCircle={false}
                                    onClick={() => {
                                        setEditingLabel(true);
                                    }}
                                />
                            )}
                            {!hasPreFilledValues && (
                                <InputType
                                    useFootnotes={useFootnotes}
                                    maxLength={tag.characters}
                                    tag={tag}
                                    path={path}
                                    asset={asset}
                                    baseAsset={baseAsset}
                                    onChange={onChange}
                                    chooseImage={chooseImage}
                                    label={
                                        editingLabel
                                            ? trans("Current value")
                                            : tag.label
                                    }
                                />
                            )}
                        </>
                    )}
                    {hasPreFilledValues && (
                        <SelectField
                            style={{ height: "40px" }}
                            name={tag.name}
                            value={tag.value}
                            label={
                                editingLabel
                                    ? trans("Current value")
                                    : tag.label
                            }
                            options={preFilledValues.map((val) => {
                                return {
                                    text: val["Pre-filled Value"],
                                    value: val["Pre-filled Value"],
                                };
                            })}
                            onChange={({ value }) => {
                                onChange("value", path, {
                                    name: tag.name,
                                    value: value,
                                });
                            }}
                        />
                    )}
                    {tag.name === "backgroundImage" && tag.value && (
                        <IconTextButton
                            style={{ margin: "1em 0" }}
                            onClick={() =>
                                onChange("value", path, {
                                    name: "backgroundImage",
                                    value: "",
                                })
                            }
                            showCircle={false}
                            icon={faTrashAlt}
                        >
                            {trans("Delete background")}
                        </IconTextButton>
                    )}
                </Column>

                {tag.type === "link" && (
                    <Column>
                        <InputField
                            label={
                                <span>
                                    {trans("Link / URL for ")}{" "}
                                    <NameEmphasis>{tag.name}</NameEmphasis>
                                </span>
                            }
                            name={tag.name}
                            value={href}
                            onChange={(event) => onChange("href", path, event)}
                            placeholder={tag.name}
                        />
                    </Column>
                )}

                {autofilled && (
                    <AutofillFieldsTable listing={autofill} type={tag.type} />
                )}

                <Column
                    style={{
                        textAlign: "right",
                    }}
                >
                    {!tag.hideAdvancedOptions && (
                        <IconTextButton
                            style={{ paddingRight: "0" }}
                            onClick={(event) => {
                                setOptionsShowing(!optionsShowing);
                            }}
                            rotation={optionsShowing ? 270 : 90}
                            showCircle={false}
                            iconBefore={false}
                        >
                            {trans("Advanced options")}
                        </IconTextButton>
                    )}
                    {useFootnotes && tag.type === "text" && (
                        <span
                            style={{
                                margin: "8px 4px 0 1rem",
                                display: "inline-block",
                            }}
                        >
                            <ManageFootnotes
                                asset={asset}
                                tag={tag}
                                path={path}
                                onChange={onChange}
                            />
                        </span>
                    )}
                </Column>
            </Row>

            <Collapse isOpened={optionsShowing}>
                <Row>
                    {!isImage ? (
                        <Column medium={5}>
                            <InputField
                                label={trans("Max Chars")}
                                type="number"
                                id={tag.name + "max_chars"}
                                name={tag.name}
                                value={tag.characters}
                                onChange={(event) =>
                                    onChange("characters", path, event)
                                }
                            />
                        </Column>
                    ) : (
                        <>
                            <Column medium={6}>
                                <InputField
                                    label={trans("Min Width PX")}
                                    type="number"
                                    id={tag.name + "width"}
                                    name={tag.name}
                                    value={tag.width}
                                    onChange={(event) =>
                                        onChange("width", path, event)
                                    }
                                />
                            </Column>
                            <Column medium={6}>
                                <InputField
                                    label={trans("Min Height PX")}
                                    type="number"
                                    id={tag.name + "height"}
                                    name={tag.name}
                                    value={tag.height}
                                    onChange={(event) =>
                                        onChange("height", path, event)
                                    }
                                />
                            </Column>
                        </>
                    )}
                    {/*<Column medium={4}>*/}
                    {/*    <FormLabel>*/}
                    {/*        {trans("Requires edit")}*/}
                    {/*        <StyledCheckbox*/}
                    {/*            id={tag.name + "_requires_edit"}*/}
                    {/*            name={tag.name}*/}
                    {/*            type="checkbox"*/}
                    {/*            checked={requiresEdit}*/}
                    {/*            onChange={event =>*/}
                    {/*                onChange("requiresEdit", path, {*/}
                    {/*                    ...event,*/}
                    {/*                    name: event.target.name,*/}
                    {/*                    value: event.target.checked*/}
                    {/*                        ? true*/}
                    {/*                        : false*/}
                    {/*                })*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    </FormLabel>*/}
                    {/*</Column>*/}
                    <Column medium={2}>
                        <FormLabel>
                            {trans("Locked")}

                            <Toggle
                                name={tag.name}
                                style={{ marginTop: "1.4rem" }}
                                checked={locked}
                                onChange={(event) => {
                                    onChange("locked", path, {
                                        ...event,
                                        name: event.target.name,
                                        value: !!event.target.checked,
                                    });
                                }}
                            />
                        </FormLabel>
                    </Column>{" "}
                    {!tag.hideAutofill && (
                        <Column medium={2}>
                            <FormLabel>
                                {trans("Autofill")}

                                <Toggle
                                    name={tag.name}
                                    style={{ marginTop: "1.4rem" }}
                                    checked={autofilled}
                                    onChange={(event) => {
                                        onChange("autofilled", path, {
                                            ...event,
                                            name: event.target.name,
                                            value: !!event.target.checked,
                                        });
                                        if (tag.hasPreFilledValues) {
                                            onChange(
                                                "hasPreFilledValues",
                                                path,
                                                {
                                                    name: "value",
                                                    value: false,
                                                }
                                            );
                                        }
                                    }}
                                />
                            </FormLabel>
                        </Column>
                    )}
                    {!isImage && !tag.hidePreFilled && (
                        <Column medium={3}>
                            <FormLabel>
                                {trans("Pre-filled")}

                                <div>
                                    <Toggle
                                        name={tag.name}
                                        style={{ marginTop: "1.4rem" }}
                                        checked={hasPreFilledValues}
                                        onChange={(event) => {
                                            onChange(
                                                "hasPreFilledValues",
                                                path,
                                                {
                                                    ...event,
                                                    name: event.target.name,
                                                    value: !!event.target
                                                        .checked,
                                                }
                                            );
                                            if (tag.autofilled) {
                                                onChange("autofilled", path, {
                                                    name: "value",
                                                    value: false,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </FormLabel>
                        </Column>
                    )}
                    {isImage && (
                        <Column medium={6}>
                            <FormLabel>
                                {trans("Allow User Upload")}
                                <br />

                                <Toggle
                                    name={tag.name}
                                    style={{ marginTop: "1.4rem" }}
                                    checked={allowUpload}
                                    onChange={(event) => {
                                        onChange("allowUpload", path, {
                                            ...event,
                                            name: event.target.name,
                                            value: !!event.target.checked,
                                        });
                                    }}
                                />
                            </FormLabel>
                        </Column>
                    )}
                    {/*{false &&*/}
                    {/*    (tag.type !== "image" ||  tag.type !== "gwd-image") &&*/}
                    {/*    autofill &&*/}
                    {/*    autofill.length > 0 && (*/}
                    {/*        <Column>*/}
                    {/*            <SelectField*/}
                    {/*                label={trans("Auto fill")}*/}
                    {/*                inlineLabel={false}*/}
                    {/*                id={tag.name + "_auto_fill"}*/}
                    {/*                name={tag.name}*/}
                    {/*                options={autofill}*/}
                    {/*                onChange={event =>*/}
                    {/*                    onChange("autofill", path, event)*/}
                    {/*                }*/}
                    {/*            />*/}
                    {/*        </Column>*/}
                    {/*    )}*/}
                </Row>
                {hasPreFilledValues && (
                    <Row>
                        <RepeaterField
                            style={{ marginTop: "2em" }}
                            values={preFilledValues}
                            onChanges={(value) => {
                                onChange("preFilledValues", path, {
                                    name: "preFilledValues",
                                    value: value,
                                });
                            }}
                            types={[
                                {
                                    name: "Pre-filled Value",
                                    type: "Text",
                                },
                            ]}
                        />
                    </Row>
                )}
            </Collapse>
        </React.Fragment>
    );
};

export default AdminEditorInputGroup;
