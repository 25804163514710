import axios from "axios";
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import {
    clearStorage,
    clearStorageExcept,
    getItem,
    setItem
} from "@cortexglobal/cortex-utilities";

// Set default base url.
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// Count all requests which were made...
let requestsCounter = 0;

// Add a request interceptor
axios.interceptors.request.use(request => {
    if (requestsCounter++ <= 0) {
        NProgress.start();
    }

    return request;
});

// Add a response interceptor
axios.interceptors.response.use(
    response => {
        if (--requestsCounter === 0) {
            NProgress.done();
        }
        const storedReleaseVersion = getItem("release_version");
        if (
            response.headers["release-version"] &&
            storedReleaseVersion["release_version"] !==
                response.headers["release-version"]
        ) {
            // console.log(
            //     `New Version detected ${response.headers["release-version"]}`
            // );
            clearStorageExcept(["refresh_token", "access_token", "token"]);
            setItem("release_version", response.headers["release-version"]);
            window.location.reload(true);
        }

        return response;
    },
    error => {
        if (--requestsCounter === 0) {
            NProgress.done();
        }

        if (
            error.response &&
            error.response.status === 401 &&
            (!error.response.data ||
                error.response.data.error !== "invalid_credentials")
        ) {
            clearStorage();
            window.location.reload(true);
        }

        throw error;
    }
);
