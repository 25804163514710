import React, { Component } from "react";
import PropTypes from "prop-types";

class Tooltip extends Component {
    getStyle() {
        return {
            zIndex: this.props.style.zIndex + 1
        };
    }
    render() {
        return (
            <div className="injectableTooltip__body" style={this.getStyle()}>
                {this.props.children}
            </div>
        );
    }
}

Tooltip.propTypes = {
    style: PropTypes.shape({
        zIndex: PropTypes.number.isRequired
    })
};

export default Tooltip;
