import React, { useCallback, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    ContentBlockLoader,
    SubmitButton,
    FormikSelect,
    FormikInput,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    IconTextButton,
    useAddToast,
    useToasts,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { PreviewImage, LocaleSwitcher } from "../components/index";

const FILE_SIZE = 1160 * 1024 * 12;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml",
];

const schema = Yup.object().shape({
    locale: Yup.string().required(trans("Required")),
    name: Yup.string().required(trans("Required")),
    description: Yup.string().nullable(),
    // order: Yup.number(),
    color: Yup.string().nullable(),
    thumbnail: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                if (!value) {
                    return Yup.mixed();
                }
                return (
                    Yup.mixed()
                        // .required("A thumbnail is required")
                        .test(
                            "fileSize",
                            "File too large",
                            (value) => value.size <= FILE_SIZE
                        )
                        .test("fileFormat", "Unsupported Format", (value) =>
                            SUPPORTED_FORMATS.includes(value.type)
                        )
                ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
});

export type InventoryCategory = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    // alias?: string;
    i18n?: { [key: string]: any };
};

export const InventoryCategoryForm = ({ item, submitForm }) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [newPreviewFile, setNewPreviewFile] = useState(null);

    const handleSubmit = (
        values: InventoryCategory,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();
        Object.keys(values).forEach((key) => fd.append(key, values[key]));
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: trans("Saving"),
        });
        submitForm(fd, setSubmitting)
            .then((data) => {
                addToast({
                    content: trans("Inventory Category saved"),
                    type: "success",
                    showFor: 3000,
                });
            })
            .catch((e) => {
                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred deleting the category"
                        ),
                        showFor: 5000,
                    });
                }
                parseValidationErrors(e).then(({ errors }) => {
                    //console.log(setErrors, errors);
                    setErrors(errors);
                    setSubmitting(false);
                });
            })
            .finally(() => {
                dispatch({ type: "removeToast", uuid: "loadToast" });
            });
    };
    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );
    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("thumbnail", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);

    return (
        <Formik
            initialValues={{
                locale: item.locale ? item.locale : null,
                name: item.name ? item.name : "",
                description: item.description ? item.description : "",
                // order: item.order,
                color: item.color ? item.color : "",
                thumbnail: item.thumbnail ? item.thumbnail : null,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                // console.log(values);
                return (
                    <Form className="form-group">
                        <FormRow
                            name="locale"
                            label={trans("Locale")}
                            helpText={trans("Optional")}
                            error={errors.locale}
                        >
                            <LocaleSwitcher
                                item={item}
                                setFieldValue={setFieldValue}
                                //@ts-ignore
                                values={values}
                            />
                        </FormRow>

                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Required")}
                            error={errors.name}
                            required={true}
                        >
                            <FormikInput
                                name="name"
                                value={values.name}
                                showError={false}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="description"
                            label={trans("Description")}
                            helpText={trans("Optional")}
                            error={errors.description}
                        >
                            <FormikInput
                                name="description"
                                value={values.description}
                                showError={false}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="thumbnail"
                            label={trans("Thumbnail")}
                            helpText={trans("Optional")}
                            error={errors.thumbnail}
                        >
                            <SimpleDropzone
                                style={{ marginBottom: "2.2rem" }}
                                onDrop={(acceptedFiles) =>
                                    onDrop(acceptedFiles, setFieldValue)
                                }
                                accept="image/*"
                                multiple={false}
                            >
                                <IconTextButton
                                    icon="upload"
                                    showCircle={false}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {values.thumbnail && values.thumbnail !== ""
                                        ? trans("Update thumbnail")
                                        : trans("Add thumbnail")}
                                </IconTextButton>
                            </SimpleDropzone>

                            {typeof values.thumbnail === "string" &&
                                values.thumbnail !== "" && (
                                    <PreviewImage src={values.thumbnail} />
                                )}
                            {newPreviewFile && (
                                <PreviewImage src={newPreviewFile} />
                            )}
                        </FormRow>
                        <FormRow
                            name="color"
                            label={trans("Color")}
                            helpText={trans("Optional")}
                            error={errors.color}
                        >
                            <FormikInput
                                type="color"
                                name="color"
                                value={values.color}
                            />{" "}
                        </FormRow>
                        {/* <FormRow
                            name="order"
                            label={trans("Order")}
                            helpText={trans("Optional")}
                            error={errors.order}
                        >
                            <FormikInput name="order" value={values.order} />{" "}
                        </FormRow> */}

                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default InventoryCategoryForm;
