import React, { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Row,
    Column,
    Button,
    SubmitButton,
    InputField,
    FormikDatePicker,
    FormikAdvancedSelect,
    FormikTextarea,
    FormRow,
    FormikSelect,
    Error,
    IconTextButton,
    FormikStarRating,
    FormLabel,
} from "@cortexglobal/rla-components";
import { trans, injectIntl } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    addTask,
    editTask,
    plannerStopEdit,
    getPlannerChildGroups,
} from "../plannerActions";

const TaskResultsSchema = Yup.object().shape({
    // name: Yup.string()
    //     .max(100)
    //     .required(trans("Please enter a campaign name")),
    // start_date: Yup.date().required(trans("Please pick a start date")),
    // end_date: Yup.date()
    //     .min(
    //         Yup.ref("start_date"),
    //         trans("End date must be after the start date")
    //     )
    //     .required(trans("Please pick an end date")),
    // planned_spend: Yup.number().min(
    //     0,
    //     trans("Planned spend must be a positive number")
    // ),
    // primary_category_alias: Yup.string().required(
    //     trans("Please select a category")
    // ),
    // media_categories: Yup.array()
    //     .of(
    //         Yup.object().shape({
    //             alias: Yup.string().required("Required"), // these constraints take precedence
    //             budget: Yup.number()
    //                 .typeError(trans("You need to enter a budget"))
    //                 .min(0, trans("You need to enter a budget"))
    //                 .required("Required") // these constraints take precedence
    //         })
    //     )
    //     .required("Please select a media category") // these constraints are shown if and only if inner constraints are satisfied
    //     .min(1, "You need to select at least one media category"),
    // goal: Yup.string().required(trans("Please select a goal"))
});
const TaskResultsForm = ({ task, onSave, onCancel, goals }) => {
    // const [fields, setFields] = useState([]);

    // useEffect(() => {
    //     const goalArray = goals.filter((goalOption) => {
    //         return goalOption.value === task.goal;
    //     });
    //     if (goalArray.length !== 1) {
    //         return null;
    //     }
    //     const { fields: selectedFields } = goalArray[0];

    //     if (!selectedFields) {
    //         return null;
    //     }
    //     setFields(selectedFields);
    // }, [goals, task]);

    const submitForm = (values, { setErrors, setSubmitting }) => {
        values["task_uuid"] = task.uuid;
        return axios
            .post(`/api/v1/planner/tasks/${task.uuid}/results`, values)
            .then(({ data }) => {
                setSubmitting(false);
                onSave(data.data);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });
            });
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    metrics: task ? task.metrics : [],
                    group_customer_rating: task
                        ? task.group_customer_rating
                        : "",
                    group_internal_rating: task
                        ? task.group_internal_rating
                        : "",
                    group_external_rating: task
                        ? task.group_external_rating
                        : "",
                }}
                onSubmit={submitForm}
                validationSchema={TaskResultsSchema}
            >
                {({ isSubmitting, values, errors, setFieldValue }) => {
                    return (
                        <Form className="form-group">
                            <Row>
                                <Column>
                                    {task.metrics.map((metric, index) => {
                                        return (
                                            <FormRow
                                                name={metric.metric_uuid}
                                                label={metric.name}
                                                error={
                                                    errors?.metrics?.[index]
                                                        ?.actual_value
                                                }
                                                helpText={metric.description}
                                            >
                                                <Column medium={6}>
                                                    <FormLabel htmlFor="actual_value">
                                                        {trans("Estimated")}
                                                    </FormLabel>
                                                    <InputField
                                                        type={
                                                            metric.type ||
                                                            "number"
                                                        }
                                                        value={
                                                            metric.estimated_value
                                                        }
                                                        disabled={true}
                                                    />
                                                </Column>
                                                <Column medium={6}>
                                                    <FormLabel htmlFor="actual_value">
                                                        {trans("Actual")}
                                                    </FormLabel>
                                                    <InputField
                                                        type={
                                                            metric.type ||
                                                            "number"
                                                        }
                                                        name={metric.uuid}
                                                        value={
                                                            values.metrics.find(
                                                                (metricValue) =>
                                                                    metricValue.metric_uuid ===
                                                                    metric.metric_uuid
                                                            )?.actual_value
                                                        }
                                                        showError={false}
                                                        onChange={(event) => {
                                                            let newValues = [
                                                                ...values.metrics,
                                                            ];
                                                            //If the metrics already contains a value for this metric, update it
                                                            if (
                                                                values.metrics.some(
                                                                    (
                                                                        metricValue
                                                                    ) =>
                                                                        metricValue.metric_uuid ===
                                                                        metric.metric_uuid
                                                                )
                                                            ) {
                                                                newValues =
                                                                    values.metrics.map(
                                                                        (
                                                                            metricValue
                                                                        ) => {
                                                                            if (
                                                                                metricValue.metric_uuid ===
                                                                                metric.metric_uuid
                                                                            ) {
                                                                                metricValue.actual_value =
                                                                                    event.value;
                                                                            }
                                                                            return metricValue;
                                                                        }
                                                                    );
                                                            } else {
                                                                //Otherwise add a new value
                                                                newValues.push({
                                                                    metric_uuid:
                                                                        metric.uuid,
                                                                    estimated_value:
                                                                        event.value,
                                                                    actual_value:
                                                                        event.value,
                                                                });
                                                            }

                                                            setFieldValue(
                                                                "metrics",
                                                                newValues
                                                            );
                                                        }}
                                                    />{" "}
                                                </Column>
                                            </FormRow>
                                        );
                                    })}
                                    <FormRow
                                        name="group_customer_rating"
                                        label={trans("Customer Rating")}
                                        error={errors.group_customer_rating}
                                        style={{ margin: "0.5rem 0" }}
                                        helpText={trans(
                                            "How well did your customers receive this campaign?"
                                        )}
                                    >
                                        <FormikStarRating
                                            name="group_customer_rating"
                                            value={values.group_customer_rating}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="group_internal_rating"
                                        label={trans("Internal Rating")}
                                        error={errors.group_internal_rating}
                                        style={{ marginBottom: "0.5em" }}
                                        helpText={trans(
                                            "How well did your internal team receive this campaign?"
                                        )}
                                    >
                                        <FormikStarRating
                                            name="group_internal_rating"
                                            value={values.group_internal_rating}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="group_external_rating"
                                        label={trans("External Rating")}
                                        error={errors.group_external_rating}
                                        style={{ marginBottom: "0.5em" }}
                                        helpText={trans(
                                            "How well did your suppliers, vendors and press receive this campaign?"
                                        )}
                                    >
                                        <FormikStarRating
                                            name="group_external_rating"
                                            value={values.group_external_rating}
                                        />
                                    </FormRow>
                                </Column>
                            </Row>
                            <Row>
                                <Column medium={6} centered>
                                    <Column
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        {onCancel && (
                                            <IconTextButton
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    onCancel();
                                                }}
                                                type="alert"
                                                icon={faTimes}
                                                showCircle={false}
                                            >
                                                {trans("Cancel")}
                                            </IconTextButton>
                                        )}
                                        <SubmitButton
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </Column>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addTask,
            editTask,
            plannerStopEdit,
            getPlannerChildGroups,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const {
        edit,
        startDate,
        endDate,
        filters,
        categories,
        config: {
            data: {
                options: { goals },
            },
        },
    } = state.planner;
    return {
        edit,
        startDate,
        endDate,
        filters,
        categories,
        goals,
    };
}

export default injectIntl(
    withTheme(
        compose(connect(mapStateToProps, mapDispatchToProps))(TaskResultsForm)
    )
);
