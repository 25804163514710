import React, { useState, useContext } from "react";
import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import {
    Loading,
    Tabordion,
    Panel,
    Row,
    Column,
    SubmitButton,
    PageTitle,
    IconTextButton,
    Button,
    useAddToast,
    useToasts,
    HeaderWithErrorIndicated,
    useSectionErrors,
} from "@cortexglobal/rla-components";
import {
    useAssetAdmin,
    UPDATE_ASSET_STATE,
    LOADED_DATA,
    SAVING_DATA,
    FINISH_SAVING_DATA,
} from "../hooks/useAssetAdmin";
import { trans } from "@cortexglobal/rla-intl";

import NamingAndGeneralSetup from "../forms/NamingAndGeneralSetup";
import Editor from "../forms/Editor";
import SiteSettingsEditor from "../forms/SiteSettingsEditor";
import NavigationEditor from "../forms/NavigationEditor";
import Availability from "../forms/Availability";
import AssetContext from "../AssetContext";
import { confirmHappyToLeave } from "./AdminAsset";
export const HeaderEmphasis = styled.em`
    font-weight: 300;
    margin-left: 0.3rem;
`;

export const VersionNumber = styled.span`
    font-weight: 80;
    margin-left: 0.3rem;
    font-size: 0.9rem;
`;

const EditAsset = ({
    assetUuid,
    assetsPath,
    filters,
    groups,
    setRefreshSearch,
}) => {
    const [errors, setErrors] = useState({});
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: ["name", "asset_type", "media_type", "locale"],
        meta: ["media-channel", "therapy-type"],
        categories: ["category-hierarchy"],
        availability: [
            "view_type",
            "available_from",
            "available_to",
            "terms",
            "certification_terms",
            "certification_ref",
            "certification_awarded_date",
            "expiration_date",
        ],
    });
    let history = useHistory();
    let match = useRouteMatch(`${assetsPath}/:assetUuid/edit/:step`);
    const addToast = useAddToast();
    const [_, toastDispatch] = useToasts();

    const [assetAdmin, dispatch] = useAssetAdmin(assetUuid, assetsPath, false);

    const { getAssetAutofillFields, autofill, config } =
        useContext(AssetContext);
    const disableSaveButton = () => {
        const { asset, assetDeclarations, assetSaving } = assetAdmin;
        let status = asset.status;
        if (assetSaving) {
            return true;
        }

        if (
            assetDeclarations[status] &&
            assetDeclarations[status].mandatoryCheck
        ) {
            return !(
                asset.accepted_terms && asset.accepted_terms[0] === "accepted"
            );
        }

        return false;
    };
    const standardOnChange = ({ name, value }) => {
        dispatch({ type: UPDATE_ASSET_STATE, name, value });
        //Remove the field from the errors if it's there
        let { [name]: oldValue, ...newErrors } = errors;
        setErrors(newErrors);
    };

    const saveUpdatedAsset = (event, previewOnly = false, rethrow = false) => {
        event.preventDefault();
        const generatingFiles = !assetAdmin.asset.files_ready && !previewOnly;
        if (generatingFiles) {
            addToast({
                uuid: "saveToast",
                type: "loader",
                content: trans("Saving your asset and generating files"),
            });
        }
        dispatch({
            type: SAVING_DATA,
        });
        return axios
            .put(`/api/v1/admin/assets/${assetUuid}`, {
                ...assetAdmin.asset,
                previewOnly,
            })
            .then(({ data: { data } }) => {
                if (generatingFiles) {
                    toastDispatch({ type: "removeToast", uuid: "saveToast" });
                }
                dispatch({
                    type: LOADED_DATA,
                    data,
                    assetsPath,
                });

                setRefreshSearch(true);

                addToast({
                    type: "success",
                    content: trans("Asset saved"),
                    showFor: 5000,
                });

                return data;
            })
            .catch((e) => {
                if (e.response && e.response.data) {
                    if (generatingFiles) {
                        toastDispatch({
                            type: "removeToast",
                            uuid: "saveToast",
                        });
                    }
                    setErrors(e.response.data.errors);

                    const errorTabs = updateSectionErrors(
                        e.response.data.errors
                    );
                    const errorTab = tabs.findIndex((tab) => {
                        return errorTabs.includes(tab.alias);
                    });

                    history.push(
                        `${assetsPath}/${assetUuid}/edit/${tabs[errorTab].alias}`
                    );

                    addToast({
                        type: "alert",
                        content:
                            e.response && e.response.data
                                ? e.response.data.message
                                : trans("An error has occurred"),
                        showFor: 5000,
                    });
                }
                dispatch({
                    type: FINISH_SAVING_DATA,
                });

                if (generatingFiles) {
                    toastDispatch({
                        type: "removeToast",
                        uuid: "saveToast",
                    });
                }
                if (rethrow) {
                    throw e;
                }
            });
    };

    const generateTabs = (assetAdmin, saveUpdatedAsset) => {
        const { asset } = assetAdmin;
        let tabs = [
            {
                alias: "general",
                component: (
                    <NamingAndGeneralSetup
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Overview")}
                                section="general"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(`${assetsPath}/${asset.uuid}/edit`);
                        }}
                        assetAdmin={assetAdmin}
                        config={config}
                        asset={asset}
                        baseAsset={asset}
                        standardOnChange={standardOnChange}
                        errors={errors}
                    />
                ),
            },
        ];

        if (asset.editable) {
            tabs.push({
                alias: "editor",
                component: (
                    <Editor
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Editor")}
                                section="editor"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/edit/editor`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={asset}
                        baseAsset={asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={false}
                        editRouteMatch={match}
                        saveUpdatedAsset={saveUpdatedAsset}
                    />
                ),
            });
        }

        if (asset.microsite) {
            tabs.push({
                alias: "site-settings",
                component: (
                    <SiteSettingsEditor
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Settings")}
                                section="site-settings"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/edit/site-settings`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={false}
                    />
                ),
            });
            tabs.push({
                alias: "navigation",
                component: (
                    <NavigationEditor
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Navigation")}
                                section="navigation"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/edit/navigation`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={asset}
                        standardOnChange={standardOnChange}
                    />
                ),
            });
        }

        //Finalise tabs with the availability last
        tabs.push({
            alias: "availability",
            component: (
                <Availability
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Availability")}
                            section="availability"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => {
                        history.push(
                            `${assetsPath}/${asset.uuid}/edit/availability`
                        );
                    }}
                    assetAdmin={assetAdmin}
                    asset={assetAdmin.asset}
                    baseAsset={asset}
                    standardOnChange={standardOnChange}
                    groups={groups}
                />
            ),
        });

        return tabs;
    };

    if (!assetAdmin.assetLoaded) {
        return <Loading align="center" padding="1rem" />;
    }

    const tabs = generateTabs(assetAdmin, saveUpdatedAsset);
    //Work out what step we're on based on the path
    const tabIndex = match
        ? tabs.findIndex((tab) => tab.alias === match.params.step)
        : -1;
    const currentStep = tabIndex !== -1 ? tabIndex : 0;
    const { asset } = assetAdmin;

    const { page } = useParams();

    return (
        <React.Fragment>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Editing")}{" "}
                        <HeaderEmphasis>
                            {asset.name}{" "}
                            {asset.versioning_enabled == "1" && (
                                <VersionNumber>{`(v${asset.versionNumber})`}</VersionNumber>
                            )}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                {/* <Row style={{ width: "80%" }}> */}

                <IconTextButton
                    as={Link}
                    to={`${assetsPath}/view/${assetUuid}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    onClick={(e) =>
                        confirmHappyToLeave(
                            assetAdmin.assetEdited,
                            asset.files_ready,
                            e
                        )
                    }
                >
                    {trans("Back to asset")}
                </IconTextButton>

                <IconTextButton
                    as={Link}
                    to={`${assetsPath}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    onClick={(e) =>
                        confirmHappyToLeave(
                            assetAdmin.assetEdited,
                            asset.files_ready,
                            e
                        )
                    }
                >
                    {trans("Back to asset library")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Column>
                    <Tabordion
                        current={currentStep}
                        unmounts={true}
                        style={{ background: "none" }}
                    >
                        {tabs.map((tab) => tab.component)}
                    </Tabordion>
                </Column>
            </Row>

            <Panel style={{ position: "sticky", bottom: 0 }}>
                <Row>
                    <Column style={{ textAlign: "right" }}>
                        {assetAdmin.assetEdited ||
                        !assetAdmin.asset.files_ready ? (
                            <Button
                                as={Link}
                                to={`${assetsPath}/view/${assetUuid}`}
                                type={"alert"}
                                onClick={(e) =>
                                    confirmHappyToLeave(
                                        assetAdmin.assetEdited,
                                        assetAdmin.asset.files_ready,
                                        e
                                    )
                                }
                            >
                                {trans("Cancel")}
                            </Button>
                        ) : (
                            <IconTextButton
                                as={Link}
                                to={`${assetsPath}/view/${assetUuid}`}
                                rotation={180}
                                showCircle={false}
                                expanded={false}
                            >
                                {trans("Back to asset")}
                            </IconTextButton>
                        )}
                        <SubmitButton
                            style={{ margin: "0 1rem" }}
                            onClick={saveUpdatedAsset}
                            disabled={disableSaveButton()}
                            submitting={assetAdmin.assetSaving}
                        >
                            {trans("Save")}
                        </SubmitButton>
                        <SubmitButton
                            // style={{ float: "right" }}
                            onClick={(event) =>
                                saveUpdatedAsset(event, false, true).then(
                                    (data) => {
                                        history.push(
                                            `${assetsPath}/view/${assetUuid}`
                                        );
                                    }
                                )
                            }
                            disabled={disableSaveButton()}
                            submitting={assetAdmin.assetSaving}
                        >
                            {trans("Save and Finish")}
                        </SubmitButton>
                        {assetAdmin.asset.microsite &&
                            !!Object.keys(assetAdmin.asset.configuration.pages)
                                .length && (
                                <SubmitButton
                                    style={{
                                        float: "right",
                                        margin: "0 1rem",
                                    }}
                                    disabled={disableSaveButton()}
                                    onClick={(event) =>
                                        saveUpdatedAsset(event).then((data) => {
                                            const win = window.open(
                                                `/presentations/${assetUuid}/${
                                                    page ? page : ""
                                                }`,
                                                "_blank"
                                            );
                                            if (win) {
                                                win.focus();
                                            }
                                        })
                                    }
                                    submitting={assetAdmin.assetSaving}
                                >
                                    {trans("Save and Preview")}
                                </SubmitButton>
                            )}
                    </Column>
                </Row>
            </Panel>

            {/* <Panel style={{ position: "sticky", bottom: 0 }}>
                <Row>
                    <Column>
                        <IconTextButton
                            as={Link}
                            to={`${assetsPath}/view/${assetUuid}`}
                            rotation={180}
                            showCircle={false}
                            expanded={false}
                            onClick={e =>
                                confirmHappyToLeave(
                                    assetAdmin.assetEdited,
                                    asset.files_ready,
                                    e
                                )
                            }
                        >
                            {trans("Back to asset")}
                        </IconTextButton>
                        <SubmitButton
                            onClick={saveUpdatedAsset}
                            submitting={assetAdmin.assetLoading}
                            style={{ float: "right" }}
                        >
                            {trans("Save Asset")}
                        </SubmitButton>
                    </Column>{" "}
                </Row>
            </Panel> */}
        </React.Fragment>
    );
};

export default EditAsset;
