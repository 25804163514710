import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Row,
    Column,
    Panel,
    Image,
    IconTextButton,
    LoadingIconSmall,
    Badge,
    FileIcon,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    SearchResultSummary,
    SearchResultBadge,
    ShowMore,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
export const AssetGrid = styled.section`
    padding: 0 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(
        ${({ panelSize = {}, minColumnWidth = 200 }) =>
            Math.floor(panelSize.width / minColumnWidth)},
        minmax(${({ minColumnWidth = 200 }) => minColumnWidth}px, 1fr)
    );
    grid-auto-rows: calc(
        ${({ panelSize = {}, minRowHeight = 290, maxRowHeight = 340 }) => {
                const maxAvailableRows = Math.floor(
                    panelSize.height / minRowHeight
                );
                const rowHeight = panelSize.height / maxAvailableRows;

                const constrainedRowHeight =
                    rowHeight > maxRowHeight
                        ? maxRowHeight
                        : rowHeight < minRowHeight
                        ? minRowHeight
                        : rowHeight;
                return constrainedRowHeight;
            }}px - 1rem
    );
`;
export const AssetResultWrapper = styled.aside`
    margin-bottom: 0.5rem;
`;
const AssetResultItem = (props) => {
    const {
        loaded,
        asset,
        canEdit,
        match: { path },
        onClick = null,
        assetsPath,
        margin,
        ...rest
    } = props;

    const getImageSrc = () => {
        let imageSrc = `${asset.thumbnail}`;
        if (
            asset.asset_type === "inDesign" ||
            asset.asset_type?.slug === "inDesign"
        ) {
            imageSrc = `${process.env.REACT_APP_IN_DESIGN_URL}/output/${asset.uuid}/${asset.uuid}.png?${asset.updated_at}`;
        }
        return imageSrc;
    };

    const viewAsset = (event, asset) => {
        event.preventDefault();
        if (!loaded) {
            return false;
        }
        props.history.push(`${assetsPath}/view/${asset.uuid}`);
    };

    return (
        <SearchResultWrapper
            textAlign="center"
            style={{
                display: "flex",
                padding: 0,
                margin: margin || "0rem",
                height: "100%",
            }}
            boxShadow={"none"}
        >
            <Link
                onClick={(event) => viewAsset(event, asset)}
                to={`${assetsPath}/view/${asset.uuid}`}
            >
                <SearchResultImageWrapper>
                    {loaded ? (
                        asset.thumbnail === null ? (
                            <FileIcon colorBehind={props.theme.colors.gray200}>
                                {asset.main_file_extension}
                            </FileIcon>
                        ) : (
                            <SearchResultImage
                                src={getImageSrc()}
                                alt={asset.name}
                            />
                        )
                    ) : (
                        <LoadingIconSmall></LoadingIconSmall>
                    )}
                </SearchResultImageWrapper>
                {/**
                    TODO: this badge needs to be wrapped under which status to show and that needs 
                    to come from configuration also possible style to highlight important 
                    eg. internal / external, approved / rejected...
                */}
                <SearchResultBadge rounded={false} type="secondary">
                    {asset.status_display}
                </SearchResultBadge>
            </Link>

            <SearchResultButton
                onClick={(event) => viewAsset(event, asset)}
                showCircle={rest.theme.search.icons.showCircle}
                iconBefore={false}
                doHover={false}
                icon={rest.theme.search.icons.searchIcon}
                iconColor={rest.theme.search.icons.color}
                iconSize={rest.theme.search.icons.size}
            >
                <SearchResultTitle title={asset.name}>
                    <ShowMore more=" " lines={3}>
                        {loaded && asset.name}
                    </ShowMore>
                </SearchResultTitle>
                {loaded && asset["media_type_name"] && (
                    <SearchResultSummary title={asset["media_type_name"]}>
                        {trans("Type: ")} {asset["media_type_name"]}
                    </SearchResultSummary>
                )}
            </SearchResultButton>
        </SearchResultWrapper>
    );
};
export default withTheme(withRouter(AssetResultItem));
