import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikCheckboxTree,
    Panel,
    Loading,
    Tabordion,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import {
    PreviewImage,
    TabordionGroup,
    LocaleSwitcher,
} from "../components/index";
import DashboardPanelRoleSection from "./DashboardPanelRoleSection";

const schema = Yup.object().shape({
    team_id: Yup.number().nullable(),
    parent_role_id: Yup.number().nullable(),
    name: Yup.string().required("Required"),
    type: Yup.string().nullable(),
    permissions: Yup.array(),
    can_send_communication_to: Yup.number(),
    dashboard_panels: Yup.array(),
});

export type Role = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    alias?: string;
    locale?: string;
};

export const RoleForm = ({ item, submitForm }) => {
    const [teams, setTeams] = useState([]);
    const [teamsLoaded, setTeamsLoaded] = useState(false);
    const [roles, setRoles] = useState([]);
    const [rolesLoaded, setRolesLoaded] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [dashboards, setDashboards] = useState([]);
    const [dashboardsLoaded, setDashboardsLoaded] = useState(false);
    const [dashboardPanels, setDashboardPanels] = useState([]);
    const [dashboardPanelsLoaded, setDashboardPanelsLoaded] = useState(false);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        axios.get(`/api/v1/admin/dashboard/options`).then(({ data }) => {
            setDashboards(data.data);
            setDashboardsLoaded(true);
        });
        axios
            .get(`/api/v1/admin/dashboard-panel?optionList=true`)
            .then(({ data }) => {
                setDashboardPanels(data.data);
                setDashboardPanelsLoaded(true);
            });

        axios.get(`/api/v1/admin/team?optionList=true`).then(({ data }) => {
            setTeams(data.data);
            setTeamsLoaded(true);
        });

        axios.get(`/api/v1/admin/role?optionList=true`).then(({ data }) => {
            setRoles(data.data);
            setRolesLoaded(true);
        });

        axios
            .get(`/api/v1/admin/permissions/permissions-tree`)
            .then(({ data }) => {
                setPermissions(data.data);
                setPermissionsLoaded(true);
            });
    }, []);

    const handleSubmit = (
        values: Role,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                locale: item.locale,
                team_id: item.team?.id,
                name: item.name,
                type: item.type,
                parent_role_id: item.parent?.id,
                permissions: item.permissions,
                can_send_communication_to: item.can_send_communication_to,
                dashboard_panels:
                    item && Array.isArray(item.dashboard_panels)
                        ? item.dashboard_panels
                        : [],
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <FormRow
                                    name="locale"
                                    label={trans("Locale")}
                                    helpText={trans("Optional")}
                                    error={errors.locale}
                                >
                                    <LocaleSwitcher
                                        item={item}
                                        setFieldValue={setFieldValue}
                                        //@ts-ignore
                                        values={values}
                                    />
                                </FormRow>
                                <FormRow
                                    name="team_id"
                                    label={trans("Team")}
                                    helpText={trans("Optional")}
                                    error={errors.team_id}
                                >
                                    <ContentBlockLoader loaded={teamsLoaded}>
                                        <FormikSelect
                                            name="team_id"
                                            value={values.team_id}
                                            options={teams}
                                        />
                                    </ContentBlockLoader>
                                </FormRow>
                                <FormRow
                                    name="name"
                                    label={trans("Name")}
                                    helpText={trans("Required")}
                                    error={errors.name}
                                >
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                    />
                                </FormRow>
                                <FormRow
                                    name="parent_role_id"
                                    label={trans("Parent Role")}
                                    helpText={trans("Optional")}
                                    error={errors.parent_role_id}
                                >
                                    <ContentBlockLoader loaded={rolesLoaded}>
                                        <FormikSelect
                                            name="parent_role_id"
                                            value={values.parent_role_id}
                                            options={roles}
                                        />
                                    </ContentBlockLoader>
                                </FormRow>
                                <FormRow
                                    name="type"
                                    label={trans("Type")}
                                    helpText={trans("Optional")}
                                    error={errors.type}
                                >
                                    <FormikInput
                                        name="type"
                                        value={values.type}
                                    />
                                </FormRow>
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Permissions")}</span>}
                                onTabClick={() => setTab(1)}
                            >
                                <FormRow
                                    name="permissions"
                                    label={trans("Application Permissions")}
                                    helpText={trans(
                                        "Please select the permissions for the role"
                                    )}
                                    error={errors.permissions}
                                >
                                    <ContentBlockLoader
                                        loaded={permissionsLoaded}
                                    >
                                        <FormikCheckboxTree
                                            name="permissions"
                                            value={values.permissions}
                                            options={permissions}
                                            selectChildren={true}
                                            allowListView={true}
                                            showWrapper={true}
                                            maxHeight={400}
                                        />
                                    </ContentBlockLoader>
                                </FormRow>
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Communication")}</span>}
                                onTabClick={() => setTab(2)}
                            >
                                <FormRow
                                    name="can_send_communication_to"
                                    label={trans("Can Communicate To")}
                                    helpText={trans("Required")}
                                    error={errors.can_send_communication_to}
                                >
                                    <FormikSelect
                                        name="can_send_communication_to"
                                        value={values.can_send_communication_to}
                                        options={[
                                            { text: "Yes", value: 1 },
                                            { text: "No", value: 0 },
                                        ]}
                                        showError={false}
                                    />
                                </FormRow>
                            </TabordionGroup>
                            <TabordionGroup
                                heading={
                                    <span>{trans("Dashboard Panels")}</span>
                                }
                                onTabClick={() => setTab(3)}
                            >
                                <DashboardPanelRoleSection
                                    dashboards={dashboards}
                                    dashboardsLoaded={dashboardsLoaded}
                                    dashboardPanels={dashboardPanels}
                                    dashboardPanelsLoaded={
                                        dashboardPanelsLoaded
                                    }
                                    roleDashboards={values.dashboard_panels}
                                    onChange={(newPanels) => {
                                        // console.log({ newPanels });
                                        setFieldValue(
                                            "dashboard_panels",
                                            newPanels
                                        );
                                    }}
                                />
                            </TabordionGroup>
                        </Tabordion>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RoleForm;
