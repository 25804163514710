import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
} from "@cortexglobal/rla-components";
import { useState } from "react";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const AssetDownloadsListingConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    filterBy,
    total,
    perSlide,
    displayType,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title
            ? title
            : intl.formatMessage({ id: "Asset Downloads Listing" }),
        filterBy: filterBy ? filterBy : "",
        total: total ? total : 5,
        perSlide: perSlide ? perSlide : 5,
    });

    const options = [
        {
            value: "downloads-by-business",
            text: intl.formatMessage({ id: "Downloads by business" }),
        },
        {
            value: "most-downloaded",
            text: intl.formatMessage({ id: "Most downloaded" }),
        },
    ];

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder="Custom Title"
                        onChange={onChange}
                    />
                    <SelectField
                        name="filterBy"
                        label={trans("Filter")}
                        value={state.filterBy}
                        options={options}
                        onChange={onChange}
                        expanded
                    />
                    <InputField
                        type="number"
                        name="total"
                        value={state.total}
                        label={trans("Number of Assets")}
                        onChange={onChange}
                    />
                    {displayType && displayType === "carousel" && (
                        <InputField
                            type="number"
                            name="perSlide"
                            value={state.perSlide}
                            label={trans("Assets per slide")}
                            onChange={onChange}
                        />
                    )}
                    <Button type="submit">{trans("Save Panel")}</Button>
                </form>
            </Column>
        </Row>
    );
};

AssetDownloadsListingConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default AssetDownloadsListingConfiguration;
