import React from "react";
import PropTypes from "prop-types";
//import 'react-select/dist/react-select.css';
// import makeAnimated from "react-select/animated";
import FormLabel from "../styledElements/label";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import Creatable from "react-select/creatable";
import { withTheme } from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { transparentize } from "@cortexglobal/cortex-utilities";
import { isEmpty } from "lodash";

export const generateCustomStyles = (props) => {
    return {
        option: (provided, { isDisabled, isFocused, isSelected }) => {
            const { limit, value } = props;
            const limitReached = limit && value.length >= limit;
            return {
                ...provided,
                cursor: isDisabled || limitReached ? "not-allowed" : "default",
                //TODO - Reinstate this once we sort color issues (e.g. select state doesn't work)
                // backgroundColor: isSelected
                //     ? props.theme.select.backgroundColor
                //     : isFocused
                //     ? props.theme.select.focus
                //     : null,
            };
        },
        container: (provided, state) => ({
            ...provided,
            marginBottom: props.marginBottom ? props.marginBottom : "1.2rem",
            minHeight: props.height ? props.height : 30,
            minWidth: props.width ? props.width : "120px",
            fontWeight: `${props.theme.input.fontWeight}`,
        }),
        control: (provided, state) => {
            return {
                ...provided,
                // height: props.height ? props.height : 30,
                minHeight: `${props.height ? props.height : 30}px`,
                backgroundColor: props.theme.input.background,
                border: `1px solid ${
                    props.error
                        ? props.theme.input.error.borderColor
                        : props.theme.input.borderColor
                }`,
                borderRadius: `${props.theme.input.radius}`,
            };
        },
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color = props.theme.input.color;

            return { ...provided, opacity, transition, color };
        },
        multiValue: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided,
            backgroundColor: isSelected
                ? props.theme.input.borderColor
                : isFocused
                ? props.theme.input.focusColor
                : null,
        }),
        indicatorContainer: (
            provided,
            { isDisabled, isFocused, isSelected }
        ) => {
            return {
                ...provided,
                backgroundColor: isSelected
                    ? props.theme.input.borderColor
                    : isFocused
                    ? props.theme.input.focusColor
                    : null,
            };
        },
        valueContainer: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided,
            backgroundColor: isSelected
                ? props.theme.input.borderColor
                : isFocused
                ? props.theme.input.focusColor
                : null,
        }),
        menuPortal: (provided, state) => {
            return {
                ...provided,
                zIndex: 9999,
            };
        },
    };
};
const animatedComponents = makeAnimated();

class StyledSelectAdvanced extends React.Component {
    shouldKeyDownEventCreateNewOption = ({ keyCode }) => {
        return [9, 13, this.props.tags ? 188 : ""].indexOf(keyCode) != -1; // Create new options only on tab and enter
    };

    onChange = (selected) => {
        const { onChange, allowSelectAll, allOption, limit, value } =
            this.props;

        //If the limit is set, check if the limit is reached
        if (limit && value.length >= limit && selected.length > value.length) {
            return;
        }
        if (allowSelectAll) {
            if (
                selected !== null &&
                selected.length > 0 &&
                selected[selected.length - 1].value === allOption.value
            ) {
                let { options } = this.props;

                return onChange(
                    options.map((option) => {
                        option.label = option.text;
                        return option;
                    })
                );
            }
        }

        return onChange(selected);
    };

    findValueInOptions = (options, value) => {
        return options
            ? options
                  .filter((option) => {
                      if (Array.isArray(value)) {
                          return (
                              value.filter((value) => {
                                  return option.value === value;
                              }).length >= 1
                          );
                      }
                      return option.value === value;
                  })
                  .map((option) => {
                      if (isEmpty(option.label)) {
                          option.label = option.text;
                      }
                      return option;
                  })
            : "";
    };

    processValue = (options, value) => {
        if (options && options.length > 0 && options[0].options) {
            let foundOptions = [];
            if (Array.isArray(options)) {
                options.map((option) => {
                    const currentOption = this.findValueInOptions(
                        option.options,
                        value
                    );

                    if (
                        Array.isArray(currentOption) &&
                        !isEmpty(currentOption[0])
                    ) {
                        foundOptions = [...foundOptions, ...currentOption];
                    }
                });
            }

            return foundOptions;
        }

        return this.findValueInOptions(options, value);
    };

    render() {
        const {
            name,
            onChange,
            error,
            creatable,
            tags,
            value,
            multi,
            allowSelectAll,
            theme: cortexTheme,
            hideSelectedOptions,
            label,
            menuPlacement,
            async,
            emptyOption,
            ...rest
        } = this.props;

        let { options = [] } = this.props;
        const Component = creatable || tags ? Creatable : ReactSelect;

        const customStyles = generateCustomStyles(this.props);

        if (allowSelectAll) {
            options = [this.props.allOption, ...options];
        }
        const disabled = rest?.disabled || false;

        // console.log("SSA options 1 ", options);

        return (
            <>
                {label && (
                    <FormLabel style={{ marginBottom: "0.5rem" }} name={name}>
                        {label}
                    </FormLabel>
                )}
                <Component
                    {...rest}
                    isDisabled={disabled}
                    isMulti={multi}
                    styles={customStyles}
                    hideSelectedOptions={
                        allowSelectAll ? false : hideSelectedOptions
                    }
                    components={animatedComponents}
                    name={name}
                    options={options.map((option) => {
                        if (option.options) {
                            return option;
                        }
                        return {
                            value: option.value,
                            label: option.text,
                        };
                    })}
                    defaultValue={
                        async ? value : this.processValue(options, value)
                    }
                    placeholder={emptyOption || null}
                    value={async ? value : this.processValue(options, value)}
                    onChange={this.onChange.bind(this)}
                    shouldKeyDownEventCreateNewOption={
                        this.shouldKeyDownEventCreateNewOption
                    }
                    menuPlacement={menuPlacement}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        fontWeight: "normal",
                        colors: {
                            ...theme.colors,
                            primary25: transparentize(
                                cortexTheme.colors.accent,
                                0.6
                            ),
                            primary: cortexTheme.colors.accent,
                        },
                    })}
                />
            </>
        );
    }
}

StyledSelectAdvanced = withTheme(StyledSelectAdvanced);
StyledSelectAdvanced.displayName = "StyledSelectAdvanced";

StyledSelectAdvanced.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
                .isRequired,
        })
    ),
    allOption: PropTypes.shape({
        value: PropTypes.any,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
    }),
    labelProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
    }),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    async: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool,
    ]),
    height: PropTypes.number,
};

StyledSelectAdvanced.defaultProps = {
    allowSelectAll: false,
    allOption: {
        text: trans("Select All"),
        value: "*",
    },
    error: "",
    labelProps: {
        label: "",
    },
    height: 38,
    menuPlacement: "auto",
};

export default StyledSelectAdvanced;
