import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { parseISO } from "date-fns";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    getLocaleDateFormat,
    format,
    transparentize,
} from "@cortexglobal/cortex-utilities";
import {
    Button,
    IconTextButton,
    DatePickerInputField,
    FormLabel,
    Select,
} from "../../../../index";
import { baseInputStyle } from "../baseInput";

import { createPopper } from "@popperjs/core";
import PeriodSelector from "./PeriodSelector";

const DatePickerWrapper = styled.div`
    display: relative;
    border: 1px solid
        ${(props) =>
            props.error
                ? props.theme.input.error.borderColor
                : props.theme.input.borderColor};
    border-radius: ${({ theme }) => theme.input.radius};
    font-size: ${(props) => props.theme.input.fontSize};
    background: ${(props) => props.theme.input.background};
    color: ${(props) =>
        props.error ? props.theme.input.error.color : props.theme.input.color};
    .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: ${({ marginBottom }) =>
            marginBottom ? marginBottom : "1.2rem"};
        display: block;
    }
    .react-datepicker {
        border-radius: ${({ theme }) => theme.input.radius};
    }

    .react-datepicker__input-container {
        width: 100%;
    }

    .react-datepicker__day--in-range,
    .react-datepicker__day--keyboard-selected {
        background-color: ${(props) => props.theme.colors.accent};
    }
    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker.month-text--in-range, .react-datepicker_quarter-text--in-range, .react-datepicker_year-text-in-range) {
        background-color: ${(props) =>
            transparentize(props.theme.colors.accent, 0.6)};
    }

    .react-datepicker__header__dropdown--select {
        padding: 0.5rem 0.5rem;
        width: 100%;
        display: flex;
        .react-datepicker__month-dropdown-container--select {
            flex-grow: 1;
        }
        select {
            ${baseInputStyle}
            width: 100%;
            margin-bottom: 0;
            padding: 0.2rem;
        }
    }
`;

const DateSelector = styled.div`
    position: absolute;
    padding: 0.5rem 0 0 10rem;
    //Using an important to override the inline style applied by the popper
    margin: 0.5rem 0 0 2rem !important;
    /* min-width: 526px;
    min-height: 300px; */
    /* top: 0.5rem;
    left: 100px; */
    z-index: 1005;
    left: 0;
    /* display: ${({ isDateSelectorShowing }) =>
        isDateSelectorShowing ? "block" : "none"}; */
    opacity: ${({ isDateSelectorShowing }) =>
        isDateSelectorShowing ? "1" : "0"};
    transition: opacity 0.3s ease-in-out;

    pointer-events: ${({ isDateSelectorShowing }) =>
        isDateSelectorShowing ? "auto" : "none"};
`;

const DateSelectorContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: ${({ theme }) => theme.input.radius};
    background-color: ${({ theme }) => transparentize(theme.colors.white, 0.1)};
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2), -1px 3px 5px rgba(0, 0, 0, 0.1);
`;
const DatePickers = styled.div`
    display: flex;
`;

const ButtonRow = styled.section`
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
    align-items: flex-end;
`;
// const getValue = (selected, allowEmpty) => {
//     if (allowEmpty && selected == "") {
//         return null;
//     }
//     if (typeof selected === "string" && selected !== "") {
//         try {
//             selected = parseISO(selected);
//         } catch {
//             try {
//                 selected = new Date(selected);
//             } catch {}
//         }
//     }
//     if (selected instanceof Date && !isNaN(selected)) {
//         return selected;
//     } else {
//         return "";
//     }
// };

// const getDate = (date) => {
//     if (typeof date === "string" && date !== "") {
//         try {
//             date = parseISO(date);
//         } catch {
//             try {
//                 date = new Date(date);
//             } catch {}
//         }
//     }
//     if (date instanceof Date && !isNaN(date)) {
//         return date;
//     } else {
//         return "";
//     }
// };

const StyledDateRangePicker = ({
    value,
    selected,
    minDate,
    maxDate,
    allowEmpty,
    marginBottom,
    onChange,
    autoApply = false,
    dateFormat = getLocaleDateFormat(),
    showUnitsSelector = false,
    showPeriodSelector = true,
    fiscalYearPeriodSelector = false,
    fiscalYearOptions = {
        startDay: 1,
        startMonth: 3,
    },
    extraPeriods = [],
    onUnitsSelect,
    includeTime = false,
    units = "days",
    ...props
}) => {
    const intl = useIntl();
    const [startDate, setStartDate] = useState(value[0]);
    const [endDate, setEndDate] = useState(value[1]);

    useEffect(() => {
        if (autoApply) {
            handleChange();
        }
    }, [startDate, endDate, autoApply]);

    const [isDateSelectorShowing, setIsDateSelectorShowing] = useState(false);
    const [popper, setPopper] = useState({});
    const popupRef = React.createRef();
    const wrapperRef = React.createRef();

    useEffect(() => {
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    useEffect(() => {
        const startDate =
            typeof value[0] === "string" ? new Date(value[0]) : value[0];
        const endDate =
            typeof value[1] === "string" ? new Date(value[1]) : value[1];
        setStartDate(startDate);
        setEndDate(endDate);
    }, [value]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setIsDateSelectorShowing(false);
                document.body.style.overflow = "unset";
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleChange = () => {
        if (!includeTime) {
            const startDateNoTime = format(
                startDate.setHours(0, 0, 0, 0),
                "yyyy-MM-dd"
            );

            const endDateNoTime = format(
                endDate.setHours(0, 0, 0, 0),
                "yyyy-MM-dd"
            );

            onChange({ name, value: [startDateNoTime, endDateNoTime] });
        }
        onChange({ name, value: [startDate, endDate] });
    };
    // const handleTime = (date) => {
    //     if (!includeTime) {
    //         const newDate = new Date(date);
    //         newDate.setHours(0, 0, 0, 0);
    //         date = newDate;
    //     }
    //     return date;
    // };

    const handleShowDateSelector = (e) => {
        e.preventDefault();

        setIsDateSelectorShowing(!isDateSelectorShowing);

        document.body.style.overflow = "hidden";
        const rawPopper = createPopper(e.target, popupRef.current, {
            placement: "bottom-end",
        });
        setPopper(rawPopper.state);
    };

    // const handleStartDateChange = (event) => {
    //     const date = event.target.value;
    //     console.log(date);
    //     if (date instanceof Date && !isNaN(date)) {
    //         setStartDate(date);
    //     }
    // };
    let { styles, attributes } = popper;
    styles = styles && styles.popper ? styles.popper : {};
    attributes = attributes && attributes.popper ? attributes.popper : {};

    return (
        <DatePickerWrapper ref={wrapperRef} marginBottom={marginBottom}>
            <IconTextButton
                onClick={(event) => {
                    handleShowDateSelector(event);
                }}
                icon={faCalendarAlt}
                showCircle={false}
            >
                {/* {getDate(value[0])} - end date */}
                {format(startDate)} - {format(endDate)}
            </IconTextButton>
            <DateSelector
                ref={popupRef}
                style={{
                    ...styles,
                    // display: isDateSelectorShowing ? "block" : "none",
                }}
                isDateSelectorShowing={isDateSelectorShowing}
                {...attributes}
            >
                <DateSelectorContent>
                    {showUnitsSelector && typeof onUnitsSelect == "function" && (
                        <div style={{ zIndex: 2 }}>
                            <Select
                                name="units"
                                label={intl.formatMessage({
                                    id: "Units",
                                })}
                                value={units}
                                onChange={onUnitsSelect}
                                options={[
                                    {
                                        value: "days",
                                        text: intl.formatMessage({
                                            id: "Days",
                                        }),
                                    },
                                    {
                                        value: "weeks",
                                        text: intl.formatMessage({
                                            id: "Weeks",
                                        }),
                                    },
                                    {
                                        value: "months",
                                        text: intl.formatMessage({
                                            id: "Months",
                                        }),
                                    },
                                    {
                                        value: "quarters",
                                        text: intl.formatMessage({
                                            id: "Quarters",
                                        }),
                                    },
                                ]}
                            />
                        </div>
                    )}
                    <DatePickers>
                        <div style={{ marginRight: "0.5rem" }}>
                            {/* <FormLabel for="startDate">
                                {trans("Start Date")}
                            </FormLabel>
                            <DatePickerInputField
                                value={format(startDate, dateFormat)}
                                onChange={handleStartDateChange}
                                id="startDate"
                            /> */}
                            <ReactDatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={maxDate}
                                minDate={minDate}
                                inline
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>

                        <div
                            style={{
                                marginRight: showPeriodSelector
                                    ? "0.5rem"
                                    : "0",
                            }}
                        >
                            <ReactDatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={maxDate}
                                inline
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>

                        {showPeriodSelector && (
                            <div>
                                <PeriodSelector
                                    onChange={(startDate, endDate) => {
                                        setStartDate(startDate);
                                        setEndDate(endDate);
                                    }}
                                    fiscalYearPeriodSelector={
                                        fiscalYearPeriodSelector
                                    }
                                    fiscalYearOptions={fiscalYearOptions}
                                    extraPeriods={extraPeriods}
                                />
                            </div>
                        )}
                    </DatePickers>
                    <ButtonRow>
                        {!autoApply && (
                            <IconTextButton
                                style={{ marginRight: ".5rem" }}
                                type="secondary"
                                onClick={() => {
                                    setIsDateSelectorShowing(false);
                                    document.body.style.overflow = "unset";
                                }}
                                icon={"times"}
                                showCircle={false}
                            >
                                {trans("Cancel")}
                            </IconTextButton>
                        )}
                        <Button
                            onClick={() => {
                                setIsDateSelectorShowing(false);
                                document.body.style.overflow = "unset";
                                if (!autoApply) {
                                    handleChange();
                                }
                            }}
                        >
                            {autoApply ? trans("Close") : trans("Apply")}
                        </Button>
                    </ButtonRow>
                </DateSelectorContent>
            </DateSelector>
        </DatePickerWrapper>
    );
};

StyledDateRangePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initialValue: PropTypes.any,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyOption: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dateFormat: PropTypes.string,
    placeholder: PropTypes.string,
    allowEmpty: PropTypes.bool,
};
StyledDateRangePicker.defaultProps = {
    value: [],
    error: "",
    expanded: true,
    dateFormat: getLocaleDateFormat(),
    placeholder: "",
    allowEmpty: false,
};
export default StyledDateRangePicker;
