import React from "react";
import styled from "styled-components";

const UserSummaryWrapper = styled.aside`
    min-width: 8rem;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const ProfileImg = styled.div<{ avatar: string }>`
    background: url(${props => props.avatar});
    background-size: cover;
    background-position: 80% 65%;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin: 1rem auto;
`;

const UserName = styled.h4`
    margin: 0;
`;
interface UserProps {
    user: {
        name: string;
        avatar: string;
        group_name: string;
    };
}

export const CommentUserSummary = ({ user }: UserProps) => {
    return (
        <UserSummaryWrapper>
            <ProfileImg avatar={user.avatar} />
            <UserName>{user.name}</UserName>
            <small>{user.group_name}</small>
        </UserSummaryWrapper>
    );
};
