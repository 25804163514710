import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Row,
    Column,
    InputField,
    SelectField,
    Button,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

class TopPerformersConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customTitle: this.props.customTitle ? this.props.customTitle : "",
            timeframe: this.props.timeframe ? this.props.timeframe : "",
        };
    }
    onChange = (input) => {
        this.setState({ [input.name]: input.value });
    };
    submitForm = (event) => {
        event.preventDefault();
        this.props.submitPanel(this.props.panel, this.state);
    };
    render() {
        const metricOptions = [
            { value: "potential", text: "Customer Potential" },
            { value: "spend", text: "Customer Spend" },
            { value: "sales", text: "Merchant Sales" },
        ];

        const totalOptions = [
            { value: 10, text: "Top Ten" },
            { value: 5, text: "Top Five" },
            { value: 3, text: "Top Three" },
        ];

        return (
            <form onSubmit={this.submitForm}>
                <Row>
                    <Column>
                        <InputField
                            type="text"
                            name="customTitle"
                            value={this.state.customTitle}
                            label={trans("Custom Title")}
                            placeholder="Custom Title"
                            onChange={this.onChange}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column large={6}>
                        <SelectField
                            name="metric"
                            label={trans("Metric")}
                            value={this.state.metric}
                            options={metricOptions}
                            onChange={this.onChange}
                            expanded
                        />
                    </Column>
                    <Column large={6}>
                        <SelectField
                            name="total"
                            label={trans("Show Number")}
                            value={this.state.total}
                            options={totalOptions}
                            onChange={this.onChange}
                            expanded
                        />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <Button type="submit">{trans("Save Panel")}</Button>
                    </Column>
                </Row>
            </form>
        );
    }
}

TopPerformersConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default TopPerformersConfiguration;
