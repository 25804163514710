import React, { Component } from "react";

import Calendar from "./Calendar";

class EventCalendar extends Component {
    render() {
        return <Calendar {...this.props} />;
    }
}

export default EventCalendar;
