import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import {
    LoginForm,
    ForgottenPasswordForm,
    ResetPasswordForm,
    RegisterForm,
    Activate,
    CortexAuthSSO,
} from "@cortexglobal/cortex-auth-react-router-dom";

import Header from "./PublicHeader";
import Footer from "./PublicFooter";

const PageWrapper = styled.div`
    display: flex;
    font-family: Helvetica;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #fff;
    background-size: cover;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0,#ddd 100%);
    font-size: 16px;
`;

const Container = styled.div`
    justify-content: center;
    align-items: center;
    width: 75%;
    max-width: 550px;
    margin: 0 auto;
    padding: 2.5rem 0 0;
    background: #fff;
    color: #484848;
    max-width: 450px;
    border: #ddd;
`;

const Content = styled.div`
    padding: 40px;
`;

class PublicApp extends Component {
    render() {
        return (
            <Switch>
                <PageWrapper>
                    <Container>
                        <Header />

                        <Content>
                            <Route path="/auth/sso" component={CortexAuthSSO} />
                            <Route path="/auth/register" component={RegisterForm} />

                            <Route path="/auth/login" component={LoginForm} />
                            <Route
                                path="/auth/forgotten"
                                component={ForgottenPasswordForm}
                            />
                            <Route
                                path="/auth/reset"
                                component={ResetPasswordForm}
                            />
                            <Route
                                path="/auth/activate/:code"
                                component={Activate}
                            />
                        </Content>
                        <Footer />
                    </Container>
                </PageWrapper>
            </Switch>
        );
    }
}

export default PublicApp;
