import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { Collapse } from "react-collapse";
import FilterSummary from "./FilterSummary";
import StandardFilters from "./StandardFilters";
import ApplyFiltersRow from "./ApplyFiltersRow";
import SavedFilters from "./SavedFilters";
import { useFilters, Column } from "../../index";

const FilterPanelWrapper = styled.div`
    margin-bottom: ${({ expanded }) => (expanded ? 1 : 0)}rem;
    background-color: ${({ theme }) => theme.filters.panel.backgroundColor};
    color: ${({ theme }) => theme.filters.panel.color};
`;

//Set filters and selected status at this level, then we can handle all state
//changes, until required to pass back up the tree.  This will allow us to
//choose whether to pass back up automatically (pinpoint style) or only on
//button press
let FilterPanel = ({
    children,
    permanentSummary,
    expanded,
    filters,
    selectedFilters: currentlySelectedFilters,
    onUpdate,
    onApply,
    onClear,
    showApply,
    showSummary = false,
    resultCount,
    searchTerm,
    searchClear,
    config = {},
    user,
    filterAreaSlug = "",
    savedFilter = null,
}) => {
    const { selectedFilters, updateFilters, clearFilters, applyFilters } =
        useFilters({
            filters,
            currentlySelectedFilters,
            onUpdate,
            onApply,
            onClear,
        });

    const showSavedFilters =
        config.allow_saved_filters && user && user.isGuest !== true;
    return (
        <FilterPanelWrapper expanded={expanded}>
            <Collapse
                isOpened={expanded}
                // initialStyle={{ backgroundColor: "#d8d8d8" }}
            >
                {children ? (
                    children({
                        updateFilters,
                        clearFilters,
                        selectedFilters,
                        filters,
                    })
                ) : (
                    <StandardFilters
                        onClear={clearFilters}
                        onUpdate={updateFilters}
                        onApply={applyFilters}
                        filters={filters}
                        selectedFilters={selectedFilters}
                    />
                )}
                {showApply && (
                    <ApplyFiltersRow
                        onApply={applyFilters}
                        onClear={clearFilters}
                        resultCount={resultCount}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        singleColumn={false}
                    >
                        {showSavedFilters && (
                            <>
                                <Column
                                // style={{
                                //     marginTop: "1rem",
                                //     marginBottom: "1rem",
                                // }}
                                >
                                    <SavedFilters
                                        onApply={onApply}
                                        filters={filters}
                                        selectedFilters={selectedFilters}
                                        filterAreaSlug={filterAreaSlug}
                                        currentSavedFilter={savedFilter}
                                    />{" "}
                                </Column>
                            </>
                        )}
                    </ApplyFiltersRow>
                )}
            </Collapse>
            <Collapse isOpened={showSummary && (permanentSummary || !expanded)}>
                <FilterSummary
                    onApply={onApply}
                    onUpdate={onUpdate}
                    onClear={clearFilters}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    searchTerm={searchTerm}
                    searchClear={searchClear}
                    addLineBreaks={false}
                    addSeparator={true}
                />
            </Collapse>
        </FilterPanelWrapper>
    );
};

/*
<FilterPanel expanded={true} filters={filters}>
    {({filters}, selectedFilters)=>{
        return <StandardFilters filters={filters} selectedFilters={selectedFilters} />
        <FilterSummary filters={filters} selectedFilters={selectedFilters} />
    }}
</FilterPanel>
*/

/*
<FilterPanel
    expanded={true}
    filters={filters}
    selectedFilters={selectedFilters}
    onUpdate={updateFilters}
    onClear={clearFilters}
/>
*/

/*
<FilterPanel
    expanded={true}
    filters={filters}
    selectedFilters={selectedFilters}
>
        <CustomFilterPanel
            onUpdate={updateFilters}
            onClear={clearFilters}
        />
</FilterPanel>

*/

FilterPanel = withTheme(FilterPanel);

FilterPanel.displayName = "FilterPanel";

const filterShape = PropTypes.shape({
    alias: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        "MultiCheckBox",
        "MultiSelect",
        "Select",
        "RadioGroup",
        "Range",
    ]).isRequired,
    comparison: PropTypes.oneOf(["===", "!==", "<", "<=", ">", ">="]),
    defaultValue: PropTypes.any,
    options: PropTypes.arrayOf({
        value: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
    }),
});
FilterPanel.propTypes = {
    /** Filters: Need to be of a certain shape.*/
    filters: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape(filterShape)),
        PropTypes.array,
    ]),
    // filters: PropTypes.objectOf(PropTypes.shape(filterShape)).isRequired,
    selectedFilters: PropTypes.object,
    children: PropTypes.func,
    permanentSummary: PropTypes.bool,
    expanded: PropTypes.bool,
    onUpdate: PropTypes.func,
    onApply: PropTypes.func,
    showApply: PropTypes.bool,
    /** Used to display a result count if the filters would be applied */
    resultCount: PropTypes.oneOfType([PropTypes.number]),
};

FilterPanel.defaultProps = {
    selectedFilters: {},
    permanentSummary: false,
    expanded: false,
    onApply: () => {},
    autoApply: false,
    showApply: true,
};

export default FilterPanel;
