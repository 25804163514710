import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { faLink, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
    Panel,
    Row,
    Column,
    Button,
    IconTextButton,
    TableList,
    Modal,
    Badge,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import RelateTaskForm from "./RelateTaskForm";

export function getRelatedTasks(taskUuid) {
    return axios.get(`/api/v1/planner/tasks/${taskUuid}/related`);
}

export function relateTask(taskUuid, relatedTask) {
    return axios.post(`/api/v1/planner/tasks/${taskUuid}/related`, relatedTask);
}

export function removeRelatedTask(taskUuid, relatedTask) {
    return axios.delete(
        `/api/v1/planner/tasks/${taskUuid}/related/${relatedTask.uuid}`
    );
}
class RelatedTasks extends Component {
    state = {
        showRelateTask: false,
        relatedTasks: [],
    };
    componentDidMount() {
        getRelatedTasks(this.props.task.uuid)
            .then((response) => {
                //console.log(response.data.data);
                const data = response.data.data;
                //console.log(data);

                this.setState({
                    relatedTasks: data,
                });
            })
            .catch((e) => e);
    }

    toggleState = () => {
        this.setState((state) => ({
            showRelateTask: !state.showRelateTask,
        }));
    };
    relateTask = (taskUuid, relatedTask) => {
        relateTask(taskUuid, relatedTask).then((response) => {
            //console.log(response.data.data);
            const data = response.data.data;
            //console.log(data);
            this.setState({ showRelateTask: false, relatedTasks: data });
        });
    };
    removeRelatedTask = (taskUuid, relatedTask) => {
        removeRelatedTask(taskUuid, relatedTask).then((response) => {
            //console.log(response.data.data);
            const data = response.data.data;
            //console.log(data);
            this.setState({ relatedTasks: data });
        });
    };
    render() {
        const { task, showHeading, theme, addTaskPath, viewTask } = this.props;
        const { relatedTasks } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Column medium={3}>
                        {showHeading && <h3>{trans("Related Campaigns")}</h3>}
                        &nbsp;
                    </Column>
                    {task.can_admin_details && (
                        <Column medium={9} style={{ textAlign: "right" }}>
                            <IconTextButton
                                as={Link}
                                to={`${addTaskPath}?relatedUuid=${task.uuid}`}
                                icon={faPlus}
                                showCircle={false}
                                onClick={this.toggleState}
                            >
                                {trans("Create New Related")}
                            </IconTextButton>
                            <IconTextButton
                                type="linkStyle"
                                icon={faLink}
                                showCircle={false}
                                onClick={this.toggleState}
                                style={{ paddingRight: 0 }}
                            >
                                {trans("Relate Existing Campaign")}
                            </IconTextButton>
                        </Column>
                    )}
                </Row>
                <TableList>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "10%",
                                }}
                            >
                                #
                            </th>
                            <th style={{ width: "30%" }}>
                                {trans("Campaign Name")}
                            </th>
                            <th style={{ width: "30%" }}>
                                {trans("Date From")}
                            </th>
                            <th style={{ width: "30%" }}>{trans("Date To")}</th>
                            <th
                                style={{
                                    width: "auto",
                                }}
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatedTasks.length <= 0 ? (
                            <tr>
                                <td colSpan="100%">{trans("No Campaigns")}</td>
                            </tr>
                        ) : (
                            relatedTasks.map((relatedTask) => {
                                return (
                                    <tr key={relatedTask.uuid}>
                                        <td>
                                            <Badge
                                                backgroundColor={
                                                    theme.colors.activities.plan
                                                }
                                            >
                                                {relatedTask.ref}
                                            </Badge>
                                        </td>
                                        <td>{relatedTask.name}</td>
                                        <td>
                                            {moment(
                                                relatedTask.start_date
                                            ).format("LL")}
                                        </td>
                                        <td>
                                            {moment(
                                                relatedTask.end_date
                                            ).format("LL")}
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {task.can_admin_details && (
                                                <Button
                                                    type="alert"
                                                    onClick={() => {
                                                        return this.removeRelatedTask(
                                                            task.uuid,
                                                            relatedTask
                                                        );
                                                    }}
                                                >
                                                    X
                                                </Button>
                                            )}

                                            <Button
                                                type="secondary"
                                                onClick={() => {
                                                    return viewTask(
                                                        relatedTask
                                                    );
                                                }}
                                            >
                                                {trans("View")}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </TableList>
                <Modal
                    visible={this.state.showRelateTask}
                    onClose={this.toggleState}
                >
                    <RelateTaskForm task={task} relateTask={this.relateTask} />
                </Modal>
            </React.Fragment>
        );
    }
}

RelatedTasks.propTypes = {
    task: PropTypes.object,
};

export default RelatedTasks;
