import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unionBy } from "lodash";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { Layout, Footnote as FootnoteInterface } from "../interfaces/layout";
import MicrositeContext from "../MicrositeContext";
import { DraftJSOutput } from "@cortexglobal/rla-components";
import {
    CloseButton,
    Subtitle,
    SlideContainer,
} from "../components/slideComponents";

// export const FootnoteWrapper = styled.div<FootnoteWrapperProps>`
//     background: ${(props) => {
//         if (props?.backgroundImage)
//             return `url(${props?.backgroundImage}) no-repeat fixed center`;
//         if (props?.backgroundColor) return props.backgroundColor;
//         return "white";
//     }};
//     /* background-size: cover; */
//     color: ${(props) => (props.textColor ? props.textColor : "black")};
//     padding: 2em 3em;
//     border-radius: 3px;
//     width: 100%;
//     height: 90%;
//     position: absolute;
//     top: 0;
//     z-index: 999;
//     font-size: 0.6em;
//     overflow-y: auto;
// `;
export const FootnoteWrapper = styled(SlideContainer)`
    position: absolute;
    top: 0;
    z-index: 999;
`;
export const Footnote = styled.span`
    color: inherit;
    display: block;
    font-size: 0.6em;
`;

export const StyledA = styled.a`
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    display: flex;
    align-items: baseline;
    margin-right: 1rem;
`;

interface FootnoteProps {
    globalTheming: { [key: string]: any };
    globalSettings: { [key: string]: any };
    content: { [key: string]: any };
    groupName?: string;
    pageName?: string;
}

export const generateFootnotes = (
    content: Layout["content"]
): FootnoteInterface[] => {
    return Object.values(content).reduce(
        (footnotesArray, { footnotes, type, value }) => {
            let newFootnotes;

            // If content type is repeater, look through all repeater values for footnotes
            if (type === "repeater") {
                newFootnotes = value
                    ? value.reduce(
                          (accumulator: [], value: { footnotes: [] }) => {
                              if (!value || !value.footnotes)
                                  return accumulator;
                              return [...accumulator, ...value.footnotes];
                          },
                          []
                      )
                    : [];

                // If content type is sequenced video, look through video content for footnotes
            } else if (type === "sequenced-video") {
                newFootnotes =
                    value && value.captions
                        ? value.captions.reduce(
                              (accumulator: [], value: { footnotes: [] }) => {
                                  if (!value || !value.footnotes)
                                      return accumulator;
                                  return [...accumulator, ...value.footnotes];
                              },
                              []
                          )
                        : [];
            } else {
                if (!Array.isArray(footnotes)) {
                    return footnotesArray;
                }

                newFootnotes = footnotes.filter(
                    (footnote) =>
                        !footnotesArray.some(
                            (innerFootnote: FootnoteInterface) =>
                                innerFootnote.uuid === footnote.uuid
                        )
                );
            }
            return unionBy(footnotesArray, newFootnotes, "number");
        },
        []
    );
};
/**
 * Handle the rendering of a content block, allows for different outputs (e.g. draft-js)
 * also allows for footnotes etc.
 * @param param0
 */
export const Footnotes = ({
    content,
    groupName,
    pageName,
    globalSettings,
    globalTheming,
}: FootnoteProps) => {
    const { footnotesShowing, setFootnotesShowing, setHasFootnotes } =
        useContext(MicrositeContext);
    const forceFootnotes = useQueryString().get("force-footnotes");

    if (!globalSettings.useFootnotes) {
        if (setHasFootnotes) setHasFootnotes(false);
        return null;
    }
    const footnotes = generateFootnotes(content);

    if (footnotes.length <= 0) {
        if (setHasFootnotes) setHasFootnotes(false);
        return null;
    } else {
        if (setHasFootnotes) setHasFootnotes(true);
    }

    const closeFootnotes = () => {
        if (setFootnotesShowing) setFootnotesShowing(false);
    };
    const highlightColor = globalTheming?.highlightColor;

    return (
        <>
            {(footnotesShowing || forceFootnotes) && (
                <FootnoteWrapper
                    textColor={globalTheming?.textColor}
                    backgroundImage={globalTheming?.backgroundImage}
                    backgroundColor={globalTheming?.backgroundColor}
                >
                    {!forceFootnotes && (
                        <CloseButton onClick={closeFootnotes} />
                    )}

                    {groupName && pageName ? (
                        <Subtitle
                            highlightColor={highlightColor}
                            groupName={groupName}
                        >
                            {pageName} {trans(" - References")}
                        </Subtitle>
                    ) : (
                        <Subtitle highlightColor={highlightColor}>
                            {trans("References")}
                        </Subtitle>
                    )}
                    {footnotes
                        .filter((footnote) => !!footnote.text)
                        .sort((a, b) => a.number - b.number)
                        .map((footnote) => {
                            // let footnotePrefix = index > 0 ? "," : "";
                            return (
                                <Footnote
                                    key={`${footnote.link}-${footnote.text}`}
                                >
                                    <StyledA
                                        href={footnote.link}
                                        target="_blank"
                                    >
                                        {/* {footnotePrefix} */}
                                        <span style={{ marginRight: "1rem" }}>
                                            {footnote.number}
                                        </span>
                                        -
                                        <DraftJSOutput
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "1rem",
                                            }}
                                        >
                                            {footnote.text}
                                        </DraftJSOutput>
                                    </StyledA>
                                </Footnote>
                            );
                        })}
                </FootnoteWrapper>
            )}
        </>
    );
};

interface FootnoteWrapperProps {
    backgroundImage?: string;
    backgroundColor?: string;
    textColor?: string;
}
