import React, { useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";

//@ts-ignore cortex
import {
    SubmitButton,
    FormikInput,
    Row,
    Column,
    ContentBlockLoader,
    FormikSelect,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    first_name: Yup.string().required(trans("Required")),
    last_name: Yup.string().required(trans("Required")),
    email: Yup.string().required(trans("Required")),
    primary_group_id: Yup.number().required(trans("Required")),
    primary_role_id: Yup.number().required(trans("Required")),
    language: Yup.string().required(trans("Required")),
    status: Yup.string().required(trans("Required")),
    exclude_from_reporting: Yup.number().required(trans("Required")),
    can_send_communication_to: Yup.number().required(trans("Required")),
    sso_auth_token: Yup.string().nullable(),
});

export type User = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: string;
    primary_group?: {
        uuid: string;
        name: string;
    };
    primary_role?: {
        uuid: string;
        i18n: { name: string };
    };
    status_wording?: string;
    sso_auth_token?: string;
};

export const UserForm = ({ item, submitForm }) => {
    const [groups, setGroups] = useState({ loaded: false, data: [] });
    const [roles, setRoles] = useState({ loaded: false, data: [] });
    const [languages, setLanguages] = useState({ loaded: false, data: [] });
    //TODO - Once the status call is working change this to use that
    // const [statuses, setStatuses] = useState({ loaded: false, data: [] });
    const [statuses, setStatuses] = useState({
        loaded: true,
        data: [],
    });

    useEffect(() => {
        axios.get(`/api/v1/admin/group?optionList=true`).then(({ data }) => {
            setGroups({ loaded: true, data: data.data });
        });
        axios.get(`/api/v1/admin/role?optionList=true`).then(({ data }) => {
            setRoles({ loaded: true, data: data.data });
        });
        axios.get(`/api/v1/language`).then(({ data }) =>
            setLanguages({
                loaded: true,
                data: data.data.map((language) => {
                    return { text: language.name, value: language.iso_code };
                }),
            })
        );
        //TODO - Get the call below working
        axios.get(`/api/v1/user-available-statuses`).then(({ data }) => {
            setStatuses({
                loaded: true,
                data: Object.entries(data).map(([value, text]) => {
                    return {
                        value,
                        text,
                    };
                }),
            });
        });
    }, []);

    const handleSubmit = (
        values: User,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const allValues = {
            ...item,
            ...values,
        };

        submitForm(allValues, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                first_name: item.first_name,
                last_name: item.last_name,
                email: item.email,
                primary_group_id: item.primary_group_id,
                primary_role_id: item.primary_role_id,
                language: item.language,
                status: item.status,
                exclude_from_reporting: !!item.exclude_from_reporting ? 1 : 0,
                can_send_communication_to:
                    item.can_send_communication_to !== 0 ? 1 : 0,
                sso_auth_token: item.sso_auth_token,
            }}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, errors }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="first_name"
                            label={trans("First Name")}
                            error={errors.first_name}
                            required={true}
                            helpText={trans("Required")}
                        >
                            <FormikInput
                                name="first_name"
                                value={values.first_name}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="last_name"
                            label={trans("Last Name")}
                            error={errors.last_name}
                            required={true}
                            helpText={trans("Required")}
                        >
                            <FormikInput
                                name="last_name"
                                value={values.last_name}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="email"
                            label={trans("Email")}
                            error={errors.email}
                            required={true}
                            helpText={trans("Required")}
                        >
                            <FormikInput
                                name="email"
                                value={values.email}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="primary_group_id"
                            label={trans("Primary Group")}
                            helpText={trans("Required")}
                            required={true}
                            error={errors.primary_group_id}
                        >
                            <ContentBlockLoader loaded={groups.loaded}>
                                <FormikSelect
                                    name="primary_group_id"
                                    value={values.primary_group_id}
                                    options={groups.data}
                                    showError={false}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="primary_role_id"
                            label={trans("Primary Role")}
                            helpText={trans("Required")}
                            required={true}
                            error={errors.primary_role_id}
                        >
                            <ContentBlockLoader loaded={roles.loaded}>
                                <FormikSelect
                                    name="primary_role_id"
                                    value={values.primary_role_id}
                                    options={roles.data}
                                    showError={false}
                                />
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="language"
                            label={trans("Language")}
                            helpText={trans("Required")}
                            required={true}
                            error={errors.language}
                        >
                            <ContentBlockLoader loaded={languages.loaded}>
                                <FormikSelect
                                    name="language"
                                    value={values.language}
                                    options={languages.data}
                                    showError={false}
                                />
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="status"
                            label={trans("Status")}
                            helpText={trans("Required")}
                            required={true}
                            error={errors.status}
                        >
                            <ContentBlockLoader loaded={statuses.loaded}>
                                <FormikSelect
                                    name="status"
                                    value={values.status}
                                    options={statuses.data}
                                    showError={false}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="exclude_from_reporting"
                            label={trans("Exclude From Reporting")}
                            helpText={trans("Required")}
                            required={true}
                            error={errors.exclude_from_reporting}
                        >
                            <FormikSelect
                                name="exclude_from_reporting"
                                value={values.exclude_from_reporting}
                                options={[
                                    { text: "Yes", value: 1 },
                                    { text: "No", value: 0 },
                                ]}
                                showError={false}
                            />
                        </FormRow>
                        <FormRow
                            name="can_send_communication_to"
                            label={trans("Can Communicate To")}
                            helpText={trans("Required")}
                            required={true}
                            error={errors.can_send_communication_to}
                        >
                            <FormikSelect
                                name="can_send_communication_to"
                                value={values.can_send_communication_to}
                                options={[
                                    { text: "Yes", value: 1 },
                                    { text: "No", value: 0 },
                                ]}
                                showError={false}
                            />
                        </FormRow>
                        <FormRow
                            name="sso_auth_token"
                            label={trans("SSO Auth Token")}
                            helpText={trans(
                                "Must match SSO Auth Token in the auth area"
                            )}
                            error={errors.sso_auth_token}
                        >
                            <FormikInput
                                name="sso_auth_token"
                                value={values.sso_auth_token}
                                showError={false}
                            />
                        </FormRow>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UserForm;
