import { useSelector } from "react-redux";
import { get } from "lodash";

const useUser = () => {
    let user = useSelector(state => {
        return get(state, "auth.user", {});
    });

    return { user };
};

export default useUser;
