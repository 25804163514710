import React, { useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Footnote, Layout } from "../interfaces/layout";
import {
    SlideContainer,
    Subtitle,
    SlideTitle,
} from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";
import { Caption } from "../components/videoComponents";

const StyledSlideTitle = styled(SlideTitle)`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    flex: 1;
`;

const SlideBody = styled.div`
    display: flex;
    flex: 4;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1em;
    justify-content: space-between;
    font-size: 0.7em;
`;
const SlideColumn = styled(animated.div)<ColumnProps>`
    display: flex;
    align-items: flex-start;
    padding-right: 1rem;
    flex-direction: column;

    &:last-of-type {
        padding-right: 0;
    }

    width: ${({ width }) => width};
`;

const ThreeColumns = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);
    if (editing || disableSequencing) {
        currentTransitionIndex = 4;
    }
    const transitionsComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );
    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                hidden={!content.body_title || !content.body_title.value}
                style={useSpring({
                    opacity: transitionsComplete.includes("body_title") ? 1 : 0,
                })}
            >
                <RenderContentBlock {...content.body_title} />
            </StyledSlideTitle>
            <SlideBody>
                <SlideColumn
                    width={
                        settings.column1Width
                            ? `${settings.column1Width}%`
                            : "25%"
                    }
                    style={useSpring({
                        opacity: transitionsComplete.includes("column_1")
                            ? 1
                            : 0,
                    })}
                >
                    {!!content.column_1 &&
                        Array.isArray(content.column_1.value) && (
                            <>
                                {content.column_1.value.map(
                                    (column: Content, index: number) => {
                                        return (
                                            <Caption
                                                highlightColor={highlightColor}
                                                key={index}
                                                showing={true}
                                                bulletPoint={!!column.bullet}
                                            >
                                                <RenderContentBlock
                                                    type="text"
                                                    value={column.value}
                                                    footnotes={
                                                        column.footnotes || []
                                                    }
                                                />
                                            </Caption>
                                        );
                                    }
                                )}
                            </>
                        )}
                </SlideColumn>
                <SlideColumn
                    width={
                        settings.column2Width
                            ? `${settings.column2Width}%`
                            : "50%"
                    }
                    style={useSpring({
                        opacity: transitionsComplete.includes("column_2")
                            ? 1
                            : 0,
                    })}
                >
                    {!!content.column_2 &&
                        Array.isArray(content.column_2.value) && (
                            <>
                                {content.column_2.value.map(
                                    (column: Content, index: number) => {
                                        return (
                                            <Caption
                                                highlightColor={highlightColor}
                                                key={index}
                                                showing={true}
                                                bulletPoint={!!column.bullet}
                                            >
                                                <RenderContentBlock
                                                    type="text"
                                                    value={column.value}
                                                    footnotes={
                                                        column.footnotes || []
                                                    }
                                                />
                                            </Caption>
                                        );
                                    }
                                )}
                            </>
                        )}
                </SlideColumn>
                <SlideColumn
                    width={
                        settings.column3Width
                            ? `${settings.column3Width}%`
                            : "25%"
                    }
                    style={useSpring({
                        opacity: transitionsComplete.includes("column_3")
                            ? 1
                            : 0,
                    })}
                >
                    {!!content.column_3 &&
                        Array.isArray(content.column_3.value) && (
                            <>
                                {content.column_3.value.map(
                                    (column: Content, index: number) => {
                                        return (
                                            <Caption
                                                highlightColor={highlightColor}
                                                key={index}
                                                showing={true}
                                                bulletPoint={!!column.bullet}
                                            >
                                                <RenderContentBlock
                                                    type="text"
                                                    value={column.value}
                                                    footnotes={
                                                        column.footnotes || []
                                                    }
                                                />
                                            </Caption>
                                        );
                                    }
                                )}
                            </>
                        )}
                </SlideColumn>
            </SlideBody>
        </SlideContainer>
    );
};

export type Content = {
    value: string;
    bullet?: boolean;
    footnotes?: Footnote[];
};

interface ColumnProps {
    width?: string;
}

export default ThreeColumns;
