import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Button,
    HelpText,
    Select,
    Loading,
    Panel,
    Modal,
} from "@cortexglobal/rla-components";
import MetaMediaType from "./MetaMediaType";
import AssetContext from "../AssetContext";
import AssetResultItem from "../components/AssetResultItem";
import AssetBrowser from "../components/AssetBrowser";
import AssetDetailsPreviewPanel from "../components/AssetDetailsPreviewPanel";

import { SectionHeading } from "./components/SectionHeading";

const OverflowDiv = styled.div`
    width: 400px;
    margin: 0 auto;
`;

const ManageRelatedAssets = ({
    asset,
    standardOnChange,
    filters,
    searchableMeta,
}) => {
    const [relatedAssets, setRelatedAssets] = useState({
        updated: false,
        loaded: false,
        data: [],
    });
    const [showingAssetBrowser, setShowingAssetBrowser] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [showSelectedAssetPreview, setShowSelectedAssetPreview] =
        useState(false);

    useEffect(() => {
        if (asset.media_type) {
            axios
                .get(
                    `/api/v1/assets/${asset.uuid}/related?per_page=10000&include_drafts=true`
                )
                .then(({ data }) => {
                    setRelatedAssets({ data: data.data, loaded: true });
                })
                .catch((e) => e);
        }
    }, []);

    const addRelatedAsset = (asset) => {
        //Check if this asset is already there and ignore it if it is
        if (
            relatedAssets.data.findIndex(
                (relatedAsset) => relatedAsset.uuid === asset.uuid
            ) !== -1
        ) {
            return;
        }
        //Push this in the related assets list
        setRelatedAssets({
            ...relatedAssets,
            updated: true,
            data: [...relatedAssets.data, asset],
        });
    };
    const removedRelatedAsset = (asset) => {
        //Check if this asset is already there and ignore it if it is
        if (
            relatedAssets.data.findIndex(
                (relatedAsset) => relatedAsset.uuid === asset.uuid
            ) === -1
        ) {
            return;
        }
        //Remove this from the related assets list
        setRelatedAssets({
            ...relatedAssets,
            updated: true,
            data: relatedAssets.data.filter((relatedAsset) => {
                return relatedAsset.uuid !== asset.uuid;
            }),
        });
    };

    const persistRelatedAssets = () => {
        axios
            .put(`/api/v1/assets/${asset.uuid}/related`, {
                related_assets: relatedAssets.data.map(
                    (relatedAssets) => relatedAssets.uuid
                ),
            })
            .then(({ data }) => {
                // setRelatedAssets({ data: data.data, loaded: true });
            })
            .catch((e) => e);
    };

    return (
        <React.Fragment>
            <SectionHeading>{trans("Related Assets")}</SectionHeading>

            <Panel>
                <Row>
                    {!relatedAssets.loaded ? (
                        <Loading />
                    ) : relatedAssets.data.length === 0 ? (
                        <h4 style={{ padding: "1rem" }}>
                            {trans(
                                "There are currently no related assets selected"
                            )}
                        </h4>
                    ) : (
                        <div>
                            <Column>
                                <h4>
                                    {trans(
                                        "Click an asset to remove the relation"
                                    )}
                                </h4>
                                <p>
                                    {trans(
                                        "Please note that draft assets will not be available to users until they are made active"
                                    )}
                                </p>
                            </Column>
                            {relatedAssets.data.map((relatedAsset) => {
                                return (
                                    <AssetResultItem
                                        loaded={relatedAssets.loaded}
                                        asset={relatedAsset}
                                        key={relatedAsset.uuid}
                                        showStatus={true}
                                        onClick={(event, asset) => {
                                            event.preventDefault();
                                            removedRelatedAsset(asset);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Row>

                <Row>
                    <Column
                        style={{ textAlign: "right", marginBottom: "1rem" }}
                    >
                        {relatedAssets.updated && (
                            <Button onClick={persistRelatedAssets}>
                                {trans("Save Related Assets")}
                            </Button>
                        )}
                        <Button
                            onClick={() =>
                                setShowingAssetBrowser(!showingAssetBrowser)
                            }
                        >
                            {showingAssetBrowser
                                ? trans("Hide Asset Browser")
                                : trans("Browse for Related Assets")}
                        </Button>
                    </Column>
                </Row>
                {showingAssetBrowser && (
                    <div style={{ paddingTop: "1rem" }}>
                        <AssetBrowser
                            onSelect={(asset) => {
                                setSelectedAsset(asset);
                                setShowSelectedAssetPreview(true);
                            }}
                        />
                    </div>
                )}

                {showSelectedAssetPreview && (
                    <Modal
                        visible={showSelectedAssetPreview}
                        onClose={() => {
                            setShowSelectedAssetPreview(false);
                        }}
                    >
                        <OverflowDiv>
                            {selectedAsset && (
                                <AssetDetailsPreviewPanel
                                    hideShadow={true}
                                    asset={selectedAsset}
                                    loaded={true}
                                />
                            )}
                        </OverflowDiv>
                        <div style={{ paddingTop: "1rem", textAlign: "right" }}>
                            <Button
                                type="hollow"
                                style={{ marginRight: "1em" }}
                                onClick={() => {
                                    setShowSelectedAssetPreview(false);
                                }}
                            >
                                {trans("Cancel")}
                            </Button>
                            <Button
                                type="alert"
                                onClick={() => {
                                    addRelatedAsset(selectedAsset);
                                    setShowSelectedAssetPreview(false);
                                }}
                            >
                                {trans("Add Related Asset")}
                            </Button>
                        </div>
                    </Modal>
                )}
            </Panel>
        </React.Fragment>
    );
};

export default ManageRelatedAssets;
