import React, { useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Modal,
    IconTextButton,
    SubmitButton,
    Row,
    Column,
    Select,
    Loading,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { GroupSwitcher } from "@cortexglobal/cortex-auth-react-router-dom";

import AssetContext from "../AssetContext";

const ButtonRow = styled(Row)`
    padding-top: ${({ showDetails }) => (showDetails ? "1rem" : "0")};
    margin-top: ${({ showDetails }) => (showDetails ? "2rem" : "1rem")};
    position: ${({ showDetails }) => (showDetails ? "sticky" : "relative")};
    bottom: 0;
    background: ${({ showDetails, theme }) =>
        showDetails ? theme.modal.background : "none"};
`;
const PersonaliseModal = ({
    asset = null,
    assetUuid,
    visible,
    onClose,
    createUserAsset,
    showDetails = false,
}) => {
    const [saving, setSaving] = useState(false);
    const [updating, setUpdating] = useState(false);

    const { group } = useContext(AssetContext);
    const [currentGroup, setCurrentGroup] = useState(group);

    return (
        <Modal visible={visible} onClose={onClose} maxWidth={"640px"}>
            <Row>
                {updating ? (
                    <Column>
                        <h2>{trans("Switching group...")}</h2>
                    </Column>
                ) : (
                    <>
                        <Column>
                            <h2>
                                {trans("Creating new asset for {groupName}", {
                                    groupName: currentGroup.name,
                                })}
                            </h2>
                            <p>
                                {trans(
                                    "You are creating new personalised asset for {groupName}, if you wish to change groups you can select one below, otherwise just press continue",
                                    {
                                        groupName: currentGroup.name,
                                    }
                                )}
                            </p>
                        </Column>
                        <Column>
                            <GroupSwitcher
                                hideLabel={true}
                                onGroupChange={(group) =>
                                    setCurrentGroup(group)
                                }
                                onSubmitting={(value) => setUpdating(value)}
                            />
                        </Column>
                    </>
                )}
            </Row>
            <Row>
                <Column
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                    }}
                >
                    <IconTextButton
                        type="alert"
                        onClick={onClose}
                        showCircle={false}
                        icon="times"
                    >
                        {trans("Cancel")}
                    </IconTextButton>
                    <SubmitButton
                        onClick={createUserAsset}
                        disabled={saving || updating}
                        submitting={saving}
                    >
                        {trans("Continue")}
                    </SubmitButton>
                </Column>
            </Row>
        </Modal>
    );
};

export default PersonaliseModal;
