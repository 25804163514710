import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikCheckboxTree,
    Panel,
    Loading,
    Tabordion,
    Row,
    Column,
    FormikRadioArray,
    FormRow,
    Toggle,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import {
    PreviewImage,
    TabordionGroup,
    LocaleSwitcher,
} from "../components/index";

const schema = Yup.object().shape({
    slug: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    description: Yup.string().nullable(),
    component: Yup.string().required("Required"),
    initial_width: Yup.number().required("Required"),
    initial_height: Yup.number().required("Required"),
    min_width: Yup.number().required("Required"),
    min_height: Yup.number().required("Required"),
    lock_aspect_ratio: Yup.boolean().required("Required"),
    full_width: Yup.boolean().required("Required"),
    configurable: Yup.boolean().required("Required"),
    allow_multiple: Yup.boolean().required("Required"),
});

export type DashboardPanel = ReturnType<typeof schema.validateSync> & {
    id?: number;
    locale?: string;
};

export const DashboardPanelForm = ({ item, submitForm }) => {
    const [tab, setTab] = useState(0);

    const handleSubmit = (
        values: DashboardPanel,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                locale: item.locale,
                slug: item.slug,
                title: item.title,
                description: item.description,
                component: item.component,
                initial_width: item.initial_width,
                initial_height: item.initial_height,
                min_width: item.min_width,
                min_height: item.min_height,
                lock_aspect_ratio: item.lock_aspect_ratio,
                full_width: item.full_width,
                configurable: item.configurable,
                allow_multiple: item.allow_multiple,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                console.log(values);
                return (
                    <Form className="form-group">
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <FormRow
                                    name="locale"
                                    label={trans("Locale")}
                                    helpText={trans("Optional")}
                                    error={errors.locale}
                                >
                                    <LocaleSwitcher
                                        item={item}
                                        setFieldValue={setFieldValue}
                                        //@ts-ignore
                                        values={values}
                                    />
                                </FormRow>

                                <FormRow
                                    name="slug"
                                    label={trans("Slug")}
                                    helpText={trans("Required")}
                                    error={errors.slug}
                                >
                                    <FormikInput
                                        name="slug"
                                        value={values.slug}
                                        showError={false}
                                    />
                                </FormRow>

                                <FormRow
                                    name="title"
                                    label={trans("Title")}
                                    helpText={trans("Required")}
                                    error={errors.title}
                                >
                                    <FormikInput
                                        name="title"
                                        value={values.title}
                                        showError={false}
                                    />
                                </FormRow>
                                <FormRow
                                    name="description"
                                    label={trans("Description")}
                                    helpText={trans("Required")}
                                    error={errors.description}
                                >
                                    <FormikInput
                                        name="description"
                                        value={values.description}
                                        showError={false}
                                    />
                                </FormRow>
                                <FormRow
                                    name="component"
                                    label={trans("Component")}
                                    helpText={trans(
                                        "Required.  A react component with this name needs to be available in the Dashboard package"
                                    )}
                                    error={errors.component}
                                >
                                    <FormikInput
                                        name="component"
                                        value={values.component}
                                        showError={false}
                                    />
                                </FormRow>
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Settings")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <FormRow
                                    name="initial_width"
                                    label={trans("Initial Width")}
                                    helpText={trans("Optional")}
                                    error={errors.initial_width}
                                >
                                    <FormikInput
                                        type="number"
                                        name="initial_width"
                                        value={values.initial_width}
                                        showError={false}
                                        min={1}
                                        max={12}
                                        step={1}
                                    />
                                </FormRow>
                                <FormRow
                                    name="initial_height"
                                    label={trans("Initial Height")}
                                    helpText={trans("Optional")}
                                    error={errors.initial_height}
                                >
                                    <FormikInput
                                        type="number"
                                        name="initial_height"
                                        value={values.initial_height}
                                        showError={false}
                                        min={1}
                                        max={12}
                                        step={1}
                                    />
                                </FormRow>
                                <FormRow
                                    name="min_width"
                                    label={trans("Min Width")}
                                    helpText={trans("Optional")}
                                    error={errors.min_width}
                                >
                                    <FormikInput
                                        type="number"
                                        name="min_width"
                                        value={values.min_width}
                                        showError={false}
                                        min={1}
                                        max={12}
                                        step={1}
                                    />
                                </FormRow>
                                <FormRow
                                    name="min_height"
                                    label={trans("Min Height")}
                                    helpText={trans("Optional")}
                                    error={errors.min_height}
                                >
                                    <FormikInput
                                        type="number"
                                        name="min_height"
                                        value={values.min_height}
                                        showError={false}
                                        min={1}
                                        max={12}
                                        step={1}
                                    />
                                </FormRow>
                                <FormRow
                                    name="lock_aspect_ratio"
                                    label={trans("Lock Aspect Ratio")}
                                    helpText={trans("Optional")}
                                    error={errors.lock_aspect_ratio}
                                >
                                    <Toggle
                                        name="lock_aspect_ratio"
                                        checked={values.lock_aspect_ratio}
                                        onChange={(e) => {
                                            console.log(e.target.checked);
                                            setFieldValue(
                                                "lock_aspect_ratio",
                                                e.target.checked
                                            );
                                        }}
                                    />
                                </FormRow>
                                <FormRow
                                    name="full_width"
                                    label={trans("Full Width")}
                                    helpText={trans("Optional")}
                                    error={errors.full_width}
                                >
                                    <Toggle
                                        name="full_width"
                                        checked={values.full_width}
                                        onChange={(e) => {
                                            console.log(e.target.checked);
                                            setFieldValue(
                                                "full_width",
                                                e.target.checked
                                            );
                                        }}
                                    />
                                </FormRow>
                                <FormRow
                                    name="configurable"
                                    label={trans("Configurable")}
                                    helpText={trans("Optional")}
                                    error={errors.configurable}
                                >
                                    <Toggle
                                        name="configurable"
                                        checked={values.configurable}
                                        onChange={(e) => {
                                            console.log(e.target.checked);
                                            setFieldValue(
                                                "configurable",
                                                e.target.checked
                                            );
                                        }}
                                    />
                                </FormRow>
                                <FormRow
                                    name="allow_multiple"
                                    label={trans("Allow Multiple")}
                                    helpText={trans("Optional")}
                                    error={errors.allow_multiple}
                                >
                                    <Toggle
                                        name="allow_multiple"
                                        checked={values.allow_multiple}
                                        onChange={(e) => {
                                            console.log(e.target.checked);
                                            setFieldValue(
                                                "allow_multiple",
                                                e.target.checked
                                            );
                                        }}
                                    />
                                </FormRow>
                            </TabordionGroup>
                        </Tabordion>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default DashboardPanelForm;
