import React from "react";
import { Table } from "@cortexglobal/rla-components";

// import Availability from "./detail/Availability";
import Classification from "./detail/ClassificationV2";
import FurtherInformation from "./detail/FurtherInformation";
import Description from "./detail/Description";
import { trans } from "@cortexglobal/rla-intl";

const AssetDetailsPanel = ({ asset }) => {
    const assetType =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset?.asset_type?.slug;
    const renderLinkProperties = () => {
        const linkPropertiesForDisplay = Object.values(
            asset.configuration
        ).filter((property) => {
            return property.name !== "url";
        });
        if (linkPropertiesForDisplay.length === 0) {
            return null;
        }

        return (
            <>
                <h3>{trans("Link Properties")}</h3>
                <Table tableType="noStripe">
                    <tbody>
                        {linkPropertiesForDisplay.map((property) => (
                            <tr>
                                <th style={{ width: "33%" }}>
                                    {property.label}
                                </th>
                                <td>{property.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        );
    };

    return (
        <>
            <h3>{trans("Classification")}</h3>
            <Table tableType="noStripe">
                <tbody>
                    <tr>
                        <th style={{ width: "33%" }}>{trans("Asset Type")}</th>
                        <td>{asset?.asset_type_config?.name || ""}</td>
                    </tr>
                    <tr>
                        <th style={{ width: "33%" }}>{trans("Media Type")}</th>
                        <td>{asset?.media_type_name || ""}</td>
                    </tr>

                    {asset.categories_by_parent_readable &&
                        Object.keys(asset.categories_by_parent_readable)
                            .length > 0 && <Classification asset={asset} />}
                </tbody>
            </Table>

            {assetType === "link" &&
                typeof asset.configuration === "object" &&
                asset.configuration !== null &&
                renderLinkProperties()}

            <h3>{trans("Further Information")}</h3>
            <FurtherInformation asset={asset} />

            {asset.description && (
                <>
                    <h3>{trans("Description")}</h3>
                    <Description asset={asset} />
                </>
            )}
        </>
    );
};

export default AssetDetailsPanel;
