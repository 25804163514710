import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
} from "@cortexglobal/rla-components";
import { useState } from "react";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const ActiveUsersChartConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    filterBy,
    total,
    perSlide,
    displayType,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title
            ? title
            : intl.formatMessage({ id: "Active Users Listing" }),
        filterBy: filterBy ? filterBy : "",
        total: total ? total : 5,
        perSlide: perSlide ? perSlide : 5,
    });

    const options = [
        {
            value: "users-active-vs-nonactive-gg",
            text: intl.formatMessage({
                id: "Users Active vs Not Active (global)",
            }),
        },
        {
            value: "users-active-vs-nonactive-ag",
            text: intl.formatMessage({
                id: "Users Active vs Not (in active group)",
            }),
        },
    ];

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder=""
                        onChange={onChange}
                    />
                    <SelectField
                        name="filterBy"
                        label={trans("Filter")}
                        value={state.filterBy}
                        options={options}
                        onChange={onChange}
                        expanded
                    />
                    <Button type="submit">{trans("Save Panel")}</Button>
                </form>
            </Column>
        </Row>
    );
};

ActiveUsersChartConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default ActiveUsersChartConfiguration;
