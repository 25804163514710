import React, { useContext } from "react";

import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

import { Row, Column, Loading } from "@cortexglobal/rla-components";

import { transparentize } from "@cortexglobal/cortex-utilities";

import FaqContext from "../FaqContext";

const PageLink = styled.a`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: 1rem 0 0 0;
    padding: 0.85rem 0.5rem 0.85rem 0;
    border: 1px solid
        ${(props) => transparentize(props.theme.colors.gray500, 0.5)};
    background: ${(props) => props.theme.panel.background};
    h4 {
        margin: 0;
        font-weight: normal;
    }
`;

const PageIcon = styled(FontAwesomeIcon)`
    margin: 0 1rem;
`;

export const FaqCategory = () => {
    const { faqState, basepath, faqNavigate } = useContext(FaqContext);
    const { category_loading, category } = faqState;

    if (category_loading) {
        return <Loading />;
    }

    return (
        <>
            {"category-name" && (
                <Row style={{ paddingTop: "0.8rem" }}>
                    <Column>
                        <h2>{category.text}</h2>
                        {category?.description && <p>{category.description}</p>}
                    </Column>
                </Row>
            )}

            <Row>
                {category.questions?.map((question) => {
                    return (
                        <Column xlarge={6} key={`question-${question.uuid}`}>
                            <PageLink
                                onClick={() =>
                                    faqNavigate(
                                        `${basepath}/${category.slug}/${question.slug}`
                                    )
                                }
                            >
                                <PageIcon icon={faFileAlt} size="3x" />
                                <h4>{question.question}</h4>
                            </PageLink>
                        </Column>
                    );
                })}
            </Row>
        </>
    );
};

export default FaqCategory;
