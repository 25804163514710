import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { foregroundColor } from "@cortexglobal/cortex-utilities";
// import { RubberBand } from "animate-css-styled-components";

const background = props =>
    props.theme.colors[props.type] ||
    props.theme.colors[props.theme.alert.default];

const color = props =>
    foregroundColor(
        background(props),
        0.75,
        props.theme.lightColor,
        props.theme.darkColor
    );

const isHollow = props => Boolean(props.hollow);

const isExpanded = props => Boolean(props.expanded);

const outline = props => `inset 0 0 0 ${props.theme.alert.borderWidth}px
${background(props)}`;

let AlertWrapper = styled.div`
    position: relative;
    border: none;
    font-family: inherit;
    height: auto;
    max-width: 500px;
    overflow-wrap: break-word;
    font-size: ${props => props.theme.alert.fontSize}px;
    font-weight: ${props => props.theme.alert.fontWeight};
    text-transform: ${props => props.theme.alert.textTransform};
    border-radius: ${props =>
        props.rounded ? props.theme.alert.borderRadius : 0}em;
    margin-bottom: ${props =>
        props.margin != undefined
            ? props.margin
            : props.theme.spacing.margin}em;
    transition: background-color 0.25s ease;
    box-shadow: ${props => outline(props)};
    padding: ${props => props.theme.spacing.padding}em;
    display: inline-block;
    text-align: ${props => props.align || "center"};
    background-color: ${props =>
        !isHollow(props) ? background(props) : "transparent"};
    color: ${props => (isHollow(props) ? background(props) : color(props))};

    // expanded
    ${props =>
        isExpanded(props) &&
        css`
            max-width: 100%;
        `};
    // dismissed
    ${props =>
        props.dismissed &&
        css`
            display: none;
        `};
`;

let Alert = ({ children, dismissable, closeBtnColor, ...rest }) => {
    const [dismissed, setDismissed] = useState(false);
    return (
        <AlertWrapper dismissed={dismissed} {...rest}>
            {dismissable && (
                <CloseButton
                    color={closeBtnColor}
                    isHollow
                    onClick={() => {
                        setDismissed(true);
                    }}
                >
                    {isHollow}
                </CloseButton>
            )}
            {children}
        </AlertWrapper>
    );
};

export const AlertTitle = styled.h1`
    font-size: ${props =>
        props.titleFontSize || props.theme.alert.title.fontSize}px;
`;

export const AlertText = styled.p`
    font-size: ${props =>
        props.textFontSize || props.theme.alert.text.fontSize}px;
    line-height: ${props =>
        props.textFontSize + 2 || props.theme.alert.text.fontSize + 2}px;
`;

Alert = withTheme(Alert);

Alert.displayName = "Alert";
AlertTitle.displayName = "AlertTitle";
AlertText.displayName = "AlertText";

Alert.propTypes = {
    align: PropTypes.oneOf(["left", "right", "center", undefined]),
    expanded: PropTypes.bool,
    hollow: PropTypes.bool,
    rounded: PropTypes.bool,
    margin: PropTypes.number,
    textFontSize: PropTypes.number,
    dismissable: PropTypes.bool,
    closeBtnColor: PropTypes.string
};

Alert.defaultProps = {
    align: "left",
    expanded: false,
    rounded: false,
    hollow: false,
    type: "primary",
    dismissable: false
};

AlertTitle.propTypes = {
    titleFontSize: PropTypes.number
};
AlertTitle.defaultProps = {
    titleFontSize: 18
};

AlertText.propTypes = {
    textFontSize: PropTypes.number
};
AlertText.defaultProps = {
    textFontSize: 12
};

export default Alert;
