import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    ContentBlockLoader,
    SubmitButton,
    FormikSelect,
    FormikInput,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    IconTextButton,
    useAddToast,
    useToasts,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { PreviewImage, LocaleSwitcher } from "../components/index";

const FILE_SIZE = 1160 * 1024 * 12;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = Yup.object().shape({
    locale: Yup.string().nullable(),
    parent_id: Yup.number().nullable(),
    category_id: Yup.number().nullable(),
    label_category_id: Yup.number().nullable(),
    name: Yup.string().required(trans("Required")),
    slug: Yup.string().required(trans("Required")),
    description: Yup.string().nullable(),
    type: Yup.string().required(trans("Required")),
    order: Yup.number(),
    color: Yup.string().nullable(),
    thumbnail: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                if (!value) {
                    return Yup.mixed();
                }
                return (
                    Yup.mixed()
                        // .required("A thumbnail is required")
                        .test(
                            "fileSize",
                            "File too large",
                            (value) => value.size <= FILE_SIZE
                        )
                        .test("fileFormat", "Unsupported Format", (value) =>
                            SUPPORTED_FORMATS.includes(value.type)
                        )
                ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
});

export type Category = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    parent?: Category;
    children?: Category[];
    slug?: string;
    i18n?: { [key: string]: any };
    area?: string;
};

export const CategoryForm = ({ item, submitForm, area }) => {
    const [categories, setCategories] = useState([]);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [types, setTypes] = useState([]);
    const [typesLoaded, setTypesLoaded] = useState(false);
    const [newPreviewFile, setNewPreviewFile] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/v1/admin/category/${area}/parent-options`)
            .then(({ data }) => {
                setCategories(
                    data.data.filter((category) => category.value !== item.id)
                );
                setCategoriesLoaded(true);
            });
        axios
            .get(`/api/v1/admin/category/${area}/type-options`)
            .then(({ data }) => {
                setTypes(data);
                setTypesLoaded(true);
            });
    }, []);
    const handleSubmit = (
        values: Category,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();
        Object.keys(values).forEach((key) => fd.append(key, values[key]));
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: trans("Saving"),
        });
        submitForm(fd, setSubmitting)
            .then((data) => {
                addToast({
                    content: trans("Category saved"),
                    type: "success",
                    showFor: 3000,
                });
            })
            .catch((e) => {
                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred deleting the category"
                        ),
                        showFor: 5000,
                    });
                }
                parseValidationErrors(e).then(({ errors }) => {
                    //console.log(setErrors, errors);
                    setErrors(errors);
                    setSubmitting(false);
                });
            })
            .finally(() => {
                dispatch({ type: "removeToast", uuid: "loadToast" });
            });
    };
    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );
    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("thumbnail", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);

    return (
        <Formik
            initialValues={{
                locale: item.locale ? item.locale : null,
                category_id: item.category_id,
                parent_id: item.parent ? item.parent.id : null,
                label_category_id: item.label ? item.label.id : null,
                name: item.name,
                slug: item.slug,
                description: item.description,
                type: item.type,
                order: item.order,
                color: item.color,
                thumbnail: item.thumbnail,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                // console.log(values);
                return (
                    <Form className="form-group">
                        <FormRow
                            name="locale"
                            label={trans("Locale")}
                            helpText={trans("Optional")}
                            error={errors.locale}
                        >
                            <LocaleSwitcher
                                item={item}
                                setFieldValue={setFieldValue}
                                //@ts-ignore
                                values={values}
                            />
                        </FormRow>
                        <FormRow
                            name="parent_id"
                            label={trans("Parent Category")}
                            helpText={trans("Optional")}
                            error={errors.parent_id}
                        >
                            <ContentBlockLoader loaded={categoriesLoaded}>
                                <FormikSelect
                                    name="parent_id"
                                    value={values.parent_id}
                                    options={categories}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="label_category_id"
                            label={trans("Label Category")}
                            helpText={trans(
                                "Optional, used to display a label for the category where needed"
                            )}
                            error={errors.label_category_id}
                        >
                            <ContentBlockLoader loaded={categoriesLoaded}>
                                <FormikSelect
                                    name="label_category_id"
                                    value={values.label_category_id}
                                    options={categories.filter(
                                        (category) => category.type === "label"
                                    )}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Required")}
                            error={errors.name}
                            required={true}
                        >
                            <FormikInput
                                name="name"
                                value={values.name}
                                placeholder={item.slug ? item.slug : ""}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="slug"
                            label={trans("Slug")}
                            helpText={trans(
                                "Required, used in the URL so must be unique and contain only letters, numbers and dashes"
                            )}
                            error={errors.slug}
                            required={true}
                        >
                            <FormikInput
                                name="slug"
                                value={values.slug}
                                placeholder={item.slug ? item.slug : ""}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="description"
                            label={trans("Description")}
                            helpText={trans("Optional")}
                            error={errors.description}
                        >
                            <FormikInput
                                name="description"
                                value={values.description}
                                showError={false}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="type"
                            label={trans("Type")}
                            helpText={trans("Required")}
                            error={errors.type}
                        >
                            <ContentBlockLoader loaded={typesLoaded}>
                                <FormikSelect
                                    name="type"
                                    value={values.type}
                                    options={types}
                                    showError={false}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="thumbnail"
                            label={trans("Thumbnail")}
                            helpText={trans("Optional")}
                            error={errors.thumbnail}
                        >
                            <SimpleDropzone
                                style={{ marginBottom: "2.2rem" }}
                                onDrop={(acceptedFiles) =>
                                    onDrop(acceptedFiles, setFieldValue)
                                }
                                accept="image/*"
                                multiple={false}
                            >
                                <IconTextButton
                                    icon="upload"
                                    showCircle={false}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {values.thumbnail && values.thumbnail !== ""
                                        ? trans("Update thumbnail")
                                        : trans("Add thumbnail")}
                                </IconTextButton>
                            </SimpleDropzone>

                            {typeof values.thumbnail === "string" &&
                                values.thumbnail !== "" && (
                                    <PreviewImage src={values.thumbnail} />
                                )}
                            {newPreviewFile && (
                                <PreviewImage src={newPreviewFile} />
                            )}
                        </FormRow>
                        <FormRow
                            name="color"
                            label={trans("Color")}
                            helpText={trans("Optional")}
                            error={errors.color}
                        >
                            <FormikInput
                                type="color"
                                name="color"
                                value={values.color}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="order"
                            label={trans("Order")}
                            helpText={trans("Optional")}
                            error={errors.order}
                        >
                            <FormikInput
                                name="order"
                                value={values.order}
                                placeholder={item.order ? item.order : "0"}
                            />{" "}
                        </FormRow>
                        {/* <FormRow
                                    name="start_date"
                                    label={trans("Start Date")}
                                    helpText={trans("Optional")}
                                    error={errors.start_date}
                                >
                                    <FormikDatePicker
                                        name="start_date"
                                        value={values.start_date}
                                    />
                                </FormRow>
                                <FormRow
                                    name="end_date"
                                    label={trans("End Date")}
                                    helpText={trans("Optional")}
                                    error={errors.end_date}
                                >
                                    <FormikDatePicker
                                        name="end_date"
                                        value={values.end_date}
                                    />
                                </FormRow> */}

                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CategoryForm;
