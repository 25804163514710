import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { calculateOffset, calculateWidth } from "./helpers";
import CalendarContext from "./CalendarContext";
import { Tooltip } from "@cortexglobal/rla-components";
import ActivityTooltipContent from "./components/ActivityTooltipContent";
const StyledActivity = styled.div`
    cursor: pointer;
    border-radius: ${({ height }) => height / 2}px;
    display: table-cell;
    padding: 0 5px;
    position: absolute;
    /* z-index: 2; */
    transition: all 0.2s ease-in-out;
    margin-top: ${({ spacing }) => spacing}px;

    background: ${({ colors, activity }) => colors.activities["activity"]};
    color: white;
    font-weight: bold;
    /* text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5),
        -1px 1px 0 rgba(0, 0, 0, 0.5), 1px 1px 0 rgba(0, 0, 0, 0.5); */
    height: ${({ height }) => height}px;
    font-size: ${({ height }) => height - 4}px;
    line-height: ${({ height }) => height}px;
    left: ${({ left }) => left}px;
    top: ${({ top }) => top}px;
    width: ${({ width }) => width}px;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
`;

class Activity extends Component {
    // activityClick(activity, task, category, left, width, event) {
    //     this.props.activityClick(activity, task, category, left, width, event);
    // }

    render() {
        const {
            category,
            task,
            activity,
            colors,
            index,
            widths,
            heights,
            units,
            start,
            end,
            activityClick,
            activityContent,
        } = this.props;
        const left = calculateOffset(activity.start_date, widths, units, start);
        const top =
            heights.task +
            heights.spacing +
            (heights.activity + heights.spacing) * index +
            1;

        const width = calculateWidth(
            start,
            end,
            activity.start_date,
            activity.end_date,
            widths,
            units
        );
        return (
            <StyledActivity
                colors={colors}
                height={heights.activity}
                spacing={heights.spacing}
                left={left}
                top={top}
                width={width}
                className={`activity ${activity.type}`}
                onClick={(event) =>
                    activityClick({
                        activity,
                        task,
                        category,
                        left,
                        width,
                        event,
                    })
                }
                activity={activity}
            >
                <Tooltip
                    tagName="div"
                    text={
                        <ActivityTooltipContent
                            activity={activity}
                            width="25rem"
                        />
                    }
                    zIndex={10000}
                    showIcon={false}
                    setMaxWidth={false}
                    direction="bottom"
                >
                    {React.createElement(activityContent, this.props)}
                </Tooltip>
            </StyledActivity>
        );
    }
}

Activity.propTypes = {
    category: PropTypes.object,
    task: PropTypes.object,
    activity: PropTypes.object,
};

export default Activity;
