import React from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Error,
    FormRow,
    FormikInput,
    SubmitButton,
    Row,
    Column
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

const makeFormSchema = mediaType => {
    switch (mediaType) {
        case "email":
            return Yup.object().shape({
                sent: Yup.number()
                    .min(0)
                    .nullable(true),
                delivered: Yup.number()
                    .min(0)
                    .nullable(true),
                clicked: Yup.number()
                    .min(0)
                    .nullable(true),
                opened: Yup.number()
                    .min(0)
                    .nullable(true),
                bounced: Yup.number()
                    .min(0)
                    .nullable(true),
                unsubscribes: Yup.number()
                    .min(0)
                    .nullable(true)
            });
        case "social":
            return Yup.object().shape({
                views: Yup.number()
                    .min(0)
                    .nullable(true),
                likes: Yup.number()
                    .min(0)
                    .nullable(true),
                shares: Yup.number()
                    .min(0)
                    .nullable(true),
                clicks: Yup.number()
                    .min(0)
                    .nullable(true)
            });
    }
};

export const FulfilmentResultForm = ({
    resultItem = null, //If there's a resultItem then we're editing an existing item, otherwise we're creating a new one
    mediaType,
    assetOrderUuid = "",
    plannerActivityUuid = "",
    onComplete
}) => {
    if (assetOrderUuid === "" && plannerActivityUuid === "") {
        return <Error>{trans("Missing Order and Activity")}</Error>;
    }

    const submitForm = (values, { setErrors, setSubmitting, ...rest }) => {
        //If there's a resultItem then we're editing an existing item, so do a PUT
        //otherwise we're creating a new one so do a POST
        if (resultItem) {
            return axios
                .put(`/api/v1/fulfilment-results/${resultItem.uuid}`, values)
                .then(response => {
                    onComplete(response);
                })
                .catch(e => {
                    parseValidationErrors(e).then(({ errors }) => {
                        //console.log(setErrors, errors);
                        setErrors(errors);
                        setSubmitting(false);
                    });
                });
        } else {
            return axios
                .post(`/api/v1/fulfilment-results`, values)
                .then(response => {
                    console.log("saved");
                    onComplete(response);
                })
                .catch(e => {
                    parseValidationErrors(e).then(({ errors }) => {
                        //console.log(setErrors, errors);
                        setErrors(errors);
                        setSubmitting(false);
                    });
                });
        }
    };

    return (
        <Row style={{ paddingBottom: "2rem" }}>
            <Formik
                initialValues={{
                    assetOrderUuid: assetOrderUuid,
                    plannerActivityUuid: plannerActivityUuid,
                    sent: resultItem ? resultItem.sent : null,
                    delivered: resultItem ? resultItem.delivered : null,
                    opened: resultItem ? resultItem.opened : null,
                    clicked: resultItem ? resultItem.clicked : null,
                    bounced: resultItem ? resultItem.bounced : null,
                    unsubscribes: resultItem ? resultItem.unsubscribes : null,
                    enquiries: resultItem ? resultItem.enquiries : null,
                    impressions: resultItem ? resultItem.impressions : null,
                    shares: resultItem ? resultItem.shares : null,
                    likes: resultItem ? resultItem.likes : null,
                    comments: resultItem ? resultItem.comments : null,
                    views: resultItem ? resultItem.views : null
                }}
                onSubmit={submitForm}
                validationSchema={makeFormSchema(mediaType)}
                className="form-group"
            >
                {({ values, errors }) => {
                    // console.log(values, errors);
                    return (
                        <Form className="form-group">
                            {mediaType === "email" && (
                                <>
                                    <FormRow
                                        name="sent"
                                        label={trans("Sent")}
                                        error={errors.sent}
                                        helpText={trans("How many were sent?")}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="sent"
                                            value={values.sent}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="delivered"
                                        label={trans("Delivered")}
                                        error={errors.delivered}
                                        helpText={trans(
                                            "How many were delivered?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="delivered"
                                            value={values.delivered}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="opened"
                                        label={trans("Opened")}
                                        error={errors.opened}
                                        helpText={trans(
                                            "How many were opened?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="opened"
                                            value={values.opened}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="clicked"
                                        label={trans("Clicked")}
                                        error={errors.clicked}
                                        helpText={trans(
                                            "How many were clicked?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="clicked"
                                            value={values.clicked}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="bounced"
                                        label={trans("Bounced")}
                                        error={errors.bounced}
                                        helpText={trans(
                                            "How many bounces were reported?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="bounced"
                                            value={values.bounced}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="unsubscribes"
                                        label={trans("Unsubscribes")}
                                        error={errors.unsubscribes}
                                        helpText={trans(
                                            "How many unsubscribed?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="unsubscribes"
                                            value={values.unsubscribes}
                                            showError={false}
                                        />
                                    </FormRow>
                                </>
                            )}

                            {mediaType === "banner" && (
                                <>
                                    <FormRow
                                        name="enquiries"
                                        label={trans("Enquiries")}
                                        error={errors.enquiries}
                                        helpText={trans(
                                            "How many were enquiries were made?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="enquiries"
                                            value={values.enquiries}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="impressions"
                                        label={trans("Impressions")}
                                        error={errors.impressions}
                                        helpText={trans(
                                            "How many impressions were recorded?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="impressions"
                                            value={values.impressions}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="shares"
                                        label={trans("Shares")}
                                        error={errors.shares}
                                        helpText={trans("How many shares?")}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="shares"
                                            value={values.shares}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="likes"
                                        label={trans("Likes")}
                                        error={errors.likes}
                                        helpText={trans(
                                            "How many were likes were made?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="likes"
                                            value={values.likes}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="comments"
                                        label={trans("Comments")}
                                        error={errors.comments}
                                        helpText={trans(
                                            "How many were comments were made?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="comments"
                                            value={values.comments}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="views"
                                        label={trans("Views")}
                                        error={errors.views}
                                        helpText={trans(
                                            "How many Views were recorded?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="views"
                                            value={values.views}
                                            showError={false}
                                        />
                                    </FormRow>
                                </>
                            )}

                            {mediaType === "social" && (
                                <>
                                    <FormRow
                                        name="shares"
                                        label={trans("Shares")}
                                        error={errors.shares}
                                        helpText={trans("How many shares?")}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="shares"
                                            value={values.shares}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="likes"
                                        label={trans("Likes")}
                                        error={errors.likes}
                                        helpText={trans(
                                            "How many were likes were made?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="likes"
                                            value={values.likes}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="comments"
                                        label={trans("Comments")}
                                        error={errors.comments}
                                        helpText={trans(
                                            "How many were comments were made?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="comments"
                                            value={values.comments}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="views"
                                        label={trans("Views")}
                                        error={errors.views}
                                        helpText={trans(
                                            "How many Views were recorded?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="views"
                                            value={values.views}
                                            showError={false}
                                        />
                                    </FormRow>
                                </>
                            )}

                            {mediaType === "print" && (
                                <>
                                    <FormRow
                                        name="enquiries"
                                        label={trans("Enquiries")}
                                        error={errors.enquiries}
                                        helpText={trans(
                                            "How many were enquiries were generated?"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="enquiries"
                                            value={values.enquiries}
                                            showError={false}
                                        />
                                    </FormRow>
                                </>
                            )}

                            <Column small={6} centered>
                                <Column medium={6}>
                                    <SubmitButton expanded>
                                        {trans("Save")}
                                    </SubmitButton>
                                </Column>
                            </Column>
                        </Form>
                    );
                }}
            </Formik>
        </Row>
    );
};

export default FulfilmentResultForm;
