import React from "react";
import { Table, Row, Column } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FormattedDate } from "@cortexglobal/rla-intl";

const DetailsSummary = ({
    event,
    loading,
    settings: { excludedOptionalFields },
}) => {
    return (
        <Table
            tableType="overview"
            zebraStripe="true"
            style={{ padding: "0 0.5rem 0.5rem" }}
        >
            <tbody>
                <tr>
                    <td width="40%">{trans("Name")}:</td>
                    <td>{event.name}</td>
                </tr>
                <tr>
                    <td>{trans("Description")}:</td>
                    <td>{event.description}</td>
                </tr>
                <tr>
                    <td>{trans("Start date")}:</td>
                    <td>
                        <FormattedDate value={new Date(event.start_date)} />
                    </td>
                </tr>
                <tr>
                    <td>{trans("End date")}:</td>
                    <td>
                        <FormattedDate value={new Date(event.end_date)} />
                    </td>
                </tr>
                {/* <tr>
                    <td>{trans("Event Type")}:</td>
                    <td>{event.event_type}</td>
                </tr> */}
                {!excludedOptionalFields.includes("meeting_element") && (
                    <tr>
                        <td>{trans("Is there a meeting element?")}:</td>
                        <td>{event.meeting_element ? "Yes" : "No"}</td>
                    </tr>
                )}
                {!excludedOptionalFields.includes("pr_requirements") && (
                    <tr>
                        <td>{trans("PR/Social media requirements")}:</td>
                        <td>{event.pr_requirements}</td>
                    </tr>
                )}
                {!excludedOptionalFields.includes("sustainability_element") && (
                    <tr>
                        <td>{trans("Sustainability element")}:</td>
                        <td>{event.sustainability_element}</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

export default DetailsSummary;
