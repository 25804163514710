import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";

import StandardFilters from "./StandardFilters";
import ApplyFiltersRow from "./ApplyFiltersRow";
import SavedFilters from "./SavedFilters";

import { Column, IconTextButton, useFilters } from "../../index";

const FilterColumnWrapper = styled.div`
    width: ${({ expanded }) => (expanded ? "280px" : "40px")};
    min-width: ${({ expanded }) => (expanded ? "280px" : "40px")};
    position: relative;
    /* margin-bottom: ${({ expanded }) => (expanded ? 1 : 0)}rem; */
    background-color: ${({ theme }) => theme.filters.panel.backgroundColor};
    color: ${({ theme }) => theme.filters.panel.color};
    transition: all 500ms;
    /* overflow-x: ${({ expanded }) => (expanded ? "visible" : "hidden")}; */
    .filterColumnInner {
        min-width: 280px;
        opacity: ${({ expanded }) => (expanded ? "1" : "0")};
        transition: opacity 500ms ease-in;
        pointer-events: ${({ expanded }) => (expanded ? "auto" : "none")};
    }
`;

const ExpandButton = styled(IconTextButton)`
    position: absolute;
    display: flex;
    align-items: flex-end;
    z-index: 0;
    right: ${({ expanded }) => (expanded ? "1rem" : "-0.5rem")};
    top: 0.5rem;
    width: 22px;
`;

const ExpandMessage = styled.div`
    position: absolute;
    top: 3rem;
    left: 1.5rem;
    transform: rotate(270deg) translateX(-100%);
    transform-origin: 0 center;
    white-space: nowrap;
    opacity: ${({ expanded }) => (expanded ? "0" : "1")};
    transition: opacity 200ms ease-in;
    cursor: ${({ expanded }) => (expanded ? "inherit" : "pointer")};
    pointer-events: ${({ expanded }) => (expanded ? "none" : "auto")};

    overflow: visible;
    text-align: right;
`;

//Set filters and selected status at this level, then we can handle all state
//changes, until required to pass back up the tree.  This will allow us to
//choose whether to pass back up automatically (pinpoint style) or only on
//button press
let FilterColumn = ({
    children,
    permanentSummary,
    filters,
    selectedFilters: currentlySelectedFilters,
    onUpdate,
    onApply,
    onClear,
    hideClear = false,
    showApply,
    showSummary = true,
    resultCount,
    searchTerm,
    searchClear,
    user,
    toggleFavorites,
    favorites,
    searchInput = null,
    filterColumnCollapse,
    filterAreaSlug = "",
    savedFilter,
    config = {},
    ...rest
}) => {
    const [expanded, setExpanded] = useState(true);

    const { selectedFilters, updateFilters, clearFilters, applyFilters } =
        useFilters({
            filters,
            currentlySelectedFilters,
            onUpdate,
            onApply,
            onClear,
        });
    const showSavedFilters =
        config.allow_saved_filters && user.isGuest !== true;
    return (
        <FilterColumnWrapper {...rest} expanded={expanded}>
            {filterColumnCollapse && (
                <ExpandButton
                    expanded={expanded}
                    icon={faChevronLeft}
                    rotation={expanded ? 0 : 180}
                    showCircle={false}
                    onClick={() => setExpanded(!expanded)}
                />
            )}{" "}
            <div className="filterColumnInner">
                {searchInput !== null && (
                    <Column style={{ marginBottom: "1rem", padding: "0.3rem" }}>
                        {searchInput}
                    </Column>
                )}
                {/* <FilterSummary
                    singleColumn={true}
                    onUpdate={resetFilterGroup}
                    onClear={clearFilters}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    searchTerm={searchTerm}
                    searchClear={searchClear}
                /> */}
                {children ? (
                    children({
                        updateFilters,
                        clearFilters,
                        selectedFilters,
                        filters,
                    })
                ) : (
                    <StandardFilters
                        singleColumn={true}
                        onClear={clearFilters}
                        onUpdate={updateFilters}
                        onApply={applyFilters}
                        filters={filters}
                        selectedFilters={selectedFilters}
                    />
                )}

                {showApply && (
                    <ApplyFiltersRow
                        onApply={(event) => applyFilters(null)}
                        onClear={clearFilters}
                        hideClear={hideClear}
                        resultCount={resultCount}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        padding={
                            showSavedFilters ? "0 0.5rem 1rem" : "1rem 0.5rem"
                        }
                        user={user}
                        toggleFavorites={toggleFavorites}
                        favorites={favorites}
                    >
                        {showSavedFilters && (
                            <>
                                <Column
                                    style={{
                                        marginTop: "1rem",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <SavedFilters
                                        onApply={onApply}
                                        filters={filters}
                                        selectedFilters={selectedFilters}
                                        filterAreaSlug={filterAreaSlug}
                                        currentSavedFilter={savedFilter}
                                    />{" "}
                                </Column>
                            </>
                        )}
                    </ApplyFiltersRow>
                )}
            </div>
            <ExpandMessage
                expanded={expanded}
                onClick={() => setExpanded(!expanded)}
            >
                {trans("Show filters")}
            </ExpandMessage>
        </FilterColumnWrapper>
    );
};

FilterColumn = withTheme(FilterColumn);

FilterColumn.displayName = "FilterColumn";

const filterShape = PropTypes.shape({
    alias: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        "MultiCheckBox",
        "MultiSelect",
        "Select",
        "RadioGroup",
        "Range",
    ]).isRequired,
    comparison: PropTypes.oneOf(["===", "!==", "<", "<=", ">", ">="]),
    defaultValue: PropTypes.any,
    options: PropTypes.arrayOf({
        value: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
    }),
});
FilterColumn.propTypes = {
    /** Filters: Need to be of a certain shape.*/
    filters: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape(filterShape)),
        PropTypes.array,
    ]),
    // filters: PropTypes.objectOf(PropTypes.shape(filterShape)).isRequired,
    selectedFilters: PropTypes.object,
    children: PropTypes.func,
    permanentSummary: PropTypes.bool,
    onUpdate: PropTypes.func,
    onApply: PropTypes.func,
    showApply: PropTypes.bool,
    /** Used to display a result count if the filters would be applied */
    resultCount: PropTypes.oneOfType([PropTypes.number]),
};

FilterColumn.defaultProps = {
    selectedFilters: {},
    permanentSummary: false,
    expanded: false,
    onApply: () => {},
    autoApply: false,
    showApply: true,
};

export default FilterColumn;
