//DON'T DELETE COMMENTED SECTIONS AS WE MAY WELL REINSTATE THIS FUNCTIONALITY LATER
import axios from "axios";
import moment from "moment";

import { getDates } from "@cortexglobal/linear-calendar";

export const SHOW_PLANNER_MODAL = "SHOW_PLANNER_MODAL";
export const HIDE_PLANNER_MODAL = "HIDE_PLANNER_MODAL";
export const SHOW_PLANNER_SUMMARY = "SHOW_PLANNER_SUMMARY";
export const HIDE_PLANNER_SUMMARY = "HIDE_PLANNER_SUMMARY";

export function showModal(modalName = "") {
    return {
        type: SHOW_PLANNER_MODAL,
        modalName: modalName,
    };
}

export function hideModal() {
    return {
        type: HIDE_PLANNER_MODAL,
    };
}

export function showSummary() {
    return {
        type: SHOW_PLANNER_SUMMARY,
    };
}

export function hideSummary() {
    return {
        type: HIDE_PLANNER_SUMMARY,
    };
}
// import { GET_ORDER } from "../../modules/orders/actions/index";
// import { loadTags, filterAssets } from "../../modules/search/actions/index";
// import {
//     LOAD_CATEGORY_ORDERS,
//     RESET_CATEGORY_ORDERS
// } from "../../modules/orders/actions/index";

export const LOAD_PLANNER_ACTIVITY_TYPES = "LOAD_PLANNER_ACTIVITY_TYPES";

export function getActivityTypes() {
    return (dispatch) => {
        axios
            .get(`/api/v1/planner/activity-types`)
            .then((response) => {
                //console.log(response.data.data);
                const { data } = response.data;
                // console.log(data);

                dispatch({
                    type: LOAD_PLANNER_ACTIVITY_TYPES,
                    data,
                });
            })
            .catch((e) => e);
    };
}

export const LOAD_PLANNER_CHILD_GROUPS = "LOAD_PLANNER_CHILD_GROUPS";

export function getPlannerChildGroups(groupAlias = null) {
    return (dispatch) => {
        axios
            .get(
                `/api/v1/group/children${
                    groupAlias ? `?groupAlias=${groupAlias}` : ""
                }`
            )
            .then((response) => {
                const { data } = response.data;

                dispatch({
                    type: LOAD_PLANNER_CHILD_GROUPS,
                    data,
                });
            })
            .catch((e) => e);
    };
}

export const LOAD_PLANNER_FILTERS = "LOAD_PLANNER_FILTERS";

export function getPlannerFilters() {
    return (dispatch) => {
        axios
            .get(`/api/v1/planner/filters`)
            .then((response) => {
                //console.log(response.data.data);
                const data = response.data;
                // console.log(data);

                dispatch({
                    type: LOAD_PLANNER_FILTERS,
                    data,
                });
            })
            .catch((e) => e);
    };
}
export const LOAD_PLANNER_CATEGORIES = "LOAD_PLANNER_CATEGORIES";

export function getPlannerCategories() {
    return (dispatch) => {
        axios
            .get(`/api/v1/planner/categories`)
            .then((response) => {
                //console.log(response.data.data);
                const { data } = response.data;
                // console.log(data);

                dispatch({
                    type: LOAD_PLANNER_CATEGORIES,
                    data,
                });
            })
            .catch((e) => e);
    };
}

export const PLANNER_SELECT_ACTIVITY = "PLANNER_SELECT_ACTIVITY";

export function selectActivity(activity) {
    return (dispatch) => {
        dispatch({
            type: PLANNER_SELECT_ACTIVITY,
            activity,
        });
    };
}

export const PLANNER_SELECT_TASK = "PLANNER_SELECT_TASK";

export function selectTask(task) {
    return (dispatch) => {
        dispatch({
            type: PLANNER_SELECT_TASK,
            task,
        });
    };
}

export const PLANNER_SELECT_CATEGORY = "PLANNER_SELECT_CATEGORY";

export function selectCategory(category) {
    return (dispatch) => {
        dispatch({
            type: PLANNER_SELECT_CATEGORY,
            category,
        });
    };
}

export const RESET_PLANNER_SELECTIONS = "RESET_PLANNER_SELECTIONS";

export function resetSelections() {
    return (dispatch) => {
        dispatch({
            type: RESET_PLANNER_SELECTIONS,
        });
    };
}

export const LOAD_MARKETING_PLAN = "LOAD_MARKETING_PLAN";
export const LOADING_MARKETING_PLAN = "LOADING_MARKETING_PLAN";

export function getPlan(startDate, endDate) {
    return (dispatch) => {
        dispatch({ type: LOADING_MARKETING_PLAN });
        axios
            .post(`/api/v1/planner/get-tasks?include=tasks,tasks.activities`, {
                startDate,
                endDate,
            })
            .then((response) => {
                //console.log(response.data.data);
                const data = response.data.data;
                //console.log(data);

                dispatch({
                    type: LOAD_MARKETING_PLAN,
                    data,
                });
            });
    };
}

export const LOADING_AVAILABLE_BUDGET = "LOADING_AVAILABLE_BUDGET";
export const LOAD_AVAILABLE_BUDGET = "LOAD_AVAILABLE_BUDGET";

export function getAvailableBudget() {
    return (dispatch, getState) => {
        try {
            dispatch({
                type: LOADING_AVAILABLE_BUDGET,
            });
            const startDate = getState().planner.startDate;
            const year = startDate
                ? moment(startDate).format("YYYY")
                : moment().format("YYYY");

            return axios
                .get(`/api/v1/budget/available/${year}`)
                .then((response) => {
                    //console.log(response.data.data);
                    const { data } = response.data;
                    // console.log(data);

                    dispatch({
                        type: LOAD_AVAILABLE_BUDGET,
                        data,
                    });
                });
        } catch (e) {
            console.log(e);
        }
    };
}
export function duplicatePlan(values) {
    //console.log(values);
    // const plannerMonth = moment(values.startDate);

    return (dispatch) => {
        return axios
            .post(`/api/v1/planner?include=tasks,tasks.activities`, values)
            .then((response) => {
                //console.log(response.data.data);
                const { data } = response.data;
                //console.log(data);
                dispatch(
                    generateDateRange(
                        moment(values.newStartDate).format(),
                        moment(values.newEndDate).format()
                    )
                );
                dispatch({
                    type: LOAD_MARKETING_PLAN,
                    data,
                });
                dispatch({
                    type: HIDE_PLANNER_MODAL,
                });
                return data;
            })
            .catch((e) => e);
    };
}

export const IMPORT_PLAN = "IMPORT_PLAN";
export const PLANNER_IMPORTING = "PLANNER_IMPORTING";

export function importPlan(file) {
    const fd = new FormData();
    fd.append("planFile", file);

    return (dispatch) => {
        dispatch({
            type: PLANNER_IMPORTING,
            importing: true,
        });
        axios
            .post("/api/v1/planner/import", fd)
            .then((response) => {
                var data = response.data;
                dispatch({
                    type: PLANNER_IMPORTING,
                    importing: false,
                });
                dispatch({
                    type: IMPORT_PLAN,
                    data,
                });
            })
            .catch((e) => e);
    };
}
export const SET_MARKETING_PLAN_DATE_RANGE = "SET_MARKETING_PLAN_DATE_RANGE";

export function generateDateRange(startDate, endDate, units = "days") {
    const dates = getDates(startDate, endDate, 1, units);
    return (dispatch) => {
        dispatch({
            type: SET_MARKETING_PLAN_DATE_RANGE,
            startDate,
            endDate,
            dates,
        });
    };
}

export const PLANNER_SET_EDITING = "PLANNER_SET_EDITING";

export function plannerEdit(type, data) {
    return (dispatch) => {
        dispatch({
            type: PLANNER_SET_EDITING,
            itemType: type,
            editing: true,
            data,
        });
    };
}

export const PLANNER_SET_NOT_EDITING = "PLANNER_SET_NOT_EDITING";

export function plannerStopEdit() {
    return (dispatch) => {
        dispatch({
            type: PLANNER_SET_NOT_EDITING,
        });
    };
}

export const ADD_PLANNER_TASK_CATEGORY = "ADD_PLANNER_TASK_CATEGORY";

export function addTaskCategory(values) {
    return (dispatch) => {
        return axios
            .post(`/api/v1/planner/categories`, values)
            .then((response) => {
                let data = response.data.data;
                // /console.log(data);
                data.activities = [];
                dispatch({
                    type: ADD_PLANNER_TASK_CATEGORY,
                    parentCategoryUuid: values.parent_category_uuid,
                    data,
                });
                dispatch({
                    type: HIDE_PLANNER_MODAL,
                });
            })
            .catch((e) => e);
    };
}

export const EDIT_PLANNER_TASK_CATEGORY = "EDIT_PLANNER_TASK_CATEGORY";

export function editTaskCategory(values) {
    return (dispatch) => {
        return axios
            .put(`/api/v1/planner/categories/${values.category_uuid}`, values)
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: EDIT_PLANNER_TASK_CATEGORY,
                    categoryUuid: values.category_uuid,
                    data,
                });
                dispatch({
                    type: HIDE_PLANNER_MODAL,
                });
                return data;
            })
            .catch((e) => e);
    };
}

export const setCategoryExpanded = (expanded, category) => {
    return (dispatch) => {
        return axios
            .put(`/api/v1/planner/categories/${category.uuid}/expanded`, {
                expanded,
            })
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: EDIT_PLANNER_TASK_CATEGORY,
                    categoryUuid: category.uuid,
                    data,
                });
                dispatch({
                    type: HIDE_PLANNER_MODAL,
                });
                return data;
            })
            .catch((e) => e);
    };
};

export const ADD_PLANNER_TASK = "ADD_PLANNER_TASK";

export function addTask(values) {
    return (dispatch) => {
        return axios.post(`/api/v1/planner/tasks`, values).then((response) => {
            let data = response.data.data;
            // /console.log(data);
            data.activities = [];
            dispatch({
                type: ADD_PLANNER_TASK,
                categoryUuid: values.category_uuid,
                data,
            });
            dispatch({
                type: HIDE_PLANNER_MODAL,
            });
            dispatch(getAvailableBudget());
            return data;
        });
    };
}

export const EDIT_PLANNER_TASK = "EDIT_PLANNER_TASK";

export function editTask(values) {
    return (dispatch) => {
        return axios
            .put(`/api/v1/planner/tasks/${values.task_uuid}`, values)
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: EDIT_PLANNER_TASK,
                    categoryUuid: values.category_uuid,
                    taskUuid: values.task_uuid,
                    data,
                });
                dispatch({
                    type: HIDE_PLANNER_MODAL,
                });
                dispatch(getAvailableBudget());
                return data;
            });
    };
}

export const DELETE_PLANNER_TASK = "DELETE_PLANNER_TASK";

export function deleteTask(uuid) {
    return (dispatch) => {
        return axios
            .delete(`/api/v1/planner/tasks/${uuid}`)
            .then((response) => {
                dispatch({
                    type: DELETE_PLANNER_TASK,
                    uuid,
                });
                dispatch({
                    type: HIDE_PLANNER_MODAL,
                });
            });
    };
}

export const ADD_PLANNER_ACTIVITY = "ADD_PLANNER_ACTIVITY";
export function addActivity(values) {
    return (dispatch) => {
        return axios
            .post(`/api/v1/planner/activities`, values)
            .then((response) => {
                const { data } = response.data;

                dispatch({
                    type: ADD_PLANNER_ACTIVITY,
                    taskUuid: values.task_uuid,
                    data,
                });

                return data;
            });
    };
}

export const EDIT_PLANNER_ACTIVITY = "EDIT_PLANNER_ACTIVITY";

export function editActivity(values) {
    return (dispatch) => {
        return axios
            .put(`/api/v1/planner/activities/${values.activity_uuid}`, values)
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: EDIT_PLANNER_ACTIVITY,
                    taskUuid: values.task_uuid,
                    activityUuid: values.activity_uuid,
                    data,
                });
                return data;
            });
    };
}

export const DELETE_PLANNER_ACTIVITY = "DELETE_PLANNER_ACTIVITY";

export function deleteActivity(activity_uuid, task_uuid) {
    return (dispatch) => {
        return axios
            .delete(`/api/v1/planner/activities/${activity_uuid}`)
            .then((response) => {
                dispatch({
                    type: DELETE_PLANNER_ACTIVITY,
                    taskUuid: task_uuid,
                    activityUuid: activity_uuid,
                });
                return response;
            })
            .catch((e) => e);
    };
}

export const SET_PLANNER_CONFIG_OPTIONS = "SET_PLANNER_CONFIG_OPTIONS";

export function getPlannerConfigOptions() {
    return (dispatch) => {
        return axios
            .get("/api/v1/planner/planner-config-options")
            .then(({ data }) => {
                dispatch({
                    type: SET_PLANNER_CONFIG_OPTIONS,
                    data: data,
                });
                return data;
            })
            .catch((e) => e);
    };
}

export const ADD_PLANNER_ORDER_DETAILS = "ADD_PLANNER_ORDER_DETAILS";

export function startOrderProcess(data) {
    return (dispatch) => {
        dispatch({
            type: ADD_PLANNER_ORDER_DETAILS,
            data,
        });
    };
}

export const REMOVE_PLANNER_ORDER_DETAILS = "REMOVE_PLANNER_ORDER_DETAILS";

export function cancelOrderProcess() {
    return (dispatch) => {
        dispatch({
            type: REMOVE_PLANNER_ORDER_DETAILS,
        });
    };
}

// export const ADD_PLANNER_ACTIVITY = "ADD_PLANNER_ACTIVITY";

// /**
//  * Internal function to handle the shared outcome of an add activity request
//  * @param {*} response
//  * @param {*} values
//  */
// function completeAddingActivity(response, values) {
//     return (dispatch, getState) => {
//         var data = response.data;
//         dispatch({
//             type: ADD_PLANNER_ACTIVITY,
//             categoryUuid: values.category_uuid,
//             taskUuid: values.task_uuid,
//             data
//         });
//         dispatch({
//             type: HIDE_PLANNER_MODAL
//         });
//         dispatch({
//             type: HIDE_PLANNER_SUMMARY
//         });
//     };
// }

// export const EDIT_PLANNER_ACTIVITY = "EDIT_PLANNER_ACTIVITY";
// /**
//  * Internal function to handle the shared outcome of an edit activity request
//  * @param {*} response
//  * @param {*} values
//  */
// function completeEditingActivity(response, values) {
//     return (dispatch, getState) => {
//         var data = response.data;
//         dispatch({
//             type: EDIT_PLANNER_ACTIVITY,
//             categoryUuid: values.category_uuid,
//             taskUuid: values.task_uuid,
//             activityUuid: values.activity_uuid,
//             data
//         });
//         dispatch({
//             type: HIDE_PLANNER_MODAL
//         });
//         dispatch({
//             type: PLANNER_SELECT_ACTIVITY,
//             activity: data
//         });
//         dispatch({
//             type: HIDE_PLANNER_SUMMARY
//         });
//     };
// }

// export const DELETE_PLANNER_ACTIVITY = "DELETE_PLANNER_ACTIVITY";
// /**
//  * Internal function to handle the shared outcome of a delete activity request
//  * @param {*} values
//  */
// function completeDeletingActivity(values) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: DELETE_PLANNER_ACTIVITY,
//             categoryUuid: values.category_uuid,
//             taskUuid: values.task_uuid,
//             activityUuid: values.activity_uuid
//         });
//         dispatch({
//             type: HIDE_PLANNER_MODAL
//         });
//         dispatch({
//             type: HIDE_PLANNER_SUMMARY
//         });
//     };
// }

// export function addPlannedActivity(values) {
//     return dispatch => {
//         axios
//             .post(`/api/v1/planner/planned-activities`, values)
//             .then(response => {
//                 dispatch(completeAddingActivity(response.data, values));
//             })
//             .catch(e => e);
//     };
// }

// export function editPlannedActivity(values) {
//     return dispatch => {
//         axios
//             .put(
//                 `/api/v1/planner/planned-activities/${values.activity_uuid}`,
//                 values
//             )
//             .then(response => {
//                 dispatch(completeEditingActivity(response.data, values));
//             })
//             .catch(e => e);
//     };
// }
// export function deletePlannedActivity(values) {
//     return dispatch => {
//         axios
//             .delete(
//                 `/api/v1/planner/planned-activities/${values.activity_uuid}`
//             )
//             .then(response => {
//                 dispatch(completeDeletingActivity(values));
//             })
//             .catch(e => e);
//     };
// }

// export function addNationalPlannedActivity(values) {
//     return dispatch => {
//         return axios
//             .post(`/api/v1/planner/national-activities`, values)
//             .then(response => {
//                 dispatch(completeAddingActivity(response.data, values));
//             });
//     };
// }

// export function editNationalActivity(values) {
//     return dispatch => {
//         axios
//             .put(
//                 `/api/v1/planner/national-activities/${values.activity_uuid}`,
//                 values
//             )
//             .then(response => {
//                 dispatch(completeEditingActivity(response.data, values));
//             })
//             .catch(e => e);
//     };
// }

// export function deleteNationalActivity(values) {
//     return dispatch => {
//         axios
//             .delete(
//                 `/api/v1/planner/national-activities/${values.activity_uuid}`
//             )
//             .then(response => {
//                 dispatch(completeDeletingActivity(values));
//             })
//             .catch(e => e);
//     };
// }

// export function addExternalActivity(values) {
//     return dispatch => {
//         axios
//             .post(`/api/v1/planner/external-activities`, values)
//             .then(response => {
//                 dispatch(completeAddingActivity(response.data, values));
//             })
//             .catch(e => e);
//     };
// }

// export function editExternalActivity(values) {
//     return dispatch => {
//         axios
//             .put(
//                 `/api/v1/planner/external-activities/${values.activity_uuid}`,
//                 values
//             )
//             .then(response => {
//                 dispatch(completeEditingActivity(response.data, values));
//             })
//             .catch(e => e);
//     };
// }

// export function deleteExternalActivity(values) {
//     return dispatch => {
//         axios
//             .delete(
//                 `/api/v1/planner/external-activities/${values.activity_uuid}`
//             )
//             .then(response => {
//                 dispatch(completeDeletingActivity(values));
//             })
//             .catch(e => e);
//     };
// }

// export const PLANNER_LINK_ACTIVITY = "PLANNER_LINK_ACTIVITY";
// export function linkActivity(values) {
//     //console.log(values);

//     return dispatch => {
//         axios
//             .post(`/api/v1/planner/internal-activity`, values)
//             .then(response => {
//                 //console.log(response);
//                 //Can this following response hanling be shared???
//                 var data = response.data;
//                 //console.log("Data", data);
//                 dispatch({
//                     type: ADD_PLANNER_ACTIVITY,
//                     categoryUuid: values.category_uuid,
//                     taskUuid: values.task_uuid,
//                     data
//                 });
//                 dispatch({
//                     type: HIDE_PLANNER_MODAL
//                 });
//             })
//             .catch(e => e);
//     };
// }

// export function showActivityOrder(activity) {
//     return dispatch => {
//         axios
//             .get(`/api/v1/planner/internal-activities/${activity.uuid}/order`)
//             .then(response => {
//                 //console.log(response.data.data);
//                 const order = response.data.data;

//                 // dispatch({
//                 //     type: GET_ORDER,
//                 //     data: order
//                 // });

//                 // dispatch(push(`/orders/${order.uuid}`));
//             })
//             .catch(e => e);
//     };
// }

// export const PLANNER_ADDING_ORDER_TO_PLAN = "PLANNER_ADDING_ORDER_TO_PLAN";

// export function addOrderToPlan(activity, task, category) {
//     //console.log(category);

//     return (dispatch, getState) => {
//         /*

// TODO - Look at getting the order and filtering functionality working

//         dispatch(loadTags()).then(() => {
//             //Call the get tags from the search actions
//             let tags = getState().search.tagGroups;
//             //console.log("Tags", tags);

//             //Set the media entries for this category to be active, perhaps make use of toggleSelectedTag from the search actions
//             const mediaTypes = JSON.parse(category.mediaTypes);
//             //console.log(mediaTypes, mediaTypes);
//             const updatedTags = {
//                 ...tags,
//                 media: tags.media.map(media => {
//                     //console.log(media.id);
//                     if (mediaTypes.indexOf(parseInt(media.id)) !== -1) {
//                         media.active = true;
//                     }
//                     return media;
//                 })
//             };
//             //console.log(updatedTags);

//             //Call the filterAssets from the search actions using the updated tag groups
//             dispatch(filterAssets(updatedTags)).then(() => {
//                 //console.log("redirecting");
//                 //Set the state to know that we're adding an order to the planner (use to display some sort of hint, e.g. floating button in the bottom right)
//                 dispatch({
//                     type: PLANNER_ADDING_ORDER_TO_PLAN,
//                     activity,
//                     task,
//                     category
//                 });

//                 //Then push the user to the asset search page
//                 dispatch(push(`/assets`));
//             });
//         });
//     */
//     };
// }

// export const PLANNER_STOP_ADDING_ORDER_TO_PLAN =
//     "PLANNER_STOP_ADDING_ORDER_TO_PLAN";
// export function stopAddingToPlanner() {
//     return dispatch => {
//         dispatch({
//             type: PLANNER_STOP_ADDING_ORDER_TO_PLAN
//         });
//     };
// }

// export const PLANNER_COMPLETE_ADDING_ORDER_TO_PLAN =
//     "PLANNER_COMPLETE_ADDING_ORDER_TO_PLAN";
// export function completeAddingToPlanner() {
//     return dispatch => {
//         dispatch({
//             type: PLANNER_COMPLETE_ADDING_ORDER_TO_PLAN
//         });
//     };
// }

// export function stopAddingAndbackToPlanner() {
//     return dispatch => {
//         dispatch(stopAddingToPlanner());
//         // dispatch(push(`/planner`));
//     };
// }
