import styled, { css } from "styled-components";

//@ts-ignore cortex
import { transparentize } from "@cortexglobal/cortex-utilities";

export const UtilityButton = styled.button<{
    theme: { [key: string]: any };
}>`
    background: ${({ theme }) => theme.buttonBackgroundColor};
    outline: none;
    border: ${({ theme }) => {
        if (theme.buttonBorder) {
            return css`1px solid ${theme.buttonColor}`;
        } else {
            return css`none`;
        }
    }};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.buttonRadius}%;
    color: ${({ theme }) => theme.buttonColor};
    cursor: pointer;
    width: 2.5em;
    height: 2.5em;
    font-size: 0.55em;
    margin-right: 0.5em;
    transition: all 200ms;

    &:hover:not([disabled]) {
        background: ${({ theme }) => theme.buttonColor};
        color: ${({ theme }) => theme.buttonBackgroundColor};
        border: ${({ theme }) => {
            if (theme.buttonBorder) {
                return css`1px solid ${theme.buttonBackgroundColor}`;
            } else {
                return css`none`;
            }
        }};
    }

    &:disabled {
        background: ${({ theme }) =>
            theme.buttonBackgroundColor
                ? transparentize(theme.buttonBackgroundColor, 0.8)
                : "none"};
        cursor: not-allowed;
        opacity: 0.4;
    }
`;

export const Spacer = styled.div<{ theme: { [key: string]: any } }>`
    background: ${({ theme }) => theme.buttonColor};
    margin-left: 0.5em;
    margin-right: 0.7em;
    height: 0.7em;
    width: 0.08em;
`;

export const Logo = styled.img<{ theme: { [key: string]: any } }>`
    max-width: 6em;
    max-height: 3em;
    position: absolute;
    ${({ theme }) => {
        if (theme.logoAlignment === "left") {
            return css`
                left: 2em;
            `;
        } else {
            return css`
                right: 2em;
            `;
        }
    }}
    margin-top: -0.5em;
`;
