import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledStarRating from "../styledElements/StyledStarRating";

const FormikStarRating = ({
    type,
    name,
    label,
    disabled,
    labelWidth,
    labelAlign,
    showError,
    ...rest
}) => {
    // const handleChange = event => {
    //     onChange({
    //         name: name,
    //         value: event.target.value
    //     });
    // };

    return (
        <React.Fragment>
            <Field name={name} {...rest}>
                {({
                    field,
                    form: { touched, errors, setFieldValue, setFieldTouched }
                }) => {
                    const error = !!touched[field.name] && errors[field.name];
                    return (
                        <React.Fragment>
                            {label && (
                                <FormLabel
                                    align={labelAlign}
                                    width={labelWidth}
                                    name={name}
                                    {...rest}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <StyledStarRating
                                {...rest}
                                name={name}
                                id={name}
                                labelWidth={labelWidth}
                                onChange={(
                                    nextValue,
                                    prevValue,
                                    name,
                                    event
                                ) => {
                                    setFieldValue(name, nextValue);
                                    setFieldTouched(name, true);
                                }}
                                error={error}
                                disabled={disabled}
                            />
                        </React.Fragment>
                    );
                }}
            </Field>
            {showError && (
                <ErrorMessage name={name}>
                    {msg => {
                        //console.log("msg", msg);
                        return <InputError error={msg} />;
                    }}
                </ErrorMessage>
            )}
        </React.Fragment>
    );
};

FormikStarRating.displayName = "FormikStarRating";

export default FormikStarRating;
