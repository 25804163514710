import React, { Component } from "react";
import { Row, Column } from "@cortexglobal/rla-components";
//import PanelContentContainer from "./PanelContentContainer";

class CampaignSummaryOutput extends Component {
    render() {
        return (
            <Row>
                <Column>
                    <h3>Campaign Panel Output</h3>
                    {this.props.customTitle}
                    {this.props.timeframe}
                </Column>
            </Row>
        );
    }
}

export default CampaignSummaryOutput;
