import React from "react";
import { createGlobalStyle } from "styled-components";
import { ToastList } from "@cortexglobal/rla-components";
import { Switch, Route } from "react-router-dom";

import Theme from "./config/theme";

import { PrivateRoute } from "@cortexglobal/cortex-auth-react-router-dom";
import PrivateApp from "./components/global/PrivateApp";
import PublicApp from "./components/global/PublicApp";
import { useTracker } from "./components/routing/useTracker";

import HeadContent from "./components/global/HeadContent";

import globalCss from "./config/globalCss";
import AdminApp from "./components/global/AdminApp";
// import ScrollToTop from "./components/global/ScrollToTop";

//Add Global CSS
const GlobalStyle = createGlobalStyle`${globalCss(Theme)}`;

//Function to render the app, used on initial load and during HMR
export const App = () => {
    useTracker();

    return (
        <React.Fragment>
            <GlobalStyle />
            <HeadContent />
            <Switch>
                <Route path={"/auth"} component={PublicApp} />
                <PrivateRoute path="/admin" component={AdminApp} />
                <PrivateRoute path="/" component={PrivateApp} />
            </Switch>
            <ToastList />
        </React.Fragment>
    );
};
export default App;
