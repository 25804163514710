import React, { useState, useContext, useCallback, useEffect } from "react";
import axios from "axios";

import {
    SubmitButton,
    IconTextButton,
    Modal
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import AssetContext from "../AssetContext";

const CloneAsset = ({ assetsPath, asset }) => {
    const [showCreateCloneModal, setShowCreateCloneModal] = useState(false);

    const { can } = usePermissions();
    const { user, history, setRefreshSearch } = useContext(AssetContext);

    const createClone = useCallback(
        event => {
            event.preventDefault();

            axios
                .post(`/api/v1/admin/assets/clone`, {
                    assetUuid: asset.uuid
                })
                .then(({ data: { data } }) => {
                    console.log(
                        "assetsPath",
                        assetsPath,
                        `${assetsPath}/${data.uuid}`
                    );

                    setRefreshSearch(true);
                    history.push(
                        `${assetsPath}/${data.uuid}/${
                            can("dam-asset-admin") ? "admin" : "edit"
                        }`
                    );
                    onClose();
                })
                .catch(e => console.log(e));
        },
        [asset]
    );
    return (
        <React.Fragment>
            <IconTextButton
                type="primary"
                expanded
                onClick={event => {
                    setShowCreateCloneModal(true);
                }}
            >
                {trans("Clone Asset")}
            </IconTextButton>

            {showCreateCloneModal && (
                <Modal
                    visible={showCreateCloneModal}
                    onClose={() => setShowCreateCloneModal(false)}
                >
                    <h3>{trans("Create a Cloned Asset")}</h3>
                    <form onSubmit={createClone}>
                        <SubmitButton>{trans("Generate Clone")}</SubmitButton>
                    </form>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default CloneAsset;
