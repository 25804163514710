import React from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";

import styled, { css, withTheme } from "styled-components";

const Loader = styled.div`
    position: relative;
    display: ${({ animationState }) => {
        const value =
            animationState === "entering" ||
            animationState === "entered" ||
            animationState === "exiting"
                ? "block"
                : "inline-block";

        return value;
    }};
    width: 100%;
    background: none;

    border-radius: ${({ theme }) =>
        theme.loaders.contentBlockLoader.borderRadius};
    &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: ${({ animationState }) => {
            const opacity =
                animationState === "entering" || animationState === "entered"
                    ? 1
                    : -1;
            return opacity;
        }};
        background-image: ${({ theme }) => {
            return `linear-gradient(
                ${theme.loaders.contentBlockLoader.gradientAngle}, 
                ${theme.loaders.contentBlockLoader.background},
                ${theme.loaders.contentBlockLoader.foreground}, 
                ${theme.loaders.contentBlockLoader.background}
            )`;
        }};
        background-size: 400% 400%;
        transition: opacity ${({ theme }) => theme.animations.duration.medium}ms;
        opacity: ${({ theme, animationState }) => {
            const opacity =
                animationState === "entering" || animationState === "entered"
                    ? theme.loaders.contentBlockLoader.opacity
                    : 0;
            return opacity;
        }};
        animation: gradient 5s ease infinite;

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    }
`;

const Children = styled.span`
    display: ${({ forceBlock }) => (forceBlock ? "block" : "inline-block")};
    width: 100%;

    ${({ hideOverflow }) => {
        if (hideOverflow) {
            return css`
                overflow: hidden;
                text-overflow: ellipsis;
            `;
        }
    }}

    opacity: ${({ animationState, theme }) => {
        const value =
            animationState === "entering" || animationState === "entered"
                ? 0
                : 1;

        return value;
    }};
    transition: all ${({ theme }) => theme.animations.duration.long}ms;
`;

const ContentBlockLoader = withTheme(
    ({ children, theme, hideOverflow, loaded = true, ...rest }) => {
        return (
            <Transition
                in={!loaded}
                timeout={theme.animations.duration.long}
                appear={true}
            >
                {(animationState) => (
                    <Loader animationState={animationState} {...rest}>
                        <Children
                            animationState={animationState}
                            forceBlock={!children}
                            hideOverflow={hideOverflow}
                        >
                            {children && loaded ? (
                                children
                            ) : (
                                <span>&nbsp;</span>
                            )}
                        </Children>
                    </Loader>
                )}
            </Transition>
        );
    }
);

ContentBlockLoader.displayName = "ContentBlockLoader";

ContentBlockLoader.propTypes = {
    /** A css color (using rgba will allow transparency) */
    foregroundColor: PropTypes.string,
    /** A css color (using rgba will allow transparency) */
    backgroundColor: PropTypes.string,
    /** A css width */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** A css height */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Hide any overflow using ellipses (useful when dealing with numbers) */
    hideOverflow: PropTypes.bool,
};

ContentBlockLoader.defaultProps = {
    width: "100%",
    height: "100%",
    hideOverflow: true,
};
export default ContentBlockLoader;
