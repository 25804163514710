import React, { Component } from "react";
import axios from "axios";
import { FormikAdvancedSelect } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const GroupSelector = ({
    // value,
    groups: { data, loaded },
    multi = true,
    allowSelectAll = true,
    name = "related_groups",
    label = trans("Related Groups")
}) => {
    if (!loaded || data.length <= 0) {
        return null;
    }
    // console.log(name, value);
    return (
        <FormikAdvancedSelect
            name={name}
            multi={multi}
            allowSelectAll={allowSelectAll}
            label={label}
            // value={value}
            options={data.map(group => {
                return {
                    value: group.alias,
                    text: group.name
                };
            })}
        />
    );
};

export default GroupSelector;
