import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { EmptyRow } from "../../components/EmptyRow";
import { Role } from "../../forms/RoleForm";

const PaddedDiv = styled.div`
    padding: 3em;
`;

const TableHeader = styled.div`
    cursor: pointer;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`;

export const RoleTable = ({
    items,
    loaded,
    path,
    pagination = null,
    deleteItem,
    onPageChange,
    onOrderBy,
    onPerPageChange,
}: RoleTableProps) => {
    const currentPage = pagination ? pagination.current_page : 1;

    const [deleteRoleModalShowing, setDeleteRoleModalShowing] = useState(false);
    const [deleteRoleName, setDeleteRoleName] = useState("");
    const [deleteRoleId, setDeleteRoleId] = useState(null);

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>{trans("Name")}</th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("slug")}>
                                {trans("Slug")}
                            </TableHeader>
                        </th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <EmptyRow colSpan={3}>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow colSpan={3}>
                            {trans("No items found")}
                        </EmptyRow>
                    ) : (
                        items.map((item) => {
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.alias}</td>
                                    <td>
                                        <IconTextButton
                                            as={Link}
                                            to={`${path}/edit/${item.uuid}?page=${currentPage}`}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEdit}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            type="alert"
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                            onClick={(event) => {
                                                setDeleteRoleId(item.uuid);
                                                setDeleteRoleModalShowing(true);
                                                setDeleteRoleName(item.name);
                                            }}
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
            {deleteRoleModalShowing && (
                <Modal
                    visible={deleteRoleModalShowing}
                    onClose={() => {
                        setDeleteRoleModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h1>You are about delete role: {deleteRoleName}</h1>

                        <p>{trans("Are you sure you want to proceed?")}</p>
                        <Button
                            type="hollow"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeleteRoleModalShowing(false);
                            }}
                        >
                            {trans("Cancel")}
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                deleteItem(deleteRoleId).catch((e) =>
                                    setDeleteRoleModalShowing(false)
                                );
                            }}
                        >
                            {trans("Delete")}
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}
        </React.Fragment>
    );
};

interface RoleTableProps {
    items: Role[];
    loaded: boolean;
    path: string;
    pagination?: any;
    deleteItem: (number) => Promise<void>;
    onPageChange?: (number) => void;
    onOrderBy?: (string) => void;
    onPerPageChange?: ({ value }) => void;
}

export default RoleTable;
