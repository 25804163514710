import { useSelector } from "react-redux";
import { get } from "lodash";

const useGroup = () => {
    let group = useSelector((state) => {
        return get(state, "auth.group", {});
    });

    return { group };
};

export default useGroup;
