import axios from "axios";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { useAddToast, useToasts } from "@cortexglobal/rla-components";

import { Category } from "../forms/CategoryForm";
const useCategories = (categoryArea) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();
    const area = categoryArea.toLowerCase();

    const loadToast = (message) => {
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: message,
        });
    };

    const removeLoadToast = () => {
        dispatch({ type: "removeToast", uuid: "loadToast" });
    };

    const loadItems = (
        setItems,
        page,
        search,
        orderBy,
        orderByDir,
        perPage
    ) => {
        setItems({
            loaded: false,
        });
        axios
            .get(`/api/v1/admin/${area}-category`, {
                params: {
                    page: page,
                    search: search ? search : null,
                    sortDirection: orderByDir ? orderByDir : null,
                    sortField: orderBy ? orderBy : null,
                    perPage: perPage ? perPage : 10,
                    include: "allI18n,media",
                },
            })
            .then(({ data }) => {
                setItems({
                    loaded: true,
                    data: data.data,
                    pagination: data.meta,
                });
            })
            .catch();
    };
    const deleteItem = (
        uuid,
        setItems,
        page = "1",
        search,
        orderBy,
        orderByDirection,
        perPage
    ): Promise<Category[]> => {
        loadToast(
            trans("Deleting {area} category", {
                area,
            })
        );
        return axios
            .delete(`/api/v1/admin/${area}-category/${uuid}`)
            .then(({ data }) => {
                removeLoadToast();
                loadItems(
                    setItems,
                    page,
                    search,
                    orderBy,
                    orderByDirection,
                    perPage
                );
                return data;
            })
            .catch((e) => {
                removeLoadToast();

                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred deleting the category"
                        ),
                        showFor: 5000,
                    });
                }
            });
    };

    return {
        loadItems,
        deleteItem,
    };
};

export default useCategories;
