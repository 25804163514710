import React, { useState } from "react";
import styled from "styled-components";
//@ts-ignore cortex
import { Image, Modal } from "@cortexglobal/rla-components";
const StyledImage = styled(Image)`
    max-height: ${({ maxHeight }) => maxHeight};
    cursor: pointer;
    margin-bottom: 1rem;
`;
const ImageWrapper = styled.div`
    display: flex;
    /* min-height: 80vh; */
    width: 100%;
    overflow: auto;
`;
export const PreviewImage = ({ src, maxHeight = "80px", ...rest }) => {
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <React.Fragment>
            <StyledImage
                onClick={() => setModalVisible(true)}
                src={src}
                maxHeight={maxHeight}
                {...rest}
            />
            {modalVisible && (
                <>
                    {/* @ts-ignore cortex */}
                    <Modal
                        visible={modalVisible}
                        maxWidth="90%"
                        onClose={() => setModalVisible(false)}
                        showCloseButton={false}
                    >
                        <ImageWrapper onClick={() => setModalVisible(false)}>
                            <Image src={src} />
                        </ImageWrapper>
                    </Modal>
                </>
            )}
        </React.Fragment>
    );
};
