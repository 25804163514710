import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import CommunicationRuleListing from "./CommunicationRuleListing";
import CommunicationRuleCreate from "./CommunicationRuleCreate";
import CommunicationRuleActivityListing from "./CommunicationRuleActivityListing";
import { CommunicationRuleEdit } from "./CommunicationRuleEdit";

export const CommunicationRuleAdmin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <CommunicationRuleListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create/:smartRef`}
                render={(props) => {
                    const {
                        match: {
                            params: { smartRef },
                        },
                    } = props;
                    return <CommunicationRuleCreate path={path} smartRef={smartRef}/>;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <CommunicationRuleCreate path={path} />;
                }}
            />

            <Route
                exact
                path={`${path}/:uuid/activity`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <CommunicationRuleActivityListing path={path} uuid={uuid} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <CommunicationRuleEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default CommunicationRuleAdmin;
