import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    InputField,
    StyledCheckbox,
    FormLabel,
    Select,
    Badge
} from "@cortexglobal/rla-components";

const PageWarning = styled(Badge)`
    // display: inline-block;
    margin: 0 0 0.2rem 0.5rem;
    padding: 0.1rem 0.5rem;
    background: ${({ theme }) => theme.colors.alert};
    color: ${({ theme }) => theme.colors.white};
`;
const EditorInputGroup = ({
    pageOptions,
    path,
    navItem,
    onChange,
    chooseImage
}) => {
    console.log("navItem.internal", navItem.internal);
    const internal =
        typeof navItem.internal !== "undefined" ? navItem.internal : true;
    const page = pageOptions.find(page => {
        // console.log(page.value, navItem.url);
        return page.value === navItem.url;
    });
    // console.log(internal, page);

    return (
        <React.Fragment key={navItem.name}>
            <Row>
                <Column medium={2}>
                    <FormLabel>{trans("Internal")}</FormLabel>
                    <StyledCheckbox
                        id={navItem.name + "_internal"}
                        name={navItem.name}
                        checked={internal}
                        onChange={event => onChange("internal", path, event)}
                    />
                </Column>
                <Column medium={4}>
                    {navItem.internal ? (
                        <Select
                            label={
                                <React.Fragment>
                                    {trans("Page")}
                                    {internal &&
                                        ((page && page.hidden) || !page) && (
                                            <PageWarning>
                                                {trans(
                                                    "page unavailable, so link will be hidden"
                                                )}
                                            </PageWarning>
                                        )}
                                </React.Fragment>
                            }
                            id={navItem.name + "_label"}
                            create={true}
                            name={navItem.name}
                            options={pageOptions}
                            value={navItem.url}
                            onChange={event => onChange("url", path, event)}
                        />
                    ) : (
                        <InputField
                            label={trans("Link")}
                            id={navItem.name + "_label"}
                            create={true}
                            name={navItem.name}
                            value={navItem.url}
                            onChange={event => onChange("url", path, event)}
                            placeholder="page/url"
                        />
                    )}
                </Column>
                <Column medium={6}>
                    <InputField
                        label={trans("Current Content")}
                        name={navItem.name}
                        value={navItem.text}
                        onChange={event => onChange("text", path, event)}
                    />
                </Column>
            </Row>
            {false && (
                <Row>
                    <Column medium={6}>
                        <InputField
                            label={trans("Image")}
                            name={navItem.name}
                            value={navItem.image}
                            onChange={event => onChange("image", path, event)}
                        />
                    </Column>
                    <Column medium={6}>
                        <InputField
                            label={trans("Colour")}
                            type="color"
                            name={navItem.name}
                            value={navItem.color}
                            onChange={event => onChange("color", path, event)}
                        />
                    </Column>
                </Row>
            )}
        </React.Fragment>
    );
};

export default EditorInputGroup;
