import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";

import { trans } from "@cortexglobal/rla-intl";
import {
    PageTitle,
    IconTextButton,
    Loading,
    Row,
    Column
} from "@cortexglobal/rla-components";

const getOptions = (selections, setOptions) => {
    axios
        .get("/api/v1/assets/categories", {
            params: {
                selections: JSON.stringify(selections),
                filterConfig: "assets-default"
            }
        })
        .then(({ data }) => {
            //   console.log("data", data);
            setOptions({
                loaded: true,
                data: data.data
            });
        })
        .catch(e => e);
};

const AssetBrowser = () => {
    let query = queryString.parse(useLocation().search);
    let urlSelections = [];
    const queryCategories = query.categories;
    if (queryCategories) {
        const rawUrlSelections = JSON.parse(queryCategories).selections;
        if (Array.isArray(rawUrlSelections)) {
            urlSelections = rawUrlSelections;
        }
    }
    // const [filters, setFilters] = useState({ loaded: false, data: [] });
    //Used to load the options based on the current level and selections
    const [options, setOptions] = useState({
        loaded: false,
        data: { options: [], filter: {}, selectedCategory: null }
    });

    const [selections, setSelections] = useState([]);

    useEffect(() => {
        setSelections(urlSelections);
        // console.log(urlSelections, selections);
        getOptions(urlSelections, setOptions);
    }, []);

    const chooseOption = (selectedSlug, filter) => {
        // console.log(filter);
        const newSelections = [
            ...selections,
            { filter: filter.alias, selection: selectedSlug }
        ];
        setSelections(newSelections);
        getOptions(newSelections, setOptions);
    };

    //Get the category slug list from the query string and shove it into state.  Load the last one as the current.
    // console.log(options);
    // console.log(selections);

    //http://localhost:3020/resources/browse?categories={%22selections%22:[{%22filter%22:%22therapy-type%22,%22selection%22:%22respiritory%22},{%22filter%22:%22product%22,%22selection%22:%22privigen%22}]}

    if (!options.loaded) {
        return <Loading />;
    }

    return (
        <Row>
            <PageTitle
                title={trans("Browse {category}", {
                    category: options.data.filter.label
                })}
                margin={0}
            ></PageTitle>
            {options.data.options
                .filter(category => category.asset_count > 0)
                .map(category => {
                    return (
                        <Column
                            small={12}
                            medium={6}
                            large={4}
                            key={category.slug}
                        >
                            <IconTextButton
                                onClick={event =>
                                    chooseOption(
                                        category.slug,
                                        options.data.filter
                                    )
                                }
                            >{`${category.text} = ${category.asset_count} assets`}</IconTextButton>
                        </Column>
                    );
                })}
            {false && JSON.stringify(options.data, null, 2)}
        </Row>
    );
};

export default AssetBrowser;
