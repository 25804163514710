import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormikAdvancedSelect } from "@cortexglobal/rla-components";

const PlannerFormikMediaChannelSelect = ({ value, ...rest }) => {
    const [mediaChannels, setMediaChannels] = useState({
        loaded: false,
        data: [],
    });

    useEffect(() => {
        loadMediaChannels();
    }, []);

    const loadMediaChannels = () => {
        axios.get(`api/v1/planner/media-channels`).then((response) => {
            const channels = response.data.data;
            setMediaChannels({ loaded: true, data: channels });
        });
    };

    return (
        <FormikAdvancedSelect
            options={mediaChannels.loaded === true ? mediaChannels.data : []}
            {...rest}
        />
    );
};

export default PlannerFormikMediaChannelSelect;
