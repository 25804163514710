import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Column } from "@cortexglobal/rla-components";

import CalendarTaskCategory from "./CalendarTaskCategory";
import CalendarContext from "./CalendarContext";
import { CategoryShape, TaskShape } from "./calendarPropTypes";
// import { Collapse } from "@cortexglobal/rla-components";

const CalendarCategoryHeader = styled(Row)`
    cursor: pointer;
    border-top: 0.2rem solid
        ${props =>
            props.colors.taskCategory && props.colors.taskCategory.background
                ? props.colors.taskCategory.background
                : "#000"};
    padding: 0.5rem;
    position: relative;
    /* z-index: 4; */
    background: ${props =>
        props.colors.calendarCategory &&
        props.colors.calendarCategory.background
            ? props.colors.calendarCategory.background
            : "#000"};
    color: ${props =>
        props.colors.calendarCategory && props.colors.calendarCategory.color
            ? props.colors.calendarCategory.color
            : "#fff"};
    h4 {
        margin-bottom: 0;
    }
`;

const Arrow = styled(FontAwesomeIcon)`
    margin-right: 0.8rem;
    font-size: 0.65rem;
`;

const CalendarCategory = props => {
    const { category } = props;
    // const { showTasks } = this.state;
    const [showTasks, setShowTasks] = useState(category.expanded);
    const {
        categorySummary,
        colors,
        addTaskCategory,
        onShowTasks
    } = useContext(CalendarContext);

    return (
        <React.Fragment>
            <CalendarCategoryHeader colors={colors}>
                <Column medium={9}>
                    <h4
                        onClick={() => {
                            // setAnimating(true);
                            const showing = !showTasks;
                            setShowTasks(showing);
                            onShowTasks(showing, category);
                        }}
                    >
                        <Arrow
                            icon="chevron-down"
                            transform={{
                                rotate: showTasks ? 180 : 0
                            }}
                        />
                        {category.name}
                        {!showTasks && (
                            <span> ({category.taskCategories.length})</span>
                        )}
                    </h4>
                </Column>
                <Column medium={3}>
                    {categorySummary &&
                        React.createElement(categorySummary, {
                            category,
                            addTaskCategory
                        })}
                </Column>
            </CalendarCategoryHeader>

            <Collapse isOpened={showTasks}>
                {category.taskCategories.map(taskCategory => {
                    taskCategory.tasks = category.tasks.filter(
                        task => task.primary_category_id === taskCategory.id
                    );
                    return (
                        <CalendarTaskCategory
                            key={taskCategory.id}
                            category={taskCategory}
                            canAdminCategory={category.can_admin_items}
                            canAdminItems={taskCategory.can_admin_items}
                        />
                    );
                })}
            </Collapse>
        </React.Fragment>
    );
};

CalendarCategory.propTypes = {
    category: PropTypes.shape({
        CategoryShape,
        tasks: PropTypes.arrayOf(TaskShape),
        taskCategories: PropTypes.arrayOf(CategoryShape)
    })
};

CalendarCategory.defaultProps = {};

export default CalendarCategory;
