import React, { useEffect } from "react";
// import styled from "styled-components";
// import { connect } from "react-redux";

import { trans } from "@cortexglobal/rla-intl";
import {
    ButtonDropdown,
    StyledCheckbox,
    Table,
} from "@cortexglobal/rla-components";

import AssetResultItem from "./AssetResultItem";

const AssetListView = ({
    assets,
    onClick,
    selectAsset,
    maximumAssetsSelected,
    loaded,
    permissions,
    canEdit,
    selectMode,
    selectedAssets,
    toggleSelectedAsset,
}) => {
    /**
     * Get all the titles custom rows from the assets to create the table headers
     */
    const customRows = assets.reduce((acc, asset) => {
        const keys = Object.keys(asset.label?.subHeading);
        return acc.length < keys.length ? keys : acc;
    }, []);

    const icon = assets[0]?.label?.icon
        ? Object.keys(assets[0].label.icon)
        : {};

    return (
        <Table>
            <thead>
                <tr>
                    {selectMode && <th />}
                    <th>{trans("Name")}</th>
                    {customRows.map((customRow) => (
                        <th>{customRow}</th>
                    ))}
                    <th />
                </tr>
            </thead>
            <tbody>
                {assets.map((asset) => (
                    <AssetResultItem
                        toggleSelectedAsset={toggleSelectedAsset}
                        view="list"
                        maximumAssetsSelected={maximumAssetsSelected}
                        loaded={loaded}
                        asset={asset}
                        onClick={onClick}
                        selectAsset={selectAsset}
                        key={asset.uuid}
                        permissions={permissions}
                        canEdit={canEdit}
                        maxColumns={3}
                        selectMode={selectMode}
                        isSelected={
                            selectedAssets &&
                            selectedAssets.includes(asset.uuid)
                        }
                        useContextMenu={true}
                        customRowHeadings={customRows}
                    />
                ))}
            </tbody>
        </Table>
    );
};

export default AssetListView;
// function mapStateToProps(state) {
//     return {
//         stateAssets: state.assets,
//     };
// }

// export default connect(mapStateToProps, null)(AssetListView);
