//DON'T DELETE COMMENTED SECTIONS AS WE MAY WELL REINSTATE THIS FUNCTIONALITY LATER
import {
    LOAD_PLANNER_ACTIVITY_TYPES,
    LOADING_MARKETING_PLAN,
    LOAD_MARKETING_PLAN,
    // PLANNER_IMPORTING,
    SET_MARKETING_PLAN_DATE_RANGE,
    PLANNER_SELECT_CATEGORY,
    PLANNER_SELECT_TASK,
    PLANNER_SELECT_ACTIVITY,
    RESET_PLANNER_SELECTIONS,
    ADD_PLANNER_TASK,
    EDIT_PLANNER_TASK,
    ADD_PLANNER_TASK_CATEGORY,
    EDIT_PLANNER_TASK_CATEGORY,
    DELETE_PLANNER_TASK,
    SET_PLANNER_CONFIG_OPTIONS,
    ADD_PLANNER_ACTIVITY,
    EDIT_PLANNER_ACTIVITY,
    DELETE_PLANNER_ACTIVITY,
    // PLANNER_SET_EDITING,
    // PLANNER_SET_NOT_EDITING,
    // PLANNER_ADDING_ORDER_TO_PLAN,
    // PLANNER_STOP_ADDING_ORDER_TO_PLAN,
    // PLANNER_COMPLETE_ADDING_ORDER_TO_PLAN,
    LOAD_PLANNER_CHILD_GROUPS,
    LOAD_PLANNER_FILTERS,
    LOAD_PLANNER_CATEGORIES,
    // SHOW_PLANNER_MODAL,
    // HIDE_PLANNER_MODAL,
    SHOW_PLANNER_SUMMARY,
    HIDE_PLANNER_SUMMARY,
    LOAD_RELATED_TASKS,
    LOAD_AVAILABLE_BUDGET,
    LOADING_AVAILABLE_BUDGET,
    ADD_PLANNER_ORDER_DETAILS,
    REMOVE_PLANNER_ORDER_DETAILS,
} from "./plannerActions";

const initialState = {
    config: {
        loaded: false,
        data: {
            options: {},
        },
    },
    modalVisible: false,
    modalName: "",
    summaryVisible: false,

    // addingToPlanner: {
    //     adding: false,
    //     complete: false,
    //     category: {},
    //     task: {},
    //     activity: {}
    // },
    startDate: "",
    endDate: "",
    dates: [],
    categories: {
        loaded: false,
        data: [],
    },
    groups: {
        loaded: false,
        data: [],
    },
    tasks: {
        loaded: false,
        loading: false,
        data: [],
    },
    edit: {
        type: "none",
        editing: false,
        data: {},
    },
    selections: {
        category: {},
        task: {},
        activity: {},
    },
    importing: false,
    filters: {
        loaded: false,
        data: [],
    },
    activityTypes: {
        loaded: false,
        data: [],
    },
    availableBudget: {
        loaded: false,
        loading: false,
        data: {},
    },
};

export default function search(state = initialState, action) {
    switch (action.type) {
        case PLANNER_SELECT_CATEGORY:
            return {
                ...state,
                selections: { ...state.selections, category: action.category },
            };
        case PLANNER_SELECT_TASK:
            return {
                ...state,
                selections: { ...state.selections, task: action.task },
            };
        case PLANNER_SELECT_ACTIVITY:
            return {
                ...state,
                selections: { ...state.selections, activity: action.activity },
            };
        case RESET_PLANNER_SELECTIONS:
            return {
                ...state,
                selections: initialState.selections,
            };
        case LOADING_MARKETING_PLAN:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    loading: true,
                },
            };
        case LOAD_MARKETING_PLAN:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    data: action.data,
                    loaded: true,
                    loading: false,
                },
            };
        // case PLANNER_IMPORTING:
        //     return { ...state, importing: action.importing };
        case SET_MARKETING_PLAN_DATE_RANGE:
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate,
                dates: action.dates,
            };
        case ADD_PLANNER_TASK_CATEGORY:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: state.categories.data.concat(action.data),
                },
            };

        case EDIT_PLANNER_TASK_CATEGORY:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: state.categories.data.map((category) =>
                        category.uuid === action.categoryUuid
                            ? action.data
                            : category
                    ),
                },
            };
        case ADD_PLANNER_TASK:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    data: state.tasks.data.concat(action.data),
                },
            };

        case EDIT_PLANNER_TASK:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    data: state.tasks.data.map((task) =>
                        task.uuid === action.taskUuid ? action.data : task
                    ),
                },
            };

        case DELETE_PLANNER_TASK:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    data: state.tasks.data.filter(
                        (task) => task.uuid !== action.uuid
                    ),
                },
            };

        case ADD_PLANNER_ACTIVITY:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    data: state.tasks.data.map((task) => {
                        if (task.uuid === action.taskUuid) {
                            return {
                                ...task,
                                activities: [...task.activities, action.data],
                            };
                        }
                        return task;
                    }),
                },
            };

        case EDIT_PLANNER_ACTIVITY:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    data: state.tasks.data.map((task) => {
                        if (task.uuid === action.taskUuid) {
                            return {
                                ...task,
                                activities: task.activities.map((activity) => {
                                    if (activity.uuid === action.activityUuid) {
                                        return { ...activity, ...action.data };
                                    }
                                    return activity;
                                }),
                            };
                        }
                        return task;
                    }),
                },
            };

        case DELETE_PLANNER_ACTIVITY:
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    data: state.tasks.data.map((task) => {
                        if (task.uuid === action.taskUuid) {
                            return {
                                ...task,
                                activities: task.activities.filter(
                                    (activity) => {
                                        return (
                                            activity.uuid !==
                                            action.activityUuid
                                        );
                                    }
                                ),
                            };
                        }
                        return task;
                    }),
                },
            };

        // case PLANNER_SET_EDITING:
        //     return {
        //         ...state,
        //         edit: {
        //             itemType: action.itemType,
        //             editing: action.editing,
        //             data: action.data
        //         }
        //     };

        // case PLANNER_SET_NOT_EDITING:
        //     return { ...state, edit: initialState.edit };

        // case PLANNER_ADDING_ORDER_TO_PLAN:
        //     return {
        //         ...state,
        //         addingToPlanner: {
        //             adding: true,
        //             category: action.category,
        //             task: action.task,
        //             activity: action.activity
        //         }
        //     };

        // case PLANNER_STOP_ADDING_ORDER_TO_PLAN:
        //     return {
        //         ...state,
        //         addingToPlanner: initialState.addingToPlanner
        //     };

        // case PLANNER_COMPLETE_ADDING_ORDER_TO_PLAN:
        //     return {
        //         ...state,
        //         addingToPlanner: {
        //             ...state.addingToPlanner,
        //             complete: true
        //         }
        //     };

        case LOAD_PLANNER_CHILD_GROUPS:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    loaded: true,
                    data: action.data,
                },
            };

        case SET_PLANNER_CONFIG_OPTIONS:
            return {
                ...state,
                config: { data: action.data, loaded: true },
            };

        case ADD_PLANNER_ORDER_DETAILS:
            return {
                ...state,
                order: { data: action.data },
            };

        case REMOVE_PLANNER_ORDER_DETAILS:
            return {
                ...state,
                order: false,
            };

        case LOAD_PLANNER_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    loaded: true,
                    data: action.data,
                },
            };

        case LOAD_PLANNER_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypes: {
                    ...state.activityTypes,
                    loaded: true,
                    data: action.data,
                },
            };

        case LOAD_RELATED_TASKS:
            return {
                ...state,
                relatedTasks: {
                    ...state.relatedTasks,
                    loaded: true,
                    data: action.data,
                },
            };

        case LOAD_PLANNER_CATEGORIES:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    loaded: true,
                    data: action.data,
                },
            };

        case LOAD_AVAILABLE_BUDGET:
            return {
                ...state,
                availableBudget: {
                    ...state.availableBudget,
                    loading: false,
                    loaded: true,
                    data: action.data,
                },
            };

        case LOADING_AVAILABLE_BUDGET:
            return {
                ...state,
                availableBudget: {
                    ...state.availableBudget,
                    loading: true,
                },
            };
        // case SHOW_PLANNER_MODAL:
        //     return {
        //         ...state,
        //         modalName: action.modalName,
        //         modalVisible: true
        //     };

        // case HIDE_PLANNER_MODAL:
        //     return { ...state, modalName: "", modalVisible: false };

        case SHOW_PLANNER_SUMMARY:
            return { ...state, summaryVisible: true };

        case HIDE_PLANNER_SUMMARY:
            return { ...state, summaryVisible: false };

        default:
            return state;
    }
}
