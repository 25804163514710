import React from "react";

import AssetTotalsConfiguration from "./AssetTotalsConfiguration";
import AssetTotalsOutput from "./AssetTotalsOutput";

const AssetTotals = props => {
    if (props.configuring) {
        return <AssetTotalsConfiguration {...props} />;
    }
    return <AssetTotalsOutput {...props} />;
};

export default AssetTotals;
