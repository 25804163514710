import React, { useState, useEffect } from "react";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import axios from "axios";
import { isEmpty, get } from "lodash";
import dnsFormat from "date-fns/format";

import {
    InputField,
    IconTextButton,
    FormLabel,
    Loading,
    StyledCheckbox,
    SubmitButton,
    useAddToast,
    Row,
    Column,
    DateTimePicker,
    StyledSelectAsync,
    Select,
    PlainButton,
} from "@cortexglobal/rla-components";
import MessageWithTags from "./MessageWithTags";

const RecipientSwitcher = ({ onChange, show }) => {
    return (
        <PlainButton
            style={{
                float: "right",
                cursor: "pointer",
                float: "right",
            }}
            onClick={() => onChange(!show)}
        >
            {show
                ? trans("Switch to select recipients")
                : trans("Switch to select mailing list")}
        </PlainButton>
    );
};

const Composer = ({
    settings,
    message,
    successCallback,
    hideComposer,
    title = "",
    mailingLists,
    allowRecipientSwitching = true,
}) => {
    const { tags, config = [] } = settings;

    const [state, setState] = useState("composing");
    const [params, setParams] = useState(message);

    const [formErrors, setFormErrors] = useState({});
    const [selectedUserUuid, setSelectedUserUuid] = useState(null);
    const [selectedRecipients, setSelectedRecipients] = useState(null);
    const [selectedMailingLists, setSelectedMailingLists] = useState(null);
    const [showMailingLists, setShowMailingLists] = useState(
        !allowRecipientSwitching && !isEmpty(mailingLists)
    );
    const [showRecipientSwitcher, setShowRecipientSwitcher] = useState(
        allowRecipientSwitching && !isEmpty(mailingLists)
    );

    const addToast = useAddToast();
    const intl = useIntl();

    useEffect(() => {
        setSelectedUserUuid(message["user_uuid"] || null);
    }, [message]);

    useEffect(() => {
        setShowRecipientSwitcher(
            allowRecipientSwitching && !isEmpty(mailingLists)
        );
    }, [allowRecipientSwitching, mailingLists]);

    if (settings.state === "loading") {
        return <Loading />;
    }

    const onUsersAndGroupsChange = (value) => {
        setSelectedRecipients(value);
        onChange("to", value || []);
    };

    const onMailingListChange = ({ value }) => {
        console.log({ value });

        setSelectedMailingLists(value);
        onChange("mailing_list_uuids", value || []);
    };

    const loadOptions = (query, loadedOptions, { page = 1 }) => {
        return axios
            .get(`/api/v1/type-ahead/user_group`, {
                params: { query, page },
            })
            .then(({ data }) => {
                return {
                    options: data.data,
                    additional: {
                        page: page + 1,
                    },
                };
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
        setFormErrors({ ...formErrors, [name]: null });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        setState("loading");
        const message = JSON.stringify(params.message);

        let formParams = {
            ...params,
            message,
            user_uuid: selectedUserUuid,
            send_to_all: selectedUserUuid === "all",
        };

        //If replying we need to format the "to" field to resemble the format produced by the typeahead
        if (params.format === "reply") {
            formParams = {
                ...formParams,
                to: [
                    {
                        value: params.user_uuid,
                        label: params.to,
                        text: params.to,
                        type: "user",
                    },
                ],
            };
        }

        if (formParams["send_now"] === true) {
            formParams["send_date"] = null;
        }

        axios
            .post(`/api/v1/communication/send`, formParams)
            .then(({ data }) => {
                setState("complete");

                addToast({
                    type: "success",
                    content: formParams.send_now
                        ? trans("Message sent")
                        : trans("Message scheduled"),
                    showFor: 5000,
                });

                successCallback();
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setFormErrors(data.errors);
                        addToast({
                            type: "alert",
                            content: trans(
                                "Please check the message information"
                            ),
                            showFor: 5000,
                        });
                    } else {
                        addToast({
                            type: "alert",
                            content:
                                e.response && e.response.data
                                    ? e.response.data.message
                                    : trans("An error has occurred"),
                            showFor: 5000,
                        });
                    }
                }

                setState("composing"); // back to composition mode
            });
    };

    const { format } = message;

    let times = [];

    for (let hour = 0; hour < 24; hour++) {
        for (let min = 0; min < 60; min = min + 15) {
            const time =
                String(hour).padStart(2, "0") +
                ":" +
                String(min).padStart(2, "0");

            times.push({
                text: time,
                value: time,
            });
        }
    }

    const renderRecipientArea = (formErrors) => {
        if (format === "reply") {
            return (
                <InputField
                    name="user_name"
                    value={params.to}
                    onChange={(e) => onChange(e.name, e.value)}
                    label={trans("To")}
                    disabled={true}
                />
            );
        }

        if (showMailingLists && Array.isArray(mailingLists)) {
            console.log({ mailingLists, selectedMailingLists });
            return (
                <>
                    <FormLabel name="mailing_list" style={{ width: "100%" }}>
                        {trans("Mailing List")}
                        {showRecipientSwitcher && (
                            <RecipientSwitcher
                                onChange={setShowMailingLists}
                                show={showMailingLists}
                            />
                        )}
                    </FormLabel>
                    <Select
                        name="mailing_list"
                        value={selectedMailingLists}
                        onChange={onMailingListChange}
                        placeholder={intl.formatMessage({
                            id: "Select a mailing list...",
                        })}
                        options={mailingLists}
                        maxMenuHeight={250}
                        multi={true}
                        error={get(formErrors, "mailing_list_uuids")}
                    />
                </>
            );
        }

        return (
            <>
                <FormLabel name="user_group" style={{ width: "100%" }}>
                    {trans("Recipients")}
                    {showRecipientSwitcher && (
                        <RecipientSwitcher
                            onChange={setShowMailingLists}
                            show={showMailingLists}
                        />
                    )}
                </FormLabel>
                <StyledSelectAsync
                    name="user_group"
                    value={selectedRecipients}
                    onChange={onUsersAndGroupsChange}
                    placeholder={intl.formatMessage({
                        id: "Select a User or Group...",
                    })}
                    maxMenuHeight={250}
                    loadOptions={loadOptions}
                    additional={{ page: 1 }}
                    multi={true}
                    error={get(formErrors, "to")}
                />
            </>
        );
    };

    return (
        <>
            <Row style={{ minHeight: "350px" }}>
                <Column>
                    <h2 style={{ marginBottom: "1.5rem" }}>
                        {title ? title : trans("Compose New Message")}
                    </h2>
                </Column>

                <Column>
                    {renderRecipientArea(formErrors)}

                    <FormLabel name="subject">{trans("Subject")}</FormLabel>
                    <InputField
                        name="subject"
                        value={params.subject}
                        onChange={(e) => onChange(e.name, e.value)}
                        error={get(formErrors, "subject")}
                        disabled={state !== "composing"}
                    />
                </Column>

                <Column>
                    <FormLabel name="priority">{trans("Priority")}</FormLabel>
                    <Select
                        name="priority"
                        value={params.priority || "normal"}
                        onChange={(e) => onChange(e.name, e.value)}
                        error={get(formErrors, "priority")}
                        disabled={state !== "composing"}
                        showEmptyOption={false}
                        options={[
                            {
                                text: intl.formatMessage({
                                    id: "Low",
                                }),
                                value: "low",
                            },
                            {
                                text: intl.formatMessage({
                                    id: "Normal",
                                }),
                                value: "normal",
                            },
                            {
                                text: intl.formatMessage({
                                    id: "High",
                                }),
                                value: "high",
                            },
                        ]}
                    />
                </Column>

                <Column>
                    <MessageWithTags
                        name="message"
                        value={params.message}
                        label={trans("Message")}
                        onChange={onChange}
                        error={get(formErrors, "message")}
                        tagsLabel={trans("Shortcodes")}
                        tags={[]} // if not supplied then the settings api will be called to look these up
                        loading={settings.state === "loading"}
                    />
                </Column>

                <Column>
                    <FormLabel name="send_now">
                        {trans("Notification Settings")}
                    </FormLabel>
                </Column>

                <Column medium={3}>
                    <StyledCheckbox
                        text={intl.formatMessage({
                            id: "Send Now",
                        })}
                        style={{ marginTop: "10px" }}
                        name="send_now"
                        checked={!!params.send_now}
                        onChange={(e) => onChange("send_now", !params.send_now)}
                        error={get(formErrors, "send_now")}
                        disabled={state !== "composing"}
                    />
                </Column>

                {!params.send_now && (
                    <Column medium={9}>
                        <FormLabel name="send_date">
                            {trans("Send Date")}
                        </FormLabel>
                        <DateTimePicker
                            minDate={new Date()}
                            name="send_date"
                            onChange={(e) =>
                                onChange(
                                    e.name,
                                    dnsFormat(
                                        new Date(e.value),
                                        "yyyy-MM-dd'T'HH:mm:ssxxx"
                                    )
                                )
                            }
                            disabled={state !== "composing"}
                            value={params.send_now ? null : params.send_date}
                            marginBottom={"0"}
                            error={get(formErrors, "send_date")}
                        />
                    </Column>
                )}
            </Row>

            <Row style={{ marginBottom: "10px" }}>
                <Column>
                    {get(config, "composer.email_alert.auto_include") ===
                        false && (
                        <StyledCheckbox
                            name="email_alert"
                            text={intl.formatMessage({
                                id: "Include Email Alert",
                            })}
                            inlineLabel={true}
                            style={{ margin: ".5rem 0" }}
                            onChange={(e) =>
                                onChange(
                                    "email_alert",
                                    !get(params, "email_alert")
                                )
                            }
                            checked={!!get(params, "email_alert", false)}
                            disabled={state !== "composing"}
                        />
                    )}
                </Column>
            </Row>

            <Row
                style={{
                    marginTop: "10px",
                }}
            >
                <Column
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                    }}
                >
                    <IconTextButton
                        onClick={() => hideComposer()}
                        disabled={state !== "composing"}
                        type="alert"
                        showCircle={false}
                        icon={"times"}
                    >
                        {trans("Cancel")}
                    </IconTextButton>
                    <SubmitButton
                        onClick={onSubmit}
                        disabled={state !== "composing"}
                        submitting={state === "loading"}
                    >
                        {trans("Send")}
                    </SubmitButton>{" "}
                </Column>
            </Row>
        </>
    );
};

export default Composer;
