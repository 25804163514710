import React from "react";
import styled, { useTheme } from "styled-components";
//@ts-ignore cortex
import { Row, Column, Panel, Button } from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { Dashboard } from "@cortexglobal/dashboard";
//@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import { Routes } from "../hooks/useAdminRoutes";

import { get } from "lodash";

import UserSummaryTile from "../components/dashboard/UserSummaryTile";
import GroupSummaryTile from "../components/dashboard/GroupSummaryTile";
import ContactTile from "../components/dashboard/ContactTile";

const AdminTitle = styled.h1`
    margin: 0.5rem 0;
`;

const NavTitle = styled.h3`
    text-transform: capitalize;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray400};
    padding: 0.5rem 1rem;
    margin-bottom: 0;
`;

const FlexColumn = styled(Column)`
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 calc(25% - 10px);
    margin: 0.5rem 0;
`;

const NavPanel = styled(Panel)`
    border-radius: 3px;
    padding: 0;
    margin-right: 1rem;
`;

const NavBody = styled.div`
    padding: 2rem;
`;

export const AdminDashboard = (routes: Routes, theme) => {
    // const { can } = usePermissions();
    // const nav = Object.entries(routes);

    return (
        <>
            <Dashboard
                slug="admin"
                heading={<>{trans("Admin Area")}</>}
                showFeaturedItemCarousel={false}
                breadcrumbKey={null}
                customizable={true}
                multiple={true}
                editRoleDashboards={false}
                // customizable={can("settings-dashboards-manage")}
                // multiple={can("settings-dashboards-manage")}
                // editRoleDashboards={can(
                //     "settings-dashboards-edit-role-dashboards"
                // )}
            />

            {/* <Row>
                <Column>
                    <AdminTitle>{trans("Admin Area")}</AdminTitle>
                </Column>
            </Row>
            <Row equaliseByRow={true} equaliseChildHeight={true}>
                <Column medium={4}>
                    <UserSummaryTile />
                </Column>

                <Column medium={4}>
                    <GroupSummaryTile />
                </Column>

                <Column medium={4}>
                    <ContactTile />
                </Column> */}

            {/* {nav.map((route) => {
                        const navItem = route[1];
                        if (navItem.length === 0) return null;

                        const hasMultipleItems = navItem.length > 1;

                        return (
                            <Column medium={3} collapse>
                                <NavPanel>
                                    <NavTitle theme={theme}>
                                        {hasMultipleItems
                                            ? route[0]
                                            : navItem[0].name}
                                    </NavTitle>
                                    <NavBody>
                                        {!hasMultipleItems ? (
                                            <>
                                                <Button
                                                    expanded
                                                    as={Link}
                                                    to={`/admin${navItem[0].path}`}
                                                    style={{ marginBottom: "1rem" }}
                                                >
                                                    {trans("View")}
                                                </Button>
                                                <Button
                                                    expanded
                                                    as={Link}
                                                    to={`/admin${navItem[0].path}/create`}
                                                >
                                                    {trans("Create")}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                {navItem.map((item) => (
                                                    <Button
                                                        expanded
                                                        as={Link}
                                                        to={`/admin${item.path}`}
                                                        style={{
                                                            marginBottom: "1rem",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Button>
                                                ))}
                                            </>
                                        )}
                                    </NavBody>
                                </NavPanel>
                            </Column>
                        );
                    })} */}
            {/* </Row> */}
        </>
    );
};
