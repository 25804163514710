import React, { useState, useContext } from "react";

import styled from "styled-components";

import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { trans } from "@cortexglobal/rla-intl";
import {
    Loading,
    Row,
    Column,
    SubmitButton,
    FormRow,
    Panel,
    Button,
    InputField,
    Select,
    useAddToast,
} from "@cortexglobal/rla-components";

import FaqContext from "../FaqContext";

const PageWrapper = styled.article`
    padding: 1rem;
    background: ${({ theme }) => theme.content.page.background};
    color: ${({ theme }) => theme.content.page.color};
    border-radius: ${({ theme }) => theme.content.page.radius}px;
`;

const PageHeaderFaq = styled.header`
    display: flex;
`;

const PageHeadingSummary = styled.section`
    flex: 1;
`;
const PageHeading = styled.h2``;

export const FaqQuestionCreate = ({
    singleColumn = false,
    collapse = false,
}) => {
    const { can } = usePermissions();
    const addToast = useAddToast();
    const {
        faqState,
        setHideUnpublished,
        basepath,
        faqNavigate,
        loadCategories,
        storeQuestion,
    } = useContext(FaqContext);

    const { categories_loading, categories } = faqState;

    const [questionData, setQuestionData] = useState({
        question: "",
        question_category: {},
    });

    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);

    const standardOnChange = ({ name, value }) => {
        setQuestionData({ ...questionData, [name]: value });
    };

    const handleSave = () => {
        setSaving(true);
        storeQuestion(questionData)
            .then((data) => {
                addToast({
                    type: "success",
                    content: trans("Question saved"),
                    showFor: 5000,
                });
                if (can("faq-manage-edit")) {
                    const categorySlug = data.slug;
                    const questionSlug = data.questions[0]?.slug;

                    // Force unpublished questions to show and navigate to the new question
                    faqNavigate(
                        `${basepath}/${categorySlug}/${questionSlug}?editing=true`
                    );
                    setHideUnpublished(false);
                } else {
                    faqNavigate(`${basepath}`);
                }

                // Reload sidebar
                loadCategories();
            })
            .catch(({ response }) => {
                setErrors(response.data.errors);
                addToast({
                    type: "alert",
                    content: trans("An error occurred saving your question"),
                    showFor: 5000,
                });
            })
            .finally(() => {
                setSaving(false);
            });
    };

    if (categories_loading) {
        return <Loading />;
    }

    return (
        <PageWrapper>
            <PageHeaderFaq>
                <PageHeadingSummary>
                    <PageHeading>
                        <span>{trans("New question")}</span>
                    </PageHeading>
                </PageHeadingSummary>
            </PageHeaderFaq>

            <section>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="question"
                    label={trans("Question")}
                    error={errors.question}
                    required={true}
                >
                    <InputField
                        name="question"
                        value={questionData.question}
                        onChange={standardOnChange}
                        showError={false}
                    />
                </FormRow>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="question_category"
                    label={trans("Category")}
                    error={errors.question_category}
                    required={true}
                >
                    <Select
                        name="question_category"
                        options={categories}
                        value={questionData.question_category.value}
                        onChange={(event) => {
                            const newCategory = categories.find((category) => {
                                return category.value === event.value;
                            });
                            if (newCategory) {
                                delete newCategory.questions;
                                standardOnChange({
                                    name: "question_category",
                                    value: newCategory,
                                });
                            }
                        }}
                        tags={false}
                        multi={false}
                        creatable={false}
                    />
                </FormRow>

                <Row>
                    <Column medium={6}>
                        <Button onClick={() => faqNavigate(`${basepath}`)}>
                            {trans("Cancel")}
                        </Button>
                    </Column>
                    <Column medium={6} style={{ textAlign: "right" }}>
                        <SubmitButton
                            submitting={saving}
                            onClick={handleSave}
                            label={trans("Submit Question")}
                        >
                            {trans("Submit Question")}
                        </SubmitButton>
                    </Column>
                </Row>
            </section>
        </PageWrapper>
    );
};

export default FaqQuestionCreate;
