import React, { Component } from "react";

import AssetUsageChartOutput from "./AssetUsageChartOutput";
import AssetChartConfiguration from "./AssetChartConfiguration";

class AssetUsageChart extends Component {
    render() {
        if (this.props.configuring) {
            return <AssetChartConfiguration {...this.props} />;
        }
        return <AssetUsageChartOutput {...this.props} />;
    }
}

export default AssetUsageChart;
