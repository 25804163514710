import React, { Component } from "react";
import PropTypes from "prop-types";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Button,
    DashboardPanel,
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DeletePanel extends Component {
    render() {
        const { panel, panels, deletePanel } = this.props;
        //console.log(panel);
        return (
            <Row>
                <Column>
                    <h2>
                        {trans("Are you sure you want to delete this panel?")}
                    </h2>
                    {/*TODO - Decide if we need the preview (remove false below), if so how to handle sizing...*/}
                    {false && (
                        <div
                            style={{
                                position: "relative",
                                height: "300px",
                                width: "300px",
                            }}
                        >
                            {React.createElement(DashboardPanel, {
                                showHeader: false,
                                panel,
                                panels,
                            })}
                        </div>
                    )}
                    <Button
                        onClick={deletePanel.bind(this, panel)}
                        color="alert"
                    >
                        <FontAwesomeIcon icon="trash-alt" /> &nbsp;{" "}
                        {trans("Yes, delete this panel")}
                    </Button>
                </Column>
            </Row>
        );
    }
}

DeletePanel.propTypes = {
    panel: PropTypes.object.isRequired,
    panels: PropTypes.object.isRequired,
};

export default DeletePanel;
