import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import { MediaChannelForm, MediaChannel } from "../../forms/MediaChannelForm";
import { HeaderEmphasis, TabordionGroup } from "../../components/index";

export const MediaChannelEdit = ({ uuid, path }) => {
    const history = useHistory();
    const page = useQueryString().get("page");

    history.listen((location, action) => {
        // console.log('changed');
    });

    const [tab, setTab] = useState(0);
    const [item, setItem] = useState<{ loaded: boolean; data?: MediaChannel }>({
        loaded: false,
        data: null,
    });
    const getItem = () => {
        setTab(0);
        setItem({ loaded: false, data: null });
        axios.get(`/api/v1/admin/media-channel/${uuid}`).then(({ data }) => {
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, [uuid]);

    const submitForm = (values, setSubmitting) => {
        return axios
            .post(`/api/v1/admin/media-channel/${uuid}`, values)
            .then(({ data }) => {
                // console.log(data);
                setSubmitting(false);
                setItem({
                    loaded: true,
                    data: data.data,
                });

                //As creation was successful move them on to the edit page
                // history.push(`${path}/edit/${data.uuid}`);

                return data;
            });
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Edit Media Channel")}{" "}
                        <HeaderEmphasis>
                            {item.data ? item.data.name : ""}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={`${path}?page=${page ? page : 1}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to Listing")}
                </IconTextButton>
            </PageTitle>
            {item.loaded ? (
                <Row>
                    <Panel style={{ paddingBottom: "0" }}>
                        <MediaChannelForm
                            item={item.data}
                            submitForm={submitForm}
                        />
                    </Panel>
                </Row>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default MediaChannelEdit;
