import React, { useState } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { Link } from "react-router-dom";
import {
    DetailsContent,
    DetailsLabel,
    DetailWrapper,
    SpacedRow,
} from "./overviewComponents";
import {
    Button,
    Loading,
    Icon,
    Tooltip,
    Column,
} from "@cortexglobal/rla-components";

const TaskApprovalDetails = ({ task, onUpdate, children, plannerPath }) => {
    const [loading, setLoading] = useState(false);

    const requestFunding = (task) => {
        if (task.uuid === undefined) {
            return;
        }

        setLoading(true);

        axios
            .post(`/api/v1/planner/tasks/${task.uuid}/request-funding`)
            .then(({ data }) => {
                onUpdate(task);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!task || loading) {
        return <Loading />;
    }

    const userCanRequestFunding =
        task.available_user_actions.includes("request-funding");

    return (
        <DetailWrapper>
            {task && !task.plan ? (
                <>
                    <p>
                        {trans(
                            "This campaign is not yet associated with a plan."
                        )}
                    </p>
                    <p>
                        <Link to={`${plannerPath}my-plans`}>
                            {trans(
                                "Create a plan, or attach this campaign to an existing plan."
                            )}
                        </Link>
                    </p>
                </>
            ) : (
                <>
                    <SpacedRow className="detailRow">
                        <Column style={{ paddingLeft: "0" }} small={6}>
                            <b>{trans("Plan")}</b>
                        </Column>

                        <Column small={6} style={{ textAlign: "right" }}>
                            <Link
                                to={`${plannerPath}my-plans/${task.plan.uuid}`}
                            >
                                {task.plan.name}
                            </Link>
                        </Column>
                    </SpacedRow>
                    <SpacedRow className="detailRow">
                        <Column style={{ paddingLeft: "0" }} small={6}>
                            <b>{trans("Plan Approval")}</b>
                        </Column>

                        <Column small={6} style={{ textAlign: "right" }}>
                            {task.plan?.approval_request?.uuid ? (
                                <Link
                                    to={
                                        "/requests/view/" +
                                        task.plan.approval_request.uuid
                                    }
                                >
                                    {task.plan.approval_status}
                                </Link>
                            ) : (
                                <Tooltip
                                    text={
                                        task.plan?.approval_status ||
                                        trans("Not requested")
                                    }
                                    showIcon={false}
                                >
                                    <Icon name="cross" color="alert" />
                                </Tooltip>
                            )}
                        </Column>
                    </SpacedRow>
                    <SpacedRow className="detailRow">
                        <Column style={{ paddingLeft: "0" }} small={6}>
                            <b>{trans("Results Approval")}</b>
                        </Column>

                        <Column small={6} style={{ textAlign: "right" }}>
                            {task.plan?.results_request?.uuid ? (
                                <Link
                                    to={
                                        "/requests/view/" +
                                        task.plan.results_request.uuid
                                    }
                                >
                                    {task.plan.results_status}
                                </Link>
                            ) : (
                                <Tooltip
                                    text={
                                        task.plan?.results_status ||
                                        trans("Not requested")
                                    }
                                    showIcon={false}
                                >
                                    <Icon name="cross" color="alert" />
                                </Tooltip>
                            )}
                        </Column>
                    </SpacedRow>

                    {children}
                </>
            )}
        </DetailWrapper>
    );
};

export default TaskApprovalDetails;
