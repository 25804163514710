import React from "react";
import styled from "styled-components";
import {
    GreedyCarousel,
    CarouselItem,
    Centred,
    Loading,
    Panel,
} from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import AssetResultItem from "../assetComponents/AssetResultItem";
import { CortexBar } from "@cortexglobal/charts";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const FlexPanel = styled(Panel)`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
`;

const AssetUsageChartOutput = ({
    panelId,
    filterBy,
    total = 5,
    perSlide = 3,
}) => {
    const intl = useIntl();

    const panelData = usePanelData(panelId, `/api/v1/assets-usage`, {
        filter_by: filterBy,
        per_page: total,
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }

    // const data = panelData.data
    //     ? [
    //           {
    //               data: panelData.data.map((item, index) => {
    //                   return [item.name, item.usedCount];
    //               })
    //           }
    //       ]
    //     : [];
    const data = panelData.data
        ? [
              {
                  label: intl.formatMessage({ id: "Usage" }),
                  data: panelData.data,
              },
          ]
        : [];

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
    };

    const axes = [
        { primary: true, type: "ordinal", position: "bottom" },
        {
            position: "left",
            type: "linear",
            stacked: false,
        },
    ];

    const series = {
        type: "bar",
    };

    return (
        <div
            style={{
                width: "95%",
                height: "95%",
                padding: "10px",
            }}
        >
            {/* <Chart series={series} data={data} axes={axes} /> */}
            <CortexBar
                data={data}
                labelKey="name"
                valueKey="usedCount"
                colors={["rgba(33, 62, 143, 0.7)"]}
                options={options}
            />
        </div>
    );
};

export default AssetUsageChartOutput;
