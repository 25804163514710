import React, { useState, useContext } from "react";
import styled from "styled-components";
import { saveAs } from "file-saver";

import axios from "axios";
import {
    Button,
    SelectField,
    IconTextButton,
    SubmitButton,
    LoadingIconSmall,
    useAddToast,
    Modal,
    Progress,
} from "@cortexglobal/rla-components";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";

import AssetContext from "../AssetContext";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import BulkEditor from "../forms/BulkEditor";
import BulkRelate from "../forms/BulkRelate";
import BulkShare from "../forms/BulkShare";

const SelectedAssetsTab = styled.div`
    position: fixed;
    bottom: 0;
    right: 50px;
    background: #f7f7f7;
    padding: 1em 4em;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    z-index: 1;
`;

const Seperator = styled.div`
    width: 1px;
    height: 30px;
    background: #b9b9b9;
    margin-right: 1em;
`;

const StyledSelectField = styled(SelectField)`
    width: 140px;
    margin: 0;
    margin-left: 1em;
`;

const SelectedAssets = ({
    selectedAssets,
    selectCurrentPage,
    isMaxSelected,
    cancelSelect,
    onApply,
    massActions = [],
}) => {
    const [showToolbar, setShowToolbar] = useState(true);
    const { addFavorite, config, removeFavorite } = useContext(AssetContext);
    const [assetAction, setAssetAction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [percentComplete, setPercentComplete] = useState(0);
    const [multiEditing, setMultiEditing] = useState(false);
    const [multiDeleting, setMultiDeleting] = useState(false);
    const [relatingAssets, setRelatingAssets] = useState(false);
    const [sharingAssets, setSharingAssets] = useState(false);
    const [prefetchedAssets, setPrefetchedAssets] = useState({
        loaded: false,
        data: [],
    });
    const addToast = useAddToast();

    const prefetchAssets = (selectedAssets) => {
        return axios
            .get(`api/v1/assets/multiple`, {
                params: { uuids: selectedAssets },
            })

            .then((response) =>
                setPrefetchedAssets({ loaded: true, data: response.data.data })
            )
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: trans("Error fetching assets"),
                    showFor: 5000,
                });
            });
    };

    const confirmAction = (action) => {
        // console.log(action);
        switch (action) {
            case "favourite":
                setIsLoading(true);
                addFavorite(selectedAssets).then(() => {
                    setIsLoading(false);
                    cancelSelect();
                    addToast({
                        type: "success",
                        content: trans("Favourites added"),
                        showFor: 5000,
                    });
                    onApply();
                });
                break;
            case "remove-favourite":
                setIsLoading(true);
                removeFavorite(selectedAssets).then(() => {
                    setIsLoading(false);
                    cancelSelect();
                    addToast({
                        type: "success",
                        content: trans("Favourites removed"),
                        showFor: 5000,
                    });
                    onApply();
                });
                break;
            case "download":
                setIsLoading(true);
                setIsDownloading(true);
                axios
                    .get(`api/v1/assets/download`, {
                        params: { uuids: selectedAssets },
                        responseType: "blob",
                        timeout: 0,
                        onDownloadProgress: (progressEvent) => {
                            setPercentComplete(
                                Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                )
                            );
                        },
                    })
                    .then((response) => {
                        setIsLoading(false);
                        setIsDownloading(false);
                        cancelSelect();

                        addToast({
                            type: "success",
                            content: trans("Assets downloaded"),
                            showFor: 5000,
                        });
                        saveAs(new Blob([response.data]), "download.zip");
                    })
                    .catch((e) => {
                        addToast({
                            type: "alert",
                            content:
                                "Asset download failed, please try again later",
                            showFor: 5000,
                        });
                    });
                break;
            case "edit":
                setMultiEditing(true);
                setShowToolbar(false);
                break;
            case "delete":
                prefetchAssets(selectedAssets);
                setMultiDeleting(true);
                setShowToolbar(false);

                break;
            case "relate":
                setRelatingAssets(true);
                setShowToolbar(false);

                break;
            case "share":
                setSharingAssets(true);
                setShowToolbar(false);

                break;
        }
    };

    return (
        <>
            {showToolbar && (
                <SelectedAssetsTab collapse>
                    <IconTextButton
                        onClick={selectCurrentPage}
                        style={{ paddingLeft: "0" }}
                        showCircle={false}
                        expanded={false}
                        icon={faCheckSquare}
                    >
                        {trans("Select all")}
                    </IconTextButton>
                    <Seperator></Seperator>

                    {trans(
                        "{count, plural, =0 {No assets selected} one {# selected asset} other {# selected assets}}",
                        {
                            count: selectedAssets.length,
                        }
                    )}

                    {isMaxSelected && (
                        <span style={{ color: "red" }}>({trans("Max")})</span>
                    )}
                    <StyledSelectField
                        name="AssetAction"
                        onChange={(input) => setAssetAction(input.value)}
                        options={massActions}
                    ></StyledSelectField>
                    <IconTextButton
                        onClick={cancelSelect}
                        icon={faTimes}
                        showCircle={false}
                    >
                        {trans("Cancel")}
                    </IconTextButton>
                    <Button
                        onClick={() => {
                            confirmAction(assetAction);
                        }}
                        style={{ marginLeft: "1em" }}
                        disabled={selectedAssets.length === 0}
                    >
                        {trans("Confirm")}
                    </Button>
                    {isLoading && (
                        <LoadingIconSmall
                            style={{ marginLeft: "1em" }}
                        ></LoadingIconSmall>
                    )}
                </SelectedAssetsTab>
            )}
            {multiEditing && (
                <Modal
                    visible={multiEditing}
                    onClose={() => setMultiEditing(false)}
                    fullScreen={true}
                    showCloseButton={false}
                    zIndex={0}
                >
                    <BulkEditor
                        uuids={selectedAssets}
                        onCancel={() => {
                            setMultiEditing(false);
                            cancelSelect();
                            onApply();
                        }}
                        onSaved={() => {
                            setMultiEditing(false);
                            cancelSelect();
                            onApply();
                        }}
                    />
                </Modal>
            )}
            {multiDeleting && (
                <Modal
                    visible={multiDeleting}
                    onClose={() => {
                        setMultiDeleting(false);
                        cancelSelect();
                    }}
                    zIndex={0}
                >
                    {prefetchedAssets.loaded ? (
                        <div>
                            <h3>
                                {trans(
                                    "Delete the following {count, plural, one {asset} other {# assets}}?",
                                    {
                                        count: selectedAssets.length,
                                    }
                                )}
                            </h3>
                            <ul>
                                {prefetchedAssets.data.map((asset) => (
                                    <li key={asset.uuid}>
                                        {asset.name} ({asset.created_at})
                                    </li>
                                ))}
                            </ul>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "1.2rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconTextButton
                                    onClick={() => {
                                        setMultiDeleting(false);
                                        cancelSelect();
                                    }}
                                    type="alert"
                                    showCircle={false}
                                    icon={"times"}
                                >
                                    {trans("Cancel")}
                                </IconTextButton>
                                <Button
                                    type="alert"
                                    onClick={() => {
                                        setIsLoading(true);
                                        axios
                                            .delete(`api/v1/assets/multiple`, {
                                                params: {
                                                    uuids: selectedAssets,
                                                },
                                            })
                                            .then(() => {
                                                setIsLoading(false);
                                                cancelSelect();
                                                addToast({
                                                    type: "success",
                                                    content:
                                                        trans("Assets deleted"),
                                                    showFor: 5000,
                                                });
                                                onApply();
                                            });
                                    }}
                                    disabled={isLoading}
                                >
                                    {trans("Confirm")}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <LoadingIconSmall></LoadingIconSmall>
                        </div>
                    )}
                </Modal>
            )}
            {relatingAssets && (
                <Modal
                    visible={relatingAssets}
                    onClose={() => {
                        setRelatingAssets(false);
                        cancelSelect();
                    }}
                    maxWidth="90%"
                    maxHeight="90%"
                    // minHeight="90%"
                    // fullScreen={true}
                    // showCloseButton={false}
                >
                    <BulkRelate
                        selectedAssets={selectedAssets}
                        onCancel={() => {
                            setRelatingAssets(false);
                            cancelSelect();
                        }}
                    />
                </Modal>
            )}
            {sharingAssets && (
                <Modal
                    visible={sharingAssets}
                    onClose={() => {
                        setSharingAssets(false);
                        cancelSelect();
                    }}
                    maxWidth="90%"
                    maxHeight="90%"
                    // minHeight="90%"
                    // fullScreen={true}
                    // showCloseButton={false}
                >
                    <BulkShare
                        selectedAssets={selectedAssets}
                        onCancel={() => {
                            setSharingAssets(false);
                            cancelSelect();
                        }}
                    />
                </Modal>
            )}
            {isDownloading && (
                <Modal
                    visible={isDownloading}
                    onClose={() => {}}
                    showCloseButton={false}
                >
                    {percentComplete > 0 ? (
                        <>
                            <h2>{trans("Downloading Assets")}</h2>
                            <Progress current={percentComplete} />
                            <p>
                                {trans("{percentComplete}% downloaded", {
                                    percentComplete,
                                })}
                            </p>
                        </>
                    ) : (
                        <>
                            <h2>{trans("Preparing download ZIP")}</h2>
                            <LoadingIconSmall />{" "}
                            <p>
                                {trans(
                                    "{count, plural, one {Preparing # asset} other {As you are downloading # assets it may take slightly longer than normal}}",
                                    {
                                        count: selectedAssets.length,
                                    }
                                )}
                            </p>
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

export default SelectedAssets;
