import React from "react";
import { Pie } from "react-chartjs-2";
import { prepareData } from "../formatters";
import { CortexChartProps } from "../types/propTypes";

/**
 * @param {Object.<string, any>} props
 * @param {Object.<string, any>[]} props.data - This is the data for the chart.  It should be an array of objects with a "label" key for the label (or a different key set by the labelKey prop), and a "value" key for the value (or a different key as specified with the valueKey prop)
 */
export const CortexPie = ({
    data,
    labelKey = "label",
    valueKey = "value",
    colors = [],
    ...rest
}: CortexChartProps) => {
    const preparedData = prepareData({ data, labelKey, valueKey, colors });
    return <Pie data={preparedData} {...rest} />;
};
