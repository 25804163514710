import React from "react";
const CalendarContext = React.createContext({
    categorySummary: false,
    prevAction: false,
    nextAction: false,
    labels: {
        addTask: { content: String.fromCharCode(10133), title: "Add Task" },
        viewTask: { content: String.fromCharCode(9998), title: "Edit Task" },
        deleteTask: {
            content: String.fromCharCode(10134),
            title: "Delete Task"
        },
        addActivity: {
            content: String.fromCharCode(10133),
            title: "Add Activity"
        }
    }
});
export default CalendarContext;
