import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";

const CurrencyField = ({
    type,
    name,
    label,
    readOnly,
    meta,
    onChange,
    error,
    labelWidth,
    labelAlign,
    labelStyle,
    placeholder,
    defaultValue: value,
    allowDecimal,
    decimalsLimit,
    intlConfig,
    ...rest
}) => {
    let fieldOptions = {};

    if (readOnly) {
        fieldOptions["readOnly"] = "readOnly";
    }

    if (error) {
        fieldOptions["error"] = "error";
    }

    /**
     * Handle validation
     */
    const handleOnValueChange = (value, _, values) => {
        if (!value) {
            value = "";
        }
        return onChange({ value, name });
    };

    return (
        <CurrencyInput
            name={name}
            id={name}
            placeholder={placeholder}
            // defaultValue={defaultValue}
            value={value}
            allowDecimals={allowDecimal}
            decimalsLimit={decimalsLimit}
            // onChange={(value, name) => onChange({ value, name })}
            onValueChange={handleOnValueChange}
            intlConfig={intlConfig}
            {...fieldOptions}
            {...rest}
        />
    );
};

CurrencyField.displayName = "CurrencyInput";

CurrencyField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    labelStyle: PropTypes.object,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.number,
    allowDecimal: PropTypes.bool,
    decimalsLimit: PropTypes.number,
};

CurrencyField.defaultProps = {
    size: "default",
    type: "number",
    readOnly: false,
    //error: "",
    placeholder: "",
    defaultValue: 0,
    allowDecimal: true,
    decimalsLimit: 2,
};

export default CurrencyField;
