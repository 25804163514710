import React, { useEffect, useState } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { StyledSelectAdvanced } from "@cortexglobal/rla-components";

const CortexAuthSiteSwitcher = ({ user, style = {} }) => {
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSites();
    }, []);

    const getSites = () => {
        setLoading(true);
        return axios
            .get(`/api/v1/cortex-auth/sites`)
            .then(({ data }) => {
                if (data?.data) {
                    setSites(data.data);
                } else {
                    setSites([]);
                }
            })
            .catch((e) => {
                setSites([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        e.preventDefault;

        const siteAlias = e.value;

        handleSwitch(siteAlias);
    };

    const handleSwitch = (redirectUrl) => {
        window.location.href = redirectUrl;
    };

    const selectedSite = !loading
        ? sites.find((site) => site.current === true)
        : null;

    if (sites.length <= 1) {
        return null;
    }

    return (
        <StyledSelectAdvanced
            disabled={loading}
            label={trans("Change Site:")}
            style={style}
            name="site"
            onChange={handleChange}
            options={sites.map((e) => ({
                value: e.switch_url,
                text: e.name,
            }))}
            value={selectedSite?.switch_url || ""}
        />
    );
};

export default CortexAuthSiteSwitcher;
