import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikCheckboxTree,
    Panel,
    Loading,
    Tabordion,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { WorkflowStatesTable } from "../screens/workflow/states/WorkflowStatesTable";

const schema = Yup.object().shape({
    name: Yup.string().required(trans("Required")),
    alias: Yup.string().required(trans("Required")),
    // permissions: Yup.array()
});

export type WorkflowState = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    alias?: string;
    super_state_id?: number;
    is_super?: boolean;
    special_type?: string;
    allow_edits: boolean;
};

export const WorkflowStateForm = ({ item, submitForm, workflow }) => {
    const [superStates, setSuperStates] = useState([]);
    const [superStatesLoaded, setSuperStatesLoaded] = useState(false);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        axios
            .get(`/api/v1/admin/workflow/${workflow.uuid}/states/super/options`)
            .then(({ data }) => {
                setSuperStates(data);
                setSuperStatesLoaded(true);
            });
    }, []);

    const handleSubmit = (
        values: WorkflowState,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                name: item.name,
                alias: item.alias,
                super_state_id: item.super_state ? item.super_state_id : null,
                special_type: item.special_type,
                is_super: item.is_super,
                allow_edits: item.allow_edits,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <h1>
                            {trans("Editing State")}: {item.name}
                        </h1>
                        <p>
                            {trans("Workflow")}: {workflow.name}
                        </p>{" "}
                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Required")}
                            error={errors.name}
                        >
                            <FormikInput name="name" value={values.name} />{" "}
                        </FormRow>
                        <FormRow
                            name="alias"
                            label={trans("Alias")}
                            helpText={trans("Required")}
                            error={errors.name}
                        >
                            <FormikInput name="alias" value={values.alias} />{" "}
                        </FormRow>
                        <FormRow
                            name="special_type"
                            label={trans("Special Type")}
                            helpText={trans("Optional")}
                            error={errors.special_type}
                        >
                            <FormikSelect
                                name="special_type"
                                value={values.special_type}
                                options={[
                                    { value: "closed", text: "Closed" },
                                    { value: "hold", text: "Hold" },
                                    { value: "initial", text: "Initial" },
                                    { value: "complete", text: "Complete" },
                                ]}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="super_state_id"
                            label={trans("Super State")}
                            helpText={trans("Optional")}
                            error={errors.special_type}
                        >
                            <ContentBlockLoader loaded={superStatesLoaded}>
                                <FormikSelect
                                    name="super_state_id"
                                    value={values.super_state_id}
                                    options={superStates}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="is_super"
                            label={trans("Is Super")}
                            helpText={trans("Required")}
                            error={errors.is_super}
                        >
                            <FormikSelect
                                name="is_super"
                                value={values.is_super}
                                options={[
                                    { value: 1, text: "Yes" },
                                    { value: 0, text: "No" },
                                ]}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="allow_edits"
                            label={trans("Allow Edits")}
                            helpText={trans("Required")}
                            error={errors.allow_edits}
                        >
                            <FormikSelect
                                name="allow_edits"
                                value={values.allow_edits}
                                options={[
                                    { value: 1, text: "Yes" },
                                    { value: 0, text: "No" },
                                ]}
                            />{" "}
                        </FormRow>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStateForm;
