import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import moment from "moment";
import FormLabel from "../styledElements/label";
import "react-input-range/lib/css/index.css";
import InputError from "../styledElements/inputError";
import StyledTimePicker from "../styledElements/styledTimePicker";
class FormikTimePicker extends Component {
    render() {
        const {
            label,
            name,
            error,
            timeFormat,
            showError = true,
            ...rest
        } = this.props;

        return (
            <React.Fragment>
                <Field name={name}>
                    {({
                        field: { name, onChange },
                        form: {
                            touched,
                            errors,
                            values,
                            setFieldTouched,
                            setFieldValue
                        }
                    }) => {
                        const error = !!touched[name] && errors[name];
                        const value = moment(values[name], timeFormat);
                        // console.log(value);
                        return (
                            <React.Fragment>
                                {label && (
                                    <FormLabel
                                        name={name}
                                        label={label}
                                        {...this.props}
                                        marginBottom={1.2}
                                    >
                                        {label}
                                    </FormLabel>
                                )}

                                <StyledTimePicker
                                    value={value.isValid() ? value : moment()}
                                    onChange={date => {
                                        const newValue = moment.isMoment(date)
                                            ? date.format(timeFormat)
                                            : date;
                                        setFieldValue(name, newValue);
                                        setFieldTouched(name, true);
                                    }}
                                    onBlur={() => {
                                        setFieldTouched(name, true);
                                    }}
                                    error={error}
                                    timeFormat={timeFormat}
                                    {...rest}
                                />
                            </React.Fragment>
                        );
                    }}
                </Field>
                {showError &&
                <ErrorMessage name={name}>
                    {msg => <InputError error={msg}/>}
                </ErrorMessage>
                }
            </React.Fragment>
        );
    }
}

FormikTimePicker.displayName = "FormikTimePicker";

FormikTimePicker.propTypes = {
    value: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showSecond: PropTypes.bool,
    use12Hours: PropTypes.bool,
    error: PropTypes.string,
    timeFormat: PropTypes.string
};

FormikTimePicker.defaultProps = {
    showSecond: false,
    error: "",
    timeFormat: "HH:mm"
};

export default FormikTimePicker;
