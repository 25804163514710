import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
    ContentBlockLoader,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const AssetChartConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    filterBy,
    total,
    perSlide,
    displayType,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Asset Usage Chart" }),
        filterBy: filterBy ? filterBy : "",
        total: total ? total : 5,
        perSlide: perSlide ? perSlide : 5,
    });

    const [options, setOptions] = useState({
        loaded: false,
        data: [
            {
                value: "usage-by-media-type",
                text: intl.formatMessage({ id: "Usage By Media type" }),
            },
            // {
            //     value: "usage-by-asset-type",
            //     text: intl.formatMessage({ id: "Usage By Asset type" })
            // }
        ],
    });
    useEffect(() => {
        axios
            .get(`/api/v1/categories?area=media&onlyTopLevel=true&optionList`)
            .then(({ data }) => {
                setOptions({
                    loaded: true,
                    data: [...options.data, ...data.data],
                });
            });
    }, []);

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    submitPanel(panel, state, panelId);
                }}
            >
                <Column>
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder=""
                        onChange={onChange}
                    />
                    <ContentBlockLoader loaded={options.loaded}>
                        <SelectField
                            name="filterBy"
                            label={trans("Filter")}
                            value={state.filterBy}
                            options={options.data}
                            onChange={onChange}
                            expanded
                        />
                    </ContentBlockLoader>
                </Column>
                <Column>
                    <Button type="submit">Save Panel</Button>
                </Column>
            </form>
        </Row>
    );
};

AssetChartConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default AssetChartConfiguration;
