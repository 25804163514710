import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Column, SelectField, Button } from "@cortexglobal/rla-components";

class RSSNewsListConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feed: this.props.feed ? this.props.feed : "",
        };
    }

    onChange = (input) => {
        this.setState({ [input.name]: input.value });
    };

    submitForm = (event) => {
        event.preventDefault();
        this.props.submitPanel(this.props.panel, this.state);
    };

    render() {
        const feedOptions = [
            {
                value: "cortex",
                text: "Cortex Platform News",
            },
            {
                value: "http://feeds.bbci.co.uk/news/technology/rss.xml",
                text: "BBC Tech News",
            },
            {
                value: "http://feeds.bbci.co.uk/news/business/rss.xml",
                text: "BBC Business News",
            },
            {
                value: "https://www.am-online.com/news/latest-news/rss.xml",
                text: "AM Online News",
            },
        ];

        return (
            <form onSubmit={this.submitForm}>
                <Row>
                    <Column large={12}>
                        <SelectField
                            name="feed"
                            label="News Feed"
                            value={this.state.feed}
                            emptyOption="--Pick an Option--"
                            options={feedOptions}
                            onChange={this.onChange}
                            expanded
                        />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <Button type="submit">Save Panel</Button>
                    </Column>
                </Row>
            </form>
        );
    }
}

RSSNewsListConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default RSSNewsListConfiguration;
