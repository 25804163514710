import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
    Row,
    Column,
    Loading,
    useAddToast,
    Button,
    Table,
    IconTextButton,
    Tooltip,
    HelpText,
    Modal,
} from "@cortexglobal/rla-components";
import {
    trans,
    FormattedDate,
    FormattedCurrency,
} from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";

import AssetDetailsPreviewPanel from "../../assets/components/AssetDetailsPreviewPanel";
import { useDownloadUserAsset } from "../../userAssets/hooks/useDownloadUserAsset";
import RecipientDetails from "./RecipientDownload";

const SectionHeader = styled.h3`
    font-size: 1.2rem;
    margin: 2.5rem 0 0.2rem 0.6rem;
`;
export const OrderSummary = ({
    order: orderProp,
    uuid,
    showPreview = true,
    children,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [showRecipientDetails, setShowRecipientDetails] = useState(false);
    const [order, setOrder] = useState(orderProp);
    const addToast = useAddToast();
    const { downloadUserAssetFiles } = useDownloadUserAsset();
    const { user } = useUser();

    useEffect(() => {
        if (!order) {
            getOrder(uuid);
        } else {
            setLoaded(true);
        }
    }, [order]);

    const getOrder = (uuid) => {
        return axios
            .get(`/api/v1/orders/${uuid}`)
            .then(({ data: { data } }) => {
                console.log(data);
                setOrder(data);
                setLoaded(true);
                return data;
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error getting order."),
                    showFor: 5000,
                });
                setLoaded(true);
            });
    };

    const processFilename = (userAsset) => {
        const googleAdsFilenameLengthLimit = 46; //Allows for extension too as max is 50

        const userAssetType =
            typeof userAsset.asset_type === "string"
                ? userAsset.asset_type
                : userAsset?.asset_type?.slug;

        const needsShorterNameForGoogle =
            userAssetType === "digital" &&
            userAsset.name &&
            userAsset.name.length > googleAdsFilenameLengthLimit;
        return needsShorterNameForGoogle
            ? userAsset.name.substring(0, googleAdsFilenameLengthLimit)
            : userAsset.name;
    };

    if (!loaded) {
        return <Loading />;
    }
    const price = order?.user_asset?.asset?.meta_data?.price;

    return (
        <>
            <Row>
                <Column medium={showPreview ? 6 : 12}>
                    {children}
                    {/* <h2>{order.user_asset.asset.name}</h2> */}
                    <SectionHeader>{trans("General Details")}</SectionHeader>

                    <Table>
                        <tbody>
                            {order.required_date && (
                                <tr>
                                    <td width="30%">
                                        <strong>{trans("Required")}</strong>
                                    </td>
                                    <td>
                                        <FormattedDate
                                            value={
                                                new Date(order.required_date)
                                            }
                                        />
                                    </td>
                                </tr>
                            )}
                            {order.contact_name && (
                                <tr>
                                    <td width="30%">
                                        <strong>{trans("Contact Name")}</strong>
                                    </td>
                                    <td>{order.contact_name}</td>
                                </tr>
                            )}
                            {order.contact_email && (
                                <tr>
                                    <td width="30%">
                                        <strong>
                                            {trans("Contact Email")}
                                        </strong>
                                    </td>
                                    <td>{order.contact_email}</td>
                                </tr>
                            )}
                            {order.contact_telephone && (
                                <tr>
                                    <td width="30%">
                                        <strong>
                                            {trans("Contact Telephone")}
                                        </strong>
                                    </td>
                                    <td>{order.contact_telephone}</td>
                                </tr>
                            )}
                            {order.special_instructions && (
                                <tr>
                                    <td width="30%">
                                        <strong>{trans("Instructions")}</strong>
                                    </td>
                                    <td>{order.special_instructions}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    {Array.isArray(order?.user_asset?.asset?.order_flows) &&
                        order.user_asset.asset.order_flows.length > 0 && (
                            <>
                                <SectionHeader>
                                    {trans("{flow} Options", {
                                        flow: order.order_flow.name,
                                    })}
                                </SectionHeader>
                                <Table>
                                    <tbody>
                                        {order.order_flow_options.map(
                                            (option) => (
                                                <tr key={option.id}>
                                                    <td width="30%">
                                                        <strong>
                                                            {option.type.name}
                                                        </strong>
                                                    </td>
                                                    <td>{option.name}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        )}
                    {order.print_details && (
                        <>
                            <SectionHeader>
                                {trans("Print Details")}
                            </SectionHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td
                                            width="30%"
                                            style={{ verticalAlign: "top" }}
                                        >
                                            <strong>
                                                {trans("Delivery Address")}
                                            </strong>
                                        </td>
                                        <td>
                                            {order.print_details.address_1 && (
                                                <p>
                                                    {
                                                        order.print_details
                                                            .address_1
                                                    }
                                                </p>
                                            )}
                                            {order.print_details.address_2 && (
                                                <p>
                                                    {
                                                        order.print_details
                                                            .address_2
                                                    }
                                                </p>
                                            )}
                                            {order.print_details.town_city && (
                                                <p>
                                                    {
                                                        order.print_details
                                                            .town_city
                                                    }
                                                </p>
                                            )}
                                            {order.print_details
                                                .county_state && (
                                                <p>
                                                    {
                                                        order.print_details
                                                            .county_state
                                                    }
                                                </p>
                                            )}

                                            {order.print_details.postcode && (
                                                <p>
                                                    {
                                                        order.print_details
                                                            .postcode
                                                    }
                                                </p>
                                            )}
                                            {order.print_details.country && (
                                                <p>
                                                    {
                                                        order.print_details
                                                            .country
                                                    }
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>{trans("Quantity")}</strong>
                                        </td>
                                        <td>{order.print_details.quantity}</td>
                                    </tr>

                                    {price && (
                                        <>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {trans("Unit Price")}
                                                    </strong>
                                                </td>
                                                <td>
                                                    <FormattedCurrency
                                                        value={price}
                                                        currency={
                                                            user.currency
                                                                .iso_code ||
                                                            "GBP"
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {trans("Total")}
                                                    </strong>
                                                </td>
                                                <td>
                                                    <FormattedCurrency
                                                        value={
                                                            price *
                                                            order.print_details
                                                                .quantity
                                                        }
                                                        currency={
                                                            user.currency
                                                                .iso_code ||
                                                            "GBP"
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </>
                    )}
                    {order.direct_mail_details && (
                        <>
                            <SectionHeader>
                                {trans("Direct Mail Details")}
                            </SectionHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Recipients")}
                                            </strong>
                                        </td>
                                        <td>
                                            {
                                                order.direct_mail_details
                                                    .total_recipients
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Proof Required")}
                                            </strong>
                                        </td>
                                        <td>
                                            {order.direct_mail_details
                                                .proof_required
                                                ? trans("Yes")
                                                : trans("No")}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                    <td width="30%">
                                        <strong>
                                            {trans("Delivery Method")}
                                        </strong>
                                    </td>
                                    <td>
                                        {order.direct_mail_details.delivery_method}
                                    </td>
                                </tr> */}
                                    <tr>
                                        <td
                                            width="30%"
                                            style={{ verticalAlign: "top" }}
                                        >
                                            <strong>
                                                {trans(
                                                    "Gone Away Delivery Address"
                                                )}
                                            </strong>
                                        </td>
                                        <td>
                                            {order.direct_mail_details
                                                .address_1 && (
                                                <p>
                                                    {
                                                        order
                                                            .direct_mail_details
                                                            .address_1
                                                    }
                                                </p>
                                            )}
                                            {order.direct_mail_details
                                                .address_2 && (
                                                <p>
                                                    {
                                                        order
                                                            .direct_mail_details
                                                            .address_2
                                                    }
                                                </p>
                                            )}
                                            {order.direct_mail_details
                                                .town_city && (
                                                <p>
                                                    {
                                                        order
                                                            .direct_mail_details
                                                            .town_city
                                                    }
                                                </p>
                                            )}
                                            {order.direct_mail_details
                                                .county_state && (
                                                <p>
                                                    {
                                                        order
                                                            .direct_mail_details
                                                            .county_state
                                                    }
                                                </p>
                                            )}

                                            {order.direct_mail_details
                                                .postcode && (
                                                <p>
                                                    {
                                                        order
                                                            .direct_mail_details
                                                            .postcode
                                                    }
                                                </p>
                                            )}
                                            {order.direct_mail_details
                                                .country && (
                                                <p>
                                                    {
                                                        order
                                                            .direct_mail_details
                                                            .country
                                                    }
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                    {order.email_details && (
                        <>
                            <SectionHeader>
                                {trans("Email Details")}
                            </SectionHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Required Send Date")}
                                            </strong>
                                        </td>
                                        <td>
                                            <FormattedDate
                                                value={
                                                    new Date(
                                                        order.email_details.required_send_date
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>{trans("Subject")}</strong>
                                        </td>
                                        <td>{order.email_details.subject}</td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Recipients")}
                                            </strong>
                                        </td>
                                        <td
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span style={{ flexGrow: 1 }}>
                                                {
                                                    order.email_details
                                                        .total_recipients
                                                }
                                            </span>
                                            <Button
                                                style={{ float: "right" }}
                                                onClick={() =>
                                                    setShowRecipientDetails(
                                                        true
                                                    )
                                                }
                                            >
                                                {trans("View / Download")}
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                    {order.digital_details && (
                        <>
                            <SectionHeader>
                                {trans("Digital Details")}
                            </SectionHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <strong>{trans("Provider")}</strong>
                                        </td>
                                        <td>
                                            {order.digital_details.provider}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>{trans("Location")}</strong>
                                            <HelpText>
                                                {trans("Postal Code: Radius")}
                                            </HelpText>
                                        </td>
                                        <td>
                                            {Array.isArray(
                                                order.digital_details.location
                                            )
                                                ? order.digital_details.location.map(
                                                      (location) => {
                                                          if (
                                                              !location ||
                                                              !location[
                                                                  "postal_code"
                                                              ]
                                                          )
                                                              return null;
                                                          return (
                                                              <p>
                                                                  {
                                                                      location[
                                                                          "postal_code"
                                                                      ]
                                                                  }
                                                                  :{" "}
                                                                  {
                                                                      location[
                                                                          "radius"
                                                                      ]
                                                                  }
                                                              </p>
                                                          );
                                                      }
                                                  )
                                                : "None set"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Daily Budget")}
                                            </strong>
                                        </td>
                                        <td>
                                            {order.digital_details.daily_budget}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Targeting")}
                                            </strong>
                                        </td>
                                        <td>
                                            {order.digital_details.targeting}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Start Date")}
                                            </strong>
                                        </td>
                                        <td>
                                            <FormattedDate
                                                value={
                                                    new Date(
                                                        order.digital_details.start_date
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>{trans("End Date")}</strong>
                                        </td>
                                        <td>
                                            <FormattedDate
                                                value={
                                                    new Date(
                                                        order.digital_details.end_date
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                    {order.press_details && (
                        <>
                            <SectionHeader>
                                {trans("Press Details")}
                            </SectionHeader>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Insertion Date")}
                                            </strong>
                                        </td>
                                        <td>
                                            <FormattedDate
                                                value={
                                                    new Date(
                                                        order.press_details.insertion_date
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Publication")}
                                            </strong>
                                        </td>
                                        <td>
                                            {order.press_details.publication}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <strong>
                                                {trans("Monochrome")}
                                            </strong>
                                        </td>
                                        <td>
                                            {order.press_details.monochrome
                                                ? trans("Yes")
                                                : trans("No")}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}

                    {order.user_asset && (
                        <>
                            <SectionHeader>
                                {trans("Asset Details")}
                            </SectionHeader>

                            <Table>
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <strong>{trans("Asset")}</strong>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/resources/my-media/${order.user_asset.uuid}`}
                                            >
                                                {order.user_asset.name}
                                            </Link>
                                        </td>
                                    </tr>
                                    {order.user_asset.asset && (
                                        <tr>
                                            <td width="30%">
                                                <strong>
                                                    {trans("Original Asset")}
                                                </strong>
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/resources/view/${order.user_asset.asset.uuid}`}
                                                >
                                                    {
                                                        order.user_asset.asset
                                                            .name
                                                    }
                                                </Link>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </>
                    )}

                    {order.planner_activity && (
                        <>
                            <SectionHeader>
                                {trans("Planner Activity Details")}
                            </SectionHeader>

                            <Table>
                                <tbody>
                                    <tr>
                                        <td width="30%">
                                            <strong>{trans("Activity")}</strong>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/plan/task/${order.planner_activity.task.uuid}/activity/${order.planner_activity.uuid}`}
                                            >
                                                {order.planner_activity.name}
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                </Column>
                {showPreview && (
                    <Column medium={6}>
                        <AssetDetailsPreviewPanel
                            hideShadow={true}
                            asset={
                                order.asset
                                    ? order.asset
                                    : order.user_asset.asset
                            }
                            userAsset={
                                order.user_asset ? order.user_asset : null
                            }
                            loaded={true}
                        />
                        <div style={{ marginTop: "1rem" }}>
                            {order.user_asset.downloadable && (
                                <Row>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        onClick={(event) => {
                                            const filename = processFilename(
                                                order.user_asset
                                            );

                                            downloadUserAssetFiles(
                                                order.user_asset.uuid,
                                                filename
                                            );
                                        }}
                                    >
                                        {trans("Download")}
                                    </IconTextButton>
                                </Row>
                            )}

                            {order.user_asset.pdfable && (
                                <>
                                    <Row>
                                        <IconTextButton
                                            type="secondary"
                                            expanded
                                            onClick={(event) =>
                                                downloadUserAssetFiles(
                                                    order.user_asset.uuid,
                                                    order.user_asset.name,
                                                    "download-pdf",
                                                    "quality=high"
                                                )
                                            }
                                        >
                                            <Tooltip
                                                text={trans(
                                                    "High quality PDF with no crop marks."
                                                )}
                                            >
                                                {trans(
                                                    "Download PDF (High-Res)"
                                                )}
                                            </Tooltip>
                                        </IconTextButton>
                                    </Row>
                                    <Row>
                                        <IconTextButton
                                            type="secondary"
                                            expanded
                                            onClick={(event) =>
                                                downloadUserAssetFiles(
                                                    order.user_asset.uuid,
                                                    order.user_asset.name,
                                                    "download-pdf",
                                                    "quality=print"
                                                )
                                            }
                                        >
                                            <Tooltip
                                                text={trans(
                                                    "High quality PDF with crop marks, for use with commercial printers."
                                                )}
                                            >
                                                {trans(
                                                    "Download Print Ready PDF (High-Res)"
                                                )}
                                            </Tooltip>
                                        </IconTextButton>
                                    </Row>
                                </>
                            )}
                            {order.user_asset.imageZippable && (
                                <Row>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        onClick={(event) =>
                                            downloadUserAssetFiles(
                                                order.user_asset.uuid,
                                                order.user_asset.name,
                                                "download-image-zip"
                                            )
                                        }
                                    >
                                        {trans("Download Image Zip")}
                                    </IconTextButton>
                                </Row>
                            )}
                        </div>
                    </Column>
                )}
            </Row>
            <Modal
                visible={showRecipientDetails}
                onClose={() => setShowRecipientDetails(false)}
            >
                <RecipientDetails order={order} />
            </Modal>
        </>
    );
};

export default OrderSummary;
