import { getNavigatorLanguage, getItem } from "@cortexglobal/cortex-utilities";
import {
    SET_LOGGED_IN_STATE,
    SET_REDIRECT_STATE,
    SET_AUTH_USER_DETAILS,
    SET_AUTH_USER_GROUP,
    SET_AUTH_USER_ROLE,
    SET_AUTH_USER_PRIMARY_ROLE,
    SET_AUTH_USER_MODULES,
    SET_AUTH_USER_GROUPS,
    SET_AUTH_USER_ROLES,
    SET_AUTH_USER_LOADING,
    SET_NAVIGATION,
    SET_BREADCRUMBS,
    // SET_SITEMAP,
    SET_AUTH_USER_META_DATA,
    DECREMENT_UNREAD_COUNTER,
} from "./authActions";

export const initialState = {
    loggedIn: false,
    loggedInChecked: false,
    redirectToReferer: false,
    user: {
        loaded: false,
        loading: false,
        language: getItem("language").language || getNavigatorLanguage(),
        additional_props: {},
    },
    group: {
        loaded: false,
        loading: false,
    },
    role: {
        permissions: [],
        loaded: false,
        loading: false,
    },
    primary_role: {
        permissions: [],
        loaded: false,
        loading: false,
    },
    groups: [],
    roles: [],
    modules: [],
    navigation: {
        data: [],
        // sitemap: [],
        loaded: false,
        breadcrumbs: [],
    },
    metaData: {
        unnotified_messages: 0,
        unread_messages: 0,
    },
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case SET_LOGGED_IN_STATE:
            return {
                ...state,
                loggedIn: action.loggedIn,
                loggedInChecked: true,
            };

        case SET_REDIRECT_STATE:
            return {
                ...state,
                redirectToReferer: action.redirect,
            };

        case SET_AUTH_USER_DETAILS:
            return {
                ...state,
                user: { ...action.user, loaded: true, loading: false },
            };

        case SET_AUTH_USER_LOADING:
            return {
                ...state,
                user: { ...state.user, loaded: false, loading: true },
            };

        case SET_AUTH_USER_GROUP:
            return {
                ...state,
                group: { ...action.group, loaded: true, loading: false },
            };

        case SET_AUTH_USER_ROLE:
            return {
                ...state,
                role: { ...action.role, loaded: true, loading: false },
            };

        case SET_AUTH_USER_PRIMARY_ROLE:
            return {
                ...state,
                primary_role: { ...action.role, loaded: true, loading: false },
            };

        case SET_AUTH_USER_MODULES:
            return {
                ...state,
                modules: { ...action.modules },
            };

        case SET_AUTH_USER_GROUPS:
            return {
                ...state,
                groups: action.groups,
            };

        case SET_AUTH_USER_ROLES:
            return {
                ...state,
                roles: action.roles,
            };

        case SET_NAVIGATION:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    data: action.data,
                    loaded: true,
                },
            };

        case SET_BREADCRUMBS:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    breadcrumbs: action.breadcrumbs,
                },
            };

        case SET_AUTH_USER_META_DATA:
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    ...action.data,
                },
            };

        case DECREMENT_UNREAD_COUNTER:
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    unread_messages: state.metaData.unread_messages - 1,
                },
            };

        default:
            return state;
    }
}
