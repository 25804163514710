import React from "react";
import styled, { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faPlus,
    faCheck,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Badge,
    Table,
    IconTextButton,
    Panel,
} from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";

const SummaryColumn = styled(Column)`
    margin-bottom: 1rem;
    > div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        height: 100%;
        /* align-items: center; */
        padding: 1rem;
        /* background: ${({ theme }) => theme.colors.gray200}; */

        p {
            margin-bottom: 0;
            font-weight: bold;
        }
        h3 {
            margin-bottom: 0;
            font-weight: normal;
        }
    }
`;

const PlanDetailSummary = ({
    plan,
    user,
    allowEdits = false,
    taskViewUrl = "/plan/task",
    removeTask = () => {},
}) => {
    const theme = useTheme();

    return (
        <>
            <Row collapse equaliseChildHeight={true}>
                <SummaryColumn small={6} medium={2}>
                    <Panel>
                        <p>{trans("Planned Spend")}</p>
                        <h3>{plan.total_budget.formatted}</h3>
                    </Panel>
                </SummaryColumn>
                <SummaryColumn small={6} medium={2}>
                    <Panel>
                        <p>{trans("Actual Spend")}</p>
                        <h3>{plan.total_cost.formatted}</h3>
                    </Panel>
                </SummaryColumn>
                <SummaryColumn small={6} medium={2}>
                    <Panel>
                        <p>{trans("Campaigns")}</p>
                        <h3>{plan.tasks.length}</h3>
                    </Panel>
                </SummaryColumn>
                <SummaryColumn small={6} medium={2}>
                    <Panel>
                        <p>{trans("Activities")}</p>
                        <h3>{plan.total_activities}</h3>
                    </Panel>
                </SummaryColumn>
                <SummaryColumn small={6} medium={2}>
                    <Panel>
                        <p>{trans("Group")}</p>
                        <h3>{plan.group.name}</h3>
                    </Panel>
                </SummaryColumn>
                <SummaryColumn small={6} medium={2}>
                    <Panel>
                        <p>{trans("Created")}</p>
                        <h3>{format(new Date(plan.created), "PP")}</h3>
                    </Panel>
                </SummaryColumn>
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>{trans("Ref")}</th>
                            <th>{trans("Task")}</th>
                            <th>{trans("Group")}</th>
                            <th>{trans("Start Date")}</th>
                            <th>{trans("End Date")}</th>
                            <th>{trans("Planned Spend")}</th>
                            <th>{trans("Actual Spend")}</th>
                            <th>{trans("Activities")}</th>
                            <th>{trans("Status")}</th>
                            <th>{trans("Results")}</th>
                            {allowEdits && <th></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {plan.tasks.map((task) => {
                            return (
                                <tr key={task.uuid}>
                                    <td>
                                        <Badge type={"warning"}>
                                            {task.ref}
                                        </Badge>{" "}
                                    </td>
                                    <td>
                                        <Link
                                            to={`${taskViewUrl}/${task.uuid}`}
                                        >
                                            {task.name}
                                        </Link>
                                    </td>
                                    <td>{task?.group?.name || "N/A"}</td>
                                    <td>
                                        {format(
                                            new Date(task.start_date),
                                            "PP"
                                        )}
                                    </td>
                                    <td>
                                        {format(new Date(task.end_date), "PP")}
                                    </td>
                                    <td>
                                        <FormattedCurrency
                                            value={
                                                task.planned_spend.value || 0.0
                                            }
                                            currency={
                                                user?.currency?.iso_code ||
                                                "GBP"
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedCurrency
                                            value={
                                                task.actual_spend.value || 0.0
                                            }
                                            currency={
                                                user?.currency?.iso_code ||
                                                "GBP"
                                            }
                                        />
                                    </td>
                                    <td>{task.activities.length || 0}</td>
                                    <td>
                                        <Badge
                                            type={
                                                task.status !== "draft"
                                                    ? "success"
                                                    : "default"
                                            }
                                        >
                                            {task.status}
                                        </Badge>
                                    </td>
                                    <td>
                                        {task.results_submitted ? (
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="xl"
                                                style={{
                                                    color: theme.colors.success,
                                                }}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                style={{
                                                    color: theme.colors.alert,
                                                }}
                                                size="xl"
                                            />
                                        )}
                                    </td>
                                    {allowEdits && (
                                        <td>
                                            <IconTextButton
                                                type="alert"
                                                onClick={() => {
                                                    removeTask(task);
                                                }}
                                                icon={faTrash}
                                                showCircle={false}
                                            >
                                                {trans("Remove")}
                                            </IconTextButton>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Row>
        </>
    );
};

export default PlanDetailSummary;
