import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { spacing } from "../../theme";
import MainNavLink from "./MainNavLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MainNavWrapper, Row, Column, SidePanel, Button } from "../../index";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const SubNavLink = styled(MainNavLink)`
    font-family: ${props => props.theme.navigation.subNavLink.fontFamily};
    font-size: 14px;
`;

const SubMenuWrapper = styled.div`
    background: ${props => props.theme.navigation.subNavigation.background};
`;
const Container = styled.div`
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    background: ${props => props.theme.navigation.background};
    margin: ${props => props.margin};
    box-shadow: ${props => props.theme.navigation.boxShadow};
`;

const MobileButton = styled.button`
    color: ${props => props.theme.navigation.mobileNav.buttonColor};
    font-size: ${props => props.theme.navigation.mobileNav.buttonFontSize};
    font-family: inherit;
    border: 0;
    padding: 0;
    cursor: pointer;
`;

class MobileNavMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showing: false,
            width: 0
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.navigation !== this.props.navigation) {
            const {
                navigation: { data },
                location
            } = this.props;

            if (!location) {
                return;
            }
            const currentPath = location.pathname;
            // console.log(data);
            data.filter(item => {
                // console.log(item);
                return item.level === 0;
            }).forEach(item => {
                // console.log(item);
                const currentSection = currentPath.split("/")[1];
                const navItemSection = item.path.split("/")[1];

                if (currentSection === navItemSection) {
                    // console.log(currentPath, item.path, "Updating");
                    this.updateSection(item.id);
                }
            });
        }
    }
    updateSection = selectedId => {
        const {
            navigation: { data }
        } = this.props;
        let children = [];
        const selectedItem = data.find(({ id }) => selectedId === id);
        if (selectedItem.parent_id > 0) {
            children = data.filter(item => {
                return item.parent_id === selectedId;
            });
        }
        this.setState({
            section: selectedId,
            children: children,
            showing: false
        });
    };

    render() {
        const {
            navigation: { data, loaded },
            theme: {
                navigation: { showMobileMenuBelow }
            },
            alignment
        } = this.props;
        const { showing, width } = this.state;

        if (width >= showMobileMenuBelow) {
            return null;
        }
        return (
            <Container {...this.props}>
                <MobileButton
                    onClick={() => {
                        this.setState({ showing: !showing });
                    }}
                >
                    <FontAwesomeIcon icon={faBars} />
                </MobileButton>

                {loaded && (
                    <SidePanel
                        visible={showing}
                        fixed={true}
                        alignment={alignment}
                        onClose={() => {
                            this.setState({ showing: !showing });
                        }}
                    >
                        {data
                            .filter(item => {
                                return item.level === 0;
                            })
                            .map(item => {
                                return (
                                    <MainNavLink
                                        exact={item.parent_id == null}
                                        style={{ display: "block" }}
                                        key={item.id}
                                        to={item.path}
                                        onClick={this.updateSection.bind(
                                            this,
                                            item.id
                                        )}
                                    >
                                        {item.name}
                                    </MainNavLink>
                                );
                            })}
                    </SidePanel>
                )}
                {this.state.children && (
                    <SubMenuWrapper>
                        <Row>
                            <Column
                                style={{ fontSize: "8pt", boxShadow: "-2" }}
                            >
                                {this.state.children.map(child => {
                                    return (
                                        <SubNavLink
                                            key={child.id}
                                            to={child.path}
                                        >
                                            {child.name}
                                        </SubNavLink>
                                    );
                                })}
                            </Column>
                        </Row>
                    </SubMenuWrapper>
                )}
            </Container>
        );
    }
}

MobileNavMenu.displayName = "MobileNavMenu";

MobileNavMenu.propTypes = {
    /** Font color - A valid css color value */
    color: PropTypes.string,
    /** Font size - A valid css size value */
    size: PropTypes.string,
    /** Font weight - A valid css weight value */
    weight: PropTypes.string,
    /** The css background property for the navigation container */
    background: PropTypes.string,
    /** The css margin property for the navigation container */
    margin: PropTypes.string,
    /** Whether the navigation panel should be on the left or right */
    alignment: PropTypes.oneOf(["left", "right"])
};

MobileNavMenu.defaultProps = {
    size: "1em",
    weight: "bold",
    margin: `0 0 ${spacing.margin}em 0`,
    alignment: "right"
};

export default withTheme(MobileNavMenu);
