import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    useAddToast,
    Loading,
    Pagination,
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssetImageResult } from "./AssetImageResult";

export const AllAssetImageBrowser = ({
    id,
    name,
    value,
    onDrop,
    attachAssetFile,
    deleteAssetFile,
    asset = null,
    width,
    height,
    selectedAssetFilesCount,
    deletedFile,
    type = "all",
    inFormRow = false,
}) => {
    const [allAssetFiles, setAllAssetFiles] = useState({
        loaded: false,
        data: [],
        meta: {},
    });
    const addToast = useAddToast();

    const getAllAssetFiles = (page = 1) => {
        const params = {
            page,
            width: isNaN(width) ? 0 : width,
            height: isNaN(height) ? 0 : height,
            type,
            group_restriction: "all",
        };

        if (asset && asset.uuid) {
            params.excludeAssetUuid = asset.uuid;
        }

        return axios
            .get(`/api/v1/asset-file`, {
                params,
            })
            .then(({ data }) => {
                setAllAssetFiles({
                    loaded: true,
                    data: data.data,
                    meta: data.meta,
                });
            });
    };

    useEffect(() => {
        getAllAssetFiles();
    }, [name, selectedAssetFilesCount, deletedFile]);

    return (
        <React.Fragment>
            <Row equaliseChildHeight={true}>
                {allAssetFiles.loaded ? (
                    allAssetFiles.data.length === 0 ? (
                        <div>{trans("No Files Found")}</div>
                    ) : (
                        allAssetFiles.data.map((file) => {
                            if (!file.file) {
                                return;
                            }
                            return (
                                <AssetImageResult
                                    name={name}
                                    selected={true}
                                    key={file.uuid}
                                    file={file}
                                    onChange={attachAssetFile}
                                    onRemove={deleteAssetFile}
                                    inFormRow={inFormRow}
                                />
                            );
                        })
                    )
                ) : (
                    // <Column medium={3}>
                    <Loading />
                    // </Column>
                )}
            </Row>

            <div style={{ margin: "1rem auto", textAlign: "center" }}>
                <Pagination
                    style={{ clear: "both" }}
                    currentPage={allAssetFiles.meta.current_page - 1}
                    perPage={allAssetFiles.meta.per_page}
                    pageCount={allAssetFiles.meta.last_page}
                    onPageChange={({ selected }) => {
                        getAllAssetFiles(selected + 1);
                    }}
                    {...allAssetFiles.meta}
                />
            </div>
        </React.Fragment>
    );
};

export default AllAssetImageBrowser;
