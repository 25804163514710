import styled from "styled-components";

const MainNavWrapper = styled.div`
    box-shadow: ${props => props.theme.navigation.boxShadow};
    border-color: ${(props) => props.theme.navigation.border.color};
    border-width: ${(props) => props.theme.navigation.border.width};
    border-style: ${(props) => props.theme.navigation.border.style};
`;

export default MainNavWrapper;
