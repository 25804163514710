import React, { useContext } from "react";

import { trans } from "@cortexglobal/rla-intl";
import { MultiCheckbox } from "@cortexglobal/rla-components";

import SurveySubmissionContext from "../SurveySubmissionContext";

/**
 * When we handle the data for a multi select aka checkboxes we need to take
 * the answer out of the database as a string and split it. We use a comma
 * as the delimiter but that will cause problems so we use a map function to
 * convert the commas in the values into and out of strings. It probably would
 * have been better to sanitise the value when inserting into the database
 * or even have an options table but either way for now we'll escape and unescape
 * the commas in this component alone so that it's all in one place.
 */
const AnswerMulti = ({ answer, options, readonly }) => {
    const { surveySystem, handleAnswerChange } = useContext(
        SurveySubmissionContext
    );

    const optionArray = options.choice.map(({ value }) => value);

    // Filter the loaded data just in case there are extra answers somehow in the DB, not that it should happen
    let answerArray = answer.answer ? JSON.parse(answer.answer) : [];
    answerArray = answerArray.filter((answerValue) => {
        return optionArray.includes(answerValue);
    });

    const handleMulti = (name, value) => {
        const newValue = JSON.stringify(value);
        handleAnswerChange(name, newValue);
    };

    return (
        <MultiCheckbox
            name={answer.question_uuid}
            onChange={(e) => handleMulti(e.name, e.value)}
            disabled={!!readonly}
            value={answerArray}
            options={options.choice}
            error={surveySystem.questionErrors[answer.question_uuid]}
            style={{ marginBottom: "1rem" }}
        />
    );
};

export default AnswerMulti;
