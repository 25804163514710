import { get } from "lodash";
import axios from "axios";

class User {
    constructor(props) {
        this.populate(props);

        this.errors = {};
    }

    populate(props) {
        this.loading = get(props, "loading", false);

        this.uuid = get(props, "uuid", "");
        this.external_ref = get(props, "external_ref", "");
        this.ref = get(props, "ref", "");
        this.avatar = get(props, "avatar", "");
        this.email = get(props, "email", "");
        this.name = get(props, "name", "");
        this.first_name = get(props, "first_name", "");
        this.last_name = get(props, "last_name", "");
        this.language = get(props, "language", "");
        this.country_code = get(props, "country_code", "");
        this.status = get(props, "status", "");
        this.status_wording = get(props, "status_wording", "");

        const primaryGroup = get(props, "primary_group", {
            name: get(props, "group_name", ""),
        });

        if (primaryGroup) {
            this.setPrimaryGroup(primaryGroup);
        }

        const activeGroup = get(props, "active_group", {
            name: get(props, "active_group_name", ""),
        });

        if (activeGroup) {
            this.setActiveGroup(activeGroup);
        }

        const primaryRole = get(props, "primary_role", {
            name: get(props, "role_name", ""),
        });

        if (primaryRole) {
            this.setPrimaryRole(primaryRole);
        }

        const activeRole = get(props, "active_role", {
            name: get(props, "active_role_name", ""),
        });

        if (activeRole) {
            this.setActiveRole(activeRole);
        }

        this.initials =
            this.name.length > 0 ? this.getInitials(this.name) : "-";

        this.activationResent = get(props, "activationResent", false);

        this.activationLink = get(props, "activation_link", "");
        this.activationCode = get(props, "activation_code", "");
        this.activationExpiry = get(props, "activation_expiry", "");

        return this;
    }

    setPrimaryGroup(group) {
        this.group_name = group.name;
        this.primary_group = group;
    }

    setActiveGroup(group) {
        this.active_group_name = group.name;
        this.active_group = group;
    }

    setPrimaryRole(role) {
        this.role_name = role.name;
        this.primary_role = role;
    }

    setActiveRole(role) {
        this.active_role_name = role.name;
        this.active_role = role;
    }

    getInitials(name) {
        let initials = "";
        //This is in a try catch because it can throw an error if the name is not a string of western characters
        try {
            initials = name.match(/\b(\w)/g).join("");
        } catch (err) {}

        return initials;
    }

    async disable() {
        const uuid = this.uuid;

        return await axios
            .put(`/api/v1/manager/users/${uuid}/disable`, {
                async: true,
            })
            .then(({ data }) => {
                const newUserData = data.data;

                this.status = get(newUserData, "status", "");
                this.status_wording = get(newUserData, "status_wording", "");

                return this;
            });
    }

    async delete() {
        const uuid = this.uuid;

        return await axios
            .put(`/api/v1/manager/users/${uuid}/delete`, {
                async: true,
            })
            .then(({ data }) => {
                const newUserData = data.data;

                this.status = get(newUserData, "status", "");
                this.status_wording = get(newUserData, "status_wording", "");

                return this;
            });
    }

    async activate() {
        const uuid = this.uuid;

        return await axios
            .put(`/api/v1/manager/users/${uuid}/reactivate`, {
                async: true,
            })
            .then(({ data }) => {
                const newUserData = data.data;

                this.status = get(newUserData, "status", "");
                this.status_wording = get(newUserData, "status_wording", "");

                return this;
            });
    }

    async approve() {
        const uuid = this.uuid;

        return await axios
            .put(`/api/v1/manager/users/${uuid}/approve`, {
                async: true,
            })
            .then(({ data }) => {
                const newUserData = data.data;

                this.status = get(newUserData, "status", "");
                this.status_wording = get(newUserData, "status_wording", "");

                return this;
            });
    }

    async reject() {
        const uuid = this.uuid;

        return await axios
            .put(`/api/v1/manager/users/${uuid}/reject`, {
                async: true,
            })
            .then(({ data }) => {
                const newUserData = data.data;

                this.status = get(newUserData, "status", "");
                this.status_wording = get(newUserData, "status_wording", "");

                return this;
            });
    }

    async resendActivation() {
        const uuid = this.uuid;

        return await axios
            .put(`/api/v1/manager/users/${uuid}/resend-activation`, {
                async: true,
            })
            .then(({ data }) => {
                const newUserData = data.data;

                this.status = get(newUserData, "status", "");
                this.status_wording = get(newUserData, "status_wording", "");
                this.activationResent = true;

                this.activationLink = get(newUserData, "activation_link", "");
                this.activationCode = get(newUserData, "activation_code", "");
                this.activationExpiry = get(
                    newUserData,
                    "activation_expiry",
                    ""
                );

                return this;
            });
    }

    update(params) {
        const uuid = this.uuid;

        return axios
            .put(`/api/v1/manager/users/${uuid}`, params)
            .then(({ data }) => {
                const newUserData = data.data;

                this.populate(newUserData);

                return this;
            })
            .catch((e) => {
                if (e.response && e.response.status === 422) {
                    return e.response.data.errors;
                }

                throw e;
            });
    }

    static create(params) {
        return axios
            .post(`/api/v1/manager/users`, params)
            .then(({ data }) => {
                const newUserData = data.data;

                const newUser = new User(newUserData);

                return newUser;
            })
            .catch((e) => {
                if (e.response && e.response.status === 422) {
                    return e.response.data.errors;
                }

                throw e;
            });
    }

    setLoading(value) {
        this.loading = !!value;

        return this;
    }
}

export default User;
