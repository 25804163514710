import React from "react";
import { withTheme } from "styled-components";
import { MainNavMenu, MobileNavMenu } from "../../index";

class MainNav extends React.Component {
    render() {
        const { navigation, location } = this.props;
        return (
            <React.Fragment>
                <MainNavMenu
                    navigation={navigation}
                    location={location}
                ></MainNavMenu>
                <MobileNavMenu
                    navigation={navigation}
                    location={location}
                ></MobileNavMenu>
            </React.Fragment>
        );
    }
}

export default withTheme(MainNav);
