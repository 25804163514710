import React, { Component } from "react";
import {
    VictoryLine,
    VictoryVoronoiContainer,
    VictoryChart,
    VictoryAxis,
    VictoryLabel,
    VictoryLegend
} from "victory";
import { primary, chartColors } from "../../../config/charts";

//import CustomTooltip from "./CustomTooltip";

class CampaignChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: { opens: [], clicks: [] },
            campaignUuid: ""
        };
    }
    componentDidMount() {
        this.generateDataCollection(this.props.campaign);
    }

    componentDidUpdate(nextProps) {
        this.generateDataCollection(nextProps.campaign);
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(
        //     "should update",
        //     this.props.campaign.uuid,
        //     nextProps.campaign.uuid
        // );
        if (this.props.campaign.uuid !== nextProps.campaign.uuid) {
            return true;
        }
        if (this.state.campaignUuid !== nextState.campaignUuid) {
            return true;
        }
        return false;
    }

    generateDataCollection = campaign => {
        const chartData = campaign.details.reduce(
            (chartDataTmp, sendDetails) => {
                return {
                    ...chartDataTmp,
                    opens: [
                        ...chartDataTmp.opens,
                        {
                            x: sendDetails.date,
                            y: sendDetails.opened
                        }
                    ],
                    openLabels: [
                        ...chartDataTmp.openLabels,
                        sendDetails.opened
                    ],
                    clicks: [
                        ...chartDataTmp.clicks,
                        {
                            x: sendDetails.date,
                            y: sendDetails.clicked
                        }
                    ],
                    clickLabels: [
                        ...chartDataTmp.clickLabels,
                        sendDetails.clicked
                    ]
                };
            },
            { opens: [], clicks: [], openLabels: [], clickLabels: [] }
        );
        this.setState(state => {
            return {
                chartData,
                campaignUuid: campaign.uuid
            };
        });
    };

    render() {
        const labels = this.state.chartData.opens.reduce(
            (carry, interaction) => {
                //console.log(interaction);
                return [...carry, interaction.x];
            },
            []
        );
        //console.log(this.state);
        return (
            <VictoryChart
                containerComponent={
                    <VictoryVoronoiContainer labels={d => `${d.y} - ${d.x}`} />
                }
            >
                <VictoryLabel
                    y={5}
                    x={225}
                    style={primary.title}
                    textAnchor="middle"
                    text={this.props.campaign.name}
                />
                <VictoryLegend
                    x={320}
                    y={40}
                    orientation="vertical"
                    gutter={0}
                    style={primary.legend}
                    data={[
                        {
                            name: "Clicks",
                            symbol: { fill: chartColors[0], type: "star" },
                            labels: { ...primary.legend.labels }
                        },
                        {
                            name: "Opens",
                            symbol: { fill: chartColors[1] },
                            labels: { ...primary.legend.labels }
                        }
                    ]}
                />
                <VictoryAxis
                    key="x"
                    tickLabelComponent={
                        <VictoryLabel
                            angle={90}
                            textAnchor="start"
                            verticalAnchor="middle"
                            renderInPortal
                        />
                    }
                    style={primary}
                    tickFormat={labels}
                    text="test"
                />
                <VictoryAxis dependentAxis key="y" style={primary} />
                <VictoryLine
                    animate={{ duration: 1000 }}
                    data={this.state.chartData.opens}
                    style={{
                        data: { stroke: chartColors[1] },
                        labels: { fill: chartColors[1] }
                    }}
                />
                <VictoryLine
                    animate={{ duration: 1000 }}
                    data={this.state.chartData.clicks}
                    style={{
                        data: { stroke: chartColors[0] },
                        labels: { fill: chartColors[0] }
                    }}
                />
            </VictoryChart>
        );
    }
}

export default CampaignChart;
