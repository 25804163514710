import React from "react";

import StyledColorField from "../styledElements/StyledColorField";

const ColorField = ({ name, onChange, ...rest }) => {
    const standardOnChange = hex => {
        onChange({ name, value: hex });
    };
    return <StyledColorField onChange={standardOnChange} {...rest} />;
};

export default ColorField;
