import {
    GET_REQUESTS_LOADING,
    GET_REQUESTS,
    GET_REQUESTS_ERROR,
    GET_REQUEST_SUMMARY,
    GET_REQUEST_SUMMARY_ERROR,
    GET_REQUEST_SUMMARY_LOADING,
    GET_REQUEST_TYPES,
    GET_REQUEST_TYPES_ERROR,
    ADD_REQUEST,
    EDIT_REQUEST,
    GET_REQUEST_FILTERS,
    GET_REQUEST_FILTERS_ERROR,
    GET_REQUEST_FILTERS_LOADING
} from "./requestsActions";

const initialState = {
    summary: {
        loaded: false,
        error: "",
        data: {}
    },
    filters: {
        data: {}
    },
    myRequests: {
        loading: false,
        loaded: false,
        error: "",
        data: {},
        meta: {}
    },
    types: {
        loaded: false,
        error: "",
        data: {}
    }
};

export default function requests(state = initialState, action) {
    switch (action.type) {
        // TYPES

        case GET_REQUEST_TYPES:
            return {
                ...state,
                types: {
                    ...state.types,
                    loaded: true,
                    data: action.data
                }
            };

        case GET_REQUEST_TYPES_ERROR:
            return {
                ...state,
                types: {
                    ...state.types,
                    loaded: true,
                    error: action.errorMessage
                }
            };

        // LISTS

        case GET_REQUEST_SUMMARY:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loaded: true,
                    data: action.data
                }
            };

        case GET_REQUEST_SUMMARY_LOADING:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loaded: false,
                    data: {}
                }
            };

        case GET_REQUEST_SUMMARY_ERROR:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loaded: true,
                    error: action.errorMessage
                }
            };

        // FILTERS - loaded from requests meta

        case GET_REQUEST_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    data: action.data
                }
            };

        // REQUESTS

        case GET_REQUESTS:
            return {
                ...state,
                myRequests: {
                    ...state.myRequests,
                    loading: true,
                    loaded: true,
                    data: action.data.data,
                    meta: action.data.meta
                }
            };

        case GET_REQUESTS_LOADING:
            return {
                ...state,
                myRequests: {
                    ...state.myRequests,
                    loading: true,
                    loaded: false,
                    data: {},
                    meta: {}
                }
            };

        case GET_REQUESTS_ERROR:
            return {
                ...state,
                myRequests: {
                    ...state.myRequests,
                    loading: false,
                    loaded: true,
                    error: action.errorMessage
                }
            };

        // ADD REQUEST

        case ADD_REQUEST:
            return {
                ...state,
                myRequests: {
                    ...state.myRequests,
                    data:
                        state.myRequests.data.length > 0
                            ? [action.data, ...state.myRequests.data]
                            : [action.data]
                }
            };

        // EDIT REQUEST

        case EDIT_REQUEST:
            return {
                ...state,
                myRequests: {
                    ...state.myRequests,
                    data:
                        state.myRequests.data.length > 0
                            ? state.myRequests.data.map(request => {
                                  if (action.data.uuid === request.uuid) {
                                      request = action.data;
                                  }
                                  return request;
                              })
                            : [action.data]
                }
            };

        default:
            return state;
    }
}
