import React, { useState, useContext, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    SaveButton,
    Tabordion,
    Loading,
    Panel,
    Button,
    useAddToast,
} from "@cortexglobal/rla-components";

import FaqContext from "../FaqContext";
import General from "../forms/General";

export const FaqQuestionEdit = ({ question }) => {
    const history = useHistory();
    const addToast = useAddToast();
    const {
        faqState,
        setHideUnpublished,
        basepath,
        faqNavigate,
        loadCategories,
        loadCategory,
        updateQuestion,
        deleteQuestion,
    } = useContext(FaqContext);

    const { category_loading, categories } = faqState;

    const { url } = useRouteMatch();

    const [questionData, setQuestionData] = useState(question);

    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);

    // Unpublished should always be showing when editing
    useEffect(() => {
        setHideUnpublished(false);
    });

    const standardOnChange = ({ name, value }) => {
        setQuestionData({ ...questionData, [name]: value });
    };

    const handleDelete = () => {
        setSaving(true);
        deleteQuestion(questionData.uuid)
            .then((data) => {
                history.push(`${basepath}`);

                addToast({
                    type: "success",
                    content: trans("The question has been deleted"),
                    showFor: 5000,
                });

                // Reload sidebar
                loadCategories();
            })
            .catch(({ response }) => {
                addToast({
                    type: "alert",
                    content: trans("An error occurred deleting the question"),
                    showFor: 5000,
                });
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const handleSave = () => {
        const questionUuid = questionData.uuid;
        setSaving(true);
        updateQuestion(questionData)
            .then((data) => {
                const newCategorySlug = data.slug;

                const newQuestionSlug = data.questions.find((question) => {
                    return question.uuid === questionUuid;
                }).slug;

                history.push(
                    `${basepath}/${newCategorySlug}/${newQuestionSlug}`
                );

                addToast({
                    type: "success",
                    content: trans("The question has been updated"),
                    showFor: 5000,
                });

                return data;
            })
            .then((data) => {
                loadCategories();
                return data;
            })
            .then((data) => {
                loadCategory(data.slug);
                return data;
            })
            .catch(({ response }) => {
                addToast({
                    type: "alert",
                    content: trans("An error occurred saving your question"),
                    showFor: 5000,
                });
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <>
            {!category_loading ? (
                <General
                    categories={categories}
                    standardOnChange={standardOnChange}
                    errors={errors}
                    questionData={questionData}
                />
            ) : (
                <Loading />
            )}

            <Row>
                <Column medium={6}>
                    <Button onClick={() => faqNavigate(`${url}`)}>
                        {trans("Cancel")}
                    </Button>
                </Column>
                <Column medium={6} style={{ textAlign: "right" }}>
                    <Button
                        onClick={handleDelete}
                        style={{ marginRight: "2rem" }}
                    >
                        {trans("Delete")}
                    </Button>
                    <SaveButton saving={saving} onClick={handleSave}>
                        {trans("Save")}
                    </SaveButton>
                </Column>
            </Row>
        </>
    );
};

export default FaqQuestionEdit;
