import React from "react";
import styled from "styled-components";

export const SummaryColumn = styled.aside`
    position: absolute;
    display: flex;
    align-items: center;
    /* z-index: 50; */
    right: 0px;
    width: ${({ summaryColumn }) => summaryColumn.width}px;
    height: 100%;
    background: ${({ backgroundColor }) => backgroundColor};
    color: ${({ textColor }) => textColor};
`;

export const SummaryColumnHeader = ({ summaryColumn, colors }) => (
    <SummaryColumn
        summaryColumn={summaryColumn}
        backgroundColor={
            colors.summaryColumn &&
            colors.summaryColumn.header &&
            colors.summaryColumn.header.background
                ? colors.summaryColumn.header.background
                : "none"
        }
        textColor={
            colors.summaryColumn &&
            colors.summaryColumn.header &&
            colors.summaryColumn.header.color
                ? colors.summaryColumn.header.color
                : colors.header.label
        }
    >
        {React.createElement(summaryColumn.header)}
    </SummaryColumn>
);

export const SummaryColumnContent = ({ summaryColumn, colors, task, user }) => (
    <SummaryColumn
        summaryColumn={summaryColumn}
        backgroundColor={
            colors.summaryColumn &&
            colors.summaryColumn.content &&
            colors.summaryColumn.content.background
                ? colors.summaryColumn.content.background
                : colors.taskRow.sidebar
        }
        textColor={
            colors.summaryColumn &&
            colors.summaryColumn.content &&
            colors.summaryColumn.content.color
                ? colors.summaryColumn.content.color
                : colors.taskRow.label
        }
    >
        {React.createElement(summaryColumn.content, { task, user })}
    </SummaryColumn>
);
