import React, { useState, useContext, useCallback, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Modal,
    IconTextButton,
    SubmitButton,
    Row,
    Column,
    Select,
    Loading,
    useAddToast,
    Table,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { GroupSwitcher } from "@cortexglobal/cortex-auth-react-router-dom";

import AssetContext from "../AssetContext";
import AssetDetailsPreviewPanel from "./AssetDetailsPreviewPanel";
import AssetDetailsPanel from "./AssetDetailsPanel";

const ButtonRow = styled(Row)`
    padding-top: ${({ showDetails }) => (showDetails ? "1rem" : "0")};
    margin-top: ${({ showDetails }) => (showDetails ? "2rem" : "1rem")};
    position: ${({ showDetails }) => (showDetails ? "sticky" : "relative")};
    bottom: 0;
    background: ${({ showDetails, theme }) =>
        showDetails ? theme.modal.background : "none"};
`;
const OrderModal = ({
    asset = null,
    assetUuid,
    visible,
    onClose,
    activityUuid = "",
    showDetails = false,
    showGroupSwitcher = false,
}) => {
    const [selectedFulfilmentFlow, setSelectedFulfilmentFlow] = useState(null);
    const [saving, setSaving] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(asset);
    const [loaded, setLoaded] = useState(false);
    const { group } = useContext(AssetContext);
    const [currentGroup, setCurrentGroup] = useState(group);
    const history = useHistory();
    const addToast = useAddToast();

    useEffect(() => {
        if (assetUuid && !asset) {
            axios
                .get(`/api/v1/assets/${assetUuid}`)
                .then(({ data }) => {
                    setSelectedAsset(data.data);
                    setLoaded(true);
                })
                .catch((e) => {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                });
        } else {
            setLoaded(true);
        }
    }, [asset, assetUuid]);

    useEffect(() => {
        if (selectedAsset) {
            if (selectedAsset.order_flows.length === 1) {
                setSelectedFulfilmentFlow(selectedAsset.order_flows[0].alias);
            }
        }
    }, [selectedAsset]);

    const generateOrder = () => {
        setSaving(true);

        let values = {
            fulfilment_flow: selectedFulfilmentFlow,
            asset_uuid: selectedAsset.uuid,
        };
        if (activityUuid) {
            values.activity_uuid = activityUuid;
        }
        axios
            .post(`/api/v1/user-assets/generate-order`, values)
            .then(({ data }) => {
                setSaving(false);
                //If the asset is editable, then we need to redirect to the edit page
                if (selectedAsset.editable) {
                    history.push(
                        `/resources/my-media/${data?.data?.uuid}/edit`
                    );
                } else {
                    history.push(`/orders/${data?.data?.order?.uuid}/edit`);
                }
                onClose();
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
                addToast({
                    type: "alert",
                    content: e.response.data.message,
                    showFor: 5000,
                });
            });
    };

    const showGroupSwitcherChoice =
        loaded && selectedAsset.editable && showGroupSwitcher;
    const showSimpleEditableMessage =
        loaded && selectedAsset.editable && !showGroupSwitcher;

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            maxWidth={showDetails ? "90%" : "640px"}
        >
            {loaded ? (
                <>
                    <Row>
                        <Column>
                            <h2>
                                {showDetails
                                    ? trans(
                                          "Create New Order With: {assetName}",
                                          { assetName: selectedAsset.name }
                                      )
                                    : trans("Order {assetName}", {
                                          assetName: selectedAsset.name,
                                      })}
                            </h2>
                        </Column>
                        {showGroupSwitcherChoice && (
                            <>
                                {updating ? (
                                    <Column>
                                        <h2>{trans("Switching group...")}</h2>
                                    </Column>
                                ) : (
                                    <>
                                        <Column>
                                            <p>
                                                <strong>
                                                    {trans(
                                                        "This asset will require personalisation for {groupName}, if you wish to change groups you can select one below, otherwise just press order to continue to customisation.",
                                                        {
                                                            groupName:
                                                                currentGroup.name,
                                                        }
                                                    )}
                                                </strong>
                                            </p>
                                        </Column>
                                        <Column>
                                            <GroupSwitcher
                                                hideLabel={true}
                                                onGroupChange={(group) =>
                                                    setCurrentGroup(group)
                                                }
                                                onSubmitting={(value) =>
                                                    setUpdating(value)
                                                }
                                            />
                                        </Column>
                                    </>
                                )}
                            </>
                        )}
                        {showSimpleEditableMessage && (
                            <Column>
                                <p>
                                    {trans(
                                        "This asset will require personalisation. Once you start the order you'll be able to customise the asset to your requirements."
                                    )}
                                </p>
                            </Column>
                        )}
                    </Row>
                    <div
                        style={{
                            position: showDetails ? "relative" : "initial",
                            height: showDetails ? "68vh" : "auto",
                            overflow: "auto",
                        }}
                    >
                        {showDetails ? (
                            <Row>
                                <Column medium={8}>
                                    {" "}
                                    <AssetDetailsPreviewPanel
                                        asset={selectedAsset}
                                        loaded={true}
                                    />
                                </Column>
                                <Column medium={4}>
                                    <AssetDetailsPanel
                                        asset={selectedAsset}
                                        loaded={true}
                                    />
                                </Column>
                            </Row>
                        ) : (
                            typeof selectedAsset.meta_data === "object" && (
                                <Row style={{ marginTop: "1.5rem" }}>
                                    <Column>
                                        <Table tableType="noStripe">
                                            <tbody>
                                                {Object.entries(
                                                    selectedAsset.meta_data
                                                ).map(
                                                    ([metaName, metaValue]) => {
                                                        return (
                                                            <tr key={metaName}>
                                                                <th
                                                                    style={{
                                                                        width: "33%",
                                                                    }}
                                                                >
                                                                    {metaName}
                                                                </th>
                                                                <td>
                                                                    {metaValue}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </Table>
                                    </Column>
                                </Row>
                            )
                        )}
                        <ButtonRow showDetails={showDetails}>
                            {selectedAsset.order_flows.length > 1 && (
                                <Column>
                                    <Select
                                        value={selectedFulfilmentFlow}
                                        onChange={({ value }) =>
                                            setSelectedFulfilmentFlow(value)
                                        }
                                        options={selectedAsset.order_flows.map(
                                            (flow) => ({
                                                value: flow.alias,
                                                text: flow.name,
                                            })
                                        )}
                                    />
                                </Column>
                            )}
                            <Column
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconTextButton
                                    type="alert"
                                    onClick={onClose}
                                    showCircle={false}
                                    icon="times"
                                >
                                    {trans("Cancel")}
                                </IconTextButton>
                                <SubmitButton
                                    onClick={generateOrder}
                                    disabled={
                                        !selectedFulfilmentFlow ||
                                        saving ||
                                        updating
                                    }
                                    submitting={saving}
                                >
                                    {trans("Order")}
                                </SubmitButton>
                            </Column>
                        </ButtonRow>
                    </div>
                </>
            ) : (
                <Row>
                    <Column>
                        <Loading />
                    </Column>
                </Row>
            )}
        </Modal>
    );
};

export default OrderModal;
