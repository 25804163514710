import React, {
    MouseEvent,
    useContext,
    useEffect,
    useState,
    useRef,
} from "react";
import styled from "styled-components";

//@ts-ignore cortex
import { useClickOutside } from "@cortexglobal/cortex-utilities";

import MicrositeContext from "../../MicrositeContext";
import { SET_PAGE_INDEX } from "../../hooks/useSlideshowNavigation";

const MenuContainer = styled.div<MenuContainerProps>`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    z-index: 1002;
    justify-content: start;
    align-items: start;

    transform: ${(props) => {
        if (props.visible) return "translateX(0%)";
        return "translateX(-100%)";
    }};

    background: ${(props) => {
        if (props?.globalTheming?.backgroundColor)
            return props.globalTheming.backgroundColor;
        return "white";
    }};

    transition: all 500ms ease;
`;

const NavigationButton = styled.button`
    background: none;
    outline: none;
    border: none;
    font-size: 0.7em;
    text-align: left;
    cursor: pointer;
    padding: 0.2em;
    padding-left: 3rem;
    font-weight: bold;
    width: 100%;

    color: ${(props) => (props.color ? props.color : "white")};
`;

// const NavigationContainerOuter = styled.div<NavigationOuterProps>`
//     width: 70%;
//     display: flex;
//     margin-top: -${({ marginTop }) => (marginTop ? parseInt(marginTop) * 4 : "0")}px;
// `;

const NavigationGroupButton = styled.p`
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.5rem 0 0.5rem 0;
    width: 100%;
    font-size: 1rem;
`;

export const SlideOutNavigation = ({
    visible,
    onClose,
    pages,
}: {
    visible: boolean;
    onClose: () => void;
    pages: {
        name: string;
        index: number;
        hidden: boolean;
    }[];
}) => {
    const { globalTheming, navigationDispatch, slideSize } =
        useContext(MicrositeContext);

    type GroupPage = {
        groupName: string;
        pages: {
            name: string;
            index: number;
            hidden: boolean;
        }[];
    };

    const [navigationPages, setNavigationPages] = useState<GroupPage[]>([]);

    const wrapperRef = useRef(null);
    //The below is not working, a click event has been attached around the content to
    //close the menu, it might be good to see if we can get this working though
    // useClickOutside(wrapperRef, () => onClose());

    const isHiddenFromNavigation = (page: any) => {
        return page[1].settings.filter(
            (setting: any) => setting.name === "hideFromNavigation"
        ).length
            ? !!page[1].settings.filter(
                  (setting: any) => setting.name === "hideFromNavigation"
              )[0].value
            : false;
    };

    useEffect(() => {
        const newPages: GroupPage[] = [];
        // console.log(pages);
        pages
            .filter((page) => !page[1].hidden)
            .forEach((page, index) => {
                if (
                    !newPages.some(
                        ({ groupName }) => groupName === page[1].group
                    )
                ) {
                    newPages.push({
                        groupName: page[1].group,
                        pages: [
                            {
                                name: page[1].name,
                                index: index,
                                hidden: isHiddenFromNavigation(page),
                            },
                        ],
                    });
                } else {
                    const newPageIndex = newPages.findIndex(
                        ({ groupName }) => groupName === page[1].group
                    );
                    newPages[newPageIndex].pages.push({
                        name: page[1].name,
                        index: index,
                        hidden: isHiddenFromNavigation(page),
                    });
                }
            });

        setNavigationPages(newPages);
    }, [pages]);

    return (
        <MenuContainer
            globalTheming={globalTheming}
            visible={visible}
            ref={wrapperRef}
        >
            {navigationPages.map((groupPage, index) => {
                return (
                    <section key={groupPage.groupName}>
                        <NavigationGroupButton>
                            {groupPage.groupName}
                        </NavigationGroupButton>
                        {groupPage.pages.map((page) => {
                            console.log(page);
                            if (page.hidden) return null;
                            return (
                                <NavigationButton
                                    key={page.index}
                                    color={globalTheming?.highlightColor}
                                    onClick={(e: MouseEvent) => {
                                        if (navigationDispatch)
                                            navigationDispatch({
                                                type: SET_PAGE_INDEX,
                                                pageIndex: page.index,
                                            });
                                        onClose();
                                    }}
                                >
                                    {page.name}
                                </NavigationButton>
                            );
                        })}
                    </section>
                );
            })}
        </MenuContainer>
    );
};

interface NavigationButtonProps {
    color?: string;
}

interface NavigationOuterProps {
    marginTop?: string;
}

interface MenuContainerProps {
    globalTheming?: { [key: string]: any };
    visible?: boolean;
}
