import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { UserInfoIcon } from "@cortexglobal/rla-components";

const SuperCounter = styled.div`
    position: absolute;
    padding: 0.1rem 5px 0 5px;
    top: -7px;
    line-height: 1.3;
    right: -13px;
    font-size: 10px;
    /* color: ${(props) => props.theme.userInfo.icon.color}; */
    color: white;
    background: red;
    border-radius: 50px;
    min-width: 15px;
    height: 15px;
    text-align: center;
`;

const MessageIconButton = ({ handleClick, style }) => {
    const { can } = usePermissions();
    // let metaData = useSelector(state => {
    //     return get(state, "auth.metaData", {});
    // });
    let metaData = {};
    try {
        metaData = useSelector((state) => state.auth.metaData);
    } catch (error) {}
    // const metaData = useSelector(state => state.auth.metaData);
    // const metaData = {};
    // console.log(metaData);
    if (!can("communication-view-messages")) {
        return null;
    }

    return (
        <UserInfoIcon
            as={Link}
            to="/communications"
            onClick={handleClick}
            style={{
                marginTop: "10px",
                marginRight: "10px",
                ...style,
            }}
        >
            <FontAwesomeIcon icon="envelope" />
            {metaData?.unread_messages > 0 && (
                <SuperCounter>{metaData.unread_messages}</SuperCounter>
            )}
        </UserInfoIcon>
    );
};

export default MessageIconButton;
