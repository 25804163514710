import React from "react";

import { ErrorIndicator } from "./ErrorIndicator";

export const HeaderWithErrorIndicated = ({
    title,
    section,
    sectionErrors,
    ...rest
}: {
    title: string;
    section: string;
    sectionErrors: string[];
    rest: any;
}) => {
    return (
        <span {...rest}>
            {title}
            {sectionErrors.indexOf(section) !== -1 && <ErrorIndicator />}
        </span>
    );
};
