import React, { useEffect, useReducer } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import {
    faCalendarAlt,
    faCog,
    faEdit,
    faAsterisk,
    faFile,
    faBars
} from "@fortawesome/free-solid-svg-icons";

export const LOADED_DATA = "LOADED_DATA";
export const LOADING_NEW_DATA = "LOADING_NEW_DATA";
export const UPDATE_ASSET_STATE = "UPDATE_ASSET_STATE";
export const SAVING_DATA = "SAVING_DATA";
export const FINISH_SAVING_DATA = "FINISH_SAVING_DATA";
export const LOADED_ASSET_TYPES = "LOADED_ASSET_TYPES";
export const LOADING_ASSET_TYPES = "LOADING_ASSET_TYPES";
export const LOADED_ASSET_STATUS_OPTIONS = "LOADED_ASSET_STATUS_OPTIONS";
export const LOADING_ASSET_STATUS_OPTIONS = "LOADING_ASSET_STATUS_OPTIONS";
export const LOADED_ASSET_STATUS_DECLARATIONS =
    "LOADED_ASSET_STATUS_DECLARATIONS";

// export interface AssetDetailType {
//     loaded: boolean;
//     data: {
//         "id": 32,
//             "smartRef": string;
//             "uuid": string;
//             "slug": string;
//             "media_type": string;
//             "main_file_path": string;
//             "main_file_extension": string;
//             "main_file_original_name": string;
//             "name": string;
//             "description": string;
//             "locale": string;
//             "version": string;
//             "author": string;
//             "group_id": number;
//             "group_uuids": [],
//             "media_category": null,
//             "thumbnail": null,
//             "country_code": string;
//             "categories": [],
//             "tags": [],
//             "valid_from": string;
//             "valid_to": string;
//             "available_from": string;
//             "available_to": string;
//             "status": string;
//             "group_access_type": string;
//             "created_at": string;
//             "updated_at": string;
//     };
// }
// export type ActionType = {
//     type: "LOADED_DATA" | "LOADING_NEW_DATA";
//     [key: string]: any;
// };

export let initialAssetState = {
    assetEdited: false,
    assetLoaded: false,
    assetSaving: false,
    asset: {},
    assetTypesLoaded: false,
    assetTypes: [],
    assetStatusOptionsLoaded: false,
    assetStatusOptions: [],
    assetDeclarations: []
};
// const reducer = (state: AssetDetailType, action: ActionType) => {
const reducer = (state, action) => {
    switch (action.type) {
        case LOADED_DATA:
            return {
                ...state,
                assetLoaded: true,
                asset: action.data,
                assetEdited: false,
                assetSaving: false
            };
        case LOADING_NEW_DATA:
            return { ...state, ...initialAssetState };
        case SAVING_DATA:
            return {
                ...state,
                assetSaving: true
            };
        case FINISH_SAVING_DATA:
            return {
                ...state,
                assetSaving: false
            };
        case UPDATE_ASSET_STATE:
            return {
                ...state,
                asset: { ...state.asset, [action.name]: action.value },
                assetEdited: true
            };
        case LOADED_ASSET_TYPES:
            return {
                ...state,
                assetTypesLoaded: true,
                assetTypes: action.data
            };
        case LOADING_ASSET_TYPES:
            return { ...state, assetTypes: [...initialAssetState.assetTypes] };

        case LOADED_ASSET_STATUS_OPTIONS:
            // console.log("action.data", action);
            return {
                ...state,
                assetStatusOptionsLoaded: true,
                assetStatusOptions: action.data
            };
        case LOADING_ASSET_STATUS_OPTIONS:
            return {
                ...state,
                assetStatusOptions: [...initialAssetState.assetStatusOptions]
            };

        case LOADED_ASSET_STATUS_DECLARATIONS:
            return {
                ...state,
                assetDeclarations: action.data
            };

        default:
            return state;
    }
};
// export const useAssetAdmin = (
//     assetUuid: string
// ): [AssetDetailType, (action: ActionType) => void] => {
export const useAssetAdmin = (assetUuid, assetsPath, fullAdmin = true) => {
    const [state, dispatch] = useReducer(reducer, initialAssetState);
    let history = useHistory();

    useEffect(() => {
        dispatch({
            type: LOADING_NEW_DATA
        });
        axios
            .get(`/api/v1/admin/assets/${assetUuid}`)
            .then(({ data: { data } }) => {
                if (data.isVersioned && data.isLive) {
                    history.push(`${assetsPath}/view/${assetUuid}`);
                }
                dispatch({
                    type: LOADED_DATA,
                    data,
                    assetsPath,
                    fullAdmin
                });

                return data;
            })
            .then(data => {
                dispatch({
                    type: LOADING_ASSET_TYPES
                });
                axios
                    .get(
                        `/api/v1/assets/types${
                            data.main_file_extension
                                ? `/${data.main_file_extension}`
                                : ""
                        }`
                    )
                    .then(({ data: { data } }) => {
                        dispatch({
                            type: LOADED_ASSET_TYPES,
                            data
                        });

                        return data;
                    })
                    .catch(e => e);

                dispatch({
                    type: LOADING_ASSET_STATUS_OPTIONS
                });
                axios
                    .get(`/api/v1/assets/status-options/${assetUuid}`)
                    .then(data => {
                        dispatch({
                            type: LOADED_ASSET_STATUS_OPTIONS,
                            data: data.data.availableStatusList
                        });
                        dispatch({
                            type: LOADED_ASSET_STATUS_DECLARATIONS,
                            data: data.data.declarations
                        });
                    })
                    .catch(e => e);
            });
    }, [assetUuid]);

    return [state, dispatch];
};
