import React, { useEffect, useReducer } from "react";
import axios from "axios";

export const LOADED_DATA = "LOADED_DATA";
export const LOADING_DATA = "LOADING_DATA";
export const UPDATE_ASSET_STATE = "UPDATE_ASSET_STATE";
export const LOADED_ASSET_TYPES = "LOADED_ASSET_TYPES";
export const LOADING_ASSET_TYPES = "LOADING_ASSET_TYPES";
// export const SET_STEP = "SET_STEP";

export let initialState = {
    loaded: false,
    data: []
};
// const reducer = (state: AssetDetailType, action: ActionType) => {
const reducer = (state, action) => {
    switch (action.type) {
        case LOADED_DATA:
            return {
                ...state,
                loaded: true,
                data: action.data
            };
        case LOADING_DATA:
            return { ...state, ...initialState };
        default:
            return state;
    }
};
// export const useAssetAdmin = (
//     assetUuid: string
// ): [AssetDetailType, (action: ActionType) => void] => {
export const useAssetComponents = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({
            type: LOADING_DATA
        });
        axios
            .get(`/api/v1/assets/components`)
            .then(({ data }) => {
                dispatch({
                    type: LOADED_DATA,
                    data
                });

                return data;
            })
            .catch(e => e);
    }, []);

    return [state, dispatch];
};
