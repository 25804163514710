import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    InputField,
    StyledCheckbox,
    FormLabel,
    Image,
    TextareaField,
    RepeaterField,
    DraftJSField,
    ColorField,
    SelectField,
    RadioField,
} from "@cortexglobal/rla-components";
import SequencedVideo from "./sequencedVideo/SequencedVideo";
import AssetImageBrowser from "./assetImageBrowser/AssetImageBrowser";
import FileUpload from "./FileUpload";

import { ManageFootnotes } from "./ManageFootnotes";

const Img = styled(Image)`
    display: block;
    max-width: 100%;
    max-height: 300px;
`;
/**
 * Returns an appropriate toolbar based on the asset type, e.g. HTML outputs currently
 * don't handle the inline styles so we're not offering them the full object is viewable here
 * https://jpuri.github.io/react-draft-wysiwyg/#/docs
 * @param {string} assetType
 */
const getToolbar = (assetType) => {
    switch (assetType) {
        case "email":
        case "digital":
            return {
                options: [
                    "inline",
                    "blockType",
                    "list",
                    "remove",
                    "history",
                    "link",
                ],
                inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                },
                blockType: {
                    inDropdown: true,
                    options: [
                        "Normal",
                        "H1",
                        "H2",
                        "H3",
                        "H4",
                        "H5",
                        "H6",
                        "Blockquote",
                    ],
                },
            };

        default:
            return {
                options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "textAlign",
                    "remove",
                    "history",
                    "link",
                ],
                blockType: {
                    inDropdown: true,
                    options: [
                        "Normal",
                        "H1",
                        "H2",
                        "H3",
                        "H4",
                        "H5",
                        "H6",
                        "Blockquote",
                    ],
                },
            };
    }
};
const FootNote = styled("div")`
    display: block;
    max-width: 100%;
    text-align: right;
    font-style: italic;
    font-size: 8pt;
    /* margin-top: -15px; */
`;

const maxCharsFootNote = (tag) => {
    const intl = useIntl();
    const theme = useTheme();
    let max = tag.characters;
    let used = tag.value ? tag.value.length : 0;
    let remaining = max - used;
    return (
        <FootNote>
            {trans("Suggested Max")}: {max} (
            <span
                style={
                    remaining < 0
                        ? { color: theme.colors.alert, fontWeight: "bold" }
                        : {}
                }
            >
                {used} {trans("used")}
            </span>
            {remaining > 0
                ? ` ${remaining} ${intl.formatMessage({ id: "remaining" })}`
                : ""}
            )
        </FootNote>
    );
};

const InputType = ({
    path,
    tag,
    onChange,
    label,
    useFootnotes,
    asset,
    baseAsset,
    editingUserAsset,
    maxLength,
}) => {
    const {
        name,
        value,
        type,
        hideLabel,
        characters,
        options,
        label: tagLabel,
        ...extraTagProps
    } = tag;
    let labelMessage = label ? label : tagLabel ? tagLabel : name;

    let assetType =
        asset && asset.asset_type
            ? asset.asset_type
            : baseAsset
            ? baseAsset.asset_type
            : "";

    assetType = typeof assetTypeSlug === "string" ? assetType : assetType.slug;

    if (hideLabel) {
        labelMessage = "";
    }

    //We need to adjust the margin if we're using footnotes so that they can be up against the input
    const styleObject = useFootnotes
        ? {
              marginBottom: "0.2rem",
          }
        : {};

    if (type === "checkbox") {
        return (
            <React.Fragment>
                <StyledCheckbox
                    id={name}
                    inline={true}
                    name={name}
                    checked={value ? value : false}
                    onChange={(event) => {
                        // console.log("event", event);
                        onChange("value", path, {
                            ...event,
                            name: event.target.name,
                            value: event.target.checked ? true : false,
                        });
                    }}
                />
                {!hideLabel && (
                    <FormLabel name={name} inlineLabel={true}>
                        {labelMessage}
                    </FormLabel>
                )}
            </React.Fragment>
        );
    }
    if (type === "image" || type === "gwd-image") {
        return (
            <div>
                {!hideLabel && (
                    <FormLabel name={name}>{labelMessage}</FormLabel>
                )}
                {/* <Img
                    src={value}
                    onClick={event => chooseImage(path, tag, event)}
                /> */}

                <AssetImageBrowser
                    editingUserAsset={editingUserAsset}
                    asset={asset}
                    src={value}
                    id={name}
                    name={name}
                    value={value}
                    width={Math.round(tag.width)}
                    height={Math.round(tag.height)}
                    allowUpload={tag.allowUpload}
                    onChange={(event) => {
                        // console.log("event", event);
                        onChange("value", path, event);
                    }}
                />
            </div>
        );
    }

    if (type === "pdf") {
        return (
            <div>
                {!hideLabel && (
                    <FormLabel name={name}>{labelMessage}</FormLabel>
                )}
                <FileUpload
                    asset={editingUserAsset ? baseAsset : asset}
                    name={name}
                    value={value}
                    onChange={(event) => {
                        onChange("value", path, event);
                    }}
                />
            </div>
        );
    }

    if (type === "link") {
        return (
            <div>
                <InputField
                    type={type}
                    label={labelMessage}
                    name={name}
                    value={value}
                    onChange={(event) => {
                        onChange("value", path, event);
                    }}
                    style={styleObject}
                />
                {editingUserAsset && (
                    <InputField
                        label={
                            <span>
                                {labelMessage} {trans("(href / url)")}
                            </span>
                        }
                        name={name}
                        value={tag.href}
                        onChange={(event) => onChange("href", path, event)}
                        placeholder={name}
                    />
                )}
            </div>
        );
    }

    if (type === "sequenced-video") {
        // console.log("sequenced-video", tag);
        return (
            <React.Fragment>
                {!hideLabel && (
                    <FormLabel name={name}>{labelMessage}</FormLabel>
                )}
                <SequencedVideo
                    path={path}
                    asset={asset}
                    id={name}
                    name={name}
                    value={value}
                    originalOnChange={onChange}
                    onChange={(event) => {
                        // console.log("event", event);
                        onChange("value", path, event);
                    }}
                    style={styleObject}
                />
            </React.Fragment>
        );
    }
    if (type === "draft-js") {
        return (
            <React.Fragment>
                {!hideLabel && (
                    <FormLabel name={name}>{labelMessage}</FormLabel>
                )}
                <DraftJSField
                    id={name}
                    name={name}
                    value={value}
                    onChange={(event) => {
                        // console.log("event", event);
                        onChange("value", path, event);
                    }}
                    style={styleObject}
                    toolbar={getToolbar(assetType)}
                />
            </React.Fragment>
        );
    }

    if (type === "color") {
        return (
            <>
                {!hideLabel && (
                    <FormLabel name={name}>{labelMessage}</FormLabel>
                )}
                <ColorField
                    name={name}
                    value={value}
                    onChange={(event) => {
                        onChange("value", path, event);
                    }}
                />
            </>
        );
    }

    if (type === "hidden") {
        return null;
    }

    if (type === "repeater") {
        return (
            <>
                {!hideLabel && (
                    <FormLabel name={name}>{labelMessage}</FormLabel>
                )}
                <RepeaterField
                    style={{ marginTop: "1rem" }}
                    noPadding={true}
                    values={value ? value : []}
                    onChanges={(value) => {
                        onChange("value", path, {
                            name: name,
                            value: value,
                        });
                    }}
                    types={[
                        {
                            name: "value",
                            type: "Text",
                            width: "50%",
                        },
                        {
                            name: "bullet",
                            type: "Toggle",
                            width: "20%",
                        },
                    ]}
                >
                    <ManageFootnotes
                        asset={asset}
                        tag={tag}
                        path={path}
                        onChange={onChange}
                    />
                </RepeaterField>
            </>
        );
    }

    if (type === "radio") {
        return (
            <>
                <RadioField
                    label={labelMessage}
                    name={name}
                    value={value}
                    options={options}
                    onChange={(event) => {
                        onChange("value", path, event);
                    }}
                    {...extraTagProps}
                    style={styleObject}
                />
            </>
        );
    }

    if (type === "select") {
        return (
            <>
                <SelectField
                    type="text"
                    label={labelMessage}
                    name={name}
                    value={value}
                    options={options}
                    onChange={(event) => {
                        onChange("value", path, event);
                    }}
                    {...extraTagProps}
                    style={styleObject}
                />
            </>
        );
    }

    if (characters > 70 || (value && value.length > 70)) {
        return (
            <>
                <TextareaField
                    label={labelMessage}
                    name={name}
                    value={value}
                    onChange={(event) => onChange("value", path, event)}
                    style={styleObject}
                />
                {maxCharsFootNote(tag)}
            </>
        );
    }

    // console.log({ tag, type, name, value });
    if (characters) {
        return (
            <>
                <InputField
                    maxLength={maxLength}
                    type={type}
                    label={labelMessage}
                    name={name}
                    value={value}
                    onChange={(event) => {
                        onChange("value", path, event);
                    }}
                    style={styleObject}
                />
                {maxCharsFootNote(tag)}
            </>
        );
    }
    return (
        <InputField
            maxLength={maxLength}
            type={type}
            label={labelMessage}
            name={name}
            value={value}
            onChange={(event) => {
                onChange("value", path, event);
            }}
            {...extraTagProps}
            style={styleObject}
        />
    );
};

export default InputType;
