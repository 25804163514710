import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    LoadingIconSmall,
    Select,
    useAddToast,
} from "@cortexglobal/rla-components";

const MediaChannelSelect = ({ mediaChannels, assetType, ...props }) => {
    const addToast = useAddToast();
    const [loaded, setLoaded] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!mediaChannels) {
            getOptions();
        } else {
            setOptions(filterOptions(mediaChannels));
            setLoaded(true);
        }
    }, [assetType]);

    const getOptions = () => {
        setLoaded(false);

        axios
            .get("/api/v1/assets/media-channels")
            .then(({ data }) => {
                setOptions(filterOptions(data.data));
            })
            .catch((e) => {
                addToast({ type: "alert", content: e.message, showFor: 5000 });
            })
            .finally(() => setLoaded(true));
    };

    const filterOptions = (allChannels) => {
        if (!assetType) return allChannels;
        return allChannels.filter(
            (mediaChannel) => mediaChannel.asset_types.indexOf(assetType) > -1
        );
    };

    return loaded ? (
        <Select {...props} options={options} />
    ) : (
        <LoadingIconSmall />
    );
};

export default MediaChannelSelect;
