import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    SelectField,
    StyledSelectAdvanced,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import { setUserGroup, getUserGroups } from "@cortexglobal/cortex-auth-redux";

class GroupSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            activeGroup: "",
        };
    }

    componentDidMount() {
        this.setState({
            activeGroup: this.props.group.uuid,
        });
    }

    componentDidUpdate(props) {
        if (this.state.activeGroup !== props.group.uuid) {
            this.setState({
                activeGroup: props.group.uuid,
            });
        }
    }

    userGroupChanged = ({ value: group }) => {
        if (!group || this.state.activeGroup === group) {
            return false;
        }

        const submittingCallback = this.props.onSubmitting;
        if (submittingCallback) {
            submittingCallback(true);
        }

        this.setState({ activeGroup: group });

        this.props
            .setUserGroup({ group })
            .then((group) => {
                if (submittingCallback) {
                    submittingCallback(false);
                }
                const onGroupChange = this.props.onGroupChange;
                if (onGroupChange) {
                    onGroupChange(group);
                } else {
                    //Reload the whole app, as all the data will need refreshing
                    window.location.reload(true);
                }
            })
            .catch((e) => e);
    };

    buildGroupOptions = (groups) => {
        if (!groups) {
            return [];
        }
        let options = [];
        let mapped = [];
        this.mapChildren(groups, options, "", mapped);
        return options;
    };

    mapChildren(descendants, options, padding = "", mapped) {
        //let prefix = "\xA0\xA0";
        let prefix = "";
        if (descendants) {
            for (let i = 0; i < descendants.length; i++) {
                if (descendants[i].children) {
                    //if descendant has children, create it as an optgroup first
                    descendants[i].type = "optgroup";
                    //insert optgroup into options
                    options.push(this.mapOptions(descendants[i], ""));

                    // make a clone of the item
                    var copyOption = { ...descendants[i] };
                    // change type back to option
                    copyOption.type = "option";
                    copyOption.name += " (All)";
                    // remove children from it
                    delete copyOption.children;

                    // insert back into itself as a child without its decsendants
                    descendants[i].children.unshift(copyOption);
                } else {
                    if (
                        mapped.indexOf(descendants[i].id) < 0 &&
                        descendants[i].can_contain_users &&
                        descendants[i].isInGroup
                    ) {
                        options.push(
                            this.mapOptions(descendants[i], "\xA0\xA0")
                        );
                        mapped.push(descendants[i].id);
                    }
                }
                if (
                    descendants[i].children //&&
                    // descendants[i].type == "optgroup"
                ) {
                    this.mapChildren(
                        descendants[i].children,
                        options,
                        padding + prefix,
                        mapped
                    );
                    // console.log('children mapped');
                }
            }
        }
    }

    mapOptions = (item, padding = "") => {
        let type = item.type ? item.type : "option";
        if (item) {
            return {
                alias: item.name,
                text: padding + " " + item.name,
                value: item.uuid,
                type: "option",
            };
        }
        return null;
    };

    render() {
        const { groups, user, hideLabel = false } = this.props;
        if (!groups || groups.length <= 1) {
            return null;
        }
        return (
            <React.Fragment>
                {user.group_structure_type === "flat" ? (
                    <StyledSelectAdvanced
                        label={hideLabel ? null : trans("Change Group:")}
                        style={{
                            marginBottom: ".5rem",
                        }}
                        name="user-group"
                        onChange={this.userGroupChanged}
                        options={groups.map((e) => ({
                            value: e.uuid,
                            text: e.name,
                        }))}
                        value={this.state.activeGroup}
                    />
                ) : (
                    <SelectField
                        label={hideLabel ? null : trans("Change Group:")}
                        style={{
                            marginBottom: ".5rem",
                        }}
                        name="user-group"
                        onChange={this.userGroupChanged}
                        options={this.buildGroupOptions(groups)}
                        value={this.state.activeGroup}
                    />
                )}

                {/* <Select
                    name="user-group"
                    onChange={this.userGroupChanged}
                    options={this.props.groups.map(group => ({
                        value: group.uuid,
                        text: group.name
                    }))}
                    value={this.state.activeGroup}
                    clearable={false}
                /> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, group, groups } = state.auth;

    return { user, group, groups };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUserGroup,
            getUserGroups,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GroupSwitcher);
