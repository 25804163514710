import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import {
    Panel,
    Row,
    Column,
    Button,
    TableList,
    Modal,
    Badge,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { DownloadFileButton } from "@cortexglobal/files";

class TaskActivityFiles extends Component {
    generateFileList = (activities) => {
        return Array.isArray(activities)
            ? activities.reduce((files, activity) => {
                  activity.files.map((file) => {
                      file.activity = activity;
                      files.push(file);
                  });
                  return files;
              }, [])
            : [];
    };

    render() {
        const { task, theme, showHeading, viewActivity } = this.props;

        const files = this.generateFileList(task.activities);
        return (
            <React.Fragment>
                <Row>
                    <Column medium={9}>
                        {showHeading && <h3>{trans("Activity Files")}</h3>}
                        &nbsp;
                    </Column>
                    <Column medium={3}></Column>
                </Row>
                <TableList>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "40%",
                                }}
                            >
                                {trans("Activity Name")}
                            </th>
                            <th
                                style={{
                                    width: "40%",
                                }}
                            >
                                {trans("Filename")}
                            </th>
                            <th
                                style={{
                                    width: "20%",
                                }}
                            >
                                {trans("Date uploaded")}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {task.activities.length <= 0 ? (
                            <tr>
                                <td colSpan="100%">{trans("No Activities")}</td>
                            </tr>
                        ) : files.length <= 0 ? (
                            <tr>
                                <td colSpan="100%">
                                    {trans("No Activity Files")}
                                </td>
                            </tr>
                        ) : (
                            files.map((file) => {
                                // console.log(file);
                                return (
                                    <tr key={file.uuid}>
                                        <td>{file.activity.name}</td>
                                        <td>{file.original_filename}</td>
                                        <td>
                                            {moment(file.end_date).format("ll")}
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: "nowrap",
                                                display: "flex",
                                            }}
                                        >
                                            <Button
                                                type="secondary"
                                                onClick={() => {
                                                    return viewActivity(
                                                        task,
                                                        file.activity
                                                    );
                                                }}
                                            >
                                                {trans("View Activity")}
                                            </Button>
                                            <DownloadFileButton
                                                file={file}
                                                smartRef={
                                                    file.activity.smart_ref
                                                }
                                            />{" "}
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </TableList>
            </React.Fragment>
        );
    }
}

TaskActivityFiles.propTypes = {
    task: PropTypes.object,
};

export default TaskActivityFiles;
