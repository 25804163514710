import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledDatePicker from "../styledElements/datePicker/StyledDatePicker";
import { format, getLocaleDateFormat } from "@cortexglobal/cortex-utilities";
import { on } from "events";

const DatePickerContainer = styled.div`
    width: 100%;
`;
const FormikDatePicker = ({
    type,
    name,
    label,
    disabled,
    value,
    labelWidth,
    labelAlign,
    dateFormat,
    showError = true,
    onBlur = () => {},
    includeTime = false,
    ...rest
}) => {
    return (
        <DatePickerContainer>
            <Field name={name}>
                {({
                    field: { name, ...field },
                    form: {
                        values,
                        touched,
                        errors,
                        setFieldTouched,
                        setFieldValue,
                    },
                }) => {
                    const error = !!touched[name] && errors[name];
                    return (
                        <React.Fragment>
                            {label && (
                                <FormLabel
                                    align={labelAlign}
                                    width={labelWidth}
                                    name={name}
                                    {...rest}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <StyledDatePicker
                                {...rest}
                                value={value}
                                selected={value}
                                disabled={disabled}
                                name={name}
                                dateFormat={dateFormat}
                                error={showError ? error : ""}
                                onChange={(date) => {
                                    if (!includeTime) {
                                        date.setHours(0, 0, 0, 0);
                                        date = format(date, "yyyy-MM-dd");
                                    }
                                    setFieldValue(name, date);
                                    setFieldTouched(name, true);
                                }}
                                onBlur={() => {
                                    setFieldTouched(name, true);
                                    onBlur(name);
                                }}
                            />
                        </React.Fragment>
                    );
                }}
            </Field>
            {showError && (
                <ErrorMessage name={name}>
                    {(msg) => <InputError error={msg} />}
                </ErrorMessage>
            )}
        </DatePickerContainer>
    );
};

FormikDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dateFormat: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.number,
};
FormikDatePicker.defaultProps = {
    expanded: true,
    dateFormat: getLocaleDateFormat(),
    selected: new Date(),
};
export default FormikDatePicker;
