import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Measure from "react-measure";
import debounce from "lodash/debounce";
import get from "lodash/get";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSortUp,
    faSortDown,
    faEdit,
    faChevronDown,
    faPlus,
    faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-collapse";

import { trans, FormattedCurrency, useIntl } from "@cortexglobal/rla-intl";
import { format, getItem, setItem } from "@cortexglobal/cortex-utilities";
import {
    PlainButton,
    Select,
    ButtonDropdown,
    Row,
    Column,
    Table,
    IconTextButton,
    Tooltip,
} from "@cortexglobal/rla-components";

const ActivityWrapper = styled.div`
    padding: 1rem;
    margin-right: 1.1rem;
    background: ${({ theme }) => theme.colors.white};
    border-top: 1px solid ${({ theme }) => theme.colors.gray400};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
    th {
        background: none;
        color: ${({ theme }) => theme.colors.black};
    }
    td {
        height: 60px;
    }
    .draftJs {
        max-height: 60px;
        overflow: scroll;
    }
`;

export const ActivityTable = ({ task, match, allowLinks, user }) => {
    return (
        <ActivityWrapper>
            {Array.isArray(task.activities) && task.activities.length > 0 ? (
                <>
                    <Table
                        type="subtle"
                        zebraStripe={true}
                        style={{ marginBottom: 0 }}
                    >
                        <thead>
                            <tr>
                                <th>{trans("Activity Name")}</th>
                                <th>{trans("Status")}</th>
                                <th>{trans("Date Range")}</th>
                                <th>{trans("Media Channel")}</th>
                                <th>{trans("Lead Stakeholder")}</th>
                                <th>{trans("Planned Cost")}</th>
                                <th>{trans("Actual Cost")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {task.activities.map((activity) => {
                                // console.log({ activity });
                                return (
                                    <tr key={activity.uuid}>
                                        <td>
                                            {allowLinks ? (
                                                <Link
                                                    to={`${match.path}/task/${task.uuid}/activity/${activity.uuid}`}
                                                >
                                                    {activity.ref} -{" "}
                                                    {activity.name}
                                                </Link>
                                            ) : (
                                                <>
                                                    {activity.ref} -{" "}
                                                    {activity.name}
                                                </>
                                            )}
                                        </td>
                                        <td>{activity.status_wording}</td>
                                        <td>
                                            {format(
                                                new Date(activity.start_date)
                                            )}
                                            {" - "}
                                            {format(
                                                new Date(activity.end_date)
                                            )}
                                        </td>
                                        <td>
                                            {activity?.media_channel_full
                                                ?.text || "-"}
                                        </td>
                                        <td>{activity.created_by}</td>
                                        <td>
                                            <FormattedCurrency
                                                value={
                                                    activity.planned_cost.value
                                                }
                                                currency={
                                                    activity.planned_cost
                                                        ?.currency
                                                }
                                            />
                                        </td>
                                        <td>
                                            <FormattedCurrency
                                                value={activity.cost.value}
                                                currency={
                                                    activity.cost?.currency
                                                }
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </>
            ) : (
                <div style={{ margin: "0 1rem", paddingTop: "1rem" }}>
                    {trans("No activities found")}
                </div>
            )}
        </ActivityWrapper>
    );
};

export default ActivityTable;
