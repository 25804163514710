import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import CategoryListing from "./CategoryListing";
import CategoryCreate from "./CategoryCreate";
import { CategoryEdit } from "./CategoryEdit";

export const CategoryAdmin = () => {
    const { path } = useRouteMatch();
    const categoryArea = path.split("/").pop();
    const friendlyCategoryArea =
        categoryArea.charAt(0).toUpperCase() + categoryArea.slice(1);
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return (
                        <CategoryListing
                            path={path}
                            categoryArea={friendlyCategoryArea}
                        />
                    );
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return (
                        <CategoryCreate
                            path={path}
                            categoryArea={friendlyCategoryArea}
                        />
                    );
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={props => {
                    const {
                        match: {
                            params: { uuid }
                        }
                    } = props;
                    return (
                        <CategoryEdit
                            path={path}
                            categoryArea={friendlyCategoryArea}
                            uuid={uuid}
                        />
                    );
                }}
            />
        </Switch>
    );
};

export default CategoryAdmin;
