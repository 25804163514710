import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useTheme } from "styled-components";
import { useInterval } from "@cortexglobal/cortex-utilities";
import { Loading } from "@cortexglobal/rla-components";
import { useAddToast } from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const EditableVideoPreview = ({ asset, userAsset }) => {
    const validAsset = userAsset ? userAsset : asset;
    const updatedAt = validAsset.updated_at;

    // console.log(validAsset)
    const videoRef = useRef();
    const [remainingTries, setRemainingTries] = useState(45);
    const [videoLink, setVideoLink] = useState("");
    const [videoAvailable, setVideoAvailable] = useState(false);
    const theme = useTheme();
    const addToast = useAddToast();

    const getPreviewFromApi = () => {
        axios
            .get(`/api/v1/assets/${validAsset.uuid}/preview`)
            .then((data) => {
                data = data.data.data;

                if (data.file_ready) {
                    setVideoAvailable(true);
                    setVideoLink(data.url);
                    setRemainingTries(0);

                    addToast({
                        type: "success",
                        content: trans("Video Now Available"),
                        showFor: 5000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        // if (userAsset) {
        getPreviewFromApi();
        // } else {
        //     setVideoLink(
        //         `${process.env.REACT_APP_BASE_URL}/api/v1/assets/${asset.uuid}/${asset.main_file_path}?${updatedAt}`
        //     );
        // }
        videoRef.current?.load();
    }, [asset, userAsset]);

    useInterval(
        () => {
            if (
                (remainingTries && userAsset) ||
                (remainingTries && asset.userAsset)
            ) {
                getPreviewFromApi();
            }

            setRemainingTries(remainingTries - 1);
        },
        remainingTries < 1 ? null : 20000
    );

    return (
        <div>
            {/* when you are in the asset screen */}
            {videoAvailable && asset && !asset.userAsset && !userAsset ? (
                <video
                    controls
                    preload={"auto"}
                    style={{ maxWidth: "95%" }}
                    ref={videoRef}
                >
                    {asset.main_file_extension === "mp4" && (
                        <source src={videoLink} type="video/mp4" />
                    )}
                    {trans("Your browser does not support the video element.")}
                </video>
            ) : videoAvailable && userAsset ? (
                <video controls style={{ maxWidth: "95%" }} ref={videoRef}>
                    {/* when you are in the user asset screen and the rendered video is ready */}
                    <source src={`${videoLink}`} type="video/mp4" />
                    {trans("Your browser does not support the video element.")}
                </video>
            ) : asset.userAsset && !videoAvailable && !userAsset ? (
                <p>
                    {/* when you are in the user asset editor without a current configuration */}
                    {trans("Once saved your video will be rendered")}
                </p>
            ) : (
                <p>
                    {/* when you are in the user asset screen and the rendered video is not ready */}
                    <Loading color={theme.asset.preview.color} />
                    {trans("This video may take a few minutes to download")}
                </p>
            )}
        </div>
    );
};

export default EditableVideoPreview;
