import React, { useContext, useReducer } from "react";
import axios from "axios";
import AssetContext from "../AssetContext";
import add from "date-fns/add";

export const LOADED_ITEMS = "LOADED_ITEMS";
export const LOADING_ITEMS = "LOADING_ITEMS";
export const UPDATE_ITEM_STATE = "UPDATE_ITEM_STATE";
export const UPDATE_SHARED_ITEM_STATE = "UPDATE_SHARED_ITEM_STATE";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECT_ALL = "SELECT_ALL";
export const DESELECT_ALL = "DESELECT_ALL";

export const bulkAssetInitialState = {
    loaded: false,
    sharedItem: {
        asset_type: "",
        media_type: null,
        description: "",
        locale: "",
        author: "",
        group_uuids: [],
        country_code: "",
        categories: [],
        tags: [],
        meta_data: [],
        valid_from: new Date(),
        valid_to: add(new Date(), {
            years: 2,
        }),
        available_from: new Date(),
        available_to: add(new Date(), {
            years: 2,
        }),
        status: "draft",
        certification_ref: "",
        certification_awarded_date: "",
        expiration_date: "",
        view_type: "all",
    },
    items: [],
};

const validate = (item, property, value, config) => {
    // console.log(item, property, value, config);
    switch (property) {
        case "asset_type": {
            const assetType = config.assetTypes.find(
                (assetType) => assetType.value === value
            );
            console.log({ assetType });
            return (
                assetType &&
                (assetType.allowed_extensions.includes("*") ||
                    assetType.allowed_extensions.includes(
                        item.main_file_extension
                    ))
            );
        }
        case "media_type": {
            const mediaChannel = config.mediaChannels.find(
                (mediaChannel) => mediaChannel.value === value
            );
            console.log({ mediaChannel });
            return (
                mediaChannel &&
                mediaChannel.asset_types.includes(item.asset_type)
            );
        }
        default:
            return true;
    }
};
// const reducer = (state: AssetDetailType, action: ActionType) => {
const reducer = (state, action) => {
    switch (action.type) {
        case LOADED_ITEMS:
            return {
                ...state,
                loaded: true,
                items: action.items,
            };
        case LOADING_ITEMS:
            return { ...state, ...bulkAssetInitialState };

        case UPDATE_ITEM_STATE:
            return {
                ...state,
                items: state.items.map((item) => {
                    if (
                        item.uuid === action.uuid &&
                        validate(
                            item,
                            action.property,
                            action.value,
                            action.config
                        )
                    ) {
                        return { ...item, [action.property]: action.value };
                    }
                    return item;
                }),
            };
        case UPDATE_SHARED_ITEM_STATE:
            return {
                ...state,
                sharedItem: {
                    ...state.sharedItem,
                    [action.property]: action.value,
                },
            };

        case REMOVE_ITEM:
            return {
                ...state,
                items: state.items.filter((item) => {
                    return item.uuid !== action.uuid;
                }),
            };

        case SELECT_ALL:
            return {
                ...state,
                items: state.items.map((item) => {
                    return { ...item, selected: true };
                }),
            };

        case DESELECT_ALL:
            return {
                ...state,
                items: state.items.map((item) => {
                    return { ...item, selected: false };
                }),
            };
        default:
            return state;
    }
};
export const useBulkAssets = (config = {}) => {
    const [state, dispatch] = useReducer(reducer, bulkAssetInitialState);

    const loadItems = (items) => {
        dispatch({
            type: LOADED_ITEMS,
            items,
        });
    };
    const reset = () => {
        dispatch({
            type: LOADING_ITEMS,
        });
    };
    const updateItems = ({ name: property, value }) => {
        console.log(property, value);
        dispatch({
            type: UPDATE_SHARED_ITEM_STATE,
            property,
            value,
        });
        state.items.forEach((item) => {
            if (item.selected) {
                updateItem(item.uuid, property, value);
            }
        });
    };
    const updateItem = (uuid, property, value) => {
        // console.log(uuid, property, value);
        dispatch({
            type: UPDATE_ITEM_STATE,
            uuid,
            property,
            value,
            config,
        });
    };
    const removeItem = (uuid) => {
        dispatch({
            type: REMOVE_ITEM,
            uuid,
        });
    };
    const selectAll = () => {
        dispatch({
            type: SELECT_ALL,
        });
    };

    const deselectAll = () => {
        dispatch({
            type: DESELECT_ALL,
        });
    };

    return {
        state,
        dispatch,
        loadItems,
        reset,
        updateItems,
        updateItem,
        removeItem,
        selectAll,
        deselectAll,
    };
};
