import React, { useState } from "react";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faChevronDown,
    faTh,
    faFilter,
} from "@fortawesome/free-solid-svg-icons";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    SelectField,
    ResultSummary,
    ContentBlockLoader,
    FilterSummary,
    Tooltip,
    useFilters,
    Row,
    Column,
} from "@cortexglobal/rla-components";

const Wrapper = styled(Row)`
    margin-top: 1rem;
`;
const WrapperColumn = styled(Column)`
    display: flex;
    justify-content: flex-end;
    /* margin: 0.5rem 2rem; */
    align-items: center;
`;
const Title = styled.h4`
    display: inline-block;
    margin: 0 0.5rem 0 0;
`;

const SortSectionWrapper = styled.span`
    display: flex;
    margin-right: 1rem;
    align-items: center;
`;

const RightAlignDiv = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
`;

const ToggleButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.3em;
    padding: 5px;
    color: ${(props) =>
        props.isToggled
            ? props.theme.icon.hover.color
            : props.theme.icon.color};

    &:hover,
    &:focus {
        color: ${(props) => props.theme.icon.hover.color};
    }
`;

const AssetViewOptions = ({
    config,
    setView,
    onOrderByUpdated,
    toggleFolderBrowser,
    showFolderBrowser,
    orderBy,
    view,
    pagination,
    folderName,
    favouriteFolderName,
    assetsShowing,
    setAssetsVisibility,
    loaded,
    selectedFilters: currentlySelectedFilters,
    onUpdate,
    onApply,
    onClear,
    filters,
    searchTerm,
    searchClear,
    filterPosition,
    hideFilterSummary,
    expanded,
}) => {
    const intl = useIntl();
    const [showSummary, setShowSummary] = useState(false);
    const { selectedFilters, filtersAreEmpty, countFilters } = useFilters({
        filters,
        currentlySelectedFilters,
        onUpdate,
        onApply,
        onClear,
    });
    return (
        <>
            <Wrapper>
                <WrapperColumn>
                    {config?.use_folder_browser !== "never" && (
                        <>
                            <Title>
                                {folderName !== ""
                                    ? trans("Assets in {folderName}", {
                                          folderName,
                                      })
                                    : trans("Assets")}
                            </Title>
                        </>
                    )}{" "}
                    {!hideFilterSummary &&
                        filterPosition === "left" &&
                        !filtersAreEmpty(selectedFilters, filters) && (
                            <IconTextButton
                                icon={faFilter}
                                showCircle={false}
                                onClick={() => setShowSummary(!showSummary)}
                                iconSize="12px"
                            >
                                <Tooltip
                                    text={intl.formatMessage({
                                        id: "Applied Filters",
                                    })}
                                    showIcon={false}
                                >
                                    {trans(
                                        "{count, plural, =0 {No filters} one {# applied filter} other {# applied filters}}",
                                        {
                                            count: countFilters(
                                                selectedFilters,
                                                filters
                                            ),
                                        }
                                    )}
                                    &nbsp;
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        rotation={showSummary ? 180 : 0}
                                    />
                                </Tooltip>
                            </IconTextButton>
                        )}
                    {assetsShowing ? (
                        <div
                            style={{ fontSize: "0.9rem", marginTop: "0.2rem" }}
                        >
                            <ContentBlockLoader loaded={loaded}>
                                {trans("Total of {total} Assets", {
                                    total: pagination.total,
                                })}
                            </ContentBlockLoader>
                        </div>
                    ) : (
                        <React.Fragment>
                            <IconTextButton
                                icon={faChevronDown}
                                showCircle={false}
                                onClick={() => setAssetsVisibility(true)}
                                style={{ minWidth: "15rem" }}
                            >
                                <ContentBlockLoader loaded={loaded}>
                                    {trans("Show {from}-{to} of {total}", {
                                        from: pagination.from,
                                        to: pagination.to,
                                        total: pagination.total,
                                    })}
                                </ContentBlockLoader>
                            </IconTextButton>
                        </React.Fragment>
                    )}
                    <RightAlignDiv>
                        {config.options && config.options.sortable_search && (
                            <SortSectionWrapper>
                                <SelectField
                                    value={orderBy}
                                    style={{
                                        margin: "0",
                                        display: "inline-block",
                                    }}
                                    name="order_by"
                                    emptyOption={intl.formatMessage({
                                        id: "Sort by",
                                    })}
                                    options={Object.values(
                                        config.options.sort_default
                                    )}
                                    onChange={({ value }) =>
                                        onOrderByUpdated(value)
                                    }
                                />
                            </SortSectionWrapper>
                        )}

                        <ToggleButton
                            onClick={() => setView("list")}
                            isToggled={view === "list"}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => setView("tile")}
                            isToggled={view === "tile"}
                        >
                            <FontAwesomeIcon icon={faTh} />
                        </ToggleButton>
                    </RightAlignDiv>
                </WrapperColumn>
            </Wrapper>{" "}
            <Collapse isOpened={!hideFilterSummary && showSummary}>
                <FilterSummary
                    selectedFilters={currentlySelectedFilters}
                    onUpdate={onUpdate}
                    onApply={onApply}
                    onClear={onClear}
                    filters={filters}
                    searchTerm={searchTerm}
                    searchClear={searchClear}
                />
            </Collapse>
        </>
    );
};

export default AssetViewOptions;
