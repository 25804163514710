import React, { Component } from "react";
import SearchField from "../components/SearchField";
import { trans } from "@cortexglobal/rla-intl";
import { Row, Column, IconTextButton } from "@cortexglobal/rla-components";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

class AssetSearchFilter extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         keyword: "",
    //         types: []
    //     };
    // }

    // onChange = ({ name, value }) => {
    //     this.setState(
    //         () => {
    //             return { [name]: value };
    //         },
    //         name === "keyword" ? () => null : this.search
    //     );
    // };

    onApply = () => {
        this.props.onApply();
    };

    onSearchUpdate = item => {
        this.props.passToParent({ search: item.value });
    };

    toggleFilterPanel = () => {
        this.props.passToParent({
            filtersShowing: !this.props.filtersShowing
        });
    };

    render() {
        const {
            filtersShowing,
            search,
            showFilterButton = true,
            margin,
            padding = "0.2rem 0 0.2rem 0.2rem"
        } = this.props;
        return (
            <React.Fragment>
                <SearchField
                    padding={padding}
                    margin={margin}
                    onUpdate={this.onSearchUpdate}
                    onApply={this.onApply}
                    search={search}
                />
                {showFilterButton && (
                    <IconTextButton
                        onClick={this.toggleFilterPanel}
                        rotation={filtersShowing ? 270 : 90}
                        showCircle={false}
                        expanded={false}
                        icon={faChevronRight}
                    >
                        <div style={{ fontWeight: "400" }}>
                            {trans("Filters")}
                        </div>
                    </IconTextButton>
                )}
            </React.Fragment>
        );
    }
}

export default AssetSearchFilter;
