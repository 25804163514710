import axios from "axios";
import storage from "local-storage-fallback";
import uuid from "uuid/v4";

import { unLoadPanel } from "./panelActions";

export const LOAD_DASHBOARD = "LOAD_DASHBOARD";
export const LOADING_DASHBOARD = "LOADING_DASHBOARD";
export const STORE_ORIGINAL_DASHBOARD = "STORE_ORIGINAL_DASHBOARD";

export const loadDashboard = (slug) => {
    const dashboardFromStorage = storage.getItem(`${slug}_dashboard`);

    if (dashboardFromStorage) {
        const dashboardData = JSON.parse(dashboardFromStorage);
        return (dispatch) => {
            //Load the dashboard from localStorage for perceived performance
            dispatch({
                type: LOAD_DASHBOARD,
                slug,
                loaded: true,
                loading: false,
                uuid: dashboardData.uuid,
                name: dashboardData.name,
                scope: dashboardData.scope,
                items: dashboardData.items,
                layouts: dashboardData.layouts,
                role: dashboardData.role,
            });
            //Always request the dashboard from the server to get the latest saved version
            dispatch(loadDashboardFromServer(slug));
        };
    }

    return loadDashboardFromServer(slug);
};

export const swapDashboard = (slug, dashboardData) => {
    return (dispatch) => {
        //Update localStorage with the data from the server
        dispatch({
            type: LOAD_DASHBOARD,
            slug,
            loaded: true,
            loading: false,
            uuid: dashboardData.uuid,
            name: dashboardData.name,
            scope: dashboardData.scope,
            items: dashboardData.items,
            layouts: dashboardData.layouts,
            role: dashboardData.role,
        });
    };
};

const loadDashboardFromServer = (slug) => {
    return (dispatch) => {
        dispatch({
            type: LOADING_DASHBOARD,
            slug,
        });

        return axios.get(`/api/v1/user-dashboard/${slug}`).then(({ data }) => {
            const dashboardData = data.data;
            dispatch({
                type: LOAD_DASHBOARD,
                slug,
                loaded: true,
                loading: false,
                uuid: dashboardData.uuid,
                name: dashboardData.name,
                scope: dashboardData.scope,
                items: dashboardData.items,
                layouts: dashboardData.layouts,
                role: dashboardData.role,
            });
            dispatch({
                type: STORE_ORIGINAL_DASHBOARD,
                slug,
                data: dashboardData,
            });
            //Update localStorage with the data from the server
            storage.setItem(`${slug}_dashboard`, JSON.stringify(dashboardData));
        });
    };
};
export const LOAD_DASHBOARD_PANELS = "LOAD_DASHBOARD_PANELS";

export const loadPanels = (slug) => {
    const panelsFromStorage = storage.getItem(`${slug}_panels`);

    if (panelsFromStorage) {
        const items = JSON.parse(panelsFromStorage);
        return (dispatch) => {
            dispatch({
                type: LOAD_DASHBOARD_PANELS,
                slug,
                loaded: true,
                items,
            });
        };
    }

    return (dispatch) => {
        axios
            .get(`/api/v1/dashboard/${slug}/panels`)
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_DASHBOARD_PANELS,
                    slug,
                    loaded: true,
                    items: data,
                });
            })
            .catch((e) => e);
    };
};

export const CHOOSE_PANEL = "CHOOSE_PANEL";

export const choosePanel = (slug, panel) => {
    return (dispatch) => {
        dispatch({
            type: CHOOSE_PANEL,
            slug,
            panel,
        });
    };
};

export const ADD_PANEL = "ADD_PANEL";

export const addPanel = (slug, panel, panelProps, cols) => {
    return (dispatch, getStore) => {
        panel.props = panelProps;
        panel.key = uuid();

        console.log("Adding panel", slug, panel, cols);

        dispatch({
            type: ADD_PANEL,
            slug,
            panel,
            cols,
        });

        dispatch(choosePanel(slug, null));
    };
};
export const UPDATE_PANEL = "UPDATE_PANEL";

export const updatePanel = (slug, panel, panelProps, panelId) => {
    console.log("Updating", panel);
    return (dispatch, getStore) => {
        panel.props = panelProps;
        panel.key = panelId;
        dispatch({
            type: UPDATE_PANEL,
            slug,
            panel,
        });
        dispatch(storeDashboard(slug, getStore().dashboards[slug]));
        dispatch(choosePanel(slug, null));
        //Unset any panel data
        dispatch(unLoadPanel(panelId));
    };
};
export const DELETE_PANEL = "DELETE_PANEL";

export const deletePanel = (slug, panel) => {
    return (dispatch, getStore) => {
        panel.key = panel.key === undefined ? panel.props.panelId : panel.key;
        dispatch({
            type: DELETE_PANEL,
            slug,
            panel,
        });
        dispatch(storeDashboard(slug, getStore().dashboards[slug]));
        dispatch(choosePanel(slug, null));
    };
};

export const DASHBOARD_EDITED = "DASHBOARD_EDITED";

export const storeDashboard = (slug, dashboardData) => {
    //console.log(layout);
    storage.setItem(`${slug}_dashboard`, JSON.stringify(dashboardData));

    return (dispatch) => {
        dispatch({
            type: LOAD_DASHBOARD,
            slug,
            loaded: true,
            uuid: dashboardData.uuid,
            name: dashboardData.name,
            scope: dashboardData.scope,
            items: dashboardData.items,
            layouts: dashboardData.layouts,
            role: dashboardData.role,
        });
        dispatch({
            type: DASHBOARD_EDITED,
            slug,
            edited: true,
        });
    };
};

export const SAVING_DASHBOARD = "SAVING_DASHBOARD";

export const saveDashboardToServer = (slug) => {
    return (dispatch) => {
        const dashboardFromStorage = storage.getItem(`${slug}_dashboard`);
        if (dashboardFromStorage) {
            const dashboardData = JSON.parse(dashboardFromStorage);

            dispatch({
                type: SAVING_DASHBOARD,
                saving: true,
            });
            axios
                .put(
                    `/api/v1/user-dashboard/${dashboardData.uuid}`,
                    dashboardData
                )
                .then((response) => {
                    dispatch({
                        type: SAVING_DASHBOARD,
                        saving: false,
                    });
                    dispatch({
                        type: DASHBOARD_EDITED,
                        slug,
                        edited: false,
                    });
                })
                .catch((e) => e);
        } else {
            dispatch({ type: "NONE" });
        }
    };
};
