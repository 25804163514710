import React from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";

const IEMinHeightFix = styled.div`
    display: flex;
    /* min-height: 100%; */
`;
const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
`;

const IEWarning = styled.div`
    background: ${props => props.theme.menu.notification};
    color: ${props => props.theme.menu.notificationColor};
    padding: 1em;
    text-align: center;
`;

const SiteWrapper = ({ children, ...rest }) => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    return (
        <IEMinHeightFix>
            <PageWrapper {...rest}>
                {isIE &&
                    <IEWarning>
                        {trans('We no longer support Internet Explorer, please upgrade to a ')}
                        <a href="https://www.google.com/chrome/" target="_blank">{trans('modern browser')}</a>
                        {trans(' for a better user experience.')}
                    </IEWarning>
                }
                {children}
            </PageWrapper>
        </IEMinHeightFix>
    );
};

export default SiteWrapper;
