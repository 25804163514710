import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// @ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import Row from "../../row";
import Column from "../../column";
import Button from "../../buttons/Button";
import { renderStandReturnInput } from "../renderInputs";

const FlexRow = styled(Row)`
    display: flex;
    align-items: center;
`;

const StyledColumn = styled(Column)`
    ${({ width }) =>
        width &&
        `
        width: ${width};
    `}
`;

const DeleteButton = styled(Button)`
    margin: 0 0 1.2rem 0;
    padding: 0.5rem 1.1rem;
`;

const RepeaterField = ({
    types: typesProp,
    values,
    onChanges,
    noPadding,
    children,
    addRowText,
    ...rest
}) => {
    const [firstInputId] = useState(uuid());
    const [types, setTypes] = useState([]);

    useEffect(() => {
        // If the types are missing aliases copy the name as the alias
        // this supports legacy uses, but doesn't handle i18n
        setTypes(
            typesProp.map((type) => ({
                ...type,
                alias: type.alias ? type.alias : type.name,
            }))
        );
    }, [typesProp]);
    const columnWidth = 12 / types.length;
    const emptyRow = types.reduce((accumulator, currentValue) => {
        return {
            ...accumulator,
            [currentValue.name]: "",
        };
    }, {});

    const handleOnDelete = (index) => {
        const newValues = [...values];
        newValues.splice(index, 1);
        onChanges(newValues);
    };

    const handleOnAdd = () => {
        // If no values passed in, when add is pressed, two rows need to be passed up
        onChanges(values.length ? [...values, emptyRow] : [emptyRow, emptyRow]);
        focusFirstInput();
    };

    const handleOnChange = (value, index) => {
        let newValues;

        if (values.length) {
            newValues = values.map((val, i) => {
                if (i === index) {
                    const newVal = { ...val };
                    //@ts-ignore
                    newVal[value.name] = value.value;
                    return newVal;
                }
                return val;
            });
        } else {
            // If no values passed in, create new empty row with the new value
            newValues = [
                {
                    ...emptyRow,
                    [value.name]: value.value,
                },
            ];
        }

        onChanges(newValues);
    };

    const focusFirstInput = () => {
        setTimeout(() => {
            document.getElementById(firstInputId).focus();
        });
    };

    const renderRow = (value, index) => {
        return (
            <FlexRow style={{ padding: "0" }} key={index}>
                <Row collapse style={{ flexGrow: 1 }}>
                    {types.map(({ type, name, alias, width }, index2) => {
                        //@ts-ignore
                        const val = value[alias];
                        const { options } = types.filter(
                            ({ alias: typeAlias }) => typeAlias === alias
                        )[0];
                        return (
                            <StyledColumn
                                width={width}
                                style={index2 === 0 ? { paddingLeft: "0" } : {}}
                                key={alias}
                                medium={columnWidth}
                            >
                                {renderStandReturnInput(
                                    {
                                        type: type,
                                        name: alias,
                                        options: options,
                                        // text: name,
                                        id:
                                            index === 0 && index2 === 0
                                                ? firstInputId
                                                : undefined,
                                    },
                                    val,
                                    (value) => handleOnChange(value, index)
                                )}
                            </StyledColumn>
                        );
                    })}
                </Row>
                <div>
                    <DeleteButton
                        disabled={!values || values.length <= 1}
                        onClick={() => {
                            handleOnDelete(index);
                        }}
                        //@ts-ignore
                        type="alert"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </DeleteButton>
                </div>
                {React.Children.length && (
                    <Column medium={1}>
                        <div
                            style={{
                                marginBottom: "2.2rem",
                                marginLeft: "1rem",
                            }}
                        >
                            {/* @ts-ignore cortex */}
                            {React.Children.map(children, (child) => {
                                if (React.isValidElement(child)) {
                                    return React.cloneElement(child, {
                                        /* @ts-ignore cortex */
                                        index: index,
                                    });
                                }

                                return child;
                            })}
                        </div>
                    </Column>
                )}
            </FlexRow>
        );
    };

    return (
        <>
            <FlexRow {...rest} style={{ padding: "0" }}>
                <Row collapse style={{ flexGrow: 1, padding: "0" }}>
                    {types.map((type, index) => (
                        <StyledColumn
                            width={type.width}
                            style={
                                index === 0
                                    ? {
                                          paddingLeft: "0",
                                      }
                                    : {}
                            }
                            key={type.name}
                            medium={columnWidth}
                        >
                            {type.name}
                        </StyledColumn>
                    ))}
                </Row>
                {/* @ts-ignore render a hidden button to ensure alignment */}
                <div
                    style={{
                        visibility: "hidden",
                        maxHeight: "0",
                        padding: "0 0.4rem",
                        overflow: "hidden",
                    }}
                >
                    <DeleteButton onClick={() => {}}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            style={{ visibility: "hidden", height: "0" }}
                        />
                    </DeleteButton>
                </div>{" "}
                {/* @ts-ignore render hidden children to ensure alignment */}
                {React.Children.length && (
                    <Column
                        medium={1}
                        style={{
                            visibility: "hidden",
                            maxHeight: "0",
                            padding: "0 0.6rem",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                marginLeft: "1rem",
                            }}
                        >
                            {React.Children.map(children, (child) => {
                                if (React.isValidElement(child)) {
                                    return React.cloneElement(child, {
                                        /* @ts-ignore cortex */
                                        index: index,
                                    });
                                }

                                return child;
                            })}
                        </div>
                    </Column>
                )}
            </FlexRow>
            {values.length > 0 ? values.map(renderRow) : renderRow(emptyRow, 0)}
            {/* @ts-ignore cortex */}
            <Row collapse style={{ marginBottom: "1rem" }}>
                <Column collapse>
                    <Button onClick={handleOnAdd}>
                        {addRowText ?? trans("Add row")}
                    </Button>
                </Column>
            </Row>
        </>
    );
};

export default RepeaterField;

//TypeScript VERSION BELOW.  CAUSED LOTS OF ISSUES.  TODO - FIX IT

// import React, { useState } from "react";
// import styled from "styled-components";
// import uuid from "uuid/v4";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";
// // @ts-ignore cortex
// import { trans } from "@cortexglobal/rla-intl";

// import Row from "../../row";
// import Column from "../../column";
// import Button from "../../buttons/Button";
// import { renderStandReturnInput } from "../renderInputs";

// // @ts-ignore cortex
// const FlexRow = styled(Row)`
//     display: flex;
//     align-items: center;
// `;

// const StyledColumn = styled(Column)<StyledColumnProps>`
//     ${({ width }) =>
//         width &&
//         `
//         width: ${width};
//     `}
// `;

// const DeleteButton = styled(Button)`
//     margin: 0 0 1.2rem 0;
//     padding: 0.5rem 1.1rem;
// `;

// const RepeaterField = ({
//     types,
//     values,
//     onChanges,
//     noPadding,
//     children,
//     ...rest
// }: RepeaterFieldProps) => {
//     const columnWidth = 8 / types.length;

//     const [firstInputId] = useState(uuid());

//     const emptyRow = types.reduce((accumulator, currentValue) => {
//         return {
//             ...accumulator,
//             [currentValue.name]: "",
//         };
//     }, {});

//     const handleOnDelete = (index: number) => {
//         const newValues = [...values];
//         newValues.splice(index, 1);
//         onChanges(newValues);
//     };

//     const handleOnAdd = () => {
//         // If no values passed in, when add is pressed, two rows need to be passed up
//         onChanges(values.length ? [...values, emptyRow] : [emptyRow, emptyRow]);
//     };

//     const handleOnChange = (value: InputValue, index: number) => {
//         let newValues;

//         if (values.length) {
//             newValues = values.map((val, i) => {
//                 if (i === index) {
//                     const newVal = { ...val };
//                     //@ts-ignore
//                     newVal[value.name] = value.value;
//                     return newVal;
//                 }
//                 return val;
//             });
//         } else {
//             // If no values passed in, create new empty row with the new value
//             newValues = [
//                 {
//                     ...emptyRow,
//                     [value.name]: value.value,
//                 },
//             ];
//         }

//         onChanges(newValues);
//     };

//     const focusFirstInput = () => {
//         setTimeout(() => {
//             document.getElementById(firstInputId).focus();
//         });
//     };

//     return (
//         <>
//             <Row {...rest} style={{ padding: "0.6rem 0 0 0" }}>
//                 {types.map((type, index) => (
//                     <StyledColumn
//                         width={type.width}
//                         style={
//                             index === 0
//                                 ? { paddingLeft: "0", marginBottom: "0.5rem" }
//                                 : { marginBottom: "0.5rem" }
//                         }
//                         key={type.name}
//                         medium={columnWidth}
//                     >
//                         {type.name}
//                     </StyledColumn>
//                 ))}
//             </Row>
//             {values.map((value, index) => (
//                 <Row style={{ paddingLeft: "0" }} key={index}>
//                     {types.map(({ type, name, width }, index2) => {
//                         //@ts-ignore
//                         const val = value[name];
//                         const { options } = types.filter(
//                             ({ name: typeName }) => typeName === name
//                         )[0];
//                         return (
//                             <StyledColumn
//                                 width={width}
//                                 style={index2 === 0 ? { paddingLeft: "0" } : {}}
//                                 key={name}
//                                 medium={columnWidth}
//                             >
//                                 {renderStandReturnInput(
//                                     {
//                                         type: type,
//                                         name: name,
//                                         options: options,
//                                         text: "",
//                                         id:
//                                             index === 0 && index2 === 0
//                                                 ? firstInputId
//                                                 : undefined,
//                                     },
//                                     val,
//                                     (value: InputValue) =>
//                                         handleOnChange(value, index)
//                                 )}
//                             </StyledColumn>
//                         );
//                     })}
//                     <Column medium={2}>
//                         {values.length > 1 && (
//                             <DeleteButton
//                                 onClick={() => {
//                                     handleOnDelete(index);
//                                 }}
//                                 //@ts-ignore
//                                 type="alert"
//                             >
//                                 <FontAwesomeIcon icon={faTrash} />
//                             </DeleteButton>
//                         )}
//                     </Column>
//                     <Column medium={1}>
//                         <div
//                             style={{
//                                 marginBottom: "2.2rem",
//                                 marginLeft: "1rem",
//                             }}
//                         >
//                             {/* @ts-ignore cortex */}
//                             {React.Children.map(children, (child) => {
//                                 if (React.isValidElement(child)) {
//                                     return React.cloneElement(child, {
//                                         /* @ts-ignore cortex */
//                                         index: index,
//                                     });
//                                 }

//                                 return child;
//                             })}
//                         </div>
//                     </Column>
//                 </Row>
//             ))}

//             {!values.length && (
//                 <Row style={{ paddingLeft: "0" }}>
//                     {types.map(({ type, name, width }, index) => {
//                         const { options } = types.filter(
//                             ({ name: typeName }) => typeName === name
//                         )[0];
//                         return (
//                             <StyledColumn
//                                 width={width}
//                                 style={index === 0 ? { paddingLeft: "0" } : {}}
//                                 key={name}
//                                 medium={columnWidth}
//                             >
//                                 {renderStandReturnInput(
//                                     {
//                                         type: type,
//                                         name: name,
//                                         options: options,
//                                         text: "",
//                                     },
//                                     "",
//                                     (value: InputValue) => {
//                                         handleOnChange(value, 0);
//                                         focusFirstInput();
//                                     }
//                                 )}
//                             </StyledColumn>
//                         );
//                     })}
//                     <Column medium={2} />
//                     <Column medium={1}>
//                         <div
//                             style={{
//                                 marginBottom: "2.2rem",
//                                 marginLeft: "1rem",
//                             }}
//                         >
//                             {/* @ts-ignore cortex */}
//                             {React.Children.map(children, (child) => {
//                                 if (React.isValidElement(child)) {
//                                     return React.cloneElement(child, {
//                                         /* @ts-ignore cortex */
//                                         index: 0,
//                                     });
//                                 }

//                                 return child;
//                             })}
//                         </div>
//                     </Column>
//                 </Row>
//             )}
//             {/* @ts-ignore cortex */}
//             <Row>
//                 <Column>
//                     <Button onClick={handleOnAdd}>{trans("Add row")}</Button>
//                 </Column>
//             </Row>
//         </>
//     );
// };

// type FieldType = "text" | "number" | "select";
// type InputType = "string" | "number" | { text: string; value: string };
// type InputValue = { name: string; value: InputType };

// /**
//  * Type of repeat field type that's passed in an array.
//  * Options is only used when type is select
//  */
// type RepeatField = {
//     name: string;
//     type: FieldType;
//     options?: { text: string; value: string }[];
//     width?: string;
// };

// interface StyledColumnProps {
//     width?: string;
// }

// /**
//  * Types must be an array of types structured above, component does not work with an empty array
//  * Values can be JSON matching the types or an empty array
//  */
// interface RepeaterFieldProps {
//     types: RepeatField[];
//     values: Object[];
//     onChanges: (value: any) => void;
//     noPadding?: boolean;
//     children?: React.ReactNode[];
// }

// export default RepeaterField;
