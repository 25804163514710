import React, { useState } from "react";
import { trans } from "@cortexglobal/rla-intl";
import {
    FormikInput,
    FormRow,
    Row,
    Column,
    Button,
} from "@cortexglobal/rla-components";

const NewMetricsForm = ({ task, values, errors }) => {
    const [metrics, setMetrics] = useState([
        {
            alias: "sales_uplift",
            name: "Expected Sales Uplift",
            unit: "sales",
            target: null,
        },
        {
            alias: "leads_generated",
            name: "Expected Leads Generated",
            unit: "leads",
            target: null,
        },
    ]);

    const addMetric = () => {
        setMetrics([
            ...metrics,
            {
                alias: "",
                name: "",
                unit: "",
                target: null,
            },
        ]);
    };

    const removeMetric = (key) => {
        setMetrics(
            metrics.filter((value, index) => {
                return index !== key;
            })
        );
    };

    return (
        <>
            <h2>{trans("Success Criteria")}</h2>

            <p>
                {trans(
                    "Define the metrics on which the success of this campaign will be measured."
                )}
            </p>

            <>
                {metrics.map((metric, key) => {
                    return (
                        <>
                            <Row collapse>
                                <Column collapse small={4}>
                                    <FormRow label="Name">
                                        <FormikInput
                                            name="metric_name"
                                            value={metric.name}
                                        />
                                    </FormRow>
                                </Column>

                                <Column collapse small={4}>
                                    <FormRow label="Target">
                                        <FormikInput
                                            name="metric_target"
                                            value={metric.target}
                                        />
                                    </FormRow>
                                </Column>

                                <Column collapse small={3}>
                                    <FormRow label="Unit">
                                        <FormikInput
                                            name="metric_unit"
                                            value={metric.unit}
                                        />
                                    </FormRow>
                                </Column>

                                <Column collapse small={1}>
                                    <Button onClick={() => removeMetric(key)}>
                                        -
                                    </Button>
                                </Column>
                            </Row>
                        </>
                    );
                })}
            </>
            <Button onClick={() => addMetric()}>+</Button>
        </>
    );
};

export default NewMetricsForm;
