const checkItemIsInArray = (item, valueArray) => {
    return (
        valueArray
            .map(value =>
                typeof value === "string" ? value.toLowerCase() : value
            )
            .indexOf(typeof item === "string" ? item.toLowerCase() : item) > -1
    );
};
export default {
    "===": (a, b) => {
        //If both arguments are arrays check if they have a shared item
        if (Array.isArray(a) && Array.isArray(b)) {
            for (var i = 0; i < a.length; i++) {
                for (var j = 0; j < b.length; j++) {
                    if (b[j] === a[i]) {
                        return true;
                    }
                }
            }
            return false;
        }
        //If the selected value is an array but the choice isn't, then check if the array has the value in it
        if (Array.isArray(a)) {
            return checkItemIsInArray(b, a);
        }
        //If the choices are an array but the selected value isn't, then check if the value is in the array
        if (Array.isArray(b)) {
            return checkItemIsInArray(a, b);
        }
        return a === b;
    },
    "!==": (a, b) => {
        return a !== b;
    },
    "<": (a, b) => {
        return a < b;
    },
    "<=": (a, b) => {
        return a <= b;
    },
    ">": (a, b) => {
        return a > b;
    },
    ">=": (a, b) => {
        return a >= b;
    }
};
