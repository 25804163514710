import React from "react";
import styled from "styled-components";
import { FormikErrors } from "formik";

import { ValidationError } from "../../../index";
import Column from "../../column";

const StyledColumn = styled(Column)`
    margin-top: ${(props) => (props.singleColumn ? "-1rem" : "0")};
    margin-bottom: ${(props) => (props.singleColumn ? "1rem" : "0")};
`;

type LabelProps = {
    error?: any;
    columns?: number;
    collapse?: boolean;
    singleColumn?: boolean;
};
export const ErrorColumn = ({
    error,
    columns = 3,
    collapse,
    singleColumn = false,
}: LabelProps) => {
    return (
        <StyledColumn
            singleColumn={singleColumn}
            medium={columns}
            collapse={collapse}
        >
            <ValidationError>{error}</ValidationError>
        </StyledColumn>
    );
};
