import React from "react";
import styled from "styled-components";
import { Row, Column, Table } from "@cortexglobal/rla-components";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";
import InventoryBasket from "../InventoryBasket";

const BudgetLabel = styled.span`
    margin-right: 0.5rem;
`;

const SummaryRow = styled.div`
    display: flex;
`;
const BudgetSummary = ({ event, settings, showingInSummary = false }) => {
    const { user } = useUser();
    const { excludedOptionalFields, excludedOptionalSections } = settings;
    console.log("event", event);
    const showActualFigures =
        event?.workflowable?.state.special_type === "complete";

    return (
        <>
            <Row collapse>
                <Table
                    tableType="overview"
                    zebraStripe="true"
                    style={{ padding: "0 0.5rem 0.5rem" }}
                >
                    <tbody>
                        {/* <tr>
                            <td width="40%">{trans("Budget Total")}:</td>
                            <td>
                                <FormattedCurrency
                                    value={event.total_budget}
                                    currency={user?.currency?.iso_code || "GBP"}
                                />
                            </td>
                        </tr> */}
                        <tr>
                            <td width="40%">{trans("Venue Total")}:</td>
                            <td>
                                <SummaryRow>
                                    <BudgetLabel>
                                        {trans("Estimated")}
                                        {": "}
                                    </BudgetLabel>
                                    <FormattedCurrency
                                        value={event.venue_cost}
                                        currency={
                                            user?.currency?.iso_code || "GBP"
                                        }
                                    />
                                    {showActualFigures && (
                                        <>
                                            <BudgetLabel>
                                                {trans("Actual")}
                                                {": "}
                                            </BudgetLabel>

                                            <FormattedCurrency
                                                value={event.actual_venue_cost}
                                                currency={
                                                    user?.currency?.iso_code ||
                                                    "GBP"
                                                }
                                            />
                                        </>
                                    )}
                                </SummaryRow>
                            </td>
                        </tr>
                        <tr>
                            <td>{trans("Equipment Total")}:</td>
                            <td>
                                <SummaryRow>
                                    <BudgetLabel>
                                        {trans("Estimated")}
                                        {": "}
                                    </BudgetLabel>
                                    <FormattedCurrency
                                        value={event.total_equipment_cost}
                                        currency={
                                            user?.currency?.iso_code || "GBP"
                                        }
                                    />
                                    {showActualFigures && (
                                        <>
                                            <BudgetLabel>
                                                {trans("Actual")}
                                                {": "}
                                            </BudgetLabel>
                                            <FormattedCurrency
                                                value={
                                                    event.actual_total_equipment_cost
                                                }
                                                currency={
                                                    user?.currency?.iso_code ||
                                                    "GBP"
                                                }
                                            />
                                        </>
                                    )}
                                </SummaryRow>
                            </td>
                        </tr>
                        <tr>
                            <td>{trans("Overall Total")}:</td>
                            <td>
                                <SummaryRow>
                                    <BudgetLabel>
                                        {trans("Estimated")}
                                        {": "}
                                    </BudgetLabel>

                                    <FormattedCurrency
                                        value={event.total_cost}
                                        currency={
                                            user?.currency?.iso_code || "GBP"
                                        }
                                    />
                                    {event?.request?.quotation && (
                                        <>
                                            <BudgetLabel>
                                                {trans("Quoted")}
                                                {": "}
                                            </BudgetLabel>
                                            {}
                                            <FormattedCurrency
                                                value={
                                                    event?.request?.quotation
                                                        ?.value
                                                }
                                                currency={
                                                    user?.currency?.iso_code ||
                                                    "GBP"
                                                }
                                            />
                                        </>
                                    )}
                                    {showActualFigures && (
                                        <>
                                            <BudgetLabel>
                                                {trans("Actual")}
                                                {": "}
                                            </BudgetLabel>
                                            <FormattedCurrency
                                                value={event.actual_total_cost}
                                                currency={
                                                    user?.currency?.iso_code ||
                                                    "GBP"
                                                }
                                            />
                                        </>
                                    )}
                                </SummaryRow>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row collapse>
                <Column medium={6}>
                    {!excludedOptionalFields.includes("budget_confirmed") && (
                        <Row>
                            <Column medium={10}>
                                {trans("Budget signed off?")}:
                            </Column>
                            <Column medium={2}>
                                {event.budget_confirmed ? "Yes" : "No"}
                            </Column>
                        </Row>
                    )}
                    {!excludedOptionalSections.includes("contracts") && (
                        <>
                            <Row>
                                <Column medium={10}>
                                    {trans("Has the contract been issued?")}:
                                </Column>
                                <Column medium={2}>
                                    {event.contract_issued ? "Yes" : "No"}
                                </Column>
                            </Row>
                            <Row>
                                <Column medium={10}>
                                    {trans("Has the contract been signed-off?")}
                                    :
                                </Column>
                                <Column medium={2}>
                                    {event.contract_signed_off ? "Yes" : "No"}
                                </Column>
                            </Row>
                        </>
                    )}
                </Column>
            </Row>

            <Row>
                <Column collapse>
                    <h3>{trans("Equipment Breakdown")}</h3>
                    <InventoryBasket
                        itemBookingList={event.bookingItems}
                        // editBookingItem={editBookingItem}
                        // removeBookingItem={removeBookingItem}
                        editable={false}
                        showImage={true}
                        includeActualSpend={showActualFigures}
                        showingInSummary={showingInSummary}
                        settings={settings}
                    />
                </Column>
            </Row>
        </>
    );
};

export default BudgetSummary;
