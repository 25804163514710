import { useState } from "react";

export const useSectionErrors = (
    initialErrors: string[],
    sections: { [key: string]: string[] } = {
        general: ["title"],
        availability: ["view_type", "available_from", "available_to"],
    }
): {
    sectionErrors: string[];
    updateSectionErrors: (errors: any) => string[];
} => {
    const [sectionErrors, setSectionErrors] = useState(initialErrors);

    const updateSectionErrors = (errors: { [key: string]: any }) => {
        // setSectionErrors([]);

        let newSectionErrors = [];

        for (const sectionKey in sections) {
            for (const errorKey in errors) {
                //@ts-ignore cortex
                if (sections[sectionKey].indexOf(errorKey) !== -1) {
                    newSectionErrors.push(sectionKey);
                    break;
                }
            }
        }

        setSectionErrors(newSectionErrors);
        return newSectionErrors;
    };

    return { sectionErrors, updateSectionErrors };
};
