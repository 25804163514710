import React, { useContext, useEffect } from "react";

import { trans } from "@cortexglobal/rla-intl";
import InventoryItemForm from "./InventoryItemForm";
import {
    IconTextButton,
    PageTitle,
    Panel,
    Row,
} from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const CreateInventoryItem = ({ path }) => {
    return (
        <>
            <PageTitle title="New Inventory Item">
                <IconTextButton
                    onClick={() => {}}
                    as={Link}
                    to={`${path}`}
                    showCircle={false}
                    icon={faChevronLeft}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Panel>
                    <InventoryItemForm path={path} />
                </Panel>
            </Row>
        </>
    );
};

export default CreateInventoryItem;
