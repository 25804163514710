import React from "react";
import styled from "styled-components";

const EmptyTR = styled.tr`
    td {
        text-align: center;
    }
`;
const EmptyCell = styled.td<{ colSpan: string | number }>``;
export const EmptyRow = ({
    colSpan = "100%",
    children
}: {
    colSpan?: string | number;
    children: React.ReactNode;
}) => {
    return (
        <EmptyTR>
            {/*@ts-ignore cortex */}
            <EmptyCell colSpan={colSpan}>{children}</EmptyCell>
        </EmptyTR>
    );
};
