import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
    Row,
    Column,
    Loading,
    useAddToast,
    Button,
    Table,
    IconTextButton,
    Tooltip,
    CsvDownload,
} from "@cortexglobal/rla-components";
import { trans, FormattedDate } from "@cortexglobal/rla-intl";
import AssetDetailsPreviewPanel from "../../assets/components/AssetDetailsPreviewPanel";
import { useDownloadUserAsset } from "../../userAssets/hooks/useDownloadUserAsset";

const SectionHeader = styled.h3`
    font-size: 1.2rem;
    margin: 2.5rem 0 0.2rem 0.6rem;
`;
export const RecipientDetails = ({ order }) => {
    const [recipients, setRecipients] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getRecipients(order.uuid);
    }, []);

    const getRecipients = (uuid) => {
        return axios
            .get(`/api/v1/orders/${uuid}/recipients`)
            .then(({ data }) => {
                console.log(data);
                setLoaded(true);
                setRecipients(data);
                return data;
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error getting order."),
                    showFor: 5000,
                });
                setLoaded(true);
            });
    };

    if (!loaded) {
        return <Loading />;
    }

    return (
        <Row>
            <Column>
                <SectionHeader>{trans("Recipient Details")}</SectionHeader>
                <Table>
                    <tbody>
                        <tr>
                            <td width="30%">
                                <strong>{trans("Recipients")}</strong>
                            </td>
                            <td>
                                <CsvDownload data={recipients}>
                                    <Button
                                        onClick={() => {}}
                                        style={{
                                            textDecoration: "none!important",
                                        }}
                                    >
                                        {trans("Download")}
                                    </Button>
                                </CsvDownload>
                            </td>
                        </tr>

                        {order.email_details && (
                            <>
                                <tr>
                                    <td width="30%">
                                        <strong>
                                            {trans("Seed Recipients")}
                                        </strong>
                                    </td>
                                    <td>
                                        {Array.isArray(
                                            order.email_details.seed_recipients
                                        )
                                            ? order.email_details.seed_recipients.map(
                                                  (recipient) => (
                                                      <div>{recipient}</div>
                                                  )
                                              )
                                            : order.email_details
                                                  .seed_recipients}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%">
                                        <strong>
                                            {trans("Test Recipients")}
                                        </strong>
                                    </td>
                                    <td>
                                        {Array.isArray(
                                            order.email_details.test_recipients
                                        )
                                            ? order.email_details.test_recipients.map(
                                                  (recipient) => (
                                                      <div>{recipient}</div>
                                                  )
                                              )
                                            : order.email_details
                                                  .test_recipients}
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </Column>
        </Row>
    );
};

export default RecipientDetails;
