import React from "react";
import styled from "styled-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import { trans } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    InputField,
    HelpText,
    FormLabel,
    IconTextButton,
} from "@cortexglobal/rla-components";
import InputType from "./InputType";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const NameEmphasis = styled.em`
    font-weight: 300;
`;

const AdminSettingInputGroup = ({
    path,
    tag,
    onChange,
    chooseImage,
    asset,
    baseAsset,
}) => {
    const href = tag.href ? tag.href : "";
    const { can } = usePermissions();

    return (
        <>
            {(!tag.admin || (tag.admin && can("dam-microsite-admin"))) && (
                <React.Fragment key={tag.name}>
                    <Row>
                        <Column medium={3} style={{ textAlign: "right" }}>
                            {tag.type != "hidden" && (
                                <>
                                    <FormLabel name="name">
                                        {tag.label}
                                    </FormLabel>
                                    <HelpText>
                                        {tag.helpText ? tag.helpText : ""}
                                    </HelpText>
                                </>
                            )}
                        </Column>
                        <Column medium={6}>
                            <InputType
                                maxLength={tag.characters}
                                tag={tag}
                                path={path}
                                asset={asset}
                                baseAsset={baseAsset}
                                onChange={onChange}
                                chooseImage={chooseImage}
                            />

                            {tag.name === "backgroundImage" && tag.value && (
                                <IconTextButton
                                    style={{ margin: "1em 0" }}
                                    onClick={() =>
                                        onChange("value", path, {
                                            name: "backgroundImage",
                                            value: "",
                                        })
                                    }
                                    showCircle={false}
                                    icon={faTrashAlt}
                                >
                                    {trans("Delete background")}
                                </IconTextButton>
                            )}
                        </Column>
                        <Column medium={3}>
                            {/* <ValidationError>{errors.name}</ValidationError> */}
                        </Column>
                    </Row>
                    <Row style={{ margin: "1.5rem 0" }}>
                        {tag.type === "link" && (
                            <Column>
                                <InputField
                                    label={
                                        <span>
                                            {trans("Link / URL for ")}{" "}
                                            <NameEmphasis>
                                                {tag.name}
                                            </NameEmphasis>
                                        </span>
                                    }
                                    name={tag.name}
                                    value={href}
                                    onChange={(event) =>
                                        onChange("href", path, event)
                                    }
                                    placeholder={tag.name}
                                />
                            </Column>
                        )}
                    </Row>
                </React.Fragment>
            )}
        </>
    );
};

export default AdminSettingInputGroup;
