import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import * as QueryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";

import {
    Column,
    Loading,
    Row,
    InputField,
    FilterColumn,
    IconTextButton,
    Pagination,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import InventoryResultItem from "./InventoryResultItem";

const SearchWrapper = styled.div`
    /* width: 100%; */
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    input {
        margin: 0;
        min-width: 100%;
    }
`;
const StyledInputField = styled(InputField)`
    margin: 0;
    flex: 1;
    min-width: 150px;
`;
const InventoryBrowser = ({
    date_from,
    date_to,
    event,
    settings,
    onItemClick,
    selectedItems = [],
}) => {
    const intl = useIntl();
    const queryString = QueryString.parse(location.search);
    const { can } = usePermissions();
    const [selectedFilters, setSelectedFilters] = useState(
        queryString.filters ? JSON.parse(queryString.filters) : []
    );
    // const [shownItems, setShownItems] = useState([]);
    const [inventoryItems, setInventoryItems] = useState([]);
    const [inventoryItemsLoaded, setInventoryItemsLoaded] = useState(false);
    // const [unlockedItems, setUnlockedItems] = useState([]);
    const [basketItems, setBasketItems] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        per_page: 20,
        total: 0,
    });

    useEffect(() => {
        getInventoryItems();
        // }, [event, selectedFilters, searchText]);
    }, [event]);

    const getInventoryItems = (page = 1, perPage) => {
        const { inventory_category_uuid } = selectedFilters;

        const per_page = perPage ? perPage : pagination.per_page;

        axios
            .post(`/api/v1/events/inventory-item`, {
                ...pagination,
                date_from: date_from,
                date_to: date_to,
                inventory_category_uuid,
                search: searchText,
                page,
                per_page,
            })
            //Had to use post due to date issues with get
            // .get(`/api/v1/events/inventory-item`, {
            //     params: {
            //         date_from: date_from,
            //         date_to: date_to,
            //         inventory_category_uuid,
            //         search: searchText,
            //     },
            // })
            .then((response) => {
                setInventoryItems(response.data.data);
                setInventoryItemsLoaded(true);
                setPagination(response.data.meta);
            });
    };

    const onPerPageChange = ({ value }) => {
        const per_page = parseInt(value);
        setPagination({ ...pagination, per_page });
        getInventoryItems(1, per_page);
    };

    const onUpdate = (selectedFilters) => {
        setSelectedFilters(selectedFilters);
    };

    const onApply = (selectedFilters) => {
        // console.log("Apply Filters", selectedFilters);
        // setSelectedFilters(selectedFilters);
        //Call the endpoint to get the filtered items
        getInventoryItems();
    };

    const onClear = () => {
        setSelectedFilters([]);
        //Call the endpoint to get the filtered items
        getInventoryItems();
    };

    const preppedFilters = settings.inventoryCategories
        ? [
              {
                  label: "Category",
                  options: settings.inventoryCategories,
                  comparison: "===",
                  extra: { limit: 40 },
                  name: "inventory_category_uuid",
                  type: "MultiCheckBox",
              },
          ]
        : [];

    return (
        <Row
            collapse
            style={{
                display: "flex",
                flexGrow: "1",
            }}
        >
            <FilterColumn
                medium={3}
                // large={theme.body.expanded ? 2 : 3}
                // filterColumnCollapse={filterColumnCollapse}
                // categoryTotals={categoryTotals}
                filters={preppedFilters}
                selectedFilters={selectedFilters}
                onUpdate={onUpdate}
                onApply={onApply}
                onClear={onClear}
                // hideClear={activityUuid !== ""}
                // searchTerm={currentSearchTerm}
                // searchClear={searchClear}
                // toggleFavorites={toggleFavorites}
                // favorites={favorites}
                // user={user}
                searchInput={
                    <Column style={{ marginTop: "1rem" }}>
                        <h4
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                            }}
                        >
                            {trans("Search")}
                        </h4>
                        <SearchWrapper>
                            <StyledInputField
                                name="search"
                                inlineLabel={false}
                                onChange={(e) => setSearchText(e.value)}
                                placeholder={intl.formatMessage({
                                    id: "Search",
                                })}
                                disabled={!inventoryItemsLoaded}
                            />
                            <IconTextButton
                                padding={"0.2rem 0 0.2rem 0.2rem"}
                                onClick={() => getInventoryItems()}
                                showCircle={false}
                                expanded={false}
                                icon={faSearch}
                                title={intl.formatMessage({ id: "Search" })}
                            ></IconTextButton>
                        </SearchWrapper>
                    </Column>
                }
                filterAreaSlug="events"
                // savedFilter={savedFilter}
                // config={config}
            />

            <Column medium={9}>
                {!inventoryItemsLoaded ? (
                    <Loading />
                ) : (
                    inventoryItems.map((item) => {
                        return (
                            <Column medium={4} large={3} key={item.uuid}>
                                <InventoryResultItem
                                    key={item.uuid}
                                    item={item}
                                    onItemClick={onItemClick}
                                    alreadySelected={selectedItems.some(
                                        (selectedItem) =>
                                            selectedItem.uuid === item.uuid
                                    )}
                                />
                            </Column>
                        );
                    })
                )}

                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => {
                        // history.push(`?page=${selected}&search=${search}`);
                        getInventoryItems(selected + 1);
                    }}
                    {...pagination}
                />
            </Column>
        </Row>
    );
};

export default InventoryBrowser;
