import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { FormikErrors } from "formik";
import { Row, Column } from "../../../index";

import { LabelColumn } from "./LabelColumn";
import { ErrorColumn } from "./ErrorColumn";

type FormRowProps = {
    name: string;
    // label: string | { [key: string]: any };
    // helpText?: string | { [key: string]: any };
    label: any;
    helpText?: any;
    tooltip?: any;
    error?: string | FormikErrors<Date>;
    required?: boolean;
    singleColumn?: boolean;
    collapse?: boolean;
};

export const FormRow: FunctionComponent<FormRowProps> = ({
    children,
    name,
    label,
    helpText,
    tooltip,
    error,
    required = false,
    singleColumn = false,
    collapse = false,
}) => {
    return (
        <>
            {/** @ts-ignore cortex */}
            <Row
                style={{
                    display: singleColumn ? "flex" : "block",
                    flexDirection: "column",
                }}
                collapse={collapse}
            >
                <LabelColumn
                    name={name}
                    label={label}
                    helpText={helpText}
                    tooltip={tooltip}
                    required={required}
                    columns={singleColumn ? 12 : 3}
                    textAlign={singleColumn ? "left" : "right"}
                    collapse={collapse}
                />
                <Column medium={singleColumn ? 12 : 6} collapse={collapse}>
                    {children}
                </Column>
                <ErrorColumn
                    columns={singleColumn ? 12 : 3}
                    error={error}
                    collapse={collapse}
                    singleColumn={singleColumn}
                />
            </Row>
        </>
    );
};

export default FormRow;
