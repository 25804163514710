import React, { useState } from "react";
import AnimateHeight from "react-animate-height";

import { trans } from "@cortexglobal/rla-intl";
import { Row, Column, TableList, Button } from "@cortexglobal/rla-components";

const SurveyRangeResult = ({ result, expanded }) => {
    return (
        <AnimateHeight
            height={expanded ? "auto" : 0}
            style={{ marginBottom: "1rem" }}
        >
            <TableList>
                <thead>
                    <tr>
                        <th>{trans("Answer")}</th>
                        <th>{trans("Responses")}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(result.answers).map(([key, value]) => (
                        <tr key={`${key}-${value}`}>
                            <td>{key}</td>
                            <td>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </TableList>
        </AnimateHeight>
    );
};

export default SurveyRangeResult;
