export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_TOOLTIP = "SHOW_TOOLTIP";
export const HIDE_TOOLTIP = "HIDE_TOOLTIP";

export function showModal(modalName = "") {
    return {
        type: SHOW_MODAL,
        modalName: modalName
    };
}

export function hideModal() {
    return {
        type: HIDE_MODAL
    };
}

export function showTooltip() {
    return {
        type: SHOW_TOOLTIP
    };
}

export function hideTooltip() {
    return {
        type: HIDE_TOOLTIP
    };
}
