import React, { useEffect, useState } from "react";
import { withRouter, Switch, Route } from "react-router-dom";

import { useIntl } from "@cortexglobal/rla-intl";

import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import { ListUserCampaigns } from "./screens/ListUserCampaigns";
import { ViewUserCampaign } from "./screens/ViewUserCampaign";

const UserCampaignRouting = ({
    title = "",
    match: { path },
    history,
    assetsPath = "/admin/assets",
}) => {
    const intl = useIntl();

    const { can } = usePermissions();

    //Selected Campaign is used to pass the campaign object to the view screen
    //This is to allow for the basic campaign data to be loaded straight away on the view screen
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const mainTitle =
        title === ""
            ? intl.formatMessage({
                  id: "Campaigns",
              })
            : title;
    const viewGroupCampaign = (event, campaign, groupCampaign) => {
        console.log("viewGroupCampaign", groupCampaign);
        setSelectedCampaign(groupCampaign);
        //Campaign can be set to null to clear the selected groupCampaign, so only
        //push to the history if the groupCampaign is not null
        if (groupCampaign && groupCampaign.uuid) {
            history.push(`${path}/view/${groupCampaign.uuid}`);
        }
    };
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return (
                        <ListUserCampaigns
                            basePath={`${path}`}
                            mainTitle={mainTitle}
                            viewGroupCampaign={viewGroupCampaign}
                            can={can}
                        />
                    );
                }}
            />
            <Route
                exact
                path={`${path}/view/:campaignUuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { campaignUuid },
                        },
                    } = props;

                    return (
                        <ViewUserCampaign
                            selectedCampaign={selectedCampaign}
                            campaignUuid={campaignUuid}
                            basePath={`${path}/`}
                            mainTitle={mainTitle}
                            can={can}
                            assetsPath={assetsPath}
                        />
                    );
                }}
            />
        </Switch>
    );
};

export default withRouter(UserCampaignRouting);
