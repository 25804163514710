import React from "react";
import { Loading, Error } from "@cortexglobal/rla-components";
import UserTable from "./UserTable";

const UserList = props => {
    const {
        users = [],
        loadingState = "loading",
        updateUsers = {},
        showEdit = {}
    } = props;

    return (
        <>
            {loadingState === "loading" && <Loading />}

            {loadingState === "loaded" && (
                <UserTable
                    users={users}
                    updateUsers={updateUsers}
                    showEdit={showEdit}
                />
            )}

            {loadingState === "error" && <Error />}
        </>
    );
};

export default UserList;
