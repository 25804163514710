import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import StyledRadio from "../styledElements/styledRadio";

const Container = styled.div`
    /* margin-bottom: ${(props) => props.theme.spacing.margin - 0.2}em; */
    margin-bottom: 1.4rem;
`;

const RadioField = ({
    options,
    input,
    value,
    type,
    name,
    onChange,
    label,
    readOnly,
    error,
    inlineRadioButtons,
    ...rest
}) => {
    const handleChange = (event) => {
        onChange(
            {
                name: name,
                value: event.target.value,
            },
            event
        );
    };
    return (
        <div>
            {label && (
                <FormLabel
                    name={name}
                    label={label}
                    {...rest}
                    marginBottom={1.2}
                >
                    {label}
                </FormLabel>
            )}
            <Container>
                {options &&
                    options.map((radio, index) => (
                        <StyledRadio
                            key={index}
                            inlineRadioButtons={inlineRadioButtons}
                            type="radio"
                            name={name}
                            {...rest}
                            text={radio.text}
                            value={radio.value}
                            onChange={handleChange}
                            checked={radio.checked || radio.value === value}
                            {...radio}
                        />
                    ))}
            </Container>
            <InputError error={error} />
        </div>
    );
};

RadioField.displayName = "RadioField";

RadioField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired,
        })
    ),
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    inlineRadioButtons: PropTypes.bool,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.string,
};
RadioField.defaultProps = {
    size: "default",
    expanded: false,
    inlineLabel: true,
    inlineRadioButtons: false,
    type: "text",
    readOnly: false,
    error: "",
};
export default RadioField;
