import React, { useState, useContext } from "react";
import axios from "axios";
import { Modal, Row, Column, Button } from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { useDispatch } from 'react-redux'
import FavouriteFolderBrowser from "./FavouriteFolderBrowser";
import { UPDATE_ASSET_FAVORITES } from "../assetActions";

const MoveFavouriteModal = ({ asset, visible, onClose }) => {
    const intl = useIntl();
    const [folder, setFolder] = useState(null);
    const dispatch = useDispatch()

    const moveAssetToFolder = () => {

        axios
            .put(`api/v1/assets/${asset.uuid}/favourite/folder`, {
                folder_uuid: folder ? folder.uuid : ""
            })
            .then(response => {
        
                const uuids = [asset.uuid];
            
                dispatch({
                    type: UPDATE_ASSET_FAVORITES,
                    data: { uuids, favorite: true, loaded: true }
                });
            
                onClose();
            })
            .catch(() => {});
    };
    return (
        <Modal visible={visible} onClose={onClose} zIndex={0} maxWidth="90%">
            <Row>
                <Column>
                    <h3>{trans("Move Asset")}</h3>
                </Column>
            </Row>
            <Row>
                <Column>
                    <FavouriteFolderBrowser
                        folderUuid={folder ? folder.uuid : ""}
                        showFolderBrowser={true}
                        onFolderUpdated={setFolder}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Button onClick={moveAssetToFolder}>
                        {folder && folder.name
                            ? trans('Move to  "{folderName}"', {
                                  folderName: folder.name
                              })
                            : trans("Move to the top level")}
                    </Button>
                </Column>
            </Row>
        </Modal>
    );
};

export default MoveFavouriteModal;
