import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Panel,
    CheckboxTree,
    Loading,
    Error,
    ConditionalWrapper,
} from "@cortexglobal/rla-components";

import { SectionHeading } from "./components/SectionHeading";

const CategoryStructure = ({
    asset,
    standardOnChange,
    errors,
    preLoadedCategories = null,
    showSectionHeading = true,
    singleColumn = false,
}) => {
    const [categoryHierarchy, setCategoryHierarchy] = useState({
        loaded: false,
        data: null,
    });
    const [error, setError] = useState("");

    useEffect(() => {
        if (preLoadedCategories) {
            setCategoryHierarchy({ loaded: true, data: preLoadedCategories });
        } else {
            axios
                .get(`/api/v1/admin/asset-hierarchy`)
                .then(({ data }) => {
                    setCategoryHierarchy({ loaded: true, data: data.data });
                })

                .catch((e) => {
                    //  console.log(e.response.data);

                    if (
                        e.response &&
                        e.response.status &&
                        e.response.status === 400
                    ) {
                        setError(e.response.data.message);
                    }
                });
        }
    }, []);

    //
    const onUpdate = (input) => {
        const { name, value } = input;
        // setSelected(value);
        standardOnChange(input);
    };

    if (error !== "") {
        return <Error>{error}</Error>;
    }

    return (
        <React.Fragment>
            {showSectionHeading && (
                <SectionHeading>{trans("Category Hierarchy")}</SectionHeading>
            )}

            <ConditionalWrapper
                condition={!singleColumn}
                wrapper={(children) => <Panel>{children}</Panel>}
            >
                <Row>
                    <Column>
                        {categoryHierarchy.loaded ? (
                            <CheckboxTree
                                name="category-hierarchy"
                                options={
                                    categoryHierarchy.data
                                        ? categoryHierarchy.data.children
                                        : []
                                }
                                onChange={onUpdate}
                                value={asset["category-hierarchy"]}
                                selectParents={true}
                                selectOnlyLeafNodes={true}
                                error={errors["category-hierarchy"]}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Column>
                </Row>
            </ConditionalWrapper>
        </React.Fragment>
    );
};

export default CategoryStructure;
