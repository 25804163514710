import { useEffect } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { Pages, MicrositeConfig } from "../interfaces/microsite";
import { Nav, Link } from "../interfaces/navigation";
import { useSlideshowNavigation } from "./useSlideshowNavigation";

/**
 * This function sets up the navigation menus for the site
 * @param nav
 * @param pages
 * @param basePath
 */
export const processLinks = (nav: Nav, pages: Pages, basePath: string) => {
    //Loop through the links and remove any internal ones that don't have relevant pages
    type PageOptionArray = {
        value: string;
        text: string;
        hidden: boolean;
    }[];

    let newNav: Nav = cloneDeep(nav);
    const pageOptions = pages.reduce(
        (pageOptionArray: PageOptionArray, [pageName, pageSetup]) => {
            const pageOption = {
                ...pageSetup,
                value: `/${pageName}`,
                text: pageSetup.name,
                hidden: pageSetup.hidden ? pageSetup.hidden : false
            };
            pageOptionArray.push(pageOption);
            return pageOptionArray;
        },
        []
    );

    const menus = Object.keys(nav);

    menus.forEach(menu => {
        newNav[menu] = nav[menu].filter(menuItem => {
            const internal =
                typeof menuItem.internal !== "undefined"
                    ? menuItem.internal
                    : true;
            const page = pageOptions.find(page => {
                // console.log(page.value, menuItem.url);
                return page.value === menuItem.url;
            });

            //If the link is internal but there's no page for it remove it
            return !(internal && ((page && page.hidden) || !page));
        });
    });

    return newNav;
};

export const useNavigation = (
    config: MicrositeConfig,
    baseUrl: string,
    showPage: string,
    editing: boolean,
    footnotesShowing: boolean,
) => {
    const history = useHistory();
    //Setup the links/menus
    const links = processLinks(config.nav, config.pages, baseUrl);

    const { state, dispatch } = useSlideshowNavigation(
        config,
        baseUrl,
        editing,
        footnotesShowing
    );

    useEffect(() => {
        if (showPage !== "") {
            history.replace(`${baseUrl}/${showPage}`);
        }
    }, []);

    return { links, state, dispatch };
};
