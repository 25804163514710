import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    Loading,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import CommunicationRuleForm from "../../forms/CommunicationRuleForm";
import CommunicationRuleAdmin from "./CommunicationRuleAdmin";
import { isEmpty } from "lodash";

export const CommunicationRuleCreate = ({ path, smartRef = null }) => {
    const history = useHistory();
    const intl = useIntl();

    const [smartRefName, setSmartRefName] = useState("");
    const [reference, setReference] = useState({});
    const [loading, setLoading] = useState(true);

    const resolveSmartRef = () => {
        return axios
            .get(`/api/v1/admin/messenger-rules/reference/${smartRef}`)
            .then(({ data }) => {
                setSmartRefName(data.data?.name || "");
                setReference(data.data || {});
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (smartRef !== null) {
            resolveSmartRef();
        } else {
            setLoading(false);
        }
    }, []);

    const submitForm = (values) => {
        return axios
            .post(`/api/v1/admin/messenger-rules`, values)
            .then(({ data }) => {
                //As creation was successful move them on to the appropriate listing page
                history.push(`${path}/edit/${data.data.uuid}`);
            });
    };

    const pageTitleName = !isEmpty(reference)
        ? trans("New Communication Rule for: {name}", reference)
        : trans("New Communication Rule");

    return (
        <div>
            <PageTitle title={pageTitleName}>
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel style={{ paddingBottom: "0" }}>
                    {loading === false ? (
                        <CommunicationRuleForm
                            submitForm={submitForm}
                            smartRef={smartRef}
                            reference={reference}
                        />
                    ) : (
                        <Loading />
                    )}
                </Panel>
            </Row>
        </div>
    );
};

export default CommunicationRuleCreate;
