import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ProductListing from "./ProductListing";
import ProductCreate from "./ProductCreate";
import { ProductEdit } from "./ProductEdit";

export const ProductAdmin = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <ProductListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <ProductCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <ProductEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default ProductAdmin;
