import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, Loading } from "@cortexglobal/rla-components";
import {
    mediaSearch,
    mediaClearResults,
    getMediaOptions,
    mediaSave,
    folderSave,
    uploadFile,
    uploadStarted,
    getFolders,
    updateExpandList,
    setCurrentMedia,
    setCurrentFolder,
    getTopFolders
} from "../mediaLibraryActions";

import Search from "../MediaSearch";
import SearchResults from "./GallerySearchResults";
import GalleryItemForm from "./GalleryItemForm";

import Folders from "../../folders/admin/Folders";
import FolderForm from "../../folders/admin/FolderForm";

class GalleryAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showResults: false,
            showFolders: false,
            showSearchForm: true,
            showFolderForm: false,
            showForm: false,
            folderData: {},
            options: {},
            lastViewState: {}
        };
    }

    componentWillMount() {
        this.props.getMediaOptions("media");
    }

    editFile = data => {
        let lastViewState = this.getVisibleValues(this.state);
        let item = this.resetVisible();
        this.setState({
            ...item,
            showForm: true,
            lastViewState: lastViewState
        });
        this.props.setCurrentMedia(data);
    };

    search = values => {
        let item = this.resetVisible();
        this.setState({ ...item, showResults: true, showSearchForm: true });
        this.props.mediaClearResults();
        this.props.mediaSearch(values);
    };

    close = () => {
        let item = this.getVisibleValues(this.state.lastViewState);
        this.setState(item);
    };

    save = input => {
        this.props.mediaSave(input);
        this.close();
    };

    createNew = () => {
        const lastViewState = this.getVisibleValues(this.state);
        let item = this.resetVisible();
        this.props.setCurrentMedia({});
        this.setState({
            ...item,
            showForm: true,
            lastViewState: lastViewState
        });
    };

    uploadFile = (files, item) => {
        this.props.uploadStarted();
        this.props.uploadFile(files[0], item.uuid);
    };

    showFolders = () => {
        let item = this.resetVisible();
        this.setState({ ...item, showFolders: true, showSearchForm: true });
    };

    editFolder = data => {
        const lastViewState = this.getVisibleValues(this.state);
        let item = this.resetVisible();
        this.setState({
            ...item,
            showFolderForm: true,
            folderData: data,
            lastViewState: lastViewState
        });
    };

    createNewFolder = () => {
        let lastViewState = this.getVisibleValues(this.state);
        let item = this.resetVisible();
        this.setState({
            ...item,
            showFolderForm: true,
            folderData: {},
            lastViewState: lastViewState
        });
    };

    resetVisible() {
        return {
            showResults: false,
            showForm: false,
            showFolders: false,
            showFolderForm: false,
            showSearchForm: false
        };
    }

    getVisibleValues(location) {
        const values = {
            showResults: location.showResults,
            showForm: location.showForm,
            showFolders: location.showFolders,
            showFolderForm: location.showFolderForm,
            showSearchForm: location.showSearchForm
        };
        return values;
    }

    getFolders = uuid => {
        const f = this.props.mediaLibrary.folders;
        if (!f[uuid]) {
            this.props.getFolders(uuid);
        } else {
            this.props.setCurrentFolder(f[uuid]);
        }
    };
    getTopFolders = () => {
        this.props.getTopFolders();
    };

    getFolderContents = folder => {
        this.props.getFolders(uuid);
    };

    folderSave = input => {
        this.props.folderSave(input);
        this.close();
    };

    updateExpandList = item => {
        if (item.uuid) {
            let list = this.props.mediaLibrary.expandList;
            let index = list.indexOf(item.uuid);
            if (index < 0) {
                list.push(item.uuid);
                this.props.updateExpandList(list);
            } else {
                list.splice(index, 1);
                this.props.updateExpandList(list);
            }
        }
    };

    render() {
        const { mediaLibrary, current } = this.props;
        return (
            <Panel>
                {this.state.showSearchForm && (
                    <Search
                        search={this.search}
                        placeholder="Search by name or slug"
                        fetchAll={true}
                        showFolders={this.showFolders}
                    />
                )}
                {this.state.showResults && mediaLibrary.searching === false && (
                    <SearchResults
                        results={mediaLibrary.results}
                        edit={this.editFile}
                        createNew={this.createNew}
                    />
                )}

                {this.state.showForm === true && (
                    <GalleryItemForm
                        library={mediaLibrary}
                        data={current}
                        close={this.close}
                        save={this.save}
                        uploadFile={this.uploadFile}
                    />
                )}

                {this.state.showFolders && (
                    <Folders
                        library={mediaLibrary}
                        getFolders={this.getFolders}
                        editFile={this.editFile}
                        editFolder={this.editFolder}
                        createNew={this.createNew}
                        updateExpandList={this.updateExpandList}
                        createNewFolder={this.createNewFolder}
                        getTopFolders={this.getTopFolders}
                    />
                )}

                {this.state.showFolderForm === true && (
                    <FolderForm
                        options={mediaLibrary.options}
                        currentFolder={mediaLibrary.currentFolder}
                        data={this.state.folderData}
                        close={this.close}
                        save={this.folderSave}
                        getFolders={this.getFolders}
                    />
                )}

                {this.props.mediaLibrary.searching === true && <Loading />}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    let { mediaLibrary } = state;
    let { current } = state.mediaLibrary;
    return {
        current,
        mediaLibrary
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            mediaSearch,
            mediaClearResults,
            getMediaOptions,
            mediaSave,
            folderSave,
            uploadFile,
            uploadStarted,
            getFolders,
            updateExpandList,
            setCurrentMedia,
            setCurrentFolder,
            getTopFolders
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryAdmin);
