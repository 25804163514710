import React, { useEffect, useState } from "react";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { Select, FormRow } from "@cortexglobal/rla-components";

import GroupContainerForm from "../../forms/groupTypeForms/GroupContainerForm";
import MerchantForm from "../../forms/groupTypeForms/MerchantForm";
import OfficeForm from "../../forms/groupTypeForms/OfficeForm";
import SupplierForm from "../../forms/groupTypeForms/SupplierForm";

export const GroupFormSelector = ({
    group,
    submitForm,
    canFullGroupAdmin = true, //This will be false if coming from the group profile
}: {
    group: any;
    submitForm: any;
    canFullGroupAdmin?: boolean;
}) => {
    // let type = group.groupable_type;
    const [groupableType, setGroupableType] = useState("");

    const groupTypes = [
        { value: "GroupContainer", text: "Group Container" },
        { value: "Merchant", text: "Merchant" },
        { value: "Office", text: "Office" },
        { value: "Supplier", text: "Supplier" },
    ];

    useEffect(() => {
        setGroupableType(group.groupable_type);
    }, []);

    const changeGroupType = (input) => {
        const { value } = input;

        setGroupableType(value);
    };

    const groupTypeForm = (type, group) => {
        //The entity is a morph relationship so load it via a Switch
        switch (type) {
            case "GroupContainer":
                return (
                    <GroupContainerForm
                        item={group}
                        submitForm={submitForm}
                        canFullGroupAdmin={canFullGroupAdmin}
                    />
                );

            case "Merchant":
                return (
                    <MerchantForm
                        item={group}
                        submitForm={submitForm}
                        canFullGroupAdmin={canFullGroupAdmin}
                    />
                );

            case "Office":
                return (
                    <OfficeForm
                        item={group}
                        submitForm={submitForm}
                        canFullGroupAdmin={canFullGroupAdmin}
                    />
                );

            case "Supplier":
                return (
                    <SupplierForm
                        item={group}
                        submitForm={submitForm}
                        canFullGroupAdmin={canFullGroupAdmin}
                    />
                );

            default:
                return trans("Unrecognized group type");
        }
    };

    return (
        <>
            {canFullGroupAdmin && (
                <FormRow name="type" label={trans("Group Type")}>
                    <Select
                        name="type"
                        value={groupableType}
                        showError={false}
                        options={groupTypes}
                        onChange={changeGroupType}
                    />
                </FormRow>
            )}

            {groupTypeForm(groupableType, group)}
        </>
    );
};

export default GroupFormSelector;
