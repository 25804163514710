import React from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { ContentBlockLoader } from "../../index";
const ResultSummary = ({ from, to, total, loaded, textAlign = "right" }) => {
    if (
        typeof from === "undefined" ||
        typeof to === "undefined" ||
        typeof total === "undefined" ||
        total === 0
    ) {
        return null;
    }
    return (
        <React.Fragment>
            <div
                style={{
                    textAlign: textAlign,
                    padding: "0 0.6rem",
                    minWidth: "15rem"
                }}
            >
                <ContentBlockLoader loaded={loaded}>
                    {trans("Currently showing {from}-{to} of {total}", {
                        from,
                        to,
                        total
                    })}
                </ContentBlockLoader>
            </div>
        </React.Fragment>
    );
};

export default ResultSummary;
