import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Row,
    Column,
    Panel,
    Image,
    IconTextButton,
    LoadingIconSmall,
    Badge,
    FileIcon,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    SearchResultSummary,
    ShowMore,
    Tooltip,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import UserAssetContext from "../UserAssetContext";
import { faThList } from "@fortawesome/free-solid-svg-icons";

const BulkIcon = styled.div`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 2;
    pointer-events: all;
`;
const UserAssetResultItem = (props) => {
    const {
        loaded,
        asset,
        canEdit,
        match: { path },
        onClick = null,
        ...rest
    } = props;

    const { assetsPath } = useContext(UserAssetContext);
    const getImageSrc = () => {
        let imageSrc = `${asset.thumbnail}`;
        // console.log(imageSrc);
        //TODO - Work out how to reimplement this to have dynamic thumbnails
        // if (asset.asset_type === "inDesign") {
        //     imageSrc = `${process.env.REACT_APP_IN_DESIGN_URL}/output/${asset.uuid}/${asset.uuid}.png?${asset.updated_at}`;
        // }
        return imageSrc;
    };

    const viewAsset = (event, asset) => {
        event.preventDefault();
        if (!loaded) {
            return false;
        }
        props.selectAsset(asset);
        props.history.push(`${assetsPath}/view/${asset.uuid}`);
    };

    //Let a passed in click handler override the viewAsset action
    const onResultClick = onClick ? onClick : viewAsset;

    return (
        <Column
            small={6}
            medium={4}
            large={4}
            xlarge={3}
            style={{ marginBottom: "1rem", height: "340px" }}
            {...rest}
        >
            <SearchResultWrapper
                textAlign="center"
                padding={0}
                style={{ position: "relative" }}
            >
                {asset.bulk_edit && (
                    <BulkIcon>
                        <Tooltip text={trans("Bulk editable")} showIcon={false}>
                            <FontAwesomeIcon icon={faThList} />
                        </Tooltip>
                    </BulkIcon>
                )}
                <Link
                    style={{
                        cursor: "pointer",
                        position: "relative",
                        textDecoration: "none",
                    }}
                    onClick={(event) => onResultClick(event, asset)}
                >
                    <SearchResultImageWrapper>
                        {loaded ? (
                            asset.thumbnail === null ? (
                                <FileIcon
                                    colorBehind={props.theme.colors.gray200}
                                >
                                    {asset.main_file_extension}
                                </FileIcon>
                            ) : (
                                <SearchResultImage
                                    src={getImageSrc()}
                                    alt={asset.name}
                                />
                            )
                        ) : (
                            <LoadingIconSmall></LoadingIconSmall>
                        )}
                    </SearchResultImageWrapper>
                </Link>

                <SearchResultButton
                    style={{ paddingTop: "1em" }}
                    onClick={(event) => onResultClick(event, asset)}
                    iconBefore={false}
                    doHover={false}
                    showCircle={props.theme.search.icons.showCircle}
                    icon={props.theme.search.icons.searchIcon}
                    iconColor={props.theme.search.icons.color}
                    iconSize={props.theme.search.icons.size}
                    expanded={true}
                >
                    <SearchResultTitle
                        title={asset.name}
                        width={
                            props.theme.search.icons.searchIcon !== null
                                ? "90%"
                                : "100%"
                        }
                    >
                        <ShowMore
                            style={{ lineBreak: "anywhere" }}
                            type={"tooltip"}
                            lines={2}
                        >
                            {loaded && asset.name}
                        </ShowMore>
                    </SearchResultTitle>
                    <SearchResultSummary title={asset["media_type_name"]}>
                        {loaded && trans("Type : ")}{" "}
                        {loaded && asset["media_type_name"]}
                    </SearchResultSummary>
                </SearchResultButton>
            </SearchResultWrapper>
        </Column>
    );
};
export default withTheme(withRouter(UserAssetResultItem));
