import React from "react";
import styled from "styled-components";
import { PlainButton } from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faFolder,
    faFolderOpen
} from "@fortawesome/free-solid-svg-icons";

const FolderBreadcrumbButton = styled(PlainButton)``;
const FolderIcon = styled(FontAwesomeIcon)`
    margin: 0 0.5rem;
`;
const ArrowIcon = styled(FontAwesomeIcon)`
    margin: 0 0.5rem 0 0.5rem;
    opacity: 0.5;
`;
export const FolderBreadcrumb = ({
    folder,
    isCurrent = false,
    onFolderUpdated
}) => {
    return (
        <FolderBreadcrumbButton
            onClick={() => onFolderUpdated(folder, "parent")}
        >
            <FolderIcon
                icon={isCurrent ? faFolderOpen : faFolder}
                transform="grow-6"
            />
            {folder.name ? folder.name : folder.text}
            {!isCurrent && <ArrowIcon icon={faAngleRight} transform="grow-2" />}
        </FolderBreadcrumbButton>
    );
};
