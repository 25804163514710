import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons/faCircle";
// import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { isDarkColor, adjustLightness } from "@cortexglobal/cortex-utilities";
import Button from "./Button";
import styled, { useTheme } from "styled-components";

const BaseIconTextButton = styled(
    React.forwardRef(
        (
            {
                rotation,
                $showCircle,
                $expanded,
                $iconBefore,
                $themeColor,
                $doHover,
                children,
                $iconVerticalAlignment,
                $wrapContent,
                ...rest
            },
            ref
        ) => (
            <Button ref={ref} {...rest}>
                {children}
            </Button>
        )
    )
)`
    display: inline-flex;
    align-items: ${({ $iconVerticalAlignment }) => $iconVerticalAlignment};
    flex-direction: ${({ $iconBefore }) =>
        $iconBefore ? "row" : "row-reverse"};
    justify-content: ${({ $iconBefore }) =>
        $iconBefore ? "flex-start" : "space-between"};
    width: ${({ $expanded }) => ($expanded ? "100%" : "auto")};

    text-decoration: ${({ textDecoration }) => textDecoration};
    transition: color 200ms,
        transform ${({ theme }) => theme.animations.presets.mediumBounce};
    padding: ${({ padding }) => padding};
    color: ${({ $themeColor, theme }) =>
        $themeColor
            ? theme.colors[$themeColor]
            : theme.button.types.iconText.foregroundColor};
    white-space: ${({ $wrapContent }) => ($wrapContent ? "wrap" : "nowrap")};
    min-height: ${({ minHeight, theme }) =>
        minHeight || theme.button.minHeight};

    .children,
    .fa-layers {
        transform: scale(1) translate(0%, 0%);
        transition: transform
            ${({ theme }) => theme.animations.presets.mediumBounce};
    }
    .fa-layers {
        transform: scale(${({ $doHover }) => ($doHover ? 1.1 : 1)})
            translate(0%, 0%)
            rotate(${({ rotation }) => (rotation ? rotation : 0)}deg);
    }

    .fa-layers svg.icon {
        left: ${({ $showCircle }) => ($showCircle ? "1px" : 0)};
    }

    &:hover,
    :focus {
        cursor: pointer;
        color: ${({ $themeColor, theme }) => {
            if (!$themeColor) {
                return theme.button.hoverColor;
            }
            const color = theme.colors[$themeColor]
                ? theme.colors[$themeColor]
                : theme.colors.black;
            const finalColor = isDarkColor(color)
                ? adjustLightness(color, 28)
                : adjustLightness(color, -18);
            return finalColor;
        }};
        .children {
            transform: translate(
                ${({ $iconBefore }) => ($iconBefore ? 1 : 0)}%,
                0%
            );
        }
        .fa-layers {
            transform: scale(${({ $showCircle }) => ($showCircle ? 1 : 1.2)})
                translate(
                    ${({ $showCircle, $iconBefore }) =>
                        $showCircle && $iconBefore ? -3 : 2}%,
                    0%
                )
                rotate(${({ rotation }) => (rotation ? rotation : 0)}deg);
        }
    }

    &:focus {
        box-shadow: none;
        /* text-shadow: 1px 1px 2px ${({ $themeColor, theme }) => {
            const color = theme.colors[$themeColor]
                ? theme.colors[$themeColor]
                : theme.colors.black;
            const finalColor = isDarkColor(color)
                ? adjustLightness(color, 28)
                : adjustLightness(color, -18);
            return finalColor;
        }}; */
    }
`;
const IconWrapper = styled.span`
    margin: ${({ $iconBefore }) =>
        $iconBefore ? "0 0.2rem 0 0.3rem " : "0 0.2rem 0 0.3rem"};
    color: ${({ iconColor, theme }) =>
        iconColor ? iconColor : theme.button.types.iconText.iconColor};
    font-size: ${({ iconSize }) => iconSize};
    text-align: left;
    position: relative;
    pointer-events: none;
`;

const ChildWrapper = styled.div`
    padding: 0 0 0 ${({ $iconBefore }) => ($iconBefore === false ? 0 : 0.5)}rem;
    /* font-size: ${({ fontSize }) => fontSize}; */
    font-weight: ${({ theme, fontWeight }) =>
        fontWeight ? fontWeight : theme.button.fontWeight};
    display: inline-block;
    width: ${({ icon }) =>
        icon !== null && icon !== undefined ? "90%" : "100%"};
    white-space: break-spaces;
    transform: scale(1) translate(0%, 0%);
    transition: transform
        ${({ theme }) => theme.animations.presets.mediumBounce};
`;
// const IconTextButton = ({
//     icon,
//     circleIcon,
//     expanded,
//     showCircle,
//     children,
//     rotation,
//     themeColor,
//     iconOffset,
//     fontSize,
//     ...props
// }) => {
const IconTextButton = React.forwardRef(
    (
        {
            icon,
            circleIcon,
            expanded,
            showCircle,
            iconBefore,
            children,
            rotation,
            themeColor,
            iconOffset,
            iconColor,
            iconSize,
            doHover,
            fontSize,
            wrapContent,
            iconVerticalAlignment,
            ...props
        },
        ref
    ) => {
        // console.log("icon", icon);
        const theme = useTheme();
        return (
            <BaseIconTextButton
                rotation={rotation}
                $showCircle={showCircle}
                $expanded={expanded}
                $themeColor={themeColor}
                $doHover={doHover}
                $wrapContent={wrapContent}
                $iconVerticalAlignment={iconVerticalAlignment}
                $iconBefore={iconBefore}
                {...props}
                ref={ref}
                type="iconText"
            >
                {icon && (
                    <IconWrapper
                        rotation={rotation}
                        $showCircle={showCircle}
                        className="fa-layers fa-fw"
                        iconColor={iconColor}
                        iconSize={iconSize}
                        $iconBefore={iconBefore}
                    >
                        {showCircle && (
                            <FontAwesomeIcon
                                icon={circleIcon}
                                transform="grow-2"
                                className="circle"
                                // showCircle={showCircle}
                            />
                        )}

                        <FontAwesomeIcon
                            icon={icon || theme.button.types.iconText.icon}
                            transform={showCircle ? "shrink-6" : ""}
                            className="icon"
                            {...{
                                // // Only pass a rotation if it's a valid value
                                // ...([90, 180, 270].indexOf(rotation) !== -1 && {
                                //     rotation
                                // }),
                                // icon,
                                // transform: showCircle && "shrink-6",
                                className: "icon",
                                // showCircle,
                                // iconOffset
                            }}
                        />
                    </IconWrapper>
                )}
                {children && (
                    <ChildWrapper
                        fontSize={fontSize}
                        icon={icon}
                        className="children"
                        $iconBefore={iconBefore}
                    >
                        {children}
                    </ChildWrapper>
                )}
            </BaseIconTextButton>
        );
    }
);

IconTextButton.displayName = "IconTextButton";

IconTextButton.propTypes = {
    /** Allows the text size to be updates */
    fontSize: PropTypes.string,
    /** Allows the text decoration to be updated */
    textDecoration: PropTypes.string,
    /** Allows the arrow icon to be rotated by a number of degrees */
    rotation: PropTypes.oneOf([0, 90, 180, 270]),
    /** The Icon used for the arrow  */
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /** The Icon used for the circle  */
    circleIcon: PropTypes.oneOfType([PropTypes.object]),
    /** The button should be expanded to 100% */
    expanded: PropTypes.bool,
    /** The Icon used for the circle  */
    showCircle: PropTypes.bool,
    /** Whether to show the icon before or after the children  */
    iconBefore: PropTypes.bool,
    /** The flex alignment attribute for the icon  */
    iconVerticalAlignment: PropTypes.string,
    /** if to expand on hover */
    doHover: PropTypes.bool,
    /** The color to be used for the text, should related to a named color in the theme  */
    themeColor: PropTypes.string,
    /** The css padding string to be used  */
    padding: PropTypes.string,
    // /** Used to adjust icon positioning as some icons have different visual properties  */
    // iconOffset: PropTypes.shape({
    //     x: PropTypes.number,
    //     y: PropTypes.number
    // }),
    // /** Used to adjust icon positioning as some icons have different visual properties  */

    wrapContent: PropTypes.bool,
};

IconTextButton.defaultProps = {
    fontSize: "0.9rem",
    textDecoration: "none",
    rotation: 0,
    circleIcon: faCircle,
    expanded: false,
    iconBefore: true,
    showCircle: true,
    doHover: true,
    iconSize: "17px",
    iconVerticalAlignment: "center",
    wrapContent: false,
    padding: "0.2rem 1rem",
    icon: faChevronRight,
    // iconOffset: {
    //     x: -195,
    //     y: 22
    // };
};

export default IconTextButton;
