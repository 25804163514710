import React, { useState } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Row, Column } from "@cortexglobal/rla-components";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon)`
    margin: 1rem;
`;
const SearchBar = styled.div`
    display: flex;
    align-items: center;
    text-align: center;

    input {
        border: ${({ inputBorder }) => inputBorder};
        flex-grow: 1;
        width: 92%;
        margin: 0 1em;
        padding: 0.5em 1em;
        font-size: 1.5em;
        ::placeholder {
            padding: 1em 0;
        }
        :focus {
            outline: none;
        }
    }
    svg {
        color: ${({ theme }) => theme.colors.accent};
        font-size: 1.5em;
    }
`;

// Placeholder file for the search bar
const AssetSearchBar = ({
    inputBorder = "1px solid rgba(0,0,0,0.2)",
    placeholder,
    preIcon,
    postIcon = "search"
}) => {
    const [searchString, setSearchString] = useState(false);
    const history = useHistory();

    const onChange = fld => {
        setSearchString(fld.target.value);
    };

    const onKeyDown = e => {
        if (e.keyCode == 13) {
            history.push("resources?refresh=true&search=" + searchString);
        }
    };
    return (
        <SearchBar inputBorder={inputBorder}>
            {preIcon && <Icon icon={preIcon} />}
            <input
                class="input-field"
                type="text"
                onChange={onChange}
                placeholder={placeholder}
                onKeyDown={e => {
                    onKeyDown(e);
                }}
            ></input>
            <Link to={"resources?refresh=true&search=" + searchString}>
                <Icon icon={postIcon} />
            </Link>
        </SearchBar>
    );
};

export default AssetSearchBar;
