import {
    MEDIA_OPTIONS,
    SEARCH_START,
    SEARCH_STOP,
    MEDIA_SEARCH_RESULTS,
    MEDIA_CLEAR_RESULTS,
    UPLOAD_START,
    UPLOAD_STOP,
    UPDATE_FOLDERS,
    SET_CURRENT_FOLDER,
    UPDATE_TOP_FOLDERS,
    MEDIA_SAVE,
    FOLDER_SAVE,
    UPDATE_EXPANDED_LIST,
    SET_CURRENT_MEDIA
} from "./mediaLibraryActions";

const initialState = {
    collection: {},
    current: {},
    currentFolder: {},
    options: {},
    results: { loaded: false, data: {} },
    searching: false,
    uploading: false,
    showForm: false,
    folders: [],
    topFolders: [],
    gallery: [],
    expandList: []
};

export default function imports(state = initialState, action) {
    switch (action.type) {
        case SEARCH_START:
            return {
                ...state,
                searching: true
            };

        case SEARCH_STOP:
            return {
                ...state,
                searching: false
            };

        case UPDATE_EXPANDED_LIST:
            return {
                ...state,
                expandList: action.data
            };

        case MEDIA_OPTIONS:
            return {
                ...state,
                options: action.data
            };

        case MEDIA_SEARCH_RESULTS:
            return {
                ...state,
                results: { loaded: true, data: action.data }
            };

        case MEDIA_CLEAR_RESULTS:
            return {
                ...state,
                results: { loaded: false, data: {} }
            };

        case SET_CURRENT_MEDIA:
            return {
                ...state,
                current: action.data
            };

        case UPLOAD_START:
            return {
                ...state,
                uploading: true
            };

        case UPLOAD_STOP:
            return {
                ...state,
                uploading: false
            };

        case UPDATE_FOLDERS:
            return {
                ...state,
                folders: {
                    ...state.folders,
                    [action.data.uuid]: action.data
                }
            };

        case UPDATE_TOP_FOLDERS:
            let list = state.expandList;
            action.data.forEach(item => {
                if (list.indexOf(item.uuid) < 0) {
                    list.push(item.uuid);
                }
            });

            return {
                ...state,
                topFolders: action.data,
                folders: {
                    ...state.folders,
                    [action.data[0].uuid]: action.data[0]
                },
                expandList: list
            };

        case SET_CURRENT_FOLDER:
            let lst = state.expandList.filter(uuid => uuid != action.data.uuid);
            lst.push(action.data.uuid);
            return {
                ...state,
                currentFolder: action.data,
                expandList: lst
            };

        case MEDIA_SAVE:
            return {
                ...state,
                current: action.data
            };

        case FOLDER_SAVE:
            return {
                ...state,
                currentFolder: action.data,
                folders: {
                    ...state.folders,
                    [action.data.uuid]: action.data
                }
            };

        default:
            return state;
    }
}
