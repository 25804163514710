import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Column,
    IconTextButton,
    ButtonDropdown,
    LoadingIconSmall,
    Modal,
    PageTitle,
    Row,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { Link, useHistory } from "react-router-dom";
import {
    faCalendarAlt,
    faChevronLeft,
    faEdit,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import BookingsListing from "./BookingsListing";
import InventoryItemBookingForm from "./InventoryItemBookingForm";

import styled from "styled-components";

const ModalTitle = styled(PageTitle)`
    padding-left: 0;
    padding-right: 0;
`;

const InventoryListingItem = ({ path, item: itemIn, removeItem, settings }) => {
    const intl = useIntl();
    const history = useHistory();
    const { can } = usePermissions();
    const [item, setItem] = useState(itemIn);
    const [bookingMode, setBookingMode] = useState("list");
    const [showBookingsModal, setShowBookingsModal] = useState(null);
    const [loadingState, setLoadingState] = useState("loaded");

    const deleteItem = (itemToDelete) => {
        if (confirm("Are you sure you want to delete this item?")) {
            setLoadingState("loading");
            axios
                .delete(`/api/v1/events/inventory/${itemToDelete.uuid}`)
                .then(({ data }) => {
                    removeItem(itemToDelete);
                    setLoadingState("loaded");
                })
                .catch((e) => {});
        }
    };

    const removeBooking = (bookingToRemove) => {
        let newItem = item;

        newItem["future_bookings"] = item.future_bookings.filter(
            (itemBooking) => itemBooking.uuid !== bookingToRemove.uuid
        );

        setItem(newItem);

        // also need to set the booking that is in the modal or the user will not see an onscreen change
        if (!!showBookingsModal) {
            setShowBookingsModal(newItem);
        }
    };

    const addBooking = (bookingToAdd) => {
        let newItem = item;

        newItem["future_bookings"] = [...item.future_bookings, bookingToAdd];

        setItem(item);

        // also need to set the booking that is in the modal or the user will not see an onscreen change
        if (!!showBookingsModal) {
            setShowBookingsModal(newItem);
        }
    };

    const renderBookingModal = (mode) => {
        switch (mode) {
            case "create":
                return (
                    <>
                        <Row>
                            <Column>
                                <ModalTitle
                                    title={trans("New booking for: {name}", {
                                        name: showBookingsModal.name,
                                    })}
                                >
                                    <IconTextButton
                                        onClick={() => {
                                            setBookingMode("list");
                                        }}
                                        showCircle={false}
                                        icon={faChevronLeft}
                                    >
                                        {trans("Back to listing")}
                                    </IconTextButton>
                                </ModalTitle>
                            </Column>
                        </Row>
                        <InventoryItemBookingForm
                            item={item}
                            successCallback={(newBooking) => {
                                addBooking(newBooking);
                                setBookingMode("listing");
                            }}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <Row>
                            <Column>
                                <ModalTitle
                                    title={trans("Bookings for: {name}", {
                                        name: showBookingsModal.name,
                                    })}
                                >
                                    <IconTextButton
                                        onClick={() => {
                                            setBookingMode("create");
                                        }}
                                        showCircle={false}
                                        icon={faPlus}
                                    >
                                        {trans("Create new")}
                                    </IconTextButton>
                                </ModalTitle>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <BookingsListing
                                    bookings={showBookingsModal.future_bookings}
                                    removeBooking={removeBooking}
                                    settings={settings}
                                />
                            </Column>
                        </Row>
                    </>
                );
        }
    };

    return (
        <>
            <tr>
                <td>{item.ref}</td>
                <td>{item.name}</td>
                <td>{item.category?.name || trans("Unknown")}</td>
                {/* <td>{item.cost?.formatted}</td>
                <td>{item.cost_basis}</td> */}
                <td>{item.readable_status}</td>
                <td>
                    <IconTextButton
                        showCircle={false}
                        expanded={false}
                        icon={faCalendarAlt}
                        onClick={() => {
                            setShowBookingsModal(item);
                        }}
                    >
                        {trans("Bookings")}{" "}
                        {item.future_bookings.length > 0 &&
                            "(" + item.future_bookings.length + ")"}
                    </IconTextButton>
                </td>
                {loadingState === "loading" ? (
                    <td>
                        <LoadingIconSmall />
                    </td>
                ) : (
                    <td>
                        <ButtonDropdown
                            type="secondary"
                            expanded={true}
                            onClick={() => {
                                history.push(`${path}/${item.uuid}`);
                            }}
                            actions={[
                                {
                                    name: intl.formatMessage({
                                        id: "Edit",
                                    }),
                                    onClick: () => {
                                        history.push(
                                            `${path}/${item.uuid}/edit`
                                        );
                                    },
                                },
                                {
                                    name: intl.formatMessage({
                                        id: "Delete",
                                    }),
                                    onClick: () => {
                                        deleteItem(item);
                                    },
                                },
                            ]}
                        >
                            {trans("View")}
                        </ButtonDropdown>
                    </td>
                )}
            </tr>
            {!!showBookingsModal && (
                <Modal
                    visible={!!showBookingsModal}
                    onClose={() => setShowBookingsModal(null)}
                    minWidth="50%"
                >
                    {renderBookingModal(bookingMode)}
                </Modal>
            )}
        </>
    );
};

export default InventoryListingItem;
