import React, { useCallback, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { PreviewImage } from "../components/index";

const FILE_SIZE = 1160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = Yup.object().shape({
    primary_color: Yup.string(),
    secondary_color: Yup.string(),
    logo: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                return Yup.mixed()
                    .test(
                        "fileSize",
                        "File too large",
                        (value) => value && value.size <= FILE_SIZE
                    )
                    .test(
                        "fileFormat",
                        "Unsupported Format",
                        (value) =>
                            value && SUPPORTED_FORMATS.includes(value.type)
                    ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
});
export type GroupBrandingType = ReturnType<typeof schema.validateSync>;
export const emptyBranding = {
    primary_color: "",
    secondary_color: "",
    logo: "",
};

export const GroupBrandingForm = ({ item = emptyBranding, submitForm }) => {
    const [newPreviewFile, setNewPreviewFile] = useState(null);

    const handleSubmit = (
        values: GroupBrandingType,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();
        Object.keys(values).forEach((key) => fd.append(key, values[key]));

        submitForm(fd, setSubmitting, "branding").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );

    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("logo", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);
    return (
        <Formik
            initialValues={{
                primary_color: item.primary_color,
                secondary_color: item.secondary_color,
                logo: item.logo,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        {/* 
                            TODO: this needs to be driven by configuration, 'cortex.site-settings.group-profile.branding.colors' 
                        <FormRow
                            name="primary_color"
                            label={trans("Primary color")}
                            helpText={trans("Optional")}
                            error={errors.primary_color}
                        >
                            <FormikInput
                                type="color"
                                name="primary_color"
                                value={values.primary_color}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="secondary_color"
                            label={trans("Secondary color")}
                            helpText={trans("Optional")}
                            error={errors.secondary_color}
                        >
                            <FormikInput
                                type="color"
                                name="secondary_color"
                                value={values.secondary_color}
                                defaultValue="#ffffff"
                            />{" "}
                        </FormRow>*/}

                        <FormRow
                            name="logo"
                            label={trans("Logo")}
                            helpText={trans(
                                "Optional. Please ensure your logo is a high-resolution PNG file"
                            )}
                            error={errors.logo}
                        >
                            <SimpleDropzone
                                onDrop={(acceptedFiles) =>
                                    onDrop(acceptedFiles, setFieldValue)
                                }
                                accept={SUPPORTED_FORMATS.join()}
                                multiple={false}
                            >
                                {values.logo && values.logo !== ""
                                    ? trans("Click here to upload your logo")
                                    : trans(
                                          "Click here to upload your logo"
                                      )}{" "}
                                <FontAwesomeIcon icon={faUpload} />
                            </SimpleDropzone>
                            {typeof values.logo === "string" &&
                                values.logo !== "" && (
                                    <PreviewImage src={values.logo} />
                                )}
                            {newPreviewFile && (
                                <PreviewImage src={newPreviewFile} />
                            )}
                        </FormRow>

                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save Branding")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default GroupBrandingForm;
