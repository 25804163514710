export const GoogleApi = function(opts) {
    opts = opts || {};
    //TODO TL - Decide what error handling we require
    if (!opts.hasOwnProperty("apiKey") && !opts.hasOwnProperty("client")) {
        console.warn("You need to supply an API Key or Client ID");
    }

    // if (!opts.hasOwnProperty("url")) {
    //     console.warn(
    //         "You must supply a Google API URL,",
    //         "assuming you require Google Maps so setting it to:",
    //         "https://maps.googleapis.com/maps/api/js"
    //     );
    // }
    const apiKey = opts.apiKey;
    const libraries = opts.libraries || [];
    const client = opts.client;
    const URL = opts.url || "https://maps.googleapis.com/maps/api/js";
    const googleVersion = opts.googleVersion || "3.30";

    // let script = null;
    // let google = window.google || null;
    // let loading = false;
    let channel = null;
    let language = null;
    let region = null;

    // let onLoadEvents = [];

    const url = () => {
        let url = URL;
        let params = {
            key: apiKey,
            callback: "CALLBACK_NAME",
            libraries: libraries.join(","),
            client: client,
            v: googleVersion,
            channel: channel,
            language: language,
            region: region
        };

        let paramStr = Object.keys(params)
            .filter(k => !!params[k])
            .map(k => `${k}=${params[k]}`)
            .join("&");

        // console.log("Options", opts);
        // console.log(`${url}?${paramStr}`);

        return `${url}?${paramStr}`;
    };

    return url();
};

export default GoogleApi;
