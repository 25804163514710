import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Row,
    Column,
    Button,
    SubmitButton,
    FormikInput,
    FormikSelect,
    FormRow,
} from "@cortexglobal/rla-components";
import { trans, injectIntl } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    addTaskCategory,
    editTaskCategory,
    plannerStopEdit,
    getPlannerFilters,
} from "../plannerActions";

const CategorySchema = Yup.object().shape({
    name: Yup.string().max(100).required(trans("Please enter a category name")),
});

class PlannerTaskCategoryForm extends React.Component {
    state = {
        errors: {},
        saving: false,
    };
    submitForm = (values, { setErrors }) => {
        // console.log(values);

        const { onGoBack, category } = this.props;
        if (this.props.action === "editTaskCategory") {
            values["category_uuid"] = category.uuid;

            this.props
                .editTaskCategory(values)
                .then((data) => {
                    this.props.getPlannerFilters();
                    onGoBack(data);
                })
                .catch((e) => {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    });
                });
        } else {
            this.props
                .addTaskCategory(values)
                .then(onGoBack)
                .catch((e) => {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    });
                });
        }
        return true;
    };

    render() {
        const { onGoBack, category, categories } = this.props;
        const editing = this.props.action === "editTaskCategory";
        console.log({ category });
        return (
            <React.Fragment>
                <Formik
                    initialValues={{
                        name: editing && category ? category.name : "",

                        description:
                            editing && category && category.description
                                ? category.description
                                : "",
                        parent_category_uuid:
                            !editing && category
                                ? category.uuid
                                : editing
                                ? category.parent_uuid
                                : "",
                    }}
                    onSubmit={this.submitForm}
                    validationSchema={CategorySchema}
                >
                    {({ isSubmitting, values, errors }) => {
                        // console.log(values);
                        return (
                            <Form className="form-group">
                                <FormRow
                                    name="name"
                                    label={trans("Category Name")}
                                    error={errors.name}
                                >
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                        showError={false}
                                    />{" "}
                                </FormRow>

                                <FormRow
                                    name="parent_category_uuid"
                                    label={trans("Parent Category")}
                                    error={errors.parent_category_uuid}
                                >
                                    <FormikSelect
                                        name="parent_category_uuid"
                                        value={values.parent_category_uuid}
                                        options={
                                            categories.loaded &&
                                            categories.data.length
                                                ? categories.data.map(
                                                      (category) => ({
                                                          value: category.uuid,
                                                          text: category.name,
                                                      })
                                                  )
                                                : []
                                        }
                                        showError={false}
                                    />{" "}
                                </FormRow>

                                <FormRow
                                    name="description"
                                    label={trans("Description")}
                                    error={errors.description}
                                >
                                    <FormikInput
                                        name="description"
                                        value={values.description}
                                        showError={false}
                                    />{" "}
                                </FormRow>

                                <FormRow>
                                    <div style={{ textAlign: "right" }}>
                                        <SubmitButton
                                            style={{ width: "50%" }}
                                            expanded
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </div>
                                </FormRow>
                            </Form>
                        );
                    }}
                </Formik>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addTaskCategory,
            editTaskCategory,
            plannerStopEdit,
            getPlannerFilters,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const { edit, startDate, endDate, filters, categories } = state.planner;
    return {
        edit,
        startDate,
        endDate,
        filters,
        categories,
    };
}

export default injectIntl(
    compose(connect(mapStateToProps, mapDispatchToProps))(
        PlannerTaskCategoryForm
    )
);
