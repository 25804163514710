import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Row,
    Column,
    Select,
    Button,
    InputField,
    ContentBlockLoader,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const LinksConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    linkUuids,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Link" }),
        linkUuids: linkUuids ? linkUuids : [],
    });

    const [options, setOptions] = useState({
        loaded: false,
        data: [],
    });
    useEffect(() => {
        axios.get(`/api/v1/links?optionList=true`).then(({ data }) => {
            setOptions({
                loaded: true,
                data: data.data.map((option) => {
                    return { ...option, value: option.uuid };
                }),
            });
        });
    }, []);

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    submitPanel(panel, state, panelId);
                }}
            >
                <Column>
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        onChange={onChange}
                    />
                    <ContentBlockLoader
                        loaded={options.loaded}
                        hideOverflow={false}
                    >
                        <Select
                            name="linkUuids"
                            label={trans(
                                "Link (if you cannot find the link you need it will need to be added via the admin area)"
                            )}
                            value={state.linkUuids}
                            emptyOption={intl.formatMessage({
                                id: "--Pick a Link--",
                            })}
                            options={options.data}
                            onChange={onChange}
                            expanded
                            multi={true}
                            // menuPlacement="top"
                            maxMenuHeight={150}
                        />
                    </ContentBlockLoader>
                </Column>
                <Column>
                    <Button type="submit">{trans("Save Panel")}</Button>
                </Column>
            </form>
        </Row>
    );
};

LinksConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default LinksConfiguration;
