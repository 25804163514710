import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import { Row, Column, Header } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { HeaderUtilityArea } from "@cortexglobal/cortex-auth-react-router-dom";

const HeaderWrapper = styled.div`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
    padding-top: 10px;
    padding-bottom: 10px;
`;

const PrivateHeader = ({ updateSection, theme }) => {
    const handleNavClick = () => {
        updateSection("");
    };

    return (
        <HeaderWrapper>
            <Header>
                <h3
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        margin: 0,
                        textTransform: "uppercase",
                        fontSize: "0.875rem",
                    }}
                >
                    <Link
                        to="/"
                        style={{ display: "inline-flex", alignItems: "center" }}
                    >
                        <img
                            style={{
                                height: "50px",
                                maxWidth: "200px",
                                flexShrink: 1,
                                marginRight: "10px",
                            }}
                            src="/img/Logo_BMW_GROUP.svg"
                        />
                        <img
                            style={{
                                // height: "60px",
                                // maxWidth: "200px",
                                marginRight: "10px",
                            }}
                            src="/img/Logo_MINI.svg"
                        />
                    </Link>
                </h3>

                <HeaderUtilityArea handleNavClick={handleNavClick} />
            </Header>
        </HeaderWrapper>
    );
};

export default withTheme(PrivateHeader);
