import React from "react";
import _ from "lodash";
import styled from "styled-components";
import {
    Row,
    Column,
    Panel,
    Image,
    IconTextButton
} from "@cortexglobal/rla-components";

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    height: 150px;
    background: ${({ theme }) => theme.image.containerBackground};
    img {
        width: 95%;
        height: 90%;
        object-fit: contain;
        position: relative;
    }
`;

const MediaButton = styled(IconTextButton)`
    width: 90%;
    padding: 1rem 0.2rem;
    position: relative;
`;
const MediaTitle = styled.h5`
    margin: 0;
    font-size: 0.9rem;
    text-align: left;
    /* width: 90%; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: noWrap;
`;

const MediaSummary = styled.aside`
    font-size: 0.6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: noWrap;
`;
class GalleryItems extends React.Component {
    editFile = item => {
        this.props.editFile(item);
    };

    bytesToSize(bytes) {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "n/a";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + " " + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    }

    render() {
        const { currentFolder } = this.props;
        const contents = currentFolder.contains;

        return (
            <Row>
                <h3>{currentFolder.name}</h3>
                {contents &&
                    Object.keys(contents).map(index => {
                        const mediaFile = contents[index];

                        return (
                            <Column small={6} medium={4} large={3} xlarge={2}>
                                <Panel
                                    textAlign="center"
                                    padding={0}
                                    style={{ position: "relative" }}
                                >
                                    <a
                                        href={mediaFile.fileurl}
                                        target="_blank"
                                        style={{
                                            cursor: "pointer",
                                            position: "relative"
                                        }}
                                    >
                                        <ImageWrapper>
                                            <Image
                                                src={mediaFile.thumbnail}
                                                alt={mediaFile.name}
                                            />
                                        </ImageWrapper>
                                    </a>

                                    {/*<div
                                        style={{
                                            height: "130px",
                                            padding: "5px"
                                        }}
                                    >
                                        <center>
                                            <a
                                                href={mediaFile.fileurl}
                                                target="_blank"
                                            >
                                                <img
                                                    src={mediaFile.thumbnail}
                                                    style={{
                                                        maxHeight: "120px",
                                                        maxWidth: "150px"
                                                    }}
                                                />
                                            </a>
                                        </center>
                                                </div>*/}
                                    {/*<div
                                        style={{
                                            textAlign: "right",
                                            marginRight: "10px"
                                        }}
                                    >
                                        <IconTextButton
                                            onClick={this.editFile.bind(
                                                this,
                                                mediaFile
                                            )}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faPen}
                                        >
                                            {trans("edit")}
                                        </IconTextButton>
                                            </div>*/}

                                    <MediaButton
                                        onClick={this.editFile.bind(
                                            this,
                                            mediaFile
                                        )}
                                        showCircle={true}
                                        iconBefore={false}
                                    >
                                        <MediaTitle title={mediaFile.name}>
                                            {mediaFile.name}
                                        </MediaTitle>
                                        <MediaSummary
                                            title={mediaFile["media_type_name"]}
                                        >
                                            {mediaFile.media[0].mime_type}
                                            <br />
                                            {this.bytesToSize(
                                                mediaFile.media[0].size
                                            )}
                                        </MediaSummary>
                                    </MediaButton>
                                </Panel>
                            </Column>
                        );
                    })}
            </Row>
        );
    }
}

export default GalleryItems;
