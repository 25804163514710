import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { Button, Row, PlainButton, IconTextButton, Tooltip } from "../../index";
import { Separator } from "./StandardFilters";
const FilterRowWrapper = styled.div`
    // display: flex;
    // justify-content: flex-end;
    padding: 0 0.5rem;
    position: sticky;
    bottom: 0;
    background-color: ${({ theme }) => theme.filters.row.backgroundColor};
    box-shadow: ${({ theme }) => theme.filters.row.shadow};
    border-top: ${({ theme }) => theme.filters.row.border};
`;
const FilterRow = styled(Row)`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: ${({ padding }) => padding};
`;

let ApplyFiltersRow = ({
    filters,
    selectedFilters,
    onApply,
    onClear,
    hideClear = false,
    children,
    padding = "0.8rem",
    user,
    toggleFavorites,
    favorites,
    singleColumn = true,
}) => {
    const intl = useIntl();

    // console.log(selectedFilters);
    const noFiltersSelected = Object.keys(selectedFilters).length === 0;
    return (
        <FilterRowWrapper>
            {children}
            {children && singleColumn && <Separator />}
            <FilterRow padding={padding}>
                {!hideClear && (
                    <IconTextButton
                        type="clear"
                        height={28}
                        onClick={onClear}
                        style={{ marginLeft: "auto" }}
                        icon={faTimes}
                        showCircle={false}
                    >
                        {trans("Clear All")}
                    </IconTextButton>
                )}
                <Button
                    // style={{ width: "50%" }}
                    height={36}
                    onClick={onApply}
                    disabled={noFiltersSelected}
                >
                    {trans("Apply")}
                </Button>
            </FilterRow>
        </FilterRowWrapper>
    );
};

ApplyFiltersRow = withTheme(ApplyFiltersRow);

ApplyFiltersRow.displayName = "ApplyFiltersRow";

ApplyFiltersRow.propTypes = {
    onApply: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
};

ApplyFiltersRow.defaultProps = {
    separator: ",",
};

export default ApplyFiltersRow;
