import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Collapse from "react-collapse";
// import { ZoomIn, ZoomOut } from "animate-css-styled-components";

import { isBrowser, useClickOutside } from "@cortexglobal/cortex-utilities";

const DropdownWrapper = styled.div`
    z-index: ${props => props.depth};
    /* width: ${props => props.width}px; */
    padding-top: ${({ theme, padding }) =>
        !isNaN(padding) ? padding : theme.spacing.padding}rem;
    padding-bottom: ${({ theme, padding }) =>
        !isNaN(padding) ? padding : theme.spacing.padding}rem;
    background: ${({ background, theme }) =>
        background ? background : theme.dropdown.background};
    position: absolute;
    top: ${props => props.rect.height}px;
    /* left: ${props =>
        (props.position != "left" && props.rect.width) + props.rect.left}px; */
    /* transform: ${props =>
        props.position != "left" && "translateX(-100%)"}; */
        box-shadow: 3px 7px 6px rgba(0,0,0,0.1);
`;

const DropdownContent = styled(Collapse)``;

const Dropdown = props => {
    // console.log(this.props.toggleRef, this.state.visible);
    let rect = {};
    if (props.toggleRef) {
        rect = props.toggleRef.getBoundingClientRect();
    }

    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, props.hideDropdown);

    return (
        <DropdownWrapper
            position={props.position}
            padding={props.padding}
            depth={props.depth}
            delay={props.delay}
            rect={rect}
            ref={wrapperRef}
        >
            <DropdownContent
                isOpened={props.visible}
                initialStyle={{ height: "0px", overflow: "hidden" }}
            >
                <div
                    ref={dropdownContent => (dropdownContent = dropdownContent)}
                >
                    {props.children}
                </div>
            </DropdownContent>
        </DropdownWrapper>
    );
};

Dropdown.propTypes = {
    // position: PropTypes.oneOf(["left", "right"]),
    // width: PropTypes.number,
    background: PropTypes.string,
    padding: PropTypes.number,
    depth: PropTypes.number,
    delay: PropTypes.number,
    visible: PropTypes.bool,
    toggleRef: PropTypes.object,
    hideDropdown: PropTypes.func
};

Dropdown.defaultProps = {
    // position: "right",
    // width: 100,
    delay: 0,
    depth: 100,
    visible: false,
    hideDropdown: () => {}
};

Dropdown.displayName = "Dropdown";

export default Dropdown;
