import React, { Component } from "react";
import {
    Row,
    Column,
    Panel,
    Loading,
    IconTextButton,
    Badge,
} from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import styled from "styled-components";
import moment from "moment";
import PlanPanel from "./PlanPanel";
const StyledPanel = styled.div`
    overflow: hidden;
    margin-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    h2 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 0.2rem;
    }
    h6 {
        font-size: 0.7rem;
        font-weight: bold;
        margin-bottom: 0rem;
    }
`;
class BudgetSummaryItem extends Component {
    render() {
        const { children, caption } = this.props;

        return (
            <StyledPanel>
                <h2>{children}</h2>
                <h6>{caption}</h6>
            </StyledPanel>
        );
    }
}
class BudgetSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plannedSpend: 0,
            campaignCount: 0,
            actualSpend: 0,
            activityCount: 0,
            onlyThisMonth: true,
        };
    }

    componentDidMount() {
        this.generateTotals(this.props.tasks);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.tasks !== this.props.tasks &&
            typeof this.props.tasks !== "undefined" &&
            Array.isArray(this.props.tasks)
        ) {
            this.generateTotals(this.props.tasks);
        }
    }

    getThisMonthsTasks() {
        return this.props.tasks.filter((task) => {
            return moment(task.start_date).isSame(this.props.start, "month");
        });
    }

    generateTotals(tasks) {
        if (this.state.onlyThisMonth) {
            tasks = tasks.filter((task) => {
                return moment(task.start_date).isSame(
                    this.props.start,
                    "month"
                );
            });
        }
        const totals = tasks.reduce(
            (totals, task) => {
                //Only include group tasks in the totals
                if (!task.can_view_details) {
                    return totals;
                }
                totals.plannedSpend += task.planned_spend.value;
                totals.campaignCount++;
                if (task.activities && task.activities.length > 0) {
                    totals.activityCount += task.activities.length;
                    totals.actualSpend += task.activities.reduce(
                        (total, activity) => {
                            total += activity.cost.value;
                            return total;
                        },

                        0
                    );
                }
                return totals;
            },
            {
                plannedSpend: 0,
                campaignCount: 0,
                actualSpend: 0,
                activityCount: 0,
            }
        );

        this.setState({ ...totals });
    }
    render() {
        const {
            user,
            availableBudget: { loaded, data },
            permissions,
        } = this.props;

        const date = moment(this.props.start);
        // const year = date.year();
        // const month = date.month() + 1; // offset by 1 because moments months start at 0

        return (
            <Row
                collapse
                style={{
                    ...this.props.style,
                    display: "flex",
                }}
            >
                <Column large={6}>
                    <Panel
                        style={{
                            height: "calc(100% - 0.8em)",
                        }}
                    >
                        <Row>
                            <Column>
                                <h3 style={{ marginBottom: "0.4rem" }}>
                                    {trans("Annual Summary")}{" "}
                                </h3>
                            </Column>
                            <Column>
                                <BudgetSummaryItem
                                    caption={trans("Total Budget")}
                                >
                                    <FormattedCurrency
                                        value={data.total?.value}
                                        loaded={loaded}
                                        currency={
                                            user?.currency?.iso_code || "GBP"
                                        }
                                    />
                                </BudgetSummaryItem>
                                <BudgetSummaryItem
                                    caption={trans("Remaining Budget")}
                                >
                                    <FormattedCurrency
                                        value={data.available?.value}
                                        loaded={loaded}
                                        currency={
                                            user?.currency?.iso_code || "GBP"
                                        }
                                    />
                                </BudgetSummaryItem>
                            </Column>
                        </Row>
                    </Panel>
                </Column>
                <Column large={6}>
                    <Panel
                        style={{
                            height: "calc(100% - 0.8em)",
                        }}
                    >
                        <Row>
                            <Column>
                                <h3 style={{ marginBottom: "0.4rem" }}>
                                    {trans("Planner Overview")}{" "}
                                    <small style={{ fontWeight: "normal" }}>
                                        {trans("Campaigns starting between ")}
                                        {date.format("LL")} -{" "}
                                        {moment(this.props.end).format("LL")}
                                    </small>
                                </h3>
                            </Column>
                            <Column medium={6} large={6}>
                                <BudgetSummaryItem
                                    caption={trans("Planned Spend")}
                                >
                                    <FormattedCurrency
                                        value={this.state.plannedSpend}
                                        loaded={loaded}
                                        currency={
                                            user?.currency?.iso_code || "GBP"
                                        }
                                    />
                                </BudgetSummaryItem>
                                <BudgetSummaryItem
                                    caption={trans("Actual Spend")}
                                >
                                    <FormattedCurrency
                                        value={this.state.actualSpend}
                                        loaded={loaded}
                                        currency={
                                            user?.currency?.iso_code || "GBP"
                                        }
                                    />
                                </BudgetSummaryItem>
                            </Column>
                            <Column medium={6} large={6}>
                                <BudgetSummaryItem caption={trans("Campaigns")}>
                                    {this.state.campaignCount}
                                </BudgetSummaryItem>
                                <BudgetSummaryItem
                                    caption={trans("Campaign Activities")}
                                >
                                    {this.state.activityCount}
                                </BudgetSummaryItem>
                            </Column>
                        </Row>
                    </Panel>
                </Column>

                {/* <Column large={3}>
                    <Panel
                        style={{
                            height: "calc(100% - 0.8em)",
                        }}
                    >
                        <Row>
                            <Column>
                                <h3 style={{ marginBottom: "0.4rem" }}>
                                    {trans("Marketing Plan Submission")}{" "}
                                </h3>
                            </Column>
                        </Row>
                        // <PlanPanel year={year} month={month} />
                    </Panel>
                </Column> */}
            </Row>
        );
    }
}

BudgetSummary.propTypes = {
    //TODO - This will need reworking
    //categories: PropTypes.array
};

export default BudgetSummary;
