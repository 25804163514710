import React from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Pagination,
    Button,
    StyledCheckbox,
    FormLabel
} from "@cortexglobal/rla-components";

const StandardPageSettingsEditor = ({
    pageName,
    currentPageFullConfiguration,
    onChange
}) => {
    return (
        <React.Fragment>
            <Column style={{ paddingLeft: "1rem" }}>
                <FormLabel inlineLabel={true}>
                    <StyledCheckbox
                        id={`${pageName}_hidden`}
                        inline={true}
                        name={`${pageName}_hidden`}
                        checked={
                            currentPageFullConfiguration.hidden
                                ? currentPageFullConfiguration.hidden
                                : false
                        }
                        onChange={event => {
                            onChange("hidden", `pages[${pageName}]`, {
                                ...event,
                                name: event.target.name,
                                value: event.target.checked ? true : false
                            });
                        }}
                    />
                    {trans("Hide page")}
                </FormLabel>
            </Column>
            {/*<Column>*/}
            {/*    <FormLabel inlineLabel={true}>*/}
            {/*        <StyledCheckbox*/}
            {/*            id={`${pageName}_hide_header`}*/}
            {/*            inline={true}*/}
            {/*            name={`${pageName}_hide_header`}*/}
            {/*            checked={*/}
            {/*                currentPageFullConfiguration.hide_header*/}
            {/*                    ? currentPageFullConfiguration.hide_header*/}
            {/*                    : false*/}
            {/*            }*/}
            {/*            onChange={event => {*/}
            {/*                onChange("hide_header", `pages[${pageName}]`, {*/}
            {/*                    ...event,*/}
            {/*                    name: event.target.name,*/}
            {/*                    value: event.target.checked ? true : false*/}
            {/*                });*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        {trans("Hide header")}*/}
            {/*    </FormLabel>*/}
            {/*</Column>*/}
            <Column style={{ paddingLeft: "1rem" }}>
                <FormLabel inlineLabel={true}>
                    <StyledCheckbox
                        id={`${pageName}_hide_footer`}
                        inline={true}
                        name={`${pageName}_hide_footer`}
                        checked={
                            currentPageFullConfiguration.hide_footer
                                ? currentPageFullConfiguration.hide_footer
                                : false
                        }
                        onChange={event => {
                            onChange("hide_footer", `pages[${pageName}]`, {
                                ...event,
                                name: event.target.name,
                                value: event.target.checked ? true : false
                            });
                        }}
                    />
                    {trans("Hide footer")}
                </FormLabel>
            </Column>
            <Column style={{ paddingLeft: "1rem" }}>
                <FormLabel inlineLabel={true}>
                    <StyledCheckbox
                        id={`${pageName}_hide_page_name`}
                        inline={true}
                        name={`${pageName}_hide_page_name`}
                        checked={
                            currentPageFullConfiguration.hide_page_name
                                ? currentPageFullConfiguration.hide_page_name
                                : false
                        }
                        onChange={event => {
                            onChange("hide_page_name", `pages[${pageName}]`, {
                                ...event,
                                name: event.target.name,
                                value: event.target.checked ? true : false
                            });
                        }}
                    />
                    {trans("Hide page name")}
                </FormLabel>
            </Column>
        </React.Fragment>
    );
};

export default StandardPageSettingsEditor;
