import PropTypes from "prop-types";
import styled, { withTheme, css } from "styled-components";

const panel = props => props.theme.panel.types[props.type];

const Hero = styled.div`
    width: 100%;
    height: ${({ height, theme }) => (height ? height : theme.hero.height)}px;
    line-height: ${props => props.height}px;
    background-image: url('${props => props.backgroundImage}');
    background-position: ${props => props.backgroundPosition};
    background-size: ${props => props.backgroundSize || cover};
    background-repeat: no-repeat;
    margin-bottom: ${props => props.theme.spacing.margin}em;
    padding-top: ${props => props.theme.spacing.padding}em;

`;

export const HeroContent = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    line-height: 1.2em;
    padding: ${props => props.theme.spacing.padding}em;
    color: ${props =>
        props.type == "light"
            ? props.theme.colors.black
            : props.theme.colors.white};
    background-color: ${props =>
        props.type == "light"
            ? props.theme.colors.white
            : props.theme.colors.black};
`;

Hero.displayName = "Hero";
HeroContent.displayName = "HeroContent";

Hero.propTypes = {
    type: PropTypes.string,
    height: PropTypes.number,
    backgroundImage: PropTypes.string,
    backgroundSize: PropTypes.string,
    backgroundPosition: PropTypes.string
};

Hero.defaultProps = {
    type: "light",
    backgroundSize: "cover",
    backgroundPosition: "center"
};

export default withTheme(Hero);
