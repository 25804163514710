import React, { Component } from "react";
import { trans } from "@cortexglobal/rla-intl";
import { Row, Column, IconTextButton } from "@cortexglobal/rla-components";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

class GalleryItemFileDetails extends Component {
    downloadFile = () => {
        window.open(this.props.fileUrl, "_blank");
    };

    bytesToSize(bytes) {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "n/a";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + " " + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    }

    render() {
        const { mediaFile, uploading, preview } = this.props;

        return (
            <React.Fragment>
                <Row>
                    <Column medium={8}>
                        <h3>{trans("Preview")}</h3>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <center>
                            {!uploading && (
                                <img
                                    src={preview}
                                    style={{
                                        maxWidth: "450px",
                                        maxHeight: "450px",
                                    }}
                                />
                            )}
                        </center>
                    </Column>
                </Row>
                <Row>
                    <Column medium={8}>
                        <h3>{trans("File Details")}</h3>
                    </Column>
                    <Column medium={4}>
                        <IconTextButton
                            style={{ float: "right" }}
                            onClick={this.downloadFile}
                            showCircle={false}
                            expanded={false}
                            icon={faDownload}
                        >
                            {trans("Download")}
                        </IconTextButton>
                    </Column>
                </Row>
                <Row>
                    <Column medium={4}>
                        <strong>{trans("Name")}</strong>
                    </Column>
                    <Column medium={8}>{mediaFile.name}</Column>
                </Row>
                <Row>
                    <Column medium={4}>
                        <strong>{trans("Created")}</strong>
                    </Column>
                    <Column medium={8}>{mediaFile.created_at}</Column>
                </Row>
                <Row>
                    <Column medium={4}>
                        <strong>{trans("Mime Type")}</strong>
                    </Column>
                    <Column medium={8}>{mediaFile.mime_type}</Column>
                </Row>
                <Row>
                    <Column medium={4}>
                        <strong>{trans("Size")}</strong>
                    </Column>
                    <Column medium={8}>
                        {this.bytesToSize(mediaFile.size)}
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

export default GalleryItemFileDetails;
