import React, { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import styled from "styled-components";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import {
    Row,
    Column,
    Modal,
    Loading,
    FormLabel,
    MultiCheckbox,
    StyledCheckbox,
    Button,
    useAddToast,
    useToasts
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const Label = styled.span`
    font-weight: bold;
    padding-right: 20px;
`;

const SelectAllCheckbox = styled(StyledCheckbox)`
    font-weight: bold;
`;

const FileModal = ({ item, visible, onClose }) => {
    const intl = useIntl();
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [formValues, setFormValues] = useState({
        formats: [],
        target_groups: []
    });
    const [selectedAllGroups, setSelectedAllGroups] = useState(false);
    const [estimatedSize, setEstimatedSize] = useState(0);
    const [fileCount, setFileCount] = useState(0);

    const formatOptions = () => {
        const keyMap = {
            pdf_low: "PDF low quality",
            pdf_high: "PDF high quality",
            pdf_crop: "PDF high quality with crop-marks",
            png: "Image PNG",
            html_zip: "Html and supporting files Zipped",
            zip: "Zipped"
        };
        let options = [];

        for (let i = 0; i < item.items.length; i++) {
            options[item.items[i].format] = keyMap[item.items[i].format];
        }

        let keys = Object.keys(options);
        let result = [];
        keys.forEach(key => {
            result.push({
                value: key,
                text: options[key]
            });
        });

        return result;
    };

    const targetGroupOptions = listing => {
        return listing.map(item => {
            return {
                value: item.uuid,
                text: item.name
            };
        });
    };

    const downloadFiles = () => {
        addToast({
            uuid: "downloadToast",
            type: "loader",
            content: trans("Assets downloading")
        });
        axios
            .get(`/api/v1/assets/bulk-files/download`, {
                params: {
                    bulk_uuid: item.uuid,
                    formats: formValues.formats,
                    target_groups: formValues.target_groups
                },
                responseType: "blob"
            })
            .then(response => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });

                addToast({
                    type: "success",
                    content: "Assets downloaded",
                    showFor: 5000
                });
                saveAs(new Blob([response.data]), item.entity.name + ".zip");
                onClose();

                // let blob = new Blob([response.data], {
                //     type: "application/octet-stream"
                // });
                // let link = document.createElement("a");
                // link.href = window.URL.createObjectURL(blob);
                // link.download = item.entity.name + ".zip";
                // link.click();
            })
            .catch(e => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });

                addToast({
                    type: "alert",
                    content: "Error downloading bulk assets",
                    showFor: 5000
                });
            });
    };

    const onChange = item => {
        let newVal = { ...formValues, [item.name]: item.value };
        setFormValues(newVal);
        calculateEstimation(newVal);
    };

    const selectAllGroups = event => {
        const allGroupUuids = item.targetGroups.map(group => {
            return group.uuid;
        });
        let newVal = {};

        if (event.target.checked) {
            newVal = { ...formValues, target_groups: allGroupUuids };
            setSelectedAllGroups(true);
        } else {
            newVal = { ...formValues, target_groups: [] };
            setSelectedAllGroups(false);
        }
        setFormValues(newVal);
        calculateEstimation(newVal);
    };

    const calculateEstimation = values => {
        let { formats, target_groups } = values;
        let size = 0;
        let count = 0;
        item.items.map(file => {
            if (
                formats.includes(file.format) &&
                target_groups.includes(file.group_uuid)
            ) {
                size += file.size;
                count += 1;
            }
        });
        setFileCount(count);
        setEstimatedSize(bytesToSize(size * 0.9));
    };

    const bytesToSize = bytes => {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "0 Byte";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    };

    return (
        <Modal visible={visible} onClose={onClose} zIndex={0} maxWidth="90%">
            {!item.uuid && <Loading />}
            {item.uuid && (
                <React.Fragment>
                    <Row>
                        <Column>
                            <h2>{item.entity.name}</h2>
                        </Column>
                    </Row>
                    <hr />
                    <Row>
                        <Column medium={3} style={{ textAlign: "right" }}>
                            <FormLabel name="formats">
                                {trans("Available Formats")}
                            </FormLabel>
                        </Column>
                        <Column medium={6}>
                            <MultiCheckbox
                                name="formats"
                                onChange={onChange}
                                options={formatOptions()}
                                value={formValues.formats}
                            />
                        </Column>
                    </Row>
                    <hr />
                    <Row>
                        <Column medium={3} style={{ textAlign: "right" }}>
                            <FormLabel name="target_groups">
                                {trans("Available Groups")}
                            </FormLabel>
                        </Column>
                        <Column medium={6}>
                            <SelectAllCheckbox
                                onChange={selectAllGroups}
                                text={intl.formatMessage({ id: "Select all" })}
                                checked={selectedAllGroups}
                            />
                            <MultiCheckbox
                                name="target_groups"
                                onChange={onChange}
                                options={targetGroupOptions(item.targetGroups)}
                                value={formValues.target_groups}
                            />
                        </Column>
                    </Row>
                    <hr />
                    <Row>
                        <Column medium={3} style={{ textAlign: "right" }}>
                            <FormLabel name="targets">
                                {trans("Number of files")}
                            </FormLabel>
                        </Column>
                        <Column medium={1}>{fileCount}</Column>
                        <Column medium={3}>
                            <Button
                                onClick={downloadFiles}
                                disabled={fileCount === 0}
                            >
                                {trans("Download")}
                            </Button>
                        </Column>
                        <Column medium={5}>
                            {fileCount === 0 &&
                                trans(
                                    "Please select at least one format and one group"
                                )}
                        </Column>
                    </Row>
                </React.Fragment>
            )}
        </Modal>
    );
};

export default FileModal;
