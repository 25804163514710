import React from "react";
import styled, { withTheme } from "styled-components";
import {
    MainNavMenu,
    MainNavLink,
    MainNavWrapper,
    Row,
    Column
} from "@cortexglobal/rla-components";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { colors } from "../../config/theme";
/*
const WizardLink = styled(MainNavLink)`
    float: right;
    padding-left: 2em;
    position: relative;
    background: ${colors.accent};
    color: ${colors.white};
`;

const WizardIcon = styled(FontAwesomeIcon)`
    top: 50%;
    left: 0;
    padding: 6px;
    height: 34px;
    width: 34px !important;
    border-radius: 34px;
    position: absolute;
    color: ${colors.black};
    background: ${colors.white};
    border: 2px solid ${colors.accent};
    transform: translate(-50%, -50%);
`;
*/

const SubNavLink = styled(MainNavLink)`
    font-family: ${props => props.theme.navigation.subNavLink.fontFamily};
    font-size: 14px;
`;

const SubMenuWrapper = styled.div`
    background: ${props => props.theme.navigation.subNavigation.background};
`;

class PrivateNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // section: "home",
            // children: [],

            width: undefined
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.navigation !== this.props.navigation) {
            const {
                navigation: { data },
                location
            } = this.props;

            const currentPath = location.pathname;
            // console.log(data);
            data.filter(item => {
                // console.log(item);
                return item.level === 0;
            }).forEach(item => {
                // console.log(item);
                const currentSection = currentPath.split("/")[1];
                const navItemSection = item.path.split("/")[1];

                if (currentSection === navItemSection) {
                    // console.log(currentPath, item.path, "Updating");
                    this.updateSection(item.id);
                }
            });
        }
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    updateSection = selectedId => {
        const {
            navigation: { data }
        } = this.props;
        let children = [];
        const selectedItem = data.find(({ id }) => selectedId === id);
        if (selectedItem.parent_id > 0) {
            children = data.filter(item => {
                return item.parent_id === selectedId;
            });
        }
        this.setState({ section: selectedId, children: children });
    };

    render() {
        const {
            navigation: { data, loaded }
        } = this.props;
        console.log(this.props);
        if (this.state.width < this.props.theme.breakpoints.medium) {
            console.log("Show hamburger!");
        }
        return (
            <MainNavMenu margin="0" background={colors.secondary}>
                {loaded && (
                    <Row>
                        <Column>
                            <MainNavWrapper>
                                {data
                                    .filter(item => {
                                        return item.level === 0;
                                    })
                                    .map(item => {
                                        // console.log(key, item);
                                        return (
                                            <MainNavLink
                                                key={item.id}
                                                to={item.path}
                                                onClick={this.updateSection.bind(
                                                    this,
                                                    item.id
                                                )}
                                            >
                                                {item.name}
                                            </MainNavLink>
                                        );
                                    })}
                            </MainNavWrapper>
                        </Column>
                    </Row>
                )}

                {this.state.children && (
                    <SubMenuWrapper>
                        <Row>
                            <Column
                                style={{ fontSize: "8pt", boxShadow: "-2" }}
                            >
                                {this.state.children.map(child => {
                                    return (
                                        <SubNavLink
                                            key={child.id}
                                            to={child.path}
                                        >
                                            {child.name}
                                        </SubNavLink>
                                    );
                                })}
                            </Column>
                        </Row>
                    </SubMenuWrapper>
                )}
            </MainNavMenu>
        );
    }
}

export default withTheme(PrivateNav);
