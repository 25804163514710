import React, { useState, useEffect } from "react";
import axios from "axios";
// import { Flipper, Flipped } from "react-flip-toolkit";
import { trans } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    Loading,
    LoadingIconSmall,
    Pagination,
    Panel,
} from "@cortexglobal/rla-components";

import AssetResultItem from "./AssetResultItem";

const RelatedAssetBrowser = ({ asset, onSelect }) => {
    const [relatedAssetsLoaded, setRelatedAssetsLoaded] = useState(false);
    const [relatedAssets, setRelatedAssets] = useState([]);
    useEffect(() => {
        setRelatedAssetsLoaded(false);
        if (asset && asset.uuid) {
            axios
                .get(`/api/v1/assets/${asset.uuid}/related?per_page=10000`)
                .then(({ data }) => {
                    setRelatedAssets(data.data);
                    setRelatedAssetsLoaded(true);
                });
        }
    }, [asset]);
    // if (relatedAssets.length <= 0) {
    //     return null;
    // }
    return (
        <Panel>
            {!relatedAssetsLoaded ? (
                <LoadingIconSmall />
            ) : (
                // <Flipper flipKey={relatedAssetsLoaded}>
                //     <Flipped flipId={`relatedAssets`} stagger={true}>
                <Row collapse>
                    {relatedAssets.length > 0 ? (
                        relatedAssets.map((relatedAsset) => {
                            return (
                                <AssetResultItem
                                    loaded={relatedAssetsLoaded}
                                    asset={relatedAsset}
                                    onClick={onSelect}
                                    key={relatedAsset.uuid}
                                />
                            );
                        })
                    ) : (
                        <p>{trans("No related assets found")}</p>
                    )}
                </Row>
                //     </Flipped>
                // </Flipper>
            )}
        </Panel>
    );
};

export default RelatedAssetBrowser;
