import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikCheckboxTree,
    Panel,
    Loading,
    Tabordion,
    Row,
    Column,
    MultiCheckbox,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import {
    PreviewImage,
    TabordionGroup,
    LocaleSwitcher,
} from "../components/index";

export const DashboardPanelRoleSection = ({
    dashboards,
    dashboardsLoaded,
    dashboardPanels,
    dashboardPanelsLoaded,
    roleDashboards,
    onChange,
}) => {
    // console.log({
    //     dashboards,
    //     dashboardsLoaded,
    //     dashboardPanels,
    //     dashboardPanelsLoaded,
    //     roleDashboards,
    //     onChange,
    // });

    if (!dashboardsLoaded || !dashboardPanelsLoaded) {
        return <Loading />;
    }

    const panelOptions = dashboardPanels; //.map((panel) => {
    //     // console.log(panel);
    //     return {
    //         value: panel.id,
    //         text: panel.title,
    //     };
    // });
    return (
        <Tabordion current={0}>
            {dashboards.map((dashboard) => {
                // console.log(dashboard);
                // console.log({ roleDashboards });
                const selectedPanels = roleDashboards
                    .filter(
                        (roleDashboard) =>
                            roleDashboard.dashboard_id === dashboard.value
                    )
                    .map((roleDashboard) => roleDashboard.id);

                // console.log({ selectedPanels, panelOptions });

                return (
                    <TabordionGroup heading={<span>{dashboard.text}</span>}>
                        <FormRow
                            name="locale"
                            label={trans("Panels")}
                            helpText={trans("Optional")}
                        >
                            <MultiCheckbox
                                options={panelOptions}
                                value={selectedPanels}
                                onChange={({ value }) => {
                                    // console.log({ value });
                                    //Values are all selected panel id's for this dashboard
                                    //so we need to remove all current selections for this dashboard
                                    //then turn back on the ones in the value, then pass this new data
                                    //to the onChange handler
                                    let newRoleDashboardPanels =
                                        roleDashboards.filter(
                                            (roleDashboard) =>
                                                roleDashboard.dashboard_id !==
                                                dashboard.value
                                        );

                                    value.forEach((panelId) => {
                                        newRoleDashboardPanels.push({
                                            dashboard_id: dashboard.value,
                                            id: panelId,
                                        });
                                    });

                                    onChange(newRoleDashboardPanels);
                                }}
                            />
                        </FormRow>
                    </TabordionGroup>
                );
            })}
        </Tabordion>
    );
};

export default DashboardPanelRoleSection;
