import React, { useEffect, useReducer } from "react";
import axios from "axios";

export const LOADED_NEWS_SUMMARIES = "LOADED_NEWS_SUMMARIES";
export const LOADING_NEWS_SUMMARIES = "LOADING_NEWS_SUMMARIES";

import { NewsStory } from "../forms/NewsForm";
export interface NewsStateType {
    loading: boolean;
    loaded: boolean;
    data: { [key: string]: NewsStory }[];
    meta: any;
}
export type ActionType = {
    type: "LOADED_NEWS_SUMMARIES" | "LOADING_NEWS_SUMMARIES";
    [key: string]: any;
};

export const initialState = {
    loading: false,
    loaded: false,
    data: [],
    meta: {},
};
const reducer = (state: NewsStateType, action: ActionType) => {
    switch (action.type) {
        case LOADING_NEWS_SUMMARIES:
            return {
                ...state,
                loading: true,
            };
        case LOADED_NEWS_SUMMARIES:
            return {
                ...state,
                data: action.data,
                pagination: action.pagination,
                loaded: true,
                loading: false,
            };

        default:
            return state;
    }
};

export const useNews = (): {
    news: NewsStateType;
    dispatch: (action: ActionType) => void;
    getNewsSummaries: any;
} => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const getNewsSummaries = (page = 1) => {
        if (state.loading) {
            return;
        }
        dispatch({
            type: LOADING_NEWS_SUMMARIES,
        });
        return axios
            .get(`/api/v1/admin/news?page=${page}`)
            .then(({ data: { data, meta } }) => {
                // console.log(meta);
                dispatch({
                    type: LOADED_NEWS_SUMMARIES,
                    data,
                    pagination: { ...meta },
                });
            })
            .catch((e) => e);
    };

    return { news: state, dispatch, getNewsSummaries };
};
