import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import styled from "styled-components";
import { withRouter, Redirect, Link } from "react-router-dom";
import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Loading,
    Modal,
    TableList,
    PageTitle,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import RelatedRequestRow from "../components/RelatedRequestRow";

class RelatedRequests extends Component {
    state = {
        loaded: false,
        requests: [],
    };
    componentDidMount() {
        this.getRequests(this.props.smartRef);
    }

    getRequests = (smartRef) => {
        axios
            .get(`api/v1/requests/related/${smartRef}`)
            .then((result) => {
                return this.setState({
                    requests: result.data.data,
                    loaded: true,
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    this.setState({ notFound: true });
                    //console.log("Redirect", this.props.plannerPath);
                }
            });
    };

    render() {
        const { requests, loaded } = this.state;
        const { smartRef, requestPath, canAdmin, showHeading } = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Column medium={9}>
                        {showHeading && <h3>{trans("Related Requests")}</h3>}
                    </Column>
                    {canAdmin && (
                        <Column
                            medium={3}
                            style={{ textAlign: "right", float: "right" }}
                        >
                            <IconTextButton
                                as={Link}
                                to={`${requestPath}/create?relatedRef=${smartRef}`}
                                rotation={180}
                                showCircle={false}
                                icon={faPlus}
                                style={{ paddingRight: 0 }}
                            >
                                {trans("Add Request")}
                            </IconTextButton>
                        </Column>
                    )}
                </Row>

                <TableList className="request-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{trans("Request")}</th>
                            <th>{trans("Status")}</th>
                            <th>{trans("Assigned To")}</th>
                            <th>{trans("Request Created")}</th>
                            <th>{trans("View")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loaded ? (
                            <tr>
                                <td colSpan="100%">
                                    <Loading />
                                </td>
                            </tr>
                        ) : requests.length === 0 ? (
                            <tr>
                                <td colSpan="100%">
                                    {trans("No related requests found")}
                                </td>
                            </tr>
                        ) : (
                            requests.map((request) => {
                                return (
                                    <RelatedRequestRow
                                        request={request}
                                        key={request.uuid}
                                    />
                                );
                            })
                        )}
                    </tbody>
                </TableList>
            </React.Fragment>
        );
    }
}

RelatedRequests.displayName = "RelatedRequests";

RelatedRequests.propTypes = {
    smartRef: PropTypes.string.isRequired,
};

RelatedRequests.defaultProps = {};

export default withRouter(RelatedRequests);
