import React, { useContext, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { saveAs } from "file-saver";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    TableList,
    SubmitButton,
    Button,
    IconTextButton,
    useAddToast,
    useToasts,
} from "@cortexglobal/rla-components";

import { SurveyButtons } from "./SurveyFormStyledComponents";

import SurveyManagementContext from "../SurveyManagementContext";
import SurveyChoiceResult from "./results/SurveyChoiceResult";
import SurveyRangeResult from "./results/SurveyRangeResult";
import SurveyTextResult from "./results/SurveyTextResult";
import SurveyDateResult from "./results/SurveyDateResult";

const SurveyResultRow = styled.div`
    border-bottom: 1px solid #cdcdcd;
    margin-bottom: 2rem;
`;
const CopiedText = styled.div`
    opacity: ${(props) => (props.show ? "1" : "0")};
    transition: opacity 650ms ease;
`;
const SurveyResultDetails = ({ result }) => {
    const [expanded, setExpanded] = useState(false);
    const isInfo = result.type === "info";

    if (isInfo) {
        return (
            <SurveyResultRow>
                <Row collapse>
                    <Column collapse>
                        <h3>{result.question}</h3>
                        {isInfo && <p>{result.caption}</p>}
                    </Column>
                </Row>
            </SurveyResultRow>
        );
    }
    return (
        <SurveyResultRow>
            <Row collapse>
                <Column
                    medium={9}
                    collapse
                    onClick={(e) => setExpanded(!expanded)}
                >
                    <h3>
                        {result.number}: {result.question}
                    </h3>
                </Column>
                <Column
                    medium={3}
                    style={{
                        textAlign: "right",
                    }}
                >
                    <IconTextButton
                        onClick={(e) => setExpanded(!expanded)}
                        style={{ marginBottom: "1rem" }}
                        icon={"chevron-down"}
                        showCircle={false}
                        rotation={expanded ? 180 : 0}
                    >
                        {expanded
                            ? trans("Hide responses")
                            : trans("Show responses")}
                    </IconTextButton>
                </Column>
                <Column collapse medium={12}>
                    {(result.type === "single" || result.type === "multi") && (
                        <SurveyChoiceResult
                            result={result}
                            expanded={expanded}
                        />
                    )}
                    {result.type === "range" && (
                        <SurveyRangeResult
                            result={result}
                            expanded={expanded}
                        />
                    )}
                    {(result.type === "text" || result.type === "textarea") && (
                        <SurveyTextResult result={result} expanded={expanded} />
                    )}
                    {result.type === "date" && (
                        <SurveyDateResult result={result} expanded={expanded} />
                    )}
                </Column>
            </Row>
        </SurveyResultRow>
    );
};

const SurveyFormResults = ({ surveyData }) => {
    const addToast = useAddToast();
    const [showCopied, setShowCopied] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(surveyData.survey_url);
        setShowCopied(true);

        const copyTimeout = window.setTimeout(() => {
            setShowCopied(false);
        }, 3000);
        return () => window.clearTimeout(copyTimeout);
    };

    const downloadSurveyResults = (fileType) => {
        if (!surveyData.uuid) {
            return;
        }

        axios
            .get(`/api/v1/surveys/${surveyData.uuid}/download`, {
                responseType: "blob",
                params: {
                    filetype: fileType,
                },
            })
            .then((res) => {
                saveAs(
                    new Blob([res.data]),
                    //Make a filename safe version of the survey name
                    surveyData.name
                        .toLowerCase()
                        .replace(/[^a-z0-9]/g, "-")
                        .replace(/-+/g, "-") +
                        "." +
                        fileType.toLowerCase()
                );
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        e.response?.data?.message ||
                        e.message ||
                        "An error occurred while downloading the file.",
                    showFor: 5000,
                });
            });
    };

    return (
        <>
            <SurveyResultRow style={{ paddingBottom: "2rem" }}>
                <Row collapse>
                    <Column large={6} collapse>
                        <TableList>
                            <thead>
                                <tr>
                                    <th>{trans("Statistics")}</th>
                                    <th>{trans("Count")}</th>
                                    <th>{trans("%")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{trans("Total Submissions")}</td>
                                    <td>{surveyData.total_finished_entries}</td>
                                    <td>
                                        {surveyData.total_entries > 0
                                            ? Math.round(
                                                  (surveyData.total_finished_entries /
                                                      surveyData.total_entries) *
                                                      100
                                              )
                                            : 0}
                                        %
                                    </td>
                                </tr>
                                <tr>
                                    <td>{trans("Unsubmitted")}</td>
                                    <td>
                                        {surveyData.total_unfinished_entries}
                                    </td>
                                    <td>
                                        {surveyData.total_entries > 0
                                            ? Math.round(
                                                  (surveyData.total_unfinished_entries /
                                                      surveyData.total_entries) *
                                                      100
                                              )
                                            : 0}
                                        %
                                    </td>
                                </tr>
                                <tr>
                                    <td>{trans("Total Recipients")}</td>
                                    <td>{surveyData.total_entries}</td>
                                    <td>
                                        {surveyData.total_entries ? 100 : 0}%
                                    </td>
                                </tr>
                            </tbody>
                        </TableList>
                    </Column>
                    <Column large={6}>
                        <Row marginTop={0}>
                            {surveyData.total_finished_entries > 0 && (
                                <>
                                    <Column medium={6} collapse>
                                        <SubmitButton
                                            expanded
                                            onClick={() =>
                                                downloadSurveyResults("Csv")
                                            }
                                        >
                                            {trans("Download Results as CSV")}
                                        </SubmitButton>
                                    </Column>
                                    <Column medium={6} collapse>
                                        <SubmitButton
                                            expanded
                                            onClick={() =>
                                                downloadSurveyResults("Xlsx")
                                            }
                                        >
                                            {trans("Download Results as XLSX")}
                                        </SubmitButton>
                                    </Column>
                                </>
                            )}
                            {surveyData.uuid && (
                                <Column collapse style={{ marginTop: "1rem" }}>
                                    <h3>{trans("Link")}</h3>
                                    <a
                                        href={surveyData.survey_url}
                                        target="_blank"
                                    >
                                        {surveyData.survey_url}
                                    </a>
                                    <IconTextButton
                                        onClick={copyUrl}
                                        showCircle={false}
                                        icon={faCopy}
                                    ></IconTextButton>
                                    <CopiedText show={showCopied}>
                                        {trans("Copied to clipboard")}
                                    </CopiedText>
                                </Column>
                            )}
                        </Row>
                    </Column>
                </Row>
            </SurveyResultRow>

            {surveyData.results?.map((result, index) => (
                <SurveyResultDetails
                    key={result.order + result.question}
                    result={result}
                />
            ))}
        </>
    );
};

export default SurveyFormResults;
