import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { Loading } from "@cortexglobal/rla-components";

class PrivateRoute extends Component {
    render() {
        const {
            component,
            loggedIn,
            loggedInChecked,
            allowGuest,
            ...props
        } = this.props;
        //console.log(loggedIn, loggedInChecked);
        if (!loggedInChecked) {
            return (
                <Loading
                    align="center"
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                />
            );
        }
        return (
            <Route
                {...props}
                render={props => {
                    if (loggedIn === true || allowGuest === true) {
                        return React.createElement(component, props);
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/auth/login",
                                    state: { from: props.location }
                                }}
                            />
                        );
                    }
                }}
            />
        );
    }
}

PrivateRoute.propTypes = {
    component: PropTypes.func
};

function mapStateToProps(state) {
    const { loggedIn, loggedInChecked } = state.auth;
    return { loggedIn, loggedInChecked };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
