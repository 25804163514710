import React, { useContext } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import AssetContext from "../AssetContext";
import { useConfirmation } from "../ConfirmationService";

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
const VersionActionsTable = (props) => {
    const {
        asset,
        assetsPath,
        canEdit,
        archiveAsset,
        deleteAsset,
        approveAsset,
        rejectAsset,
        createAssetRevision,
        submitForApproval,
    } = useContext(AssetContext);

    const { history } = props;
    const confirm = useConfirmation();

    if (!canEdit) {
        return null;
    }

    return (
        <ButtonWrapper>
            <div small={4} style={{ margin: "0 5px" }}>
                <Button
                    onClick={(event) => {
                        createAssetRevision(asset.uuid);
                        props.toggleModal(false);
                    }}
                    $expanded
                    style={{ padding: "0 1em" }}
                >
                    {trans("Create revision")}
                </Button>
            </div>

            {asset.status == "draft" && (
                <div small={4} style={{ margin: "0 5px" }}>
                    <Button
                        onClick={(event) =>
                            submitForApproval(asset)
                                .then(history.push(`${assetsPath}`))
                                .then(props.toggleModal(false))
                        }
                        $expanded
                        style={{ padding: "0 1em" }}
                    >
                        {trans("Get Approval")}
                    </Button>
                </div>
            )}

            {asset.status == "pending" && (
                <React.Fragment>
                    <div small={4} style={{ margin: "0 5px" }}>
                        <Button
                            onClick={(event) =>
                                approveAsset(asset)
                                    .then(history.push(`${assetsPath}`))
                                    .then(props.toggleModal(false))
                            }
                            $expanded
                            style={{ padding: "0 1em" }}
                        >
                            {trans("Approve")}
                        </Button>
                    </div>

                    <div small={4} style={{ margin: "0 5px" }}>
                        <Button
                            onClick={(event) =>
                                rejectAsset(asset)
                                    .then(history.push(`${assetsPath}`))
                                    .then(props.toggleModal(false))
                            }
                            $expanded
                            style={{ padding: "0 1em" }}
                        >
                            {trans("Reject")}
                        </Button>
                    </div>
                </React.Fragment>
            )}

            {asset.status == "active" && (
                <React.Fragment>
                    <div small={4} style={{ margin: "0 5px" }}>
                        <Button
                            onClick={(event) =>
                                archiveAsset(asset.uuid)
                                    .then(history.push(`${assetsPath}`))
                                    .then(props.toggleModal(false))
                            }
                            $expanded
                            style={{ padding: "0 1em" }}
                        >
                            {trans("Archive This Version")}
                        </Button>
                    </div>
                    <div small={4} style={{ margin: "0 5px" }}>
                        <Button
                            onClick={(event) => console.log("Disable Asset")}
                            $expanded
                            style={{ padding: "0 1em" }}
                        >
                            {trans("Disable Asset")}
                        </Button>
                    </div>
                    <div small={4} style={{ margin: "0 5px" }}>
                        <Button
                            onClick={(event) =>
                                console.log("Rollback to last Major")
                            }
                            $expanded
                            style={{ padding: "0 1em" }}
                        >
                            {trans("Rollback")}
                        </Button>
                    </div>
                </React.Fragment>
            )}

            {false && (
                <div small={4} style={{ margin: "0 5px" }}>
                    <Button
                        type="alert"
                        onClick={(event) => {
                            confirm({
                                variant: "danger",
                                catchOnCancel: true,
                                title: trans("Are you sure you want to permanently delete this asset?"),
                                description:
                                    trans("Once deleted it will not be retrievable."),
                            })
                                .then(() =>
                                    deleteAsset(
                                        asset.uuid,
                                        `${assetsPath}`
                                    ).then(history.push(`${assetsPath}`))
                                )
                                .catch();
                        }}
                        $expanded
                        style={{ padding: "0 1em" }}
                    >
                        {trans("Permanently Delete")}
                    </Button>
                </div>
            )}
        </ButtonWrapper>
    );
};

export default withRouter(VersionActionsTable);
