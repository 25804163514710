import React from "react";

import {
    Centred,
    Loading,
    CircularProgress,
    GreedyCarousel,
} from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import { trans } from "@cortexglobal/rla-intl";
import styled from "styled-components";

const StatContainerTop = styled.div`
    font-size: 0.8rem;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #777;
`;
const StatContainerBottom = styled.div`
    font-size: 0.8rem;
    text-align: center;
    margin-top: 5px;
`;

const StatLabel = styled.div`
    font-weight: bold;
`;

const GoodStat = styled.span`
    color: ${({ theme }) => theme.colors.success};
    font-weight: bold;
`;

const BadStat = styled.span`
    color: ${({ theme }) => theme.colors.alert};
    font-weight: bold;
`;

const ActiveUsersChartOutput = ({
    panelId,
    layout,
    filterBy,
    perPage = 10,
}) => {
    const panelData = usePanelData(panelId, `/api/v1/active-users`, {
        filter_by: filterBy,
        per_page: perPage,
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }

    return (
        <GreedyCarousel layout={layout}>
            <CircularProgress
                backgroundPadding={5}
                strokeWidth={14}
                value={
                    panelData.data.totalUsers > 0
                        ? Math.round(
                              (panelData.data.active /
                                  panelData.data.totalUsers) *
                                  100
                          )
                        : 0
                }
                initialAnimation={true}
                containerWidth={"100%"}
            />
            <Centred style={{ width: "60%" }}>
                <StatContainerTop>
                    <StatLabel>{trans("Active")}</StatLabel>
                    <GoodStat>
                        {panelData.data.totalUsers > 0
                            ? Math.round(
                                  (panelData.data.active /
                                      panelData.data.totalUsers) *
                                      100
                              )
                            : 0}
                        %
                    </GoodStat>{" "}
                    ({panelData.data.active})
                </StatContainerTop>
                <StatContainerBottom>
                    <StatLabel>{trans("Not Active")}</StatLabel>
                    <BadStat>
                        {panelData.data.totalUsers > 0
                            ? Math.round(
                                  (panelData.data.nonactive /
                                      panelData.data.totalUsers) *
                                      100
                              )
                            : 0}
                        %
                    </BadStat>{" "}
                    ({panelData.data.nonactive})
                </StatContainerBottom>
            </Centred>
        </GreedyCarousel>
    );
};

export default ActiveUsersChartOutput;
