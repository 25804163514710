import React from "react";
import styled from "styled-components";

import { trans } from "@cortexglobal/rla-intl";
import { Image } from "@cortexglobal/rla-components";

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    padding: ${({ theme }) => theme.spacing.padding / 2}rem;
    margin-right: ${({ theme }) => theme.spacing.padding / 2}rem;
    color: ${({ theme }) => theme.colors.white};
    min-width: 170px;
    max-width: 170px;
    min-height: 10rem;
    background-color: ${({ theme, isDragging, isCurrentPage }) =>
        isDragging
            ? theme.colors.secondary
            : isCurrentPage
            ? theme.colors.gray800
            : theme.colors.gray600};
    text-align: center;
    position: relative;

    &:hover button {
        display: block;
    }
    font-size: 0.8em;
`;

const PageThumbnail = styled(Image)`
    display: block;
    margin: 0 auto 10px auto;
    max-width: 150px;
`;

const DeleteButton = styled.button`
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    background: #e63f3f;
    color: white;
    border: none;
    outline: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;
`;

const PageItem = ({
    currentPage,
    pageSlug,
    pageSetup,
    onPageChange,
    provided,
    snapshot,
    onPageDelete,
    canDeletePage
}) => {
    // console.log("PAGE ITEM", pageSlug, currentPage);
    return (
        <ItemWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => onPageChange(pageSlug)}
            isDragging={snapshot.isDragging}
            isCurrentPage={pageSlug === currentPage}
        >
            {canDeletePage &&
                <DeleteButton onClick={event => {
                    event.stopPropagation();
                    onPageDelete(pageSlug)}
                }>
                    x
                </DeleteButton>
            }
            <PageThumbnail
                src={`${process.env.REACT_APP_BASE_URL}/img/${pageSetup.component}.png`}
            />
            {pageSetup.group}: {pageSetup.name}
        </ItemWrapper>
    );
};

export default PageItem;
