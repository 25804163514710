import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { useAddToast, useToasts } from "@cortexglobal/rla-components";

export const useDownloadAsset = () => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    /**
     *
     * @param {*} uuid
     * @param {*} filename
     * @param {*} downloadType The download type to be used in the api path.  Either 'download', 'download-pdf', 'download-pdf-zip', 'download-image-zip'
     * @param {*} queryString Options to be passed to the api in the query string
     */
    const downloadAssetFiles = (
        uuid,
        filename,
        downloadType = "download",
        queryString = ""
    ) => {
        addToast({
            uuid: "downloadToast",
            type: "loader",
            content: trans("Asset is downloading")
        });
        return axios
            .get(
                `/api/v1/assets/${downloadType}/${uuid}${
                    queryString !== "" ? `?${queryString}` : ""
                }`,
                {
                    responseType: "blob",
                    timeout: 0
                }
            )
            .then(response => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });

                saveAs(new Blob([response.data]), filename);
            })
            .catch(e => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred downloading your file");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000
                });
            });
    };

    return { downloadAssetFiles };
};
