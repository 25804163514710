import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import {
    Row,
    Column,
    Button,
    SubmitButton,
    IconTextButton,
    Panel,
    useAddToast,
    StyledCheckbox,
    Loading,
} from "@cortexglobal/rla-components";
import { GroupSwitcher } from "@cortexglobal/cortex-auth-react-router-dom";

export const GenerateCampaign = ({ campaign, onCancel, onComplete }) => {
    const addToast = useAddToast();
    const intl = useIntl();
    const { can } = usePermissions();
    const [showGroupSelector, setShowGroupSelector] = useState(false);

    const groups = useSelector((state) => state?.auth?.groups);
    const group = useSelector((state) => state?.auth?.group);

    const [currentGroup, setCurrentGroup] = useState(group);
    const [saving, setSaving] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [campaignCheckComplete, setCampaignCheckComplete] = useState(false);
    const [campaignExists, setCampaignExists] = useState(false);
    const [overrideExisting, setOverrideExisting] = useState(false);

    useEffect(() => {
        if (Array.isArray(groups) && groups.length > 1) {
            setShowGroupSelector(true);
        }
    }, [groups]);

    useEffect(() => {
        checkCampaignExists();
    }, [currentGroup]);

    const submitCampaign = () => {
        setSaving(true);
        axios
            .post(
                `/api/v1/campaigns/${campaign.uuid}/group-campaigns/${currentGroup.uuid}`,
                {
                    override_existing: overrideExisting,
                }
            )
            .then(({ data: { data } }) => {
                setSaving(false);
                onComplete(data);
                addToast({
                    type: "success",
                    content: trans("Campaign generated successfully"),
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setSaving(false);
                addToast({
                    type: "alert",
                    content: trans("Error generating campaign"),
                    showFor: 5000,
                });
            });
    };

    const checkCampaignExists = () => {
        setCampaignCheckComplete(false);
        axios
            .get(
                `/api/v1/campaigns/${campaign.uuid}/group-campaigns/${currentGroup.uuid}`
            )
            .then(({ data }) => {
                setCampaignExists(true);
                setCampaignCheckComplete(true);
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    setCampaignExists(false);
                    setCampaignCheckComplete(true);
                } else {
                    addToast({
                        type: "alert",
                        content: trans("Error loading campaign"),
                        showFor: 5000,
                    });
                }
            });
    };
    console.log({ campaign, onCancel, onComplete });
    return (
        <>
            <Row>
                {updating ? (
                    <Column>
                        <h2>{trans("Switching group...")}</h2>
                    </Column>
                ) : (
                    <>
                        <Column>
                            <h2>
                                {trans(
                                    "Generating {campaignName} Campaign for {groupName}",
                                    {
                                        groupName: currentGroup.name,
                                        campaignName: campaign.name,
                                    }
                                )}
                            </h2>

                            <p>
                                {trans(
                                    "You are creating the {campaignName} campaign for {groupName}. ",
                                    {
                                        groupName: currentGroup.name,
                                        campaignName: campaign.name,
                                    }
                                )}
                                {showGroupSelector &&
                                    trans(
                                        "If you wish to change groups you can select a different group below."
                                    )}
                            </p>
                        </Column>
                        {campaignExists && (
                            <Column>
                                <Panel style={{ padding: "1rem" }}>
                                    <h3>
                                        {trans(
                                            "The {campaignName} Campaign already exists for {groupName}",
                                            {
                                                groupName: currentGroup.name,
                                                campaignName: campaign.name,
                                            }
                                        )}
                                    </h3>
                                    {showGroupSelector ? (
                                        <p>
                                            {trans(
                                                "If you choose to continue, the existing campaign will be overwritten.  If you wish to keep the existing campaign, you can cancel or change the group.  Otherwise, check the box below and press Generate to overwrite the existing campaign."
                                            )}
                                        </p>
                                    ) : (
                                        <p>
                                            {trans(
                                                "If you choose to continue, the existing campaign will be overwritten.  If you wish to keep the existing campaign, you can cancel.  Otherwise check the box below and press Generate to overwrite the existing campaign."
                                            )}
                                        </p>
                                    )}
                                    <StyledCheckbox
                                        text={intl.formatMessage({
                                            id: "I understand and wish to continue",
                                        })}
                                        style={{ marginTop: "10px" }}
                                        name="overrideExisting"
                                        checked={overrideExisting}
                                        onChange={(e) =>
                                            setOverrideExisting(
                                                e.target.checked
                                            )
                                        }
                                        disabled={saving || updating}
                                    />
                                </Panel>
                            </Column>
                        )}
                        {showGroupSelector && (
                            <Column>
                                <GroupSwitcher
                                    hideLabel={true}
                                    onGroupChange={(group) =>
                                        setCurrentGroup(group)
                                    }
                                    onSubmitting={(value) => setUpdating(value)}
                                />
                            </Column>
                        )}
                    </>
                )}
            </Row>
            <Row>
                <Column
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                    }}
                >
                    <IconTextButton
                        type="alert"
                        onClick={onCancel}
                        showCircle={false}
                        icon="times"
                    >
                        {trans("Cancel")}
                    </IconTextButton>
                    <SubmitButton
                        onClick={submitCampaign}
                        disabled={
                            saving ||
                            updating ||
                            !campaignCheckComplete ||
                            (!overrideExisting && campaignExists)
                        }
                        submitting={saving}
                    >
                        {trans("Generate")}
                    </SubmitButton>
                </Column>
            </Row>
        </>
    );
};

export default GenerateCampaign;
