import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import {
    GreedyCarousel,
    CarouselItem,
    Centred,
    Loading,
    Panel,
    Row,
    Column,
    IconTextButton,
    CircularProgress,
} from "@cortexglobal/rla-components";
import { withTheme } from "styled-components";
import usePanelData from "../usePanelData";
import { isSet } from "lodash";

const FlexPanel = styled(Panel)`
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    margin-bottom: 0;
    box-shadow: none;
    padding-top: 0;
    box-sizing: content-box;
    flex-wrap: wrap;
`;

const KpiColumn = styled(Panel)`
    /* padding: 2rem; */
    text-align: center;
    min-width: 20%;
    max-width: 20%;
    box-shadow: none;
    padding-bottom: 0.2rem;
    padding-top: 0.5rem;
    margin-bottom: 0;
`;

const KpiColumnHeading = styled.h3`
    margin-top: 1.5rem;
    word-wrap: break-word;
`;

const MarketingKpiBreakdownOutput = ({
    panelId,
    viewBy,
    period,
    startDate,
    endDate,
    theme,
}) => {
    const panelData = usePanelData(
        panelId,
        `/api/v1/marketing-kpi/detail/summary`,
        { viewBy, period, startDate, endDate }
    );

    if (!panelData || !panelData.loaded) {
        return (
            <Centred style={{ width: "100px" }}>
                <Loading type="spin" align="center" height="50" width="50" />
            </Centred>
        );
    }

    const { data } = panelData;

    const getViewByName = (viewBy) => {
        if (viewBy == "quarter") {
            return trans("Quarter");
        } else if (viewBy == "year") {
            return trans("Year");
        } else if (viewBy == "specific") {
            return trans("Custom Date Range");
        }
    };

    const getPeriodLabel = () => {
        if (data.period_label) {
            return data.period_label;
        }
        return startDate + " - " + endDate;
    };

    const getStrokeColor = (percentage) => {
        if (percentage > 85) {
            return theme.colors.success;
        } else if (percentage > 60) {
            return theme.colors.warning;
        }
        return theme.colors.alert;
    };

    return (
        <>
            <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
                <p style={{ paddingLeft: "1rem", width: "50%" }}>
                    <strong>{trans("Period")}:</strong> {getPeriodLabel()} (
                    {getViewByName(viewBy)})
                </p>
                <p
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        width: "auto",
                        textAlign: "right",
                        marginLeft: "auto",
                    }}
                >
                    <IconTextButton as={Link} to="/plan" expanded>
                        {trans("Go To Marketing Planner")}
                    </IconTextButton>
                </p>
            </div>
            <div style={{ flexBasis: "100%", height: 0 }} />
            <FlexPanel>
                {data.metrics.map((metric) => {
                    return (
                        <KpiColumn key={metric.value}>
                            <CircularProgress
                                backgroundPadding={5}
                                strokeWidth={12}
                                value={metric.percentage}
                                initialAnimation={true}
                                strokeColor={getStrokeColor(metric.percentage)}
                                text={metric.percentage + "%"}
                            />
                            <KpiColumnHeading>{metric.text}</KpiColumnHeading>
                            <p>
                                <strong>{trans("Target")}:</strong>{" "}
                                {metric.target}
                            </p>
                            <p>
                                <strong>{trans("Actual")}:</strong>{" "}
                                {metric.actual}
                            </p>
                        </KpiColumn>
                    );
                })}
            </FlexPanel>
        </>
    );
};

export default withTheme(MarketingKpiBreakdownOutput);
