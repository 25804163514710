import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const G = styled.g.attrs((props) => ({
    width: props.theme.checkbox.size || 15,
    height: props.theme.checkbox.size,
    transform: `translate(${props.theme.checkbox.size / 2}, ${
        props.theme.checkbox.size / 2
    })`,
}))`
    transform: translate(50%, 50%);
`;

const CheckboxCheck = styled.path.attrs(({ checked, theme }) => {
    return {
        transform: checked ? `scale(${theme.checkbox.size / 20})` : "scale(0)",
        transition: "transform 0.25s ease",
        d: "M-2.73,6.11-8.29.56a.86.86,0,0,1,0-1.21l1.21-1.21a.86.86,0,0,1,1.21,0l3.75,3.75,8-8a.86.86,0,0,1,1.21,0L8.29-4.9a.86.86,0,0,1,0,1.21L-1.52,6.11A.86.86,0,0,1-2.73,6.11Z",
    };
})`
    fill: ${(props) => props.theme.checkbox.color};
    transition: transform 0.25s ease;
`;

const CheckboxSquare = styled.rect.attrs((props) => ({
    width: props.theme.checkbox.size,
    height: props.theme.checkbox.size,
}))`
    stroke-width: 1px;
    fill: ${({ theme, disabled }) =>
        disabled ? theme.checkbox.disabled : theme.checkbox.background};
    stroke: ${(props) => props.theme.checkbox.stroke};
    rx: ${(props) => props.theme.checkbox.radius}px;
    ry: ${(props) => props.theme.checkbox.radius}px;
`;

const Checkbox = styled.svg.attrs((props) => ({
    width: props.theme.checkbox.size,
    height: props.theme.checkbox.size,
    viewBox: `0 0 ${props.theme.checkbox.size} ${props.theme.checkbox.size}`,
}))`
    display: inline-block;
    min-width: 1rem;
    margin-top: 0.1rem;
`;

const Input = styled.input`
    display: none;
    // &:checked + ${Checkbox} ${CheckboxCheck} {
    //     transform: scale(1);
    // }
`;

const Wrapper = styled.label`
    padding-bottom: ${({ marginBottom }) =>
        marginBottom ? marginBottom : 1}rem;
    display: ${(props) => {
        return props.inline ? "inline-flex" : "flex";
    }};
    align-items: center;
    padding-right: ${(props) => props.theme.spacing.margin}rem;
    cursor: pointer;
`;

const Text = styled.span`
    display: inline-block;
    margin-left: 0.5rem;
`;

const OptionIcon = styled(FontAwesomeIcon)`
    margin-left: 0.5rem;
`;
class StyledCheckbox extends Component {
    render() {
        const {
            checked,
            text,
            onChange,
            inline,
            name,
            disabled,
            children,
            icon,
            ...rest
        } = this.props;

        return (
            <Wrapper inline={inline} {...rest}>
                <Input
                    name={name}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                />
                <Checkbox>
                    <CheckboxSquare disabled={disabled} />
                    <G>
                        <CheckboxCheck checked={checked} />
                    </G>
                </Checkbox>

                {icon && <OptionIcon icon={icon} transform="grow-4" />}
                {text && <Text>{" " + text}</Text>}
                {children}
            </Wrapper>
        );
    }
}

StyledCheckbox.displayName = "StyledCheckbox";

StyledCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
    checked: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default StyledCheckbox;
