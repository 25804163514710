import React from "react";

const MessagesContext = React.createContext({
    selectedMessage: null,
    setSelectedMessage: () => {},
    unreadTotals: {
        inbox: 0,
        outbox: 0,
        notifications: 0
    },
    emptyViewPaneMessage: "",
    setBoxUnreadTotal: () => {}
});

export default MessagesContext;
