import React from "react";
import _ from "lodash";
import MFC from "./FolderContent";
import FolderLine from "./FolderLine";

class FolderContent extends React.Component {
    openFolder = item => {
        this.props.openFolder(item);
    };

    updateExpandList = item => {
        this.props.updateExpandList(item);
    };

    render() {
        const { subFolders, folders, currentFolder, expandList } = this.props;

        return (
            <div>
                {subFolders &&
                    Object.keys(subFolders).map(index => {
                        const subFolder = subFolders[index];

                        return (
                            <div
                                key={index}
                                style={{
                                    marginLeft: "10px"
                                }}
                            >
                                <FolderLine
                                    folder={subFolder}
                                    currentFolder={currentFolder}
                                    expandList={expandList}
                                    openFolder={this.openFolder}
                                    updateExpandList={this.updateExpandList}
                                />

                                <div>
                                    {folders[subFolder.uuid] &&
                                        this.props.expandList.indexOf(
                                            subFolder.uuid
                                        ) > -1 && (
                                            <MFC
                                                folders={folders}
                                                subFolders={
                                                    folders[subFolder.uuid]
                                                        .subFolders
                                                }
                                                openFolder={this.openFolder}
                                                loadContents={this.loadContents}
                                                currentFolder={currentFolder}
                                                updateExpandList={
                                                    this.updateExpandList
                                                }
                                                expandList={expandList}
                                            />
                                        )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }
}

export default FolderContent;
