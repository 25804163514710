import React, { useContext } from "react";
import styled from "styled-components";

import { Layout } from "../interfaces/layout";
import { SlideContainer } from "../components/slideComponents";
import MicrositeContext from "../MicrositeContext";
import { SidePanelNav } from "../components/Navigation";

const StyledSlideContainer = styled(SlideContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Navigation = ({ settings, transitions, disableSequencing }: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        config: { pages },
    } = useContext(MicrositeContext);
    if (editing || disableSequencing) {
        currentTransitionIndex = 2;
    }

    return (
        <StyledSlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            <SidePanelNav pages={pages} visible={true} onClose={() => {}} />
        </StyledSlideContainer>
    );
};

export default Navigation;
