import React, { Component } from "react";

import AssetWidgetConfiguration from "../assetComponents/AssetWidgetConfiguration";
import AssetListingOutput from "./AssetListingOutput";

class AssetListing extends Component {
    render() {
        if (this.props.configuring) {
            return (
                <AssetWidgetConfiguration
                    displayType="listing"
                    {...this.props}
                />
            );
        }
        return <AssetListingOutput {...this.props} />;
    }
}

export default AssetListing;
