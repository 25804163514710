import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { SelectField, Row, Column } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

/**
 * TODO - Investigate swapping react-paginate for this:
 * https://github.com/ChoTotOSS/react-paginating
 * It uses render props, so would allow for a nicer component architecture
 * It doesn't appear to natively handle margin pages (neighbouring pages)
 * but this tutorial explains an option:
 * https://scotch.io/tutorials/build-custom-pagination-with-react
 */
import ReactPaginate from "react-paginate";
import ResultSummary from "./ResultSummary";

const FlexWrapper = styled.div`
    flex: 1 1 0px;
`;

const FlexColumn = styled(Column)`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent || "center"};
`;
/**
 * Having to use a wrapper to allow us to add CSS without the .css file, which allows us to hook into the theme
 */
export const PaginationWrapper = styled(Row)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    .pagination {
        display: flex;
        flex: 1 1 0px;
        flex-grow: 1;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
        margin: ${({ theme, margin }) =>
            margin
                ? margin
                : theme.pagination.margin
                ? theme.pagination.margin
                : "inherit"};
        li {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            border: ${({ theme }) => theme.pagination.border};
            background: ${({ theme }) => theme.pagination.background};
            transition: all 500ms;
            margin: 0 0.1em;

            a {
                display: flex;
                min-height: ${({ theme }) => theme.pagination.height};
                line-height: ${({ theme }) => theme.pagination.height};
                padding-left: 15px;
                padding-right: 15px;
                color: ${({ theme }) => theme.pagination.color};
            }

            &.next a,
            &.previous a {
                font-size: ${({ theme }) => theme.pagination.arrowSize};
                color: ${({ theme }) => theme.pagination.arrowColor};
            }
            &:hover {
                background: ${({ theme }) => theme.pagination.hoverBackground};

                a {
                    color: ${({ theme }) => theme.pagination.hoverColor};
                }
            }
            &.active {
                background: ${({ theme }) => theme.pagination.activeBackground};
                border: none;
                a {
                    color: ${({ theme }) => theme.pagination.activeColor};
                    font-weight: ${({ theme }) =>
                        theme.pagination.activeFontWeight};
                }
            }
        }
    }
`;

const StyledSelectField = styled(SelectField)`
    margin: 0 0 0 1em;
`;

const Pagination = ({
    children,
    currentPage,
    from,
    to,
    total,
    pageCount,
    onPageChange,
    marginPagesDisplayed,
    pageRangeDisplayed,
    previousLabel,
    nextLabel,
    breakLabel,
    breakClassName,
    containerClassName,
    subContainerClassName,
    activeClassName,
    alwaysShow,
    showSummary,
    perPage,
    onPerPageChange,
    loaded = true,
    ...rest
}) => {
    // console.log("pageCount", pageCount);
    if (
        !alwaysShow &&
        typeof pageCount !== "undefined" &&
        pageCount <= 1 &&
        !onPerPageChange
    ) {
        return null;
    }

    const perPageOptions = [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 40, text: "40" },
        { value: 60, text: "60" },
        { value: 80, text: "80" },
        { value: 100, text: "100" },
    ];

    return (
        <PaginationWrapper {...rest}>
            {onPerPageChange && total > 0 ? (
                <FlexColumn medium={3} justifyContent="start">
                    {trans("View")}
                    <StyledSelectField
                        value={perPage}
                        name="perPage"
                        onChange={onPerPageChange}
                        options={perPageOptions}
                    ></StyledSelectField>
                </FlexColumn>
            ) : (
                <FlexColumn medium={3}>&nbsp;</FlexColumn>
            )}
            {pageCount > 1 ? (
                <FlexColumn medium={6} justifyContent="center">
                    <ReactPaginate
                        onPageChange={onPageChange}
                        pageCount={pageCount}
                        forcePage={currentPage}
                        marginPagesDisplayed={marginPagesDisplayed}
                        pageRangeDisplayed={pageRangeDisplayed}
                        previousLabel={previousLabel}
                        nextLabel={nextLabel}
                        breakLabel={breakLabel}
                        breakClassName={breakClassName}
                        containerClassName={containerClassName}
                        subContainerClassName={subContainerClassName}
                        activeClassName={activeClassName}
                        {...rest}
                    />
                </FlexColumn>
            ) : (
                <FlexColumn medium={6} justifyContent="center">
                    &nbsp;
                </FlexColumn>
            )}
            {showSummary && total > 0 ? (
                <FlexColumn medium={3} justifyContent="end">
                    <ResultSummary
                        loaded={loaded}
                        from={from}
                        to={to}
                        total={total}
                    />
                </FlexColumn>
            ) : (
                <FlexColumn medium={3} justifyContent="end">
                    &nbsp;
                </FlexColumn>
            )}
        </PaginationWrapper>
    );
};

Pagination.displayName = "Pagination";

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    /* The current starting result */
    from: PropTypes.number,
    /* The current ending result */
    to: PropTypes.number,
    /* The overall result total */
    total: PropTypes.number,
    marginPagesDisplayed: PropTypes.number,
    pageRangeDisplayed: PropTypes.number,
    previousLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    nextLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    breakLabel: PropTypes.string,
    breakClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    subContainerClassName: PropTypes.string,
    activeClassName: PropTypes.string,
    alwaysShow: PropTypes.bool,
    showSummary: PropTypes.bool,
};

Pagination.defaultProps = {
    previousLabel: <span>&lsaquo;</span>,
    nextLabel: <span>&rsaquo;</span>,
    breakLabel: "...",
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 2,
    breakClassName: "break-me",
    containerClassName: "pagination",
    subContainerClassName: "pages pagination",
    activeClassName: "active",
    alwaysShow: false,
    showSummary: true,
};

export default Pagination;
