import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { Badge, CloseButton } from "@cortexglobal/rla-components";
import { cancelOrderProcess } from "@cortexglobal/marketing-planner";

const OrderHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-bottom: 2px solid #ededed;
    position: fixed;
    top: 0;
    z-index: 10;
    height: 100px;
    padding: 0 2em;
`;

const Steps = styled.div`
    display: flex;
    margin-bottom: 1em;
`;

const Step = styled.div`
    width: 35px;
    height: 35px;
    background: lightgrey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;
    font-weight: bold;

    &:after {
        content: "";
        width: 10px;
        height: 2px;
        background: lightgrey;
        position: absolute;
        right: -10px;
    }

    &:last-of-type {
        &:after {
            display: none;
        }
    }

    ${({ complete }) =>
        complete &&
        `
        background: #484848;
        color: white;
    `}
`;

const OrderDetails = styled.div`
    display: flex;
    align-items: center;
`;

const OrderDetail = styled.p`
    margin: 0 3em 0 0;
`;

const OrderHeaderSpacer = styled.div`
    height: 100px;
`;

const OrderHeader = ({ order, path }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const getStep = () => {
        switch (path.split("/")[1]) {
            case "resources":
                if (path.split("/")[2] === "my-media") {
                    return 2;
                }
                return 1;
            case "my-media":
                return 2;
            case "orders":
                if (path.split("/").at(-1) === "edit") {
                    return 3;
                }
                return 4;
        }
    };

    const getTitle = () => {
        switch (path.split("/")[1]) {
            case "resources":
                if (path.split("/")[2] === "my-media") {
                    return "Customise Selected Asset";
                }
                return "Select an Asset";
            case "my-media":
                return "Customise Selected Asset";
            case "orders":
                if (path.split("/").at(-1) === "edit") {
                    return "Provide Additional Information";
                }
                return "Confirm Order Details";
        }
    };

    const cancelOrder = () => {
        dispatch(cancelOrderProcess());
        history.push(`/plan`);
    }

    const { task, activity } = order.data;
    return (
        <>
            <OrderHeaderSpacer />
            <OrderHeaderContainer>
                <div>
                    <Steps>
                        <Step complete={getStep() >= 1}>1</Step>
                        <Step complete={getStep() >= 2}>2</Step>
                        <Step complete={getStep() >= 3}>3</Step>
                        <Step complete={getStep() >= 4}>4</Step>
                    </Steps>
                    <h3 style={{ margin: "0" }}>{getTitle()}</h3>
                </div>
                <OrderDetails>
                    <OrderDetail>
                        <b>Campaign:</b>
                        <Badge
                            style={{ margin: "0 1rem" }}
                            type="warning"
                        >
                            {task.ref}
                        </Badge>
                        {task.name}
                    </OrderDetail>
                    {activity.media_category && (
                        <OrderDetail>
                            <b>Media Channel:</b> &nbsp;{" "}
                            {activity.media_category.text}
                        </OrderDetail>
                    )}
                </OrderDetails>
                <CloseButton
                    onClick={cancelOrder}
                    style={{ position: "static" }}
                />
            </OrderHeaderContainer>
        </>
    );
};

export default OrderHeader;
