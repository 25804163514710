import React, { Component } from "react";
import PropTypes from "prop-types";

import panels from "./panels/index";

class ConfigurePanel extends Component {
    renderComponent = (panel, callback) => {
        return React.createElement(panels[panel.component], {
            configuring: true,
            submitPanel: callback,
            panel,
            ...panel.props
        });
    };
    render() {
        const { panel, creating, addPanel, updatePanel } = this.props;
        //Render the element, in configuration mode, needs to fire the addPanel
        //or updatePanel callbacks, as required.  If creating new just render the component
        //as we're in the creation dialog, otherwise render it wrapped with editing UI

        //
        if (creating) {
            return this.renderComponent(panel, addPanel);
        }

        return <div>{this.renderComponent(panel, updatePanel)}</div>;
    }
}

ConfigurePanel.propTypes = {
    panel: PropTypes.object.isRequired,
    addPanel: PropTypes.func,
    updatePanel: PropTypes.func,
    creating: PropTypes.bool
};

ConfigurePanel.defaultProps = {
    creating: true
};
export default ConfigurePanel;
