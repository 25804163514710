import React, { useState, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    HelpText,
    SimpleDropzone,
    Tooltip,
    Panel,
    Button,
    Image,
    LoadingIconSmall,
    useAddToast,
    useToasts,
} from "@cortexglobal/rla-components";
import { useInterval } from "@cortexglobal/cortex-utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDownloadAsset } from "../hooks/useDownloadAsset";

import { SectionHeading } from "./components/SectionHeading";

const ManageFiles = ({ asset, standardOnChange, updateAsset }) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [remainingTries, setRemainingTries] = useState(100);
    const [thumbnail, setThumbnail] = useState(asset.thumbnail);

    const { downloadAssetFiles } = useDownloadAsset();

    const onThumbnailDrop = useCallback((acceptedFiles) => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Thumbnail is being uploaded"),
        });
        const fd = new FormData();
        fd.append("thumbnail", acceptedFiles[0]);
        axios
            .post(`/api/v1/admin/assets/${asset.uuid}/thumbnail`, fd)
            .then(({ data }) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                standardOnChange({ name: "thumbnail", value: data.thumbnail });
                setThumbnail(data.thumbnail);
            })
            .catch((e) => console.log(e));
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        const fd = new FormData();
        fd.append("mainFile", acceptedFiles[0]);
        //console.log(acceptedFiles);
        axios
            .post(`/api/v1/admin/assets/${asset.uuid}/main-file`, fd)
            .then(({ data }) => {
                updateAsset(data.data);
            })
            .catch((e) => console.log(e));
    }, []);

    useInterval(
        () => {
            if (!thumbnail && remainingTries > 0) {
                axios
                    .get(`/api/v1/assets/${asset.uuid}/thumbnail`)
                    .then((result) => {
                        setThumbnail(result.data);
                    });
                setRemainingTries(remainingTries - 1);
            }
        },
        thumbnail || remainingTries < 1 ? null : 7000
    );
    const downloadMainFile = (asset) => {
        downloadAssetFiles(
            asset.uuid,
            asset.name + "." + asset.main_file_extension
        );
    };

    const readableBytes = (bytes) => {
        var i = Math.floor(Math.log(bytes) / Math.log(1024)),
            sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        return (bytes / Math.pow(1024, i)).toFixed(1) * 1 + " " + sizes[i];
    };

    return (
        <React.Fragment>
            {!asset.microsite && asset.main_file_path && (
                <React.Fragment>
                    <SectionHeading>{trans("Main File")}</SectionHeading>

                    <Panel>
                        {asset.main_file_path ? (
                            <React.Fragment>
                                <Row>
                                    <Column medium={3}>&nbsp;</Column>
                                    <Column medium={3}>
                                        <i>{trans("File")}</i>
                                    </Column>
                                    <Column medium={1}>
                                        <i>{trans("Filetype")}</i>
                                    </Column>
                                    <Column medium={1}>
                                        <i>{trans("Filesize")}</i>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column
                                        medium={3}
                                        style={{ textAlign: "right" }}
                                    >
                                        {trans("Main file Upload")}
                                    </Column>
                                    <Column medium={3}>
                                        <a
                                            style={{
                                                cursor: "pointer",
                                                color: "blue",
                                                overflowWrap: "break-word",
                                            }}
                                            onClick={(event) =>
                                                downloadMainFile(asset)
                                            }
                                        >
                                            {asset.main_file_original_name}
                                        </a>
                                    </Column>
                                    <Column medium={1}>
                                        {asset.main_file_extension}
                                    </Column>
                                    <Column medium={1}>
                                        {asset.main_file_size
                                            ? readableBytes(
                                                  asset.main_file_size
                                              )
                                            : "unknown"}
                                    </Column>
                                    <Column medium={4}>
                                        {asset.configuration ? (
                                            <Panel
                                                type="alert"
                                                style={{
                                                    padding: ".5rem",
                                                    margin: "1rem 0",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "8pt",
                                                        fontStyle: "italic",
                                                    }}
                                                >
                                                    {trans(
                                                        "Please be aware that uploading a new main file may remove your configuration customization"
                                                    )}
                                                </p>
                                                <SimpleDropzone
                                                    name="mainFile"
                                                    onDrop={(event) =>
                                                        onDrop(event)
                                                    }
                                                >
                                                    <Button
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                        onClick={() => {
                                                            return false;
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon="upload" />{" "}
                                                        {trans(
                                                            "Upload new main file"
                                                        )}
                                                    </Button>
                                                </SimpleDropzone>
                                            </Panel>
                                        ) : (
                                            <SimpleDropzone
                                                name="mainFile"
                                                onDrop={(event) =>
                                                    onDrop(event)
                                                }
                                            >
                                                <Button
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                    onClick={() => {
                                                        return false;
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="upload" />{" "}
                                                    {trans(
                                                        "Upload new main file"
                                                    )}
                                                </Button>
                                            </SimpleDropzone>
                                        )}
                                    </Column>
                                </Row>
                            </React.Fragment>
                        ) : (
                            <Row>
                                <Column
                                    medium={3}
                                    style={{ textAlign: "right" }}
                                >
                                    {trans("Main file Upload")}
                                </Column>
                                <Column medium={3}>
                                    <i>{trans("No main file selected")} </i>
                                </Column>
                            </Row>
                        )}
                    </Panel>
                </React.Fragment>
            )}

            <SectionHeading>{trans("Preview Image")}</SectionHeading>

            <Panel>
                <Row>
                    <Column medium={3} style={{ textAlign: "right" }}>
                        {trans("Preview Image Upload")}
                        <HelpText>{trans("Optional")}</HelpText>
                    </Column>
                    <Column medium={3}>
                        <SimpleDropzone
                            name="previewFile"
                            onDrop={(event) => onThumbnailDrop(event)}
                        >
                            <Button
                                style={{ textDecoration: "none" }}
                                onClick={() => {
                                    return false;
                                }}
                            >
                                <FontAwesomeIcon icon="upload" />{" "}
                                {trans("Upload")}
                            </Button>
                        </SimpleDropzone>
                    </Column>
                    <Column medium={6}>
                        {!thumbnail && remainingTries > 0 ? (
                            <span
                                style={{
                                    display: "inline-flex",
                                    flexDirection: "row",
                                    gap: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <LoadingIconSmall />{" "}
                                <Tooltip
                                    text={trans(
                                        "The thumbnail may be being generated, if you want to upload one, please use the upload button"
                                    )}
                                >
                                    {trans("Loading thumbnail")}
                                </Tooltip>
                            </span>
                        ) : (
                            <Image
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100px",
                                }}
                                alt="asset thumbnail"
                                src={thumbnail}
                            />
                        )}
                    </Column>
                </Row>
            </Panel>

            {/*<Column medium={6}>
                <Row>
                    <Column>
                        <h3>{trans("Preview Image")}</h3>
                    </Column>
                    <Column>
                        <Image
                            style={{
                                maxWidth: "100%",
                                maxHeight: "400px"
                            }}
                            alt="asset thumbnail"
                            src={
                                `${process.env.REACT_APP_BASE_URL}` +
                                asset.thumbnail
                            }
                        />
                    </Column>
                    <Column>
                        <SimpleDropzone
                            name="previewFile"
                            onDrop={event => onThumbnailDrop(event)}
                        >
                            <Button
                                style={{ textDecoration: "none" }}
                                onClick={() => {
                                    return false;
                                }}
                                expanded
                            >
                                <FontAwesomeIcon icon="upload" />{" "}
                                {trans("Upload new preview file")}
                            </Button>
                        </SimpleDropzone>
                    </Column>
                </Row>
            </Column>
            <Column medium={6}>
                <Row>
                    <Column>
                        <h3>{trans("Preview Image")}</h3>
                    </Column>
                    <Column>
                        {asset.main_file_path ? (
                            <React.Fragment>
                                <Column medium={6}>
                                    <strong>
                                        {asset.main_file_original_name}
                                    </strong>
                                </Column>
                                <Column medium={6}>
                                    <Button
                                        onClick={event =>
                                            downloadMainFile(asset)
                                        }
                                        expanded
                                        download
                                        style={{ cursor: "pointer" }}
                                    >
                                        <FontAwesomeIcon icon="download" />{" "}
                                        {trans("Download")}
                                    </Button>
                                </Column>
                            </React.Fragment>
                        ) : (
                            <span>{trans("No main file selected")}</span>
                        )}
                    </Column>
                    <Column>
                        <Panel
                            type="alert"
                            style={{ padding: "1rem", margin: "1rem 0" }}
                        >
                            <p>
                                {trans(
                                    "Please be aware that uploading a new main file may remove your configuration customization"
                                )}
                            </p>
                            <SimpleDropzone
                                name="mainFile"
                                onDrop={event => onDrop(event)}
                            >
                                <Button
                                    style={{ textDecoration: "none" }}
                                    onClick={() => {
                                        return false;
                                    }}
                                    expanded
                                >
                                    <FontAwesomeIcon icon="upload" />{" "}
                                    {trans("Upload new main file")}
                                </Button>
                            </SimpleDropzone>
                        </Panel>
                    </Column>
                </Row>
                                </Column>*/}
        </React.Fragment>
    );
};

export default ManageFiles;
