import axios from "axios";
import { saveAs } from "file-saver";

export const SHOW_ASSETS_MODAL = "SHOW_ASSET_MODAL";

export function showModal(modalName = "") {
    return {
        type: SHOW_ASSETS_MODAL,
        modalName: modalName
    };
}

export const HIDE_ASSETS_MODAL = "HIDE_ASSET_MODAL";

export function hideModal() {
    return {
        type: HIDE_ASSETS_MODAL
    };
}

export const GET_ASSETS = "GET_ASSETS";
export const ASSET_UPDATE_PAGINATION = "ASSET_UPDATE_PAGINATION";

// export function getAssets({ ...params }) {
//     return dispatch => {
//         axios
//             .get("/api/v1/filtered-assets", { ...params, page: 1 })
//             .then(({ result: { data, meta } }) => {
//                 dispatch({
//                     type: GET_ASSETS,
//                     data,
//                     loaded: true
//                 });
//                 dispatch({
//                     type: ASSET_UPDATE_PAGINATION,
//                     pagination: meta
//                 });
//             })
//             .catch(e => e);
//     };
// }

export const GET_ASSET = "GET_ASSET";

export function getAsset(uuid) {

    return dispatch => {
        return axios
            .get("/api/v1/assets/" + uuid)
            .then(({ data: { data } }) => {

                dispatch({
                    type: GET_ASSET,
                    data: { ...data, loaded: true }
                });
            });
    };
}

export function selectAsset(asset) {
    return {
        type: GET_ASSET,
        data: { ...asset, loaded: true }
    };
}

export const RESET_ASSET = "RESET_ASSET";
export function resetAsset() {
    return {
        type: RESET_ASSET
    };
}

export const UPDATE_ASSET_FAVORITES = "UPDATE_ASSET_FAVORITES";
export function addFavorite(uuids) {
    uuids = Array.isArray(uuids) ? uuids : [uuids];

    return dispatch =>
        axios
            .post("/api/v1/assets/favorite/add", { uuids })
            .then(({ data: { data } }) => {
                dispatch({
                    type: UPDATE_ASSET_FAVORITES,
                    data: { uuids, favorite: true, loaded: true }
                });
            })
            .catch(e => e);
}

export function removeFavorite(uuids) {
    uuids = Array.isArray(uuids) ? uuids : [uuids];

 
    return dispatch =>
        axios
            .post("/api/v1/assets/favorite/remove", { uuids: uuids })
            .then(({ data: { data } }) => {
                dispatch({
                    type: UPDATE_ASSET_FAVORITES,
                    data: { uuids, favorite: false, loaded: true }
                });
            })
            .catch(e => e);
}

export const DELETE_ASSET = "DELETE_ASSET";

export function deleteAsset(uuid) {
    return dispatch =>
        axios
            .delete(`/api/v1/admin/assets/${uuid}`)
            .then(({ data: { data } }) => {
                dispatch({
                    type: DELETE_ASSET,
                    data
                });
                return data;
            })
            .catch(e => e);
}

export function createAssetRevision(uuid) {
    return dispatch =>
        axios
            .get(`/api/v1/admin/assets/revision/${uuid}`)
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_ASSET,
                    data
                });
                return data;
            })
            .catch(e => e);
}

export function submitForApproval(asset) {
    return dispatch =>
        axios
            .put(`/api/v1/admin/assets/submission`, { uuid: asset.uuid })
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_ASSET,
                    data
                });
                return data;
            })
            .catch(e => e);
}

export function approveAsset(asset) {
    return dispatch =>
        axios
            .put(`/api/v1/admin/assets/approve`, { uuid: asset.uuid })
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_ASSET,
                    data
                });
                return data;
            })
            .catch(e => e);
}

export function rejectAsset(asset) {
    return dispatch =>
        axios
            .put(`/api/v1/admin/assets/reject`, { uuid: asset.uuid })
            .then(({ data: { data } }) => {
                dispatch({
                    type: GET_ASSET,
                    data
                });
                return data;
            })
            .catch(e => e);
}

export const ARCHIVE_ASSET = "ARCHIVE_ASSET";

export function archiveAsset(uuid) {
    return dispatch =>
        axios
            .put(`/api/v1/assets/archive`, { uuid: uuid })
            .then(({ data: { data } }) => {
                dispatch({
                    type: ARCHIVE_ASSET,
                    data
                });
                return data;
            })
            .catch(e => e);
}

// export const RESET_ASSET_FOR_EDITING = "RESET_ASSET_FOR_EDITING";

// export function resetAssetForEditing() {
//     return {
//         type: RESET_ASSET_FOR_EDITING
//     };
// }

export const SET_ASSET_FILTERS = "SET_ASSET_FILTERS";

export function getFilters(filterConfig) {
    return dispatch => {
        axios
            .get("/api/v1/assets/filters", {
                params: { filterConfig: filterConfig }
            })
            .then(({ data }) => {
                dispatch({
                    type: SET_ASSET_FILTERS,
                    data: data
                });
            })
            .catch(e => e);
    };
}
export const CLEAR_ASSET_FILTERS = "CLEAR_ASSET_FILTERS";

export function clearFilters() {
    return { type: CLEAR_ASSET_FILTERS };
}

export const SET_CATEGORY_TOTALS = "SET_CATEGORY_TOTALS";

export function getCategoryTotals(
    parameters,
    favorites,
    search,
    folderUuid,
    libraryArea
) {
    return dispatch => {
        axios
            .get("/api/v1/assets/category-totals", {
                params: {
                    qry: parameters,
                    favorites: favorites,
                    search: search,
                    folderUuid,
                    libraryArea
                }
            })
            .then(({ data }) => {
                dispatch({
                    type: SET_CATEGORY_TOTALS,
                    data: data
                });
            })
            .catch(e => e);
    };
}

export const SET_ASSET_SEARCHABLE_META = "SET_ASSET_SEARCHABLE_META";

export function getSearchableMeta(filterConfig) {
    return dispatch => {
        axios
            .get("/api/v1/assets/searchable-meta", {
                params: { filterConfig: filterConfig }
            })
            .then(({ data }) => {
                dispatch({
                    type: SET_ASSET_SEARCHABLE_META,
                    data: data
                });
            })
            .catch(e => e);
    };
}

export const SET_ASSET_CONFIG_OPTIONS = "SET_ASSET_CONFIG_OPTIONS";

export function getAssetConfigOptions() {
    return dispatch => {
        axios
            .get("/api/v1/assets/config-options")
            .then(({ data }) => {
                dispatch({
                    type: SET_ASSET_CONFIG_OPTIONS,
                    data: data
                });
            })
            .catch(e => e);
    };
}

// export const SET_ASSET_CUSTOM_FORM = "SET_ASSET_CUSTOM_FORM";

// export function getAssetCustomForm(key) {
//     return dispatch => {
//         axios
//             .get("/api/v1/admin/assets-custom-form", {
//                 params: { key: key }
//             })
//             .then(({ data }) => {
//                 dispatch({
//                     type: SET_ASSET_CUSTOM_FORM,
//                     data: data
//                 });
//             })
//             .catch(e => e);
//     };
// }
// export const GET_ASSET_TYPES = "GET_ASSET_TYPES";

// export function getAssetTypes() {
//     return dispatch => {
//         axios
//             .get("/api/v1/assets/types")
//             .then(({ data }) => {
//                 dispatch({
//                     type: GET_ASSET_TYPES,
//                     data,
//                     loaded: true
//                 });
//             })
//             .catch(e => e);
//     };
// }

export const GET_MEDIA_OUTPUT_TYPES = "GET_MEDIA_OUTPUT_TYPES";

export function getMediaOutputTypes(assetType) {
    return dispatch => {
        axios
            .get(`/api/v1/media-output-types/${assetType}`)
            .then(({ data }) => {
                dispatch({
                    type: GET_MEDIA_OUTPUT_TYPES,
                    data,
                    loaded: true
                });
            })
            .catch(e => e);
    };
}

export const GET_ASSET_AUTOFILL_FIELDS = "GET_ASSET_AUTOFILL_FIELDS";

export function getAssetAutofillFields() {
    return dispatch => {
        axios
            .get("/api/v1/assets/autofill-fields")
            .then(({ data }) => {
                dispatch({
                    type: GET_ASSET_AUTOFILL_FIELDS,
                    data,
                    loaded: true
                });
            })
            .catch(e => e);
    };
}

export const LOAD_SEARCH_RESULTS = "LOAD_SEARCH_RESULTS";
export const LOADING_SEARCH_RESULTS = "LOADING_SEARCH_RESULTS";

export function searchAssets(parameters, page = 1) {

    return dispatch => {
        dispatch({ type: LOADING_SEARCH_RESULTS });
        axios
            .post(`/api/v1/assets/search?page=${page}`, parameters)
            .then(({ data: { data, meta } }) => {
                dispatch({
                    type: LOAD_SEARCH_RESULTS,
                    data,
                    selectedFilters:
                        parameters && parameters.filters
                            ? parameters.filters
                            : "",
                    search:
                        parameters && parameters.search
                            ? parameters.search
                            : "",
                    favourites:
                        parameters && parameters.favourites ? true : false,
                    folderUuid:
                        parameters && parameters.folderUuid
                            ? parameters.folderUuid
                            : ""
                });
                dispatch({
                    type: ASSET_UPDATE_PAGINATION,
                    pagination: meta
                });
            })
            .catch(e => console.log(e));
    };
}

export const SET_REFRESH_SEARCH = "SET_REFRESH_SEARCH";

export function setRefreshSearch(refresh) {
    return { type: SET_REFRESH_SEARCH, refresh };
}
export const CLEAR_ASSET_SEARCH = "CLEAR_ASSET_SEARCH";

export function clearSearch() {
    return { type: CLEAR_ASSET_SEARCH };
}

// export const DOWNLOAD_ASSET_PDF = "DOWNLOAD_ASSET_PDF";

// export function downloadAssetPDF(uuid, filename) {
//     axios
//         .get(`/api/v1/assets/download-pdf/` + uuid, {
//             responseType: "blob",
//             timeout: 30000
//         })
//         .then(response => {
//             saveAs(new Blob([response.data]), filename);
//         })
//         .catch(e => {});

//     return {
//         type: DOWNLOAD_ASSET_PDF
//     };
// }

// export const DOWNLOAD_ASSET_IMAGES = "DOWNLOAD_ASSET_IMAGES";

// export function downloadAssetImageZip(uuid, filename) {
//     axios
//         .get(`/api/v1/assets/download-image-zip/` + uuid, {
//             responseType: "blob",
//             timeout: 30000
//         })
//         .then(response => {
//             saveAs(new Blob([response.data]), filename);
//         })
//         .catch(e => {});

//     return {
//         type: DOWNLOAD_ASSET_IMAGES
//     };
// }

export function updatePresentation(uuid) {
    return axios.get(`/api/v1/assets/presentations/${uuid}`).then(response => {
        if (response.status !== 200) {
            console.warn(response.status);
            return;
        }

        const { data } = response.data;

        const presentationData = JSON.stringify(data);

        localStorage.setItem(`${uuid}`, presentationData);
        return data;
    });
}
