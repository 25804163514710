import React from "react";

//@ts-ignore cortex
import { useIntl } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { Button } from "@cortexglobal/rla-components";
import { FileType } from "../FileType";

interface FileManagementButtonsProps {
    file: FileType;
    deleteFile: Function;
}

export const DeleteFileButton = ({
    file,
    deleteFile
}: FileManagementButtonsProps) => {
    const intl = useIntl();

    const performDelete = (event: MouseEvent) => {
        deleteFile(file.uuid);
        event.preventDefault();
    };

    return (
        <Button
            title={intl.formatMessage({ id: "Download" })}
            type="alert"
            onClick={performDelete}
        >
            X
        </Button>
    );
};
