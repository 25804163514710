import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    SubmitButton,
    FormikInput,
    InputError,
} from "@cortexglobal/rla-components";
import { trans, injectIntl, intl } from "@cortexglobal/rla-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { resetPassword } from "@cortexglobal/cortex-auth-redux";

const LoginSchema = Yup.object().shape({
    email: Yup.string().email(trans("Invalid email")).required(trans("Required")),
    password: Yup.string()
        .min(2, trans("Too Short!"))
        .max(70, trans("Too Long!"))
        .required(trans("Required")),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], trans("Passwords must match"))
        .required(trans("Required")),
});

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        const { location, email, buttonText } = props;
        let { token } = props;

        if (token === undefined && location !== undefined) {
            token = queryString.parse(this.props.location.search).token;

            if (!token) {
                return (window.location.href = "/auth/forgotten");
            }
        }

        let title =
            props.title === undefined ? trans("Reset Password") : props.title;

        this.state = {
            email: email,
            title: title,
            token: token,
            tokenError: false,
            success: false,
            successMessage: "",
            buttonText:
                buttonText === undefined ? trans("Reset Password") : buttonText,
        };
    }

    submitForm = (values, { setErrors }) => {
        this.props
            .resetPassword(values)
            .then((response) => {
                this.setState((state) => ({
                    success: true,
                    successMessage: response.response_trans,
                }));
            })
            .catch((e) => {
                parseValidationErrors(e)
                    .then(({ errors }) => {
                        setErrors(errors);
                    })
                    .catch((e) => {
                        if (
                            e.response &&
                            e.response.status &&
                            e.response.status === 403
                        ) {
                            this.setState((state) => ({
                                tokenError: e.response.data.data.response_trans,
                            }));
                        }
                    });
            });
        return false;
    };

    render() {
        const {
            token,
            tokenError,
            success,
            successMessage,
            title,
            email,
            buttonText,
        } = this.state;

        const { intl } = this.props;

        return (
            <div>
                <h3>{title}</h3>

                {success ? (
                    <>
                        <p>
                            {trans("Success!")} {successMessage}
                        </p>
                        <p>
                            <a href="/auth/login" className="button">
                                {trans("Go to Login")}
                            </a>
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            {trans(
                                "Passwords must be at least 8 characters long and contain at least 1 letter, 1 number and 1 special character."
                            )}
                        </p>

                        <Formik
                            initialValues={{
                                token: token,
                                email: email,
                                password: "",
                                password_confirmation: "",
                            }}
                            onSubmit={this.submitForm}
                            validationSchema={LoginSchema}
                        >
                            {({ submitting }) => (
                                <Form className="form-group">
                                    <FormikInput
                                        type="hidden"
                                        name="token"
                                        placeholder=""
                                        value={token}
                                        readOnly={true}
                                    />

                                    {tokenError && (
                                        <InputError error={tokenError} />
                                    )}

                                    <FormikInput
                                        type={
                                            email === undefined
                                                ? "text"
                                                : "hidden"
                                        }
                                        name="email"
                                        label={
                                            email === undefined
                                                ? intl.formatMessage({
                                                      id: "Email",
                                                  })
                                                : ""
                                        }
                                        value={email}
                                        placeholder="user@domain.com"
                                    />
                                    <FormikInput
                                        type="password"
                                        name="password"
                                        label={trans("New Password")}
                                        placeholder=""
                                    />
                                    <FormikInput
                                        type="password"
                                        name="password_confirmation"
                                        label={trans("Confirm Password")}
                                        placeholder=""
                                    />
                                    <SubmitButton
                                        expanded
                                        centered
                                        label={buttonText}
                                        submitting={submitting}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetPassword,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ResetPassword));
