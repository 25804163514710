import React from "react";
import styled from "styled-components";
import { StyledRadio } from "@cortexglobal/rla-components";
export const FormWrapper = styled.section`
    margin-top: 1.2rem;
`;

export const YesNoSelector = (props) => {
    const loading = props.loading === undefined ? false : props.loading;
    const error = props.error === undefined ? "" : props.error;

    const { name, value, onChange } = props;

    return (
        <>
            <StyledRadio
                name={name}
                text="Yes"
                checked={value === true}
                onChange={(e) => onChange(name, true)}
                disabled={loading}
                error={error}
                inlineRadioButtons
            />
            <StyledRadio
                name={name}
                text="No"
                checked={value === false}
                onChange={(e) => onChange(name, false)}
                disabled={loading}
                error={error}
                inlineRadioButtons
            />
        </>
    );
};
