import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import DashboardPanelForm, {
    DashboardPanel,
} from "../../forms/DashboardPanelForm";

export const DashboardPanelCreate = ({ path }) => {
    const history = useHistory();

    const item: DashboardPanel = {
        slug: "",
        title: "",
        description: "",
        component: "",
        initial_width: 1,
        initial_height: 1,
        min_width: 1,
        min_height: 1,
        lock_aspect_ratio: false,
        full_width: false,
        configurable: false,
        allow_multiple: false,
    };

    const submitForm = (values) => {
        return axios
            .post(`/api/v1/admin/dashboard-panel`, values)
            .then(({ data }) => {
                //As creation was successful move them on to the appropriate listing page
                history.push(`${path}`);
            });
    };
    return (
        <div>
            <PageTitle title={<>{trans("New Dashboard Panel")}</>}>
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <DashboardPanelForm item={item} submitForm={submitForm} />
                </Panel>
            </Row>
        </div>
    );
};

export default DashboardPanelCreate;
