import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    FormRow,
    InputField,
    IconTextButton,
    SelectField,
    DraftJSField,
} from "@cortexglobal/rla-components";
import SurveyManagementContext from "../SurveyManagementContext";
import AliasField from "./AliasField";

const CopiedText = styled.div`
    opacity: ${(props) => (props.show ? "1" : "0")};
    transition: opacity 650ms ease;
`;

const SurveyFormDetails = ({
    survey,
    setSurveyData,
    handleChange,
    errors,
    singleColumn = false,
    collapse = false,
}) => {
    const [showCopied, setShowCopied] = useState(false);
    const { surveySystem } = useContext(SurveyManagementContext);

    const { isSubmitting, disableEdit, loading, loaded } = surveySystem;

    const [surveyTypesLoaded, setSurveyTypesLoaded] = useState(false);
    const [surveyTypes, setSurveyTypes] = useState([]);

    useEffect(() => {
        if (loaded === true) {
            loadSurveyTypes(survey);
        }
    }, [loaded]);

    const loadSurveyTypes = (surveyData) => {
        setSurveyTypesLoaded(false);

        axios.get("/api/v1/surveys/types").then(({ data: { data } }) => {
            setSurveyTypes(
                data.map((type) => {
                    return { value: type.alias, text: type.name };
                })
            );

            // If there is only 1 type, set it as the default, as the
            // user will not be able to change it.
            if (data.length === 1) {
                handleChange("form_type", data[0].alias);
            }

            setSurveyTypesLoaded(true);
        });
    };

    const copyUrl = () => {
        navigator.clipboard.writeText(survey.survey_url);
        setShowCopied(true);

        const copyTimeout = window.setTimeout(() => {
            setShowCopied(false);
        }, 3000);
        return () => window.clearTimeout(copyTimeout);
    };

    // const onNameChange = (e) => {
    //     const alias = slugify(e.value);
    //
    //     handleChange("alias", alias);
    //     handleChange("name", e.value);
    // };
    //
    // console.log(survey);

    return (
        <>
            {surveyTypesLoaded &&
                Array.isArray(surveyTypes) &&
                surveyTypes.length > 1 && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="form_type"
                        label={trans("Form Type")}
                        required={true}
                        helpText={
                            disableEdit
                                ? trans(
                                      "This form has responses so the survey type cannot be changed."
                                  )
                                : trans("Required")
                        }
                        error={errors.form_type}
                    >
                        <SelectField
                            name="form_type"
                            onChange={(e) => handleChange(e.name, e.value)}
                            disabled={isSubmitting || disableEdit}
                            value={survey.form_type}
                            options={surveyTypes}
                        />
                    </FormRow>
                )}
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="name"
                label={trans("Title")}
                required={true}
                helpText={trans("Required")}
                error={errors.name}
            >
                <InputField
                    name="name"
                    onChange={(e) => handleChange(e.name, e.value)}
                    disabled={isSubmitting}
                    value={survey.name}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="alias"
                label={trans("Url Alias")}
                helpText={trans(
                    "The alias is used to generate the url for the form"
                )}
                error={errors.alias}
            >
                <AliasField
                    base={survey.name}
                    uuid={survey.uuid}
                    name="alias"
                    onChange={(e) => handleChange(e.name, e.value)}
                    disabled={isSubmitting}
                    value={survey.alias}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="description"
                label={trans("Description")}
                helpText={trans("Description of the form")}
            >
                <DraftJSField
                    name="description"
                    onChange={(e) => handleChange(e.name, e.value)}
                    disabled={isSubmitting}
                    value={survey.description}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="survey_link"
                label={trans("Link")}
            >
                <div style={{ marginBottom: "1rem" }}>
                    {!survey.uuid && (
                        <span>
                            {trans(
                                "Your link will be generated after creation"
                            )}
                        </span>
                    )}
                    {survey.uuid && (
                        <>
                            <a href={survey.survey_url} target="_blank">
                                {survey.survey_url}
                            </a>
                            <IconTextButton
                                onClick={copyUrl}
                                showCircle={false}
                                icon={faCopy}
                            ></IconTextButton>
                            <CopiedText show={showCopied}>
                                {trans("Copied to clipboard")}
                            </CopiedText>
                        </>
                    )}
                </div>
            </FormRow>
        </>
    );
};

export default SurveyFormDetails;
