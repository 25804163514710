import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import queryString from "query-string";
import {
    Panel,
    Row,
    Column,
    Button,
    PageTitle,
    Loading,
    IconTextButton,
} from "@cortexglobal/rla-components";
import PlannerTaskForm from "../forms/PlannerTaskForm";
import { trans } from "@cortexglobal/rla-intl";
import { HeaderEmphasis } from "../components/overviewComponents";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";

const AddTask = ({
    category,
    viewPlanner,
    groups,
    user,
    group,
    allUserGroups,
    viewTask,
    addActivity,
    location,
    orphanedActivities,
    setOrphanedActivities,
    ...props
}) => {
    const [relatedUuid, setRelatedUuid] = useState("");
    const [eventUuid, setEventUuid] = useState("");
    const [related, setRelated] = useState({
        data: {},
        loaded: false,
        error: false,
        checked: false,
    });

    useEffect(() => {
        //Clear any orphaned activities
        setOrphanedActivities([]);

        // if (task?.uuid) {
        const { relatedUuid, eventUuid } = queryString.parse(location.search);

        //If a relatedUuid has been passed in we want to create a new campaign based
        //on the information in the related one.
        if (relatedUuid) {
            setRelatedUuid(relatedUuid);
            axios
                .get(`api/v1/planner/tasks/${relatedUuid}`)
                .then((result) => {
                    //Unset certain fields that we want the user to select
                    //when creating a related campaign

                    //Destructuring to remove fields that shouldn't be passed to "duplicate"
                    let { planned_spend, description, ...data } =
                        result.data.data;

                    return setRelated({
                        data,
                        loaded: true,
                        checked: true,
                    });
                })
                .catch((e) => {
                    //If the related task has failed to load just continue adding a 'normal' task
                    setRelated({
                        ...related,
                        error: true,
                        checked: true,
                    });
                });
        } else if (eventUuid) {
            axios
                .get(`/api/v1/events/${eventUuid}`)
                .then((result) => {
                    const event = result.data.data;

                    setEventUuid(eventUuid);
                    setRelated({
                        data: {
                            name: event.name,
                            start_date: event.start_date,
                            end_date: event.end_date,
                        },
                        loaded: true,
                        checked: true,
                    });
                })
                .catch((e) => {
                    //If the related task has failed to load just continue adding a 'normal' task
                    setRelated({
                        ...related,
                        error: true,
                        checked: true,
                    });
                });
        } else {
            setRelated({ ...related, checked: true });
        }
    }, []);

    return (
        <>
            <PageTitle
                title={
                    category && category.name ? (
                        <>
                            {trans("Adding Campaign to")}{" "}
                            <HeaderEmphasis>{category.name}</HeaderEmphasis>
                        </>
                    ) : related.loaded ? (
                        <>
                            {trans("Add Campaign related to")}{" "}
                            <HeaderEmphasis>{related.data.name}</HeaderEmphasis>
                        </>
                    ) : (
                        trans("Add Campaign")
                    )
                }
            >
                <IconTextButton
                    onClick={() => {
                        viewPlanner();
                    }}
                    showCircle={false}
                    icon={faArrowLeft}
                >
                    {trans("Cancel")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Column>
                    <Panel>
                        {related.loaded && (
                            <Row>
                                <Column>
                                    <p>
                                        {trans(
                                            'You are creating a campaign based on "{name}". This form has been pre-populated with some information from that campaign.  You can change what you like and will have to select some information',
                                            {
                                                name: related.data.name,
                                            }
                                        )}
                                    </p>
                                </Column>
                            </Row>
                        )}
                        <Row>
                            {!related.checked ||
                            ((relatedUuid !== "" || eventUuid !== "") &&
                                !related.loaded &&
                                !related.error) ? (
                                <Loading />
                            ) : (
                                <PlannerTaskForm
                                    action="addTask"
                                    groups={groups}
                                    user={user}
                                    group={group}
                                    allUserGroups={allUserGroups}
                                    category={category}
                                    onGoBack={(task) =>
                                        task ? viewTask(task) : viewPlanner()
                                    }
                                    relatedUuid={relatedUuid}
                                    eventUuid={eventUuid}
                                    task={
                                        related.loaded
                                            ? related.data
                                            : undefined
                                    }
                                    addActivity={addActivity}
                                    orphanedActivities={orphanedActivities}
                                    setOrphanedActivities={
                                        setOrphanedActivities
                                    }
                                />
                            )}
                        </Row>
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default AddTask;
