import React, { useEffect, useState } from "react";
import axios from "axios";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { Button } from "@cortexglobal/rla-components";
import { CommentList } from "./CommentList";
import { CommentForm } from "./CommentForm";

interface CommentProps {
    smartRef: string;
    user: { name: string; uuid: string };
    deletable: boolean;
    alwaysShowForm: boolean;
    showTitle: boolean;
    newestFirst: boolean;
    forceRefresh: boolean;
}

export const Comments = ({
    smartRef,
    user,
    deletable = true,
    alwaysShowForm = true,
    showTitle = false,
    newestFirst = true,
    forceRefresh = false
}: CommentProps) => {
    const [comments, setComments] = useState([]);
    const [showForm, setShowForm] = useState(alwaysShowForm);

    useEffect(() => {
        axios
            .get(`/api/v1/comments/${smartRef}`)
            .then(res => {
                setComments(res.data.data);
            })
            .catch(e => {
                console.log(e.message);
            });
    }, [forceRefresh]);
    const addComment = (values: {}, smartRef: string) => {
        return axios
            .post(`/api/v1/comments/${smartRef}`, values)
            .then(res => {
                const { data } = res.data;
                setComments(res.data.data);
                setShowForm(false);
                return data;
            })
            .catch(e => {
                console.log(e.message);
            });
    };
    const editComment = (values: {}, commentUuid: string) => {
        return axios
            .put(`/api/v1/comments/${smartRef}/comment/${commentUuid}`, values)
            .then(res => {
                setComments(res.data.data);
            })
            .catch(e => {
                console.log(e.message);
            });
    };
    const deleteComment = (commentUuid: string) => {
        if (!deletable) {
            return null;
        }
        return axios
            .delete(`/api/v1/comments/${smartRef}/comment/${commentUuid}`)
            .then(res => {
                setComments(res.data.data);
            })
            .catch(e => {
                console.log(e.message);
            });
    };
    return (
        <React.Fragment>
            <div style={{ padding: "0.2em 2em 1em 2em" }}>
                <CommentList
                    comments={comments}
                    user={user}
                    editComment={editComment}
                    deleteComment={deleteComment}
                    deletable={deletable}
                    showTitle={showTitle}
                    newestFirst={newestFirst}
                />
                {!alwaysShowForm && !showForm && (
                    <Button onClick={() => setShowForm(!showForm)}>
                        {trans("Add a comment")}
                    </Button>
                )}
                {(alwaysShowForm || showForm) && (
                    <CommentForm
                        smartRef={smartRef}
                        addComment={addComment}
                        setShowForm={setShowForm}
                        alwaysShowForm={alwaysShowForm}
                        showTitle={showTitle}
                    />
                )}
            </div>
        </React.Fragment>
    );
};
