import React from "react";
import PropTypes from "prop-types";
import FormLabel from "../styledElements/label";

import { AsyncPaginate } from "react-select-async-paginate";

import { useTheme } from "styled-components";
import { transparentize } from "@cortexglobal/cortex-utilities";
import { trans } from "@cortexglobal/rla-intl";

import { generateCustomStyles } from "./StyledSelectAdvanced";
import InputError from "./inputError";

const StyledSelectAsync = ({
    name,
    onChange,
    creatable,
    tags,
    value,
    multi,
    allowSelectAll,
    hideSelectedOptions,
    label,
    menuPlacement,
    async,
    ...rest
}) => {
    const globalTheme = useTheme();
    const shouldKeyDownEventCreateNewOption = ({ keyCode }) => {
        return [9, 13].indexOf(keyCode) != -1; // Create new options only on tab and enter
    };

    const handleChange = (event) => {
        return onChange(event);
    };

    const processValue = (value) => {
        return Array.isArray(value)
            ? value.map((selectedItem) => {
                  return { ...selectedItem, label: selectedItem.text };
              })
            : [];
    };
    const customStyles = generateCustomStyles({
        ...rest,
        theme: { ...globalTheme },
    });

    return (
        <>
            {label && (
                <FormLabel style={{ marginBottom: "0.5rem" }} name={name}>
                    {label}
                </FormLabel>
            )}
            <AsyncPaginate
                {...rest}
                debounceTimeout={800}
                isMulti={multi}
                styles={customStyles}
                name={name}
                defaultValue={processValue(value)}
                value={processValue(value)}
                onChange={handleChange}
                shouldKeyDownEventCreateNewOption={
                    shouldKeyDownEventCreateNewOption
                }
                menuPlacement={menuPlacement}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: transparentize(
                            globalTheme.colors.accent,
                            0.6
                        ),
                        primary: globalTheme.colors.accent,
                    },
                })}
            />
            <InputError error={rest.error} />
        </>
    );
};

StyledSelectAsync.displayName = "StyledSelectAsync";

StyledSelectAsync.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    labelProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
    }),
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    async: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
};

StyledSelectAsync.defaultProps = {
    error: "",
    labelProps: {
        label: "",
    },
    height: 34,
    menuPlacement: "auto",
    debounceTimeout: 1,
};

export default StyledSelectAsync;
