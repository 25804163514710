import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { IconTextButton } from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import NotifyModal from "./NotifyModal";
import { trans } from "@cortexglobal/rla-intl";

export const NotifyButton = ({
    title = "",
    subject = "",
    prependedMessage = "",
    smartRef = "",
    buttonText = "",
}) => {
    const { can } = usePermissions();

    const [showModal, setShowModal] = useState(false);

    const onClose = () => {
        setShowModal(false);
    };

    if (!can("communication-send-messages")) {
        return null;
    }

    return (
        <>
            <IconTextButton
                icon={faMessage}
                showCircle={false}
                onClick={() => setShowModal(true)}
            >
                {buttonText ? buttonText : trans("Notify")}
            </IconTextButton>
            <NotifyModal
                title={title}
                subject={subject}
                prependedMessage={prependedMessage}
                visible={showModal}
                onClose={onClose}
                smartRef={smartRef}
            />
        </>
    );
};

export default NotifyButton;
