import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    UserInfoText,
    UserInfoTitle,
    Button,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { LogoutWrapper } from "@cortexglobal/cortex-auth-react-router-dom";

const UserInfo = ({ user, group, role, theme }) => {
    const { infoSections } = theme.userInfo;

    if (user.isGuest) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "flex-end",
                    maxWidth: "10rem",
                }}
            >
                <LogoutWrapper loginUrl="/auth/login">
                    {({ logout }) => (
                        <Button
                            as="a"
                            href="/auth/login"
                            onClick={logout}
                            style={{
                                textAlign: "center",
                            }}
                            height="30px"
                        >
                            <FontAwesomeIcon
                                icon="sign-in-alt"
                                transform="grow-3"
                            />
                            {trans("Login")}
                        </Button>
                    )}
                </LogoutWrapper>
                <Button
                    as={Link}
                    to="/auth/register"
                    style={{
                        textAlign: "center",
                    }}
                    height="30px"
                >
                    <FontAwesomeIcon icon="user-plus" transform="grow-3" />{" "}
                    {trans("Register")}
                </Button>
            </div>
        );
    }
    return (
        <React.Fragment>
            <UserInfoTitle
                style={{
                    fontSize: "1rem",
                    lineHeight: "1.2",
                }}
            >
                {infoSections.name && user.name}
                {infoSections.email && (
                    <span
                        style={{
                            fontSize: "0.9rem",
                            lineHeight: "1.2",
                            display: "block",
                            fontWeight: "normal",
                        }}
                    >
                        {" "}
                        {user.email}
                    </span>
                )}
            </UserInfoTitle>
            {infoSections.group && (
                <UserInfoText
                    style={{
                        fontSize: "0.9rem",
                        lineHeight: "1.2",
                        opacity: "1",
                    }}
                >
                    {group ? group.name : ""}
                </UserInfoText>
            )}
            {infoSections.role && (
                <UserInfoText
                    style={{
                        fontSize: "0.9rem",
                        opacity: "1",
                    }}
                >
                    {role ? role.name : ""}
                </UserInfoText>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { user, group, role } = state.auth;

    return { user, group, role };
};

export default withTheme(connect(mapStateToProps, null)(UserInfo));
