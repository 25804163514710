import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    Row,
    Column,
    Centred,
    Button,
    Loading,
    Table,
    Panel,
    IconTextButton,
    DatePicker,
} from "@cortexglobal/rla-components";
import {
    trans,
    FormattedCurrency,
    FormattedDate,
} from "@cortexglobal/rla-intl";
import moment from "moment";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import { format } from "@cortexglobal/cortex-utilities";

import {
    FlexPanel,
    FlexRow,
    FlexSection,
    StickyRow,
} from "../layoutComponents";
// import withPanelData from "./withPanelData";
import usePanelData from "./usePanelData";

const MarketingPlannerMonthSummary = ({ panelId, height }) => {
    // const [startDate, setStartDate] = useState(new Date());
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));

    //cortex-api-local.rla.co.uk/api/v1/budget/available/2020
    const panelData = usePanelData(
        panelId,
        `/api/v1/planner/get-tasks`,
        {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(addMonths(startDate, 1), "yyyy-MM-dd"),
        },
        [startDate]
    );

    const user = useSelector((state) => state.auth.user);

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }
    const { data } = panelData;

    const totals = data.reduce(
        (totals, task) => {
            //Only include group tasks in the totals
            if (!task.can_view_details) {
                return totals;
            }
            totals.plannedSpend += task.planned_spend.value;
            totals.actualSpend += task.actual_spend.value;
            totals.campaignCount++;
            totals.activityCount += task.activities
                ? task.activities.length
                : 0;
            // if (task.activities && task.activities.length > 0) {
            //     totals.activityCount += task.activities.length;
            //     // totals.actualSpend += task.activities.reduce(
            //     //     (total, activity) => {
            //     //         total += activity.cost.value;
            //     //         return total;
            //     //     },

            //     //     0
            //     // );
            //     // task.activities.forEach((activity) => {
            //     //     totals.actualSpend += activity.cost.value;
            //     //     totals.plannedSpend += activity.planned_cost.value;
            //     // });
            // }
            return totals;
        },
        {
            plannedSpend: 0,
            campaignCount: 0,
            actualSpend: 0,
            activityCount: 0,
        }
    );

    const tasks = data
        .filter((task) => {
            return task.can_view_details;
        })
        .sort((a, b) => {
            if (a.start_date > b.start_date) {
                return 1;
            }
            if (a.start_date < b.start_date) {
                return -1;
            }
            return 0;
        });

    const handleDateChange = (date) => {
        setStartDate(new Date(date));
    };

    return (
        <FlexPanel>
            <FlexRow>
                <FlexSection textAlign="left">
                    <h4>{trans("Month")}</h4>
                    <DatePicker
                        onChange={({ value }) => handleDateChange(value)}
                        name="month"
                        value={startDate}
                        showMonthYearPicker
                    />
                </FlexSection>
                <FlexSection>
                    <h4>{trans("Actual Spend")}</h4>
                    <FormattedCurrency
                        value={totals.actualSpend}
                        currency={user?.currency?.iso_code}
                    />
                </FlexSection>
                <FlexSection>
                    <h4>{trans("Planned Spend")}</h4>
                    <FormattedCurrency
                        value={totals.plannedSpend}
                        currency={user?.currency?.iso_code}
                    />
                </FlexSection>
                <FlexSection>
                    <h4>{trans("Campaigns")}</h4>
                    {totals.campaignCount}
                </FlexSection>
            </FlexRow>
            <Row style={{ width: "100%", flex: "1", paddingTop: "0.3rem" }}>
                <Column>
                    <Table>
                        <thead>
                            <tr>
                                <th>{trans("Start")}</th>
                                <th>{trans("End")}</th>
                                <th>{trans("Campaign Name")}</th>
                                <th style={{ textAlign: "right" }}>
                                    {trans("Planned")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.length <= 0 ? (
                                <tr>
                                    <td colSpan="4">
                                        {trans(
                                            "No upcoming activity this month"
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                tasks.map((task) => {
                                    return (
                                        <tr key={task.uuid}>
                                            <td>
                                                <FormattedDate
                                                    value={
                                                        new Date(
                                                            task.start_date
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <FormattedDate
                                                    value={
                                                        new Date(task.end_date)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/plan/task/${task.uuid}`}
                                                >
                                                    {task.name}
                                                </Link>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <FormattedCurrency
                                                    value={
                                                        task.planned_spend.value
                                                    }
                                                    currency={
                                                        user?.currency?.iso_code
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>
                </Column>
            </Row>
            <StickyRow center={false}>
                <Column style={{ paddingBottom: "1em" }}>
                    <IconTextButton
                        as={Link}
                        to="/plan"
                        style={{ paddingLeft: 0 }}
                    >
                        {trans("Marketing Planner")}
                    </IconTextButton>
                </Column>
            </StickyRow>
        </FlexPanel>
    );
};

export default MarketingPlannerMonthSummary;
