import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { SurveyFormLabel } from "../components/SurveyFormStyledComponents.js";

import {
    Row,
    Column,
    FormRow,
    InputField,
    Toggle,
    SelectField,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const SurveyRangeOptions = ({
    options,
    index,
    isSubmitting,
    disableEdit,
    updateOptionsData,
    singleColumn = false,
    collapse = false,
}) => {
    const updateRangeOptions = (name, value, index) => {
        let updatedOptions = { ...options };
        updatedOptions[name] = parseInt(value);
        updateOptionsData("range", updatedOptions, index);
    };

    return (
        <>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="default_value"
                label={trans("Range/Slider Settings")}
                // error={errors}
                // required={true}
            ></FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="min_value"
                label={trans("Min Value")}
                // error={errors}
                // required={true}
            >
                <InputField
                    name="min_value"
                    onChange={(e) => updateRangeOptions(e.name, e.value, index)}
                    disabled={isSubmitting || disableEdit}
                    value={options.min_value}
                    type={"number"}
                />
            </FormRow>

            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="max_value"
                label={trans("Max Value")}
                // error={errors}
                // required={true}
            >
                <InputField
                    name="max_value"
                    onChange={(e) => updateRangeOptions(e.name, e.value, index)}
                    disabled={isSubmitting || disableEdit}
                    value={options.max_value}
                    type={"number"}
                />
            </FormRow>

            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="default_value"
                label={trans("Default Value")}
                // error={errors}
                // required={true}
            >
                <InputField
                    name="default_value"
                    onChange={(e) => updateRangeOptions(e.name, e.value, index)}
                    disabled={isSubmitting || disableEdit}
                    value={options.default_value}
                    type={"number"}
                />
            </FormRow>
        </>
    );
};

export default SurveyRangeOptions;
