import React, { useContext } from "react";
import MicrositeContext from "./MicrositeContext";

const withNavItems = (WrappedComponent: fn) => {
    return () => {
        const {
            links,
            config: { pages }
        } = useContext(MicrositeContext);

        // This could be reusable
        const mainNavItems = links.reduce(
            (items: Array<Object>, item: object, index: number) => {
                const { value } = item;

                const itemProps = Object.values(value);

                return [
                    ...items,
                    {
                        [itemProps[0].type]: itemProps[0].value,
                        [itemProps[1].type]: itemProps[1].value
                    }
                ];
            },
            []
        );

        return <WrappedComponent mainNav={mainNavItems} />;
    };
};

/*
const withNavItems = (WrappedComponent: fn, config: object) => {
    return class extends Component {
		static contextType = MicrositeContext;

        render() {

			if (config === undefined || !config.constructor === Object) {
				return null;
			}

            const {
                nav: {
                    main: { value: rawNavItems }
                }
            } = config;




            const mainNavItems = rawNavItems.reduce(
                (items: Array<Object>, item: object, index: number) => {
                    const { value } = item;

                    const itemProps = Object.values(value);

                    return [
                        ...items,
                        {
                            [itemProps[0].type]: itemProps[0].value,
                            [itemProps[1].type]: itemProps[1].value
                        }
                    ];
                },
                []
            );

            return <WrappedComponent mainNav={mainNavItems} />;
        }
    };
};
*/

export default withNavItems;
