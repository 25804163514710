import React from "react";

import { GreedyCarousel, Centred, Loading } from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import DetailSummary from "../components/DetailSummary";
import SingleFigure from "../components/SingleFigure";

const PageViewTotalOutput = ({ height, layout, panelId, timeFrame }) => {
    const panelData = usePanelData(panelId, `/api/v1/page-view/total`, {
        timeFrame
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }
    // console.log("REST", rest);
    const { total, title, description } = panelData.data;
    return (
        <GreedyCarousel layout={layout}>
            <SingleFigure>{total}</SingleFigure>

            <Centred style={{ width: "100%" }}>
                <DetailSummary title={title} description={description} />
            </Centred>
        </GreedyCarousel>
    );
};

export default PageViewTotalOutput;
