import React from "react";
import { Table } from "@cortexglobal/rla-components";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";

const VenueSummary = (props) => {
    const { event } = props;
    const { user } = useUser();
    console.log("VenueSummary", event);
    return (
        <Table
            tableType="overview"
            zebraStripe="true"
            style={{ padding: "0 0.5rem 0.5rem" }}
        >
            <tbody>
                <tr>
                    <td width="40%">
                        <span>{trans("Name")}:</span>
                    </td>
                    <td>
                        <span>{event.venue_name}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Reference")}:</span>
                    </td>
                    <td>
                        <span>{event.venue_reference}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Contact Name")}:</span>
                    </td>
                    <td>
                        <span>{event.venue_primary}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Phone")}:</span>
                    </td>
                    <td>
                        <span>{event.venue_phone}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Email")}:</span>
                    </td>
                    <td>
                        <span>{event.venue_email}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Venue Hire Cost")}:</span>
                    </td>
                    <td>
                        <FormattedCurrency
                            value={event.venue_cost}
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Indoors")}:</span>
                    </td>
                    <td>
                        <span>{event.indoor ? trans("Yes") : trans("No")}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Address")}:</span>
                    </td>
                    <td>
                        <span>
                            {[
                                event.address_building,
                                event.address_street,
                                event.address_neighbourhood,
                                event.address_suburb,
                                event.address_city,
                                event.address_state,
                                event.postcode,
                                event.country,
                            ]
                                .filter((a) => a && a.length > 1)
                                .join(", ")}
                        </span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <span>{trans("Additional venue notes")}:</span>
                    </td>
                    <td>
                        <span>{event.venue_notes}</span>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default VenueSummary;
