import React, { useContext } from "react";
import { animated } from "react-spring";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlainButton } from "@cortexglobal/rla-components";
import { Settings } from "../interfaces/layout";
import MicrositeContext from "../MicrositeContext";

export const SlideWrapper = styled.main<Settings>`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: ${({ settings }) =>
        settings?.backgroundColor ? settings.backgroundColor : "none"};
    ${({ settings }) =>
        settings?.textColor
            ? `
        color: ${settings.textColor}`
            : null};
    ${({ settings, backgroundImage }) =>
        settings?.backgroundImage || backgroundImage
            ? `
                background: url("${
                    settings?.backgroundImage || backgroundImage
                }");
                background-size: cover;
                background-position: center;
            `
            : null}}
    position: relative;
`;

export const SlideContainer = styled(SlideWrapper)<Settings>`
    padding: 5vh 5vw;
    font-size: ${(props) => props.fontSize};
    margin: 0 auto;
    padding-bottom: 6rem;
    max-height: 100vh;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const SlideTitle = styled(animated.h1)<{ size?: number }>`
    display: flex;
    flex: 2;
    align-items: flex-end;
    position: relative;
    z-index: 1;
    font-size: ${({ size }) => (size ? size : 1.34)}em;
    font-weight: bold;
`;

export const SlideBody = styled(animated.div)`
    display: flex;
    flex: 4;
    align-items: flex-start;
    position: relative;
    z-index: 1;
`;

const SubtitleContent = styled.h2<SubtitleProps>`
    font-size: ${({ size }) => size};
    color: ${(props) => props.highlightColor};
    z-index: 1;
    font-weight: bold;
`;

export const Subtitle = ({
    size = "0.7em",
    children,
    groupName,
    highlightColor,
}: SubtitleProps) => {
    const { globalSettings } = useContext(MicrositeContext);

    if (globalSettings?.hideSlideNames) {
        return null;
    }
    return (
        <SubtitleContent highlightColor={highlightColor} size={size}>
            {groupName && (
                <span style={{ textTransform: "uppercase" }}>
                    {groupName}:{" "}
                </span>
            )}
            {children}
        </SubtitleContent>
    );
};

interface SubtitleProps {
    hidden?: boolean;
    highlightColor: string;
    groupName?: string;
    children?: React.ReactNode;
    size?: string;
}

const CloseButtonWrapper = styled(PlainButton)`
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    z-index: 1000;
    color: white;
    font-size: 1.5rem;
`;
export const CloseButton = ({ onClick }: any) => {
    return (
        <CloseButtonWrapper onClick={onClick}>
            <FontAwesomeIcon
                style={{ filter: "drop-shadow(0 0 0.1rem rgba(0,0,0,0.5))" }}
                icon={faTimes}
            />
        </CloseButtonWrapper>
    );
};
