import merge from "lodash/merge";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

/**
 * Colors
 *
 * The colors used in various sections of the site
 *
 * package: Core
 * customisation: likely
 */

export const colors = {
    primary: "#d74540",
    secondary: "#283c56",
    accent: "#d6001c",

    lightGray: "#f5f5f5",
    mediumGray: "#e8e8e8",
    darkGray: "#53565a",

    white: "#ffffff",
    gray100: "#f5f5f5",
    gray200: "#e8e8e8",
    gray300: "#cbc9c7",
    gray400: "#bbc0c7",
    gray500: "#aaafb7",
    gray600: "#9a9fa7",
    gray700: "#53565a",
    gray800: "#404346",
    gray900: "#2b2d2f",
    black: "#040404",

    alert: "#D54644",
    warning: "#ff9800",
    success: "#4caf50",
    info: "#00bcd4",
    background: "#e8e8e8",
    platinum: "#a2b8e4",
    gold: "#ffdb04",
    silver: "#dadada",
    bronze: "#ff8604",
    highlight: "#e6c2c1",
};

/**
 * Spacing
 *
 * The spacing used for elements on the site.  Most often expressed as 'em' values
 *
 * package: Core
 * customisation: maybe
 */
export const spacing = {
    radius: "0.2",
    padding: "1.2",
    margin: "0.8",
};

/**
 * Breakpoints
 *
 * The breakpoint used for responsive design.  These are used to adjust the layout and features of
 * the application based around the width of the browser window
 *
 * package: Core
 * customisation: maybe
 */
export const breakpoints = {
    small: 0,
    medium: 500,
    large: 700,
    xlarge: 1050,
};

/**
 * Fonts
 *
 * Can be used to inject @font-face rules into the global css of the application
 *
 * options: string containing the @font-face rules
 * package: Core
 * customisation: maybe
 */
const fonts = `
@font-face { font-family: "EuclidCircularA-Light"; src: url("/fonts/EuclidCircularA-Light.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Light.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Light.woff") format("font-woff"), url("/fonts/EuclidCircularA-Light.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-Bold"; src: url("/fonts/EuclidCircularA-Bold.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Bold.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Bold.woff") format("font-woff"), url("/fonts/EuclidCircularA-Bold.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-Medium"; src: url("/fonts/EuclidCircularA-Medium.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Medium.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Medium.woff") format("font-woff"), url("/fonts/EuclidCircularA-Medium.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-Regular"; src: url("/fonts/EuclidCircularA-Regular.eot?") format("font-eot"), url("/fonts/EuclidCircularA-Regular.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-Regular.woff") format("font-woff"), url("/fonts/EuclidCircularA-Regular.otf") format("font-opentype"); }

@font-face { font-family: "EuclidCircularA-SemiBold"; src: url("/fonts/EuclidCircularA-SemiBold.eot?") format("font-eot"), url("/fonts/EuclidCircularA-SemiBold.woff2") format("font-woff2"), url("/fonts/EuclidCircularA-SemiBold.woff") format("font-woff"), url("/fonts/EuclidCircularA-SemiBold.otf") format("font-opentype"); } 
`;

/**
 * Font
 *
 * Used within the theme to specify fonts of different weights, using the options injected by the
 * @font-face rules in the globalCss
 *
 * options: see the default setup below
 * package: Core
 * customisation: maybe
 */
const font = {
    fontLight: "'EuclidCircularA-Light', sans-serif",
    fontBody: "'EuclidCircularA-Regular', sans-serif",
    fontMedium: "'EuclidCircularA-Medium', sans-serif",
    fontSemibold: "'EuclidCircularA-SemiBold', sans-serif",
    fontBold: "'EuclidCircularA-Bold', sans-serif",
    fontHeading: "'EuclidCircularA-Bold', sans-serif",
};

/**
 * Logo
 *
 * Used to specify the location of the application's main logo.
 *
 * options: The URL of the logo.  Usually relative to the site root, but could be absolute.
 * package: Core
 * customisation: likely
 */
export const logo = `
/img/cortex-logo-black@2x.png
`;

/**
 * Planner
 *
 * Options for styling various parts of the planner.
 *
 * options: See default sample below
 * package: Planner
 * customisation: unlikely
 */

// const OWNER_TYPE_SELF = 'self';
// const OWNER_TYPE_HEAD_OFFICE = 'head-office';
// const OWNER_TYPE_GROUP_CONTAINER = 'group-container';
// const OWNER_TYPE_MERCHANT = 'merchant';
// const OWNER_TYPE_HEAD_OTHER = 'other';
export const planner = {
    colors: {
        task: {
            self: "#75abf5",
            "head-office": "#ff9800",
            "group-container": "#4caf50",
            merchant: colors.alert,
            other: colors.gray800,
        },
        activities: {
            parent: "#75abf5",
            child: colors.success,
            self: colors.warning,
            activity: colors.primary,
            other: colors.gray800,
            taskBackgroundGenerator: ({ baseColor, transparentizedColor }) => {
                return `repeating-linear-gradient(
                    -45deg,
                    ${baseColor},
                    ${baseColor} 5px,
                    ${transparentizedColor} 5px,
                    ${transparentizedColor} 10px
                  )`;
            },
            activityBackgroundGenerator: ({ baseColor }) => {
                return `${baseColor}`;
            },
        },
        // activities: {
        //     national: colors.primary,
        //     plan: colors.gray800,
        //     group: colors.success
        // },
        header: {
            label: colors.lightGray,
            button: colors.lightGray,
            background: colors.black,
            color: colors.white,
            publicHeader: {
                background: colors.accent,
                color: colors.white,
            },
        },
        micro: {
            label: colors.mediumGray,
            current: colors.white,
            currentBackground: colors.gray700,
            line: "rgba(0, 0, 0, 0.1)",
        },
        kpiCategory: {
            color: colors.white,
            background: colors.black,
        },
        calendarCategory: {
            color: colors.white,
            background: colors.black,
        },
        taskCategory: {
            label: colors.black,
            button: colors.secondary,
            border: colors.black,
            background: colors.gray400,
            color: colors.black,
            hoverColor: colors.white,
        },
        taskRow: {
            label: colors.darkGray,
            labelHover: colors.black,
            buttonHover: colors.gray200,
            background: colors.white,
            sidebar: colors.gray100,
        },
        /**
         * This whole section can be omitted and the taskRow sidebar styling will be used
         */
        // summaryColumn: {
        //     /**
        //      * This header section can be omitted and the general header styling will be used
        //      */
        //     header: {
        //         color: colors.white,
        //         background: colors.secondary
        //     },
        //     content: {
        //         color: colors.white,
        //         background: colors.secondary
        //     }
        // }
    },
    heights: {
        task: 22,
        activity: 16,
        spacing: 7,
    },
};

/**
 * Animations
 *
 * Used to specify animation durations and types for an install
 *
 * options: See default sample below
 * package: Core
 * customisation: unlikely
 */
let animations = {
    duration: {
        short: 250,
        medium: 400,
        long: 550,
    },
    types: {
        bounce: "cubic-bezier(0.68, -0.55, 0.265, 2.05)",
    },
};

animations.presets = {
    shortBounce: `${animations.duration.short}ms ${animations.types.bounce}`,
    mediumBounce: `${animations.duration.medium}ms ${animations.types.bounce}`,
    longBounce: `${animations.duration.long}ms ${animations.types.bounce}`,
};

/**
 * Default Theme Options
 *
 * These are the options above in a single object.  This is shared with installs to give an easier
 * starting point for their own theme options.  When the theme is being generated on the fly, the
 * specific install's theme options are merged with the default options, to allow them to be overridden
 * and then used throughout the rest of the theme.
 *
 * package: Core
 * customisation: never
 */
export const defaultThemeOptions = {
    animations: animations,
    breakpoints: breakpoints,
    colors: colors,
    fonts: fonts,
    font: font,
    spacing: spacing,
};

/**
 * Generate Theme
 *
 * This function merges a specific install's siteOptions and siteTheme with the defaults to create
 * the final customised theme
 *
 * package: Core
 * customisation: never
 *
 * @param {object} siteOptions An object based around the shape of the defaultThemeOptions above.  Entries in
 * this object will override matching entries in the defaultThemeOptions.  This allows for changes to easily
 * be made across the whole theme.
 *
 * @param {object}  siteTheme An object based around the shape of the defaultTheme (see inside the function).
 * Entries in this object will override matching entries in the defaultTheme.  This allows for more fine
 * grained control of the theme
 */
export const generateTheme = (siteOptions = {}, siteTheme = {}) => {
    const options = merge(defaultThemeOptions, siteOptions);
    const { animations, breakpoints, colors, fonts, font, spacing } = options;
    const defaultTheme = {
        //General Setup (alphabetical)
        animations: animations,
        breakpoints: breakpoints,
        colors: colors,
        fonts: fonts,
        spacing: spacing,

        //Specific component/area theming (alphabetical)

        alert: {
            borderWidth: 2,
            borderRadius: 0.25,
            default: "primary",
            title: {
                fontSize: 18,
                fontWeight: "bold",
                textTransform: "uppercase",
            },
            text: {
                fontSize: 14,
                fontWeight: "normal",
                textTransform: "normal",
            },
        },
        anchor: {
            color: colors.black,
        },
        asset: {
            groupAvailability: {
                multi: true,
            },
            preview: {
                background: colors.gray900,
                color: colors.white,
            },
        },
        badge: {
            color: colors.white,
            display: "inline-block",
            borderRadius: 2,
            lineHeight: 1.2,
        },
        body: {
            color: colors.black,
            background: colors.background,
            fontFamily: font.fontBody,
            fontSize: 16,
            expanded: false,
        },
        breadcrumb: {
            background: colors.gray100,
            crumb: {
                linkColor: colors.secondary,
                fontSize: "0.7rem",
            },
        },
        button: {
            fontSize: 14,
            minHeight: "2rem",
            borderWidth: 2,
            borderRadius: 3,
            fontWeight: "normal",
            textTransform: "none",
            textAlign: "left",
            default: "primary",
            hoverColor: colors.accent,
            types: {
                iconText: {
                    icon: faChevronRight,
                    boxShadow: "none",
                    foregroundColor: colors.black,
                    backgroundColor: colors.white,
                    backgroundAlpha: 0,
                    padding: 0,
                    textTransform: "none",
                    iconColor: "inherit",
                },
                default: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.secondary,
                    padding: 2,
                },
                clear: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.secondary,
                },
                submit: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.secondary,
                    padding: 2,
                },
                secondary: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.gray700,
                    padding: 2,
                },
                alert: {
                    hollow: false,
                    foregroundColor: colors.white,
                    backgroundColor: colors.alert,
                    padding: 2,
                },
                linkStyle: {
                    boxShadow: "none",
                    textDecoration: "underline",
                    foregroundColor: colors.black,
                    backgroundColor: colors.secondary,
                    backgroundAlpha: 0,
                    padding: 0,
                    textTransform: "none",
                },
            },
        },
        card: {
            borderRadius: 1,
            background: colors.white,
        },
        carousel: {
            height: "30vw",
            maxHeight: "360px",
            item: {
                padding: spacing.padding,
                color: colors.black,
                background: colors.white,
            },
            arrows: {
                size: 1.5,
                color: colors.black,
                strokeColor: colors.white,
                filter: "drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5))",
                leftIcon: "arrowLeft",
                rightIcon: "arrowRight",
            },
            dots: {
                size: 0.5,
                activeBackground: colors.primary,
                background: colors.white,
                radius: 2,
                margin: 0.2,
            },
        },
        chart: { budgeting: [colors.primary, colors.success, colors.warning] },
        checkbox: {
            color: colors.white,
            background: colors.secondary,
            disabled: colors.gray300,
            stroke: colors.black,
            radius: 3,
            size: 15,
        },
        checkboxButton: {
            activeColor: colors.primary,
            defaultColor: colors.secondary,
        },
        checkboxTree: {
            container: {
                maxHeight: 220,
            },
            button: {
                color: colors.white,
                background: colors.secondary,
                activeColor: colors.white,
                activeBackground: colors.accent,
            },
        },
        closeButton: {
            margin: spacing.margin,
            color: colors.black,
        },
        column: {
            columns: 12,
            padding: spacing.padding,
            breakpoints: {
                small: 0,
                medium: 350,
                large: 700,
                xlarge: 1050,
            },
        },
        content: {
            navigation: {
                background: colors.gray300,
                color: colors.black,
            },
            page: {
                background: colors.white,
                color: colors.black,
                radius: 3,
            },
            //Used to setup fonts in the rich text editor
            styles: {
                body: {
                    fontFamily: font.fontBody,
                    fontSize: "16px",
                },
                h1: {
                    fontFamily: font.fontHeading,
                    fontSize: "30px",
                },
                h2: {
                    fontFamily: font.fontHeading,
                    fontSize: "24px",
                },
                h3: {
                    fontFamily: font.fontHeading,
                    fontSize: "20px",
                },
                h4: {
                    fontFamily: font.fontHeading,
                    fontSize: "18px",
                },
                h5: {
                    fontFamily: font.fontHeading,
                    fontSize: "16px",
                },
                h6: {
                    fontFamily: font.fontHeading,
                    fontSize: "14px",
                },
            },
        },
        contextMenu: {
            minWidth: "10rem",
            background: colors.gray100,
            color: colors.black,
            hoverBackground: colors.gray300,
            hoverColor: colors.black,
        },
        circularProgress: {
            trailColor: colors.gray300,
            strokeColor: colors.accent,
            trailWidth: 8,
            strokeWidth: 8,
            strokeLinecap: "butt",
            textColor: colors.black,
        },
        dashboard: {
            pageTitle: {
                background: colors.white,
            },
            panel: {
                bar: {
                    height: 36,
                    iconSize: "1.2",
                    iconColor: colors.black,
                    background: colors.lightGray,
                    padding: "0.5",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    titleColor: colors.black,
                    textTransform: "uppercase",
                    padding: `${spacing.padding}rem`,
                    borderBottom: `none`,
                },
                content: {
                    background: colors.lightGray,
                    paddingTop: "1rem",
                },
                wrapper: {
                    radius: 5,
                    boxShadow: "rgba(0, 18, 27, 0.2) 0px 2px 6px",
                },
                resize: {},
            },
        },
        dropzone: {
            default: "primary",
            radius: 0,
            margin: spacing.margin,
            padding: spacing.padding,
            border: `1px dotted ${colors.gray300}`,
            types: {
                default: {
                    background: colors.lightGray,
                    color: colors.secondary,
                },
                dark: {
                    background: colors.secondary,
                    color: colors.white,
                },
                accent: {
                    background: colors.primary,
                    color: colors.secondary,
                },
            },
        },
        dropdown: {
            minWidth: "20",
            background: colors.lightGray,
        },
        dropdownButton: {
            background: colors.white,
            focus: colors.gray100,
        },
        darkColor: colors.black,
        filters: {
            link: {
                color: colors.black,
                fontWeight: "bold",
            },
            removeFiltersIcon: "times-circle",
            showIconOnButton: true,
            showLabelOnActiveFilters: true,
            panel: {
                backgroundColor: colors.gray400,
                color: "inherit",
            },
            row: {
                backgroundColor: colors.gray200,
                color: colors.black,
                shadow: "-3px -3px 3px 1px rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(0, 0, 0, 0.2)",
            },
        },
        folder: {
            backgroundColor: colors.white,
            boxShadow: "rgba(0, 18, 27, 0.2) 0px 2px 6px",
            boxShadowHover: "rgba(0, 18, 27, 0.2) 0px 2px 15px",
            color: "inherit",
        },
        footer: {
            color: colors.white,
            background: colors.black,
            padding: spacing.padding,
            margin: spacing.margin,
        },
        header: {
            color: colors.black,
            background: colors.white,
        },
        helpText: {
            fontSize: "0.7rem",
            color: colors.gray600,
            fontStyle: "italic",
            lineHeight: 1.2,
        },
        hero: {
            height: "500",
        },
        icon: {
            colors: colors,
            color: colors.gray500,
            strokeWidth: 2,
            hover: {
                color: colors.black,
            },
        },
        image: {
            containerBackground: colors.black,
        },
        input: {
            borderColor: colors.gray500,
            fontSize: "0.8rem",
            fontWeight: "normal",
            radius: "3px",
            background: colors.white,
            disabledBackground: colors.gray200,
            color: colors.black,
            focusColor: colors.primary,
            iconColor: colors.primary,
            iconBackground: colors.lightGray,
            iconScale: 0.4,
            error: {
                color: colors.alert,
                borderColor: colors.alert,
            },
            label: {
                marginBottom: 0,
            },
        },
        lightColor: colors.white,
        link: {
            color: colors.black,
            colors: colors,
            fontWeight: "bold",
            textDecoration: "none",
        },
        loaders: {
            color: colors.secondary,
            contentBlockLoader: {
                opacity: 0.2,
                gradientAngle: "-45deg",
                foreground: colors.gray800,
                background: colors.gray100,
                borderRadius: "3px",
            },
        },
        logo: {
            src: logo,
            height: "30px",
        },
        menu: {
            color: colors.secondary,
            background: colors.white,
            hoverBackground: colors.lightGray,
            borderBottom: "1px solid " + colors.lightGray,
            padding: "0.5rem 1.2rem",
            verticalPadding: 0.8,
            horizontalPadding: 1.5,
            notification: colors.alert,
            notificationColor: colors.white,
        },
        modal: {
            padding: spacing.padding,
            margin: spacing.margin,
            radius: spacing.radius,
            background: colors.white,
            color: colors.secondary,
        },
        navigation: {
            admin: {
                background: colors.gray100,
                color: colors.black,
                hoverBackground: colors.accent,
                hoverColor: colors.white,
            },
            showMobileMenuBelow: breakpoints.medium,
            border: {
                width: 0,
                color: colors.primary,
                style: "solid",
            },
            mobileNav: {
                buttonColor: colors.black,
                buttonFontSize: "1.4rem",
            },
            background: colors.neutral,
            boxShadow: "none",
            maxWidth: "1400px",
            // boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
            subNavigation: {
                background: colors.gray100,
            },
            navLink: {
                margin: 0.6,
                color: colors.black,
                activeColor: colors.primary,
                activeTextDecoration: "none",
                hoverTextDecoration: "underline",
                activeFontWeight: "normal",
                hoverFontWeight: "normal",
                padding: 0.6,
                textTransform: "uppercase",
                fontWeight: "normal",
                fontFamily: font.fontMedium,
                letterSpacing: 0,
                fontSize: 16,
                navUnderline: {
                    enabled: false,
                    height: 0,
                    activeHeight: 0,
                    hoverHeight: 0,
                    bottomOffset: 0,
                    sideOffset: 0,
                    color: "transparent",
                },
            },
            subNavLink: {
                fontFamily: font.fontBody,
                fontSize: 12,
                color: colors.accent,
                activeColor: colors.accent,
                activeTextDecoration: "underline",
                textTransform: "uppercase",
                fontWeight: "normal",
            },
        },
        pageTitle: {
            background: colors.white,
            fontSize: "1.5rem",
            smallFontSize: "1.2rem",
            fontFamily: font.fontHeading,
            color: "inherit",
        },
        pagination: {
            border: "1px solid " + colors.gray600,
            color: colors.black,
            background: colors.gray300,
            activeColor: colors.white,
            activeBackground: colors.secondary,
            activeFontWeight: "bold",
            arrowSize: "1.4rem",
            arrowColor: colors.gray600,
            hoverColor: colors.black,
            hoverBackground: colors.gray600,
            height: "2rem",
        },
        panel: {
            default: "white",
            headingBackground: colors.gray800,
            headingColor: colors.white,
            radius: 0,
            boxShadow: "none",
            // boxShadow: `${colors.gray200} 0px 2px 6px`,
            overlay: colors.black,
            background: colors.white,
            color: colors.black,
        },
        planner: planner,
        progress: {
            borderColor: colors.lightGray,
            radius: spacing.radius,
            background: colors.white,
            barColor: colors.primary,
            textColor: colors.primary,
            textColorAlt: colors.white,
        },
        search: {
            background: colors.white,
            boxShadow: "rgba(0, 18, 27, 0.2) 0px 2px 6px",
            boxShadowHover: "rgba(0, 18, 27, 0.2) 0px 2px 15px",
            transformHover: "translateY(0)",
            // searchIcon: faChevronRight,
            // showCircle: true,
            color: colors.body,
            hoverColor: colors.accent,
            hoverTextDecoration: "none",
            icons: {
                searchIcon: "chevron-right",
                color: "inherit",
                size: "20px",
                showCircle: true,
            },
            title: {
                color: "inherit",
            },
            image: {
                background: colors.gray900,
                height: "200px",
                objectFit: "contain",
                padding: "0.3rem",
            },
            summary: {
                color: colors.gray500,
            },
            badge: {
                show: true,
                textTransform: "uppercase",
                badgeType: {
                    default: {
                        background: colors.gray800,
                        color: colors.white,
                        display: "none",
                        showBorder: true,
                    },
                },
            },
        },
        select: {
            background: colors.gray100,
            color: colors.black,
            highlighted: colors.highlight,
            hover: colors.gray200,
            focus: colors.gray200,
        },
        starRating: {
            fontSize: "22px",
            starColor: "#ffb400",
            emptyStarColor: "rgba(0, 0, 0, 0.2)",
        },
        steps: {
            barHeight: 20,
            spacing: -3,
            circleDiameter: 40,
            progressColor: colors.gray600,
            backgroundColor: colors.white,
            borderColor: colors.secondary,
            borderRadius: 100,
            labelColor: "inherit",
            margin: spacing.margin,
            border: 1,
            padding: 4,
        },
        tabordion: {
            background: colors.white,
            padding: spacing.padding,
            margin: spacing.margin,
            borderThickness: "1px",
            borderColor: colors.white,
            fontWeight: "bold",
            tabStyle: "tabs",
            accordion: {
                textAlign: "left",
            },
            default: {
                color: "inherit",
                background: colors.gray300,
                hover: colors.gray400,
            },
            active: {
                color: colors.secondary,
                background: colors.gray100,
                borderColor: colors.gray300,
            },
            content: {
                color: "inherit",
                background: colors.white,
            },
            dividers: {
                height: 2.5,
                padding: 0.7,
                fontSize: 0.4,
                fontWeight: "bold",
                color: colors.white,
                background: colors.primary,
                border: "1px solid " + colors.white,
                radius: 2,
            },
            heading: { fontSize: "1rem" },
            tabs: {
                textAlign: "center",
                height: "40px",
            },
            twisty: {
                size: 2,
                color: colors.white,
                background: "transparent",
                nonActiveIcon: "arrowDown",
                activeIcon: "arrowUp",
            },
        },
        table: {
            margin: spacing.margin,
            padding: "0.5",
            types: {
                default: {
                    background: colors.white,
                    backgroundAlt: colors.lightGray,
                    rowHover: "#545963",
                    rowHighlight: colors.highlight,
                    thBackground: colors.secondary,
                    thColor: colors.white,
                    tdLineColor: colors.mediumGray,
                },
                planner: {
                    background: colors.white,
                    backgroundAlt: colors.lightGray,
                    rowHover: "#545963",
                    rowHighlight: colors.highlight,
                    thBackground: colors.secondary,
                    thColor: colors.white,
                    tdLineColor: colors.mediumGray,
                },
                subtle: {
                    background: colors.white,
                    backgroundAlt: colors.lightGray,
                    rowHover: "#545963",
                    rowHighlight: colors.highlight,
                    thBackground: colors.gray300,
                    thColor: colors.black,
                    tdLineColor: colors.mediumGray,
                },
                noStripe: {
                    background: colors.white,
                    backgroundAlt: colors.white,
                    rowHover: "#545963",
                    rowHighlight: colors.highlight,
                    thBackground: colors.gray300,
                    thColor: colors.black,
                    tdLineColor: colors.mediumGray,
                },
                overview: {
                    background: colors.gray100,
                    backgroundAlt: colors.white,
                    rowHover: "#545963",
                    rowHighlight: colors.highlight,
                    thBackground: colors.gray300,
                    thColor: colors.black,
                    tdLineColor: "none",
                },
            },
        },
        toast: {
            width: "400px",
            verticalPosition: "bottom",
            horizontalPosition: "right",
            background: colors.white,
            border: "1px solid " + colors.gray400,
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.14)",
            iconColor: colors.white,
            types: {
                alert: colors.alert,
                warning: colors.warning,
                success: colors.success,
                info: colors.info,
                loader: colors.gray600,
            },
        },
        tooltip: {
            color: colors.white,
            background: colors.secondary,
            fontSize: "1rem",
            opacity: 0.95,
            padding: spacing.padding + "rem",
            margin: spacing.margin + "rem",
            radius: "3px",
            borderColor: colors.secondary,
            display: "none",
        },
        typography: {
            margin: spacing.margin,
            sizes: {
                pageTitle: "1.5rem",
            },
        },
        userInfo: {
            spacingX: 0.8,
            spacingY: 0.2,
            padding: spacing.padding,
            backgroundColor: colors.white,
            height: 8,
            icon: {
                size: 1,
                color: colors.black,
            },
            infoSections: {
                name: true,
                email: true,
                group: true,
                role: true,
            },
            notification: {
                size: 0.7,
                top: -0.5,
                right: -0.5,
                minWidth: 1.2,
                color: colors.white,
                backgroundColor: colors.primary,
            },
            title: {
                size: 1,
                color: colors.secondary,
            },
            text: {
                size: 0.8,
                color: colors.primary,
            },
            wrapper: {
                marginRight: "1rem",
                paddingRight: "0",
                borderRight: "0",
            },
            messageIconButton: {
                style: null,
            },
        },
    };
    return merge(defaultTheme, siteTheme);
};

export default generateTheme();
