import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    IconTextButton,
    PageTitle,
    Loading,
    Table,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { EmptyRow, PreviewImage } from "../../components/index";

const loadItems = (setItems, page = 1) => {
    axios
        .get(`/api/v1/admin/featured-item`, {
            params: { page: page },
        })
        .then(({ data }) => {
            // console.log(data);
            setItems({
                loaded: true,
                data: data.data,
                pagination: data.meta,
            });
        })
        .catch();
};
const deleteItem = (uuid, loadItems, setItems, page = 1) => {
    axios
        .delete(`/api/v1/admin/featured-item/${uuid}`)
        .then(({ data }) => {
            // console.log(data);
            loadItems(setItems, page);
        })
        .catch();
};
export const FeaturedItemListing = ({ path }) => {
    //@ts-ignore cortex
    const [items, setItems] = useState({
        loaded: false,
        data: [],
        pagination: {},
    });

    useEffect(() => {
        loadItems(setItems);
    }, [setItems]);
    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Featured Items")}{" "}
                        {/* <HeaderEmphasis>{assetAdmin.asset.name}</HeaderEmphasis> */}
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={`${path}/create`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    icon={faPlus}
                >
                    {trans("Create new featured item")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>{trans("Name")}</th>
                            <th>{trans("Image")}</th>
                            <th>{trans("Link")}</th>
                            <th>{trans("Order")}</th>
                            <th>{trans("Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!items.loaded ? (
                            <EmptyRow colSpan={5}>
                                <Loading />
                            </EmptyRow>
                        ) : items.data.length === 0 ? (
                            <EmptyRow colSpan={5}>
                                {trans("No items found")}
                            </EmptyRow>
                        ) : (
                            items.data.map((item) => {
                                return (
                                    <tr>
                                        <td>{item.title}</td>
                                        <td>
                                            {item.banner ? (
                                                <PreviewImage
                                                    src={item.banner}
                                                />
                                            ) : (
                                                trans("No image provided")
                                            )}
                                        </td>
                                        <td>{item.link}</td>
                                        <td>{item.order}</td>
                                        <td>
                                            <IconTextButton
                                                as={Link}
                                                to={`${path}/edit/${item.uuid}`}
                                                showCircle={false}
                                                expanded={false}
                                                icon={faEdit}
                                            >
                                                {trans("Edit")}
                                            </IconTextButton>

                                            <IconTextButton
                                                type="alert"
                                                showCircle={false}
                                                expanded={false}
                                                icon={faTrash}
                                                onClick={(event) => {
                                                    confirm(
                                                        "Are you sure you want to delete this featured item?"
                                                    )
                                                        ? deleteItem(
                                                              item.uuid,
                                                              loadItems,
                                                              setItems
                                                          )
                                                        : event.preventDefault();
                                                }}
                                            >
                                                {trans("Delete")}
                                            </IconTextButton>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </Row>
        </div>
    );
};

export default FeaturedItemListing;
