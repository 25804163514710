import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Row,
    Column,
    DraftJSField,
    Button,
    InputField,
} from "@cortexglobal/rla-components";
import { useState } from "react";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const WysiwygConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    content,
}) => {
    const intl = useIntl();
    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Content" }),
        content: content ? content : "",
    });

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder=""
                        onChange={onChange}
                    />
                    <DraftJSField
                        name="content"
                        value={state.content}
                        onChange={onChange}
                    />

                    <Button type="submit">{trans("Save Panel")}</Button>
                </form>
            </Column>
        </Row>
    );
};

WysiwygConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default WysiwygConfiguration;
