import React, { useContext } from "react";
import {useHistory, useRouteMatch} from "react-router-dom";

import styled from "styled-components";

import { Collapse } from "react-collapse";

import { trans } from "@cortexglobal/rla-intl";
import {
    Accordion,
    AccordionHeader,
    TypeAhead,
} from "@cortexglobal/rla-components";

import FaqContext from "../FaqContext";

const NavHeader = styled(AccordionHeader)`
    cursor: pointer;

    h4 {
        margin: 0;
    }
`;

const NavContent = styled.nav`
    padding: 1rem 0 0.3rem;
    background: ${({ theme }) => theme.content.navigation.background};
    color: ${({ theme }) => theme.content.navigation.color};
`;

const PageLink = styled.a`
    color: ${({ selected, theme }) =>
        selected
            ? theme.navigation.navLink.activeColor
            : theme.navigation.navLink.color};
    text-decoration: ${({ selected, theme }) =>
        selected ? theme.navigation.navLink.activeTextDecoration : "none"};
    display: block;
    padding: 0 0 1rem 1rem;
    cursor: pointer;
`;

const FaqSidebar = () => {
    const { faqState, basepath, faqNavigate } = useContext(FaqContext);
    const history = useHistory();

    const { categories } = faqState;

    const {
        params: { category: categorySlug, question: questionSlug },
    } = useRouteMatch();

    // Functions
    const onTypeAheadUpdate = (searchValue) => {
        history.push(`${basepath}/${searchValue.value[0].text}`);
    };

    return (
        <>
            <TypeAhead
                name={"search_faq"}
                url={"api/v1/type-ahead/faq"}
                label={trans("Search")}
                placeholder={trans("Search FAQ...")}
                labelStyle={{ marginBottom: "1rem" }}
                onChange={onTypeAheadUpdate}
            />
            <Accordion>
                {categories.map((category) => {
                    //Only show the section if it has questions
                    if (
                        !category.questions ||
                        category.questions.length === 0
                    ) {
                        return false;
                    }
                    return (
                        <div key={`sidebar-${category.uuid}`}>
                            <NavHeader
                                onClick={() =>
                                    faqNavigate(`${basepath}/${category.slug}`)
                                }
                                current={category.slug === categorySlug}
                            >
                                <h4>{category.text}</h4>
                            </NavHeader>
                            <Collapse isOpened={category.slug === categorySlug}>
                                <NavContent>
                                    {category.questions.map((question) => {
                                        if (question.show_in_sidebar === 0) {
                                            return;
                                        }
                                        return (
                                            <PageLink
                                                key={
                                                    "question-" + question.uuid
                                                }
                                                selected={
                                                    category.slug ===
                                                        categorySlug &&
                                                    question.slug ===
                                                        questionSlug
                                                }
                                                onClick={() =>
                                                    faqNavigate(
                                                        `${basepath}/${category.slug}/${question.slug}`
                                                    )
                                                }
                                            >
                                                {question?.status !==
                                                "published" ? (
                                                    <span
                                                        style={{
                                                            fontStyle:
                                                                "italic",
                                                            fontWeight:
                                                                "normal",
                                                        }}
                                                    >
                                                    {
                                                        question?.question
                                                    }
                                                </span>
                                                ) : (
                                                    question?.question
                                                )}
                                            </PageLink>
                                        );
                                    })}
                                </NavContent>
                            </Collapse>
                        </div>
                    );
                })}
            </Accordion>
        </>
    );
};

export default FaqSidebar;
