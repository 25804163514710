import { ChartProps } from "react-chartjs-2";
import { ChartData as ChartJSChartData } from "chart.js";

export const generateColor = (index: number, length: number) => {
    const hue = (255 / length) * index;
    return `hsla(${hue}, 100%, 40%, 0.6)`;
};

export const prepareData = ({
    data,
    labelKey = "label",
    valueKey = "value",
    colors = [],
}: {
    data: {
        [key: string]: any;
    }[];
    labelKey?: string;
    valueKey?: string;
    colors?: string[];
    //TODO: Look at removing the any below
}): ChartProps | any => {
    return data.reduce(
        (formattedData, dataItem, index) => {
            // console.log(dataItem.media_channel);
            formattedData.labels.push(dataItem[labelKey]);
            formattedData.datasets[0].data.push(dataItem[valueKey]);

            //TODO - Load up the correct colours
            const color = colors[index]
                ? colors[index]
                : generateColor(index, data.length);
            // console.log(color);
            formattedData.datasets[0].backgroundColor.push(color);
            formattedData.datasets[0].hoverBackgroundColor.push(color);

            return formattedData;
        },
        {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                },
            ],
        }
    );
};

export const prepareArrayData = ({
    data,
    labelKey = "label",
    valueKey = "value",
    colors = [],
}: {
    data: {
        label: string;
        data: {
            [key: string]: any;
        }[];
    }[];
    labelKey?: string;
    valueKey?: string;
    colors?: string[];
}): ChartProps | any => {
    return data.reduce(
        (formattedData, dataGroup, outerIndex) => {
            // console.log(dataGroup);
            //@ts-ignore cortex

            formattedData.datasets = [
                ...formattedData.datasets,
                {
                    label: dataGroup.label,
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                },
            ];

            //TODO - Load up the correct colours
            const color = colors[outerIndex]
                ? colors[outerIndex]
                : generateColor(outerIndex, data.length);
            //@ts-ignore cortex
            dataGroup.data.map((dataItem, index) => {
                if (outerIndex === 0) {
                    //@ts-ignore cortex

                    formattedData.labels.push(dataItem[labelKey]);
                }
                //@ts-ignore cortex

                formattedData.datasets[outerIndex].data.push(
                    dataItem[valueKey]
                );
                //@ts-ignore cortex

                formattedData.datasets[outerIndex].backgroundColor.push(color);
                //@ts-ignore cortex

                formattedData.datasets[outerIndex].hoverBackgroundColor.push(
                    color
                );
            });

            return formattedData;
        },
        {
            labels: [],
            datasets: [],
        }
    );
};
