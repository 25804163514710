import React, { Component } from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { DetailPanelItem } from "./DetailPanelItem";

class GroupContactDetails extends Component {
    render() {
        const { contact } = this.props;
        return (
            <React.Fragment>
                <DetailPanelItem>
                    <strong>{trans("Name")}</strong> {contact.name}
                </DetailPanelItem>

                <DetailPanelItem>
                    <strong>{trans("Address")}</strong>
                    {contact.address_1} {contact.address_1 && <br />}
                    {contact.address_2} {contact.address_2 && <br />}
                    {contact.address_3} {contact.address_3 && <br />}
                    {contact.address_4} {contact.address_4 && <br />}
                    {contact.address_5} {contact.address_5 && <br />}
                    {contact.country}
                </DetailPanelItem>

                {contact.phone && (
                    <DetailPanelItem>
                        <strong>{trans("Phone")}</strong> {contact.phone}
                    </DetailPanelItem>
                )}
                {contact.email && (
                    <DetailPanelItem>
                        <strong>{trans("Email")}</strong> {contact.email}
                    </DetailPanelItem>
                )}
            </React.Fragment>
        );
    }
}

export default GroupContactDetails;
