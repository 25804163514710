import React, { useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import styled from "styled-components";
import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Modal,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import TaskResultsForm from "../forms/TaskResultsForm";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TaskNotificationForm from "../forms/TaskNotificationForm";
import TaskActivationModal from "./TaskActivationModal";

const ExpandedPanel = styled(Panel)`
    flex: 1;
    overflow: auto;
    width: 100%;
`;

const TaskActionPanel = ({
    task,
    updateTask,
    deleteActivity,
    deleteTask,
    setEditing,
    editing,
    theme: plannerTheme,
    viewActivity,
    reloadTask,
    ...props
}) => {
    const [activationModal, setActivationModal] = useState({
        show: false,
    });
    const [deleting, setDeleting] = useState(false);
    const [addingResults, setAddingResults] = useState(false);
    const [notifying, setNotifying] = useState(false);

    if (!task || !task.can_admin_details) {
        return null;
    }

    const showActivationModal = () => {
        setActivationModal({
            ...activationModal,
            show: true,
        });
    };

    const hideActivationModal = () => {
        setActivationModal({
            ...activationModal,
            show: false,
        });
    };

    return (
        <>
            <h3>{trans("Available Actions")}</h3>
            <ExpandedPanel>
                <Column>
                    <>
                        {task.status === "draft" && (
                            <IconTextButton
                                expanded
                                onClick={() => showActivationModal()}
                                icon="plus"
                                showCircle={false}
                            >
                                {trans("Activate Campaign")}
                            </IconTextButton>
                        )}

                        <IconTextButton
                            expanded
                            onClick={() => setEditing(!editing)}
                            icon="pencil-alt"
                            showCircle={false}
                        >
                            {trans("Edit Campaign")}
                        </IconTextButton>

                        {task.type === "national" && (
                            <IconTextButton
                                expanded
                                onClick={() => setNotifying(true)}
                                icon={faCommentDots}
                                showCircle={false}
                            >
                                {trans("Notify")}
                            </IconTextButton>
                        )}

                        {task.complete && (
                            <IconTextButton
                                expanded
                                onClick={() => setAddingResults(true)}
                                icon="plus"
                                showCircle={false}
                            >
                                {trans("Add Campaign Results")}
                            </IconTextButton>
                        )}
                        <IconTextButton
                            expanded
                            themeColor="alert"
                            onClick={() => setDeleting(true)}
                            disabled={!task || !task.uuid}
                            icon={faTrash}
                            showCircle={false}
                        >
                            {trans("Delete Campaign")}
                        </IconTextButton>

                        {task.calculated_status === "ended" && (
                            <IconTextButton
                                icon={faPlus}
                                expanded
                                disabled={!task || !task.uuid}
                                onClick={() => setAddingResults(true)}
                            >
                                {trans("Add Results")}
                            </IconTextButton>
                        )}
                    </>
                </Column>
            </ExpandedPanel>

            <TaskActivationModal
                visible={activationModal.show}
                onClose={hideActivationModal}
                task={task}
                theme={plannerTheme}
                viewActivity={viewActivity}
                onSuccessCallback={() => {
                    reloadTask();
                    hideActivationModal();
                }}
            />

            <Modal visible={deleting} onClose={() => setDeleting(false)}>
                <Row>
                    <Column>
                        <h2>
                            {trans(
                                "Are you sure you want to delete this campaign?"
                            )}
                        </h2>
                    </Column>
                    <Column large={6}>
                        <IconTextButton
                            expanded
                            onClick={() => setDeleting(false)}
                        >
                            {trans("Cancel")}
                        </IconTextButton>
                    </Column>
                    <Column large={6}>
                        <IconTextButton
                            icon={faTrash}
                            showCircle={false}
                            expanded
                            themeColor="alert"
                            onClick={() => {
                                deleteTask(task.uuid);
                            }}
                        >
                            {trans("Delete")}
                        </IconTextButton>
                    </Column>
                </Row>
            </Modal>

            <Modal
                visible={addingResults}
                onClose={() => setAddingResults(false)}
                maxWidth="90%"
            >
                <h2>{trans("Add Campaign Results")}</h2>
                <TaskResultsForm
                    task={task}
                    onSave={(updatedTask) => {
                        updateTask(updatedTask);
                        setAddingResults(false);
                    }}
                    onCancel={() => setAddingResults(false)}
                />
            </Modal>

            <Modal
                visible={notifying}
                onClose={() => setNotifying(false)}
                maxWidth="90%"
            >
                <TaskNotificationForm
                    task={task}
                    onComplete={() => setNotifying(false)}
                />
            </Modal>
        </>
    );
};

export default TaskActionPanel;
