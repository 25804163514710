import React from "react";
import add from "date-fns/add";

import {
    RadioField,
    DatePicker,
    InputField,
    FormRow,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const PressOrderForm = ({ order, standardOnChange, errors }) => {
    const { formatMessage } = useIntl();
    //Get the press_details from the order. If it doesn't exist, create it
    //can't use destructing because it can be null
    console.log("order", order);
    let { press_details } = order;
    if (!press_details) {
        press_details = {
            publication: "",
            monochrome: false,
            insertion_date: "",
        };
    }

    const minDate = add(new Date(), {
        days: order?.user_asset?.asset?.order_flow_details?.lead_time_days || 0,
    });

    const onChange = ({ name, value }) => {
        standardOnChange({
            name: "press_details",
            value: { ...press_details, [name]: value },
        });
    };

    return (
        <>
            <FormRow
                name="publication"
                label={trans("Publication")}
                error={errors.publication}
            >
                <InputField
                    name="publication"
                    value={press_details.publication}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="monochrome"
                label={trans("Monochrome")}
                error={errors.monochrome}
            >
                <RadioField
                    name="monochrome"
                    value={press_details.monochrome}
                    showError={false}
                    onChange={onChange}
                    inlineRadioButtons={true}
                    options={[
                        {
                            text: formatMessage({ id: "Yes" }),
                            value: true,
                        },
                        {
                            text: formatMessage({ id: "No" }),
                            value: false,
                        },
                    ]}
                />
            </FormRow>
            <FormRow
                name="insertion_date"
                label={trans("Insertion Date")}
                error={errors.insertion_date}
                helpText={trans("When is the ad being placed?")}
                required
            >
                <DatePicker
                    name="insertion_date"
                    value={press_details.insertion_date}
                    showError={false}
                    onChange={onChange}
                    minDate={minDate}
                />
            </FormRow>
        </>
    );
};

export default PressOrderForm;
