import React, { createRef } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Icon } from "../../index";
import { isTouchDevice } from "@cortexglobal/cortex-utilities";

const ArrowIcon = styled(Icon)`
    filter: ${(props) => props.theme.carousel.arrows.filter};
`;
const Container = styled.div`
    margin: auto;
    max-width: ${(props) => props.width};
    height: ${({ height, theme }) => height || theme.carousel.height};
    position: relative;
    overflow: hidden;
    background: ${({ background, theme }) =>
        background || theme.carousel.item.background};
`;
const ControlContainer = styled.div`
    pointer-events: none;
    margin: auto;
    max-width: ${(props) => props.controlWidth};
    height: ${({ height, theme }) => height || theme.carousel.height};
    position: relative;
`;

const ArrowButton = styled.a`
    pointer-events: all;
    top: 50%;
    font-size: ${(props) => props.theme.carousel.arrows.size}em;
    line-height: 0;
    position: absolute;
    transform: translateY(-50%);
    color: ${(props) => props.theme.carousel.arrows.color};
    cursor: pointer;
    z-index: 1;
`;

const ArrowButtonLeft = styled(ArrowButton)`
    left: 0;
`;

const ArrowButtonRight = styled(ArrowButton)`
    right: 0;
`;

const Dots = styled.div`
    pointer-events: all;
    bottom: 0;
    width: 100%;
    position: absolute;
    text-align: center;
`;

const Dot = styled.a`
    width: ${(props) => props.theme.carousel.dots.size}em;
    height: ${(props) => props.theme.carousel.dots.size}em;
    background: ${(props) =>
        props.active
            ? props.theme.carousel.dots.activeBackground
            : props.theme.carousel.dots.background};
    border-radius: ${(props) => props.theme.carousel.dots.radius}em;
    margin: ${(props) => props.theme.carousel.dots.margin}em;
    display: inline-block;
    cursor: pointer;
`;

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.carouselElementRef = React.createRef();
        this.resizeObserver = null;

        this.state = {
            current: 0,
            direction: undefined,
            reset: false,
            beingHovered: false,
        };
    }

    componentDidMount() {
        const node = this.carouselElementRef.current;
        this.resizeObserver = new ResizeObserver((entries) => {
            if (this.state.current > 0) {
                this.setCurrentItem(0);
            }
        });

        this.resizeObserver.observe(node);

        //Only use the hover to show controls on non touch devices
        if (isTouchDevice()) {
            this.setState({ beingHovered: true });
        } else {
            node.addEventListener("mouseenter", this.setHoverState);
            node.addEventListener("mouseleave", this.removeHoverState);
        }
        if (this.props.showSlideFor) {
            this.addAutoplay();
            node.addEventListener("mouseover", this.removeAutoplay);
            node.addEventListener("mouseout", this.addAutoplay);
        }
    }

    componentWillUnmount() {
        const node = this.carouselElementRef.current;
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (!isTouchDevice()) {
            node.removeEventListener("mouseenter", this.setHoverState);
            node.removeEventListener("mouseleave", this.removeHoverState);
        }
        if (this.props.showSlideFor) {
            this.removeAutoplay();
            node.removeEventListener("mouseover", this.removeAutoplay);
            node.removeEventListener("mouseout", this.addAutoplay);
        }
    }
    removeAutoplay = () => clearInterval(this.autoPlayId);
    addAutoplay = () => {
        this.autoPlayId = setInterval(this.nextItem, this.props.showSlideFor);
    };

    setHoverState = () => {
        this.setState({ beingHovered: true });
    };

    removeHoverState = () => {
        this.setState({ beingHovered: false });
    };

    prevItem = () => {
        var x;
        if (this.state.current) {
            x = this.state.current - 1;
        } else {
            x = this.props.children.length - 1;
        }
        this.setState({ current: x, direction: "left" });
    };

    nextItem = () => {
        var x;
        if (this.state.current != this.props.children.length - 1) {
            x = this.state.current + 1;
        } else {
            x = 0;
        }
        this.setState({ current: x, direction: "right" });
    };

    setCurrentItem = (x = 0) => {
        // console.log("resizing?");
        var direction = this.state.current > x ? "left" : "right";
        this.setState({
            current: x,
            direction: direction,
        });
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            typeof nextProps.showItem == "number" &&
            nextProps.showItem !== prevState.current
        ) {
            return { current: nextProps.showItem };
        } else {
            return null;
        }
    }

    render() {
        let children = this.props.children.filter(function (x) {
            return x != (undefined || null || false || "");
        });

        let childrenWithProps = React.Children.map(children, (child, i) =>
            React.cloneElement(child, {
                i: i,
                current: this.state.current,
                direction: this.state.direction,
                first: Boolean(i == 0),
                last: Boolean(i == React.Children.count(children) - 1),
            })
        );

        const {
            height,
            arrows,
            dots,
            hideControls,
            showControlsOnlyOnHover,
            theme,
            controlWidth,
            ...rest
        } = this.props;

        const { beingHovered } = this.state;

        const hidingControls =
            hideControls || (showControlsOnlyOnHover && !beingHovered);
        return (
            <Container height={height} ref={this.carouselElementRef} {...rest}>
                {childrenWithProps}
                <ControlContainer controlWidth={controlWidth}>
                    {!hidingControls &&
                        arrows && [
                            <ArrowButtonLeft
                                key={1}
                                onClick={() => this.prevItem(children)}
                            >
                                <ArrowIcon
                                    name={"arrowLeft"}
                                    strokeColor={
                                        theme.carousel.arrows.strokeColor
                                    }
                                />
                            </ArrowButtonLeft>,
                            <ArrowButtonRight
                                key={2}
                                onClick={() => this.nextItem(children)}
                            >
                                <ArrowIcon
                                    name={"arrowRight"}
                                    strokeColor={
                                        theme.carousel.arrows.strokeColor
                                    }
                                />
                            </ArrowButtonRight>,
                        ]}

                    {!hidingControls &&
                        dots && [
                            <Dots key={3}>
                                {React.Children.map(children, (child, i) => {
                                    return (
                                        <Dot
                                            onClick={() =>
                                                this.setCurrentItem(i)
                                            }
                                            active={this.state.current == i}
                                            key={i}
                                        />
                                    );
                                })}
                            </Dots>,
                        ]}
                </ControlContainer>
            </Container>
        );
    }
}

// Carousel = withTheme(Carousel);

Carousel.displayName = "Carousel";

Carousel.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    dots: PropTypes.bool,
    arrows: PropTypes.bool,
    showItem: PropTypes.number,
    showSlideFor: PropTypes.number,
    controlWidth: PropTypes.string,
    hideControls: PropTypes.bool,
    showControlsOnlyOnHover: PropTypes.bool,
};

Carousel.defaultProps = {
    width: "100%",
    height: "100%",
    dots: true,
    arrows: true,
    showItem: null,
    controlWidth: "1400px",
    hideControls: false,
    showControlsOnlyOnHover: true,
};

export default withTheme(Carousel);
