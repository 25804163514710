import React, { useEffect, useState, useCallback, useContext } from "react";
import styled, { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle as faCheckCircleEmpty } from "@fortawesome/free-regular-svg-icons";
import { format } from "@cortexglobal/cortex-utilities";
import { isEmpty } from "lodash";

import { trans } from "@cortexglobal/rla-intl";
import {
    Image,
    CircularProgress,
    Tabordion,
    PlainButton,
    FileIcon,
    ShowMore,
    HeaderWithErrorIndicated,
    useSectionErrors,
} from "@cortexglobal/rla-components";

import MetaDetails from "../../MetaDetails";
import NamingAndGeneralSetup from "../../NamingAndGeneralSetup";
import Availability from "../../Availability";
import CategoryStructure from "../../CategoryStructure";

const ItemWrapper = styled.article`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.gray400};
    border-radius: ${({ theme }) => theme.spacing.radius}rem;
`;

const Thumbnail = styled.div`
    display: flex;
    position: relative;
    opacity: ${({ selected }) => (selected ? 1 : 0.6)};
    height: 250px;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    background: ${({ theme }) => theme.search.image.background};
    &:hover {
        opacity: ${({ selected }) => (selected ? 0.8 : 0.8)};
        cursor: pointer;
    }
`;

const RemoveButton = styled(PlainButton)`
    position: absolute;
    top: 0.4rem;
    right: 1rem;
    color: ${({ theme }) => theme.colors.accent};
`;

const SelectedIcon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0.8rem;
    left: 1rem;
    opacity: ${({ selected }) => (selected ? 1 : 0.2)};
    color: ${({ theme }) => theme.colors.accent};
    transition: all 0.5s;
`;

const PreviewImage = styled(Image)`
    max-width: 100%;
    max-height: 100%;
`;

const Overlay = styled.aside`
    display: flex;
    opacity: ${({ visible }) => (visible ? "1" : "0")};

    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    pointer-events: none;
`;

const NameHeading = styled.h3`
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.5rem 0.5rem;
    line-break: anywhere;
    position: absolute;
    bottom: 0;
    height: 2.6rem;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    width: 100%;
    margin: 0;
`;

export const BulkItem = ({
    item,
    onChange,
    config,
    filters,
    searchableMeta,
    openSectionIndex,
    setOpenSectionIndex,
    groups,
    groupsInHierarchy,
    preloadedLanguages,

    preLoadedCategories,
    assetAdmin,
    errors = {},
    removeItem,
    assetHierarchyAvailable = false,

    assetTypes,
    mediaChannels,
}) => {
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: ["name", "asset_type", "media_type", "locale"],
        meta: ["media-channel", "therapy-type"],
        categories: ["category-hierarchy"],
        availability: [
            "view_type",
            "available_from",
            "available_to",
            "terms",
            "certification_terms",
            "certification_ref",
            "certification_awarded_date",
            "expiration_date",
        ],
    });
    const theme = useTheme();
    useEffect(() => {
        if (!isEmpty(errors)) {
            updateSectionErrors(errors);
        }
    }, [errors]);
    const onItemChange = ({ name: property, value }, event) => {
        onChange(item.uuid, property, value);
    };

    const overlayVisible =
        item.hasOwnProperty("progress") && item.progress < 100;

    console.log({ config });
    return (
        <ItemWrapper>
            <Thumbnail
                onClick={() => onChange(item.uuid, "selected", !item.selected)}
                selected={item.selected}
            >
                <Overlay visible={overlayVisible}>
                    <CircularProgress
                        backgroundPadding={5}
                        strokeWidth={14}
                        value={item.progress}
                        initialAnimation={true}
                        containerWidth={"100%"}
                    />
                </Overlay>
                <SelectedIcon
                    icon={item.selected ? faCheckCircle : faCheckCircleEmpty}
                    selected={item.selected}
                    transform="grow-12"
                />
                <RemoveButton onClick={() => removeItem(item.uuid)}>
                    <FontAwesomeIcon icon={faTimes} transform="grow-10" />
                </RemoveButton>
                {item.thumbnail || item.preview ? (
                    <PreviewImage
                        src={item.thumbnail ? item.thumbnail : item.preview}
                    />
                ) : (
                    <FileIcon colorBehind={theme.search.image.background}>
                        {item.main_file_extension}
                    </FileIcon>
                )}
                <NameHeading>
                    <ShowMore type="title" lines={2} more="...">
                        {item.name}
                    </ShowMore>
                </NameHeading>
            </Thumbnail>

            {/* <p>{format(item.lastModified, "PP")}</p> */}
            <form>
                <Tabordion
                    type="accordion"
                    current={openSectionIndex}
                    onTabClick={(key) => setOpenSectionIndex(key)}
                    allowFullyClosed={false}
                >
                    <NamingAndGeneralSetup
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Overview")}
                                section="general"
                                sectionErrors={sectionErrors}
                            />
                        }
                        assetAdmin={{}}
                        config={config}
                        asset={item}
                        standardOnChange={onItemChange}
                        errors={errors}
                        singleColumn={true}
                        showSectionHeading={false}
                        collapse={true}
                        preloadedLanguages={preloadedLanguages}
                        // excludedFields={["description"]} //No longer excluded, but if needed, can be added back via config in the future
                        assetTypes={assetTypes}
                        mediaChannels={mediaChannels}
                    />

                    <MetaDetails
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Meta Data")}
                                section="meta"
                                sectionErrors={sectionErrors}
                            />
                        }
                        asset={item}
                        standardOnChange={onItemChange}
                        filters={filters}
                        searchableMeta={searchableMeta}
                        errors={errors}
                        singleColumn={true}
                        showSectionHeading={false}
                        collapse={true}
                        excludedFields={["version", "mediaForm"]}
                    />
                    {assetHierarchyAvailable && (
                        <CategoryStructure
                            heading={
                                <HeaderWithErrorIndicated
                                    title={trans("Categories")}
                                    section="categories"
                                    sectionErrors={sectionErrors}
                                />
                            }
                            preLoadedCategories={preLoadedCategories}
                            asset={item}
                            standardOnChange={onItemChange}
                            errors={errors}
                            singleColumn={true}
                            showSectionHeading={false}
                            collapse={true}
                        />
                    )}
                    <Availability
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Availability")}
                                section="availability"
                                sectionErrors={sectionErrors}
                            />
                        }
                        asset={item}
                        standardOnChange={onItemChange}
                        filters={filters}
                        searchableMeta={searchableMeta}
                        errors={errors}
                        assetAdmin={assetAdmin}
                        groups={groups}
                        groupsInHierarchy={groupsInHierarchy}
                        singleColumn={true}
                        showSectionHeading={false}
                        excludedFields={[]}
                    />
                </Tabordion>
            </form>
        </ItemWrapper>
    );
};

export default BulkItem;
