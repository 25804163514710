import React, { Component } from "react";
import styled from "styled-components";
import { trans, injectIntl } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    InputField,
    IconTextButton,
} from "@cortexglobal/rla-components";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchWrapper = styled.div`
    /* width: 100%; */
    margin: ${({ margin }) => margin};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    input {
        margin: 0;
        min-width: 100%;
    }
`;

const StyledInputField = styled(InputField)`
    margin: 0;
    flex: 1;
    min-width: 150px;
`;
class SearchField extends Component {
    constructor(props) {
        super(props);
    }

    onUpdate = (input, event) => {
        this.props.onUpdate(input);
    };

    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            this.props.onApply();
        }
    };

    onApply = () => {
        this.props.onApply();
    };

    render() {
        const {
            intl,
            margin = "0 0 0 2rem",
            padding = "0.2rem 1rem",
        } = this.props;

        return (
            <SearchWrapper margin={margin}>
                <StyledInputField
                    type="search"
                    name="search"
                    value={this.props.search}
                    inlineLabel={false}
                    onChange={this.onUpdate}
                    onKeyDown={this.handleKeyDown}
                    expanded
                />
                <IconTextButton
                    padding={padding}
                    onClick={this.onApply}
                    showCircle={false}
                    expanded={false}
                    icon={faSearch}
                    title={intl.formatMessage({ id: "Search" })}
                ></IconTextButton>{" "}
            </SearchWrapper>
        );
    }
}

export default injectIntl(SearchField);
