import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

export const FileUploader = ({ onUpload }) => {
    console.warn("THIS ISN'T WORKING");
    return <p>FILE UPLOADER WILL GO HERE ONCE WORKING!</p>;
    return (
        <FilePond
            server={{
                process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort,
                    transfer,
                    options
                ) => {
                    onUpload(
                        fieldName,
                        file,
                        metadata,
                        load,
                        error,
                        progress,
                        abort,
                        transfer,
                        options
                    );
                },
                load: (source, load) => {
                    // simulates loading a file from the server
                    fetch(source)
                        .then(res => res.blob())
                        .then(load);
                }
            }}
        />
    );
};

FileUploader.displayName = "FileUploader";

FileUploader.propTypes = {
    type: PropTypes.string
};

FileUploader.defaultProps = {
    type: "default"
};

export default FileUploader;
