import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { v4 as uuid4 } from "uuid";

import {
    Row,
    Column,
    Button,
    FormLabel,
    InputField,
    IconTextButton,
    Toggle,
    Tabordion,
    Image,
    useAddToast,
    useToasts,
    PlainButton
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlay,
    faPause,
    faBackward,
    faForward,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { TabordionContentWrapper } from "../editorComponents";
import { ManageFootnotes } from "../ManageFootnotes";

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
const FlexColumn = styled(Column)`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
`;
const Video = styled.video`
    max-width: 100%;
`;

const SmallButton = styled(Button)`
    padding: 0.5rem 1rem;
    margin-left: 5px;
`;

const VideoControls = styled.div`
    position: absolute;
    bottom: 5px;
    right: 9px;
`;

const VideoContainer = styled.div`
    position: relative;
`;

const SequencedVideoEditor = ({
    name,
    asset,
    value,
    onChange,
    originalOnChange,
    onClose,
    path
}) => {
    const intl = useIntl();
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const { videoSrc, imageSrc, captions = [], autoplay = false } = value;

    const videoElement = useRef(null);

    const [duration, setDuration] = useState(0);
    const [elapsed, setElapsed] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const [tempCaptions, setTempCations] = useState(captions);
    const [tempAutoplay, setTempAutoplay] = useState(autoplay);

    const setDurationOfVideo = () => {
        setDuration(videoElement.current ? videoElement.current.duration : 0);
    };
    const setElapsedBasedOnVideo = () => {
        setElapsed(videoElement.current ? videoElement.current.currentTime : 0);
    };
    useEffect(() => {
        if (videoElement.current) {
            videoElement.current.addEventListener(
                "durationchange",
                setDurationOfVideo
            );
            videoElement.current.addEventListener(
                "timeupdate",
                setElapsedBasedOnVideo
            );
        }
        return () => {
            if (videoElement.current) {
                videoElement.current.removeEventListener(
                    "durationchange",
                    setDurationOfVideo
                );
                videoElement.current.removeEventListener(
                    "timeupdate",
                    setElapsedBasedOnVideo
                );
            }
        };
    }, [videoElement, videoSrc]);

    const toggleVideoPlaying = () => {
        if (isPlaying) {
            videoElement.current.pause();
            setIsPlaying(false);
        } else {
            videoElement.current.play();
            setIsPlaying(true);
        }
    };
    const selectPlaceholderFrame = () => {
        // const selectedTime = elapsed === duration ? elapsed - 0.1 : elapsed;
        // console.log(elapsed, duration);
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Video placeholder is being generated")
        });
        axios
            .post(`/api/v1/asset-file/extract-video-image/${asset.uuid}`, {
                elapsed: elapsed,
                videoSrc,
                asset_input_name: name
            })
            .then(({ data }) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                const newValue = { ...value, imageSrc: data };
                console.log(newValue);
                onChange({
                    name,
                    value: newValue
                });
            })
            .catch(e => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                addToast({
                    type: "alert",
                    content: e.response.data.message,
                    showFor: 5000
                });
            });
    };

    const onUpdate = ({ name, value }, uuid) => {
        const updatedCaptions = tempCaptions.map(caption => {
            if (caption.uuid === uuid) {
                caption = { ...caption, [name]: value };
            }
            return caption;
        });
        setTempCations(updatedCaptions);
    };

    return (
        <React.Fragment>
            <div style={{ minHeight: "430px" }}>
                <Row>
                    <Column>
                        <h2>{trans("Sub Claims and Video Sequencing")}</h2>
                    </Column>
                </Row>
                <Row style={{ marginTop: "1rem" }} equaliseChildHeight>
                    <Column style={{ paddingRight: "2rem" }} large={9}>
                        <Tabordion current={0} unmounts={false}>
                            <TabordionContentWrapper
                                heading={trans("Sub Claims")}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "2rem"
                                    }}
                                >
                                    <h3
                                        style={{
                                            marginBottom: "0",
                                            display: "inline-block"
                                        }}
                                    >
                                        {trans("Sub Claims")}
                                    </h3>
                                    <IconTextButton
                                        onClick={() =>
                                            setTempCations([
                                                ...tempCaptions,
                                                {
                                                    uuid: uuid4(),
                                                    value: "",
                                                    enterAt:
                                                        Math.round(
                                                            elapsed * 10
                                                        ) / 10,
                                                    pauseVideo: false,
                                                    bulletPoint: false
                                                }
                                            ])
                                        }
                                        showCircle={false}
                                        expanded={false}
                                        icon={faPlus}
                                    >
                                        {trans("Add Sub Claim")}
                                    </IconTextButton>
                                </div>
                                {!!tempCaptions.length && (
                                    <Row
                                        style={{
                                            margin: "0 0 1rem 0",
                                            paddingLeft: "0"
                                        }}
                                    >
                                        <Column
                                            medium={4}
                                            style={{ paddingLeft: "0" }}
                                        >
                                            {trans("Content")}
                                        </Column>
                                        <Column medium={3}>
                                            {trans("Display Time (s)")}
                                        </Column>
                                        <Column
                                            medium={1}
                                            style={{ marginRight: "1rem" }}
                                        >
                                            {trans("Pause")}
                                        </Column>
                                        <Column
                                            medium={2}
                                            style={{ paddingLeft: "0" }}
                                        >
                                            {trans("Bullet point")}
                                        </Column>
                                    </Row>
                                )}
                                {tempCaptions.map((caption, index) => (
                                    <Row
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingLeft: "0",
                                            margin: "0 0 1rem 0"
                                        }}
                                        key={caption.uuid}
                                    >
                                        <Column
                                            medium={4}
                                            style={{ paddingLeft: "0" }}
                                        >
                                            <InputField
                                                name="value"
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: "Enter your caption"
                                                    }
                                                )}
                                                value={caption.value}
                                                onChange={event => {
                                                    onUpdate(
                                                        event,
                                                        caption.uuid
                                                    );
                                                }}
                                            />
                                        </Column>
                                        <Column medium={3}>
                                            <InputField
                                                name="enterAt"
                                                type="number"
                                                max={duration}
                                                min="0"
                                                step="0.1"
                                                value={caption.enterAt}
                                                onChange={event => {
                                                    console.log(event.value);
                                                    onUpdate(
                                                        {
                                                            ...event,
                                                            value: parseFloat(
                                                                event.value
                                                            )
                                                        },
                                                        caption.uuid
                                                    );
                                                    setElapsed(event.value);
                                                    videoElement.current.currentTime =
                                                        event.value;
                                                }}
                                            />
                                        </Column>
                                        <Column
                                            medium={1}
                                            style={{ marginRight: "1rem" }}
                                        >
                                            <FormLabel>
                                                <Toggle
                                                    style={{
                                                        marginBottom: "1.2rem"
                                                    }}
                                                    checked={caption.pauseVideo}
                                                    onChange={event => {
                                                        onUpdate(
                                                            {
                                                                name:
                                                                    "pauseVideo",
                                                                value:
                                                                    event.target
                                                                        .checked
                                                            },
                                                            caption.uuid
                                                        );
                                                    }}
                                                />
                                            </FormLabel>
                                        </Column>
                                        <Column medium={2}>
                                            <FormLabel>
                                                <Toggle
                                                    style={{
                                                        marginBottom: "1.2rem"
                                                    }}
                                                    checked={
                                                        caption.bulletPoint
                                                    }
                                                    onChange={event => {
                                                        onUpdate(
                                                            {
                                                                name:
                                                                    "bulletPoint",
                                                                value:
                                                                    event.target
                                                                        .checked
                                                            },
                                                            caption.uuid
                                                        );
                                                    }}
                                                />
                                            </FormLabel>
                                        </Column>
                                        <Column
                                            medium={2}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft: "-1rem"
                                            }}
                                        >
                                            <PlainButton
                                                onClick={() =>
                                                    setTempCations([
                                                        ...tempCaptions.slice(
                                                            0,
                                                            index
                                                        ),
                                                        ...tempCaptions.slice(
                                                            index + 1,
                                                            tempCaptions.length
                                                        )
                                                    ])
                                                }
                                                style={{
                                                    marginBottom: "1.2rem",
                                                    marginRight: "1rem"
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                />
                                            </PlainButton>

                                            <div
                                                style={{
                                                    marginBottom: "1.2rem"
                                                }}
                                            >
                                                <ManageFootnotes
                                                    asset={asset}
                                                    tag={caption}
                                                    path={`${path}.value.captions[${index}]`}
                                                    onChange={originalOnChange}
                                                />
                                            </div>
                                        </Column>
                                    </Row>
                                ))}
                            </TabordionContentWrapper>
                            <TabordionContentWrapper
                                heading={trans("Export View")}
                            >
                                <Row>
                                    <Column style={{ display: "flex" }}>
                                        <div>
                                            {imageSrc ? (
                                                <Image
                                                    src={imageSrc}
                                                    style={{
                                                        maxWidth: "200px"
                                                    }}
                                                />
                                            ) : null}
                                            <Button
                                                style={{ marginTop: "1rem" }}
                                                onClick={() =>
                                                    selectPlaceholderFrame()
                                                }
                                            >
                                                {trans("Select frame")}
                                            </Button>
                                        </div>
                                        <div style={{ marginLeft: "1rem" }}>
                                            <h3>{trans("Export View")}</h3>
                                            <p>
                                                {trans(
                                                    "Pause the video on the frame you want to be your export view and press select frame"
                                                )}
                                            </p>
                                        </div>
                                    </Column>

                                    <Column />
                                    <Column style={{ marginTop: "1rem" }} />
                                </Row>
                            </TabordionContentWrapper>
                            <TabordionContentWrapper
                                heading={trans("Settings")}
                            >
                                <h3>Settings</h3>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <Toggle
                                        name="autoplay"
                                        checked={tempAutoplay}
                                        onChange={event => {
                                            setTempAutoplay(
                                                event.target.checked
                                            );
                                        }}
                                    />
                                    Autoplay Video
                                </div>
                            </TabordionContentWrapper>
                        </Tabordion>
                    </Column>

                    <Column style={{ position: "relative" }} large={3}>
                        <VideoContainer>
                            <Video ref={videoElement} src={videoSrc} />
                            <VideoControls>
                                <SmallButton
                                    onClick={() =>
                                        (videoElement.current.currentTime =
                                            videoElement.current.currentTime -
                                            0.1)
                                    }
                                >
                                    <FontAwesomeIcon icon={faBackward} />
                                </SmallButton>
                                <SmallButton onClick={toggleVideoPlaying}>
                                    <FontAwesomeIcon
                                        icon={isPlaying ? faPause : faPlay}
                                    />
                                </SmallButton>
                                <SmallButton
                                    onClick={() =>
                                        (videoElement.current.currentTime =
                                            videoElement.current.currentTime +
                                            0.1)
                                    }
                                >
                                    <FontAwesomeIcon icon={faForward} />
                                </SmallButton>
                            </VideoControls>
                        </VideoContainer>
                    </Column>
                    <FlexColumn large={5}>
                        {false && <div>Video uploader</div>}
                        <FlexRow>
                            {/* <SmallButton
                                onClick={() =>
                                    (videoElement.current.currentTime =
                                        videoElement.current.currentTime - 1)
                                }
                            >
                                <FontAwesomeIcon icon={faBackward} />
                            </SmallButton>
                            <SmallButton onClick={() => videoElement.current.play()}>
                                <FontAwesomeIcon icon={faPlay} />
                            </SmallButton>
                            <SmallButton onClick={() => videoElement.current.pause()}>
                                <FontAwesomeIcon icon={faPause} />
                            </SmallButton>
                            <SmallButton
                                onClick={() =>
                                    (videoElement.current.currentTime =
                                        videoElement.current.currentTime + 1)
                                }
                            >
                                <FontAwesomeIcon icon={faForward} />
                            </SmallButton> */}
                        </FlexRow>
                        {/* <div>
                            <Column medium={6}>
                                Duration:
                                <InputField
                                    type="number"
                                    disabled
                                    value={Math.round(duration)}
                                    onChange={() => {}}
                                />
                            </Column>
                            <Column medium={6}>
                                Elapsed:{" "}
                                <InputField
                                    type="number"
                                    max={duration}
                                    min="0"
                                    value={Math.round(elapsed)}
                                    onChange={event => {
                                        setElapsed(event.value);
                                        videoElement.current.currentTime =
                                            event.value;
                                    }}
                                />
                            </Column>
                        </div> */}
                    </FlexColumn>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Column medium={12} style={{ textAlign: "right" }}>
                        <Button
                            onClick={() => {
                                onChange({
                                    name: "value",
                                    value: {
                                        ...value,
                                        captions: tempCaptions,
                                        autoplay: tempAutoplay
                                    }
                                });
                                onClose();
                            }}
                        >
                            {trans("Save")}
                        </Button>
                    </Column>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default SequencedVideoEditor;
