import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
    Modal,
    Row,
    Column,
    MultiCheckbox,
    StyledCheckbox,
    Button,
    useAddToast,
    useToasts,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const SelectAllCheckbox = styled(StyledCheckbox)`
    font-weight: bold;
`;
const BulkGenerateModal = ({ group, userAsset, visible, onClose }) => {
    const intl = useIntl();
    const [selectedAll, setSelectedAll] = useState(false);
    const [targetUuids, setTargetUuids] = useState(false);
    const [showThankYou, setShowThankyou] = useState(false);
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const generateBulkAssetFiles = () => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Bulk asset files are being generated"),
        });
        axios
            .post(`/api/v1/assets/bulk-generate`, {
                user_asset_uuid: userAsset.uuid,
                target_uuids: targetUuids,
            })
            .then(({ data: { data } }) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                setShowThankyou(true);
            })
            .catch((error) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    addToast({
                        type: "alert",
                        content: error.response.data.message,
                        showFor: 10000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "There was an error generating your files. Please try again later, or contact support."
                        ),
                        showFor: 10000,
                    });
                }
            });
    };

    const onChange = (item) => {
        setTargetUuids(item.value);
    };

    const selectAll = (event) => {
        const allChildrenUuids = group.children.map((child) => {
            return child.uuid;
        });
        if (event.target.checked) {
            setSelectedAll(true);
            setTargetUuids(allChildrenUuids);
        } else {
            setTargetUuids(false);
            setSelectedAll(false);
        }
    };

    const mapOptions = (groups) => {
        return groups.map((item) => {
            return {
                value: item.uuid,
                text: item.name,
            };
        });
    };

    return (
        <Modal visible={visible} onClose={onClose} zIndex={0} maxWidth="600px">
            {showThankYou === false && (
                <React.Fragment>
                    <Row>
                        <Column>
                            <h2>{trans("Bulk Asset File Generation")}</h2>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <p>
                                {trans(
                                    "Please select below which groups you want to generate the artwork file on behalf of. "
                                )}
                            </p>
                        </Column>
                        <Column>
                            <h3 style={{ marginTop: "1rem" }}>
                                {trans("Available Groups")}
                            </h3>
                        </Column>
                        <Column
                            style={{
                                height: "200px",
                                overflowY: "scroll",
                                marginBottom: "10px",
                            }}
                        >
                            <SelectAllCheckbox
                                onChange={selectAll}
                                text={intl.formatMessage({ id: "Select all" })}
                                checked={selectedAll}
                            />
                            <MultiCheckbox
                                name="target_uuids"
                                onChange={onChange}
                                options={mapOptions(group.children)}
                                value={targetUuids}
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Column medium={8}>
                            {(userAsset.asset_type === "inDesign" ||
                                userAsset.asset_type?.slug === "inDesign") &&
                            targetUuids.length > 10 ? (
                                trans(
                                    "Your request may take over {time} minutes",
                                    {
                                        time: targetUuids.length * 3,
                                    }
                                )
                            ) : (
                                <span>&nbsp;</span>
                            )}
                        </Column>
                        <Column medium={4} style={{ textAlign: "right" }}>
                            <Button onClick={generateBulkAssetFiles}>
                                {trans("Generate Files")}
                            </Button>
                        </Column>
                    </Row>
                </React.Fragment>
            )}
            {showThankYou === true && (
                <React.Fragment>
                    <Row>
                        <Column>
                            <h2>{trans("Thank You")}</h2>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <p>
                                {trans(
                                    "Your request has been queued. You will receive a notification from the system when the file(s) are ready to review. To view the status of the queue please click"
                                )}{" "}
                                <Link to={"/resources/my-media/bulk-files"}>
                                    {trans("here")}
                                </Link>
                            </p>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <Button onClick={onClose}>{trans("Close")}</Button>
                        </Column>
                    </Row>
                </React.Fragment>
            )}
        </Modal>
    );
};

export default BulkGenerateModal;
