import React, { useState } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import UserAssetContext from "./UserAssetContext";
import BulkFileListing from "./bulkFiles/BulkFileListing";
import EditUserAsset from "./screens/EditUserAsset";
import ViewUserAsset from "./screens/ViewUserAsset";
import ListUserAssets from "./screens/ListUserAssets";
import CreateUserAsset from "./screens/CreateUserAsset";
import { useUserAsset } from "./hooks/useUserAsset";
import { useUserAssets } from "./hooks/useUserAssets";

const UserAssetRouting = ({ userAssetPath, match: { path }, group }) => {
    const [refreshSearch, setRefreshSearch] = useState(false);
    const [userAssetData, userAssetDispatch] = useUserAsset();
    const {
        userAssets,
        getUserAssets,
        selectUserAsset,
        userAssetsDispatch,
        assetsLoading,
    } = useUserAssets();

    return (
        <UserAssetContext.Provider
            value={{
                userAssets,
                userAssetDispatch,
                userAssetsDispatch,
                userAssetPath: path,
                getUserAssets,
                selectUserAsset,
                group: group,
            }}
        >
            <Switch>
                <Route
                    exact
                    path={`${path}`}
                    render={() => {
                        return (
                            <ListUserAssets
                                assetsPath={`${path}`}
                                userAssets={userAssets}
                                getUserAssets={getUserAssets}
                                selectUserAsset={selectUserAsset}
                                refreshSearch={refreshSearch}
                                setRefreshSearch={setRefreshSearch}
                                assetsLoading={assetsLoading}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${path}/bulk-files`}
                    render={() => {
                        return <BulkFileListing />;
                    }}
                />
                <Route
                    exact
                    path={`${path}/:userAssetUuid`}
                    render={(props) => {
                        const {
                            match: {
                                params: { userAssetUuid },
                            },
                        } = props;

                        return (
                            <ViewUserAsset
                                userAsset={userAssets.selectedUserAsset}
                                userAssetUuid={userAssetUuid}
                                selectUserAsset={selectUserAsset}
                                assetsPath={`${path}`}
                                // assetPath={userAssetPath}
                            />
                        );
                    }}
                />
                <Route
                    path={`${path}/:userAssetUuid/edit`}
                    render={(props) => {
                        const {
                            match: {
                                params: { userAssetUuid },
                            },
                        } = props;

                        return (
                            <EditUserAsset
                                userAsset={userAssets.selectedUserAsset}
                                assetUuid={userAssetUuid}
                                assetsPath={`${path}`}
                                selectUserAsset={selectUserAsset}
                                setRefreshSearch={setRefreshSearch}
                                // assetPath={userAssetPath}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${path}/create/:assetUuid`}
                    render={(props) => {
                        const {
                            match: {
                                params: { assetUuid },
                            },
                        } = props;

                        return (
                            <CreateUserAsset
                                assetUuid={assetUuid}
                                assetsPath={`${path}`}
                                // userAssetPath={userAssetPath}
                                selectUserAsset={selectUserAsset}
                                setRefreshSearch={setRefreshSearch}
                            />
                        );
                    }}
                />
            </Switch>
        </UserAssetContext.Provider>
    );
};

export default withRouter(UserAssetRouting);
