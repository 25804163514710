import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Row,
    Column,
    IconTextButton,
    Badge,
    Modal,
    Table,
    Loading,
    Panel,
} from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import { usePermissions, useUser } from "@cortexglobal/cortex-auth-redux";
import { format } from "@cortexglobal/cortex-utilities";

const AttachMarketingPlanner = ({
    event,
    getEvent,
    setShowModal,
    plannerPath,
    can,
}) => {
    const { user } = useUser();
    const [campaigns, setCampaigns] = useState({ loaded: false, data: [] });

    useEffect(() => {
        axios
            .post(`/api/v1/planner/get-tasks?include=tasks,tasks.activities`, {
                startDate: event.start_date,
                endDate: event.end_date,
            })
            .then((response) => {
                //console.log(response.data.data);
                const data = response.data.data;
                //console.log(data);

                setCampaigns({ loaded: true, data });
            })
            .catch((e) => e);
    }, [event]);

    const attachCampaign = (task) => {
        //Create a new activity for the event
        axios
            .post(`/api/v1/events/${event.uuid}/activity`, {
                task_uuid: task.uuid,
            })
            .then((response) => {
                setShowModal(false);
                getEvent(event.uuid);
            })
            .catch((e) => e);
    };

    if (!campaigns.loaded) return <Loading />;

    return (
        <>
            <Row>
                <Column>
                    <h2>{trans("Choose a Campaign or create a new one")}</h2>
                    {campaigns.data.length > 0 ? (
                        <Table>
                            <thead>
                                <tr>
                                    <th>{trans("Name")}</th>
                                    <th>{trans("Dates")}</th>
                                    <th>{trans("Planned Spend")}</th>
                                    <th>{trans("Actual Spend")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaigns.data.map((campaign) => {
                                    return (
                                        <tr>
                                            <td>{campaign.name}</td>
                                            <td>
                                                {format(
                                                    new Date(
                                                        campaign.start_date
                                                    )
                                                )}
                                                {" - "}
                                                {format(
                                                    new Date(campaign.end_date)
                                                )}
                                            </td>
                                            <td>
                                                <FormattedCurrency
                                                    value={
                                                        campaign.planned_spend
                                                            .value
                                                    }
                                                    currency={
                                                        user?.currency
                                                            ?.iso_code || "GBP"
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <FormattedCurrency
                                                    value={
                                                        campaign.actual_spend
                                                            .value
                                                    }
                                                    currency={
                                                        user?.currency
                                                            ?.iso_code || "GBP"
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <IconTextButton
                                                    onClick={() => {
                                                        attachCampaign(
                                                            campaign
                                                        );
                                                    }}
                                                    showCircle={false}
                                                    expanded={false}
                                                    icon={faCalendarCheck}
                                                >
                                                    {trans("Select")}
                                                </IconTextButton>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : (
                        <div>
                            <h3>{trans("No campaigns found")}</h3>

                            {can("planner-manage") && (
                                <IconTextButton
                                    icon={"plus"}
                                    as={Link}
                                    to={`${plannerPath}/add?eventUuid=${event.uuid}`}
                                    showCircle={false}
                                >
                                    {trans("Create a new campaign")}
                                </IconTextButton>
                            )}
                        </div>
                    )}
                </Column>
            </Row>
            {/* <Row>
                <Column style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={() => {}}>
                        {trans("Create a new campaign")}
                    </Button>
                    <Button disabled={!selectedCampaign} onClick={() => {}}>
                        {trans("Select")}
                    </Button>
                </Column>
            </Row> */}
        </>
    );
};

const MarketingPlannerSummary = ({
    event,
    settings: { excludedOptionalFields },
    plannerPath,
    getEvent,
}) => {
    const { can } = usePermissions();
    const theme = useTheme();

    const [showModal, setShowModal] = useState(false);

    const { activity } = event.raw;

    if (!can("planner-view")) return null;

    return (
        <>
            <h3>{trans("Marketing Planner")}</h3>
            {!activity ? (
                //If no activity is attached to the event, display a message and a button to attach a campaign
                <Panel>
                    <Row>
                        <Column>
                            <p>
                                {trans(
                                    "No activity selected, select an existing campaign or create a new one to attach it to"
                                )}
                            </p>
                        </Column>
                    </Row>
                    <IconTextButton
                        icon={"link"}
                        onClick={() => {
                            setShowModal(true);
                        }}
                        showCircle={false}
                    >
                        {trans("Select campaign")}
                    </IconTextButton>
                    {can("planner-manage") && (
                        <IconTextButton
                            icon={"plus"}
                            as={Link}
                            to={`${plannerPath}/add?eventUuid=${event.uuid}`}
                            showCircle={false}
                        >
                            {trans("Create a new campaign")}
                        </IconTextButton>
                    )}
                    {showModal && (
                        <Modal
                            visible={showModal}
                            onClose={() => setShowModal(false)}
                            maxWidth={"90%"}
                        >
                            <AttachMarketingPlanner
                                event={event}
                                getEvent={getEvent}
                                setShowModal={setShowModal}
                                plannerPath={plannerPath}
                                can={can}
                            />
                        </Modal>
                    )}
                </Panel>
            ) : (
                //Otherwise display the activity name, details and link to view the activity
                <Table tableType="noStripe">
                    <tbody>
                        {activity.task && (
                            <tr>
                                <th style={{ width: "33%" }}>
                                    {trans("Campaign")}
                                </th>
                                <td>
                                    <Link
                                        to={`${plannerPath}/task/${activity.task.uuid}`}
                                    >
                                        <Badge
                                            style={{
                                                margin: "0 0.5rem 0 0",
                                                textDecoration: "none",
                                            }}
                                            backgroundColor={
                                                theme.planner.colors.activities
                                                    .plan
                                            }
                                        >
                                            {activity.task.ref}
                                        </Badge>
                                        {activity.task.name}
                                    </Link>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th style={{ width: "33%" }}>
                                {trans("Activity")}
                            </th>
                            <td>
                                <Link
                                    to={`${plannerPath}/task/${activity.task.uuid}/activity/${activity.uuid}`}
                                >
                                    <Badge
                                        type={"warning"}
                                        style={{
                                            margin: "0 0.5rem 0 0",
                                            textDecoration: "none",
                                        }}
                                    >
                                        {activity.ref}
                                    </Badge>
                                    {activity.name}
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            )}
        </>
    );
};

export default MarketingPlannerSummary;
