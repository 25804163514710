import React, { Component } from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { Panel, Loading } from "@cortexglobal/rla-components";
import {
    mediaSearch,
    mediaClearResults,
    getMediaOptions,
    uploadStarted,
    getFolders,
    updateExpandList,
    setCurrentMedia,
    setCurrentFolder,
    getTopFolders,
} from "../mediaLibraryActions";

import Search from "../MediaSearch";
import SearchResults from "./GallerySearchResults";

import Folders from "../../folders/frontend/Folders";

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showResults: false,
            showFolders: false,
            showSearchForm: true,
            folderData: {},
            options: {},
            lastViewState: {},
        };
    }

    componentWillMount() {
        this.props.getMediaOptions("media");
    }

    search = (values) => {
        let item = this.resetVisible();
        this.setState({ ...item, showResults: true, showSearchForm: true });
        this.props.mediaClearResults();
        this.props.mediaSearch(values);
    };

    close = () => {
        let item = this.getVisibleValues(this.state.lastViewState);
        this.setState(item);
    };

    closeFolders = () => {
        let item = this.resetVisible();
        this.setState({ ...item, showSearchForm: true });
    };

    showFolders = () => {
        let item = this.resetVisible();
        this.setState({ ...item, showFolders: true, showSearchForm: true });
    };

    resetVisible() {
        return {
            showResults: false,
            showFolders: false,
            showSearchForm: false,
        };
    }

    getVisibleValues(location) {
        const values = {
            showResults: location.showResults,
            showFolders: location.showFolders,
            showSearchForm: location.showSearchForm,
        };
        return values;
    }

    getFolders = (uuid) => {
        const f = this.props.mediaLibrary.folders;
        if (!f[uuid]) {
            this.props.getFolders(uuid);
        } else {
            this.props.setCurrentFolder(f[uuid]);
        }
    };
    getTopFolders = () => {
        this.props.getTopFolders();
    };

    getFolderContents = (folder) => {
        this.props.getFolders(uuid);
    };

    chooseItem = (item) => {
        this.props.chooseItem(item);
    };

    updateExpandList = (item) => {
        if (item.uuid) {
            let list = this.props.mediaLibrary.expandList;
            let index = list.indexOf(item.uuid);
            if (index < 0) {
                list.push(item.uuid);
                this.props.updateExpandList(list);
            } else {
                list.splice(index, 1);
                this.props.updateExpandList(list);
            }
        }
    };

    render() {
        const { mediaLibrary } = this.props;
        return (
            <Panel>
                {this.state.showSearchForm && (
                    <Search
                        search={this.search}
                        placeholder="Search by name or slug"
                        fetchAll={true}
                        showFolders={this.showFolders}
                    />
                )}
                {this.state.showResults && mediaLibrary.searching === false && (
                    <SearchResults
                        results={mediaLibrary.results}
                        chooseItem={this.chooseItem}
                    />
                )}

                {this.state.showFolders && (
                    <Folders
                        library={mediaLibrary}
                        getFolders={this.getFolders}
                        updateExpandList={this.updateExpandList}
                        getTopFolders={this.getTopFolders}
                        chooseItem={this.chooseItem}
                        closeFolders={this.closeFolders}
                    />
                )}

                {this.props.mediaLibrary.searching === true && <Loading />}
            </Panel>
        );
    }
}
Gallery.propTypes = {
    chooseItem: PropTypes.func.isRequired,
};

export default Gallery;

// function mapStateToProps(state) {
//     let { mediaLibrary } = state;
//     return {
//         mediaLibrary
//     };
// }

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators(
//         {
//             mediaSearch,
//             mediaClearResults,
//             getMediaOptions,
//             uploadStarted,
//             getFolders,
//             updateExpandList,
//             setCurrentMedia,
//             setCurrentFolder,
//             getTopFolders
//         },
//         dispatch
//     );
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
