import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { trans, useIntl } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    FormRow,
    PlainButton,
    InputField,
    IconTextButton,
    TextareaField,
    DraftJSField,
    Select,
    Toggle,
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AutofillFieldsTable from "../../assets/forms/components/AutofillFieldsTable";
import AllAssetImageBrowser from "../../assets/forms/components/assetImageBrowser/AllAssetImageBrowser";
import ImageCustomField from "./components/ImageCustomField";

const CustomFieldRow = styled(Row)`
    /* display: flex;
    align-items: center; */
    margin-bottom: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
`;

const DeleteButtonColumn = styled(Column)`
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0.5rem 0;
    p {
        margin: 0;
    }
`;

const TypeColumn = styled(Column)`
    padding-left: 1rem;
`;

// const DeleteButton = styled(PlainButton)`
//     margin-left: 1rem;
//     color: ${(props) => props.theme.colors.alert};
//     font-size: 1.2rem;
// `;

export const CustomFieldSection = ({ campaign, onChange, errors }) => {
    const intl = useIntl();
    const [autofill, setAutofill] = useState({
        loaded: false,
        data: [],
    });

    useEffect(() => {
        if (!autofill.loaded) {
            getAssetAutofillFields();
        }
    }, [autofill]);

    const getAssetAutofillFields = () => {
        axios
            .get("/api/v1/assets/autofill-fields")
            .then(({ data }) => {
                setAutofill({
                    loaded: true,
                    data,
                });
            })
            .catch((e) => e);
    };
    const handleCustomFieldChange = (index, { name, value }) => {
        console.log({ index, name, value });
        //Find this custom field in the campaign object and update it
        const customFields = campaign.custom_fields.map((customField, i) => {
            if (i === index) {
                return { ...customField, [name]: value };
            }
            return customField;
        });

        onChange({ name: "custom_fields", value: customFields });
    };

    const handleTypeChange = (index, { name, value }) => {
        //Find this custom field in the campaign object and update it
        const customFields = campaign.custom_fields.map((customField, i) => {
            if (i === index) {
                return {
                    ...customField,
                    tag_type: value,
                    tag_value: "",
                    autofilled: false,
                };
            }
            return customField;
        });

        onChange({ name: "custom_fields", value: customFields });
    };

    const handleAddCustomField = () => {
        //Add a new custom field to the campaign object
        const customFields = Array.isArray(campaign.custom_fields)
            ? campaign.custom_fields
            : [];
        onChange({
            name: "custom_fields",
            value: [...customFields, { uuid: uuid(), tag_name: "", value: "" }],
        });
    };

    const renderValueField = (customField, index) => {
        switch (customField.tag_type) {
            case "text":
                return (
                    <InputField
                        name="tag_value"
                        value={customField.tag_value}
                        onChange={(e) => handleCustomFieldChange(index, e)}
                        error={errors[customField.tag_value]}
                    />
                );
            case "draft-js":
                return (
                    <DraftJSField
                        name="tag_value"
                        value={customField.tag_value}
                        onChange={(e) => handleCustomFieldChange(index, e)}
                        error={errors[customField.tag_value]}
                    />
                );
            case "image":
            case "gwd-image":
                return (
                    <ImageCustomField
                        customField={customField}
                        name="tag_value"
                        value={customField.tag_value}
                        onChange={(e) => handleCustomFieldChange(index, e)}
                        error={errors[customField.tag_value]}
                    />
                );

            case "video":
                return "TODO: Add asset video browser field";
            default:
                return <span>{trans("Please select a tag type")}</span>;
        }
    };
    return (
        <>
            {
                //List of existing custom fields
                //TODO: Add drag and drop to reorder custom fields and update the order property
            }
            <div>
                {Array.isArray(campaign.custom_fields) &&
                campaign.custom_fields.length > 0 ? (
                    campaign.custom_fields.map((customField, index) => (
                        <CustomFieldRow key={customField.uuid}>
                            <FormRow
                                label={trans("Name & Type")}
                                helpText={trans("Required")}
                                tooltip={trans(
                                    "The name is the name of the tag that will be used in the template, these need to match to allow the correct data to be inserted into the template.  The type is the type of data that will be stored in the custom field."
                                )}
                            >
                                <Column collapse medium={8}>
                                    <InputField
                                        label={trans("Tag Name")}
                                        labelStyle={{ display: "none" }}
                                        name="tag_name"
                                        value={customField.tag_name}
                                        onChange={(e) =>
                                            handleCustomFieldChange(index, e)
                                        }
                                        error={
                                            errors[
                                                `custom_fields.${index}.tag_name`
                                            ]
                                        }
                                    />
                                </Column>
                                <TypeColumn collapse medium={4}>
                                    <Select
                                        label={trans("Tag Type")}
                                        labelProps={{
                                            style: { display: "none" },
                                        }}
                                        name="tag_type"
                                        value={customField.tag_type}
                                        onChange={(e) =>
                                            handleTypeChange(index, e)
                                        }
                                        error={
                                            errors[
                                                `custom_fields.${index}.tag_type`
                                            ]
                                        }
                                        emptyOption={intl.formatMessage({
                                            id: "Type",
                                        })}
                                        options={[
                                            {
                                                value: "text",
                                                text: intl.formatMessage({
                                                    id: "Text",
                                                }),
                                            },
                                            {
                                                value: "draft-js",
                                                text: intl.formatMessage({
                                                    id: "Rich Text",
                                                }),
                                            },
                                            {
                                                value: "image",
                                                text: intl.formatMessage({
                                                    id: "Image",
                                                }),
                                            },
                                            {
                                                value: "gwd-image",
                                                text: intl.formatMessage({
                                                    id: "Google Wed Designer Image",
                                                }),
                                            },
                                            // { value: "video", text: "Video" },
                                        ]}
                                    />
                                </TypeColumn>
                            </FormRow>
                            <FormRow
                                label={trans("Value")}
                                helpText={trans(
                                    "This is the default value that will appear in the field"
                                )}
                                error={
                                    errors[`custom_fields.${index}.tag_value`]
                                }
                            >
                                {renderValueField(customField, index)}
                                {customField.tag_type && (
                                    <label
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "end",
                                            width: "100%",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        {trans("Auto fill this field ")}{" "}
                                        <Toggle
                                            name={"autofilled"}
                                            style={{ marginLeft: "0.5rem" }}
                                            checked={customField.autofilled}
                                            onChange={(event) => {
                                                handleCustomFieldChange(index, {
                                                    name: "autofilled",
                                                    value: !!event.target
                                                        .checked,
                                                });

                                                //TODO - If this is an image type field,
                                                //should probably clear the value
                                            }}
                                            small={true}
                                        />
                                    </label>
                                )}
                                {!!customField.autofilled && !!autofill.loaded && (
                                    <div style={{ margin: "2rem 0 1rem" }}>
                                        <AutofillFieldsTable
                                            listing={autofill.data}
                                            type={customField.tag_type}
                                        />
                                    </div>
                                )}
                            </FormRow>
                            <FormRow
                                label={trans("Label")}
                                helpText={trans(
                                    "This label will be displayed to users filling in the custom field information"
                                )}
                                error={errors[`custom_fields.${index}.label`]}
                            >
                                <InputField
                                    name="label"
                                    value={customField.label}
                                    onChange={(e) =>
                                        handleCustomFieldChange(index, e)
                                    }
                                />
                            </FormRow>
                            <FormRow
                                label={trans("Description")}
                                helpText={trans(
                                    "Optional, this description will be displayed to users filling in the custom field information"
                                )}
                                error={
                                    errors[`custom_fields.${index}.description`]
                                }
                            >
                                <TextareaField
                                    name="description"
                                    value={customField.description}
                                    onChange={(e) =>
                                        handleCustomFieldChange(index, e)
                                    }
                                    error={errors[customField.description]}
                                />
                            </FormRow>

                            <DeleteButtonColumn medium={6} centered>
                                <IconTextButton
                                    type="alert"
                                    icon={faTrash}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        //Remove this custom field from the campaign object
                                        const customFields =
                                            campaign.custom_fields.filter(
                                                (customField, i) => i !== index
                                            );
                                        onChange({
                                            name: "custom_fields",
                                            value: customFields,
                                        });
                                    }}
                                    showCircle={false}
                                >
                                    {trans("Remove this custom field")}
                                </IconTextButton>
                            </DeleteButtonColumn>
                        </CustomFieldRow>
                    ))
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <h3>{trans("No Custom Fields Setup")}</h3>
                    </div>
                )}
            </div>
            {
                //Option to add more custom fields
            }
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingBottom: "1rem",
                }}
            >
                <IconTextButton
                    icon="plus"
                    onClick={(e) => {
                        e.preventDefault();
                        //Add a new custom field to the campaign object
                        handleAddCustomField();
                    }}
                    showCircle={false}
                >
                    {trans("Add Custom Field")}
                </IconTextButton>
            </div>
        </>
    );
};

export default CustomFieldSection;
