import React from "react";

import LinksOutput from "./LinksOutput";
import LinksConfiguration from "./LinksConfiguration";

const Links = ({ configuring, ...props }) => {
    if (configuring) {
        return <LinksConfiguration {...props} />;
    }
    return <LinksOutput {...props} />;
};

export default Links;
