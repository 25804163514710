import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
    Button,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikDatePicker,
    Panel,
    Loading,
    Row,
    Column,
    SimpleDropzone,
    FormikColorField,
    FormRow,
    useAddToast,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { PreviewImage } from "../components/index";

const FILE_SIZE = 1024 * 1024 * 5; //Around 5mb
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = Yup.object().shape({
    dashboard_id: Yup.string().required("Please select a dashboard"),
    title: Yup.string().required(trans("Required")),
    description: Yup.string().nullable(),
    background_color: Yup.string(),
    link: Yup.string().required(trans("Required")),
    banner: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                if (value == null) {
                    return;
                }
                return Yup.mixed()
                    .test(
                        "fileSize",
                        "File too large",
                        (value) => value && value.size <= FILE_SIZE
                    )
                    .test(
                        "fileFormat",
                        "Unsupported Format",
                        (value) =>
                            value && SUPPORTED_FORMATS.includes(value.type)
                    ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
    order: Yup.number(),
    start_date: Yup.date().required(trans("Please pick a start date")),
    end_date: Yup.date()
        .min(
            Yup.ref("start_date"),
            trans("End date must be after the start date")
        )
        .required(trans("Please pick an end date")),
});
export type FeaturedItem = ReturnType<typeof schema.validateSync>;

export const FeaturedItemForm = ({ item, submitForm }) => {
    const [newPreviewFile, setNewPreviewFile] = useState(null);
    const [categories, setCategories] = useState([]);
    const addToast = useAddToast();
    const handleSubmit = (
        values: FeaturedItem,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();
        Object.keys(values).forEach((key) => {
            fd.append(key, values[key]);
        });
        submitForm(fd, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                addToast({
                    content: trans("Featured Item could not be saved"),
                    type: "error",
                    showFor: 3000,
                });
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    useEffect(() => {
        axios
            .get(`/api/v1/admin/dashboard/options`)
            .then((response) => {
                setCategories(response.data.data);
            })
            .catch((e) => e);
    }, []);

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );

    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("banner", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);
    return (
        <Formik
            initialValues={{
                dashboard_id: item.dashboard_id,
                title: item.title,
                description: item.description,
                background_color: item.background_color,
                link: item.link,
                banner: item.banner,
                order: item.order ?? 0,
                start_date: item.start_date,
                end_date: item.end_date,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Row>
                        <Panel>
                            <Form className="form-group">
                                <FormRow
                                    name="dashboard_id"
                                    label={trans("Dashboard")}
                                    helpText={trans("Required")}
                                    error={errors.dashboard_id}
                                >
                                    <FormikSelect
                                        name="dashboard_id"
                                        value={values.dashboard_id}
                                        options={categories}
                                    />
                                </FormRow>
                                <FormRow
                                    name="title"
                                    label={trans("Title")}
                                    helpText={trans("Required")}
                                    error={errors.title}
                                >
                                    <FormikInput
                                        name="title"
                                        value={values.title}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="description"
                                    label={trans("Description")}
                                    helpText={trans("Optional")}
                                    error={errors.description}
                                >
                                    <FormikInput
                                        name="description"
                                        value={values.description}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="background_color"
                                    label={trans("Background Color")}
                                    helpText={trans("Optional")}
                                    error={errors.background_color}
                                >
                                    <FormikColorField
                                        name="background_color"
                                        value={values.background_color}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="link"
                                    label={trans("Link")}
                                    helpText={trans("Required")}
                                    error={errors.link}
                                >
                                    <FormikInput
                                        name="link"
                                        value={values.link}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="banner"
                                    label={trans("Banner")}
                                    helpText={trans("Optional")}
                                    error={errors.banner}
                                >
                                    <SimpleDropzone
                                        onDrop={(acceptedFiles) =>
                                            onDrop(acceptedFiles, setFieldValue)
                                        }
                                        accept={SUPPORTED_FORMATS.join()}
                                        multiple={false}
                                    >
                                        <Button
                                            onClick={(event) => {
                                                event.preventDefault();
                                            }}
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            {values.banner &&
                                            values.banner !== ""
                                                ? trans("Update Banner")
                                                : trans("Add Banner")}
                                        </Button>
                                    </SimpleDropzone>

                                    {typeof values.banner === "string" &&
                                        values.banner !== "" && (
                                            <PreviewImage src={values.banner} />
                                        )}
                                    {newPreviewFile && (
                                        <PreviewImage src={newPreviewFile} />
                                    )}
                                </FormRow>
                                <FormRow
                                    name="order"
                                    label={trans("Order")}
                                    helpText={trans("Optional")}
                                    error={errors.order}
                                >
                                    <FormikInput
                                        type="number"
                                        name="order"
                                        value={values.order}
                                    />
                                </FormRow>
                                <FormRow
                                    name="start_date"
                                    label={trans("Start Date")}
                                    helpText={trans("Optional")}
                                    error={errors.start_date}
                                >
                                    <FormikDatePicker
                                        name="start_date"
                                        value={values.start_date}
                                    />
                                </FormRow>
                                <FormRow
                                    name="end_date"
                                    label={trans("End Date")}
                                    helpText={trans("Optional")}
                                    error={errors.end_date}
                                >
                                    <FormikDatePicker
                                        name="end_date"
                                        value={values.end_date}
                                    />
                                </FormRow>

                                <Row>
                                    <Column
                                        medium={6}
                                        style={{ textAlign: "right" }}
                                    >
                                        &nbsp;
                                    </Column>
                                    <Column
                                        medium={3}
                                        flex
                                        style={{
                                            paddingBottom: "1rem",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <SubmitButton
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </Column>
                                </Row>
                            </Form>
                        </Panel>
                    </Row>
                );
            }}
        </Formik>
    );
};

export default FeaturedItemForm;
