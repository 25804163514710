import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFolder,
    faCircleNotch,
    faEdit,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
    PlainButton,
    ShowMore,
    ContextMenu
} from "@cortexglobal/rla-components";
import { useIntl } from "@cortexglobal/rla-intl";

const FolderButton = styled(PlainButton)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
    margin: 0.5rem;
    width: 100%;
    height: 100%;
    min-height: ${({ numLines }) => `${numLines * 25}px`};
    background-color: ${({ theme }) => theme.folder.backgroundColor};
    box-shadow: ${({ boxShadow, theme }) =>
        boxShadow || theme.search.boxShadow};
    background: ${({ background, theme }) =>
        background || theme.search.background};
    transition: all 200ms;
    &:hover {
        box-shadow: ${({ boxShadow, theme }) =>
            boxShadow || theme.search.boxShadowHover};
    }
`;
const FolderIcon = styled(FontAwesomeIcon)`
    margin-right: 1rem;
`;
export const Folder = ({
    folder,
    onFolderUpdated,
    loaded,
    numLines = 2,
    canEdit = false,
    canDelete = false,
    selectFolderForEditing,
    selectFolderForDeleting
}) => {
    const intl = useIntl();

    //Set the amount to grow the icon (6 appears good for one line)
    const grow = numLines * 6;
    let actions = [];
    if (canEdit) {
        actions.push({
            name: intl.formatMessage({
                id: "Edit"
            }),
            onClick: () => selectFolderForEditing(folder),
            icon: faEdit
        });
    }
    if (canDelete) {
        actions.push({
            name: intl.formatMessage({
                id: "Delete"
            }),
            onClick: () => selectFolderForDeleting(folder),
            icon: faTrash
        });
    }

    return (
        <ContextMenu actions={actions}>
            <FolderButton
                onClick={() => onFolderUpdated(folder, "child")}
                expanded={true}
                disabled={!loaded}
                numLines={numLines}
            >
                <FolderIcon
                    icon={loaded ? faFolder : faCircleNotch}
                    spin={!loaded}
                    transform={`grow-${grow}`}
                />
                <ShowMore type={"tooltip"} lines={numLines}>
                    {folder.name ? folder.name : folder.text}
                </ShowMore>
            </FolderButton>
        </ContextMenu>
    );
};
