import React, { Component } from "react";
import PropTypes from "prop-types";
import Measure from "react-measure";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

import CalendarContext from "./CalendarContext";
import CalendarHeader from "./CalendarHeader";
import CalendarBody from "./CalendarBody";
import CalendarFooter from "./CalendarFooter";
import CalendarBackground from "./CalendarBackground";

class LinearCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //TODO - Extra Feature - Change units dependent on zoom
            units: {
                macro: "MMMM",
                micro: "D",
                macroName: "month",
                microName: "day"
            },
            widths: {
                calendar: 0,
                sidebar: props.sidebarWidth,
                addButton: props.addButtonWidth,
                item: 0
            },
            maxHeight: 0,
            dimensions: {
                height: 0
            },
            animating: false,
            allowResize: false
        };
    }

    resize = contentRect => {
        //We only care if the width has changed, so bail if not
        // if (
        //     !this.state.allowResize &&
        //     this.state.dimensions.width === contentRect.bounds.width
        // ) {
        //     return;
        // }
        //Work out what a unit should measure
        // console.log("resize calc");

        const calendarWidth =
            contentRect.bounds.width -
            this.props.sidebarWidth -
            this.props.summaryColumn.width -
            this.props.addButtonWidth;
        const itemWidth = calendarWidth / this.props.dates.length;
        this.setState({
            dimensions: contentRect.bounds,
            maxHeight:
                contentRect.bounds.height > this.state.maxHeight
                    ? contentRect.bounds.height
                    : this.state.maxHeight,
            widths: {
                calendar: calendarWidth,
                sidebar: this.props.sidebarWidth,
                addButton: this.props.addButtonWidth,
                item: itemWidth
            },
            allowResize: false
        });
    };

    setAnimating = animating => {
        // console.log("animating", animating);
        this.setState(state => {
            if (state.animating === true && animating === false) {
                // console.log("allowing resize");
                return { animating, allowResize: true };
            }
            return { animating };
        });
    };
    render() {
        return (
            <CalendarContext.Provider
                value={{
                    summaryColumn: this.props.summaryColumn,
                    colors: this.props.theme.colors,
                    labels: this.props.labels,

                    start: this.props.start,
                    end: this.props.end,

                    dates: this.props.dates,
                    heights: this.props.theme.heights,
                    widths: this.state.widths,
                    units: this.state.units,
                    // units: {
                    //     macro: "MMMM",
                    //     micro: "D",
                    //     macroName: "month",
                    //     microName: "day"
                    // },
                    prev: this.props.prev,
                    prevAction: this.props.prevAction,
                    next: this.props.next,
                    nextAction: this.props.nextAction,
                    onShowTasks: this.props.onShowTasks,
                    activityClick: this.props.activityClick,
                    activityContent: this.props.activityContent,
                    taskContent: this.props.taskContent,
                    categorySummary: this.props.categorySummary,
                    editTaskCategory: this.props.editTaskCategory,
                    addTaskCategory: this.props.addTaskCategory,
                    addTask: this.props.addTask,
                    addActivity: this.props.addActivity,
                    deleteTask: this.props.deleteTask,
                    viewTask: this.props.viewTask,
                    taskClick: this.props.taskClick,

                    user: this.props.user

                    //setAnimating: this.setAnimating
                    // animating: this.state.animating
                }}
            >
                <Measure
                    bounds
                    onResize={debounce(this.resize.bind(this), 300)}
                >
                    {({ measureRef }) => (
                        <div
                            ref={measureRef}
                            className="linearCalendar"
                            // style={{ overflow: "hidden" }}
                        >
                            <CalendarHeader
                                widths={this.state.widths}
                                style={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1000
                                }}
                            >
                                {this.props.header}
                            </CalendarHeader>

                            <CalendarBackground
                                height={this.state.maxHeight}
                                widths={this.state.widths}
                                style={{
                                    zIndex: 999
                                    // overflow: "hidden"
                                }}
                            >
                                <CalendarBody
                                    {...this.props}
                                    {...this.state}
                                ></CalendarBody>
                            </CalendarBackground>
                        </div>
                    )}
                </Measure>
                <CalendarFooter
                    widths={this.state.widths}
                    units={this.state.units}
                >
                    {this.props.footer}
                </CalendarFooter>
            </CalendarContext.Provider>
        );
    }
}

LinearCalendar.propTypes = {
    tasks: PropTypes.array,
    summaryColumn: PropTypes.shape({
        header: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.object
        ]),
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.object
        ]),
        width: PropTypes.number.isRequired
    }),
    sidebarWidth: PropTypes.number,
    addButtonWidth: PropTypes.number,
    addActivity: PropTypes.func,
    activityContent: PropTypes.func,
    activityClick: PropTypes.func,
    taskClick: PropTypes.func,
    onShowTasks: PropTypes.func,
    editTaskCategory: PropTypes.func,
    addTaskCategory: PropTypes.func,
    addTask: PropTypes.func,
    deleteTask: PropTypes.func,
    viewTask: PropTypes.func,
    prev: PropTypes.shape({
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        className: PropTypes.string
    }),
    prevAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    next: PropTypes.shape({
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        className: PropTypes.string
    }),
    nextAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    labels: PropTypes.shape({
        addTask: PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            title: PropTypes.string
        }),
        viewTask: PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            title: PropTypes.string
        }),
        deleteTask: PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            title: PropTypes.string
        }),
        addActivity: PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            title: PropTypes.string
        })
    }),
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            activities: PropTypes.shape({
                national: PropTypes.string,
                plan: PropTypes.string,
                group: PropTypes.string
            }),
            header: PropTypes.shape({
                label: PropTypes.string,
                button: PropTypes.string
            }),
            calendarCategory: PropTypes.shape({
                color: PropTypes.string,
                background: PropTypes.string
            }),
            micro: PropTypes.shape({
                label: PropTypes.string,
                current: PropTypes.string,
                currentBackground: PropTypes.string,
                line: PropTypes.string
            }),
            taskRow: PropTypes.shape({
                label: PropTypes.string,
                labelHover: PropTypes.string,
                button: PropTypes.string,
                background: PropTypes.string,
                sidebar: PropTypes.string
            })
        }),
        heights: PropTypes.shape({
            row: PropTypes.number,
            task: PropTypes.number,
            activity: PropTypes.number
        }),
        user: PropTypes.object
    })
};

LinearCalendar.defaultProps = {
    tasks: [],
    prevAction: false,
    nextAction: false,
    onShowTasks: () => {},
    addTaskCategory: () => {},
    editTaskCategory: () => {},
    summaryColumn: {
        header: { element: () => {} },
        content: { element: () => {} },
        width: 0
    },
    labels: {
        addTask: { content: String.fromCharCode(10133), title: "Add Task" },
        viewTask: { content: String.fromCharCode(9998), title: "Edit Task" },
        deleteTask: {
            content: String.fromCharCode(10134),
            title: "Delete Task"
        },
        addActivity: {
            content: String.fromCharCode(10133),
            title: "Add Activity"
        }
    },
    heights: {
        row: 40,
        task: 16,
        activity: 16
    },
    user: {}
};

export default LinearCalendar;
