import React, { memo } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import StyledCheckbox from "../styledElements/styledCheckbox";

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${({ depth }) => depth * 1}rem;
`;

const CheckboxAndLabel = styled(StyledCheckbox)`
    flex-grow: 1;
    /* cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")}; */
`;

const ExpandControl = styled.div`
    cursor: pointer;
    min-width: 2rem;
    display: flex;
    justify-content: flex-end;
    padding-right: ${({ fixHeight }) => (fixHeight ? "1.5rem" : "0.2rem")};
`;

const CheckboxTreeCheckbox = ({
    option,
    name,
    depth = 0,
    parents = [],
    hasChildren,
    isShowingChildren,
    isChecked,
    handleChange,
    handleExpand,
    selectOnlyLeafNodes,
    marginBottom,
    expandedOptionIndex,
    optionIcon,
    fixHeight = false,
}) => {
    return (
        <CheckboxWrapper depth={depth}>
            <CheckboxAndLabel
                key={option.value}
                name={name}
                onChange={(event) => handleChange(event, option.value)}
                checked={isChecked}
                text={option.text}
                marginBottom={marginBottom}
                onClick={() => {
                    selectOnlyLeafNodes &&
                        hasChildren &&
                        handleExpand(expandedOptionIndex, option, parents);
                }}
                disabled={
                    (selectOnlyLeafNodes && hasChildren) || option.disabled
                }
                icon={optionIcon}
            />

            {hasChildren && (
                <ExpandControl
                    onClick={() =>
                        handleExpand(expandedOptionIndex, option, parents)
                    }
                    fixHeight={fixHeight}
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        rotation={isShowingChildren ? 270 : 90}
                    />
                </ExpandControl>
            )}
        </CheckboxWrapper>
    );
};

export default memo(CheckboxTreeCheckbox);
