const icons = {
    bell: {
        viewbox: "0 0 80 88.5",
        paths: [
            "M63.3,36.8C51.9,12.3,45.9,3,27.3,3.4c-6.6,0.1-5-4.8-10.1-2.9c-5.1,1.9-0.7,4.6-5.9,8.9 C-3.2,21.2-1.9,32.2,4.9,58.5c2.8,11.1-6.8,11.6-3,22.3c2.8,7.8,23.4,11.1,45.1,3C68.7,75.8,82.4,59.8,79.6,52 C75.8,41.2,68.1,47.2,63.3,36.8z M44.6,77.2c-19.4,7.2-35.4,3-36,1.1C7.4,75.1,14.9,64.2,37,56c22.2-8.2,34.6-5.2,35.9-1.6 C73.7,56.6,64,70,44.6,77.2z M38.4,59.8c-10.1,3.8-17.2,8.1-21.8,12c3.2,2.9,9.2,3.6,15.2,1.4c7.6-2.8,12.3-9.3,10.5-14.5 c0-0.1-0.1-0.1-0.1-0.2C41,58.8,39.7,59.3,38.4,59.8z"
        ]
    },
    gear: {
        viewbox: "0 0 21.589 21.589",
        paths: [
            "M18.622,8.371l-0.545-1.295c0,0,1.268-2.861,1.156-2.971l-1.679-1.639c-0.116-0.113-2.978,1.193-2.978,1.193l-1.32-0.533 c0,0-1.166-2.9-1.326-2.9H9.561c-0.165,0-1.244,2.906-1.244,2.906L6.999,3.667c0,0-2.922-1.242-3.034-1.131L2.289,4.177 C2.173,4.29,3.507,7.093,3.507,7.093L2.962,8.386c0,0-2.962,1.141-2.962,1.295v2.322c0,0.162,2.969,1.219,2.969,1.219l0.545,1.291 c0,0-1.268,2.859-1.157,2.969l1.678,1.643c0.114,0.111,2.977-1.195,2.977-1.195l1.321,0.535c0,0,1.166,2.898,1.327,2.898h2.369 c0.164,0,1.244-2.906,1.244-2.906l1.322-0.535c0,0,2.916,1.242,3.029,1.133l1.678-1.641c0.117-0.115-1.22-2.916-1.22-2.916 l0.544-1.293c0,0,2.963-1.143,2.963-1.299v-2.32C21.59,9.425,18.622,8.371,18.622,8.371z M14.256,10.794 c0,1.867-1.553,3.387-3.461,3.387c-1.906,0-3.461-1.52-3.461-3.387s1.555-3.385,3.461-3.385 C12.704,7.41,14.256,8.927,14.256,10.794z"
        ]
    },
    menu: {
        viewbox: "0 0 18 24",
        paths: [
            "M16 17H2c-1.1 0-2 .9-2 2s.9 2 2 2h14c1.1 0 2-.9 2-2S17.1 17 16 17zM16 10H2c-1.1 0-2 .9-2 2s.9 2 2 2h14c1.1 0 2-.9 2-2S17.1 10 16 10zM16 3H2C.9 3 0 3.9 0 5s.9 2 2 2h14c1.1 0 2-.9 2-2S17.1 3 16 3z"
        ]
    },
    close: {
        viewbox: "0 0 20 20",
        paths: [
            "M12.83 10 20 17.17 17.17 20 10 12.83 2.83 20 0 17.17 7.17 10 0 2.83 2.83 0 10 7.17 17.17 0 20 2.83 12.83 10z"
        ]
    },
    check: {
        viewbox: "0 0 32 32",
        paths: [
            "M16,0C7.163,0,0,7.163,0,16c0,8.837,7.163,16,16,16c8.836,0,16-7.164,16-16C32,7.163,24.836,0,16,0z M16,30   C8.268,30,2,23.732,2,16C2,8.268,8.268,2,16,2s14,6.268,14,14C30,23.732,23.732,30,16,30z",
            "M23.3,10.393L13.012,20.589l-4.281-4.196c-0.394-0.391-1.034-0.391-1.428,0   c-0.395,0.391-0.395,1.024,0,1.414l4.999,4.899c0.41,0.361,1.023,0.401,1.428,0l10.999-10.899c0.394-0.39,0.394-1.024,0-1.414   C24.334,10.003,23.695,10.003,23.3,10.393z"
        ]
    },
    cross: {
        viewbox: "0 0 32 32",
        paths: [
            "M16,0C7.163,0,0,7.163,0,16c0,8.837,7.163,16,16,16c8.836,0,16-7.164,16-16C32,7.163,24.836,0,16,0z M16,30   C8.268,30,2,23.732,2,16C2,8.268,8.268,2,16,2s14,6.268,14,14C30,23.732,23.732,30,16,30z",
            "M9.271,22.707c0.394,0.39,1.034,0.39,1.429,0l5.324-5.28l5.276,5.276c0.394,0.395,1.034,0.395,1.428,0   c0.394-0.395,0.394-1.037,0-1.432l-5.268-5.269l5.238-5.195c0.394-0.391,0.394-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0   l-5.231,5.188l-5.309-5.31c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l5.301,5.302l-5.331,5.287   C8.877,21.683,8.877,22.317,9.271,22.707z"
        ]
    },
    arrowLeft: {
        viewbox: "0 0 32 32",
        paths: [
            "M7.701,14.276l9.586-9.585c0.879-0.878,2.317-0.878,3.195,0l0.801,0.8c0.878,0.877,0.878,2.316,0,3.194  L13.968,16l7.315,7.315c0.878,0.878,0.878,2.317,0,3.194l-0.801,0.8c-0.878,0.879-2.316,0.879-3.195,0l-9.586-9.587  C7.229,17.252,7.02,16.62,7.054,16C7.02,15.38,7.229,14.748,7.701,14.276z"
        ]
    },
    arrowRight: {
        viewbox: "0 0 32 32",
        paths: [
            "M24.291,14.276L14.705,4.69c-0.878-0.878-2.317-0.878-3.195,0l-0.8,0.8c-0.878,0.877-0.878,2.316,0,3.194  L18.024,16l-7.315,7.315c-0.878,0.878-0.878,2.317,0,3.194l0.8,0.8c0.878,0.879,2.317,0.879,3.195,0l9.586-9.587  c0.472-0.471,0.682-1.103,0.647-1.723C24.973,15.38,24.763,14.748,24.291,14.276z"
        ]
    },
    arrowUp: {
        viewbox: "0 0 32 32",
        paths: [
            "M18.221,7.206l9.585,9.585c0.879,0.879,0.879,2.317,0,3.195l-0.8,0.801c-0.877,0.878-2.316,0.878-3.194,0  l-7.315-7.315l-7.315,7.315c-0.878,0.878-2.317,0.878-3.194,0l-0.8-0.801c-0.879-0.878-0.879-2.316,0-3.195l9.587-9.585  c0.471-0.472,1.103-0.682,1.723-0.647C17.115,6.524,17.748,6.734,18.221,7.206z"
        ]
    },
    arrowDown: {
        viewbox: "0 0 32 32",
        paths: [
            "M14.77,23.795L5.185,14.21c-0.879-0.879-0.879-2.317,0-3.195l0.8-0.801c0.877-0.878,2.316-0.878,3.194,0  l7.315,7.315l7.316-7.315c0.878-0.878,2.317-0.878,3.194,0l0.8,0.801c0.879,0.878,0.879,2.316,0,3.195l-9.587,9.585  c-0.471,0.472-1.104,0.682-1.723,0.647C15.875,24.477,15.243,24.267,14.77,23.795z"
        ]
    },
    stethoscope: {
        viewbox: "0 0 1792 1792",
        paths: [
            "M1472 704q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128 0q0 62-35.5 111t-92.5 70v395q0 159-131.5 271.5t-316.5 112.5-316.5-112.5-131.5-271.5v-132q-164-20-274-128t-110-252v-512q0-26 19-45t45-19q6 0 16 2 17-30 47-48t65-18q53 0 90.5 37.5t37.5 90.5-37.5 90.5-90.5 37.5q-33 0-64-18v402q0 106 94 181t226 75 226-75 94-181v-402q-31 18-64 18-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5q35 0 65 18t47 48q10-2 16-2 26 0 45 19t19 45v512q0 144-110 252t-274 128v132q0 106 94 181t226 75 226-75 94-181v-395q-57-21-92.5-70t-35.5-111q0-80 56-136t136-56 136 56 56 136z"
        ]
    },
    sync: {
        viewbox: "0 0 16 16",
        paths: [
            "M14,8c-0.609,0-0.898,0.43-1,0.883C12.635,10.516,11.084,13,8,13c-0.757,0-1.473-0.172-2.114-0.474L6.414,12  C6.773,11.656,7,11.445,7,11c0-0.523-0.438-1-1-1H3c-0.609,0-1,0.492-1,1v3c0,0.541,0.428,1,1,1c0.484,0,0.688-0.273,1-0.594  l0.408-0.407C5.458,14.632,6.685,15,8,15c4.99,0,7-4.75,7-5.938C15,8.336,14.469,8,14,8z M3,7.117C3.365,5.485,4.916,3,8,3  c0.757,0,1.473,0.171,2.114,0.473L9.586,4C9.227,4.344,9,4.555,9,5c0,0.523,0.438,1,1,1h3c0.609,0,1-0.492,1-1V2  c0-0.541-0.428-1-1-1c-0.484,0-0.688,0.273-1,0.594l-0.408,0.407C10.542,1.368,9.315,1,8,1C3.01,1,1,5.75,1,6.938  C1,7.664,1.531,8,2,8C2.609,8,2.898,7.57,3,7.117z"
        ]
    },
    shield: {
        viewbox: "0 0 512 512",
        paths: [
            "m461.82,3.142c-11.117-5.32-24.281-3.781-33.883,3.93-41.156,33.109-110.718,33.109-151.875,0-11.719-9.422-28.406-9.422-40.125,0-41.156,33.109-110.718,33.109-151.875,0-9.586-7.719-22.773-9.258-33.883-3.93-11.108,5.32-18.179,16.539-18.179,28.859v255.999c0,100.297 80.398,183.562 215.062,222.726 2.922,0.852 5.93,1.273 8.938,1.273 3.008,0 6.016-0.422 8.938-1.273 134.664-39.164 215.062-122.429 215.062-222.726v-255.999c-0.001-12.32-7.071-23.539-18.18-28.859zm-365.82,284.859v-32h160v-185.219c47.445,27.922 107.945,32.852 160,14.75v170.468h-160v190.57c-100.39-31.507-160-90.351-160-158.569z"
        ]
    },
    formula: {
        viewbox: "0 0 95.177 95.177",
        paths: [
            "M93.779,63.676c-0.981-1.082-2.24-1.653-3.639-1.653c-1.145,0-3.953,0.396-5.318,4.062   c-0.344,0.922-0.443,1.413-0.907,1.363c-0.786-0.078-3.845-3.346-4.845-8.145l-2.482-11.6c1.961-3.177,3.977-5.629,5.988-7.292   c1.08-0.882,2.314-1.349,3.808-1.43c3.815-0.26,5.203-0.74,6.14-1.399c1.547-1.115,2.397-2.728,2.397-4.542   c0-1.596-0.604-3.019-1.75-4.115c-1.106-1.059-2.581-1.618-4.26-1.618c-2.468,0-5.239,1.142-8.474,3.49   c-1.91,1.388-3.935,3.406-6.121,6.111c-0.711-2.653-1.319-3.889-1.771-4.628c-1.396-2.303-3.664-2.303-4.41-2.303l-0.813,0.013   l-23.045,0.544l1.297-5.506c0.828-3.593,1.915-6.436,3.226-8.45c0.638-0.98,1.614-2.148,2.638-2.148   c0.387,0,1.152,0.063,2.582,0.36c3.978,0.86,5.465,0.959,6.239,0.959c1.708,0,3.21-0.571,4.347-1.651   c1.176-1.119,1.797-2.583,1.797-4.233c0-1.29-0.424-3.156-2.445-4.722c-1.396-1.081-3.311-1.629-5.691-1.629   c-3.568,0-7.349,1.141-11.241,3.39c-3.862,2.232-7.038,5.317-9.438,9.171c-2.105,3.379-3.929,8.124-5.555,14.459H21.877   l-2.238,8.831h10.186l-7.74,31.116c-1.603,6.443-2.777,8.028-3.098,8.361c-0.875,0.904-2.68,1.094-4.04,1.094   c-1.683,0-3.477-0.121-5.349-0.361c-1.286-0.157-2.265-0.234-2.991-0.234c-1.878,0-3.423,0.488-4.59,1.448   C0.716,81.858,0,83.403,0,85.14c0,1.357,0.44,3.309,2.539,4.895c1.434,1.08,3.389,1.628,5.813,1.628   c6.069,0,11.725-2.411,16.813-7.165c4.947-4.624,8.571-11.413,10.773-20.195l6.119-24.935l20.87,0.354l2.244,9.64l-4.573,6.748   c-0.824,1.209-2.051,2.701-3.658,4.441c-0.84,0.92-1.398,1.426-1.721,1.689c-1.316-1.608-2.809-2.424-4.432-2.424   c-1.525,0-2.91,0.625-4.002,1.804c-1.036,1.116-1.583,2.514-1.583,4.038c0,1.83,0.783,3.459,2.264,4.709   c1.357,1.146,3.034,1.728,4.981,1.728c2.414,0,4.884-0.921,7.344-2.737c2.053-1.519,4.697-4.526,8.074-9.189   c2.17,6.24,5.248,10.252,6.714,11.927c2.313,2.644,6.049,4.22,9.993,4.22c3.348,0,5.244-1.402,6.916-2.641l0.148-0.109   c2.926-2.164,3.54-4.545,3.54-6.166C95.174,65.965,94.691,64.679,93.779,63.676z"
        ]
    }
};

export default icons;
