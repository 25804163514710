import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    Loading,
    Row,
    Column,
    DraftJSOutput,
    PageTitle,
    IconTextButton,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import NewsContext from "./NewsContext";
import NewsForm, { NewsStory } from "../../forms/NewsForm";

const NewsEdit = ({ uuid }) => {
    const history = useHistory();
    const { path } = useContext(NewsContext);

    const [loaded, setLoaded] = useState(false);
    const [story, setStory] = useState<NewsStory>({
        uuid: "",
        title: "",
        excerpt: "",
        body: "",
        thumbnail: "",
        status: "",
    });

    useEffect(() => {
        //If we have a uuid then we're editing so load the data
        if (uuid) {
            axios
                .get(`/api/v1/admin/news/${uuid}`)
                .then(({ data: { data } }) => {
                    setStory({
                        ...data,
                    });
                    setLoaded(true);
                });
        } else {
            setLoaded(true);
        }
    }, [uuid]);

    const updateNewsStory = (values) => {
        return axios
            .post(`/api/v1/admin/news/${uuid}`, values)
            .then(({ data: { data } }) => {
                // history.push(`${path}`);
                return data;
            });
    };

    if (!loaded) {
        return <Loading />;
    }

    return (
        <>
            <PageTitle title={trans("Edit a News Story")}>
                {" "}
                <IconTextButton
                    as={Link}
                    to={`${path}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            {/*@ts-ignore cortex*/}
            <Panel>
                <NewsForm story={story} onSubmit={updateNewsStory} />
            </Panel>
        </>
    );
};

export default NewsEdit;
