import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import axios from "axios";
import EventPlanner from "./planner/EventPlanner";
import InventoryManager from "./inventory/InventoryManager";
import Events from "./events/Events";
import Event from "./events/Event";
import EquipmentBrowser from "./equipment/EquipmentBrowser";

const EventsApp = ({ plannerPath = "/plan" }) => {
    const { path } = useRouteMatch();

    const [settings, setSettings] = useState({});
    const [settingsLoadingState, setSettingsLoadingState] = useState("loading");

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        setSettingsLoadingState("loading");

        axios
            .get(`/api/v1/events/settings`)
            .then(({ data }) => {
                const settings = data.data;
                setSettings(settings);
                setSettingsLoadingState("loaded");
            })
            .catch((e) => {});
    };

    return (
        <Switch>
            <Route
                path={`${path}/planner/:uuid?`}
                render={() => <EventPlanner settings={settings} />}
            />
            <Route
                path={`${path}/inventory`}
                render={() => <InventoryManager settings={settings} />}
            />
            <Route
                path={`${path}/equipment`}
                render={() => (
                    <EquipmentBrowser
                        path={path}
                        settings={settings}
                        settingsLoadingState={settingsLoadingState}
                    />
                )}
            />
            <Route
                path={`${path}/:uuid`}
                render={() => (
                    <Event plannerPath={plannerPath} settings={settings} />
                )}
            />
            <Route path={path} render={() => <Events />} />
        </Switch>
    );
};

export default EventsApp;
