import React, { useState, useRef, useEffect } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//@ts-ignore
import { createPopper } from "@popperjs/core";
//@ts-ignore cortex
import { darken } from "@cortexglobal/cortex-utilities";
import styled, { css } from "styled-components";
import Button from "./buttons/Button";

const background = (props: ButtonDropdownProps) => {
    return props.theme.button.types[props.color ? props.color : "default"]
        .backgroundColor;
};

const foreground = (props: ButtonDropdownProps) => {
    return props.theme.button.types[
        props.hideBackground
            ? "iconText"
            : props.color
            ? props.color
            : "default"
    ].foregroundColor;
};

const StyledButtonDropdownContainer = styled.div`
    display: inline-flex;
    border-radius: ${(props) => props.theme.button.borderRadius}px;
    overflow: hidden;
    align-items: center;
`;

// const StyledButtonDropdown = styled.button<ButtonDropdownProps>`
//     background: ${(props) =>
//         props.hideBackground ? "none" : background(props)};
//     border: ${(props) =>
//         props.hideBackground ? "none" : `1px solid ${background(props)}`};
//     color: ${(props) => foreground(props)};
//     padding: ${({ type, theme }) =>
//         theme.button.types[type] && theme.button.types[type].padding
//             ? isNaN(theme.button.types[type].padding)
//                 ? theme.button.types[type].padding
//                 : `0 ${theme.button.types[type].padding}em`
//             : `0 ${theme.spacing.padding}em`};
//     cursor: pointer;
//     height: 2rem;

//     &:hover,
//     &:active,
//     &:focus,
//     &:visited {
//         background-color: ${(props) =>
//             props.hideBackground ? "none" : darken(background(props), 0.8)};
//         border: ${(props) =>
//             props.hideBackground
//                 ? "none"
//                 : `1px solid ${darken(background(props), 0.8)}`};
//         box-shadow: none;
//         outline: none;
//     }
// `;
const StyledButtonDropdown = styled(Button)<ButtonDropdownProps>`
    background: ${(props) =>
        props.hideBackground ? "none" : background(props)};
    border: ${(props) =>
        props.hideBackground ? "none" : `1px solid ${background(props)}`};
    color: ${(props) => foreground(props)};
    padding: ${({ type, theme }) =>
        theme.button.types[type] && theme.button.types[type].padding
            ? isNaN(theme.button.types[type].padding)
                ? theme.button.types[type].padding
                : `0 ${theme.button.types[type].padding}em`
            : `0 ${theme.spacing.padding}em`};
    cursor: pointer;
    height: 2rem;
    box-shadow: none;
    /* border-radius: 0; */

    &:hover,
    &:active,
    &:focus,
    &:visited {
        background-color: ${(props) =>
            props.hideBackground ? "none" : darken(background(props), 0.8)};
        border: ${(props) =>
            props.hideBackground
                ? "none"
                : `1px solid ${darken(background(props), 0.8)}`};
        outline: none;
    }
`;

const PrimaryButtonDropdown = styled(StyledButtonDropdown)<ButtonDropdownProps>`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

const ArrowButtonDropdown = styled(
    StyledButtonDropdown
)<ArrowButtonDropdownProps>`
    ${({ hidePrimaryAction }) =>
        !hidePrimaryAction &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `}
`;

const DropdownContainer = styled.div`
    background: ${(props) => props.theme.dropdownButton.background};
    padding: 0.5em 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    min-width: 100px;
`;

const DropdownButton = styled.button<ButtonDropdownProps>`
    background: unset;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    display: block;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.dropdownButton.focus};
    }

    &:active {
        background: ${(props) => background(props)};
        color: ${(props) => foreground(props)};
    }

    ${(props) =>
        props.active &&
        `
        background: ${background(props)};
        color: ${foreground(props)};

        &:hover, &:focus {
            background: ${background(props)};
            color: ${foreground(props)};
        }
    `}
`;

const PopperElement = styled.div<{ isDropdownShowing: boolean }>`
    z-index: 2;
    visibility: ${({ isDropdownShowing }) =>
        isDropdownShowing ? "visible" : "hidden"};
    pointer-events: ${({ isDropdownShowing }) =>
        isDropdownShowing ? "auto" : "none"};
    position: absolute;
    padding-top: 0.5rem;
`;

const ArrowLabel = styled.span`
    display: inline-block;
    margin-right: 0.5rem;
`;

const ButtonDropdown = ({
    onClick,
    children,
    color = "default",
    arrowLabel = "",
    actions,
    hidePrimaryAction = false,
    hideBackground = false,
    icon = faCaretDown,
    buttonstyle,
}: ButtonDropdown) => {
    const [isDropdownShowing, setIsDropdownShowing] = useState(false);
    const [popper, setPopper] = useState<any>({});
    const popupRef = useRef(null);
    const wrapperRef = useRef(null);

    // const [referenceElement, setReferenceElement] = useState(null);
    const buttonWrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                buttonWrapperRef.current &&
                !buttonWrapperRef.current.contains(event.target)
            ) {
                setIsDropdownShowing(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        setPopper(
            createPopper(buttonWrapperRef.current, popupRef.current, {
                placement: "bottom-start",
                strategy: "fixed",
            })
        );
    }, []);
    let { styles, attributes } = popper;
    styles = styles && styles.popper ? styles.popper : {};
    attributes = attributes && attributes.popper ? attributes.popper : {};

    return (
        <>
            <StyledButtonDropdownContainer>
                {!hidePrimaryAction && (
                    <PrimaryButtonDropdown
                        type="button"
                        onClick={(event) => {
                            onClick();
                            event.stopPropagation();
                        }}
                        color={color}
                        hideBackground={hideBackground}
                    >
                        {/** @ts-ignore cortex */}
                        {children}
                    </PrimaryButtonDropdown>
                )}

                <span ref={buttonWrapperRef}>
                    <ArrowButtonDropdown
                        type="button"
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsDropdownShowing(!isDropdownShowing);
                        }}
                        color={color}
                        hideBackground={hideBackground}
                        hidePrimaryAction={hidePrimaryAction}
                        disabled={!actions || actions.length === 0}
                    >
                        {arrowLabel && <ArrowLabel>{arrowLabel}</ArrowLabel>}
                        <FontAwesomeIcon icon={icon} />
                    </ArrowButtonDropdown>
                </span>
            </StyledButtonDropdownContainer>

            {
                <PopperElement
                    ref={popupRef}
                    isDropdownShowing={isDropdownShowing}
                    style={{
                        ...styles,
                    }}
                    {...attributes}
                >
                    <DropdownContainer ref={wrapperRef}>
                        {actions.map((action, index) => {
                            return (
                                <DropdownButton
                                    key={index}
                                    active={action.active}
                                    color={color}
                                    onClick={(event) => {
                                        setIsDropdownShowing(false);
                                        event.stopPropagation();
                                        action.onClick();
                                    }}
                                    style={buttonstyle}
                                >
                                    {action.name}
                                </DropdownButton>
                            );
                        })}
                    </DropdownContainer>
                </PopperElement>
            }
        </>
    );
};

interface ButtonDropdown {
    name: string;
    color?: "default" | "secondary" | "alert";
    children: React.ReactNode;
    actions: Action[];
    onClick: () => void;
    theme?: any;
    hidePrimaryAction?: boolean;
    hideBackground?: boolean;
    icon?: any;
    buttonstyle?: object;
    arrowLabel?: string;
}

interface ButtonDropdownProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: any;
    active?: boolean;
    color?: "default" | "secondary" | "alert";
    hideBackground?: boolean;
}

interface ArrowButtonDropdownProps extends ButtonDropdownProps {
    hidePrimaryAction?: boolean;
}

interface Action {
    name: string;
    onClick: () => void;
    active?: boolean;
}

export default ButtonDropdown;
