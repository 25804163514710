import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    Row,
    Column,
    Centred,
    Button,
    Loading,
    Table,
    Panel,
    IconTextButton,
    DatePicker,
} from "@cortexglobal/rla-components";
import {
    trans,
    FormattedCurrency,
    FormattedDate,
} from "@cortexglobal/rla-intl";
import moment from "moment";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import { format } from "@cortexglobal/cortex-utilities";

import {
    FlexPanel,
    FlexRow,
    FlexSection,
    StickyRow,
} from "../layoutComponents";
// import withPanelData from "./withPanelData";
import usePanelData from "./usePanelData";

const MarketingPlannerSummary = ({ panelId, height }) => {
    // const [startDate, setStartDate] = useState(new Date());
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));

    //cortex-api-local.rla.co.uk/api/v1/budget/available/2020
    const panelData = usePanelData(
        panelId,
        `/api/v1/planner/get-tasks`,
        {
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(addMonths(startDate, 1), "yyyy-MM-dd"),
        },
        [startDate]
    );

    const user = useSelector((state) => state.auth.user);

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }
    const { data } = panelData;

    const date = new Date();
    const todayString = date.toISOString();

    const tasks = data
        .filter((task) => {
            return task.end_date > todayString && task.can_view_details;
        })
        .sort((a, b) => {
            if (a.start_date > b.start_date) {
                return 1;
            }
            if (a.start_date < b.start_date) {
                return -1;
            }
            return 0;
        })
        .slice(0, 5);

    return (
        <FlexPanel>
            <Row style={{ width: "100%", flex: "1", paddingTop: "0.3rem" }}>
                <Column>
                    <Table>
                        <thead>
                            <tr>
                                <th>{trans("Start")}</th>
                                <th>{trans("End")}</th>
                                <th>{trans("Campaign Name")}</th>
                                <th style={{ textAlign: "right" }}>
                                    {trans("Planned")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.length <= 0 ? (
                                <tr>
                                    <td colSpan="100%">
                                        {trans(
                                            "No upcoming activity this month"
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                tasks.map((task) => {
                                    return (
                                        <tr key={task.uuid}>
                                            <td>
                                                <FormattedDate
                                                    value={
                                                        new Date(
                                                            task.start_date
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <FormattedDate
                                                    value={
                                                        new Date(task.end_date)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/plan/task/${task.uuid}`}
                                                >
                                                    {task.name}
                                                </Link>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                <FormattedCurrency
                                                    value={
                                                        task.planned_spend.value
                                                    }
                                                    currency={
                                                        user?.currency?.iso_code
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>
                </Column>
            </Row>
            <StickyRow center={false}>
                <Column style={{ paddingBottom: "1em" }}>
                    <IconTextButton
                        as={Link}
                        to="/plan"
                        style={{ paddingLeft: 0 }}
                    >
                        {trans("Marketing Planner")}
                    </IconTextButton>
                </Column>
            </StickyRow>
        </FlexPanel>
    );
};

export default MarketingPlannerSummary;
