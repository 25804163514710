import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    FormikInput,
    FormikSelect,
    FormRow,
    Row,
    Column,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { get } from "lodash";

const generateSchema = (groups, roles, languages) => {
    return Yup.object().shape({
        first_name: Yup.string()
            .min(2, "Too Short!")
            .max(255, "Too Long!")
            .required("Required"),
        last_name: Yup.string()
            .min(2, "Too Short!")
            .max(255, "Too Long!")
            .required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        primary_role_uuid: roles
            ? Yup.string().required("Required")
            : Yup.string().nullable(),
        primary_group_uuid: groups
            ? Yup.string().required("Required")
            : Yup.string().nullable(),
        language:
            languages && languages.length > 0
                ? Yup.string().required("Required")
                : Yup.string().nullable(),
    });
};

const ProfileForm = ({
    onSubmit,
    user = null,
    groups = null,
    roles = null,
    languages = [],
    submitLabel = "Save",
}) => {
    // console.log(user);
    return (
        <Row style={{ minWidth: "500px" }}>
            <Formik
                initialValues={{
                    first_name: get(user, "first_name", ""),
                    last_name: get(user, "last_name", ""),
                    email: get(user, "email", ""),
                    primary_role_uuid: get(user, "primary_role.uuid", ""),
                    primary_group_uuid: get(user, "primary_group.uuid", ""),
                    language: get(user, "language", ""),
                }}
                onSubmit={onSubmit}
                validationSchema={generateSchema(groups, roles, languages)}
            >
                {({ values, errors, submitting }) => {
                    return (
                        <Form className="form-group">
                            <FormRow
                                name="first_name"
                                label={trans("First Name")}
                                helpText={trans("Required")}
                                error={errors.first_name}
                            >
                                <FormikInput
                                    type="text"
                                    name="first_name"
                                    placeholder="Joe"
                                    value={values.first_name}
                                    showError={false}
                                />
                            </FormRow>

                            <FormRow
                                name="last_name"
                                label={trans("Last Name")}
                                helpText={trans("Required")}
                                error={errors.last_name}
                            >
                                <FormikInput
                                    type="text"
                                    name="last_name"
                                    placeholder="Bloggs"
                                    value={values.last_name}
                                    showError={false}
                                />
                            </FormRow>

                            <FormRow
                                name="email"
                                label={trans("Email")}
                                helpText={trans("Required")}
                                error={errors.email}
                            >
                                <FormikInput
                                    type="text"
                                    name="email"
                                    placeholder="user@domain.com"
                                    value={values.email}
                                    showError={false}
                                />
                            </FormRow>

                            {languages && languages.length > 0 && (
                                <FormRow
                                    name="language"
                                    label={trans("Language")}
                                    helpText={trans("Required")}
                                    error={errors.language}
                                >
                                    <FormikSelect
                                        name="language"
                                        value={values.language}
                                        options={languages}
                                        showError={false}
                                    />
                                </FormRow>
                            )}
                            {roles && (
                                <FormRow
                                    name="primary_role_uuid"
                                    label={trans("Role")}
                                    helpText={trans("Required")}
                                    error={errors.primary_role_uuid}
                                >
                                    <FormikSelect
                                        name="primary_role_uuid"
                                        value={values.primary_role_uuid}
                                        options={roles}
                                        showError={false}
                                    />
                                </FormRow>
                            )}

                            {groups && (
                                <FormRow
                                    name="primary_group_uuid"
                                    label={trans("Group")}
                                    helpText={trans("Required")}
                                    error={errors.primary_group_uuid}
                                >
                                    <FormikSelect
                                        name="primary_group_uuid"
                                        value={values.primary_group_uuid}
                                        options={groups}
                                        showError={false}
                                    />
                                </FormRow>
                            )}
                            <Column
                                small={6}
                                centered
                                style={{ textAlign: "right" }}
                            >
                                <SubmitButton
                                    label={submitLabel}
                                    submitting={submitting}
                                />
                            </Column>
                        </Form>
                    );
                }}
            </Formik>
        </Row>
    );
};

export default ProfileForm;
