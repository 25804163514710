import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    calculateContentIndent,
    calculateDuration
} from "@cortexglobal/linear-calendar";

class ActivityContent extends Component {
    render() {
        const {
            activity,
            widths,
            units,
            start,
            dateFormat,
            activityDateFormat
        } = this.props;

        const duration = calculateDuration(
            activity.start_date,
            activity.end_date,
            units,
            activityDateFormat
        );

        //If the activity starts before the calendar its left is -1 micro unit
        //so we need to indent the content to compensate
        const indent = calculateContentIndent(
            activity.start_date,
            widths,
            start
        );
        // console.log(
        //     indent,
        //     activity.start_date,
        //     widths,
        //     start,
        //     activityDateFormat
        // );
        return (
            <div
                style={{
                    pointerEvents: "none",
                    textIndent: indent,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "100%"
                }}
            >
                &nbsp;
                <small>{activity.name ? activity.name : ""}</small>
            </div>
        );
    }
}

ActivityContent.propTypes = {
    activity: PropTypes.object
};

export default ActivityContent;
