import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import {
    Loading,
    Row,
    Column,
    InputField,
    TextareaField,
    CurrencyInput,
    Button,
    StyledDateRangePicker,
} from "@cortexglobal/rla-components";
import { FormattedCurrency, trans, useIntl } from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";

const InventoryItemBooking = ({
    item,
    updateBookingItem,
    bookedItem,
    event,
}) => {
    const [quantityValue, setQuantityValue] = useState("");
    const [predictedCost, setPredictedCostValue] = useState(0);
    const [formValues, setFormValues] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const { user } = useUser();
    const intl = useIntl();
    const [dates, setDates] = useState([
        event.raw.start_date,
        event.raw.end_date,
    ]);

    useEffect(() => {
        loadInitialValues();
    }, []);

    const loadInitialValues = () => {
        if (bookedItem && bookedItem.quantity) {
            const start = bookedItem.start_date ?? event.raw.start_date;
            const end = bookedItem.end_date ?? event.raw.end_date;
            setQuantityValue(bookedItem.quantity);
            setPredictedCostValue(bookedItem.predictedCost);
            onChangeDates([start, end]);
            setIsEdit(true);

            Object.keys(bookedItem.custom_choices).forEach((index) => {
                let value = bookedItem.custom_choices[index];
                setFormValues({ ...formValues, [index]: value });
            });
        } else {
            if (item.cost_basis === "event") {
                setQuantityValue(1);
            }
            onChangeDates([event.raw.start_date, event.raw.end_date]);
        }
    };

    const onChangeQuantity = (value) => {
        setQuantityValue(value);
    };

    const onChangePredictedCost = (value) => {
        setPredictedCostValue(value);
    };

    const onChangeDates = (value) => {
        if (value[0] && value[1]) {
            setDates([value[0], value[1]]);
            const start = new Date(value[0]);
            const end = new Date(value[1]);
            // To calculate the time difference of two dates
            const differenceInTime = end.getTime() - start.getTime();
            // To calculate the no. of days between two dates
            const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1; // +1 inclusive of start date
            setQuantityValue(differenceInDays);
        }
    };

    const onChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
    };

    const orderItem = () => {
        updateBookingItem(quantityValue, predictedCost, formValues, dates);
    };

    if (isEmpty(item)) {
        return <Loading />;
    }

    const costingTexts = () => {
        if (item.cost_basis == "estimate") {
            return trans("Please provide the best estimate for this.");
        }
        const cost = item.cost ? item.cost : item?.item?.cost;
        if (cost) {
            const formattedCost = cost.formatted;
            if (item.cost_basis == "daily") {
                return trans(
                    "This is charged rate of {formattedCost} per day",
                    { formattedCost }
                );
            }
            if (item.cost_basis == "event") {
                return trans(
                    "This has a one off cost of {formattedCost} per event",
                    { formattedCost }
                );
            }
            if (item.cost_basis == "unit") {
                return trans(
                    "This is charged rate of {formattedCost} per unit",
                    { formattedCost }
                );
            }
        } else {
            return trans(
                "An Item cost has not been provided for this item yet."
            );
        }
    };

    const costingTotal = () => {
        if (item.cost_basis === "estimate") {
            return predictedCost;
        }
        if (item.cost) {
            if (item.cost_basis === "daily" || item.cost_basis === "unit") {
                return quantityValue * item.cost.value;
            }
            if (item.cost_basis === "event") {
                return item.cost.value;
            }
        } else {
            return 0;
        }
    };

    return (
        <div>
            <Row>
                <Column>
                    <h3>{item.name}</h3>
                </Column>
            </Row>
            <Row style={{ paddingBottom: "30px" }}>
                <Column medium={10}>
                    <p>{item.description}</p>
                    <hr />
                    <div style={{ marginRight: "10px" }}>
                        <h5>{costingTexts()}</h5>
                        {item.cost && (
                            <p>
                                {trans("Expected Cost: ")}
                                <span
                                    style={{
                                        fontSize: "1.5em",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <FormattedCurrency
                                        value={costingTotal()}
                                        currency={
                                            user.currency.iso_code || "GBP"
                                        }
                                    />
                                </span>
                            </p>
                        )}
                    </div>
                </Column>
                <Column medium={2}>
                    <img
                        src={item.thumbnail || "/img/placeholder.png"}
                        width="90%"
                    />
                </Column>
            </Row>
            <Row>
                <Column medium={12}>
                    {item.cost_basis === "estimate" && (
                        <CurrencyInput
                            name="predictedCost"
                            label={trans("Estimated Cost")}
                            value={predictedCost}
                            onChange={(e) => onChangePredictedCost(e.value)}
                        />
                    )}
                    {item.cost_basis === "unit" && (
                        <InputField
                            name="quantity"
                            type="number"
                            min="1"
                            label={trans("Quantity")}
                            value={quantityValue}
                            onChange={(e) => onChangeQuantity(e.value)}
                        />
                    )}
                    {item.cost_basis === "daily" && (
                        <>
                            {/* {console.log({ dates, bookedItem })} */}
                            <StyledDateRangePicker
                                name="dateRange"
                                value={[new Date(dates[0]), new Date(dates[1])]}
                                onChange={({ value }) => onChangeDates(value)}
                                showUnitsSelector={false}
                                showPeriodSelector={false}
                                minDate={new Date(event.raw.start_date)}
                                maxDate={new Date(event.raw.end_date)}
                                units={"days"}
                            />
                            {/* <InputField
                                name="quantity"
                                type="number"
                                min="1"
                                max={eventDuration}
                                label={trans("Days Required")}
                                value={quantityValue}
                                onChange={(e) => onChangeQuantity(e.value)}
                            /> */}
                        </>
                    )}
                    {item.cost_basis === "event" && (
                        <p>
                            {trans("Fixed Cost:")}
                            <FormattedCurrency
                                value={item.item?.cost?.value}
                                currency={user.currency.iso_code || "GBP"}
                            />
                        </p>
                    )}
                    <TextareaField
                        name="comments"
                        label={
                            item.cost_basis === "estimate"
                                ? trans("Justification / Comments")
                                : trans("Note / Comment")
                        }
                        value={
                            isEmpty(formValues.comments)
                                ? ""
                                : formValues.comments
                        }
                        onChange={(e) => onChange(e.name, e.value)}
                    />
                    <Column medium={12} style={{ textAlign: "right" }}>
                        <Button
                            onClick={(e) => orderItem()}
                            disabled={
                                quantityValue < 1 && isEmpty(predictedCost)
                            }
                        >
                            {isEdit ? trans("Update") : trans("Add")}
                        </Button>
                    </Column>
                </Column>
            </Row>
        </div>
    );
};

export default InventoryItemBooking;
