import React, { useState } from "react";
import axios from "axios";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IconTextButton, LoadingIconSmall } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { format } from "@cortexglobal/cortex-utilities";

const BookingsListingItem = ({
    booking: bookingIn,
    removeBooking,
    settings,
    showReturnCondition = false,
}) => {
    const [booking, setBooking] = useState(bookingIn);
    const [loadingState, setLoadingState] = useState("loaded");

    const renderComment = (object) => {
        if (typeof object === "string" || object instanceof String) {
            return object || "-";
        }

        let commentObj = JSON.parse(object);

        if (Array.isArray(commentObj) && commentObj.length > 0) {
            return commentObj[0].comment || "-";
        }
    };

    const deleteBooking = (booking) => {
        if (confirm("Are you sure you want to delete this booking?")) {
            setLoadingState("loading");
            axios
                .delete(`/api/v1/events/inventory/bookings/${booking.uuid}`)
                .then(({ data }) => {
                    removeBooking(booking);
                    setLoadingState("loaded");
                })
                .catch((e) => {});
        }
    };
    let bookingType = null;
    if (settings?.bookingTypes && booking?.booking_type) {
        bookingType = settings?.bookingTypes[booking?.booking_type];
    }
    return (
        <tr>
            <td>{booking.ref}</td>
            <td>{format(new Date(booking.start_date))}</td>
            <td>{format(new Date(booking.end_date))}</td>
            <td>{bookingType?.text ? bookingType.text : "-"}</td>
            <td>{renderComment(booking.comments)}</td>
            {showReturnCondition && <td>{booking.return_condition}</td>}

            {loadingState === "loading" ? (
                <td>
                    <LoadingIconSmall />
                </td>
            ) : (
                <td>
                    <IconTextButton
                        onClick={() => deleteBooking(booking)}
                        showCircle={false}
                        expanded={false}
                        icon={faTrash}
                        iconSize="12px"
                    >
                        {trans("Delete")}
                    </IconTextButton>
                </td>
            )}
        </tr>
    );
};

export default BookingsListingItem;
