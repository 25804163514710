import styled, { css } from "styled-components";

export const Table = styled.table`
    width: 100%;
    border-spacing: 0;
    margin-bottom: ${({ theme }) => theme.spacing.margin}em;
    caption {
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        background: ${({ theme }) => theme.colors.secondary};
        padding: 10px;
    }
    th {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        background: ${({ theme, tableType }) =>
            tableType
                ? theme.table.types[tableType].thBackground
                : theme.table.types.default.thBackground};
        color: ${({ theme, tableType }) =>
            tableType
                ? theme.table.types[tableType].thColor
                : theme.table.types.default.thColor};
        text-align: left;
        padding: 10px;
        vertical-align: bottom;
    }
    td {
        text-align: left;
        padding: 10px;
        background: ${({ theme, tableType, zebraStripe }) =>
            zebraStripe
                ? "none"
                : tableType
                ? theme.table.types[tableType].background
                : theme.table.types.default.background};
        &:nth-child(even) {
            background: ${({ theme, tableType, zebraStripe }) =>
                zebraStripe
                    ? "none"
                    : tableType
                    ? theme.table.types[tableType].backgroundAlt
                    : theme.table.types.default.backgroundAlt};
        }
    }

    tr {
        transition: background-color 500ms ease;
        background-color: ${({ theme, tableType, zebraStripe }) =>
            !zebraStripe
                ? "none"
                : tableType
                ? theme.table.types[tableType].background
                : theme.table.types.default.background};
        &:nth-child(even) {
            background-color: ${({ theme, tableType, zebraStripe }) =>
                !zebraStripe
                    ? "none"
                    : tableType
                    ? theme.table.types[tableType].backgroundAlt
                    : theme.table.types.default.backgroundAlt};
        }
        ${({ showHover }) =>
            showHover &&
            css`&:hover {
            background-color: ${({ theme, tableType }) =>
                tableType && theme.table.types[tableType].rowHover
                    ? theme.table.types[tableType].rowHover
                    : theme.table.types.default.rowHover};`}
    }
    tr:not(:last-child) td {
        border-bottom: 1px solid ${({ theme, tableType, zebraStripe }) => {
            return tableType
                ? theme.table.types[tableType].tdLineColor
                : theme.table.types.default.tdLineColor;
        }}
    }
    th,
    td {
        &:first-child {
            /* padding-left: ${({ theme }) => theme.spacing.padding}em; */
        }
       
    }
`;

export default Table;
