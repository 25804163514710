import dateFnsFormat from "date-fns/format";
import { getItem } from "@cortexglobal/cortex-utilities";
import {
    registerLocale,
    setDefaultLocale,
    getDefaultLocale,
} from "react-datepicker";
export { default as formatISO } from "date-fns/formatISO";

export const getLocaleDateFormat = (localeString = null) => {
    //Get the date format from the user's localStorage and return it if it exists
    const date_format = getItem("date_format");
    if (date_format["date_format"]) {
        return date_format["date_format"];
    }

    //Otherwise get the date format from the browser locale
    const formats = {
        "af-ZA": "yyyy/MM/dd",
        "am-ET": "d/M/yyyy",
        "ar-AE": "dd/MM/yyyy",
        "ar-BH": "dd/MM/yyyy",
        "ar-DZ": "dd-MM-yyyy",
        "ar-EG": "dd/MM/yyyy",
        "ar-IQ": "dd/MM/yyyy",
        "ar-JO": "dd/MM/yyyy",
        "ar-KW": "dd/MM/yyyy",
        "ar-LB": "dd/MM/yyyy",
        "ar-LY": "dd/MM/yyyy",
        "ar-MA": "dd-MM-yyyy",
        "ar-OM": "dd/MM/yyyy",
        "ar-QA": "dd/MM/yyyy",
        "ar-SA": "dd/MM/yy",
        "ar-SY": "dd/MM/yyyy",
        "ar-TN": "dd-MM-yyyy",
        "ar-YE": "dd/MM/yyyy",
        "arn-CL": "dd-MM-yyyy",
        "as-IN": "dd-MM-yyyy",
        "az-Cyrl-AZ": "dd.MM.yyyy",
        "az-Latn-AZ": "dd.MM.yyyy",
        "ba-RU": "dd.MM.yy",
        "be-BY": "dd.MM.yyyy",
        "bg-BG": "dd.M.yyyy",
        "bn-BD": "dd-MM-yy",
        "bn-IN": "dd-MM-yy",
        "bo-CN": "yyyy/M/d",
        "br-FR": "dd/MM/yyyy",
        "bs-Cyrl-BA": "d.M.yyyy",
        "bs-Latn-BA": "d.M.yyyy",
        "ca-ES": "dd/MM/yyyy",
        "co-FR": "dd/MM/yyyy",
        "cs-CZ": "d.M.yyyy",
        "cy-GB": "dd/MM/yyyy",
        "da-DK": "dd-MM-yyyy",
        "de-AT": "dd.MM.yyyy",
        "de-CH": "dd.MM.yyyy",
        "de-DE": "dd.MM.yyyy",
        "de-LI": "dd.MM.yyyy",
        "de-LU": "dd.MM.yyyy",
        "dsb-DE": "d. M. yyyy",
        "dv-MV": "dd/MM/yy",
        "el-GR": "d/M/yyyy",
        "en-029": "MM/dd/yyyy",
        "en-AU": "d/MM/yyyy",
        "en-BZ": "dd/MM/yyyy",
        "en-CA": "dd/MM/yyyy",
        "en-GB": "dd/MM/yyyy",
        "en-IE": "dd/MM/yyyy",
        "en-IN": "dd-MM-yyyy",
        "en-JM": "dd/MM/yyyy",
        "en-MY": "d/M/yyyy",
        "en-NZ": "d/MM/yyyy",
        "en-PH": "M/d/yyyy",
        "en-SG": "d/M/yyyy",
        "en-TT": "dd/MM/yyyy",
        "en-US": "M/d/yyyy",
        "en-ZA": "yyyy/MM/dd",
        "en-ZW": "M/d/yyyy",
        "es-AR": "dd/MM/yyyy",
        "es-BO": "dd/MM/yyyy",
        "es-CL": "dd-MM-yyyy",
        "es-CO": "dd/MM/yyyy",
        "es-CR": "dd/MM/yyyy",
        "es-DO": "dd/MM/yyyy",
        "es-EC": "dd/MM/yyyy",
        "es-ES": "dd/MM/yyyy",
        "es-GT": "dd/MM/yyyy",
        "es-HN": "dd/MM/yyyy",
        "es-MX": "dd/MM/yyyy",
        "es-NI": "dd/MM/yyyy",
        "es-PA": "MM/dd/yyyy",
        "es-PE": "dd/MM/yyyy",
        "es-PR": "dd/MM/yyyy",
        "es-PY": "dd/MM/yyyy",
        "es-SV": "dd/MM/yyyy",
        "es-US": "M/d/yyyy",
        "es-UY": "dd/MM/yyyy",
        "es-VE": "dd/MM/yyyy",
        "et-EE": "d.MM.yyyy",
        "eu-ES": "yyyy/MM/dd",
        "fa-IR": "MM/dd/yyyy",
        "fi-FI": "d.M.yyyy",
        "fil-PH": "M/d/yyyy",
        "fo-FO": "dd-MM-yyyy",
        "fr-BE": "d/MM/yyyy",
        "fr-CA": "yyyy-MM-dd",
        "fr-CH": "dd.MM.yyyy",
        "fr-FR": "dd/MM/yyyy",
        "fr-LU": "dd/MM/yyyy",
        "fr-MC": "dd/MM/yyyy",
        "fy-NL": "d-M-yyyy",
        "ga-IE": "dd/MM/yyyy",
        "gd-GB": "dd/MM/yyyy",
        "gl-ES": "dd/MM/yy",
        "gsw-FR": "dd/MM/yyyy",
        "gu-IN": "dd-MM-yy",
        "ha-Latn-NG": "d/M/yyyy",
        "he-IL": "dd/MM/yyyy",
        "hi-IN": "dd-MM-yyyy",
        "hr-BA": "d.M.yyyy.",
        "hr-HR": "d.M.yyyy",
        "hsb-DE": "d. M. yyyy",
        "hu-HU": "yyyy. MM. dd.",
        "hy-AM": "dd.MM.yyyy",
        "id-ID": "dd/MM/yyyy",
        "ig-NG": "d/M/yyyy",
        "ii-CN": "yyyy/M/d",
        "is-IS": "d.M.yyyy",
        "it-CH": "dd.MM.yyyy",
        "it-IT": "dd/MM/yyyy",
        "iu-Cans-CA": "d/M/yyyy",
        "iu-Latn-CA": "d/MM/yyyy",
        "ja-JP": "yyyy/MM/dd",
        "ka-GE": "dd.MM.yyyy",
        "kk-KZ": "dd.MM.yyyy",
        "kl-GL": "dd-MM-yyyy",
        "km-KH": "yyyy-MM-dd",
        "kn-IN": "dd-MM-yy",
        "ko-KR": "yyyy. MM. dd",
        "kok-IN": "dd-MM-yyyy",
        "ky-KG": "dd.MM.yy",
        "lb-LU": "dd/MM/yyyy",
        "lo-LA": "dd/MM/yyyy",
        "lt-LT": "yyyy.MM.dd",
        "lv-LV": "yyyy.MM.dd.",
        "mi-NZ": "dd/MM/yyyy",
        "mk-MK": "dd.MM.yyyy",
        "ml-IN": "dd-MM-yy",
        "mn-MN": "yy.MM.dd",
        "mn-Mong-CN": "yyyy/M/d",
        "moh-CA": "M/d/yyyy",
        "mr-IN": "dd-MM-yyyy",
        "ms-BN": "dd/MM/yyyy",
        "ms-MY": "dd/MM/yyyy",
        "mt-MT": "dd/MM/yyyy",
        "nb-NO": "dd.MM.yyyy",
        "ne-NP": "M/d/yyyy",
        "nl-BE": "d/MM/yyyy",
        "nl-NL": "d-M-yyyy",
        "nn-NO": "dd.MM.yyyy",
        "nso-ZA": "yyyy/MM/dd",
        "oc-FR": "dd/MM/yyyy",
        "or-IN": "dd-MM-yy",
        "pa-IN": "dd-MM-yy",
        "pl-PL": "dd.MM.yyyy",
        "prs-AF": "dd/MM/yy",
        "ps-AF": "dd/MM/yy",
        "pt-BR": "d/M/yyyy",
        "pt-PT": "dd-MM-yyyy",
        "qut-GT": "dd/MM/yyyy",
        "quz-BO": "dd/MM/yyyy",
        "quz-EC": "dd/MM/yyyy",
        "quz-PE": "dd/MM/yyyy",
        "rm-CH": "dd/MM/yyyy",
        "ro-RO": "dd.MM.yyyy",
        "ru-RU": "dd.MM.yyyy",
        "rw-RW": "M/d/yyyy",
        "sa-IN": "dd-MM-yyyy",
        "sah-RU": "MM.dd.yyyy",
        "se-FI": "d.M.yyyy",
        "se-NO": "dd.MM.yyyy",
        "se-SE": "yyyy-MM-dd",
        "si-LK": "yyyy-MM-dd",
        "sk-SK": "d. M. yyyy",
        "sl-SI": "d.M.yyyy",
        "sma-NO": "dd.MM.yyyy",
        "sma-SE": "yyyy-MM-dd",
        "smj-NO": "dd.MM.yyyy",
        "smj-SE": "yyyy-MM-dd",
        "smn-FI": "d.M.yyyy",
        "sms-FI": "d.M.yyyy",
        "sq-AL": "yyyy-MM-dd",
        "sr-Cyrl-BA": "d.M.yyyy",
        "sr-Cyrl-CS": "d.M.yyyy",
        "sr-Cyrl-ME": "d.M.yyyy",
        "sr-Cyrl-RS": "d.M.yyyy",
        "sr-Latn-BA": "d.M.yyyy",
        "sr-Latn-CS": "d.M.yyyy",
        "sr-Latn-ME": "d.M.yyyy",
        "sr-Latn-RS": "d.M.yyyy",
        "sv-FI": "d.M.yyyy",
        "sv-SE": "yyyy-MM-dd",
        "sw-KE": "M/d/yyyy",
        "syr-SY": "dd/MM/yyyy",
        "ta-IN": "dd-MM-yyyy",
        "te-IN": "dd-MM-yy",
        "tg-Cyrl-TJ": "dd.MM.yy",
        "th-TH": "d/M/yyyy",
        "tk-TM": "dd.MM.yy",
        "tn-ZA": "yyyy/MM/dd",
        "tr-TR": "dd.MM.yyyy",
        "tt-RU": "dd.MM.yyyy",
        "tzm-Latn-DZ": "dd-MM-yyyy",
        "ug-CN": "yyyy-M-d",
        "uk-UA": "dd.MM.yyyy",
        "ur-PK": "dd/MM/yyyy",
        "uz-Cyrl-UZ": "dd.MM.yyyy",
        "uz-Latn-UZ": "dd/MM yyyy",
        "vi-VN": "dd/MM/yyyy",
        "wo-SN": "dd/MM/yyyy",
        "xh-ZA": "yyyy/MM/dd",
        "yo-NG": "d/M/yyyy",
        "zh-CN": "yyyy/M/d",
        "zh-HK": "d/M/yyyy",
        "zh-MO": "d/M/yyyy",
        "zh-SG": "d/M/yyyy",
        "zh-TW": "yyyy/M/d",
        "zu-ZA": "yyyy/MM/dd",
    };

    const locale = localeString ? localeString : getLocale();
    return formats[locale] || "yyyy-MM-dd";
};

export const getLocale = (useLanguage = false) => {
    const language = getItem("language");
    if (language["language"] && useLanguage) {
        return language["language"];
    }

    if (typeof Intl !== "undefined") {
        return Intl.DateTimeFormat().resolvedOptions().locale;
    }

    return navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
};

/**
 * Register a date-fns locale with Cortex (Simple wrapper around react-datepicker
 * registerLocale).  This means this is setup for react-datepicker, and for our
 * own date formatting functions.
 *
 * @param {string} localeName The name of the locale to register
 * @param {object} localeData The locale date-fns locale data to register
 *
 * @see https://github.com/Hacker0x01/react-datepicker/blob/v4.6.0/src/date_utils.js
 * @see https://github.com/Hacker0x01/react-datepicker#localization
 */
export function registerCortexLocale(localeName, localeData) {
    registerLocale(localeName, localeData);
}

/**
 * Set the default locale for Cortex (Simple wrapper around react-datepicker
 * setDefaultLocale).  This means this is setup for react-datepicker, and for our
 * own date formatting functions.
 *
 * @param {sting} localeName The name of the locale to set as the default
 *
 * @see https://github.com/Hacker0x01/react-datepicker/blob/v4.6.0/src/date_utils.js
 * @see https://github.com/Hacker0x01/react-datepicker#localization
 */
export function setCortexDefaultLocale(localeName) {
    setDefaultLocale(localeName);
}

/**
 * Get the default locale for Cortex (Simple wrapper around react-datepicker
 * getDefaultLocale).  This means this is setup for react-datepicker, and for our
 * own date formatting functions.
 *
 * @returns {string} The name of the default locale
 * @returns {null} If no default locale is set
 *
 * @see https://github.com/Hacker0x01/react-datepicker/blob/v4.6.0/src/date_utils.js
 * @see https://github.com/Hacker0x01/react-datepicker#localization
 */
export function getCortexDefaultLocale() {
    const defaultLocale = getDefaultLocale();
    return defaultLocale ? defaultLocale : getLocale(true);
}

/**
 * Get the date-fns locale object for the given locale spec.  This is a simple
 * re-implementation react-datepicker's getLocaleObject function, as it is not
 * exported.
 *
 * @param {string|object} localeSpec The locale spec to get the date-fns locale
 * object for
 * @returns {object} The date-fns locale object
 * @returns {null} If the locale spec is invalid
 *
 * @see https://github.com/Hacker0x01/react-datepicker/blob/v4.6.0/src/date_utils.js
 * @see https://github.com/Hacker0x01/react-datepicker#localization
 */
export function getCortexLocaleObject(localeSpec) {
    if (typeof localeSpec === "string") {
        // Treat it as a locale name registered by registerLocale
        const scope = typeof window !== "undefined" ? window : globalThis;
        return scope.__localeData__ ? scope.__localeData__[localeSpec] : null;
    } else {
        // Treat it as a raw date-fns locale object
        return localeSpec;
    }
}

/**
 * Format a date using the given format string.  This is a simple wrapper around the
 * date-fns format function, allowing us to set a default locale.
 * @param {Date} date The date to format
 * @param {string} dateFormat The format string to use for formatting
 * @returns {string} The formatted date
 *
 * @see https://date-fns.org/v2.29.3/docs/format
 */
export const format = (date, dateFormat = "PP") => {
    const dateFnsLocale = getCortexLocaleObject(getDefaultLocale());

    //If the date is a string, convert it to a Date object
    if (typeof date === "string") {
        try {
            date = new Date(date);
        } catch (e) {
            //If the date is in ISO format, try to convert it to a Date object
            try {
                date = new Date(date.split("T")[0]);
            } catch (e) {
                return date;
            }
        }
    }

    if (dateFnsLocale) {
        return dateFnsFormat(date, dateFormat, {
            locale: dateFnsLocale,
        });
    } else {
        return dateFnsFormat(date, dateFormat);
    }
};

export const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
};

//TODO - Look at reimplementing the functions below using date-fns if they are required

// /**
//  * Gets the next weekday from the start date.  The weekday can be supplied, otherwise it's assumed to be a Monday
//  * @param {moment} startDate A moment date that you want the next specific weekday from
//  * @param {int} isoWeekday The next day you require (defaults to Monday which is ISO weekday 1)
//  */
// export const getNextWeekDay = (startDate, isoWeekday = 1) => {
//     const startDay = startDate.isoWeekday();
//     // if the day is the required day
//     if (startDay === isoWeekday) {
//         // then just give me the date back
//         return startDate;
//     } else {
//         // otherwise, give me *next week's* instance of that same day
//         return startDate.add(1, "weeks").isoWeekday(isoWeekday);
//     }
// };
// /**
//  * Gets the previous weekday from the start date.  The weekday can be supplied, otherwise it's assumed to be a Monday
//  * @param {moment} startDate A moment date that you want the next specific weekday from
//  * @param {int} isoWeekday The next day you require (defaults to Monday which is ISO weekday 1)
//  */
// export const getPreviousWeekDay = (startDate, isoWeekday = 1) => {
//     const startDay = startDate.isoWeekday();
//     // if the day is the required day
//     if (startDay === isoWeekday) {
//         // then just give me the date back
//         return startDate;
//     } else {
//         // otherwise, give me *previous week's* instance of that same day
//         return startDate.subtract(1, "weeks").isoWeekday(isoWeekday);
//     }
// };

// /**
//  * Gets the date of the required weekday in the week of the supplied date.  The weekday can be supplied, otherwise it's assumed to be a Monday
//  * @param {moment} startDate A moment date that you want the next specific weekday from
//  * @param {int} isoWeekday The next day you require (defaults to Monday which is ISO weekday 1)
//  */
// export const getWeekDayInWeek = (startDate, isoWeekday = 1) => {
//     const startDay = startDate.isoWeekday();
//     // if we haven't yet passed the day of the week that I need:
//     if (startDay <= isoWeekday) {
//         // then just give me this week's instance of that day
//         return startDate.isoWeekday(isoWeekday);
//     } else {
//         // otherwise, give me *next week's* instance of that same day
//         return startDate.add(1, "weeks").isoWeekday(isoWeekday);
//     }
// };
