import React, { useState, useEffect } from "react";
import { IconTextButton, LoadingIconSmall } from "@cortexglobal/rla-components";
import {
    faBan,
    faPlus,
    faEdit,
    faTrash,
    faThumbsUp,
    faThumbsDown,
    faEnvelope,
    faCheckSquare
} from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import User from "../objects/User";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import CopyToClipboardButton from "./CopyToClipboardButton";

const UserRow = props => {
    const { can } = usePermissions();

    const { showEdit = {}, updateUsers = {} } = props;

    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    const disableUser = editUser => {
        setUser(new User({ ...editUser, loading: true }));

        editUser.disable().then(newUser => setUser(newUser));
    };

    const deleteUser = editUser => {
        setUser(new User({ ...editUser, loading: true }));

        editUser.delete().then(newUser => updateUsers(newUser, true));
    };

    const activateUser = editUser => {
        setUser(new User({ ...editUser, loading: true }));

        editUser.activate().then(newUser => setUser(newUser));
    };

    const approveUser = editUser => {
        setUser(new User({ ...editUser, loading: true }));

        editUser.approve().then(newUser => updateUsers(newUser));
    };

    const rejectUser = editUser => {
        setUser(new User({ ...editUser, loading: true }));

        editUser.reject().then(newUser => updateUsers(newUser));
    };

    const resendActivation = editUser => {
        setUser(new User({ ...editUser, loading: true }));

        editUser.resendActivation().then(newUser => setUser(newUser));
    };

    const getStatusButtons = user => {
        const { status, activationResent } = user;

        switch (status) {
            case "active":
                return (
                    can("manage-users-disable") && (
                        <IconTextButton
                            style={{
                                textDecoration: "none"
                            }}
                            icon={faBan}
                            showCircle={false}
                            expanded
                            onClick={() => disableUser(user)}
                        >
                            {trans("Disable")}
                        </IconTextButton>
                    )
                );
                break;
            case "account-disabled":
                return (
                    <>
                        {can("manage-users-activate") && (
                            <IconTextButton
                                style={{
                                    textDecoration: "none"
                                }}
                                icon={faPlus}
                                showCircle={false}
                                expanded
                                onClick={() => activateUser(user)}
                            >
                                {trans("Activate")}
                            </IconTextButton>
                        )}
                        {can("manage-users-delete") && (
                            <IconTextButton
                                style={{
                                    textDecoration: "none"
                                }}
                                icon={faTrash}
                                showCircle={false}
                                expanded
                                onClick={() => deleteUser(user)}
                            >
                                {trans("Delete")}
                            </IconTextButton>
                        )}
                    </>
                );
                break;
            case "awaiting-approval":
                return (
                    can("manage-users-approve") && (
                        <>
                            <IconTextButton
                                style={{
                                    textDecoration: "none"
                                }}
                                icon={faThumbsUp}
                                showCircle={false}
                                expanded
                                onClick={() => approveUser(user)}
                            >
                                {trans("Approve")}
                            </IconTextButton>
                            <IconTextButton
                                style={{
                                    textDecoration: "none"
                                }}
                                icon={faThumbsDown}
                                showCircle={false}
                                expanded
                                onClick={() => rejectUser(user)}
                            >
                                {trans("Reject")}
                            </IconTextButton>
                        </>
                    )
                );
                break;
            case "awaiting-activation":
                return (
                    <>
                        {can("manage-users-activate") &&
                            (!activationResent ? (
                                <IconTextButton
                                    style={{
                                        textDecoration: "none"
                                    }}
                                    icon={faEnvelope}
                                    showCircle={false}
                                    expanded
                                    onClick={() => resendActivation(user)}
                                >
                                    {trans("Resend Activation Email")}
                                </IconTextButton>
                            ) : (
                                <IconTextButton
                                    style={{
                                        textDecoration: "none",
                                        color: "green"
                                    }}
                                    icon={faCheckSquare}
                                    showCircle={false}
                                    expanded
                                >
                                    {trans("Activation Resent")}
                                </IconTextButton>
                            ))}
                        {can("manage-users-activate") &&
                            user.activationLink &&
                            user.activationLink.length > 0 && (
                                <CopyToClipboardButton
                                    text={<>{trans("Copy Activation Link")}</>}
                                    successText={
                                        <>{trans("Activation Link Copied")}</>
                                    }
                                    copy={user.activationLink}
                                />
                            )}
                        {can("manage-users-delete") && (
                            <IconTextButton
                                style={{
                                    textDecoration: "none"
                                }}
                                icon={faTrash}
                                showCircle={false}
                                expanded
                                onClick={() => deleteUser(user)}
                            >
                                {trans("Delete")}
                            </IconTextButton>
                        )}
                    </>
                );
                break;
            default:
                return <></>;
        }
    };

    return (
        <tr>
            <td>{user.ref}</td>
            <td>{user.name}</td>
            <td>{user.role_name}</td>
            <td>{user.group_name}</td>
            <td>{user.email}</td>
            <td>{user.status_wording}</td>
            <td>
                {user.loading === true ? (
                    <LoadingIconSmall />
                ) : (
                    <>
                        {can("manage-users-edit") && (
                            <IconTextButton
                                style={{
                                    textDecoration: "none"
                                }}
                                icon={faEdit}
                                showCircle={false}
                                expanded
                                onClick={() => showEdit(user)}
                            >
                                {trans("Edit")}
                            </IconTextButton>
                        )}
                        {getStatusButtons(user)}
                    </>
                )}
            </td>
        </tr>
    );
};

export default UserRow;
