import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import MediaChannelListing from "./MediaChannelListing";
import MediaChannelCreate from "./MediaChannelCreate";
import { MediaChannelEdit } from "./MediaChannelEdit";

export const MediaChannelAdmin = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <MediaChannelListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <MediaChannelCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <MediaChannelEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default MediaChannelAdmin;
