import { get } from "lodash";

const getInitials = (name) => name.match(/\b(\w)/g).join("");

function User(props) {
    this.uuid = get(props, "uuid", "");
    this.external_ref = get(props, "external_ref", "");
    this.avatar = get(props, "avatar", "");
    this.email = get(props, "email", "");
    this.name = get(props, "name", "");
    this.first_name = get(props, "first_name", "");
    this.last_name = get(props, "last_name", "");
    this.status = get(props, "status", "");
    this.status_wording = get(props, "status_wording", "");
    this.group_name = get(props, "group_name", "");
    this.role_name = get(props, "role_name", "");

    //This is in a try catch because it can throw an error if the name is not a string of western characters
    //currently the initials are only used in the UserRoundel component for alt text on the avatar
    try {
        this.initials = this.name.length > 0 ? getInitials(this.name) : "";
    } catch (e) {
        this.initials = "";
    }
}

export default User;
