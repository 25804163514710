import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Panel,
    Row,
    Column,
    Button,
    PlainButton,
    IconTextButton,
    TableList,
    Modal,
    Badge,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FormattedCurrency } from "@cortexglobal/rla-intl";
import { getContrastingColor } from "@cortexglobal/cortex-utilities";

const ActivityLinkButton = styled(PlainButton)`
    color: ${({ backgroundColor }) => {
        return getContrastingColor(backgroundColor);
    }};
    cursor: pointer;
    text-decoration: none;
    font-size: 0.8rem;
`;
const ActivityLink = styled.a`
    cursor: pointer;
    text-decoration: underline;
    font-size: 0.8rem;
`;

const RelatedActivities = ({
    task,
    addActivity,
    viewActivity,
    theme: plannerTheme,
    showHeading,
    activities,
    user,
    deleteActivity: deleteActivityIn,
    getTask,
    ...props
}) => {
    const [state, setState] = useState({
        activityToDelete: false,
    });

    const deleteActivity = (activityUuid, taskUuid) => {
        deleteActivityIn(activityUuid, taskUuid).then((response) => {
            setState({
                ...state,
                activityToDelete: false,
            });
        });
    };

    const { activityToDelete } = state;

    return (
        <>
            <Row>
                <Column medium={9}>
                    {showHeading && <h3>{trans("Activities")}</h3>}&nbsp;
                </Column>
                {task.can_admin_details && (
                    <Column medium={3} style={{ textAlign: "right" }}>
                        <IconTextButton
                            icon={faPlus}
                            showCircle={false}
                            onClick={(event) =>
                                addActivity(task, () => getTask(task.uuid))
                            }
                            style={{ paddingRight: 0 }}
                        >
                            {trans("Add Activity")}
                        </IconTextButton>
                    </Column>
                )}
            </Row>
            <TableList>
                <thead>
                    <tr>
                        <th
                            style={{
                                width: "10%",
                            }}
                        >
                            #
                        </th>
                        <th style={{ width: "30%" }}>
                            {trans("Activity Name")}
                        </th>
                        <th style={{ width: "10%" }}>{trans("Status")}</th>
                        <th style={{ width: "20%" }}>{trans("Date Range")}</th>
                        <th style={{ width: "15%" }}>
                            {trans("Planned Cost")}
                        </th>
                        <th style={{ width: "15%" }}>{trans("Cost")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {activities.length <= 0 ? (
                        <tr>
                            <td colSpan="100%">
                                {trans("No activities found")}
                            </td>
                        </tr>
                    ) : (
                        activities.map((activity) => {
                            return (
                                <tr key={activity.uuid}>
                                    <td>
                                        <Badge
                                            backgroundColor={
                                                plannerTheme.colors.activities
                                                    .activity
                                            }
                                            style={{ marginBottom: 0 }}
                                        >
                                            <ActivityLinkButton
                                                backgroundColor={
                                                    plannerTheme.colors
                                                        .activities.activity
                                                }
                                                onClick={(e) => {
                                                    viewActivity(
                                                        task,
                                                        activity
                                                    );
                                                }}
                                            >
                                                {activity.ref}
                                            </ActivityLinkButton>
                                        </Badge>
                                    </td>
                                    <td>
                                        <ActivityLink
                                            backgroundColor={
                                                plannerTheme.colors.activities
                                                    .activity
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                viewActivity(task, activity);
                                            }}
                                        >
                                            {activity.name}
                                        </ActivityLink>
                                    </td>
                                    <td>{activity.status_wording}</td>
                                    <td>
                                        {moment(activity.start_date).format(
                                            "l"
                                        )}
                                        {" - "}
                                        {moment(activity.end_date).format("l")}
                                    </td>
                                    <td>
                                        <FormattedCurrency
                                            value={
                                                activity.planned_cost?.value ||
                                                0
                                            }
                                            currency={
                                                activity.planned_cost
                                                    ?.currency || "GBP"
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedCurrency
                                            value={activity.cost?.value || 0}
                                            currency={
                                                activity.cost?.currency || "GBP"
                                            }
                                        />
                                    </td>
                                    <td
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {!task.plan && task.can_admin_details && (
                                            <PlainButton
                                                type="alert"
                                                onClick={() => {
                                                    return setState({
                                                        ...state,
                                                        activityToDelete:
                                                            activity,
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon="trash-alt" />
                                            </PlainButton>
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </TableList>
            <Modal
                visible={!!activityToDelete}
                onClose={() => setState({ ...state, activityToDelete: false })}
            >
                <Row>
                    <Column>
                        <h2>
                            {trans(
                                "Are you sure you want to delete this activity?"
                            )}
                        </h2>
                    </Column>
                    <Column style={{ textAlign: "right" }}>
                        <IconTextButton
                            onClick={() =>
                                setState({ ...state, activityToDelete: false })
                            }
                            icon={faTimes}
                            showCircle={false}
                        >
                            {trans("Cancel")}
                        </IconTextButton>
                        <Button
                            type="alert"
                            onClick={() => {
                                deleteActivity(
                                    activityToDelete.uuid,
                                    task.uuid
                                );
                            }}
                        >
                            {trans("Delete")}
                        </Button>
                    </Column>
                </Row>
            </Modal>
        </>
    );
};

export default RelatedActivities;
