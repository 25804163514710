import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
import { useIntl } from "@cortexglobal/rla-intl";

//@ts-ignore cortex
import { Button } from "@cortexglobal/rla-components";
import MicrositeContext from "../MicrositeContext";
import { bookmarkSite } from "../micrositeFunctions";
import { UtilityButton, Spacer, Logo } from "./utilityComponents";

const HeaderContainer = styled.header<HeaderContainerProps>`
    position: absolute;

    z-index: 1000;
    top: 0;
    right: 0;

    /* display: flex; */
    transition: all 300ms ease;
    /* transform: translateY(0%); */

    ${({ theme }) => {
        if (theme.backgroundType === "none") {
            return;
        }
        if (theme.backgroundType === "color") {
            return css`
                background: linear-gradient(
                    ${theme.backgroundColor1},
                    ${theme.backgroundColor2}
                );
            `;
        }
        if (theme.backgroundImage) {
            return css`
                background-image: url(${theme.backgroundImage});
            `;
        }
    }}

    padding: 1em;
    /* padding-top: ${({ theme }) =>
        theme.backgroundSlant === "none" ? "0.8em" : "1.5em"};
    padding-bottom: 0.7em; */
    /* padding-top: calc(${(props) => props.padding} * 2.2); */
    /* padding-bottom: calc(${(props) => props.padding} * 1.2); */
    /* ${({ theme }) => {
        if (theme.backgroundSlant === "none") {
            return;
        }
        if (theme.backgroundSlant === "highLeft") {
            return css`
                clip-path: polygon(0% 0%, 100% 20%, 100% 100%, 0% 100%);
            `;
        }
        if (theme.backgroundSlant === "highRight") {
            return css`
                clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0 100%);
            `;
        }
    }} */
    margin: 0 auto;
    display: flex;
    justify-content: ${({ theme }) => theme.buttonAlignment};
    align-items: center;
    font-size: ${(props) => props.fontSize};
    width: ${(props) => props.width};
`;

export const Header = ({
    menuShowing,
    setMenuShowing,
}: {
    menuShowing: boolean;
    setMenuShowing: (showing: boolean) => void;
}) => {
    const intl = useIntl();
    const {
        // fullscreen,
        // setFullscreen,
        slideSize,
        // setPrescribingInfoShowing,
        // prescribingInfoShowing,
        // setFootnotesShowing,
        // footnotesShowing,
        // hasFootnotes,
        // setAdditionalInformationShowing,
        // additionalInformationShowing,
        // hasAdditionalInformation,
        // globalSettings,
        // navigationDispatch,
        // preview,
        // globalTheming,
        headerTheming,
        // currentPageConfig,
    } = useContext(MicrositeContext);

    console.log({ headerTheming });
    return (
        <HeaderContainer
            width={slideSize?.width}
            padding={slideSize?.fontSize}
            fontSize={slideSize?.fontSize}
            theme={headerTheming}
        >
            {" "}
            <UtilityButton
                onClick={() => {
                    setMenuShowing(!menuShowing);
                }}
                title={intl.formatMessage({
                    id: "Navigation Menu",
                })}
                theme={headerTheming}
            >
                <FontAwesomeIcon icon={faBars} />
            </UtilityButton>
        </HeaderContainer>
    );
};

interface HeaderContainerProps {
    width?: string;
    padding?: string;
    fontSize?: string;
    theme: { [key: string]: any };
}
