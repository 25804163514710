import React from "react";

import { Table, ContentBlockLoader } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { format } from "@cortexglobal/cortex-utilities";

const AssetDetailCertification = ({ asset }) => {
    return (
        <React.Fragment>
            <h3>{trans("Certification Details")}</h3>
            <Table tableType="noStripe">
                <tbody>
                    <tr>
                        <th style={{ width: "33%" }}>{trans("Ref No.")}</th>
                        <td>
                            <ContentBlockLoader loaded={asset.loaded}>
                                {asset.certification_ref}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                    <tr>
                        <th>{trans("Date Issued")}</th>
                        <td>
                            <ContentBlockLoader loaded={asset.loaded}>
                                {format(
                                    new Date(asset.certification_awarded_date),
                                    "PP"
                                )}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                    <tr>
                        <th>{trans("Location")}</th>
                        <td>
                            <ContentBlockLoader loaded={asset.loaded}>
                                {asset.created_by_group_name}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default AssetDetailCertification;
