import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    HelpText,
    SimpleDropzone,
    Tooltip,
    Panel,
    Button,
    Image,
    LoadingIconSmall,
    useAddToast,
    useToasts,
} from "@cortexglobal/rla-components";
import { useInterval } from "@cortexglobal/cortex-utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CampaignFiles = ({ campaign, onChange }) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [remainingTries, setRemainingTries] = useState(100);
    const [thumbnail, setThumbnail] = useState(campaign.thumbnail);
    const [hero, setHero] = useState(campaign.hero);

    useEffect(() => {
        console.log("campaign", campaign);
        if (campaign.thumbnail) {
            setThumbnail(campaign.thumbnail);
        }
        if (campaign.hero) {
            setHero(campaign.hero);
        }
    }, [campaign]);

    const onDrop = useCallback((acceptedFiles, type) => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("The file is being uploaded"),
        });
        const fd = new FormData();
        fd.append("file", acceptedFiles[0]);
        //The type is either hero or thumbnail
        fd.append("type", type);
        axios
            .post(`/api/v1/campaigns/admin/${campaign.uuid}/file`, fd)
            .then(({ data }) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                onChange({ name: type, value: data.data[type] });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("There was an error uploading the file"),
                    showFor: 5000,
                });
            })
            .finally(() => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
            });
    }, []);

    useInterval(
        () => {
            if ((!hero || !thumbnail) && remainingTries > 0) {
                axios
                    .get(`/api/v1/campaigns/${campaign.uuid}`)
                    .then(({ data }) => {
                        console.log("data", data);
                        if (data.data.hero) {
                            setHero(data.data.hero);
                        }
                        if (data.data.thumbnail) {
                            setThumbnail(data.data.thumbnail);
                        }
                    });
                setRemainingTries(remainingTries - 1);
            }
        },
        (thumbnail && hero) || remainingTries < 1 ? null : 7000
    );

    return (
        <div style={{ padding: "1rem 2rem" }}>
            <h3>{trans("Hero Image")}</h3>

            <Panel>
                <Row>
                    <Column medium={3} style={{ textAlign: "right" }}>
                        {trans("Hero Image Upload")}
                        <HelpText>
                            {trans("Optional, used in the campaign details")}
                        </HelpText>
                    </Column>
                    <Column medium={3}>
                        <SimpleDropzone
                            name="hero"
                            onDrop={(event) => onDrop(event, "hero")}
                        >
                            <Button
                                style={{ textDecoration: "none" }}
                                onClick={() => {
                                    return false;
                                }}
                            >
                                <FontAwesomeIcon icon="upload" />{" "}
                                {trans("Upload")}
                            </Button>
                        </SimpleDropzone>
                    </Column>
                    <Column medium={6}>
                        {!hero && remainingTries > 0 ? (
                            <Tooltip
                                text={trans(
                                    "The hero may be being generated, if you want to upload one, please use the upload button"
                                )}
                            >
                                <LoadingIconSmall /> {trans("Loading hero")}
                            </Tooltip>
                        ) : (
                            <Image
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100px",
                                }}
                                alt="campaign hero"
                                src={hero}
                            />
                        )}
                    </Column>
                </Row>
            </Panel>

            <h3>{trans("Thumbnail")}</h3>

            <Panel>
                <Row>
                    <Column medium={3} style={{ textAlign: "right" }}>
                        {trans("Thumbnail Upload")}
                        <HelpText>
                            {trans("Optional, used in the campaign listing")}
                        </HelpText>
                    </Column>
                    <Column medium={3}>
                        <SimpleDropzone
                            name="thumbnail"
                            onDrop={(event) => onDrop(event, "thumbnail")}
                        >
                            <Button
                                style={{ textDecoration: "none" }}
                                onClick={() => {
                                    return false;
                                }}
                            >
                                <FontAwesomeIcon icon="upload" />{" "}
                                {trans("Upload")}
                            </Button>
                        </SimpleDropzone>
                    </Column>
                    <Column medium={6}>
                        {!thumbnail && remainingTries > 0 ? (
                            <Tooltip
                                text={trans(
                                    "The thumbnail may be being generated, if you want to upload one, please use the upload button"
                                )}
                            >
                                <LoadingIconSmall />{" "}
                                {trans("Loading thumbnail")}
                            </Tooltip>
                        ) : (
                            <Image
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100px",
                                }}
                                alt="campaign thumbnail"
                                src={thumbnail}
                            />
                        )}
                    </Column>
                </Row>
            </Panel>
        </div>
    );
};

export default CampaignFiles;
