import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { VictoryChart, VictoryBar } from "victory";

import { Layout } from "../interfaces/layout";
import { SlideWrapper } from "../components/slideComponents";
import MicrositeContext from "../MicrositeContext";

const SlideContainer = styled(SlideWrapper)`
    display: flex;
    flex-direction: column;
`;

const SlideTitle = styled(animated.h1)`
    display: flex;
    justify-content: center;
    flex: 2;
    align-items: flex-end;
`;
const SlideBody = styled(animated.div)`
    display: flex;
    flex: 4;
    align-items: flex-start;
`;
const TitleBarChart = ({ settings, content, links }: Layout) => {
    // console.log(settings, content, links);

    let {
        navigationState: { currentTransitionIndex },
        editing
    } = useContext(MicrositeContext);
    // console.log("currentTransitionIndex", currentTransitionIndex, content);
    if (editing) {
        currentTransitionIndex = 2;
    }
    const getData = () => {
        const bars = [
            "category 1",
            "category 2",
            "category 3",
            "category 4",
            "category 5"
        ];
        return bars.map(bar => {
            return { x: bar, y: Math.round(Math.random() * 10) };
        });
    };

    return (
        <SlideContainer settings={settings}>
            <SlideTitle
                style={useSpring({
                    opacity: currentTransitionIndex > 0 ? 1 : 0
                })}
            >
                {content.body_title.value}
            </SlideTitle>
            <SlideBody
                style={useSpring({
                    opacity: currentTransitionIndex > 1 ? 1 : 0
                })}
            >
                <VictoryChart
                    domainPadding={{ x: 20 }}
                    animate={{ duration: 500 }}
                >
                    <VictoryBar
                        data={currentTransitionIndex > 1 ? getData() : []}
                    />
                </VictoryChart>
            </SlideBody>
        </SlideContainer>
    );
};

export default TitleBarChart;
