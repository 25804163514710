import React, { useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Footnote, Layout } from "../interfaces/layout";
import {
    SlideContainer,
    Subtitle,
    SlideTitle,
} from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";
import { Captions, Caption } from "../components/videoComponents";

const StyledSlideTitle = styled(SlideTitle)`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    flex: 1;
`;

const SlideBody = styled.div`
    display: flex;
    flex: 4;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1em;
`;

const ImageSlideColumn = styled(animated.div)`
    height: 100%;
    flex: 1;
    display: flex;
    padding-right: 1em;
    position: relative;
`;

export const StyledCaptions = styled(animated.div)<CaptionsProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
    width: ${(props) => props.contentWidth};
    font-size: 0.55em;
    position: relative;
    top: ${(props) => props.contentTop};
`;

const Image = styled.img<ImageProps>`
    width: 100%;
    position: absolute;
    top: ${(props) => props.contentTop};
`;

const ImageLeft = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);
    if (editing || disableSequencing) {
        currentTransitionIndex = 3;
    }
    const transitionsComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );

    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    console.log(content);

    return (
        <SlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                hidden={!content.title || !content.title.value}
                style={useSpring({
                    opacity: transitionsComplete.includes("title") ? 1 : 0,
                })}
            >
                <RenderContentBlock {...content.title} />
            </StyledSlideTitle>
            <SlideBody>
                <ImageSlideColumn
                    style={useSpring({
                        opacity: transitionsComplete.includes("image") ? 1 : 0,
                    })}
                >
                    {content.image && content.image.value && (
                        <Image
                            contentTop={`${
                                settings.top_image ? "0" : settings.content_top
                            }%`}
                            src={content.image.value}
                            alt="image"
                        />
                    )}
                </ImageSlideColumn>
                <StyledCaptions
                    style={useSpring({
                        opacity: transitionsComplete.includes("column") ? 1 : 0,
                    })}
                    contentTop={`${settings.content_top}%`}
                    contentWidth={`${settings.content_width}%`}
                >
                    {!!content.column && Array.isArray(content.column.value) && (
                        <>
                            {content.column.value.map(
                                (content: Content, index: number) => {
                                    return (
                                        <Caption
                                            highlightColor={highlightColor}
                                            key={index}
                                            showing={true}
                                            bulletPoint={!!content.bullet}
                                        >
                                            <RenderContentBlock
                                                type="text"
                                                value={content.value}
                                                footnotes={content.footnotes}
                                            />
                                        </Caption>
                                    );
                                }
                            )}
                        </>
                    )}
                </StyledCaptions>
            </SlideBody>
        </SlideContainer>
    );
};

export type Content = {
    value: string;
    bullet?: boolean;
    footnotes?: Footnote[];
};

interface CaptionsProps {
    contentTop?: string;
    contentWidth?: string;
}

interface ImageProps {
    contentTop?: string;
}

export default ImageLeft;
