import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import {
    faTrash,
    faEdit,
    faChartLine,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    Image,
    Toggle,
    FormLabel,
    Tooltip,
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PaddedDiv = styled.div`
    padding: 3em;
`;

const TableHeader = styled.div`
    cursor: pointer;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`;

export const CommunicationRuleTable = ({
    items,
    loaded,
    path,
    pagination = null,
    deleteItem,
    enableItem,
    disableItem,
    onPageChange,
    onOrderBy,
    onPerPageChange,
}) => {
    const history = useHistory();

    const currentPage = pagination ? pagination.current_page : 1;

    const [
        deleteCommunicationRuleModalShowing,
        setDeleteCommunicationRuleModalShowing,
    ] = useState(false);
    const [deleteCommunicationRuleName, setDeleteCommunicationRuleName] =
        useState("");
    const [deleteCommunicationRuleId, setDeleteCommunicationRuleId] =
        useState(null);
    const [enableCommunicationRuleId, setEnableCommunicationRuleId] =
        useState(null);

    const convertToTranslatedBoolean = (value) => {
        return value ? <FontAwesomeIcon icon={faCheck} size="lg" /> : "";
    };

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th style={{ width: "30%" }}>{trans("Name")}</th>
                        <th>{trans("Internal")}</th>
                        <th>{trans("Pop-up")}</th>
                        <th>{trans("Email")}</th>
                        <th>{trans("Status")}</th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded
                        ? ""
                        : items.map((item) => {
                              const ended =
                                  item.schedule_end_date &&
                                  new Date(item.schedule_end_date) < new Date();

                              return (
                                  <tr>
                                      <td>{item.id}</td>
                                      <td>{item.name}</td>
                                      <td style={{ textAlign: "center" }}>
                                          {convertToTranslatedBoolean(
                                              item.internal_message
                                          )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                          {convertToTranslatedBoolean(
                                              item.is_pop_up_message
                                          )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                          {convertToTranslatedBoolean(
                                              item.email
                                          )}
                                      </td>
                                      <td>
                                          <Tooltip
                                              text={
                                                  ended
                                                      ? trans(
                                                            "Rule ended on {schedule_end_date}",
                                                            item
                                                        )
                                                      : ""
                                              }
                                          >
                                              <Toggle
                                                  name="show_archived"
                                                  disabled={ended}
                                                  onChange={(e) =>
                                                      item.is_active
                                                          ? disableItem(
                                                                item.uuid
                                                            )
                                                          : enableItem(
                                                                item.uuid
                                                            )
                                                  }
                                                  checked={item.is_active}
                                                  size="small"
                                              >
                                                  <FormLabel>
                                                      {item.status}
                                                  </FormLabel>
                                              </Toggle>
                                          </Tooltip>
                                      </td>
                                      <td>
                                          <IconTextButton
                                              as={Link}
                                              to={`${path}/edit/${item.uuid}?page=${currentPage}`}
                                              showCircle={false}
                                              expanded={false}
                                              icon={faEdit}
                                          >
                                              {trans("Edit")}
                                          </IconTextButton>
                                          <IconTextButton
                                              type="alert"
                                              showCircle={false}
                                              expanded={false}
                                              icon={faTrash}
                                              onClick={(event) => {
                                                  setDeleteCommunicationRuleId(
                                                      item.uuid
                                                  );
                                                  setDeleteCommunicationRuleModalShowing(
                                                      true
                                                  );
                                                  setDeleteCommunicationRuleName(
                                                      item.name
                                                  );
                                              }}
                                          >
                                              {trans("Delete")}
                                          </IconTextButton>
                                          {item.is_active && (
                                              <IconTextButton
                                                  type="alert"
                                                  showCircle={false}
                                                  expanded={false}
                                                  icon={faChartLine}
                                                  onClick={(event) => {
                                                      history.push(
                                                          `${path}/${item.uuid}/activity`
                                                      );
                                                  }}
                                              >
                                                  {trans("Activity")}
                                              </IconTextButton>
                                          )}
                                      </td>
                                  </tr>
                              );
                          })}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
            {deleteCommunicationRuleModalShowing && (
                <Modal
                    visible={deleteCommunicationRuleModalShowing}
                    onClose={() => {
                        setDeleteCommunicationRuleModalShowing(false);
                    }}
                >
                    <h1>
                        {trans("You are about delete communication rule")}:
                        {deleteCommunicationRuleName}
                    </h1>

                    <p>{trans("Are you sure you want to proceed?")}</p>
                    <Button
                        type="hollow"
                        style={{ marginRight: "1em" }}
                        onClick={() => {
                            setDeleteCommunicationRuleModalShowing(false);
                        }}
                    >
                        {trans("Cancel")}
                    </Button>
                    <Button
                        type="alert"
                        onClick={() => {
                            deleteItem(deleteCommunicationRuleId).then((e) =>
                                setDeleteCommunicationRuleModalShowing(false)
                            );
                        }}
                    >
                        {trans("Delete")}
                    </Button>
                </Modal>
            )}
        </>
    );
};

export default CommunicationRuleTable;
