import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: inline-block;
    margin: 0 10px;
`;
const Container = styled.div`
    display: flex;
    align-items: center;
`;
const Example = styled.div<{ color: string }>`
    width: 20px;
    min-width: 20px;
    height: 20px;
    background: ${(props) => props.color};
    border-radius: 50%;
`;
const Label = styled.div`
    padding-left: 10px;
    font-weight: bold;
`;
export const LegendItem = ({ label, color }) => {
    return (
        <Wrapper>
            <Container>
                <Example color={color} />
                <Label>{label}</Label>
            </Container>
        </Wrapper>
    );
};
