import React, { Component } from "react";

import TopTenConfiguration from "./TopPerformersConfiguration";
import TopTenOutput from "./TopPerformersOutput";

class TopPerformers extends Component {
    render() {
        if (this.props.configuring) {
            return <TopTenConfiguration {...this.props} />;
        }
        return <TopTenOutput {...this.props} />;
    }
}

export default TopPerformers;
