import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    Row,
    FormLabel,
    Column,
    Button,
    InputField,
    StyledInput,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const AddFavouriteFolderModal = ({
    asset,
    visible,
    onClose,
    setRefreshSearch,
}) => {
    const intl = useIntl();
    const [folder, setFolder] = useState(null);
    const [folderName, setFolderName] = useState(null);

    const moveAssetToFolder = () => {
        axios
            .put(`api/v1/assets/${asset.uuid}/folder`, {
                parent_folder_uuid: folder ? folder.uuid : "",
            })
            .then((response) => {
                setRefreshSearch(true);
                onClose();
            })
            .catch(() => {});
    };
    return (
        <Modal visible={visible} onClose={onClose} zIndex={0} maxWidth="90%">
            <Row>
                <Column>
                    <h3>{trans("Create Folder")}</h3>
                </Column>
            </Row>
            <Row>
                <Column>
                    <FormLabel name="name">
                        <InputField
                            name="name"
                            value={folderName}
                            onChange={(item) => setFolderName(item.value)}
                        />
                    </FormLabel>
                </Column>
            </Row>
            <Row>
                <FormLabel name="addFolder">
                    <Button onClick={moveAssetToFolder}>
                        {folder && folder.name
                            ? trans('Add Folder  "{folderName}"', {
                                  folderName: folder.name,
                              })
                            : trans("Add Base Folder")}
                    </Button>
                </FormLabel>
            </Row>
        </Modal>
    );
};

export default AddFavouriteFolderModal;
