import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { EmptyRow, PreviewImage } from "../../components/index";
import { Category } from "../../forms/CategoryForm";

const PaddedDiv = styled.div`
    padding: 3em;
`;

const TableHeader = styled.div`
    cursor: pointer;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`;

export const CategoryTable = ({
    items,
    loaded,
    path,
    pagination = null,
    deleteItem,
    onPageChange,
    onOrderBy,
    onPerPageChange,
}: CategoryTableProps) => {
    const currentPage = pagination ? pagination.current_page : 1;

    const [deleteCategoryModalShowing, setDeleteCategoryModalShowing] =
        useState(false);
    const [deleteCategoryName, setDeleteCategoryName] = useState("");
    const [deleteCategoryId, setDeleteCategoryId] = useState(null);

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>{trans("ID")}</th>
                        <th>{trans("Name")}</th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("slug")}>
                                {trans("Slug")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("area")}>
                                {trans("Area")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("type")}>
                                {trans("Type")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("order")}>
                                {trans("Order")}
                            </TableHeader>
                        </th>
                        <th>{trans("Image")}</th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <EmptyRow colSpan={"100%"}>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow colSpan={"100%"}>
                            {trans("No items found")}
                        </EmptyRow>
                    ) : (
                        items.map((item) => {
                            return (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.slug}</td>
                                    <td>{item.area}</td>
                                    <td>{item.type}</td>
                                    <td>{item.order}</td>
                                    <td>
                                        {item.thumbnail ? (
                                            <PreviewImage
                                                src={item.thumbnail}
                                            />
                                        ) : (
                                            trans("No image provided")
                                        )}
                                    </td>
                                    <td>
                                        <IconTextButton
                                            as={Link}
                                            to={`${path}/edit/${item.uuid}?page=${currentPage}`}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEdit}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            type="alert"
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                            onClick={(event) => {
                                                setDeleteCategoryId(item.uuid);
                                                setDeleteCategoryModalShowing(
                                                    true
                                                );
                                                setDeleteCategoryName(
                                                    item.name
                                                );
                                            }}
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
            {deleteCategoryModalShowing && (
                <Modal
                    visible={deleteCategoryModalShowing}
                    onClose={() => {
                        setDeleteCategoryModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h3>
                            {trans(
                                "You are about delete: {deleteCategoryName}",
                                {
                                    deleteCategoryName,
                                }
                            )}
                        </h3>

                        <p>{trans("Are you sure you want to proceed?")}</p>
                        <Button
                            type="secondary"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeleteCategoryModalShowing(false);
                            }}
                        >
                            {trans("Cancel")}
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                deleteItem(deleteCategoryId).then(() => {
                                    setDeleteCategoryModalShowing(false);
                                });
                            }}
                        >
                            {trans("Delete")}
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}
        </React.Fragment>
    );
};

interface CategoryTableProps {
    items: Category[];
    loaded: boolean;
    path: string;
    pagination?: any;
    deleteItem: (number) => Promise<Category[]>;
    onPageChange?: (number) => void;
    onOrderBy?: (string) => void;
    onPerPageChange?: ({ value }) => void;
}

export default CategoryTable;
