import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
///@ts-ignore cortex
import { useTheme } from "styled-components";
//@ts-ignore cortex
// import ReactFlow, { Background, MiniMap, Controls } from "react-flow-renderer";
import { ReactFlow, MiniMap, Controls } from "@xyflow/react";
import "@xyflow/react/dist/style.css";

const PaddedDiv = styled.div`
    padding: 3em;
`;

export const WorkflowDiagram = ({ workflow, states, transitions }) => {
    const [elements, setElements] = useState([]);
    const [elementsLoaded, setElementsLoaded] = useState(false);
    const [coordsSaved, setCoordsSaved] = useState(true);
    const theme = useTheme();

    const buildElements = (workflowStates, workflowTransitions) => {
        const stateElements = [];
        let currentX = 100;
        let currentY = 10;

        workflowStates.map((state) => {
            if (state?.uuid) {
                stateElements.push({
                    id: state.uuid,
                    type:
                        state.special_type == "initial"
                            ? "input"
                            : state.special_type == "closed"
                            ? "output"
                            : "default",
                    data: { label: state.name },
                    position: {
                        x: state.x_coord
                            ? state.x_coord
                            : state.is_super
                            ? currentX + 200
                            : currentX,
                        y: state.y_coord ? state.y_coord : currentY,
                    },
                    // sourcePosition: "right",
                    // targetPosition: "left",
                    parentNode:
                        state.super_states.length > 0
                            ? state.super_states[0].uuid
                            : null,
                    draggable: true,
                    connectable: true,
                    selectable: true,
                    style: {
                        backgroundColor:
                            state.special_type == "super"
                                ? // @ts-ignore
                                  theme.colors.lightGray
                                : "inherit",
                    },
                    extent: "parent",
                });
                currentY = currentY + 100;
            }
        });

        workflowTransitions.map((transition) => {
            if (transition.uuid) {
                if (!transition.new_state) {
                    return false;
                }

                stateElements.push({
                    id: transition.uuid,
                    source: transition?.state?.uuid,
                    target: transition.new_state.uuid,
                    label: transition.name,
                    type: "step",
                    animated: true,
                });
            }
        });

        setElements(stateElements);
        setElementsLoaded(true);
    };

    const onNodeDragStop = (event, node) => {
        console.log(node);
        if (!coordsSaved) {
            return false;
        }

        setCoordsSaved(false);

        axios
            .put(`/api/v1/admin/workflow/states/${node.id}/save-coords`, {
                x_coord: node.position.x,
                y_coord: node.position.y,
            })
            .then(({ data }) => {
                setCoordsSaved(true);
            })
            .catch(() => {
                setCoordsSaved(true);
            });
    };

    useEffect(() => {
        if (!elementsLoaded) {
            buildElements(states, transitions);
        }
    }, []);

    // elements = [
    //     {
    //       id: '1',
    //       type: 'input', // input node
    //       data: { label: 'Input Node' },
    //       position: { x: 250, y: currentY },
    //     },
    //     // default node
    //     {
    //       id: '2',
    //       // you can also pass a React component as a label
    //       data: { label: <div>Default Node</div> },
    //       position: { x: 100, y: 125 },
    //     },
    //     {
    //       id: '3',
    //       type: 'output', // output node
    //       data: { label: 'Output Node' },
    //       position: { x: 250, y: 250 },
    //     },
    //     // animated edge
    //     { id: 'e1-2', source: '1', target: '2', animated: true },
    //     { id: 'e2-3', source: '2', target: '3' },
    //   ];

    return (
        <React.Fragment>
            <div style={{ height: "600px", width: "100%" }}>
                {states && (
                    <ReactFlow
                        //@ts-ignore
                        elements={elements}
                        minZoom={0.4}
                        maxZoom={1}
                        snapToGrid={true}
                        zoomOnScroll={false}
                        onNodeDragStop={onNodeDragStop}
                    >
                        {/* <Background variant="dots" gap={15} size={1} /> */}
                        <Controls showInteractive={false} />
                        <MiniMap
                            nodeColor={(node) => {
                                switch (node.type) {
                                    case "input":
                                        return "#ddd";
                                    case "default":
                                        return "#00ff00";
                                    case "output":
                                        return "rgb(0,0,255)";
                                    default:
                                        return "#eee";
                                }
                            }}
                            nodeStrokeWidth={3}
                        />
                    </ReactFlow>
                )}
            </div>
        </React.Fragment>
    );
};

// interface WorkflowStatesTableProps {
//     items: State[];
//     loaded: boolean;
//     pagination?: any;
//     deleteItem?: (number) => Promise;
//     onPageChange?: (number) => void;
//     onOrderBy?: (string) => void;
//     onPerPageChange?: ({ value: string }) => void;
// }

export default WorkflowDiagram;
