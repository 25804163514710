import React from "react";
import styled from "styled-components";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Orders from "./Orders";
import Order from "./Order";
import OrderEdit from "./OrderEdit";
import OrdersAssigned from "./assigned/OrdersAssigned";
import OrderAssigned from "./assigned/OrderAssigned";

const OrderRouting = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}/assigned/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;

                    return <OrderAssigned path={path} uuid={uuid} />;
                }}
            />
            <Route
                exact
                path={`${path}/assigned`}
                render={() => {
                    return <OrdersAssigned path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/:uuid/edit`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;

                    return <OrderEdit path={path} uuid={uuid} />;
                }}
            />
            <Route
                exact
                path={`${path}/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;

                    return <Order path={path} uuid={uuid} />;
                }}
            />
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <Orders path={path} />;
                }}
            />
        </Switch>
    );
};

export default OrderRouting;
