import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CollapseWrapper = styled.div`
    overflow: hidden;
    transition: height ${props => props.speed} ease;
`;
class Collapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0
        };
    }
    componentDidMount() {
        this.updateHeight();
    }
    shouldComponentUpdate() {
        if (this.measure.clientHeight != this.state.height) return true;
        return false;
    }
    componentDidUpdate() {
        this.updateHeight();
    }
    updateHeight() {
        this.setState({ height: this.measure.clientHeight });
    }
    render() {
        const { speed, open, children } = this.props;
        const { height } = this.state;
        return (
            <CollapseWrapper
                style={{ height: open ? height : 0 }}
                speed={speed}
            >
                <div ref={measure => (this.measure = measure)}>{children}</div>
            </CollapseWrapper>
        );
    }
}

Collapse.propTypes = {
    speed: PropTypes.string,
    open: PropTypes.bool
};

Collapse.defaultProps = {
    speed: "1s",
    open: false
};

export default Collapse;
