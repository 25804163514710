import React, { useState } from "react";
import axios from "axios";
import add from "date-fns/add";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
    Row,
    Column,
    InputField,
    FormRow,
    DatePicker,
    SimpleDropzone,
    IconTextButton,
    CsvDownload,
    TextareaField,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const EmailOrderForm = ({ order, standardOnChange, errors }) => {
    const [recipientsUploaded, setRecipientsUploaded] = useState(null);
    const addToast = useAddToast();

    const minDate = order?.required_date
        ? new Date(order.required_date)
        : add(new Date(), {
              days:
                  order?.user_asset?.asset?.order_flow_details
                      ?.lead_time_days || 0,
          });

    //Get the email_details from the order. If it doesn't exist, create it
    //can't use destructing because it can be null
    let { email_details } = order;
    if (!email_details) {
        email_details = {
            recipients: "",
            subject: "",
            send_at: null,
            test_recipients: "",
            seed_recipients: "",
        };
    }
    const onChange = ({ name, value }) => {
        standardOnChange({
            name: "email_details",
            value: { ...email_details, [name]: value },
        });
    };

    const onDrop = (acceptedFiles, key) => {
        standardOnChange({
            name: "email_details",
            value: { ...email_details, [key]: acceptedFiles[0] },
        });

        let fd = new FormData();
        fd.append("file", acceptedFiles[0]);

        axios
            .post(`/api/v1/orders/${order.uuid}/recipients`, fd)
            .then(({ data }) => {
                const { recipients_count } = data;
                setRecipientsUploaded(recipients_count);

                standardOnChange({
                    name: "email_details",
                    value: {
                        ...email_details,
                        total_recipients: recipients_count,
                    },
                });
                addToast({
                    content: trans(
                        "{count, plural, =0 {no recipients uploaded} one {# recipient uploaded} other {# recipients uploaded}}",
                        {
                            count: recipients_count,
                        }
                    ),
                    showFor: 5000,
                });
            });
    };

    const csvHeaders = [
        { label: "Email", key: "email" },
        { label: "Name", key: "name" },
    ];

    return (
        <>
            <FormRow
                name="recipients"
                label={trans("Recipient Data")}
                helpText={trans(
                    "Please upload a list of all contacts, using the template provided"
                )}
                error={errors.recipients}
                required
            >
                <Column medium={6}>
                    <SimpleDropzone
                        onDrop={(acceptedFiles) =>
                            onDrop(acceptedFiles, "recipients")
                        }
                        multiple={false}
                    >
                        <IconTextButton
                            // style={{
                            //     textDecoration: "none",
                            //     paddingTop: "1rem",
                            //     paddingBottom: "0.8rem",
                            // }}
                            onClick={() => {
                                return false;
                            }}
                            icon={faUpload}
                            showCircle={false}
                            expanded={false}
                        >
                            <div
                                style={{
                                    fontWeight: "400",
                                }}
                            >
                                {recipientsUploaded
                                    ? trans(
                                          "{count, plural, =0 {no recipients uploaded} one {# recipient uploaded} other {# recipients uploaded}}",
                                          {
                                              count: recipientsUploaded,
                                          }
                                      )
                                    : trans("Upload")}
                            </div>
                        </IconTextButton>
                    </SimpleDropzone>
                </Column>
                <Column medium={6}>
                    <CsvDownload headers={csvHeaders} filename="recipients.csv">
                        <IconTextButton
                            // style={{
                            //     textDecoration: "none",
                            //     paddingTop: "1rem",
                            //     paddingBottom: "0.8rem",
                            // }}
                            onClick={() => {
                                return false;
                            }}
                            icon={faDownload}
                            showCircle={false}
                            expanded={false}
                        >
                            <span
                                style={{
                                    textDecoration: "none",
                                    border: "none !important",
                                }}
                            >
                                {trans("Download Template")}
                            </span>
                        </IconTextButton>
                    </CsvDownload>
                </Column>
            </FormRow>
            <FormRow
                name="required_send_date"
                label={trans("Send Date")}
                error={errors.required_send_date}
                helpText={trans("When is the email being sent?")}
                required
            >
                <DatePicker
                    name="required_send_date"
                    value={order.required_send_date}
                    showError={false}
                    onChange={standardOnChange}
                    minDate={minDate}
                />
            </FormRow>
            <FormRow
                name="subject"
                label={trans("Subject")}
                error={errors.subject}
                required
            >
                <InputField
                    name="subject"
                    value={email_details.subject}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>

            <FormRow
                name="test_recipients"
                label={trans("Test Email Addresses")}
                helpText={trans(
                    "A comma delimited list of email addresses to send tests"
                )}
                error={errors.test_recipients}
            >
                <TextareaField
                    name="test_recipients"
                    value={email_details.test_recipients}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>

            <FormRow
                name="seed_recipients"
                label={trans("Seed Email Addresses")}
                helpText={trans(
                    "A comma delimited list of email addresses to send during the actual send"
                )}
                error={errors.seed_recipients}
            >
                <TextareaField
                    name="seed_recipients"
                    value={email_details.seed_recipients}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
        </>
    );
};

export default EmailOrderForm;

// import React, { useCallback, useState } from "react";
// import { get } from "lodash";
// import {
//     Button,
//     FormikDatePicker,
//     FormikInput,
//     FormikTextarea,
//     FormRow,
//     IconTextButton,
//     Loading,
//     SimpleDropzone,
//     useAddToast,
//     FormikDateTimePicker,
// } from "@cortexglobal/rla-components";
// import * as Yup from "yup";
// import { Formik, Form } from "formik";
// import { trans } from "@cortexglobal/rla-intl";
// import { faUpload } from "@fortawesome/free-solid-svg-icons";
// import { NavigationButtons } from "../OrderEdit";

// const EmailOrderForm = ({
//     order,
//     uuid,
//     planner,
//     handleBackClick,
//     handleSubmit,
//     submitting,
//     standardFields,
//     standardInitialValues,
//     standardSchema,
// }) => {
//     console.log(standardInitialValues);
//     const addToast = useAddToast();

//     const [fileUploaded, setFileUploaded] = useState(false);

//     const customSchema = {
//         ...standardSchema,
//         // recipients: Yup.mixed().required("Required"),
//         send_at: Yup.date().required("Required"),
//         subject: Yup.string().required("Required"),
//         test_recipients: Yup.string(),
//         seed_recipients: Yup.string(),
//     };

//     const schema = Yup.object().shape(customSchema);

//     const onDrop = useCallback((acceptedFiles, setFieldValue) => {
//         setFieldValue("recipients", acceptedFiles[0]);

//         // add delay to make it seem real
//         setTimeout(() => {
//             setFileUploaded(true);
//             addToast({
//                 type: "success",
//                 content: trans("File uploaded successfully."),
//                 showFor: 5000,
//             });
//         }, 1000);
//     }, []);

//     if (submitting) {
//         return <Loading />;
//     }

//     const emailDetails = order.email_details || {};

//     const defaultValues = {
//         ...emailDetails,
//         send_at: emailDetails.required_send_date
//             ? new Date(emailDetails.required_send_date)
//             : new Date(),
//     };

//     return (
//         <Formik
//             onSubmit={handleSubmit}
//             initialValues={{
//                 ...standardInitialValues,
//                 send_at: get(defaultValues, "send_at") || new Date(),
//                 subject: get(defaultValues, "subject", ""),
//                 test_recipients: get(defaultValues, "test_recipients", ""),
//                 seed_recipients: get(defaultValues, "seed_recipients", ""),
//             }}
//             validationSchema={schema}
//         >
//             {({ values, errors, setFieldValue }) => {
//                 return (
//                     <Form className="form-group">
//                         {standardFields(values, errors)}

//                         <h3 style={{ margin: "2em 0" }}>Email Details</h3>

//                         <FormRow
//                             required={!get(defaultValues, "total_recipients")}
//                             name="recipients"
//                             label={trans("Contact Data")}
//                             error={errors.recipients}
//                             helpText={trans(
//                                 "Please upload a list of all contacts, please use the template provided"
//                             )}
//                         >
//                             <SimpleDropzone
//                                 onDrop={(acceptedFiles) =>
//                                     onDrop(acceptedFiles, setFieldValue)
//                                 }
//                                 multiple={false}
//                             >
//                                 <IconTextButton
//                                     style={{
//                                         textDecoration: "none",
//                                         paddingTop: "1rem",
//                                         paddingBottom: "0.8rem",
//                                     }}
//                                     onClick={() => {
//                                         return false;
//                                     }}
//                                     icon={faUpload}
//                                     showCircle={false}
//                                     expanded={false}
//                                 >
//                                     <div
//                                         style={{
//                                             fontWeight: "400",
//                                         }}
//                                     >
//                                         {fileUploaded
//                                             ? trans(
//                                                   "File uploaded successfully"
//                                               )
//                                             : trans("Upload")}
//                                     </div>
//                                 </IconTextButton>
//                             </SimpleDropzone>
//                         </FormRow>

//                         <FormRow
//                             name="required_send_date"
//                             label={trans("Send At")}
//                             error={errors.required_send_date}
//                             helpText={trans("When is the email being sent?")}
//                         >
//                             <FormikDateTimePicker
//                                 name="required_send_date"
//                                 value={values.send_at}
//                                 showError={false}
//                             />
//                         </FormRow>

//                         <FormRow
//                             required
//                             name="subject"
//                             label={trans("Email Subject Line")}
//                             error={errors.subject}
//                             helpText={trans(
//                                 "Override the default subject line"
//                             )}
//                         >
//                             <FormikInput
//                                 name="subject"
//                                 value={values.subject}
//                                 showError={false}
//                             />
//                         </FormRow>

//                         <FormRow
//                             name="test_recipients"
//                             label={trans("Test Email Addresses")}
//                             error={errors.test_recipients}
//                             helpText={trans(
//                                 "A comma delimited list of email addresses to send tests"
//                             )}
//                         >
//                             <FormikTextarea
//                                 name="test_recipients"
//                                 value={values.test_recipients}
//                                 showError={false}
//                             />
//                         </FormRow>

//                         <FormRow
//                             name="seed_recipients"
//                             label={trans("Seed Email Addresses")}
//                             error={errors.seed_recipients}
//                             helpText={trans(
//                                 "A comma delimited list of email addresses to send during the..."
//                             )}
//                         >
//                             <FormikTextarea
//                                 name="seed_recipients"
//                                 value={values.seed_recipients}
//                                 showError={false}
//                             />
//                         </FormRow>

//                         <NavigationButtons>
//                             <Button type="button" onClick={handleBackClick}>
//                                 Back
//                             </Button>
//                             <Button type="submit">Next</Button>
//                         </NavigationButtons>
//                     </Form>
//                 );
//             }}
//         </Formik>
//     );
// };

// export default EmailOrderForm;
