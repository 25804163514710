import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import {
    DashboardPanelForm,
    DashboardPanel,
} from "../../forms/DashboardPanelForm";
import { HeaderEmphasis } from "../../components/index";

const deleteItem = (id, setItem, item) => {
    axios
        .delete(`/api/v1/admin/dashboard-panel/${id}`)
        .then(({ data }) => {
            // console.log(data);
            //As a sub dashboard-panel has been deleted remove it
            setItem({
                loaded: true,
                data: {
                    ...item.data,
                    children: item.data.children.filter(
                        (child) => child.id !== id
                    ),
                },
            });
        })
        .catch();
};
export const DashboardPanelEdit = ({ id, path }) => {
    const history = useHistory();
    const page = useQueryString().get("page");

    const [item, setItem] = useState<{
        loaded: boolean;
        data?: DashboardPanel;
    }>({
        loaded: false,
        data: null,
    });
    const getItem = () => {
        setItem({ loaded: false, data: null });
        axios.get(`/api/v1/admin/dashboard-panel/${id}`).then(({ data }) => {
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, [id]);

    const submitForm = (values, setSubmitting) => {
        return axios
            .put(`/api/v1/admin/dashboard-panel/${id}`, values)
            .then(({ data }) => {
                // console.log(data);
                setSubmitting(false);
                setItem({
                    loaded: true,
                    data: data.data,
                });

                //As creation was successful move them on to the edit page
                // history.push(`${path}/edit/${data.id}`);
            });
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Edit Dashboard Panel")}{" "}
                        <HeaderEmphasis>
                            {item.data ? item.data.title : ""}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={`${path}?page=${page ? page : 1}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            {item.loaded ? (
                <Row>
                    <Panel style={{ paddingBottom: "0" }}>
                        <DashboardPanelForm
                            item={item.data}
                            submitForm={submitForm}
                        />
                    </Panel>
                </Row>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default DashboardPanelEdit;
