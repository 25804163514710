import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { useHistory } from "react-router-dom";

import {
    Column,
    Row,
    Panel,
    SubmitButton,
    useAddToast,
    Loading,
    Tabordion,
    PageTitle,
    HeaderWithErrorIndicated,
    useSectionErrors,
} from "@cortexglobal/rla-components";
import GeneralOrderForm from "./forms/GeneralOrderForm";
import EmailOrderForm from "./forms/EmailOrderForm";
import PrintOrderForm from "./forms/PrintOrderForm";
import PosOrderForm from "./forms/PosOrderForm";
import PressOrderForm from "./forms/PressOrderForm";
import DigitalOrderForm from "./forms/DigitalOrderForm";
import OrderFlowOptionsForm from "./forms/OrderFlowOptionsForm";
import { PlannerAssetOrderForm } from "./forms/PlannerAssetOrderForm";
import OrderSummary from "./components/OrderSummary";
import DirectMailOrderForm from "./forms/DirectMailOrderForm";

export const NavigationButtons = styled(Panel)`
    margin-top: 1rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
`;

const TabContent = styled.section`
    padding: 1rem 0;
`;

const TabDescription = ({ children }) => {
    return (
        <Row>
            <Column medium={6} centered>
                <p>{children}</p>
            </Column>
        </Row>
    );
};
const OrderEdit = ({ uuid }) => {
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [orderFlows, setOrderFlows] = useState({
        loaded: false,
        data: [],
    });
    const [order, setOrder] = useState();
    const [errors, setErrors] = useState(false);
    const [modules, setModules] = useState({ loaded: false, data: {} });
    const addToast = useAddToast();
    const history = useHistory();
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: ["name", "asset_type", "media_type", "locale"],
        print: ["quantity"],
        options: ["order_flow_options_uuids"],
    });

    const getOrder = (uuid) => {
        setLoading(true);
        return axios
            .get(`/api/v1/orders/${uuid}`)
            .then(({ data: { data } }) => {
                setLoading(false);
                setOrder(data);
                return data;
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error getting order."),
                    showFor: 5000,
                });
                setLoading(false);
            });
    };
    const getModules = () => {
        return axios
            .get(`/api/v1/modules`)
            .then(({ data }) => {
                setModules({ loaded: true, data });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error getting order."),
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getOrder(uuid).then((data) => {
            axios
                .get(
                    `/api/v1/assets/available-order-flows/${data.user_asset.asset.uuid}`
                )
                .then(({ data: { data } }) => {
                    setOrderFlows({ data, loaded: true });
                });
        });
        getModules();
    }, [uuid]);

    const handleSubmit = (confirmed = false) => {
        setSubmitting(true);

        //clear out unnecessary data
        const { user_asset, workflow, state, request, user, ...orderData } =
            order;
        // const fd = jsonToFormData(orderData, {
        //     initialFormData: new FormData(),
        //     showLeafArrayIndexes: true,
        //     includeNullValues: true,
        //     mapping: function (value) {
        //         if (typeof value === "boolean") {
        //             return +value ? "1" : "0";
        //         }
        //         if (Array.isArray(value) && value.length === 0) {
        //             return [];
        //         }

        //         return value;
        //     },
        // });
        // fd.append("confirmed", confirmed);
        return (
            axios
                .post(`/api/v1/orders/${uuid}`, { ...orderData, confirmed })

                // .post(`/api/v1/orders/${uuid}`, fd)
                .then(({ data: { data } }) => {
                    setSubmitting(false);
                    setOrder(data);
                    if (confirmed) {
                        history.push(`/requests/view/${data.request.uuid}`);
                    }
                })
                .catch((e) => {
                    setSubmitting(false);

                    if (e.response && e.response.data) {
                        if (e.response.status === 422) {
                            setErrors(e.response.data.errors);
                            const errorTabs = updateSectionErrors(
                                e.response.data.errors
                            );

                            const errorTab = tabs.findIndex((tab) => {
                                return errorTabs.includes(tab.alias);
                            });
                            history.push(
                                `${assetsPath}/${assetUuid}/admin/${
                                    errorTab && tabs[errorTab]
                                        ? tabs[errorTab].alias
                                        : ""
                                }`
                            );
                        }
                        addToast({
                            type: "alert",
                            content:
                                e.response && e.response.data
                                    ? e.response.data.message
                                    : trans("An error has occurred"),
                            showFor: 5000,
                        });
                    }
                })
        );
    };

    const standardOnChange = ({ name, value }) => {
        setOrder({
            ...order,
            [name]: value,
        });
        //Remove the field from the errors if it's there
        let { [name]: oldValue, ...newErrors } = errors;
        setErrors(newErrors);
        updateSectionErrors(newErrors);
    };

    if (!order || loading || !orderFlows.loaded || !modules.loaded) {
        return (
            <Row style={{ marginTop: "1rem" }}>
                <Loading />
            </Row>
        );
    }

    const hasChoosableOrderFlowOptions = (order, orderFlows) => {
        if (
            !Array.isArray(order?.user_asset?.asset?.order_flows) ||
            order.user_asset.asset.order_flows.length === 0
        ) {
            return false;
        }
        let hasChoosableOrderFlowOptions = false;
        order.user_asset.asset.order_flows.map((baseOrderFlow) => {
            const orderFlow = orderFlows.data.find(
                (orderFlow) => orderFlow.uuid === baseOrderFlow.uuid
            );
            if (!orderFlow) {
                return null;
            }
            return orderFlow.types.map((type) => {
                //Get the options for this type
                const options = order.user_asset.asset.order_flow_options
                    .filter((option) => {
                        return option.type.uuid === type.uuid;
                    })
                    .map((option) => {
                        return {
                            value: option.uuid,
                            text: option.name,
                        };
                    });

                if (options.length > 1) {
                    hasChoosableOrderFlowOptions = true;
                }
            });
        });
        return hasChoosableOrderFlowOptions;
    };

    const renderSpecificOrderTypeForm = () => {
        switch (order.type) {
            case "email":
                return (
                    <TabContent
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Email Information")}
                                section="email"
                                sectionErrors={sectionErrors}
                            />
                        }
                    >
                        <EmailOrderForm
                            uuid={uuid}
                            order={order}
                            standardOnChange={standardOnChange}
                            errors={errors}
                        />
                    </TabContent>
                );

            case "print":
                return (
                    <TabContent
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Print Information")}
                                section="print"
                                sectionErrors={sectionErrors}
                            />
                        }
                    >
                        <PrintOrderForm
                            uuid={uuid}
                            order={order}
                            standardOnChange={standardOnChange}
                            errors={errors}
                        />
                    </TabContent>
                );

            case "press":
                return (
                    <TabContent
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Press Information")}
                                section="print"
                                sectionErrors={sectionErrors}
                            />
                        }
                    >
                        <PressOrderForm
                            uuid={uuid}
                            order={order}
                            standardOnChange={standardOnChange}
                            errors={errors}
                        />
                    </TabContent>
                );

            case "pos":
            case "editable_pos":
                return (
                    <TabContent
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("POS Information")}
                                section="print"
                                sectionErrors={sectionErrors}
                            />
                        }
                    >
                        <PosOrderForm
                            uuid={uuid}
                            order={order}
                            standardOnChange={standardOnChange}
                            errors={errors}
                        />
                    </TabContent>
                );

            case "direct_mail":
                return (
                    <TabContent
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Direct Mail Information")}
                                section="direct-mail"
                                sectionErrors={sectionErrors}
                            />
                        }
                    >
                        <DirectMailOrderForm
                            uuid={uuid}
                            order={order}
                            standardOnChange={standardOnChange}
                            errors={errors}
                        />
                    </TabContent>
                );

            case "digital":
                return (
                    <TabContent
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Digital Information")}
                                section="digital"
                                sectionErrors={sectionErrors}
                            />
                        }
                    >
                        <DigitalOrderForm
                            uuid={uuid}
                            order={order}
                            standardOnChange={standardOnChange}
                            errors={errors}
                        />
                    </TabContent>
                );

            // case "basic":
            // default:
            //     return (
            //         <BasicOrderForm
            //             heading={trans("Additional Information: Asset Order")}
            //             uuid={uuid}
            //             order={order}
            //             handleBackClick={handleBackClick}
            //             handleSubmit={handleSubmit}
            //             submitting={submitting}
            //             standardFields={standardFields}
            //             standardInitialValues={standardInitialValues}
            //             standardSchema={standardSchema}
            //         />
            //     );
            default:
                return;
        }
    };

    const generateTabs = () => {
        let tabs = [];

        if (hasChoosableOrderFlowOptions(order, orderFlows)) {
            tabs = [
                <TabContent
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Options")}
                            section="options"
                            sectionErrors={sectionErrors}
                        />
                    }
                >
                    {/* <TabDescription>
                        {trans(
                            "Options related to the output of this specific asset"
                        )}
                    </TabDescription> */}
                    <OrderFlowOptionsForm
                        order={order}
                        errors={errors}
                        standardOnChange={standardOnChange}
                        orderFlows={orderFlows}
                    />
                </TabContent>,
            ];
        }
        //Add the general form applicable to all order types
        tabs = [
            ...tabs,
            ...[
                <TabContent
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("General Details")}
                            section="print"
                            sectionErrors={sectionErrors}
                        />
                    }
                >
                    <GeneralOrderForm
                        order={order}
                        errors={errors}
                        standardOnChange={standardOnChange}
                    />
                </TabContent>,
            ],
        ];

        //Add the specific form for the order type, if it exists
        if (renderSpecificOrderTypeForm()) {
            tabs = [...tabs, ...[renderSpecificOrderTypeForm()]];
        }

        //Add in the order summary
        tabs = [
            ...tabs,
            ...[
                //TODO - cortex - Look at the implementation of this in a later sprint
                // if (modules.data.planner) {
                //     tabs.push(
                //         <PlannerAssetOrderForm
                //             heading={
                //                 <HeaderWithErrorIndicated
                //                     title={trans("Planner")}
                //                     section="planner"
                //                     sectionErrors={sectionErrors}
                //                 />
                //             }
                //             order={order}
                //             errors={errors}
                //             standardOnChange={standardOnChange}
                //         />
                //     );
                // }

                <TabContent heading={trans("Order Summary")}>
                    <OrderSummary order={order} loading={loading}>
                        <Panel>
                            <div style={{ padding: "0 1rem" }}>
                                <h2>{trans("Please Note")}</h2>
                                <p>
                                    {trans(
                                        "Upon submitting your order, your request will be acknowledged within 2 working days. You will be provided with full tracking and communication on the status of your order."
                                    )}
                                </p>
                            </div>
                        </Panel>
                    </OrderSummary>
                </TabContent>,
            ],
        ];
        return tabs;
    };

    const tabs = generateTabs();
    const assetName = order?.user_asset?.name;
    return (
        <div style={{ position: "relative" }}>
            <PageTitle
                title={
                    <>
                        {trans("Order")}
                        {assetName ? `: ${assetName}` : ""}
                    </>
                }
            ></PageTitle>
            <Row>
                <Tabordion
                    current={tab}
                    type="steps"
                    style={{ paddingTop: "1rem" }}
                >
                    {!loading && modules.loaded ? (
                        tabs.map((tab, index) => {
                            return React.cloneElement(tab, {
                                onTabClick: () => {
                                    handleSubmit();
                                    setTab(index);
                                },
                                icon: index + 1,
                            });
                        })
                    ) : (
                        <Loading />
                    )}
                </Tabordion>
            </Row>
            <Row style={{ position: "sticky", bottom: 0 }}>
                <NavigationButtons>
                    <div style={{ flexGrow: 1 }}>
                        <SubmitButton
                            disabled={loading || tab === 0}
                            onClick={() => {
                                handleSubmit();
                                if (tab > 0) {
                                    setTab(tab - 1);
                                }
                            }}
                        >
                            {trans("Back")}
                        </SubmitButton>
                    </div>
                    <div>
                        {tab !== tabs.length - 1 ? (
                            <SubmitButton
                                disabled={loading || tab === tabs.length - 1}
                                onClick={() => {
                                    handleSubmit();
                                    if (tab < tabs.length - 1) {
                                        setTab(tab + 1);
                                    }
                                }}
                            >
                                {trans("Next")}
                            </SubmitButton>
                        ) : (
                            <SubmitButton
                                disabled={submitting || loading}
                                onClick={() => handleSubmit(true)}
                            >
                                {trans("Submit Order")}
                            </SubmitButton>
                        )}
                    </div>
                </NavigationButtons>
            </Row>
        </div>
    );
};

export default OrderEdit;
