import React, { Component } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//@ts-ignore cortex
import { darken, hexToRgbaString } from "@cortexglobal/cortex-utilities";

import { PlainButton } from "../index";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const background = (props) => {
    return props.theme.button.types[props.color ? props.color : "default"]
        .backgroundColor;
};

const foreground = (props) => {
    return props.theme.button.types[props.color ? props.color : "default"]
        .foregroundColor;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: ${(props) => `1px solid ${background(props)}`};
    border-radius: ${({ theme }) => theme.button.borderRadius}px;
    overflow: hidden;
`;

const Button = styled(PlainButton)<{ active: boolean }>`
    color: ${(props) => foreground(props)};
    padding: 0.5rem 0.7rem;
    background: ${(props) =>
        props.active
            ? hexToRgbaString(background(props), 0.7)
            : background(props)};
    &:hover,
    &:active,
    &:focus,
    &:visited {
        background-color: ${(props) => {
            return hexToRgbaString(background(props), 0.7);
        }};
    }
`;

const Content = styled.span`
    color: ${(props) => foreground(props)};
`;
export const ComboButton = ({
    buttons,
    color = "default",
}: {
    buttons: {
        content?: string;
        onClick: () => void;
        active?: boolean;
        icon?: IconProp;
        key?: string;
    }[];
    color: string;
}) => {
    return (
        <Container>
            {buttons.map((button, index) => {
                return (
                    <Button
                        key={button.key ? button.key : index}
                        onClick={button.onClick}
                        color={color}
                        active={button.active}
                    >
                        {button.icon && <FontAwesomeIcon icon={button.icon} />}{" "}
                        <Content>{button.content}</Content>
                    </Button>
                );
            })}
        </Container>
    );
};
export default ComboButton;
