import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Layout, Settings } from "../interfaces/layout";
import {
    SlideContainer,
    SlideTitle,
    Subtitle,
} from "../components/slideComponents";
import { SequencedVideo } from "../components/SequencedVideo";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";

const StyledSlideTitle = styled(SlideTitle)`
    flex: 0;
`;

const SlideColumn = styled.div<SlideColumnProps>`
    overflow-wrap: break-word;
    position: absolute;
    bottom: 0;

    height: ${(props) => props.height};
`;

const BottomContentSequencedVideo = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        // navigationState,
        navigationDispatch,
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);

    if (editing || disableSequencing) {
        currentTransitionIndex = 1;
    }
    const transitionNamesComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );
    const currentTransitionObject =
        transitions[
            currentTransitionIndex === 0 ? 0 : currentTransitionIndex - 1
        ];

    const contentBottom = settings.content_bottom
        ? `${settings.content_bottom}%`
        : "20%";
    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
            settings={settings}
        >
            <SequencedVideo
                isCurrent={
                    currentTransitionObject &&
                    currentTransitionObject.name === "sequenced_video"
                }
                disableSequencing={!!(editing || disableSequencing)}
                setup={content.sequenced_video.value || {}}
                navigationDispatch={navigationDispatch}
                contentBottom={contentBottom}
                contentWidth="90%"
                highlightColor={settings.highlightColor}
                columnWidth={settings.twoColumns ? "50%" : undefined}
            />
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                style={useSpring({
                    opacity: 1,
                })}
            >
                <RenderContentBlock {...content.title} />
            </StyledSlideTitle>
        </SlideContainer>
    );
};

interface SlideColumnProps {
    height: string;
}

export default BottomContentSequencedVideo;
