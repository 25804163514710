import React, { useState, useEffect } from "react";
import axios from "axios";

import { useQueryString } from "@cortexglobal/cortex-utilities";
import {
    Loading,
    useAddToast,
    Error,
    Button,
} from "@cortexglobal/rla-components";

import { setItem } from "@cortexglobal/cortex-utilities";
import { trans } from "@cortexglobal/rla-intl";

const CortexAuthSSO = (props) => {
    const addToast = useAddToast();

    const [loadingState, setLoadingState] = useState("loading");
    const [errorMessage, setErrorMessage] = useState("An error occurred");

    const lowerToken = useQueryString().get("token");
    const upperToken = useQueryString().get("TOKEN");
    const token = upperToken ?? lowerToken;

    useEffect(() => {
        if (token !== undefined && token !== "") {
            loginWithToken(token);
        } else {
            setLoadingState("error");

            setErrorMessage("Login failed.");
        }
    }, []);

    const loginWithToken = (token) => {
        setLoadingState("loading");

        const params = { token: token };

        axios
            .post(`/cortex-sso`, params)
            .then(({ data }) => {
                const { access_token, token_type } = data;

                if (access_token === undefined || access_token === "") {
                    throw "Access token is required, none supplied";
                }

                if (token_type === undefined || token_type === "") {
                    throw "Token type is required, none supplied";
                }

                setItem("token", access_token);
                setItem("access_token", `${token_type} ${access_token}`);

                window.location.replace("/");
            })
            .catch((e) => {
                if (e.response !== undefined && e.response.code < 500) {
                    setErrorMessage(e.response.data.message);
                }

                addToast({
                    type: "alert",
                    content: errorMessage,
                    showFor: 5000,
                });

                setLoadingState("error");
            });
    };

    const render = (state, message) => {
        if (state === "error") {
            return (
                <div>
                    <Error>
                        <p>{message}</p>
                    </Error>
                    <p>
                        {trans(
                            "Please try again or log in using your email and password."
                        )}
                    </p>
                    <Button $expanded as="a" href="/auth/login">
                        {trans("Log in")}
                    </Button>
                </div>
            );
        }

        return (
            <div>
                <p>{trans("Please wait while we log you in...")}</p>
                <Loading />
            </div>
        );
    };

    return (
        <div style={{ textAlign: "center" }}>
            {render(loadingState, errorMessage)}
        </div>
    );
};

export default CortexAuthSSO;
