import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { format } from "@cortexglobal/cortex-utilities";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {
    Column,
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    Tabordion,
    Button,
    FormikDatePicker,
    FormikTextarea,
    FormikTimePicker,
    Loading,
    Badge,
    useAddToast,
    UserInfoIcon,
} from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import { cancelOrderProcess } from "@cortexglobal/marketing-planner";
import AssetDetailsPreviewPanel from "../assets/components/AssetDetailsPreviewPanel";
import OrderFulfilmentResult from "./components/OrderFulfilmentResult";
import { NavigationButtons } from "./OrderEdit";

const TabordionGroup = ({ children, ...rest }) => {
    return <div {...rest}>{children}</div>;
};

const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: column;
`;

const ExpandedPanel = styled(Panel)`
    flex: 1;
`;

const DetailsLabel = styled.h4`
    margin: 0 0 0 0;
`;

const DetailsColumn = styled(Column)`
    margin-bottom: 1.2rem;
`;

const DescriptionContainer = styled.div`
    white-space: pre-line;
`;

const OrderDetail = styled.div`
    background: white;
    display: flex;
    padding: 1rem;

    &:nth-of-type(odd) {
        background: #f3f3f3;
    }

    p {
        margin: 0;
    }

    p:first-of-type {
        width: 190px;
        min-width: 190px;
        font-weight: bold;
    }
`;

const renderServiceRequired = (orderType) => {
    let wording = "";

    switch (orderType) {
        case "email":
            wording = "Email fulfilment";
            break;
        case "print":
            wording = "Print fulfilment";
            break;
        case "basic":
            wording = "Asset fulfilment";
            break;
    }

    return <>{wording}</>;
};

const renderMediaSpecificDetails = (order) => {
    switch (order.type) {
        case "email":
            if (!order.email_details) {
                return;
            }
            return (
                <>
                    <OrderDetail>
                        <p>{trans("Contacts Supplied")}: </p>
                        {order.email_details.total_recipients}
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Send Date")}: </p>
                        <p>
                            {format(
                                new Date(
                                    order.email_details.required_send_date
                                ),
                                "PP"
                            )}
                        </p>
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Send Time")}: </p>
                        <p>
                            {format(
                                new Date(
                                    order.email_details.required_send_date
                                ),
                                "pp"
                            )}
                        </p>
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Subject Line")}: </p>
                        {order.email_details.subject}
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Test Email Addresses")}: </p>
                        {Array.isArray(order.email_details.test_recipients) &&
                            order.email_details.test_recipients.join(", ")}
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Seed Email Addresses")}: </p>
                        {Array.isArray(order.email_details.seed_recipients) &&
                            order.email_details.seed_recipients.join(", ")}
                    </OrderDetail>
                </>
            );

        case "print":
            if (!order.print_details) {
                return;
            }
            return (
                <>
                    <OrderDetail>
                        <p>{trans("Delivery Address")}: </p>
                        {order.print_details.delivery_address}
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Quantity Required")}: </p>
                        <p>{order.print_details.quantity}</p>
                    </OrderDetail>
                </>
            );

        case "basic":
            if (!order.basic_details) {
                return;
            }
            return (
                <>
                    <OrderDetail>
                        <p>{trans("Instructions")}: </p>
                        {order.basic_details.instructions}
                    </OrderDetail>
                </>
            );
        default:
            return null;
    }
};

export const OrderDetails = ({ order, showActivityDetails = true }) => {
    const user = useSelector((state) => state.auth.user);
    const { planner_activity } = order;

    return (
        <Row>
            <Column medium={6}>
                {showActivityDetails && order.planner_activity && (
                    <section style={{ margin: "1rem 0 2rem" }}>
                        <h3 style={{ padding: "0 1rem" }}>
                            {trans("Activity")}
                        </h3>
                        <OrderDetail>
                            <p>{trans("Activity")}: </p>
                            <p>
                                <Badge
                                    style={{ margin: "0 1rem" }}
                                    type="warning"
                                >
                                    {planner_activity.ref}
                                </Badge>
                                {planner_activity.name}
                            </p>
                        </OrderDetail>
                        <OrderDetail>
                            <p>{trans("Media Channel")}: </p>
                            <p>
                                {order.planner_activity.media_category
                                    ? order.planner_activity.media_category.text
                                    : "N/A"}
                            </p>
                        </OrderDetail>
                        <OrderDetail>
                            <p>{trans("Date range")}: </p>
                            <p>
                                {format(
                                    new Date(order.planner_activity.start_date),
                                    "PP"
                                )}{" "}
                                -{" "}
                                {format(
                                    new Date(order.planner_activity.end_date),
                                    "PP"
                                )}
                            </p>
                        </OrderDetail>
                    </section>
                )}

                <section
                    style={{
                        margin: showActivityDetails ? "3rem 0" : "1rem 0",
                    }}
                >
                    <h3 style={{ padding: "0 1rem" }}>
                        {trans("Order Details")}
                    </h3>
                    {!showActivityDetails && (
                        <>
                            <OrderDetail>
                                <p>{trans("Order")}: </p>
                                <p>
                                    <Badge type="warning">{order.ref}</Badge>
                                </p>
                            </OrderDetail>
                            <OrderDetail>
                                <p>{trans("Status")}: </p>
                                <p>
                                    <Badge>
                                        {order.workflow &&
                                            order.workflow.status}
                                    </Badge>
                                </p>
                            </OrderDetail>

                            <OrderDetail>
                                <p>{trans("Date Created")}: </p>
                                <p>
                                    {format(new Date(order.created_at), "PP")}
                                </p>
                            </OrderDetail>
                        </>
                    )}
                    <OrderDetail>
                        <p>{trans("Date Required")}: </p>
                        <p>{format(new Date(order.required_date), "PP")}</p>
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Contact Email Address")}: </p>
                        <p>{order.contact_email}</p>
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Contact Telephone")}: </p>
                        <p>{order.contact_telephone}</p>
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Special Instructions")}: </p>
                        <p>{order.special_instructions}</p>
                    </OrderDetail>
                    {renderMediaSpecificDetails(order)}
                </section>
                <section style={{ margin: "3rem 0" }}>
                    <h3 style={{ padding: "0 1rem", marginTop: "2rem" }}>
                        {trans("Costs")}
                    </h3>
                    <OrderDetail>
                        <p>{trans("Service Required")}: </p>
                        {renderServiceRequired(order.type)}
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Total Cost")}: </p>
                        <p>
                            <FormattedCurrency
                                value={order.estimated_cost}
                                currency={user.currency || "GBP"}
                            />
                        </p>
                    </OrderDetail>
                    <OrderDetail>
                        <p>{trans("Brand Contribution")}: </p>
                        {/**TODO Make the below a number based on appropriate config */}
                        <p>
                            <FormattedCurrency
                                value={order.estimated_cost / 2}
                                currency={user.currency || "GBP"}
                            />
                        </p>
                    </OrderDetail>
                </section>
            </Column>
            <Column medium={6}>
                <AssetDetailsPreviewPanel
                    hideShadow={true}
                    asset={order.asset ? order.asset : order.user_asset.asset}
                    loaded={true}
                />
            </Column>
        </Row>
    );
};

const Order = ({ path, uuid, planner }) => {
    const [order, setOrder] = useState({
        loaded: false,
        data: {},
    });
    const dispatch = useDispatch();
    const addToast = useAddToast();
    const history = useHistory();

    const getOrder = () => {
        return axios
            .get(`/api/v1/orders/${uuid}`)
            .then(({ data: { data } }) => {
                setOrder({ loaded: true, data });
            });
    };

    useEffect(() => {
        getOrder();
    }, []);

    const confirmOrder = () => {
        return axios
            .post(`/api/v1/orders/${uuid}/confirm`)
            .then(({ data: { data } }) => {
                console.log("Confirm data", data);
                // setOrder(data);
                dispatch(cancelOrderProcess());
                addToast({
                    type: "success",
                    content: trans("Order saved"),
                    showFor: 5000,
                });
                if (planner && planner.order && planner.order.data) {
                    history.push(
                        `/plan/task/${planner.order.data.task.uuid}/activity/${planner.order.data.activity.uuid}`
                    );
                } else {
                    setOrder({ loaded: true, data });
                }
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error activating order."),
                    showFor: 5000,
                });
                dispatch(cancelOrderProcess());
            });
    };

    return (
        <>
            <PageTitle title={"Order"}>
                <Badge>{order.data.state && order.data.state.name}</Badge>
            </PageTitle>

            <Row>
                <Column>
                    {order.loaded ? (
                        <Tabordion unmounts={false} current={0}>
                            <div heading={<span>{trans("Details")}</span>}>
                                <OrderDetails order={order.data} />

                                {order?.data?.state?.alias === "pending" && (
                                    <NavigationButtons>
                                        {planner &&
                                            planner.order &&
                                            planner.order.data && (
                                                <Button
                                                    as={Link}
                                                    to={`/orders/${uuid}/edit?activityUuid=${planner.order.data.activity.uuid}&taskUuid=${planner.order.data.task.uuid}`}
                                                    type="button"
                                                >
                                                    {trans("Back")}
                                                </Button>
                                            )}
                                        <Button onClick={confirmOrder}>
                                            {trans("Confirm Order")}
                                        </Button>
                                    </NavigationButtons>
                                )}
                            </div>
                            {order?.data?.state?.alias === "complete" && (
                                <OrderFulfilmentResult
                                    order={order.data}
                                    heading={<span>{trans("Results")}</span>}
                                    getOrder={getOrder}
                                />
                            )}
                        </Tabordion>
                    ) : (
                        <Loading />
                    )}
                </Column>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => {
    return { planner: state.planner };
};

export default connect(mapStateToProps, null)(Order);
