import React, { useState } from "react";
import { isEmpty } from "lodash";
import { faTrashAlt, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import {
    Row,
    Column,
    IconTextButton,
    TableList,
    Modal,
} from "@cortexglobal/rla-components";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import { format } from "@cortexglobal/cortex-utilities";
import { useUser } from "@cortexglobal/cortex-auth-redux";
import InventoryItemDetails from "../../equipment/InventoryItemDetails";

const InventoryBasket = ({
    itemBookingList,
    removeBookingItem,
    editBookingItem,
    editable,
    includeActualSpend = false,
    showImage,
    showingInSummary,
    settings,
}) => {
    const { user } = useUser();
    const [selectedItem, setSelectedItem] = useState(null);

    const formatCustomChoices = (custom_choices) => {
        if (isEmpty(custom_choices)) {
            return "";
        }

        let result = "";
        custom_choices &&
            Object.keys(custom_choices).map((property) => {
                result += `${property}: ${custom_choices[property]}, `;
            });

        return result;
    };

    return (
        <>
            <TableList>
                <thead>
                    <tr>
                        {showImage && <th></th>}
                        <th>{trans("Name")}</th>
                        <th style={{ textAlign: "right" }}>
                            {trans("Qty/Days")}
                        </th>
                        <th>{trans("Dates")}</th>
                        <th style={{ textAlign: "right" }}>
                            {trans("Budgeted Cost")}
                        </th>
                        {includeActualSpend === true && (
                            <th style={{ textAlign: "right" }}>
                                {trans("Actual Cost")}
                            </th>
                        )}
                        <th>{trans("Custom Choices")}</th>
                        {(editable || showingInSummary) && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {!isEmpty(itemBookingList) ? (
                        <>
                            {itemBookingList.map((item) => {
                                return (
                                    <tr key={item.uuid}>
                                        {showImage && (
                                            <td>
                                                <img
                                                    src={
                                                        item.item.thumbnail ||
                                                        "/img/placeholder.png"
                                                    }
                                                    style={{ maxWidth: "60px" }}
                                                    alt={item.name}
                                                />
                                            </td>
                                        )}
                                        <td>{`${item.categoryName} - ${item.name}`}</td>
                                        <td style={{ textAlign: "right" }}>
                                            {item.quantity}
                                        </td>
                                        <td>
                                            {format(new Date(item.start_date))}{" "}
                                            - {format(new Date(item.end_date))}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <FormattedCurrency
                                                value={item.predicted_cost}
                                                currency={
                                                    user?.currency?.iso_code ||
                                                    "GBP"
                                                }
                                            />
                                        </td>
                                        {includeActualSpend === true && (
                                            <td style={{ textAlign: "right" }}>
                                                <FormattedCurrency
                                                    value={item.actual_cost}
                                                    currency={
                                                        user?.currency
                                                            ?.iso_code || "GBP"
                                                    }
                                                />
                                            </td>
                                        )}
                                        <td>
                                            {formatCustomChoices(
                                                item.custom_choices
                                            )}
                                        </td>
                                        {editable && (
                                            <td style={{ width: "40px" }}>
                                                <Row>
                                                    <Column medium={6}>
                                                        <IconTextButton
                                                            onClick={() =>
                                                                editBookingItem(
                                                                    item
                                                                )
                                                            }
                                                            showCircle={false}
                                                            icon={faEdit}
                                                        />
                                                    </Column>
                                                    <Column medium={6}>
                                                        <IconTextButton
                                                            onClick={() =>
                                                                removeBookingItem(
                                                                    item
                                                                )
                                                            }
                                                            showCircle={false}
                                                            icon={faTrashAlt}
                                                        />
                                                    </Column>
                                                </Row>
                                            </td>
                                        )}
                                        {showingInSummary && (
                                            <td style={{ width: "40px" }}>
                                                <IconTextButton
                                                    onClick={() => {
                                                        //Show the availability
                                                        setSelectedItem(item);
                                                    }}
                                                    showCircle={false}
                                                    icon={faEye}
                                                >
                                                    {trans("Show Availability")}
                                                </IconTextButton>
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </>
                    ) : (
                        <tr>
                            <td colSpan="100%">{trans("No kit selected")}</td>
                        </tr>
                    )}
                </tbody>
            </TableList>

            {selectedItem !== null && (
                <Modal
                    visible={selectedItem !== null}
                    onClose={() => setSelectedItem(null)}
                    maxWidth="800px"
                >
                    <InventoryItemDetails
                        item={selectedItem}
                        date={new Date(selectedItem.start_date)}
                        settings={settings}
                    />
                </Modal>
            )}
        </>
    );
};

export default InventoryBasket;
