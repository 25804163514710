import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    FormikInput,
    FormikCheckbox,
    Row,
    Column,
    FormRow,
    FormikDatePicker,
    FormikSelect,
    Tabordion,
    HeaderWithErrorIndicated,
    useSectionErrors,
    Select,
    useAddToast,
    LoadingIconSmall,
} from "@cortexglobal/rla-components";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { MessageWithTags } from "@cortexglobal/communication";
import { TabordionGroup } from "../components";
import { Quill } from "react-quill";
import { isEmpty } from "lodash";

const quillGetHTML = (inputDelta) => {
    // hack to display Quill delta as HTML - creates a temp element, parses the delta into it then gets the output
    let temp = document.createElement("div");
    temp.setAttribute("id", "tmp-quill");

    new Quill(temp).setContents(inputDelta);

    return temp.getElementsByClassName("ql-editor")[0].innerHTML;
};

const schema = Yup.object().shape({
    name: Yup.string().required(),
    priority: Yup.string(),
    subject: Yup.string(),
    mailing_list: Yup.string().required(trans("Required")),
    message: Yup.string(),
    communication_frequency: Yup.string().required(trans("Required")),
    mailing_list_recipient_interval: Yup.number().nullable(),
    email_alert: Yup.boolean(),
    pop_up_notification_enabled: Yup.boolean(),
    communication_interval: Yup.string().nullable(),
    schedule_start_date: Yup.date().nullable(),
    schedule_end_date: Yup.date().nullable(),
    pop_up_interval: Yup.string().nullable(),
    pop_up_subject: Yup.string().nullable(),
    pop_up_message: Yup.string().nullable(),
    message_as_html: Yup.string().nullable(),
    smart_ref: Yup.string().nullable(),
});

export const CommunicationRuleForm = ({
    item = {
        name: "",
        priority: "normal",
        subject: "",
        mailing_list: "",
        message: "",
        pop_up_subject: "",
        pop_up_message: "",
        communication_frequency: "",
        mailing_list_recipient_interval: 0,
        email_alert: false,
        pop_up_notification_enabled: false,
        communication_interval: 0,
        schedule_start_date: "",
        schedule_end_date: "",
        pop_up_interval: 0,
        message_as_html: "",
        smart_ref: smartRef,
    },
    submitForm,
    smartRef = null,
    reference = {},
}) => {
    const intl = useIntl();
    const addToast = useAddToast();
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: [
            "name",
            "schedule_start_date",
            "communication_frequency",
            "mailing_list",
        ],
        communication: ["subject", "priority"],
    });

    const [tags, setTags] = useState({
        loading: false,
        default: [],
        mailing_list_tags: [],
        error: "",
    });

    const [loadingMailingLists, setLoadingMailingLists] = useState(false);
    const [selectedMailingLists, setSelectedMailingLists] = useState(null);
    const [allMailingLists, setAllMailingLists] = useState([]);
    const [tab, setTab] = useState(0);
    const [hidePopUpTabordian, setHidePopUpTabordian] = useState(
        item?.pop_up_notification_enabled || false
    );

    useEffect(() => {
        getMailingLists(smartRef);
    }, [smartRef]);

    const getMailingLists = (smartRef) => {
        setLoadingMailingLists(true);
        const listType = smartRef || "general";

        axios
            .get(`/api/v1/communication/mailing-lists/${listType}`, {
                params: { with_general: true },
            })
            .then(({ data }) => {
                setSelectedMailingLists(data);

                let flatenedList = data;
                if (data[0]?.options !== undefined) {
                    flatenedList = [];
                    data.map((listItem) => {
                        if (Array.isArray(listItem.options)) {
                            flatenedList = [
                                ...flatenedList,
                                ...listItem.options,
                            ];
                        }
                    });
                }

                setAllMailingLists(flatenedList);
                setMailingListTags(flatenedList, item.mailing_list);
            })
            .catch()
            .finally(() => {
                setLoadingMailingLists(false);
            });
    };

    const setMailingListTags = (mailingLists, mailingListUuid) => {
        if (isEmpty(mailingLists) || isEmpty(mailingListUuid)) {
            return;
        }

        const list = mailingLists.filter((l) => l.value === mailingListUuid)[0];

        const selectedListTags = list?.tags || [];

        setTags({
            ...tags,
            mailing_list_tags: selectedListTags,
        });
    };

    const handleSubmit = (values, { setErrors, setSubmitting, ...rest }) => {
        setSubmitting(true);

        let formValues = { ...values };
        formValues["message_as_html"] = quillGetHTML(formValues.message);
        formValues["smart_ref"] = smartRef;
        formValues["message"] = JSON.stringify(formValues.message);
        formValues["pop_up_message"] = JSON.stringify(
            formValues.pop_up_message
        );

        submitForm(formValues, setSubmitting)
            .then(() => {
                addToast({
                    type: "success",
                    content: trans("Rule saved"),
                    showFor: 5000,
                });
            })

            .catch(({ response }) => {
                if (response?.status === 422) {
                    updateSectionErrors(response.data.errors);
                    setErrors(response.data.errors);
                    addToast({
                        content: trans(
                            "Please check you have filled in all the required information"
                        ),
                        type: "alert",
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        content: trans("Please check the information provided"),
                        type: "alert",
                    });
                }
            })
            .finally(() => setSubmitting(false));
    };

    const updateErrors = (name, errors) => {
        // console.log(name, errors);
        let { [name]: oldValue, ...newErrors } = errors;
        // console.log(newErrors);
        updateSectionErrors(newErrors);
    };

    const getMailingListMessageTags = (uuid) => {
        setTags({ ...tags, loading: true });
        axios
            .get(`/api/v1/communication/mailing-lists/${uuid}/message-tags`)
            .then(({ data: { data } }) => {
                setTags({ ...tags, default: data });
            })
            .catch((e) => {
                setTags({ ...tags, data: {}, error: e.message });
            })
            .finally(() => {
                setTags({ ...tags, loading: false });
            });
    };

    const getInitalStartDate = () => {
        if (!isEmpty(item.schedule_start_date)) {
            return new Date(item.schedule_start_date);
        }

        if (!isEmpty(reference.start_date)) {
            return new Date(reference.start_date);
        }

        return null;
    };

    const getInitalEndDate = () => {
        if (!isEmpty(item.schedule_end_date)) {
            return new Date(item.schedule_end_date);
        }

        if (!isEmpty(reference.end_date)) {
            return new Date(reference.end_date);
        }

        return null;
    };

    return (
        <Formik
            initialValues={{
                name: item.name,
                subject: item.subject,
                priority: item.priority,
                mailing_list: item.mailing_list,
                message: item.message,
                communication_frequency: item.communication_frequency,
                email_alert: item.email_alert,
                pop_up_notification_enabled: item.pop_up_notification_enabled,
                mailing_list_recipient_interval:
                    item.mailing_list_recipient_interval,
                schedule_start_date: getInitalStartDate(),
                schedule_end_date: getInitalEndDate(),
                pop_up_interval: item.pop_up_interval,
                pop_up_message: item.pop_up_message,
                pop_up_subject: item.pop_up_subject,
                communication_interval: item.communication_interval,
                smart_ref: item.smart_ref,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                //@ts-ignore cortex
                                heading={
                                    <HeaderWithErrorIndicated
                                        title={trans("Rule")}
                                        section="general"
                                        sectionErrors={sectionErrors}
                                    />
                                }
                                onTabClick={() => setTab(0)}
                            >
                                <FormRow
                                    name="name"
                                    required={true}
                                    label={trans("Name")}
                                    error={errors.name}
                                >
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                        showError={false}
                                        onBlur={(e) => {
                                            updateErrors(e, errors);
                                        }}
                                    />
                                </FormRow>

                                <FormRow
                                    name="schedule_start_date"
                                    label={trans("Schedule Start Date")}
                                    helpText={trans(
                                        "Date the communication rule will start."
                                    )}
                                    required={true}
                                    error={errors.schedule_start_date}
                                >
                                    <FormikDatePicker
                                        name="schedule_start_date"
                                        value={values.schedule_start_date}
                                        showError={false}
                                        onBlur={(e) => {
                                            updateErrors(e, errors);
                                        }}
                                    />
                                </FormRow>

                                <FormRow
                                    name="schedule_end_date"
                                    label={trans("Schedule End Date")}
                                    helpText={trans(
                                        "Date the communication rule will end, leave blank if ongoing."
                                    )}
                                    error={errors.schedule_end_date}
                                >
                                    <FormikDatePicker
                                        name="schedule_end_date"
                                        value={values.schedule_end_date}
                                        showError={false}
                                        onBlur={(e) => {
                                            updateErrors(e, errors);
                                        }}
                                    />
                                </FormRow>

                                <FormRow
                                    name="communication_frequency"
                                    required={true}
                                    label={trans(
                                        "Communication Send Frequency"
                                    )}
                                    helpText={trans(
                                        "How often should this communication be sent?"
                                    )}
                                    error={errors.communication_frequency}
                                >
                                    <FormikSelect
                                        name="communication_frequency"
                                        value={values.communication_frequency}
                                        options={[
                                            {
                                                text: intl.formatMessage({
                                                    id: "Daily",
                                                }),
                                                value: "daily",
                                            },
                                            {
                                                text: intl.formatMessage({
                                                    id: "Weekly",
                                                }),
                                                value: "weekly",
                                            },
                                            {
                                                text: intl.formatMessage({
                                                    id: "Fortnightly",
                                                }),
                                                value: "fortnightly",
                                            },
                                            {
                                                text: intl.formatMessage({
                                                    id: "Monthly",
                                                }),
                                                value: "monthly",
                                            },
                                            {
                                                text: intl.formatMessage({
                                                    id: "Yearly",
                                                }),
                                                value: "yearly",
                                            },
                                        ]}
                                        showError={false}
                                        onBlur={(e) => {
                                            updateErrors(e, errors);
                                        }}
                                    />
                                </FormRow>

                                <FormRow
                                    name="mailing_list"
                                    label={trans("Mailing List")}
                                    required={true}
                                    error={errors.mailing_list}
                                >
                                    {selectedMailingLists ? (
                                        <Select
                                            name="mailing_list"
                                            value={values.mailing_list}
                                            placeholder={intl.formatMessage({
                                                id: "Select a mailing list...",
                                            })}
                                            options={selectedMailingLists}
                                            onChange={({ value }) => {
                                                setFieldValue(
                                                    "mailing_list",
                                                    value
                                                );

                                                setMailingListTags(
                                                    allMailingLists,
                                                    value
                                                );
                                            }}
                                            onBlur={(e) => {
                                                updateErrors(e, errors);
                                            }}
                                        />
                                    ) : (
                                        <LoadingIconSmall />
                                    )}
                                </FormRow>

                                <FormRow
                                    name="mailing_list_recipient_interval"
                                    label={trans("Send delay (days)")}
                                    helpText={trans(
                                        "The number of days to wait after a contact joins a contact list they receive a message."
                                    )}
                                    error={
                                        errors.mailing_list_recipient_interval
                                    }
                                >
                                    <FormikInput
                                        type="number"
                                        name="mailing_list_recipient_interval"
                                        value={
                                            values.mailing_list_recipient_interval
                                        }
                                        showError={false}
                                    />
                                </FormRow>

                                <FormRow
                                    name="pop_up_notification_enabled"
                                    label={trans("Enable Pop-up")}
                                    helpText={trans("Enable pop-up")}
                                    error={errors.pop_up_notification_enabled}
                                >
                                    <FormikCheckbox
                                        name="pop_up_notification_enabled"
                                        value={
                                            values.pop_up_notification_enabled
                                        }
                                        onChange={(e) => {
                                            setHidePopUpTabordian(
                                                e.target.checked
                                            );
                                        }}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                            </TabordionGroup>

                            <TabordionGroup
                                //@ts-ignore cortex
                                heading={
                                    <HeaderWithErrorIndicated
                                        title={trans("Communication")}
                                        section="communication"
                                        sectionErrors={sectionErrors}
                                    />
                                }
                                onTabClick={() => setTab(1)}
                            >
                                <FormRow
                                    name="subject"
                                    required={true}
                                    label={trans("Subject")}
                                    error={errors.subject}
                                >
                                    <FormikInput
                                        name="subject"
                                        value={values.subject}
                                        showError={false}
                                        onBlur={(e) => {
                                            updateErrors(e, errors);
                                        }}
                                    />{" "}
                                </FormRow>

                                <FormRow
                                    name="priority"
                                    required={true}
                                    label={trans("Priority")}
                                    helpText={trans(
                                        "Setting the priority will manage the order in which communications are processed."
                                    )}
                                    error={errors.priority}
                                >
                                    <FormikSelect
                                        name="priority"
                                        required={true}
                                        value={values.priority}
                                        options={[
                                            {
                                                text: intl.formatMessage({
                                                    id: "Low",
                                                }),
                                                value: "low",
                                            },
                                            {
                                                text: intl.formatMessage({
                                                    id: "Normal",
                                                }),
                                                value: "normal",
                                            },
                                            {
                                                text: intl.formatMessage({
                                                    id: "High",
                                                }),
                                                value: "high",
                                            },
                                        ]}
                                        showError={false}
                                        onBlur={(e) => {
                                            updateErrors(e, errors);
                                        }}
                                    />
                                </FormRow>

                                <FormRow
                                    name="message"
                                    label={trans("Message")}
                                    required={true}
                                >
                                    <MessageWithTags
                                        name="message"
                                        value={values.message}
                                        onChange={(name, value) => {
                                            setFieldValue("message", value);
                                        }}
                                        tags={[
                                            ...(tags.default || []),
                                            ...(tags.mailing_list_tags || []),
                                        ]} // if not supplied then the settings api will be called to look these up
                                        loading={false}
                                    />
                                </FormRow>
                                <FormRow
                                    name="email_alert"
                                    label={trans("Include email")}
                                    helpText={trans("Optional")}
                                >
                                    <FormikCheckbox
                                        name="email_alert"
                                        value={values.email_alert}
                                    />
                                </FormRow>
                                <FormRow
                                    name="communication_interval"
                                    label={trans(
                                        "Communication Interval (days)"
                                    )}
                                    helpText={trans(
                                        "The number of days you wish to add on top of your communication send frequency (used for abnormal send frequencies)"
                                    )}
                                >
                                    <FormikInput
                                        type="number"
                                        name="communication_interval"
                                        value={values.communication_interval}
                                    />
                                </FormRow>
                            </TabordionGroup>

                            {hidePopUpTabordian && (
                                <TabordionGroup
                                    heading={<span>{trans("Pop-up")}</span>}
                                    onTabClick={() => setTab(2)}
                                >
                                    <FormRow
                                        name="pop_up_subject"
                                        label={trans("Pop-up Subject")}
                                        helpText={trans("Optional")}
                                        error={errors.pop_up_subject}
                                    >
                                        <FormikInput
                                            name="pop_up_subject"
                                            value={values.pop_up_subject}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="pop_up_message"
                                        label={trans("Pop-up Message")}
                                        required={true}
                                    >
                                        <MessageWithTags
                                            name="pop_up_message"
                                            value={values.pop_up_message}
                                            onChange={(name, value) => {
                                                setFieldValue(
                                                    "pop_up_message",
                                                    value
                                                );
                                            }}
                                            tags={[
                                                ...(tags.default || []),
                                                ...(tags.mailing_list_tags ||
                                                    []),
                                            ]} // if not supplied then the settings api will be called to look these up
                                            loading={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="pop_up_interval"
                                        label={trans(
                                            "Communication Interval (days)"
                                        )}
                                        helpText={trans(
                                            "The number of days you wish to add on top of your communication send frequency (used for abnormal send frequencies)"
                                        )}
                                    >
                                        <FormikInput
                                            type="number"
                                            name="pop_up_interval"
                                            value={values.pop_up_interval}
                                        />
                                    </FormRow>
                                </TabordionGroup>
                            )}
                        </Tabordion>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CommunicationRuleForm;
