import React, { useEffect, useState } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import {
    Column,
    FormLabel,
    Loading,
    LoadingIconSmall,
    Tooltip,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import QuillField from "./editors/QuillField";
import Delta from "quill";
import { useModule } from "@cortexglobal/cortex-auth-redux";

const MessageWithTags = ({
    name = "message",
    value,
    label,
    tagsLabel,
    error,
    onChange,
    tags: tagsIn = [],
    loading: loadingIn,
}) => {
    const [loading, setLoading] = useState(loadingIn);
    const [tags, setTags] = useState(tagsIn);
    const [copiedTag, setCopiedTag] = useState(null);

    useEffect(() => {
        getTags();
    }, []);

    const { modules } = useModule();

    //TODO - see if we can make this work.  Looks like the react-quill editor has
    // a way of getting the underlying quill object, but we'll need to add a ref and a way of getting the
    // editor object from the ref
    // const addTagToMessage = (tag) => {
    //     let newMessage = value || { ops: [] };

    //     const tagString = `<<${tag.key}>>`; // todo: use start and end from settings

    //     if (newMessage instanceof Delta) {
    //         newMessage = newMessage.insert(tagString);
    //     } else if (typeof newMessage === "object") {
    //         if (!Array.isArray(newMessage["ops"])) {
    //             newMessage["ops"] = [];
    //         }

    //         newMessage["ops"].push({ insert: tagString });
    //     } else {
    //         console.info(
    //             "Tag could not be appended, message is neither a Delta or object"
    //         );
    //     }

    //     onChange("message", newMessage);
    // };

    const copyTagToClipboard = (tag) => {
        const tagString = `<<${tag.key}>>`; // todo: use start and end from settings

        navigator.clipboard.writeText(tagString).then(() => {
            setCopiedTag(tag);
        });
    };

    const getTags = (suppliedTags = []) => {
        setLoading(true);

        axios
            .get(`/api/v1/communication/settings`)
            .then(({ data }) => {
                setTags([...(data?.tags || []), ...suppliedTags]);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let columnWidth = 12;

    if (modules.behavior_driven_comms?.enabled) {
        columnWidth = 8;
    }

    return (
        <>
            <Column medium={columnWidth} collapse>
                {label && (
                    <FormLabel name="message">
                        {label || trans("Message")}
                    </FormLabel>
                )}

                <QuillField
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={error}
                    disabled={loading}
                />
            </Column>

            {modules.behavior_driven_comms?.enabled && (
                <Column medium={4} style={{ position: "relative" }}>
                    {tagsLabel && (
                        <FormLabel name="message">
                            {tagsLabel || trans("Tags")}
                        </FormLabel>
                    )}

                    <div
                        style={{
                            border: "1px solid #aaafb7",
                            padding: "5px 10px 10px 10px",
                            borderRadius: "3px",
                            width: "100%",
                            overflow: "scroll",
                            fontSize: "0.8rem",
                        }}
                    >
                        {loading === true ? (
                            <LoadingIconSmall style={{ margin: "0 auto" }} />
                        ) : (
                            [...tags, ...tagsIn].map((tag) => {
                                return (
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            copyTagToClipboard(tag);
                                            //addTagToMessage(tag)
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        }}
                                        key={"tag-" + tag.value}
                                    >
                                        {tag.value} {copiedTag === tag && "✓"}
                                    </div>
                                );
                            })
                        )}
                    </div>
                    <p
                        style={{
                            fontSize: "0.8rem",
                        }}
                    >
                        {trans("Click to copy tag to clipboard")}
                    </p>
                </Column>
            )}
        </>
    );
};

export default MessageWithTags;
