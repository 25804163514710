import React, { useState } from "react";
import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import {
    Loading,
    Tabordion,
    Panel,
    Row,
    Column,
    InputField,
    SubmitButton,
    Button,
    PageTitle,
    IconTextButton,
    useAddToast,
    useToasts,
    HeaderWithErrorIndicated,
    useSectionErrors,
} from "@cortexglobal/rla-components";
import {
    useUserAsset,
    UPDATE_ASSET_STATE,
    LOAD_USER_ASSET,
    SAVING_DATA,
} from "../hooks/useUserAsset";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { useQueryString } from "@cortexglobal/cortex-utilities";

import Editor from "../../assets/forms/Editor";
import SiteSettingsEditor from "../../assets/forms/SiteSettingsEditor";
import NavigationEditor from "../../assets/forms/NavigationEditor";
import { confirmHappyToLeave } from "../../assets/screens/AdminAsset";
export const HeaderEmphasis = styled.em`
    font-weight: 300;
    margin: 0 1.3rem 0 0.3rem;
`;

const HeaderEditWrapper = styled.div`
    display: flex;
    width: 100%;
`;
const HeaderInput = styled(InputField)`
    flex-grow: 1;
    min-height: 100%;
    font-size: ${({ theme }) => theme.typography.pageTitle};
`;
const EditUserAsset = ({
    asset,
    assetUuid,
    assetsPath,
    filters,
    groups,
    setRefreshSearch,
    selectUserAsset,
    planner,
}) => {
    const [editingName, setEditingName] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: ["name", "asset_type", "media_type", "locale"],
        meta: ["media-channel", "therapy-type"],
        categories: ["category-hierarchy"],
        availability: [
            "view_type",
            "available_from",
            "available_to",
            "terms",
            "certification_terms",
            "certification_ref",
            "certification_awarded_date",
            "expiration_date",
        ],
    });
    const intl = useIntl();
    let history = useHistory();
    let match = useRouteMatch(`${assetsPath}/:assetUuid/edit/:step?`);
    const addToast = useAddToast();
    const [_, toastDispatch] = useToasts();
    const { page } = useParams();

    const [userAssetData, dispatch] = useUserAsset(assetUuid);

    const standardOnChange = ({ name, value }) => {
        dispatch({ type: UPDATE_ASSET_STATE, name, value });
        //Remove the field from the errors if it's there
        let { [name]: oldValue, ...newErrors } = errors;
        setErrors(newErrors);
    };

    const saveUpdatedAsset = (event, previewOnly = false, rethrow = false) => {
        event.preventDefault();
        setEditingName(false);
        const generatingFiles =
            !userAssetData.userAsset.files_ready && !previewOnly;
        if (generatingFiles) {
            addToast({
                uuid: "saveToast",
                type: "loader",
                content: trans("Saving your asset and generating files"),
            });
        }
        dispatch({
            type: SAVING_DATA,
            data: true,
        });
        return axios
            .put(`/api/v1/user-assets/${assetUuid}`, {
                ...userAssetData.userAsset,
                previewOnly,
            })
            .then(({ data: { data } }) => {
                if (generatingFiles) {
                    toastDispatch({ type: "removeToast", uuid: "saveToast" });
                }
                dispatch({
                    type: LOAD_USER_ASSET,
                    data,
                    assetsPath,
                });
                selectUserAsset(data);
                setRefreshSearch(true);

                addToast({
                    type: "success",
                    content: trans("Asset saved"),
                    showFor: 5000,
                });
                if (!previewOnly && planner && planner.order) {
                    setLoading(true);
                    return axios
                        .post(`/api/v1/orders/stub`, {
                            user_asset_uuid: assetUuid,
                            activity_uuid: planner.order.data.activity.uuid,
                        })
                        .then(({ data }) => {
                            setLoading(false);
                            //Push to the order edit page
                            history.push(
                                `/orders/${data.data.uuid}/edit?activityUuid=${planner.order.data.activity.uuid}&taskUuid=${planner.order.data.task.uuid}`
                            );
                            return false;
                        })
                        .catch((e) => {
                            setLoading(false);
                            addToast({
                                type: "alert",
                                content: trans("Error creating order."),
                                showFor: 5000,
                            });
                        });
                }
                return data;
            })
            .catch((e) => {
                if (e.response && e.response.data) {
                    setErrors(e.response.data.errors);
                    updateSectionErrors(e.response.data.errors);
                    addToast({
                        type: "alert",
                        content:
                            e.response && e.response.data
                                ? e.response.data.message
                                : trans("An error has occurred"),
                        showFor: 5000,
                    });
                }
                if (generatingFiles) {
                    toastDispatch({
                        type: "removeToast",
                        uuid: "saveToast",
                    });
                }
                dispatch({
                    type: SAVING_DATA,
                    data: false,
                });
                if (rethrow) {
                    throw e;
                }
            });
    };

    const generateTabs = () => {
        const { userAsset } = userAssetData;

        let { asset } = userAsset;
        let tabs = [
            // {
            //     alias: "general",
            //     component: (
            //         <NamingAndGeneralSetup
            //             heading={
            //                 <HeaderWithErrorIndicated
            //                     title={trans("Overview")}
            //                     section="general"
            //                     sectionErrors={sectionErrors}
            //                 />
            //             }
            //             onTabClick={() => {
            //                 history.push(
            //                     `${assetsPath}/${userAsset.uuid}/edit`
            //                 );
            //             }}
            //             userAsset={userAsset}
            //             standardOnChange={standardOnChange}
            //             errors={errors}
            //         />
            //     )
            // }
        ];

        if (userAsset.editable) {
            tabs.push({
                alias: "editor",
                component: (
                    <Editor
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Editor")}
                                section="editor"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${userAsset.uuid}/edit/editor`
                            );
                        }}
                        assetAdmin={userAssetData}
                        saveUpdatedAsset={saveUpdatedAsset}
                        asset={userAsset}
                        baseAsset={asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={false}
                        editingUserAsset={true}
                        editRouteMatch={match}
                    />
                ),
            });
        }

        if (userAsset.microsite) {
            tabs.push({
                alias: "site-settings",
                component: (
                    <SiteSettingsEditor
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Settings")}
                                section="site-settings"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${userAsset.uuid}/edit/site-settings`
                            );
                        }}
                        assetAdmin={userAssetData}
                        asset={userAsset}
                        baseAsset={asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={false}
                    />
                ),
            });
            tabs.push({
                alias: "navigation",
                component: (
                    <NavigationEditor
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Navigation")}
                                section="navigation"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${userAsset.uuid}/edit/navigation`
                            );
                        }}
                        assetAdmin={userAssetData}
                        asset={userAsset}
                        baseAsset={asset}
                        standardOnChange={standardOnChange}
                    />
                ),
            });
        }

        return tabs;
    };

    if (!userAssetData.assetLoaded || loading) {
        return <Loading align="center" padding="1rem" />;
    }

    const tabs = generateTabs();
    //Work out what step we're on based on the path
    const tabIndex = match
        ? tabs.findIndex((tab) => tab.alias === match.params.step)
        : -1;
    const currentStep = tabIndex !== -1 ? tabIndex : 0;
    const { userAsset } = userAssetData;

    return (
        <React.Fragment>
            <PageTitle
                title={
                    !editingName && (
                        <React.Fragment>
                            {trans("Editing")}{" "}
                            <HeaderEmphasis>{userAsset.name}</HeaderEmphasis>
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setEditingName(true);
                                }}
                            />
                        </React.Fragment>
                    )
                }
            >
                {editingName && (
                    <HeaderEditWrapper>
                        <HeaderInput
                            name="name"
                            value={userAsset.name}
                            onChange={(event) => standardOnChange(event)}
                            placeholder={intl.formatMessage({
                                id: "Asset Name",
                            })}
                        />
                        <SubmitButton
                            style={{ margin: "0 1rem" }}
                            onClick={saveUpdatedAsset}
                            disabled={userAssetData.assetSaving}
                            submitting={userAssetData.assetSaving}
                        >
                            {trans("Save")}
                        </SubmitButton>
                    </HeaderEditWrapper>
                )}
            </PageTitle>

            <Row>
                <Column>
                    <Tabordion
                        current={currentStep}
                        unmounts={true}
                        alwaysShowTabs={false}
                        style={{ background: "none" }}
                    >
                        {tabs.map((tab) => tab.component)}
                    </Tabordion>
                </Column>
            </Row>

            <Panel
                style={
                    planner && planner.order
                        ? {
                              position: "fixed",
                              bottom: "0",
                              marginBottom: "0",
                              width: "100%",
                              background: "white",
                              zIndex: "1",
                          }
                        : { position: "sticky", bottom: 0, zIndex: "1" }
                }
            >
                <Row>
                    <Column
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        {userAssetData.assetEdited ||
                        !userAssetData.userAsset.files_ready ? (
                            <Button
                                as={Link}
                                to={`${assetsPath}/${assetUuid}`}
                                type={"alert"}
                                onClick={(e) =>
                                    confirmHappyToLeave(
                                        userAssetData.assetEdited,
                                        userAsset.files_ready,
                                        e
                                    )
                                }
                            >
                                {trans("Cancel")}
                            </Button>
                        ) : (
                            <>
                                {!planner ||
                                    (!planner.order && (
                                        <IconTextButton
                                            as={Link}
                                            to={`${assetsPath}/${assetUuid}`}
                                            rotation={180}
                                            showCircle={false}
                                            expanded={false}
                                        >
                                            {trans("Back to asset")}
                                        </IconTextButton>
                                    ))}
                            </>
                        )}
                        <SubmitButton
                            style={{ marginLeft: "1rem" }}
                            onClick={(event) =>
                                saveUpdatedAsset(event, false, true)
                                    .then((data) => {
                                        if (!data) {
                                            return false;
                                        }
                                        if (userAsset.order) {
                                            history.push(
                                                `/orders/${userAsset.order.uuid}/edit`
                                            );
                                        } else {
                                            history.push(
                                                `${assetsPath}/${assetUuid}`
                                            );
                                        }
                                    })
                                    .catch((e) => {})
                            }
                            disabled={userAssetData.assetSaving}
                            submitting={userAssetData.assetSaving}
                        >
                            {userAsset.order
                                ? trans("Next")
                                : trans("Save and Finish")}
                        </SubmitButton>
                        {userAssetData.userAsset.asset.microsite &&
                            !!Object.keys(
                                userAssetData.userAsset.asset.configuration
                                    .pages
                            ).length && (
                                <SubmitButton
                                    style={{
                                        float: "right",
                                        marginLeft: "1rem",
                                        marginRight: "1rem",
                                    }}
                                    onClick={(event) =>
                                        saveUpdatedAsset(event).then((data) => {
                                            const win = window.open(
                                                `/presentations/${assetUuid}/${
                                                    page ? page : ""
                                                }`,
                                                "_blank"
                                            );
                                            if (win) {
                                                win.focus();
                                            }
                                        })
                                    }
                                    disabled={userAssetData.assetSaving}
                                    submitting={userAssetData.assetSaving}
                                >
                                    {trans("Save and Preview")}
                                </SubmitButton>
                            )}
                    </Column>
                </Row>
            </Panel>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { planner: state.planner };
};

export default connect(mapStateToProps, null)(EditUserAsset);
