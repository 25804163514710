import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { calculateContentIndent } from "@cortexglobal/linear-calendar";

class TaskContent extends Component {
    render() {
        const { task, taskStartDate, widths, start } = this.props;

        //If the activity starts before the calendar its left is -1 micro unit
        //so we need to indent the content to compensate
        const indent = calculateContentIndent(taskStartDate, widths, start);
        return (
            <div
                style={{
                    // pointerEvents: "none",
                    textIndent: indent + widths.item / 4,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "100%",
                }}
            >
                &nbsp;
                <small>
                    {moment(task.start_date).format("ll")} -{" "}
                    {moment(task.end_date).format("ll")}
                </small>
            </div>
        );
    }
}

TaskContent.propTypes = {
    task: PropTypes.object,
};

export default TaskContent;
