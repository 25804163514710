import React from "react";
import _ from "lodash";
import {
    Row,
    Column,
    Panel,
    TableList,
    IconTextButton
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { faPen, faImage } from "@fortawesome/free-solid-svg-icons";

class GallerySearchResults extends React.Component {
    edit = uuid => {
        this.props.edit(uuid);
    };
    createNew = () => {
        this.props.createNew();
    };

    render() {
        let collection = this.props.results.data;
        return (
            <React.Fragment>
                <Panel
                    heading={
                        <Row>
                            <Column medium={10}>{trans("Results")}</Column>
                            <Column medium={2}>
                                <IconTextButton
                                    onClick={this.createNew.bind(this)}
                                    showCircle={false}
                                    expanded={false}
                                    icon={faImage}
                                >
                                    {trans("Create New Media")}
                                </IconTextButton>
                            </Column>
                        </Row>
                    }
                    padding={0}
                >
                    {!_.isEmpty(collection) && (
                        <TableList>
                            <thead>
                                <tr>
                                    <th />
                                    <th>{trans("Name")}</th>
                                    <th>{trans("Slug")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(collection).map(index => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <img
                                                    style={{
                                                        maxWidth: "50px",
                                                        maxhieght: "50px"
                                                    }}
                                                    src={
                                                        collection[index]
                                                            .thumbnail
                                                    }
                                                />
                                            </td>
                                            <td>
                                                {collection[index].name
                                                    ? collection[index].name
                                                    : "-"}
                                            </td>
                                            <td>
                                                {collection[index].slug
                                                    ? collection[index].slug
                                                    : "-"}
                                            </td>
                                            <td>
                                                <IconTextButton
                                                    onClick={this.edit.bind(
                                                        this,
                                                        collection[index]
                                                    )}
                                                    showCircle={false}
                                                    expanded={false}
                                                    icon={faPen}
                                                >
                                                    {trans("Edit")}
                                                </IconTextButton>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </TableList>
                    )}

                    {_.isEmpty(collection) && (
                        <Row>
                            <Column>
                                <p style={{ marginTop: "1.2em" }}>
                                    {trans(
                                        "Please enter a value to find a media"
                                    )}
                                </p>
                            </Column>
                        </Row>
                    )}
                </Panel>
            </React.Fragment>
        );
    }
}

export default GallerySearchResults;
