import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { useDropzone } from "react-dropzone";
import { trans } from "@cortexglobal/rla-intl";

// const SimpleDropzone = styled(Dropzone)`
//     background: ${props => dropzone(props).background};
//     margin-bottom: ${props => props.theme.dropzone.margin}em;
//     padding: ${props => props.theme.dropzone.padding}em;
//     border: ${props => props.theme.dropzone.border};
//     color: ${props => dropzone(props).color};
// `;

export const SimpleDropzone = ({ children, showFiles = false, ...rest }) => {
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            ...rest,
        });

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} {trans("bytes")}
        </li>
    ));

    return (
        <div {...getRootProps()} {...rest}>
            <input {...getInputProps()} />
            {children}
            {showFiles && (
                <div>
                    <ul>{files}</ul>
                </div>
            )}
            {/* {isDragActive ? (
                <p>Drop the files here ...</p>
            ) : (
                
            )} */}
        </div>
    );
};

export default SimpleDropzone;
