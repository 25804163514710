import React from "react";
import StarRatingComponent from "react-star-rating-component";

const StarRating = ({ onChange, ...rest }) => {
    const handleChange = (nextValue, prevValue, name, event) => {
        onChange(
            {
                name: name,
                value: nextValue
            },
            event
        );
    };
    return <StarRatingComponent onStarClick={handleChange} {...rest} />;
};

export default StarRating;
