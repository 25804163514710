import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import startOfMonth from "date-fns/startOfMonth";
import endOfYear from "date-fns/endOfYear";

//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import {
    IconTextButton,
    PageTitle,
    useAddToast,
    ///@ts-ignore cortex
} from "@cortexglobal/rla-components";
import FeaturedItemForm, { FeaturedItem } from "../../forms/FeaturedItemForm";

export const FeaturedItemCreate = ({ path }) => {
    const history = useHistory();
    const addToast = useAddToast();
    const startDate = startOfMonth(new Date());
    const endDate = endOfYear(new Date());
    const item: FeaturedItem = {
        dashboard_id: "",
        title: "",
        description: "",
        link: "",
        banner: "",
        background_color: "",
        order: 0,
        start_date: startDate,
        end_date: endDate,
    };

    const submitForm = (values) => {
        return axios
            .post(`/api/v1/admin/featured-item`, values)
            .then(({ data }) => {
                addToast({
                    content: trans("Featured Item created successfully"),
                    type: "success",
                    showFor: 3000,
                });
                //As creation was successful move them on to the listing page
                history.push(`${path}`);
            });
    };
    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("New Featured Item")}{" "}
                        {/* <HeaderEmphasis>{assetAdmin.asset.name}</HeaderEmphasis> */}
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            <FeaturedItemForm item={item} submitForm={submitForm} />
        </div>
    );
};

export default FeaturedItemCreate;
