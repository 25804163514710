import React, { useEffect } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";

import { useIntl } from "@cortexglobal/rla-intl";

import {
    updatePresentation,
    archiveAsset,
    deleteAsset,
    createAssetRevision,
    submitForApproval,
    approveAsset,
    rejectAsset,
    getFilters,
    clearFilters,
    getSearchableMeta,
    setRefreshSearch,
    addFavorite,
    removeFavorite,
    clearSearch,
    getAssetConfigOptions,
    getAssetAutofillFields,
} from "./assetActions";
import AssetContext from "./AssetContext";
import CreateAsset from "./screens/CreateAsset";
import CompareAsset from "./screens/CompareAsset";
import AdminAsset from "./screens/AdminAsset";
import EditAsset from "./screens/EditAsset";
import ViewAsset from "./screens/ViewAsset";
import OrderAsset from "./screens/OrderAsset";
import ListAssets from "./screens/ListAssets";
import Gallery from "../gallery/admin/GalleryAdmin";

const AssetRouting = ({
    config: config,
    userAssetPath,
    campaignsPath = "/admin/campaigns",
    match: { path },
    history,
    asset,
    filters,
    searchableMeta,
    user,
    group,
    groups,
    archiveAsset,
    deleteAsset,
    createAssetRevision,
    approveAsset,
    rejectAsset,
    submitForApproval,
    setRefreshSearch,
    microSiteComponents,
    addFavorite,
    removeFavorite,
    getAssetAutofillFields,
    autofill,
    refresh,
    role,
    getFilters,
    getSearchableMeta,
    getAssetConfigOptions,
    clearSearch,
    clearFilters,
    libraryArea = "assets-default",
    libraryTitle = "",
    includeAllLibraryAreas = false,
}) => {
    const intl = useIntl();

    useEffect(() => {
        getFilters(libraryArea);
        getSearchableMeta(libraryArea);
        getAssetConfigOptions();
        return () => {
            setRefreshSearch(true);
            clearSearch();
            clearFilters();
        };
    }, []);

    useEffect(() => {
        setRefreshSearch(true);
        clearSearch();
        clearFilters();
    }, [libraryArea]);

    const mainTitle =
        libraryTitle === ""
            ? intl.formatMessage({
                  id: "Asset Library",
              })
            : libraryTitle;

    const checkPermission = (alias) => {
        if (role && role.permissions) {
            let result = role.permissions.filter((p) => p.alias == alias);
            return result.length > 0;
        }
        return false;
    };

    if (!filters.loaded || !config.loaded) {
        return null;
    }
    return (
        <AssetContext.Provider
            value={{
                config: config.data,
                libraryArea,
                asset: asset,
                filters: filters,
                searchableMeta: searchableMeta,
                user: user,
                group: group,
                groups: groups,
                canEdit: checkPermission("dam-asset"),
                canAdmin: checkPermission("dam-asset-admin"),
                updatePresentation: updatePresentation,
                addFavorite: addFavorite,
                removeFavorite: removeFavorite,
                setRefreshSearch: setRefreshSearch,
                history: history,
                archiveAsset: archiveAsset,
                deleteAsset: deleteAsset,
                createAssetRevision: createAssetRevision,
                submitForApproval: submitForApproval,
                approveAsset: approveAsset,
                rejectAsset: rejectAsset,
                assetsPath: path,
                userAssetPath: userAssetPath ? userAssetPath : path,
                campaignsPath: campaignsPath,
                microSiteComponents: microSiteComponents,
                getAssetAutofillFields,
                autofill,
            }}
        >
            <Switch>
                <Route
                    exact
                    path={`${path}`}
                    render={() => {
                        return (
                            <ListAssets
                                config={config.data}
                                assetsPath={`${path}`}
                                setRefreshSearch={setRefreshSearch}
                                refresh={refresh}
                                userAssetPath={userAssetPath}
                                mainTitle={mainTitle}
                                groups={groups}
                                searchableMeta={searchableMeta}
                                libraryArea={libraryArea}
                                includeAllLibraryAreas={includeAllLibraryAreas}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${path}/manage-gallery`}
                    render={() => {
                        return <Gallery />;
                    }}
                />
                <Route
                    exact
                    path={`${path}/manage-gallery`}
                    render={() => {
                        return <Gallery />;
                    }}
                />
                <Route
                    exact
                    path={`${path}/create`}
                    render={() => {
                        return (
                            <CreateAsset
                                assetsPath={`${path}`}
                                history={history}
                                setRefreshSearch={setRefreshSearch}
                            />
                        );
                    }}
                />
                <Route
                    // exact
                    path={`${path}/:assetUuid/admin/:step?/:page?`}
                    render={(props) => {
                        const {
                            match: {
                                params: { assetUuid },
                            },
                        } = props;

                        return (
                            <AdminAsset
                                assetUuid={assetUuid}
                                assetsPath={`${path}`}
                                filters={filters}
                                searchableMeta={searchableMeta}
                                groups={groups}
                                setRefreshSearch={setRefreshSearch}
                                campaignsPath={campaignsPath}
                            />
                        );
                    }}
                />
                <Route
                    // exact
                    path={`${path}/:assetUuid/edit/:step?/:page?`}
                    render={(props) => {
                        const {
                            match: {
                                params: { assetUuid },
                            },
                        } = props;

                        return (
                            <EditAsset
                                assetUuid={assetUuid}
                                assetsPath={`${path}`}
                                filters={filters}
                                groups={groups}
                                setRefreshSearch={setRefreshSearch}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${path}/view/:assetUuid`}
                    render={(props) => {
                        const {
                            match: {
                                params: { assetUuid },
                            },
                        } = props;

                        return (
                            <ViewAsset
                                user={user}
                                groups={groups}
                                asset={asset}
                                assetUuid={assetUuid}
                                assetsPath={`${path}/`}
                                userAssetPath={userAssetPath}
                                campaignsPath={campaignsPath}
                                mainTitle={mainTitle}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${path}/order/:assetUuid`}
                    render={(props) => {
                        const {
                            match: {
                                params: { assetUuid },
                            },
                        } = props;

                        return (
                            <OrderAsset
                                user={user}
                                groups={groups}
                                asset={asset}
                                assetUuid={assetUuid}
                                assetsPath={`${path}/`}
                                userAssetPath={userAssetPath}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${path}/:assetUuid/compare/:versionUuid/`}
                    render={(props) => {
                        const {
                            match: {
                                params: { assetUuid, versionUuid },
                            },
                        } = props;

                        return (
                            <CompareAsset
                                history={history}
                                asset={asset}
                                assetUuid={assetUuid}
                                versionUuid={versionUuid}
                                assetsPath={`${path}/`}
                            />
                        );
                    }}
                />
            </Switch>
        </AssetContext.Provider>
    );
};

const mapStateToProps = (state) => {
    const {
        assets: {
            filters,
            searchableMeta,
            current: asset,
            config,
            autofill,
            collection: { refresh },
        },
        auth: { user, group, groups, role },
    } = state;

    return {
        asset,
        config,
        user,
        groups,
        group,
        role,
        filters,
        searchableMeta,
        autofill,
        refresh,
    };
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getFilters,
            clearFilters,
            updatePresentation,
            archiveAsset,
            deleteAsset,
            createAssetRevision,
            submitForApproval,
            approveAsset,
            rejectAsset,
            setRefreshSearch,
            clearSearch,
            getSearchableMeta,
            addFavorite,
            removeFavorite,
            getAssetConfigOptions,
            getAssetAutofillFields,
        },
        dispatch
    );
}
export default withRouter(
    compose(connect(mapStateToProps, mapDispatchToProps))(AssetRouting)
);
