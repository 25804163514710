import React, { Component } from "react";
import PropTypes from "prop-types";
import InputRange from "react-input-range";
import { rangePropType, valuePropType } from "./rangePropTypes";
//TODO - Work out the best way to override styling
import "react-input-range/lib/css/index.css";

class StyledRange extends Component {
    render() {
        const {
            label,
            name,
            value,
            onChange,
            minValue,
            maxValue,
            error,
            disabled,
            ...rest
        } = this.props;

        return (
            <InputRange
                value={value}
                onChange={onChange}
                minValue={minValue}
                maxValue={maxValue}
                disabled={disabled}
                {...rest}
            />
        );
    }
}

export { rangePropType };
export { valuePropType };

StyledRange.propTypes = {
    ariaLabelledby: PropTypes.string,
    ariaControls: PropTypes.string,
    classNames: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    draggableTrack: PropTypes.bool,
    formatLabel: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxValue: rangePropType,
    minValue: rangePropType,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onChangeStart: PropTypes.func,
    onChangeComplete: PropTypes.func,
    step: PropTypes.number,
    value: valuePropType,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

StyledRange.defaultProps = {
    error: "",
    disabled: false
};

export default StyledRange;
