import React from "react";
import styled from "styled-components";
import { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faCheckCircle,
    faTimesCircle,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import CloseButton from "../closeButton";
import { LoadingIconSmall } from "@cortexglobal/rla-components";

export const ToastContainer = styled.aside`
    display: flex;
    flex-direction: row;
    width: ${({ theme }) => theme.toast.width};
    max-width: 90vw;
    min-height: 5rem;
    background: ${({ theme }) => theme.toast.background};
    border: ${({ theme }) => theme.toast.borderColor};
    box-shadow: ${({ theme }) => theme.toast.boxShadow};
`;

export const ToastIconContainer = styled.figure`
    display: flex;
    margin: 0;
    width: 5rem;
    align-items: center;
    justify-content: center;
    background: ${({ theme, type }) => {
        return typeof theme.toast.types[type] === "undefined"
            ? theme.colors.gray300
            : theme.toast.types[type];
    }};
`;
export const ToastIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.toast.iconColor};
`;
export const ToastContent = styled.section`
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin: 0.8rem;
`;

const StyledCloseButton = styled(CloseButton)`
    position: static;
    display: flex;
    align-items: center;
`;

export const ToastIconType = ({ type }) => {
    let icon = faInfoCircle;
    switch (type) {
        case "warning":
            icon = faExclamationCircle;
            break;
        case "alert":
            icon = faTimesCircle;
            break;

        case "success":
            icon = faCheckCircle;
            break;
    }

    return (
        <ToastIconContainer type={type}>
            {type === "loader" ? (
                <LoadingIconSmall />
            ) : (
                <ToastIcon icon={icon} transform="grow-6" />
            )}
        </ToastIconContainer>
    );
};
export const Toast = ({ type, uuid, content, showFor, removeToast }) => {
    const removeThisToast = () => {
        removeToast(uuid);
    };
    useEffect(() => {
        if (typeof showFor !== "undefined" && !isNaN(showFor)) {
            setTimeout(removeThisToast, showFor);
            return clearTimeout(removeThisToast);
        }
    });
    return (
        <ToastContainer>
            <ToastIconType type={type} />
            <ToastContent>{content}</ToastContent>
            <StyledCloseButton onClick={removeThisToast} />
        </ToastContainer>
    );
};
