import React from "react";
import styled from "styled-components";
import { IconTextButton, Tooltip } from "@cortexglobal/rla-components";
import { faReply, faShare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const IconButton = styled(IconTextButton)`
    text-decoration: none;
    padding-right: ${({ verbose }) => (verbose ? "10px" : "0")};
`;
const MessageControls = ({ selectedMessage, verbose, ...props }) => {
    const { can } = usePermissions();

    return (
        <>
            {can("communication-send-messages") && selectedMessage.can_reply && (
                <Tooltip showIcon={false} text={trans("Reply")}>
                    <IconButton
                        style={{
                            background: selectedMessage.can_reply ? "" : "#eee",
                            color: selectedMessage.can_reply
                                ? "inherit"
                                : "#fff",
                        }}
                        icon={faReply}
                        showCircle={false}
                        expanded={false}
                        onClick={() => props.reply(selectedMessage)}
                        verbose
                    >
                        {verbose ? trans("Reply") : ""}
                    </IconButton>
                </Tooltip>
            )}

            {can("communication-send-messages") && selectedMessage.can_forward && (
                <Tooltip showIcon={false} text={trans("Forward")}>
                    <IconButton
                        style={{
                            background: selectedMessage.can_forward
                                ? ""
                                : "#eee",
                            color: selectedMessage.can_forward
                                ? "inherit"
                                : "#fff",
                        }}
                        icon={faShare}
                        showCircle={false}
                        expanded={false}
                        onClick={() => props.forward(selectedMessage)}
                        verbose
                    >
                        {verbose ? trans("Forward") : ""}
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip showIcon={false} text={trans("Delete")}>
                <IconButton
                    style={{}}
                    icon={faTrash}
                    showCircle={false}
                    expanded={false}
                    onClick={() => props.destroy(selectedMessage)}
                    verbose
                >
                    {verbose ? trans("Delete") : ""}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default MessageControls;
