import React from "react";
import PropTypes from "prop-types";
import styled, { css, useTheme } from "styled-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { selectionIsEmpty } from "@cortexglobal/cortex-utilities";
import { Button, Row, Column } from "../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconTextButton, useFilters } from "../../index";

const FilterLink = styled(IconTextButton)`
    color: ${({ theme }) => theme.filters.link.color};
    padding: ${({ singleColumn }) => (singleColumn ? "0.5rem 0" : "0.8em")};
`;

const SelectedFilters = styled.strong`
    font-weight: ${({ theme }) => theme.filters.link.fontWeight};
`;

const AppliedFilterTitle = styled.h5`
    margin: 1rem 1em 0 0;
    display: inline;
`;

const flattenOptions = (
    options,
    flattenedOptions = [],
    key = "children",
    parentText = ""
) => {
    options.forEach((option) => {
        //Add the parent text to the front of the option text
        const currentOptionLabel = option.originalText
            ? option.originalText
            : option.text;
        if (parentText === "") {
            option.parentText = currentOptionLabel;
        } else {
            option.parentText = `${parentText} > ${currentOptionLabel}`;
        }
        flattenedOptions.push(option);
        if (option[key] && Array.isArray(option[key])) {
            flattenOptions(
                option[key],
                flattenedOptions,
                key,
                option.parentText
            );
        }
    });
    return flattenedOptions;
};

const AppliedFilterRow = styled(Row)`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.filters.row.backgroundColor};
    color: ${({ theme }) => theme.filters.row.color};
    padding-right: 1rem;
`;

let FilterSummary = ({
    filters,
    selectedFilters: currentlySelectedFilters,
    onUpdate,
    onApply,
    onClear,
    separator,
    addLineBreaks,
    searchTerm,
    searchClear,
    singleColumn = false,
    addSeparator,
}) => {
    const theme = useTheme();

    const {
        selectedFilters,
        updateFilters,
        resetFilterGroup,
        clearFilters,
        filtersAreEmpty,
    } = useFilters({
        filters,
        currentlySelectedFilters,
        onUpdate,
        onApply,
        onClear,
    });
    const intl = useIntl();
    const { removeFiltersIcon, showIconOnButton, showLabelOnActiveFilters } =
        theme.filters;

    const selectedFilterArray = Object.keys(selectedFilters);

    /**
     * Used to render out the selected option(s)
     * @param {*} selectedFilter
     * @param {*} selectedFilterOption
     * @param {*} filterDetail
     * @param {*} addSeparator
     */
    const renderOptions = (
        selectedFilterOption,
        filterDetail,
        addSeparator
    ) => {
        if (Array.isArray(selectedFilterOption)) {
            //If there are more than 5 selected options we just show a summary
            //by "faking" a call back to this function (and use the key of the first option)
            if (selectedFilterOption.length > 5) {
                return renderOptions(
                    selectedFilterOption[0],
                    {
                        parentText: intl.formatMessage(
                            {
                                id: "{selectedFilterOptionLength} selected",
                                defaultMessage:
                                    "{selectedFilterOptionLength} selected",
                            },
                            {
                                selectedFilterOptionLength:
                                    selectedFilterOption.length,
                            }
                        ),
                    },
                    false
                );
            }

            return selectedFilterOption.map((selectedOption, index) => {
                const optionDetail = filterDetail.options.find(({ value }) => {
                    return value === selectedOption;
                });

                return renderOptions(
                    selectedOption,
                    optionDetail,
                    index + 1 < selectedFilterOption.length
                );
            });
        }

        return (
            <span
                key={selectedFilterOption}
                style={{
                    display: addLineBreaks ? "block" : "in-line",
                    marginTop: "0.6rem",
                    marginRight: addLineBreaks ? "1rem" : "0",
                }}
            >
                {filterDetail && filterDetail.parentText
                    ? filterDetail.parentText
                    : filterDetail && filterDetail.originalText
                    ? filterDetail.originalText
                    : filterDetail && filterDetail.text
                    ? filterDetail.text
                    : selectedFilterOption instanceof Date
                    ? selectedFilterOption.toLocaleDateString()
                    : selectedFilterOption}
                {addSeparator && separator}
            </span>
        );
    };

    if (filtersAreEmpty(selectedFilters, filters) && !searchTerm) {
        return null;
    }
    return (
        <AppliedFilterRow
            style={{
                flexDirection: singleColumn ? "column" : "row",
            }}
        >
            <Column
                style={{
                    display: "flex",
                    flexDirection: singleColumn ? "column" : "row",
                }}
                medium={singleColumn ? 12 : 10}
            >
                {selectedFilterArray.map((selectedFilter) => {
                    const selectedOptions = selectedFilters[selectedFilter];
                    let filterDetail = filters.filter(
                        (filter) => filter.name === selectedFilter
                    )[0];
                    //Check we've got filterDetail and if there's any options selected
                    //as we only want to show options that can be de-selected
                    if (filterDetail && !selectionIsEmpty(selectedOptions)) {
                        //If the filter type is a tree we need to flatten the options
                        if (
                            filterDetail.type === "CheckboxTree" ||
                            filterDetail.type === "LinkTree"
                        ) {
                            filterDetail = {
                                ...filterDetail,
                                options: flattenOptions([
                                    ...filterDetail.options,
                                ]),
                            };
                        }
                        return (
                            <FilterLink
                                height={28}
                                key={selectedFilter}
                                onClick={() => resetFilterGroup(selectedFilter)}
                                icon={removeFiltersIcon}
                                iconVerticalAlignment={"start"}
                                showCircle={false}
                                wrapContent={true}
                                singleColumn={singleColumn}
                                fontSize="0.8rem"
                                doHover={false}
                            >
                                {showLabelOnActiveFilters && (
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            display: "block",
                                        }}
                                    >
                                        {`${filterDetail.label}: `}
                                    </span>
                                )}
                                <SelectedFilters
                                    style={{
                                        flexWrap: "",
                                        marginRight: "0.5rem",
                                        textDecoration: "none",
                                        maxWidth: "100%",
                                    }}
                                >
                                    {renderOptions(
                                        selectedOptions,
                                        filterDetail
                                    )}
                                </SelectedFilters>
                            </FilterLink>
                        );
                    } else {
                        return null;
                    }
                })}
                {searchTerm && (
                    <FilterLink
                        height={28}
                        onClick={searchClear}
                        icon={removeFiltersIcon}
                        iconVerticalAlignment={"start"}
                        showCircle={false}
                        wrapContent={true}
                        singleColumn={singleColumn}
                    >
                        <span style={{ fontWeight: "normal" }}>
                            {trans("Search")}:{" "}
                        </span>
                        <SelectedFilters style={{ marginRight: "0.8rem" }}>
                            {searchTerm}
                        </SelectedFilters>
                    </FilterLink>
                )}
            </Column>
            {!singleColumn && (
                <Column
                    flex={true}
                    medium={2}
                    style={{ textAlign: "right", padding: "0.8em 0" }}
                >
                    <Button
                        type="clear"
                        height={28}
                        onClick={() => clearFilters()}
                        style={{ marginLeft: "auto" }}
                    >
                        {trans("Clear All")}
                        {showIconOnButton && (
                            <FontAwesomeIcon
                                icon={removeFiltersIcon}
                                style={{ marginLeft: "0.5em" }}
                            />
                        )}
                    </Button>
                </Column>
            )}
        </AppliedFilterRow>
    );
};

FilterSummary.displayName = "FilterSummary";

FilterSummary.propTypes = {
    /** Filters: Need to be of a certain shape, but this needs to be worked out.*/
    filters: PropTypes.array.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    separator: PropTypes.string,
    addLineBreaks: PropTypes.bool,
};

FilterSummary.defaultProps = {
    separator: ", ",
    addLineBreaks: true,
    addSeparator: false,
};

export default FilterSummary;
