import React, { useEffect, useState } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import InventoryItemForm from "./InventoryItemForm";
import {
    IconTextButton,
    PageTitle,
    Panel,
    Row,
    Loading,
} from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const EditInventoryItem = ({ path, uuid }) => {
    const [item, setItem] = useState({});
    const [loadingState, setLoadingState] = useState("loading");

    const getInventoryItem = (uuid) => {
        setLoadingState("loading");

        axios
            .get(`/api/v1/events/inventory/${uuid}`)
            .then(({ data }) => {
                const item = data.data;
                setItem(item);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });
    };

    useEffect(() => getInventoryItem(uuid), [uuid]);

    return (
        <>
            <PageTitle title="Edit Inventory Item">
                <IconTextButton
                    onClick={() => {}}
                    as={Link}
                    to={`${path}`}
                    showCircle={false}
                    icon={faChevronLeft}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Panel>
                    {loadingState === "loading" ? (
                        <Loading />
                    ) : (
                        <InventoryItemForm path={path} item={item} />
                    )}
                </Panel>
            </Row>
        </>
    );
};

export default EditInventoryItem;
