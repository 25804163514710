import React, { useState } from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Pagination,
    Button,
    Panel,
} from "@cortexglobal/rla-components";
import set from "lodash/set";
import get from "lodash/get";

import Gallery from "../../gallery/frontend/Gallery";

import NavInputGroup from "./components/NavInputGroup";
import EditorInputGroup from "./components/EditorInputGroup";
import AdminEditorInputGroup from "./components/AdminEditorInputGroup";
import AdminSettingInputGroup from "./components/AdminSettingInputGroup";

/**
 * Recursively render the input groups for the editor
 * This also builds up the path back to the element
 * @param {*} tag
 * @param {*} pageName
 * @param {*} onChange
 * @param {*} autofill
 * @param {*} path
 * @param {*} index
 */
const renderInputGroupRecursive = ({
    fullAdmin,
    tag,
    onChange,
    chooseImage,
    autofill,
    path = "",
    index = 0,
    asset,
}) => {
    //If the tag type is array we need to recursively setup the contents of it's value
    if (tag.children && tag.children.length > 0) {
        return tag.map((subTag, tagIndex) => {
            //console.log(path, index, tagIndex, subTag.name);
            return renderInputGroupRecursive({
                tag: subTag,
                onChange,
                chooseImage,
                autofill,
                path,
                index: tagIndex,
            });
        });
    }
    if (fullAdmin) {
        return (
            <AdminEditorInputGroup
                key={tag.name}
                path={path}
                tag={tag}
                onChange={onChange}
                chooseImage={chooseImage}
                autofill={autofill}
                fullAdmin={fullAdmin}
                asset={asset}
            />
        );
    }
    return (
        <React.Fragment key={tag.name}>
            <EditorInputGroup
                asset={asset}
                path={path}
                tag={tag}
                onChange={onChange}
                chooseImage={chooseImage}
                autofill={autofill}
            />
        </React.Fragment>
    );
};

/**
 * Recursively render the input groups for the editor
 * This also builds up the path back to the element
 * @param {*} tag
 * @param {*} pageName
 * @param {*} onChange
 * @param {*} autofill
 * @param {*} path
 * @param {*} index
 */
const renderSettingInputGroupRecursive = ({
    fullAdmin,
    tag,
    onChange,
    chooseImage,
    autofill,
    path = "",
    asset,
}) => {
    //If the tag type is array we need to recursively setup the contents of it's value
    if (tag.children && tag.children.length > 0) {
        return tag.map((subTag, tagIndex) => {
            //console.log(path, index, tagIndex, subTag.name);
            return renderSettingInputGroupRecursive({
                tag: subTag,
                onChange,
                chooseImage,
                autofill,
                path,
                index: tagIndex,
            });
        });
    }

    return (
        <AdminSettingInputGroup
            key={tag.name}
            path={path}
            tag={tag}
            onChange={onChange}
            chooseImage={chooseImage}
            autofill={autofill}
            asset={asset}
        />
    );
};

/**
 * Update the configuration of the specified property
 * This is different depending on from where the tags were created
 * The the current time (2020-03-19) the InDesign server puts all the pages at the top level
 * with the page elements directly underneath
 * Whereas the microsite/presentation editor puts the pages in a key of pages (as there's other top level info)
 * with the page elements in a 'content' key underneath (again there's other top level info)
 * @param {*} configuration
 * @param {*} page
 * @param {*} path
 * @param {*} property
 * @param {*} value
 */
const updateConfiguration = (configuration, path, property, value) => {
    // console.log(configuration, path, property, value);
    const currentPageElement = get(configuration, path);

    const updatedPageElement = {
        ...currentPageElement,
        [property]: value,
    };
    //console.log(currentPageElement, updatedPageElement);
    set(configuration, path, updatedPageElement);

    return configuration;
};

/**
 * Pass the updated configuration to the main asset change handler
 * @param {*} standardOnChange
 * @param {*} asset
 * @param {*} property
 * @param {*} event
 * @param {*} path
 */
const onChange = (standardOnChange, asset, property, event, path) => {
    // console.log(standardOnChange, asset, property, event, path);
    let { name, value } = event;
    if (property === "internal") {
        name = event.target.name;
        value = event.target.checked ? true : false;
    }
    //Update the item in the configuration as it's all submitted
    let { configuration, extracted_tags } = asset;

    //If there's currently no configuration copy the original extracted tags in there
    if (!configuration) {
        configuration = extracted_tags;
    }

    standardOnChange({
        name: "configuration",
        value: updateConfiguration(configuration, path, property, value),
    });
};

const SectionHeading = styled.h3`
    margin-top: 1em;
`;

const LinkSettings = ({
    asset,
    standardOnChange,
    fullAdmin,
    theming = false,
}) => {
    const assetTypeSlug =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset.asset_type.slug;
    if (assetTypeSlug !== "link") {
        return null;
    }
    const { configuration } = asset;
    const currentConfiguration = Object.entries(configuration);
    return (
        <React.Fragment>
            <SectionHeading>{trans("Link Settings")}</SectionHeading>
            <Panel>
                <Row>
                    <Column>
                        {currentConfiguration.length > 0 ? (
                            currentConfiguration.map(
                                ([name, tag], elementIndex) => {
                                    // console.log(tag);
                                    //Add the code to check if the tag is an array, and do the looping,
                                    //moving the below out into it's own component would be good
                                    return renderSettingInputGroupRecursive({
                                        tag,
                                        onChange: (value, path, event) => {
                                            onChange(
                                                standardOnChange,
                                                asset,
                                                value,
                                                event,
                                                path
                                            );
                                        },
                                        path: name,
                                        index: `${elementIndex}`,
                                        fullAdmin,
                                        asset,
                                    });
                                }
                            )
                        ) : (
                            <span>{trans("No editable fields")}</span>
                        )}
                    </Column>
                </Row>
            </Panel>
        </React.Fragment>
    );
};

export default LinkSettings;
