import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { NavLink } from "react-router-dom";
import { Collapse } from "react-collapse";

import {
    MainNavWrapper,
    Row,
    Column,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { trans, useIntl } from "@cortexglobal/rla-intl";

import { Routes } from "../hooks/useAdminRoutes";

const BaseNavLink = styled(NavLink).attrs({
    exact: true,
    activeClassName: "active",
})`
    display: flex;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const AdminNavLink = styled(BaseNavLink)`
    &.active,
    :hover {
        color: ${(props) => props.theme.navigation.admin.hoverColor};
        background: ${(props) => props.theme.navigation.admin.hoverBackground};
    }
`;
const SubNavLink = styled(BaseNavLink)`
    display: flex;
    /* padding-left: 2rem; */
    &.active,
    :hover {
        color: ${(props) => props.theme.navigation.admin.hoverColor};
        background: ${(props) => props.theme.navigation.admin.hoverBackground};
    }
`;

const AdminNav = ({ routes }: AdminNavProps) => {
    const getExpandedRouteKey = (): string => {
        const nav = Object.entries(routes);
        let selectedKey = "";
        nav.filter(([navItemKey, navItem]) => {
            return navItem.length > 1;
        }).forEach(([navItemKey, navItem]) => {
            navItem.map((child) => {
                if (`/admin${child.path}` === window.location.pathname) {
                    selectedKey = navItemKey;
                }
            });
        });
        return selectedKey;
    };
    const [expandedKey, setExpandedKey] = useState("");

    const setExpanded = (item) => {
        if (expandedKey === item || !item) {
            setExpandedKey(null);
            return;
        }

        setExpandedKey(item);
    };

    const theme = useTheme();
    const intl = useIntl();

    useEffect(() => {
        //If the routes change we need to check if we need to expand any of the sub menus
        setExpandedKey(getExpandedRouteKey());
    }, [routes]);

    const nav = Object.entries(routes);

    return (
        ///@ts-ignore cortex
        <div
            style={{
                ///@ts-ignore cortex
                background: theme.navigation.admin.background,
            }}
        >
            <Row collapse>
                <Column
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    collapse
                >
                    <AdminNavLink
                        onClick={() => {
                            setExpanded(null);
                        }}
                        to={`/admin/`}
                        // end
                        className="homeActive"
                    >
                        {trans("Admin Home")}
                    </AdminNavLink>
                    {nav.map(([navItemKey, navItem]) => {
                        //If there's no nav item, don't render anything, this should never happen
                        if (navItem.length === 0) return null;
                        if (navItem.length === 1) {
                            return (
                                <AdminNavLink
                                    onClick={() => {
                                        setExpanded(null);
                                    }}
                                    key={navItemKey}
                                    to={`/admin${navItem[0].path}`}
                                >
                                    {navItem[0].name}
                                </AdminNavLink>
                            );
                        }

                        const translatedName = intl.formatMessage({
                            id: navItemKey,
                        });

                        return (
                            <React.Fragment key={navItemKey}>
                                <AdminNavLink
                                    theme={theme}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setExpanded(navItemKey);
                                    }}
                                    to={``}
                                >
                                    {translatedName}
                                </AdminNavLink>

                                <Collapse isOpened={expandedKey === navItemKey}>
                                    <div
                                        style={{
                                            background: "rgba(0,0,0,0.1)",
                                            boxShadow:
                                                "inset 0px 2px 5px rgba(0,0,0,0.15)",
                                        }}
                                    >
                                        {navItem.map((child) => {
                                            return (
                                                <SubNavLink
                                                    theme={theme}
                                                    key={child.name}
                                                    to={`/admin${child.path}`}
                                                >
                                                    {child.name}
                                                </SubNavLink>
                                            );
                                        })}
                                    </div>
                                </Collapse>
                            </React.Fragment>
                        );
                    })}
                </Column>
            </Row>
        </div>
    );
};

interface AdminNavProps {
    routes: Routes;
}

export default AdminNav;
