import { useReducer, useEffect } from "react";

/**
 * useFilters encapsulates all the logic to manage filters
 * @param {object} setup An object container the state and callback functions to be used in this hook
 * @returns
 */
let useFilters = ({
    filters,
    currentlySelectedFilters,
    onUpdate,
    onApply,
    onClear,
}) => {
    const [selectedFilters, setSelectedFilters] = useReducer(
        (selectedFilters, newSelectedFilters) => ({
            ...selectedFilters,
            ...newSelectedFilters,
        }),
        currentlySelectedFilters
    );

    useEffect(() => {
        // console.log("currentlySelectedFilters changed", currentlySelectedFilters);
        setSelectedFilters({ ...currentlySelectedFilters });
    }, [currentlySelectedFilters]);

    /**
     * Update the selected filters based upon the input passed in
     *
     * @param {object} input object containing a name and value pair
     */
    const updateFilters = ({ name, value }) => {
        if (!Array.isArray(value)) {
            value = [value];
        }
        setSelectedFilters({ [name]: value });
        const tempState = { ...selectedFilters, [name]: value };
        //Update the local selected filter state and pass the updated state
        //to the onUpdate callback
        onUpdate(tempState);
    };

    /**
     * Resets a whole section of the filters
     * @param {string} filterKey
     * @param {boolean} callApply
     */
    const resetFilterGroup = (
        filterKey,
        callUpdate = true,
        callApply = true
    ) => {
        // const filterToClear = filters[filterKey];
        const filterToClear = filters.filter(
            (filter) => filter.name === filterKey
        )[0];
        if (!filterToClear) {
            return;
        }
        const resetValue =
            filterToClear && filterToClear.defaultValue
                ? filterToClear.defaultValue
                : null;

        //As state is updated async we're also creating a temp state to
        //pass to the callback functions
        setSelectedFilters({ [filterKey]: resetValue });
        const tempState = { ...selectedFilters, [filterKey]: resetValue };

        if (callUpdate) {
            onUpdate(tempState);
        }
        if (callApply) {
            onApply(tempState);
        }
        return resetValue;
    };

    const clearFilters = () => {
        //Update the local selected filter state to be empty
        // and build up the temp state by calling resetFilterGroup
        // on each of the currentlySelectedFilters
        const tempState = Object.keys(selectedFilters).reduce(
            (tempState, selectedFilter) => {
                const resetValue = resetFilterGroup(
                    selectedFilter,
                    false,
                    false
                );
                return { ...tempState, [selectedFilter]: resetValue };
            },
            {}
        );
        setSelectedFilters({ ...selectedFilters, ...tempState });
        //update and apply it
        onUpdate(tempState);
        onClear(tempState);
    };
    const applyFilters = (filterToAdd = null) => {
        //If the filterToAdd is not empty merge it in with the current state
        // console.log("applyFilters", filterToAdd, state);
        let tempState = { ...selectedFilters };
        if (filterToAdd !== null) {
            const { name, value } = filterToAdd;
            tempState = { ...selectedFilters, [name]: [value] };
            setSelectedFilters(tempState);
        }
        //Pass the current selected filter state the the onApply callback
        onApply(tempState);
    };

    /**
     * Checks to see if the filters are empty
     * @param {object} selectedFilters
     */
    const filtersAreEmpty = (selectedFilters, filters) => {
        let emptyFilters = true;
        const selectedFilterArray = Object.keys(selectedFilters);
        const availableFilterAliases = filters.reduce(
            (filterAliases, filter) => {
                return [...filterAliases, filter.name];
            },
            []
        );
        // console.log(selectedFilterArray, filters);
        //If the selectedFilterArray is empty there are no filters so no need to update
        if (selectedFilterArray.length > 0) {
            for (
                let i = 0;
                i < selectedFilterArray.length && emptyFilters;
                i++
            ) {
                //If the selected filter alias is in the array of selectable filters
                const isSelectableFilter = availableFilterAliases.includes(
                    selectedFilterArray[i]
                );
                const selectedOptions = selectedFilters[selectedFilterArray[i]];
                //If the selected filter isn't one of the filters that can be selected, or it's set to null
                //it can be ignored
                if (selectedOptions === null || !isSelectableFilter) {
                    continue;
                }

                if (Array.isArray(selectedOptions) && selectedOptions > 0) {
                    emptyFilters = false;
                } else if (selectedOptions > 0) {
                    emptyFilters = false;
                } else if (selectedOptions.length > 0) {
                    emptyFilters = false;
                }
            }
        }

        return emptyFilters;
    };

    /**
     * Checks to see if the filters are empty
     * @param {object} selectedFilters
     */
    const countFilters = (selectedFilters, filters) => {
        let filterCount = 0;
        const selectedFilterArray = Object.keys(selectedFilters);
        const availableFilterAliases = filters.reduce(
            (filterAliases, filter) => {
                return [...filterAliases, filter.name];
            },
            []
        );
        // console.log(selectedFilterArray, filters);
        //If the selectedFilterArray is empty there are no filters so no need to update
        if (selectedFilterArray.length > 0) {
            for (let i = 0; i < selectedFilterArray.length; i++) {
                //If the selected filter alias is in the array of selectable filters
                const isSelectableFilter = availableFilterAliases.includes(
                    selectedFilterArray[i]
                );
                const selectedOptions = selectedFilters[selectedFilterArray[i]];
                //If the selected filter isn't one of the filters that can be selected, or it's set to null
                //it can be ignored
                if (selectedOptions === null || !isSelectableFilter) {
                    continue;
                }

                //If the selectedOptions is an array add the number selected in that array, otherwise
                //just add one on (e.g. it must be a search or some form of single select)
                if (Array.isArray(selectedOptions)) {
                    filterCount += selectedOptions.length;
                } else {
                    filterCount += 1;
                }
            }
        }

        return filterCount;
    };

    return {
        selectedFilters,
        updateFilters,
        resetFilterGroup,
        clearFilters,
        applyFilters,
        filtersAreEmpty,
        countFilters,
    };
};
export default useFilters;
