import React from "react";
import { Button } from "../index";
const ELEMENT_ID = "jira-feedback-button";
const WINDOW_VAR_NAME = "jiraIssueCollector";
window["ATL_JQ_PAGE_PROPS"] = {
    triggerFunction: function (showCollectorDialog) {
        document
            .getElementById(ELEMENT_ID)
            .addEventListener("click", function (e) {
                e.preventDefault();
                showCollectorDialog();
            });
    },
};

export const JiraIssueCollector = ({ src, children }) => {
    const setCollector = () => {
        const appElement = document.querySelector("body");
        if (appElement) {
            console.log("loading issue collector");
            //Only add the script if it doesn't already exist
            if (document.getElementById("jira-feedback-script")) {
                return;
            }
            console.log("adding issue collector script");

            const snippet = document.createElement("script");
            snippet.id = "jira-feedback-script";
            snippet.type = "text/javascript";
            snippet.src = src;
            appElement.appendChild(snippet);
        }
    };

    if (!window[WINDOW_VAR_NAME]) {
        setCollector();
        window[WINDOW_VAR_NAME] = this;
    }

    return (
        <Button href="#" color="primary" id={ELEMENT_ID}>
            {children}
        </Button>
    );
};
