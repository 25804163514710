import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loading, Centred } from "@cortexglobal/rla-components";

import { loadPanel } from "../../panelActions";
import PanelContentContainer from "./PanelContentContainer";

/**
 * A HOC to inject data into a dashboard panel
 * @param {*} WrappedComponent - The dashboard panel component that requires the data
 * @param {*} url - The URL to get the data from
 * @param {*} propsToPass - An array of keys from the configured props to pass as parameters to the URL
 */
const withPanelData = (WrappedComponent, url = "", propsToPass = []) => {
    class BasePanel extends Component {
        componentDidMount() {
            this.loadPanelData();
        }

        componentDidUpdate(prevProps) {
            //console.log("Current props", this.props, "Next Props", prevProps);
            if (
                (prevProps.panelData && !prevProps.panelData.loaded) ||
                (this.props.panelData && !this.props.panelData.loaded)
            ) {
                this.loadPanelData();
            }
        }

        loadPanelData = () => {
            const parameters = propsToPass.reduce((carry, propName) => {
                return { ...carry, [propName]: this.props[propName] };
            }, {});
            this.props.loadPanel(this.props.panelId, url, parameters);
        };

        componentWillUnmount() {}

        render() {
            const { panelData, loadPanel, ...rest } = this.props;
            if (!panelData || !panelData.loaded) {
                return (
                    <PanelContentContainer>
                        <Centred>
                            <Loading
                                type="spin"
                                color="rgba(255,255,225,0.2)"
                                align="center"
                                height="auto"
                            />
                        </Centred>
                    </PanelContentContainer>
                );
            }

            return <WrappedComponent data={panelData.data} {...rest} />;
        }
    }
    const mapStateToProps = (state, ownProps) => {
        return { panelData: state.panels[ownProps.panelId] };
    };

    const mapDispatchToProps = dispatch =>
        bindActionCreators({ loadPanel }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(BasePanel);
};

export default withPanelData;
