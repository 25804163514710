import PropTypes from "prop-types";

export const CategoryShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    parent_id: PropTypes.number.isRequired
});
export const TaskShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    primary_category_id: PropTypes.number.isRequired,
    primary_planner_category_id: PropTypes.number.isRequired
});
