import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { withRouter, Redirect, Link, useHistory } from "react-router-dom";
import { format } from "@cortexglobal/cortex-utilities";
import { OrderSummary } from "@cortexglobal/cortex-dam";
import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Loading,
    PageTitle,
    UserContactDetails,
    GroupContactDetails,
    DetailPanelItem,
    useAddToast,
    Badge,
    Tabordion,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { Comments } from "@cortexglobal/comments";
import { Files } from "@cortexglobal/files";
import { TransitionButtons, Audit } from "@cortexglobal/workflow";
import RequestDetails from "../components/RequestDetails";
import RequestForm from "../forms/RequestForm";
import ViewOption from "../components/ViewOption";
import {
    PlanDetails,
    // TaskView,
    // ActivityView,
} from "@cortexglobal/marketing-planner";
import { EventSummaryDetails } from "@cortexglobal/events";

const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: column;
`;

const ExpandedPanel = styled(Panel)`
    flex: 1;
`;

const Request = ({ requestsPath, user, requestUuid, match, filters }) => {
    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        loaded: false,
        notFound: false,
        request: null,
        editing: false,
        deleting: false,
        deleted: false,
    });

    const setLoading = (loading) => {
        setState({
            ...state,
            loading: !!loading,
            loaded: !loading,
        });
    };

    const setRequest = (request) => {
        setState({
            ...state,
            request: request,
        });
    };

    const addToast = useAddToast();

    const { request, notFound, deleted, deleting, loaded } = state;

    useEffect(() => {
        getRequest();
    }, []);

    const getRequest = () => {
        const requestUuid = !requestUuid
            ? match.params.request_uuid
            : requestUuid;

        axios
            .get(`api/v1/requests/${requestUuid}`)
            .then((result) => {
                return setState({
                    ...state,
                    request: result.data.data,
                    loaded: true,
                    loading: false,
                });
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    setState({
                        ...state,
                        notFound: true,
                        loading: false,
                    });

                    addToast({
                        type: "info",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                }
            });
    };

    const deleteRequest = () => {
        const requestUuid = !requestUuid
            ? match.params.request_uuid
            : requestUuid;

        if (requestUuid !== undefined) {
            axios
                .delete(`api/v1/requests/${requestUuid}`)
                .then((result) => {
                    window.location.href = "/requests";
                })
                .catch((e) => {
                    if (e.response && e.response.status === 404) {
                        setState({
                            ...state,
                            notFound: true,
                            loading: false,
                        });
                    }
                });
        }
    };

    const workflowUpdatedCallback = (smartRef, newWorkflow) => {
        // setState({
        //     ...state,
        //     request: { ...state.request, workflow: { ...newWorkflow } },
        // });
        getRequest();

        // short term fix to alleviate 404 problem where request moves to another user/group
        // window.location.href = "/requests";
        // history.push("/requests");
    };

    const renderDetailsOrEdit = () => {
        const { request, loading, loaded, editing } = state;

        if (loading) {
            return <Loading />;
        }

        if (editing) {
            return (
                <Row expanded>
                    <RequestForm
                        setLoading={setLoading}
                        setRequest={setRequest}
                        onGoBack={stopEditing}
                        request={request}
                        selectedRequestType={request.type}
                        action="editRequest"
                    />
                </Row>
            );
        } else {
            //Only render if we have information to show
            if (!request) {
                return null;
            }

            if (
                !request.description &&
                !request.custom_form_field_answers?.length &&
                !request.quotation
            ) {
                return null;
            }

            return (
                <>
                    <h3>{trans("Request Details")}</h3>

                    <ExpandedPanel>
                        <RequestDetails
                            filters={filters}
                            request={request}
                            user={user}
                        />
                    </ExpandedPanel>
                </>
            );
        }
    };

    const renderRequestableDetails = () => {
        const { request, loading, loaded, editing } = state;

        if (loaded === true && request) {
            switch (request?.type?.alias) {
                case "events":
                    return (
                        <Panel>
                            <EventSummaryDetails
                                eventUuid={request?.relation_uuid}
                                showingInSummary={true}
                            />
                        </Panel>
                    );

                case "asset-order":
                    return (
                        <Panel>
                            <OrderSummary uuid={request?.relation_uuid} />
                        </Panel>
                    );

                case "marketing_plan_approval":
                case "marketing_plan_results_approval":
                case "marketing-plan-approval":
                    if (!request?.relation_uuid) {
                        return (
                            <Panel>
                                <Row>
                                    <Column>
                                        <h3>
                                            {trans(
                                                "Campaign details not found"
                                            )}
                                        </h3>
                                    </Column>
                                </Row>
                            </Panel>
                        );
                    }
                    return (
                        <Panel>
                            <Row>
                                <Column>
                                    <h3 style={{ marginBottom: "1.5rem" }}>
                                        {trans("Plan Summary")}
                                    </h3>
                                    <PlanDetails
                                        planUuid={request.relation_uuid}
                                        user={user}
                                    />
                                </Column>
                            </Row>
                        </Panel>
                    );

                // THIS CODE IS NOT USED AND THE TaskView AND ActivityView COMPONENTS ARE
                // NO LONGER EXPORTED FROM THE MARKETING-PLANNER PACKAGE
                // case "marketing_plan_funding":
                // case "marketing-plan-funding":
                //     return (
                //         <Panel>
                //             <Row>
                //                 <Column>
                //                     <h3>{trans("Campaign")}</h3>
                //                     <TaskView
                //                         taskUuid={request.relation_uuid}
                //                         user={user}
                //                     />
                //                 </Column>
                //             </Row>
                //         </Panel>
                //     );

                // case "marketing_plan_funding_payment":
                // case "marketing-plan-funding-payment":
                //     return (
                //         <Panel>
                //             <Row>
                //                 <Column>
                //                     <ActivityView
                //                         activityUuid={request.relation_uuid}
                //                         user={user}
                //                     />
                //                 </Column>
                //             </Row>
                //         </Panel>
                //     );
            }
        }

        return null;
    };

    const stopEditing = (request) => {
        getRequest();

        let newState = state;

        if (request) {
            newState["request"] = request;
        }

        newState["editing"] = false;

        setState({ ...state, ...newState });
    };

    if (deleted || notFound) {
        return <Redirect to={`${requestsPath}`} />;
    }

    return (
        <>
            <PageTitle
                title={
                    request ? (
                        <>
                            <Badge
                                style={{ marginRight: "0.5rem" }}
                                type={"warning"}
                            >
                                {request.ref}
                            </Badge>{" "}
                            {request.title || trans("Request")}
                        </>
                    ) : (
                        "Request"
                    )
                }
            >
                <IconTextButton
                    as={Link}
                    to={requestsPath}
                    rotation={180}
                    showCircle={false}
                >
                    {trans("Back to Requests")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <FlexColumn large={9}>
                    {renderDetailsOrEdit()}

                    {loaded && renderRequestableDetails()}

                    {request && user && (
                        <>
                            {request?.type?.allow_file_uploads && (
                                <Files
                                    smartRef={request.smartRef}
                                    user={user}
                                    showHeading={true}
                                    heading={<span>{trans("Files")}</span>}
                                />
                            )}
                            <Tabordion current={0}>
                                {request?.type?.allow_comments && (
                                    <Comments
                                        heading={trans("Comments")}
                                        smartRef={request.smartRef}
                                        user={user}
                                    />
                                )}
                                <Audit
                                    heading={trans("Action History")}
                                    smartRef={request.smartRef}
                                    status={request.workflow?.status}
                                    showHeading={false}
                                />
                            </Tabordion>
                        </>
                    )}
                </FlexColumn>
                <FlexColumn large={3}>
                    <h3>{trans("Available Actions")}</h3>
                    <ExpandedPanel>
                        <Row>
                            <Column collapse>
                                {loaded && (
                                    <>
                                        <TransitionButtons
                                            item={request}
                                            callback={workflowUpdatedCallback}
                                            errorCallback={(
                                                smartRef,
                                                error
                                            ) => {
                                                addToast({
                                                    type: "alert",
                                                    content:
                                                        error.response.data
                                                            .message,
                                                    showFor: 5000,
                                                });
                                            }}
                                        >
                                            {request?.workflow?.state
                                                ?.allow_edits && (
                                                <IconTextButton
                                                    // style={{ paddingLeft: 0 }}
                                                    expanded
                                                    onClick={() =>
                                                        setState({
                                                            ...state,
                                                            editing: true,
                                                        })
                                                    }
                                                >
                                                    {trans("Edit Request")}
                                                </IconTextButton>
                                            )}
                                        </TransitionButtons>
                                        <ViewOption request={request} />
                                    </>
                                )}
                            </Column>
                        </Row>
                    </ExpandedPanel>

                    <h3>{trans("Current Request Status")}</h3>
                    <ExpandedPanel>
                        <Row>
                            <Column>
                                {loaded && request.workflow && (
                                    <>
                                        <DetailPanelItem>
                                            <strong>{trans("Status")}</strong>{" "}
                                            {request.workflow.status}
                                        </DetailPanelItem>
                                        <DetailPanelItem>
                                            <strong>{trans("Category")}</strong>{" "}
                                            {request.type?.category?.text ||
                                                "-"}
                                        </DetailPanelItem>
                                        <DetailPanelItem>
                                            <strong>
                                                {trans("Request Type")}
                                            </strong>{" "}
                                            {request.type?.name || trans("-")}
                                        </DetailPanelItem>
                                        <DetailPanelItem>
                                            <strong>
                                                {trans("Assigned To")}
                                            </strong>{" "}
                                            {request.workflow
                                                .assigned_to_user ||
                                                request.workflow
                                                    .assigned_to_role ||
                                                request.workflow
                                                    .assigned_to_team ||
                                                trans("Anyone")}
                                            {request.workflow
                                                .assigned_to_group &&
                                                ", " +
                                                    request.workflow
                                                        .assigned_to_group}
                                        </DetailPanelItem>
                                        <DetailPanelItem>
                                            <strong>
                                                {trans("Last Updated")}
                                            </strong>{" "}
                                            {format(
                                                new Date(request.updated),
                                                "PP"
                                            )}
                                        </DetailPanelItem>
                                    </>
                                )}
                            </Column>
                        </Row>
                    </ExpandedPanel>
                    <h3>{trans("Contact Details")}</h3>
                    <Panel>
                        <Row>
                            <Column>
                                {request && (
                                    <UserContactDetails user={request.user} />
                                )}
                            </Column>
                        </Row>
                    </Panel>
                    {request && request.group && request.group.contact && (
                        <>
                            <h3>{trans("Dealer Details")}</h3>
                            <Panel>
                                <Row>
                                    <Column>
                                        <GroupContactDetails
                                            contact={request.group.contact}
                                        />
                                    </Column>
                                </Row>
                            </Panel>
                        </>
                    )}
                </FlexColumn>
            </Row>
        </>
    );
};

export default withRouter(Request);
