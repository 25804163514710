import styled from "styled-components";

export const SingleFigure = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 3rem;
`;

export default SingleFigure;
