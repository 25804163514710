import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    TextareaField,
    SelectField,
    InputField,
    Row,
    Column,
    SubmitButton,
    DatePicker,
    FormRow,
} from "@cortexglobal/rla-components";
import { get, isEmpty } from "lodash";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import moment from "moment";

const InventoryItemBookingForm = ({ item, successCallback = () => {} }) => {
    const [loadingState, setLoadingState] = useState("loaded");
    const [formValues, setFormValues] = useState({});
    const intl = useIntl();

    useEffect(() => {
        loadInitialValues();
    }, []);

    const loadInitialValues = () => {
        setFormValues(item);
    };

    const onChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value, updated: true });
    };

    const bookingTypeOptions = () => {
        return [
            {
                text: intl.formatMessage({ id: "Service" }),
                value: "service",
            },
            { text: intl.formatMessage({ id: "Transit" }), value: "transit" },
            { text: intl.formatMessage({ id: "Event" }), value: "event" },
            { text: intl.formatMessage({ id: "Other" }), value: "other" },
        ];
    };

    const onSubmit = (values) => {
        setLoadingState("loading");

        axios
            .post(`/api/v1/events/inventory/bookings/${item.uuid}`, values)
            .then(({ data }) => {
                const newBooking = data.data;
                setLoadingState("loaded");
                successCallback(newBooking);
            })
            .catch((e) => {
                setLoadingState("error");
            });

        return;
    };

    return (
        <>
            <Row>
                <Column medium={12}>
                    <FormRow name="start_date" label={trans("From date")}>
                        <DatePicker
                            name="start_date"
                            onChange={(e) => onChange(e.name, e.value)}
                            value={get(formValues, "start_date", "")}
                            minDate={moment().add(1, "day")}
                        />
                    </FormRow>

                    <FormRow name="end_date" label={trans("To date")}>
                        <DatePicker
                            name="end_date"
                            onChange={(e) => onChange(e.name, e.value)}
                            value={get(formValues, "end_date", "")}
                            minDate={moment().add(1, "day")}
                        />
                    </FormRow>

                    <FormRow name="booking_type" label={trans("Booking Type")}>
                        <SelectField
                            height={38}
                            name="booking_type"
                            onChange={(e) => onChange(e.name, e.value)}
                            options={bookingTypeOptions()}
                            value={get(formValues, "booking_type", "")}
                        />
                    </FormRow>

                    {formValues.booking_type === "event" && (
                        <FormRow name="event_name" label={trans("Event Name")}>
                            <InputField
                                name="event_name"
                                onChange={(e) => onChange(e.name, e.value)}
                                value={get(formValues, "event_name", "")}
                            />
                        </FormRow>
                    )}

                    <FormRow name="comments" label={trans("Comments")}>
                        <TextareaField
                            name="comments"
                            onChange={(e) => onChange(e.name, e.value)}
                            value={get(formValues, "comments", "")}
                        />
                    </FormRow>
                </Column>
            </Row>

            <Row>
                <Column>
                    <SubmitButton
                        submitting={loadingState === "loading"}
                        label="Create Booking"
                        onClick={() => onSubmit(formValues)}
                    />
                </Column>
            </Row>
        </>
    );
};

export default InventoryItemBookingForm;
