import React from "react";
import axios from "axios";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    useAddToast,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import LinkForm, { Link } from "../../forms/LinkForm";

export const LinkCreate = ({ path }) => {
    const history = useHistory();
    const addToast = useAddToast();

    const item: Link = {
        id: null,
        name: "",
        url: "",
        description: "",
        color: null,
        thumbnail: "",
    };

    const submitForm = (values) => {
        return axios.post(`/api/v1/admin/link`, values).then(({ data }) => {
            addToast({
                type: "success",
                content: trans("Link Created"),
                showFor: 5000,
            });
            // console.log(data);
            //As creation was successful move them on to the appropriate listing page
            history.push(`${path}`);
        });
    };
    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("New Link")}{" "}
                        {/* <HeaderEmphasis>{assetAdmin.asset.name}</HeaderEmphasis> */}
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={ReactRouterLink}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <LinkForm item={item} submitForm={submitForm} />
                </Panel>
            </Row>
        </div>
    );
};

export default LinkCreate;
