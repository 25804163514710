import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Loading,
    InputField,
    Button,
    Tooltip,
    StyledCheckbox,
    FormLabel,
    Image,
    useAddToast,
    SaveButton,
    PlainButton,
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckSquare,
    faSquare,
    faCheck,
} from "@fortawesome/free-regular-svg-icons";

const StickyFooter = styled(Row)`
    position: sticky;
    bottom: 0;
    background: ${(props) => props.theme.modal.background};
    padding: 1rem;
`;

const AssetRow = styled.section`
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
`;

const TooltipImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    min-height: 200px;
`;
const BulkRelate = ({ selectedAssets, onCancel }) => {
    const intl = useIntl();
    const addToast = useAddToast();

    const [assets, setAssets] = useState([]);
    const [search, setSearch] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [keepExisting, setKeepExisting] = useState(true);
    const [baseAssetUuid, setBaseAssetUuid] = useState("");
    useEffect(() => {
        axios
            .get(`/api/v1/assets/multiple`, {
                params: { uuids: selectedAssets, per_page: 10000 },
            })
            .then(({ data }) => {
                setAssets(
                    data.data.map((asset) => {
                        asset.visible = true;
                        return asset;
                    })
                );
                setLoaded(true);
            });
    }, [selectedAssets]);

    const handleSearch = (event) => {
        setSearch(event.value);
        const filteredAssets = assets.map((asset) => {
            asset.visible = asset.name
                .toLowerCase()
                .includes(event.value.toLowerCase());
            return asset;
        });
        setAssets(filteredAssets);
    };

    const saveAssetRelations = () => {
        setSaving(true);
        return axios
            .put(`/api/v1/assets/${baseAssetUuid}/related`, {
                related_assets: selectedAssets.filter(
                    (uuid) => uuid !== baseAssetUuid
                ),
                keep_existing: true,
            })
            .then(({ data }) => {
                setSaving(false);
                onCancel();

                addToast({
                    type: "success",
                    content: trans("Assets related successfully"),
                    showFor: 5000,
                });
            });
    };

    const renderTooltipContent = (asset) => {
        return (
            <div
                style={{
                    textAlign: "center",
                }}
            >
                <h3>{asset.name}</h3>
                {asset?.thumbnail && (
                    <TooltipImageWrapper>
                        <Image
                            src={asset.thumbnail}
                            alt={asset.name}
                            style={{
                                maxWidth: "200px",
                                maxHeight: "200px",
                            }}
                        />
                    </TooltipImageWrapper>
                )}
                {asset?.label?.subHeading &&
                    Object.entries(asset.label.subHeading).map(
                        ([label, value]) => {
                            if (value) {
                                return (
                                    <p>
                                        {!label && (
                                            `${value}`
                                        )}

                                        {(label && value) && (
                                            `${label}: ${value}`
                                        )}
                                    </p>
                                );
                            }
                        }
                    )}
            </div>
        );
    };

    return (
        <>
            <Row>
                <Column>
                    <h2>{trans("Relate Assets")}</h2>
                </Column>
                <Column medium={6}>
                    <p>
                        {trans(
                            "Please select the main asset you're relating from"
                        )}
                    </p>
                </Column>
                <Column medium={6}>
                    <InputField
                        // label={trans("Narrow list")}
                        value={search}
                        onChange={(event) => handleSearch(event)}
                        placeholder={intl.formatMessage({
                            id: "Enter name to narrow the list",
                        })}
                    />
                </Column>
            </Row>
            {loaded ? (
                <Row>
                    <Column>
                        {assets.map((asset) => {
                            const isSelectedAsset =
                                asset.uuid === baseAssetUuid;
                            if (!asset.visible && !isSelectedAsset) return null;
                            return (
                                <AssetRow key={asset.uuid}>
                                    <PlainButton
                                        style={{
                                            marginBottom: "1rem",
                                        }}
                                        expanded={true}
                                        useHover={false}
                                        onClick={() =>
                                            setBaseAssetUuid(asset.uuid)
                                        }
                                        icon={
                                            isSelectedAsset
                                                ? faCheckSquare
                                                : faSquare
                                        }
                                        transform="grow-6"
                                        showCircle={false}
                                        doHover={false}
                                    >
                                        <Tooltip
                                            text={renderTooltipContent(asset)}
                                            showIcon={false}
                                        >
                                            <FontAwesomeIcon
                                                style={{
                                                    marginRight: "0.5rem",
                                                }}
                                                icon={
                                                    isSelectedAsset
                                                        ? faCheckSquare
                                                        : faSquare
                                                }
                                                transform="grow-6"
                                            />{" "}
                                            {asset.name}
                                        </Tooltip>
                                    </PlainButton>
                                </AssetRow>
                            );
                        })}
                    </Column>
                </Row>
            ) : (
                <Row>
                    <Column>
                        <Loading />
                    </Column>
                </Row>
            )}

            <StickyFooter>
                <Column medium={6}>
                    <Button onClick={onCancel}>{trans("Cancel")}</Button>
                </Column>
                <Column medium={6} style={{ textAlign: "right" }}>
                    <FormLabel
                        name="keep_existing"
                        style={{ marginRight: "1rem" }}
                    >
                        <StyledCheckbox
                            name="keep_existing"
                            id="keep_existing"
                            type="checkbox"
                            inline={true}
                            value={keepExisting}
                            checked={keepExisting}
                            onChange={(event) =>
                                setKeepExisting(event.target.checked)
                            }
                        />
                        {trans("Keep the main asset's existing relations")}
                    </FormLabel>
                    <SaveButton
                        saving={saving}
                        onClick={saveAssetRelations}
                        disabled={baseAssetUuid === ""}
                    >
                        {trans("Save")}
                    </SaveButton>
                </Column>
            </StickyFooter>
        </>
    );
};

export default BulkRelate;
