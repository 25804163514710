import React, { Component } from "react";

import PerformanceSummaryConfiguration from "./PerformanceSummaryConfiguration";
import PerformanceSummaryOutput from "./PerformanceSummaryOutput";

class PerformanceSummary extends Component {
    render() {
        if (this.props.configuring) {
            return <PerformanceSummaryConfiguration {...this.props} />;
        }
        return <PerformanceSummaryOutput {...this.props} />;
    }
}

export default PerformanceSummary;
