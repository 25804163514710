import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
    useAddToast,
    useToasts,
    Row,
    Loading,
    Column,
} from "@cortexglobal/rla-components";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import { get } from "lodash";
import ProfileForm from "./ProfileForm";

const EditProfile = () => {
    const [languages, setLanguages] = useState({ loaded: false, data: [] });
    const user = useSelector((state) => state.auth.user);
    const intl = useIntl();
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    useEffect(() => {
        axios.get(`/api/v1/language`).then(({ data }) =>
            setLanguages({
                loaded: true,
                data: data.data.map((language) => {
                    return { text: language.name, value: language.iso_code };
                }),
            })
        );
    }, []);
    const loadToast = (message) => {
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: message,
        });
    };

    const removeLoadToast = () => {
        dispatch({ type: "removeToast", uuid: "loadToast" });
    };

    const onSubmit = (values, { setErrors }) => {
        loadToast(trans(`Updating your profile`));

        return axios
            .put(`/api/v1/profile`, values)
            .then((response) => {
                removeLoadToast();

                addToast({
                    type: "success",
                    content: trans(
                        "Your profile has been successfully updated"
                    ),
                    showFor: 5000,
                });
                return;
            })
            .catch((e) => {
                removeLoadToast();
                if (e.response && e.response.status === 422) {
                    setErrors(get(e, "response.data.errors", {}));
                    return;
                }

                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred deleting the category"
                        ),
                        showFor: 5000,
                    });
                }
            });
    };

    return (
        <Row style={{ paddingBottom: "1rem" }}>
            <Column>
                {!user.loaded || !languages.loaded ? (
                    <Loading />
                ) : (
                    <ProfileForm
                        onSubmit={onSubmit}
                        user={user}
                        languages={languages.data}
                        submitLabel={intl.formatMessage({
                            id: "Update Profile",
                        })}
                    />
                )}
            </Column>
        </Row>
    );
};

export default EditProfile;
