export const objectFlip = (obj) => {
    return Object.keys(obj).reduce((newObj, value) => {
        newObj[obj[value]] = value;
        return newObj;
    }, {});
};

export const parseBool = (boolStr) => {
    return /true/i.test(boolStr);
};

export const isJSON = (str) => {
    if (typeof str === "undefined" || str === "") {
        return false;
    }
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};

export function isObject(object) {
    if (object === undefined || object === null) {
        return false;
    }

    return object.constructor === Object ? true : false;
}

export const UCFirstLetter = (string) => {
    const stringCopy = (" " + string).slice(1);
    return stringCopy.charAt(0).toUpperCase() + stringCopy.slice(1);
};

export const removeObjectLevelByKey = (item, keyToRemove = "data") => {
    if (typeof item !== "object" || item === null) return item;
    let newObjectItem = Array.isArray(item[keyToRemove]) ? [] : {};
    for (let key in item) {
        if (key !== keyToRemove)
            newObjectItem[key] = removeObjectLevelByKey(item[key], keyToRemove);
    }

    if (item[keyToRemove]) {
        for (let key in item[keyToRemove]) {
            if (key !== keyToRemove) {
                newObjectItem[key] = removeObjectLevelByKey(
                    item[keyToRemove][key],
                    keyToRemove
                );
            }
        }
    }
    return newObjectItem;
};

export function findItem(state, key, uuid) {
    return findObjectInArray(findInObject(state)(key))(uuid);
}

function findInObject(object) {
    return (key) => {
        return object[key];
    };
}

function findObjectInArray(array) {
    return (key) => {
        return array.filter((item) => {
            if (item.uuid === key) {
                return item;
            }
            return false;
        })[0];
    };
}

export function isValidEmail(email) {
    // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // above causing warning but might be correct
    var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function buildCollection(formCollectionData) {
    var formCollection = {};
    for (var key in formCollectionData) {
        if (
            formCollectionData.hasOwnProperty(key) &&
            typeof formCollectionData[key] === "object"
        ) {
            formCollection[key] = JSON.stringify(
                formCollectionData[key].values
            );
        }
    }
    return formCollection;
}

export const parseValidationErrors = (e) => {
    return new Promise((resolve, reject) => {
        if (!e.response || !e.response.status || e.response.status !== 422) {
            return reject(e);
        }

        const {
            response: {
                data: { errors: messages },
            },
        } = e;

        const errors = Object.keys(messages || []).reduce((carry, key) => {
            let message = messages[key];

            if (Array.isArray(message)) {
                message = message.join(" ");
            }

            carry[key] = message;

            return carry;
        }, {});

        resolve({ errors });
    });
};

export const calculatePercentage = (loaded, total) =>
    Math.floor(loaded * 1.0) / total;

export const getNavigatorLanguage = () => {
    // Different browsers have the user locale defined
    // on different fields on the `navigator` object, so we make sure to account
    // for these different by checking all of them
    return (
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage
    );
};

export const getType = (obj) =>
    Object.prototype.toString
        .call(obj)
        .replace(/^\[object (.+)\]$/, "$1")
        .toLowerCase();

export const collectionContainsKeys = (
    keys,
    collection,
    property = "alias"
) => {
    let matched = [];
    if (collection) {
        collection.forEach((item) => {
            if (keys.includes(item[property])) {
                matched.push(item);
            }
        });
    }
    return matched;
};

/**
 * Checks if the user has one of the required permissions
 * @param {string|array} requiredPermission - The alias or array of aliases that are required
 * @param {array} userPermissions - The user permission collection
 */
export const hasPermission = (requiredPermission, userPermissions) => {
    if (!Array.isArray(requiredPermission)) {
        requiredPermission = [requiredPermission];
    }
    const matched = collectionContainsKeys(requiredPermission, userPermissions);
    return matched.length > 0;
};

/**
 * Convert a standard JS object to a FormData object
 * Based on answer given here: https://stackoverflow.com/a/49388446/14360508
 *
 * @param obj
 * @param rootName
 * @param ignoreList
 * @returns {FormData}
 */
export const objectToFormData = (obj, rootName, ignoreList) => {
    let formData = new FormData();

    const appendFormData = (data, root) => {
        if (!ignore(root)) {
            root = root || "";
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + "[" + i + "]");
                }
            } else if (typeof data === "object" && data) {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === "") {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + "[" + key + "]");
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== "undefined") {
                    formData.append(root, data);
                }
            }
        }
    };

    const ignore = (root) => {
        return (
            Array.isArray(ignoreList) &&
            ignoreList.some(function (x) {
                return x === root;
            })
        );
    };

    appendFormData(obj, rootName);

    return formData;
};

export const removeDomainFromUrl = (url) => {
    if (!url) return url;
    const urlObj = new URL(url);
    // if (!urlObj || !urlObj.canParse()) return url;
    return urlObj.pathname + urlObj.search + urlObj.hash;
};
