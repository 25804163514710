import React, { useEffect, useContext } from "react";
import { useRouteMatch } from "react-router-dom";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useQueryString } from "@cortexglobal/cortex-utilities";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { trans } from "@cortexglobal/rla-intl";
import {
    PageTitle,
    IconTextButton,
    Column,
    Loading,
    Row,
    FormLabel,
    Toggle,
    LoadingIconSmall,
} from "@cortexglobal/rla-components";

import FaqContext from "../FaqContext";
import FaqSidebar from "../components/FaqSidebar";
import FaqIndex from "../components/FaqIndex";
import FaqCategory from "../components/FaqCategory";
import FaqQuestionCreate from "../components/FaqQuestionCreate";
import FaqQuestionView from "../components/FaqQuestionView";

const FaqScreen = () => {
    const { can } = usePermissions();

    const {
        faqState,
        hideUnpublished,
        setHideUnpublished,
        basepath,
        faqNavigate,
        loadCategory,
    } = useContext(FaqContext);

    const { categories_loading, category_loading, categories } = faqState;

    const {
        params: { category: categorySlug, question: questionSlug },
    } = useRouteMatch();

    // We determine if we're editing using a get variable and navigate to it with history.push
    const newQuestion = !!useQueryString().get("new") || false;
    const editing = !!useQueryString().get("editing") || false;

    // Effects
    useEffect(() => {
        loadCategory(categorySlug);
    }, [categorySlug, hideUnpublished]);

    // I wonder if this would be better served with routes
    const renderContentType = () => {
        if (newQuestion) {
            return <FaqQuestionCreate />;
        }

        if (questionSlug) {
            return <FaqQuestionView />;
        }

        if (categorySlug) {
            return <FaqCategory />;
        }

        // If there is no category or question slug and new question isn't set then default to the faq home page/index
        if (categories) {
            return <FaqIndex />;
        }

        // If nothing is ready show a loading bar
        return <Loading />;
    };

    return (
        <div>
            <PageTitle
                title={
                    <header style={{ display: "flex", alignItems: "center" }}>
                        {trans("Frequently Asked Questions")}
                    </header>
                }
            >
                {can("faq-manage") && !editing && !newQuestion && (
                    <FormLabel
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            margin: 0,
                        }}
                    >
                        {trans("Hide unpublished")}

                        <Toggle
                            style={{ marginLeft: "0.5rem" }}
                            name="hideUnpublished"
                            checked={hideUnpublished}
                            onChange={(event) => {
                                setHideUnpublished(event.target.checked);
                            }}
                            size="small"
                        />
                    </FormLabel>
                )}
                <IconTextButton
                    icon={faPlus}
                    showCircle={false}
                    expanded={false}
                    onClick={() => faqNavigate(`${basepath}?new=true`)}
                >
                    {trans("Ask a new question")}
                </IconTextButton>
            </PageTitle>

            {categories_loading && <Loading />}

            {!categories_loading && (
                <Row>
                    <Column large={4} xlarge={3}>
                        {categories && <FaqSidebar />}
                    </Column>
                    <Column large={8} xlarge={9}>
                        {renderContentType()}
                    </Column>
                </Row>
            )}
        </div>
    );
};

export default FaqScreen;
