import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Select,
    InputField,
    DatePicker,
    TextareaField,
} from "@cortexglobal/rla-components";

export const CellInput = ({
    cell = {},
    column,
    rowId,
    onChange,
    disabled = false,
}) => {
    const handleChange = (e) => {
        onChange(rowId, column.key, e.value);
    };

    const { value: cellValue = "", error: cellError = "" } = cell;
    switch (column.type) {
        case "select":
            return (
                <Select
                    value={cellValue}
                    onChange={handleChange}
                    options={column.options.map((option) => {
                        if (typeof option === "object") {
                            return option;
                        }

                        return {
                            text: option,
                            value: option,
                        };
                    })}
                    error={cellError}
                    disabled={disabled}
                />
            );

        case "number":
            return (
                <InputField
                    type="number"
                    value={cellValue}
                    onChange={handleChange}
                    error={cellError}
                    disabled={disabled}
                />
            );

        case "date":
            return (
                <DatePicker
                    value={cellValue}
                    onChange={handleChange}
                    error={cellError}
                    disabled={disabled}
                />
            );

        case "textarea":
            return (
                <TextareaField
                    value={cellValue}
                    onChange={handleChange}
                    rows={4}
                    error={cellError}
                    disabled={disabled}
                />
            );
        case "id":
            return (
                <InputField
                    value={cellValue}
                    onChange={handleChange}
                    readOnly={true}

                    error={cellError}
                />
            );
        default:
            //If we're here we're treating the data as a string
            //If the string is longer than 50 characters, we'll show a textarea
            //If the string is shorter than 50 characters, we'll show a text input
            if (cellValue && cellValue.length > 50) {
                return (
                    <TextareaField
                        value={cellValue}
                        onChange={handleChange}
                        rows={4}
                        error={cellError}
                        disabled={disabled}
                    />
                );
            }
            return (
                <InputField
                    type="text"
                    value={cellValue}
                    onChange={handleChange}
                    error={cellError}
                    disabled={disabled}
                />
            );
    }
};
