import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";

import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Dropdown,
    Button
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    faCogs,
    faTrashAlt,
    faBox,
    faBoxOpen,
    faEdit
} from "@fortawesome/free-solid-svg-icons";
import { useConfirmation } from "../ConfirmationService";
import { useTheme } from "styled-components";
import AssetContext from "../AssetContext";
import VersionOptions from "./VersionOptions";
import CreateAssetMinorVersion from "./CreateAssetMinorVersion";
// const StyledLink = styled(Link)`
//     text-indent: none;
// `;

const AssetAdminOptions = props => {
    const theme = useTheme();
    const [showDropdown, setShowDropdown] = useState(false);
    const {
        asset,
        assetsPath,
        archiveAsset,
        canAdmin,
        deleteAsset,
        publishAsset
    } = useContext(AssetContext);
    const dropdownButton = useRef();
    const { history } = props;
    const confirm = useConfirmation();
    if (!canAdmin) {
        return null;
    }
    // console.log(dropdownButton);
    return (
        <div style={{ position: "relative" }}>
            <IconTextButton
                ref={dropdownButton}
                onClick={event => {
                    setShowDropdown(!showDropdown);
                }}
                rotation={showDropdown ? 270 : 90}
                showCircle={false}
                expanded={false}
            >
                {trans("Admin Options")}
            </IconTextButton>

            <Dropdown
                visible={showDropdown}
                hideDropdown={() => setShowDropdown(false)}
                toggleRef={dropdownButton.current}
                padding={0}
            >
                {asset.isVersioned &&
                (asset.isLive || asset.status === "archived") ? (
                    <CreateAssetMinorVersion
                        assetsPath={assetsPath}
                        asset={asset}
                    />
                ) : (
                    <IconTextButton
                        type="info"
                        as={Link}
                        to={`${assetsPath}/${asset.uuid}/admin`}
                        icon={faEdit}
                        showCircle={false}
                        expanded={false}
                        style={{
                            paddingTop: "0.8rem",
                            paddingBottom: "0.8rem"
                        }}
                    >
                        {trans("Edit Asset")}
                    </IconTextButton>
                )}

                {asset.status != "archived" && (
                    <IconTextButton
                        type="info"
                        onClick={event =>
                            confirm({
                                variant: "danger",
                                catchOnCancel: true,
                                title:
                                    trans("Are you sure you want to archive this asset?"),
                                description:
                                    trans("Once archived it will not be available.")
                            })
                                .then(() =>
                                    archiveAsset(asset.uuid).then(
                                        history.push(`${assetsPath}`)
                                    )
                                )
                                .catch()
                        }
                        icon={faBox}
                        showCircle={false}
                        expanded={false}
                        style={{
                            textDecoration: "none",
                            paddingTop: "0.8rem",
                            paddingBottom: "0.8rem",
                            color: theme.colors.warning
                        }}
                    >
                        {trans("Archive Asset")}
                    </IconTextButton>
                )}

                <IconTextButton
                    type="info"
                    onClick={event =>{
                        confirm({
                            variant: "danger",
                            catchOnCancel: true,
                            title:
                                trans("Are you sure you want to permanently delete this asset?"),
                            description:
                               trans( "Once deleted it will not be retrievable.")
                        })
                            .then(() =>
                                deleteAsset(asset.uuid, `${assetsPath}`).then(
                                    history.push(`${assetsPath}`)
                                )
                            )
                            .catch()
                    }}
                    icon={faTrashAlt}
                    showCircle={false}
                    expanded={false}
                    style={{
                        textDecoration: "none",
                        paddingTop: "0.8rem",
                        paddingBottom: "1rem",
                        color: theme.colors.alert
                    }}
                >
                    {trans("Delete Asset")}
                </IconTextButton>
            </Dropdown>
        </div>
    );
};

export default withRouter(AssetAdminOptions);
