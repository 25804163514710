import React, { useState } from "react";
import axios from "axios";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Panel,
    IconTextButton,
} from "@cortexglobal/rla-components";

import {
    renderSettingInputGroupRecursive,
    onConfigurationChange,
} from "./components/sharedEditorFunctions";

import { SectionHeading } from "./components/SectionHeading";

const SiteSettingsEditor = ({
    asset,
    standardOnChange,
    fullAdmin,
    theming = false,
}) => {
    if (!asset || !asset.editable || !asset.microsite) {
        return null;
    }
    const { configuration, extracted_tags, autofill, uuid, asset_type } = asset;

    let currentConfiguration = configuration ? configuration : extracted_tags;
    if (!currentConfiguration) {
        return "No configuration has been extracted from this asset";
    }

    //TODO - Handle multiple navigation items at the moment it's only doing the 'main' one
    const basePath = `globalSettings`;
    //Get the nav menus or create an empty default
    const settings = currentConfiguration.globalSettings
        ? currentConfiguration.globalSettings
        : [];
    const resetSettings = () => {
        //Load the default theming from the server
        axios.get(`/api/v1/assets/microsite/configuration`).then(({ data }) => {
            //Replace the current configuration globalSettings with the defaults from the configuration
            let newConfiguration = {
                ...currentConfiguration,
                globalSettings: data.globalSettings,
            };
            //Update the configuration
            standardOnChange({
                name: "configuration",
                value: newConfiguration,
            });
        });
    };
    return (
        <React.Fragment>
            <div style={{ display: "flex" }}>
                <SectionHeading style={{ flexGrow: 1 }}>
                    {trans("Global Settings")}
                </SectionHeading>
                <IconTextButton
                    icon={faSync}
                    showCircle={false}
                    onClick={resetSettings}
                >
                    {trans("Reset all settings to default")}
                </IconTextButton>
            </div>
            <Panel>
                <Row>
                    <Column>
                        {settings.length > 0 ? (
                            settings.map((tag, elementIndex) => {
                                // console.log(tag);
                                //Add the code to check if the tag is an array, and do the looping,
                                //moving the below out into it's own component would be good
                                return renderSettingInputGroupRecursive({
                                    tag,
                                    onChange: (value, path, event) => {
                                        onConfigurationChange(
                                            standardOnChange,
                                            asset,
                                            value,
                                            event,
                                            path
                                        );
                                    },
                                    chooseImage: (path, tag, event) => {
                                        //TODO - Set the correct image/tag in state to be used on the
                                        //update method
                                        return setChoosingImage(!choosingImage);
                                    },
                                    autofill,
                                    path: basePath,
                                    index: `${elementIndex}`,
                                    fullAdmin,
                                    asset,
                                });
                            })
                        ) : (
                            <span>{trans("No editable fields")}</span>
                        )}
                    </Column>
                </Row>
            </Panel>
        </React.Fragment>
    );
};

export default SiteSettingsEditor;
