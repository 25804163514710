import React from "react";

const SurveySubmissionContext = React.createContext({
    surveyEntry: {},
    surveyAnswers: [],
    surveyAnswersData: [],
    surveySystem: {
        loading: false,
        error: "",
        isSubmitting: false,
        isSubmitted: false,
        unsavedChanges: false,
        questionErrors: [],
    },
    loadSurvey: (uuid) => {},
    saveProgress: (uuid) => {},
    submitAnswers: (uuid) => {},
    setSurveyAnswersData: (surveyData) => {},
    setSurveySystem: (surveySystem) => {},
    handleAnswerChange: (name, value) => {},
});

export default SurveySubmissionContext;
