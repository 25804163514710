import React from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    StyledCheckbox,
    FormLabel,
    Select,
    Panel,
    HelpText,
} from "@cortexglobal/rla-components";

import EditorContext from "./EditorContext";

import { SectionHeading } from "./components/SectionHeading";

const VideoEditorSettings = ({
    assetAdmin,
    asset,
    standardOnChange,
    saveUpdatedAsset,
    config,
}) => {
    if (!asset || !asset.editable) {
        return null;
    }

    let { configuration } = asset;

    configuration = !configuration ? {} : configuration;

    const getResolutionKeys = () => {
        return config.video_templates.templates
            .reduce((resolutions, template) => {
                template.available_resolutions.forEach((newResolution) => {
                    if (
                        !resolutions.some(
                            (resolution) => resolution === newResolution
                        )
                    ) {
                        resolutions.push(newResolution);
                    }
                });
                return resolutions;
            }, [])
            .map((resolution) => {
                return { text: resolution, value: resolution };
            });
    };

    return (
        <EditorContext.Provider
            value={{
                assetAdmin: assetAdmin,
                saveUpdatedAsset: saveUpdatedAsset,
            }}
        >
            <SectionHeading>{trans("Video Editor Settings")}</SectionHeading>

            <Panel>
                <Row>
                    <Row>
                        <Column medium={3} style={{ textAlign: "right" }}>
                            <FormLabel name="availbleEndFrameResolutions">
                                {trans("Available Resolutions")}
                            </FormLabel>
                            <HelpText>
                                {trans("Select one of the Resolutions")}
                            </HelpText>
                        </Column>

                        <Column medium={6}>
                            {config.video_templates && (
                                <>
                                    <FormLabel
                                        style={{
                                            marginBottom: "1.2em",
                                            display: "flex",
                                        }}
                                    >
                                        <Select
                                            name="resolution"
                                            value={configuration.resolution}
                                            onChange={(event) => {
                                                standardOnChange({
                                                    name: "configuration",
                                                    value: {
                                                        ...configuration,
                                                        resolution: event.value,
                                                        pages: {},
                                                    },
                                                });
                                            }}
                                            options={getResolutionKeys()}
                                            expanded={true}
                                        />
                                    </FormLabel>
                                </>
                            )}
                        </Column>
                    </Row>

                    {configuration.resolution && (
                        <Row>
                            <Column medium={3} style={{ textAlign: "right" }}>
                                <FormLabel name="availableEndFrameTemplate">
                                    {trans("Available End-Frame Templates")}
                                </FormLabel>
                                <HelpText>
                                    {trans(
                                        "Select the templates you want to make available"
                                    )}
                                </HelpText>
                            </Column>

                            <Column medium={6}>
                                {config.video_templates && (
                                    <>
                                        {config.video_templates.templates
                                            .filter((template) =>
                                                template.available_resolutions.includes(
                                                    configuration.resolution
                                                )
                                            )
                                            .map((template) => {
                                                const templateIsSelected =
                                                    Object.keys(
                                                        configuration.pages
                                                    ).includes(template.name);
                                                return (
                                                    <FormLabel
                                                        key={template.name}
                                                        style={{
                                                            marginBottom:
                                                                "1.2em",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <StyledCheckbox
                                                            style={{
                                                                marginBottom:
                                                                    "0",
                                                            }}
                                                            name="availableEndFrameTemplate"
                                                            checked={
                                                                templateIsSelected
                                                            }
                                                            value={
                                                                templateIsSelected
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    !event
                                                                        .target
                                                                        .checked
                                                                ) {
                                                                    //Remove this page/template
                                                                    const {
                                                                        [template.name]:
                                                                            oldTemplate,
                                                                        ...newPages
                                                                    } =
                                                                        configuration.pages;
                                                                    standardOnChange(
                                                                        {
                                                                            name: "configuration",
                                                                            value: {
                                                                                ...configuration,
                                                                                pages: newPages,
                                                                            },
                                                                        }
                                                                    );
                                                                } else {
                                                                    //Add this page/template
                                                                    standardOnChange(
                                                                        {
                                                                            name: "configuration",
                                                                            value: {
                                                                                ...configuration,
                                                                                pages: {
                                                                                    ...configuration.pages,
                                                                                    [template.name]:
                                                                                        template,
                                                                                },
                                                                            },
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        {template.displayName}
                                                    </FormLabel>
                                                );
                                            })}
                                    </>
                                )}
                            </Column>
                        </Row>
                    )}
                </Row>
            </Panel>
        </EditorContext.Provider>
    );
};

export default VideoEditorSettings;
