import React from "react";
import { v4 as uuid4 } from "uuid";

import { Button } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import { InputField } from "@cortexglobal/rla-components";

const BackgroundImageEditor = ({
    basePath,
    asset,
    onChange,
    fullAdmin,
    pageElements,
    children,
}) => {
    // console.log("asset.autofill", asset.autofill);
    // console.log("autofill", autofill);
    const emptyElement = {
        uuid: uuid4(),
        name: "",
        label: "",
        original_src: "",
        new_src: "",
    };
    return (
        <React.Fragment>
            {children}
            {pageElements ? (
                pageElements.map((tag, elementIndex) => {
                    if (
                        ((tag.autofilled &&
                            (tag.editable === "false" ||
                                tag.editable === false)) ||
                            (tag.autofilled && asset.bulk_edit === true)) &&
                        !fullAdmin
                    ) {
                        return null;
                    }
                    const elementPath = `${basePath}backgroundImages[${elementIndex}]`;
                    if (fullAdmin) {
                        return (
                            <div key={tag.uuid}>
                                <InputField
                                    label={
                                        <span>
                                            {trans("Label for")} {tag.name}
                                        </span>
                                    }
                                    id={tag.name + "_label"}
                                    name={tag.name}
                                    value={tag.label}
                                    onChange={(event) =>
                                        onChange(`label`, elementPath, event)
                                    }
                                    placeholder={tag.name}
                                />
                                <InputField
                                    label={
                                        <span>{trans("Original source")} </span>
                                    }
                                    id={tag.original_src + "_label"}
                                    name={tag.name}
                                    value={tag.original_src}
                                    onChange={(event) =>
                                        onChange(
                                            "original_src",
                                            elementPath,
                                            event
                                        )
                                    }
                                />
                                <InputField
                                    label={<span>{trans("New source")} </span>}
                                    id={tag.new_src + "_label"}
                                    name={tag.name}
                                    value={tag.new_src}
                                    onChange={(event) =>
                                        onChange("new_src", elementPath, event)
                                    }
                                />
                            </div>
                        );
                    }
                    return "Editor version of the background images";
                })
            ) : (
                <div>
                    <p>No background images found</p>
                </div>
            )}
            {fullAdmin && (
                <Button
                    onClick={() => {
                        if (pageElements) {
                            const newImages = [...pageElements, emptyElement];
                            onChange(`backgroundImages`, basePath, {
                                value: newImages,
                            });
                        } else {
                            onChange("backgroundImages", basePath, {
                                value: [emptyElement],
                            });
                        }
                    }}
                >
                    {trans("Add Background Image")}
                </Button>
            )}
        </React.Fragment>
    );
};

export default BackgroundImageEditor;
