import React from "react";
import styled from "styled-components";
import {
    Column,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    IconTextButton,
} from "@cortexglobal/rla-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const ButtonWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    text-align: right;
`;

const DeleteButton = styled(IconTextButton)`
    padding: 0%.2rem;
    color: ${({ theme }) => theme.colors.alert};
`;

export const Img = styled(SearchResultImage)`
    display: block;
    max-width: 100%;
    max-height: 150px;
    cursor: pointer;
    /* margin: 1rem; */
`;

const ResultWrapper = styled(SearchResultWrapper)`
    padding-top: 0;
    padding-bottom: 0;
    border: ${({ selected, theme }) =>
        selected
            ? `2px ${theme.colors.accent} solid`
            : "2px rgba(0,0,0,0.3) solid"};
    min-height: 200px;
    opacity: ${({ selected }) => (selected ? 1 : 0.6)};
`;

const ImageWrapper = styled(SearchResultImageWrapper)`
    height: 100%;
`;

export const AssetImageResult = ({
    file,
    name,
    selected,
    onChange,
    onRemove = null,
    inFormRow = false,
}) => {
    return (
        <Column
            medium={inFormRow ? 12 : 6}
            large={inFormRow ? 12 : 4}
            xlarge={inFormRow ? 6 : 3}
            key={file.uuid}
            collapse={false}
            style={{ marginBottom: "1rem" }}
        >
            <ResultWrapper selected={selected}>
                {onRemove !== null && (
                    <ButtonWrapper>
                        <DeleteButton
                            icon={faTimes}
                            onClick={() => onRemove(file.uuid)}
                        />
                    </ButtonWrapper>
                )}
                <ImageWrapper>
                    <Img
                        src={file.thumbnail}
                        onClick={() =>
                            onChange({
                                name,
                                value: file.file,
                                uuid: file.uuid,
                            })
                        }
                    />
                </ImageWrapper>
            </ResultWrapper>
        </Column>
    );
};

export default AssetImageResult;
