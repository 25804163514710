import React from "react";

import { trans } from "@cortexglobal/rla-intl";

import {
    InputField,
    Select,
    FormRow,
    DraftJSField,
    Toggle,
} from "@cortexglobal/rla-components";

const getToolbar = () => {
    return {
        options: ["inline", "blockType", "list", "link", "remove", "history"],
        inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
        },
        blockType: {
            inDropdown: true,
            options: [
                "Normal",
                "H1",
                "H2",
                "H3",
                "H4",
                "H5",
                "H6",
                "Blockquote",
            ],
        },
    };
};

export const General = ({
    categories = [],
    standardOnChange,
    errors,
    questionData,
    singleColumn = false,
    collapse = false,
}) => {
    return (
        <>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="question"
                label={trans("Question")}
                error={errors.question}
                required={true}
            >
                <InputField
                    name="question"
                    value={questionData.question}
                    onChange={standardOnChange}
                    showError={false}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="answer"
                label={trans("Answer")}
                error={errors.answer}
            >
                <DraftJSField
                    id="answer"
                    name="answer"
                    value={questionData.answer}
                    onChange={standardOnChange}
                    toolbar={getToolbar()}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="question_category"
                label={trans("Category")}
                error={errors.question_category}
                required={true}
            >
                <Select
                    name="question_category"
                    options={categories}
                    value={questionData.question_category.value}
                    onChange={(event) => {
                        const newCategory = categories.find((category) => {
                            return category.value === event.value;
                        });
                        if (newCategory) {
                            delete newCategory.questions;
                            standardOnChange({
                                name: "question_category",
                                value: newCategory,
                            });
                        }
                    }}
                    tags={false}
                    multi={false}
                    creatable={false}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="order"
                label={trans("Order")}
                error={errors.order}
                required={false}
            >
                <InputField
                    name="order"
                    value={questionData.order}
                    onChange={standardOnChange}
                    showError={false}
                />
            </FormRow>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="show_in_sidebar"
                label={trans("Show in sidebar")}
                error={errors.title}
            >
                <Toggle
                    name="show_in_sidebar"
                    checked={questionData.show_in_sidebar}
                    onChange={(event) => {
                        standardOnChange({
                            name: "show_in_sidebar",
                            value: event.target.checked,
                        });
                    }}
                    size="small"
                />
            </FormRow>
        </>
    );
};

export default General;
