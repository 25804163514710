import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Tooltip,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import UserAssetContext from "../UserAssetContext";
import ShareModal from "../../assets/components/ShareModal";
import { useConfirmation } from "../../assets/ConfirmationService";
import PrintOrderModal from "../../assets/components/Print/PrintOrderModalBulkPricing";
import { useDownloadUserAsset } from "../hooks/useDownloadUserAsset";
import BulkGenerateModal from "../components/BulkGenerateModal";

const ActionsPanel = ({ userAsset, getUserAsset }) => {
    const { userAssetPath, getUserAssets, group } =
        useContext(UserAssetContext);

    const { downloadUserAssetFiles } = useDownloadUserAsset();

    const [showLiveActions, setShowLiveActions] = useState(
        userAsset.asset.isLive
    );

    const [loadingState, setLoadingState] = useState("loaded");

    const { can } = usePermissions();

    const confirm = useConfirmation();
    const history = useHistory();

    const [showShareModal, setShowShareModal] = useState(false);
    const [showOrderPrintModal, setShowOrderPrintModal] = useState(false);
    const [showBulkGenerateModal, setShowBulkGenerateModal] = useState(false);

    const addToast = useAddToast();

    const sendForApproval = () => {
        axios
            .post(`/api/v1/user-assets/${userAsset.uuid}/request-approval`)
            .then(({ data }) => {
                setLoadingState("loaded");
                getUserAsset();
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    addToast({
                        type: "alert",
                        content: trans(
                            "There was an issue requesting download approval"
                        ),
                        showFor: 5000,
                    });
                }

                setLoadingState("error");
            });
    };

    const printOrderEnabled = !!userAsset.printable;

    return (
        <React.Fragment>
            <h3>{trans("Available Actions")}</h3>
            <Panel>
                {showLiveActions ? (
                    <React.Fragment>
                        {userAsset.editable && (
                            <Row>
                                <IconTextButton
                                    type="primary"
                                    expanded
                                    as={Link}
                                    to={`${userAssetPath}/${userAsset.uuid}/edit`}
                                >
                                    {trans("Edit")}
                                </IconTextButton>
                            </Row>
                        )}
                        {userAsset.asset.microsite &&
                            !!Object.keys(userAsset.asset.configuration.pages)
                                .length && (
                                <Row>
                                    <IconTextButton
                                        type="primary"
                                        expanded
                                        onClick={(event) => {
                                            event.preventDefault();
                                            const win = window.open(
                                                `/presentations/${userAsset.uuid}`,
                                                "_blank"
                                            );
                                            if (win) {
                                                win.focus();
                                            }
                                        }}
                                    >
                                        {trans("View")}
                                    </IconTextButton>
                                </Row>
                            )}

                        {userAsset.bulk_edit && (
                            <Row>
                                <IconTextButton
                                    type="primary"
                                    onClick={() =>
                                        setShowBulkGenerateModal(true)
                                    }
                                >
                                    {trans("Generate Batch Artwork Files")}
                                </IconTextButton>
                            </Row>
                        )}

                        {false && userAsset.emailable && (
                            <Row>
                                <IconTextButton
                                    type="primary"
                                    expanded
                                    as={Link}
                                    to={`${userAssetPath}/${userAsset.uuid}/contacts`}
                                >
                                    {trans("Manage Contact List")}
                                </IconTextButton>
                            </Row>
                        )}

                        {userAsset.shareable && (
                            <Row>
                                <IconTextButton
                                    type="primary"
                                    expanded
                                    onClick={() => setShowShareModal(true)}
                                >
                                    {trans("Share Asset")}
                                </IconTextButton>
                            </Row>
                        )}

                        {userAsset.downloadable &&
                            !userAsset.is_downloadable_by_user &&
                            !userAsset.has_approval_request && (
                                <Row>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        onClick={() => sendForApproval()}
                                    >
                                        {trans("Submit For Approval")}
                                    </IconTextButton>
                                </Row>
                            )}

                        {userAsset.downloadable &&
                            userAsset.has_approval_request &&
                            userAsset.download_approval_request_link && (
                                <Row>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        as={"a"}
                                        href={
                                            userAsset.download_approval_request_link
                                        }
                                    >
                                        {trans("View Request")}
                                    </IconTextButton>
                                </Row>
                            )}

                        {userAsset.is_downloadable_by_user &&
                            !userAsset.imageZippable &&
                            !userAsset.pdfable && (
                                <Row>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        onClick={(event) => {
                                            const googleAdsFilenameLengthLimit = 46; //Allows for extension too as max is 50
                                            const needsShorterNameForGoogle =
                                                (userAsset.asset_type ===
                                                    "digital" ||
                                                    userAsset.asset_type
                                                        ?.slug === "digital") &&
                                                userAsset.name &&
                                                userAsset.name.length >
                                                    googleAdsFilenameLengthLimit;
                                            const filename =
                                                needsShorterNameForGoogle
                                                    ? userAsset.name.substring(
                                                          0,
                                                          googleAdsFilenameLengthLimit
                                                      )
                                                    : userAsset.name;

                                            downloadUserAssetFiles(
                                                userAsset.uuid,
                                                filename
                                            );
                                        }}
                                    >
                                        {trans("Download")}
                                    </IconTextButton>
                                </Row>
                            )}

                        {!userAsset.bulk_edit &&
                            userAsset.pdfable &&
                            userAsset.is_downloadable_by_user && (
                                <React.Fragment>
                                    <Row>
                                        <IconTextButton
                                            type="secondary"
                                            expanded
                                            onClick={(event) =>
                                                downloadUserAssetFiles(
                                                    userAsset.uuid,
                                                    userAsset.name,
                                                    "download-pdf",
                                                    "quality=high"
                                                )
                                            }
                                        >
                                            <Tooltip
                                                text={trans(
                                                    "High quality PDF with no crop marks."
                                                )}
                                            >
                                                {trans(
                                                    "Download PDF (High-Res)"
                                                )}
                                            </Tooltip>
                                        </IconTextButton>
                                    </Row>
                                    <Row>
                                        <IconTextButton
                                            type="secondary"
                                            expanded
                                            onClick={(event) =>
                                                downloadUserAssetFiles(
                                                    userAsset.uuid,
                                                    userAsset.name,
                                                    "download-pdf",
                                                    "quality=print"
                                                )
                                            }
                                        >
                                            <Tooltip
                                                text={trans(
                                                    "High quality PDF with crop marks, for use with commercial printers."
                                                )}
                                            >
                                                {trans(
                                                    "Download Print Ready PDF (High-Res)"
                                                )}
                                            </Tooltip>
                                        </IconTextButton>
                                    </Row>
                                </React.Fragment>
                            )}
                        {userAsset.imageZippable &&
                            userAsset.is_downloadable_by_user && (
                                <Row>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        onClick={(event) =>
                                            downloadUserAssetFiles(
                                                userAsset.uuid,
                                                userAsset.name,
                                                "download-image-zip"
                                            )
                                        }
                                    >
                                        {trans("Download Image Zip")}
                                    </IconTextButton>
                                </Row>
                            )}
                        {false && (
                            <Row>
                                <IconTextButton
                                    type="secondary"
                                    as={Link}
                                    expanded
                                    to={`/request/amendment/${userAsset.smartRef}`}
                                >
                                    {trans("Request amendment")} -todo
                                </IconTextButton>
                            </Row>
                        )}

                        {printOrderEnabled && (
                            <Row>
                                <IconTextButton
                                    onClick={() => setShowOrderPrintModal(true)}
                                >
                                    {trans("Order Print")}
                                </IconTextButton>
                            </Row>
                        )}
                    </React.Fragment>
                ) : (
                    <Row>
                        <Column>
                            <p>
                                {trans(
                                    "This asset is not available, so you cannot use it."
                                )}
                            </p>
                        </Column>
                        {can("dam-asset-admin") && (
                            <IconTextButton
                                icon={"eye"}
                                onClick={() => setShowLiveActions(true)}
                                showCircle={false}
                            >
                                {trans("Temporarily Enable live actions")}
                            </IconTextButton>
                        )}
                    </Row>
                )}
                {userAsset.is_downloadable_by_user && (
                    <Row>
                        <IconTextButton
                            themeColor="alert"
                            onClick={(event) =>
                                confirm({
                                    variant: "danger",
                                    catchOnCancel: true,
                                    title: trans(
                                        "Are you sure you want to permanently delete this asset?"
                                    ),
                                    description: trans(
                                        "Once deleted it will not be retrievable."
                                    ),
                                })
                                    .then(() =>
                                        axios
                                            .delete(
                                                `/api/v1/user-assets/${userAsset.uuid}`
                                            )
                                            .then(() => {
                                                addToast({
                                                    type: "success",
                                                    content:
                                                        trans("Asset deleted"),
                                                    showFor: 5000,
                                                });
                                                getUserAssets();
                                                history.push(
                                                    `${userAssetPath}`
                                                );
                                            })
                                            .catch((e) => {
                                                console.log(e);
                                                addToast({
                                                    type: "alert",
                                                    content:
                                                        e.response &&
                                                        e.response.data
                                                            ? e.response.data
                                                                  .message
                                                            : trans(
                                                                  "An error has occurred"
                                                              ),
                                                    showFor: 5000,
                                                });
                                            })
                                    )
                                    .catch((e) => e)
                            }
                            icon={faTrashAlt}
                            showCircle={false}
                            expanded={false}
                            style={{
                                textDecoration: "none",
                                paddingTop: "0.8rem",
                                paddingBottom: "1rem",
                            }}
                        >
                            {trans("Delete Asset")}
                        </IconTextButton>
                    </Row>
                )}
            </Panel>
            {showShareModal && (
                <ShareModal
                    onClose={() => setShowShareModal(false)}
                    smartRef={userAsset.smartRef}
                    visible={showShareModal}
                />
            )}
            {showOrderPrintModal && (
                <PrintOrderModal
                    onClose={() => setShowOrderPrintModal(false)}
                    userAsset={userAsset}
                    visible={showOrderPrintModal}
                />
            )}
            {showBulkGenerateModal && (
                <BulkGenerateModal
                    group={group}
                    onClose={() => setShowBulkGenerateModal(false)}
                    userAsset={userAsset}
                    visible={showBulkGenerateModal}
                />
            )}
        </React.Fragment>
    );
};

export default ActionsPanel;
