import React from "react";
import styled from "styled-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";

const SummaryWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
`;

const SummaryColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 5px 10px;
    justify-content: center;
`;

export const BudgetColumnHeader = props => {
    return (
        <SummaryWrapper style={{ textAlign: "right" }}>
            <SummaryColumn>{trans("Planned")}</SummaryColumn>
            <SummaryColumn>{trans("Actual")}</SummaryColumn>
        </SummaryWrapper>
    );
};
export const BudgetColumnSummary = ({ task, user }) => {
    if (!task.can_view_details) {
        return null;
    }
    const cost = task.activities.reduce(
        (total, activity) => {
            total += activity.cost;
            return total;
        },

        0
    );
    return (
        <SummaryWrapper style={{ textAlign: "right" }}>
            <SummaryColumn style={{ fontSize: "0.8rem" }}>
                <FormattedCurrency
                    value={task.planned_spend}
                    currency={(user && user.currency) || "GBP"}
                />
            </SummaryColumn>
            {task.planned_spend < cost && (<SummaryColumn style={{ fontSize: "0.8rem", color: "#de0000" }}>
                <FormattedCurrency
                    value={cost}
                    currency={(user && user.currency) || "GBP"}
                />
            </SummaryColumn>)}

            {task.planned_spend >= cost && (<SummaryColumn style={{ fontSize: "0.8rem", color: "#de0000", fontWeight: "bold"  }}>
                <FormattedCurrency
                    value={cost}
                    currency={(user && user.currency) || "GBP"}
                />
            </SummaryColumn>)}
            
        </SummaryWrapper>
    );
};
