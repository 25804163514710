import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import EventCategoryListing from "./EventCategoryListing";
import EventCategoryCreate from "./EventCategoryCreate";
import { EventCategoryEdit } from "./EventCategoryEdit";

export const EventCategoryAdmin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <EventCategoryListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <EventCategoryCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <EventCategoryEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default EventCategoryAdmin;
