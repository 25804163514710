import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Panel,
    Tabordion,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import { HeaderEmphasis, TabordionGroup } from "../../components/index";
import { GroupFormSelector } from "./GroupFormSelector";
import ContactForm from "../../forms/ContactForm";
import GroupBranding from "./GroupBranding";
import GroupRelations from "../../forms/GroupRelations";
import GroupCategoriesForm from "../../forms/GroupCategoriesForm";
import BudgetForm from "../../forms/BudgetForm";
import { CommunicationForm } from "../../forms/CommunicationForm";

export const GroupEdit = ({ uuid, path }) => {
    const history = useHistory();
    const page = useQueryString().get("page");

    const [tab, setTab] = useState(0);
    const [item, setItem] = useState({ loaded: false, data: null });
    const getItem = () => {
        setTab(0);
        setItem({ loaded: false, data: null });
        axios.get(`/api/v1/admin/group/${uuid}`).then(({ data }) => {
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, [uuid]);
    const submitForm = (values, setSubmitting, section) => {
        return axios
            .post(`/api/v1/admin/group/${uuid}/${section}`, values)
            .then(({ data }) => {
                setSubmitting(false);
                setItem({
                    loaded: true,
                    data: data.data,
                });

                //As creation was successful move them on to the edit page
                // history.push(`${path}/edit/${data.uuid}`);
            });
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Edit Group")}{" "}
                        <HeaderEmphasis>
                            {item.data ? item.data.name : ""}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                {item.loaded && item.data?.parents[0] !== undefined && (
                    <IconTextButton
                        as={Link}
                        to={`${path}/edit/${item.data.parents[0].uuid}`}
                        rotation={180}
                        showCircle={false}
                        expanded={false}
                    >
                        {trans("Back to Parent")}
                    </IconTextButton>
                )}
                <IconTextButton
                    as={Link}
                    to={`${path}?page=${page ? page : 1}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to Listing")}
                </IconTextButton>
            </PageTitle>
            {item.loaded ? (
                <Row>
                    <Panel>
                        <Tabordion current={0} unmounts={true}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                            >
                                <GroupFormSelector
                                    group={item.data}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                            {item.data.entity &&
                                typeof item.data.entity.contact !==
                                    "undefined" && (
                                    <TabordionGroup
                                        heading={
                                            <span>
                                                {trans("Contact Details")}
                                            </span>
                                        }
                                    >
                                        <ContactForm
                                            item={item.data.entity.contact}
                                            submitForm={submitForm}
                                        />
                                    </TabordionGroup>
                                )}

                            <TabordionGroup
                                heading={<span>{trans("Related Groups")}</span>}
                            >
                                <GroupRelations
                                    item={item}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>

                            <TabordionGroup
                                heading={
                                    <span>{trans("Group Categories")}</span>
                                }
                            >
                                <GroupCategoriesForm
                                    item={item}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Branding")}</span>}
                            >
                                <GroupBranding
                                    item={item}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Budget")}</span>}
                            >
                                <BudgetForm
                                    item={item.data.contributor}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Communication")}</span>}
                            >
                                <CommunicationForm
                                    item={item}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                        </Tabordion>
                    </Panel>
                </Row>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default GroupEdit;
