import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableList } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const TableRow = styled.tr`
    ${props =>
        props.selected &&
        css`
            background-color: ${({ colors }) => colors.primary} !important;
        `};
`;

const renderCommsChannel = channel => {
    switch (channel) {
        case "phone":
            return <FontAwesomeIcon icon="phone" />;
        case "sms":
            return <FontAwesomeIcon icon="mobile-alt" />;
        case "email":
        default:
            return <FontAwesomeIcon icon="envelope" />;
    }
};

const CommsTable = props => {
    return (
        <div>
            {/* <h3>{trans("Live communications")}</h3> */}
            <div>
                <TableList className="dashboard-table">
                    <thead>
                        <tr>
                            <th />
                            <th>{trans("Customer")}</th>
                            <th>{trans("Account")}</th>
                            <th>{trans("Offer/Action")}</th>
                            <th>{trans("Send date")}</th>
                            <th>{trans("Channel")}</th>
                            <th>{trans("Opened")}</th>
                            <th>{trans("Clicked")}</th>
                            <th>{trans("Followed")}</th>
                            <th>{trans("Preview")}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {props.comms
                            .slice(0, 10)
                            .map((communication, index) => {
                                return (
                                    <TableRow key={index} index={index}>
                                        <td>
                                            <FontAwesomeIcon icon="download" />
                                        </td>
                                        <td>{communication.customerName}</td>
                                        <td>
                                            {communication.externalIdentifier}
                                        </td>
                                        <td>
                                            {communication.communicationName}
                                        </td>
                                        <td>{communication.sendDate}</td>
                                        <td>
                                            {renderCommsChannel(
                                                communication.method
                                            )}
                                        </td>
                                        <td>{communication.opened}</td>
                                        <td>{communication.clicked}</td>
                                        <td>
                                            <div
                                                onClick={props.setFollowedStatus.bind(
                                                    this,
                                                    communication,
                                                    index
                                                )}
                                            >
                                                {communication.followed ? (
                                                    <FontAwesomeIcon icon="check-square" />
                                                ) : (
                                                    <FontAwesomeIcon icon="square" />
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                onClick={props.previewCommunication.bind(
                                                    this,
                                                    communication
                                                )}
                                            >
                                                <FontAwesomeIcon icon="search" />
                                            </div>
                                        </td>
                                    </TableRow>
                                );
                            })}
                    </tbody>
                </TableList>
            </div>
        </div>
    );
};

export default CommsTable;
