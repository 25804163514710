import React, { Component } from "react";
import PropTypes from "prop-types";
import Autocomplete from "downshift";
import axios from "axios";
import moment from "moment";
import debounce from "lodash/debounce";
import {
    Row,
    Column,
    Button,
    StyledInput,
    SubmitButton,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import styled from "styled-components";

const ItemList = styled.div`
    position: absolute;
    margin-top: -1rem;
    z-index: 1;
    max-height: 90%;
    min-width: 50%;
    overflow: scroll;
    border-radius: ${(props) => props.theme.input.radius};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.75);
    > div {
        padding: 1rem;
        :hover {
            color: ${(props) => props.theme.colors.white};
            /* background: ${(props) => props.theme.colors.gray600}; */
        }
    }
`;
class RelateTaskForm extends Component {
    constructor(props) {
        super(props);
        this.delayedSearch = debounce(this.getRelatableTasks, 500);
    }
    state = {
        items: [],
        selectedItem: null,
        value: "",
    };
    itemSelected = (selectedItem) => {
        // console.log(selectedItem);
        this.setState({ selectedItem });
    };

    searchUpdated = (event) => {
        const value = event.target.value;
        this.setState({ value });
        event.persist();
        this.delayedSearch(event);
    };

    getRelatableTasks = (event) => {
        const { task } = this.props;

        // would probably be a good idea to debounce this
        // 😅
        const value = event.target.value;
        this.setState({ value });
        if (!value) {
            return;
        }
        axios
            .get(`/api/v1/planner/tasks/${task.uuid}/relatable?q=${value}`)
            .then((response) => {
                this.setState({
                    items: response.data.data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    relateTask = (event) => {
        event.preventDefault();
        this.props.relateTask(this.props.task.uuid, this.state.selectedItem);
    };
    render() {
        const { items, selectedItem, value } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <h2>{trans("Select a Campaign")}</h2>
                        <p>
                            {trans(
                                "Please type in a campaign name and select the related campaign"
                            )}
                        </p>
                    </Column>
                </Row>
                <form onSubmit={this.relateTask}>
                    <Row>
                        <Column medium={6}>
                            <Autocomplete
                                onChange={this.itemSelected}
                                onStateChange={this.updateValue}
                                itemToString={(i) => (i == null ? "" : i.name)}
                                inputValue={value}
                            >
                                {({
                                    selectedItem,
                                    getInputProps,
                                    getItemProps,
                                    highlightedIndex,
                                    isOpen,
                                }) => {
                                    // console.log({ ...getInputProps() });
                                    return (
                                        <div>
                                            <StyledInput
                                                {...getInputProps({
                                                    // onChange: this.searchUpdated
                                                    onChange: (e) => {
                                                        return this.searchUpdated(
                                                            e
                                                        );
                                                    },
                                                })}
                                                name="selectedItem"
                                            />
                                            {isOpen && (
                                                <ItemList>
                                                    {items.map(
                                                        (item, index) => {
                                                            // console.log("item", item);
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    {...getItemProps(
                                                                        {
                                                                            item,
                                                                            index,
                                                                            style: {
                                                                                backgroundColor:
                                                                                    highlightedIndex ===
                                                                                    index
                                                                                        ? "gray"
                                                                                        : "white",
                                                                                fontWeight:
                                                                                    selectedItem ===
                                                                                    item
                                                                                        ? "bold"
                                                                                        : "normal",
                                                                            },
                                                                        }
                                                                    )}
                                                                >
                                                                    {/* {`${item.id} - ${item.name}`} */}
                                                                    {`${item.name}`}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </ItemList>
                                            )}
                                        </div>
                                    );
                                }}
                            </Autocomplete>
                        </Column>
                        <Column medium={6}>
                            {selectedItem && (
                                <React.Fragment>
                                    <h4>
                                        <small>{selectedItem.id}</small> -{" "}
                                        {selectedItem.name}
                                    </h4>

                                    <p>{selectedItem.description}</p>
                                    <p>
                                        {moment(selectedItem.start_date).format(
                                            "LL"
                                        )}{" "}
                                        -{" "}
                                        {moment(selectedItem.end_date).format(
                                            "LL"
                                        )}
                                    </p>
                                </React.Fragment>
                            )}
                        </Column>
                    </Row>
                    <Row>
                        <Column
                            style={{ justifyContent: "end", display: "flex" }}
                        >
                            <Button
                                style={{ marginRight: "1rem" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        selectedItem: null,
                                        value: "",
                                    });
                                }}
                            >
                                {trans("Select a Different Campaign")}
                            </Button>
                            <SubmitButton disabled={selectedItem === null}>
                                {trans("Select this Campaign")}
                            </SubmitButton>
                        </Column>
                    </Row>
                </form>
            </React.Fragment>
        );
    }
}

RelateTaskForm.propTypes = {
    task: PropTypes.object,
};

export default RelateTaskForm;
