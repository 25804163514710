import React, { Component } from "react";
import { trans } from "@cortexglobal/rla-intl";
import { v4 as uuid } from "uuid";
import {
    Row,
    InputField,
    Column,
    Button,
    SelectField,
    IconTextButton,
} from "@cortexglobal/rla-components";
import {
    faSave,
    faTimes,
    faMinusCircle,
    faPlus,
    faCopy,
    faUnlock,
} from "@fortawesome/free-solid-svg-icons";

class FolderForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new: props.data.uuid ? false : true,
            slugLocked: true,
            formData: {
                uuid: props.data.uuid ? props.data.uuid : uuid(),
                name: props.data.name ? props.data.name : "",
                slug: props.data.slug ? props.data.slug : "",
                gallery: props.data.gallery ? props.data.gallery : [],
                folders: props.data.folders ? props.data.folders : [],
                subFolders: [],
                parents: [],
                contains: [],
            },
        };
    }

    componentWillMount() {
        if (this.props.data.uuid) {
            this.props.getFolders(this.props.data.uuid);
        } else {
            let formData = this.state.formData;
            formData.parents = [this.props.currentFolder];
            this.setState({ formData: formData });
        }
    }

    componentWillReceiveProps(props) {
        if (props.currentFolder) {
            let formData = this.state.formData;
            formData.subFolders = props.currentFolder.subFolders;
            formData.parents = props.currentFolder.parentFolder;
            formData.contains = props.currentFolder.contains;
            this.setState({ formData: formData });
        }
    }

    onChange = (input) => {
        let form = {
            ...this.state.formData,
            [input.name]: input.value,
        };
        this.setState({ formData: form });
    };

    availableFolders() {
        let { parents, subFolders } = this.state.formData;
        let filteredOptions = this.props.options.folders.filter(
            (folder) =>
                parents.filter(
                    (p) =>
                        folder.value === p.uuid ||
                        folder.value === this.props.data.uuid
                ).length !== 1
        );
        let finalFilterd = filteredOptions.filter(
            (folder) =>
                subFolders.filter((sub) => folder.value === sub.uuid).length !==
                1
        );

        return finalFilterd;
    }

    close = () => {
        this.props.close();
    };

    removeSelectItem = (field, index) => {
        let newList = field.splice(index, 1);
        this.setState(newList);
    };

    addSelectItem = (field, target) => {
        for (let item of this.props.options.folders) {
            let tempFolder = this.folderMap(item, this.state.formData[field]);
            if (tempFolder) {
                let nodes = this.state.formData[target];
                nodes.push(tempFolder);
                let updatedForm = {
                    ...this.state.formData,
                    [target]: nodes,
                    [field]: "",
                };
                this.setState({ formData: updatedForm });
                break;
            }
        }
    };

    folderMap(item, value) {
        if (item.value === value) {
            return {
                name: item.text,
                uuid: item.value,
            };
        }
        return null;
    }

    save = () => {
        this.props.save(this.state.formData);
    };

    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };
    unlockSlug = () => {
        this.setState({ slugLocked: false });
    };

    render() {
        const { formData } = this.state;
        const { subFolders, parents, contains } = formData;

        return (
            <div>
                <Row>
                    <Column medium={8}>
                        <h2>{trans("General")}</h2>
                    </Column>
                    <Column medium={4}>
                        <IconTextButton
                            onClick={this.save}
                            showCircle={false}
                            expanded={false}
                            icon={faSave}
                        >
                            {trans("Update Record")}
                        </IconTextButton>{" "}
                        <IconTextButton
                            onClick={this.close}
                            showCircle={false}
                            expanded={false}
                            icon={faTimes}
                        >
                            {trans("Close / Search")}
                        </IconTextButton>
                    </Column>
                </Row>

                <Row>
                    <Column medium={2}>
                        <strong>{trans("Name")}</strong>
                    </Column>
                    <Column medium={4}>
                        <InputField
                            type="text"
                            name="name"
                            inlineLabel={false}
                            onChange={this.onChange}
                            value={formData.name}
                            expanded
                        />
                    </Column>
                    <Column medium={2}>
                        <strong>{trans("Created")}</strong>
                    </Column>
                    <Column medium={4}>{this.props.data.created_at}</Column>
                </Row>

                {this.props.data.slug && (
                    <Row>
                        <Column medium={2}>
                            <strong>{trans("Slug")}</strong>
                            <IconTextButton
                                style={{ marginTop: "-10px" }}
                                onClick={this.copyToClipboard.bind(
                                    this,
                                    this.props.data.slug
                                )}
                                showCircle={false}
                                expanded={false}
                                icon={faCopy}
                            >
                                {trans("Copy")}
                            </IconTextButton>
                        </Column>
                        {this.state.slugLocked ? (
                            <React.Fragment>
                                <Column medium={3}>
                                    <span style={{ color: "#777" }}>
                                        {formData.slug}
                                    </span>
                                </Column>
                                <Column medium={1}>
                                    <IconTextButton
                                        style={{ marginTop: "-10px" }}
                                        onClick={this.unlockSlug}
                                        showCircle={false}
                                        expanded={false}
                                        icon={faUnlock}
                                    ></IconTextButton>
                                </Column>
                            </React.Fragment>
                        ) : (
                            <Column medium={4}>
                                <InputField
                                    type="text"
                                    name="slug"
                                    inlineLabel={false}
                                    onChange={this.onChange}
                                    value={formData.slug}
                                    expanded
                                />
                            </Column>
                        )}
                        <Column medium={2}>
                            <strong>{trans("uuid")}</strong>
                            <IconTextButton
                                style={{ marginTop: "-10px" }}
                                onClick={this.copyToClipboard.bind(
                                    this,
                                    formData.uuid
                                )}
                                showCircle={false}
                                expanded={false}
                                icon={faCopy}
                            >
                                {trans("Copy")}
                            </IconTextButton>
                        </Column>
                        <Column medium={4}>
                            <span style={{ color: "#777" }}>
                                {formData.uuid}
                            </span>
                        </Column>
                    </Row>
                )}

                {/* <Row>
                    <Column medium={2}>
                        <strong>{trans("Slug")}</strong>
                    </Column>
                    <Column medium={4}>
                        <InputField
                            type="text"
                            name="slug"
                            inlineLabel={false}
                            onChange={this.onChange}
                            value={formData.slug}
                            expanded
                        />
                    </Column>
                    <Column medium={2}>
                        <strong>{trans("Created")}</strong>
                    </Column>
                    <Column medium={4}>{this.props.data.created_at}</Column>
               </Row>*/}

                <Row>
                    <Column>
                        <hr />
                    </Column>
                </Row>
                <Row>
                    <Column medium={8}>
                        <h2>{trans("Structure")}</h2>
                    </Column>
                </Row>

                <Row>
                    <Column medium={6}>
                        <h3>Parent Folder(s)</h3>
                        <table className="dashboard-table">
                            <tbody>
                                {subFolders &&
                                    Object.keys(parents).map((index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{parents[index].name}</td>
                                                <td>
                                                    <IconTextButton
                                                        onClick={this.removeSelectItem.bind(
                                                            this,
                                                            parents,
                                                            index
                                                        )}
                                                        showCircle={false}
                                                        expanded={false}
                                                        icon={faMinusCircle}
                                                    >
                                                        {trans("Remove")}
                                                    </IconTextButton>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                <tr>
                                    <td>
                                        <SelectField
                                            type="text"
                                            name="parentSelect"
                                            inlineLabel={false}
                                            options={this.availableFolders()}
                                            onChange={this.onChange}
                                            expanded
                                        />
                                    </td>
                                    <td>
                                        <IconTextButton
                                            onClick={this.addSelectItem.bind(
                                                this,
                                                "parentSelect",
                                                "parents"
                                            )}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faPlus}
                                        >
                                            {trans("Add")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Column>

                    <Column medium={6}>
                        <h3>Sub Folders</h3>
                        <table className="dashboard-table">
                            <tbody>
                                {subFolders &&
                                    Object.keys(subFolders).map((index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {subFolders[index].name}
                                                </td>
                                                <td>
                                                    <IconTextButton
                                                        onClick={this.removeSelectItem.bind(
                                                            this,
                                                            subFolders,
                                                            index
                                                        )}
                                                        showCircle={false}
                                                        expanded={false}
                                                        icon={faMinusCircle}
                                                    >
                                                        {trans("Remove")}
                                                    </IconTextButton>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                <tr>
                                    <td>
                                        <SelectField
                                            type="text"
                                            name="subFolderSelect"
                                            inlineLabel={false}
                                            onChange={this.onChange}
                                            options={this.availableFolders()}
                                            expanded
                                        />
                                    </td>
                                    <td>
                                        <IconTextButton
                                            onClick={this.addSelectItem.bind(
                                                this,
                                                "subFolderSelect",
                                                "subFolders"
                                            )}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faPlus}
                                        >
                                            {trans("Add")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <hr />
                    </Column>
                </Row>

                <Row>
                    <Column medium={8}>
                        <h2>
                            {trans("Permissions")} / {trans("Avalability")}
                        </h2>
                    </Column>
                </Row>
            </div>
        );
    }
}

export default FolderForm;
