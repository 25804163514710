import React, { useState } from "react";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSuperscript,
} from "@fortawesome/free-solid-svg-icons";

import {
    PlainButton,
} from "@cortexglobal/rla-components";
import { useIntl } from "@cortexglobal/rla-intl";

import ManageFootnotesModal from "./ManageFootnotesModal";

const FootnoteButton = styled(PlainButton)`
    margin-top: 0;
    float: right;
`;

export const ManageFootnotes = ({ asset, path, tag, onChange, index }) => {
    const [showModal, setShowModal] = useState(false);
    const intl = useIntl();

    return (
        <React.Fragment>
            <FootnoteButton
                onClick={() => setShowModal(true)}
                title={intl.formatMessage({ id: "Manage references" })}
            >
                <FontAwesomeIcon icon={faSuperscript} />
            </FootnoteButton>
            {showModal && (
                <ManageFootnotesModal
                    index={index}
                    asset={asset}
                    path={path}
                    tag={tag}
                    onChange={onChange}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
        </React.Fragment>
    );
};

export default ManageFootnotes;
