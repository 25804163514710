import React, { useState, useContext, useCallback, useEffect } from "react";
import axios from "axios";

import {
    IconTextButton,
    Modal,
    Column,
    Row,
} from "@cortexglobal/rla-components";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import AssetContext from "../AssetContext";

const CreateAssetMinorVersion = ({ assetsPath, asset }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showVersionWarning, setShowVersionWarning] = useState(false);
    const { can } = usePermissions();
    const { user, history, setRefreshSearch } = useContext(AssetContext);

    const createMinor = useCallback(
        (event) => {
            //event.preventDefault();
            axios
                .post(`/api/v1/admin/assets/create-minor`, {
                    assetUuid: asset.uuid,
                })
                .then(({ data: { data } }) => {
                    setRefreshSearch(true);
                    history.push(
                        `${assetsPath}/${data.uuid}/${
                            can("dam-asset-admin") ? "admin" : "edit"
                        }`
                    );
                })
                .catch((e) => console.log(e));
        },
        [asset]
    );
    const draftVersions = asset.allVersions.filter(
        (version) => version.status === "draft"
    );
    return (
        <React.Fragment>
            <IconTextButton
                type="primary"
                expanded
                icon={faPlus}
                showCircle={false}
                onClick={(event) => {
                    //TODO - Check for other draft assets in the allVersions and then pop-up a confirmation dialog
                    //with the options to create the new version or view the versions table
                    if (draftVersions.length > 0) {
                        setShowVersionWarning(true);
                    } else {
                        setShowConfirmation(true);
                    }
                }}
            >
                {trans("New Version")}
            </IconTextButton>
            <Modal
                onClose={() => setShowConfirmation(false)}
                visible={showConfirmation}
            >
                <h4>{trans("Please Note")}</h4>
                <p>
                    {trans(
                        "As this is a live asset a new draft version will be created.  Once the draft version is made live it will replace the current version"
                    )}
                </p>
                <Row>
                    <Column medium={6}>
                        <IconTextButton
                            type="primary"
                            expanded
                            icon={faTimes}
                            showCircle={false}
                            onClick={(event) => {
                                setShowConfirmation(false);
                            }}
                        >
                            {trans("Cancel")}
                        </IconTextButton>
                    </Column>
                    <Column medium={6}>
                        <IconTextButton
                            type="primary"
                            expanded
                            icon={faPlus}
                            showCircle={false}
                            onClick={createMinor}
                        >
                            {trans("Continue")}
                        </IconTextButton>
                    </Column>
                </Row>
            </Modal>
            <Modal
                onClose={() => setShowVersionWarning(false)}
                visible={showVersionWarning}
            >
                <h4>{trans("Are you sure?")}</h4>
                <p>
                    {trans(
                        "There are other draft versions of this asset, you can view and select them from the versions tab"
                    )}
                </p>
                <Row>
                    <Column medium={6}>
                        <IconTextButton
                            type="primary"
                            expanded
                            icon={faTimes}
                            showCircle={false}
                            onClick={(event) => {
                                setShowVersionWarning(false);
                            }}
                        >
                            {trans("Cancel")}
                        </IconTextButton>
                    </Column>
                    <Column medium={6}>
                        <IconTextButton
                            type="primary"
                            expanded
                            icon={faPlus}
                            showCircle={false}
                            onClick={createMinor}
                        >
                            {trans("Continue")}
                        </IconTextButton>
                    </Column>
                </Row>
            </Modal>
        </React.Fragment>
    );
};

export default CreateAssetMinorVersion;
