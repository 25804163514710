import React, { useState } from "react";
import add from "date-fns/add";

import {
    Button,
    DatePicker,
    InputField,
    TextareaField,
    FormRow,
    Loading,
    Panel,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const GeneralOrderForm = ({ order, standardOnChange, errors }) => {
    const minDate = add(new Date(), {
        days: order?.user_asset?.asset?.order_flow_details?.lead_time_days || 0,
    });

    return (
        <>
            <FormRow
                name="required_date"
                label={trans("Required Date")}
                error={errors.required_date}
                helpText={trans("When is the activity occurring?")}
            >
                <DatePicker
                    name="required_date"
                    value={order.required_date}
                    showError={false}
                    onChange={standardOnChange}
                    minDate={minDate}
                />
            </FormRow>

            <FormRow
                required
                name="contact_name"
                label={trans("Contact Name")}
                error={errors.contact_name}
                helpText={trans("The name of the main contact")}
            >
                <InputField
                    name="contact_name"
                    value={order.contact_name}
                    showError={false}
                    onChange={standardOnChange}
                />
            </FormRow>

            <FormRow
                required
                name="contact_email"
                label={trans("Contact Email Address")}
                error={errors.contact_email}
                helpText={trans("Email address for contact")}
            >
                <InputField
                    name="contact_email"
                    value={order.contact_email}
                    showError={false}
                    onChange={standardOnChange}
                />
            </FormRow>

            <FormRow
                name="contact_telephone"
                label={trans("Contact Telephone")}
                error={errors.contact_telephone}
                helpText={trans("Telephone number for contact")}
            >
                <InputField
                    name="contact_telephone"
                    value={order.contact_telephone}
                    showError={false}
                    onChange={standardOnChange}
                />
            </FormRow>

            <FormRow
                name="special_instructions"
                label={trans("Special Instructions")}
                error={errors.special_instructions}
                helpText={trans(
                    "Anything else you feel would be valuable to add"
                )}
            >
                <TextareaField
                    name="special_instructions"
                    value={order.special_instructions}
                    showError={false}
                    onChange={standardOnChange}
                />
            </FormRow>

            {false && (
                <FormRow
                    name="objective"
                    label={trans("Objective")}
                    error={errors.objective}
                    helpText={trans(
                        "Please select the main objective of this activity"
                    )}
                >
                    {/** Add an objective selector here */}
                </FormRow>
            )}
        </>
    );
};

export default GeneralOrderForm;
