import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { get } from "lodash";

import {
    PageTitle,
    useAddToast,
    Tabordion,
    Row,
    Error,
    Column,
    Button,
    SubmitButton,
    Panel,
    StyledRadio,
    HeaderWithErrorIndicated,
    useSectionErrors,
    Loading,
    Modal,
    IconTextButton,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import InventoryBrowser from "./InventoryBrowser";
import InventoryItemDetails from "./InventoryItemDetails";

const EquipmentBrowser = ({ path, settings, settingsLoadingState }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const onItemClick = (item) => {
        setSelectedItem(item);
    };

    return (
        <>
            <PageTitle title={trans("Equipment Browser")}>
                <IconTextButton
                    icon="plus"
                    as={Link}
                    to={`${path}/planner/`}
                    showCircle={false}
                >
                    {trans("Create an Event")}
                </IconTextButton>
            </PageTitle>
            <Row>
                {settingsLoadingState === "loaded" ? (
                    <InventoryBrowser
                        event={null}
                        settings={settings}
                        onItemClick={onItemClick}
                    />
                ) : (
                    <Loading />
                )}
            </Row>
            {selectedItem !== null && (
                <Modal
                    visible={selectedItem !== null}
                    onClose={() => setSelectedItem(null)}
                    maxWidth="800px"
                >
                    <InventoryItemDetails
                        item={selectedItem}
                        showCreateEvent={true}
                        eventsPath={path}
                        settings={settings}
                    />
                </Modal>
            )}
        </>
    );
};

export default EquipmentBrowser;
