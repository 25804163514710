import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router";
import { trans } from "@cortexglobal/rla-intl";
import { IconTextButton } from "@cortexglobal/rla-components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Summary = styled.aside`
    text-align: right;
    font-size: 0.7rem;
`;

class CategorySummary extends Component {
    render() {
        const { category, addTaskCategory } = this.props;

        if (category.can_admin_items) {
            return (
                <Summary>
                    <IconTextButton
                        themeColor="white"
                        onClick={() => addTaskCategory(category)}
                        icon={faPlus}
                        showCircle={false}
                        height={16}
                        style={{ padding: 0 }}
                    >
                        {trans("Add Category")}
                    </IconTextButton>
                </Summary>
            );
        }

        return null;

        //OLD SUMMARY
        //        const { category: {tasks} } = this.props;

        // return (
        //     <Summary>
        //         {trans(
        //             "{tasks, number} Planned {tasks, plural, one {task} other { tasks}}",
        //             { tasks: tasks.length }
        //         )}{" "}
        //         ({tasks.filter(task => task.type === "national").length}{" "}
        //         {trans("National")}
        //         {", "}
        //         {tasks.filter(task => task.type === "group").length}{" "}
        //         {trans("Dealer")})
        //     </Summary>
        // );
    }
}

export default CategorySummary;
