import React, { MouseEvent, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import MicrositeContext from "../MicrositeContext";
import { OverlayNavigation } from "./navigation/OverlayNavigation";
import { SlideOutNavigation } from "./navigation/SlideOutNavigation";

export const Navigation = ({
    visible,
    onClose,
    pages,
}: {
    visible: boolean;
    onClose: () => void;
    pages: any[];
}) => {
    const { globalSettings, navigationDispatch, slideSize } =
        useContext(MicrositeContext);

    if (globalSettings?.navigationStyle === "slideout") {
        return (
            <SlideOutNavigation
                onClose={onClose}
                visible={visible}
                pages={pages}
            />
        );
    }
    return (
        <OverlayNavigation onClose={onClose} visible={visible} pages={pages} />
    );
};
