import React, { useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import uuid from "uuid/v4";
import cloneDeep from "lodash/cloneDeep";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlusCircle,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { trans } from "@cortexglobal/rla-intl";
import { Button } from "@cortexglobal/rla-components";

import SurveyManagementContext from "../SurveyManagementContext";
import SurveyQuestion from "./SurveyQuestion";

const SurveyButtons = styled.div`
    margin-bottom: ${(props) => `${props.theme.spacing.margin}rem`};
    display: flex;
    gap: 1.2em;
    justify-content: flex-start;
    align-items: center;
`;

const SurveyQuestionListPanel = styled.div``;
const Container = styled.div``;

const WarningContainer = styled.div`
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: ${(props) => props.theme.colors.warning};
    color: #fff;
`;

const SurveyQuestionList = ({ setIsDragging, surveyData, setSurveyData }) => {
    const { surveySystem } = useContext(SurveyManagementContext);
    const { isSubmitting, disableEdit, hasEntries } = surveySystem;

    const surveyUuid = surveyData.uuid;
    const addQuestion = (e) => {
        const newQuestion = {
            uuid: uuid(),
            question: "",
            caption: "",
            type: "",
            options: {
                range: {
                    min_value: 0,
                    max_value: 10,
                    default_value: 5,
                },
                choice: [],
            },
            required: false,
        };

        setSurveyData({
            ...surveyData,
            questions: [...(surveyData?.questions || []), newQuestion],
        });
    };

    const removeQuestion = (index) => {
        if (!isSubmitting) {
            let updatedQuestions = [...surveyData.questions];

            updatedQuestions.splice(index, 1);

            setSurveyData({
                ...surveyData,
                questions: updatedQuestions,
            });
        }
    };

    const onDragStart = (result) => {
        setIsDragging(true);
    };

    const onDragEnd = (result) => {
        if (
            result.source?.droppableId == "questionList" &&
            result.destination?.droppableId == "questionList"
        ) {
            let updatedQuestions = cloneDeep(surveyData.questions);
            updatedQuestions = reorderSurveyArray(
                updatedQuestions,
                result.source.index,
                result.destination.index
            );
            setSurveyData({
                ...surveyData,
                questions: updatedQuestions,
            });
        }

        if (
            result.source?.droppableId.startsWith("optionList") &&
            result.source?.droppableId === result.destination?.droppableId
        ) {
            const questionIndex = result.source?.droppableId.split("_").pop();
            let updatedQuestions = cloneDeep(surveyData.questions);

            updatedQuestions[questionIndex].options.choice = reorderSurveyArray(
                updatedQuestions[questionIndex].options.choice,
                result.source.index,
                result.destination.index
            );

            setSurveyData({
                ...surveyData,
                questions: updatedQuestions,
            });
        }
        setIsDragging(false);
    };

    const reorderSurveyArray = (array, sourceIndex, destinationIndex) => {
        const [removed] = array.splice(sourceIndex, 1);
        array.splice(destinationIndex, 0, removed);
        return array;
    };

    const renderButtons = () => (
        <>
            {!disableEdit && (
                <SurveyButtons>
                    <Button
                        onClick={addQuestion}
                        disabled={isSubmitting}
                        style={{ marginTop: "1rem" }}
                    >
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            size="lg"
                            style={{ marginRight: "0.25rem" }}
                        />
                        {trans("Add Question")}
                    </Button>
                </SurveyButtons>
            )}
        </>
    );

    return (
        <>
            {disableEdit === true && (
                <WarningContainer>
                    {trans(
                        "This survey has responses. No changes to the questions can be made."
                    )}
                </WarningContainer>
            )}
            {disableEdit === false && hasEntries === true && (
                <WarningContainer>
                    <h3>
                        <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                        {trans("This survey has responses.")}
                    </h3>
                    <p>
                        {trans(
                            "You can make changes, however be aware this may effect users responses."
                        )}
                    </p>
                </WarningContainer>
            )}

            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="questionList" type="QUESTIONS">
                    {(dropProvided, dropSnapshot) => (
                        <SurveyQuestionListPanel
                            {...dropProvided.droppableProps}
                            ref={dropProvided.innerRef}
                        >
                            {(surveyData?.questions || []).map(
                                (question, index) => (
                                    <Draggable
                                        key={question.uuid}
                                        draggableId={question.uuid}
                                        index={index}
                                        isDragDisabled={
                                            isSubmitting || disableEdit
                                        }
                                    >
                                        {(provided, snapshot) => (
                                            <Container
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <SurveyQuestion
                                                    question={question}
                                                    index={index}
                                                    isSubmitting={isSubmitting}
                                                    surveyData={surveyData}
                                                    setSurveyData={
                                                        setSurveyData
                                                    }
                                                    disableEdit={disableEdit}
                                                    removeQuestion={
                                                        removeQuestion
                                                    }
                                                    surveyUuid={surveyUuid}
                                                />
                                            </Container>
                                        )}
                                    </Draggable>
                                )
                            )}
                            {dropProvided.placeholder}
                        </SurveyQuestionListPanel>
                    )}
                </Droppable>
            </DragDropContext>
            {renderButtons()}
        </>
    );
};

export default SurveyQuestionList;
