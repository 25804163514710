import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import FeaturedItemListing from "./FeaturedItemListing";
import FeaturedItemCreate from "./FeaturedItemCreate";
import { FeaturedItemEdit } from "./FeaturedItemEdit";

export const FeaturedItemAdmin = () => {
    const { path } = useRouteMatch();

    // console.log(items);
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <FeaturedItemListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <FeaturedItemCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={props => {
                    const {
                        match: {
                            params: { uuid }
                        }
                    } = props;
                    return <FeaturedItemEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default FeaturedItemAdmin;
