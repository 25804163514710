import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    ContentBlockLoader,
    SubmitButton,
    FormikAdvancedSelect,
    FormikInput,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    LoadingIconSmall,
    useAddToast,
    useToasts,
    Toggle,
    IconTextButton,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans, useIntl } from "@cortexglobal/rla-intl";
///@ts-ignore cortex
import { useModule } from "@cortexglobal/cortex-auth-redux";

import { PreviewImage, LocaleSwitcher } from "../components/index";

const FILE_SIZE = 1160 * 1024 * 12;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = Yup.object().shape({
    locale: Yup.string().nullable(),
    name: Yup.string().required(trans("Required")),
    slug: Yup.string().required(trans("Required")),
    description: Yup.string().nullable(),
    assetTypeIds: Yup.array().nullable(),
    available_in_asset_library: Yup.boolean().nullable(),
    available_in_planner: Yup.boolean().nullable(),
    custom_form_id: Yup.number().nullable(),
    // meta_fields: Yup.array().nullable(),
    order: Yup.number(),
    // color: Yup.string().nullable(),
    thumbnail: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                if (!value) {
                    return Yup.mixed();
                }
                return (
                    Yup.mixed()
                        // .required("A thumbnail is required")
                        .test(
                            "fileSize",
                            "File too large",
                            (value) => value.size <= FILE_SIZE
                        )
                        .test("fileFormat", "Unsupported Format", (value) =>
                            SUPPORTED_FORMATS.includes(value.type)
                        )
                ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
});
// .required();

// export type MediaChannel = ReturnType<typeof schema.validateSync> & {
export type MediaChannel = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    slug?: string;
    i18n?: { [key: string]: any };
};

export const MediaChannelForm = ({ item, submitForm }) => {
    const { modules } = useModule();
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();
    const intl = useIntl();

    const [assetTypes, setAssetTypes] = useState([]);
    const [assetTypesLoaded, setAssetTypesLoaded] = useState(false);

    const [customForms, setCustomForms] = useState([]);
    const [customFormsLoaded, setCustomFormsLoaded] = useState(false);

    const [newPreviewFile, setNewPreviewFile] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/v1/admin/asset-type?optionList=true`)
            .then(({ data }) => {
                setAssetTypes(data.data);
                setAssetTypesLoaded(true);
            });
        axios
            .get(`/api/v1/admin/custom-form?optionList=true&type=media_channel`)
            .then(({ data }) => {
                setCustomForms(data.data);
                setCustomFormsLoaded(true);
            });
    }, []);
    const handleSubmit = (
        values: MediaChannel,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();
        Object.keys(values).forEach((key) => fd.append(key, values[key]));
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: trans("Saving"),
        });
        submitForm(fd, setSubmitting)
            // submitForm(values, setSubmitting)
            .then((data) => {
                addToast({
                    content: trans("Media Channel saved"),
                    type: "success",
                    showFor: 3000,
                });
            })
            .catch((e) => {
                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred updating the media channel"
                        ),
                        showFor: 5000,
                    });
                }
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });
            })
            .finally(() => {
                setSubmitting(false);
                dispatch({ type: "removeToast", uuid: "loadToast" });
            });
    };
    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );
    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("thumbnail", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);

    return (
        <Formik
            initialValues={{
                locale: item.locale ? item.locale : null,
                name: item.name,
                slug: item.slug,
                description: item.description,
                assetTypeIds: item.assetTypeIds ? item.assetTypeIds : [],
                available_in_asset_library:
                    item.available_in_asset_library !== null
                        ? item.available_in_asset_library
                        : true,
                available_in_planner:
                    item.available_in_planner !== null
                        ? item.available_in_planner
                        : true,
                custom_form_id: item.custom_form_id
                    ? item.custom_form_id
                    : null,
                // meta_fields: item.meta_fields ? item.meta_fields : [],
                order: item.order ? item.order : 0,
                // color: item.color,
                thumbnail: item.thumbnail,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="locale"
                            label={trans("Locale")}
                            helpText={trans("Optional")}
                            error={errors.locale}
                        >
                            <LocaleSwitcher
                                item={item}
                                setFieldValue={setFieldValue}
                                //@ts-ignore
                                values={values}
                            />
                        </FormRow>
                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Required")}
                            error={errors.name}
                            required={true}
                        >
                            <FormikInput
                                name="name"
                                value={values.name}
                                placeholder={item.slug ? item.slug : ""}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="slug"
                            label={trans("Slug")}
                            helpText={trans(
                                "Required, used in the URL so must be unique and contain only letters, numbers and dashes"
                            )}
                            error={errors.slug}
                            required={true}
                        >
                            <FormikInput
                                name="slug"
                                value={values.slug}
                                placeholder={item.slug ? item.slug : ""}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="description"
                            label={trans("Description")}
                            helpText={trans("Optional")}
                            error={errors.description}
                        >
                            <FormikInput
                                name="description"
                                value={values.description}
                                showError={false}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="assetTypeIds"
                            label={trans("Asset Type(s)")}
                            helpText={trans(
                                "Used to specify the type of asset that can be uploaded to this channel"
                            )}
                            error={errors.assetTypeIds}
                        >
                            {assetTypesLoaded ? (
                                <FormikAdvancedSelect
                                    name="assetTypeIds"
                                    value={values.assetTypeIds}
                                    options={assetTypes}
                                    showError={false}
                                    multi={true}
                                />
                            ) : (
                                <LoadingIconSmall />
                            )}

                            {/* <ContentBlockLoader loaded={assetTypesLoaded}>
                                <FormikAdvancedSelect
                                    name="assetTypeIds"
                                    value={values.assetTypeIds}
                                    options={assetTypes}
                                    showError={false}
                                    multi={true}
                                />{" "}
                            </ContentBlockLoader> */}
                        </FormRow>
                        <FormRow
                            name="available_in_asset_library"
                            label={trans("Available in Asset Library")}
                            helpText={trans(
                                "Whether this channel is available in the Asset Library"
                            )}
                            error={errors.available_in_asset_library}
                        >
                            <Toggle
                                name="available_in_asset_library"
                                checked={values.available_in_asset_library}
                                onChange={(e) => {
                                    setFieldValue(
                                        "available_in_asset_library",
                                        e.target.checked
                                    );
                                }}
                            />
                        </FormRow>

                        {modules.planner?.enabled && (
                            <FormRow
                                name="available_in_planner"
                                label={trans("Available in Marketing Planner")}
                                helpText={trans(
                                    "Whether this channel is available in the Marketing Planner"
                                )}
                                error={errors.available_in_planner}
                            >
                                <Toggle
                                    name="available_in_planner"
                                    checked={values.available_in_planner}
                                    onChange={(e) => {
                                        setFieldValue(
                                            "available_in_planner",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </FormRow>
                        )}

                        <FormRow
                            name="custom_form_id"
                            label={trans("Custom Form")}
                            helpText={trans(
                                "Optional. Used to specify a custom form to be displayed when uploading assets to this channel"
                            )}
                            error={errors.custom_form_id}
                        >
                            {customFormsLoaded ? (
                                customForms.length === 0 ? (
                                    trans("No custom forms available")
                                ) : (
                                    <FormikAdvancedSelect
                                        name="custom_form_id"
                                        value={values.custom_form_id}
                                        options={[
                                            {
                                                text: intl.formatMessage({
                                                    id: "None",
                                                }),
                                                value: null,
                                            },
                                            ...customForms,
                                        ]}
                                        showError={false}
                                    />
                                )
                            ) : (
                                <LoadingIconSmall />
                            )}
                        </FormRow>

                        {/* <FormRow
                            name="meta_fields"
                            label={trans("Meta Data")}
                            helpText={trans(
                                "Extra fields to be displayed when uploading assets to this channel"
                            )}
                            error={errors.meta_fields}
                        >
                            <RepeaterField
                                style={{ marginTop: "1rem" }}
                                noPadding={true}
                                values={
                                    values.meta_fields ? values.meta_fields : []
                                }
                                onChanges={(e) => {
                                    setFieldValue(
                                        "meta_fields",
                                        e.target.value
                                    );
                                }}
                                types={[
                                    {
                                        name: "name",
                                        type: "Text",
                                        width: "50%",
                                    },
                                    {
                                        name: "label",
                                        type: "Text",
                                        width: "50%",
                                    },
                                    {
                                        name: "placeHolder",
                                        type: "Text",
                                        width: "50%",
                                    },
                                    {
                                        name: "type",
                                        type: "Text",
                                        width: "50%",
                                    },
                                ]}
                            ></RepeaterField>
                        </FormRow> */}

                        <FormRow
                            name="thumbnail"
                            label={trans("Thumbnail")}
                            helpText={trans("Optional")}
                            error={errors.thumbnail}
                        >
                            <SimpleDropzone
                                style={{ marginBottom: "2.2rem" }}
                                onDrop={(acceptedFiles) =>
                                    onDrop(acceptedFiles, setFieldValue)
                                }
                                accept="image/*"
                                multiple={false}
                            >
                                <IconTextButton
                                    icon="upload"
                                    showCircle={false}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {values.thumbnail && values.thumbnail !== ""
                                        ? trans("Update thumbnail")
                                        : trans("Add thumbnail")}
                                </IconTextButton>
                            </SimpleDropzone>

                            {typeof values.thumbnail === "string" &&
                                values.thumbnail !== "" && (
                                    <PreviewImage src={values.thumbnail} />
                                )}
                            {newPreviewFile && (
                                <PreviewImage src={newPreviewFile} />
                            )}
                        </FormRow>
                        {/* <FormRow
                            name="color"
                            label={trans("Color")}
                            helpText={trans("Optional")}
                            error={errors.color}
                        >
                            <FormikInput
                                type="color"
                                name="color"
                                value={values.color}
                            />{" "}
                        </FormRow>*/}
                        <FormRow
                            name="order"
                            label={trans("Order")}
                            helpText={trans("Optional")}
                            error={errors.order}
                        >
                            <FormikInput
                                name="order"
                                value={values.order}
                                placeholder={item.order ? item.order : "0"}
                            />{" "}
                        </FormRow>
                        {/* <FormRow
                                    name="start_date"
                                    label={trans("Start Date")}
                                    helpText={trans("Optional")}
                                    error={errors.start_date}
                                >
                                    <FormikDatePicker
                                        name="start_date"
                                        value={values.start_date}
                                    />
                                </FormRow>
                                <FormRow
                                    name="end_date"
                                    label={trans("End Date")}
                                    helpText={trans("Optional")}
                                    error={errors.end_date}
                                >
                                    <FormikDatePicker
                                        name="end_date"
                                        value={values.end_date}
                                    />
                                </FormRow> */}

                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MediaChannelForm;
