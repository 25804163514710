import { useSelector } from "react-redux";
import { get } from "lodash";

const useGroups = () => {
    let groups = useSelector((state) => {
        return get(state, "auth.groups", {});
    });

    return { groups };
};

export default useGroups;
