import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { PlainButton } from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import ShareModal from "./ShareModal";
export const ShareButton = ({
    title = "",
    subject = "",
    prependedMessage = "",
    linkText = "",
}) => {
    const { can } = usePermissions();

    const [showModal, setShowModal] = useState(false);
    const [documentTitle, setDocumentTitle] = useState("");

    useEffect(() => {
        setDocumentTitle(document.title);
    }, [showModal]);

    const onClose = () => {
        setShowModal(false);
    };

    if (!can("communication-send-messages")) {
        return null;
    }

    return (
        <>
            <PlainButton onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faShareAlt} size="lg" />
            </PlainButton>
            <ShareModal
                title={title}
                subject={subject}
                prependedMessage={prependedMessage}
                visible={showModal}
                onClose={onClose}
                urls={[
                    {
                        url: window.location.href,
                        title: linkText ? linkText : documentTitle,
                    },
                ]}
            />
        </>
    );
};

export default ShareButton;
