import React, { Component } from "react";

class PointsSummary extends Component {
    render() {
        return (
            <div>
                <h3>Points</h3>
                {this.props.title}
            </div>
        );
    }
}

export default PointsSummary;
