import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Tooltip from "./Tooltip";
import Arrow from "./Arrow";

require("./tooltip.css");

class InjectableTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            transition: "opacity",
            width: 0,
            height: 0,
            correctedOffscreen: null,
            style: {},
            calculatedPosition: ""
        };
    }
    getStyle(positionString, parent, top, left) {
        let style = {};

        switch (positionString) {
            case "left":
                style.top =
                    top + parent.offsetHeight / 2 - this.state.height / 2;
                style.left = left - this.state.width;

                break;

            case "right":
                style.top =
                    top + parent.offsetHeight / 2 - this.state.height / 2;
                style.left = left + parent.offsetWidth;

                break;

            case "top":
                style.top = top - this.state.height;
                style.left =
                    left - this.state.width / 2 + parent.offsetWidth / 2;

                break;

            case "bottom":
            default:
                style.top = top + parent.offsetHeight;
                style.left =
                    left - this.state.width / 2 + parent.offsetWidth / 2;

                break;
        }

        style = this.correctOffScreenPostion(style);

        return style;
    }

    correctOffScreenPostion(style) {
        if (style.left < 0) {
            style.left = 0;
            this.setState({ correctedOffscreen: "left" });
        } else if (style.left + this.state.width > window.innerWidth) {
            style.left = window.innerWidth - this.state.width;
            this.setState({ correctedOffscreen: "right" });
        }
        return style;
    }
    getTooltipContainerPosition = positionString => {
        let { parent } = this.props;
        let tooltipPosition = parent.getBoundingClientRect();
        let scrollY =
            window.scrollY !== undefined ? window.scrollY : window.pageYOffset;
        let scrollX =
            window.scrollX !== undefined ? window.scrollX : window.pageXOffset;
        let top = scrollY + tooltipPosition.top;
        let left = scrollX + tooltipPosition.left;

        let style = this.getStyle(positionString, parent, top, left);
        //Stores any corrections applied, useful in sub components
        let calculatedPosition = positionString;

        switch (positionString) {
            case "left":
                if (style.left + this.state.width > window.innerWidth) {
                    calculatedPosition = "right";
                    style = this.getStyle("right", parent, top, left);
                }
                break;

            case "right":
                if (style.left - this.state.width < 0) {
                    calculatedPosition = "left";
                    style = this.getStyle("left", parent, top, left);
                }
                break;

            case "top":
                if (style.top - this.state.height < window.scollTop) {
                    calculatedPosition = "bottom";
                    style = this.getStyle("bottom", parent, top, left);
                }

                break;

            case "bottom":
            default:
                if (style.top + this.state.height > window.innerHeight) {
                    calculatedPosition = "top";
                    style = this.getStyle("top", parent, top, left);
                }

                break;
        }
        style.position = "absolute";
        style.zIndex = this.props.tooltipStyle.zIndex;
        this.setState(state => ({ style, calculatedPosition }));
        //return { style, calculatedPosition };
    };
    handleMouseEnter() {
        this.props.active && this.setState({ hover: true });
    }
    handleMouseLeave() {
        this.setState({ hover: false });
    }
    componentDidMount() {
        this.getTooltipContainerPosition(this.props.position);
        //console.log(this.tooltipContainer.offsetWidth);
        this.setState({
            width: this.tooltipContainer.offsetWidth,
            height: this.tooltipContainer.offsetHeight
        });
    }

    componentWillReceiveProps(nextProps) {
        //console.log(nextProps);
        if (this.props.parent !== nextProps.parent) {
            //console.log("updating container");
            this.getTooltipContainerPosition(nextProps.position);
        }

        if (this.state.timeout && !this.props.active) {
            this.setState({
                timeout: null
            });
            clearTimeout(this.state.timeout);
        }

        if (this.props.active && !nextProps.active) {
            this.setState({
                timeout: setTimeout(() => {
                    this.props.active = false;
                }, this.props.tooltipTimeout)
            });
        }

        this.setState({
            correctedOffscreen: null,
            correctedPosition: null,
            width: this.tooltipContainer.offsetWidth,
            height: this.tooltipContainer.offsetHeight
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.parent !== prevProps.parent) {
            this.getTooltipContainerPosition(this.props.position);
            //console.log(this.tooltipContainer.offsetWidth);
            this.setState({
                width: this.tooltipContainer.offsetWidth,
                height: this.tooltipContainer.offsetHeight
            });
        }
    }
    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    render() {
        const { arrowPlacement, parent } = this.props;
        // const { style, calculatedPosition } = this.getTooltipContainerPosition(
        //     position
        // );
        const { style, calculatedPosition } = this.state;
        //console.log("Rendering", style);
        return (
            <div
                className="injectableTooltip__container"
                onMouseEnter={this.handleMouseEnter.bind(this)}
                onMouseLeave={this.handleMouseLeave.bind(this)}
                ref={container => {
                    this.tooltipContainer = container;
                }}
                style={style}
            >
                <Tooltip style={{ ...this.props.tooltipStyle, ...style }}>
                    {this.props.hideTooltip && (
                        <Row>
                            <Column style={{ textAlign: "right" }}>
                                <Button
                                    type="secondary"
                                    onClick={this.props.hideTooltip}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </Column>
                        </Row>
                    )}
                    {this.props.children}
                </Tooltip>
                {arrowPlacement ? (
                    <Arrow
                        position={calculatedPosition}
                        placement={arrowPlacement}
                        parent={parent}
                        style={{ ...this.props.tooltipStyle, ...style }}
                        tooltip={{
                            width: this.state.width,
                            height: this.state.height,
                            corrected: this.state.correctedOffscreen
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

InjectableTooltip.propTypes = {
    parent: PropTypes.instanceOf(Element).isRequired,
    active: PropTypes.bool,
    group: PropTypes.string,
    tooltipTimeout: PropTypes.number,
    position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
    arrowPlacement: PropTypes.oneOf([
        null,
        "center",
        "top",
        "right",
        "bottom",
        "left"
    ]),
    tooltipStyle: PropTypes.object,
    hideTooltip: PropTypes.func
};
InjectableTooltip.defaultProps = {
    active: false,
    position: "bottom",
    arrowPlacement: "center",
    tooltipTimeout: 500,
    tooltipStyle: {
        zIndex: 50
    },
    hideTooltip: null
};

export default InjectableTooltip;
