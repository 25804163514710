import React, { useContext } from "react";

import { Overlay } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import EditorContext from "../EditorContext";

const AssetPreviewOverlay = ({ children }) => {
    const { assetAdmin, saveUpdatedAsset, ...rest } = useContext(EditorContext);
    return (
        <Overlay
            showOverlay={assetAdmin.assetEdited}
            onClick={event => saveUpdatedAsset(event, true)}
            buttonLabel={trans("Save to update preview")}
            submitting={assetAdmin.assetSaving}
        >
            {children}
        </Overlay>
    );
};

export default AssetPreviewOverlay;
