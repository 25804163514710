import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { v4 as uuid } from "uuid";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";

import { transparentize } from "@cortexglobal/cortex-utilities";

const TransitionItem = styled.h5`
    flex: 1;
    margin: 0 0.8rem;
    font-size: 1rem;
`;
const TransitionDragIcon = styled.span`
    font-size: 1rem;
    color: ${({ theme, isDragging }) =>
        isDragging ? theme.colors.white : theme.colors.black};
`;
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (theme, isDragging, draggableStyle) => {
    // console.log(theme.colors.primary);
    return {
        display: "flex",
        flexDirection: "Row",
        userSelect: "none",
        padding: `${theme.spacing.padding / 2}rem`,
        marginBottom: `${theme.spacing.margin / 2}rem`,
        color: theme.colors.white,

        // change background colour if dragging
        backgroundColor: isDragging
            ? theme.colors.secondary
            : theme.colors.gray600,

        // styles we need to apply on draggables
        ...draggableStyle,
    };
};

const getListStyle = (theme, isDraggingOver) => ({
    backgroundColor: isDraggingOver
        ? transparentize(theme.colors.primary, 0.8)
        : transparentize(theme.colors.gray300, 0.4),
    padding: `${theme.spacing.padding}rem`,
    width: "100%",
    transition: "background-color 500ms ease",
});

const addTransition = (contentElement, transitions) => {
    // console.log("contentElement", contentElement);
    //Exclude items that are marked as having no transition
    if (
        contentElement.transitionType &&
        contentElement.transitionType === "none"
    ) {
        return transitions;
    }
    const newTransition = {
        type: contentElement.transitionType
            ? contentElement.transitionType
            : "transition",
        uuid: contentElement.uuid,
        name: contentElement.name,
    };

    return [...transitions, newTransition];
};

export const setupNewTransitions = (content, onChange) => {
    return content.reduce((tempTransitions, contentElement) => {
        return addTransition(contentElement, tempTransitions);
    }, []);
};

const addToTransitionGroup = (result, transition, transitions) => {
    // Temp copy of the transitions
    const items = [...transitions];

    //Remove the dropped on item the main list
    const movedItem = items.splice(result.source.index, 1);

    //Find the index of the item that was dropped on using its droppableId
    const droppedOnIndex = transitions.findIndex(
        (transitionItem) => transitionItem.uuid === result.combine.droppableId
    );

    //Remove the dropped on item the main list as we'll reinsert it later anyhow
    const droppedOnObject = items.splice(droppedOnIndex, 1);

    //If the item was dropped on a group, add it to a cloned version the existing list
    //otherwise create a new group with the original item and the moved one in the list
    let transitionGroup = {};
    if (droppedOnObject.type === "transitionGroup") {
        //Add the moved item to the group
        transitionGroup = {
            ...droppedOnObject,
            groupedTransitions: [
                ...droppedOnObject.groupedTransitions,
                movedItem,
            ],
        };
    } else {
        //The dropped on object is just another transition so we need to convert
        //it to a transitionGroup
        transitionGroup = {
            type: "transitionGroup",
            uuid: uuid(),
            groupedTransitions: [droppedOnObject, movedItem],
        };
    }
    items.splice(droppedOnIndex, 0, transitionGroup);
    return items;
};

const TransitionEditor = ({ onChange, transitions, content, children }) => {
    const theme = useTheme();
    // console.log(transitions, content);
    //If transitions don't already exist create them from the content
    useEffect(() => {
        if (!Array.isArray(transitions) && Array.isArray(content)) {
            const items = setupNewTransitions(content);
            onChange({
                name: "transitions",
                value: items,
            });
        }
    }, [transitions, content]);

    const onDragEnd = (result) => {
        // console.log(result);

        // combining item
        if (result.combine) {
            // Temp copy of the transitions
            const items = [...transitions];
            //Get the moved item and remove it from the main array
            const movedItem = items.splice(result.source.index, 1);

            //TODO - Add the item to the item it was dropped on... might need more of a structure...

            // console.log(movedItem, items);
            // onChange({
            //     name: "transitions",
            //     value: items
            // });
            return;
        }

        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            transitions,
            result.source.index,
            result.destination.index
        );

        onChange({
            name: "transitions",
            value: items,
        });
    };
    return transitions && transitions.length > 0 ? (
        <React.Fragment>
            {children}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="transitionList" isCombineEnabled={true}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(theme, snapshot.isDraggingOver)}
                        >
                            {transitions.map((transition, index) => (
                                <Draggable
                                    key={transition.uuid}
                                    draggableId={transition.uuid}
                                    index={index}
                                >
                                    {(provided, snapshot) => {
                                        const relatedContentElement =
                                            content.find((contentElement) => {
                                                // console.log(
                                                //     contentElement,
                                                //     transition
                                                // );
                                                return (
                                                    transition.uuid ===
                                                    contentElement.uuid
                                                );
                                            });
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    theme,
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                <TransitionDragIcon
                                                    isDragging={
                                                        snapshot.isDragging
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faArrowsAlt}
                                                    />
                                                </TransitionDragIcon>
                                                <TransitionItem>
                                                    {" "}
                                                    {relatedContentElement
                                                        ? relatedContentElement.label
                                                            ? relatedContentElement.label
                                                            : relatedContentElement.name
                                                        : "n/a"}
                                                </TransitionItem>
                                            </div>
                                        );
                                    }}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>{" "}
        </React.Fragment>
    ) : (
        <span>No available transitions</span>
    );
};

export default TransitionEditor;
