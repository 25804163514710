import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";

import {
    Row,
    InputField,
    DatePicker,
    useAddToast,
} from "@cortexglobal/rla-components";
import AssetsOverallAdminOptions from "../../assets/components/AssetsOverallAdminOptions";
import AssetResultItem from "../../assets/components/AssetResultItem";

export const AssetSection = ({
    campaign,
    onChange,
    errors,
    loadCampaign,
    config,
    assetsPath,
}) => {
    const addToast = useAddToast();
    const history = useHistory();
    //As the assets store the relation to the campaign we only really need to refresh it
    //could just update the state with the data if we want it more snappy, but feels more
    //robust to just reload the campaign
    const updateCampaign = () => {
        loadCampaign(campaign.uuid).then((data) => {
            addToast({
                type: "success",
                content: trans("Assets successfully updated"),
            });
        });
    };
    return (
        <>
            <div>
                <AssetsOverallAdminOptions
                    campaignUuid={campaign.uuid}
                    onApply={updateCampaign}
                    config={config}
                    assetsPath={assetsPath}
                    folderUuid={""}
                    onFileAdded={() => {}}
                />
            </div>
            <Row>
                {Array.isArray(campaign.assets) && campaign.assets.length > 0
                    ? campaign.assets.map((asset) => {
                          return (
                              <AssetResultItem
                                  key={asset.uuid}
                                  asset={asset}
                                  loaded={true}
                                  onClick={() => {
                                      history.push(
                                          `${assetsPath}/view/${asset.uuid}`
                                      );
                                  }}
                              />
                          );
                      })
                    : "No assets"}
            </Row>
        </>
    );
};

export default AssetSection;
