import React, { useContext } from "react";
import styled from "styled-components";
import { DraftJSOutput } from "@cortexglobal/rla-components";

import { ContentBlock } from "../interfaces/layout";
import MicrositeContext from "../MicrositeContext";

const Footnote = styled.sup`
    font-size: 0.6em;
`;

const generateFootnotes = (
    footnotes: ContentBlock["footnotes"],
    useFootnotes: boolean
) => {
    if (!useFootnotes || !Array.isArray(footnotes)) {
        return null;
    }
    return (
        <Footnote>
            {footnotes
                .sort((a, b) => {
                    return a.number - b.number;
                })
                .map((footnote, index) => {
                    return index > 0 ? (
                        <span title={footnote.text}>, {footnote.number}</span>
                    ) : (
                        <span title={footnote.text}>{footnote.number}</span>
                    );
                })}
        </Footnote>
    );
};

/**
 * Handle the rendering of a content block, allows for different outputs (e.g. draft-js)
 * also allows for footnotes etc.
 * @param param0
 */
export const RenderContentBlock = ({
    value,
    type,
    footnotes,
}: ContentBlock) => {
    const { globalSettings } = useContext(MicrositeContext);
    const footnoteElements = generateFootnotes(
        footnotes,
        globalSettings?.useFootnotes
    );
    if (type === "draft-js") {
        return (
            <React.Fragment>
                <DraftJSOutput>{value}</DraftJSOutput>
                {footnoteElements}
            </React.Fragment>
        );
    }
    return (
        <div>
            {value}
            {footnoteElements}
        </div>
    );
};
