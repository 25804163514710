import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import DatePickerInputField from "./DatePickerInputField";
import CalendarContainer from "./CalendarContainer";
import { parseISO } from "date-fns";
import { getLocaleDateFormat } from "@cortexglobal/cortex-utilities";

const DateTimePickerWrapper = styled.div`
    .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: ${({ marginBottom }) =>
            marginBottom ? marginBottom : "1.2rem"};
        display: block;
    }

    .react-datepicker__input-container {
        width: 100%;
    }
`;
const getValue = (selected, allowEmpty) => {
    if (allowEmpty && selected == "") {
        return null;
    }
    if (typeof selected === "string" && selected !== "") {
        try {
            selected = parseISO(selected);
        } catch {
            try {
                selected = new Date(selected);
            } catch {}
        }
    }
    if (selected instanceof Date && !isNaN(selected)) {
        return selected;
    } else {
        return "";
    }
};
const StyledDateTimePicker = ({
    value,
    selected,
    allowEmpty,
    marginBottom,
    ...props
}) => {
    const [processedValue, setProcessedValue] = useState(null);

    useEffect(() => {
        setProcessedValue(getValue(selected, allowEmpty));
    }, [value]);

    return (
        <DateTimePickerWrapper marginBottom={marginBottom}>
            <ReactDatePicker
                {...props}
                selected={processedValue}
                customInput={
                    <DatePickerInputField dateFormat={props.dateFormat} />
                }
                onSelect={(value) => {
                    props.onChange(value);
                }}
                className={`form-control ${props.error ? "alert" : ""}`}
                popperContainer={CalendarContainer}
                popperClassName="showInFront"
                autoComplete="off"
                placeholderText={props.placeholder}
                showTimeSelect
            />
        </DateTimePickerWrapper>
    );
};

StyledDateTimePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initialValue: PropTypes.any,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyOption: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dateFormat: PropTypes.string,
    placeholder: PropTypes.string,
    allowEmpty: PropTypes.bool,
};
StyledDateTimePicker.defaultProps = {
    error: "",
    expanded: true,
    dateFormat: getLocaleDateFormat() + " HH:mm",
    placeholder: "",
    allowEmpty: false,
};
export default StyledDateTimePicker;
