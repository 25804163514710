import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Column,
    ContentBlockLoader,
    CurrencyInput,
    FormRow,
    IconTextButton,
    Tabordion,
    InputField,
    PageTitle,
    Row,
    SelectField,
    StyledCheckbox,
    SubmitButton,
    TextareaField,
    useAddToast,
    SimpleDropzone,
    PreviewImage,
    Loading,
    DatePicker,
    StyledDateRangePicker,
} from "@cortexglobal/rla-components";

///@ts-ignore cortex
import { LocaleSwitcher } from "@cortexglobal/admin";

const ButtonRow = styled(Row)`
    width: 100%;
    padding: 0 0.6rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
const SaveButton = styled(SubmitButton)`
    margin: 0 0.5rem;
`;

const TabordionGroup = ({ children, ...rest }) => {
    return <div {...rest}>{children}</div>;
};
import { add } from "date-fns";

const InventoryItemForm = ({ item: itemIn, path }) => {
    const history = useHistory();
    const [loadingState, setLoadingState] = useState("loading");
    const [savingState, setSavingState] = useState("saved");
    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({});
    const [settings, setSettings] = useState({});
    const [newPreviewFile, setNewPreviewFile] = useState(null);
    const [tab, setTab] = useState(0);
    const startDate = get(formValues, "start_date", null);
    const endDate = get(formValues, "end_date", null);
    const addToast = useAddToast();
    const intl = useIntl();

    useEffect(() => {
        getSettings();
        loadInitialValues(itemIn);
    }, [itemIn]);

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );
    const loadInitialValues = (item) => {
        const values = {
            ...(item || {}),
            cost: item?.cost?.value || 0,
            category: item?.category?.uuid,
            own_thumbnail: item?.own_thumbnail || null,
            bookable: item ? item.bookable : 1,
        };
        setNewPreviewFile(null);
        setFormValues(values);
    };

    const getSettings = () => {
        setLoadingState("loading");

        axios
            .get(`/api/v1/events/settings`)
            .then(({ data }) => {
                const settings = data.data;
                setSettings(settings);
                setLoadingState("loaded");
            })
            .catch((e) => {});
    };

    const onChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: null });
    };

    const onLocaleChange = (locale) => {
        console.log({ locale });

        if (!locale) return;

        //If there is no i18n data, set the locale and return
        if (!formValues.i18n) {
            setFormValues({
                ...formValues,
                locale: locale,
            });
            return;
        }

        //Find the appropriate entry in the allI18n
        const selectedLocaleI18nData = formValues.i18n.find(
            (i18nData) => i18nData.locale === locale
        );
        setFormValues({
            ...formValues,
            locale: locale,
            name: selectedLocaleI18nData?.name || "",
            description: selectedLocaleI18nData?.description || "",
        });
    };

    const onCheckboxChange = (e) => {
        const { name } = e.target;
        formValues[name] == 1 ? onChange(name, 0) : onChange(name, 1);
    };

    const saveInventoryItem = (values) => {
        setSavingState("saving");

        const fd = new FormData();

        Object.keys(values).forEach((key) => {
            const value = values[key];
            if (value === null) return;
            fd.append(key, values[key]);
        });
        axios
            .post(`/api/v1/events/inventory`, fd)
            .then(({ data }) => {
                const item = data.data;

                loadInitialValues(item);
                setSavingState("saved");

                addToast({
                    type: "success",
                    content: trans("Item saved"),
                    showFor: 5000,
                });
                history.push(`${path}/${item.uuid}/edit`);
            })
            .catch((e) => {
                if (e.response && e.response.status === 422) {
                    setErrors(get(e, "response.data.errors", {}));
                    addToast({
                        type: "alert",
                        content: trans("Please check the form for errors"),
                        showFor: 5000,
                    });
                    setSavingState("saved");
                } else {
                    addToast({
                        type: "alert",
                        content: trans("Error saving item"),
                        showFor: 5000,
                    });
                    setSavingState("error");
                }
            });
    };

    const onDrop = (acceptedFiles) => {
        setFormValues({ ...formValues, thumbnail: acceptedFiles[0] });
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    };

    if (loadingState === "loading") {
        return <Loading />;
    }

    return (
        <>
            <Row>
                <Tabordion current={tab} unmounts={false}>
                    <TabordionGroup
                        heading={<span>{trans("Details")}</span>}
                        onTabClick={() => setTab(0)}
                    >
                        <Column>
                            <FormRow
                                name="locale"
                                label={trans("Locale")}
                                required
                                error={errors.locale}
                            >
                                <LocaleSwitcher
                                    item={formValues}
                                    onChange={(value) => {
                                        // onChange(name, value);
                                        onLocaleChange(value);
                                    }}
                                    //@ts-ignore
                                    values={formValues}
                                    name="locale"
                                />
                            </FormRow>
                            <FormRow
                                name="name"
                                label={trans("Name")}
                                required
                                error={errors.name}
                            >
                                <InputField
                                    name="name"
                                    onChange={(e) => onChange(e.name, e.value)}
                                    value={formValues.name}
                                    // value={get(formValues, "name", "")}
                                    labelStyle={{ marginBottom: 0 }}
                                />
                            </FormRow>

                            <FormRow
                                name="description"
                                label={trans("Description")}
                                error={errors.description}
                            >
                                <TextareaField
                                    name="description"
                                    onChange={(e) => onChange(e.name, e.value)}
                                    value={get(formValues, "description", "")}
                                />
                            </FormRow>

                            <FormRow
                                name="category"
                                label={trans("Category")}
                                required
                                error={errors.category}
                            >
                                <SelectField
                                    height={38}
                                    name="category"
                                    onChange={(e) => onChange(e.name, e.value)}
                                    disabled={loadingState === "loading"}
                                    options={
                                        (loadingState === "loaded" &&
                                            settings?.inventoryCategories) ||
                                        []
                                    }
                                    value={get(formValues, "category", "")}
                                    loading={loadingState === "loading"}
                                />
                            </FormRow>

                            <FormRow
                                name="cost_basis"
                                label={trans("Cost Basis")}
                                required
                                error={errors.cost_basis}
                            >
                                <SelectField
                                    height={38}
                                    name="cost_basis"
                                    onChange={(e) => onChange(e.name, e.value)}
                                    disabled={loadingState === "loading"}
                                    options={
                                        (loadingState === "loaded" &&
                                            settings?.costBasisOptions) ||
                                        []
                                    }
                                    value={get(formValues, "cost_basis", "")}
                                    loading={loadingState === "loading"}
                                />
                            </FormRow>

                            {get(formValues, "cost_basis", "") !=
                                "estimate" && (
                                <FormRow name="cost" label={trans("Cost")}>
                                    <CurrencyInput
                                        name="cost"
                                        type="number"
                                        onChange={(e) =>
                                            onChange(e.name, e.value)
                                        }
                                        value={get(formValues, "cost", 0)}
                                    />
                                </FormRow>
                            )}

                            {(get(formValues, "cost_basis", "") === "daily" ||
                                get(formValues, "cost_basis", "") ===
                                    "event") && (
                                <>
                                    <FormRow
                                        name="pickup_transit_days"
                                        label={trans(
                                            "Days required to pick the item up"
                                        )}
                                        required={true}
                                        error={errors.pickup_transit_days}
                                    >
                                        <InputField
                                            name="pickup_transit_days"
                                            type="number"
                                            onChange={(e) =>
                                                onChange(e.name, e.value)
                                            }
                                            value={get(
                                                formValues,
                                                "pickup_transit_days",
                                                0
                                            )}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="return_transit_days"
                                        label={trans(
                                            "Days required to return the item"
                                        )}
                                        required={true}
                                        error={errors.return_transit_days}
                                    >
                                        <InputField
                                            name="return_transit_days"
                                            type="number"
                                            onChange={(e) =>
                                                onChange(e.name, e.value)
                                            }
                                            value={get(
                                                formValues,
                                                "return_transit_days",
                                                0
                                            )}
                                        />
                                    </FormRow>
                                </>
                            )}

                            <FormRow
                                name="thumbnail"
                                label={trans("Thumbnail")}
                                helpText={trans("Optional")}
                                error={errors.thumbnail}
                            >
                                <SimpleDropzone
                                    style={{ marginBottom: "2.2rem" }}
                                    onDrop={(acceptedFiles) =>
                                        onDrop(acceptedFiles)
                                    }
                                    accept="image/*"
                                    multiple={false}
                                >
                                    <IconTextButton
                                        icon="upload"
                                        showCircle={false}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        {formValues.own_thumbnail &&
                                        formValues.own_thumbnail !== ""
                                            ? trans("Update thumbnail")
                                            : trans("Add thumbnail")}
                                    </IconTextButton>
                                </SimpleDropzone>

                                {typeof formValues.own_thumbnail === "string" &&
                                    formValues.own_thumbnail !== "" && (
                                        <PreviewImage
                                            src={formValues.own_thumbnail}
                                        />
                                    )}
                                {newPreviewFile && (
                                    <PreviewImage src={newPreviewFile} />
                                )}
                            </FormRow>

                            <FormRow
                                name="bookable"
                                label={trans("Bookable")}
                                error={errors.bookable}
                            >
                                <StyledCheckbox
                                    inline={true}
                                    name="bookable"
                                    checked={get(formValues, "bookable", 1)}
                                    onChange={(e) => onCheckboxChange(e)}
                                    value={true}
                                />
                            </FormRow>
                        </Column>
                    </TabordionGroup>
                    <TabordionGroup
                        heading={<span>{trans("Availability")}</span>}
                        onTabClick={() => setTab(1)}
                    >
                        <Column>
                            <FormRow
                                helpText={trans("Required")}
                                label={trans("Can Book Item From")}
                                name={"can_book_from"}
                                error={errors.can_book_from}
                                required={true}
                            >
                                <DatePicker
                                    name="can_book_from"
                                    onChange={(e) => onChange(e.name, e.value)}
                                    disabled={loadingState === "loading"}
                                    value={get(formValues, "can_book_from", "")}
                                    showError={true}
                                />
                            </FormRow>

                            <FormRow
                                label={trans("Can Book Item Until")}
                                name={"can_book_to"}
                                error={errors.can_book_to}
                                required={false}
                            >
                                <DatePicker
                                    name="can_book_to"
                                    onChange={(e) => onChange(e.name, e.value)}
                                    disabled={loadingState === "loading"}
                                    value={get(formValues, "can_book_to", "")}
                                    minDate={add(
                                        new Date(
                                            get(formValues, "can_book_from")
                                        ),
                                        {
                                            days: 1,
                                        }
                                    )}
                                    showError={false}
                                />
                            </FormRow>
                            <FormRow
                                helpText={trans("Required")}
                                name="allowed_in_advance_booking_period"
                                label={trans("Can Book Item In Advance Period")}
                                required={true}
                                error={errors.allowed_in_advance_booking_period}
                            >
                                <InputField
                                    name="allowed_in_advance_booking_period"
                                    type="number"
                                    min={1}
                                    onChange={(e) => onChange(e.name, e.value)}
                                    value={get(
                                        formValues,
                                        "allowed_in_advance_booking_period",
                                        0
                                    )}
                                />
                            </FormRow>
                            <FormRow
                                helpText={trans("Required")}
                                label={trans(
                                    "Can Book Item In Advance Period Type"
                                )}
                                name={"allowed_in_advance_booking_period_type"}
                                error={
                                    errors.allowed_in_advance_booking_period_type
                                }
                                required={true}
                            >
                                <SelectField
                                    height={38}
                                    name="allowed_in_advance_booking_period_type"
                                    onChange={(e) => onChange(e.name, e.value)}
                                    disabled={loadingState === "loading"}
                                    options={[
                                        {
                                            value: "day",
                                            text: intl.formatMessage({
                                                id: "Day",
                                            }),
                                        },
                                        {
                                            value: "week",
                                            text: intl.formatMessage({
                                                id: "Week",
                                            }),
                                        },
                                        {
                                            value: "month",
                                            text: intl.formatMessage({
                                                id: "Month",
                                            }),
                                        },
                                        {
                                            value: "year",
                                            text: intl.formatMessage({
                                                id: "Year",
                                            }),
                                        },
                                    ]}
                                    value={get(
                                        formValues,
                                        "allowed_in_advance_booking_period_type",
                                        ""
                                    )}
                                    showError={false}
                                />
                            </FormRow>
                            {/*<FormRow*/}
                            {/*    helpText={trans("Required")}*/}
                            {/*    label={trans("Can Book Item In Advance Period")}*/}
                            {/*    name={"allowed_in_advance_booking_period"}*/}
                            {/*    error={errors.allowed_in_advance_booking_period}*/}
                            {/*    required={true}*/}
                            {/*>*/}
                            {/*    <SelectField*/}
                            {/*        height={38}*/}
                            {/*        name="allowed_in_advance_booking_period"*/}
                            {/*        onChange={(e) => onChange(e.name, e.value)}*/}
                            {/*        disabled={loadingState === "loading"}*/}
                            {/*        options={[*/}
                            {/*            { value: "1-week", text: intl.formatMessage({ id: "1 week" }) },*/}
                            {/*            { value: "2-week", text: intl.formatMessage({ id: "2 weeks"}) },*/}
                            {/*            { value: "3-week", text: intl.formatMessage({ id: "3 weeks" }) },*/}
                            {/*            { value: "4-week", text: intl.formatMessage({ id: "4 weeks" }) },*/}
                            {/*            { value: "6-week", text: intl.formatMessage({ id: "6 weeks" }) },*/}
                            {/*            { value: "1-month", text: intl.formatMessage({ id: "1 month" }) },*/}
                            {/*            { value: "2-month", text: intl.formatMessage({ id: "2 months" }) },*/}
                            {/*            { value: "3-month", text: intl.formatMessage({ id: "3 months" }) },*/}
                            {/*            { value: "4-month", text: intl.formatMessage({ id: "4 months" }) },*/}
                            {/*            { value: "5-month", text: intl.formatMessage({ id: "5 months" }) },*/}
                            {/*            { value: "6-month", text: intl.formatMessage({ id: "6 months"}) },*/}
                            {/*            { value: "9-month", text: intl.formatMessage({ id: "9 months" }) },*/}
                            {/*            { value: "12-month", text: intl.formatMessage({ id: "12 months" }) },*/}
                            {/*            { value: "18-month", text: intl.formatMessage({ id: "18 months" }) },*/}
                            {/*        ]}*/}
                            {/*        value={get(formValues, "allowed_in_advance_booking_period", "")}*/}
                            {/*        showError={false}*/}
                            {/*    />*/}
                        </Column>
                    </TabordionGroup>
                </Tabordion>
            </Row>
            <FormRow>
                <ButtonRow>
                    <SaveButton
                        submitting={savingState === "saving"}
                        label={trans("Save")}
                        onClick={() => saveInventoryItem(formValues)}
                    />
                </ButtonRow>
            </FormRow>
        </>
    );
};

export default InventoryItemForm;
