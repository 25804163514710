import React from "react";
import { get } from "lodash";
import {
    FormRow,
    Column,
    renderStandReturnInput,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const CustomFormFields = ({ form, onChange, values, errors, disabled }) => {
    return (
        <>
            {form.elements.map((element) => {
                const formValue = get(values, element.name, "");
                const error = get(errors, element.name);
                const required =
                    element.validation_rules &&
                    JSON.stringify(element.validation_rules)
                        .toLowerCase()
                        .includes("required");
                return (
                    <FormRow
                        key={element.uuid}
                        name={element.name}
                        label={element.text}
                        helpText={required ? trans("Required") : ""}
                        error={error}
                        required={required}
                    >
                        {renderStandReturnInput(
                            {
                                ...element,
                                hideLabel: true, // Hide the label as we have already rendered it
                                error: null, // Hide the error as we have already rendered it
                                disabled: disabled,
                            },
                            formValue,
                            (e) => {
                                onChange({
                                    name: e.name,
                                    value: e.value,
                                });
                            },
                            (e) => {
                                onChange({
                                    name: e.name,
                                    value: e.files,
                                });
                            }
                        )}
                    </FormRow>
                );
            })}
        </>
    );
};

export default CustomFormFields;
