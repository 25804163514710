import React from "react";
import styled, { useTheme } from "styled-components";

import { Collapse } from "react-collapse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { trans } from "@cortexglobal/rla-intl";
import { transparentize } from "@cortexglobal/cortex-utilities";

import PageItem from "./PageItem";
import {usePermissions} from "@cortexglobal/cortex-auth-redux";

const Wrapper = styled.div`
    background-color: ${({ theme, isDraggingOver }) =>
        isDraggingOver
            ? transparentize(theme.colors.primary, 0.8)
            : transparentize(theme.colors.gray300, 0.4)};
    display: flex;
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing.padding / 2}rem;
    user-select: none;
    transition: background-color 0.1s ease;
    margin: ${({ theme }) => theme.spacing.padding / 2}rem 0;
    overflow-x: scroll;
`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, [...removed]);

    return { selectedSlug: removed[0], pages: result };
};

const PageNavigator = ({
    currentPage,
    pages,
    onChange,
    onPageChange,
    onPageDelete,
    showing
}) => {
    const theme = useTheme();
    const { can } = usePermissions();

    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newPageOrder = reorder(
            pages,
            result.source.index,
            result.destination.index
        );

        onChange(newPageOrder);
    };

    return (
        <Collapse isOpened={showing}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="transitionList"
                    internalScroll={true}
                    direction="horizontal"
                >
                    {(dropProvided, dropSnapshot) => (
                        <Wrapper
                            isDraggingOver={dropSnapshot.isDraggingOver}
                            {...dropProvided.droppableProps}
                            ref={dropProvided.innerRef}
                        >
                            {pages.map((page, index) => {
                                const [pageSlug, pageSetup] = page;
                                return (
                                    <Draggable
                                        key={pageSlug}
                                        draggableId={pageSlug}
                                        index={index}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <PageItem
                                                    canDeletePage={pages.length > 1 && can('dam-microsite-admin')}
                                                    currentPage={currentPage}
                                                    provided={provided}
                                                    snapshot={snapshot}
                                                    onPageChange={onPageChange}
                                                    onPageDelete={onPageDelete}
                                                    pageSlug={pageSlug}
                                                    pageSetup={pageSetup}
                                                />
                                            );
                                        }}
                                    </Draggable>
                                );
                            })}
                            {dropProvided.placeholder}
                        </Wrapper>
                    )}
                </Droppable>
            </DragDropContext>
        </Collapse>
    );
};

export default PageNavigator;
