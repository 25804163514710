import React, { Component } from "react";

import MarketingKpiVsBudgetOutput from "./MarketingKpiVsBudgetOutput";
import MarketingKpiVsBudgetConfiguration from "./MarketingKpiVsBudgetConfiguration";

const MarketingKpiVsBudget = (props) => {
    if (props.configuring) {
        return <MarketingKpiVsBudgetConfiguration {...props} />;
    }
    return <MarketingKpiVsBudgetOutput {...props} />;
};

export default MarketingKpiVsBudget;
