import React from "react";
import { TableList } from "@cortexglobal/rla-components";
import { isEmpty } from "lodash";
import { trans } from "@cortexglobal/rla-intl";
import BookingsListingItem from "./BookingsListingItem";

const BookingsListing = ({
    bookings,
    removeBooking = () => {},
    settings,
    showReturnCondition = false,
}) => {
    return (
        <TableList>
            <thead>
                <tr>
                    <th>{trans("ID")}</th>
                    <th>{trans("From")}</th>
                    <th>{trans("To")}</th>
                    <th>{trans("Type")}</th>
                    <th>{trans("Comments")}</th>
                    {showReturnCondition && (
                        <th>{trans("Return Condition")}</th>
                    )}
                    <th>{trans("Actions")}</th>
                </tr>
            </thead>
            <tbody>
                {isEmpty(bookings) ? (
                    <tr>
                        <td colSpan="100%">{trans("No bookings found")}</td>
                    </tr>
                ) : (
                    bookings.map((booking) => (
                        <BookingsListingItem
                            key={booking.uuid}
                            booking={booking}
                            removeBooking={removeBooking}
                            settings={settings}
                            showReturnCondition={showReturnCondition}
                        />
                    ))
                )}
            </tbody>
        </TableList>
    );
};

export default BookingsListing;
