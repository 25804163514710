import React from "react";
import PropTypes from "prop-types";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { trans } from "@cortexglobal/rla-intl";

import { startOrderProcess } from "../plannerActions";

import {
    Panel,
    Row,
    Column,
    PageTitle,
    Button,
} from "@cortexglobal/rla-components";

class ActivityAdded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: {},
            taskError: false,
            taskLoading: false,
        };
    }

    handleAddOrder(task, activityUuid) {
        console.log(task);
        console.log(activityUuid);

        this.props.startOrderProcess({
            task,
            activityUuid,
        });
        this.props.history.push(`/resources`);
        // console.log(this.props.match.params.activity_uuid);
    }

    componentDidMount() {
        this.getTask(this.props.match.params.task_uuid);
    }

    getTask(taskUuid) {
        this.setState({
            taskLoading: true,
        });
        // console.log("taskUuid", taskUuid, "task", this.props.task);
        axios
            .get(`api/v1/planner/tasks/${taskUuid}`)
            .then(({ data }) => {
                this.setState({
                    task: data.data,
                    taskLoading: false,
                });
            })
            .catch((e) => {
                this.setState({
                    taskError: true,
                    taskLoading: false,
                });
            });
    }

    render() {
        //console.log(this.props.groups.data);
        const { match, plannerPath } = this.props;

        return (
            <React.Fragment>
                <PageTitle title="Activity Added" />

                <Row>
                    <Column>
                        <Panel
                            style={{
                                maxWidth: "700px",
                                margin: "3em auto",
                                padding: "2em",
                                textAlign: "center",
                            }}
                        >
                            <h2>{trans("Activity successfully added")}</h2>
                            <Link
                                to={`/plan/task/${match.params.task_uuid}/activity/${match.params.activity_uuid}`}
                            >
                                <Button style={{ marginRight: "1rem" }}>
                                    {trans("View Activity")}
                                </Button>
                            </Link>{" "}
                            {/*<Link to={`/resources?activityUuid=${match.params.activity_uuid}`}>*/}
                            {!this.state.taskError && (
                                <Button
                                    disabled={this.state.taskLoading}
                                    onClick={() => {
                                        this.handleAddOrder(
                                            this.state.task,
                                            match.params.activity_uuid
                                        );
                                    }}
                                    style={{ marginRight: "1rem" }}
                                >
                                    {trans("Add Order")}
                                </Button>
                            )}
                            {/*</Link>*/}{" "}
                            <Link
                                to={`/requests/create?activityUuid=${match.params.activity_uuid}`}
                            >
                                <Button
                                    disabled={this.state.taskLoading}
                                    style={{ marginRight: "1rem" }}
                                >
                                    {trans("Add Reference")}
                                </Button>
                            </Link>
                        </Panel>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

ActivityAdded.displayName = "ActivityAdded";

ActivityAdded.propTypes = {
    task: PropTypes.shape({
        name: PropTypes.string,
    }),
    activity: PropTypes.shape({
        name: PropTypes.string,
    }),
    filters: PropTypes.array,
    user: PropTypes.object,
    viewPlanner: PropTypes.func,
};

ActivityAdded.defaultProps = {};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            startOrderProcess,
        },
        dispatch
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(
    withTheme(
        compose(connect(mapStateToProps, mapDispatchToProps))(ActivityAdded)
    )
);
