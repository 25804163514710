import React from "react";

import { Row, Column, IconTextButton } from "@cortexglobal/rla-components";

import { OrderDetails } from "@cortexglobal/cortex-dam";
import { trans } from "@cortexglobal/rla-intl";

const OrderListing = ({ activity, addAnOrder }) => {
    const orders = activity?.asset_orders;

    if (!orders || orders.length === 0) {
        return (
            <Row>
                <Column style={{ padding: "1em 0" }}>
                    {/*<h2>{trans("No orders found")}</h2>*/}
                    <IconTextButton
                        icon="plus"
                        showCircle={false}
                        onClick={addAnOrder}
                    >
                        {trans("Create an Order")}
                    </IconTextButton>
                </Column>
            </Row>
        );
    }

    return (
        <>
            {orders.map((order) => {
                return (
                    <OrderDetails order={order} showActivityDetails={false} />
                );
            })}
        </>
    );
};

export default OrderListing;
