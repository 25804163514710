import React, { Component } from "react";

import ActiveUsersChartConfiguration from "./ActiveUsersChartConfiguration";
import ActiveUsersChartOutput from "./ActiveUsersChartOutput";

class ActiveUsersChart extends Component {
    render() {
        if (this.props.configuring) {
            return <ActiveUsersChartConfiguration {...this.props} />;
        }
        return <ActiveUsersChartOutput {...this.props} />;
    }
}

export default ActiveUsersChart;
