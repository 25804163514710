import React, {
    MouseEvent,
    useState,
    useEffect,
    useContext,
    useRef,
} from "react";
import styled, { css } from "styled-components";
import { useRouteMatch, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faChevronLeft,
    faChevronRight,
    faBars,
    faExpand,
    faCompress,
    faQuoteLeft,
    faInfo,
    faHouse,
} from "@fortawesome/free-solid-svg-icons";

//@ts-ignore cortex
import { useQueryString, transparentize } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
import { useIntl } from "@cortexglobal/rla-intl";

import { GO_HOME, ActionType } from "../hooks/useSlideshowNavigation";
import { Link } from "../interfaces/navigation";
import MicrositeContext from "../MicrositeContext";
import { UtilityButton, Spacer, Logo } from "./utilityComponents";

const FooterContainer = styled.footer<FooterProps>`
    position: relative;
    /* z-index: 1000; */
    transition: all 300ms ease;
    transform: translateY(-100%);

    ${({ theme }) => {
        if (theme.backgroundType === "none") {
            return;
        }
        if (theme.backgroundType === "color") {
            return css`
                background: linear-gradient(
                    ${theme.backgroundColor1},
                    ${theme.backgroundColor2}
                );
            `;
        }
        if (theme.backgroundImage) {
            return css`
                background-image: url(${theme.backgroundImage});
            `;
        }
    }}

    padding: 1em;
    padding-top: ${({ theme }) =>
        theme.backgroundSlant === "none" ? "0.8em" : "1.5em"};
    padding-bottom: 0.7em;
    /* padding-top: calc(${(props) => props.padding} * 2.2); */
    /* padding-bottom: calc(${(props) => props.padding} * 1.2); */
    ${({ theme }) => {
        if (theme.backgroundSlant === "none") {
            return;
        }
        if (theme.backgroundSlant === "highLeft") {
            return css`
                clip-path: polygon(0% 0%, 100% 20%, 100% 100%, 0% 100%);
            `;
        }
        if (theme.backgroundSlant === "highRight") {
            return css`
                clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0 100%);
            `;
        }
    }}
    margin: 0 auto;
    display: flex;
    justify-content: ${({ theme }) => theme.buttonAlignment};
    align-items: center;
    font-size: ${(props) => props.fontSize};
    width: ${(props) => props.width};
`;

export const Footer = ({
    menu = "main",
    menuShowing,
    setMenuShowing,
}: {
    dispatch: React.Dispatch<ActionType>;
    menu?: string;
    menuShowing: boolean;
    setMenuShowing: (showing: boolean) => void;
}) => {
    const intl = useIntl();
    const footerRef = useRef(null);

    const {
        fullscreen,
        setFullscreen,
        slideSize,
        setPrescribingInfoShowing,
        prescribingInfoShowing,
        setFootnotesShowing,
        footnotesShowing,
        hasFootnotes,
        setAdditionalInformationShowing,
        additionalInformationShowing,
        hasAdditionalInformation,
        globalSettings,
        navigationDispatch,
        preview,
        globalTheming,
        footerTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);

    const updateFullScreen = (event: Event) => {
        if (document.fullscreenElement) {
            setFullscreen(true);
        } else {
            setFullscreen(false);
        }
    };
    useEffect(() => {
        document.addEventListener("fullscreenchange", updateFullScreen, true);
        return document.removeEventListener(
            "fullscreenchange",
            updateFullScreen
        );
    }, []);

    const toggleFootnotes = () => {
        if (setFootnotesShowing) setFootnotesShowing(!footnotesShowing);
        if (setAdditionalInformationShowing)
            setAdditionalInformationShowing(false);
        if (setPrescribingInfoShowing) setPrescribingInfoShowing(false);
    };

    const toggleAdditionalInformation = () => {
        if (setAdditionalInformationShowing)
            setAdditionalInformationShowing(!additionalInformationShowing);
        if (setFootnotesShowing) setFootnotesShowing(false);
        if (setPrescribingInfoShowing) setPrescribingInfoShowing(false);
    };

    const togglePrescribingInfo = () => {
        if (setPrescribingInfoShowing)
            setPrescribingInfoShowing(!prescribingInfoShowing);
        if (setAdditionalInformationShowing)
            setAdditionalInformationShowing(false);
        if (setFootnotesShowing) setFootnotesShowing(false);
    };

    //Use the page path URL and pathname to find the current page config
    //then use the config to see whether to hide the footer

    const micrositeBaseUrl = useRouteMatch().url;
    const fullLocationUrl = useLocation().pathname;
    const pagePath = fullLocationUrl.replace(`${micrositeBaseUrl}/`, "");
    //Get the appropriate config (if the path is empty it's the index page, which is the
    //first in the pages array)

    // const pageConfig =
    //     pagePath === "" || pagePath === micrositeBaseUrl
    //         ? pages[0]
    //         : pages.find((page) => page[0] === pagePath);

    // console.log(
    // "micrositeBaseUrl",
    // micrositeBaseUrl,
    // "fullLocationUrl",
    // fullLocationUrl,
    // "pagePath",
    // pagePath,
    //     "pageConfig",
    //     pageConfig
    // );
    // console.log("pages", pages);
    //Ensure the config was found and the relevant settings actually says to hide the footer
    if (useQueryString().get("hide-footer")) {
        return null;
    }

    if (
        currentPageConfig?.hide_footer &&
        (currentPageConfig?.hide_footer === true ||
            currentPageConfig.hide_footer === "true")
    ) {
        return null;
    }
    //TODO - Check if the page has additional information and if it does enable the
    //the extra info button to display it in a modal
    return (
        <React.Fragment>
            <FooterContainer
                ref={footerRef}
                width={slideSize?.width}
                padding={slideSize?.fontSize}
                fontSize={slideSize?.fontSize}
                theme={footerTheming}
            >
                {!preview && (
                    <>
                        <UtilityButton
                            disabled={preview}
                            onClick={(e: MouseEvent) => {
                                setMenuShowing(!menuShowing);
                            }}
                            title={intl.formatMessage({
                                id: "Navigation Menu",
                            })}
                            theme={footerTheming}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </UtilityButton>

                        <Spacer theme={footerTheming} />

                        <UtilityButton
                            disabled={preview}
                            onClick={(e: MouseEvent) => {
                                setMenuShowing(false);
                                if (navigationDispatch)
                                    navigationDispatch({ type: GO_HOME });
                            }}
                            title={intl.formatMessage({ id: "Home" })}
                            theme={footerTheming}
                        >
                            <FontAwesomeIcon icon={faHouse} />
                        </UtilityButton>

                        {globalSettings?.useAsSlideshow && (
                            <>
                                <UtilityButton
                                    disabled={preview}
                                    onClick={(e: MouseEvent) => {
                                        setMenuShowing(false);
                                        document.dispatchEvent(
                                            new KeyboardEvent("keydown", {
                                                key: "ArrowLeft",
                                            })
                                        );
                                    }}
                                    title={intl.formatMessage({
                                        id: "Previous",
                                    })}
                                    theme={footerTheming}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </UtilityButton>

                                <UtilityButton
                                    disabled={preview}
                                    onClick={(e: MouseEvent) => {
                                        setMenuShowing(false);
                                        document.dispatchEvent(
                                            new KeyboardEvent("keydown", {
                                                key: "ArrowRight",
                                            })
                                        );
                                    }}
                                    title={intl.formatMessage({ id: "Next" })}
                                    theme={footerTheming}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </UtilityButton>

                                <Spacer theme={footerTheming} />

                                <UtilityButton
                                    disabled={preview}
                                    onClick={(e: MouseEvent) => {
                                        if (!document.fullscreenElement) {
                                            document.documentElement.requestFullscreen();
                                        } else {
                                            if (document.exitFullscreen) {
                                                document.exitFullscreen();
                                            }
                                        }
                                    }}
                                    title={
                                        !fullscreen
                                            ? intl.formatMessage({
                                                  id: "Enter Full Screen",
                                              })
                                            : intl.formatMessage({
                                                  id: "Exit Full Screen",
                                              })
                                    }
                                    theme={footerTheming}
                                >
                                    {!fullscreen ? (
                                        <>
                                            <FontAwesomeIcon icon={faExpand} />
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faCompress}
                                            />
                                        </>
                                    )}
                                </UtilityButton>
                            </>
                        )}

                        {(hasAdditionalInformation || hasFootnotes) && (
                            <>
                                <Spacer theme={footerTheming} />

                                {hasAdditionalInformation && (
                                    <UtilityButton
                                        disabled={
                                            !hasAdditionalInformation || preview
                                        }
                                        onClick={toggleAdditionalInformation}
                                        title={intl.formatMessage({
                                            id: "Additional Information",
                                        })}
                                        theme={footerTheming}
                                    >
                                        <FontAwesomeIcon icon={faInfo} />
                                    </UtilityButton>
                                )}

                                {hasFootnotes && (
                                    <UtilityButton
                                        disabled={!hasFootnotes || preview}
                                        onClick={toggleFootnotes}
                                        title={intl.formatMessage({
                                            id: "Show Footnotes",
                                        })}
                                        theme={footerTheming}
                                    >
                                        <FontAwesomeIcon icon={faQuoteLeft} />
                                    </UtilityButton>
                                )}
                            </>
                        )}

                        {globalSettings?.prescribingInformation && (
                            <UtilityButton
                                disabled={preview}
                                onClick={togglePrescribingInfo}
                                title={intl.formatMessage({
                                    id: "Prescribing Info",
                                })}
                                theme={footerTheming}
                            >
                                PI
                            </UtilityButton>
                        )}
                    </>
                )}
                {globalTheming?.logo && (
                    <Logo theme={footerTheming} src={globalTheming?.logo} />
                )}
            </FooterContainer>
        </React.Fragment>
    );
};

interface FooterProps {
    width?: string;
    padding?: string;
    fontSize?: string;
    theme: { [key: string]: any };
}
