import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import MediaChannelForm, { MediaChannel } from "../../forms/MediaChannelForm";
export const MediaChannelCreate = ({ path }) => {
    const history = useHistory();

    const item: MediaChannel = {
        name: "",
        slug: "",
        description: "",
        // thumbnail: "",
        order: 0,
        locale: "",
        assetTypeIds: [],
        available_in_asset_library: true,
        available_in_planner: true,
        custom_form_id: null,
        thumbnail: "",
    };

    const submitForm = (values) => {
        return axios
            .post(`/api/v1/admin/media-channel`, values)
            .then(({ data }) => {
                //As creation was successful move them on to the appropriate listing page
                history.push(`${path}`);

                return data;
            });
    };
    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("New Media Channel")}{" "}
                        {/* <HeaderEmphasis>{assetAdmin.asset.name}</HeaderEmphasis> */}
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <MediaChannelForm item={item} submitForm={submitForm} />
                </Panel>
            </Row>
        </div>
    );
};

export default MediaChannelCreate;
