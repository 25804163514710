import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Currency from "../standardReturnElements/currency";
import { shade, hexToRgb } from "@cortexglobal/cortex-utilities";
import CloseButton from "../../closeButton";
// const InputWrapper = styled.span`
//     display: inline-block;
//     position: relative;
// `;
// const ClearButton = styled(CloseButton)`
//     position: absolute;
//     top: 0;
//     right: 0;
// `;
const types = [
    "date",
    "datetime-local",
    "email",
    "hidden",
    "month",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "time",
    "week",
];

// export const StyledBaseInput = styled(BaseInput)`
export const StyledCurrencyInput = styled(Currency)`
    ${(props) =>
        types.indexOf(props.type) !== -1 &&
        css`
            padding: 0 5px;
            height: ${(props) => props.height}px;
            width: ${(props) => (props.width ? props.width : "100%")};
            /* width: ${(props) =>
                props.labelWidth
                    ? `calc(100% - ${props.labelWidth}px)`
                    : "100%"}; */
            border: 1px solid
                ${(props) =>
                    props.error
                        ? props.theme.input.error.borderColor
                        : props.theme.input.borderColor};
            /* margin-bottom: ${(props) => props.theme.spacing.margin}rem; */
            margin-bottom: ${(props) =>
                props.marginBottom ? props.marginBottom : "1.2rem"};
            font: inherit;
            font-size: 0.9rem;
            background: ${(props) => props.theme.input.background};
            color: ${(props) =>
                props.error
                    ? props.theme.input.error.color
                    : props.theme.input.color};

            // Input group styles
            ${(props) =>
                props.inputGroup
                    ? css`
                          border-radius: 0
                              ${(props) => props.theme.input.radius}
                              ${(props) => props.theme.input.radius} 0;
                          border-left: none;
                      `
                    : css`
                          border-radius: ${(props) => props.theme.input.radius};
                      `};

            // focus
            &:focus {
                outline: none;
                box-shadow: 0px 0px 0px 3px
                    rgba(
                        ${(props) => {
                            const rgb = hexToRgb(props.theme.input.focusColor);
                            return `${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2`;
                        }}
                    );
            }
            // disabled
            &:disabled {
                background: ${(props) => props.theme.input.disabledBackground};
                cursor: not-allowed;
            }
        `};
`;

StyledCurrencyInput.displayName = "StyledCurrencyInput";

StyledCurrencyInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
    labelWidth: PropTypes.string,
    labelAlign: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.number,
    allowDecimal: PropTypes.bool,
    decimalsLimit: PropTypes.number,
};

StyledCurrencyInput.defaultProps = {
    size: "default",
    type: "number",
    readOnly: false,
    //error: "",
    height: 38,
    placeholder: "",
    defaultValue: 0,
    allowDecimal: true,
    decimalsLimit: 2,
};

export default StyledCurrencyInput;
