import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Collapse } from "react-collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { saveAs } from "file-saver";
import {
    faEllipsisV,
    faPlus,
    faCalendarAlt,
    faList,
    faTable,
} from "@fortawesome/free-solid-svg-icons";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";

import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Loading,
    SidePanel,
    PageTitle,
    FilterPanel,
    CloseButton,
    Modal,
    DatePicker,
    useToasts,
    useAddToast,
    StyledDateRangePicker,
    Select,
    LoadingIconSmall,
    ButtonDropdown,
    ComboButton,
    BulkImporter,
} from "@cortexglobal/rla-components";

import {
    format,
    getItem,
    setItem,
    isValidDate,
    getLocaleDateFormat,
} from "@cortexglobal/cortex-utilities";
import { trans, useIntl } from "@cortexglobal/rla-intl";

import { LinearCalendar } from "@cortexglobal/linear-calendar";
import { InjectableTooltip } from "@cortexglobal/injectable-tooltip";

import BudgetSummary from "../components/BudgetSummary";
import Legend from "../components/Legend";
import TaskList from "../components/TaskList";
import TaskTable from "../components/TaskTable";
import ActivityOverview from "../tooltips/ActivityOverview";
import TaskOverview from "../tooltips/TaskOverview";
import ActivityContent from "../components/ActivityContent";
import TaskContent from "../components/TaskContent";
import DuplicatePlanner from "../modals/DuplicatePlanner";
import ImportPlanner from "../modals/ImportPlanner";
import {
    BudgetColumnHeader,
    BudgetColumnSummary,
} from "../components/plannerBudgetSummaryComponents";

import {
    // getPlan,
    // generateDateRange,
    selectCategory,
    selectTask,
    selectActivity,
    resetSelections,
    // showActivityOrder,
    // addOrderToPlan,
    showModal,
    hideModal,
    showSummary,
    hideSummary,

    // //Move to appropriate components
    // plannerEdit,
    getAvailableBudget,
    setCategoryExpanded,
} from "../plannerActions";
import CategorySummary from "../components/CategorySummary";
import styled from "styled-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const OverviewCloseButton = styled(CloseButton)`
    margin: 1rem;
    color: ${({ theme }) => theme.panel.headingColor};
`;

const Planner = ({
    tasks,
    getAvailableBudget,
    startDate,
    endDate,
    refreshPlan,
    config,
    selectedFilters,
    ...props
}) => {
    const { can } = usePermissions();
    const intl = useIntl();
    const history = useHistory();
    const addToast = useAddToast();
    const [state, setState] = useState({
        action: "none",
        filtersShowing: false,
        budgetSummaryShowing: false,
        viewType: getItem("planner_view_type", "table")["planner_view_type"],
        units: getItem("planner_units")["planner_units"],
        // selectedFilters: {},

        start: null,
        end: null,
        modalName: "",
    });

    useEffect(() => {
        getAvailableBudget();
        refreshPlan();
        setState({
            ...state,
            start: new Date(startDate),
            end: new Date(endDate),
        });
    }, []);

    useEffect(() => {
        if (state.start === null || state.end === null) {
            setState({
                ...state,
                start: new Date(startDate),
                end: new Date(endDate),
            });
        }
    }, [startDate, endDate]);

    const showSummary = ({ activity, task, category, left, width, event }) => {
        setState({ ...state, target: event.target });
        props.selectCategory(category);
        props.selectTask(task);
        props.selectActivity(activity);
        props.showSummary();
    };

    const hideSummary = () => {
        props.hideSummary();
    };
    /**
     * Override the default addActivity function to add the activity to the plan and refresh the plan
     * @param {*} task
     */
    const addActivity = (task) => {
        props.addActivity(task, refreshPlan);
    };

    const renderSummaryContent = () => {
        const { activity, task, category } = props.selections;
        const {
            modalVisible,
            modalName,
            showModal,
            hideModal,
            addOrderToPlan,
            national,
            startDate,
            endDate,
            viewActivity,
            user,
            viewTask,
            theme,
            match,
        } = props;

        if (!activity) {
            return (
                <TaskOverview
                    category={category}
                    task={task}
                    viewTask={viewTask}
                    addActivity={addActivity}
                    start={startDate}
                    end={endDate}
                    addOrderToPlan={addOrderToPlan}
                    user={user}
                    match={match}
                    theme={theme}
                    hideSummary={hideSummary}
                />
            );
        }

        return (
            <ActivityOverview
                category={category}
                task={task}
                activity={activity}
                modalVisible={modalVisible}
                modalName={modalName}
                showModal={showModal}
                hideModal={hideModal}
                viewActivity={viewActivity}
                start={startDate}
                end={endDate}
                addOrderToPlan={addOrderToPlan}
                user={user}
                theme={theme}
            />
        );
    };

    const handleDateChange = ({ value }) => {
        setState({ ...state, start: value[0], end: value[1] });
        if (value[0] && value[1]) {
            props.changeDates(value[0], value[1]);
        }
    };

    const setUnits = ({ value }) => {
        setState({ ...state, units: value });
        setItem("planner_units", value);
        refreshPlan();
    };

    const handleButtonClick = (viewType) => {
        setItem("planner_view_type", viewType);
        setState({ ...state, viewType });
    };

    const handleShowTasks = (expanded, category) => {
        if (config.use_user_categories) {
            props.setCategoryExpanded(expanded, category);
        }
    };

    const exportKpiData = (campaignIds, exportType) => {
        axios
            .get("api/v1/planner/kpi-export", {
                responseType: "blob",
                params: {
                    campaignIds: campaignIds,
                    exportType: exportType,
                },
            })
            .then((response) => {
                saveAs(new Blob([response.data]), `${exportType}_export.xlsx`);
                addToast({
                    type: "success",
                    content: trans("KPI export downloaded successfully"),
                    showFor: 5000,
                });
            })
            .catch(async (e) => {
                let response = await e.response.data.text();

                let responseObj = JSON.parse(response);

                const message = responseObj
                    ? responseObj.message
                    : trans("Kpi export could not be downloaded");
                props.toast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            });
    };

    const header = () => {
        return <div />;
    };

    const footer = () => {
        return <div />;
    };

    const {
        summaryVisible,
        tasksLoading,
        theme,
        categories,
        changeDates,
        viewTask,
        addTaskCategory,
        editTaskCategory,
        addTask,
        heights,
        user,
        group,
        summaryType,
        filters,
        filterTasks,
        permissions,
        dates,
        match,
        kpiData,
    } = props;

    const {
        filtersShowing,
        // selectedFilters,
        budgetSummaryShowing,
        viewType,
        modalName,
        units,
    } = state;

    const labels = {
        editCategory: {
            content: (
                <small>
                    <FontAwesomeIcon icon="edit" /> {trans("Edit Category")}
                </small>
            ),
            title: "Add Campaign",
            className: "",
        },
        addTask: {
            content: (
                <small>
                    <FontAwesomeIcon icon="plus-circle" />{" "}
                    {trans("Add Campaign")}
                </small>
            ),
            title: "Add Campaign",
            className: "",
        },
        viewTask: {
            // content: <FontAwesomeIcon icon="eye" />,
            title: "View Campaign",
            className: "",
        },
        addActivity: {
            content: <FontAwesomeIcon icon="plus" />,
            title: "Add Activity",
            className: "",
        },
    };

    if (!state.start || !state.end) {
        return null;
    }

    //The extra actions are determined by the permissions of the user
    let extraActions = [];
    if (can("planner-manage")) {
        extraActions.push({
            name: intl.formatMessage({
                id: "Duplicate Plan",
            }),
            onClick: () => {
                setState({ ...state, modalName: "duplicatePlan" });
            },
        });
    }
    if (can("admin-categories-create")) {
        extraActions.push({
            name: intl.formatMessage({
                id: "Add Category",
            }),
            onClick: () => {
                history.push(`${match.path}/task-category/add`);
            },
        });
    }
    if (can("planner-import-kpi-data")) {
        extraActions.push({
            name: intl.formatMessage({
                id: "Import KPI Data",
            }),
            onClick: () => {
                setState({ ...state, modalName: "importKpiData" });
            },
        });
    }

    extraActions.push({
        name: intl.formatMessage({
            id: "Export Campaign KPI Data",
        }),
        onClick: () => {
            const campaignIds = tasks.map((task) => task.id);

            exportKpiData(campaignIds, "campaign");
        },
    });
    extraActions.push({
        name: intl.formatMessage({
            id: "Export Activity Data",
        }),
        onClick: () => {
            const campaignIds = tasks.map((task) => task.id);

            exportKpiData(campaignIds, "activity");
        },
    });

    return (
        <>
            <section>
                <Helmet>
                    <title>
                        {process.env.REACT_APP_NAME} -{" "}
                        {intl.formatMessage({ id: "Marketing Planner" })}
                    </title>
                </Helmet>
                <PageTitle
                    title={
                        <span style={{ display: "flex" }}>
                            {trans("Marketing Planner")}{" "}
                            {tasksLoading && (
                                <LoadingIconSmall
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "1rem",
                                    }}
                                />
                            )}
                        </span>
                    }
                    margin={0}
                >
                    <div style={{ zIndex: 999, marginRight: "1.5rem" }}>
                        <ComboButton
                            buttons={[
                                // {
                                //     key: "calendar",
                                //     onClick: () => {
                                //         handleButtonClick("calendar");
                                //     },
                                //     content: trans("Calendar"),
                                //     active: viewType === "calendar",
                                //     icon: faCalendarAlt,
                                // },
                                {
                                    key: "list",
                                    onClick: () => {
                                        handleButtonClick("list");
                                    },
                                    content: trans("List"),
                                    active: viewType === "list",
                                    icon: faList,
                                },
                                {
                                    key: "table",
                                    onClick: () => {
                                        handleButtonClick("table");
                                    },
                                    content: trans("Planner"),
                                    active: viewType === "table",
                                    icon: faTable,
                                },
                            ]}
                            onChange={({ value }) => {
                                setItem("planner_view_type", value);
                                setState({ ...state, viewType: value });
                            }}
                            marginBottom="0"
                        />
                    </div>
                    <div style={{ minWidth: "160px" }}>
                        {viewType === "calendar" ? (
                            <DatePicker
                                showMonthYearPicker
                                value={new Date(startDate)}
                                onChange={({ value }) =>
                                    changeDates(
                                        startOfMonth(new Date(value)),
                                        endOfMonth(new Date(value))
                                    )
                                }
                                marginBottom="0"
                                dateFormat="LLLL yyyy"
                            />
                        ) : (
                            <StyledDateRangePicker
                                name="dateRange"
                                value={[state.start, state.end]}
                                onChange={handleDateChange}
                                showUnitsSelector={viewType === "table"}
                                onUnitsSelect={(units) => {
                                    setUnits(units);
                                }}
                                units={units}
                            />
                        )}
                    </div>
                    <IconTextButton
                        onClick={() => {
                            setState({
                                ...state,
                                filtersShowing: !state.filtersShowing,
                            });
                        }}
                        rotation={filtersShowing ? 270 : 90}
                        showCircle={false}
                        expanded={false}
                    >
                        {trans("Filters")}
                    </IconTextButton>

                    {can("planner-manage") && (
                        <IconTextButton
                            onClick={() => {
                                history.push(`${match.path}/add`);
                            }}
                            showCircle={false}
                            expanded={false}
                            icon={faPlus}
                        >
                            {trans("Add Campaign")}
                        </IconTextButton>
                    )}
                    {extraActions.length > 0 && (
                        <div style={{ zIndex: "998", display: "flex" }}>
                            <ButtonDropdown
                                type="secondary"
                                expanded={true}
                                icon={faEllipsisV}
                                hidePrimaryAction={true}
                                hideBackground={true}
                                actions={extraActions}
                            >
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </ButtonDropdown>
                        </div>
                    )}
                </PageTitle>

                {filters.loaded && (
                    <FilterPanel
                        expanded={filtersShowing}
                        filters={filters.data}
                        selectedFilters={selectedFilters}
                        onUpdate={(selectedFilters) => {
                            filterTasks(selectedFilters);
                        }}
                        onClear={() => {
                            filterTasks({});

                            setState({
                                ...state,
                                filtersShowing: false,
                            });
                        }}
                        onApply={() => {
                            setState({
                                ...state,
                                filtersShowing: false,
                            });
                        }}
                        showApply={true}
                        showSummary={true}
                        config={{ allow_saved_filters: false }}
                        user={user}
                        savedFilter={null}
                    />
                )}
                <section style={{ position: "relative" }}>
                    {viewType === "calendar" && (
                        <Row>
                            <Column
                                collapse
                                style={{
                                    position: "relative",
                                    overflow: "hidden",
                                }}
                            >
                                <LinearCalendar
                                    summaryColumn={{
                                        header: BudgetColumnHeader,
                                        content: BudgetColumnSummary,
                                        width: 200,
                                    }}
                                    start={startDate}
                                    end={endDate}
                                    dates={dates}
                                    categories={categories.data}
                                    tasks={tasks}
                                    // dateFormat={DATE_FORMAT}
                                    // activityDateFormat={DATABASE_DATE_FORMAT}
                                    sidebarWidth={200}
                                    addButtonWidth={40}
                                    onShowTasks={handleShowTasks}
                                    activityClick={showSummary}
                                    activityContent={ActivityContent}
                                    taskContent={TaskContent}
                                    categorySummary={CategorySummary}
                                    taskClick={showSummary}
                                    // taskClick={addTask}
                                    addTaskCategory={addTaskCategory}
                                    editTaskCategory={editTaskCategory}
                                    addTask={addTask}
                                    addActivity={addActivity}
                                    // addActivity={addActivity.bind(this)}
                                    viewTask={viewTask}
                                    header={header()}
                                    footer={footer()}
                                    prev={{
                                        content: (
                                            <FontAwesomeIcon icon="angle-double-left" />
                                        ),
                                        className: "",
                                    }}
                                    // prevAction={() => changeDates(-1)}
                                    next={{
                                        content: (
                                            <FontAwesomeIcon icon="angle-double-right" />
                                        ),
                                        className: "",
                                    }}
                                    // nextAction={() => changeDates(1)}
                                    labels={labels}
                                    theme={theme}
                                    user={user}
                                />

                                {summaryType === "tooltip" &&
                                    summaryVisible &&
                                    state.target && (
                                        <InjectableTooltip
                                            active={summaryVisible}
                                            parent={state.target}
                                            position="bottom"
                                            arrowPlacement="top"
                                            zIndex={50}
                                            hideTooltip={hideSummary}
                                        >
                                            {renderSummaryContent()}
                                        </InjectableTooltip>
                                    )}
                            </Column>
                        </Row>
                    )}
                    {viewType === "list" && (
                        <TaskList
                            tasks={tasks}
                            addActivity={addActivity}
                            user={user}
                            match={match}
                            resetSelections={resetSelections}
                        />
                    )}
                    {viewType === "table" && (
                        <TaskTable
                            start={startDate}
                            end={endDate}
                            dates={dates}
                            categories={categories.data}
                            tasks={tasks}
                            onShowTasks={handleShowTasks}
                            activityClick={showSummary}
                            activityContent={ActivityContent}
                            taskContent={TaskContent}
                            categorySummary={CategorySummary}
                            taskClick={showSummary}
                            addTaskCategory={addTaskCategory}
                            editTaskCategory={editTaskCategory}
                            addTask={addTask}
                            addActivity={addActivity}
                            viewTask={viewTask}
                            labels={labels}
                            theme={theme}
                            user={user}
                            match={match}
                            resetSelections={resetSelections}
                            units={units}
                            kpiData={kpiData}
                        />
                    )}
                    <Row
                        style={{
                            position: "sticky",
                            bottom: 0,
                            marginTop: "1rem",
                            zIndex: 1,
                        }}
                    >
                        <Panel
                            style={{
                                marginBottom: 0,
                            }}
                        >
                            <Row
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Column
                                    medium={6}
                                    className="marketingPlanner__Legend"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Legend
                                        user={user}
                                        group={group}
                                        config={config}
                                        colors={theme.colors}
                                    />
                                </Column>
                                <Column
                                    medium={6}
                                    style={{ textAlign: "right" }}
                                >
                                    <IconTextButton
                                        onClick={() => {
                                            setState({
                                                ...state,
                                                budgetSummaryShowing:
                                                    !budgetSummaryShowing,
                                            });
                                            window.scrollTo(
                                                0,
                                                document.body.scrollHeight
                                            );
                                            // window.scrollTo(
                                            //     0,
                                            //     document.documentElement
                                            //         .scrollHeight
                                            // );
                                        }}
                                    >
                                        {budgetSummaryShowing
                                            ? trans("Hide Budget Summary")
                                            : trans("Show Budget Summary")}
                                    </IconTextButton>
                                </Column>
                            </Row>
                        </Panel>
                    </Row>
                </section>

                {summaryType === "sidePanel" && state.target && (
                    <SidePanel
                        sticky={false}
                        fixed={true}
                        visible={summaryVisible}
                        onClose={hideSummary}
                        width="400px"
                    >
                        <div
                            style={{
                                position: "sticky",
                            }}
                        >
                            <OverviewCloseButton onClick={hideSummary} />
                            {renderSummaryContent()}
                        </div>
                    </SidePanel>
                )}

                {modalName === "duplicatePlan" && (
                    <DuplicatePlanner
                        start={startDate}
                        end={endDate}
                        modalName={modalName}
                        hideModal={() => setState({ ...state, modalName: "" })}
                    />
                )}

                {modalName === "importPlan" && (
                    <ImportPlanner
                        modalName={modalName}
                        hideModal={() => setState({ ...state, modalName: "" })}
                    />
                )}
                {modalName === "importKpiData" && (
                    <Modal
                        visible={modalName === "importKpiData"}
                        onClose={() => {
                            setState({ ...state, modalName: "" });
                        }}
                        showCloseButton={false}
                        fullScreen={true}
                        modalBackground="rgba(0,0,0,0.5)"
                    >
                        <BulkImporter
                            templateUrl={`/api/v1/bulk-importer?template=kpi`}
                            fileSubmissionUrl={`/api/v1/bulk-importer/pre-import?template=kpi`}
                            rowSubmissionUrl={`/api/v1/bulk-importer/post-import?template=kpi`}
                            onCancel={() => {
                                setState({ ...state, modalName: "" });
                                refreshPlan();
                            }}
                            fileType="csv"
                        />
                    </Modal>
                )}
                <Collapse isOpened={budgetSummaryShowing}>
                    <BudgetSummary
                        style={{ marginTop: "1rem" }}
                        user={user}
                        availableBudget={props.availableBudget}
                        tasks={tasks}
                        start={startDate}
                        end={endDate}
                        permissions={permissions}
                    />
                </Collapse>
            </section>
        </>
    );
};

Planner.displayName = "Planner";

Planner.propTypes = {
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            activities: PropTypes.shape({
                national: PropTypes.string,
                plan: PropTypes.string,
                group: PropTypes.string,
            }),
            header: PropTypes.shape({
                label: PropTypes.string,
                button: PropTypes.string,
            }),
            micro: PropTypes.shape({
                label: PropTypes.string,
                current: PropTypes.string,
                currentBackground: PropTypes.string,
                line: PropTypes.string,
            }),
            calendarCategory: PropTypes.shape({
                color: PropTypes.string,
                background: PropTypes.string,
            }),
            campaignGroup: PropTypes.shape({
                label: PropTypes.string,
                button: PropTypes.string,
                background: PropTypes.string,
            }),
            taskRow: PropTypes.shape({
                label: PropTypes.string,
                labelHover: PropTypes.string,
                button: PropTypes.string,
                background: PropTypes.string,
                sidebar: PropTypes.string,
            }),
        }),
        heights: PropTypes.shape({
            row: PropTypes.number,
            task: PropTypes.number,
            activity: PropTypes.number,
        }),
    }),
    selectedFilters: PropTypes.object,
    national: PropTypes.bool,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    showSummary: PropTypes.func.isRequired,
    hideSummary: PropTypes.func.isRequired,
    modalVisible: PropTypes.bool.isRequired,
    modalName: PropTypes.string.isRequired,
    summaryVisible: PropTypes.bool.isRequired,
    addTaskCategory: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // getPlan,
            // generateDateRange,
            selectCategory,
            selectTask,
            selectActivity,
            // plannerEdit,
            // showActivityOrder,
            // addOrderToPlan,
            showModal,
            hideModal,
            showSummary,
            hideSummary,
            getAvailableBudget,
            setCategoryExpanded,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const {
        startDate,
        endDate,
        dates,
        categories,
        selections,
        loaded,
        modalVisible,
        modalName,
        summaryVisible,
        availableBudget,
    } = state.planner;
    const { group } = state.auth;
    const { permissions } = state.auth.role;
    return {
        startDate,
        endDate,
        dates,
        categories,
        selections,
        loaded,
        modalVisible,
        modalName,
        summaryVisible,
        availableBudget,
        group,
        permissions,
    };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Planner);
