import React, { useEffect, useState } from "react";
import { trans } from "@cortexglobal/rla-intl";
import {
    Badge,
    Button,
    Column,
    Loading,
    PageTitle,
    Row,
    TableList,
    Tabordion,
    useAddToast,
} from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "@cortexglobal/cortex-utilities";
import OrderTable from "../components/OrderTable";

const OrderRow = ({ order, path }) => {
    return (
        <tr>
            <td>
                <Badge type="warning">{order.ref}</Badge>
            </td>
            <td>
                <span style={{ textTransform: "capitalize" }}>
                    {order.type}
                </span>
            </td>
            <td>{format(new Date(order.created), "PP")}</td>
            <td>
                <Link to={`${path}/assigned/${order.uuid}`}>
                    <Button>View Order</Button>
                </Link>
            </td>
        </tr>
    );
};

const OrdersAssigned = ({ path }) => {
    const addToast = useAddToast();
    const [loading, setLoading] = useState(true);
    const [ordersAssigned, setOrdersAssigned] = useState([]);

    const getOrdersAssigned = (type) => {
        setLoading(true);

        axios
            .get("/api/v1/orders/assigned", {
                params: {
                    list: type ? type : "assigned",
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setOrdersAssigned(data.data);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "Error loading " + type + " orders",
                    showFor: 5000,
                });
            });
    };

    useEffect(() => {
        getOrdersAssigned();
    }, []);

    return (
        <>
            <PageTitle title={trans("Orders Assigned")} />

            <Row>
                <Column>
                    <Tabordion current={0} unmounts={false}>
                        <OrderTable
                            orders={ordersAssigned}
                            path={path}
                            loading={loading}
                            heading={
                                <div
                                    onClick={() =>
                                        getOrdersAssigned("assigned")
                                    }
                                >
                                    {trans("Orders Assigned")}
                                </div>
                            }
                        />

                        <OrderTable
                            orders={ordersAssigned}
                            path={path}
                            loading={loading}
                            heading={
                                <div
                                    onClick={() => getOrdersAssigned("created")}
                                >
                                    {trans("Orders Created")}
                                </div>
                            }
                        />

                        <OrderTable
                            orders={ordersAssigned}
                            path={path}
                            loading={loading}
                            heading={
                                <div
                                    onClick={() =>
                                        getOrdersAssigned("available")
                                    }
                                >
                                    {trans("Orders Available")}
                                </div>
                            }
                        />
                    </Tabordion>
                </Column>
            </Row>
        </>
    );
};

export default OrdersAssigned;
