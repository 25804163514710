import React from "react";
import styled from "styled-components";
import moment from "moment";
import { get, isEmpty } from "lodash";
import { Row, ContentBlockLoader, Table } from "@cortexglobal/rla-components";
import { trans, FormattedDate } from "@cortexglobal/rla-intl";

const StatusSummary = ({ event, workflowLoading = "loaded" }) => {
    return (
        <Table tableType="noStripe">
            <tbody>
                <tr>
                    <th style={{ width: "33%" }}>{trans("Created")}</th>
                    <td>
                        <FormattedDate value={new Date(event.created)} />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: "33%" }}>{trans("Status")}</th>
                    <td>
                        <ContentBlockLoader
                            loaded={workflowLoading === "loaded"}
                        >
                            {get(event, "workflowable.state.name", "Draft")}
                        </ContentBlockLoader>
                    </td>
                </tr>
                <tr>
                    <th style={{ width: "33%" }}>{trans("Assigned To")}</th>
                    <td>
                        <ContentBlockLoader
                            loaded={workflowLoading === "loaded"}
                        >
                            {event.assignedTo()}
                        </ContentBlockLoader>
                    </td>
                </tr>

                {/* {event.getSignOffs().length > 0 && (
                    <tr>
                        <th style={{ width: "33%" }}>
                            {trans("Signed Off By")}
                        </th>
                        <td>
                            <ContentBlockLoader
                                loaded={workflowLoading === "loaded"}
                            >
                                {event.getSignOffs().map((signoff) => {
                                    return (
                                        <section>
                                            {formatDate(
                                                moment(get(signoff, "date")),
                                                "DD/MM/Y HH:mm"
                                            )}{" "}
                                            by{" "}
                                            {[
                                                get(signoff, "name"),
                                                get(signoff, "role"),
                                                get(signoff, "group"),
                                            ]
                                                .filter((s) => !!s)
                                                .join(", ")}
                                        </section>
                                    );
                                })}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                )} */}
            </tbody>
        </Table>
    );
};

export default StatusSummary;
