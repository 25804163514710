import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
} from "@cortexglobal/rla-components";
import { useState } from "react";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const PageViewTotalConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    timeFrame,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Page View Total" }),
        timeFrame: timeFrame ? timeFrame : "",
    });

    const options = [
        { value: "1", text: intl.formatMessage({ id: "-1 Month" }) },
        { value: "3", text: intl.formatMessage({ id: "-3 Month" }) },
        { value: "6", text: intl.formatMessage({ id: "-6 Month" }) },
        { value: "12", text: intl.formatMessage({ id: "-1 Year" }) },
        { value: "all", text: intl.formatMessage({ id: "All time" }) },
    ];

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder="Custom Title"
                        onChange={onChange}
                    />
                    <SelectField
                        name="timeFrame"
                        label={trans("Time frame")}
                        value={state.timeFrame}
                        options={options}
                        onChange={onChange}
                        expanded
                    />
                    <Button type="submit">{trans("Save Panel")}</Button>
                </form>
            </Column>
        </Row>
    );
};

PageViewTotalConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default PageViewTotalConfiguration;
