import React, { useEffect, useState } from "react";
import axios from "axios";

import {
    ContentBlockLoader,
    CheckboxTree,
    Row,
    Column,
    SubmitButton,
    useAddToast,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

interface GroupCategory {
    id: number;
}
interface GroupCategorySection {
    name: string;
    categories: [];
}

interface CategoryOption {
    value: number;
    text: string;
}
export const GroupCategoriesForm = ({ item, submitForm }) => {
    const [categorySections, setCategorySections] = useState<{
        loaded: boolean;
        data: GroupCategorySection[];
    }>({
        loaded: false,
        data: [],
    });
    const addToast = useAddToast();

    const [groupCategories, setGroupCategories] = useState<GroupCategory[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState("");

    useEffect(() => {
        axios.get(`/api/v1/admin/group/categories`).then(({ data }) => {
            setCategorySections({ loaded: true, data: data.data });
        });
    }, []);

    useEffect(() => {
        setGroupCategories(item.data.category_ids);
    }, [item]);

    const updateGroupCategories = (event) => {
        setGroupCategories(event.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const values = {
            category_ids: groupCategories,
        };

        submitForm(values, setSubmitting, "categories").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            {categorySections.data.map((section) => (
                <FormRow
                    key={section.name}
                    name="categories"
                    label={section.name}
                    helpText={trans("Optional")}
                >
                    <ContentBlockLoader loaded={categorySections.loaded}>
                        <CheckboxTree
                            onChange={updateGroupCategories}
                            name="categories"
                            value={groupCategories}
                            options={section.categories}
                            selectChildren={true}
                            allowListView={true}
                            showWrapper={true}
                        />
                    </ContentBlockLoader>
                </FormRow>
            ))}
            <Row>
                <Column medium={6} style={{ textAlign: "right" }}>
                    &nbsp;
                </Column>
                <Column
                    medium={3}
                    flex
                    style={{
                        paddingBottom: "1rem",
                        justifyContent: "flex-end",
                    }}
                >
                    <SubmitButton
                        label={trans("Save")}
                        submitting={submitting}
                        type="primary"
                    />
                </Column>
            </Row>
        </form>
    );
};

export default GroupCategoriesForm;
