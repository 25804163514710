import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

// import ValidationError from "./components/ValiationError";
import {
    InputField,
    TextareaField,
    Select,
    RadioField,
    StyledCheckbox,
    Panel,
    FormRow,
    ConditionalWrapper,
    useAddToast,
} from "@cortexglobal/rla-components";

import AssetContext from "../AssetContext";
import { SectionHeading } from "./components/SectionHeading";
import CampaignSelect from "./components/CampaignSelect";
import AssetTypeSelect from "./components/AssetTypeSelect";
import MediaChannelSelect from "./components/MediaChannelSelect";

export const NamingAndGeneralSetup = ({
    // assetAdmin: { assetTypesLoaded, assetTypes },
    standardOnChange,
    asset,
    config,
    libraryArea = "assets-default",
    errors,
    singleColumn = false,
    showSectionHeading = true,
    collapse = false,
    excludedFields = [],
    preLoadedCountries = null,
    preloadedLanguages = null,
    assetTypes = null,
    mediaChannels = null,
    campaignsPath = "/admin/campaigns",
}) => {
    const { can } = usePermissions();
    const { user } = useContext(AssetContext);
    const [localeSetting, setLocaleSetting] = useState(
        config.options && config.options.locale_setting
            ? config.options.locale_setting
            : "default"
    );
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);

    const assetType =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset?.asset_type?.slug;

    const [showVersioning, setShowVersioning] = useState(
        config.options &&
            config.options.global_versioning === "enabled" &&
            config.asset_types[assetType] &&
            (typeof config.asset_types[assetType].versioning_disabled ===
                "undefined" ||
                !config.asset_types[assetType].versioning_disabled)
    );

    useEffect(() => {
        const usersLanguage = languages.find(
            (language) => language.iso_code === user.language
        );
        if (usersLanguage && !asset.language_code) {
            standardOnChange({ name: "locale", value: usersLanguage.iso_code });
        }
    }, [languages]);

    useEffect(() => {
        switch (localeSetting) {
            case "country":
                if (preLoadedCountries) {
                    setCountries(preLoadedCountries);
                    if (user && user.country_code) {
                        preLoadedCountries.forEach((country) => {
                            if (country.iso_code === user.country_code) {
                                setLanguages(country.available_languages);
                            }
                        });
                    }
                } else {
                    axios
                        .get(`/api/v1/country`)
                        .then(({ data: { data: countries } }) => {
                            setCountries(countries);
                            if (user && user.country_code) {
                                countries.forEach((country) => {
                                    if (
                                        country.iso_code === user.country_code
                                    ) {
                                        setLanguages(
                                            country.available_languages
                                        );
                                    }
                                });
                            }
                        });
                }

                break;
            case "language":
                // default:
                if (preloadedLanguages) {
                    setLanguages(preloadedLanguages);
                } else {
                    axios
                        .get(`/api/v1/language`)
                        .then(({ data: { data: languages } }) => {
                            setLanguages(languages);
                        });
                }

                break;
        }
    }, [preloadedLanguages]);

    useEffect(() => {
        if (asset.isVersioned) {
            standardOnChange({ name: "versioning_enabled", value: "1" });
        }
        // if (asset.asset_type) {
        //     // narrowMediaTypes(asset.asset_type);
        //
        //     // const assetTypeIsVersioned =
        //     //     config.options &&
        //     //     config.options.global_versioning === "enabled" &&
        //     //     config.asset_types[asset.asset_type] &&
        //     //     (typeof config.asset_types[asset.asset_type]
        //     //         .versioning_disabled === "undefined" ||
        //     //         !config.asset_types[asset.asset_type].versioning_disabled);
        //
        //     const assetTypeIsVersioned = asset.isVersioned;
        //
        //     setShowVersioning(assetTypeIsVersioned);
        //     if (assetTypeIsVersioned && config.options.default_versioning_on) {
        //         standardOnChange({ name: "versioning_enabled", value: "1" });
        //     }
        // }
    }, [asset.asset_type]);

    // const narrowMediaTypes = (assetType) => {
    //     const availableMediaTypes = Object.values(config.media_types)
    //         .filter((mediaType) => {
    //             return (mediaType.asset_types || []).includes(assetType);
    //         })
    //         .map((mediaType) => {
    //             return {
    //                 text: mediaType.name,
    //                 value: mediaType.alias,
    //             };
    //         });
    //     setMediaTypes(availableMediaTypes);
    //     if (availableMediaTypes.length === 1) {
    //         standardOnChange({
    //             name: "media_type",
    //             value: availableMediaTypes[0].value,
    //         });
    //     }
    // };

    // const showVersioning =
    //     config.options &&
    //     config.options.global_versioning === "enabled" &&
    //     config.asset_types[asset.asset_type] &&
    //     (typeof config.asset_types[asset.asset_type].versioning_disabled ===
    //         "undefined" ||
    //         !config.asset_types[asset.asset_type].versioning_disabled);

    const userAssetsRequireApprovalByDefault =
        config?.options?.user_assets_require_approval;
    return (
        <React.Fragment>
            {showSectionHeading && (
                <SectionHeading>{trans("General Details")}</SectionHeading>
            )}

            <ConditionalWrapper
                condition={!singleColumn}
                wrapper={(children) => <Panel>{children}</Panel>}
            >
                {" "}
                {!excludedFields.includes("name") && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="name"
                        label={trans("Asset Name")}
                        error={errors.name}
                        // helpText={trans("Optional")}
                    >
                        <InputField
                            name="name"
                            value={asset.name}
                            onChange={standardOnChange}
                            showError={false}
                        />
                    </FormRow>
                )}
                {!excludedFields.includes("description") && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="description"
                        label={trans("Asset Description")}
                        error={errors.description}
                        helpText={trans("Optional")}
                    >
                        <TextareaField
                            name="description"
                            value={asset.description || ""}
                            onChange={standardOnChange}
                        />
                    </FormRow>
                )}
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="library_area"
                    label={trans("Library")}
                    error={errors.library_area}
                    helpText={trans(
                        "Which library should this asset appear in?"
                    )}
                >
                    <Select
                        name="library_area"
                        options={config.libraries}
                        value={asset.library_area}
                        onChange={standardOnChange}
                        tags={false}
                        multi={false}
                        creatable={false}
                    />
                </FormRow>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="asset_type"
                    label={trans("Asset Type")}
                    error={errors.asset_type}
                    helpText={trans("How is the asset used by the system?")}
                >
                    <AssetTypeSelect
                        name="asset_type"
                        value={asset?.asset_type}
                        onChange={standardOnChange}
                        tags={false}
                        multi={false}
                        creatable={false}
                        extension={asset?.main_file_extension}
                        assetTypes={assetTypes}
                    />
                </FormRow>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="media_type"
                    label={trans("Media Type")}
                    error={errors.media_type}
                    helpText={trans("What format/type is the asset?")}
                >
                    <MediaChannelSelect
                        name="media_type"
                        value={asset?.media_type}
                        onChange={standardOnChange}
                        tags={false}
                        multi={false}
                        creatable={false}
                        disabled={!assetType}
                        mediaChannels={mediaChannels}
                        assetType={assetType}
                    />
                </FormRow>
                {asset.printable && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="print_configuration_id"
                        label={trans("Print Configuration")}
                        error={errors.print_configuration_id}
                        // helpText={trans("What format/type is the asset?")}
                    >
                        <Select
                            name="print_configuration_id"
                            options={
                                config.printConfigurations
                                    ? config.printConfigurations.map((item) => {
                                          return {
                                              text: item.name,
                                              value: item.id,
                                          };
                                      })
                                    : []
                            }
                            value={asset.print_configuration_id}
                            onChange={standardOnChange}
                            tags={false}
                            multi={false}
                            creatable={false}
                            error={errors.print_configuration_id}
                        />
                    </FormRow>
                )}
                {localeSetting === "country" && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="country_code"
                        label={trans("Country")}
                        error={errors.country_code}
                        helpText={trans(
                            "What country is the asset designed for?"
                        )}
                    >
                        <Select
                            expanded={true}
                            name="country_code"
                            value={asset.country_code}
                            options={countries.reduce(
                                (countryOptions, country) => {
                                    countryOptions.push({
                                        value: country.iso_code,
                                        text: country.name,
                                    });
                                    return countryOptions;
                                },
                                [{ value: "", text: "None Selected" }]
                            )}
                            onChange={(input) => {
                                standardOnChange(input);
                                if (countries) {
                                    const selectedCountry = countries.find(
                                        (country) =>
                                            country.iso_code === input.value
                                    );
                                    const availableLanguages = selectedCountry
                                        ? selectedCountry.available_languages
                                        : [];
                                    setLanguages(availableLanguages);

                                    standardOnChange({
                                        name: "locale",
                                        value:
                                            availableLanguages.length === 1
                                                ? availableLanguages[0].iso_code
                                                : "",
                                    });
                                }
                            }}
                        />
                    </FormRow>
                )}
                {(localeSetting === "country" ||
                    localeSetting === "language") && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="locale"
                        label={trans("Language")}
                        error={errors.locale}
                        helpText={trans("What language is the asset?")}
                    >
                        <Select
                            expanded={true}
                            name="locale"
                            value={asset.locale}
                            options={languages.reduce(
                                (languageOptions, language) => {
                                    languageOptions.push({
                                        value: language.iso_code,
                                        text: language.name,
                                    });
                                    return languageOptions;
                                },
                                []
                            )}
                            disabled={languages.length === 0}
                            onChange={standardOnChange}
                        />
                    </FormRow>
                )}
                {showVersioning && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="versioning_enabled"
                        label={trans("Enable Versioning")}
                        error={errors.versioning_enabled}
                        helpText={trans("Should updates be versioned")}
                    >
                        <RadioField
                            name="versioning_enabled"
                            label={trans("Enable Versioning")}
                            error={errors.versioning_enabled}
                            helpText={trans("Should updates be versioned")}
                            options={[
                                { text: "Yes", value: "1" },
                                { text: "No", value: "0" },
                            ]}
                            value={asset.versioning_enabled}
                            onChange={standardOnChange}
                            tags={false}
                            multi={false}
                            inlineRadioButtons={true}
                            creatable={false}
                        />
                    </FormRow>
                )}
                {userAssetsRequireApprovalByDefault && asset.editable && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="user_assets_require_approval"
                        label={trans("Personalised Assets Require Approval?")}
                        helpText={trans(
                            "If not checked, personalised assets will be immediately available for download."
                        )}
                        error={errors.user_assets_require_approval}
                        // helpText={trans("What format/type is the asset?")}
                    >
                        <StyledCheckbox
                            name="user_assets_require_approval"
                            id="user_assets_require_approval"
                            type="checkbox"
                            inline={true}
                            value={asset.user_assets_require_approval}
                            checked={asset.user_assets_require_approval}
                            onChange={(event) =>
                                standardOnChange({
                                    name: "user_assets_require_approval",
                                    value: event.target.checked,
                                })
                            }
                        />
                    </FormRow>
                )}
                {can("dam-campaign-edit") && (
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="campaign_uuid"
                        label={trans("Campaign")}
                        helpText={trans(
                            "Optional, Which campaign should this asset appear in?"
                        )}
                        error={errors.campaign_uuid}
                    >
                        <CampaignSelect
                            name="campaign_uuid"
                            id="campaign_uuid"
                            value={asset.campaign_uuid}
                            onChange={standardOnChange}
                            campaignsPath={campaignsPath}
                        />
                    </FormRow>
                )}
            </ConditionalWrapper>
            {/*<Row>
                <Column medium={6}>
                    <InputField
                        name="shareable_approval_ref"
                        value={asset.shareable_approval_ref}
                        // label={trans("Approved for sharing reference")}
                        onChange={standardOnChange}
                    />
                </Column>
            </Row>*/}
        </React.Fragment>
    );
};
// NamingAndGeneralSetup.propTypes = {
//     updateAssetProps: PropTypes.func.isRequired
// };
export default NamingAndGeneralSetup;
