import React from "react";
import styled from "styled-components";
import {
    HelpText,
    FormLabel,
    Column,
    ConditionalWrapper,
    Tooltip,
} from "../../../index";

type LabelProps = {
    name: string;
    // label: string | { [key: string]: any };
    // helpText?: string | { [key: string]: any };
    label: any;
    helpText?: any;
    tooltip?: any;
    columns?: number;
    required?: boolean;
    textAlign?: string;
    collapse?: boolean;
};

const Required = styled.span`
    color: red;
    position: absolute;
    right: -10px;
    top: 0;
`;

export const LabelColumn = ({
    name,
    label,
    helpText,
    tooltip,
    columns = 3,
    required = false,
    textAlign = "right",
    collapse,
}: LabelProps) => {
    return (
        <Column
            style={{ textAlign: textAlign }}
            medium={columns}
            collapse={collapse}
        >
            {label && (
                <div style={{ position: "relative" }}>
                    <FormLabel
                        style={{ textAlign: textAlign }}
                        marginBottom={0}
                        name={name}
                    >
                        <ConditionalWrapper
                            condition={tooltip}
                            wrapper={(children) => (
                                <Tooltip zIndex={99999} text={tooltip}>
                                    {children}
                                </Tooltip>
                            )}
                        >
                            {label}
                        </ConditionalWrapper>
                    </FormLabel>{" "}
                    {!collapse && helpText && <HelpText>{helpText}</HelpText>}
                    {required && <Required>*</Required>}
                </div>
            )}
        </Column>
    );
};
