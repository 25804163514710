import React, { useEffect, useState } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { ShareComposer } from "@cortexglobal/communication";

import { editTask, getPlannerChildGroups } from "../plannerActions";

const TaskNotificationForm = ({ task, onComplete, groups }) => {
    console.log({ groups });
    const intl = useIntl();
    return (
        <React.Fragment>
            <ShareComposer
                urls={[
                    {
                        title: task.name,
                        url: `${window.origin}/plan/task/${task.uuid}`,
                    },
                ]}
                onClose={onComplete}
                title={trans("Notify Users about this Campaign")}
                subject={intl.formatMessage({
                    id: "Head Office Campaign added to the planner",
                })}
                prependedMessage={intl.formatMessage({
                    id: "Please view the the details of this campaign:",
                })}
            />
        </React.Fragment>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            editTask,
            getPlannerChildGroups,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const { groups } = state.planner;
    return {
        groups,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskNotificationForm);
