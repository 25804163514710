import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    FormikSelect,
    FormikInput,
    FormikTextarea,
    Row,
    Column,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    id: Yup.number(),
    uuid: Yup.string(),
    name: Yup.string().required("Required"),
    alias: Yup.string(),
    opening_times_summary: Yup.string().max(255).nullable(),
    preferred_print_supplier_id: Yup.number(),
});
export type MerchantForm = ReturnType<typeof schema.validateSync>;

export const MerchantFormForm = ({ item, submitForm, canFullGroupAdmin }) => {
    const [supplierGroups, setSupplierGroups] = useState({
        loaded: false,
        data: [],
    });
    const { can } = usePermissions();

    useEffect(() => {
        getSuppliers();
    }, []);

    const getSuppliers = () => {
        axios
            .get(`/api/v1/admin/suppliers?optionList=true`)
            .then(({ data }) => {
                setSupplierGroups({
                    loaded: true,
                    data: data.data,
                });
            });
    };

    const handleSubmit = (
        values: MerchantForm,
        { setErrors, setSubmitting, ...rest }
    ) => {
        values["groupable_type"] = "Merchant";
        values["groupable_uuid"] = item.entity.uuid;

        submitForm(values, setSubmitting, "merchant").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    const getInitialOpeningTimes = (item) => {
        if (item.entity && item.entity.opening_times_summary) {
            return item.entity.opening_times_summary;
        }

        if (item.opening_times_summary) {
            return item.opening_times_summary;
        }

        return "";
    };

    const getInitialPrintSupplier = (item) => {
        if (item.entity && item.entity.preferredPrintSupplier) {
            return item.entity.preferredPrintSupplier.id;
        } else {
            return "";
        }
    };

    return (
        <Formik
            initialValues={{
                id: item.id,
                uuid: item.uuid,
                name: item.name,
                alias: item.alias,
                opening_times_summary: getInitialOpeningTimes(item),
                preferred_print_supplier_id: getInitialPrintSupplier(item),
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        {canFullGroupAdmin && (
                            <>
                                <FormRow name="id" label={trans("ID")}>
                                    <FormikInput
                                        disabled
                                        name="id"
                                        value={item.id}
                                    />{" "}
                                </FormRow>
                                <FormRow name="uuid" label={trans("UUID")}>
                                    <FormikInput
                                        disabled
                                        name="uuid"
                                        value={item.uuid}
                                    />
                                </FormRow>
                            </>
                        )}
                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Required")}
                            error={errors.name}
                        >
                            <FormikInput name="name" value={values.name} />
                        </FormRow>
                        <FormRow
                            name="alias"
                            label={trans("Alias")}
                            helpText={trans(
                                "Optional (will be automated if not added)"
                            )}
                            error={errors.alias}
                        >
                            <FormikInput
                                disabled={!can("admin-group-edit")}
                                name="alias"
                                value={values.alias}
                                showError={false}
                            />
                        </FormRow>

                        {supplierGroups.data.length > 0 && ( // dont show printers if there are none
                            <FormRow
                                name="preferred_print_supplier_id"
                                label={trans("Print Supplier")}
                                helpText={trans(
                                    "Optional (print options will only be available if this is set)"
                                )}
                                error={errors.preferred_print_supplier_id}
                            >
                                <FormikSelect
                                    name="preferred_print_supplier_id"
                                    value={values.preferred_print_supplier_id}
                                    showError={false}
                                    options={supplierGroups.data}
                                />
                            </FormRow>
                        )}

                        <FormRow
                            name="opening_times_summary"
                            label={trans("Opening times summary")}
                            helpText={trans("Optional")}
                            error={errors.opening_times_summary}
                        >
                            <FormikTextarea
                                name="opening_times_summary"
                                value={values.opening_times_summary}
                            />
                        </FormRow>

                        <Row>
                            <Column medium={6}>&nbsp;</Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MerchantFormForm;
