import React, { useEffect, useState } from "react";
import axios from "axios";
import { InputField, LoadingIconSmall } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { isEmpty } from "lodash";

const AliasField = ({ uuid, onChange, base, ...rest }) => {
    const [currentValue, setCurrentValue] = useState("");
    const [taken, setTaken] = useState(false);
    const [suggestion, setSuggestion] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isEmpty(uuid) && !isEmpty(base)) {
            checkAlias(slugify(base), true);
        }
    }, [base]);

    /**
     * Reference: https://gist.github.com/codeguy/6684588
     * @param text
     * @returns {string}
     */
    const slugify = (text) => {
        if (typeof text !== "string") {
            return;
        }

        return text
            .toString() // Cast to string (optional)
            .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
            .toLowerCase() // Convert the string to lowercase letters
            .trim() // Remove whitespace from both sides of a string (optional)
            .replace(/\s+/g, "-") // Replace spaces with -
            .replace(/[^\w\-]+/g, "") // Remove all non-word chars
            .replace(/\_/g, "-") // Replace _ with -
            .replace(/\-\-+/g, "-") // Replace multiple - with single -
            .replace(/\-$/g, ""); // Remove trailing -
    };

    const checkAlias = (value, autoChange = false) => {
        setLoading(true);
        onChange({ name: "alias", value });

        setCurrentValue(value);

        const params = {
            params: {
                alias: value,
                uuid: uuid || null,
            },
        };

        axios
            .get("/api/v1/surveys/alias-check", params)
            .then(({ data }) => {
                setTaken(false);
                setSuggestion(null);
            })
            .catch((e) => {
                let autoSuggestion = e.response.data?.suggestion;

                if (autoChange === false) {
                    setTaken(true);
                    setSuggestion(e.response.data?.suggestion || null);
                    autoSuggestion = value;
                } else {
                    setTaken(false);
                }

                onChange({ name: "alias", value: autoSuggestion });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const error =
        taken && suggestion
            ? trans("{alias} is taken, suggestion: {suggestion}", {
                  alias: currentValue,
                  suggestion: suggestion,
              })
            : taken && !suggestion
            ? trans("{alias} is taken, suggestion, please pick another", {
                  alias: currentValue,
              })
            : "";

    return (
        <div
            style={{
                position: "relative",
                marginBottom: !isEmpty(error) ? "10px" : "inherit",
            }}
        >
            <InputField
                {...rest}
                onChange={(e) => checkAlias(e.value, false)}
                style={{ color: taken === true ? "red" : "black" }}
                error={error}
            />
            {loading && (
                <LoadingIconSmall
                    style={{
                        width: "20px",
                        margin: "2px 5px 0 0",
                        display: "inline-block",
                        position: "absolute",
                        right: "-0px",
                        top: "0px",
                    }}
                />
            )}
        </div>
    );
};

export default AliasField;
