import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Redirect, useParams } from "react-router-dom";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
    Panel,
    Row,
    Column,
    IconTextButton,
    Loading,
    Modal,
    Tabordion,
    PageTitle,
    Badge,
    CircularProgress,
    ContentBlockLoader,
    StyledStarRating,
    Table,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans, FormattedCurrency, useIntl } from "@cortexglobal/rla-intl";
import { Comments } from "@cortexglobal/comments";
import { Files } from "@cortexglobal/files";
import RelatedTasks from "../components/RelatedTasks";
import RelatedActivities from "../components/RelatedActivities";
import TaskActivityFiles from "../components/TaskActivityFiles";
import TaskDetails from "../components/TaskDetails";
import TaskActionPanel from "../components/TaskActionPanel";
import PlannerTaskForm from "../forms/PlannerTaskForm";
import TaskResultsForm from "../forms/TaskResultsForm";
import TaskResult from "../components/TaskResult";
import TaskApprovalDetails from "../components/TaskApprovalDetails";

const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: column;
`;

const ExpandedPanel = styled(Panel)`
    flex: 1;
`;

const initialState = {
    loaded: false,
    notFound: false,
    task: null,
    editing: false,
    deleting: false,
    addingResults: false,
};

const TabHeading = styled.span`
    display: flex;
    span {
        display: inline-block;
        margin-left: 0.3rem;
    }
`;
const ViewTask = ({
    deleteActivity: deleteActivityIn,
    deleteTask,
    theme: plannerTheme,
    viewPlanner,
    user,
    plannerPath,
    filters,
    groups,
    group,
    allUserGroups,
    category,
    addActivity,
    viewActivity,
    viewTask,
    addTaskPath,
    ...props
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const addToast = useAddToast();
    const [task, setTask] = useState(null);
    const [state, setState] = useState({ ...initialState });
    const { task_uuid } = useParams();

    useEffect(() => {
        getTask(task_uuid);
    }, [task_uuid]);

    const reloadTask = () => {
        if (task) {
            getTask(task.uuid);
        } else {
            getTask(task_uuid);
        }
    };

    const getTask = (taskUuid) => {
        setState({ ...state, loaded: false });

        axios
            .get(`api/v1/planner/tasks/${taskUuid}`)
            .then((result) => {
                setTask(result.data.data);
            })
            .catch((e) => {
                if (e.response) {
                    setState({ ...state, notFound: true });
                    addToast({
                        type: "alert",
                        message:
                            e?.response?.data?.message ||
                            "Error loading information, please check you have the correct permissions.",
                        showFor: 5000,
                    });
                }
            })
            .finally(() => {
                setState({ ...state, loaded: true });
            });
    };

    const deleteActivity = (activityUuid, taskUuid) => {
        return deleteActivityIn(activityUuid, taskUuid).then((response) => {
            getTask(taskUuid);
        });
    };

    const stopEditing = (task) => {
        if (task) {
            setTask(task);
        }
        setState({ ...state, editing: false });
    };

    const getTaskTitle = (task) => {
        const categories = filters.data.filter(
            (category) => category.name === "primary_category_alias"
        )[0];

        if (typeof categories === "undefined") {
            return `${task.name}`;
        }

        const taskCategoryName = categories.options.filter((channel) => {
            return task.primary_category_alias === channel.value;
        })[0];

        if (typeof taskCategoryName === "undefined") {
            return `${task.name}`;
        }

        return (
            <div>
                {task.ref && (
                    <Badge
                        style={{ marginRight: "0.5rem" }}
                        backgroundColor={
                            plannerTheme.colors.task[task.owner_type]
                        }
                    >
                        {task.ref}
                    </Badge>
                )}

                {taskCategoryName?.text && (
                    <Badge type="secondary" style={{ marginRight: "0.5rem" }}>
                        {taskCategoryName.text}
                    </Badge>
                )}

                {task.name}

                {task.results_submitted && (
                    <StyledStarRating
                        style={{ margin: "0 0 0 1rem" }}
                        editing={false}
                        value={task.overall_rating}
                        fontSize="22px"
                    />
                )}
            </div>
        );
    };

    const renderDetailsOrEdit = (task) => {
        const { loaded, editing } = state;

        if (!task || !loaded || !filters.loaded) {
            return (
                <div style={{ paddingTop: "1.2rem" }}>
                    <Loading />
                </div>
            );
        }

        if (editing) {
            return (
                <div style={{ paddingTop: "1.2rem" }}>
                    <PlannerTaskForm
                        task={task}
                        user={user}
                        group={group}
                        allUserGroups={allUserGroups}
                        groups={groups}
                        action="editTask"
                        onGoBack={(task) => {
                            if (task) {
                                return stopEditing(task);
                            }
                            return stopEditing();
                        }}
                    />
                </div>
            );
        } else {
            const activityFileCount = task.activities.reduce(
                (total, activity) => {
                    return (total += activity.files.length);
                },
                0
            );

            return (
                <Tabordion unmounts={false} current={0}>
                    <TaskDetails
                        heading={<span>{trans("Info")}</span>}
                        filters={filters.data}
                        task={task}
                        user={user}
                        groups={groups}
                    />

                    <RelatedActivities
                        category={category}
                        task={task}
                        activities={task.activities}
                        showHeading={false}
                        heading={
                            <TabHeading>
                                {trans("Activities")}{" "}
                                <Badge small={true}>
                                    {task.activities.length}
                                </Badge>
                            </TabHeading>
                        }
                        addActivity={addActivity}
                        deleteActivity={deleteActivity}
                        viewActivity={viewActivity}
                        theme={plannerTheme}
                        user={user}
                        getTask={getTask}
                    />

                    <RelatedTasks
                        task={task}
                        showHeading={false}
                        heading={
                            <TabHeading>
                                {trans("Related Campaigns")}{" "}
                                <Badge small={true}>{task.total_related}</Badge>
                            </TabHeading>
                        }
                        theme={plannerTheme}
                        viewTask={viewTask}
                        addTaskPath={addTaskPath}
                    />

                    <Files
                        smartRef={task.smartRef}
                        user={user}
                        showHeading={false}
                        canAdmin={task.can_admin_details}
                        heading={
                            <TabHeading>
                                {trans("Campaign Files")}{" "}
                                <Badge small={true}>{task.total_files}</Badge>
                            </TabHeading>
                        }
                    />

                    <TaskActivityFiles
                        task={task}
                        showHeading={false}
                        heading={
                            <TabHeading>
                                {trans("Activity Files")}{" "}
                                <Badge small={true}>{activityFileCount}</Badge>
                            </TabHeading>
                        }
                        theme={plannerTheme}
                        viewActivity={viewActivity}
                    />

                    <Comments
                        heading={
                            <TabHeading>
                                {trans("Discussion")}{" "}
                                <Badge small={true}>
                                    {task.total_comments}
                                </Badge>
                            </TabHeading>
                        }
                        smartRef={task.smartRef}
                        user={user}
                        alwaysShowForm={false}
                    />
                    {task.complete && (
                        <TaskResult
                            heading={<span>{trans("Results")} </span>}
                            task={task}
                            onSave={(updatedTask) => {
                                setTask(updatedTask);
                            }}
                            onCancel={null}
                            user={user}
                        />
                    )}
                </Tabordion>
            );
        }
    };

    const { notFound, deleting, addingResults, editing } = state;

    if (notFound) {
        return <Redirect to={`${plannerPath}`} />;
    }

    const budgetPercentage =
        task && task?.planned_spend?.value > 0 && task?.actual_spend?.value > 0
            ? Math.round(
                  (task.actual_spend.value / task.planned_spend.value) * 100
              )
            : 0;

    const { can_view_details } = task || {};

    return (
        <>
            <Helmet>
                <title>
                    {process.env.REACT_APP_NAME} -{" "}
                    {intl.formatMessage({ id: "Marketing Planner" })} -{" "}
                    {task ? task.name : ""}
                </title>
            </Helmet>

            <PageTitle size="small" title={task ? getTaskTitle(task) : ""}>
                <div>
                    {task && task.status_wording && (
                        <Badge>{task.status_wording}</Badge>
                    )}
                </div>

                <IconTextButton
                    onClick={viewPlanner}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to Planner")}
                </IconTextButton>
            </PageTitle>

            {state.loaded === false || !task ? (
                <Loading />
            ) : (
                <>
                    {task.status === "draft" && (
                        <Row>
                            <Column>
                                <p
                                    style={{
                                        background: theme.colors.warning,
                                        color: "white",
                                        padding: "15px 20px",
                                    }}
                                >
                                    {trans(
                                        "Please note: A campaign in Draft status will not affect any processes or data."
                                    )}
                                </p>
                            </Column>
                        </Row>
                    )}
                    <Row>
                        <FlexColumn large={can_view_details ? 9 : 12}>
                            <h3>{trans("Campaign Details")}</h3>
                            <ExpandedPanel style={{ paddingTop: 0 }}>
                                {renderDetailsOrEdit(task)}
                            </ExpandedPanel>
                        </FlexColumn>

                        {can_view_details && (
                            <FlexColumn large={3}>
                                <TaskActionPanel
                                    deleteActivity={deleteActivity}
                                    deleteTask={deleteTask}
                                    setEditing={(value) =>
                                        setState({ ...state, editing: value })
                                    }
                                    editing={editing}
                                    updateTask={setTask}
                                    task={task}
                                    theme={plannerTheme}
                                    viewActivity={viewActivity}
                                    reloadTask={reloadTask}
                                />

                                <h3>{trans("Campaign Approval Status")}</h3>
                                <Panel style={{ padding: "1rem" }}>
                                    <TaskApprovalDetails
                                        task={task}
                                        onUpdate={(task) => getTask(task.uuid)}
                                        plannerPath={plannerPath}
                                    />
                                </Panel>

                                <h3>{trans("Budget Summary")}</h3>
                                <Panel style={{ textAlign: "center" }}>
                                    <div style={{ marginBottom: "1rem" }}>
                                        <CircularProgress
                                            backgroundPadding={5}
                                            strokeWidth={14}
                                            value={budgetPercentage}
                                            text={`${budgetPercentage}%`}
                                            initialAnimation={true}
                                        />
                                    </div>
                                    <Column medium={6}>
                                        <h3>{trans("Planned")}</h3>{" "}
                                        <ContentBlockLoader loaded={!!task}>
                                            <FormattedCurrency
                                                value={
                                                    task
                                                        ? task.planned_spend
                                                              .value
                                                        : 0
                                                }
                                                currency={
                                                    user?.currency?.iso_code ||
                                                    "GBP"
                                                }
                                            />
                                        </ContentBlockLoader>
                                    </Column>
                                    <Column medium={6}>
                                        <h3>{trans("Actual")}</h3>{" "}
                                        <ContentBlockLoader loaded={!!task}>
                                            <FormattedCurrency
                                                value={
                                                    task
                                                        ? task.actual_spend
                                                              .value
                                                        : 0
                                                }
                                                currency={
                                                    user?.currency?.iso_code ||
                                                    "GBP"
                                                }
                                            />
                                        </ContentBlockLoader>
                                    </Column>
                                </Panel>
                            </FlexColumn>
                        )}
                    </Row>

                    <Modal
                        visible={deleting}
                        onClose={() => setState({ ...state, deleting: false })}
                    >
                        <Row>
                            <Column>
                                <h2>
                                    {trans(
                                        "Are you sure you want to delete this campaign?"
                                    )}
                                </h2>
                            </Column>
                            <Column large={6}>
                                <IconTextButton
                                    expanded
                                    onClick={() =>
                                        setState({ ...state, deleting: false })
                                    }
                                >
                                    {trans("Cancel")}
                                </IconTextButton>
                            </Column>
                            <Column large={6}>
                                <IconTextButton
                                    icon={faTrash}
                                    showCircle={false}
                                    expanded
                                    themeColor="alert"
                                    onClick={() => {
                                        deleteTask(task.uuid);
                                    }}
                                >
                                    {trans("Delete")}
                                </IconTextButton>
                            </Column>
                        </Row>
                    </Modal>

                    {addingResults && (
                        <Modal
                            visible={addingResults}
                            onClose={() =>
                                setState({ ...state, addingResults: false })
                            }
                            maxWidth="90%"
                        >
                            <h2>{trans("Add Campaign Results")}</h2>
                            <TaskResultsForm
                                task={task}
                                onSave={(updatedTask) => {
                                    setTask(updatedTask);
                                    setState({
                                        ...state,
                                        addingResults: false,
                                    });
                                }}
                                onCancel={() =>
                                    setState({ ...state, addingResults: false })
                                }
                            />
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default ViewTask;
