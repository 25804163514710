import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Panel,
} from "@cortexglobal/rla-components";
import CommunicationRuleForm from "../../forms/CommunicationRuleForm";
import { isEmpty } from "lodash";

export const CommunicationRuleEdit = ({ uuid, path }) => {
    const history = useHistory();
    const page = useQueryString().get("page");

    const [smartRefName, setSmartRefName] = useState(null);
    const [reference, setReference] = useState({});

    const [item, setItem] = useState({
        loaded: false,
        data: null,
    });

    const getItem = () => {
        setItem({ loaded: false, data: null });
        axios.get(`/api/v1/admin/messenger-rules/${uuid}`).then(({ data }) => {
            setSmartRefName(data.data?.smart_ref_decoded?.name);
            setReference(data.data?.smart_ref_decoded || {});
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, [uuid]);

    const submitForm = (values, setSubmitting) => {
        setSubmitting(true);

        return axios
            .put(`/api/v1/admin/messenger-rules/${uuid}`, values)
            .then(({ data }) => {
                setItem({
                    loaded: true,
                    data: data.data,
                });

                return data.data;
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const pageTitleName = !isEmpty(reference)
        ? trans("Edit Communication Rule for: {name}", reference)
        : trans("Edit Communication Rule");

    return (
        <div>
            <PageTitle title={pageTitleName}>
                <IconTextButton
                    as={Link}
                    to={`${path}?page=${page ? page : 1}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel style={{ paddingBottom: "0" }}>
                    {item.loaded ? (
                        <CommunicationRuleForm
                            item={item.data}
                            submitForm={submitForm}
                            smartRef={item.data.smart_ref}
                            reference={reference}
                        />
                    ) : (
                        <Loading />
                    )}
                </Panel>
            </Row>
        </div>
    );
};

export default CommunicationRuleEdit;
