import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import {
    Modal,
    Row,
    Column,
    MultiCheckbox,
    StyledSelectSimple,
    Button,
    FormLabel,
    useAddToast,
    useToasts
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const BulkEditModal = ({ group, asset, visible, onClose }) => {
    const intl = useIntl();
    const history = useHistory();
    const [previewUuid, setPreviewUuid] = useState(false);
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const createBlkEdit = () => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Bulk editable asset is being created")
        });
        axios
            .post(`/api/v1/assets/bulk-create`, {
                asset_uuid: asset.uuid,
                preview_uuid: previewUuid
            })
            .then(({ data: { data } }) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                addToast({
                    type: "success",
                    content: trans("Bulk editable asset created successful"),
                    showFor: 5000
                });
                console.log("data", data);

                history.push(`/resources/my-media/${data.uuid}/edit`);
            });
    };

    const mapOptions = listing => {
        return listing.map(item => {
            return {
                value: item.uuid,
                text: item.name
            };
        });
    };

    return (
        <Modal visible={visible} onClose={onClose} zIndex={0} maxWidth="600px">
            <Row>
                <Column>
                    <h2>{trans("Bulk Asset Creation")}</h2>
                </Column>
            </Row>
            <Row>
                <Column>
                    <p style={{ marginBottom: "2rem" }}>
                        {trans(
                            "In order to edit an asset, please select a group you'd like to use for previewing purposes. Groups you'd like to generate artwork for can selected after the asset has been customised."
                        )}
                    </p>
                    <p>
                        {trans(
                            "Autofill fields will not be available for when editing a bulk customisable asset."
                        )}
                    </p>
                </Column>
            </Row>
            {group.children && (
                <Row>
                    <Column>
                        <FormLabel name="group">
                            {trans("Select a group to preview")}
                        </FormLabel>
                        <StyledSelectSimple
                            name="group"
                            onChange={e => setPreviewUuid(e.target.value)}
                            options={mapOptions(group.children)}
                        />
                    </Column>
                </Row>
            )}
            <Row style={{ textAlign: "right" }}>
                <Column>
                    <Button onClick={createBlkEdit}>{trans("Create")}</Button>
                </Column>
            </Row>
        </Modal>
    );
};

export default BulkEditModal;
