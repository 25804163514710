import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikCheckboxTree,
    Panel,
    Loading,
    Tabordion,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { PreviewImage, TabordionGroup } from "../components/index";
import { WorkflowStatesTable } from "../screens/workflow/states/WorkflowStatesTable";
import { WorkflowTransitionsTable } from "../screens/workflow/transitions/WorkflowTransitionsTable";
import { WorkflowDiagram } from "../screens/workflow/states/WorkflowDiagram";

const schema = Yup.object().shape({
    name: Yup.string().required(trans("Required")),
    status: Yup.string().required(trans("Required")),
    // permissions: Yup.array()
});

export type Workflow = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    status?: string;
};

export const WorkflowForm = ({ item, submitForm }) => {
    // const [teams, setTeams] = useState([]);
    // const [teamsLoaded, setTeamsLoaded] = useState(false);
    // const [permissions, setPermissions] = useState([]);
    // const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [workflowStates, setWorkflowStates] = useState([]);
    const [workflowStatesLoaded, setWorkflowStatesLoaded] = useState(false);
    const [workflowTransitions, setWorkflowTransitions] = useState([]);
    const [workflowTransitionsLoaded, setWorkflowTransitionsLoaded] =
        useState(false);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (!workflowStatesLoaded) {
            axios
                .get(`/api/v1/admin/workflow/${item.uuid}/states`)
                .then(({ data }) => {
                    setWorkflowStates(data.data);
                    setWorkflowStatesLoaded(true);
                });
        }

        if (!workflowTransitionsLoaded) {
            axios
                .get(`/api/v1/admin/workflow/${item.uuid}/transitions`)
                .then(({ data }) => {
                    setWorkflowTransitions(data.data);
                    setWorkflowTransitionsLoaded(true);
                });
        }

        // axios.get(`/api/v1/admin/team?optionList=true`).then(({ data }) => {
        //     console.log(data);
        //     setTeams(data.data);
        //     setTeamsLoaded(true);
        // });
    }, []);

    const handleSubmit = (
        values: Workflow,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };
    return (
        <Formik
            initialValues={{
                name: item.name,
                status: item.status,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Tabordion current={tab} unmounts={false}>
                        <TabordionGroup
                            heading={<span>{trans("Details")}</span>}
                            onTabClick={() => setTab(0)}
                        >
                            <Form className="form-group">
                                {" "}
                                <FormRow
                                    name="name"
                                    label={trans("Name")}
                                    helpText={trans("Required")}
                                    error={errors.name}
                                >
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="status"
                                    label={trans("Status")}
                                    helpText={trans("Required")}
                                    error={errors.status}
                                >
                                    <FormikSelect
                                        name="status"
                                        value={values.status}
                                        options={[
                                            { value: "draft", text: "Draft" },
                                            { value: "active", text: "Active" },
                                        ]}
                                    />{" "}
                                </FormRow>
                                <Row>
                                    <Column
                                        medium={6}
                                        style={{ textAlign: "right" }}
                                    >
                                        &nbsp;
                                    </Column>
                                    <Column
                                        medium={3}
                                        flex
                                        style={{
                                            paddingBottom: "1rem",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <SubmitButton
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </Column>
                                </Row>
                            </Form>
                        </TabordionGroup>
                        <TabordionGroup
                            heading={<span>{trans("States")}</span>}
                            onTabClick={() => setTab(1)}
                        >
                            <WorkflowStatesTable
                                loaded={workflowStatesLoaded}
                                items={workflowStates}
                                workflow={item}
                            />
                        </TabordionGroup>
                        {workflowStatesLoaded && (
                            <TabordionGroup
                                heading={<span>{trans("Transitions")}</span>}
                                onTabClick={() => setTab(2)}
                            >
                                <WorkflowTransitionsTable
                                    loaded={workflowTransitionsLoaded}
                                    items={workflowTransitions}
                                    states={workflowStates}
                                    workflow={item}
                                />
                            </TabordionGroup>
                        )}
                        {workflowStatesLoaded && workflowTransitionsLoaded && (
                            <TabordionGroup
                                heading={<span>{trans("Diagram")}</span>}
                                onTabClick={() => setTab(3)}
                            >
                                <WorkflowDiagram
                                    workflow={item}
                                    states={workflowStates}
                                    transitions={workflowTransitions}
                                />
                            </TabordionGroup>
                        )}
                    </Tabordion>
                );
            }}
        </Formik>
    );
};

export default WorkflowForm;
