import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row, Column } from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import { SpacedRow, DetailWrapper } from "./overviewComponents";

const ActivitySummary = styled.li``;
const ActivitySummaryList = styled.ul`
    margin: 0;
    padding-left: 0;
    list-style: none;
`;
class TaskSummary extends Component {
    render() {
        const { category, task, user, match } = this.props;
        const start = moment(task.start_date);
        const end = moment(task.end_date);
        const created = moment(task.created_at);

        const duration = end.diff(start, "days") + 1;
        return (
            <DetailWrapper>
                <SpacedRow className="detailRow">
                    <Column small={5}>{trans("Start Date")}:</Column>
                    <Column small={7}>{start.format("LL")}</Column>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <Column small={5}>{trans("End Date")}:</Column>
                    <Column small={7}>{end.format("LL")}</Column>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <Column small={5}>{trans("Duration:")}</Column>
                    <Column small={7}>
                        {trans(
                            "{duration, plural, one {# day} other {# days}}",
                            {
                                duration,
                            }
                        )}
                    </Column>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <Column small={5}>{trans("Created Date")}:</Column>
                    <Column small={7}>{created.format("LL")}</Column>
                </SpacedRow>
                {task?.user?.name && (
                    <SpacedRow className="detailRow">
                        <Column small={5}>{trans("Created By (User)")}:</Column>
                        <Column small={7}>{task.user.name}</Column>
                    </SpacedRow>
                )}

                {task?.group?.name && (
                    <SpacedRow className="detailRow">
                        <Column small={5}>
                            {trans("Created By (Group)")}:
                        </Column>
                        <Column small={7}>{task.group.name}</Column>
                    </SpacedRow>
                )}
                {task.can_view_details && (
                    <SpacedRow className="detailRow">
                        <Column small={5}>{trans("Planned Cost")}:</Column>
                        <Column small={7}>
                            {task.planned_spend ? (
                                <FormattedCurrency
                                    value={task.planned_spend.value}
                                    currency={user?.currency?.iso_code || "GBP"}
                                />
                            ) : (
                                `-`
                            )}
                        </Column>
                    </SpacedRow>
                )}
                <SpacedRow className="detailRow">
                    <Column small={5}>{trans("Job Number")}:</Column>
                    <Column small={7}>
                        {task.external_ref ? task.external_ref : "-"}
                    </Column>
                </SpacedRow>
                <Row className="detailRow">
                    <Column>
                        <p>{trans("Activities")}:</p>
                    </Column>
                    <Column>
                        <ActivitySummaryList>
                            {task.activities.length > 0
                                ? task.activities.map((activity) => {
                                      return (
                                          <ActivitySummary key={activity.uuid}>
                                              <Link
                                                  to={`${match.path}/task/${task.uuid}/activity/${activity.uuid}`}
                                              >
                                                  #{activity.ref} -{" "}
                                                  {activity.name}
                                                  <FormattedCurrency
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                      value={
                                                          activity.cost.value
                                                      }
                                                      currency={
                                                          user.currency
                                                              .iso_code || "GBP"
                                                      }
                                                  />
                                              </Link>
                                          </ActivitySummary>
                                      );
                                  })
                                : trans("None")}
                        </ActivitySummaryList>
                    </Column>
                </Row>
            </DetailWrapper>
        );
    }
}

TaskSummary.propTypes = {
    task: PropTypes.object,
};

export default TaskSummary;
