import styled, { css } from "styled-components";

const HelpText = styled.aside`
    font-size: ${({ theme }) => theme.helpText.fontSize};
    color: ${({ theme }) => theme.helpText.color};
    font-style: ${({ theme }) => theme.helpText.fontStyle};
    line-height: ${({ theme }) => theme.helpText.lineHeight};
`;

export default HelpText;
