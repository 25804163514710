import React, { useContext } from "react";

import styled from "styled-components";

import { Row, Column } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import SurveySubmissionContext from "../SurveySubmissionContext";
import AnswerText from "./AnswerText";
import AnswerTextarea from "./AnswerTextarea";
import AnswerSingle from "./AnswerSingle";
import AnswerMulti from "./AnswerMulti";
import AnswerRange from "./AnswerRange";
import AnswerDate from "./AnswerDate";
import Attachment from "../components/Attachment";
import { transparentize } from "@cortexglobal/cortex-utilities";

const Caption = styled.p`
    color: gray;
    font-style: italic;
`;

const QuestionNumber = styled.span`
    background-color: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.white};
    display: inline-block;
    height: 23px;
    width: 23px;
    border-radius: 100%;
    text-align: center;
    vertical-align: top;
    line-height: 23px;
    font-size: 0.8rem;
    margin-right: 0.5rem;
`;

const InfoRow = styled(Row)`
    padding: 1.5rem 0;
    border-top: 1px ${(props) => transparentize(props.theme.colors.accent, 0.7)}
        solid;
`;

const SubmissionQuestion = ({ question, index, readonly = false }) => {
    const { surveyAnswersData } = useContext(SurveySubmissionContext);

    // If the question is an info section, render it differently
    const infoSection = question.type === "info";
    if (infoSection) {
        return (
            <InfoRow>
                <Column medium={8} centered={true}>
                    <h3>{question.question}</h3>
                    <p>{question.caption}</p>
                    {question.attachment && (
                        <Attachment attachment={question.attachment} />
                    )}
                </Column>
            </InfoRow>
        );
    }

    const findAnswer = () => {
        if (surveyAnswersData.length > 0) {
            return surveyAnswersData.find((answer) => {
                return answer.question_uuid === question.uuid;
            });
        }
        return false;
    };

    const answer = findAnswer() || { question_uuid: question.uuid, answer: "" };
    return (
        <Row style={{ marginBottom: "1rem" }}>
            <Column medium={8} centered={true}>
                <h3>
                    {question.number && (
                        <QuestionNumber>{question.number}</QuestionNumber>
                    )}{" "}
                    {question.question}
                    {(question.required === 1 ||
                        question.required === true) && (
                        <span style={{ color: "red" }}> *</span>
                    )}
                </h3>
                {question.caption && <Caption>{question.caption}</Caption>}
                {question.attachment && (
                    <Attachment attachment={question.attachment} />
                )}
                {question.type === "text" && (
                    <AnswerText answer={answer} readonly={readonly} />
                )}
                {question.type === "textarea" && (
                    <AnswerTextarea answer={answer} readonly={readonly} />
                )}
                {question.type === "single" && (
                    <AnswerSingle
                        answer={answer}
                        options={question.options}
                        readonly={readonly}
                    />
                )}
                {question.type === "multi" && (
                    <AnswerMulti
                        answer={answer}
                        options={question.options}
                        readonly={readonly}
                    />
                )}
                {question.type === "range" && (
                    <AnswerRange
                        answer={answer}
                        options={question.options}
                        readonly={readonly}
                    />
                )}

                {question.type === "date" && (
                    <AnswerDate
                        answer={answer}
                        options={question.options}
                        readonly={readonly}
                    />
                )}
            </Column>
        </Row>
    );
};

export default SubmissionQuestion;
