import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
Chart.register(CategoryScale);

export * from "react-chartjs-2";

export * from "./formatters";

export { CortexPie } from "./charts/CortexPie";
export { CortexBar } from "./charts/CortexBar";
export { CortexLine } from "./charts/CortexLine";
