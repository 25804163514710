import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
    Row,
    Column,
    PageTitle,
    IconTextButton,
    Loading,
    Error,
    Badge,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { ConfirmationServiceProvider } from "../../assets/ConfirmationService";
import Availability from "../../assets/components/detail/Availability";
import UserAssetContext from "../UserAssetContext";
import { LOADING_DATA, SELECT_USER_ASSET } from "../hooks/useUserAsset";
import AssetDetailsPreviewPanel from "../../assets/components/AssetDetailsPreviewPanel";
import ActionsPanel from "../components/ActionsPanel";

const ViewUserAsset = ({
    assetsPath,
    userAssetUuid,
    userAsset,
    selectUserAsset,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const getUserAsset = () => {
        setLoaded(false);
        axios
            .get(`/api/v1/user-assets/${userAssetUuid}`)
            .then(({ data }) => {
                selectUserAsset(data.data);
                setLoaded(true);
            })
            .catch((e) =>
                setError(
                    e.response && e.response.data
                        ? e.response.data.message
                        : trans("An error has occurred")
                )
            );
    };

    useEffect(() => {
        //Conditional removed so the user asset is always reloaded
        //this ensures all the extra details are loaded (and allows
        //the listing page to be more performant)
        // if (userAsset === null) {
        getUserAsset();
        // } else {
        //     setLoaded(true);
        // }
    }, [userAssetUuid]);

    if (error) {
        return <Error>{error}</Error>;
    } else if (!loaded || userAsset === null) {
        return <Loading />;
    } else {
        const asset = userAsset.asset ? userAsset.asset : userAsset;

        return (
            <ConfirmationServiceProvider>
                <PageTitle title={userAsset.name}>
                    {userAsset.download_approval_status && (
                        <Badge
                            type="secondary"
                            style={{ margin: "0.2rem 0.5rem 0 0" }}
                        >
                            {userAsset.download_approval_status}
                        </Badge>
                    )}
                    <IconTextButton
                        as={Link}
                        to={assetsPath}
                        rotation={180}
                        showCircle={false}
                        expanded={false}
                    >
                        {trans("Back to your assets")}
                    </IconTextButton>
                </PageTitle>
                <Row style={{ marginTop: "1rem" }}>
                    <Column medium={8}>
                        <h3>{trans("Asset Preview")}</h3>
                        <AssetDetailsPreviewPanel
                            asset={asset}
                            userAsset={userAsset}
                            loaded={true}
                        />
                    </Column>
                    <Column medium={4}>
                        <ActionsPanel
                            userAsset={userAsset}
                            getUserAsset={getUserAsset}
                        />
                        <Availability asset={asset} loaded={true} />
                    </Column>
                </Row>
            </ConfirmationServiceProvider>
        );
    }
};

export default ViewUserAsset;
