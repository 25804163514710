import React, { useContext, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import { IconTextButton, Modal } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import AssetContext from "../AssetContext";
import AddNewAsset from "../forms/AddNewAsset";
import BulkUploader from "../forms/BulkUploader";

export const AssetsOverallAdminOptions = ({
    folderUuid = "",
    onApply,
    campaignUuid = "",
    assetsPath,
    config,

    filters,
    searchableMeta,
    onFileAdded,
    groups,
    libraryArea,
}) => {
    const [showAddAsset, setShowAddAsset] = useState(false);
    const { can } = usePermissions();

    const creatableAssetTypes = Object.values(config.asset_types).filter(
        ({ creatable }) => creatable
    );

    if (!can("dam-asset-admin")) {
        return null;
    }

    return (
        <div style={{ display: "flex" }}>
            {creatableAssetTypes.length > 0 && can("dam-asset-create") && (
                <React.Fragment>
                    <IconTextButton
                        style={{
                            textDecoration: "none",
                            paddingTop: "1rem",
                            paddingBottom: "0.8rem",
                        }}
                        onClick={() => {
                            setShowAddAsset(true);
                        }}
                        icon={faPlus}
                        showCircle={false}
                        expanded={false}
                    >
                        {trans("Create New")}
                    </IconTextButton>

                    <Modal
                        visible={showAddAsset}
                        onClose={() => setShowAddAsset(false)}
                        maxWidth="600px"
                        minWidth="320px"
                    >
                        <AddNewAsset
                            onClose={() => setShowAddAsset(false)}
                            creatableAssetTypes={creatableAssetTypes}
                            assetsPath={assetsPath}
                            folderUuid={folderUuid}
                            campaignUuid={campaignUuid}
                        />
                    </Modal>
                </React.Fragment>
            )}
            <BulkUploader
                assetsPath={assetsPath}
                folderUuid={folderUuid}
                campaignUuid={campaignUuid}
                onApply={onApply}
                onFileAdded={onFileAdded}
                config={config}
                filters={filters}
                searchableMeta={searchableMeta}
                groups={groups}
                libraryArea={libraryArea}
            />
        </div>
    );
};

export default AssetsOverallAdminOptions;
