import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { PageTitle, Pagination, Loading } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import BulkFileItem from "./BulkFileItem";
import FileModal from "./FileModal";

const BulkFileListing = () => {
    const [bulkFileList, setBulkFileList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [modalItem, setModalItem] = useState({});

    useEffect(() => {
        axios
            .get(`/api/v1/assets/bulk-files`)
            .then(({ data }) => {
                setLoaded(true);
                setBulkFileList(data.data);
            })
            .catch(e => console.log(e));
    }, []);

    const downloadModal = item => {
        setModalItem(item);
        setShowDownloadModal(true);
    };

    if (!loaded) {
        return (
            <div>
                <PageTitle title={trans("Bulk Files")}></PageTitle>
                <center>
                    <Loading />
                </center>
            </div>
        );
    }

    return (
        <div>
            <PageTitle title={trans("Bulk Files")}></PageTitle>

            {bulkFileList.length === 0 ? (
                <center>
                    <h3>{trans("No files available")}</h3>
                </center>
            ) : (
                <React.Fragment>
                    {bulkFileList &&
                        bulkFileList.map(item => {
                            return (
                                <BulkFileItem
                                    key={item.uuid}
                                    item={item}
                                    downloadModal={downloadModal}
                                />
                            );
                        })}
                </React.Fragment>
            )}

            {showDownloadModal && (
                <FileModal
                    item={modalItem}
                    onClose={e => setShowDownloadModal(false)}
                    visible={showDownloadModal}
                />
            )}
        </div>
    );
};

export default BulkFileListing;
