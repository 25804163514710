import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

import { Loading, Error } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import ResetPassword from "./ResetPasswordForm";

const Activate = () => {
    const { code } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [passwordResetData, setPasswordResetData] = useState({
        token: "",
        email: "",
    });

    useEffect(() => {
        activate();
    }, []);

    const activate = () => {
        setLoading(true);

        axios
            .post(`/api/v1/activate/${code}`)
            .then(({ data }) => {
                const { password_reset_token, email } = data;

                if (password_reset_token !== undefined) {
                    setPasswordResetData({
                        token: password_reset_token,
                        email: email,
                    });
                }

                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setError(true);
            });
    };

    if (loading) {
        return (
            <>
                <p>
                    {trans(
                        "Please wait while we check your activation code..."
                    )}
                </p>
                <Loading />
            </>
        );
    }

    if (error) {
        return (
            <>
                <p>
                    {trans(
                        "Sorry, there was a problem with your activation code, your account was not activated."
                    )}
                </p>
                <Error />
            </>
        );
    }

    return (
        <>
            <p>{trans("Success! Your account has been activated.")}</p>
            {!!passwordResetData.token ? (
                <ResetPassword
                    title={trans("Set a password")}
                    token={passwordResetData.token}
                    email={passwordResetData.email}
                    buttonText={trans("Set Password")}
                />
            ) : (
                <p style={{ marginTop: "40px" }}>
                    <Link to="/auth/login">
                        {trans("Click here to log in.")}
                    </Link>
                </p>
            )}
        </>
    );
};

export default Activate;
