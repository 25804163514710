import React, { useContext, useState } from "react";
import AssetContext from "../AssetContext";
import { withRouter } from "react-router-dom";
import VersionsTable from "./VersionsTable";
import VersionActionsTable from "./VersionActionsTable";

import { Row, IconTextButton, Modal } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";

const VersionOptions = ({ asset }) => {
    const { canEdit } = useContext(AssetContext);

    const [isModelShowing, toggleModal] = useState(false);
    // const { history } = props;
    // const confirm = useConfirmation();

    if (!canEdit) {
        return null;
    }

    return (
        <React.Fragment>
            <IconTextButton
                type="info"
                onClick={() => toggleModal(true)}
                icon={faCodeBranch}
                showCircle={false}
                expanded={false}
                style={{
                    textDecoration: "none",
                    paddingTop: "1rem",
                    paddingBottom: "0.8rem"
                }}
            >
                {trans("Versions")}
            </IconTextButton>

            <Modal
                key="versionModal"
                onClose={() => toggleModal(false)}
                visible={isModelShowing}
                maxWidth="90%"
            >
                <Row>
                    <h3>{trans("Available Versions")}</h3>
                    <VersionsTable asset={asset} onVersionClick={toggleModal} />
                </Row>

                {false && (
                    <Row style={{ marginTop: "2rem" }} collapse>
                        <VersionActionsTable toggleModal={toggleModal} />
                    </Row>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default withRouter(VersionOptions);
