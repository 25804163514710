import React, { useContext } from "react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import UserRoundel from "./UserRoundel";

import MessagesContext from "../MessagesContext";
import MessageControls from "./MessageControls";

import QuillOutput from "./editors/QuillOutput";
import { faCircleDown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Title = styled.h2`
    flex-grow: 1;
    align-items: center;
    margin: 0;
    word-wrap: break-word;
`;

const Break = styled.hr`
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    margin: 10px 0;
`;

const DetailsList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;

    li {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
`;

const StyledOutput = styled.div`
    margin-top: 20px;

    p {
        margin: 2px;
    }
`;

const UnsubscribeLinks = styled.p`
    margin-top: 20px;
    font-size: 0.8em;
`;

const UnsubscribeLink = styled.span`
    list-style: none;
    display: inline-block;
    background: #283c56;
    color: white;
    padding: 2px 7px;
    margin: 0 5px;
    cursor: pointer;
`;

const ViewPane = ({ reply, forward, destroy, selectedMessage }) => {
    const { selectedMessage: message } = useContext(MessagesContext);

    const unsubscribeFromTag = async (tag) => {
        await axios.post(`/api/v1/communication/tags/unsubscribe`, {
            tag: tag,
            async: true,
        });
    };

    return (
        <div>
            <Row style={{ display: "flex" }}>
                <div>
                    <UserRoundel diameter={40} user={message.sender} />
                </div>

                <Column
                    style={{
                        flexGrow: "1",
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                    }}
                >
                    <Title>
                        <>
                            {message.priority === "high" && (
                                <FontAwesomeIcon
                                    style={{
                                        color: "#D54644",
                                        fontSize: "1.2rem",
                                        marginRight: "8px",
                                    }}
                                    icon="exclamation-circle"
                                />
                            )}
                            {message.priority === "low" && (
                                <FontAwesomeIcon
                                    style={{
                                        color: "#0065B1",
                                        fontSize: "1.2rem",
                                        marginRight: "8px",
                                    }}
                                    icon={faCircleDown}
                                />
                            )}
                        </>
                        {message.subject}
                    </Title>
                    <div style={{ float: "right" }}>
                        <MessageControls
                            verbose={false}
                            selectedMessage={selectedMessage}
                            reply={reply}
                            forward={forward}
                            destroy={destroy}
                        />
                    </div>
                </Column>
            </Row>
            <Break />
            <Row collapse>
                <Column medium={6} collapse>
                    <DetailsList>
                        <li>
                            <strong>{trans("To")}:</strong> {message.sent_to}
                        </li>
                        <li>
                            <strong>{trans("From")}:</strong>{" "}
                            {message.sender.name}
                        </li>
                    </DetailsList>
                </Column>
                <Column medium={6}>
                    <DetailsList>
                        <li>
                            <strong>
                                {message.is_scheduled
                                    ? trans("Scheduled:")
                                    : trans("Received:")}
                            </strong>{" "}
                            {message.received_at
                                ? message.received_at.format(
                                      "Do MMMM YYYY HH:mm"
                                  )
                                : "-"}
                        </li>

                        <li>
                            <strong>{trans("Read")}:</strong>{" "}
                            {message.read
                                ? message.read_at.format("Do MMMM YYYY HH:mm")
                                : "-"}
                        </li>
                    </DetailsList>
                </Column>
            </Row>

            <Break />

            <Row collapse>
                <Column medium={12} collapse>
                    <StyledOutput>
                        <QuillOutput value={message.message} />
                    </StyledOutput>
                </Column>
            </Row>

            {Array.isArray(message.tags) && message.tags.length > 0 && (
                <>
                    <Break />

                    <Row>
                        <Column medium={12}>
                            <UnsubscribeLinks>
                                Unsubscribe from:{" "}
                                {message.tags.map((tag) => {
                                    return (
                                        <>
                                            <UnsubscribeLink
                                                onClick={() => {
                                                    unsubscribeFromTag(tag);
                                                }}
                                            >
                                                {tag}
                                            </UnsubscribeLink>
                                        </>
                                    );
                                })}
                            </UnsubscribeLinks>
                        </Column>
                    </Row>
                </>
            )}
        </div>
    );
};

export default ViewPane;
