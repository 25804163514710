import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import PortalFrame from "react-portal-frame";

import { trans } from "@cortexglobal/rla-intl";
import { Iframe } from "@cortexglobal/rla-components";

import { Microsite } from "@cortexglobal/microsite";
import AssetContext from "../../AssetContext";
import AssetPreviewOverlay from "./AssetPreviewOverlay";
import EditableVideoPreview from "./EditableVideoPreview";
import InDesignPreview from "./InDesignPreview";

const AssetPreview = ({
    asset,
    page,
    configuration,
    editRouteMatch,
    preview,
}) => {
    const { assetsPath, microSiteComponents } = useContext(AssetContext);

    const assetType =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset?.asset_type?.slug;

    switch (assetType) {
        case "inDesign":
            return <InDesignPreview asset={asset} page={page} />;

        case "email":
        case "digital":
        case "static_digital":
        case "static_email":
            // console.log("preview user asset", asset);
            let path = asset.userAsset
                ? `${process.env.REACT_APP_BASE_URL}/api/v1/user-assets/html-preview/${asset.uuid}/index.html?${asset.updated_at}`
                : `${process.env.REACT_APP_BASE_URL}/api/v1/assets/html-preview/${asset.uuid}/index.html?${asset.updated_at}`;
            return (
                <AssetPreviewOverlay>
                    <Iframe
                        src={path}
                        style={{
                            height: "100%",
                            width: "100%",
                            border: "none",
                            minHeight: assetType === "email" ? "80vh" : "40vh",
                        }}
                    />
                </AssetPreviewOverlay>
            );
        case "microsite":
        case "presentation":
            //TODO - Inject the fonts from the theme...
            return (
                <PortalFrame
                    style={{ minHeight: "50vw", transform: "scale(0.9)" }}
                    head={`<link
                            href="https://fonts.googleapis.com/css?family=Montserrat:100,300,500,700,900&amp;display=swap"
                            rel="stylesheet"
                        />`}
                >
                    <Microsite
                        preview={true}
                        style={{ transform: "scale(0.9)" }}
                        components={microSiteComponents}
                        config={configuration}
                        basePath={`${editRouteMatch.path}`}
                        baseUrl={`${editRouteMatch.url}`}
                        showPage={`${page}`}
                        editing={true}
                    />
                </PortalFrame>
            );
        case "editableVideo":
            return <EditableVideoPreview asset={asset} />;
        default:
            return <div>{trans("No preview available")}</div>;
    }
};

export default withRouter(AssetPreview);
