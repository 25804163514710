import React, { Component } from "react";

import NewsListConfiguration from "./NewsListConfiguration";
import NewsListOutput from "./NewsListOutput";

class NewsList extends Component {
    render() {
        if (this.props.configuring) {
            return <NewsListConfiguration {...this.props} />;
        }

        return <NewsListOutput {...this.props} />;
    }
}

export default NewsList;
