import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    IconTextButton,
    PageTitle,
    InputField,
    useAddToast,
    useToasts,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { CustomForm } from "../../forms/CustomFormForm";
import { CustomFormTable } from "./CustomFormTable";

export const CustomFormListing = ({ path }) => {
    const history = useHistory();
    const page = useQueryString().get("page") || 1;
    const searchParam = useQueryString().get("search") || "";
    const orderByParam = useQueryString().get("orderBy") || "";
    const dirParam = useQueryString().get("dir") || "ASC";
    const perPageParam = useQueryString().get("perPage") || 10;

    const [searchInput, setSearchInput] = useState(searchParam);
    const [search, setSearch] = useState(searchParam);

    const [orderBy, setOrderBy] = useState(orderByParam);
    const [orderByDirection, setOrderByDirection] = useState(dirParam);

    //@ts-ignore cortex
    const [items, setItems] = useState({
        loaded: false,
        data: [],
        pagination: null,
    });

    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const loadToast = (message) => {
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: message,
        });
    };

    const removeLoadToast = () => {
        dispatch({ type: "removeToast", uuid: "loadToast" });
    };

    const loadItems = (
        setItems,
        page,
        search,
        orderBy,
        orderByDir,
        perPage
    ) => {
        setItems({
            loaded: false,
        });
        axios
            .get(`/api/v1/admin/custom-form`, {
                params: {
                    page: page,
                    search: search ? search : null,
                    sortDirection: orderByDir ? orderByDir : null,
                    sortField: orderBy ? orderBy : null,
                    perPage: perPage ? perPage : 10,
                    include: "allI18n",
                },
            })
            .then(({ data }) => {
                setItems({
                    loaded: true,
                    data: data.data,
                    pagination: data.meta,
                });
                const orderByValue = orderBy
                    ? `&orderBy=${orderBy}&dir=${orderByDir}`
                    : "";

                history.push(`?page=${page}&search=${search}${orderByValue}`);
            })
            .catch();
    };
    const deleteItem = (
        uuid,
        setItems,
        page = "1",
        search,
        orderBy,
        orderByDirection,
        perPage
    ): Promise<CustomForm[]> => {
        loadToast(trans("Deleting custom form"));
        return axios
            .delete(`/api/v1/admin/custom-form/${uuid}`)
            .then(({ data }) => {
                removeLoadToast();
                loadItems(
                    setItems,
                    page,
                    search,
                    orderBy,
                    orderByDirection,
                    perPage
                );
                return data;
            })
            .catch((e) => {
                removeLoadToast();

                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred deleting the Custom Form"
                        ),
                        showFor: 5000,
                    });
                }
            });
    };

    useEffect(() => {
        loadItems(
            setItems,
            page,
            search,
            orderBy,
            orderByDirection,
            perPageParam
        );
    }, [setItems]);

    const onSearch = () => {
        if (searchInput.length) {
            // const orderByValue = orderBy
            //     ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            //     : "";

            setSearch(searchInput);
            // history.push(`?page=${page}&search=${searchInput}${orderByValue}`);
            loadItems(
                setItems,
                page,
                searchInput,
                orderBy,
                orderByDirection,
                perPageParam
            );
        }
    };

    const onOrderBy = (data) => {
        let orderDir = orderByDirection;

        if (orderBy === data) {
            setOrderByDirection(orderByDirection === "ASC" ? "DESC" : "ASC");
            orderDir = orderByDirection === "ASC" ? "DESC" : "ASC";
        } else {
            setOrderBy(data);
            setOrderByDirection("ASC");
            orderDir = "ASC";
        }

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(
            `?page=${page}&orderBy=${data}&dir=${orderDir}${searchValue}${perPageValue}`
        );
        loadItems(setItems, page, search, data, orderDir, perPageParam);
    };

    const onClearSearch = () => {
        setSearch("");
        setSearchInput("");

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";

        history.push(`?page=${page}${orderByValue}${perPageValue}`);
        loadItems(setItems, page, "", orderBy, orderByDirection, perPageParam);
    };

    const onPerPageChange = ({ value }: { value: string }) => {
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(`?perPage=${value}${orderByValue}${searchValue}`);
        loadItems(setItems, page, search, orderBy, orderByDirection, value);
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Custom Forms")}{" "}
                        {/* <HeaderEmphasis>{assetAdmin.asset.name}</HeaderEmphasis> */}
                    </React.Fragment>
                }
            >
                {search && (
                    <IconTextButton
                        onClick={onClearSearch}
                        showCircle={false}
                        icon={faTimes}
                    >
                        {trans("Clear search")}
                    </IconTextButton>
                )}
                <InputField
                    style={{ width: "300px", marginBottom: "0" }}
                    value={searchInput}
                    name="search"
                    onChange={(item) => setSearchInput(item.value)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            onSearch();
                        }
                    }}
                />
                <IconTextButton
                    onClick={onSearch}
                    showCircle={false}
                    icon={faSearch}
                ></IconTextButton>
                <IconTextButton
                    as={Link}
                    to={`${path}/create`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    icon={faPlus}
                >
                    {trans("Create new Custom Form")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <CustomFormTable
                    onPerPageChange={onPerPageChange}
                    onOrderBy={onOrderBy}
                    items={items.data}
                    loaded={items.loaded}
                    path={path}
                    pagination={items.pagination}
                    onPageChange={(selected) => {
                        history.push(`?page=${selected}&search=${search}`);
                        loadItems(
                            setItems,
                            selected,
                            search,
                            orderBy,
                            orderByDirection,
                            perPageParam
                        );
                    }}
                    deleteItem={(uuid) =>
                        deleteItem(
                            uuid,
                            setItems,
                            "1",
                            search,
                            orderBy,
                            orderByDirection,
                            perPageParam
                        )
                    }
                />
            </Row>
        </div>
    );
};

export default CustomFormListing;
