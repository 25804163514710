import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";

import { useAddToast } from "@cortexglobal/rla-components";

import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import SurveyManagementContext from "./SurveyManagementContext";
import SurveyDashboard from "./management/SurveyDashboard";
import CreateSurvey from "./management/CreateSurvey";
import EditSurvey from "./management/EditSurvey";
import ViewSurvey from "./management/ViewSurvey";

const SurveyManagementApp = (props) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const addToast = useAddToast();
    const { can } = usePermissions();

    const [surveySystem, setSurveySystem] = useState({
        loading: false,
        loaded: false,
        error: "",
        isSubmitting: false,
        unsavedChanges: false,
        disableSave: false,
        disableEdit: false,
        hasEntries: false,
        params: {
            page: 1,
            per_page: 20,
            order_by: "id",
            order_by_direction: "desc",
            show_archived: false,
        },
    });

    const loadSurvey = (uuid) => {
        if (!uuid || uuid === "undefined") {
            return;
        }
        setSurveySystem({
            ...surveySystem,
            loading: true,
            loaded: false,
            error: null,
        });

        return axios
            .get(`/api/v1/surveys/${uuid}`)
            .then(({ data }) => {
                setSurveySystem({
                    ...surveySystem,
                    loading: false,
                    loaded: true,
                    error: null,
                    disableEdit:
                        data.data.total_finished_entries > 0 &&
                        !can("surveys-manage-edit-live"),
                    hasEntries: data.data.total_finished_entries > 0,
                });

                return data.data;
            })
            .catch((e) => {
                setSurveySystem({
                    ...surveySystem,
                    loading: false,
                    loaded: false,
                    error: e.response?.statusText,
                });
            });
    };

    const deleteSurvey = (uuid) => {
        if (!uuid || uuid === "undefined") {
            history.push(path); // go back to dash
        }

        setSurveySystem({
            ...surveySystem,
            loading: true,
            error: null,
        });

        return axios
            .delete(`/api/v1/surveys/${uuid}`)
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: trans("Survey deleted successfully."),
                    showFor: 4000,
                });

                history.push(path);
            })
            .catch((e) => {
                setSurveySystem({
                    ...surveySystem,
                    loading: false,
                    error: e.response?.statusText,
                });
                addToast({
                    type: "alert",
                    content: e.response?.statusText,
                    showFor: 4000,
                });
            });
    };

    return (
        <SurveyManagementContext.Provider
            value={{
                surveySystem,
                deleteSurvey,
                setSurveySystem,
            }}
        >
            <Switch>
                <Route
                    path={`${path}/create`}
                    render={({ location }) => (
                        <CreateSurvey path={path} loadSurvey={loadSurvey} />
                    )}
                />
                <Route
                    path={`${path}/:uuid/edit`}
                    render={({ location }) => (
                        <EditSurvey path={path} loadSurvey={loadSurvey} />
                    )}
                />
                <Route
                    path={`${path}/:uuid`}
                    render={({ location }) => (
                        <ViewSurvey path={path} loadSurvey={loadSurvey} />
                    )}
                />
                <Route
                    path={path}
                    render={({ location }) => <SurveyDashboard path={path} />}
                />
            </Switch>
        </SurveyManagementContext.Provider>
    );
};

export default SurveyManagementApp;
