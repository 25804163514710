import React, { Component } from "react";

import MarketingKpiBreakdownOutput from "./MarketingKpiBreakdownOutput";
import MarketingKpiBreakdownConfiguration from "./MarketingKpiBreakdownConfiguration";

const MarketingKpiBreakdown = (props) => {
    if (props.configuring) {
        return <MarketingKpiBreakdownConfiguration {...props} />;
    }
    return <MarketingKpiBreakdownOutput {...props} />;
};

export default MarketingKpiBreakdown;
