import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
    ContentBlockLoader,
    SubmitButton,
    FormikAdvancedSelect,
    FormikInput,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    LoadingIconSmall,
    useAddToast,
    useToasts,
    Toggle,
    RepeaterField,
    renderStandReturnInput,
    IconTextButton,
    Modal,
    Table,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { LocaleSwitcher } from "../components/index";

import CustomFormField, {
    CustomField,
    customFieldSchema,
} from "./CustomFormField";

const schema = Yup.object().shape({
    locale: Yup.string().nullable(),
    name: Yup.string().required(trans("Required")),
    alias: Yup.string().required(trans("Required")),
    type: Yup.string().nullable(),
    // description: Yup.string().nullable(),
    custom_form_fields: Yup.array().of(customFieldSchema).nullable(),
});

export type CustomForm = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    alias?: string;
    i18n?: { [key: string]: any };
};

export const CustomFormForm = ({ item, submitForm }) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [fieldTypes, setFieldTypes] = useState([]);
    const [fieldTypesLoaded, setFieldTypesLoaded] = useState(false);

    const [formTypes, setFormTypes] = useState([]);
    const [formTypesLoaded, setFormTypesLoaded] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [selectedField, setSelectedField] = useState(null);

    useEffect(() => {
        axios.get(`/api/v1/custom-forms/available-fields`).then(({ data }) => {
            setFieldTypes(data.data);
            setFieldTypesLoaded(true);
        });
        axios.get(`/api/v1/custom-forms/types`).then(({ data }) => {
            setFormTypes(data);
            setFormTypesLoaded(true);
        });
    }, []);
    const handleSubmit = (
        values: CustomForm,
        { setErrors, setSubmitting, ...rest }
    ) => {
        // const fd = new FormData();
        // Object.keys(values).forEach((key) => fd.append(key, values[key]));
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: trans("Saving"),
        });
        // submitForm(fd, setSubmitting)
        submitForm(values, setSubmitting)
            .then((data) => {
                addToast({
                    content: trans("Media Channel saved"),
                    type: "success",
                    showFor: 3000,
                });
            })
            .catch((e) => {
                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred updating the media channel"
                        ),
                        showFor: 5000,
                    });
                }
                parseValidationErrors(e).then(({ errors }) => {
                    //console.log(setErrors, errors);
                    setErrors(errors);
                    setSubmitting(false);
                });
            })
            .finally(() => {
                dispatch({ type: "removeToast", uuid: "loadToast" });
            });
    };

    const closeModal = useCallback(() => {
        setShowModal(false);
        setSelectedField(null);
    }, []);

    const deleteField = (field: CustomField, setFieldValue, values) => {
        //fire off a request to delete the field
        axios.delete(`/api/v1/custom-forms/field/${field.uuid}`).then(() => {
            //then remove it from the list
            setFieldValue(
                "custom_form_fields",
                values.custom_form_fields.filter(
                    (f: CustomField) => f.field_name !== field.field_name
                )
            );
        });
    };

    return (
        <>
            <Formik
                initialValues={{
                    locale: item.locale ? item.locale : null,
                    name: item.name,
                    alias: item.alias,
                    type: item.type ? item.type : null,
                    // description: item.description,
                    custom_form_fields: item.custom_form_fields
                        ? item.custom_form_fields
                        : [],
                }}
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                {({ isSubmitting, values, setFieldValue, errors }) => {
                    // console.log(values, errors);
                    return (
                        <Form className="form-group">
                            <FormRow
                                name="locale"
                                label={trans("Locale")}
                                helpText={trans("Optional")}
                                error={errors.locale}
                            >
                                <LocaleSwitcher
                                    item={item}
                                    setFieldValue={setFieldValue}
                                    //@ts-ignore
                                    values={values}
                                />
                            </FormRow>
                            <FormRow
                                name="name"
                                label={trans("Name")}
                                helpText={trans("Required")}
                                error={errors.name}
                                required={true}
                            >
                                <FormikInput
                                    name="name"
                                    value={values.name}
                                    showError={false}
                                />{" "}
                            </FormRow>
                            <FormRow
                                name="alias"
                                label={trans("Alias")}
                                helpText={trans(
                                    "Required, used in the URL so must be unique and contain only letters, numbers and dashes"
                                )}
                                error={errors.alias}
                                required={true}
                            >
                                <FormikInput
                                    name="alias"
                                    value={values.alias}
                                    placeholder={
                                        values.alias ? values.alias : ""
                                    }
                                    showError={false}
                                />{" "}
                            </FormRow>
                            <FormRow
                                name="type"
                                label={trans("Type")}
                                helpText={trans(
                                    "Optional, used to offer different form types for different areas of the platform"
                                )}
                                error={errors.type}
                                required={true}
                            >
                                <FormikAdvancedSelect
                                    name="type"
                                    value={values.type}
                                    options={formTypes}
                                    showError={false}
                                />{" "}
                            </FormRow>
                            {/* <FormRow
                                name="description"
                                label={trans("Description")}
                                helpText={trans("Optional")}
                                error={errors.description}
                            >
                                <FormikInput
                                    name="description"
                                    value={values.description}
                                    showError={false}
                                />{" "}
                            </FormRow> */}

                            {item?.uuid && (
                                <FormRow
                                    name="custom_form_fields"
                                    label={trans("Custom Form Fields")}
                                    helpText={trans(
                                        "Extra fields to be displayed when uploading assets to this channel"
                                    )}
                                >
                                    {fieldTypesLoaded ? (
                                        <>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {trans("Label")}
                                                        </th>
                                                        <th>
                                                            {trans(
                                                                "Field Name"
                                                            )}
                                                        </th>
                                                        <th>{trans("Type")}</th>

                                                        <th>
                                                            {trans("Actions")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.custom_form_fields.map(
                                                        (
                                                            field: CustomField
                                                        ) => {
                                                            // let { element } = field;
                                                            // console.log(element.type);
                                                            return (
                                                                <tr
                                                                    key={
                                                                        field.field_name
                                                                    }
                                                                >
                                                                    <td>
                                                                        {
                                                                            field.label
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            field.field_name
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            field.form_builder_field_type
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <IconTextButton
                                                                            icon={
                                                                                faEdit
                                                                            }
                                                                            showCircle={
                                                                                false
                                                                            }
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                setShowModal(
                                                                                    true
                                                                                );
                                                                                setSelectedField(
                                                                                    field
                                                                                );
                                                                            }}
                                                                        ></IconTextButton>
                                                                        <IconTextButton
                                                                            type="alert"
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                            showCircle={
                                                                                false
                                                                            }
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault();
                                                                                deleteField(
                                                                                    field,
                                                                                    setFieldValue,
                                                                                    values
                                                                                );
                                                                            }}
                                                                        ></IconTextButton>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </Table>
                                            <IconTextButton
                                                icon={faPlus}
                                                showCircle={false}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowModal(true);
                                                    setSelectedField({
                                                        locale: null,
                                                        field_name: "",
                                                        label: "",
                                                        form_builder_field_type:
                                                            "",
                                                        order: 0,
                                                        per_row: 0,
                                                        validation_rules: "",
                                                        default_values: "",
                                                        named_fields_required:
                                                            "",
                                                        display_output_format:
                                                            "",
                                                    });
                                                }}
                                            >
                                                {trans("Add field")}
                                            </IconTextButton>
                                        </>
                                    ) : (
                                        <LoadingIconSmall />
                                    )}
                                </FormRow>
                            )}

                            <Row>
                                <Column
                                    medium={6}
                                    style={{ textAlign: "right" }}
                                >
                                    &nbsp;
                                </Column>
                                <Column
                                    medium={3}
                                    flex
                                    style={{
                                        paddingBottom: "1rem",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <SubmitButton
                                        label={trans("Save")}
                                        submitting={isSubmitting}
                                        type="primary"
                                    />
                                </Column>
                            </Row>
                            <Modal
                                visible={showModal}
                                onClose={closeModal}
                                maxWidth="90%"
                            >
                                <h2>
                                    {selectedField?.uuid
                                        ? `Editing ${selectedField.label}`
                                        : `Creating new field`}
                                </h2>
                                <CustomFormField
                                    fieldTypes={fieldTypes}
                                    item={selectedField}
                                    formUuid={item.uuid}
                                    formLocale={item.locale}
                                    onComplete={(field) => {
                                        if (selectedField?.uuid) {
                                            console.log(
                                                "updating field",
                                                field
                                            );
                                            setFieldValue(
                                                "custom_form_fields",
                                                values.custom_form_fields.map(
                                                    (f: CustomField) => {
                                                        if (
                                                            f.uuid ===
                                                            field.uuid
                                                        ) {
                                                            return field;
                                                        }
                                                        return f;
                                                    }
                                                )
                                            );
                                        } else {
                                            console.log("adding field", field);
                                            setFieldValue(
                                                "custom_form_fields",
                                                [
                                                    ...values.custom_form_fields,
                                                    field,
                                                ]
                                            );
                                        }
                                        closeModal();
                                    }}
                                />
                            </Modal>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default CustomFormForm;
