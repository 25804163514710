import React from "react";
import moment from "moment";

const IfDateCheck = props => {
    const {
        when,
        date: { dateStr, format },
        children
    } = props;

    const now = moment();


    if (when === "before") {
        const before = moment(dateStr, format);

        return now.isBefore(before) === true ? (
            <React.Fragment>{children}</React.Fragment>
        ) : null;
    }

    if (when === "after") {
        const after = moment(dateStr, format);

        return now.isAfter(after) !== false ? (
            <React.Fragment>{children}</React.Fragment>
        ) : null;
    }

    console.warn("Invalid before/after supplied to 'when'");

    return null;
};

export default IfDateCheck;
