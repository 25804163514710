import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter, Redirect } from "react-router-dom";
import {
    Panel,
    Row,
    Column,
    PageTitle,
    Loading,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import PlannerActivityCreateForm from "../forms/PlannerActivityCreateForm";
import { HeaderEmphasis } from "../components/overviewComponents";

const AddActivity = ({
    displayName = "AddActivity",
    task: taskIn = null,
    category,
    onGoBack,
    plannerPath,
    match,
    successCallback,
    ...props
}) => {
    const [task, setTask] = useState(taskIn);
    const [status, setStatus] = useState("loading");

    useEffect(() => {
        if ((taskIn && taskIn.uuid) || match.params.task_uuid) {
            loadTask(taskIn.uuid || match.params.task_uuid);
        } else {
            setStatus("loaded");
        }
    }, [taskIn]);

    const loadTask = (taskUuid) => {
        setStatus("loading");

        axios
            .get(`api/v1/planner/tasks/${taskUuid}`)
            .then((result) => {
                setTask(result.data.data);
                setStatus("loaded");
            })
            .catch((e) => {
                setStatus("error");

                if (e.response && e.response.status === 404) {
                    setStatus("not-found");
                }
            });
    };

    if (status === "not-found") {
        return <Redirect to={`${plannerPath}`} />;
    }

    return (
        <>
            <PageTitle
                title={
                    task && task.name ? (
                        <>
                            {trans("Add an Activity to")}{" "}
                            <HeaderEmphasis>{task.name}</HeaderEmphasis>
                        </>
                    ) : (
                        trans("Add an Activity")
                    )
                }
            />

            <Row>
                <Column>
                    {status === "loaded" ? (
                        <PlannerActivityCreateForm
                            action="addActivity"
                            task={task}
                            category={category}
                            onGoBack={onGoBack}
                            successCallback={successCallback}
                        />
                    ) : status === "loading" ? (
                        <Loading />
                    ) : (
                        <div>Error</div>
                    )}
                </Column>
            </Row>
        </>
    );
};

export default withRouter(AddActivity);
