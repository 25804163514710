import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Row,
    Column,
    InputField,
    SelectField,
    Button,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

class CampaignSummaryConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customTitle: this.props.customTitle ? this.props.customTitle : "",
            timeframe: this.props.timeframe ? this.props.timeframe : "",
        };
    }
    onChange = (input) => {
        this.setState({ [input.name]: input.value });
    };
    submitForm = (event) => {
        event.preventDefault();
        this.props.submitPanel(this.props.panel, this.state);
    };
    render() {
        const options = [
            { value: "-1", text: "-1 Month" },
            { value: "-3", text: "-3 Months" },
            { value: "-6", text: "-6 Months" },
            { value: "-12", text: "-1 Year" },
        ];

        return (
            <Row>
                <Column>
                    <form onSubmit={this.submitForm}>
                        <InputField
                            type="text"
                            name="customTitle"
                            value={this.state.customTitle}
                            label={trans("Custom Title")}
                            placeholder=""
                            onChange={this.onChange}
                        />
                        <SelectField
                            name="timeframe"
                            label={trans("Timeframe")}
                            value={this.state.timeframe}
                            options={options}
                            onChange={this.onChange}
                            expanded
                        />
                        <Button type="submit">{trans("Save Panel")}</Button>
                    </form>
                </Column>
            </Row>
        );
    }
}

CampaignSummaryConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default CampaignSummaryConfiguration;
