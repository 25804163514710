import React, { useEffect } from "react";
import { Row, Column, InputField, FormRow } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { useGroup } from "@cortexglobal/cortex-auth-redux";
import QuantityFormRow from "../components/QuantityFormRow";

const PosOrderForm = ({ order, standardOnChange, errors }) => {
    const availableStock = order?.user_asset?.asset?.meta_data?.stock;
    const price = order?.user_asset?.asset?.meta_data?.price;
    const minQty =
        order?.user_asset?.asset?.order_flow_details?.min_quantity || 0;
    let maxQty = order?.user_asset?.asset?.order_flow_details?.max_quantity;
    if (
        (availableStock && maxQty > availableStock) ||
        (!maxQty && availableStock)
    ) {
        maxQty = availableStock;
    }
    const { group } = useGroup();

    const contact = group?.entity?.contact ?? null;

    //Get the print_details from the order. If it doesn't exist, create it
    //can't use destructing because it can be null
    let { print_details } = order;
    if (!print_details) {
        print_details = {
            quantity: minQty,
        };
        if (contact) {
            print_details = {
                ...print_details,
                address_1:
                    contact.address_building + " " + contact.address_street,
                address_2: contact.address_2,
                town_city: contact.address_city,
                county_state: contact.address_state,
                postcode: contact.postcode,
                country: contact.country,
            };
        }
    }
    useEffect(() => {
        standardOnChange({
            name: "print_details",
            value: print_details,
        });
        return () => {
            standardOnChange({
                name: "print_details",
                value: print_details,
            });
        };
    }, []);
    const onChange = ({ name, value }) => {
        standardOnChange({
            name: "print_details",
            value: { ...print_details, [name]: value },
        });
    };

    return (
        <>
            <QuantityFormRow
                order={order}
                onChange={onChange}
                errors={errors}
                value={print_details.quantity}
                minQty={minQty}
                maxQty={maxQty}
                price={price}
            />
            <Row>
                <Column medium={3} style={{ textAlign: "right" }}>
                    <h3>{trans("Delivery Address")}</h3>
                </Column>
            </Row>
            <FormRow
                name="address_1"
                label={trans("Address 1")}
                error={errors.address_1}
            >
                <InputField
                    name="address_1"
                    value={print_details.address_1}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="address_2"
                label={trans("Address 2")}
                error={errors.address_2}
            >
                <InputField
                    name="address_2"
                    value={print_details.address_2}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="town_city"
                label={trans("Town/City")}
                error={errors.town_city}
            >
                <InputField
                    name="town_city"
                    value={print_details.town_city}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="county_state"
                label={trans("County/State")}
                error={errors.county}
            >
                <InputField
                    name="county_state"
                    value={print_details.county_state}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="postcode"
                label={trans("Postcode")}
                error={errors.postcode}
            >
                <InputField
                    name="postcode"
                    value={print_details.postcode}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="country"
                label={trans("Country")}
                error={errors.country}
            >
                <InputField
                    name="country"
                    value={print_details.country}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
        </>
    );
};

export default PosOrderForm;
