import React, { useState } from "react";
import { faEye, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { IconTextButton, Modal } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { PlanDetails } from "@cortexglobal/marketing-planner";
import { useUser } from "@cortexglobal/cortex-auth-redux";

export const ViewOption = ({ request }) => {
    //If we don't have a relation type or link, we can't show anything
    if (
        !request ||
        !request.relation_type ||
        !request.relation_link ||
        !request.relation_link === ""
    ) {
        return null;
    }
    switch (request.relation_type) {
        case "UserAsset":
            return (
                <IconTextButton
                    href={request.relation_link}
                    target="_blank"
                    icon={faExternalLinkAlt}
                    showCircle={false}
                    as={"a"}
                >
                    {trans("View asset")}
                </IconTextButton>
            );

        case "Asset Order":
            return (
                <IconTextButton
                    href={request.relation_link}
                    target="_blank"
                    icon={faExternalLinkAlt}
                    showCircle={false}
                    as={"a"}
                >
                    {trans("View order")}
                </IconTextButton>
            );

        default:
            return (
                <IconTextButton
                    href={request.relation_link}
                    target="_blank"
                    icon={faExternalLinkAlt}
                    showCircle={false}
                    as={"a"}
                >
                    {trans("View in new tab")}
                </IconTextButton>
            );
    }
};

export default ViewOption;
