import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";

const ProgressContainer = styled.div`
    width: ${(props) => {
        return props.containerWidth;
    }};
    max-width: ${(props) => {
        return props.containerMaxWidth;
    }};
    margin: ${(props) => (props.center ? "auto" : 0)};
`;

const ProgressProvider = ({ valueStart, valueEnd, children }) => {
    const [value, setValue] = React.useState(valueStart);
    React.useEffect(() => {
        setValue(valueEnd);
    }, [valueEnd]);

    return children(value);
};

const CustomCircularProgressbar = (props) => {
    return (
        <CircularProgressbar
            styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                // rotation: 0.25,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: props.theme.circularProgress.strokeLinecap,

                // Text size
                textSize: "16px",

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: props.strokeColor
                    ? props.strokeColor
                    : props.theme.circularProgress.strokeColor,
                textColor: props.textColor
                    ? props.textColor
                    : props.theme.circularProgress.textColor,
                trailColor: props.trailColor
                    ? props.trailColor
                    : props.theme.circularProgress.trailColor,
            })}
            {...props}
        />
    );
};

const CircularProgress = ({
    value,
    initialAnimation = true,
    valueStart = 0,
    containerWidth = "60%",
    containerMaxWidth = "10rem",
    center = true,
    ...props
}) => {
    // console.log({ value });
    return (
        <ProgressContainer
            containerWidth={containerWidth}
            containerMaxWidth={containerMaxWidth}
            center={center}
        >
            <style>
                {true &&
                    `
                    /*
                    * react-circular-progressbar styles
                    * All of the styles in this file are configurable!
                    */

                    .CircularProgressbar {
                    /*
                    * This fixes an issue where the CircularProgressbar svg has
                    * 0 width inside a "display: flex" container, and thus not visible.
                    */
                    width: 100%;
                    /*
                    * This fixes a centering issue with CircularProgressbarWithChildren:
                    * https://github.com/kevinsqi/react-circular-progressbar/issues/94
                    */
                    vertical-align: middle;
                    }

                    .CircularProgressbar .CircularProgressbar-path {
                    stroke: #3e98c7;
                    stroke-linecap: round;
                    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
                    transition: stroke-dashoffset 0.5s ease 0s;
                    }

                    .CircularProgressbar .CircularProgressbar-trail {
                    stroke: #d6d6d6;
                    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
                    stroke-linecap: round;
                    }

                    .CircularProgressbar .CircularProgressbar-text {
                    fill: #3e98c7;
                    font-size: 20px;
                    dominant-baseline: middle;
                    text-anchor: middle;
                    }

                    .CircularProgressbar .CircularProgressbar-background {
                    fill: #d6d6d6;
                    }

                    /*
                    * Sample background styles. Use these with e.g.:
                    *
                    *   <CircularProgressbar
                    *     className="CircularProgressbar-inverted"
                    *     background
                    *     percentage={50}
                    *   />
                    */
                    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
                    fill: #3e98c7;
                    }

                    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
                    fill: #fff;
                    }

                    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
                    stroke: #fff;
                    }

                    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
                    stroke: transparent;
                    }
                `}
            </style>
            {initialAnimation ? (
                <ProgressProvider valueStart={valueStart} valueEnd={value}>
                    {(value) => (
                        <CustomCircularProgressbar value={value} {...props} />
                    )}
                </ProgressProvider>
            ) : (
                <CustomCircularProgressbar value={value} {...props} />
            )}
        </ProgressContainer>
    );
};
CircularProgress.propTypes = {
    containerWidth: PropTypes.string,
    containerMaxWidth: PropTypes.string,
    center: PropTypes.bool,
    /** Any valid CSS colour value */
    strokeColor: PropTypes.string,
    /** Any valid CSS colour value */
    textColor: PropTypes.string,
    /** Any valid CSS colour value */
    trailColor: PropTypes.string,
};

export default withTheme(CircularProgress);
