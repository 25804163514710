import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const AssetWidgetConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    filterBy,
    total,
    perSlide,
    displayType,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Asset Listing" }),
        filterBy: filterBy ? filterBy : "",
        total: total ? total : 5,
        perSlide: perSlide ? perSlide : 5,
    });

    useEffect(() => {
        setState({
            title: title ? title : "Asset Listing",
            filterBy: filterBy ? filterBy : "",
            total: total ? total : 5,
            perSlide: perSlide ? perSlide : 5,
        });
    }, [title, filterBy, total, perSlide]);

    const options = [
        {
            value: "recently_added",
            text: intl.formatMessage({ id: "Recently added" }),
        },
        { value: "popular", text: intl.formatMessage({ id: "Popular" }) },
        {
            value: "viewed",
            text: intl.formatMessage({ id: "Recently viewed" }),
        },
    ];

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder="Custom Title"
                        onChange={onChange}
                    />
                    <SelectField
                        name="filterBy"
                        label={trans("Filter")}
                        value={state.filterBy}
                        options={options}
                        onChange={onChange}
                        expanded
                    />
                    <InputField
                        type="number"
                        name="total"
                        value={state.total}
                        label={trans("Number of Assets")}
                        onChange={onChange}
                        min={1}
                    />
                    {displayType && displayType === "carousel" && (
                        <InputField
                            type="number"
                            name="perSlide"
                            value={state.perSlide}
                            label={trans("Assets per slide")}
                            onChange={onChange}
                            min={1}
                        />
                    )}
                    <Button type="submit">{trans("Save Panel")}</Button>
                </form>
            </Column>
        </Row>
    );
};

AssetWidgetConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default AssetWidgetConfiguration;
