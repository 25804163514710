import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { withTheme } from "styled-components";

import {
    MainNav,
    SiteWrapper,
    MainContentWrapper,
    Loading,
} from "@cortexglobal/rla-components";

import Header from "./PrivateHeader";
// import Nav from "./PrivateNav";
import Footer from "./PrivateFooter";

const Home = lazy(() => import("../../modules/home/Home"));
const Assets = lazy(() => import("../../modules/assets/Assets"));
const DocumentAssets = lazy(() =>
    import("../../modules/assets/DocumentAssets")
);
const DealerReports = lazy(() => import("../../modules/assets/DealerReports"));
const UserAssets = lazy(() => import("../../modules/assets/UserAssets"));
const Requests = lazy(() => import("../../modules/requests/Requests"));
const Orders = lazy(() => import("../../modules/orders/Orders"));
const Reports = lazy(() => import("../../modules/reporting/Reports"));
const Communications = lazy(() =>
    import("../../modules/communications/Communications")
);
const News = lazy(() => import("../../modules/news/News"));
const Area = lazy(() => import("../../modules/area/Area"));
const Contact = lazy(() => import("../../modules/contact/Contact"));
const MarketingPlanner = lazy(() =>
    import("../../modules/planner/MarketingPlanner")
);
const BudgetManager = lazy(() =>
    import("../../modules/budget-manager/BudgetManager")
);
const Profile = lazy(() => import("../../modules/Profile"));
import { UserManagement } from "@cortexglobal/user-management";
import { GroupProfileAdmin } from "@cortexglobal/admin";
import { Imports } from "@cortexglobal/data-imports";
import { OrderHeader } from "@cortexglobal/cortex-dam";
import axios from "axios";
import * as QueryString from "query-string";
import { startOrderProcess } from "@cortexglobal/marketing-planner";
import { MySurveysApp } from "@cortexglobal/surveys";
import { FaqApp } from "@cortexglobal/faq";
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { EventsApp } from "@cortexglobal/events";

class PrivateApp extends Component {
    state = {
        section: "",
        children: [],
    };
    componentDidUpdate(prevProps) {
        if (prevProps.navigation !== this.props.navigation) {
            const {
                navigation: { data },
                location,
            } = this.props;

            const currentPath = location.pathname;
            // console.log(data);
            data.filter((item) => {
                // console.log(item);
                return item.level === 0;
            }).forEach((item) => {
                // console.log(item);
                const currentSection = currentPath.split("/")[1];
                const navItemSection = item.path.split("/")[1];

                if (currentSection === navItemSection) {
                    // console.log(currentPath, item.path, "Updating");
                    this.updateSection(item.id);
                }
            });
        }
    }

    componentDidMount() {
        const queryString = QueryString.parse(this.props.location.search);
        const activityUuid = queryString.activityUuid;
        const taskUuid = queryString.taskUuid;

        if (
            !!activityUuid &&
            !!taskUuid &&
            (!this.props.planner || !this.props.planner.order)
        ) {
            axios
                .all([
                    axios.get(`/api/v1/planner/activities/${activityUuid}`),
                    axios.get(`/api/v1/planner/tasks/${taskUuid}`),
                ])
                .then(
                    axios.spread(
                        ({ data: activityData }, { data: taskData }) => {
                            this.props.startOrderProcess({
                                task: taskData.data,
                                activity: activityData.data,
                            });
                        }
                    )
                )
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    updateSection = (selectedId = "") => {
        const {
            navigation: { data },
        } = this.props;
        let children = [];
        const selectedItem = data.find(({ id }) => selectedId === id);
        if (selectedItem && selectedItem.parent_id > 0) {
            children = data.filter((item) => {
                return item.parent_id === selectedId;
            });
        }
        // console.log(selectedId, children);
        this.setState({ section: selectedId, children: children });
    };

    render() {
        const { navigation, role, location, planner } = this.props;
        const { children } = this.state;

        const queryString = new URLSearchParams(window.location.search);
        const hideHeaderQuery = queryString.get("hideHeader");
        const hideHeader = hideHeaderQuery === "true";
        // console.log(children, navigation);

        return (
            <SiteWrapper>
                {!hideHeader && (
                    <>
                        <Header updateSection={this.updateSection} />
                        <MainNav
                            navigation={navigation}
                            location={location}
                            // section={section}
                            children={children}
                            updateSection={this.updateSection}
                        />
                    </>
                )}

                {/* TODO - Removed for now will want turning back on */}
                {/* <Breadcrumbs trail={navigation.breadcrumbs} /> */}
                <MainContentWrapper>
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            {/* TODO - Switch this back to the Home component when we're ready */}
                            {/* <Route exact path="/" component={MarketingPlanner} /> */}
                            <Route exact path="/" component={Home} />

                            <Route
                                path="/resources/media-library"
                                component={Assets}
                            />
                            <Route
                                path="/resources/documents"
                                component={DocumentAssets}
                            />
                            <Route
                                path="/resources/dealer-reports"
                                component={DealerReports}
                            />
                            <Route
                                path="/resources/my-media"
                                component={UserAssets}
                            />
                            <Route path="/resources" component={Assets} />
                            <Route path="/news" component={News} />
                            <Route
                                path="/area/:area/:section?/:page?"
                                render={(route) => <Area route={route} />}
                            />

                            <Route
                                test="test2"
                                path="/plan/marketing-planner"
                                component={MarketingPlanner}
                            />
                            <Route
                                path="/plan/budget"
                                component={BudgetManager}
                            />
                            <Route path="/plan" component={MarketingPlanner} />

                            <Route
                                path="/manage/requests"
                                component={Requests}
                            />
                            <Route path="/manage/orders" component={Orders} />

                            <Route path="/requests" component={Requests} />
                            <Route path="/orders" component={Orders} />

                            <Route
                                path="/communications"
                                component={Communications}
                            />
                            <Route path="/reporting" component={Reports} />

                            <Route
                                path="/management"
                                component={UserManagement}
                            />
                            <Route path="/profile" component={Profile} />
                            <Route
                                path="/group-profile"
                                component={GroupProfileAdmin}
                            />

                            <Route path="/import" component={Imports} />

                            <Route path="/help/faq" component={FaqApp} />

                            <Route path="/help/contact" component={Contact} />

                            <Route path="/survey">
                                <MySurveysApp area="surveys" />
                            </Route>
                            <Route path="/forms">
                                <MySurveysApp area="forms" />
                            </Route>

                            <Route path="/events" component={EventsApp} />
                        </Switch>
                    </Suspense>
                </MainContentWrapper>

                <Footer />
            </SiteWrapper>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ startOrderProcess }, dispatch);
}

const mapStateToProps = (state) => {
    const { navigation } = state.auth;

    return { navigation, planner: state.planner };
};

export default withRouter(
    withTheme(connect(mapStateToProps, mapDispatchToProps)(PrivateApp))
);
