import React, {
    useEffect,
    useState,
    useCallback,
    useContext,
    useRef,
    useLayoutEffect,
} from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle as faCheckCircleEmpty } from "@fortawesome/free-regular-svg-icons";
import useResizeObserver from "@react-hook/resize-observer";

import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    SimpleDropzone,
    useAddToast,
    Button,
    Row,
    Column,
    useToasts,
    Panel,
    PageTitle,
    Tabordion,
    Modal,
    Tooltip,
    Loading,
} from "@cortexglobal/rla-components";

import { faUpload } from "@fortawesome/free-solid-svg-icons";
import MetaDetails from "./MetaDetails";
import NamingAndGeneralSetup from "./NamingAndGeneralSetup";
import AssetContext from "../AssetContext";
import Availability from "./Availability";
import CategoryStructure from "./CategoryStructure";
import { BulkItem } from "./components/bulkEditor/BulkItem";

import { useBulkAssets } from "../hooks/useBulkAssets";

export const Grid = styled.section`
    max-width: 100%;
    padding: 0 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(
        ${({ size = {}, minColumnWidth = 200 }) => {
            const numColumns = Math.floor(size.width / minColumnWidth) - 1;
            /* return numColumns; */
            return numColumns > 1 ? numColumns : 1;
        }},
        minmax(${({ minColumnWidth = 200 }) => minColumnWidth}px, 1fr)
    );
`;

export const FlexRow = styled(Row)`
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
`;
export const ButtonRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    padding-bottom: 0.4rem;
    z-index: 1000;
    top: 0;
    background: ${({ theme }) => theme.modal.background};
    justify-content: flex-end;
`;
export const ItemColumn = styled(Column)`
    margin-bottom: 1rem;
`;

const BulkUploader = ({
    assetsPath,
    folderUuid,
    onApply,
    campaignUuid = "",
    onFileAdded,

    filters,
    searchableMeta,
    config,
    groups,
    libraryArea,
}) => {
    const target = useRef(null);

    const [size, setSize] = useState({});
    const [assetTypes, setAssetTypes] = useState({
        loaded: false,
        data: [],
    });
    const [mediaChannels, setMediaChannels] = useState({
        loaded: false,
        data: [],
    });
    const {
        state: { items, sharedItem },
        loadItems,
        reset,
        updateItem,
        updateItems,
        removeItem,
        selectAll,
        deselectAll,
    } = useBulkAssets({
        assetTypes: assetTypes.data,
        mediaChannels: mediaChannels.data,
    });
    useLayoutEffect(() => {
        if (target.current) {
            setSize(target.current.getBoundingClientRect());
        }
    }, [target, items, mediaChannels, assetTypes]);

    useResizeObserver(target, (entry) => {
        if (entry.contentRect.width !== size.width) {
            setSize(entry.contentRect);
        }
    });

    const [showBulkUploader, setShowBulkUploader] = useState(false);
    const history = useHistory();

    const addToast = useAddToast();
    const [_, dispatch] = useToasts();
    // const [files, setFiles] = useState([]);
    const [assetAdmin, setAssetAdmin] = useState({
        assetStatusOptionsLoaded: true,
        assetStatusOptions: [],
        assetDeclarations: [],
    });
    const [languages, setLanguages] = useState([]);

    const [saving, setSaving] = useState(false);
    const [assetHierarchyAvailable, setAssetHierarchyAvailable] =
        useState(false);
    const [categoryHierarchy, setCategoryHierarchy] = useState(null);
    const [errors, setErrors] = useState({});
    const [groupsInHierarchy, setGroupsInHierarchy] = useState([]);
    const [openSectionIndex, setOpenSectionIndex] = useState(0);
    const [singleUploadProgress, setSingleUploadProgress] = useState(0);

    const resetUploader = () => {
        setShowBulkUploader(false);
        setSaving(false);
        reset();
        setSaving(false);
        setAssetTypes({
            loaded: false,
            data: [],
        });
        setMediaChannels({
            loaded: false,
            data: [],
        });
        items.forEach((file) => URL.revokeObjectURL(file.preview));
    };
    useEffect(() => {
        if (showBulkUploader) {
            //If the locale_setting is set to "language" then preload the languages to stop them
            //being loaded in each overview form
            if (
                config.options &&
                config.options.locale_setting === "language"
            ) {
                axios
                    .get(`/api/v1/language`)
                    .then(({ data: { data: languages } }) => {
                        setLanguages(languages);
                    });
            }
            //Load the bulk asset status options
            axios.get(`/api/v1/assets/bulk-status-options`).then(({ data }) => {
                setAssetAdmin({
                    assetStatusOptionsLoaded: true,
                    assetStatusOptions: data.availableStatusList,
                    assetDeclarations: data.declarations,
                });
            });

            axios
                .get("/api/v1/assets/asset-types")
                .then(({ data }) => {
                    setAssetTypes({
                        loaded: true,
                        data: data.data,
                    });
                })
                .catch((e) => {
                    addToast({
                        type: "alert",
                        content: e.message,
                        showFor: 5000,
                    });
                });

            axios
                .get("/api/v1/assets/media-channels")
                .then(({ data }) => {
                    setMediaChannels({
                        loaded: true,
                        data: data.data,
                    });
                })
                .catch((e) => {
                    addToast({
                        type: "alert",
                        content: e.message,
                        showFor: 5000,
                    });
                });

            axios.get(`/api/v1/admin/group/structure`).then(({ data }) => {
                setGroupsInHierarchy(data.data);
            });

            //Add in the categories section but only if it's an available option
            const hasAssetHierarchyAvailable =
                filters?.loaded &&
                filters.data.findIndex(
                    (filter) => filter.name === "category-hierarchy"
                ) !== -1;
            setAssetHierarchyAvailable(hasAssetHierarchyAvailable);
            if (hasAssetHierarchyAvailable) {
                axios.get(`/api/v1/admin/asset-hierarchy`).then(({ data }) => {
                    setCategoryHierarchy(data.data);
                });
            }
        }
    }, [showBulkUploader]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        return resetUploader();
    }, []);

    useEffect(() => {
        if (showBulkUploader && items.length === 0) {
            resetUploader();
            onApply();
            addToast({
                // type: "success", //Removed as it could be the result of all files failing
                content: trans("Bulk uploading complete"),
                showFor: 5000,
            });
        }
    }, [items]);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length === 1) {
            return uploadSingleFile(acceptedFiles[0]);
        }

        const newFiles = acceptedFiles.map((file) => {
            // console.log(file);
            const preAsset = {
                ...sharedItem,
                file,
                uuid: uuid(),
                library_area: libraryArea || "assets-default",
                campaign_uuid: campaignUuid,
                preview:
                    file.type === "image/jpeg" || file.type === "image/png"
                        ? URL.createObjectURL(file)
                        : null,
                progress: 0,
                selected: true,
                size: file.size,
                path: file.path,
                name: file.name ? file.name.replace(/\.[^/.]+$/, "") : "",
                main_file_extension: file.name.split(".").pop(),
                type: file.type,
                lastModified: file.lastModified
                    ? new Date(file.lastModified)
                    : new Date(),
            };

            // console.log(preAsset);
            return preAsset;
        });
        loadItems(newFiles);
        setShowBulkUploader(true);
        onFileAdded();
        //Refresh the languages (forces the default language to be loaded into state)
        if (config.options && config.options.locale_setting === "language") {
            axios
                .get(`/api/v1/language`)
                .then(({ data: { data: languages } }) => {
                    setLanguages(languages);
                });
        }
        newFiles.forEach((file) => {
            sendFile(file);
        });
    }, []);

    const uploadSingleFile = (acceptedFile) => {
        setSaving(true);
        const fd = new FormData();
        fd.append("file", acceptedFile);
        fd.append("library_area", libraryArea || "assets-default");
        if (folderUuid !== "") {
            fd.append("asset_folder_uuid", folderUuid);
        }
        if (campaignUuid !== "") {
            fd.append("campaign_uuid", campaignUuid);
        }

        const config = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                // console.log(percentCompleted);
                setSingleUploadProgress(percentCompleted);
                // useToastDispatch({
                //     type: "updateToast",
                //     uuid: "uploadToast",
                //     content: trans("Upload progress: {progress}%", {
                //         progress: percentCompleted,
                //     }),
                // });
            },
        };
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Asset is being uploaded"),
            // content: trans("Upload progress: {progress}%", {
            //     progress: singleUploadProgress,
            // }),
        });

        axios
            .post(`/api/v1/admin/assets`, fd, config)
            .then(({ data: { data } }) => {
                setSaving(false);
                onFileAdded();
                onApply();
                history.push(`${assetsPath}/${data.uuid}/admin`);
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                addToast({
                    type: "success",
                    content: trans("Asset uploaded"),
                    showFor: 5000,
                });
            })
            .catch((e) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred uploading your file");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
                setSingleUploadProgress(0);
                setSaving(false);
            });
    };

    const sendFile = useCallback(({ file, name, uuid, type }) => {
        const fd = new FormData();
        fd.append("file", file);
        fd.append("name", name);
        fd.append("uuid", uuid);
        fd.append("type", type);
        if (folderUuid !== "") {
            fd.append("asset_folder_uuid", folderUuid);
        }
        if (campaignUuid !== "") {
            fd.append("campaign_uuid", campaignUuid);
        }

        const config = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );

                updateItem(uuid, "progress", percentCompleted);
            },
        };

        axios
            .post(`/api/v1/assets/pre-asset`, fd, config)
            .then(({ data }) => {
                // dispatch({ type: "removeToast", uuid: "uploadToast" });
                // console.log("complete");
            })
            .catch((e) => {
                //TODO - Look at disabling the item instead of removing it?
                removeItem(uuid);
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred uploading your file {name}", {
                          name,
                      });
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            });
    });

    const onSubmit = () => {
        setSaving(true);
        items
            .filter((file) => file.selected)
            .forEach((file) => {
                saveAsAsset(file);
            });
    };

    const saveAsAsset = (item) => {
        return axios
            .put(`/api/v1/assets/pre-asset/${item.uuid}`, item)
            .then(({ data }) => {
                removeItem(item.uuid);
                return data;
            })
            .catch((e) => {
                if (e.response && e.response.data) {
                    if (e.response.status === 422) {
                        setErrors((errors) => {
                            return {
                                ...errors,
                                [item.uuid]: e.response.data.errors,
                            };
                        });
                    } else {
                        addToast({
                            type: "alert",
                            content:
                                e.response && e.response.data
                                    ? e.response.data.message
                                    : trans("An error has occurred"),
                            showFor: 5000,
                        });
                    }
                }
            });
    };

    const filesSelected = items.filter((file) => file.selected).length;

    return (
        <>
            <SimpleDropzone
                onDrop={onDrop}
                disabled={saving || singleUploadProgress > 0}
            >
                <IconTextButton
                    style={{
                        textDecoration: "none",
                        paddingTop: "1rem",
                        paddingBottom: "0.8rem",
                    }}
                    onClick={() => {
                        return false;
                    }}
                    icon={faUpload}
                    showCircle={false}
                    expanded={false}
                    disabled={saving || singleUploadProgress > 0}
                >
                    <div style={{ fontWeight: "400" }}>
                        {singleUploadProgress > 0
                            ? trans("Uploading: {progress}%", {
                                  progress: singleUploadProgress,
                              })
                            : trans("Upload")}
                    </div>
                </IconTextButton>
            </SimpleDropzone>
            {showBulkUploader && (
                <Modal
                    visible={showBulkUploader}
                    onClose={() => setShowBulkUploader(false)}
                    fullScreen={true}
                    showCloseButton={false}
                    onlyHideWithCss={true}
                    zIndex={0}
                >
                    <Row expanded={true} collapse={true}>
                        <Column collapse={true}>
                            <PageTitle
                                title={
                                    <Tooltip
                                        text={trans(
                                            "Please note that not all attributes are available in the bulk uploader.  If you cannot find the option you require please save the assets then edit that asset individually"
                                        )}
                                    >
                                        {trans("Upload Files")}{" "}
                                    </Tooltip>
                                }
                            >
                                <IconTextButton
                                    onClick={resetUploader}
                                    rotation={180}
                                    showCircle={false}
                                    expanded={false}
                                >
                                    {trans("Cancel")}
                                </IconTextButton>
                                <Button onClick={onSubmit} expanded={false}>
                                    {trans(
                                        "{filesSelected, plural, =0 {No files selected} one {Save # file} other {Save # files}}",
                                        {
                                            filesSelected,
                                        }
                                    )}
                                </Button>
                            </PageTitle>
                        </Column>
                    </Row>
                    {assetTypes.loaded && mediaChannels.loaded ? (
                        <Row
                            // expanded={true}
                            collapse={true}
                            style={{
                                display: "flex",
                                height: "85vh",
                            }}
                        >
                            <Column
                                medium={4}
                                large={3}
                                xlarge={2}
                                style={{
                                    height: "100%",
                                    overflow: "scroll",
                                }}
                                collapse={true}
                            >
                                <Panel>
                                    <Tabordion
                                        type="accordion"
                                        allowMultiple={true}
                                        current={openSectionIndex}
                                        onTabClick={(key) => {
                                            // console.log(key);
                                            setOpenSectionIndex(key);
                                        }}
                                        allowFullyClosed={false}
                                    >
                                        <NamingAndGeneralSetup
                                            heading={trans("Overview")}
                                            assetAdmin={assetAdmin}
                                            config={config}
                                            asset={sharedItem}
                                            standardOnChange={updateItems}
                                            errors={{}}
                                            singleColumn={true}
                                            showSectionHeading={false}
                                            excludedFields={[
                                                "name",
                                                "description",
                                            ]}
                                            preloadedLanguages={languages}
                                            assetTypes={assetTypes.data}
                                            mediaChannels={mediaChannels.data}
                                        />

                                        <MetaDetails
                                            heading={trans("Meta Data")}
                                            asset={sharedItem}
                                            standardOnChange={updateItems}
                                            filters={filters}
                                            searchableMeta={searchableMeta}
                                            errors={{}}
                                            singleColumn={true}
                                            showSectionHeading={false}
                                            excludedFields={["version"]}
                                        />

                                        {assetHierarchyAvailable && (
                                            <CategoryStructure
                                                heading={trans("Categories")}
                                                preLoadedCategories={
                                                    categoryHierarchy
                                                }
                                                asset={sharedItem}
                                                standardOnChange={updateItems}
                                                errors={errors}
                                                singleColumn={true}
                                                showSectionHeading={false}
                                                collapse={true}
                                            />
                                        )}
                                        <Availability
                                            heading={trans("Availability")}
                                            asset={sharedItem}
                                            standardOnChange={updateItems}
                                            filters={filters}
                                            searchableMeta={searchableMeta}
                                            errors={{}}
                                            assetAdmin={assetAdmin}
                                            groups={groups}
                                            groupsInHierarchy={
                                                groupsInHierarchy
                                            }
                                            singleColumn={true}
                                            showSectionHeading={false}
                                            excludedFields={[]}
                                        />
                                    </Tabordion>
                                </Panel>
                            </Column>
                            <Column
                                medium={8}
                                large={9}
                                xlarge={10}
                                style={{
                                    position: "relative",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                }}
                                collapse={true}
                            >
                                <ButtonRow>
                                    <IconTextButton
                                        onClick={selectAll}
                                        showCircle={false}
                                        expanded={false}
                                        icon={faCheckCircle}
                                    >
                                        {trans("Select All")}
                                    </IconTextButton>
                                    <IconTextButton
                                        onClick={deselectAll}
                                        showCircle={false}
                                        expanded={false}
                                        icon={faCheckCircleEmpty}
                                    >
                                        {trans("Deselect All")}
                                    </IconTextButton>
                                </ButtonRow>
                                <Grid size={size} ref={target}>
                                    {items.map((file) => {
                                        // console.log(file);
                                        return (
                                            <BulkItem
                                                key={file.uuid}
                                                item={file}
                                                onChange={updateItem}
                                                config={config}
                                                filters={filters}
                                                searchableMeta={searchableMeta}
                                                openSectionIndex={
                                                    openSectionIndex
                                                }
                                                setOpenSectionIndex={
                                                    setOpenSectionIndex
                                                }
                                                groups={groups}
                                                groupsInHierarchy={
                                                    groupsInHierarchy
                                                }
                                                preloadedLanguages={languages}
                                                preLoadedCategories={
                                                    categoryHierarchy
                                                }
                                                assetHierarchyAvailable={
                                                    assetHierarchyAvailable
                                                }
                                                assetAdmin={assetAdmin}
                                                errors={errors[file.uuid]}
                                                removeItem={removeItem}
                                                assetTypes={assetTypes.data}
                                                mediaChannels={
                                                    mediaChannels.data
                                                }
                                            />
                                        );
                                    })}
                                </Grid>
                            </Column>
                        </Row>
                    ) : (
                        <Loading />
                    )}
                </Modal>
            )}
        </>
    );
};

export default BulkUploader;
