import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import RequestTypeListing from "./RequestTypeListing";
import RequestTypeCreate from "./RequestTypeCreate";
import { RequestTypeEdit } from "./RequestTypeEdit";

export const RequestTypeAdmin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <RequestTypeListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <RequestTypeCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <RequestTypeEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default RequestTypeAdmin;
