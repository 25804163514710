import axios from "axios";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { useAddToast, useToasts } from "@cortexglobal/rla-components";

const useUsers = () => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const loadToast = (message) => {
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: message,
        });
    };

    const removeLoadToast = () => {
        dispatch({ type: "removeToast", uuid: "loadToast" });
    };

    const disableUser = async (uuid) => {
        loadToast(trans("User is being disabled"));
        return await axios
            .put(`/api/v1/manager/users/${uuid}/disable`, {
                async: true,
            })
            .then(({ data }) => {
                removeLoadToast();
                return data;
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: error.message,
                    showFor: 5000,
                });
            });
    };

    const deleteUser = async (uuid) => {
        loadToast(trans("User is being deleted"));
        return await axios
            .put(`/api/v1/manager/users/${uuid}/delete`, {
                async: true,
            })
            .then(({ data }) => {
                removeLoadToast();
                return data;
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: error.message,
                    showFor: 5000,
                });
            });
    };

    const activateUser = async (uuid) => {
        loadToast(trans("User is being activated"));
        return await axios
            .put(`/api/v1/manager/users/${uuid}/reactivate`, {
                async: true,
            })
            .then(({ data }) => {
                removeLoadToast();
                return data;
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: error.message,
                    showFor: 5000,
                });
            });
    };

    const approveUser = async (uuid) => {
        loadToast(trans("User is being approved"));
        return await axios
            .put(`/api/v1/manager/users/${uuid}/approve`, {
                async: true,
            })
            .then(({ data }) => {
                removeLoadToast();
                return data;
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: error.message,
                    showFor: 5000,
                });
            });
    };

    const ghostAsUser = async (uuid) => {
        loadToast(trans("User is being ghosted"));
        return await axios
            .post(`/api/v1/admin/ghost/${uuid}`, {
                async: true,
            })
            .then(({ data }) => {
                removeLoadToast();
                return data;
            })
            .catch((error) => {
                removeLoadToast();

                addToast({
                    type: "alert",
                    content: error.message,
                    showFor: 5000,
                });
            });
    };

    const rejectUser = async (uuid) => {
        loadToast(trans("User is being rejected"));
        return await axios
            .put(`/api/v1/manager/users/${uuid}/reject`, {
                async: true,
            })
            .then(({ data }) => {
                removeLoadToast();
                return data;
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: error.message,
                    showFor: 5000,
                });
            });
    };

    const resendActivation = async (uuid) => {
        loadToast(trans("User is being resent activation"));
        return await axios
            .put(`/api/v1/manager/users/${uuid}/resend-activation`, {
                async: true,
            })
            .then(({ data }) => {
                removeLoadToast();
                return data;
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: error.message,
                    showFor: 5000,
                });
            });
    };

    return {
        disableUser,
        deleteUser,
        activateUser,
        approveUser,
        rejectUser,
        resendActivation,
        ghostAsUser,
    };
};

export default useUsers;
