import React, { useContext, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGripVertical,
    faUpload,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    FormRow,
    InputField,
    Toggle,
    SelectField,
    Button,
    SimpleDropzone,
    IconTextButton,
} from "@cortexglobal/rla-components";

import styled from "styled-components";

import SurveyChoiceOptions from "./SurveyChoiceOptions";
import SurveyRangeOptions from "./SurveyRangeOptions";
import SurveyDateOptions from "./SurveyDateOptions";
import Attachment from "../components/Attachment";

const SurveyQuestionSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    border: 1px solid #a2a2a2;
    background-color: #ffffff;
    margin-top: -1px;
`;

const SurveyQuestionDragHandle = styled.div`
    width: 50px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    background-color: #eeeeee;
    cursor: grabbing;
`;

const SurveyQuestionPanel = styled.div`
    flex-grow: 1;
    padding: ${(props) => `${props.theme.spacing.padding}rem`};
`;

const SurveyQuestionDelete = styled.div`
    width: auto;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => `${props.theme.spacing.padding}rem`};
`;

const SurveyQuestion = ({
    question,
    index,
    isSubmitting,
    surveyData,
    disableEdit,
    setSurveyData,
    removeQuestion,
    singleColumn = false,
    collapse = false,
}) => {
    const surveyUuid = surveyData.uuid;
    const intl = useIntl();

    const checkOptions = () => {
        if (typeof question.options !== "object") {
            question.options = {};
        }
        if (!question.options?.range) {
            question.options.range = {
                min_value: 0,
                max_value: 10,
                default_value: 5,
            };
        }
        if (!question.options?.choice) {
            question.options.choice = [];
        }
    };

    checkOptions();

    useEffect(() => {
        checkOptions();
    }, [surveyData, question]);

    const handleQuestionChange = (
        propertyName,
        value,
        index,
        boolToInt = false
    ) => {
        if (boolToInt) {
            value = value ? 1 : 0;
        }
        let updatedQuestions = surveyData.questions;
        updatedQuestions[index][propertyName] = value;
        setSurveyData({
            ...surveyData,
            questions: [...updatedQuestions],
        });
    };

    const updateOptionsData = (type, values, index) => {
        // console.log({ type, values, index });
        let updatedOptions = { ...question.options };
        updatedOptions[type] = values;
        handleQuestionChange("options", updatedOptions, index);
    };

    //When a file is dropped, we need to upload to the server and then update the state
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", file);

        axios
            .post(
                `/api/v1/surveys/${surveyUuid}/question/${question.uuid}/attachment`,
                formData
            )
            .then(({ data }) => {
                handleQuestionChange("attachment", data.data, index);
            });
    };

    const renderDetails = () => {
        if (!question.type) return null;

        if (question.type === "info") {
            return (
                <>
                    {" "}
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="question"
                        label={trans("Title")}
                        // error={errors}
                        // required={true}
                    >
                        <InputField
                            name="question"
                            onChange={(e) =>
                                handleQuestionChange(e.name, e.value, index)
                            }
                            disabled={isSubmitting || disableEdit}
                            value={question.question}
                        />
                    </FormRow>
                    <FormRow
                        singleColumn={singleColumn}
                        collapse={collapse}
                        name="caption"
                        label={trans("Description")}
                        // error={errors}
                        // required={true}
                    >
                        <InputField
                            name="caption"
                            onChange={(e) =>
                                handleQuestionChange(e.name, e.value, index)
                            }
                            disabled={isSubmitting || disableEdit}
                            value={question.caption}
                        />
                    </FormRow>
                </>
            );
        }
        return (
            <>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="question"
                    label={trans("Question")}
                    // error={errors}
                    // required={true}
                >
                    <InputField
                        name="question"
                        onChange={(e) =>
                            handleQuestionChange(e.name, e.value, index)
                        }
                        disabled={isSubmitting || disableEdit}
                        value={question.question}
                    />
                </FormRow>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="caption"
                    label={trans("Caption")}
                    // error={errors}
                    // required={true}
                >
                    <InputField
                        name="caption"
                        onChange={(e) =>
                            handleQuestionChange(e.name, e.value, index)
                        }
                        disabled={isSubmitting || disableEdit}
                        value={question.caption}
                    />
                </FormRow>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="attachment"
                    label={trans("Attachment")}
                    // error={errors}
                    // required={true}
                >
                    {!surveyUuid ? (
                        <div style={{ marginBottom: "1rem" }}>
                            {trans(
                                "Please save the survey before adding attachments"
                            )}
                        </div>
                    ) : (
                        <>
                            <SimpleDropzone
                                onDrop={onDrop}
                                disabled={isSubmitting || disableEdit}
                            >
                                <IconTextButton
                                    style={{
                                        textDecoration: "none",
                                        paddingTop: "1rem",
                                        paddingBottom: "0.8rem",
                                    }}
                                    onClick={() => {
                                        return false;
                                    }}
                                    icon={faUpload}
                                    showCircle={false}
                                    expanded={false}
                                >
                                    <div
                                        style={{
                                            fontWeight: "400",
                                        }}
                                    >
                                        {trans("Upload")}
                                    </div>
                                </IconTextButton>
                            </SimpleDropzone>

                            <Attachment attachment={question.attachment} />
                        </>
                    )}
                </FormRow>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="required"
                    label={trans("Answer Required")}
                    // error={errors}
                    // required={true}
                >
                    <Toggle
                        name="required"
                        onChange={(e) =>
                            handleQuestionChange(
                                "required",
                                e.target.checked,
                                index,
                                true
                            )
                        }
                        disabled={isSubmitting || disableEdit}
                        checked={question.required}
                        size="small"
                        style={{ marginBottom: "1.2rem" }}
                    />
                </FormRow>
                {(question.type === "single" || question.type === "multi") && (
                    <SurveyChoiceOptions
                        options={question.options?.choice}
                        index={index}
                        isSubmitting={isSubmitting}
                        disableEdit={disableEdit}
                        updateOptionsData={updateOptionsData}
                    />
                )}
                {question.type === "range" && (
                    <SurveyRangeOptions
                        options={question.options?.range}
                        index={index}
                        isSubmitting={isSubmitting}
                        disableEdit={disableEdit}
                        updateOptionsData={updateOptionsData}
                    />
                )}
                {question.type === "date" && (
                    <SurveyDateOptions
                        options={question.options?.range}
                        index={index}
                        isSubmitting={isSubmitting}
                        disableEdit={disableEdit}
                        updateOptionsData={updateOptionsData}
                    />
                )}
            </>
        );
    };

    return (
        <SurveyQuestionSection>
            {!disableEdit && (
                <SurveyQuestionDragHandle>
                    <FontAwesomeIcon icon={faGripVertical} size="lg" />
                </SurveyQuestionDragHandle>
            )}

            <SurveyQuestionPanel>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="type"
                    label={trans("Type")}
                    // error={errors}
                    // required={true}
                >
                    <SelectField
                        name="type"
                        value={question.type}
                        onChange={(e) =>
                            handleQuestionChange(e.name, e.value, index)
                        }
                        disabled={isSubmitting || disableEdit}
                        options={[
                            {
                                value: "info",
                                text: intl.formatMessage({
                                    id: "Section/Information",
                                }),
                            },
                            {
                                value: "single",
                                text: intl.formatMessage({
                                    id: "Single Choice",
                                }),
                            },
                            {
                                value: "multi",
                                text: intl.formatMessage({
                                    id: "Multiple Choice",
                                }),
                            },
                            {
                                value: "range",
                                text: intl.formatMessage({
                                    id: "Range/Slider",
                                }),
                            },
                            {
                                value: "text",
                                text: intl.formatMessage({
                                    id: "Text (Single Line)",
                                }),
                            },
                            {
                                value: "textarea",
                                text: intl.formatMessage({
                                    id: "Text (Multiple Lines)",
                                }),
                            },
                            {
                                value: "date",
                                text: intl.formatMessage({
                                    id: "Date",
                                }),
                            },
                        ]}
                    />
                </FormRow>
                {renderDetails()}
            </SurveyQuestionPanel>

            {!disableEdit && (
                <Button
                    type={"alert"}
                    onClick={() => removeQuestion(index)}
                    disabled={isSubmitting}
                    style={{
                        margin: "0.5rem",
                        height: "2.5rem",
                        padding: "0 0.8em 0 1em",
                    }}
                >
                    <FontAwesomeIcon
                        icon={faTrash}
                        size="lg"
                        style={{ marginRight: "0.25rem" }}
                    />
                </Button>
            )}
        </SurveyQuestionSection>
    );
};

export default SurveyQuestion;
