import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { trans } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import {
    Row,
    Column,
    CardGrid,
    SubmitButton,
    IconTextButton,
    Modal,
    useAddToast,
    PageTitle,
    Loading,
    Image,
} from "@cortexglobal/rla-components";
import GenerateCampaign from "./GenerateCampaign";
import AssetResultItem from "../../assets/components/AssetResultItem";

export const ViewCampaign = ({
    basePath,
    campaignUuid,
    selectedCampaign,
    assetsPath,
}) => {
    const addToast = useAddToast();
    const history = useHistory();
    const { can } = usePermissions();
    const [showGenerationModal, setShowGenerationModal] = useState(false);

    const [campaign, setCampaign] = useState({
        loading: true,
        loaded: false,
        data: {},
    });
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const groups = useSelector((state) => state?.auth?.groups);

    useEffect(() => {
        axios
            .get(`/api/v1/campaigns/${campaignUuid}`)
            .then(({ data: { data } }) => {
                setCampaign({
                    loaded: true,
                    loading: false,
                    data,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error loading campaign"),
                    showFor: 5000,
                });
            });
    }, [campaignUuid]);

    const getTitle = () => {
        //Best to use the name loaded fresh from the API
        if (campaign.loaded) {
            return campaign.data.name;
        }
        //If we have a selected campaign, use that
        if (selectedCampaign) {
            return selectedCampaign.name;
        }
        //We're still loading
        if (campaign.loading) {
            return trans("Loading...");
        }
        //We've finished loading but don't have a campaign
        return trans("Campaign not found");
    };

    const renderContent = () => {
        if (campaign.loading) {
            return <Loading />;
        }
        if (!campaign.loaded || !campaign.data) {
            return <h3>{trans("Campaign not found")}</h3>;
        }

        return (
            <>
                {campaign.data.hero && (
                    <Column>
                        <Image
                            src={campaign.data.hero}
                            style={{ maxWidth: "100%" }}
                        />
                    </Column>
                )}

                {campaign.data.description && (
                    <Column
                        style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                    >
                        <p>{campaign.data.description}</p>
                    </Column>
                )}
                {campaign.data.assets && campaign.data.assets.length > 0 && (
                    <Column>
                        {campaign.data.assets.map((asset) => (
                            <AssetResultItem
                                key={asset.uuid}
                                asset={asset}
                                loaded={true}
                                onClick={() => {
                                    history.push(
                                        `/${assetsPath}/view/${asset.uuid}`
                                    );
                                }}
                            />
                        ))}
                    </Column>
                )}
            </>
        );
    };

    return (
        <>
            <PageTitle title={<h2>{getTitle()}</h2>}>
                {can("dam-campaign-edit") && (
                    <IconTextButton
                        as={Link}
                        to={`${basePath}${campaignUuid}/edit`}
                        showCircle={false}
                        icon="edit"
                    >
                        {trans("Edit")}
                    </IconTextButton>
                )}
                {can("dam-group-campaign-create") && (
                    <IconTextButton
                        type="primary"
                        onClick={() => setShowGenerationModal(true)}
                        icon="plus"
                        showCircle={false}
                    >
                        {trans("Generate")}
                    </IconTextButton>
                )}
                <IconTextButton
                    as={Link}
                    to={`${basePath}`}
                    showCircle={false}
                    rotation={180}
                >
                    {trans("Back to Campaigns")}
                </IconTextButton>
            </PageTitle>
            <Row>{renderContent()}</Row>{" "}
            <Modal
                visible={showGenerationModal}
                onClose={() => setShowGenerationModal(false)}
            >
                <GenerateCampaign
                    campaign={campaign.data}
                    onCancel={() => setShowGenerationModal(false)}
                    onComplete={() => {
                        setShowGenerationModal(false);
                    }}
                />
            </Modal>
        </>
    );
};

export default ViewCampaign;
