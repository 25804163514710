import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
    Table,
    Cell as FixedDataTableCell,
    DataCell as FixedDataTableDataCell,
} from "fixed-data-table-2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

import { PlainButton } from "@cortexglobal/rla-components";

export { Column as DataColumn, Cell } from "fixed-data-table-2";

export const SortIcon = styled(FontAwesomeIcon)`
    margin-left: 0.5rem;
`;

export const DataTable = styled(Table)`
    overscroll-behavior-x: none;
    .fixedDataTableRowLayout_rowExpanded,
    .activityWrapper {
        transition: height 2000ms ease-in-out;
        box-shadow: -1px 1px 5px inset rgba(0, 0, 0, 0.1);
        /* border-top: 0.5px solid ${({ theme }) => theme.colors.black};
        border-bottom: 1px solid ${({ theme }) => theme.colors.black}; */
    }
    .public_fixedDataTableCell_main {
        border: none;
    }
    .public_fixedDataTable_scrollbarSpacer,
    .public_fixedDataTable_header,
    .public_fixedDataTable_header .public_fixedDataTableCell_main {
        background-image: none;
        background: ${({ theme, type = "default" }) => {
            return theme.table.types[type].thBackground;
        }};
        color: ${({ theme, type = "default" }) => {
            return theme.table.types[type].thColor;
        }} !important;
        border: none;
    }
`;

export const FlexCell = styled(FixedDataTableDataCell)`
    display: flex;
    align-items: center;
    padding: 2px 0;
`;

export const TaskNameCell = styled(FixedDataTableDataCell)`
    display: flex;
    justify-content: space-between;
    small {
        display: inline-block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        text-align: right;
        min-width: 5rem;
    }
    .taskButtons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        opacity: 0;

        transition: all 0.2s ease;
    }
    &:hover .taskButtons {
        opacity: 1;
    }
`;

export const CategoryCell = styled(FixedDataTableCell)`
    background: ${({ $categoryTheme }) => $categoryTheme?.background || "none"};
    color: ${({ $categoryTheme }) => $categoryTheme?.color || "inherit"};
    border-top: 1px solid
        ${({ $categoryTheme }) => $categoryTheme?.border || "inherit"};
    .plannerTaskCategoryEditLink {
        float: right;
        opacity: 0;
        transition: all 0.2s ease;
    }
    &:hover .plannerTaskCategoryEditLink {
        opacity: 1;
    }
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
    transition: transform
        ${({ theme }) => theme.animations.presets.mediumBounce};
`;

export const TaskLink = styled(Link)`
    display: flex;
    flex-grow: 1;
    text-decoration: none;
    overflow: hidden;
    &:hover {
        text-decoration: underline;
    }
`;

const DataCell = styled(FixedDataTableDataCell)`
    width: ${({ width }) => width}px;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: ${({ type }) => (type === "currency" ? "right" : "left")};
    border-right: ${({ $resizable }) =>
        $resizable ? "2px solid #e0e0e0" : "none"};
`;

export const TableHeader = ({
    columnData,
    columnSlug,
    sortSettings,
    onChange,
    width,
    sortable = true,
    resizable = true,
    children = null,
}) => {
    const { sortBy, sortDirection } = sortSettings;
    const isSorted = sortBy === columnSlug;
    const sortDirectionIcon = sortDirection === "desc" ? faSortUp : faSortDown;
    return (
        <DataCell type={columnData.type} width={width} $resizable={resizable}>
            {sortable ? (
                <PlainButton
                    style={{
                        color: "inherit",
                        fontSize: "12px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        height: "100%",
                        textAlign:
                            columnData.type === "currency" ? "right" : "left",
                    }}
                    expanded={true}
                    onClick={() =>
                        onChange({
                            sortBy: columnSlug,
                            sortDirection:
                                isSorted && sortDirection === "asc"
                                    ? "desc"
                                    : "asc",
                        })
                    }
                >
                    {children || columnData.label}
                    {isSorted && (
                        <SortIcon icon={sortDirectionIcon} transform="grow-3" />
                    )}
                </PlainButton>
            ) : (
                children || columnData.label
            )}
        </DataCell>
    );
};

export const DateCellContents = styled.div`
    background: ${({ plannerTheme, task }) => {
        // console.log(task.group_relationship, plannerTheme.colors.activities);
        const groupRelationshipColor =
            plannerTheme.colors.task[task.owner_type];
        return groupRelationshipColor
            ? groupRelationshipColor
            : plannerTheme.colors.activities.other;
    }};
    cursor: pointer;
    opacity: ${({ beingHovered }) => (beingHovered ? 1 : 0.6)};
    position: absolute;
    transition: opacity 0.35s ease;
    height: 20px;
    ${({ isStartDate, isEndDate }) => {
        if (isStartDate && isEndDate) {
            return css`
                margin: 0 25%;
                width: 50%;
                border-radius: 10px;
            `;
        }

        if (isStartDate) {
            return css`
                margin: 0 0 0 25%;
                width: 75%;
                border-radius: 10px 0 0 10px;
            `;
        }

        if (isEndDate) {
            return css`
                margin: 0 25% 0 0;
                width: 75%;
                border-radius: 0 10px 10px 0;
            `;
        }

        return css`
            margin: 0;
            width: 100%;
            border-radius: 0;
        `;
    }}
`;

export const DateHeader = styled(FixedDataTableCell)`
    text-align: center;
`;
export const SmallDate = styled.span`
    display: block;
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: normal;
`;
