import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import {
    Row,
    Column,
    RadioField,
    Loading,
    FormRow,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const OrderFlowOptionsForm = ({
    order,
    standardOnChange,
    errors,
    orderFlows,
}) => {
    const handleOptionChange = (value, options) => {
        //If the current order.order_flow_options_uuids includes any of the other options in this type
        //(passed in via the options), then remove them
        const availableTypeOptionUuids = options.map((option) => option.value);
        let newValues = order.order_flow_options_uuids.filter((uuid) => {
            return !availableTypeOptionUuids.includes(uuid);
        });

        //Add the new value
        newValues = [...newValues, value];

        //Fire off the change event with the new values
        standardOnChange({
            name: "order_flow_options_uuids",
            value: newValues,
        });
    };

    const { data, loaded } = orderFlows;

    if (!loaded) {
        return <Loading />;
    }

    return (
        <Row>
            <Column>
                {Array.isArray(order?.user_asset?.asset?.order_flows) &&
                order.user_asset.asset.order_flows.length > 0 ? (
                    order.user_asset.asset.order_flows.map((baseOrderFlow) => {
                        const orderFlow = orderFlows.data.find(
                            (orderFlow) => orderFlow.uuid === baseOrderFlow.uuid
                        );
                        if (!orderFlow) {
                            return null;
                        }
                        return (
                            <>
                                {orderFlow.types.map((type) => {
                                    //Get the options for this type
                                    const options =
                                        order.user_asset.asset.order_flow_options
                                            .filter((option) => {
                                                return (
                                                    option.type.uuid ===
                                                    type.uuid
                                                );
                                            })
                                            .map((option) => {
                                                return {
                                                    value: option.uuid,
                                                    text: option.name,
                                                };
                                            });
                                    //Get the current value for this type
                                    const selectedOption = options.find(
                                        (option) =>
                                            option.value ===
                                            order.order_flow_options_uuids.find(
                                                (uuid) => uuid === option.value
                                            )
                                    );

                                    const onlyOneOption = options.length === 1;

                                    if (onlyOneOption && !selectedOption) {
                                        handleOptionChange(
                                            options[0].value,
                                            options
                                        );
                                    }

                                    return (
                                        <FormRow
                                            label={type.name}
                                            required={type.required}
                                        >
                                            {onlyOneOption ? (
                                                <strong
                                                    style={{
                                                        display: "inline-block",
                                                        marginBottom: "1rem",
                                                        marginTop: "0.1rem",
                                                    }}
                                                >
                                                    {selectedOption?.text}
                                                </strong>
                                            ) : (
                                                <RadioField
                                                    value={
                                                        selectedOption?.value
                                                    }
                                                    onChange={({ value }) => {
                                                        handleOptionChange(
                                                            value,
                                                            options
                                                        );
                                                    }}
                                                    options={options}
                                                />
                                            )}
                                        </FormRow>
                                    );
                                })}
                            </>
                        );
                    })
                ) : (
                    <p>
                        {trans("There are no options available for this order")}
                    </p>
                )}
            </Column>
        </Row>
    );
};

export default withRouter(OrderFlowOptionsForm);
