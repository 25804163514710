import React, { useContext } from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    ShowMore,
} from "@cortexglobal/rla-components";

import FaqContext from "../FaqContext";

const CategoryGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
`;

const CategoryResult = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CategoryResultImageWrapper = styled(SearchResultImageWrapper)`
    cursor: pointer;
    flex-grow: 1;
`;

const CategoryResultButton = styled(SearchResultButton)`
    cursor: pointer;
    display: flex;
    height: auto;
    align-items: center;
    min-height: 4rem;
`;

export const FaqIndex = () => {
    const theme = useTheme();

    const { faqState, basepath, faqNavigate } = useContext(FaqContext);

    const { categories } = faqState;

    return (
        <>
            <CategoryGrid>
                {categories.map((category) => {
                    //Only show the section if it has questions
                    if (
                        !category.questions ||
                        category.questions.length === 0
                    ) {
                        <Row>
                            <Column>
                                <p>{trans("No FAQs currently available")}</p>
                            </Column>
                        </Row>;
                    }
                    return (
                        <CategoryResult key={`category-${category.uuid}`}>
                            <SearchResultWrapper textAlign="center" padding={0}>
                                <CategoryResultImageWrapper
                                    onClick={() =>
                                        faqNavigate(
                                            `${basepath}/${category.slug}`
                                        )
                                    }
                                >
                                    {
                                        <SearchResultImage
                                            src={category.thumbnail}
                                            alt={category.text}
                                        />
                                    }
                                </CategoryResultImageWrapper>
                                <CategoryResultButton
                                    onClick={() =>
                                        faqNavigate(
                                            `${basepath}/${category.slug}`
                                        )
                                    }
                                >
                                    <SearchResultTitle
                                        width={
                                            theme.search.icons.searchIcon !==
                                            null
                                                ? "90%"
                                                : "100%"
                                        }
                                    >
                                        <ShowMore
                                            style={{ lineBreak: "anywhere" }}
                                            type={"tooltip"}
                                            lines={2}
                                        >
                                            {category.text}
                                        </ShowMore>
                                    </SearchResultTitle>
                                </CategoryResultButton>
                            </SearchResultWrapper>
                        </CategoryResult>
                    );
                })}
            </CategoryGrid>
        </>
    );
};

export default FaqIndex;
