import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import FaqContext, { FaqFunctions } from "./FaqContext";
import FaqScreen from "./screens/FaqScreen";

export const FaqRouter = () => {
    const match = useRouteMatch();
    const { path } = useRouteMatch();
    const {
        faqState,
        dispatch,
        hideUnpublished,
        setHideUnpublished,
        basepath,
        setBasepath,
        faqNavigate,
        loadCategories,
        loadCategory,
        storeQuestion,
        updateQuestion,
        deleteQuestion,
    } = FaqFunctions();

    useEffect(() => {
        setBasepath(path);
    }, [path]);

    return (
        <FaqContext.Provider
            value={{
                faqState,
                dispatch,
                hideUnpublished,
                setHideUnpublished,
                basepath,
                setBasepath,
                faqNavigate,
                loadCategories,
                loadCategory,
                storeQuestion,
                updateQuestion,
                deleteQuestion,
            }}
        >
            <Switch>
                <Route
                    path={`${path}/:category?/:question?`}
                    component={FaqScreen}
                />
            </Switch>
        </FaqContext.Provider>
    );
};
