import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import axios from "axios";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import {
    Column,
    Loading,
    PageTitle,
    Table,
    Row,
    Modal,
    IconTextButton,
    Pagination,
} from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import PlanSummary from "../components/PlanSummary";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PlanApprovalCreateForm from "../forms/PlanApprovalCreateForm";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const getMyPlans = (page, perPage) => {
    return axios
        .get("/api/v1/planner/plan/my-plans", {
            params: {
                page,
                per_page: perPage,
            },
        })
        .then(({ data }) => data);
};

const MyPlans = ({ theme, user, match }) => {
    // const [myPlans, setMyPlans] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [deletePlan, setDeletePlan] = useState(null);
    const [createPlanModalVisible, setCreatePlanModalVisible] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const { can } = usePermissions();

    const queryClient = useQueryClient();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const {
        isLoading,
        error,
        data = {
            myPlans: [],
            meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 10,
                to: 1,
                total: 1,
            },
        },
        status,
        isFetching,
        isPreviousData,
    } = useQuery({
        queryKey: ["my-plans", page, perPage],
        queryFn: () => getMyPlans(page, perPage),

        keepPreviousData: true,
        staleTime: 5000,
    });

    // Prefetch the next page!
    useEffect(() => {
        if (
            !isPreviousData &&
            data?.meta?.current_page < data?.meta?.last_page
        ) {
            queryClient.prefetchQuery({
                queryKey: ["my-plans", page + 1, perPage],
                queryFn: () => getMyPlans(page + 1, perPage),
            });
        }
    }, [data, isPreviousData, page, queryClient]);

    const { data: myPlans, meta: pagination } = data;

    const planCanBeDeleted = (plan) => {
        //The plan can only be deleted if the user has the permission
        //to submit plans
        if (!can("planner-submit-plans")) {
            return false;
        }

        //If the plan has a results request, the user can only delete the plan
        //if the results request allow edits or the results have been approved (oddly, the client requested this)
        if (plan.results_request && plan.results_request.workflow) {
            return (
                plan.results_request.workflow.isComplete ||
                plan.results_request.workflow.allow_edits
            );
        }

        //If the plan has an approval request, the user can only delete the plan
        //if the approval request has not been submitted
        if (plan.approval_request && plan.approval_request.workflow) {
            return plan.approval_request.workflow.allow_edits;
        }

        return true;
    };

    return (
        <>
            <PageTitle title={trans("My Plan Approvals")} margin={0}>
                {can("planner-submit-plans") && (
                    <IconTextButton
                        rotation={180}
                        showCircle={false}
                        expanded={false}
                        icon={faPlus}
                        onClick={() => setCreatePlanModalVisible(true)}
                    >
                        {trans("Create a new Plan")}
                    </IconTextButton>
                )}
            </PageTitle>
            {isLoading ? (
                <Loading />
            ) : (
                <Row style={{ marginTop: "2rem" }}>
                    <Column>
                        {!myPlans || (myPlans && !myPlans.length) ? (
                            <p style={{ textAlign: "center", padding: "1rem" }}>
                                {trans("No items found")}
                            </p>
                        ) : (
                            <>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{trans("#")}</th>
                                            <th>{trans("Year")}</th>
                                            <th>{trans("Quarter")}</th>
                                            <th>{trans("Activities")}</th>
                                            <th>{trans("Planned Budget")}</th>
                                            <th>{trans("Submitted on")}</th>
                                            <th>{trans("Status")}</th>
                                            <th>{trans("Last Updated")}</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myPlans.map((plan) => {
                                            return (
                                                <tr key={plan.uuid}>
                                                    <td>
                                                        <Link
                                                            to={`${location.pathname}/${plan.uuid}`}
                                                        >
                                                            <>
                                                                {trans("#")}
                                                                {plan.ref}
                                                            </>
                                                        </Link>
                                                    </td>
                                                    <td>{plan.year}</td>
                                                    <td>
                                                        {trans("Q")}
                                                        {plan.period}
                                                    </td>
                                                    <td>
                                                        {plan.total_activities}
                                                    </td>
                                                    <td>
                                                        <FormattedCurrency
                                                            value={
                                                                plan
                                                                    .total_budget
                                                                    .value
                                                            }
                                                            currency={
                                                                user?.currency
                                                                    ?.iso_code
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        {format(
                                                            new Date(
                                                                plan.created
                                                            ),
                                                            "PP"
                                                        )}
                                                    </td>
                                                    <td>{plan.status}</td>
                                                    <td>
                                                        {format(
                                                            new Date(
                                                                plan.updated
                                                            ),
                                                            "PP"
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{ width: "30%" }}
                                                    >
                                                        <IconTextButton
                                                            onClick={
                                                                () =>
                                                                    history.push(
                                                                        `${location.pathname}/${plan.uuid}`
                                                                    )
                                                                // setSelectedPlan(plan) // This would trigger the old modal
                                                            }
                                                        >
                                                            {trans(
                                                                "View Plan Summary"
                                                            )}
                                                        </IconTextButton>

                                                        {planCanBeDeleted(
                                                            plan
                                                        ) && (
                                                            <IconTextButton
                                                                themeColor="alert"
                                                                icon={faTrash}
                                                                showCircle={
                                                                    false
                                                                }
                                                                onClick={() =>
                                                                    setDeletePlan(
                                                                        plan
                                                                    )
                                                                }
                                                            >
                                                                {trans(
                                                                    "Delete Plan"
                                                                )}
                                                            </IconTextButton>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                                {pagination && (
                                    <Pagination
                                        onPerPageChange={({ value }) => {
                                            setPerPage(value);
                                        }}
                                        style={{ clear: "both" }}
                                        currentPage={
                                            pagination.current_page - 1
                                        }
                                        perPage={pagination.per_page}
                                        pageCount={pagination.last_page}
                                        onPageChange={({ selected }) =>
                                            setPage(selected + 1)
                                        }
                                        {...pagination}
                                    />
                                )}
                            </>
                        )}
                    </Column>
                </Row>
            )}
            {createPlanModalVisible && (
                <Modal
                    visible={createPlanModalVisible}
                    onClose={() => setCreatePlanModalVisible(false)}
                >
                    <h2>{trans("Create a new Plan")}</h2>
                    <PlanApprovalCreateForm
                        user={user}
                        onComplete={(newPlan) => {
                            setCreatePlanModalVisible(false);
                            // Redirect to the new plan
                            history.push(
                                `${location.pathname}/${newPlan.uuid}`
                            );
                        }}
                        onCancel={() => {
                            setCreatePlanModalVisible(false);
                        }}
                    />
                </Modal>
            )}
            <Modal
                maxWidth="70%"
                visible={!!selectedPlan}
                onClose={() => setSelectedPlan(null)}
            >
                <Row collapse>
                    <Column>
                        <h2>{trans("Plan Summary")}</h2>
                    </Column>
                </Row>

                {selectedPlan && (
                    <PlanSummary planUuid={selectedPlan.uuid} user={user} />
                )}
            </Modal>
            <Modal
                maxWidth="70%"
                visible={!!deletePlan}
                onClose={() => setDeletePlan(null)}
            >
                <Row collapse>
                    <Column>
                        <h2>
                            {trans("Delete the {planRef} {planName} Plan", {
                                planRef: deletePlan?.ref,
                                planName: deletePlan?.name,
                            })}
                        </h2>
                        <p>
                            {trans(
                                "Deleting this plan will also delete all associated requests. After deleting you can recreate the plan if required."
                            )}
                        </p>
                    </Column>
                </Row>
                <Row collapse>
                    <Column
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconTextButton
                            style={{ marginRight: "1rem" }}
                            icon={faTimes}
                            showCircle={false}
                            onClick={() => {
                                setDeletePlan(null);
                            }}
                        >
                            {trans("Cancel")}
                        </IconTextButton>
                        <IconTextButton
                            themeColor="alert"
                            icon={faTrash}
                            showCircle={false}
                            onClick={() => {
                                axios
                                    .delete(
                                        `/api/v1/planner/plan/my-plans/${deletePlan.uuid}`
                                    )
                                    .then(() => {
                                        setDeletePlan(null);
                                        queryClient.invalidateQueries([
                                            "my-plans",
                                        ]);
                                    });
                            }}
                        >
                            {trans("Delete")}
                        </IconTextButton>
                    </Column>
                </Row>
            </Modal>
        </>
    );
};

export default MyPlans;
