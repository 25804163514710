import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
    Row,
    Column,
    Pagination,
    Button,
    StyledCheckbox,
    FormLabel,
    Tabordion,
    InputField,
    IconTextButton
} from "@cortexglobal/rla-components";

export const TabordionContentWrapper = styled.section`
    padding: 1rem 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;
export const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FlexRow = styled.section`
    display: flex;
    margin: 0.5rem 0;
    //flex-wrap: wrap;
`;

export const EditorColumn = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    background: ${props => props.theme.tabordion.background};
    padding: 0;
    width: 33%;
    min-width: 25rem;
    margin-right: 0.5rem;
`;
export const PreviewColumn = styled.aside`
    /* display: flex; */
    min-width: 25rem;
    flex-grow: 1;
    /* flex-direction: row; */
    align-items: center;
    background: ${({ theme }) => theme.asset.preview.background};
    color: ${({ theme }) => theme.asset.preview.color};
    padding: 0.5rem;
    position: relative;
    justify-content: center;
`;
export const PreviewContainer = styled.div`
    position: sticky;
    top: 0.5rem;
    margin: auto;
    text-align: center;
`;
