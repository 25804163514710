import React, { Component } from "react";
import PropTypes from "prop-types";
import RCTimePicker from "rc-time-picker";
import moment from "moment";

import "rc-time-picker/assets/index.css";

class StyledTimePicker extends Component {
    render() {
        const {
            label,
            name,
            value,
            onChange,
            timeFormat,
            error,
            ...rest
        } = this.props;
        const valueObj = moment.isMoment(value)
            ? value
            : moment(value, timeFormat);
        return (
            <RCTimePicker
                name={name}
                defaultValue={valueObj}
                value={valueObj}
                onChange={onChange}
                {...rest}
            />
        );
    }
}

StyledTimePicker.displayName = "StyledTimePicker";

StyledTimePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showSecond: PropTypes.bool,
    use12Hours: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    timeFormat: PropTypes.string
};

StyledTimePicker.defaultProps = {
    showSecond: false,
    error: "",
    value: moment(),
    timeFormat: "HH:mm"
};

export default StyledTimePicker;
