import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Row,
    Column,
    Loading,
    Centred,
    IconTextButton,
    InputField,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import styled from "styled-components";
import PanelWrapper from "./PanelWrapper";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Title = styled.h6`
    margin: 0.1em;
    font-size: 1em;
    color: ${({ theme }) => theme.colors.black};
`;

const Description = styled.p`
    color: ${({ theme }) => theme.colors.gray800};
    font-size: 0.8em;
    margin: 0.4em 0;
`;

const PanelListContainer = styled.ul`
    list-style: none;
    margin: 0 0;
    padding: 10px 0;
    max-height: 60vh;
    overflow: scroll;

    li {
        margin: 10px 0;
        background: ${({ theme }) => theme.colors.gray200};
        padding: 10px;

        button {
            font-size: 12px;
            margin: 0;
            padding: 0;
        }
    }
`;

const PanelList = ({ panels, choosePanel }) => {
    const intl = useIntl();
    const [localPanels, setLocalPanels] = useState([]);
    const [searchText, setSearchText] = useState("");
    useEffect(() => {
        setLocalPanels(
            panels.items.map((tempPanel) => {
                tempPanel.visible = true;
                return tempPanel;
            })
        );
    }, [panels]);

    const filterPanels = (searchString) => {
        setSearchText(searchString);
        const tempPanels = localPanels.map((tempPanel) => {
            tempPanel.visible =
                tempPanel.title.includes(searchString) ||
                tempPanel.description.includes(searchString);
            return tempPanel;
        });
        setLocalPanels(tempPanels);
    };

    if (!panels.loaded) {
        return (
            <div style={{ height: 200 }}>
                <Centred>
                    <Loading color="rgba(0,0,0,0.3)" height="auto" />
                </Centred>
            </div>
        );
    }

    return (
        <PanelWrapper>
            <Row>
                <Column>
                    <h3>{trans("Add Panel")}</h3>
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputField
                        value={searchText}
                        onChange={({ value }) => filterPanels(value)}
                        placeholder={intl.formatMessage({ id: "Search" })}
                    />
                </Column>
            </Row>

            <Row>
                <Column medium={12}>
                    <PanelListContainer>
                        {panels.items
                            .filter((panel) => {
                                return panel.visible;
                            })
                            .map((panel, i) => {
                                return (
                                    <li key={panel.slug}>
                                        <Row className="panel" key={i}>
                                            <Column medium={8}>
                                                <Title>{panel.title}</Title>
                                                <Description>
                                                    {panel.description}
                                                </Description>
                                            </Column>
                                            <Column
                                                medium={4}
                                                align={"right"}
                                                style={{ textAlign: "right" }}
                                            >
                                                <IconTextButton
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                    icon={faPlus}
                                                    showCircle={false}
                                                    expanded={false}
                                                    onClick={() =>
                                                        choosePanel(panel)
                                                    }
                                                >
                                                    {trans("Add To Dashboard")}
                                                </IconTextButton>
                                            </Column>
                                        </Row>
                                    </li>
                                );
                            })}
                    </PanelListContainer>
                </Column>
            </Row>
        </PanelWrapper>
    );
};

PanelList.propTypes = {
    panels: PropTypes.object.isRequired,
    choosePanel: PropTypes.func.isRequired,
};

export default PanelList;
