import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import axios from "axios";
import styled, { useTheme } from "styled-components";
import { get } from "lodash";

import Kalend, { CalendarView, OnEventDragFinish } from "kalend";
import "kalend/dist/styles/index.css";

import {
    PageTitle,
    useAddToast,
    Tabordion,
    Row,
    Error,
    Column,
    Button,
    SubmitButton,
    Panel,
    StyledRadio,
    HeaderWithErrorIndicated,
    useSectionErrors,
    Loading,
    Modal,
    DraftJSOutput,
    Table,
} from "@cortexglobal/rla-components";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";
import { format } from "@cortexglobal/cortex-utilities";

const ja = {
    common: {
        noEvents: "イベントなし",
    },
    buttons: {
        today: "本日",
        agenda: "アジェンダ",
        day: "日",
        threeDays: "3日間",
        week: "週",
        month: "月",
        showMore: "もっと表示",
        newEvent: "新しいイベント",
    },
    months: {
        january: "1月",
        february: "2月",
        march: "3月",
        april: "4月",
        may: "5月",
        june: "6月",
        july: "7月",
        august: "8月",
        september: "9月",
        october: "10月",
        november: "11月",
        december: "12月",
    },
    weekDays: {
        Mon: "月曜日",
        Tue: "火曜日",
        Wed: "水曜日",
        Thu: "木曜日",
        Fri: "金曜日",
        Sat: "土曜日",
        Sun: "日曜日",
    },
    weekShort: "週",
};

const locales = {
    ja,
};

const CalenderWrapper = styled.div`
    & * {
        font-family: inherit;
    }
    height: 100%;
    min-height: 540px;
`;

const EquipmentAvailability = ({ item, date: passedDate = null, settings }) => {
    // const [events, setEvents] = useState([]);
    const [eventModalVisible, setEventModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(false);
    const [availability, setAvailability] = useState([]);
    const globalTheme = useTheme();
    const { user } = useUser();
    console.log({ settings });
    useEffect(() => {
        loadAvailability(passedDate);
    }, []);

    const loadAvailability = (date) => {
        if (!date) {
            date = "";
        }
        axios
            .get(`/api/v1/events/inventory-item/${item.uuid}/availability`, {
                params: {
                    date: String(date),
                },
            })
            .then(({ data }) => {
                setAvailability(data.data);
            });
    };

    const onEventClick = (data) => {
        if (!data.bookingType) {
            return;
        }
        setSelectedEvent(data);
        setEventModalVisible(true);
    };

    // const onNewEventClick = (data) => {
    //     //todo
    // };

    const onStateChange = (data) => {
        if (data.selectedView === CalendarView.MONTH) {
            loadAvailability(data.selectedDate);
        }
    };

    const language = user.language;

    const formattedAvailability = availability.map((item) => {
        const bookingType = settings?.bookingTypes[item.booking_type];
        return {
            ...item,
            color: bookingType.color || globalTheme.colors.primary,
            summary: bookingType.text,
            bookingType,
        };
    });
    console.log({ selectedEvent });
    return (
        <>
            {eventModalVisible && (
                <Modal
                    visible={eventModalVisible}
                    onClose={() => setEventModalVisible(false)}
                >
                    <Row>
                        <Column>
                            <h2>
                                {format(new Date(selectedEvent.startAt))} -{" "}
                                {format(new Date(selectedEvent.endAt))}
                            </h2>
                        </Column>
                        <Column>
                            <h3>
                                {trans("Booking type: {bookingTypeName}", {
                                    bookingTypeName:
                                        selectedEvent.bookingType.text,
                                })}
                            </h3>
                        </Column>
                    </Row>
                    {selectedEvent.event && (
                        <Row style={{ marginTop: "1.2rem" }}>
                            <Column>
                                <h3>{trans("Event Details")}</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th width="30%">{trans("Name")}</th>
                                            <td>{selectedEvent.event.name}</td>
                                        </tr>
                                        {selectedEvent.event.description && (
                                            <tr>
                                                <th>{trans("Description")}</th>
                                                <td>
                                                    {
                                                        selectedEvent.event
                                                            .description
                                                    }
                                                </td>
                                            </tr>
                                        )}

                                        {selectedEvent.event.group && (
                                            <>
                                                <tr>
                                                    <th>
                                                        {trans("Booked by")}
                                                    </th>
                                                    <td>
                                                        {
                                                            selectedEvent.event
                                                                .group.name
                                                        }{" "}
                                                        (
                                                        {
                                                            selectedEvent.event
                                                                .createdBy
                                                        }
                                                        )
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        {trans("Booked on")}
                                                    </th>
                                                    <td>
                                                        {format(
                                                            new Date(
                                                                selectedEvent.event.created
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <th>
                                                <span>{trans("Venue")}:</span>
                                            </th>
                                            <td>
                                                <span>
                                                    {
                                                        selectedEvent.event
                                                            .venue_name
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{trans("Address")}:</span>
                                            </th>
                                            <td>
                                                <span>
                                                    {[
                                                        selectedEvent.event
                                                            .address_building,
                                                        selectedEvent.event
                                                            .address_street,
                                                        selectedEvent.event
                                                            .address_neighbourhood,
                                                        selectedEvent.event
                                                            .address_suburb,
                                                        selectedEvent.event
                                                            .address_city,
                                                        selectedEvent.event
                                                            .address_state,
                                                        selectedEvent.event
                                                            .postcode,
                                                        selectedEvent.event
                                                            .country,
                                                    ]
                                                        .filter(
                                                            (a) =>
                                                                a &&
                                                                a.length > 1
                                                        )
                                                        .join(", ")}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{trans("Indoors")}:</span>
                                            </th>
                                            <td>
                                                <span>
                                                    {selectedEvent.event.indoor
                                                        ? trans("Yes")
                                                        : trans("No")}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Column>
                        </Row>
                    )}
                </Modal>
            )}

            <Row style={{ height: "545px" }}>
                <CalenderWrapper>
                    <style>
                        {`
                            .Kalend__CalendarHeader, .Kalend__CalendarHeader-dark, .Kalend__CalendarHeader-tall, .Kalend__CalendarHeader-tall-dark, .Kalend__CalendarHeader-small, .Kalend__CalendarHeader-day, .Kalend__CalendarHeader-tall-day {
                                margin-bottom: 0.5rem;
                            }
                            .Kalend__header_calendar_button-text-selected, .Kalend__header_calendar_button-text-selected-dark {
                                color: ${globalTheme.colors.accent} !important;
                            }
                            .Kalend__Event-month {
                                padding: 10px 1px;
                            }
                        `}
                    </style>

                    <Kalend
                        // kalendRef={props.kalendRef}
                        onNewEventClick={() => {}}
                        initialView={CalendarView.MONTH}
                        disabledViews={[
                            CalendarView.DAY,
                            CalendarView.WEEK,
                            CalendarView.THREE_DAYS,
                            CalendarView.AGENDA,
                        ]}
                        onEventClick={onEventClick}
                        events={formattedAvailability}
                        initialDate={
                            passedDate
                                ? passedDate.toISOString()
                                : new Date().toISOString()
                        }
                        hourHeight={60}
                        showWeekNumbers={false}
                        timezone={"Europe/Berlin"}
                        onEventDragFinish={OnEventDragFinish}
                        showTimeLine={true}
                        isDark={false}
                        autoScroll={true}
                        onStateChange={onStateChange}
                        disabledDragging={true}
                        style={{
                            primaryColor: globalTheme.colors.primary,
                            baseColor: globalTheme.colors.secondary,
                            inverseBaseColor: globalTheme.colors.primary,
                        }}
                        colors={{
                            light: {
                                primaryColor: globalTheme.colors.accent,
                            },
                        }}
                        language={language}
                        customLanguage={locales[language]}
                    />
                </CalenderWrapper>
                <Column style={{ marginTop: "1.2rem" }}>
                    <h3>{trans("Legend")}</h3>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                        }}
                    >
                        {Object.keys(settings?.bookingTypes || {}).map(
                            (key) => {
                                const bookingType = settings?.bookingTypes[key];
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "0.5rem",
                                            marginRight: "1rem",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "1rem",
                                                height: "1rem",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                    bookingType.color,
                                                marginRight: "0.5rem",
                                            }}
                                        />
                                        {bookingType.text}
                                    </div>
                                );
                            }
                        )}
                    </div>
                </Column>
            </Row>
        </>
    );
};

export default EquipmentAvailability;
