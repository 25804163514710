import React from "react";

import { Centred, Loading, TableList } from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import { trans } from "@cortexglobal/rla-intl";

const AssetDownloadsListingOutput = ({ panelId, filterBy, perPage = 10 }) => {
    const panelData = usePanelData(panelId, `/api/v1/assets-usage`, {
        filter_by: filterBy,
        per_page: perPage
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }

    return (
        <div>
            <TableList className="dashboard-table">
                <thead>
                    {filterBy === "downloads-by-business" && (
                        <tr>
                            <th>{trans("Business Name")}</th>
                            <th>{trans("Downloads")}</th>
                        </tr>
                    )}
                    {filterBy === "most-downloaded" && (
                        <tr>
                            <th>{trans("Pos")}</th>
                            <th>{trans("Asset Name")}</th>
                            <th>{trans("Asset Type")}</th>
                            <th>{trans("Downloads")}</th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {panelData.data ? (
                        panelData.data.map((item, index) => {
                            if (filterBy === "downloads-by-business") {
                                return (
                                    <tr>
                                        <td>{item.name}</td>

                                        <td>{item.downloads}</td>
                                    </tr>
                                );
                            }
                            if (filterBy === "most-downloaded") {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>

                                        <td>{item.media_type}</td>
                                        <td>{item.downloads}</td>
                                    </tr>
                                );
                            }
                        })
                    ) : (
                        <Loading align="center" padding="1rem" />
                    )}
                </tbody>
            </TableList>
        </div>
    );
};

export default AssetDownloadsListingOutput;
