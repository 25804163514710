import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    IconTextButton,
    PageTitle,
    InputField,
    useAddToast
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { WorkflowTable } from "./WorkflowTable";

const loadItems = (setItems, page, search, orderBy, orderByDir, perPage) => {
    setItems({
        loaded: false
    });
    axios
        .get(`/api/v1/admin/workflow`, {
            params: {
                page: page,
                search: search ? search : null,
                sortDirection: orderByDir ? orderByDir : null,
                sortField: orderBy ? orderBy : null,
                perPage: perPage ? perPage : 10
            }
        })
        .then(({ data }) => {
            // console.log(data);
            setItems({
                loaded: true,
                data: data.data,
                pagination: data.meta
            });
        })
        .catch();
};
const deleteItem = (
    uuid,
    setItems,
    page = 1,
    search,
    orderBy,
    orderByDirection,
    perPage,
    addToast
) => {
    return axios
        .delete(`/api/v1/admin/workflow/${uuid}`)
        .then(({ data }) => {
            // console.log(data);
            loadItems(
                setItems,
                page,
                search,
                orderBy,
                orderByDirection,
                perPage
            );
        })
        .catch(e => {
            addToast({
                type: "alert",
                content:
                    e.response && e.response.data
                        ? e.response.data.message
                        : trans("An error has occurred"),
                showFor: 5000
            });
            throw e;
        });
};
export const WorkflowListing = ({ path }) => {
    const history = useHistory();
    const page = useQueryString().get("page") || 1;
    const searchParam = useQueryString().get("search") || "";
    const orderByParam = useQueryString().get("orderBy") || "";
    const dirParam = useQueryString().get("dir") || "ASC";
    const perPageParam = useQueryString().get("perPage") || 10;

    const [searchInput, setSearchInput] = useState(searchParam);
    const [search, setSearch] = useState(searchParam);

    const [orderBy, setOrderBy] = useState(orderByParam);
    const [orderByDirection, setoOrderByDirection] = useState(dirParam);

    const addToast = useAddToast();

    //@ts-ignore cortex
    const [items, setItems] = useState({
        loaded: false,
        data: [],
        pagination: null
    });

    useEffect(() => {
        loadItems(
            setItems,
            page,
            search,
            orderBy,
            orderByDirection,
            perPageParam
        );
    }, [setItems]);

    const onSearch = () => {
        if (searchInput.length) {
            const orderByValue = orderBy
                ? `&orderBy=${orderBy}&dir=${orderByDirection}`
                : "";

            setSearch(searchInput);
            history.push(`?page=${page}&search=${searchInput}${orderByValue}`);
            loadItems(
                setItems,
                page,
                searchInput,
                orderBy,
                orderByDirection,
                perPageParam
            );
        }
    };

    const onOrderBy = data => {
        let orderDir = orderByDirection;

        if (orderBy === data) {
            setoOrderByDirection(orderByDirection === "ASC" ? "DESC" : "ASC");
            orderDir = orderByDirection === "ASC" ? "DESC" : "ASC";
        } else {
            setOrderBy(data);
            setoOrderByDirection("ASC");
            orderDir = "ASC";
        }

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(
            `?page=${page}&orderBy=${data}&dir=${orderDir}${searchValue}${perPageValue}`
        );
        loadItems(setItems, page, search, data, orderDir, perPageParam);
    };

    const onClearSearch = () => {
        setSearch("");
        setSearchInput("");

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";

        history.push(`?page=${page}${orderByValue}${perPageValue}`);
        loadItems(
            setItems,
            page,
            search,
            orderBy,
            orderByDirection,
            perPageParam
        );
    };

    const onPerPageChange = ({ value }: { value: string }) => {
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(`?perPage=${value}${orderByValue}${searchValue}`);
        loadItems(setItems, page, search, orderBy, orderByDirection, value);
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Workflows")}{" "}
                        {/* <HeaderEmphasis>{assetAdmin.asset.name}</HeaderEmphasis> */}
                    </React.Fragment>
                }
            >
                <InputField
                    style={{ width: "300px", marginBottom: "0" }}
                    value={searchInput}
                    onChange={item => setSearchInput(item.value)}
                />
                <IconTextButton
                    onClick={onSearch}
                    showCircle={false}
                    icon={faSearch}
                ></IconTextButton>
                {search && (
                    <IconTextButton
                        onClick={onClearSearch}
                        showCircle={false}
                        icon={faTimes}
                    >
                        {trans("Clear search")}
                    </IconTextButton>
                )}
                <IconTextButton
                    as={Link}
                    to={`${path}/create`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    icon={faPlus}
                >
                    {trans("Create new workflow")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <WorkflowTable
                    onPerPageChange={onPerPageChange}
                    onOrderBy={onOrderBy}
                    items={items.data}
                    loaded={items.loaded}
                    path={path}
                    pagination={items.pagination}
                    onPageChange={selected => {
                        history.push(`?page=${selected}&search=${search}`);
                        loadItems(
                            setItems,
                            selected,
                            search,
                            orderBy,
                            orderByDirection,
                            perPageParam
                        );
                    }}
                    deleteItem={uuid =>
                        deleteItem(
                            uuid,
                            setItems,
                            items.pagination.current_page,
                            search,
                            orderBy,
                            orderByDirection,
                            perPageParam,
                            addToast
                        )
                    }
                />
            </Row>
        </div>
    );
};

export default WorkflowListing;
