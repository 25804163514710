import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { get } from "lodash";

import {
    PageTitle,
    Loading,
    IconTextButton,
    useAddToast,
    Error,
    Badge,
} from "@cortexglobal/rla-components";
import EventSummary from "../planner/components/EventSummary";
import { trans, useIntl } from "@cortexglobal/rla-intl";

import EventObject from "../objects/EventObject";

const Event = ({ plannerPath, settings }) => {
    const addToast = useAddToast();
    const intl = useIntl();

    const [workflowLoadingState, setWorkflowLoadingState] = useState("loading");
    const [loadingState, setLoadingState] = useState("loading");
    const [event, setEvent] = useState({});

    const { path } = useRouteMatch();
    const match = useRouteMatch(path);

    useEffect(() => {
        const uuid = get(match, "params.uuid");
        getEvent(uuid);
    }, []);

    const getEvent = (uuid) => {
        setLoadingState("loading");

        axios
            .get(`/api/v1/events/${uuid}`)
            .then(({ data }) => {
                const eventData = data.data;

                setEvent(new EventObject(eventData));

                setLoadingState("loaded");
                setWorkflowLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });

        return;
    };

    const workflowUpdatedCallback = (smartRef, newWorkflow) => {
        let newEvent = event.setWorkflow(newWorkflow);

        setEvent(new EventObject({ ...newEvent }));

        setWorkflowLoadingState("loaded");

        addToast({
            type: "success",
            content: trans("Event status successfully changed"),
            showFor: 5000,
        });
    };

    const workflowLoadingCallback = (smartRef) => {
        setWorkflowLoadingState("loading");
    };

    const render = (loading) => {
        if (loading === "loading") {
            return <Loading />;
        }

        if (loading === "loaded") {
            return (
                <EventSummary
                    event={event}
                    getEvent={getEvent}
                    setEvent={setEvent}
                    loading={loading}
                    settings={settings}
                    workflowLoadingCallback={workflowLoadingCallback}
                    workflowUpdatedCallback={workflowUpdatedCallback}
                    workflowLoading={workflowLoadingState}
                    plannerPath={plannerPath}
                />
            );
        }

        return (
            <Error>
                {trans(
                    "An error occurred loading this event, please try later"
                )}
            </Error>
        );
    };

    return (
        <div id="event" style={{ marginBottom: "20px" }}>
            <Helmet>
                <title>
                    {`${process.env.REACT_APP_NAME} - ${intl.formatMessage({
                        id: "Events",
                    })} - ${
                        loadingState === "loaded" && event && event.name
                            ? event.name
                            : ""
                    }`}
                </title>
            </Helmet>
            <PageTitle
                title={
                    loadingState === "loaded"
                        ? trans("Event: {name}", { name: event.name })
                        : trans("Event")
                }
            >
                {loadingState === "loaded" && event?.workflowable?.status && (
                    <Badge margin={0}>{event?.workflowable?.status}</Badge>
                )}

                <IconTextButton
                    as={Link}
                    to="/events"
                    rotation={180}
                    showCircle={false}
                >
                    {trans("Back to Events")}
                </IconTextButton>
            </PageTitle>
            {render(loadingState)}
        </div>
    );
};

export default Event;
