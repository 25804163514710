import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import {
    GreedyCarousel,
    CarouselItem,
    Centred,
    Loading,
    Panel,
    Row,
    Column,
    IconTextButton,
    CircularProgress,
} from "@cortexglobal/rla-components";
import { withTheme } from "styled-components";
import usePanelData from "../usePanelData";
import { isSet } from "lodash";

const FlexPanel = styled(Panel)`
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    margin-bottom: 0;
    box-shadow: none;
    padding-top: 0;
    box-sizing: content-box;
    flex-wrap: wrap;
`;

const KpiColumn = styled(Panel)`
    /* padding: 2rem; */
    text-align: center;
    min-width: 50%;
    box-shadow: none;
    padding-bottom: 0.2rem;
    padding-top: 0.5rem;
    margin-bottom: 0;
`;

const KpiColumnHeading = styled.h3`
    margin-top: 1.5rem;
`;

const MarketingKpiVsBudgetOutput = ({
    panelId,
    viewBy,
    period,
    startDate,
    endDate,
    theme,
}) => {
    // const panelData = usePanelData(
    //     panelId,
    //     `/api/v1/marketing-kpi/detail/summary`,
    //     { viewBy, period, startDate, endDate }
    // );
    const panelData = usePanelData(
        panelId,
        `/api/v1/marketing-kpi/detail/summary`,
        { viewBy, period, startDate, endDate }
    );

    if (!panelData || !panelData.loaded) {
        return (
            <Centred style={{ width: "100px" }}>
                <Loading type="spin" align="center" height="50" width="50" />
            </Centred>
        );
    }

    const { data } = panelData;

    const getViewByName = (viewBy) => {
        if (viewBy == "quarter") {
            return trans("Quarter");
        } else if (viewBy == "year") {
            return trans("Year");
        } else if (viewBy == "specific") {
            return trans("Custom Date Range");
        }
    };

    const getPeriodLabel = () => {
        if (data.period_label) {
            return data.period_label;
        }
        return startDate + " - " + endDate;
    };

    const getStrokeColor = (percentage) => {
        if (percentage > 85) {
            return theme.colors.success;
        } else if (percentage > 60) {
            return theme.colors.warning;
        }
        return theme.colors.alert;
    };

    const generateAveragePercentage = (metricData) => {
        const total = metricData.reduce((acc, item) => {
            if (item.percentage > 100) {
                return acc + 100;
            }
            return acc + item.percentage;
        }, 0);
        return Math.round(
            total / metricData.filter((metric) => metric.percentage > 0).length
        );
    };

    const averagePercentage = generateAveragePercentage(data.metrics) || 0;
    let budgetPercentage = 0;
    if (data.budget && data.budget.planned) {
        budgetPercentage = Math.round(
            (data.budget.actual / data.budget.planned) * 100
        );
    }
    return (
        <>
            <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
                <p style={{ paddingLeft: "1rem", width: "50%" }}>
                    <strong>{trans("Period")}:</strong> {getPeriodLabel()} (
                    {getViewByName(viewBy)})
                </p>
                <p
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        width: "auto",
                        textAlign: "right",
                        marginLeft: "auto",
                    }}
                >
                    <IconTextButton as={Link} to="/plan" expanded>
                        {trans("Go To Marketing Planner")}
                    </IconTextButton>
                </p>
            </div>

            <div style={{ flexBasis: "100%", height: 0 }} />
            <FlexPanel>
                <KpiColumn>
                    <CircularProgress
                        backgroundPadding={5}
                        strokeWidth={12}
                        value={averagePercentage}
                        initialAnimation={true}
                        strokeColor={getStrokeColor(averagePercentage)}
                        text={averagePercentage + "%"}
                    />
                    <KpiColumnHeading>
                        {trans("Marketing KPI Summary")}:
                    </KpiColumnHeading>
                    <p>
                        {trans("Total Marketing KPI Achievement (all metrics)")}
                    </p>
                </KpiColumn>
                <KpiColumn>
                    <CircularProgress
                        backgroundPadding={5}
                        strokeWidth={12}
                        value={budgetPercentage}
                        initialAnimation={true}
                        strokeColor={getStrokeColor(budgetPercentage)}
                        text={budgetPercentage + "%"}
                    />
                    <KpiColumnHeading>{trans("Budget Spend")}</KpiColumnHeading>
                    <p>{trans("Total Budget Spend (planned vs actual)")}</p>
                </KpiColumn>
            </FlexPanel>
        </>
    );
};

export default withTheme(MarketingKpiVsBudgetOutput);
