import React, { Component } from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { LegendItem } from "@cortexglobal/rla-components";
class Legend extends Component {
    isHeadOffice = (currentAlias, config) => {
        if (currentAlias == config.data.options.base_group_alias) {
            return true;
        }
        return false;
    };

    render() {
        const { colors, config, user, group } = this.props;
        const currentGroupAlias = group ? group.alias : null;

        return (
            <React.Fragment>
                <LegendItem
                    label={trans("My Plan")}
                    color={colors.task["self"]}
                />
                <LegendItem
                    label={trans("Head Office")}
                    color={colors.task["head-office"]}
                />

                <LegendItem
                    label={trans("Dealer Group")}
                    color={colors.task["group-container"]}
                />

                {group.groupType !== "Merchant" && (
                    <LegendItem
                        label={trans("Dealer")}
                        color={colors.task["merchant"]}
                    />
                )}

                <LegendItem
                    label={trans("Other")}
                    color={colors.task["other"]}
                />
            </React.Fragment>
        );
    }
}

export default Legend;
