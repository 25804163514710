import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import CustomFormListing from "./CustomFormListing";
import CustomFormCreate from "./CustomFormCreate";
import { CustomFormEdit } from "./CustomFormEdit";

export const CustomFormAdmin = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <CustomFormListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <CustomFormCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <CustomFormEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default CustomFormAdmin;
