import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    useAddToast,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import FeaturedItemForm from "../../forms/FeaturedItemForm";
import { HeaderEmphasis } from "../../components/HeaderEmphasis";

export const FeaturedItemEdit = ({ uuid, path }) => {
    const history = useHistory();
    const addToast = useAddToast();

    const [item, setItem] = useState({ loaded: false, data: null });
    const getItem = () => {
        axios.get(`/api/v1/admin/featured-item/${uuid}`).then(({ data }) => {
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, [uuid]);

    const submitForm = (values, setSubmitting) => {
        return axios
            .post(`/api/v1/admin/featured-item/${uuid}`, values)
            .then(({ data }) => {
                addToast({
                    content: trans("Featured Item updated successfully"),
                    type: "success",
                    showFor: 3000,
                });
                setItem({ loaded: true, data: data.data });
                setSubmitting(false);
                //As creation was successful move them on to the edit page
                // history.push(`${path}/edit/${uuid}`);
            });
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Edit Featured Item")}{" "}
                        <HeaderEmphasis>
                            {item.data ? item.data.title : ""}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            {item.loaded ? (
                <FeaturedItemForm item={item.data} submitForm={submitForm} />
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default FeaturedItemEdit;
