import React, { useContext, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Row,
    Column,
    Panel,
    Image,
    IconTextButton,
    LoadingIconSmall,
    Badge,
    FileIcon,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    SearchResultSummary,
    SearchResultBadge,
    ShowMore,
    ButtonDropdown,
    useAddToast,
    ContextMenu,
    StyledCheckbox,
    PlainButton,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import AssetContext from "../AssetContext";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";

import ShareModal from "../components/ShareModal";
import DownloadModal from "../components/DownloadModal";
import PrintOrderModal from "./Print/PrintOrderModalBulkPricing";
import BulkEditModal from "./BulkEditModal";
import MoveAssetModal from "../components/MoveAssetModal";
import MoveFavouriteModal from "./MoveFavouriteModal";

const SearchResultRow = styled.div`
    position: relative;
    background: ${(props) => props.theme.card.background};
    padding: 0.5em;
    margin-bottom: 1em;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: rgba(0, 18, 27, 0.1) 0px 1px 4px;
    transition: all 0.2s;

    &:hover {
        box-shadow: rgba(0, 18, 27, 0.2) 0px 2px 6px;
    }
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

const StyledSearchResultBadge = styled(SearchResultBadge)`
    position: static;
    display: inline;
    border-radius: 3px;
`;

const FavoriteStar = styled.div`
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 7px;
`;

const AssetResultItem = (props) => {
    const {
        loaded,
        asset,
        canEdit,
        match: { path },
        onClick = null,
        maxColumns = 3,
        selectMode,
        isSelected,
        maximumAssetsSelected,
        useContextMenu = false,
        view,
        toggleSelectedAsset,
        filteredFilters,
        showStatus = false,
        customRowHeadings,
        ...rest
    } = props;
    const history = useHistory();
    const intl = useIntl();
    const { can } = usePermissions();
    const [actions, setActions] = useState([]);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [showOrderPrintModal, setShowOrderPrintModal] = useState(false);
    const [showBulkEditModal, setShowBulkEditModal] = useState(false);
    const [showMoveAssetModal, setShowMoveAssetModal] = useState(false);
    const [showMoveFavouriteModal, setShowMoveFavouriteModal] = useState(false);
    const {
        config,
        assetsPath,
        addFavorite,
        removeFavorite,
        userAssetPath,
        user,
        group,
        setRefreshSearch,
    } = useContext(AssetContext);

    const addToast = useAddToast();

    useEffect(() => {
        generateActions();
    }, [asset]);

    const getImageSrc = () => {
        let imageSrc = `${asset.thumbnail}`;
        return imageSrc;
    };

    const viewAsset = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (!loaded) {
            return false;
        }
        props.selectAsset(asset);
        props.history.push(`${assetsPath}/view/${asset.uuid}`);
    };

    const editAsset = () => {
        props.history.push(`${assetsPath}/${asset.uuid}/edit`);
    };

    const adminAsset = () => {
        props.history.push(`${assetsPath}/${asset.uuid}/admin`);
    };

    const createUserAsset = () => {
        history.push(`${userAssetPath}/create/${asset.uuid}`);
    };

    const generateActions = () => {
        if (useContextMenu === false) {
            return [];
        }
        const printOrderEnabled =
            !!asset.printable && !asset.editable && user.canPrint;
        let actions = [
            {
                name: intl.formatMessage({
                    id: "Open in new tab",
                }),
                onClick: () => {
                    window.open(`${assetsPath}/view/${asset.uuid}`, "_blank");
                },
            },
            {
                name: asset.isFavorite
                    ? intl.formatMessage({
                          id: "Unfavorite",
                      })
                    : intl.formatMessage({
                          id: "Favorite",
                      }),
                onClick: asset.isFavorite ? unfavoriteAsset : favoriteAsset,
            },
        ];

        if (assetsPath && asset.userCanManage) {
            actions.push({
                name: intl.formatMessage({
                    id: "Admin Asset",
                }),
                onClick: adminAsset,
            });
        } else if (assetsPath && asset.editable && can("dam-asset-edit")) {
            actions.push({
                name: intl.formatMessage({
                    id: "Edit Asset",
                }),
                onClick: editAsset,
            });
        }

        if (assetsPath && asset.editable && can("dam-asset-use")) {
            actions.push({
                name: intl.formatMessage({
                    id: "Use this asset",
                }),
                onClick: createUserAsset,
            });
        }

        if (
            group &&
            group.children &&
            group.children.length > 0 &&
            asset.multi_group_editable &&
            can("dam-asset-use")
        ) {
            actions.push({
                name: intl.formatMessage({
                    id: "Bulk personalise asset",
                }),
                onClick: () => setShowBulkEditModal(true),
            });
        }

        if (config && (asset.downloadable || asset.pdfable)) {
            actions.push({
                name: intl.formatMessage({
                    id: "Download",
                }),
                onClick: () => setShowDownloadModal(true),
            });
        }
        if (printOrderEnabled) {
            actions.push({
                name: intl.formatMessage({
                    id: "Order Print",
                }),
                onClick: () => setShowOrderPrintModal(true),
            });
        }
        if (
            assetsPath &&
            typeof config !== "undefined" &&
            typeof config.use_folder_browser !== "undefined" &&
            config.use_folder_browser !== "never"
        ) {
            actions.push({
                name: intl.formatMessage({
                    id: "Move Asset",
                }),
                onClick: () => setShowMoveAssetModal(true),
            });
        }

        setActions(actions);
    };

    const favoriteAsset = () => {
        config.options.multiple_favourites_enabled
            ? setShowMoveFavouriteModal(true)
            : addFavorite([asset.uuid]).then(() => {
                  addToast({
                      type: "success",
                      content: intl.formatMessage({
                          id: "Favourite added",
                      }),
                      showFor: 5000,
                  });
              });
    };

    const unfavoriteAsset = () => {
        removeFavorite([asset.uuid]).then(() => {
            addToast({
                type: "success",
                content: intl.formatMessage({
                    id: "Favourite removed",
                }),
                showFor: 5000,
            });
        });
    };

    //Let a passed in click handler override the viewAsset action
    const onResultClick = (event, asset) => {
        if (selectMode) {
            event.preventDefault();
        }
        return onClick ? onClick(event, asset) : viewAsset(event);
    };

    const selectedStyle = selectMode && !isSelected ? { opacity: "0.5" } : {};
    const maximumAssetsSelectedStyle = isSelected
        ? {}
        : maximumAssetsSelected
        ? { cursor: "not-allowed" }
        : {};

    const handleAssetSelected = (uuid) => {
        if (!maximumAssetsSelected) {
            toggleSelectedAsset(uuid);
        }
    };

    const customRows =
        asset.label && asset.label.subHeading
            ? Object.values(asset.label.subHeading)
            : [];

    return (
        <>
            {view === "list" ? (
                <tr>
                    {selectMode && (
                        <td>
                            <StyledCheckbox
                                onChange={() => handleAssetSelected(asset.uuid)}
                                checked={isSelected}
                                style={{
                                    margin: "0",
                                    paddingRight: "0",
                                }}
                            />
                        </td>
                    )}

                    <td>
                        <PlainButton onClick={viewAsset}>
                            {asset.name}
                        </PlainButton>
                    </td>

                    {customRowHeadings.map((customRow) => {
                        return (
                            <td>{asset.label?.subHeading[customRow] || ""}</td>
                        );
                    })}

                    <td>
                        <ButtonDropdown
                            hidePrimaryAction
                            // onClick={viewAsset}
                            name="View Asset"
                            actions={[
                                {
                                    name: intl.formatMessage({
                                        id: "View Asset",
                                    }),
                                    onClick: viewAsset,
                                },
                                ...actions,
                            ]}
                            buttonstyle={{ textAlign: "left" }}
                        />
                    </td>
                </tr>
            ) : (
                <Column
                    small={12}
                    medium={4}
                    large={3}
                    xlarge={maxColumns}
                    {...rest}
                    style={{ marginBottom: "1rem", height: "360px" }}
                >
                    <ContextMenu actions={actions}>
                        <SearchResultWrapper
                            style={selectedStyle}
                            textAlign="center"
                            padding={0}
                        >
                            <Link
                                to={`${assetsPath}/view/${asset.uuid}`}
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    onResultClick(event, asset);
                                }}
                            >
                                <SearchResultImageWrapper
                                    style={maximumAssetsSelectedStyle}
                                >
                                    {loaded ? (
                                        asset.thumbnail === null ? (
                                            <FileIcon
                                                colorBehind={
                                                    props.theme.search.image
                                                        .background
                                                }
                                            >
                                                {asset.main_file_extension}
                                            </FileIcon>
                                        ) : (
                                            <SearchResultImage
                                                src={getImageSrc()}
                                                alt={asset.name}
                                            />
                                        )
                                    ) : (
                                        <LoadingIconSmall></LoadingIconSmall>
                                    )}
                                </SearchResultImageWrapper>

                                <SearchResultBadge
                                    badgeType={asset.status}
                                    rounded={false}
                                    showBadge={showStatus}
                                    type="secondary"
                                >
                                    {asset.status_display}
                                </SearchResultBadge>

                                {asset.isFavorite && (
                                    <FontAwesomeIcon
                                        style={{
                                            position: "absolute",
                                            top: "5",
                                            left: "5",
                                        }}
                                        icon={faStar}
                                        color="gold"
                                    />
                                )}
                            </Link>

                            <SearchResultButton
                                style={maximumAssetsSelectedStyle}
                                onClick={(event) => {
                                    if (
                                        onResultClick &&
                                        typeof onResultClick === "function"
                                    ) {
                                        onResultClick(event, asset);
                                    }
                                }}
                                showCircle={rest.theme.search.icons.showCircle}
                                iconBefore={false}
                                doHover={false}
                                icon={rest.theme.search.icons.searchIcon}
                                iconColor={rest.theme.search.icons.color}
                                iconSize={rest.theme.search.icons.size}
                                expanded={true}
                            >
                                <SearchResultTitle
                                    width={
                                        rest.theme.search.icons.searchIcon !==
                                        null
                                            ? "90%"
                                            : "100%"
                                    }
                                >
                                    <ShowMore
                                        style={{ lineBreak: "anywhere" }}
                                        type={"tooltip"}
                                        lines={2}
                                    >
                                        {loaded && asset.label.heading}
                                    </ShowMore>
                                </SearchResultTitle>
                                <SearchResultSummary
                                    width={
                                        rest.theme.search.icons.searchIcon !==
                                        null
                                            ? "90%"
                                            : "100%"
                                    }
                                    title={asset.label.heading}
                                >
                                    {asset.label.subHeading.constructor.name ==
                                    "Object"
                                        ? Object.keys(
                                              asset.label.subHeading
                                          ).map((key, index) => {
                                              return (
                                                  <React.Fragment
                                                      key={
                                                          key +
                                                          asset.label
                                                              .subHeading[key]
                                                      }
                                                  >
                                                      {!key &&
                                                          asset.label
                                                              .subHeading[key]}

                                                      {key &&
                                                          key +
                                                              ": " +
                                                              asset.label
                                                                  .subHeading[
                                                                  key
                                                              ]}
                                                      <br />
                                                  </React.Fragment>
                                              );
                                          })
                                        : asset.label.subHeading}
                                </SearchResultSummary>
                            </SearchResultButton>
                        </SearchResultWrapper>
                    </ContextMenu>
                </Column>
            )}
            {showShareModal && (
                <ShareModal
                    onClose={() => setShowShareModal(false)}
                    smartRef={asset.smartRef}
                    visible={showShareModal}
                />
            )}
            {showDownloadModal && (
                <DownloadModal
                    messages={config.messages}
                    configLoaded={true}
                    onClose={() => setShowDownloadModal(false)}
                    asset={asset}
                    visible={showDownloadModal}
                />
            )}
            {showOrderPrintModal && (
                <PrintOrderModal
                    onClose={() => setShowOrderPrintModal(false)}
                    asset={asset}
                    visible={showOrderPrintModal}
                />
            )}
            {showBulkEditModal && (
                <BulkEditModal
                    group={group}
                    onClose={() => setShowBulkEditModal(false)}
                    asset={asset}
                    visible={showBulkEditModal}
                />
            )}
            {showMoveAssetModal && (
                <MoveAssetModal
                    onClose={() => setShowMoveAssetModal(false)}
                    asset={asset}
                    visible={showMoveAssetModal}
                    setRefreshSearch={setRefreshSearch}
                />
            )}
            {showMoveFavouriteModal && (
                <MoveFavouriteModal
                    onClose={() => setShowMoveFavouriteModal(false)}
                    asset={asset}
                    visible={showMoveFavouriteModal}
                    setRefreshSearch={setRefreshSearch}
                />
            )}
        </>
    );
};

export default withTheme(withRouter(AssetResultItem));
