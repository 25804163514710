import moment from "moment";

export const getDates = (startString, stopString, step = 1, unit = "days") => {
    let dateArray = [];
    let currentDate = moment(startString);
    let stopDate = moment(stopString);
    //console.log(currentDate, stopDate);
    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate));
        currentDate = moment(currentDate).add(step, unit);
    }
    return dateArray;
};

export const isNow = (date, units) => {
    var now = moment(new Date());

    return moment(date).isSame(now, units) ? true : false;
};

export const calculateOffset = (itemStart, widths, units, calendarStart) => {
    //TODO sort out date formats
    const itemStartDate = moment(itemStart);
    const calendarStartDate = moment(calendarStart);

    //If the start date is before the start of the calendar set it to be a 0 offset
    if (itemStartDate.isSame(calendarStartDate)) {
        return 0;
    }
    if (itemStartDate.isBefore(calendarStartDate)) {
        return -widths.item;
    }
    return itemStartDate.diff(calendarStartDate, units.microName) * widths.item;
};
export const calculateWidth = (
    calendarStartDate,
    calendarEndDate,
    itemStart,
    itemEnd,
    widths,
    units,
    adjustment = 0
) => {
    //TODO sort out date formats
    const itemStartDate = moment(itemStart);
    const itemEndDate = moment.parseZone(itemEnd);
    let width = 0;
    if (itemStartDate.isBefore(calendarStartDate)) {
        //Note we need to add +2 as the ones that start before already have a minus 1 offset
        width =
            itemEndDate.diff(calendarStartDate, units.microName) +
            1 +
            adjustment;
    } else {
        width =
            itemEndDate.diff(itemStartDate, units.microName) + 1 + adjustment;
    }
    //If the item ends after the calendar take off the difference
    if (itemEndDate.isAfter(calendarEndDate)) {
        width -= itemEndDate.diff(calendarEndDate, units.microName) - 1;
    }
    return width * widths.item;
};

export const calculateContentIndent = (itemStart, widths, calendarStart) => {
    const itemStartDate = moment.parseZone(itemStart);
    const calendarStartDate = moment.parseZone(calendarStart);
    if (itemStartDate.isBefore(calendarStartDate)) {
        return widths.item;
    }
    return 0;
};
export const calculateDuration = (itemStart, itemEnd, units) => {
    const start = moment(itemStart);
    const end = moment(itemEnd);
    return end.diff(start, units.microName) + 1;
};
