import React, { useState } from "react";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    PasswordField,
    SubmitButton,
    Button,
} from "@cortexglobal/rla-components";
import { get } from "lodash";

const ResetPasswordForm = (props) => {
    const { onSubmit, onCancel, errors, loadingStatus = "loaded" } = props;

    const [params, setParams] = useState({
        password: "",
        confirm_password: "",
    });

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
    };

    return (
        <>
            <Row>
                <Column>
                    <PasswordField
                        name="password"
                        value={get(params, "password", "")}
                        onChange={(e) => onChange(e.name, e.value)}
                        label={trans("Password")}
                        error={get(errors, "password")}
                    />

                    <PasswordField
                        name="confirm_password"
                        value={get(params, "confirm_password", "")}
                        onChange={(e) => onChange(e.name, e.value)}
                        label={trans("Confirm Password")}
                        error={get(errors, "confirm_password")}
                    />
                </Column>
            </Row>

            <Row collapse>
                <Column medium={6}>
                    {onCancel !== undefined && typeof onCancel === "function" && (
                        <Button
                            onClick={() => onCancel()}
                            disabled={loadingStatus == "loading"}
                            $expanded
                            type="alert"
                        >
                            {trans("Cancel")}
                        </Button>
                    )}
                </Column>
                <Column medium={6}>
                    <SubmitButton
                        onClick={() => props.onSubmit(params)}
                        disabled={loadingStatus == "loading"}
                        submitting={loadingStatus === "loading"}
                        $expanded
                    >
                        {trans("Save")}
                    </SubmitButton>
                </Column>
            </Row>
        </>
    );
};

export default ResetPasswordForm;
