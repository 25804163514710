import React, { useState, useRef, useEffect, useCallback } from "react";
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
//@ts-ignore
import { createPopper } from "@popperjs/core";
//@ts-ignore cortex
import styled, { css } from "styled-components";
import PlainButton from "./buttons/PlainButton";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ContextMenuTrigger = styled.div`
    display: flex;
    width: 100%;
    min-width: 100%;
    height: 100%;
`;

const ContextMenuList = styled.ul`
    display: flex;
    flex-direction: column;
    min-width: ${({ theme }) => theme.contextMenu.minWidth};
    background: ${(props) => props.theme.contextMenu.background};
    padding: 0.5em;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
`;
const ContextMenuItem = styled.li`
    list-style: none;
    display: flex;
    flex-direction: row;
`;
const ContextMenuIcon = styled(FontAwesomeIcon)`
    min-width: 2rem;
    /* margin-right: 0.5rem; */
`;
const ContextMenuButton = styled(PlainButton)`
    width: 100%;
    padding: 0.25rem;
    background-color: ${(props) => props.theme.contextMenu.background};
    color: ${(props) => props.theme.contextMenu.color};
    transition: all 500ms;
    :hover {
        background-color: ${(props) => props.theme.contextMenu.hoverBackground};
        color: ${(props) => props.theme.contextMenu.hoverColor};
    }
`;

const PopperElement = styled.div`
    z-index: 2;
    display: flex;
    position: absolute;
`;

const ContextMenu = ({ children, actions }: ContextMenu) => {
    const [isDropdownShowing, setIsDropdownShowing] = useState(false);
    const [popper, setPopper] = useState<any>({});
    const popupRef = React.createRef<any>();
    const wrapperRef = React.createRef<any>();

    useEffect(() => {
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setIsDropdownShowing(false);
                document.body.style.overflow = "unset";
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleContextMenu = (e) => {
        e.preventDefault();
        const virtualReference = {
            getBoundingClientRect() {
                return {
                    top: e.clientY,
                    left: e.clientX,
                    bottom: 20,
                    right: 100,
                    width: 90,
                    height: 10,
                };
            },
        };
        setIsDropdownShowing(true);

        document.body.style.overflow = "hidden";
        /** @ts-ignore cortex */
        const rawPopper = createPopper(virtualReference, popupRef.current, {
            placement: "bottom-start",
            // strategy: "fixed"
        });
        setPopper(rawPopper.state);
    };
    let { styles, attributes } = popper;
    styles = styles && styles.popper ? styles.popper : {};
    attributes = attributes && attributes.popper ? attributes.popper : {};
    return (
        <>
            <ContextMenuTrigger
                onContextMenu={(event) => {
                    if (actions.length > 0) {
                        event.preventDefault();
                        handleContextMenu(event);
                    }
                }}
            >
                {/* @ts-ignore cortex */}
                {children}
            </ContextMenuTrigger>

            {actions.length > 0 && (
                <PopperElement
                    ref={popupRef}
                    style={{
                        ...styles,
                        display: isDropdownShowing ? "block" : "none",
                    }}
                    {...attributes}
                >
                    <ContextMenuList ref={wrapperRef}>
                        {actions.map((action) => {
                            return (
                                <ContextMenuItem key={action.name}>
                                    <ContextMenuButton
                                        onClick={(event) => {
                                            action.onClick();
                                            setIsDropdownShowing(false);
                                        }}
                                    >
                                        {action.icon && (
                                            <ContextMenuIcon
                                                icon={action.icon}
                                            />
                                        )}
                                        {action.name}
                                    </ContextMenuButton>
                                </ContextMenuItem>
                            );
                        })}
                    </ContextMenuList>
                </PopperElement>
            )}
        </>
    );
};

interface ContextMenu {
    children: React.ReactNode;
    actions: Action[];
    onClick: () => void;
    theme?: any;
}

interface ContextMenuProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: any;
    active?: boolean;
}

interface Action {
    name: string;
    onClick: () => void;
    icon?: IconProp;
}

export default ContextMenu;
