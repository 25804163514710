import React from "react";
import styled, { useTheme } from "styled-components";
import differenceInSeconds from "date-fns/differenceInSeconds";

import { UserInitials, Badge } from "@cortexglobal/rla-components";
import { trans, FormattedRelativeTime } from "@cortexglobal/rla-intl";

const ActivityUserDetails = styled.div`
    margin-left: 1rem;
    flex-grow: 1;
    display: flex;
`;
const InitialsWrapper = styled.aside`
    margin-right: 1rem;
`;

const AssetActivityPanel = ({ usage }) => {
    const { user, created_at, action, group } = usage;
    const theme = useTheme();

    const getAction = (action) => {
        switch (action) {
            case "viewed":
                return (
                    <Badge backgroundColor={theme.colors.success}>
                        {trans("Viewed")}
                    </Badge>
                );
            case "downloaded":
                return (
                    <Badge backgroundColor={theme.colors.bronze}>
                        {trans("Downloaded")}
                    </Badge>
                );
            case "created":
                return (
                    <Badge backgroundColor={theme.colors.silver}>
                        {trans("Created")}
                    </Badge>
                );
            case "status-changed":
                return (
                    <Badge backgroundColor={theme.colors.alert}>
                        {trans("Changed the status")}
                    </Badge>
                );
            case "updated":
                return (
                    <Badge backgroundColor={theme.colors.alert}>
                        {trans("Updated")}
                    </Badge>
                );
            case "personalized":
                return (
                    <Badge backgroundColor={theme.colors.bronze}>
                        {trans("Personalised")}
                    </Badge>
                );
            default:
                return (
                    <Badge backgroundColor={theme.colors.grey800}>
                        {action}
                    </Badge>
                );
        }
    };

    return (
        <tr>
            <td>
                <ActivityUserDetails>
                    <InitialsWrapper>
                        <UserInitials
                            name={user?.name || user?.last_name || ""}
                            diameter={30}
                        />
                    </InitialsWrapper>
                    <p>
                        <b>{user?.name || trans("Anonymous")}</b>
                        {group ? " (" + group.name + ")" : ""} &nbsp;
                    </p>
                </ActivityUserDetails>
            </td>
            <td>&nbsp;{getAction(action)} </td>
            <td>
                {/* <ActivityActionDetails> */}
                <FormattedRelativeTime
                    value={differenceInSeconds(
                        new Date(created_at),
                        new Date()
                    )}
                    numeric="auto"
                    updateIntervalInSeconds={10}
                />
                {/* </ActivityActionDetails> */}
            </td>
        </tr>
    );
};

export default AssetActivityPanel;
