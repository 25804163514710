import React, { Component } from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { DetailPanelItem } from "./DetailPanelItem";

class UserContactDetails extends Component {
    render() {
        const { user } = this.props;
        return (
            <React.Fragment>
                <DetailPanelItem>
                    <strong>{trans("Name")}</strong> {user.name}
                </DetailPanelItem>
                {user.phone && (
                    <DetailPanelItem>
                        <strong>{trans("Phone")}</strong> {user.phone}
                    </DetailPanelItem>
                )}
                {user.email && (
                    <DetailPanelItem>
                        <strong>{trans("Email")}</strong> {user.email}
                    </DetailPanelItem>
                )}
            </React.Fragment>
        );
    }
}

export default UserContactDetails;
