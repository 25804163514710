import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import CalendarContext from "./CalendarContext";
import { SummaryColumnHeader } from "./SummaryColumn";
import { isNow } from "./helpers";

const Header = styled.div`
    background: ${({ background }) => background};
    position: relative;
    height: ${({ heights }) => heights.task + 5}px;
    line-height: ${({ heights }) => heights.task + 5}px;
`;

const Label = styled.div`
    display: flex;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 1.2rem;
    text-align: center;
    color: ${({ colors }) => colors.header.label};
    width: ${({ widths }) => widths.sidebar}px;
    position: absolute;
    left: 0;
`;

const Month = styled.span`
    flex: 1;
`;

const Micro = styled.div`
    position: absolute;
    width: ${({ widths }) => widths.item}px;
    left: ${({ leftIndent }) => leftIndent}px;
    font-size: ${({ widths }) => Math.min(widths.item / 2, 13)}px;
    color: ${({ colors, isNow }) => colors.micro[isNow ? "current" : "label"]};
    ${(props) =>
        props.isNow &&
        css`
            font-weight: bold;
            background: ${(props) => props.colors.micro.currentBackground};
        `};
`;

const Button = styled.a`
    font-size: 0.8em;
    cursor: pointer;
    vertical-align: middle;
    color: ${({ colors }) => colors.header.button};
`;

const marginBottom = 10;

const CalendarHeader = (props) => {
    const { children, height, widths, ...rest } = props;
    const {
        colors,
        prev,
        prevAction,
        next,
        nextAction,
        dates,
        units,
        heights,
        summaryColumn,
    } = useContext(CalendarContext);

    return (
        <Header
            className="linearCalendar__header"
            marginBottom={marginBottom}
            widths={widths}
            heights={heights}
            background={colors.header.background}
            {...rest}
        >
            <Label
                className="linearCalendar__headerSidebar"
                widths={widths}
                colors={colors}
            >
                {children}
                {prevAction && (
                    <Button
                        colors={colors}
                        className={
                            "linearCalendar__headerCalendar__prevButton " +
                            prev.className
                        }
                        onClick={prevAction}
                    >
                        {prev.content}
                    </Button>
                )}{" "}
                <Month>{dates[0] && dates[0].format(units.macro)}</Month>
                {nextAction && (
                    <Button
                        colors={colors}
                        className={
                            "linearCalendar__headerCalendar__nextButton " +
                            next.className
                        }
                        onClick={nextAction}
                    >
                        {next.content}
                    </Button>
                )}
            </Label>
            <div
                className="linearCalendar__headerCalendar"
                style={{
                    width: widths.calendar,
                    position: "absolute",
                    left: widths.sidebar + widths.addButton,
                    textAlign: "center",
                }}
            >
                {dates.map((date, index) => {
                    let now = isNow(date, units.micro);
                    return (
                        <React.Fragment key={date}>
                            <Micro
                                isNow={now}
                                widths={widths}
                                colors={colors}
                                leftIndent={widths.item * index}
                            >
                                {date.format(units.micro)}
                            </Micro>
                        </React.Fragment>
                    );
                })}
            </div>

            {summaryColumn && (
                <SummaryColumnHeader
                    summaryColumn={summaryColumn}
                    colors={colors}
                />
            )}
        </Header>
    );
};

CalendarHeader.propTypes = {
    dates: PropTypes.array,
};

export default CalendarHeader;
