import React, { useState, useEffect } from "react";
import axios from "axios";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Panel,
    Tabordion,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { HeaderEmphasis, TabordionGroup } from "../../components/index";
import { get } from "lodash";

import { GroupFormSelector } from "../group/GroupFormSelector";
import ContactForm from "../../forms/ContactForm";
import GroupBranding from "./GroupBranding";

export const GroupProfileAdmin = () => {
    const [item, setItem] = useState({ loaded: false, data: null });
    const { can } = usePermissions();
    const getItem = () => {
        axios.get(`/api/v1/group-profile`).then(({ data }) => {
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, []);

    const submitForm = (values, setSubmitting, section) => {
        return axios
            .post(`/api/v1/group-profile/${section}`, values)
            .then(({ data }) => {
                // console.log(data);
                setItem({ loaded: true, data: data.data });
                setSubmitting(false);
            });
    };
    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Edit Company Details")}{" "}
                        {/* <HeaderEmphasis>
                            {item.data ? item.data.name : ""}
                        </HeaderEmphasis> */}
                    </React.Fragment>
                }
            ></PageTitle>
            {item.loaded ? (
                <Row>
                    <Tabordion current={0} unmounts={false}>
                        {can("group-profile-details") && (
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                            >
                                <GroupFormSelector
                                    group={item.data}
                                    submitForm={submitForm}
                                    canFullGroupAdmin={false}
                                />
                            </TabordionGroup>
                        )}
                        {can("group-profile-contact") && (
                            <TabordionGroup
                                heading={
                                    <span>{trans("Contact Details")}</span>
                                }
                            >
                                <ContactForm
                                    item={get(item, "data.entity.contact", {})}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                        )}
                        {can("group-profile-branding") && (
                            <TabordionGroup
                                heading={<span>{trans("Branding")}</span>}
                            >
                                <GroupBranding
                                    item={item}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                        )}
                    </Tabordion>
                </Row>
            ) : (
                <Loading />
            )}
        </div>
    );
    return;
};

export default GroupProfileAdmin;
