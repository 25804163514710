import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

//@ts-ignore cortex
import {
    Loading,
    Row,
    Column,
    DraftJSOutput,
    PageTitle,
    IconTextButton,
    useAddToast,
    Image,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { NewsStory } from "../../forms/NewsForm";
import NewsContext from "./NewsContext";

const Thumbnail = styled(Image)`
    float: left;
    margin: 0 1em 2em 0;
    max-width: 100%;
`;

const News = ({ uuid }) => {
    const addToast = useAddToast();
    const { can } = usePermissions();
    const history = useHistory();
    const { path } = useContext(NewsContext);

    const [loaded, setLoaded] = useState(false);
    const [story, setStory] = useState<NewsStory>(null);

    useEffect(() => {
        axios
            .get(`/api/v1/admin/news/${uuid}`)
            .then(({ data: { data } }) => {
                setStory({
                    ...data,
                });
                setLoaded(true);
            })
            .catch((e) => e);
    }, []);

    const deleteStory = () => {
        axios
            .delete(`/api/v1/admin/news/${uuid}`)
            .then(({ data: { data } }) => {
                //Add a toast and redirect to the news listing
                addToast({
                    type: "success",
                    content: trans("The news story has been deleted"),
                    showFor: 5000,
                });
                history.push(path);
            })
            .catch((e) => e);
    };

    if (!loaded) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <PageTitle title={story.title}>
                {can("content-news-delete") && (
                    <IconTextButton
                        icon={faTimes}
                        showCircle={false}
                        onClick={deleteStory}
                        style={{
                            paddingTop: "0.8rem",
                            paddingBottom: "0.8rem",
                        }}
                    >
                        {trans("Delete this story")}
                    </IconTextButton>
                )}
                {can("content-news-edit") && (
                    <IconTextButton
                        as={Link}
                        to={`${path}/${story.uuid}/edit`}
                        icon={faEdit}
                        showCircle={false}
                        expanded={false}
                        style={{
                            paddingTop: "0.8rem",
                            paddingBottom: "0.8rem",
                        }}
                    >
                        {trans("Edit this story")}
                    </IconTextButton>
                )}
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Column small={4}>
                    <Thumbnail src={`${story.thumbnail}`} />
                </Column>
                <Column small={8}>
                    <h2>{story.title}</h2>
                    {story.body && <DraftJSOutput>{story.body}</DraftJSOutput>}
                </Column>
            </Row>
        </React.Fragment>
    );
};

export default News;
