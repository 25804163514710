import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import CalendarContext from "./CalendarContext";
import { isNow } from "./helpers";

const Background = styled.div`
    /* background: ${({ background }) => background}; */
    position: relative;
    /* max-height: ${({ height }) => height}px; */
    overflow: hidden;
`;

const SideBar = styled.div`
    display: flex;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 1.2rem;
    text-align: center;
    color: ${({ colors }) => colors.header.label};
    width: ${({ widths }) => widths.sidebar}px;
    position: absolute;
    left: 0;
`;

const MicroLine = styled.div`
    width: 1px;
    position: absolute;
    z-index: 2;
    height: ${({ microLineHeight }) => microLineHeight}px;
    /* top: ${({ microLineTop }) => microLineTop}px; */
    left: ${({ leftIndent }) => leftIndent}px;
    background: ${({ colors }) => colors.micro.line};
`;

const marginBottom = 10;

const CalendarBackground = (props) => {
    const { children, height, widths, ...rest } = props;
    const {
        colors,
        prev,
        prevAction,
        next,
        nextAction,
        dates,
        units,
        heights,
        summaryColumn,
    } = useContext(CalendarContext);
    const microLineHeight = height - (widths.item + marginBottom);
    const microLineTop = heights.task + marginBottom;

    return (
        <Background
            marginBottom={marginBottom}
            widths={widths}
            heights={heights}
            height={height}
            background={colors.header.background}
            // {...rest}
        >
            <SideBar widths={widths} colors={colors}></SideBar>
            <div
                style={{
                    width: widths.calendar,
                    position: "absolute",
                    left: widths.sidebar + widths.addButton,
                    textAlign: "center",
                    zIndex: 0,
                }}
            >
                {dates.map((date, index) => {
                    let now = isNow(date, units.micro);
                    return (
                        <MicroLine
                            key={date}
                            microLineHeight={microLineHeight}
                            microLineTop={microLineTop}
                            leftIndent={widths.item * index}
                            colors={colors}
                        />
                    );
                })}

                <MicroLine
                    microLineHeight={microLineHeight}
                    microLineTop={microLineTop}
                    leftIndent={widths.item * dates.length + 1}
                    colors={colors}
                />
            </div>
            {children}
        </Background>
    );
};

CalendarBackground.propTypes = {
    dates: PropTypes.array,
};

export default CalendarBackground;
