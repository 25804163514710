import React from "react";

import WysiwygConfiguration from "./WysiwygConfiguration";
import WysiwygOutput from "./WysiwygOutput";

export const Wysiwyg = ({ configuring, ...props }) => {
    if (configuring) {
        return <WysiwygConfiguration {...props} />;
    }
    return <WysiwygOutput {...props} />;
};

export default Wysiwyg;
