import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { trans, useIntl } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    FormRow,
    PlainButton,
    InputField,
    IconTextButton,
    TextareaField,
    Image as CortexImage,
    useAddToast,
    SimpleDropzone,
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faSearch } from "@fortawesome/free-solid-svg-icons";
import AllAssetImageBrowser from "../../../assets/forms/components/assetImageBrowser/AllAssetImageBrowser";

// const DeleteButton = styled(PlainButton)`
//     margin-left: 1rem;
//     color: ${(props) => props.theme.colors.alert};
//     font-size: 1.2rem;
// `;

export const ImageCustomField = ({
    name,
    customField,
    onChange,
    errors,
    width = 0,
    height = 0,
}) => {
    const intl = useIntl();
    const addToast = useAddToast();
    const [showImageBrowser, setShowImageBrowser] = useState(false);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];

        const image = new Image();
        image.addEventListener("load", () => {
            // console.log(`${image.width}x${image.height}`);

            //If the image is too small display a message and abort the upload
            if (image.width < width || image.height < height) {
                addToast({
                    type: "alert",
                    content: trans(
                        "Images need to be more than {width}px wide and {height}px high",
                        { width, height }
                    ),
                    showFor: 5000,
                });
                return;
            } else {
                const fd = new FormData();
                fd.append("file", acceptedFiles[0]);
                fd.append("type", "image");
                fd.append("asset_input_name", name);
                axios
                    .post(`/api/v1/asset-file`, fd)
                    .then(({ data }) => {
                        // console.log(data);

                        onChange({ name, value: data });
                    })
                    .catch((e) => {
                        const message = e?.response?.data?.errors?.file
                            ? e.response.data.errors.file
                            : trans("An error occurred uploading your file");
                        addToast({
                            type: "alert",
                            content: message,
                            showFor: 5000,
                        });
                    });
            }
        });
        image.src = URL.createObjectURL(file);
    };

    if (customField.autofilled) {
        return (
            <InputField
                name={name}
                value={customField.tag_value}
                onChange={onChange}
            />
        );
    }
    return (
        <>
            {customField.tag_value && (
                <Column>
                    <CortexImage
                        src={customField.tag_value}
                        alt={customField.tag_name}
                        style={{ maxWidth: "100%" }}
                    />
                </Column>
            )}
            <Column medium={6}>
                <IconTextButton icon={faUpload} showCircle={false}>
                    <SimpleDropzone onDrop={onDrop}>
                        {trans("Upload")}
                    </SimpleDropzone>
                </IconTextButton>
            </Column>
            <Column medium={6}>
                <IconTextButton
                    icon={faSearch}
                    showCircle={false}
                    onClick={() => setShowImageBrowser(!showImageBrowser)}
                >
                    {trans("Browse")}
                </IconTextButton>
            </Column>
            {showImageBrowser && (
                <AllAssetImageBrowser
                    name={name}
                    asset={null}
                    src={customField.tag_value}
                    value={customField.tag_value}
                    allowUpload={true}
                    attachAssetFile={(e) => {
                        onChange(e);
                    }}
                    type="image"
                    inFormRow={true}
                />
            )}
        </>
    );
};

export default ImageCustomField;
