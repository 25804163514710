import React from "react";
import {
    ContentBlockLoader,
    InputField,
    SelectField,
    TextareaField,
    FormRow,
} from "@cortexglobal/rla-components";
import { get, isEmpty } from "lodash";
import { trans } from "@cortexglobal/rla-intl";
import { FormWrapper } from "./formLayoutComponents";

const OverviewForm = ({
    loadingState,
    errors,
    params,
    onChange,
    targetAudienceOptions,
    excludedOptionalFields,
}) => {
    return (
        <FormWrapper>
            <FormRow
                helpText={trans("Required")}
                label={trans("Predicted number of attendees")}
                name={"predicted_attendee_count"}
                error={get(errors, "predicted_attendee_count")}
            >
                <InputField
                    name="predicted_attendee_count"
                    type="number"
                    min="0"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "predicted_attendee_count", "")}
                />
            </FormRow>
            {!excludedOptionalFields.includes("max_attendees") && (
                <FormRow
                    helpText={trans("Optional")}
                    label={trans("Maximum number of attendees")}
                    name={"max_attendees"}
                    error={get(errors, "max_attendees")}
                >
                    <InputField
                        name="max_attendees"
                        type="number"
                        min="0"
                        onChange={(e) => onChange(e.name, e.value)}
                        disabled={loadingState === "loading"}
                        value={get(params, "max_attendees", "")}
                    />
                </FormRow>
            )}
            {!excludedOptionalFields.includes("staff_attendee_count") && (
                <FormRow
                    helpText={trans("Required")}
                    label={trans("Staff attendees")}
                    name={"staff_attendee_count"}
                    error={get(errors, "staff_attendee_count")}
                >
                    <InputField
                        name="staff_attendee_count"
                        type="number"
                        min="0"
                        onChange={(e) => onChange(e.name, e.value)}
                        disabled={loadingState === "loading"}
                        value={get(params, "staff_attendee_count", "")}
                    />
                </FormRow>
            )}

            <FormRow
                helpText={trans("Optional")}
                label={trans("Target audience")}
                name={"target_audience"}
                error={get(errors, "target_audience")}
            >
                <ContentBlockLoader loaded={!isEmpty(targetAudienceOptions)}>
                    <SelectField
                        height={38}
                        name="target_audience"
                        onChange={(e) => onChange(e.name, e.value)}
                        disabled={loadingState === "loading"}
                        options={targetAudienceOptions}
                        value={get(params, "target_audience", "")}
                    />
                </ContentBlockLoader>
            </FormRow>

            {!excludedOptionalFields.includes("pr_requirements") && (
                <FormRow
                    helpText={trans("Required")}
                    label={trans("PR/Social media requirements")}
                    name={"pr_requirements"}
                    error={get(errors, "pr_requirements")}
                >
                    <TextareaField
                        name="pr_requirements"
                        onChange={(e) => onChange(e.name, e.value)}
                        disabled={loadingState === "loading"}
                        value={get(params, "pr_requirements") || ""}
                    />
                </FormRow>
            )}
            {!excludedOptionalFields.includes("sustainability_element") && (
                <FormRow
                    helpText={trans("Optional")}
                    label={trans("Sustainability element")}
                    name={"sustainability_element"}
                    error={get(errors, "sustainability_element")}
                >
                    <TextareaField
                        name="sustainability_element"
                        onChange={(e) => onChange(e.name, e.value)}
                        disabled={loadingState === "loading"}
                        value={get(params, "sustainability_element") || ""}
                    />
                </FormRow>
            )}
        </FormWrapper>
    );
};

export default OverviewForm;
