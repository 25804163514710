import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
    DatePicker,
    ContentBlockLoader,
} from "@cortexglobal/rla-components";

import {
    startOfMonth,
    lastDayOfMonth,
    startOfQuarter,
    lastDayOfQuarter,
    startOfYear,
    lastDayOfYear,
} from "date-fns";

import { format } from "@cortexglobal/cortex-utilities";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const MarketingKpiBreakdownConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    viewBy,
    startDate,
    endDate,
    period,
    periodLabel,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title
            ? title
            : intl.formatMessage({ id: "Marketing KPI Tracker" }),
        viewBy: viewBy ? viewBy : "quarter",
        startDate: startDate
            ? startDate
            : format(startOfQuarter(new Date()), "yyyy-MM-dd"),
        endDate: endDate
            ? endDate
            : format(lastDayOfQuarter(new Date()), "yyyy-MM-dd"),
        period: period ? period : "",
        periodLabel: periodLabel ? periodLabel : "",
    });

    const [options, setOptions] = useState({
        loaded: false,
        data: {},
    });

    // Need to do something to get the options by viewBy type and ensure something exists before loading it

    useEffect(() => {
        let isMounted = true;

        axios
            .get(`/api/v1/marketing-kpi/configure/view-by-options`)
            .then(({ data }) => {
                console.log(data);
                setOptions({
                    loaded: true,
                    data: data.data,
                });
            });

        return () => {
            // 👇️ when component unmounts, set isMounted to false
            isMounted = false;
        };
    }, []);

    const viewByOptions = [
        { value: "year", text: intl.formatMessage({ id: "Year" }) },
        { value: "quarter", text: intl.formatMessage({ id: "Quarter" }) },
        { value: "month", text: intl.formatMessage({ id: "Month" }) },
        { value: "specific", text: intl.formatMessage({ id: "Specific" }) },
    ];

    const onChange = (input) => {
        // console.log({ input, state });
        // console.log(state.viewBy, input.name);
        if (state.viewBy === "year" && input.name === "period") {
            //If the date
            setState({
                ...state,
                [input.name]: input.value,
                periodLabel: input.text,
                startDate: format(
                    startOfYear(new Date(input.value), 12),
                    "yyyy-MM-dd"
                ),
                endDate: format(
                    lastDayOfYear(new Date(input.value), 12),
                    "yyyy-MM-dd"
                ),
            });
        } else if (state.viewBy === "quarter" && input.name === "period") {
            const tempEndDate = format(
                lastDayOfQuarter(new Date(input.value)),
                "yyyy-MM-dd"
            );
            //If the date
            setState({
                ...state,
                periodLabel: input.text,
                [input.name]: input.value,
                startDate: input.value,
                endDate: tempEndDate,
            });
        } else if (input.name == "period") {
            setState({
                ...state,
                [input.name]: input.value,
                periodLabel: input.text,
            });
        } else {
            setState({ ...state, [input.name]: input.value });
        }
    };

    const getCurrentViewByOptions = () => {
        if (!options.loaded) {
            return [];
        }

        if (!options.data.hasOwnProperty(state.viewBy)) {
            return [];
        }

        return options.data[state.viewBy];
    };

    return (
        <Row>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    submitPanel(panel, state, panelId);
                }}
            >
                <Column>
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder="Custom Title"
                        onChange={onChange}
                    />
                    <SelectField
                        name="viewBy"
                        label={trans("View By")}
                        value={state.viewBy}
                        options={viewByOptions}
                        onChange={onChange}
                        expanded
                    />
                    {state.viewBy !== "specific" && (
                        <ContentBlockLoader loaded={options.loaded}>
                            <SelectField
                                name="period"
                                label={trans("Period")}
                                value={state.period}
                                options={getCurrentViewByOptions()}
                                onChange={onChange}
                                expanded
                            />
                        </ContentBlockLoader>
                    )}
                    {state.viewBy === "specific" && (
                        <>
                            <DatePicker
                                name="startDate"
                                label={trans("From")}
                                style={{ display: "block" }}
                                value={state.startDate}
                                onChange={onChange}
                                allowEmpty={true}
                            />
                            <DatePicker
                                name="endDate"
                                label={trans("To")}
                                style={{ display: "block" }}
                                value={state.endDate}
                                onChange={onChange}
                                allowEmpty={true}
                            />
                        </>
                    )}
                </Column>
                <Column>
                    <Button type="submit">{trans("Save Panel")}</Button>
                </Column>
            </form>
        </Row>
    );
};

MarketingKpiBreakdownConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default MarketingKpiBreakdownConfiguration;
