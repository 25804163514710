import { useSelector } from "react-redux";
import { get } from "lodash";

const useModule = () => {
    let modules = useSelector((state) => {
        return get(state, "auth.modules", {});
    });

    return { modules };
};

export default useModule;
