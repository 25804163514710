import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { trans } from "@cortexglobal/rla-intl";

import { Column, IconTextButton, Badge } from "@cortexglobal/rla-components";
import {
    SpacedRow,
    Header,
    Description,
    DetailWrapper,
} from "../components/overviewComponents";

const ActivityOverview = ({
    activity,
    task,
    theme: plannerTheme,
    viewActivity,
    ...props
}) => {
    const start = moment(activity.start_date);
    const end = moment(activity.end_date);
    const created = moment(activity.created_at);

    return (
        <>
            <Header>
                <h4>
                    <Badge
                        backgroundColor={
                            plannerTheme.colors.activities.activity
                        }
                        style={{ marginRight: "0.4rem" }}
                    >
                        {activity.ref}
                    </Badge>
                    <strong>{activity.name}</strong>
                </h4>
            </Header>

            {activity.description && (
                <Description>
                    <p>{activity.description}</p>
                </Description>
            )}

            <DetailWrapper>
                <SpacedRow className="detailRow">
                    <Column small={6}>{trans("Start Date")}:</Column>
                    <Column small={6}>{start.format("LL")}</Column>
                </SpacedRow>

                <SpacedRow className="detailRow">
                    <Column small={6}>{trans("End Date")}</Column>
                    <Column small={6}>{end.format("LL")}</Column>
                </SpacedRow>

                <SpacedRow className="detailRow">
                    <Column small={6}>{trans("Cost")}:</Column>
                    <Column small={6}>
                        {activity.cost ? `£${activity.cost.toFixed(2)}` : `-`}
                    </Column>
                </SpacedRow>

                <SpacedRow className="detailRow">
                    <Column small={6}>{trans("Created Date")}:</Column>
                    <Column small={6}>{created.format("LL")}</Column>
                </SpacedRow>

                {activity.created_by && (
                    <SpacedRow className="detailRow">
                        <Column small={6}>{trans("Created By")}:</Column>
                        <Column small={6}>{activity.created_by}</Column>
                    </SpacedRow>
                )}
            </DetailWrapper>

            {typeof viewActivity === "function" && (
                <SpacedRow>
                    <Column medium={6}>
                        <IconTextButton
                            expanded
                            onClick={(event) => {
                                return viewActivity(task, activity, event);
                            }}
                        >
                            {trans("View Activity")}
                        </IconTextButton>
                    </Column>
                </SpacedRow>
            )}
        </>
    );
};

export default ActivityOverview;
