import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import debounce from "lodash/debounce";

import { isJSON } from "@cortexglobal/cortex-utilities";

import {
    // Editor,
    EditorState,
    RichUtils,
    ContentState,
    convertFromRaw,
    convertToRaw,
} from "draft-js";
// import "draft-js/dist/Draft.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { transparentize } from "@cortexglobal/cortex-utilities";
import { baseInputStyle } from "./baseInput";
import { baseWysiwygStyle } from "../../DraftJSOutput";

const EditorWrapper = styled.div`
    ${baseInputStyle};

    ${({ hideToolbar }) =>
        hideToolbar
            ? `
            height: 34px;
            margin-bottom: 0;
            
            .rdw-editor-main {
                border: none !important;
            }
            
            .rdw-editor-wrapper {
                height: 100%;
            }
            
            .DraftEditor-root {
                bottom: 4px;
            }
        `
            : `.rdw-editor-toolbar {
                margin: 5px 0;
            }`}

    .rdw-editor-main {
        min-height: 200px;

        .DraftEditor-editorContainer {
            z-index: 0;
        }
        ${baseWysiwygStyle}
    }
`;

const StyledEditor = styled(Editor)``;

const DraftJSField = ({
    name,
    value,
    onChange,
    label,
    readOnly,
    error,
    hideToolbar,
    toolbar,
    ...rest
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    useEffect(() => {
        if (value) {
            if (isJSON(value)) {
                setEditorState(
                    EditorState.createWithContent(
                        convertFromRaw(JSON.parse(value))
                    )
                );
            } else if (typeof value === "object") {
                setEditorState(
                    EditorState.createWithContent(convertFromRaw(value))
                );
            }
        }
    }, []);

    const theme = useTheme();

    const delayedOnChange = debounce((editorState) => {
        const valueString = JSON.stringify(
            convertToRaw(editorState.getCurrentContent())
        );
        //If the current value as a string is equal to the value string, then don't need to
        //update the value.  This helps prevent unwanted updates when the focus is altered,
        // e.g. when the user moves the cursor.
        if (valueString !== value) {
            onChange(
                {
                    name,
                    value: valueString,
                },
                editorState
            );
        }
    }, 500);

    const onEditorChange = (editorState) => {
        setEditorState(editorState);
        delayedOnChange(editorState);
    };

    const handleKeyCommand = (command, editorState) => {
        // console.log("handleKeyCommand", command, editorState);
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorChange(newState);
            return "handled";
        }
        return "not-handled";
    };
    return (
        <EditorWrapper hideToolbar={hideToolbar}>
            <StyledEditor
                toolbar={toolbar}
                toolbarHidden={hideToolbar}
                id={name}
                name={name}
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onEditorStateChange={onEditorChange}
                editorStyle={{
                    border: `1px solid  ${transparentize(
                        theme.input.borderColor,
                        0.7
                    )}`,
                    padding: "0 0.5em",
                    borderRadius: theme.input.radius,
                    background: theme.colors.white,
                    marginBottom: "0.3rem",
                }}
                toolbarStyle={{ border: "none" }}
                // onChange={event => {
                //     console.log("event", event.getCurrentContent());
                //     onChange({
                //         name: name,
                //         value: JSON.stringify(
                //             convertToRaw(event.getCurrentContent())
                //         )
                //     });
                // }}
                {...rest}
            />
        </EditorWrapper>
    );
};

DraftJSField.displayName = "DraftJSField";

DraftJSField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
DraftJSField.defaultProps = {
    size: "default",
    expanded: false,
    inlineLabel: true,
    readOnly: false,
    error: "",
};
export default DraftJSField;
