import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class PublicFooter extends Component {
    render() {
        return <></>;
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublicFooter);
