import React from "react";

//@ts-ignore cortex
import { CampaignRouting } from "@cortexglobal/cortex-dam";
//@ts-ignore cortex
import { useIntl } from "@cortexglobal/rla-intl";

export const CampaignAdmin = () => {
    const intl = useIntl();

    return (
        <CampaignRouting
            libraryArea="assets-admin"
            title={intl.formatMessage({ id: "Campaign Admin" })}
        />
    );
};

export default CampaignAdmin;
