import React, { Component } from "react";

import CampaignSummaryConfiguration from "./CampaignSummaryConfiguration";
import CampaignSummaryOutput from "./CampaignSummaryOutput";

class CampaignSummary extends Component {
    render() {
        if (this.props.configuring) {
            return <CampaignSummaryConfiguration {...this.props} />;
        }
        return <CampaignSummaryOutput {...this.props} />;
    }
}

export default CampaignSummary;
