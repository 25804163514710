import React from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { SelectField } from "@cortexglobal/rla-components";

const RoleSelect = props => {
    const roles = useSelector(state =>
        isEmpty(state.auth.roles)
            ? [
                  {
                      value: "",
                      text: "..."
                  }
              ]
            : state.auth.roles.map(role => {
                  return {
                      value: role.uuid,
                      text: role.name
                  };
              })
    );

    const { value, error, onChange } = props;

    return (
        <SelectField
            name="role_uuid"
            options={roles}
            value={value}
            error={error}
            onChange={e => onChange(e)}
        />
    );
};

export default RoleSelect;
