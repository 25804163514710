import React, { useContext } from "react";
import AssetContext from "../../AssetContext";
import { Row, Column } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const AssetDetailsDescription = (props) => {
    const { asset } = useContext(AssetContext);

    return (
        <section style={{ whiteSpace: "pre-wrap" }}>
            {asset.description}
        </section>
    );
};

export default AssetDetailsDescription;
