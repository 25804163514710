import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";

/**
 * Everything to do with the context for this part of the module is kept in this file.
 * This keeps other areas clear of functionality.
 */

// Holds the app context
const FaqContext = React.createContext({
    faqState: {
        loading: false,
        categories: null,
        category: null,
    },
    dispatch: (state) => {},
    hideUnpublished: false,
    setHideUnpublished: (bool) => {},
    basepath: "",
    setBasepath: (string) => {},
    faqNavigate: (path) => {},
    loadCategories: () => {},
    loadCategory: (categorySlug) => {},
    storeQuestion: (questionData) => {},
    updateQuestion: (questionData) => {},
    deleteQuestion: (questionUuid) => {},
});

export default FaqContext;

// Holds the app state
export const FAQ_ACTION = {
    LOADING: "LOADING",
    LOADED: "LOADED",
    CATEGORIES_LOADING: "CATEGORIES_LOADING",
    CATEGORY_LOADING: "CATEGORY_LOADING",
    CATEGORIES: "CATEGORIES",
    CATEGORY: "CATEGORY",
};

const initialState = {
    categories_loading: false,
    category_loading: false,
    categories: [],
    category: {},
};

const faqReducer = (state, action) => {
    switch (action.type) {
        case FAQ_ACTION.LOADING:
            return {
                ...state,
                categories_loading: true,
                category_loading: true,
            };
        case FAQ_ACTION.LOADED:
            return {
                ...state,
                categories_loading: false,
                category_loading: false,
            };
        case FAQ_ACTION.CATEGORIES_LOADING:
            return {
                ...state,
                categories_loading: true,
            };
        case FAQ_ACTION.CATEGORY_LOADING:
            return {
                ...state,
                category_loading: true,
            };
        case FAQ_ACTION.CATEGORIES:
            return {
                ...state,
                categories: action.data,
                categories_loading: false,
                category_loading: false,
            };
        case FAQ_ACTION.CATEGORY:
            return {
                ...state,
                category: action.data,
                categories_loading: false,
                category_loading: false,
            };
        default:
            return state;
    }
};

// Contains all the functionality
export const FaqFunctions = () => {
    const history = useHistory();

    const [faqState, dispatch] = useReducer(faqReducer, initialState);
    const [hideUnpublished, setHideUnpublished] = useState(false);
    const [basepath, setBasepath] = useState("");

    // EFFECTS
    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        loadCategories();
    }, [hideUnpublished]);

    // APP FUNCTIONS

    // faqNavigate is a wrapper function because I didn't really think
    // about how system messages would be cleared between pages
    const faqNavigate = (path) => {
        history.push(path);
    };

    const loadCategories = () => {
        if (faqState.loading) {
            return;
        }
        dispatch({ type: FAQ_ACTION.CATEGORIES_LOADING });
        return axios
            .get(`/api/v1/faq`, {
                params: { hide_unpublished: hideUnpublished },
            })
            .then(({ data: { data } }) => {
                dispatch({
                    type: FAQ_ACTION.CATEGORIES,
                    data,
                });
            })
            .catch((e) => e);
    };

    const loadCategory = (categorySlug) => {
        if (faqState.loading || !categorySlug || categorySlug === "undefined") {
            return;
        }

        dispatch({ type: FAQ_ACTION.CATEGORY_LOADING });
        return axios
            .get(`/api/v1/faq/${categorySlug}`, {
                params: { hide_unpublished: hideUnpublished },
            })
            .then(({ data: { data } }) => {
                dispatch({
                    type: FAQ_ACTION.CATEGORY,
                    data,
                });
            })
            .catch((e) => e);
    };

    const storeQuestion = (questionData) => {
        dispatch({ type: FAQ_ACTION.LOADING });

        if (!questionData.question || !questionData.question_category.value) {
            dispatch({ type: FAQ_ACTION.LOADED });
            return new Promise((resolve, reject) => {
                throw {
                    response: trans("Question and category must be entered"),
                };
            });
        }

        const questionDataWithCategoryAsUuid = {
            question: {
                question: questionData.question,
            },
            question_category: questionData.question_category.value,
        };

        return axios
            .post(`/api/v1/faq`, questionDataWithCategoryAsUuid)
            .then(({ data: { data } }) => {
                dispatch({ type: FAQ_ACTION.LOADED });
                return data;
            });
    };

    const updateQuestion = (questionData) => {
        dispatch({ type: FAQ_ACTION.CATEGORY_LOADING });

        if (!questionData.question || !questionData.question_category.value) {
            dispatch({ type: FAQ_ACTION.LOADED });
            return new Promise((resolve, reject) => {
                throw {
                    response: trans("Question and category must be entered"),
                };
            });
        }

        const questionDataWithCategoryAsUuid = {
            ...questionData,
            question: {
                uuid: questionData.question_uuid,
                question: questionData.question,
            },
            answer: {
                uuid: questionData?.answer_uuid
                    ? questionData?.answer_uuid
                    : null,
                answer: questionData.answer,
            },
            question_category: questionData.question_category.value,
        };

        return axios
            .put(
                `/api/v1/faq/${questionData.uuid}`,
                questionDataWithCategoryAsUuid
            )
            .then(({ data: { data } }) => {
                dispatch({
                    type: FAQ_ACTION.CATEGORY,
                    data,
                });
                return data;
            });
    };

    const deleteQuestion = (questionUuid) => {
        dispatch({ type: FAQ_ACTION.LOADING });
        return axios
            .delete(`/api/v1/faq/${questionUuid}`)
            .then(({ data: { data } }) => {
                dispatch({ type: FAQ_ACTION.LOADED });
                return data;
            });
    };

    return {
        faqState,
        dispatch,
        hideUnpublished,
        setHideUnpublished,
        basepath,
        setBasepath,
        faqNavigate,
        loadCategories,
        loadCategory,
        storeQuestion,
        updateQuestion,
        deleteQuestion,
    };
};
