import React, { useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Layout } from "../interfaces/layout";
import {
    SlideContainer,
    Subtitle,
    SlideTitle,
} from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";
import { Content } from "./ThreeColumns";
import { Caption } from "../components/videoComponents";

const StyledSlideTitle = styled(SlideTitle)`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    flex: 1;
`;

const SlideBody = styled.div`
    display: flex;
    flex: 4;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1em;
    justify-content: space-between;
    font-size: 0.7em;
    flex-direction: column;
`;

const SlideColumn = styled(animated.div)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

const Image = styled.img`
    padding-bottom: 10vh;
`;

const ImageBottom = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);

    if (editing || disableSequencing) {
        currentTransitionIndex = 3;
    }
    const transitionsComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );
    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                hidden={!content.title || !content.title.value}
                style={useSpring({
                    opacity: transitionsComplete.includes("title") ? 1 : 0,
                })}
            >
                <RenderContentBlock {...content.title} />
            </StyledSlideTitle>

            <SlideBody>
                <SlideColumn
                    style={useSpring({
                        opacity: transitionsComplete.includes("column") ? 1 : 0,
                    })}
                >
                    {!!content.column && Array.isArray(content.column.value) && (
                        <>
                            {content.column.value.map(
                                (column: Content, index: number) => {
                                    return (
                                        <Caption
                                            highlightColor={highlightColor}
                                            key={index}
                                            showing={true}
                                            bulletPoint={!!column.bullet}
                                        >
                                            <RenderContentBlock
                                                type="text"
                                                value={column.value}
                                                footnotes={
                                                    column.footnotes || []
                                                }
                                            />
                                        </Caption>
                                    );
                                }
                            )}
                        </>
                    )}
                </SlideColumn>
                <SlideColumn
                    style={useSpring({
                        opacity: transitionsComplete.includes("image") ? 1 : 0,
                    })}
                >
                    {content.image && content.image.value && (
                        <Image
                            style={
                                settings.image_center_align
                                    ? {
                                          width: `${settings.image_width}%`,
                                          margin: "0 auto",
                                      }
                                    : { width: `${settings.image_width}%` }
                            }
                            src={content.image.value}
                            alt="image"
                        />
                    )}
                </SlideColumn>
            </SlideBody>
        </SlideContainer>
    );
};

export default ImageBottom;
