import React from "react";
import { Column, Badge } from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";

import { SpacedRow, DetailWrapper } from "./overviewComponents";

const CampaignBudgetDetails = ({
    task,
    activity,
    theme,
    viewTask,
    category,
    user,
}) => {
    const activityMediaCategory = activity.media_channel
        ? activity.media_channel
        : "";

    const spendTotals = task.activities
        ? task.activities.reduce(
              (totals, taskActivity) => {
                  totals.overallSpend += taskActivity.cost?.value || 0;
                  totals.overallPlanned +=
                      taskActivity.planned_cost?.value || 0;
                  if (
                      activityMediaCategory &&
                      taskActivity.media_channel &&
                      activityMediaCategory === taskActivity.media_channel
                  ) {
                      totals.channelSpend += taskActivity.cost?.value || 0;
                      totals.channelPlanned +=
                          taskActivity.planned_cost?.value || 0;
                  }
                  return totals;
              },
              {
                  overallSpend: 0,
                  channelSpend: 0,
                  overallPlanned: 0,
                  channelPlanned: 0,
              }
          )
        : {
              overallSpend: 0,
              channelSpend: 0,
              overallPlanned: 0,
              channelPlanned: 0,
          };

    // const plannedTotals = { overall: 0, channel: 0 };

    return (
        <div>
            <h4 style={{ padding: "0 1em" }}>
                <Badge
                    style={{
                        margin: "0 0.5rem 0 0",
                        textDecoration: "none",
                    }}
                    backgroundColor={theme.colors.task[task.owner_type]}
                >
                    {task.ref}
                </Badge>
                <a
                    href={`/plan/task/${task.uuid}`}
                    onClick={(event) => {
                        event.preventDefault();
                        return viewTask(task, category);
                    }}
                >
                    {task.name}
                </a>
            </h4>

            <DetailWrapper>
                <SpacedRow className="detailRow">
                    <Column style={{ paddingLeft: "0" }} small={6}>
                        <b>{trans("Total Planned")}</b>
                    </Column>
                    <Column small={6} style={{ textAlign: "right" }}>
                        <FormattedCurrency
                            value={spendTotals.overallPlanned}
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </Column>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <Column style={{ paddingLeft: "0" }} small={6}>
                        <b>{trans("Total Remaining")}</b>
                    </Column>
                    <Column small={6} style={{ textAlign: "right" }}>
                        <FormattedCurrency
                            value={
                                spendTotals.overallPlanned -
                                spendTotals.overallSpend
                            }
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </Column>{" "}
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <Column style={{ paddingLeft: "0" }} small={6}>
                        <b>
                            {trans("{channel} Planned", {
                                channel: activity?.media_channel_full?.text,
                            })}
                        </b>
                    </Column>
                    <Column small={6} style={{ textAlign: "right" }}>
                        <FormattedCurrency
                            value={spendTotals.channelPlanned}
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </Column>
                </SpacedRow>
                <SpacedRow className="detailRow">
                    <Column style={{ paddingLeft: "0" }} small={6}>
                        {" "}
                        <b>
                            {trans("{channel} Remaining", {
                                channel: activity?.media_channel_full?.text,
                            })}
                        </b>
                    </Column>
                    <Column small={6} style={{ textAlign: "right" }}>
                        <FormattedCurrency
                            value={
                                spendTotals.channelPlanned -
                                spendTotals.channelSpend
                            }
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </Column>
                </SpacedRow>
            </DetailWrapper>
        </div>
    );
};

export default CampaignBudgetDetails;
