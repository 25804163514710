import axios from "axios";

export const ERROR = "SET_REPORTS";
export const SEARCH_START = "SEARCH_START";
export const SEARCH_STOP = "SEARCH_STOP";
export const MEDIA_SEARCH_RESULTS = "MEDIA_SEARCH_RESULTS";

export function mediaSearch(values) {
    return dispatch => {
        dispatch({
            type: SEARCH_START
        });
        axios
            .post(`/api/v1/media/search`, values)
            .then(({ data: { data } }) => {
                dispatch({
                    type: MEDIA_SEARCH_RESULTS,
                    data: data
                });
                dispatch({
                    type: SEARCH_STOP
                });
            })
            .catch(e => e);
    };
}

export const MEDIA_CLEAR_RESULTS = "MEDIA_CLEAR_RESULTS";
export function mediaClearResults() {
    return {
        type: MEDIA_CLEAR_RESULTS
    };
}

export const MEDIA_OPTIONS = "MEDIA_OPTIONS";
export function getMediaOptions(type) {
    return dispatch => {
        axios
            .get(`/api/v1/admin/${type}/options`)
            .then(response => {
                var data = response.data;
                dispatch({
                    type: MEDIA_OPTIONS,
                    data: data
                });
            })
            .catch(e => e);
    };
}

export const MEDIA_SAVE = "MEDIA_SAVE";
export function mediaSave(values) {
    return dispatch => {
        axios
            .post(`/api/v1/admin/media/save`, values)
            .then(response => {
                var { data } = response;
                dispatch({
                    type: MEDIA_SAVE,
                    data: data
                });
                return data.folder[0].uuid;
            })
            .then(folderUUID => dispatch(getFolders(folderUUID)))
            .catch(e => e);
    };
}

export const UPLOAD_START = "UPLOAD_START";
export const UPLOAD_STOP = "UPLOAD_STOP";
export const SET_CURRENT_MEDIA = "SET_CURRENT_MEDIA";

export function uploadStarted() {
    return {
        type: UPLOAD_START
    };
}

export function uploadFile(file, uuid) {
    const fd = new FormData();
    fd.append("mediaFiles", file);
    fd.append("uuid", uuid);

    return (dispatch, getStore) => {
        axios
            .post("/api/v1/admin/media/upload", fd)
            .then(response => {
                var data = response.data;
                dispatch({
                    type: SET_CURRENT_MEDIA,
                    data
                });
                dispatch({
                    type: UPLOAD_STOP
                });
            })
            .catch(e => {
                let data = { errorMessage: e.response.data };
                dispatch({
                    type: UPLOAD_STOP
                });
            });
    };
}

export const UPDATE_FOLDERS = "UPDATE_FOLDERS";
export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER";

export function getFolders(uuid) {
    return dispatch => {
        //let n = Date.now();
        axios
            .get(`/api/v1/folder/${uuid}`)
            .then(response => {
                var { data } = response.data;
                //            data.openState = "open";
                dispatch({
                    type: UPDATE_FOLDERS,
                    data: data
                });
                dispatch({
                    type: SET_CURRENT_FOLDER,
                    data: data
                });
            })
            .catch(e => e);
    };
}

export const UPDATE_TOP_FOLDERS = "UPDATE_TOP_FOLDERS";

export function getTopFolders(folders) {
    return dispatch => {
        axios
            .get(`/api/v1/folder/top/${folders ? folders : "gallery"}`)
            .then(response => {
                var { data } = response.data;
                dispatch({
                    type: UPDATE_TOP_FOLDERS,
                    data: data
                });
                dispatch({
                    type: SET_CURRENT_FOLDER,
                    data: data[0]
                });
            })
            .catch(e => e);
    };
}

export function setCurrentFolder(data) {
    return {
        type: SET_CURRENT_FOLDER,
        data: data
    };
}

export const FOLDER_SAVE = "FOLDER_SAVE";
export function folderSave(values) {
    return dispatch => {
        axios
            .post(`/api/v1/admin/folder/save`, values)
            .then(response => {
                var { data } = response.data;
                dispatch({
                    type: FOLDER_SAVE,
                    data: data
                });
            })
            .catch(e => e);
    };
}

export const UPDATE_EXPANDED_LIST = "UPDATE_EXPANDED_LIST";
export function updateExpandList(data) {
    return {
        type: UPDATE_EXPANDED_LIST,
        data: data
    };
}

export function setCurrentMedia(data) {
    return {
        type: SET_CURRENT_MEDIA,
        data: data
    };
}
