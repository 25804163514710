import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import {
    SubmitButton,
    FormikInput,
    Panel,
    Column,
    FormLabel,
    FormikAdvancedSelect,
    Modal,
    Loading,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import NewDealerForm from "./NewDealerForm";

const Schema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, "Too Short!")
        .max(255, "Too Long!")
        .required("Required"),
    last_name: Yup.string()
        .min(2, "Too Short!")
        .max(255, "Too Long!")
        .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    primary_group_uuid: Yup.string().required("Required"),
    password_required: Yup.bool(),
    password: Yup.string().when("password_required", {
        is: true,
        then: Yup.string().required("Required"),
    }),
    password_confirmation: Yup.string().when("password_required", {
        is: true,
        then: Yup.string()
            .oneOf([Yup.ref("password")], "Passwords must match")
            .required("Required"),
    }),
});

const Label = styled.label`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
`;

const StyledButton = styled.button`
    background: none;
    outline: none;
    border: none;
    color: blue;
    text-decoration: underline;
    display: inline;
    cursor: pointer;
    float: right;
    position: relative;
    padding: 0;
    margin-bottom: 0.3rem;
`;

const RegisterForm = (props) => {
    const { autoActivation } = props;
    const [loadingState, setLoadingState] = useState(false);
    const [settingsLoadingState, setSettingsLoadingState] = useState(false);
    const [jobTitles, setJobTitles] = useState([]);
    const [success, setSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [newDealerModalShowing, setNewDealerModalShowing] = useState(false);

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        setSettingsLoadingState(true);

        axios
            .get(`/api/v1/registration-settings`)
            .then(({ data }) => {
                setJobTitles(data.roles);
            })
            .finally(() => {
                setSettingsLoadingState(false);
            });
    };

    const loadDealers = (inputValue, callback) => {
        axios
            .get(`/api/v1/dealer-search?type=dealer&search=${inputValue}`)
            .then(({ data }) => {
                callback(
                    data.data.map((data) => {
                        return {
                            label: data.text,
                            value: data.value,
                        };
                    })
                );
            });
    };

    const loadDealerGroups = (inputValue, callback) => {
        axios
            .get(`/api/v1/dealer-search?type=dealer-group&search=${inputValue}`)
            .then(({ data }) => {
                callback(
                    data.data.map((data) => {
                        return {
                            label: data.text,
                            value: data.value,
                        };
                    })
                );
            });
    };

    const onSubmit = (values, { setErrors }) => {
        setLoadingState(true);
        setFormError(false);

        const sendValues = {
            ...values,
            primary_group_uuid: values.primary_group_uuid.value,
            autoActivation: autoActivation,
        };

        axios
            .post(`/api/v1/registration`, sendValues)
            .then((response) => {
                setSuccess(true);
                return;
            })
            .catch((e) => {
                parseValidationErrors(e)
                    .then(({ errors }) => {
                        setErrors(errors);
                    })
                    .catch((e) => {
                        if (
                            e.response &&
                            e.response.status &&
                            e.response.status === 403
                        ) {
                            this.setState((state) => ({
                                tokenError: e.response.data.data.response_trans,
                            }));
                        }
                    });

                setFormError(true);
            })
            .finally(() => {
                setLoadingState(false);
            });
    };

    const ErrorMessage = styled.p`
        color: red;
    `;

    const getRoleUuid = (dealerType) => {
        if (!jobTitles.length) {
            return "";
        }

        const nameToFind =
            dealerType === "dealer" ? "Dealer User" : "Dealer group user";

        const jobTitle = jobTitles.find((item) => item.text === nameToFind);

        return jobTitle ? jobTitle.value : "";
    };

    const handleDealerTypeChange = (type, setFieldValue) => {
        setFieldValue("dealer_type", type);
        setFieldValue("primary_group_uuid", "");
        setFieldValue("primary_role_uuid", getRoleUuid(type));
    };

    // if (redirectTo) {
    //     return <Redirect to={redirectTo} />;
    // }

    const render = () => {
        return (
            <>
                <React.Fragment>
                    {success ? (
                        <Column>
                            <h2>{trans("Success!")}</h2>
                            <p
                                style={{
                                    textAlign: "center",
                                    padding: "1rem",
                                    margin: "0",
                                }}
                            >
                                {trans("Your registration was successful.")}
                            </p>

                            <Link to="/auth/login">
                                {trans("Go to Log in")}
                            </Link>
                        </Column>
                    ) : (
                        <Formik
                            initialValues={{
                                first_name: get(props, "user.first_name", ""),
                                last_name: get(props, "user.last_name", ""),
                                email: get(props, "user.email", ""),
                                dealer_type: "dealer",
                                primary_group_uuid: "",
                                primary_role_uuid: getRoleUuid("dealer"),
                                password: "",
                                password_confirmation: "",
                                password_required: !!autoActivation,
                            }}
                            onSubmit={onSubmit}
                            validationSchema={Schema}
                        >
                            {({ values, setFieldValue, errors }) => {
                                return (
                                    <Form className="form-group">
                                        <FormikInput
                                            type="text"
                                            name="first_name"
                                            label="First Name"
                                            placeholder="Joe"
                                            value={values.first_name}
                                        />
                                        <FormikInput
                                            type="text"
                                            name="last_name"
                                            label="Last name"
                                            placeholder="Bloggs"
                                            value={values.last_name}
                                        />
                                        <FormikInput
                                            type="text"
                                            name="email"
                                            label="Email"
                                            placeholder="user@domain.com"
                                            value={values.email}
                                        />

                                        {autoActivation && (
                                            <React.Fragment>
                                                <p>
                                                    {trans(
                                                        "Passwords must be at least 8 characters long and contain at least 1 letter, 1 number and 1 special character."
                                                    )}
                                                </p>
                                                <FormikInput
                                                    type="password"
                                                    name="password"
                                                    label="Password"
                                                    value={values.password}
                                                />
                                                <FormikInput
                                                    type="password"
                                                    name="password_confirmation"
                                                    label="Confirm Password"
                                                    value={
                                                        values.password_confirmation
                                                    }
                                                />
                                            </React.Fragment>
                                        )}

                                        <div style={{ marginBottom: "0.8rem" }}>
                                            <FormLabel name="dealer_type">
                                                I am registering on behalf of
                                                a...
                                            </FormLabel>
                                            <Label>
                                                <Field
                                                    checked={
                                                        values.dealer_type ===
                                                        "dealer"
                                                    }
                                                    type="radio"
                                                    name="dealer_type"
                                                    value="dealer"
                                                    onChange={() =>
                                                        handleDealerTypeChange(
                                                            "dealer",
                                                            setFieldValue
                                                        )
                                                    }
                                                />
                                                Dealership
                                            </Label>
                                            <Label>
                                                <Field
                                                    type="radio"
                                                    name="dealer_type"
                                                    value="dealer_group"
                                                    onChange={() =>
                                                        handleDealerTypeChange(
                                                            "dealer_group",
                                                            setFieldValue
                                                        )
                                                    }
                                                />
                                                Dealer Group
                                            </Label>
                                        </div>

                                        <FormLabel
                                            style={{
                                                float: "left",
                                            }}
                                            name="primary_group_uuid"
                                        >
                                            {values.dealer_type === "dealer"
                                                ? trans("Dealership")
                                                : trans("Dealer Group")}
                                        </FormLabel>
                                        <StyledButton
                                            onClick={() =>
                                                setNewDealerModalShowing(true)
                                            }
                                            type="button"
                                        >
                                            Can't find your{" "}
                                            {values.dealer_type === "dealer"
                                                ? "dealership"
                                                : "dealer group"}
                                            ?
                                        </StyledButton>
                                        <div
                                            style={{
                                                clear: "both",
                                                marginTop: "0.3rem",
                                            }}
                                        >
                                            <FormikAdvancedSelect
                                                async
                                                loadFunction={
                                                    values.dealer_type ===
                                                    "dealer"
                                                        ? loadDealers
                                                        : loadDealerGroups
                                                }
                                                name="primary_group_uuid"
                                                placeholder="Start typing to search options"
                                            />
                                        </div>
                                        {formError && (
                                            <ErrorMessage>
                                                {trans(
                                                    "There was an error submitting your form."
                                                )}
                                            </ErrorMessage>
                                        )}
                                        <SubmitButton
                                            expanded
                                            style={{ marginTop: "1rem" }}
                                            label="Register"
                                            submitting={loadingState === true}
                                            disabled={loadingState === true}
                                        />
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </React.Fragment>

                <Modal
                    onClose={() => {
                        setNewDealerModalShowing(false);
                    }}
                    visible={newDealerModalShowing}
                >
                    <div style={{ padding: "2rem" }}>
                        <h2>
                            {trans(
                                "Can't find your Dealership / Dealer group?"
                            )}
                        </h2>

                        <p>
                            {trans(
                                "Please use the form below to provide your details to our team for us to get you setup."
                            )}
                        </p>

                        <NewDealerForm
                            successCallback={() =>
                                setNewDealerModalShowing(false)
                            }
                            props
                        />
                    </div>
                </Modal>
            </>
        );
    };

    return <>{settingsLoadingState ? <Loading /> : render()}</>;
};

export default RegisterForm;
