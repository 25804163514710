import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faSort,
    faSortUp,
    faSortDown,
} from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { EmptyRow } from "../../components/EmptyRow";
import { Navigation } from "../../forms/NavigationForm";

const PaddedDiv = styled.div`
    padding: 3em;
`;

const TableHeader = styled.div`
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;

    &:hover {
        text-decoration: underline;
    }
`;

export const NavigationTable = ({
    items,
    loaded,
    path,
    pagination = null,
    deleteItem,
    onPageChange,
    onOrderBy,
    onPerPageChange,
    orderBy,
    orderByDirection,
}: NavigationTableProps) => {
    const currentPage = pagination ? pagination.current_page : 1;

    const [deleteNavigationModalShowing, setDeleteNavigationModalShowing] =
        useState(false);
    const [deleteNavigationName, setDeleteNavigationName] = useState("");
    const [deleteNavigationId, setDeleteNavigationId] = useState(null);

    const renderSortIcon = (item) => {
        if (item === orderBy) {
            if (orderByDirection === "ASC") {
                return <FontAwesomeIcon icon={faSortUp} />;
            } else {
                return <FontAwesomeIcon icon={faSortDown} />;
            }
        } else {
            return <FontAwesomeIcon icon={faSort} />;
        }
    };

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <TableHeader onClick={() => onOrderBy("slug")}>
                                {trans("Slug")} {renderSortIcon("slug")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("position")}>
                                {trans("Position")} {renderSortIcon("position")}
                            </TableHeader>
                        </th>
                        <th>{trans("Parent")}</th>
                        <th>{trans("Name")}</th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("path")}>
                                {trans("Path")} {renderSortIcon("path")}
                            </TableHeader>
                        </th>
                        <th>{trans("Show in Nav")}</th>
                        <th>{trans("Color")}</th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>

                <tbody>
                    {!loaded ? (
                        <EmptyRow colSpan={8}>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow colSpan={8}>
                            {trans("No items found")}
                        </EmptyRow>
                    ) : (
                        items.map((item) => {
                            return (
                                <tr>
                                    <td>{item.slug}</td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.position}
                                    </td>
                                    <td>{item.parent_name}</td>
                                    <td>{item.name}</td>
                                    <td>{item.path}</td>
                                    <td>
                                        {item.show_in_nav
                                            ? trans("Yes")
                                            : trans("No")}
                                    </td>
                                    <td>
                                        <span
                                            style={{
                                                borderBottom:
                                                    "5px solid " +
                                                    item.underline_color,
                                            }}
                                        >
                                            {item.underline_color}
                                        </span>
                                    </td>
                                    <td>
                                        <IconTextButton
                                            as={Link}
                                            to={`${path}/edit/${item.uuid}?page=${currentPage}`}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEdit}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            type="alert"
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                            onClick={(event) => {
                                                setDeleteNavigationId(
                                                    item.uuid
                                                );
                                                setDeleteNavigationModalShowing(
                                                    true
                                                );
                                                setDeleteNavigationName(
                                                    item.name
                                                );
                                            }}
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
            {deleteNavigationModalShowing && (
                <Modal
                    visible={deleteNavigationModalShowing}
                    onClose={() => {
                        setDeleteNavigationModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h1>
                            You are about delete navigation:{" "}
                            {deleteNavigationName}
                        </h1>

                        <p>{trans("Are you sure you want to proceed?")}</p>
                        <Button
                            type="hollow"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeleteNavigationModalShowing(false);
                            }}
                        >
                            {trans("Cancel")}
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                deleteItem(deleteNavigationId)
                                    .then(() => {
                                        setDeleteNavigationModalShowing(false);
                                    })
                                    .catch((e) =>
                                        setDeleteNavigationModalShowing(false)
                                    );
                            }}
                        >
                            {trans("Delete")}
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}
        </React.Fragment>
    );
};

interface NavigationTableProps {
    items: Navigation[];
    loaded: boolean;
    path: string;
    pagination?: any;
    orderBy: string;
    orderByDirection: string;
    onPageChange?: (number) => void;
    deleteItem: (number) => Promise<void>;
    onOrderBy?: (string) => void;
    onPerPageChange?: ({ value }) => void;
}

export default NavigationTable;
