import axios from "axios";

import { HIDE_MODAL } from "../../../dashboardActions";

export const COMMS_SET_FOLLOW_UP_STATUS = "COMMS_SET_FOLLOW_UP_STATUS";

export function setFollowedUp(communication, status, message = "") {
    return dispatch => {
        const values = {
            communication,
            status,
            message
        };
        axios
            .post(`/api/v1/customer/communication/follow-up`, values)
            .then(response => {
                //console.log(response.data.data);
                const data = response.data.data;
                //console.log(data);

                //TODO - Implement the code to use the following action
                //It will need to find the panel in the redux store (this will need to be passed in)
                //And then update the communication within it's data
                //At the current time the code is just toggling in the component without any knowledge of whether
                //the request succeeded.

                dispatch({
                    type: COMMS_SET_FOLLOW_UP_STATUS,
                    communication: data
                });
                dispatch({
                    type: HIDE_MODAL
                });
            })
            .catch(e => e);
    };
}
