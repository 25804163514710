import React from "react";
import { get } from "lodash";
import { CurrencyInput, FormRow } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FormWrapper, YesNoSelector } from "./formLayoutComponents";

const BudgetForm = ({
    loadingState,
    errors,
    params,
    onChange,
    excludedOptionalFields,
}) => {
    return (
        <FormWrapper>
            <FormRow
                helpText={trans("Required")}
                label={trans("Budget")}
                name={"total_budget"}
                error={get(errors, "total_budget")}
            >
                <CurrencyInput
                    name="total_budget"
                    type="number"
                    min="0"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "total_budget", "")}
                    showError={false}
                />
            </FormRow>
            {!excludedOptionalFields.includes("budget_confirmed") && (
                <FormRow
                    helpText={trans("Required")}
                    label={trans("Has the budget been signed off?")}
                    name={"budget_confirmed"}
                    error={get(errors, "budget_confirmed")}
                >
                    <YesNoSelector
                        name="budget_confirmed"
                        value={get(params, "budget_confirmed")}
                        onChange={onChange}
                        loading={loadingState === "loading"}
                    />
                </FormRow>
            )}
        </FormWrapper>
    );
};

export default BudgetForm;
