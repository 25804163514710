import React from "react";
import PropTypes from "prop-types";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledInput from "../styledElements/styledInput";

const InputField = ({
    type,
    name,
    label,
    readOnly,
    meta,
    onChange,
    error,
    labelWidth,
    labelAlign,
    labelStyle,
    labelMarginBottom,
    id,
    wrapperStyle,
    className,
    ...rest
}) => {
    let fieldOptions = {};

    if (readOnly) {
        fieldOptions["readOnly"] = "readOnly";
    }

    if (error) {
        fieldOptions["error"] = "error";
    }

    const handleChange = (event) => {
        onChange(
            {
                name: name,
                value: event.target.value,
            },
            event
        );
    };

    return (
        <div style={wrapperStyle} className={className}>
            {label && (
                <FormLabel
                    align={labelAlign}
                    width={labelWidth}
                    name={name}
                    marginBottom={labelMarginBottom}
                    style={labelStyle}
                    {...rest}
                >
                    {label}
                </FormLabel>
            )}
            <StyledInput
                type={type}
                name={name}
                id={id || name}
                labelWidth={labelWidth}
                onChange={handleChange}
                {...fieldOptions}
                {...rest}
            />
            <InputError error={error} />
        </div>
    );
};

InputField.displayName = "InputField";

InputField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    //error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    labelStyle: PropTypes.object,
    labelMarginBottom: PropTypes.number,
};

InputField.defaultProps = {
    size: "default",
    type: "text",
    readOnly: false,
    //error: "",
    labelMarginBottom: 1,
    wrapperStyle: {},
};

export default InputField;
