import React, { memo, useRef, useEffect } from "react";

import StyledCheckbox from "../styledElements/styledCheckbox";

const CheckboxTreeIndividualCheckbox = ({
    option,
    name,
    setSize,
    isChecked,
    handleChange,
    style,
    index,
    windowWidth,
}) => {
    const checkboxRef = useRef();

    useEffect(() => {
        setSize(index, checkboxRef.current.getBoundingClientRect().height + 10);
    }, [setSize, index, windowWidth]);

    return (
        <div key={option.value} style={style}>
            <label ref={checkboxRef} style={{ display: "flex" }}>
                <StyledCheckbox
                    name={name}
                    checked={isChecked}
                    onChange={(event) => handleChange(event, option.value)}
                />
                <span>{option.label}</span>
            </label>
        </div>
    );
};

export default memo(CheckboxTreeIndividualCheckbox);
