import React, { useState } from "react";
import styled from "styled-components";
import { Tabordion, IconTextButton } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    add,
    addQuarters,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfQuarter,
    endOfQuarter,
    startOfYear,
    endOfYear,
} from "date-fns";
import { startOfFiscalYear, endOfFiscalYear } from "fiscal-fns";

const PeriodWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    > div {
        height: 100%;
    }
`;
export const PeriodSelector = ({
    onChange,
    fiscalYearPeriodSelector,
    fiscalYearOptions = {
        startDay: 1,
        startMonth: 3,
    },
    extraPeriods = [],
}) => {
    const [tab, setTab] = useState(1);
    const handleChange = (adjustment = 0, period = "weeks") => {
        let adjustedDate = new Date();
        let adjust_period = period === "fiscalYears" ? "years" : period;
        if (period !== "quarters") {
            adjustedDate = add(adjustedDate, {
                [adjust_period]: adjustment,
            });
        } else {
            adjustedDate = addQuarters(adjustedDate, adjustment);
        }

        switch (period) {
            case "weeks":
                onChange(
                    startOfWeek(adjustedDate, { weekStartsOn: 1 }),
                    endOfWeek(adjustedDate, { weekStartsOn: 1 })
                );
                break;
            case "months":
                onChange(startOfMonth(adjustedDate), endOfMonth(adjustedDate));
                break;
            case "quarters":
                onChange(
                    startOfQuarter(adjustedDate),
                    endOfQuarter(adjustedDate)
                );
                break;
            case "years":
                onChange(startOfYear(adjustedDate), endOfYear(adjustedDate));
                break;
            case "fiscalYears":
                onChange(
                    startOfFiscalYear(adjustedDate, fiscalYearOptions),
                    endOfFiscalYear(adjustedDate, fiscalYearOptions)
                );
                break;
            default:
                break;
        }
    };
    return (
        <Tabordion style={{ width: "280px", height: "100%" }} current={tab}>
            <PeriodWrapper heading={trans("Prev")} onTabClick={() => setTab(0)}>
                <IconTextButton onClick={() => handleChange(-1, "weeks")}>
                    {trans("Week")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(-1, "months")}>
                    {trans("Month")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(-1, "quarters")}>
                    {trans("Quarter")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(-1, "years")}>
                    {trans("Year")}
                </IconTextButton>
                {fiscalYearPeriodSelector && (
                    <IconTextButton
                        onClick={() => handleChange(-1, "fiscalYears")}
                    >
                        {trans("Fiscal Year")}
                    </IconTextButton>
                )}
            </PeriodWrapper>
            <PeriodWrapper
                heading={trans("Current")}
                onTabClick={() => setTab(1)}
            >
                <IconTextButton onClick={() => handleChange(0, "weeks")}>
                    {trans("Week")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(0, "months")}>
                    {trans("Month")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(0, "quarters")}>
                    {trans("Quarter")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(0, "years")}>
                    {trans("Year")}
                </IconTextButton>
                {fiscalYearPeriodSelector && (
                    <IconTextButton
                        onClick={() => handleChange(0, "fiscalYears")}
                    >
                        {trans("Fiscal Year")}
                    </IconTextButton>
                )}
            </PeriodWrapper>
            <PeriodWrapper heading={trans("Next")} onTabClick={() => setTab(2)}>
                <IconTextButton onClick={() => handleChange(1, "weeks")}>
                    {trans("Week")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(1, "months")}>
                    {trans("Month")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(1, "quarters")}>
                    {trans("Quarter")}
                </IconTextButton>
                <IconTextButton onClick={() => handleChange(1, "years")}>
                    {trans("Year")}
                </IconTextButton>
                {fiscalYearPeriodSelector && (
                    <IconTextButton
                        onClick={() => handleChange(1, "fiscalYears")}
                    >
                        {trans("Fiscal Year")}
                    </IconTextButton>
                )}
            </PeriodWrapper>
            {extraPeriods.length > 0 && (
                <PeriodWrapper
                    heading={trans("Misc")}
                    onTabClick={() => setTab(3)}
                >
                    {extraPeriods.includes("h1") && (
                        <IconTextButton
                            onClick={() => {
                                let now = new Date();
                                onChange(
                                    startOfYear(now),
                                    add(startOfYear(now), {
                                        months: 6,
                                        days: -1,
                                    })
                                );
                            }}
                        >
                            {trans("Current H1")}
                        </IconTextButton>
                    )}
                    {extraPeriods.includes("h2") && (
                        <IconTextButton
                            onClick={() => {
                                let now = new Date();
                                onChange(
                                    add(startOfYear(now), {
                                        months: 6,
                                    }),
                                    endOfYear(now)
                                );
                            }}
                        >
                            {trans("Current H2")}
                        </IconTextButton>
                    )}
                    {extraPeriods.includes("fh1") && (
                        <IconTextButton
                            onClick={() => {
                                let now = new Date();
                                onChange(
                                    startOfFiscalYear(now, fiscalYearOptions),
                                    add(
                                        startOfFiscalYear(
                                            now,
                                            fiscalYearOptions
                                        ),
                                        {
                                            months: 6,
                                            days: -1,
                                        }
                                    )
                                );
                            }}
                        >
                            {trans("FY H1")}
                        </IconTextButton>
                    )}

                    {extraPeriods.includes("fh2") && (
                        <IconTextButton
                            onClick={() => {
                                let now = new Date();
                                onChange(
                                    add(
                                        startOfFiscalYear(
                                            now,
                                            fiscalYearOptions
                                        ),
                                        {
                                            months: 6,
                                        }
                                    ),
                                    endOfFiscalYear(now, fiscalYearOptions)
                                );
                            }}
                        >
                            {trans("FY H2")}
                        </IconTextButton>
                    )}
                </PeriodWrapper>
            )}
        </Tabordion>
    );
};

export default PeriodSelector;
