import storage from "local-storage-fallback";

export const getItem = (key, defaultValue = null) => {
    //Get from local localStorage if available
    if (storage.getItem(key)) {
        return { [key]: storage.getItem(key) };
    }
    return { [key]: defaultValue };
};

export const setItem = (key, value) => {
    const data =
        value !== null && typeof value === "object"
            ? JSON.stringify(value)
            : value;

    // console.log("Setting item: " + key, value, data);
    storage.setItem(key, data);
};

export const clearStorage = () => {
    storage.clear();
};

export const clearStorageExcept = (keysToKeep = []) => {
    let savedKeys = [];
    keysToKeep.map(key => {
        savedKeys.push(getItem(key));
    });

    clearStorage();

    savedKeys.forEach(savedKey => {
        // console.log(savedKey);
        Object.entries(savedKey).forEach(([key, value]) => {
            setItem(key, value);
        });
    });
};
