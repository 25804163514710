import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";

// export const LOADED_DATA = "LOADED_DATA";
export const LOADING_DATA = "LOADING_DATA";
export const LOAD_USER_ASSETS = "LOAD_USER_ASSETS";
export const SELECT_USER_ASSET = "SELECT_USER_ASSET";
export const UPDATE_ASSET_STATE = "UPDATE_ASSET_STATE";
export const SAVING_DATA = "SAVING_DATA";

// export const SET_STEP = "SET_STEP";

export let initialState = {
    data: [],
    loaded: false,
    pagination: { current_page: 1 },
    selectedUserAsset: null,
};
// const reducer = (state: AssetDetailType, action: ActionType) => {
const reducer = (state, action) => {
    switch (action.type) {
        case LOADING_DATA:
            return {
                ...state,
                loaded: false,
            };

        case LOAD_USER_ASSETS:
            return {
                ...state,
                loaded: true,
                data: action.data,
                pagination: action.pagination,
            };
        case SELECT_USER_ASSET:
            return {
                ...state,
                selectedUserAsset: action.userAsset,
            };
        default:
            return state;
    }
};

export const useUserAssets = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [assetsLoading, setAssetsLoading] = useState(false);

    const getUserAssets = (scope = "user", page = 1) => {
        dispatch({
            type: LOADING_DATA,
        });

        setAssetsLoading(true);

        return axios
            .get(`/api/v1/user-assets?scope=${scope}&page=${page}`)
            .then(({ data }) => {
                dispatch({
                    type: LOAD_USER_ASSETS,
                    data: data.data,
                    pagination: data.meta,
                });

                return data;
            })
            .finally(() => {
                setAssetsLoading(false);
            });
    };

    useEffect(() => {
        getUserAssets();
    }, []);

    const selectUserAsset = (userAsset) => {
        dispatch({
            type: SELECT_USER_ASSET,
            userAsset,
        });
    };

    return {
        userAssets: state,
        userAssetsDispatch: dispatch,
        getUserAssets,
        selectUserAsset,
        assetsLoading,
    };
};
