import React from "react";
import { trans } from "@cortexglobal/rla-intl";

import {
    Panel,
    Select,
    DatePicker,
    InputField,
    MultiCheckbox,
    ConditionalWrapper,
    FormRow,
    GroupAndUserSelector,
} from "@cortexglobal/rla-components";

import { SectionHeading } from "./components/SectionHeading";

const credentialsRequired = (asset, declarations) => {
    return (
        declarations[asset.status] &&
        declarations[asset.status].credentialsRequired
    );
};

const mandatoryCheck = (asset, declarations) => {
    return (
        declarations[asset.status] && declarations[asset.status].mandatoryCheck
    );
};

const termsDisabled = (asset, declarations) => {
    if (!credentialsRequired(asset, declarations)) {
        return false;
    }

    let d = declarations[asset.status];
    if (d.mandatoryFields && Array.isArray(d.mandatoryFields)) {
        let flag = false;
        for (let i = 0; i < d.mandatoryFields.length; i++) {
            const value = asset[d.mandatoryFields[i]];
            if (value === "" || value === null || !value instanceof Date) {
                flag = true;
            }
        }
        return flag;
    }
    return false;
};

const termsCheckBlock = (
    asset,
    assetDeclarations,
    standardOnChange,
    singleColumn,
    collapse,
    errors,
    inputName = "accepted_terms"
) => {
    return (
        <FormRow
            singleColumn={singleColumn}
            collapse={collapse}
            name={inputName}
            label={trans("Confirmation")}
            error={errors[inputName]}
            // helpText={trans("Optional")}
            required={true}
        >
            <MultiCheckbox
                name={inputName}
                options={[
                    {
                        value: "accepted",
                        name: { inputName },
                        text: assetDeclarations[asset.status].text,
                    },
                ]}
                value={asset[inputName]}
                disabled={termsDisabled(asset, assetDeclarations)}
                onChange={standardOnChange}
            />
        </FormRow>
    );
};

const Availability = ({
    standardOnChange,
    groups,
    groupsInHierarchy,
    asset,
    assetAdmin: {
        assetStatusOptionsLoaded,
        assetStatusOptions,
        assetDeclarations,
    },
    errors = {},
    singleColumn = false,
    showSectionHeading = true,
    collapse = false,
    excludedFields = [],
}) => {
    const credentialsUpdate = ({ name, value }) => {
        standardOnChange({ name, value });
        standardOnChange({
            name: "accepted_terms",
            value: "",
        });
    };

    return (
        <React.Fragment>
            {showSectionHeading && (
                <SectionHeading>{trans("Asset Status")}</SectionHeading>
            )}

            <ConditionalWrapper
                condition={!singleColumn}
                wrapper={(children) => <Panel>{children}</Panel>}
            >
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="status"
                    label={trans("Status")}
                    error={errors.status}
                    // helpText={trans("Optional")}
                >
                    <Select
                        name="status"
                        defaultValue={asset.status}
                        value={asset.status}
                        //labelProps={{ label: trans("Status") }}
                        options={
                            assetStatusOptionsLoaded ? assetStatusOptions : []
                        }
                        onChange={credentialsUpdate}
                        tags={false}
                        multi={false}
                        creatable={false}
                        placeholder="Select a Status..."
                    />
                </FormRow>

                {!credentialsRequired(asset, assetDeclarations) &&
                    mandatoryCheck(asset, assetDeclarations) &&
                    termsCheckBlock(
                        asset,
                        assetDeclarations,
                        standardOnChange,
                        singleColumn,
                        collapse,
                        errors,
                        "terms"
                    )}
            </ConditionalWrapper>

            {credentialsRequired(asset, assetDeclarations) && (
                <React.Fragment>
                    {!singleColumn && (
                        <SectionHeading>
                            {trans("Approval Credentials")}
                        </SectionHeading>
                    )}

                    <Panel>
                        <FormRow
                            singleColumn={singleColumn}
                            collapse={collapse}
                            name="certification_ref"
                            label={trans("Document / Reference Number")}
                            error={errors.certification_ref}
                            // helpText={trans("Optional")}
                        >
                            <InputField
                                name="certification_ref"
                                value={asset.certification_ref}
                                // label={trans("Approved for sharing reference")}
                                onChange={credentialsUpdate}
                            />
                        </FormRow>

                        <FormRow
                            singleColumn={singleColumn}
                            collapse={collapse}
                            name="certification_awarded_date"
                            label={trans("Document Approval Date")}
                            error={errors.certification_awarded_date}
                            // helpText={trans("Optional")}
                        >
                            <DatePicker
                                name="certification_awarded_date"
                                style={{ display: "block" }}
                                value={
                                    new Date(asset.certification_awarded_date)
                                }
                                onChange={credentialsUpdate}
                                maxDate={new Date()}
                                allowEmpty={true}
                            />
                        </FormRow>

                        <FormRow
                            singleColumn={singleColumn}
                            collapse={collapse}
                            name="expiration_date"
                            label={trans("Expiration Date")}
                            error={errors.expiration_date}
                            // helpText={trans("Optional")}
                        >
                            <DatePicker
                                name="expiration_date"
                                style={{ display: "block" }}
                                value={new Date(asset.expiration_date)}
                                onChange={credentialsUpdate}
                                allowEmpty={true}
                            />
                        </FormRow>

                        {!singleColumn && (
                            <FormRow
                                singleColumn={singleColumn}
                                collapse={collapse}
                                name="approved_for_use_in"
                                label={trans("Approval Location")}
                                error={errors.approved_for_use_in}
                                // helpText={trans("Optional")}
                            >
                                <p>{asset.created_by_group_name}</p>
                            </FormRow>
                        )}

                        {termsCheckBlock(
                            asset,
                            assetDeclarations,
                            standardOnChange,
                            singleColumn,
                            collapse,
                            errors,
                            "certification_terms"
                        )}
                    </Panel>
                </React.Fragment>
            )}
            {showSectionHeading && (
                <SectionHeading>{trans("Availability")}</SectionHeading>
            )}

            <ConditionalWrapper
                condition={!singleColumn}
                wrapper={(children) => <Panel>{children}</Panel>}
            >
                <GroupAndUserSelector
                    onChange={standardOnChange}
                    groups={groups}
                    groupsInHierarchy={groupsInHierarchy}
                    singleColumn={singleColumn}
                    collapse={collapse}
                    errors={errors}
                    view_type={asset.view_type}
                    allowed_users={asset.allowed_users}
                    allowed_groups={asset.allowed_groups}
                    allowed_groups_individual={asset.allowed_groups_individual}
                />

                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="available_from"
                    label={trans("Available From")}
                    error={errors.available_from}
                    // helpText={trans("Optional")}
                >
                    <DatePicker
                        name="available_from"
                        style={{ display: "block" }}
                        value={new Date(asset.available_from)}
                        onChange={standardOnChange}
                        maxDate={
                            asset.available_to
                                ? new Date(asset.available_to)
                                : null
                        }
                    />
                </FormRow>
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="available_to"
                    label={trans("Available To")}
                    error={errors.available_to}
                    // helpText={trans("Optional")}
                >
                    <DatePicker
                        name="available_to"
                        style={{ display: "block" }}
                        value={new Date(asset.available_to)}
                        onChange={standardOnChange}
                        minDate={
                            asset.available_from ? asset.available_from : null
                        }
                    />
                </FormRow>
            </ConditionalWrapper>
        </React.Fragment>
    );
};

export default Availability;
