import React, { useEffect, useReducer } from "react";
import axios from "axios";

// export const LOADED_DATA = "LOADED_DATA";
export const LOADING_DATA = "LOADING_DATA";
export const LOAD_USER_ASSET = "LOAD_USER_ASSET";
export const SELECT_USER_ASSET = "SELECT_USER_ASSET";
export const UPDATE_ASSET_STATE = "UPDATE_ASSET_STATE";
export const SAVING_DATA = "SAVING_DATA";

// export const SET_STEP = "SET_STEP";

export let initialState = {
    userAsset: null,
    assetEdited: false,
    assetLoaded: false,
    assetSaving: false,
    // selectedUserAsset: null
};
// const reducer = (state: AssetDetailType, action: ActionType) => {
const reducer = (state, action) => {
    switch (action.type) {
        // case LOADING_DATA:
        //     return initialState;
        // case LOADED_DATA:
        //     return {
        //         ...state,
        //         assetLoaded: true,
        //         asset: action.data,
        //         assetEdited: false,
        //         assetSaving: false
        //     };
        case LOAD_USER_ASSET:
            return {
                ...state,
                assetLoaded: true,
                userAsset: action.data,
                assetEdited: false,
                assetSaving: false,
            };
        case SAVING_DATA:
            return {
                ...state,
                assetSaving: action.data,
            };
        case UPDATE_ASSET_STATE:
            return {
                ...state,
                userAsset: {
                    ...state.userAsset,
                    [action.name]: action.value,
                },
                assetEdited: true,
            };
        default:
            return state;
    }
};
// export const useAssetAdmin = (
//     assetUuid: string
// ): [AssetDetailType, (action: ActionType) => void] => {
export const useUserAsset = (uuid) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (!uuid) {
            return;
        }
        dispatch({
            type: LOADING_DATA,
        });
        axios
            .get(`/api/v1/user-assets/${uuid}`)
            .then(({ data }) => {
                dispatch({
                    type: LOAD_USER_ASSET,
                    data: data.data,
                });

                return data;
            })
            .catch((e) => e);
    }, []);

    return [state, dispatch];
};
