import { useEffect, useReducer, useCallback } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { Pages, PageSetup, MicrositeConfig } from "../interfaces/microsite";

interface PageItem {
    page: string;
    disableSequencing?: boolean;
    url: string;
    transitions: string[];
}
type PageItemArray = PageItem[];
const addSlashIfNeeded = (baseUrl: string) => {
    const lastChar = baseUrl[baseUrl.length - 1];
    return lastChar === "/" ? baseUrl : `${baseUrl}/`;
};

const getCurrentLinkIndex = (
    history: RouteComponentProps["history"],
    pages: PageItemArray,
    safeBaseUrl: string
) => {
    const pageIndex = pages.findIndex((page) => {
        // console.log(
        //     "page.url",
        //     page.url,
        //     "history.location",
        //     history.location.pathname.replace(safeBaseUrl, ""),
        //     safeBaseUrl
        // );
        return (
            page.url ===
            "/" + history.location.pathname.replace(safeBaseUrl, "")
        );
    });

    return pageIndex >= 0 ? pageIndex : 0;
};

const getPageTransitions = (page: PageSetup) => {
    // console.log("page.transitions", page.transitions);
    if (typeof page.transitions === "undefined") {
        return [];
    }
    return page.transitions;
};

const buildSiteNavigation = (pages: Pages) => {
    return pages.reduce(
        (navigationArray: PageItemArray, [pageName, pageSetup]) => {
            //Don't include hidden pages
            if (pageSetup.hidden) {
                return navigationArray;
            }
            const page = {
                page: pageName,
                url: `/${pageName}`,
                transitions: getPageTransitions(pageSetup),
                disableSequencing: pageSetup.disableSequencing
                    ? pageSetup.disableSequencing
                    : false,
            };

            navigationArray.push(page);
            return navigationArray;
        },
        []
    );
};

export interface NavigationState {
    pageChanged: boolean;
    currentPageIndex: number;
    currentTransitionIndex: number;
    subTransitionInProgress: boolean;
    pages: PageItemArray;
}
export type ActionType = {
    type:
        | "BUILD_NAVIGATION"
        | "SET_PAGE_INDEX"
        | "GO_TO_PAGE"
        | "PAGE_UPDATED"
        | "MOVE_FORWARD"
        | "MOVE_BACKWARD"
        | "SUB_TRANSITION_IN_PROGRESS"
        | "GO_HOME";
    [key: string]: any;
};

export const BUILD_NAVIGATION = "BUILD_NAVIGATION";
export const SET_PAGE_INDEX = "SET_PAGE_INDEX";
export const PAGE_UPDATED = "PAGE_UPDATED";
export const MOVE_FORWARD = "MOVE_FORWARD";
export const MOVE_BACKWARD = "MOVE_BACKWARD";
export const GO_TO_PAGE = "GO_TO_PAGE";
export const SUB_TRANSITION_IN_PROGRESS = "SUB_TRANSITION_IN_PROGRESS";
export const GO_HOME = "GO_HOME";

const hasSequencedVideoTransitions = (state: NavigationState) => {
    return state.pages[state.currentPageIndex].transitions.some(
        (transition) => transition.name === "sequenced_video"
    );
};

const reducer = (state: NavigationState, action: ActionType) => {
    switch (action.type) {
        case BUILD_NAVIGATION:
            return {
                ...state,
                pages: action.pages,
            };
        case SET_PAGE_INDEX:
            return {
                ...state,
                currentPageIndex: action.pageIndex,
                pageChanged: true,
                currentTransitionIndex: 0,
            };
        case GO_TO_PAGE:
            return {
                ...state,
                currentPageIndex: state.pages.findIndex((page) => {
                    return page.url === action.url;
                }),
                pageChanged: true,
                currentTransitionIndex: 0,
            };
        case PAGE_UPDATED:
            return {
                ...state,
                pageChanged: false,
            };
        case MOVE_FORWARD:
            //If there's a sub transition in progress that element is in charge so just return the state
            if (state.subTransitionInProgress) {
                return state;
            }

            //If there's transitions available use them if they're not disabled ()
            if (
                !state.pages[state.currentPageIndex].disableSequencing &&
                state.pages[state.currentPageIndex].transitions.length >
                    state.currentTransitionIndex &&
                !hasSequencedVideoTransitions(state)
            ) {
                return {
                    ...state,
                    currentTransitionIndex: state.currentTransitionIndex + 1,
                };
            }

            //If there's pages available use them
            if (state.currentPageIndex + 1 < state.pages.length) {
                const nextPageIndex = state.currentPageIndex + 1;
                const nextPage = state.pages[nextPageIndex];
                return {
                    ...state,
                    pageChanged: true,
                    currentPageIndex: nextPageIndex,
                    currentTransitionIndex:
                        nextPage.disableSequencing === true
                            ? nextPage.transitions.length
                            : 0,
                };
            }

            //Otherwise there's no forward transitions left so leave it as it is.
            //TOOO - Is there something useful we could do here (e.g. end of slideshow?)
            console.log("LAST PAGE");
            return state;

        case MOVE_BACKWARD:
            //If there's a sub transition in progress that element is in charge so just return the state
            if (state.subTransitionInProgress) {
                return state;
            }
            //If there's transitions available use them if they're not disabled
            if (
                !state.pages[state.currentPageIndex].disableSequencing &&
                state.currentTransitionIndex > 0 &&
                !hasSequencedVideoTransitions(state)
            ) {
                return {
                    ...state,
                    currentTransitionIndex: state.currentTransitionIndex - 1,
                };
            }

            // If there's pages available use them
            // If react renders the reducer twice then check the page hasn't changed and left the transition index at above 0 (signifying a multiple render)
            // Could potentially be removed if this is only produced in development mode
            if (
                state.currentPageIndex > 0 &&
                ((state.currentTransitionIndex === 0 && state.pageChanged) ||
                    !state.pageChanged)
            ) {
                return {
                    ...state,
                    pageChanged: true,
                    currentPageIndex: state.currentPageIndex - 1,
                    currentTransitionIndex:
                        state.pages[state.currentPageIndex].transitions.length,
                };
            }

            //Otherwise there's no forward transitions left so leave it as it is.
            //TOOO - Is there something useful we could do here (e.g. end of slideshow?)
            console.log("FIRST PAGE");
            return state;

        case GO_HOME:
            return {
                ...state,
                currentPageIndex: 0,
                pageChanged: true,
                currentTransitionIndex: 0,
            };

        case SUB_TRANSITION_IN_PROGRESS:
            return {
                ...state,
                subTransitionInProgress: action.subTransitionInProgress,
            };
        default:
            return state;
    }
};

const initialState = {
    pageChanged: false,
    currentPageIndex: 0,
    currentTransitionIndex: 0,
    subTransitionInProgress: false,
    pages: [],
};
export const useSlideshowNavigation = (
    config: MicrositeConfig,
    baseUrl: string,
    editing: boolean,
    footnotesShowing: boolean
) => {
    const history = useHistory();

    const memoizedReducer = useCallback(reducer, [initialState]);
    const [state, dispatch] = useReducer(memoizedReducer, initialState);

    // const [state, dispatch] = useReducer(reducer, initialState);
    // console.log(state);

    const handleSlideshowKeyboardNavigation = (event: KeyboardEvent) => {
        // console.log(event.key);
        if (!footnotesShowing) {
            switch (event.key) {
                case "ArrowLeft":
                    dispatch({ type: MOVE_BACKWARD });
                    break;
                case "ArrowRight":
                    dispatch({ type: MOVE_FORWARD });
                    break;
            }
        }
    };

    const useAsSlideshowEntry = config.globalSettings.find((item) => {
        return item.name === "useAsSlideshow";
    });
    // console.log(config.globalSettings, useAsSlideshowEntry);
    const useAsSlideshow =
        !editing && useAsSlideshowEntry && useAsSlideshowEntry.value === true;

    //Build the site navigation
    useEffect(() => {
        const pages = buildSiteNavigation(config.pages);
        //Build the navigation array
        dispatch({ type: BUILD_NAVIGATION, pages: pages });
        dispatch({
            type: SET_PAGE_INDEX,
            pageIndex: getCurrentLinkIndex(
                history,
                pages,
                addSlashIfNeeded(baseUrl)
            ),
        });
    }, []);

    //If showPage is set then push it into the history
    useEffect(() => {
        //If this is a slideshow then attach the left and right key handlers
        if (useAsSlideshow) {
            document.addEventListener(
                "keydown",
                handleSlideshowKeyboardNavigation,
                true
            );
        }
        return () => {
            if (useAsSlideshow) {
                document.removeEventListener(
                    "keydown",
                    handleSlideshowKeyboardNavigation,
                    true
                );
            }
        };
    }, [handleSlideshowKeyboardNavigation]);

    useEffect(() => {
        if (state.pageChanged) {
            dispatch({ type: PAGE_UPDATED });
            history.push(baseUrl + state.pages[state.currentPageIndex].url);
        }
    });
    return { state, dispatch };
};
