import React, { useCallback } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trans } from "@cortexglobal/rla-intl";

//import { Link } from "react-router-dom";
import {
    Row,
    Column,
    Select,
    Panel,
    StyledDropzone,
    useAddToast
} from "@cortexglobal/rla-components";

const CreateAsset = ({ assetsPath, history, setRefreshSearch }) => {
    const addToast = useAddToast();

    const onDrop = useCallback(acceptedFiles => {
        const fd = new FormData();
        fd.append("file", acceptedFiles[0]);
        axios
            .post(`/api/v1/admin/assets`, fd)
            .then(({ data: { data } }) => {
                setRefreshSearch(true);

                history.push(`${assetsPath}/${data.uuid}/edit`);
            })
            .catch(e => {
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred uploading your file");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000
                });
            });
    }, []);

    return (
        <React.Fragment>
            <Panel>
                <Row>
                    <Column small={10}>
                        <h1>{trans("Add New Asset")}</h1>
                    </Column>
                </Row>
            </Panel>
            <Row>
                <Column>
                    <strong>
                        <label>{trans("Main File")}</label>
                    </strong>
                    <StyledDropzone
                        name="mainFile"
                        onDrop={event => onDrop(event)}
                    >
                        <span>
                            <FontAwesomeIcon icon="upload" />
                        </span>{" "}
                        {trans(
                            "Drop the asset file here, or click to browse for it"
                        )}
                    </StyledDropzone>
                </Column>
            </Row>{" "}
        </React.Fragment>
    );
};

export default CreateAsset;
