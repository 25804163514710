import React, { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { faTrash, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Row,
    Column,
    SubmitButton,
    FormikInput,
    FormikDatePicker,
    FormikAdvancedSelect,
    FormikTextarea,
    FormRow,
    Error,
    IconTextButton,
    FormikRadioArray,
    FormikCurrencyInput,
    Table,
    InputField,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans, injectIntl, FormattedCurrency } from "@cortexglobal/rla-intl";
import {
    parseValidationErrors,
    format,
    isValidDate,
} from "@cortexglobal/cortex-utilities";

import {
    addTask,
    editTask,
    plannerStopEdit,
    getPlannerChildGroups,
} from "../plannerActions";
import GroupSelector from "../components/GroupSelector";
import NewMetricsForm from "../components/NewMetricsForm";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const TaskSchema = Yup.object().shape({
    name: Yup.string().max(100).required(trans("Please enter a campaign name")),
    start_date: Yup.date().required(trans("Please pick a start date")),
    end_date: Yup.date()
        .min(
            Yup.ref("start_date"),
            trans("End date must be after the start date")
        )
        .required(trans("Please pick an end date")),
    planned_spend: Yup.number().min(
        0,
        trans("Planned spend must be a positive number")
    ),
    primary_category_alias: Yup.string().required(
        trans("Please select a category")
    ),
    goal_uuid: Yup.string()
        .typeError(trans("Please select a goal"))
        .required(trans("Please select a goal")),
    metrics: Yup.array().of(
        Yup.object().shape({
            metric_uuid: Yup.string().required(),
            // estimated_value: Yup.number()
            //     .typeError(trans("Please enter a number"))
            //     .nullable(),
            // actual_value: Yup.number(),
        })
    ),
    // can_be_selected_by_request: Yup.number()
    //     .typeError(
    //         trans(
    //             "Please select if can be selected by a campaign application request"
    //         )
    //     )
    //     .required(
    //         trans(
    //             "Please select if can be selected by a campaign application request"
    //         )
    //     ),
    // status: Yup.string().required(
    //     trans("Please select the status of the campaign")
    // ),
});

const PlannerTaskForm = ({
    task,
    startDate,
    endDate,
    filters,
    onGoBack,
    category,
    user,
    group,
    groups,
    allUserGroups,
    intl,
    categories: { data: userCategories },
    plannerStopEdit,
    action,
    relatedUuid,
    eventUuid,
    addTask,
    editTask,
    getPlannerChildGroups,
    config,
    addActivity,
    orphanedActivities,
    setOrphanedActivities,
}) => {
    // console.log("config", config);
    const {
        goals,
        use_focus_products,
        task_activities_required,
        focus_product_limit,
    } = config.data.options;
    const { can } = usePermissions();
    const addToast = useAddToast();
    const [availableBudget, setAvailableBudget] = useState(null);
    const [displayGroupWarning, setDisplayGroupWarning] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getAvailableBudget();
        getProducts();
        return plannerStopEdit;
    }, []);

    const getProducts = () => {
        axios
            .get(`/api/v1/products`)
            .then((response) => {
                const { data } = response.data;
                setProducts(data);
            })
            .catch((e) => e);
    };

    const submitForm = (values, { setErrors, setSubmitting }) => {
        if (displayGroupWarning) {
            confirm(
                intl.formatMessage({
                    id: "Please note that this campaign will not display on your current planner as the group you've selected is outside of your current group hierarchy.  It will still be saved and available to the appropriate groups",
                })
            );
        }
        if (values["can_be_selected_by_request"]) {
            values["can_be_selected_by_request"] = parseInt(
                values.can_be_selected_by_request
            );
        } else {
            values["can_be_selected_by_request"] = 0;
        }

        if (values["start_date"]) {
            values["start_date"] = isValidDate(values.start_date)
                ? format(values.start_date, "yyyy-MM-dd")
                : values.start_date;
        }

        if (values["end_date"]) {
            values["end_date"] = isValidDate(values.end_date)
                ? format(values.end_date, "yyyy-MM-dd")
                : values.end_date;
        }

        if (action === "editTask") {
            values["task_uuid"] = task.uuid;

            editTask(values)
                .then((data) => onGoBack(data))
                .catch((e) => {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    });
                });
        } else {
            //If task_activities_required and no activities are selected, show an error using a toast
            //and return false to prevent the form from submitting
            if (
                task_activities_required &&
                (!orphanedActivities || orphanedActivities.length === 0)
            ) {
                addToast({
                    type: "alert",
                    content: trans("Please add at least one activity"),
                    showFor: 5000,
                });
                setSubmitting(false);
                return false;
            }

            if (relatedUuid) {
                values["related_task_uuid"] = relatedUuid;
            }

            values["activity_uuids"] = orphanedActivities.map(
                (activity) => activity.uuid
            );

            addTask(values)
                .then((data) => {
                    const task = data;
                    if (eventUuid) {
                        //Create a new activity for the event
                        axios
                            .post(`/api/v1/events/${eventUuid}/activity`, {
                                task_uuid: task.uuid,
                            })
                            .then(onGoBack(task));
                    } else {
                        onGoBack(task);
                    }
                })
                .catch((e) => {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    });
                });
        }
        return true;
    };

    const getAvailableBudget = (groupAlias = null) => {
        return axios
            .get(
                `/api/v1/budget/available/${new Date(startDate).getFullYear()}${
                    groupAlias ? `?groupAlias=${groupAlias}` : ""
                }`
            )
            .then((response) => {
                const { data } = response.data;
                setAvailableBudget(data.available);
            })
            .catch((e) => e);
    };

    const groupChanged = ({ name, value }) => {
        //Display a warning if the selected group is not the active one, or one of
        //the child groups as it won't show on the planner after saving
        const isActiveGroup = group?.alias === value;
        const isChildGroup =
            groups &&
            Array.isArray(groups.data) &&
            groups.data.some((group) => {
                return group.alias === value;
            });

        setDisplayGroupWarning(!isActiveGroup || !isChildGroup);
        //Update budget and child groups
        getAvailableBudget(value);
        getPlannerChildGroups(value);
    };

    const renderGoalOptions = (values, errors, setFieldValue) => {
        if (!values.goal_uuid) {
            return null;
        }
        const selectedGoal = goals.find((goalOption) => {
            return goalOption.uuid === values.goal_uuid;
        });

        if (!selectedGoal || !Array.isArray(selectedGoal.metrics)) {
            return null;
        }
        // setFields(selectedFields);
        return selectedGoal.metrics.map((metric, index) => {
            let value = {
                metric_uuid: metric.uuid,
                estimated_value: null,
                actual_value: null,
            };
            const existingValue = values.metrics.find((value) => {
                return value.metric_uuid === metric.uuid;
            });

            if (existingValue) {
                value = existingValue;
            }

            return (
                <FormRow
                    name={metric.uuid}
                    label={metric.name}
                    // error={errors?.metrics?.[index]?.estimated_value}
                    helpText={metric.description}
                >
                    <InputField
                        type={metric.type || "number"}
                        name={metric.uuid}
                        value={value.estimated_value}
                        showError={false}
                        onChange={(event) => {
                            let newValues = [...values.metrics];
                            //If the metrics already contains a value for this metric, update it
                            if (
                                values.metrics.find(
                                    (metricValue) =>
                                        metricValue.metric_uuid === metric.uuid
                                )
                            ) {
                                newValues = values.metrics.map(
                                    (metricValue) => {
                                        if (
                                            metricValue.metric_uuid ===
                                            metric.uuid
                                        ) {
                                            metricValue.estimated_value =
                                                event.value;
                                        }
                                        return metricValue;
                                    }
                                );
                            } else {
                                //Otherwise add a new value
                                newValues.push({
                                    metric_uuid: metric.uuid,
                                    estimated_value: event.value,
                                    actual_value: null,
                                });
                            }

                            setFieldValue("metrics", newValues);
                        }}
                    />{" "}
                </FormRow>
            );
        });
    };

    const handleGoalChange = (event, setFieldValue) => {
        const goalUuid = event.value;
        const selectedGoal = goals.find((goalOption) => {
            return goalOption.uuid === goalUuid;
        });
        setFieldValue("goal_uuid", goalUuid);
        if (!selectedGoal || !Array.isArray(selectedGoal.metrics)) {
            return;
        }
        let metricValues = [];
        selectedGoal.metrics.forEach((metric) => {
            metricValues.push({
                metric_uuid: metric.uuid,
                estimated_value: null,
                actual_value: null,
            });
        });

        setFieldValue("metrics", metricValues);
    };

    const userCategoryOptions = userCategories
        .filter((category) => category.type === "task")
        .map((category) => {
            return {
                value: category.alias,
                text: category.label,
            };
        });

    const relatedGroups =
        task && task.related_groups
            ? task.related_groups.map((group) => {
                  return group.alias;
              })
            : [];

    const productIds =
        task && task.products
            ? task.products.map((product) => {
                  return product.value;
              })
            : [];

    const initialFields = {
        name: task ? task.name : "",
        start_date: new Date(task?.start_date || startDate),
        end_date: new Date(task?.end_date || endDate),

        external_ref: task ? task.external_ref : "",
        primary_category_alias: task
            ? task.primary_category_alias
            : category
            ? category.alias
            : "",
        goal_uuid: task ? task?.goal?.uuid : "",
        metrics: task ? task.metrics : [],
        group_alias: task && task.group ? task.group.alias : group.alias,
        related_groups: relatedGroups,
        categories: task ? task.categories : [],
        products: productIds,
        estimated_campaign_budget:
            task && task.estimated_campaign_budget
                ? task.estimated_campaign_budget.value
                : "",
        description: task && task.description ? task.description : "",
        can_be_selected_by_request: task
            ? String(task.can_be_selected_by_request)
            : "0",
        status: task ? task.status : "draft",
    };
    return (
        <React.Fragment>
            <Formik
                initialValues={initialFields}
                onSubmit={submitForm}
                validationSchema={TaskSchema}
            >
                {({ isSubmitting, values, setFieldValue, errors }) => {
                    return (
                        <Form className="form-group">
                            <Row>
                                <Column>
                                    <h4 style={{ margin: "0 0 2em" }}>
                                        {trans("General")}
                                    </h4>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <FormRow
                                        name="name"
                                        label={trans("Campaign Name")}
                                        error={errors.name}
                                        helpText={trans("Required")}
                                        required={true}
                                    >
                                        <FormikInput
                                            name="name"
                                            value={values.name}
                                            showError={false}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="description"
                                        label={trans("Description")}
                                        error={errors.description}
                                        helpText={trans("Optional")}
                                    >
                                        <FormikTextarea
                                            name="description"
                                            value={values.description}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="start_date"
                                        label={trans("Start Date")}
                                        error={errors.start_date}
                                    >
                                        <FormikDatePicker
                                            name="start_date"
                                            value={values.start_date}
                                            showError={false}
                                        />
                                    </FormRow>

                                    <FormRow
                                        name="end_date"
                                        label={trans("End Date")}
                                        error={errors.end_date}
                                    >
                                        <FormikDatePicker
                                            name="end_date"
                                            value={values.end_date}
                                            showError={false}
                                        />
                                    </FormRow>

                                    {Array.isArray(allUserGroups) &&
                                        allUserGroups.length > 1 && (
                                            <FormRow
                                                name="group_alias"
                                                label={trans("Campaign Owner")}
                                                error={errors.group_alias}
                                                helpText={trans(
                                                    "Which group does this campaign belong?"
                                                )}
                                            >
                                                <FormikAdvancedSelect
                                                    name="group_alias"
                                                    onChange={groupChanged}
                                                    options={allUserGroups.map(
                                                        (group) => {
                                                            return {
                                                                value: group.alias,
                                                                text: group.name,
                                                            };
                                                        }
                                                    )}
                                                />
                                            </FormRow>
                                        )}

                                    <FormRow
                                        name="primary_category_alias"
                                        label={trans("Category")}
                                        error={errors.primary_category_alias}
                                        helpText={trans("Required")}
                                        required={true}
                                    >
                                        <FormikAdvancedSelect
                                            name="primary_category_alias"
                                            value={
                                                values.primary_category_alias
                                            }
                                            options={userCategoryOptions}
                                            showError={false}
                                        />
                                    </FormRow>

                                    {use_focus_products && (
                                        <FormRow
                                            name="products"
                                            label={trans("Focus Product(s)")}
                                            error={errors.products}
                                            helpText={trans(
                                                "Optional{limit, plural, one {, choose one product} other {, choose upto # products}}",
                                                {
                                                    limit: focus_product_limit,
                                                }
                                            )}
                                        >
                                            <FormikAdvancedSelect
                                                name="products"
                                                value={values.products}
                                                options={products}
                                                showError={false}
                                                multi={true}
                                                limit={focus_product_limit}
                                            />
                                        </FormRow>
                                    )}

                                    {can("planner-make-requestable") && (
                                        <FormRow
                                            name="can_be_selected_by_request"
                                            label={trans(
                                                "Can Be Selected By A Campaign Application Request?"
                                            )}
                                            error={
                                                errors.can_be_selected_by_request
                                            }
                                        >
                                            <FormikRadioArray
                                                options={[
                                                    {
                                                        value: "1",
                                                        text: intl.formatMessage(
                                                            {
                                                                id: "Yes",
                                                            }
                                                        ),
                                                    },
                                                    {
                                                        value: "0",
                                                        text: intl.formatMessage(
                                                            {
                                                                id: "No",
                                                            }
                                                        ),
                                                    },
                                                ]}
                                                label=""
                                                value={
                                                    values.can_be_selected_by_request
                                                }
                                                name="can_be_selected_by_request"
                                                inlineRadioButtons={true}
                                            />
                                        </FormRow>
                                    )}
                                    {/* 
                                    <FormRow
                                        name="status"
                                        label={trans("Campaign Status")}
                                        error={errors.status}
                                    >
                                        <FormikRadioArray
                                            options={[
                                                {
                                                    value: "draft",
                                                    text: intl.formatMessage({
                                                        id: "Draft",
                                                    }),
                                                },
                                                {
                                                    value: "planned",
                                                    text: intl.formatMessage({
                                                        id: "Planned",
                                                    }),
                                                },
                                                {
                                                    value: "complete",
                                                    text: intl.formatMessage({
                                                        id: "Complete",
                                                    }),
                                                },
                                            ]}
                                            label=""
                                            value={values.status}
                                            name="status"
                                        />
                                    </FormRow> */}
                                    <FormRow
                                        name="estimated_campaign_budget"
                                        label={trans(
                                            "Estimated Campaign Budget"
                                        )}
                                        error={errors.estimated_campaign_budget}
                                        required={false}
                                        helpText={trans(
                                            "Optional - High level estimation of budget for the whole campaign"
                                        )}
                                    >
                                        <FormikCurrencyInput
                                            name="estimated_campaign_budget"
                                            value={
                                                values.estimated_campaign_budget
                                            }
                                            showError={false}
                                        />
                                    </FormRow>
                                    {action !== "editTask" && (
                                        <FormRow
                                            name="activities"
                                            label={trans("Activities")}
                                            helpText={
                                                task_activities_required
                                                    ? trans(
                                                          "Required. Please select at least one media channel."
                                                      )
                                                    : trans(
                                                          "Optional. Please select at least one media channel."
                                                      )
                                            }
                                            required={task_activities_required}
                                        >
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th width="20%">
                                                            {trans("Channel")}
                                                        </th>
                                                        <th>
                                                            {trans(
                                                                "Date Range"
                                                            )}
                                                        </th>
                                                        <th>
                                                            {trans(
                                                                "Planned Cost"
                                                            )}
                                                        </th>
                                                        <th>
                                                            {trans(
                                                                "Actual Cost"
                                                            )}
                                                        </th>
                                                        <th>
                                                            {trans("Status")}
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orphanedActivities.map(
                                                        (activity) => (
                                                            <tr>
                                                                <td>
                                                                    {activity
                                                                        .media_channel_full
                                                                        ?.name ||
                                                                        activity.name}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        activity.start_date
                                                                    }
                                                                    {" - "}
                                                                    {
                                                                        activity.end_date
                                                                    }
                                                                </td>

                                                                <td>
                                                                    <FormattedCurrency
                                                                        value={
                                                                            activity
                                                                                .planned_cost
                                                                                ?.value ||
                                                                            0
                                                                        }
                                                                        currency={
                                                                            activity
                                                                                .planned_cost
                                                                                ?.currency ||
                                                                            "GBP"
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <FormattedCurrency
                                                                        value={
                                                                            activity
                                                                                .cost
                                                                                ?.value ||
                                                                            0
                                                                        }
                                                                        currency={
                                                                            activity
                                                                                .cost
                                                                                ?.currency ||
                                                                            "GBP"
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {
                                                                        activity.status_wording
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <IconTextButton
                                                                        type="alert"
                                                                        showCircle={
                                                                            false
                                                                        }
                                                                        expanded={
                                                                            false
                                                                        }
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                        onClick={() => {
                                                                            setOrphanedActivities(
                                                                                orphanedActivities.filter(
                                                                                    (
                                                                                        orphanActivity
                                                                                    ) =>
                                                                                        orphanActivity.uuid !==
                                                                                        activity.uuid
                                                                                )
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </Table>

                                            <IconTextButton
                                                type="alert"
                                                showCircle={false}
                                                expanded={false}
                                                icon={faPlus}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    addActivity({
                                                        ...initialFields,
                                                        ...values,
                                                    });
                                                }}
                                            >
                                                {trans("Add Activity")}
                                            </IconTextButton>
                                        </FormRow>
                                    )}
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <h4 style={{ margin: "1em 0 2em" }}>
                                        {trans("Campaign Goal")}
                                    </h4>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <FormRow
                                        name="goal_uuid"
                                        label={trans("Goal")}
                                        error={errors.goal_uuid}
                                        helpText={trans(
                                            "What is the purpose of this campaign?"
                                        )}
                                        required={true}
                                    >
                                        <FormikAdvancedSelect
                                            name="goal_uuid"
                                            options={goals.map((goal) => {
                                                return {
                                                    value: goal.uuid,
                                                    text: goal.name,
                                                };
                                            })}
                                            showError={false}
                                            value={values.goal_uuid}
                                            onChange={(event) =>
                                                handleGoalChange(
                                                    event,
                                                    setFieldValue
                                                )
                                            }
                                        />
                                    </FormRow>

                                    {renderGoalOptions(
                                        values,
                                        errors,
                                        setFieldValue
                                    )}

                                    {Array.isArray(groups) &&
                                        groups.length > 1 && (
                                            <FormRow
                                                name="related_groups"
                                                label={trans("Related Groups")}
                                                error={errors.related_groups}
                                            >
                                                <GroupSelector
                                                    label={null}
                                                    groups={groups}
                                                    value={
                                                        values.related_groups
                                                    }
                                                />
                                            </FormRow>
                                        )}
                                </Column>
                            </Row>
                            {/* TODO - Reinstate the section below when we have time to implement it! */}
                            {false && (
                                <Row>
                                    <Column>
                                        <NewMetricsForm
                                            task={task}
                                            values={values}
                                            errors={errors}
                                        />
                                    </Column>
                                </Row>
                            )}

                            <Row style={{ marginBottom: "1.2rem" }}>
                                <Column medium={6} centered>
                                    <Column
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <IconTextButton
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onGoBack();
                                            }}
                                            type="alert"
                                            icon={faTimes}
                                            showCircle={false}
                                        >
                                            {trans("Cancel")}
                                        </IconTextButton>

                                        <SubmitButton
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </Column>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addTask,
            editTask,
            plannerStopEdit,
            getPlannerChildGroups,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const { edit, startDate, endDate, filters, categories, config } =
        state.planner;
    return {
        edit,
        startDate,
        endDate,
        filters,
        categories,
        config,
    };
}

export default injectIntl(
    withTheme(
        compose(connect(mapStateToProps, mapDispatchToProps))(PlannerTaskForm)
    )
);
