import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import ConfigurePanel from "./ConfigurePanel";

import PanelWrapper from "./PanelWrapper";

class SelectedPanel extends Component {
    renderConfigurationOrSubmit = (panel) => {
        if (panel.configurable) {
            return (
                <ConfigurePanel addPanel={this.props.addPanel} panel={panel} />
            );
        }
        return (
            <Row>
                <Column>
                    <Button
                        onClick={this.props.addPanel.bind(this, panel, null)}
                    >
                        {trans("Add Panel")}
                    </Button>
                </Column>
            </Row>
        );
    };
    render() {
        const { panel } = this.props;
        return (
            <PanelWrapper>
                <Row>
                    <Column>
                        <h3>{panel.title}</h3>
                        <p>{panel.description}</p>
                    </Column>
                </Row>
                {this.renderConfigurationOrSubmit(panel)}
            </PanelWrapper>
        );
    }
}

SelectedPanel.propTypes = {
    panel: PropTypes.object.isRequired,
    addPanel: PropTypes.func.isRequired,
};

export default SelectedPanel;
