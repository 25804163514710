import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import {
    Row,
    Column,
    Select,
    RadioField,
    InputField,
    Panel,
    FormRow,
    StyledCheckbox,
    Loading,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import AssetContext from "../AssetContext";
import { SectionHeading } from "./components/SectionHeading";

const OrderOptions = ({
    asset,
    standardOnChange,
    errors,
    showSectionHeading = true,
}) => {
    const { canEdit } = useContext(AssetContext);
    const [orderFlows, setOrderFlows] = useState({
        loaded: false,
        data: [],
    });

    useEffect(() => {
        axios
            .get(`/api/v1/assets/available-order-flows/${asset.uuid}`)
            .then(({ data: { data } }) => {
                setOrderFlows({ data, loaded: true });
            });
    }, [asset]);
    if (!canEdit) {
        return null;
    }

    const { data, loaded } = orderFlows;

    if (!loaded) {
        return <Loading />;
    }

    const renderCheckboxes = (type) => {
        return (
            <FormRow label={type.name} required={type.required}>
                {type.options.map((option) => {
                    return (
                        <StyledCheckbox
                            checked={asset.order_flow_options_uuids.includes(
                                option.uuid
                            )}
                            text={option.name}
                            onChange={(e) => {
                                let newValues = [];
                                if (
                                    asset.order_flow_options_uuids.includes(
                                        option.uuid
                                    )
                                ) {
                                    newValues =
                                        asset.order_flow_options_uuids.filter(
                                            (uuid) => uuid !== option.uuid
                                        );
                                } else {
                                    newValues = [
                                        ...asset.order_flow_options_uuids,
                                        option.uuid,
                                    ];
                                }
                                standardOnChange({
                                    name: "order_flow_options_uuids",
                                    value: newValues,
                                });
                            }}
                        />
                    );
                })}
            </FormRow>
        );
    };
    const renderRadioButtons = (type) => {
        const options = type.options.map((option) => {
            return {
                value: option.uuid,
                text: option.name,
            };
        });

        //Get the current value for this type
        const selectedOption = options.find(
            (option) =>
                option.value ===
                asset.order_flow_options_uuids.find(
                    (uuid) => uuid === option.value
                )
        );
        return (
            <FormRow label={type.name} required={type.required}>
                <RadioField
                    value={selectedOption?.value}
                    onChange={({ value }) => {
                        //Remove any selected options for this type (e.g. only keep the selected
                        //options where they're not in the options for this type)
                        let newValues = asset.order_flow_options_uuids.filter(
                            (uuid) => {
                                return !options
                                    .map((option) => option.value)
                                    .includes(uuid);
                            }
                        );

                        //Add the new value
                        newValues = [...newValues, value];

                        //Fire off the change event with the new values
                        standardOnChange({
                            name: "order_flow_options_uuids",
                            value: newValues,
                        });
                    }}
                    options={options}
                />
            </FormRow>
        );
    };

    const handleDetailUpdate = ({ name, value }) => {
        const currentDetails = asset.order_flow_details || {};
        standardOnChange({
            name: "order_flow_details",
            value: { ...currentDetails, [name]: value },
        });
    };

    return (
        <>
            {showSectionHeading && (
                <SectionHeading>
                    {trans("Output and Order Options")}
                </SectionHeading>
            )}
            <Panel>
                <Row>
                    <FormRow
                        name="lead_time_days"
                        label={trans("Lead Time")}
                        helpText={trans(
                            "How many days are required to process this order?"
                        )}
                    >
                        <InputField
                            name="lead_time_days"
                            type={"number"}
                            value={asset?.order_flow_details?.lead_time_days}
                            onChange={handleDetailUpdate}
                        />
                    </FormRow>

                    <FormRow
                        name="min_quantity"
                        label={trans("Minimum Quantity")}
                        helpText={trans(
                            "What is the minimum quantity that can be ordered?"
                        )}
                    >
                        <InputField
                            name="min_quantity"
                            type={"number"}
                            value={asset?.order_flow_details?.min_quantity}
                            onChange={handleDetailUpdate}
                        />
                    </FormRow>

                    <FormRow
                        name="max_quantity"
                        label={trans("Maximum Quantity")}
                        helpText={trans(
                            "What is the maximum quantity that can be ordered?"
                        )}
                    >
                        <InputField
                            name="max_quantity"
                            type={"number"}
                            value={asset?.order_flow_details?.max_quantity}
                            onChange={handleDetailUpdate}
                        />
                    </FormRow>

                    <FormRow
                        label={trans("Order Flows")}
                        helpText={trans("Select an order flow(s)")}
                    >
                        <Select
                            value={asset.order_flow_uuids}
                            onChange={({ value }) => {
                                standardOnChange({
                                    name: "order_flow_uuids",
                                    value: value,
                                });
                            }}
                            multi={true}
                            options={orderFlows.data.map((orderFlow) => ({
                                value: orderFlow.uuid,
                                text: orderFlow.name,
                            }))}
                        />
                    </FormRow>
                    <Column>
                        {Array.isArray(asset.order_flow_uuids) &&
                        asset.order_flow_uuids.length > 0 ? (
                            asset.order_flow_uuids.map((orderFlowUuid) => {
                                const orderFlow = orderFlows.data.find(
                                    (orderFlow) =>
                                        orderFlow.uuid === orderFlowUuid
                                );
                                console.log(typeof orderFlow.max_quantity);
                                return (
                                    <>
                                        <Row>
                                            <Column
                                                medium={3}
                                                style={{ textAlign: "right" }}
                                            >
                                                <h2>{orderFlow.name}</h2>
                                            </Column>
                                        </Row>
                                        {orderFlow.types.map((type) => {
                                            if (type.single_value) {
                                                return renderRadioButtons(type);
                                            } else {
                                                return renderCheckboxes(type);
                                            }
                                        })}
                                    </>
                                );
                            })
                        ) : (
                            <p>{trans("Please select an order flow")}</p>
                        )}
                    </Column>
                </Row>
            </Panel>
        </>
    );
};

export default withRouter(OrderOptions);
