import React, { useEffect, useState } from "react";
import {
    Loading,
    TableList,
    Centred,
    DatePicker,
    Row,
    Column
} from "@cortexglobal/rla-components";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trans } from "@cortexglobal/rla-intl";
import usePanelData from "../usePanelData";
import moment from "moment";

const Breadcrumbs = styled.div`
    padding: 8px 0;
`;

const Arrow = styled.div`
    position: absolute;
    right: 5px;
    z-index: 5;
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.table.types.default.thBackground};
    color: ${({ theme }) => theme.table.types.default.thColor};
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border-radius: 50%;
    cursor: pointer;
    transform: translateY(-9px);
`;

const Tr = styled.tr`
    &:hover {
        .arrow {
            display: flex;
        }
    }
`;

const Breadcrumb = styled.span`
    margin-right: 0.5rem;
    cursor: pointer;
    font-size: 0.8rem;
    ${({ active }) => active && `font-weight: bold;`}
    &:hover {
        text-decoration: underline;
    }
    &:last-of-type {
        margin-right: 0;
    }
`;

const LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DrillDownChart = ({
    journey,
    panelId,
    filterBy,
    timeFrame,
    timeInterval,
    fromDate,
    toDate,
    perPage = 10
}) => {
    const [panelData, setPanelData] = useState();
    const [step, setStep] = useState(1);
    const [params, setParams] = useState(null);
    const [dateFrom, setDateFrom] = useState(fromDate);
    const [dateTo, setDateTo] = useState(toDate);

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            label: "Home",
            link: null
        }
    ]);

    const calcDateQuery = () => {
        switch (timeFrame) {
            case "rolling-days":
            case "rolling-qtr":
                return `&timeframe=${timeFrame}&interval=${timeInterval}`;
            case "date-range":
            case "date-range-picker":
                return `&datefrom=${moment(fromDate).format(
                    "YYYY-MM-DD"
                )}&dateto=${moment(toDate).format("YYYY-MM-DD")}`;
        }
        return "";
    };

    const panel = usePanelData(
        panelId,
        `/api/v1/drilldown-chart?journey=${journey}&step=${step}&params=${params}` +
            calcDateQuery(),
        {
            filter_by: filterBy,
            per_page: perPage
        }
    );

    useEffect(() => {
        setPanelData(panel);
    }, [panel]);

    const breadcrumbClick = (link, index) => {
        setParams(link);
        setStep(index);

        setBreadcrumbs([...breadcrumbs.slice(0, index)]);
    };

    const drilldownClick = (params, name) => {
        let nextStep = step + 1;
        setParams(params);
        setStep(nextStep);

        setBreadcrumbs([
            ...breadcrumbs,
            {
                label: name,
                link: params
            }
        ]);
    };

    const onChange = () => {};

    const loading =
        !panelData ||
        !panelData.loaded ||
        (panelData.refreshing && panelData.refresh);
    if (loading) {
        return (
            <LoadingOverlay>
                <div style={{ width: "50px" }}>
                    <Loading />
                </div>
            </LoadingOverlay>
        );
    }
    return (
        <div>
            <>
                <Row>
                    <Column medium={10}>
                        {step > 0 && (
                            <Breadcrumbs>
                                {breadcrumbs.map((breadcrumb, index) => (
                                    <React.Fragment>
                                        <Breadcrumb
                                            active={
                                                index === breadcrumbs.length - 1
                                            }
                                            onClick={() =>
                                                breadcrumbClick(
                                                    breadcrumb.link,
                                                    index + 1
                                                )
                                            }
                                        >
                                            {breadcrumb.label}
                                        </Breadcrumb>
                                        {index < breadcrumbs.length - 1 && (
                                            <Breadcrumb>&gt;</Breadcrumb>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Breadcrumbs>
                        )}
                    </Column>
                    {timeFrame === "date-range-picker" && (
                        <React.Fragment>
                            <Column medium={1}>
                                <DatePicker
                                    name="fromDate"
                                    label="From Date"
                                    style={{ display: "block" }}
                                    value={fromDate}
                                    onChange={onChange}
                                    allowEmpty={true}
                                />
                            </Column>
                            <Column medium={1}>
                                <DatePicker
                                    name="toDate"
                                    label="To Date"
                                    style={{ display: "block" }}
                                    value={toDate}
                                    onChange={onChange}
                                    allowEmpty={true}
                                />
                            </Column>
                        </React.Fragment>
                    )}
                </Row>
                {panelData.data.rows && (
                    <>
                        <TableList className="dashboard-table">
                            <thead>
                                <tr>
                                    {panelData.data.columns.map(column => (
                                        <th>{column.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {panelData.data.rows.map(row => (
                                    <Tr>
                                        {panelData.data.columns.map(column => (
                                            <td>{row[column.property]}</td>
                                        ))}
                                        <td
                                            style={{
                                                width: "0",
                                                padding: "0"
                                            }}
                                        >
                                            {row.params && (
                                                <Arrow
                                                    onClick={() => {
                                                        drilldownClick(
                                                            row.params,
                                                            row.name
                                                        );
                                                    }}
                                                    className="arrow"
                                                >
                                                    <FontAwesomeIcon icon="arrow-right" />
                                                </Arrow>
                                            )}
                                        </td>
                                    </Tr>
                                ))}
                            </tbody>
                        </TableList>
                    </>
                )}
                {!panelData.data.rows && (
                    <center>
                        <h3>{trans("Currently no results available")}</h3>
                    </center>
                )}
            </>
        </div>
    );
};

export default DrillDownChart;
