import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Field, ErrorMessage } from "formik";
import { format } from "@cortexglobal/cortex-utilities";
import parseISO from "date-fns/parseISO";

import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledDatePicker from "../styledElements/datePicker/StyledDatePicker";
import StyledSelectSimple from "../styledElements/StyledSelectSimple";
import { getLocaleDateFormat } from "@cortexglobal/cortex-utilities";

const hourOptions = [
    { text: "01", value: 1 },
    { text: "02", value: 2 },
    { text: "03", value: 3 },
    { text: "04", value: 4 },
    { text: "05", value: 5 },
    { text: "06", value: 6 },
    { text: "07", value: 7 },
    { text: "08", value: 8 },
    { text: "09", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 },
    { text: "13", value: 13 },
    { text: "14", value: 14 },
    { text: "15", value: 15 },
    { text: "16", value: 16 },
    { text: "17", value: 17 },
    { text: "18", value: 18 },
    { text: "19", value: 19 },
    { text: "20", value: 20 },
    { text: "21", value: 21 },
    { text: "22", value: 22 },
    { text: "23", value: 23 },
    { text: "24", value: 24 },
];

const minuteOptions = [
    { text: "00", value: 0 },
    { text: "15", value: 15 },
    { text: "30", value: 30 },
    { text: "45", value: 45 },
];

const DatePickerContainer = styled.div`
    width: 100%;
`;

const formatMinutes = (minutes) => {
    if (
        minutes !== "00" ||
        minutes !== "15" ||
        minutes !== "30" ||
        minutes !== "45"
    )
        return 0;

    return minutes;
};

const FormikDateTimePicker = ({
    type,
    name,
    label,
    disabled,
    value,
    labelWidth,
    labelAlign,
    dateFormat,
    showError = true,
    ...rest
}) => {
    const [dateVal, setDateVal] = useState(
        format(new Date(value), "yyyy-MM-dd")
    );
    const [hourVal, setHourVal] = useState(format(new Date(value), "HH"));
    const [minuteVal, setMinuteVal] = useState(
        formatMinutes(format(new Date(value), "mm"))
    );

    return (
        <DatePickerContainer>
            <Field name={name}>
                {({
                    field: { name, ...field },
                    form: {
                        values,
                        touched,
                        errors,
                        setFieldTouched,
                        setFieldValue,
                    },
                }) => {
                    const error = !!touched[name] && errors[name];

                    return (
                        <React.Fragment>
                            {label && (
                                <FormLabel
                                    align={labelAlign}
                                    width={labelWidth}
                                    name={name}
                                    {...rest}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <div style={{ display: "flex" }}>
                                <div style={{ flexGrow: "1" }}>
                                    <StyledDatePicker
                                        {...rest}
                                        style={{ flexGrow: "1" }}
                                        value={dateVal}
                                        selected={dateVal}
                                        disabled={disabled}
                                        name={name}
                                        dateFormat={dateFormat}
                                        error={showError ? error : ""}
                                        onChange={(date) => {
                                            setFieldValue(name, date);
                                            setFieldTouched(name, true);
                                        }}
                                        onChange={(date) => {
                                            const newValue = format(
                                                date,
                                                "yyyy-MM-dd"
                                            );
                                            setDateVal(newValue);
                                            const formattedValue = parseISO(
                                                `${newValue}T${hourVal}:${minuteVal}`
                                            );

                                            setFieldValue(name, formattedValue);
                                            setFieldTouched(name, true);
                                        }}
                                        onBlur={() => {
                                            setFieldTouched(name, true);
                                        }}
                                    />
                                </div>
                                <StyledSelectSimple
                                    value={hourVal}
                                    onChange={(value) => {
                                        setHourVal(value.target.value);

                                        const formattedValue = parseISO(
                                            `${dateVal}T${value.target.value}:${minuteVal}`
                                        );

                                        setFieldValue(name, formattedValue);
                                        setFieldTouched(name, true);
                                    }}
                                    style={{ width: "50px", marginLeft: "1em" }}
                                    name={`${name}-hour`}
                                    options={hourOptions}
                                />
                                <StyledSelectSimple
                                    value={minuteVal}
                                    onChange={(value) => {
                                        setMinuteVal(value.target.value);

                                        const formattedValue = parseISO(
                                            `${dateVal}T${hourVal}:${value.target.value}`
                                        );

                                        setFieldValue(name, formattedValue);
                                        setFieldTouched(name, true);
                                    }}
                                    style={{ width: "50px", marginLeft: "1em" }}
                                    name={`${name}-minute`}
                                    options={minuteOptions}
                                />
                            </div>
                        </React.Fragment>
                    );
                }}
            </Field>
            {showError && (
                <ErrorMessage name={name}>
                    {(msg) => <InputError error={msg} />}
                </ErrorMessage>
            )}
        </DatePickerContainer>
    );
};

FormikDateTimePicker.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dateFormat: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.number,
};
FormikDateTimePicker.defaultProps = {
    expanded: true,
    dateFormat: getLocaleDateFormat(),
    selected: new Date(),
};
export default FormikDateTimePicker;
