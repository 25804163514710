import React, { Component } from "react";
import { Link } from "react-router-dom";
import withPanelData from "./withPanelData";
import styled from "styled-components";
import {
    GreedyCarousel,
    Column,
    Hero,
    Centred
} from "@cortexglobal/rla-components";

const StyledCentred = styled(Centred)`
    width: 90%;
    text-align: center;
`;

const Title = styled.p`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

class FeaturedAssets extends Component {
    render() {
        const { data, layout, width, height } = this.props;

        const imageSize =
            Math.min(parseInt(width, 10), parseInt(height, 10)) - 150;

        return (
            <GreedyCarousel perTile={2} layout={layout}>
                {data.map((asset, index) => {
                    return (
                        <Column>
                            <StyledCentred>
                                <Link to={"/assets/" + asset.uuid}>
                                    <Hero
                                        height={imageSize}
                                        backgroundSize="contain"
                                        backgroundImage={
                                            process.env.REACT_APP_BASE_URL +
                                            asset.thumbnail
                                        }
                                    />
                                    <Title>{asset.name}</Title>
                                </Link>
                            </StyledCentred>
                        </Column>
                    );
                })}
            </GreedyCarousel>
        );
    }
}

export default withPanelData(FeaturedAssets, "/api/v1/assets/featured");
