import React from "react";
import { trans } from "@cortexglobal/rla-intl";

import { renderInputGroupRecursive } from "./sharedEditorFunctions";

const PageEditor = ({
    basePath,
    asset,
    baseAsset,
    onChange,
    fullAdmin,
    editingUserAsset,
    pageElements,
    setChoosingImage,
    choosingImage,
    chooseImage,
    useFootnotes,
    children,
    autofill,
}) => {
    // console.log("pageElements", pageElements);
    // console.log("asset.autofill", asset.autofill);
    // console.log("autofill", autofill);

    return (
        <React.Fragment>
            {children}
            {pageElements && pageElements.length > 0 ? (
                pageElements.map((tag, elementIndex) => {
                    //Add the code to check if the tag is an array, and do the looping,
                    //moving the below out into it's own component would be good
                    return renderInputGroupRecursive({
                        fullAdmin,
                        editingUserAsset,
                        tag,
                        asset,
                        baseAsset,
                        onChange,
                        chooseImage,
                        useFootnotes,
                        // autofill: asset.autofill,
                        autofill: autofill,
                        path: basePath,
                        index: `${elementIndex}`,
                    });
                })
            ) : (
                <span>{trans("No editable fields")}</span>
            )}
        </React.Fragment>
    );
};

export default PageEditor;
