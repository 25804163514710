import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
// import { Flipper, Flipped } from "react-flip-toolkit";
import { trans } from "@cortexglobal/rla-intl";

import {
    Row,
    Column,
    FilterPanel,
    IconTextButton,
    SelectField,
} from "@cortexglobal/rla-components";

import { filterEmptyFilterOptions } from "../../helpers/filterEmptyFilterOptions";
import AssetResults from "./AssetResults";
import SearchField from "./SearchField";
import AssetContext from "../AssetContext";

const AssetBrowser = ({ onSelect }) => {
    const { filters, user, config, libraryArea } = useContext(AssetContext);
    const [filtersShowing, setFiltersShowing] = useState(false);
    const [library, setLibrary] = useState(libraryArea);
    const [showFilterSummary, setShowFilterSummary] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [search, setSearch] = useState("");
    const [assets, setAssets] = useState({
        data: [],
        meta: {},
        loaded: false,
    });
    useEffect(() => {
        axios
            .post(`/api/v1/assets/search`, {
                libraryArea: library,
            })
            .then(({ data }) => {
                setAssets({ ...data, loaded: true });
            });
    }, []);
    useEffect(() => {
        onApply(selectedFilters, search, 1, true);
    }, [library]);

    const onApply = (
        selectedFilters,
        search,
        page = 1,
        collapseFilters = true
    ) => {
        // this.props.history.push({
        //     search: `?filters=${JSON.stringify(
        //         selectedFilters
        //     )}&search=${search}&page=${page}&hideFilterSummary=false`
        // });
        setShowFilterSummary(true);
        setAssets({ ...assets, loaded: false });

        // this.setState({ hideFilterSummary: false, currentSearchTerm: search });

        /**
         * The raw property
         *
         * This is used to toggle between a search summary with relevance score etc. or the asset models.
         *  -   true  ( returns the search summary with only key info eg.name, description, thumbnail url
         *                  etc. plus search stats including the score relevance score )
         *  -   false ( returns a collection with fuller asset information but no search stats )
         */
        axios
            .post(`/api/v1/assets/search?page=${page}`, {
                libraryArea: library,
                filters: selectedFilters,
                search,
                raw: false,
            })
            .then(({ data }) => {
                setAssets({ ...data, loaded: true });
            });

        if (collapseFilters) {
            setFiltersShowing(false);
        }
    };

    // const onSearchApply = () => {
    //     let { selectedFilters } = this.state;
    //     onApply(selectedFilters);
    // };

    const searchClear = () => {
        setSearch("");
        onApply(selectedFilters, "");
    };

    const onClear = (selectedFilters, page = 1) => {
        setSearch("");
        onApply(selectedFilters, "", page, false);
        // this.setState({ search: "", currentSearchTerm: "" }, () =>
        //     this.onApply(selectedFilters, page, false)
        // );
    };

    const onUpdate = (selectedFilters) => {
        setSelectedFilters(selectedFilters);
        // this.setState({ selectedFilters: selectedFilters });
    };
    // console.log("assets", assets);

    const { libraries } = config;
    return (
        <React.Fragment>
            <Row>
                <Column medium={4}>
                    <h3>{trans("Asset Browser")}</h3>
                </Column>
                <Column
                    medium={8}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "1rem",
                    }}
                >
                    {libraries && libraries.length > 0 && (
                        <SelectField
                            name="library"
                            options={libraries}
                            value={library}
                            onChange={({ value }) => setLibrary(value)}
                            tags={false}
                            multi={false}
                            creatable={false}
                            style={{ marginBottom: 0 }}
                        />
                    )}
                    <SearchField
                        onUpdate={({ value }) => setSearch(value)}
                        onApply={() => onApply(selectedFilters, search)}
                        search={search}
                    />
                    <IconTextButton
                        onClick={() => setFiltersShowing(!filtersShowing)}
                        rotation={filtersShowing ? 270 : 90}
                        showCircle={false}
                        expanded={false}
                    >
                        {trans("Filters")}
                    </IconTextButton>
                    {/* <AssetSearchFilter
                    filtersShowing={filtersShowing}
                    selectedFilters={selectedFilters}
                    // passToParent={this.updateMyState}
                    onApply={this.onSearchApply}
                    search={search}
                /> */}
                </Column>
            </Row>{" "}
            <Row collapse>
                {filters.loaded && (
                    <FilterPanel
                        expanded={filtersShowing}
                        filters={filterEmptyFilterOptions(filters.data)}
                        selectedFilters={selectedFilters}
                        onUpdate={onUpdate}
                        onApply={onApply}
                        onClear={onClear}
                        searchTerm={search}
                        // searchTerm={currentSearchTerm}
                        searchClear={searchClear}
                        filterAreaSlug="asset"
                        showSummary={true}
                        config={{ ...config.data, allow_saved_filters: false }}
                        user={user}
                        savedFilter={{}}
                    />
                )}
                <div style={{ marginTop: "1rem" }}>
                    <AssetResults
                        title=""
                        // selectAsset={onSelect}
                        selectAsset={onSelect}
                        updateSearch={(selectedFilters, page) => {
                            // console.log(selectedFilters, page);
                            onApply(selectedFilters, search, page);
                        }}
                        selectedFilters={selectedFilters}
                        permissions={[]}
                        collection={assets}
                        pagination={assets.meta}
                        visible={true}
                        navigate={false}
                    />
                </div>
            </Row>
        </React.Fragment>
    );
};

export default AssetBrowser;
