import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { get } from "lodash";

import {
    PageTitle,
    useAddToast,
    Tabordion,
    Row,
    Error,
    Column,
    Button,
    SubmitButton,
    Panel,
    StyledRadio,
    HeaderWithErrorIndicated,
    useSectionErrors,
    Tooltip,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import EventSummary from "./components/EventSummary";

import EventInventoryEditor from "./components/EventInventoryEditor";
import VenueForm from "./components/forms/VenueForm";
import BudgetForm from "./components/forms/BudgetForm";
import OverviewForm from "./components/forms/OverviewForm";
import ContractsForm from "./components/forms/ContractsForm";
import GeneralDetailForm from "./components/forms/GeneralDetailForm";
import EventObject from "../objects/EventObject";

const PlannerWrapper = styled.div`
    position: relative;
`;
const ButtonRow = styled(Row)`
    width: 100%;
    padding: 0 0.6rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
const SaveButton = styled(SubmitButton)`
    margin: 0 0.5rem;
`;
const CancelButton = styled(Button)`
    margin: 0 0.5rem;
`;
const EventPlanner = ({ settings }) => {
    const { path } = useRouteMatch();
    const match = useRouteMatch(path);
    const history = useHistory();
    const intl = useIntl();

    const [errors, setErrors] = useState({});
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: [
            "name",
            "description",
            "start_date",
            "end_date",
            "event_type",
            "primary_category_id",
        ],
        venue: ["venue_name"],
        budget: ["total_budget", "budget_confirmed"],
        overview: [
            "pr_requirements",
            "staff_attendee_count",
            "sustainability_element",
            "predicted_attendee_count",
        ],
        contracts: ["contract_issued", "contract_signed_off"],
    });

    const [event, setEvent] = useState({});
    const [loadingState, setLoadingState] = useState("loading");
    const [currentStep, setStep] = useState(0);

    const [inventoryItemList, setInventoryItemList] = useState({});

    const addToast = useAddToast();

    const [params, setParams] = useState({});

    useEffect(() => {
        setParams(event.raw);
    }, [event]);

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
        let { [name]: oldValue, ...newErrors } = errors;
        setErrors(newErrors);
        updateSectionErrors(newErrors);
    };

    const onSubmit = (finish = false) => {
        saveEvent(params, finish);
    };

    useEffect(() => {
        const uuid = get(match, "params.uuid");

        getEvent(uuid);
    }, []);

    const saveEvent = (params, finish = false) => {
        setLoadingState("loading");
        event.uuid ? updateEvent(params, finish) : saveNewEvent(params);
    };

    const saveNewEvent = (params) => {
        axios
            .post(`/api/v1/events/request`, params)
            .then(({ data }) => {
                const event = new EventObject(data.data);

                history.push(`/events/planner/${event.uuid}`);
                setEvent(event);
                setInventoryItemList(event.bookableInventoryItems);
                setLoadingState("loaded");

                addToast({
                    type: "success",
                    content: trans("Event saved"),
                    showFor: 5000,
                });

                // nextStep();
                setErrors([]);
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        updateSectionErrors(data.errors);
                        setErrors(data.errors);
                    }

                    addToast({
                        type: "alert",
                        content:
                            e.response && e.response.data
                                ? e.response.data.message
                                : trans("An error has occurred"),
                        showFor: 5000,
                    });
                }

                setLoadingState("loaded");
            });

        return;
    };

    const updateEvent = (params, finish = false) => {
        setLoadingState("loading");
        if (finish) {
            params.status = "pending";
        }

        axios
            .put(`/api/v1/events/${event.uuid}`, params)
            .then(({ data }) => {
                if (finish) {
                    addToast({
                        type: "success",
                        content: trans("Event saved"),
                        showFor: 5000,
                    });
                    history.push(`/events/${event.uuid}`);
                } else {
                    const event = new EventObject(data.data);
                    setEvent(event);
                    setInventoryItemList(event.bookableInventoryItems);

                    setErrors([]);
                    setLoadingState("loaded");

                    addToast({
                        type: "success",
                        content: trans("Event updated"),
                        showFor: 5000,
                    });
                }
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        updateSectionErrors(data.errors);
                        setErrors(data.errors);
                        const message = finish
                            ? trans(
                                  "Please check the information you have provided, to finalise this event"
                              )
                            : trans(
                                  "Please check the information you have provided"
                              );
                        addToast({
                            type: "alert",
                            content: message,
                            showFor: 5000,
                        });
                    } else {
                        addToast({
                            type: "alert",
                            content:
                                e.response && e.response.data
                                    ? e.response.data.message
                                    : trans("An error has occurred"),
                            showFor: 5000,
                        });
                    }
                }

                setLoadingState("loaded");
            });

        return;
    };

    const bookItems = (items) => {
        axios
            .put(`/api/v1/events/${event.uuid}/book-items`, { items })
            .then(({ data }) => {
                const event = new EventObject(data.data);
                setEvent(event);
                setInventoryItemList(event.bookableInventoryItems);

                addToast({
                    type: "success",
                    content: trans("Item bookings updated"),
                    showFor: 5000,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        e.response && e.response.data
                            ? e.response.data.message
                            : trans("An error has occurred"),
                    showFor: 5000,
                });
            });

        return;
    };

    const getEvent = (uuid) => {
        if (!uuid) {
            const defaultEventData = {
                start_date: null,
                end_date: null,
            };

            setEvent(new EventObject(defaultEventData));

            setLoadingState("loaded");

            return;
        }

        setLoadingState("loading");

        axios
            .get(`/api/v1/events/${uuid}`)
            .then(({ data }) => {
                const event = data.data;
                const eventObj = new EventObject(event);
                setEvent(eventObj);
                setInventoryItemList(eventObj.bookableInventoryItems);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });

        return;
    };

    const workflowUpdatedCallback = (smartRef, newWorkflow) => {
        let newEvent = event.setWorkflow(newWorkflow);

        setEvent(new EventObject({ ...newEvent }));

        addToast({
            type: "success",
            content: trans("Event status successfully changed"),
            showFor: 5000,
        });

        // TODO: Replace this horrible hack with some logic to do with entry_data for new state
        if (get(newWorkflow, "status") === "Event Manager Review") {
            window.location.replace(`/events/${event.uuid}`);
        }
    };

    const {
        eventTypeOptions,
        targetAudienceOptions,
        excludedOptionalFields = [],
        excludedOptionalSections = [],
    } = settings;

    return (
        <PlannerWrapper>
            <PageTitle title={trans("Event Planner")}></PageTitle>
            <Row>
                <Tabordion
                    unmounts={false}
                    current={currentStep}
                    style={{ margin: "20px 0", padding: "20px" }}
                >
                    <GeneralDetailForm
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("General Details")}
                                section="general"
                                sectionErrors={sectionErrors}
                            />
                        }
                        loadingState={loadingState}
                        errors={errors}
                        params={params}
                        onChange={onChange}
                        eventTypeOptions={eventTypeOptions}
                        excludedOptionalFields={excludedOptionalFields}
                    />

                    <div
                        heading={
                            <Tooltip
                                text={
                                    !event.uuid
                                        ? intl.formatMessage({
                                              id: "Please select dates and save your event to enable equipment selection",
                                          })
                                        : null
                                }
                                showIcon={false}
                            >
                                {trans("Equipment Selection")}
                            </Tooltip>
                        }
                        disabled={!event.uuid}
                    >
                        {event.uuid !== "" ? (
                            <EventInventoryEditor
                                path={path}
                                event={event}
                                inventoryItemList={inventoryItemList}
                                loadingState={loadingState}
                                settings={settings}
                                bookItems={bookItems}
                            />
                        ) : (
                            <Error>
                                {trans(
                                    "Please save the general details before you can select equipment."
                                )}
                            </Error>
                        )}
                    </div>

                    {!excludedOptionalSections.includes("budget") && (
                        <BudgetForm
                            heading={
                                <HeaderWithErrorIndicated
                                    title={trans("Budget")}
                                    section="budget"
                                    sectionErrors={sectionErrors}
                                />
                            }
                            loadingState={loadingState}
                            errors={errors}
                            params={params}
                            onChange={onChange}
                            excludedOptionalFields={excludedOptionalFields}
                        />
                    )}
                    {!excludedOptionalSections.includes("overview") && (
                        <OverviewForm
                            heading={
                                <HeaderWithErrorIndicated
                                    title={trans("Overview")}
                                    section="overview"
                                    sectionErrors={sectionErrors}
                                />
                            }
                            loadingState={loadingState}
                            errors={errors}
                            params={params}
                            onChange={onChange}
                            targetAudienceOptions={targetAudienceOptions}
                            excludedOptionalFields={excludedOptionalFields}
                        />
                    )}

                    <VenueForm
                        heading={
                            <HeaderWithErrorIndicated
                                title={
                                    <Tooltip
                                        text={
                                            !event.uuid
                                                ? intl.formatMessage({
                                                      id: "Please save your event to enable venue information",
                                                  })
                                                : null
                                        }
                                        showIcon={false}
                                    >
                                        {trans("Venue")}
                                    </Tooltip>
                                }
                                section="venue"
                                sectionErrors={sectionErrors}
                            />
                        }
                        disabled={!event.uuid}
                        loadingState={loadingState}
                        errors={errors}
                        params={params}
                        onChange={onChange}
                        excludedOptionalFields={excludedOptionalFields}
                    />

                    {!excludedOptionalSections.includes("contracts") && (
                        <ContractsForm
                            heading={
                                <HeaderWithErrorIndicated
                                    title={trans("Contracts")}
                                    section="contracts"
                                    sectionErrors={sectionErrors}
                                />
                            }
                            loadingState={loadingState}
                            errors={errors}
                            params={params}
                            onChange={onChange}
                            excludedOptionalFields={excludedOptionalFields}
                        />
                    )}
                </Tabordion>
            </Row>
            <Panel style={{ position: "sticky", bottom: 0 }}>
                <ButtonRow>
                    <CancelButton
                        onClick={(event) => {
                            event.preventDefault();
                            history.goBack();
                        }}
                        type="alert"
                    >
                        {trans("Cancel")}
                    </CancelButton>
                    <SaveButton
                        onClick={() => onSubmit()}
                        submitting={loadingState === "loading"}
                    >
                        {trans("Save")}
                    </SaveButton>
                    <SaveButton
                        onClick={() => onSubmit(true)}
                        submitting={loadingState === "loading"}
                        disabled={!event.uuid}
                    >
                        {trans("Save and Finish")}
                    </SaveButton>
                </ButtonRow>
            </Panel>
        </PlannerWrapper>
    );
};

export default EventPlanner;
