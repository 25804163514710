import React, { useEffect, useState } from "react";
import uuid from "uuid/v4";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlusCircle,
    faGripVertical,
    faTimesCircle,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";
import { SurveyFormLabel } from "../components/SurveyFormStyledComponents.js";

import {
    Row,
    Column,
    FormRow,
    InputField,
    Button,
    Toggle,
    SelectField,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const SurveyOptionList = styled.div``;

const SurveyOptionSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    border: 1px solid #a2a2a2;
    background-color: #ffffff;
    margin-top: -1px;
`;

const SurveyOptionDragHandle = styled.div`
    /* width: 50px; */
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    background-color: #eeeeee;
    cursor: grabbing;
`;

const SurveyOptionPanel = styled.div`
    flex-grow: 1;
    padding: ${(props) => `${props.theme.spacing.padding / 2}rem`};
    padding-right: 0;
`;

const SurveyOptionDelete = styled.div`
    width: auto;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => `0 ${props.theme.spacing.padding / 2}rem`};
`;

const SurveyOptionButtons = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const SurveyChoiceOptions = ({
    options,
    index,
    isSubmitting,
    disableEdit,
    updateOptionsData,
    singleColumn = false,
    collapse = false,
}) => {
    const updateChoiceOptions = (name, value, index, optionIndex) => {
        let updatedOptions = [...options];
        updatedOptions[optionIndex][name] = value;
        updatedOptions[optionIndex]["value"] = value;
        updateOptionsData("choice", updatedOptions, index);
    };

    const addOption = (e) => {
        const newOption = {
            uuid: uuid(),
            text: "",
            value: "",
        };

        updateOptionsData("choice", [...options, newOption], index);
    };

    const removeOption = (optionIndex) => {
        if (!isSubmitting) {
            let updatedOptions = [...options];
            updatedOptions.splice(optionIndex, 1);
            updateOptionsData("choice", updatedOptions, index);
        }
    };

    return (
        <FormRow
            singleColumn={singleColumn}
            collapse={collapse}
            name="default_value"
            label={trans("Single/Multiple Choice Settings")}
            // error={errors}
            // required={true}
        >
            <Droppable
                droppableId={`optionList_${index}`}
                type={`ANSWERS${index}`}
            >
                {(dropProvided, dropSnapshot) => (
                    <SurveyOptionList
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                    >
                        {options?.map((option, optionIndex) => (
                            <Draggable
                                key={option.uuid}
                                draggableId={option.uuid}
                                index={optionIndex}
                                questionIndex={index}
                                isDragDisabled={isSubmitting || disableEdit}
                            >
                                {(provided, snapshot) => (
                                    <SurveyOptionSection
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        {!disableEdit && (
                                            <SurveyOptionDragHandle>
                                                <FontAwesomeIcon
                                                    icon={faGripVertical}
                                                    size="lg"
                                                />
                                            </SurveyOptionDragHandle>
                                        )}
                                        <SurveyOptionPanel>
                                            <InputField
                                                name="text"
                                                onChange={(e) =>
                                                    updateChoiceOptions(
                                                        e.name,
                                                        e.value,
                                                        index,
                                                        optionIndex
                                                    )
                                                }
                                                disabled={
                                                    isSubmitting || disableEdit
                                                }
                                                value={option.text}
                                                style={{
                                                    marginBottom: 0,
                                                    flexGrow: 1,
                                                    maxWidth: "500px",
                                                }}
                                            />
                                        </SurveyOptionPanel>

                                        {!disableEdit && (
                                            <SurveyOptionDelete>
                                                <Button
                                                    type={"alert"}
                                                    onClick={() =>
                                                        removeOption(
                                                            optionIndex
                                                        )
                                                    }
                                                    disabled={isSubmitting}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        size="lg"
                                                        style={{
                                                            opacity:
                                                                isSubmitting
                                                                    ? 0.7
                                                                    : 1,
                                                        }}
                                                    />
                                                </Button>
                                            </SurveyOptionDelete>
                                        )}
                                        {provided.placeholder}
                                    </SurveyOptionSection>
                                )}
                            </Draggable>
                        ))}
                        {dropProvided.placeholder}
                    </SurveyOptionList>
                )}
            </Droppable>

            {!disableEdit && (
                <SurveyOptionButtons>
                    <Button
                        onClick={addOption}
                        disabled={isSubmitting}
                        style={{ marginTop: "1rem" }}
                    >
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            size="lg"
                            style={{ marginRight: "0.25rem" }}
                        />
                        {trans("Add Option")}
                    </Button>
                </SurveyOptionButtons>
            )}
        </FormRow>
    );
};

export default SurveyChoiceOptions;
