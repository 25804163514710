import React, {
    useRef,
    useLayoutEffect,
    useState,
    useEffect,
    useCallback,
} from "react";
import PropTypes from "prop-types";
import styled, { useTheme, css } from "styled-components";
import { Collapse } from "react-collapse";
import { VariableSizeList as List } from "react-window";
import { faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";

import { trans, useIntl } from "@cortexglobal/rla-intl";

import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import Input from "./input";
import CheckboxTreeCheckbox from "./CheckboxTreeCheckbox";

import Toggle from "./toggle";
import { ConditionalWrapper } from "../../ConditionalWrapper";
import Button from "../../buttons/Button";
import IconTextButton from "../../buttons/IconTextButton";
import CheckboxTreeIndividualCheckbox from "./CheckboxTreeIndividualCheckbox";

const Container = styled.div`
    margin-bottom: 1.8rem;
    border: 1px solid ${({ theme }) => theme.input.borderColor};
    border-radius: ${({ theme }) => theme.input.radius};
`;

const ModeWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const ContentWrapper = styled.div`
    ${({ fixHeight }) =>
        fixHeight &&
        css`
            height: ${({ maxHeight }) => maxHeight}px;
            overflow: auto;
            box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1); //, inset 0 10px 10px -10px rgba(0,0,0,0.1)",
            padding: 0rem 0 0 0.5rem;
            :first-child {
                padding-top: 0.5rem;
            }
        `}
`;

const Toolbar = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: ${({ theme }) => theme.colors.gray100};
    box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.1); //, inset 0 10px 10px -10px rgba(0,0,0,0.1)",
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0.5rem 0rem 0.5rem 0;
`;

const ModeOptions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
`;
const ModeButton = styled(Button)`
    margin-left: 0.5rem;
    padding: 0 1rem;
    color: ${({ theme, active }) =>
        active
            ? theme.checkboxTree.button.activeColor
            : theme.checkboxTree.button.color};
    background-color: ${({ theme, active }) =>
        active
            ? theme.checkboxTree.button.activeBackground
            : theme.checkboxTree.button.background};
`;

const SearchInput = styled(Input)`
    flex: 1;
    margin-bottom: 0;
    input {
        margin-bottom: 0;
    }
`;

const FlatCheckboxList = ({
    visibleFlatOptions,
    value,
    handleSingleChange,
    marginBottom,
    containerHeight,
}) => {
    const listRef = useRef();
    const [windowWidth, setWindowWidth] = useState(0);

    useLayoutEffect(() => {
        function updateWindowWidth() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener("resize", updateWindowWidth);
        updateWindowWidth();

        return () => window.removeEventListener("resize", updateWindowWidth);
    }, []);

    const sizeMap = useRef({});

    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);
    const getSize = (index) => sizeMap.current[index] || 50;

    const generateFlatCheckboxList = ({ data, index, style }) => {
        const option = visibleFlatOptions[index];
        // const option = data;
        const isChecked =
            Array.isArray(value) && value.indexOf(option.value) > -1;
        return (
            <CheckboxTreeIndividualCheckbox
                handleChange={handleSingleChange}
                option={option}
                marginBottom={marginBottom}
                isChecked={isChecked}
                style={style}
                index={index}
                setSize={setSize}
                windowWidth={windowWidth}
                name={option.value}
            />
        );
    };

    return (
        <List
            ref={listRef}
            height={containerHeight}
            itemCount={visibleFlatOptions.length}
            itemSize={getSize}
        >
            {generateFlatCheckboxList}
        </List>
    );
};

const CheckboxTree = ({
    name,
    onChange,
    options: passedOptions,
    value = [],
    optionIcon,
    error,
    inlineRadioButtons,
    selectChildren,
    selectParents,
    selectOnlyLeafNodes,
    disableAnimation,
    expandOnRender,
    allowMultiple,
    marginBottom,
    hideLabel,
    tabordionMode,
    label,
    showWrapper = false,
    maxHeight,
    defaultView = "tree",
    allowListView = false,
    includeParentLabelOnListView = true,
    ...rest
}) => {
    const [expandedOptions, setExpandedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [flatOptions, setFlatOptions] = useState([]);
    const [visibleFlatOptions, setVisibleFlatOptions] = useState([]);
    const [view, setView] = useState(defaultView);
    const [onlySelected, setOnlySelected] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const theme = useTheme();
    const intl = useIntl();
    const containerHeight = maxHeight
        ? maxHeight
        : theme.checkboxTree.container.maxHeight;
    const fixHeight = allowListView || maxHeight || showWrapper;

    useEffect(() => {
        processOptions(passedOptions, value);
    }, [passedOptions]);

    const processOptions = (
        options,
        values,
        parentValue = "",
        tempExpandedOptions = [],
        flatOptions = []
    ) => {
        const newOptions = options.map((option) => {
            //Push this into the flat options array if it's not already there
            if (
                !flatOptions.find(
                    (flatOption) => flatOption.value === option.value
                )
            ) {
                const listOption = { ...option };
                // If we're includeParentLabelOnListView we want to add the parent label
                // to the text and make actual value stand out, otherwise just leave it as is
                if (includeParentLabelOnListView) {
                    //If the option has a parent add the parent label to the text and adjust
                    //the emphasis, otherwise just make the text bold
                    if (parentValue !== "") {
                        const parentOption = findOption(
                            passedOptions,
                            parentValue
                        );
                        if (parentOption && parentOption.text) {
                            listOption.label = (
                                <>
                                    <em>{parentOption.text}</em>
                                    {" > "}
                                    <strong>{listOption.text}</strong>
                                </>
                            );
                            listOption.searchableText = `${parentOption.text} ${listOption.text}`;
                        }
                    } else {
                        listOption.label = <strong>{listOption.text}</strong>;
                        listOption.searchableText = listOption.text;
                    }
                } else {
                    listOption.label = listOption.text;
                    listOption.searchableText = listOption.text;
                }
                flatOptions.push(listOption);
            }
            //Add in if the option has children
            option.hasChildren =
                Array.isArray(option.children) && option.children.length > 0;

            //Add in if the option has a parent
            option.parentValue = parentValue;

            //If the option has any selected descendant add it to the expanded options
            if (
                option.hasChildren &&
                optionHasSelectedDescendant(option.children, values) &&
                !tabordionMode &&
                expandOnRender
            ) {
                tempExpandedOptions.push(option.value);
            }
            if (option.hasChildren) {
                option.children = processOptions(
                    option.children,
                    values,
                    option.value,
                    tempExpandedOptions,
                    flatOptions
                );
            }

            return option;
        });
        setExpandedOptions([...tempExpandedOptions]);
        setOptions(newOptions);
        setFlatOptions(flatOptions);
        setVisibleFlatOptions(flatOptions);
        return newOptions;
    };

    const optionHasSelectedDescendant = (
        children,
        values,
        hasSelected = false
    ) => {
        for (const child of children) {
            const isChecked =
                Array.isArray(values) && values.indexOf(child.value) > -1;
            if (isChecked) {
                return (hasSelected = true);
            }
            //If the child has children check them before moving on
            if (Array.isArray(child.children) && child.children.length > 0) {
                return (hasSelected = optionHasSelectedDescendant(
                    child.children,
                    values,
                    hasSelected
                ));
            }
        }
    };

    const optionDirectChildSelectedCount = (children, values) => {
        let selectedChildCount = 0;
        for (const child of children) {
            if (values.indexOf(child.value) > -1) {
                selectedChildCount += 1;
            }
        }
        return selectedChildCount;
    };

    const findOption = (options, valueToFind) => {
        // return true;
        for (const child of options) {
            // console.log("child", child.value, value);
            if (child.value === valueToFind) {
                return child;
            } else if (child.hasChildren) {
                let foundOption = findOption(child.children, valueToFind);
                if (foundOption) return foundOption;
            }
        }
        return false;
    };

    const addChildren = (children, values) => {
        return children.reduce((values, option) => {
            let newValues = values;
            if (!option.disabled) {
                newValues = [...newValues, option.value];
            }

            if (option.hasChildren) {
                newValues = addChildren(option.children, newValues);
            }
            return newValues;
        }, values);
    };

    const addParent = (parentValue, options, values) => {
        const parent = findOption(options, parentValue);
        if (!parent) {
            return;
        }

        let newValues = values;
        if (!option.disabled) {
            newValues = [...newValues, parent.value];
        }

        if (parent.parentValue !== "") {
            newValues = addParent(parent.parentValue, options, newValues);
        }
        return newValues;
    };

    const removeChildren = (children, values) => {
        return children.reduce((values, option) => {
            let newValues = values.filter((value) => value !== option.value);

            if (option.hasChildren) {
                newValues = removeChildren(option.children, newValues);
            }
            return newValues;
        }, values);
    };
    const removeParents = (parentValue, options, values) => {
        const parent = findOption(options, parentValue);
        //If the parent isn't found return
        if (!parent) {
            return values;
        }
        //If there are any selected children return as it should stay selected
        if (optionDirectChildSelectedCount(parent.children, values) > 0) {
            return values;
        }
        //There must only be one selected child, which should be the one we're removing
        //so deselect this option and process its parent, if it has one
        let newValues = values.filter((value) => value !== parent.value);

        if (parent.parentValue !== "") {
            newValues = removeParents(parent.parentValue, options, newValues);
        }
        return newValues;
    };

    const handleAdding = (newValue, refresh = false) => {
        let newValues = Array.isArray(value) && !refresh ? value.slice() : [];
        newValues.push(newValue);
        const option = findOption(options, newValue);
        if (!option) {
            return newValues;
        }
        //Select all the children
        if (selectChildren && option.hasChildren) {
            return addChildren(option.children, newValues);
        }

        //Handle selecting parents if required
        if (selectParents && option.parentValue !== "") {
            return addParent(option.parentValue, options, newValues);
        }
        return newValues;
    };

    const handleRemoving = (newValue) => {
        let newValues = Array.isArray(value) ? value.slice() : [];
        newValues.splice(newValues.indexOf(newValue), 1);
        const option = findOption(options, newValue);
        // console.log("option", option, "value", value);

        if (!option) {
            return newValues;
        }

        //TODO - Work out how to handle deselecting children
        if (selectChildren && option.hasChildren) {
            return removeChildren(option.children, newValues);
        }

        //Handle deselecting parents if required
        if (selectParents && option.parentValue !== "") {
            if (option.hasChildren && !selectOnlyLeafNodes) {
                newValues = removeChildren(option.children, newValues);
            }
            return removeParents(option.parentValue, options, newValues);
        }

        return newValues;
    };

    const handleChange = useCallback((event, newValue) => {
        //If we're not allowing multiple, but selectParents is true,
        //then we need to remove all the selected options
        //then just handleAdding

        if (!allowMultiple && !selectParents) {
            if (expandedOptions.indexOf(newValue) === -1) {
                setExpandedOptions([...expandedOptions, newValue]);
            }
            return onChange({ name: name, value: [newValue] }, event);
        }
        let newValues = [];
        if (event.target.checked) {
            const forceRefresh = !allowMultiple;
            newValues = handleAdding(newValue, forceRefresh);
            if (expandedOptions.indexOf(newValue) === -1) {
                setExpandedOptions([...expandedOptions, newValue]);
            }
        } else {
            newValues = handleRemoving(newValue);
        }

        return onChange({ name: name, value: newValues }, event);
    });

    //Used when selecting and deselecting options in list mode
    const handleSingleChange = useCallback((event, newValue) => {
        if (!allowMultiple) {
            return onChange({ name: name, value: [newValue] }, event);
        }
        let newValues = [];
        if (event.target.checked) {
            Array.isArray(value) && value.length > 0
                ? (newValues = [...value, newValue])
                : (newValues = [newValue]);
        } else {
            newValues = value.filter((value) => value !== newValue);
        }

        return onChange({ name: name, value: newValues }, event);
    });

    const handleExpand = useCallback((expandedOptionIndex, option, parents) => {
        const isShowingChildren = expandedOptionIndex > -1;
        if (isShowingChildren) {
            setExpandedOptions([
                ...expandedOptions.slice(0, expandedOptionIndex),
                ...expandedOptions.slice(expandedOptionIndex + 1),
            ]);
        } else {
            if (tabordionMode) {
                this.setState({
                    expandedOptions: [...parents, option.value],
                });
            } else {
                if (expandedOptions.indexOf(option.value) !== -1) {
                    setExpandedOptions(
                        expandedOptions.filter(
                            (expandedOption) => expandedOption !== option.value
                        )
                    );
                } else {
                    setExpandedOptions([...expandedOptions, option.value]);
                }
            }
        }
    });
    const handleExpandAll = (options, expandedOptions = []) => {
        options.forEach((option) => {
            if (option.hasChildren) {
                expandedOptions.push(option.value);
                handleExpandAll(option.children, expandedOptions);
            }
        });
        return expandedOptions;
    };

    const generateCheckboxes = (
        name,
        currentOptions,
        depth = 0,
        parents = []
        // checkboxes = []
    ) => {
        return currentOptions.map((option) => {
            const { hasChildren } = option;
            const isChecked =
                Array.isArray(value) && value.indexOf(option.value) > -1;

            const expandedOptionIndex = expandedOptions.indexOf(option.value);
            const isShowingChildren = expandedOptionIndex > -1;
            return (
                <React.Fragment key={option.value}>
                    <CheckboxTreeCheckbox
                        name={name}
                        hasChildren={hasChildren}
                        isChecked={isChecked}
                        isShowingChildren={isShowingChildren}
                        selectOnlyLeafNodes={selectOnlyLeafNodes}
                        handleChange={handleChange}
                        handleExpand={handleExpand}
                        option={option}
                        depth={depth}
                        parents={parents}
                        marginBottom={marginBottom}
                        expandedOptionIndex={expandedOptionIndex}
                        optionIcon={optionIcon}
                        fixHeight={fixHeight}
                    />
                    {hasChildren && isShowingChildren && (
                        <>
                            {disableAnimation ? (
                                <div>
                                    {generateCheckboxes(
                                        name,
                                        option.children,
                                        depth + 1,
                                        [...parents, option.value]
                                    )}
                                </div>
                            ) : (
                                <Collapse isOpened={isShowingChildren}>
                                    {generateCheckboxes(
                                        name,
                                        option.children,
                                        depth + 1,
                                        [...parents, option.value]
                                    )}
                                </Collapse>
                            )}
                        </>
                    )}
                </React.Fragment>
            );
        });

        // return checkboxes;
    };

    const filterFlatOptions = (searchTerm, onlySelected) => {
        const filteredOptions = flatOptions.filter((option) => {
            if (onlySelected && value.indexOf(option.value) === -1) {
                return false;
            }
            return option.searchableText
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
        });
        setVisibleFlatOptions(filteredOptions);
    };

    return (
        <ConditionalWrapper
            condition={showWrapper}
            wrapper={(children) => <Container>{children}</Container>}
        >
            {!hideLabel && label && <FormLabel {...rest}>{label}</FormLabel>}
            <ModeWrapper>
                {allowListView && (
                    <Toolbar>
                        <ButtonRow>
                            <ModeButton
                                type="primary"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setView("tree");
                                }}
                                active={view === "tree"}
                            >
                                {trans("Tree")}
                            </ModeButton>
                            <ModeButton
                                type="primary"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setView("list");
                                    filterFlatOptions(searchTerm, onlySelected);
                                }}
                                // $hollow={view === "tree"}
                                active={view === "list"}
                            >
                                {trans("List")}
                            </ModeButton>
                        </ButtonRow>
                        <ModeOptions>
                            {view === "tree" ? (
                                <>
                                    <IconTextButton
                                        icon={faCompress}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setExpandedOptions([]);
                                        }}
                                        showCircle={false}
                                        title={intl.formatMessage({
                                            id: "Collapse All",
                                        })}
                                    />
                                    <IconTextButton
                                        icon={faExpand}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setExpandedOptions(
                                                handleExpandAll(options)
                                            );
                                        }}
                                        showCircle={false}
                                        title={intl.formatMessage({
                                            id: "Expand All",
                                        })}
                                    />
                                </>
                            ) : (
                                <>
                                    <SearchInput
                                        placeholder={intl.formatMessage({
                                            id: "search",
                                        })}
                                        name="search"
                                        onChange={({ value }) => {
                                            setSearchTerm(value);
                                            filterFlatOptions(
                                                value,
                                                onlySelected
                                            );
                                        }}
                                        value={searchTerm}
                                    />
                                    <div style={{ marginLeft: "0.5rem" }}>
                                        {trans("Checked")}
                                        <Toggle
                                            style={{ marginLeft: "0.5rem" }}
                                            name="onlySelected"
                                            checked={onlySelected}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                setOnlySelected(
                                                    event.target.checked
                                                );
                                                filterFlatOptions(
                                                    searchTerm,
                                                    event.target.checked
                                                );
                                            }}
                                            size="small"
                                        />
                                    </div>
                                </>
                            )}
                        </ModeOptions>
                    </Toolbar>
                )}
                <ContentWrapper
                    fixHeight={fixHeight}
                    maxHeight={containerHeight}
                >
                    {view === "tree" ? (
                        generateCheckboxes(name, options, 0)
                    ) : (
                        <FlatCheckboxList
                            visibleFlatOptions={visibleFlatOptions}
                            containerHeight={containerHeight}
                            value={value}
                            handleSingleChange={handleSingleChange}
                            marginBottom={marginBottom}
                        />
                    )}
                </ContentWrapper>
            </ModeWrapper>
            <InputError error={error} />
        </ConditionalWrapper>
    );
};
CheckboxTree.displayName = "CheckboxTree";

CheckboxTree.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    optionIcon: PropTypes.any,
    error: PropTypes.string,
    inlineRadioButtons: PropTypes.bool,
    selectChildren: PropTypes.bool,
    selectParents: PropTypes.bool,
    selectOnlyLeafNodes: PropTypes.bool,
    disableAnimation: PropTypes.bool,
    expandOnRender: PropTypes.bool,
    allowMultiple: PropTypes.bool,
    showWrapper: PropTypes.bool,
    maxHeight: PropTypes.string,
};

CheckboxTree.defaultProps = {
    expandOnRender: true,
    allowMultiple: true,
    optionIcon: null,
    tabordionMode: false,
};

export default CheckboxTree;
