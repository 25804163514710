import React from "react";
import styled from "styled-components";
import { Collapse } from "react-collapse";

const Content = styled.div`
    color: ${(props) => props.theme.tabordion.content.color};
    background: ${(props) => props.theme.tabordion.active.background};
    padding: ${(props) => props.theme.tabordion.padding}em
        ${(props) => props.theme.tabordion.padding / 2}em 0;
    /* overflow: hidden; */
`;

const AccordionContent = ({ children, ...rest }) => {
    return (
        <Collapse {...rest}>
            <Content className="accordion-content" {...rest}>
                {children}
            </Content>
        </Collapse>
    );
};

export default AccordionContent;
