import React from "react";
import _ from "lodash";
import styled from "styled-components";
import {
    Row,
    Column,
    Panel,
    Image,
    IconTextButton
} from "@cortexglobal/rla-components";

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    height: 150px;
    background: ${({ theme }) => theme.image.containerBackground};
    img {
        width: 95%;
        height: 90%;
        object-fit: contain;
        position: relative;
    }
`;

const MediaTitle = styled.h5`
    margin: 0;
    font-size: 0.9rem;
    text-align: left;
    /* width: 90%; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: noWrap;
`;

const MediaSummary = styled.aside`
    font-size: 0.6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: noWrap;
`;
class GalleryItems extends React.Component {
    chooseItem = item => {
        this.props.chooseItem(item);
    };

    bytesToSize(bytes) {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "n/a";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + " " + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    }

    render() {
        const { currentFolder } = this.props;
        const contents = currentFolder.contains;

        return (
            <Row>
                <h3>{currentFolder.name}</h3>
                {contents &&
                    Object.keys(contents).map(index => {
                        const mediaFile = contents[index];

                        return (
                            <Column small={6} medium={4} large={3} xlarge={2}>
                                <Panel
                                    textAlign="center"
                                    padding={0}
                                    style={{ position: "relative" }}
                                >
                                    <a
                                        onClick={this.chooseItem.bind(
                                            this,
                                            mediaFile
                                        )}
                                        target="_blank"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <ImageWrapper>
                                            <Image
                                                src={mediaFile.thumbnail}
                                                alt={mediaFile.name}
                                            />
                                        </ImageWrapper>
                                    </a>
                                    <MediaTitle title={mediaFile.name}>
                                        {mediaFile.name}
                                    </MediaTitle>
                                    <MediaSummary
                                        title={mediaFile["media_type_name"]}
                                    >
                                        {mediaFile.media[0].mime_type}
                                        <br />
                                        {this.bytesToSize(
                                            mediaFile.media[0].size
                                        )}
                                    </MediaSummary>
                                </Panel>
                            </Column>
                        );
                    })}
            </Row>
        );
    }
}

export default GalleryItems;
