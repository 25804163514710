import React, { useState, useRef, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Modal,
    Button,
    SubmitButton,
    Row,
    Column,
    MultiCheckbox,
    StyledCheckbox,
    DatePicker,
    FormLabel,
    InputField,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const ShareLink = styled.textarea`
    width: 100%;
    /* border: ${({ theme }) => theme.colors.gray300}; */
    background: none;
    resize: none;
`;

const ShareModal = ({ smartRef, visible, onClose }) => {
    const shareLinkElement = useRef(null);
    const [shareLink, setShareLink] = useState("");
    const [copySuccess, setCopySuccess] = useState(false);
    const [shareLinkGenerated, setShareLinkGenerated] = useState(false);
    const [oneTimeUse, setOneTimeUse] = useState(false);
    const [useExpiresAt, setUseExpiresAt] = useState(false);
    const [expiresAt, setExpiresAt] = useState(null);
    const [usePassword, setUsePassword] = useState(false);
    const [password, setPassword] = useState("");

    const generateShare = useCallback(
        (event) => {
            event.preventDefault();
            console.log(smartRef);

            //Ensure conditional fields are only filled if the related checkbox is selected
            const expires = useExpiresAt ? expiresAt : null;
            const passwordToSubmit = usePassword ? password : null;

            axios
                .post("/api/v1/user-share", {
                    smartRef: smartRef,
                    oneTimeUse: oneTimeUse,
                    expires: expires,
                    password: passwordToSubmit,
                })
                .then(({ data }) => {
                    // console.log(data);
                    setShareLink(data.data.path);
                    setShareLinkGenerated(true);
                });
        },
        [smartRef, oneTimeUse, useExpiresAt, expiresAt, password, usePassword]
    );

    const copyToClipboard = (e) => {
        shareLinkElement.current.select();
        document.execCommand("copy");
        // Refocus the button to deselect the highlighted text
        e.target.focus();
        setCopySuccess(true);
    };

    console.log(usePassword, useExpiresAt);
    return (
        <Modal visible={visible} onClose={onClose}>
            {" "}
            <Row>
                {" "}
                <Column>
                    <h3>{trans("Share this asset")}</h3>
                </Column>
                {!shareLinkGenerated ? (
                    <form onSubmit={generateShare}>
                        <Column>
                            <FormLabel inlineLabel={true}>
                                <StyledCheckbox
                                    inline={true}
                                    name="one_time_use"
                                    checked={oneTimeUse}
                                    options={[
                                        { value: "oneTimeUse", text: "" },
                                    ]}
                                    onChange={(event) => {
                                        setOneTimeUse(event.target.checked);
                                    }}
                                />
                                {trans(
                                    "One time use; once the link is used it won't be available again"
                                )}
                            </FormLabel>
                        </Column>{" "}
                        <Column>
                            <FormLabel inlineLabel>
                                <StyledCheckbox
                                    inline={true}
                                    name="use_expires_at"
                                    checked={useExpiresAt}
                                    options={[
                                        { value: "useExpiresAt", text: "" },
                                    ]}
                                    onChange={(event) => {
                                        setUseExpiresAt(event.target.checked);
                                    }}
                                />
                                {trans(
                                    "Set an expiry date; the link will become unavailable after this date"
                                )}
                            </FormLabel>
                        </Column>
                        {useExpiresAt && (
                            <Column>
                                <DatePicker
                                    name="expires_at"
                                    label={trans("Expires At")}
                                    style={{ display: "block" }}
                                    value={expiresAt}
                                    onChange={({ value }) =>
                                        setExpiresAt(value)
                                    }
                                    minDate={new Date()}
                                />
                            </Column>
                        )}
                        <Column>
                            <FormLabel inlineLabel>
                                <StyledCheckbox
                                    inline={true}
                                    name="usePassword"
                                    checked={usePassword}
                                    options={[
                                        { value: "usePassword", text: "" },
                                    ]}
                                    onChange={(event) => {
                                        setUsePassword(event.target.checked);
                                    }}
                                />
                                {trans(
                                    "Use a password, you'll need to share this with the link recipient"
                                )}
                            </FormLabel>
                        </Column>
                        {usePassword && (
                            <Column>
                                <InputField
                                    type="text"
                                    name="password"
                                    label={trans("Password")}
                                    style={{ display: "block" }}
                                    value={password}
                                    onChange={({ value }) => setPassword(value)}
                                />
                            </Column>
                        )}
                        <Column>
                            <SubmitButton>
                                {trans("Generate Share Link")}
                            </SubmitButton>
                        </Column>
                    </form>
                ) : (
                    <React.Fragment>
                        <Column style={{ paddingBottom: "1rem" }}>
                            <ShareLink
                                ref={shareLinkElement}
                                value={shareLink}
                                readonly
                                autocomplete="off"
                                autocorrect="off"
                                autocapitalize="off"
                                spellcheck="false"
                            />
                        </Column>

                        {document.queryCommandSupported("copy") && (
                            <Column>
                                <Button
                                    onClick={(event) => copyToClipboard(event)}
                                >
                                    <FontAwesomeIcon icon="clipboard" />{" "}
                                    {copySuccess
                                        ? trans("Copied")
                                        : trans("Copy")}
                                </Button>
                            </Column>
                        )}
                    </React.Fragment>
                )}
            </Row>
        </Modal>
    );
};

export default ShareModal;
