import React from "react";
import FormLabel from "../styledElements/label";
import PropTypes from "prop-types";
import isArray from "lodash/isArray";
import InputError from "../styledElements/inputError";
//import 'react-select/dist/react-select.css';
import StyledSelectAdvanced from "../styledElements/StyledSelectAdvanced";

class Select extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.value || [],
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                values: this.props.value,
            });
        }
    }
    handleChange = (selected) => {
        let value = "";
        let { name, onChange } = this.props;

        if (isArray(selected)) {
            value = selected.map((selected) => selected.value);
        } else if (selected && selected.value) {
            value = selected.value;
        }

        onChange({ name, value });
    };

    render() {
        const {
            options,
            name,
            onChange,
            labelProps,
            error,
            creatable,
            tags,
            value,
            label,
            ...rest
        } = this.props;
        const labelProp = labelProps.label ? labelProps.label : label;
        return (
            <>
                {labelProp && (
                    <FormLabel
                        style={{
                            marginBottom: "1rem",
                        }}
                        name={name}
                        {...labelProps}
                    >
                        {labelProp}
                    </FormLabel>
                )}{" "}
                <StyledSelectAdvanced
                    name={name}
                    options={options}
                    value={value}
                    onChange={this.handleChange}
                    creatable={creatable}
                    tags={tags}
                    error={error}
                    {...rest}
                />
                <InputError error={error} />
            </>
        );
    }
}

Select.displayName = "Select";

Select.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
                .isRequired,
        })
    ),
    labelProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
    }),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Select.defaultProps = {
    error: "",
    labelProps: {
        label: "",
    },
};

export default Select;
