import React, { Component } from "react";
import styled from "styled-components";

import { Row, Column, Footer } from "@cortexglobal/rla-components";

let d = new Date(),
    n = d.getFullYear(),
    copyrightYear = "2019",
    currentYear = n;

const FooterLogo = styled.img`
    max-width: 125px;
    margin-bottom: 2rem;
`;

const FooterBorder = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.mediumGray};
    padding-top: 1.5rem;
`;

class PrivateFooter extends Component {
    render() {
        return (
            <Footer>
                <Row>
                    <Column>
                        <FooterBorder />
                    </Column>
                </Row>
                <Row>
                    <Column medium={6}>
                        <p>&copy; Cortex {currentYear}</p>
                    </Column>
                    <Column medium={6} style={{ textAlign: "right" }}>
                        <FooterLogo
                            src="/img/logo.svg"
                            style={{ filter: "invert(1)" }}
                        />
                    </Column>
                </Row>
            </Footer>
        );
    }
}

export default PrivateFooter;
