import React, { Component } from "react";

import DrillDownConfiguration from "./DrillDownConfiguration";
import DrillDownChart from "./DrillDownChart";

class DrillDown extends Component {
    render() {
        if (this.props.configuring) {
            return <DrillDownConfiguration {...this.props} />;
        }
        return <DrillDownChart {...this.props} />;
    }
}

export default DrillDown;
