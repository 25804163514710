import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
    ContentBlockLoader,
    SubmitButton,
    FormikInput,
    FormikCheckbox,
    Row,
    DateTimePicker,
    Column,
    FormRow,
    DraftJSField,
    StyledSelectAdvanced,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import dnsFormat from "date-fns/format";

const schema = Yup.object().shape({
    title: Yup.string().required(trans("Required")),
    event_category_uuid: Yup.string().required(trans("Required")),
    start_date: Yup.date().required(trans("Please pick a start date")),
    end_date: Yup.date()
        .min(
            Yup.ref("start_date"),
            trans("End date must be after the start date")
        )
        .required(trans("Please pick an end date")),
});

export type CalendarEvent = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: string;
    title?: string;
    notify_users?: boolean;
    description?: string;
    event_category_uuid?: string;
    // all_day?: false;
    notify_before_frequency?: string;
    notify_before?: string;
    event_category?: any;
};

export const CalendarEventForm = ({ item, submitForm }) => {
    const [categories, setCategories] = useState([]);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);

    useEffect(() => {
        axios
            .get(`/api/v1/categories`, {
                params: {
                    area: "calendar",
                },
            })
            .then(({ data }) => {
                setCategories(data.data);
                setCategoriesLoaded(true);
            });
    }, []);

    const handleSubmit = (
        values: CalendarEvent,
        { setErrors, setSubmitting, ...rest }
    ) => {
        if (!values.notify_before) {
            values.notify_before_frequency = "";
        }

        submitForm(values, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                title: item.title ? item.title : "",
                start_date: item.start_date ? item.start_date : new Date(),
                end_date: item?.end_date ? item.end_date : new Date(),
                description: item.description ? item.description : "",
                notify_users: item.notify_users ? item.notify_users : false,
                notify_before: item.notify_before ? item.notify_before : "",
                // all_day: item.all_day ? item.all_day : false,
                notify_before_frequency: "day",
                event_category_uuid: item?.event_category?.value
                    ? item?.event_category?.value
                    : "",
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="title"
                            label={trans("Title")}
                            helpText={trans("Required")}
                            error={errors.title}
                        >
                            <FormikInput
                                name="title"
                                value={values.title}
                                showError={false}
                            />{" "}
                        </FormRow>
                        {/* <FormRow
                            name="all_day"
                            label={trans("All Day Event?")}
                            helpText={trans("Required")}
                            error={errors.all_day}
                        >
                            <FormikCheckbox
                                name="all_day"
                                value={values.all_day}
                            />{" "}
                        </FormRow> */}
                        <FormRow
                            name="start_date"
                            label={trans("Start Date/Time")}
                            helpText={trans("Required")}
                            error={errors.start_date}
                        >
                            <DateTimePicker
                                name="start_date"
                                value={values.start_date}
                                onChange={(input) => {
                                    const { name, value } = input;
                                    setFieldValue(
                                        name,
                                        dnsFormat(
                                            new Date(value),
                                            "yyyy-MM-dd'T'HH:mm:ssxxx"
                                        )
                                    );
                                }}
                                showError={false}
                            />
                        </FormRow>
                        <FormRow
                            name="end_date"
                            label={trans("End Date/Time")}
                            helpText={trans("Required")}
                            error={errors.end_date}
                        >
                            <DateTimePicker
                                name="end_date"
                                value={values.end_date}
                                onChange={(input) => {
                                    const { name, value } = input;
                                    setFieldValue(
                                        name,
                                        dnsFormat(
                                            new Date(value),
                                            "yyyy-MM-dd'T'HH:mm:ssxxx"
                                        )
                                    );
                                }}
                                minDate={
                                    values.start_date
                                        ? new Date(values.start_date)
                                        : null
                                }
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="description"
                            label={trans("Description")}
                            helpText={trans("Required")}
                            error={errors.description}
                        >
                            <DraftJSField
                                name="description"
                                value={values.description}
                                onChange={(input) => {
                                    const { name, value } = input;
                                    setFieldValue(name, value);
                                }}
                                showError={false}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="event_category_uuid"
                            label={trans("Event Category")}
                            helpText={trans("Required")}
                            error={errors.event_category_uuid}
                        >
                            <StyledSelectAdvanced
                                style={{
                                    marginBottom: ".5rem",
                                }}
                                name="event_category_uuid"
                                onChange={(input) => {
                                    const { label, value } = input;
                                    setFieldValue("event_category_uuid", value);
                                }}
                                options={categories}
                                value={values.event_category_uuid}
                                showError={false}
                            />{" "}
                        </FormRow>

                        {!values.notify_users && (
                            <FormRow
                                name="notify_users"
                                label={trans(
                                    "Do you want to notify recipients before the event?"
                                )}
                                helpText={trans("Required")}
                                error={errors.notify_users}
                            >
                                <FormikCheckbox
                                    name="notify_users"
                                    value={values.notify_users}
                                    showError={false}
                                />{" "}
                            </FormRow>
                        )}

                        {values.notify_users && (
                            <FormRow
                                name="notify_before"
                                label={trans("How many days before the event?")}
                                helpText={trans("Required")}
                                error={errors.notify_before}
                            >
                                <FormikInput
                                    type="number"
                                    name="notify_before"
                                    value={values.notify_before}
                                    showError={false}
                                />{" "}
                            </FormRow>
                        )}

                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CalendarEventForm;
