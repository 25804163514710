import React, { useEffect, useReducer, useState } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import * as QueryString from "query-string";
import { Helmet } from "react-helmet-async";

import { trans } from "@cortexglobal/rla-intl";

import { getItem, setItem } from "@cortexglobal/cortex-utilities";

import {
    PageTitle,
    FilterPanel,
    FilterColumn,
    IconTextButton,
    Row,
    Column,
    SelectField,
    LoadingIconSmall,
} from "@cortexglobal/rla-components";

import AssetViewOptions from "../components/AssetViewOptions";
import FolderBrowser from "../components/FolderBrowser";
import FavouriteFolderBrowser from "../components/FavouriteFolderBrowser";
import AddFavouriteFolderModal from "../components/AddFavouriteFolderModal";
import CategoryBrowser from "../components/CategoryBrowser";
import AssetResults from "../components/AssetResults";
import SelectedAssets from "../components/SelectedAssets";
import AssetsOverallAdminOptions from "../components/AssetsOverallAdminOptions";
import AssetSearchFilter from "../components/AssetSearchFilter";

import {
    filterEmptyFilterOptions,
    filterAndAddCountToOptions,
} from "../../helpers/filterEmptyFilterOptions";
import {
    selectAsset,
    searchAssets,
    getFilters,
    getCategoryTotals,
} from "../assetActions";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar as faSolidStar } from "@fortawesome/free-solid-svg-icons/faStar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faObjectGroup,
    faTimes,
    faBars,
    faTh,
} from "@fortawesome/free-solid-svg-icons";
const ListAssets = ({
    assets,
    getCategoryTotals,
    group,
    config,
    searchAssets,
    selectAsset,
    permissions,
    setRefreshSearch,
    theme,
    user,
    planner,
    assetsPath,
    mainTitle,
    libraryArea,
    searchableMeta,
    groups,
    history,
    location,
    userAssetPath,
    includeAllLibraryAreas = false,
}) => {
    const queryString = QueryString.parse(location.search);

    const [activity, setActivity] = useState({
        loading: false,
        data: null,
    });

    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            asset: null,
            assetsShowing: true,
            filtersShowing: false,
            filtersUpdated: false,
            assetFilters: [],
            showingFilterModal: false,
            favorites:
                queryString.favorites && queryString.favorites !== "false"
                    ? true
                    : false,
            selectedFilters: queryString.filters
                ? JSON.parse(queryString.filters)
                : assets.collection.selectedFilters,
            search: queryString.search
                ? queryString.search
                : assets.collection.search,
            currentSearchTerm: queryString.search
                ? queryString.search
                : assets.collection.search,
            page: queryString.page ? queryString.page : 1,
            hideFilterSummary:
                queryString.hideFilterSummary &&
                queryString.hideFilterSummary === "true"
                    ? true
                    : false,
            hideHeader:
                queryString.hideHeader && queryString.hideHeader === "true"
                    ? true
                    : false,
            refresh:
                queryString.refresh && queryString.refresh === "true"
                    ? true
                    : false,
            perPage: queryString.perPage ? queryString.perPage : "20",
            selectMode: false,
            selectedAssets: [],
            view: getItem(
                "asset_view",
                config?.options?.default_view
                    ? config?.options?.default_view
                    : "tile"
            ).asset_view,
            orderBy: queryString.orderBy ? queryString.orderBy : "",
            folderUuid: queryString.folderUuid ? queryString.folderUuid : "",
            favouriteFolderUuid: queryString.favouriteFolderUuid
                ? queryString.favouriteFolderUuid
                : "",
            folderName: queryString.folderName ? queryString.folderName : "",
            favouriteFolderName: queryString.favouriteFolderName
                ? queryString.favouriteFolderName
                : "",
            showFolderBrowser:
                queryString.folderUuid && queryString.folderUuid !== ""
                    ? true
                    : false,
            showFavouriteFolderBrowser:
                queryString.favouriteFolderUuid &&
                queryString.favouriteFolderUuid !== ""
                    ? true
                    : false,
            activityUuid: queryString.activityUuid
                ? queryString.activityUuid
                : "",
        }
    );

    useEffect(() => {
        const {
            refresh,
            folderUuid,
            search,
            orderBy,
            perPage,
            page,
            favorites,
        } = state;
        if (assets.filters.data) {
            setState({
                assetFilters: [...assets.filters.data],
            });
        }

        if (!assets.collection.loaded || assets.collection.refresh || refresh) {
            //If there is a group filter and the group has not already been selected
            //push the user's group into the selected filters
            //TODO - Do the same for the admin_options filter, e.g. if it's there and not selected
            //push the active status(es) into the selected filters, need to work out how to get them
            if (assets.filters.data) {
                let newSelectedFilters = { ...state.selectedFilters };
                // console.log({
                //     newSelectedFilters,
                //     config,
                //     "assets.filters.data": assets.filters.data,
                // });
                const addGroup =
                    config.auto_select_group &&
                    assets.filters.data.findIndex((filter) => {
                        return filter.name === "group";
                    }) !== -1 &&
                    typeof newSelectedFilters.group === "undefined";

                //If this site has auto_select_group turned on, and there's groups in the filters
                //add the users active group
                if (addGroup) {
                    newSelectedFilters = {
                        ...newSelectedFilters,
                        group: [group.alias],
                    };
                }

                const addStatus =
                    assets.filters.data.findIndex((filter) => {
                        return filter.name === "admin_options";
                    }) !== -1 &&
                    typeof newSelectedFilters.admin_options === "undefined";
                if (
                    addStatus &&
                    Array.isArray(config?.options?.live_statuses)
                ) {
                    newSelectedFilters = {
                        ...newSelectedFilters,
                        admin_options: [...config.options.live_statuses],
                    };
                }
                // console.log(newSelectedFilters);

                if (config.display_asset_count) {
                    getCategoryTotals(
                        newSelectedFilters,
                        favorites,
                        search,
                        folderUuid
                    );
                }
                if (addGroup || addStatus) {
                    setState({ refresh: false });
                    onUpdate(newSelectedFilters);
                    onApply(newSelectedFilters, page, true);
                    setInitialLoadComplete(true);
                    return;
                }
            }

            //TODO - Merge in the owner bits...
            setState({ refresh: false });

            //Decode your filters
            searchAssets(
                {
                    filters: selectedFilters,
                    favorites,
                    folderUuid: folderUuid,
                    search: search,
                    raw: false,
                    per_page: perPage,
                    orderBy,
                    libraryArea: libraryArea,
                    includeAllLibraryAreas: includeAllLibraryAreas,
                },
                page
            );
            setInitialLoadComplete(true);
        }
    }, [libraryArea]);

    useEffect(() => {
        if (initialLoadComplete) {
            onSearchApply();
        }
    }, [
        state.favorites,
        state.favouriteFolderName,
        state.favouriteFolderUuid,
        state.search,
        state.currentSearchTerm,
        state.page,
        state.perPage,
        state.orderBy,
        state.folderUuid,
        state.folderName,
        state.activityUuid,
    ]);

    useEffect(() => {
        //TODO - Load the activity if a uuid is passed in
        //then add some display logic to the asset list
        if (state.activityUuid !== "") {
            setActivity({ ...activity, loading: true });
            axios
                .get(`/api/v1/planner/activities/${state.activityUuid}`)
                .then(({ data }) => {
                    setActivity({ loaded: true, data: data.data });
                })
                .catch(() => {
                    setActivity({ ...activity, loading: false });
                });
        } else {
            setState({ hideHeader: false });
            if (queryString.hideHeader) {
                const { hideHeader, ...rest } = queryString;
                history.replace({
                    search: QueryString.stringify(rest),
                });
            }
        }
    }, [state.activityUuid]);

    const addForm = () => {
        history.push(`${assetsPath}/create`);
    };

    const updateMyState = (item) => {
        setState(item);
    };

    const setAssetsVisibility = (visibility) => {
        if (state.assetsShowing !== visibility) {
            setState({ assetsShowing: visibility });
        }
    };

    const onSearchApply = () => {
        onApply(selectedFilters);
    };

    const searchClear = () => {
        setState({ search: "", currentSearchTerm: "" });
    };

    const toggleFavorites = () => {
        if (config.options.multiple_favourites_enabled) {
            setState({ showingFilterModal: true, favorites: !favorites });
        } else {
            setState({ favorites: !favorites });
        }
    };

    const onApply = (selectedFilters, page = 1, collapseFilters = true) => {
        const {
            activityUuid,
            perPage,
            orderBy,
            search,
            folderUuid,
            hideFilterSummary,
            hideHeader,
        } = state;
        setState({ selectedFilters });

        window.scrollTo(0, 0);

        history.push({
            search: `?filters=${JSON.stringify(
                selectedFilters
            )}&favorites=${favorites}&search=${search}&page=${page}&folderUuid=${folderUuid}&folderName=${folderName}&favouriteFolderUuid=${favouriteFolderUuid}&favouriteFolderName=${favouriteFolderName}&hideFilterSummary=${hideFilterSummary}&perPage=${perPage}&orderBy=${orderBy}&activityUuid=${activityUuid}&hideHeader=${hideHeader}`,
        });

        setState({
            hideFilterSummary,
            currentSearchTerm: search,
            filtersUpdated: false,
        });

        /**
         * The raw property
         *
         * This is used to toggle between a search summary with relevance score etc. or the asset models.
         *  -   true  ( returns the search summary with only key info eg.name, description, thumbnail url
         *                  etc. plus search stats including the score relevance score )
         *  -   false ( returns a collection with fuller asset information but no search stats )
         */
        searchAssets(
            {
                filters: selectedFilters,
                favorites,
                folderUuid: state.folderUuid,
                favouriteFolderUuid: state.favouriteFolderUuid,
                search,
                raw: false,
                per_page: perPage,
                orderBy,
                libraryArea: libraryArea,
                includeAllLibraryAreas: includeAllLibraryAreas,
            },
            page
        );

        if (config.display_asset_count) {
            getCategoryTotals(
                selectedFilters,
                favorites,
                search,
                state.folderUuid,
                libraryArea
            );
        }
        if (collapseFilters) {
            setState({ filtersShowing: false });
        }
    };

    const onClear = (selectedFilters, page = 1) => {
        setState({ search: "", currentSearchTerm: "" }, () =>
            onApply(selectedFilters, page, false)
        );
    };

    const onUpdate = (selectedFilters) => {
        cancelSelect();

        setState({
            selectedFilters: selectedFilters,
            filtersUpdated: true,
        });
    };

    const onOrderByUpdated = (orderBy) => {
        setState({
            orderBy,
            filtersUpdated: true,
        });
    };

    const toggleFolderBrowser = () => {
        setState({ showFolderBrowser: !state.showFolderBrowser });
    };

    const cancelSelect = () => {
        setState({ selectMode: false, selectedAssets: [] });
    };

    const toggleSelectedAsset = (id, selectedAssets) => {
        const assets = selectedAssets.includes(id)
            ? selectedAssets.filter((assetId) => assetId !== id)
            : [...selectedAssets, id];

        setState({ selectedAssets: assets });
    };

    const selectCurrentPage = (
        collection,
        maxSelectionLimit,
        selectedAssets
    ) => {
        setState({
            selectedAssets: [
                ...selectedAssets,
                ...collection
                    .map((collection) => collection.uuid)
                    .filter((id) => !selectedAssets.includes(id)),
            ].slice(0, maxSelectionLimit),
        });
    };

    const prepFilters = (
        assetFilters,
        categoryTotals = [],
        displayAssetCount = false
    ) => {
        let excludedBlocks = [];
        if (state.activityUuid) {
            excludedBlocks.push("media-channel");
        }
        if (displayAssetCount) {
            return filterAndAddCountToOptions(
                assetFilters,
                categoryTotals,
                excludedBlocks
            );
        }
        return filterEmptyFilterOptions(assetFilters, excludedBlocks);
    };

    const onPerPageChange = (value) => {
        setState({ perPage: value }, onSearchApply);
    };

    const onFolderUpdated = ({ uuid, name }) => {
        // console.log(uuid);
        setState({ folderUuid: uuid, folderName: name }, onSearchApply);
    };

    const onFavouriteFolderUpdated = ({ uuid, name }) => {
        setState(
            { favouriteFolderUuid: uuid, favouriteFolderName: name },
            onSearchApply
        );
    };

    const setView = (view = "tile") => {
        setState({ view });
        setItem("asset_view", view);
    };

    const {
        collection,
        pagination,
        filters,
        categoryTotals,
        // config,
        savedFilter,
    } = assets;

    const {
        favorites,
        filtersShowing,
        selectedFilters,
        currentSearchTerm,
        folderUuid,
        folderName,
        favouriteFolderName,
        favouriteFolderUuid,
        assetsShowing,
        perPage,
        filtersUpdated,
        selectedAssets,
        selectMode,
        activityUuid,
        view,
        search,
        hideFilterSummary,
    } = state;
    const massActions = Array.isArray(config?.multiple_actions)
        ? config.multiple_actions.filter((action) => {
              if (Array.isArray(action.excluded_libraries)) {
                  return !action.excluded_libraries.includes(libraryArea);
              }
              if (Array.isArray(action.permissions)) {
                  return action.permissions.some((permission) => {
                      return !!permissions.find((p) => p.alias == permission);
                  });
              }
              return true;
          })
        : [];
    const massActionsAvailable = massActions.length > 0;
    const maxSelectionLimit = 100;
    const isMaxSelected = selectedAssets.length >= maxSelectionLimit;
    const filterPosition =
        config && config.filter_position ? config.filter_position : "top";
    const filterColumnCollapse = config ? config.filter_column_collapse : false;

    const preppedFilters = prepFilters(
        state.assetFilters,
        categoryTotals,
        config.display_asset_count
    );

    const renderPageTitle = () => {
        // console.log({ activity });
        if (activity.loading) {
            return <PageTitle title={<LoadingIconSmall />} margin={0} />;
        }
        if (!activity.loading && activity.data) {
            return (
                <PageTitle
                    title={trans("Adding an order to {activityName} activity", {
                        activityName: activity.data.name,
                    })}
                    margin={0}
                >
                    <IconTextButton
                        onClick={() => {
                            setActivity({ loading: false, data: null });
                            setState({ activityUuid: "" });
                            history.push(
                                `/plan/task/${activity.data.task.uuid}/activity/${activity.data.uuid}`
                            );
                        }}
                        showCircle={false}
                        icon="times"
                    >
                        {trans("Cancel Activity Order")}
                    </IconTextButton>
                </PageTitle>
            );
        }

        return (
            <PageTitle title={mainTitle} margin={0}>
                {user.isGuest !== true && !(planner && planner.order) && (
                    <>
                        <AssetsOverallAdminOptions
                            folderUuid={folderUuid}
                            onApply={onSearchApply}
                            config={config}
                            assetsPath={assetsPath}
                            filters={filters}
                            searchableMeta={searchableMeta}
                            groups={groups}
                            libraryArea={libraryArea}
                            onFileAdded={() => {
                                setRefreshSearch(true);
                            }}
                        />
                        {massActionsAvailable && !selectMode && (
                            <IconTextButton
                                icon={faObjectGroup}
                                showCircle={false}
                                onClick={() => {
                                    setState({ selectMode: true });
                                }}
                            >
                                {trans("Select Multiple")}
                            </IconTextButton>
                        )}
                        {massActionsAvailable && selectMode && (
                            <IconTextButton
                                icon={faTimes}
                                showCircle={false}
                                onClick={cancelSelect}
                            >
                                {trans("Cancel Selection")}
                            </IconTextButton>
                        )}
                    </>
                )}

                {user.isGuest !== true &&
                    !config.options.multiple_favourites_enabled && (
                        <IconTextButton
                            onClick={toggleFavorites}
                            showCircle={false}
                            expanded={false}
                            icon={favorites ? faSolidStar : faRegularStar}
                        >
                            <div>{trans("Favorites")}</div>
                        </IconTextButton>
                    )}
                {filterPosition === "top" && (
                    <>
                        <AssetSearchFilter
                            filtersShowing={filtersShowing}
                            selectedFilters={selectedFilters}
                            passToParent={updateMyState}
                            onApply={onSearchApply}
                            search={search}
                        />
                    </>
                )}
            </PageTitle>
        );
    };

    const expanded =
        filterPosition === "left" ? (theme.body.expanded ? true : false) : true;

    return (
        <>
            <Helmet>
                <title>
                    {process.env.REACT_APP_NAME} - {mainTitle}
                </title>
            </Helmet>
            {renderPageTitle()}
            <Row
                expanded={expanded}
                collapse
                style={{
                    display: filterPosition === "left" ? "flex" : "block",
                    flexGrow: "1",
                    marginBottom: "-13px",
                }}
            >
                {filters.loaded && filterPosition === "left" ? (
                    <FilterColumn
                        medium={3}
                        large={theme.body.expanded ? 2 : 3}
                        filterColumnCollapse={filterColumnCollapse}
                        categoryTotals={categoryTotals}
                        filters={preppedFilters}
                        selectedFilters={selectedFilters}
                        onUpdate={onUpdate}
                        onApply={onApply}
                        onClear={onClear}
                        hideClear={activityUuid !== ""}
                        searchTerm={currentSearchTerm}
                        searchClear={searchClear}
                        toggleFavorites={toggleFavorites}
                        favorites={favorites}
                        user={user}
                        searchInput={
                            <Column style={{ marginTop: "1rem" }}>
                                <h4
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                    }}
                                >
                                    {trans("Search")}
                                </h4>
                                <AssetSearchFilter
                                    margin="0"
                                    filtersShowing={filtersShowing}
                                    selectedFilters={selectedFilters}
                                    passToParent={updateMyState}
                                    onApply={onSearchApply}
                                    search={search}
                                    showFilterButton={false}
                                />
                            </Column>
                        }
                        filterAreaSlug="asset"
                        savedFilter={savedFilter}
                        config={config}
                    />
                ) : (
                    <FilterPanel
                        expanded={filtersShowing}
                        categoryTotals={categoryTotals}
                        filters={preppedFilters}
                        selectedFilters={selectedFilters}
                        onUpdate={onUpdate}
                        onApply={onApply}
                        onClear={onClear}
                        searchTerm={currentSearchTerm}
                        searchClear={searchClear}
                        filterAreaSlug="asset"
                        showSummary={true}
                        config={config}
                        user={user}
                        savedFilter={savedFilter}
                    />
                )}

                <div style={{ width: "100%" }}>
                    {config.use_folder_browser === "collapse" && (
                        <IconTextButton
                            onClick={toggleFolderBrowser}
                            rotation={showFolderBrowser ? 270 : 90}
                            showCircle={false}
                            expanded={false}
                        >
                            <div style={{ fontWeight: "400" }}>
                                {trans("Folders")}
                            </div>
                        </IconTextButton>
                    )}
                    <AddFavouriteFolderModal
                        onClose={() => setState({ showingFilterModal: false })}
                        setRefreshSearch={setRefreshSearch}
                        visible={state.showingFilterModal}
                    />
                    <FavouriteFolderBrowser
                        config={config.options}
                        showFolderBrowser={
                            config.options.multiple_favourites_enabled
                        }
                        favouriteFolderUuid={state.favouriteFolderUuid}
                        onFolderUpdated={onFavouriteFolderUpdated}
                    />
                    <FolderBrowser
                        config={config}
                        showFolderBrowser={state.showFolderBrowser}
                        folderUuid={folderUuid}
                        onFolderUpdated={onFolderUpdated}
                    />
                    {config?.data?.options?.browsable_category && (
                        <Row expanded={expanded} style={{ marginTop: "1rem" }}>
                            <Column>
                                <CategoryBrowser
                                    // filters={filters}
                                    category={config.options.browsable_category}
                                    categoryTotals={categoryTotals}
                                    filters={preppedFilters}
                                    selectedFilters={selectedFilters}
                                    onApply={onApply}
                                    setAssetsVisibility={setAssetsVisibility}
                                />
                            </Column>
                        </Row>
                    )}
                    <AssetViewOptions
                        config={config}
                        onOrderByUpdated={onOrderByUpdated}
                        toggleFolderBrowser={toggleFolderBrowser}
                        showFolderBrowser={state.showFolderBrowser}
                        orderBy={state.orderBy}
                        view={view}
                        setView={setView}
                        pagination={pagination}
                        loaded={collection?.loaded}
                        folderName={folderName}
                        assetsShowing={assetsShowing}
                        setAssetsVisibility={setAssetsVisibility}
                        selectedFilters={selectedFilters}
                        onUpdate={onUpdate}
                        onApply={onApply}
                        onClear={onClear}
                        filters={preppedFilters}
                        searchTerm={currentSearchTerm}
                        searchClear={searchClear}
                        filterPosition={filterPosition}
                        hideFilterSummary={hideFilterSummary}
                        expanded={expanded}
                    />

                    <AssetResults
                        title=""
                        view={view}
                        addFunction={addForm}
                        selectAsset={selectAsset}
                        updateSearch={onApply}
                        selectedFilters={selectedFilters}
                        permissions={permissions}
                        collection={collection}
                        pagination={pagination}
                        favorites={favorites}
                        toggleFavorites={toggleFavorites}
                        perPage={perPage}
                        onPerPageChange={(input) =>
                            onPerPageChange(input.value)
                        }
                        breakpointColumns={
                            filterPosition === "left" ? { medium: 9 } : {}
                        }
                        filtersUpdated={filtersUpdated}
                        onApply={onApply}
                        selectedAssets={selectedAssets}
                        maximumAssetsSelected={isMaxSelected}
                        selectMode={selectMode}
                        toggleSelectedAsset={
                            selectMode
                                ? (id) => {
                                      toggleSelectedAsset(id, selectedAssets);
                                  }
                                : null
                        }
                        userAssetPath={userAssetPath}
                        assetsPath={assetsPath}
                        visible={assetsShowing}
                        activityUuid={activityUuid}
                    />
                    {massActionsAvailable && selectMode && (
                        <SelectedAssets
                            cancelSelect={cancelSelect}
                            isMaxSelected={isMaxSelected}
                            selectCurrentPage={() => {
                                selectCurrentPage(
                                    collection.data,
                                    maxSelectionLimit,
                                    selectedAssets
                                );
                            }}
                            selectedAssets={selectedAssets}
                            onApply={onSearchApply}
                            massActions={massActions}
                        />
                    )}
                </div>
            </Row>
        </>
    );
};

function mapStateToProps(state) {
    return {
        assets: state.assets,
        permissions: state.auth.role.permissions,
        group: state.auth.group,
        user: state.auth.user,
        planner: state.planner,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            selectAsset,
            searchAssets,
            getFilters,
            getCategoryTotals,
        },
        dispatch
    );
}

export default withTheme(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ListAssets))
);
