import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import { PlainButton } from "../../index";
import { Collapse } from "react-collapse";

const Container = styled.div`
    margin-bottom: ${(props) => props.theme.spacing.margin - 0.2}em;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${({ depth }) => depth * 1}rem;
    padding-top: ${({ paddingTop }) => paddingTop};
`;

const Choice = styled(PlainButton)`
    flex-grow: 1;
    color: ${({ selected, theme }) =>
        selected ? theme.search.hoverColor : theme.search.color};
    font-weight: ${({ selected }) => (selected ? "bold" : "inherit")};
`;

const Tree = ({
    options: passedOptions,
    onClick,
    paddingTop,
    selectedChoices,
    tabordionMode,
}) => {
    const [state, setState] = useState({
        expandedOptions: [],
        options: [],
    });

    useEffect(() => {
        const processedOptions = processOptions(passedOptions);
        if (state.expandedOptions.length === 0) {
            setState({
                ...state,
                options: processedOptions,
                expandedOptions: handleInitialExpandedOptions(processedOptions),
            });
        } else {
            setState({
                ...state,
                options: processedOptions,
            });
        }
    }, [passedOptions]);

    const processOptions = (options) => {
        return options.map((option) => {
            //Add in if the option has children
            option.hasChildren =
                Array.isArray(option.children) && option.children.length > 0;
            //If the option has any selected descendant add it to the expanded options
            if (option.hasChildren) {
                // console.log([...state.expandedOptions, option.value]);
                option.children = processOptions(option.children);
            }
            // console.log(tempExpandedOptions);
            return option;
        });
    };
    const handleInitialExpandedOptions = (options, expandedOptions = []) => {
        if (tabordionMode) return [];
        return options.reduce((expandedOptions, option) => {
            if (option.hasChildren) {
                expandedOptions = [...expandedOptions, option.value];
                return handleInitialExpandedOptions(
                    option.children,
                    expandedOptions
                );
            }
            return expandedOptions;
        }, expandedOptions);
    };

    const generateChoices = (
        options,
        selectedChoices,
        depth = 0,
        parents = []
    ) => {
        const { expandedOptions } = state;

        return options.map((option) => {
            const { hasChildren } = option;

            // console.log(option, hasChildren, expandedOptions, hasChildren);

            const expandedOptionIndex = expandedOptions.indexOf(option.value);
            const isShowingChildren = expandedOptionIndex > -1;
            const selected =
                Array.isArray(selectedChoices) &&
                selectedChoices.includes(option.value);
            // console.log("selected", selected, selectedChoices, option.value);
            return (
                <React.Fragment key={option.value}>
                    <ButtonWrapper depth={depth} paddingTop={paddingTop}>
                        <Choice
                            onClick={(event) => onClick(event, option.value)}
                            selected={selected}
                        >
                            {option.text}
                        </Choice>
                        {hasChildren && (
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                rotation={isShowingChildren ? 270 : 90}
                                onClick={() => {
                                    if (isShowingChildren) {
                                        setState({
                                            ...state,
                                            expandedOptions: [
                                                ...state.expandedOptions.slice(
                                                    0,
                                                    expandedOptionIndex
                                                ),
                                                ...state.expandedOptions.slice(
                                                    expandedOptionIndex + 1
                                                ),
                                            ],
                                        });
                                    } else {
                                        if (tabordionMode) {
                                            setState({
                                                ...state,
                                                expandedOptions: [
                                                    ...parents,
                                                    option.value,
                                                ],
                                            });
                                        } else {
                                            setState({
                                                ...state,
                                                expandedOptions: [
                                                    ...state.expandedOptions,
                                                    option.value,
                                                ],
                                            });
                                        }
                                    }
                                }}
                            />
                        )}
                    </ButtonWrapper>
                    {hasChildren && (
                        <Collapse isOpened={isShowingChildren}>
                            {generateChoices(
                                option.children,
                                selectedChoices,
                                depth + 1,
                                [...parents, option.value]
                            )}
                        </Collapse>
                    )}
                </React.Fragment>
            );
        });

        // return checkboxes;
    };

    const { options } = state;
    // console.log(options);
    return (
        <Container>{generateChoices(options, selectedChoices, 0)}</Container>
    );
};
Tree.displayName = "Tree";

Tree.propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Tree;
