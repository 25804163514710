import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import FormLabel from "../styledElements/label";
import InputError from "../styledElements/inputError";
import StyledDatePicker from "../styledElements/datePicker/StyledDatePicker";
import { format, getLocaleDateFormat } from "@cortexglobal/cortex-utilities";

const DatePickerContainer = styled.div`
    width: 100%;
    max-width: ${(props) => (props.expanded ? "100%" : "100%")};
`;

const DatePicker = ({
    name,
    label,
    value,
    allowEmpty,
    easyRead,
    onChange,
    includeTime = false,
    ...props
}) => {
    const handleChange = (date) => {
        if (!includeTime) {
            date.setHours(0, 0, 0, 0);
            date = format(date, "yyyy-MM-dd");
        }
        return onChange({ name, value: date });
    };

    return (
        <DatePickerContainer>
            {label && <FormLabel {...props}>{label}</FormLabel>}
            {easyRead && <b>{easyRead}</b>}
            <StyledDatePicker
                {...props}
                name={name}
                value={value}
                selected={value}
                onChange={handleChange}
            />

            <InputError error={props.error} />
        </DatePickerContainer>
    );
};

DatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    initialValue: PropTypes.any,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyOption: PropTypes.string,
    error: PropTypes.string,
    allowEmpty: PropTypes.bool,
};
DatePicker.defaultProps = {
    error: "",
    expanded: true,
    dateFormat: getLocaleDateFormat(),
    selected: new Date(),
    allowEmpty: false,
};
export default DatePicker;
