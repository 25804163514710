import React from "react";

const NewsContext = React.createContext({
    path: "",
    news: {
        loading: false,
        loaded: false,
        data: []
    },
    // permissions: [],
    getNewsSummaries: () => {}
});
export default NewsContext;
