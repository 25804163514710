import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import {
    SubmitButton,
    Toggle,
    LoadingIconSmall,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const EditCategoryTable = styled.table`
    margin: 0 auto;
    width: 100%;
    max-width: 600px;

    td,
    th {
        padding-bottom: 1rem;
        text-align: right;
        width: 25%;
    }
`;

const CenterDiv = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem 0;
`;

interface UserCategory {
    can_admin_category: boolean;
    can_admin_items: boolean;
    can_view_category: boolean;
    id: number;
    meta: any;
    name: string;
}

export const UserCategoryForm = ({ user }) => {
    const [item, setItem] = useState<{
        loaded: boolean;
        data?: UserCategory[];
    }>({
        loaded: false,
        data: null,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getItem = () => {
        setItem({ loaded: false, data: null });
        axios
            .get(`/api/v1/admin/user/${user.id}/categories`)
            .then(({ data }) => {
                setItem({ loaded: true, data: data.data });
            });
    };

    const handleSubmit = () => {
        setIsSubmitting(true);

        axios
            .post(`/api/v1/admin/user/${user.id}/categories`, item.data)
            .then(({ data }) => {
                setIsSubmitting(false);
                setItem({
                    loaded: true,
                    data: data.data,
                });
            });
    };

    const handleCheck = (name, checked, type) => {
        const newData = item.data.map((category) => {
            if (category.name === name) {
                // If can_view_category is unchecked, uncheck other toggles
                let disabledChecks = {};
                if (type === "can_view_category" && checked === false) {
                    disabledChecks = {
                        can_admin_category: false,
                        can_admin_items: false,
                    };
                }

                return {
                    ...category,
                    [type]: checked,
                    ...disabledChecks,
                };
            }
            return category;
        });

        setItem({ loaded: item.loaded, data: newData });
    };

    useEffect(getItem, [user]);

    return (
        <>
            {item.loaded ? (
                <EditCategoryTable>
                    <tr>
                        <th>{trans("Category")}</th>
                        <th>{trans("Can View Category")}</th>
                        <th>{trans("Can Edit Category")}</th>
                        <th>{trans("Can Edit Items")}</th>
                    </tr>
                    {item.data.map((category) => {
                        return (
                            <tr>
                                <td>{category.name}</td>
                                <td>
                                    <Toggle
                                        name={category.name}
                                        checked={category.can_view_category}
                                        onChange={(event) => {
                                            handleCheck(
                                                category.name,
                                                event.target.checked,
                                                "can_view_category"
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <Toggle
                                        disabled={!category.can_view_category}
                                        name={category.name}
                                        checked={category.can_admin_category}
                                        onChange={(event) =>
                                            handleCheck(
                                                category.name,
                                                event.target.checked,
                                                "can_admin_category"
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <Toggle
                                        disabled={!category.can_view_category}
                                        name={category.name}
                                        checked={category.can_admin_items}
                                        onChange={(event) =>
                                            handleCheck(
                                                category.name,
                                                event.target.checked,
                                                "can_admin_items"
                                            )
                                        }
                                    />
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td />
                        <td />
                        <td />
                        <td>
                            <SubmitButton
                                onClick={handleSubmit}
                                label={trans("Save")}
                                submitting={isSubmitting}
                                type="primary"
                            />
                        </td>
                    </tr>
                </EditCategoryTable>
            ) : (
                <CenterDiv style={{ textAlign: "center" }}>
                    <LoadingIconSmall />
                </CenterDiv>
            )}
        </>
    );
};

export default UserCategoryForm;
