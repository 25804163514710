import React, { Component } from "react";

import UserTotalsConfiguration from "./UserTotalsConfiguration";
import UserTotalsOutput from "./UserTotalsOutput";

class UserTotals extends Component {
    render() {
        if (this.props.configuring) {
            return <UserTotalsConfiguration {...this.props} />;
        }
        return <UserTotalsOutput {...this.props} />;
    }
}

export default UserTotals;
