import React from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { SelectField } from "@cortexglobal/rla-components";

const GroupSelect = props => {
    const groups = useSelector(state =>
        isEmpty(state.auth.groups)
            ? [
                  {
                      value: "",
                      text: "..."
                  }
              ]
            : state.auth.groups.map(group => {
                  return {
                      value: group.uuid,
                      text: group.name
                  };
              })
    );

    const { value, error, onChange } = props;

    return (
        <SelectField
            name="group_uuid"
            options={groups}
            value={value}
            error={error}
            onChange={e => onChange(e)}
        />
    );
};

export default GroupSelect;
