import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import StyledCheckbox from "../styledElements/styledCheckbox";
import InputError from "../styledElements/inputError";
import CheckboxTree from "../standardReturnElements/CheckboxTree";

const FormikCheckboxTree = ({
    type,
    name,
    label,
    options,
    labelWidth,
    labelAlign,
    inline,
    ...rest
}) => {
    return (
        <React.Fragment>
            <Field name={name}>
                {({ field: { value, onChange }, form: { setFieldValue } }) => {
                    // console.log("value", value);
                    return (
                        <CheckboxTree
                            name={name}
                            value={value}
                            options={options}
                            onChange={event => {
                                // console.log("Changed checkbox tree", event);
                                setFieldValue(name, event.value);
                            }}
                            text={label}
                            labelWidth={labelWidth}
                            labelAlign={labelAlign}
                            inline={inline}
                            {...rest}
                        />
                    );
                }}
            </Field>

            <ErrorMessage name={name}>
                {msg => <InputError error={msg} errorStyle={errorStyle} />}
            </ErrorMessage>
        </React.Fragment>
    );
};

FormikCheckboxTree.displayName = "FormikCheckboxTree";

FormikCheckboxTree.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string
};

FormikCheckboxTree.defaultProps = {
    readOnly: false,
    height: 30
};

export default FormikCheckboxTree;
