import React, { useContext } from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PDFViewer } from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";

import MicrositeContext from "../MicrositeContext";

import { CloseButton } from "../components/slideComponents";

const PrescribingInfoWrapper = styled.div<PrescribingInfoWrapperProps>`
    color: ${(props) => (props.textColor ? props.textColor : "black")};
    /* padding: 3em; */
    border-radius: 3px;
    width: 100%;
    height: 100%;
    overflow: visible;
    position: absolute;
    top: 0;
    z-index: 999;
    font-size: 0.6em;
`;

// const CloseButton = styled.div`
//     position: absolute;
//     top: 0;
//     right: 0;
//     padding: 1em;
//     cursor: pointer;
//     z-index: 2;
// `;

const PdfWrapper = styled.div<PrescribingInfoWrapperProps>`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "none"};
    background-image: ${(props) =>
        props.backgroundImage ? `url(${props.backgroundImage})` : "none"};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: baseline;
    padding-top: 1em;
`;

// const PdfView = styled.embed`
const PdfView = styled(PDFViewer)`
    width: 100%;
    height: 85%;
`;

export const PrescribingInfo = () => {
    const {
        prescribingInfoShowing,
        setPrescribingInfoShowing,
        globalSettings,
        globalTheming,
    } = useContext(MicrositeContext);

    const prescribingInformation = globalSettings?.prescribingInformation;
    const forcePrescribing = useQueryString().get("force-prescribing");

    const closePrescribingInfo = () => {
        if (setPrescribingInfoShowing) setPrescribingInfoShowing(false);
    };

    console.log({ globalTheming });
    return (
        <>
            {(forcePrescribing || prescribingInfoShowing) && (
                <PrescribingInfoWrapper textColor={globalTheming?.textColor}>
                    {!forcePrescribing && (
                        <CloseButton onClick={closePrescribingInfo}>
                            <FontAwesomeIcon icon={faTimes} />
                        </CloseButton>
                    )}

                    <PdfWrapper
                        backgroundImage={globalTheming?.backgroundImage}
                        backgroundColor={globalTheming?.backgroundColor}
                    >
                        <PdfView
                            // src={prescribingInformation}
                            height={forcePrescribing ? "auto" : "85%"}
                            filePath={prescribingInformation}
                            paginate={false}
                        />
                    </PdfWrapper>
                </PrescribingInfoWrapper>
            )}
        </>
    );
};

interface PrescribingInfoWrapperProps {
    backgroundImage?: string;
    backgroundColor?: string;
    textColor?: string;
}
