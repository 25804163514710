import React from "react";
import _ from "lodash";
import FolderContent from "./FolderContent";
import Gallery from "../../gallery/admin/GalleryItems";
import SubFolders from "./SubFolders";
import { Row, Column } from "@cortexglobal/rla-components";

class Folders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: {},
            initialFolder: null,
            gallery: []
        };
    }

    componentWillMount() {
        this.props.getTopFolders();
    }

    componentWillReceiveProps(props) {
        let uuid = Object.keys(props.library.folders)[0];
        let folder = props.library.folders[uuid];
        this.setState({ initialFolder: folder });
    }

    openFolder = item => {
        this.props.getFolders(item.uuid);
    };

    editFolder = item => {
        //todo
    };

    editFile = item => {
        this.props.editFile(item);
    };

    editFolder = item => {
        this.props.editFolder(item);
    };

    createNew = () => {
        this.props.createNew();
    };

    updateExpandList = item => {
        this.props.updateExpandList(item);
    };

    createNewFolder = () => {
        this.props.createNewFolder();
    };

    render() {
        let { initialFolder } = this.state;
        let {
            currentFolder,
            folders,
            expandList,
            topFolders
        } = this.props.library;

        return (
            <div>
                {initialFolder && (
                    <Row>
                        <Column medium={2}>
                            {initialFolder && (
                                <FolderContent
                                    folders={folders}
                                    currentFolder={currentFolder}
                                    subFolders={topFolders}
                                    editFile={this.editFile}
                                    openFolder={this.openFolder}
                                    editFolder={this.editFolder}
                                    updateExpandList={this.updateExpandList}
                                    expandList={expandList}
                                    createNewFolder={this.createNewFolder}
                                />
                            )}
                        </Column>
                        <Column
                            medium={10}
                            style={{ borderLeft: "1px solid #cccccc" }}
                        >
                            <SubFolders
                                folders={currentFolder.subFolders}
                                parents={currentFolder.parentFolder}
                                openFolder={this.openFolder}
                                createNew={this.createNew}
                                createNewFolder={this.createNewFolder}
                            />
                            <Gallery
                                currentFolder={currentFolder}
                                editFile={this.editFile}
                            />
                        </Column>
                    </Row>
                )}
            </div>
        );
    }
}

export default Folders;
