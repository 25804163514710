import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
// import styled from "styled-components";
import {
    Column,
    FormikCheckbox,
    FormikAdvancedSelect,
    Row,
    SubmitButton,
    FormRow,
    FormikSelect,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    can_send_communication_to: Yup.number(),
});

export type Communication = ReturnType<typeof schema.validateSync> & {
    can_send_communication_to?: number;
};

export const CommunicationForm = ({ item, submitForm }) => {
    const handleSubmit = (
        values: Communication,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting, "communication").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                can_send_communication_to: item?.data?.can_send_communication_to
                    ? 1
                    : 0,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, errors }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="can_send_communication_to"
                            label={trans("Can Communicate To")}
                            helpText={trans("Required")}
                            error={errors.can_send_communication_to}
                        >
                            <FormikSelect
                                name="can_send_communication_to"
                                value={values.can_send_communication_to}
                                options={[
                                    { text: "Yes", value: 1 },
                                    { text: "No", value: 0 },
                                ]}
                                showError={false}
                            />
                        </FormRow>
                        <Row>
                            <Column medium={6}>&nbsp;</Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CommunicationForm;
