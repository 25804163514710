import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ExternalDashboardListing from "./ExternalDashboardListing";
import ExternalDashboardCreate from "./ExternalDashboardCreate";
import { ExternalDashboardEdit } from "./ExternalDashboardEdit";

export const ExternalDashboardAdmin = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <ExternalDashboardListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <ExternalDashboardCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <ExternalDashboardEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default ExternalDashboardAdmin;
