import React from "react";
import set from "lodash/set";
import get from "lodash/get";

import AdminEditorInputGroup from "./AdminEditorInputGroup";
import EditorInputGroup from "./EditorInputGroup";
import AdminSettingInputGroup from "./AdminSettingInputGroup";

/**
 * Recursively render the input groups for the editor
 * This also builds up the path back to the element
 * @param {*} tag
 * @param {*} pageName
 * @param {*} onChange
 * @param {*} autofill
 * @param {*} path
 * @param {*} index
 */
export const renderInputGroupRecursive = ({
    fullAdmin,
    editingUserAsset,
    tag,
    asset,
    baseAsset,
    onChange,
    chooseImage,
    useFootnotes,
    autofill,
    path = "",
    index = 0,
}) => {
    //If the tag type is array we need to recursively setup the contents of it's value

    if (
        ((tag.autofilled &&
            (tag.editable === "false" || tag.editable === false)) ||
            (tag.autofilled && asset.bulk_edit === true)) &&
        !fullAdmin
    ) {
        return false;
    }

    if (tag.type === "array") {
        //Prepend the index of the "array" tag to the path, and point to it's 'value' property
        path += `[${index}].value`;
        return tag.value.map((subTag, tagIndex) => {
            //console.log(path, index, tagIndex, subTag.name);
            return renderInputGroupRecursive({
                fullAdmin,
                editingUserAsset,
                tag: subTag,
                asset,
                baseAsset,
                onChange,
                chooseImage,
                useFootnotes,
                autofill,
                path,
                index: tagIndex,
            });
        });
    }
    if (fullAdmin) {
        return (
            <AdminEditorInputGroup
                key={`${path}[${index}]${tag.name}`}
                path={`${path}[${index}]`}
                tag={tag}
                asset={asset}
                baseAsset={baseAsset}
                onChange={onChange}
                chooseImage={chooseImage}
                autofill={autofill}
                fullAdmin={fullAdmin}
                useFootnotes={useFootnotes}
            />
        );
    }
    return (
        <EditorInputGroup
            key={`${path}[${index}]${tag.name}`}
            path={`${path}[${index}]`}
            tag={tag}
            asset={asset}
            baseAsset={baseAsset}
            onChange={onChange}
            chooseImage={chooseImage}
            autofill={autofill}
            fullAdmin={fullAdmin}
            editingUserAsset={editingUserAsset}
            useFootnotes={useFootnotes}
        />
    );
};

/**
 * Recursively render the input groups for the settings editor
 * This also builds up the path back to the element
 * TODO - Look at if this can be refactored to use the more general renderInputGroupRecursive above
 * @param {*} tag
 * @param {*} pageName
 * @param {*} onChange
 * @param {*} autofill
 * @param {*} path
 * @param {*} index
 */
export const renderSettingInputGroupRecursive = ({
    tag,
    onChange,
    chooseImage,
    path = "",
    index = 0,
    asset,
}) => {
    //If the tag type is array we need to recursively setup the contents of it's value
    if (tag.children && tag.children.length > 0) {
        //Prepend the index of the "array" tag to the path, and point to it's 'value' property
        path += `[${index}]`;
        return tag.map((subTag, tagIndex) => {
            //console.log(path, index, tagIndex, subTag.name);
            return renderSettingInputGroupRecursive({
                tag: subTag,
                onChange,
                chooseImage,
                path,
                index: tagIndex,
            });
        });
    }

    return (
        <AdminSettingInputGroup
            key={tag.name}
            path={path + `[${index}]`}
            tag={tag}
            onChange={onChange}
            chooseImage={chooseImage}
            asset={asset}
        />
    );
};

/**
 * Pass the updated configuration to the main asset change handler
 * @param {*} standardOnChange
 * @param {*} asset
 * @param {*} property
 * @param {*} event
 * @param {*} path
 */
export const onConfigurationChange = (
    standardOnChange,
    asset,
    property,
    event,
    path
) => {
    // console.log(standardOnChange, asset, property, event, path);
    let { value } = event;
    if (property === "internal") {
        value = event.target.checked ? true : false;
    }
    //Update the item in the configuration as it's all submitted
    let { configuration, extracted_tags } = asset;

    //If there's currently no configuration copy the original extracted tags in there
    if (!configuration) {
        configuration = extracted_tags;
    }

    standardOnChange({
        name: "configuration",
        value: updateConfiguration(configuration, path, property, value),
    });
};

/**
 * Update the configuration of the specified property
 * This is different depending on from where the tags were created
 * The the current time (2020-03-19) the InDesign server puts all the pages at the top level
 * with the page elements directly underneath
 * Whereas the microsite/presentation editor puts the pages in a key of pages (as there's other top level info)
 * with the page elements in a 'content' key underneath (again there's other top level info)
 * @param {*} configuration
 * @param {*} path
 * @param {*} property
 * @param {*} value
 */
export const updateConfiguration = (configuration, path, property, value) => {
    // console.log({ configuration, path, property, value });
    const currentPageElement = get(configuration, path);

    const updatedPageElement = {
        ...currentPageElement,
        [property]: value,
    };
    // console.log(currentPageElement, updatedPageElement);
    set(configuration, path, updatedPageElement);

    return configuration;
};
