import React from "react";
import styled from "styled-components";
import ReactToggle from "react-toggle";
import FormLabel from "../styledElements/label";

const ToggleWrapper = styled.div`
    display: inline-block;

    .react-toggle {
        touch-action: pan-x;

        display: inline-block;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-right: 0.5rem;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
    }

    .react-toggle-screenreader-only {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .react-toggle--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        -webkit-transition: opacity 0.25s;
        transition: opacity 0.25s;
    }

    .react-toggle-track {
        width: ${({ size }) => {
            switch (size) {
                case "small":
                    return "25px";
                case "medium":
                    return "35px";
                case "large":
                    return "50px";
                default:
                    return "50px";
            }
        }};
        height: ${({ size }) => {
            switch (size) {
                case "small":
                    return "12px";
                case "medium":
                    return "18px";
                case "large":
                    return "24px";
                default:
                    return "24px";
            }
        }};
        padding: 0;
        border-radius: 30px;
        background-color: #4d4d4d;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #000000;
    }

    .react-toggle--checked .react-toggle-track {
        background-color: #19ab27;
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled)
        .react-toggle-track {
        background-color: #128d15;
    }

    .react-toggle-track-check {
        position: absolute;
        width: 14px;
        height: 10px;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        left: 8px;
        opacity: 0;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }

    .react-toggle--checked .react-toggle-track-check {
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }

    .react-toggle-track-x {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        right: 10px;
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
    }

    .react-toggle--checked .react-toggle-track-x {
        city: 0;
    }

    .react-toggle-thumb {
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        position: absolute;
        top: ${({ size }) => {
            switch (size) {
                case "small":
                    return "0px";
                case "medium":
                    return "0px";
                case "large":
                    return "1px";
                default:
                    return "1px";
            }
        }};
        left: 1px;
        width: ${({ size }) => {
            switch (size) {
                case "small":
                    return "12px";
                case "medium":
                    return "18px";
                case "large":
                    return "22px";
                default:
                    return "22px";
            }
        }};
        height: ${({ size }) => {
            switch (size) {
                case "small":
                    return "12px";
                case "medium":
                    return "18px";
                case "large":
                    return "22px";
                default:
                    return "22px";
            }
        }};
        border: 1px solid #4d4d4d;
        border-radius: 50%;
        background-color: #fafafa;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .react-toggle--checked .react-toggle-thumb {
        left: ${({ size }) => {
            switch (size) {
                case "small":
                    return "12px";
                case "medium":
                    return "18px";
                case "large":
                    return "27px";
                default:
                    return "27px";
            }
        }};
        border-color: #19ab27;
    }

    .react-toggle--focus .react-toggle-thumb {
        -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
        -moz-box-shadow: 0px 0px 3px 2px #0099e0;
        box-shadow: 0px 0px 2px 3px #0099e0;
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
        -moz-box-shadow: 0px 0px 5px 5px #0099e0;
        box-shadow: 0px 0px 5px 5px #0099e0;
    }
`;

const FlexLabel = styled.label`
    display: flex;
    align-items: center;
`;

const Toggle = ({
    defaultChecked = false,
    icons = false,
    onChange, // event => {event.target.checked is the value}
    children,
    disabled,
    checked,
    label,
    size = "large",
    ...rest
}) => {
    return (
        <ToggleWrapper size={size} {...rest}>
            <FlexLabel>
                {label && (
                    <FormLabel name={name} marginBottom={1}>
                        {label}
                    </FormLabel>
                )}
                <ReactToggle
                    checked={checked}
                    // defaultChecked={defaultChecked}
                    icons={icons}
                    onChange={onChange}
                    disabled={disabled}
                />
                {children ? children : null}
            </FlexLabel>
        </ToggleWrapper>
    );
};

export default Toggle;
