import React, { Component } from "react";
import { Link } from "react-router-dom";
import { format } from "@cortexglobal/cortex-utilities";

import { trans } from "@cortexglobal/rla-intl";

class RequestRow extends Component {
    state = {
        request: {},
    };

    componentDidMount = () => {
        const { request } = this.props;

        this.setState({
            request: request,
        });
    };

    updateWorkflow = (smartRef, newWorkflow) => {
        this.setState({
            request: { ...this.state.request, workflow: { ...newWorkflow } },
        });
        this.props.onUpdate();
    };

    renderAssignedTo = (workflow) => {
        let assignedTo = [];

        if (workflow.assigned_to_group) {
            assignedTo.push(workflow.assigned_to_group);
        }

        if (workflow.assigned_to_team) {
            assignedTo.push(workflow.assigned_to_team);
        }

        if (workflow.assigned_to_role) {
            assignedTo.push(workflow.assigned_to_role);
        }

        if (workflow.assigned_to_user) {
            assignedTo.push(workflow.assigned_to_user);
        }

        return assignedTo.join(", ");
    };

    render = () => {
        const { requestsPath } = this.props;
        const { request } = this.state;

        if (_.isEmpty(request)) {
            return (
                <tr>
                    <td colSpan="100%">
                        <p>No requests found</p>
                    </td>
                </tr>
            );
        }

        const created = new Date(request.created);
        const updated = new Date(request.updated);
        const workflow = request.workflow;
        const { status } = workflow;

        const linkToRequest = requestsPath + "/view/" + request.uuid;

        return (
            <tr>
                <td>
                    <Link to={linkToRequest}>
                        {trans("#")}
                        {request.ref}
                    </Link>
                </td>
                <td>{request.type?.category?.text || "-"}</td>
                <td>{request.type?.name || "-"}</td>
                <td>{status}</td>
                <td>{this.renderAssignedTo(workflow)}</td>
                <td>{request.createdBy}</td>
                <td>{format(created, "Pp")}</td>
                <td>{format(updated, "Pp")}</td>
                <td>
                    <Link to={linkToRequest}>{trans("View")}</Link>
                </td>
            </tr>
        );
    };
}

export default RequestRow;
