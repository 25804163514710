import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Link } from "react-router-dom";

const Breadcrumb = styled.li`
    margin: 0;
    user-select: none;
    font-size: ${props => props.theme.breadcrumb.crumb.fontSize};
    padding: 1em 0;
    a {
        color: ${props => props.theme.breadcrumb.crumb.linkColor};
        position: relative;
        padding: 0 0.6em 0 1em;
        text-decoration: none;
        font-weight: normal;
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%
            width: 0.4em;
            height: 0.4em;
            transform: rotate(45deg) translateX(-50%);
            border-right: 0.1em solid
                ${props => props.theme.breadcrumb.crumb.linkColor};
            border-top: 0.1em solid
                ${props => props.theme.breadcrumb.crumb.linkColor};
        }
    }
    &:first-of-type {
        a {
            padding-left:0;
            &:before {
                display:none;
            }
        }
    }
    &:last-of-type {
        a {
            text-decoration: underline;
        }
    }
`;

class Crumb extends React.Component {
    render() {
        let { path, title, current } = this.props;
        if (current) {
            return <Breadcrumb>{title}</Breadcrumb>;
        }
        return (
            <Breadcrumb>
                <Link to={path}>{title}</Link>
            </Breadcrumb>
        );
    }
}

export default Crumb;
