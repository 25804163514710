import {
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_TOOLTIP,
    HIDE_TOOLTIP
} from "../actions/index";

const initialState = {
    modalVisible: false,
    modalName: "",
    tooltipVisible: false
};

export default function modal(state = initialState, action) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                modalName: action.modalName,
                modalVisible: true
            };

        case HIDE_MODAL:
            return { ...state, modalName: "", modalVisible: false };

        case SHOW_TOOLTIP:
            return { ...state, tooltipVisible: true };

        case HIDE_TOOLTIP:
            return { ...state, tooltipVisible: false };

        default:
            return state;
    }
}
