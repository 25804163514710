import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import EventObject from "../objects/EventObject";
import moment from "moment";
import {
    Row,
    Column,
    TableList,
    Error,
    IconTextButton,
    Loading,
    PageTitle,
    ButtonDropdown,
} from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { Link } from "react-router-dom";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { faPlus, faBoxes, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";

const Events = (props) => {
    const { can } = usePermissions();
    const intl = useIntl();
    const [loadingState, setLoadingState] = useState("loading");
    const [events, setEvents] = useState([]);

    useEffect(() => {
        getEvents();
    }, []);

    const getEvents = () => {
        setLoadingState("loading");

        axios
            .get(`/api/v1/events`)
            .then(({ data }) => {
                const events = data.data.map(
                    (eventData) => new EventObject(eventData)
                );
                setEvents(events);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });

        return;
    };

    const generateEventActions = (event) => {
        let actions = [];

        if (event.workflowable?.allow_edits) {
            actions.push({
                name: intl.formatMessage({ id: "Edit" }),
                onClick: () => {
                    history.push(`/events/planner/${event.uuid}`);
                },
            });
        }

        if (
            !event.workflowable ||
            event.workflowable?.isClosed ||
            event.workflowable?.allow_edits
        ) {
            actions.push({
                name: intl.formatMessage({ id: "Delete" }),
                onClick: () => {
                    deleteItem(item);
                },
            });
        }

        return actions;
    };

    const renderAssignedTo = (workflow) => {
        let assignedTo = [];

        if (workflow.assigned_to_group) {
            assignedTo.push(workflow.assigned_to_group);
        }

        if (workflow.assigned_to_team) {
            assignedTo.push(workflow.assigned_to_team);
        }

        if (workflow.assigned_to_role) {
            assignedTo.push(workflow.assigned_to_role);
        }

        if (workflow.assigned_to_system_role) {
            assignedTo.push(workflow.assigned_to_system_role);
        }

        if (workflow.assigned_to_user) {
            assignedTo.push(workflow.assigned_to_user);
        }

        return assignedTo.join(", ");
    };

    const render = (state, events) => {
        if (state === "loading") {
            return (
                <tr>
                    <td colSpan="100%">
                        <Loading />
                    </td>
                </tr>
            );
        }

        if (state === "error") {
            return (
                <tr>
                    <td colSpan="100%">
                        <Error />
                    </td>
                </tr>
            );
        }

        if (!events.length) {
            return (
                <tr>
                    <td colSpan="100%">No events available</td>
                </tr>
            );
        }

        return (
            <>
                {events.map((event) => (
                    <tr key={event.uuid}>
                        <td>
                            <Link to={`/events/${event.uuid}`}>
                                {event.ref}
                            </Link>
                        </td>
                        <td>{event.name}</td>
                        <td>{event.workflowable?.state?.name || "Unknown"}</td>
                        <td>
                            {moment.isMoment(event.start_date)
                                ? event.start_date.format("DD/MM/Y")
                                : event.start_date}
                        </td>
                        <td>
                            {moment.isMoment(event.end_date)
                                ? event.end_date.format("DD/MM/Y")
                                : event.end_date}
                        </td>
                        <td>
                            {event.created.format("DD/MM/Y HH:mm")}{" "}
                            {trans("by")} {event.createdBy}
                        </td>
                        <td>
                            <ButtonDropdown
                                type="secondary"
                                expanded={true}
                                onClick={() => {
                                    history.push(`/events/${event.uuid}`);
                                }}
                                actions={generateEventActions(event)}
                            >
                                {trans("View")}
                            </ButtonDropdown>
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    return (
        <>
            <PageTitle title={trans("My Events")}>
                {can("events-booking-requests-create") && (
                    <IconTextButton
                        as={Link}
                        to={"/events/planner"}
                        showCircle={false}
                        icon={faPlus}
                    >
                        {trans("Create a new event")}
                    </IconTextButton>
                )}
            </PageTitle>
            <Row>
                <Column medium={12}>
                    <TableList>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Event</th>
                                <th>Status</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Created</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>{render(loadingState, events)}</tbody>
                    </TableList>
                </Column>
            </Row>
        </>
    );
};

export default Events;
