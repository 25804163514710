import React, { useState } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    SimpleDropzone,
    useAddToast,
    useToasts
} from "@cortexglobal/rla-components";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const FileUpload = ({
    name,
    onChange,
    asset,
    value
}) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const onDrop = acceptedFiles => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans('Asset is being uploaded'),
        });

        const fd = new FormData();
        fd.append("file", acceptedFiles[0]);
        fd.append("type", "pdf");
        fd.append("asset_input_name", name);
        axios
            .post(`/api/v1/asset-file/${asset.uuid}`, fd)
            .then(({ data }) => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });

                if (value) {
                    const pdfView = document.getElementById("pdfView");
                    const newPdfView = pdfView.cloneNode(true);
                    newPdfView.setAttribute('src', data);
                    pdfView.parentNode.replaceChild(newPdfView, pdfView);
                }

                onChange({ name, value: data });
            })
            .catch(e => {
                console.log(e.response);
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred uploading your file");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000
                });
            });
    };

    return (
        <>
            {value &&
                <div style={{ margin: '1rem 0' }}>
                    <a href={value} target="_blank">{value}</a>
                </div>
            }
            <SimpleDropzone onDrop={onDrop}>
                <IconTextButton
                    style={{
                        textDecoration: "none",
                        paddingTop: "1rem",
                        paddingBottom: "0.8rem"
                    }}
                    onClick={() => {
                        return false;
                    }}
                    icon={faUpload}
                    showCircle={false}
                    expanded={false}
                >
                    <div style={{ fontWeight: "400" }}>
                        {trans("Upload")}
                    </div>
                </IconTextButton>
            </SimpleDropzone>
        </>
    );
};

export default FileUpload;
