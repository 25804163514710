import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Column,
    Panel,
    Tabordion,
    Table,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import UserForm, { User } from "../../forms/UserForm";
//@ts-ignore cortex
import { format } from "@cortexglobal/cortex-utilities";

import { HeaderEmphasis, TabordionGroup } from "../../components/index";
import UserCategoryForm from "../../forms/UserCategoryForm";
import UserGroupForm from "../../forms/UserGroupForm";

//@ts-ignore cortex
const UserNotes = ({ user }) => {
    return (
        <Row>
            <Column>
                {Array.isArray(user.notes) && user.notes.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>{trans("Date")}</th>
                                <th>{trans("User")}</th>
                                <th>{trans("Note")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user.notes.map((note) => (
                                <tr>
                                    <td>{format(new Date(note.date), "Pp")}</td>
                                    <td>
                                        {note.addedByUserName ||
                                            trans("System")}
                                    </td>
                                    <td>{note.text}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>{trans("No notes for this user")}</p>
                )}
            </Column>
        </Row>
    );
};

export const UserEdit = ({ id, path }) => {
    const page = useQueryString().get("page");
    const [tab, setTab] = useState(0);

    const [item, setItem] = useState<{ loaded: boolean; data?: User }>({
        loaded: false,
        data: null,
    });

    const getItem = () => {
        setItem({ loaded: false, data: null });
        axios.get(`/api/v1/admin/user/${id}`).then(({ data }) => {
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, [id]);

    const submitForm = (values, setSubmitting) => {
        return axios
            .put(`/api/v1/admin/user/${id}`, values)
            .then(({ data }) => {
                setSubmitting(false);
                setItem({
                    loaded: true,
                    data: data.data,
                });
            });
    };

    return (
        <>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Edit User")}{" "}
                        <HeaderEmphasis>
                            {item.data
                                ? `${item.data.first_name} ${item.data.last_name}`
                                : ""}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={`${path}?page=${page ? page : 1}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            {item.loaded ? (
                <Row>
                    <Panel style={{ paddingBottom: "0" }}>
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <UserForm
                                    item={item.data}
                                    submitForm={submitForm}
                                />
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Categories")}</span>}
                                onTabClick={() => setTab(1)}
                            >
                                <UserCategoryForm user={item.data} />
                            </TabordionGroup>
                            <TabordionGroup
                                heading={<span>{trans("Groups")}</span>}
                                onTabClick={() => setTab(2)}
                            >
                                <UserGroupForm user={item.data} tab={tab} />
                            </TabordionGroup>

                            <TabordionGroup
                                heading={<span>{trans("Notes")}</span>}
                                onTabClick={() => setTab(3)}
                            >
                                <UserNotes user={item.data} />
                            </TabordionGroup>
                        </Tabordion>
                    </Panel>
                </Row>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default UserEdit;
