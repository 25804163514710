import axios from "axios";

// export const GET_REQUEST_FILTERS = "GET_REQUEST_FILTERS";
export function getRequestsFilters(list, groupFilter, statusFilter) {}

export const GET_REQUEST_SUMMARY = "GET_REQUEST_SUMMARY";
export const GET_REQUEST_SUMMARY_ERROR = "GET_REQUEST_SUMMARY_ERROR";
export const GET_REQUEST_SUMMARY_LOADING = "GET_REQUEST_SUMMARY_LOADING";
export function getRequestsSummary(includeClosed) {
    return (dispatch) => {
        dispatch({
            type: GET_REQUEST_SUMMARY_LOADING,
        });

        let params = {};

        if (includeClosed !== undefined) {
            params["include_closed"] = !!includeClosed;
        }

        axios
            .get("/api/v1/requests/summary", { params: params })
            .then(({ data }) => {
                dispatch({
                    type: GET_REQUEST_SUMMARY,
                    data: data.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: GET_REQUEST_SUMMARY_ERROR,
                    errorMessage: e.message,
                });
            });
    };
}

export const GET_REQUESTS_LOADING = "GET_REQUESTS_LOADING";
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_REQUESTS_ERROR = "GET_REQUESTS_ERROR";
export const GET_REQUEST_FILTERS = "GET_REQUEST_FILTERS";
export function getRequests(
    page,
    list,
    groupFilter,
    statusFilter,
    typeFilter,
    includeClosed
) {
    return (dispatch) => {
        dispatch({
            type: GET_REQUESTS_LOADING,
        });

        let params = {
            // per_page: 3,
            page: page || 1,
        };

        if (list !== undefined) {
            params["list"] = list;
        }

        if (groupFilter !== undefined) {
            params["group"] = groupFilter;
        }

        if (statusFilter !== undefined) {
            params["status"] = statusFilter;
        }

        if (typeFilter !== undefined) {
            params["type"] = typeFilter;
        }

        if (includeClosed !== undefined) {
            params["include_closed"] = !!includeClosed;
        }

        return axios
            .get("/api/v1/requests", { params: params })
            .then(({ data }) => {
                dispatch({
                    // this must be called before GET_REQUESTS to ensure filters are loaded properly
                    type: GET_REQUEST_FILTERS,
                    data: data.meta.filters,
                });

                dispatch({
                    type: GET_REQUESTS,
                    data: data,
                });
                return data;
            })
            .catch((e) => {
                dispatch({
                    type: GET_REQUESTS_ERROR,
                    errorMessage: e.message,
                });
            });
    };
}

export const GET_REQUEST = "GET_REQUEST";
export const GET_REQUEST_ERROR = "GET_REQUEST_ERROR";
export function getRequest(uuid) {
    return (dispatch) => {
        axios
            .get("/api/v1/requests/" + uuid)
            .then(({ data }) => {
                dispatch({
                    type: GET_REQUEST,
                    data: data.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: GET_REQUEST_ERROR,
                    errorMessage: e.message,
                });
            });
    };
}

export const ADD_REQUEST = "ADD_REQUEST";
export function addRequest(formData) {
    return (dispatch) => {
        return axios.post("/api/v1/requests", formData).then(({ data }) => {
            dispatch({
                type: ADD_REQUEST,
                data: data.data,
            });
        });
    };
}

export const EDIT_REQUEST = "EDIT_REQUEST";
export function editRequest(requestUuid, formData) {
    return (dispatch) => {
        return axios
            .post(`/api/v1/requests/${requestUuid}`, formData)
            .then(({ data }) => {
                dispatch({
                    type: EDIT_REQUEST,
                    data: data.data,
                });
            });
    };
}

export const GET_REQUEST_TYPES = "GET_REQUEST_TYPES";
export const GET_REQUEST_TYPES_ERROR = "GET_REQUEST_TYPES_ERROR";
export function getRequestTypes() {
    return (dispatch) => {
        return axios
            .get("/api/v1/request-types")
            .then(({ data }) => {
                dispatch({
                    type: GET_REQUEST_TYPES,
                    data: data.data,
                });
                return data.data;
            })
            .catch((e) => {
                dispatch({
                    type: GET_REQUEST_TYPES_ERROR,
                    errorMessage: e.message,
                });
            });
    };
}
