import React from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import {
    Loading,
    Table,
    Pagination,
    ButtonDropdown,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import useUsers from "../../hooks/useUsers";

import { EmptyRow } from "../../components";
import { User } from "../../forms/UserForm";

const TableHeader = styled.div`
    cursor: pointer;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`;

export const UserTable = ({
    items,
    loaded,
    path,
    pagination = null,
    onPageChange,
    onOrderBy,
    onPerPageChange,
    refreshItems,
}: UserTableProps) => {
    const history = useHistory();
    const { can } = usePermissions();
    const {
        disableUser,
        deleteUser,
        activateUser,
        approveUser,
        rejectUser,
        resendActivation,
        ghostAsUser,
    } = useUsers();
    const currentPage = pagination ? pagination.current_page : 1;

    const getUserActions = (user) => {
        const { status } = user;

        const actions = [];

        switch (status) {
            case "active":
                if (can("manage-users-disable")) {
                    actions.push({
                        name: "Disable",
                        onClick: () =>
                            disableUser(user.uuid).then(() => {
                                refreshItems();
                            }),
                    });
                }
                if (can("ghost")) {
                    actions.push({
                        name: "Ghost",
                        onClick: () =>
                            ghostAsUser(user.id).then(() => {
                                window.location.href = "/";
                            }),
                    });
                }
                break;
            case "account-disabled":
                if (can("manage-users-activate")) {
                    actions.push({
                        name: "Activate",
                        onClick: () =>
                            activateUser(user.uuid).then(() => {
                                refreshItems();
                            }),
                    });
                }
                if (can("manage-users-delete")) {
                    actions.push({
                        name: "Delete",
                        onClick: () =>
                            deleteUser(user.uuid).then(() => {
                                refreshItems();
                            }),
                    });
                }
                break;
            case "awaiting-approval":
                if (can("manage-users-approve")) {
                    actions.push({
                        name: "Approve",
                        onClick: () =>
                            approveUser(user.uuid).then(() => {
                                refreshItems();
                            }),
                    });
                    actions.push({
                        name: "Reject",
                        onClick: () =>
                            rejectUser(user.uuid).then(() => {
                                refreshItems();
                            }),
                    });
                }
                break;
            case "awaiting-activation":
                if (can("manage-users-activate")) {
                    actions.push({
                        name: "Resend Activation Email",
                        onClick: () =>
                            resendActivation(user.uuid).then(() => {
                                refreshItems();
                            }),
                    });
                }
                if (can("manage-users-delete")) {
                    actions.push({
                        name: "Delete",
                        onClick: () =>
                            deleteUser(user.uuid).then(() => {
                                refreshItems();
                            }),
                    });
                }
                break;
            case "pending":
                actions.push({
                    name: "No Actions Available",
                });
                break;
        }

        return actions;
    };

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <TableHeader onClick={() => onOrderBy("id")}>
                                {trans("ID")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader
                                onClick={() => onOrderBy("first_name")}
                            >
                                {trans("First Name")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("last_name")}>
                                {trans("Last Name")}
                            </TableHeader>
                        </th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("email")}>
                                {trans("Email")}
                            </TableHeader>
                        </th>
                        <th>{trans("Primary Group")}</th>
                        <th>{trans("Primary Role")}</th>
                        <th>
                            <TableHeader onClick={() => onOrderBy("status")}>
                                {trans("Status")}
                            </TableHeader>
                        </th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <EmptyRow>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow>{trans("No items found")}</EmptyRow>
                    ) : (
                        items.map((item) => {
                            return (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.first_name}</td>
                                    <td>{item.last_name}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {item.primary_group && (
                                            <Link
                                                to={`/admin/groups/edit/${
                                                    item.primary_group.uuid
                                                }?page=${
                                                    currentPage
                                                        ? currentPage
                                                        : 1
                                                }`}
                                            >
                                                {item.primary_group
                                                    ? item.primary_group.name
                                                    : ""}
                                            </Link>
                                        )}
                                    </td>
                                    <td>
                                        {item.primary_role && (
                                            <Link
                                                to={`/admin/roles/edit/${
                                                    item.primary_role.uuid
                                                }?page=${
                                                    currentPage
                                                        ? currentPage
                                                        : 1
                                                }`}
                                            >
                                                {item.primary_role
                                                    ? item.primary_role.i18n
                                                          .name
                                                    : ""}
                                            </Link>
                                        )}
                                    </td>
                                    <td>{item.status_wording}</td>
                                    <td>
                                        <ButtonDropdown
                                            onClick={() =>
                                                history.push(
                                                    `${path}/edit/${item.id}?page=${currentPage}`
                                                )
                                            }
                                            name="primary"
                                            actions={getUserActions(item)}
                                        >
                                            {trans("Edit")}
                                        </ButtonDropdown>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
        </React.Fragment>
    );
};

interface UserTableProps {
    items: User[];
    loaded: boolean;
    path: string;
    pagination?: any;
    onPageChange?: (number) => void;
    onOrderBy?: (string) => void;
    onPerPageChange?: ({ value }) => void;
    refreshItems?: () => void;
}

export default UserTable;
