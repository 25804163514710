import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    LoadingIconSmall,
    FileIcon,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    SearchResultSummary,
    SearchResultBadge,
    ShowMore,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const CategoryResultItem = (props) => {
    const {
        loaded,
        category,
        canEdit,
        match: { path },
        onClick = null,
        assetsPath,
        margin,
        showAssetCount = false,
        ...rest
    } = props;

    const getImageSrc = () => {
        let imageSrc = `${category.thumbnail}`;
        return imageSrc;
    };

    return (
        <SearchResultWrapper
            textAlign="center"
            style={{ padding: 0, margin: margin || "0rem", height: "100%" }}
        >
            <Link
                onClick={(event) => onClick(event, category)}
                // to={`${assetsPath}/view/${category.uuid}`}
            >
                <SearchResultImageWrapper background={category.color}>
                    {loaded ? (
                        category.thumbnail === null ? (
                            <FileIcon
                                colorBehind={props.theme.colors.gray200}
                            ></FileIcon>
                        ) : (
                            <SearchResultImage
                                src={getImageSrc()}
                                alt={category.name}
                                objectFit={"cover"}
                            />
                        )
                    ) : (
                        <LoadingIconSmall></LoadingIconSmall>
                    )}
                </SearchResultImageWrapper>
                {/**
                    TODO: this badge needs to be wrapped under which status to show and that needs 
                    to come from configuration also possible style to highlight important 
                    eg. internal / external, approved / rejected...
                */}
            </Link>

            <SearchResultButton
                onClick={(event) => onClick(event, category)}
                showCircle={rest.theme.search.icons.showCircle}
                iconBefore={false}
                doHover={false}
                icon={rest.theme.search.icons.searchIcon}
                iconColor={rest.theme.search.icons.color}
                iconSize={rest.theme.search.icons.size}
            >
                <SearchResultTitle title={category.name} width="95%">
                    <ShowMore more=" " lines={3}>
                        {loaded && category.text}
                    </ShowMore>
                </SearchResultTitle>
                {loaded && category["media_type_name"] && (
                    <SearchResultSummary title={category["media_type_name"]}>
                        {trans("Type: ")} {category["media_type_name"]}
                    </SearchResultSummary>
                )}
                {loaded && showAssetCount && (
                    <SearchResultSummary>
                        {trans("Assets: ")} {category["asset_count"]}
                    </SearchResultSummary>
                )}
            </SearchResultButton>
        </SearchResultWrapper>
    );
};
export default withTheme(withRouter(CategoryResultItem));
