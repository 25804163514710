import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    InputField,
    HelpText,
    Select,
    FormLabel,
    DatePicker,
    IconTextButton,
    Panel,
    ValidationError,
    renderStandReturnInput,
    FormRow,
    ConditionalWrapper,
} from "@cortexglobal/rla-components";
import MetaMediaType from "./MetaMediaType";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons/faCodeBranch";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import { SectionHeading } from "./components/SectionHeading";

const MetaDetails = ({
    asset,
    standardOnChange,
    filters,
    searchableMeta,
    errors,
    singleColumn = false,
    showSectionHeading = true,
    collapse = false,
    excludedFields = [],
}) => {
    const [mediaForm, setMediaForm] = useState({ form: null });

    useEffect(() => {
        if (!excludedFields.includes("mediaForm") && asset.media_type) {
            axios
                .get(`/api/v1/admin/assets/${asset.media_type}/meta-form`)
                .then(({ data }) => {
                    setMediaForm(data.data);
                })
                .catch((e) => e);
        }
    }, [asset.asset_type]);

    const onMetaChange = ({ name, value }) => {
        let meta = { ...asset.meta_data, [name]: value };
        // console.log("Meta Changes", { name, value }, meta);

        standardOnChange({ name: "meta_data", value: meta });
    };

    const [displayOverride, setDisplayOverride] = useState(!asset.version);

    return (
        <>
            {showSectionHeading && (
                <SectionHeading>{trans("General")}</SectionHeading>
            )}

            <ConditionalWrapper
                condition={!singleColumn}
                wrapper={(children) => <Panel>{children}</Panel>}
            >
                <FormRow
                    singleColumn={singleColumn}
                    collapse={collapse}
                    name="author"
                    label={trans("Author / Source")}
                    error={errors.author}
                    helpText={trans("Optional")}
                >
                    <InputField
                        name="author"
                        value={asset.author}
                        onChange={standardOnChange}
                        showError={false}
                    />
                </FormRow>

                {!excludedFields.includes("version") && (
                    <Row>
                        {displayOverride ? (
                            <>
                                <Column
                                    medium={3}
                                    style={{ textAlign: "right" }}
                                >
                                    <FormLabel name="System_version_number">
                                        {trans("System Version")}
                                    </FormLabel>
                                </Column>
                                <Column medium={5}>
                                    <InputField
                                        name="System_version_number"
                                        value={asset.versionNumber}
                                        disabled={true}
                                        onChange={() => {}}
                                    />
                                </Column>
                                <Column medium={1}>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        onClick={(event) => {
                                            setDisplayOverride(
                                                !displayOverride
                                            );
                                        }}
                                        icon={faUnlockAlt}
                                        showCircle={false}
                                        title="Override system generated version with your own custom version name or number."
                                    ></IconTextButton>
                                </Column>
                            </>
                        ) : (
                            <>
                                <Column
                                    medium={3}
                                    style={{ textAlign: "right" }}
                                >
                                    <FormLabel name="version">
                                        {trans("Custom Version")}
                                    </FormLabel>
                                </Column>
                                <Column medium={5}>
                                    <InputField
                                        name="version"
                                        value={asset.version}
                                        onChange={standardOnChange}
                                    />
                                </Column>
                                <Column medium={1}>
                                    <IconTextButton
                                        type="secondary"
                                        expanded
                                        onClick={(event) => {
                                            standardOnChange({
                                                name: "version",
                                                value: "",
                                            });
                                            setDisplayOverride(
                                                !displayOverride
                                            );
                                        }}
                                        icon={faCodeBranch}
                                        showCircle={false}
                                        title="Clear custom version number and use system generated version instead"
                                    ></IconTextButton>
                                </Column>
                            </>
                        )}

                        <Column medium={3}>{/*validation*/}</Column>
                    </Row>
                )}

                <Row>
                    {asset.produced_at && (
                        <FormRow
                            singleColumn={singleColumn}
                            collapse={collapse}
                            name="produced_at"
                            label={trans("Date Produced")}
                            error={errors.produced_at}
                            helpText={trans("Optional")}
                        >
                            <DatePicker
                                name={"produced_at"}
                                onChange={standardOnChange}
                                value={new Date(asset.produced_at)}
                            />
                        </FormRow>
                    )}
                </Row>

                {filters && filters.loaded && (
                    <>
                        {Object.keys(filters.data).map((index) => {
                            let item = filters.data[index];

                            const filterExemptions = [
                                "media-channel",
                                "media-type",
                                "admin_options",
                                "features",
                                "availability_status",
                            ];

                            const extra = item.extra;

                            if (
                                filterExemptions.includes(item.name) ===
                                    false &&
                                !extra.hideFromEditor
                            ) {
                                item = { ...item, ...extra };

                                return (
                                    <FormRow
                                        key={item.name}
                                        singleColumn={singleColumn}
                                        collapse={collapse}
                                        name={item.name}
                                        label={item.label}
                                        error={errors.produced_at}
                                        helpText={
                                            extra &&
                                            extra.requiredInAdmin === true
                                                ? trans("Required")
                                                : trans("Optional")
                                        }
                                    >
                                        {renderStandReturnInput(
                                            { ...item, hideLabel: true },
                                            asset[item.name],
                                            standardOnChange,
                                            () => {},
                                            10,
                                            "admin"
                                        )}
                                    </FormRow>
                                );
                                // }
                            }
                        })}
                        {searchableMeta &&
                            searchableMeta.loaded &&
                            Object.keys(searchableMeta.data).map((index) => {
                                const item = searchableMeta.data[index];
                                return (
                                    <FormRow
                                        key={item.name}
                                        singleColumn={singleColumn}
                                        collapse={collapse}
                                        name={item.name}
                                        label={item.label}
                                        error={errors.produced_at}
                                        helpText={
                                            extra &&
                                            extra.requiredInAdmin === true
                                                ? trans("Required")
                                                : trans("Optional")
                                        }
                                    >
                                        <Select
                                            name={item.name}
                                            options={item.options}
                                            onChange={standardOnChange}
                                            value={asset[item.name]}
                                            tags={false}
                                            multi={true}
                                            creatable={false}
                                        />
                                    </FormRow>
                                );
                            })}
                    </>
                )}
            </ConditionalWrapper>
            {mediaForm && mediaForm.form && mediaForm.form.elements.length > 0 && (
                <>
                    <Row>
                        <Column>
                            <SectionHeading>
                                {asset.media_type_name &&
                                asset.media_type_name !== "-"
                                    ? trans("{mediaLabel} Fields", {
                                          mediaLabel: asset.media_type_name,
                                      })
                                    : trans("Media Specific Fields")}
                            </SectionHeading>
                        </Column>
                    </Row>
                    <Panel>
                        <MetaMediaType
                            onChange={onMetaChange}
                            mediaForm={mediaForm.form}
                            asset={asset}
                        />
                    </Panel>
                </>
            )}
        </>
    );
};

export default MetaDetails;
