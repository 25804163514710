import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    IconTextButton,
    PageTitle,
    InputField,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import useExternalDashboards from "../../hooks/useExternalDashboards";
import { ExternalDashboardTable } from "./ExternalDashboardTable";

export const ExternalDashboardListing = ({ path }) => {
    const history = useHistory();
    const page = useQueryString().get("page") || "1";
    const searchParam = useQueryString().get("search") || "";
    const orderByParam = useQueryString().get("orderBy") || "";
    const dirParam = useQueryString().get("dir") || "ASC";
    const perPageParam = useQueryString().get("perPage") || 10;

    const [searchInput, setSearchInput] = useState(searchParam);
    const [search, setSearch] = useState(searchParam);

    const [orderBy, setOrderBy] = useState(orderByParam);
    const [orderByDirection, setoOrderByDirection] = useState(dirParam);

    //@ts-ignore cortex
    const [items, setItems] = useState({
        loaded: false,
        data: [],
        pagination: null,
    });

    const { loadItems, deleteItem } = useExternalDashboards();

    useEffect(() => {
        loadItems(
            setItems,
            page,
            search,
            orderBy,
            orderByDirection,
            perPageParam
        );
    }, [setItems]);

    const onSearch = () => {
        if (searchInput.length) {
            const orderByValue = orderBy
                ? `&orderBy=${orderBy}&dir=${orderByDirection}`
                : "";

            setSearch(searchInput);
            history.push(`?page=${page}&search=${searchInput}${orderByValue}`);
            loadItems(
                setItems,
                page,
                searchInput,
                orderBy,
                orderByDirection,
                perPageParam
            );
        }
    };

    const onOrderBy = (data) => {
        let orderDir = orderByDirection;

        if (orderBy === data) {
            setoOrderByDirection(orderByDirection === "ASC" ? "DESC" : "ASC");
            orderDir = orderByDirection === "ASC" ? "DESC" : "ASC";
        } else {
            setOrderBy(data);
            setoOrderByDirection("ASC");
            orderDir = "ASC";
        }

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(
            `?page=${page}&orderBy=${data}&dir=${orderDir}${searchValue}${perPageValue}`
        );
        loadItems(setItems, page, search, data, orderDir, perPageParam);
    };

    const onClearSearch = () => {
        setSearch("");
        setSearchInput("");

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";

        history.push(`?page=${page}${orderByValue}${perPageValue}`);
        loadItems(setItems, page, "", orderBy, orderByDirection, perPageParam);
    };

    const onPerPageChange = ({ value }: { value: string }) => {
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(`?perPage=${value}${orderByValue}${searchValue}`);
        loadItems(setItems, page, search, orderBy, orderByDirection, value);
    };

    return (
        <div>
            <PageTitle title={trans("External Dashboards")}>
                {search && (
                    <IconTextButton
                        onClick={onClearSearch}
                        showCircle={false}
                        icon={faTimes}
                    >
                        {trans("Clear search")}
                    </IconTextButton>
                )}
                <InputField
                    style={{ width: "300px", marginBottom: "0" }}
                    value={searchInput}
                    onChange={(item) => setSearchInput(item.value)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            onSearch();
                        }
                    }}
                />
                <IconTextButton
                    onClick={onSearch}
                    showCircle={false}
                    icon={faSearch}
                ></IconTextButton>
                <IconTextButton
                    as={Link}
                    to={`${path}/create`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    icon={faPlus}
                >
                    {trans("Create new External Dashboard")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <ExternalDashboardTable
                    onPerPageChange={onPerPageChange}
                    onOrderBy={onOrderBy}
                    items={items.data}
                    loaded={items.loaded}
                    path={path}
                    pagination={items.pagination}
                    onPageChange={(selected) => {
                        history.push(`?page=${selected}&search=${search}`);
                        loadItems(
                            setItems,
                            selected,
                            search,
                            orderBy,
                            orderByDirection,
                            perPageParam
                        );
                    }}
                    deleteItem={(uuid) => {
                        return deleteItem(
                            uuid,
                            setItems,
                            page,
                            search,
                            orderBy,
                            orderByDirection,
                            perPageParam
                        );
                    }}
                />
            </Row>
        </div>
    );
};

export default ExternalDashboardListing;
