import React, { useState, useEffect } from "react";
import axios from "axios";
// import { Flipper, Flipped } from "react-flip-toolkit";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import {
    Row,
    Column,
    Loading,
    LoadingIconSmall,
    Pagination,
    StyledCheckbox,
    Panel,
} from "@cortexglobal/rla-components";

import AssetResultItem from "./AssetResultItem";

const RelatedAssets = ({ asset, selectAsset, assetDetailLoaded }) => {
    const { can } = usePermissions();
    const intl = useIntl();
    const [relatedAssetLoaded, setRelatedAssetsLoaded] = useState(false);
    const [relatedAssets, setRelatedAssets] = useState([]);
    const [pagination, setPagination] = useState({});
    const [includeDrafts, setIncludeDrafts] = useState(false);
    const canViewRelatedAssets = can("dam-asset-admin");
    const loadAssets = (page = 1, includeDrafts = false) => {
        setRelatedAssetsLoaded(false);

        axios
            .get(
                `/api/v1/assets/${asset.uuid}/related?page=${page}${
                    includeDrafts ? "&include_drafts=true" : ""
                }`
            )
            .then(({ data }) => {
                setRelatedAssets(data.data);
                setPagination(data.meta);
                setRelatedAssetsLoaded(true);
            });
    };

    useEffect(() => {
        setRelatedAssetsLoaded(false);
        if (asset && asset.uuid) {
            loadAssets();
        }
    }, [asset]);
    // if (relatedAssets.length <= 0) {
    //     return null;
    // }
    return (
        <Panel style={{ padding: "1.2em", marginTop: "1rem" }}>
            <Row collapse>
                <Column collapse medium={canViewRelatedAssets ? 9 : 12}>
                    <h3>{trans("Related Assets")}</h3>
                </Column>
                {canViewRelatedAssets && assetDetailLoaded && (
                    <Column collapse medium={3} style={{ textAlign: "right" }}>
                        <StyledCheckbox
                            checked={includeDrafts}
                            onChange={() => {
                                const includeDraftsTemp = !includeDrafts;
                                setIncludeDrafts(includeDraftsTemp);
                                loadAssets(
                                    pagination.current_page,
                                    includeDraftsTemp
                                );
                            }}
                            text={intl.formatMessage({ id: "Include Drafts" })}
                        ></StyledCheckbox>
                    </Column>
                )}
            </Row>

            {!relatedAssetLoaded ? (
                <LoadingIconSmall />
            ) : (
                // <Flipper flipKey={relatedAssetLoaded}>
                //     <Flipped flipId={`relatedAssets`} stagger={true}>
                <Row collapse equaliseChildHeight={true} equaliseByRow={true}>
                    {relatedAssets.length > 0 ? (
                        relatedAssets.map((asset) => {
                            return (
                                <AssetResultItem
                                    loaded={relatedAssetLoaded}
                                    asset={asset}
                                    selectAsset={selectAsset}
                                    key={asset.uuid}
                                    maxColumns={4}
                                />
                            );
                        })
                    ) : (
                        <p>{trans("No related assets found")}</p>
                    )}
                </Row>
                //     </Flipped>
                // </Flipper>
            )}

            {!_.isEmpty(pagination) && (
                <div style={{ margin: "1rem auto", textAlign: "center" }}>
                    <Pagination
                        alwaysShow={true}
                        style={{ clear: "both" }}
                        currentPage={pagination.current_page - 1}
                        perPage={pagination.per_page}
                        pageCount={pagination.last_page}
                        onPageChange={({ selected }) =>
                            loadAssets(selected + 1)
                        }
                        {...pagination}
                    />
                </div>
            )}
        </Panel>
    );
};

export default RelatedAssets;
