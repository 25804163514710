import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
// import { Simulate } from "react-dom/test-utils";
// import load = Simulate.load;

//@ts-ignore cortex
import { useQueryString, useInterval } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    IconTextButton,
    PageTitle,
    InputField,
    useAddToast,
    Loading,
    Tabordion,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { TabordionGroup } from "../../components";
import CommunicationRuleActivityUserTable from "./CommunicationRuleActivityUserTable";
//@ts-ignore cortex
import CommunicationRuleMessagesTable from "./CommunicationRuleMessagesTable";

const Wrapper = styled.dl`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
`;

const StatisticLabel = styled.dl`
    box-sizing: border-box;
    min-width: 230px;
`;
const ResultItemWrapper = styled(Panel)`
    display: flex;
    flex-direction: column;
    margin: 0 1rem 1rem 1rem;
    min-width: 300px;
    min-height: 1rem;
    flex: 1 0 21%;
    max-height: 10rem;
    padding: 1rem;
    max-width: 33%;
    text-align: center;
    aspect-ratio: 1;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
`;

const Label = styled.dt`
    font-size: 1rem;
`;
const LargeLabel = styled.dt`
    font-size: 1.8rem;
`;
const Value = styled.dd`
    font-size: 3rem;
    margin: 0;
    small {
        display: block;
        font-weight: bold;
    }
`;

export const CommunicationRuleActivityListing = ({ path, uuid }) => {
    const [remainingTries, setRemainingTries] = useState(45);
    const [isRefreshed, setIsRefreshed] = useState(false);

    const history = useHistory();
    const page = useQueryString().get("page") || 1;
    const searchParam = useQueryString().get("search") || "";
    const orderByParam = useQueryString().get("orderBy") || "";
    const dirParam = useQueryString().get("dir") || "ASC";
    const perPageParam = useQueryString().get("perPage") || 10;
    const [tab, setTab] = useState(0);

    const [searchInput, setSearchInput] = useState(searchParam);
    const [search, setSearch] = useState(searchParam);

    const [orderBy, setOrderBy] = useState(orderByParam);
    const [orderByDirection, setoOrderByDirection] = useState(dirParam);

    const addToast = useAddToast();
    //@ts-ignore cortex
    const [items, setItems] = useState({
        loaded: false,
        data: [],
        pagination: null,
        meta: null,
    });

    const loadItems = (uuid) => {
        setItems({
            ...items,
            loaded: false,
        });
        axios
            .get(`/api/v1/admin/messenger-rules/${uuid}/activity`, {
                params: {
                    page: page,
                    search: search ? search : null,
                    sortDirection: orderByDirection ? orderByDirection : null,
                    sortField: orderBy ? orderBy : null,
                    perPage: perPageParam ? perPageParam : 10,
                },
            })
            .then(({ data }) => {
                setItems({
                    loaded: true,
                    data: data.data,
                    meta: data.meta,
                    pagination: data.meta,
                });

                setIsRefreshed(!data.meta.refreshing);

                if (data.meta.refreshing === false) {
                    setRemainingTries(0);
                }
            })
            .catch();
    };

    useInterval(
        () => {
            if (!isRefreshed) {
                loadItems(uuid);
            }

            setRemainingTries(remainingTries - 1);
        },
        remainingTries < 1 ? null : 10000
    );

    useEffect(() => {
        loadItems(uuid);
    }, [setItems]);

    const onSearch = () => {
        if (searchInput.length) {
            const orderByValue = orderBy
                ? `&orderBy=${orderBy}&dir=${orderByDirection}`
                : "";

            setSearch(searchInput);
            history.push(`?page=${page}&search=${searchInput}${orderByValue}`);
            loadItems(uuid);
        }
    };

    const onOrderBy = (data) => {
        let orderDir = orderByDirection;

        if (orderBy === data) {
            setoOrderByDirection(orderByDirection === "ASC" ? "DESC" : "ASC");
            orderDir = orderByDirection === "ASC" ? "DESC" : "ASC";
        } else {
            setOrderBy(data);
            setoOrderByDirection("ASC");
            orderDir = "ASC";
        }

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(
            `?page=${page}&orderBy=${data}&dir=${orderDir}${searchValue}${perPageValue}`
        );
        loadItems(uuid);
    };

    const onClearSearch = () => {
        setSearch("");
        setSearchInput("");

        const perPageValue = perPageParam ? `&perPage=${perPageParam}` : "";
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";

        history.push(`?page=${page}${orderByValue}${perPageValue}`);
        loadItems(uuid);
    };

    const onPerPageChange = ({ value }: { value: string }) => {
        const orderByValue = orderBy
            ? `&orderBy=${orderBy}&dir=${orderByDirection}`
            : "";
        const searchValue = search ? `&search=${searchInput}` : "";

        history.push(`?perPage=${value}${orderByValue}${searchValue}`);
        loadItems(uuid);
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {items?.meta?.name
                            ? trans("Activity for {ruleName}", {
                                  ruleName: items.meta.name,
                              })
                            : trans(
                                  "Automated Communication Rule Activity"
                              )}{" "}
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={`${path}?page=1`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to Rules")}
                </IconTextButton>
            </PageTitle>
            <Row>
                {isRefreshed ? (
                    <Tabordion current={tab} unmounts={false}>
                        <TabordionGroup
                            heading={<span>{trans("Statistics")}</span>}
                            onTabClick={() => setTab(0)}
                        >
                            {items.data && (
                                <>
                                    <Wrapper
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <StatisticLabel>
                                            <LargeLabel>
                                                {trans("Mailing List Name")}
                                            </LargeLabel>

                                            {items.meta &&
                                                items.meta.mailing_list_name}
                                        </StatisticLabel>
                                        <StatisticLabel>
                                            <LargeLabel>
                                                {trans("Rule Refreshed")}
                                            </LargeLabel>

                                            {items.meta &&
                                                (items.meta.refreshing
                                                    ? trans("No")
                                                    : trans("Yes"))}
                                        </StatisticLabel>
                                    </Wrapper>

                                    <Wrapper>
                                        <ResultItemWrapper minPan="200px">
                                            <Value>
                                                {items.meta &&
                                                    items.meta.created_at}
                                            </Value>
                                            <Label>
                                                {trans("Date Created")}
                                            </Label>
                                        </ResultItemWrapper>
                                        <ResultItemWrapper>
                                            <Value>
                                                {items.meta && items.meta.total}
                                            </Value>
                                            <Label>
                                                {trans(
                                                    "Users currently in the Mailing List"
                                                )}
                                            </Label>
                                        </ResultItemWrapper>

                                        <ResultItemWrapper>
                                            <Value>
                                                {items.meta &&
                                                    items.meta
                                                        .total_pop_up_sends}
                                            </Value>
                                            <Label>
                                                {trans("Total Pop-up Sends")}{" "}
                                            </Label>
                                        </ResultItemWrapper>
                                        <ResultItemWrapper>
                                            <Value>
                                                {items.meta &&
                                                    items.meta.total_sends}
                                            </Value>
                                            <Label>
                                                {trans("Total Internal Sends")}{" "}
                                            </Label>
                                        </ResultItemWrapper>
                                    </Wrapper>
                                </>
                            )}
                        </TabordionGroup>
                        <TabordionGroup
                            heading={<span>{trans("Users")}</span>}
                            onTabClick={() => setTab(1)}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <CommunicationRuleActivityUserTable
                                    onPerPageChange={onPerPageChange}
                                    onOrderBy={onOrderBy}
                                    items={items.data}
                                    loaded={items.loaded}
                                    path={path}
                                    pagination={items.pagination}
                                    onPageChange={(selected) => {
                                        history.push(
                                            `?page=${selected}&search=${search}`
                                        );
                                        loadItems(uuid);
                                    }}
                                />
                            </div>
                        </TabordionGroup>
                        <TabordionGroup
                            heading={<span>{trans("Messages")}</span>}
                            onTabClick={() => setTab(2)}
                        >
                            <div style={{ marginBottom: "1rem" }}>
                                <CommunicationRuleMessagesTable
                                    ruleUuid={uuid}
                                />
                            </div>
                        </TabordionGroup>
                    </Tabordion>
                ) : (
                    <Loading />
                )}
            </Row>
        </div>
    );
};

export default CommunicationRuleActivityListing;
