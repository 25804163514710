import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";

export const SpacedRow = styled(Row).attrs({ collapse: true })`
    padding: 0.5rem 0;
    display: flex;
`;

export const Header = styled.header`
    padding: 1rem;
    background: ${({ theme }) => theme.panel.headingBackground};
    color: ${({ theme }) => theme.panel.headingColor};
    h4 {
        margin: 0 2rem 0 0;
    }
`;

export const HeaderEmphasis = styled.em`
    font-weight: 100;
    margin-left: 0.3rem;
`;

export const Description = styled.div`
    padding: 1rem;
    white-space: pre-line;
    background: ${({ theme }) => theme.colors.white};
`;

export const DetailWrapper = styled.div`
    .detailRow {
        margin: 0;
        // padding: 0.5rem ${({ theme }) => 1 - theme.spacing.padding / 2}rem;
        padding: 1em;
    }
    .detailRow:nth-child(odd) {
        background: ${({ theme }) => theme.colors.gray100};
        color: ${({ theme }) => theme.colors.black};
    }
`;
export const DetailsLabel = styled.h4`
    min-width: 25%;
    max-width: 25%;
    margin: 0 0 0 0;
    padding-right: 0.5rem;
`;
export const DetailsContent = styled.div`
    flex-grow: 1;
`;

export const DetailsColumn = styled(Column)`
    margin-bottom: 1.2rem;
`;

export const DescriptionContainer = styled.div`
    white-space: pre-line;
`;
