import React from "react";
import styled from "styled-components";
import { Row, Column, Centred, Loading } from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import AssetResultItem, {
    AssetGrid,
    AssetResultWrapper,
} from "../assetComponents/AssetResultItem";

const AssetListingOutput = ({ panelId, filterBy, total = 5, panelSize }) => {
    const panelData = usePanelData(panelId, `/api/v1/assets`, {
        filter_by: filterBy,
        //Per page and total are the same as we want to return all the assets up to the total
        per_page: total,
        total: total,
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }

    return (
        <AssetGrid panelSize={panelSize} minColumnWidth={200}>
            {panelData.data ? (
                panelData.data.map((asset) => {
                    return (
                        <AssetResultWrapper key={asset.uuid}>
                            <AssetResultItem
                                loaded={panelData.loaded}
                                asset={asset}
                                // selectAsset={selectAsset}
                                // permissions={permissions}
                                assetsPath={"/resources"}
                            />
                        </AssetResultWrapper>
                    );
                })
            ) : (
                <Loading align="center" padding="1rem" />
            )}
        </AssetGrid>
    );

    return (
        <Row collapse={true} equaliseChildHeight={true} equaliseByRow={true}>
            {panelData.data ? (
                panelData.data.map((asset) => {
                    return (
                        <Column
                            key={asset.uuid}
                            small={12}
                            medium={6}
                            large={4}
                            xlarge={3}
                            style={{ marginBottom: "1rem" }}
                        >
                            <AssetResultItem
                                loaded={panelData.loaded}
                                asset={asset}
                                // selectAsset={selectAsset}
                                // permissions={permissions}
                                assetsPath={"/resources"}
                            />
                        </Column>
                    );
                })
            ) : (
                <Loading align="center" padding="1rem" />
            )}
        </Row>
    );
};

export default AssetListingOutput;
