import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
} from "@cortexglobal/rla-components";
import { useState } from "react";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const UserTotalsConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    timeFrame,
    status,
    group,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "User Totals" }),
        timeFrame: timeFrame ? timeFrame : "",
        status: status ? status : "",
        group: group ? group : "",
    });

    const timeFrameOptions = [
        { value: "1", text: intl.formatMessage({ id: "-1 Month" }) },
        { value: "3", text: intl.formatMessage({ id: "-3 Month" }) },
        { value: "6", text: intl.formatMessage({ id: "-6 Month" }) },
        { value: "12", text: intl.formatMessage({ id: "-1 Year" }) },
        { value: "all", text: intl.formatMessage({ id: "All time" }) },
    ];
    const statusOptions = [
        { value: "created", text: intl.formatMessage({ id: "Created" }) },
        { value: "deleted", text: intl.formatMessage({ id: "Deleted" }) },
    ];
    const groupOptions = [
        { value: "group", text: intl.formatMessage({ id: "Your group" }) },
        { value: "all", text: intl.formatMessage({ id: "All" }) },
    ];

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder="Custom Title"
                        onChange={onChange}
                    />
                    <SelectField
                        name="timeFrame"
                        label={trans("Time frame")}
                        value={state.timeFrame}
                        options={timeFrameOptions}
                        onChange={onChange}
                        expanded
                    />
                    <SelectField
                        name="status"
                        label={trans("Status")}
                        value={state.status}
                        options={statusOptions}
                        onChange={onChange}
                        expanded
                    />
                    <SelectField
                        name="group"
                        label={trans("Group Scope")}
                        value={state.group}
                        options={groupOptions}
                        onChange={onChange}
                        expanded
                    />
                    <Button type="submit">{trans("Save Panel")}</Button>
                </form>
            </Column>
        </Row>
    );
};

UserTotalsConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default UserTotalsConfiguration;
