import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import {
    LOAD_PANEL_DATA,
    SET_PANEL_DATA_FOR_REFRESH,
    SET_REFRESHING_PANEL_DATA
} from "../../panelActions";

const usePanelData = (id, url = "", propsToPass = {}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const panelData = useSelector(state => state.panels[id]);

    useEffect(() => {
        if ((panelData && panelData.refreshing) || loading) {
            return;
        }
        if (
            !panelData ||
            !panelData.loaded ||
            (panelData && panelData.refresh)
        ) {
            dispatch({ type: SET_REFRESHING_PANEL_DATA, id });
            setLoading(true);
            axios
                .get(url, { params: propsToPass })
                .then(response => {
                    //Handle the data.data issue if it has or has not gone through a response resource
                    const data = response.data.hasOwnProperty("data")
                        ? response.data.data
                        : response.data;
                    // console.log("data", data);
                    dispatch({
                        type: LOAD_PANEL_DATA,
                        id,
                        loaded: true,
                        data: data
                    });
                    setLoading(false);
                })
                .catch(e => e);
        }
        return () => dispatch({ type: SET_PANEL_DATA_FOR_REFRESH, id });
    }, [id, propsToPass, panelData]);

    return panelData;
};
export default usePanelData;
