// import cloneDeep from "lodash/cloneDeep";
// import { Pages } from "./interfaces/microsite";
// import { Nav, Link } from "./interfaces/navigation";
import { RouteComponentProps } from "react-router-dom";
import { number } from "prop-types";
import { TransitionItem } from "./interfaces/layout";

export const makeProps = (propsArray?: { [key: string]: any }[]) => {
    if (!propsArray) {
        return {};
    }
    return propsArray.reduce((builtProps: object, prop: any, index: number) => {
        const { name, value } = prop;

        return { ...builtProps, [name]: value };
    }, {});
};
export const arrayToObjectUsingNameKey = (
    arrayToConvert: { name: string; [key: string]: any }[]
) => {
    return arrayToConvert.reduce(
        (
            builtObject: { [key: string]: { [key: string]: any } },
            configItem
        ) => {
            const { name, ...rest } = configItem;

            return { ...builtObject, [name]: rest };
        },
        {}
    );
};

export const prepareContent = (
    arrayToConvert: { name: string; [key: string]: any }[]
) => {
    let footnoteIndex = 0;
    return arrayToConvert.reduce(
        (
            builtObject: { [key: string]: { [key: string]: any } },
            configItem
        ) => {
            let { name, footnotes, ...rest } = configItem;
            let processedFootnotes = [];
            if (Array.isArray(footnotes)) {
                processedFootnotes = footnotes.map((footnote) => {
                    footnoteIndex += 1;
                    footnote.index = footnoteIndex;
                    return footnote;
                });
            }
            return {
                ...builtObject,
                [name]: { ...rest, footnotes: processedFootnotes },
            };
        },
        {}
    );
};

export const processTransitionsToElementNames = (
    transitions: TransitionItem[]
) => {
    return transitions.reduce((simplifiedTransitions, transitionObject) => {
        return [...simplifiedTransitions, transitionObject.name];
    }, [] as string[]);
};

export const getCompletedTransitionNames = (
    transitions: TransitionItem[],
    currentTransitionIndex: number
) => {
    return processTransitionsToElementNames(transitions).slice(
        0,
        currentTransitionIndex
    );
};

/**
 * Display a message explaining how to bookmark the site (optionally navigate to a certain page first)
 * @param history - React Router History
 * @param bookmarkUrl - Optional URL to navigate to before bookmarking (e.g. the home page, or opening page of a presentation)
 */
export const bookmarkSite = (
    history: RouteComponentProps["history"],
    bookmarkUrl = ""
) => {
    if (bookmarkUrl !== "") {
        history.push(bookmarkUrl);
    }
    alert(
        "Press " +
            (navigator.userAgent.toLowerCase().indexOf("mac") != -1
                ? "Command/Cmd"
                : "CTRL") +
            " + D to bookmark this site."
    );
};
