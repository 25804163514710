import React, { useEffect, useState } from "react";
import axios from "axios";
//@ts-ignore cortex
import { SelectField, ContentBlockLoader } from "@cortexglobal/rla-components";

export const LocaleSwitcher = ({
    item,
    setFieldValue,
    values,
    onChange,
}: {
    item: { [key: string]: any };
    setFieldValue;
    values: { locale: string; [key: string]: any };
    onChange?: (locale: string) => void;
}) => {
    const [locales, setLocales] = useState({ loaded: false, data: [] });
    useEffect(() => {
        axios.get(`/api/v1/language`).then(({ data }) => {
            setLocales({
                loaded: true,
                data: data.data.map((language) => {
                    return {
                        text: language.native_name
                            ? language.native_name
                            : language.name,
                        value: language.iso_code,
                    };
                }),
            });
            if (setFieldValue && typeof setFieldValue === "function") {
                setFieldValue("locale", item.locale);
            }

            // Callback
            if (onChange && typeof onChange === "function") {
                onChange(item.locale);
            }
        });
    }, []);

    const handleLocaleChange = (
        locale: string,
        item: { [key: string]: any },
        values: { [key: string]: any }
    ) => {
        // Callback
        if (onChange && typeof onChange === "function") {
            // console.log("onChange", locale);
            onChange(locale);
        }

        if (setFieldValue && typeof setFieldValue === "function") {
            setFieldValue("locale", locale);
        }
        //If there's no i18n info available we don't need to updates any other fields so we can just return
        if (!item.i18n || !Array.isArray(item.i18n)) {
            return;
        }
        //Find the appropriate entry in the allI18n
        const selectedLocaleI18nData = item.i18n.find(
            (i18nData) => i18nData.locale === locale
        );

        //Set the field values of any of the things in the i18n that match, otherwise empty them
        if (selectedLocaleI18nData) {
            Object.keys(selectedLocaleI18nData).forEach((field) => {
                //If the values contain a field that's in the i18n data, set it's value
                if (
                    typeof values[field] !== "undefined" &&
                    setFieldValue &&
                    typeof setFieldValue === "function"
                ) {
                    setFieldValue(field, selectedLocaleI18nData[field]);
                }
            });
        } else {
            item.i18n[0] &&
                Object.keys(item.i18n[0]).forEach((field) => {
                    //If the values contain a field that's in the i18n data, set it's value
                    if (
                        values[field] &&
                        setFieldValue &&
                        typeof setFieldValue === "function"
                    ) {
                        setFieldValue(field, "");
                    }
                });
        }
    };

    return (
        <ContentBlockLoader loaded={locales.loaded}>
            <SelectField
                name="locale"
                //@ts-ignore
                value={values.locale}
                options={locales.data}
                onChange={({ value }) => {
                    handleLocaleChange(value, item, values);
                }}
            />
        </ContentBlockLoader>
    );
};

export default LocaleSwitcher;
