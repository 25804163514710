import { useEffect } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Raven from "raven-js";
import { useAddToast } from "@cortexglobal/rla-components";

import { get } from "lodash";
import { useDispatch } from "react-redux";

//Google Analytics
ReactGA.initialize("", {
    debug: false,
});

export const useTracker = (options = {}) => {
    const addToast = useAddToast();
    let location = useLocation();
    const dispatch = useDispatch();

    const trackPage = (page) => {
        if (!window.location.hostname) {
            return;
        }

        axios
            .post("/api/v1/page-viewed", {
                pathname: page,
                hostname: window.location.hostname,
            })
            .then((response) => {
                const metaData = get(response, "data.meta.user_data", []);

                const totalNotifications = get(
                    metaData,
                    "unnotified_messages",
                    0
                );

                if (totalNotifications > 0) {
                    addToast({
                        type: "success",
                        content:
                            `You have ${totalNotifications} new message` +
                            (totalNotifications != 1 ? "s" : ""),
                        showFor: 5000,
                    });
                }

                dispatch({
                    type: "SET_AUTH_USER_META_DATA",
                    data: metaData,
                });
            })
            .catch((err) => {
                Raven.captureException(err);
            });

        if (!process.env.REACT_APP_LOG_PAGE_VIEWS) {
            return;
        }

        ReactGA.set({
            page,
            // ...options
        });
        ReactGA.pageview(page);
    };

    useEffect(() => {
        trackPage(location.pathname);
    }, [location]);
};

export default useTracker;
