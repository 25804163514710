import React, { useEffect, useState } from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { saveAs } from "file-saver";

import {
    Row,
    PageTitle,
    Panel,
    Column,
    Button,
    useAddToast,
    useToasts,
} from "@cortexglobal/rla-components";

const download = (addToast, dispatch) => {
    addToast({
        uuid: "transfile-download",
        type: "loader",
        content: trans("Downloading the latest translations file"),
    });

    axios
        .post(
            `/api/v1/admin/translations/download`,
            { locale: "ja" },
            {
                responseType: "blob", // Important: responseType must be 'blob'
            }
        )
        .then((response) => {
            const filename = `translations-${new Date().toISOString()}.xlsx`;
            saveAs(response.data, filename);

            addToast({
                type: "success",
                content: trans(
                    "The translations file has been successfully downloaded"
                ),
                showFor: 5000,
            });
        })
        .catch((e) => {
            const message = e?.response?.data?.errors?.file
                ? e.response.data.errors.file
                : trans(
                      "An unknown error occurred while downloading the translations file"
                  );

            addToast({
                type: "alert",
                content: message,
                showFor: 5000,
            });
        })
        .finally(() => {
            dispatch({ type: "removeToast", uuid: "transfile-download" });
        });
};

export const TranslationsListing = ({ path }) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    return (
        <>
            <PageTitle title={<>{trans("Translations")} </>}></PageTitle>
            <Panel>
                <Row>
                    <Column>
                        <Button onClick={() => download(addToast, dispatch)}>
                            Download Translations File
                        </Button>
                    </Column>
                </Row>
            </Panel>
        </>
    );
};

export default TranslationsListing;
