import {
    LOAD_PANEL_DATA,
    UNLOAD_PANEL_DATA,
    SET_PANEL_DATA_FOR_REFRESH,
    SET_REFRESHING_PANEL_DATA
} from "./panelActions";

const initialState = {};

export default function panels(state = initialState, action) {
    switch (action.type) {
        case LOAD_PANEL_DATA:
            // console.log(action.id);
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    loaded: action.loaded,
                    refresh: false,
                    refreshing: false,
                    data: action.data
                }
            };
        case UNLOAD_PANEL_DATA:
            return {
                ...state,
                [action.id]: {
                    loaded: false,
                    refresh: false,
                    refreshing: false,
                    data: null
                }
            };
        case SET_PANEL_DATA_FOR_REFRESH:
            // console.log("Set panel for refresh");
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    refresh: true
                }
            };
        case SET_REFRESHING_PANEL_DATA:
            // console.log("Refreshing panel data");
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    refreshing: true,
                    refresh: false
                }
            };

        default:
            return state;
    }
}
