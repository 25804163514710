import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Row,
    Column,
    PageTitle,
    Pagination,
    Select,
    Loading,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import UserAssetResultItem from "../components/UserAssetResultItem";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const ListUserAssets = ({
    userAssets,
    getUserAssets,
    assetsPath,
    selectUserAsset,
    refreshSearch,
    setRefreshSearch,
    assetsLoading,
}) => {
    const history = useHistory();
    const intl = useIntl();
    const [scope, setScope] = useState("user");

    const { can } = usePermissions();

    useEffect(() => {
        if (refreshSearch) {
            getUserAssets(scope).then(() => {
                setRefreshSearch(false);
            });
        }
    }, [refreshSearch]);

    const handleSelect = (userAsset) => {
        selectUserAsset(userAsset);
        history.push(`${assetsPath}/${userAsset.uuid}`);
    };

    const { data, pagination } = userAssets;

    const getOptions = () => {
        let options = [
            {
                value: "user",
                text: intl.formatMessage({ id: "My Media" }),
            },
            {
                value: "group",
                text: intl.formatMessage({
                    id: "Group Media",
                }),
            },
            {
                value: "all",
                text: intl.formatMessage({ id: "Group and My Media" }),
            },
        ];

        if (can("dam-user-asset-view-all")) {
            options.push({
                value: "everything",
                text: intl.formatMessage({ id: "All Media" }),
            });
        }

        return options;
    };

    return (
        <div>
            <PageTitle title={trans("My Media")}>
                <Select
                    value={scope}
                    options={getOptions()}
                    onChange={({ value }) => {
                        setScope(value);
                        getUserAssets(value);
                    }}
                    isSearchable={false}
                    marginBottom={"0"}
                />
            </PageTitle>
            {assetsLoading ? (
                <Loading />
            ) : (
                <>
                    <Row collapse={true}>
                        {data.map((userAsset) => {
                            return (
                                <UserAssetResultItem
                                    loaded={!!userAsset}
                                    asset={userAsset}
                                    onClick={() => handleSelect(userAsset)}
                                    key={userAsset.uuid}
                                />
                            );
                        })}
                    </Row>
                    <Row>
                        <Column>
                            <Pagination
                                alwaysShow={true}
                                style={{ clear: "both" }}
                                currentPage={pagination.current_page - 1}
                                perPage={pagination.per_page}
                                pageCount={pagination.last_page}
                                onPageChange={({ selected }) =>
                                    getUserAssets(scope, selected + 1)
                                }
                                {...pagination}
                            />
                        </Column>
                    </Row>
                </>
            )}
        </div>
    );
};

export default ListUserAssets;
