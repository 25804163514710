import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "@cortexglobal/rla-components";
import PlanDetailSummary from "./PlanDetailSummary";

export const PlanSummary = ({ planUuid, user }) => {
    const [plan, setPlan] = useState(null);
    const [planLoaded, setPlanLoaded] = useState(false);

    useEffect(() => {
        if (!planLoaded) {
            loadPlan(planUuid);
        }
    }, [planUuid]);

    const loadPlan = (planUuid) => {
        setPlanLoaded(false);

        axios
            .get("/api/v1/planner/plan/my-plans/" + planUuid)
            .then(({ data }) => {
                setPlan(data.data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        e?.response?.data?.message ||
                        trans("Error loading plan"),
                    showFor: 5000,
                });
            })
            .finally(() => {
                setPlanLoaded(true);
            });
    };

    if (!planLoaded || !plan) {
        return <Loading />;
    }

    return <PlanDetailSummary plan={plan} user={user} />;
};

export default PlanSummary;
