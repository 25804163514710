import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

// import registerServiceWorker, { unregister } from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { ThemeProvider, ToastProvider } from "@cortexglobal/rla-components";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { merge } from "lodash";

import { getMessages } from "./i18n/languageSetup";

import configureStore from "./redux/configureStore";
import { setupAuth } from "@cortexglobal/cortex-auth-react-router-dom";

import "moment/locale/en-gb";
import "moment/locale/ja";
import moment from "moment";
// import { registerLocale, setDefaultLocale } from "react-datepicker";
import {
    registerCortexLocale,
    setCortexDefaultLocale,
} from "@cortexglobal/cortex-utilities";
import { enGB, ja } from "date-fns/locale";

import App from "./App";

// import packageJson from "../package.json";

import theme from "./config/theme";
import globalCss from "./config/globalCss";
require("./config/customFonts.css");
require("./config/icons");
require("./config/axiosConfig");

//Setup the store
const store = configureStore({});
store.dispatch(setupAuth());

//Add Global CSS
// const GlobalStyle = createGlobalStyle`${globalCss(customTheme)}`;
const GlobalStyle = createGlobalStyle`${globalCss(theme)}`;

//Function to render the app, used on initial load and during HMR
function renderApp() {
    // const currentAppVersion = packageJson.version;

    const downgradeTransErrors = (err) => {
        console.warn("Missing translation", err);
        return;
    };

    //Language setup
    let language =
        store.getState().auth.user.language ||
        window.navigator.userLanguage ||
        window.navigator.language;

    language = language.substring(0, 2);
    let dateLocale = language;
    //For now convert "en" to "enGB" as we only have one English translation
    //TODO - work out how to handle this better, will require changes to the backend
    if (language === "en") {
        dateLocale = "en-gb";
    }
    const messages = getMessages(language);
    moment.locale(dateLocale);
    registerCortexLocale("ja", ja);
    registerCortexLocale("en-gb", enGB);
    setCortexDefaultLocale(dateLocale);

    return (
        <Provider store={store}>
            <IntlProvider
                locale={language}
                messages={messages}
                onError={downgradeTransErrors}
            >
                <HelmetProvider>
                    <ThemeProvider theme={merge(theme)}>
                        <ToastProvider>
                            <Router>
                                <App />
                            </Router>
                        </ToastProvider>
                    </ThemeProvider>
                </HelmetProvider>
            </IntlProvider>
        </Provider>
    );
}

// ReactDOM.render(renderApp(), document.getElementById("root"));
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(renderApp());
// root.render(<App tab="home" />);

unregister();
