import React, { Component } from "react";
import PropTypes from "prop-types";
import { calculateOffset, calculateWidth } from "./helpers";

import { StyledTask } from "./components/StyledTask";
import { Tooltip } from "@cortexglobal/rla-components";
import TaskTooltipContent from "./components/TaskTooltipContent";
class Task extends Component {
    // activityClick(activity, task, category, left, width, event) {
    //     this.props.activityClick(activity, task, category, left, width, event);
    // }

    shouldComponentUpdate(nextProps) {
        if (
            this.props.widths.item !== nextProps.widths.item ||
            this.props.start !== nextProps.start ||
            this.props.task !== nextProps.task
        ) {
            return true;
        }
        return false;
    }

    render() {
        const {
            category,
            task,
            colors,
            widths,
            heights,
            units,
            start,
            end,
            taskClick,
            taskContent,
        } = this.props;
        const left = calculateOffset(task.start_date, widths, units, start);

        const width = calculateWidth(
            start,
            end,
            task.start_date,
            task.end_date,
            widths,
            units
        );
        const type = task.owner_type;

        return (
            <StyledTask
                colors={colors}
                height={heights.task}
                spacing={heights.spacing}
                left={left}
                width={width}
                type={type}
                className={`task ${type}`}
                onClick={(event) =>
                    taskClick({ category, task, left, width, event })
                }
            >
                <Tooltip
                    tagName="div"
                    text={<TaskTooltipContent task={task} />}
                    zIndex={10000}
                    showIcon={false}
                    setMaxWidth={false}
                    direction="bottom"
                >
                    {React.createElement(taskContent, {
                        task,
                        taskStartDate: task.start_date,
                        type,
                        colors,
                        widths,
                        width,
                        heights,
                        units,
                        start,
                    })}
                </Tooltip>
            </StyledTask>
        );
    }
}

Task.propTypes = {
    category: PropTypes.object,
    task: PropTypes.object,
};

export default Task;
