import React from "react";
import add from "date-fns/add";

import {
    RadioField,
    DatePicker,
    InputField,
    RepeaterField,
    TextareaField,
    FormRow,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const DigitalOrderForm = ({ order, standardOnChange, errors }) => {
    const { formatMessage } = useIntl();
    //Get the digital_details from the order. If it doesn't exist, create it
    //can't use destructing because it can be null
    let { digital_details } = order;
    if (!digital_details) {
        digital_details = {
            provider: "",
            location: [],
            start_date: "",
            end_date: "",
            daily_budget: 0,
            targeting: "",
            destination_url: "",
        };
    }

    const minDate = order?.required_date
        ? new Date(order.required_date)
        : add(new Date(), {
              days:
                  order?.user_asset?.asset?.order_flow_details
                      ?.lead_time_days || 0,
          });

    const onChange = ({ name, value }) => {
        standardOnChange({
            name: "digital_details",
            value: { ...digital_details, [name]: value },
        });
    };

    return (
        <>
            <FormRow
                name="provider"
                label={trans("Provider")}
                error={errors.provider}
            >
                <InputField
                    name="provider"
                    value={digital_details.provider}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="location"
                label={trans("Location")}
                error={errors.location}
            >
                <RepeaterField
                    style={{ marginTop: "2em" }}
                    values={digital_details.location}
                    addRowText={formatMessage({
                        id: "Add Location",
                    })}
                    onChanges={(value) => onChange({ name: "location", value })}
                    types={[
                        {
                            name: formatMessage({ id: "Postal Code" }),
                            alias: "postal_code",
                            type: "Text",
                        },
                        {
                            name: formatMessage({ id: "Radius" }),
                            alias: "radius",
                            type: "Number",
                        },
                    ]}
                />
            </FormRow>
            <FormRow
                name="start_date"
                label={trans("Start Date")}
                error={errors.start_date}
                helpText={trans("When does the ad start running?")}
                required
            >
                <DatePicker
                    name="start_date"
                    value={digital_details.start_date}
                    showError={false}
                    onChange={onChange}
                    minDate={minDate}
                />
            </FormRow>
            <FormRow
                name="end_date"
                label={trans("End Date")}
                error={errors.end_date}
                helpText={trans("When does the ad stop running?")}
                required
            >
                <DatePicker
                    name="end_date"
                    value={digital_details.end_date}
                    showError={false}
                    onChange={onChange}
                    minDate={
                        digital_details.start_date
                            ? add(digital_details.start_date, { days: 1 })
                            : add(minDate, { days: 1 })
                    }
                />
            </FormRow>
            <FormRow
                name="daily_budget"
                label={trans("Daily Budget")}
                error={errors.daily_budget}
            >
                <InputField
                    type="number"
                    name="daily_budget"
                    value={digital_details.daily_budget}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="targeting"
                label={trans("Targeting")}
                error={errors.targeting}
            >
                <TextareaField
                    name="targeting"
                    value={digital_details.targeting}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
            <FormRow
                name="destination_url"
                label={trans("Destination URL")}
                error={errors.destination_url}
            >
                <InputField
                    name="destination_url"
                    value={digital_details.destination_url}
                    showError={false}
                    onChange={onChange}
                />
            </FormRow>
        </>
    );
};

export default DigitalOrderForm;
