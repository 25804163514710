import React, { useEffect, useState } from "react";
import axios from "axios";
import Image from "./Image";
import Error from "./errors/Error";
import { useInterval } from "@cortexglobal/cortex-utilities";
import { trans } from "@cortexglobal/rla-intl";
import LoadingIconSmall from "./loaders/LoadingIconSmall";

const imageExists = async (src, setImageReady) => {
    const result = await axios
        .get(src)
        .then((res) => {
            setImageReady(res.ok);
            if (res.ok) {
                return true;
            } else {
                return true;
            }
        })
        .catch((err) => false);

    return result;
};

const PollableImage = ({
    src,
    fallback = "/img/placeholder.png",
    loadingMessage = "Generating",
    maxTries = 60,
    interval = 5000,
    ...rest
}) => {
    const [remainingTries, setRemainingTries] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [imageReady, setImageReady] = useState(false);

    useEffect(() => {
        setRemainingTries(maxTries);
    }, []);

    useInterval(
        () => {
            if (src && remainingTries > 0) {
                imageExists(src, setImageReady);
                if (imageReady) {
                    setLoaded(true);
                } else {
                    setRemainingTries(remainingTries - 1);
                }
            }
        },
        loaded || remainingTries < 1 ? null : interval
    );
    // console.log(src, remainingTries);
    if (loaded) {
        return <Image src={src} fallback={fallback} {...rest} />;
    }
    if (remainingTries > 0) {
        return (
            <span>
                <LoadingIconSmall />
                {loadingMessage}
            </span>
        );
    }
    return <Error>{trans("The image is currently unavailable")}</Error>;
};

export default PollableImage;
