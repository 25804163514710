import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Column,
    Centred,
    Loading,
    IconTextButton,
} from "@cortexglobal/rla-components";

import { getItem } from "@cortexglobal/cortex-utilities";

import { CortexBar, CortexPie, CortexLine } from "@cortexglobal/charts";

import { trans, useIntl } from "@cortexglobal/rla-intl";
// import withPanelData from "./withPanelData";
import usePanelData from "./usePanelData";
import styled from "styled-components";
import { FlexPanel } from "../layoutComponents";
import { pie, chartColors } from "../../config/charts";

const BudgetBreakdown = styled.div`
    border-top: ${({ colors }) => colors.gray200} 1px solid;
    margin-top: 1rem;
    padding-top: 1rem;
    text-align: center;
`;
const PlannerMediaSummary = ({ panelId, height, user }) => {
    //cortex-api-local.rla.co.uk/api/v1/budget/available/2020
    const panelData = usePanelData(panelId, `/api/v1/planner/summary/media`);
    const intl = useIntl();
    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }
    const { data } = panelData;
    // const processedData = data.map(summary => {
    //     // console.log(summary.media_channel);
    //     return {
    //         x: summary.media_channel,
    //         y: summary.total,
    //         label: `${summary.media_channel} (${summary.count})`
    //     };
    // });
    // const rechartsData = data.map(summary => {
    //     // console.log(summary.media_channel);
    //     return {
    //         x: summary.media_channel,
    //         total: summary.total,
    //         name: `${summary.media_channel} (${summary.count})`
    //     };
    // });

    // const chartJSData = data.reduce(
    //     (formattedData, summary) => {
    //         // console.log(summary.media_channel);
    //         formattedData.labels.push(
    //             `${summary.media_channel} (${summary.count}, ${summary.total} )`
    //         );
    //         formattedData.datasets[0].data.push(summary.total);
    //         return formattedData;
    //     },
    //     {
    //         labels: [],
    //         datasets: [
    //             {
    //                 data: []
    //             }
    //         ]
    //     }
    // );

    // const chartJSData = {
    //     labels: ["Red", "Blue", "Yellow"],
    //     datasets: [
    //         {
    //             data: [300, 50, 100]
    //             // backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    //             // hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
    //         }
    //     ]
    // };
    // return <pre>{JSON.stringify(data, null, 2)}</pre>;
    const localStorageCurrency = getItem("currency");
    let amendedOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: localStorageCurrency ? localStorageCurrency.currency : "GBP",
    };
    return (
        <FlexPanel>
            <Row style={{ width: "100%", flex: "1", paddingTop: "1rem" }}>
                {/* <CortexLine
                    data={[
                        { label: "First", data },
                        { label: "Second", data }
                    ]}
                    labelKey="media_channel"
                    valueKey="total"
                /> */}
                <CortexPie
                    data={data}
                    labelKey="media_channel"
                    valueKey="total"
                    options={{
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    // console.log(tooltipItem, data);
                                    let label =
                                        data.labels[tooltipItem.index] || "";

                                    if (label) {
                                        label += ": ";
                                    }

                                    label += intl.formatNumber(
                                        data.datasets[tooltipItem.datasetIndex]
                                            .data[tooltipItem.index],
                                        amendedOptions
                                    );
                                    return label;
                                },
                            },
                        },
                    }}
                />
            </Row>
            <div>
                <IconTextButton as={Link} to="/plan/budget-manager" expanded>
                    {trans("Budget Manager")}
                </IconTextButton>
            </div>
        </FlexPanel>
    );
};

// export default withPanelData(PlannerMediaSummary, "/api/v1/budget/annual-summary");
export default PlannerMediaSummary;
