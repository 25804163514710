import React, { Component } from "react";
import withPanelData from "./withPanelData";
import { findIndex } from "lodash";
import {
    VictoryPie,
    VictoryContainer,
    VictoryPortal,
    VictoryLabel
} from "victory";
import { Row, Column, Centred } from "@cortexglobal/rla-components";
import { pie, chartColors } from "../../config/charts";

class CustomerTypeSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: this.generateDataCollection(props.data)
        };
    }

    componentDidMount() {
        this.timeout = window.setTimeout(() => {
            this.swapForRealData();
        }, 100);
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeout);
    }

    swapForRealData() {
        this.setState(state => {
            return {
                chartData: this.state.chartData.map(currentData => {
                    return { ...currentData, y: currentData.realTotal };
                })
            };
        });
    }
    generateDataCollection(data) {
        return this.props.data.reduce((customerTypesArray, customer) => {
            const existingCustomerType = findIndex(
                customerTypesArray,
                customerType => {
                    return customerType.x === customer.type;
                }
            );
            //console.log(customerTypesArray, existingCustomerType);
            if (existingCustomerType > -1) {
                customerTypesArray[existingCustomerType] = {
                    ...customerTypesArray[existingCustomerType],
                    realTotal: ++customerTypesArray[existingCustomerType]
                        .realTotal
                };
                return customerTypesArray;
            }
            customerTypesArray.push({
                x: customer.type,
                y: 1,
                realTotal: 1
            });
            return customerTypesArray;
        }, []);
    }

    render() {
        const { height } = this.props;
        return (
            <Row>
                <Column>
                    <Centred style={{ width: "100%", maxWidth: height }}>
                        <VictoryContainer width={300} height={300}>
                            <VictoryPie
                                standalone={false}
                                data={this.state.chartData}
                                animate={{ duration: 2000 }}
                                width={230}
                                height={230}
                                labelComponent={
                                    <VictoryPortal>
                                        <VictoryLabel />
                                    </VictoryPortal>
                                }
                                style={pie}
                                colorScale={chartColors}
                                innerRadius={0}
                                padding={{
                                    top: 70,
                                    left: 70
                                }}
                            />
                        </VictoryContainer>
                    </Centred>
                </Column>
            </Row>
        );
    }
}

export default withPanelData(
    CustomerTypeSummary,
    "/api/v1/sales/summary/customer-type"
);
