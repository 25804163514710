import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
//@ts-ignore cortex
import { format } from "@cortexglobal/cortex-utilities";

//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import {
    TableList,
    Pagination,
    Loading,
    Panel,
} from "@cortexglobal/rla-components";

const Action = styled.h4`
    margin: 0 0 0.1rem 0;
`;

const States = styled.p`
    margin: 0 0 0.1rem 0;
    font-size: 0.7rem;
`;

interface AuditProps {
    smartRef: string;
    status: string; //(this is used to trigger updates)
}

/**
 * Displays an audit trail of workflow updates
 * @param props an object containing the smartRef of the Workflowable item
 * and the current status (this is used to trigger updates)
 */
export const Audit = ({
    smartRef,
    status = "",
    showHeading = true,
}: AuditProps) => {
    const limit = 10;
    const [state, setState] = useState("loading");
    const [pageCount, setPageCount] = useState(1);
    const [page, setPage] = useState(0);
    const [auditItems, setAuditItems] = useState([]);

    useEffect(() => {
        setState("loading");
        axios
            .get(`/api/v1/workflow/${smartRef}/audit`)
            .then((res) => {
                const { data } = res.data;
                setPageCount(Math.ceil(data.length / limit));
                setAuditItems(res.data.data);
                setState("loaded");
            })
            .catch((e) => {
                setState("error");
            });
    }, [status]);

    const startIndex = page * limit;
    const endIndex = startIndex + limit;

    if (state === "loading") {
        return (
            <>
                {showHeading && <h3>{trans("Action History")}</h3>}
                <Panel>
                    <Loading />
                </Panel>
            </>
        );
    }

    if (state === "error") {
        return (
            <>
                {showHeading && <h3>{trans("Action History")}</h3>}
                <Panel>
                    <p>{trans("An error occurred, please try again later.")}</p>
                </Panel>
            </>
        );
    }

    return (
        <>
            {showHeading && <h3>{trans("Action History")}</h3>}
            <TableList>
                <thead>
                    <tr>
                        <th
                            style={{
                                width: "50%",
                            }}
                        >
                            {trans("Action")}
                        </th>
                        <th
                            style={{
                                width: "25%",
                            }}
                        >
                            {trans("Date")}
                        </th>
                        <th
                            style={{
                                width: "25%",
                            }}
                        >
                            {trans("By")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {auditItems.length <= 0 ? (
                        <tr>
                            <td colSpan="100%">{trans("No actions")}</td>
                        </tr>
                    ) : (
                        auditItems.slice(startIndex, endIndex).map((item) => {
                            return (
                                <tr key={item.ref}>
                                    <td>
                                        {item.action && (
                                            <Action>{item.action}</Action>
                                        )}

                                        <States>
                                            {item.before && (
                                                <span>
                                                    {item.before} &#8594;{" "}
                                                </span>
                                            )}
                                            <strong>{item.after}</strong>
                                        </States>
                                    </td>
                                    <td>{format(new Date(item.date), "Pp")}</td>
                                    <td>
                                        {item.user && item.user.name
                                            ? item.user.name
                                            : ""}
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </TableList>

            {auditItems.length > 1 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Pagination
                        // previousLabel={"<"}
                        // nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        onPageChange={(newPage) => {
                            return setPage(newPage.selected);
                        }}
                    />
                </div>
            )}
        </>
    );
};
