import React, { Component } from "react";
import { Centred, GreedyCarousel } from "@cortexglobal/rla-components";
//import styled from "styled-components";
import withPanelData from "../withPanelData";

import CampaignTable from "./CampaignTable";
import CampaignChart from "./CampaignChart";

/*
const NoCampaignSeletedContainer = styled.div`
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
*/

class CampaignReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCampaign: null
        };
    }

    selectCampaign = campaign => {
        //console.log(campaign);
        this.setState({ selectedCampaign: campaign });
    };

    componentWillMount() {
        if (this.props.data && this.props.data.length > 0) {
            this.selectCampaign(this.props.data[0]);
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (nextProps.data !== this.props.data) {
    //         return true;
    //     }
    //     if (
    //         nextState.selectedCampaign.uuid !== this.state.selectedCampaign.uuid
    //     ) {
    //         console.log(
    //             nextState.selectedCampaign.uuid,
    //             this.state.selectedCampaign.uuid
    //         );
    //         return true;
    //     }
    //     return false;
    // }

    render() {
        const { height, layout } = this.props;

        //console.log(this.props);

        return (
            <GreedyCarousel layout={layout}>
                <CampaignTable
                    campaigns={this.props.data}
                    selectedCampaign={this.state.selectedCampaign}
                    selectCampaign={this.selectCampaign}
                />

                <Centred
                    style={{
                        width: "100%",
                        maxWidth: height
                    }}
                >
                    <CampaignChart campaign={this.state.selectedCampaign} />
                </Centred>
            </GreedyCarousel>
        );
    }
}

export default withPanelData(CampaignReport, "/api/v1/campaign/summary", []);
