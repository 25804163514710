import React from "react";
const EditorContext = React.createContext({
    assetAdmin: {
        assetEdited: false,
        assetLoaded: false,
        assetSaving: false
    },
    saveUpdatedAsset: () => {}
});
export default EditorContext;
