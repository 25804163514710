import React, { useState } from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import { SubmitButton, FormLabel } from "../../../index";

const Popover = styled.div`
    position: absolute;
    z-index: 2;
`;

const Cover = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;

const ColorPickerContainer = styled.div<ColorPickerContainerProps>`
    width: ${({ width }) => width};
    border-radius: ${({ theme }) => theme.input.radius};
    border: 1px solid ${({ theme }) => theme.input.borderColor};
    height: 30px;
    display: flex;
    overflow: hidden;
    cursor: pointer;
`;
0;
const ColorPicker = styled.div<ColorPickerProps>`
    flex-grow: 1;
    background: ${({ color }) => color};
`;

const ColorPickerButton = styled.div`
    color: ${({ theme }) => theme.input.color};
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledColorField = ({
    label,
    value,
    onChange,
    disableAlpha = true,
    labelMarginBottom = "1rem",
    width = "225px",
}: StyledColorFieldProps) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    return (
        <div style={{ marginBottom: "1.2rem" }}>
            {label && (
                <FormLabel marginBottom={labelMarginBottom}>{label}</FormLabel>
            )}
            {!value ? (
                <>
                    <span style={{ marginRight: "1rem" }}>
                        {trans("No Colour Selected")}
                    </span>
                    {/*@ts-ignore*/}
                    <SubmitButton
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setDisplayColorPicker(true);
                        }}
                    >
                        {trans("Pick Color")}
                    </SubmitButton>
                </>
            ) : (
                <ColorPickerContainer
                    width={width}
                    onClick={() => setDisplayColorPicker(true)}
                >
                    <ColorPicker color={value} />
                    <ColorPickerButton>
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </ColorPickerButton>
                </ColorPickerContainer>
            )}

            {displayColorPicker ? (
                <Popover>
                    <Cover onClick={() => setDisplayColorPicker(false)} />
                    <ChromePicker
                        disableAlpha={disableAlpha}
                        color={value || undefined}
                        onChangeComplete={(color) => {
                            onChange(color.hex);
                        }}
                    />
                </Popover>
            ) : null}
        </div>
    );
};

interface ColorPickerContainerProps {
    width: string;
}
interface ColorPickerProps {
    color: string;
}

interface StyledColorFieldProps {
    label?: string;
    width?: string;
    labelMarginBottom?: string;
    name: string;
    value?: string;
    onChange: (value: string) => {};
    disableAlpha?: boolean;
}

export default StyledColorField;
