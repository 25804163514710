import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    StyledDropzone,
    SimpleDropzone,
    IconTextButton,
    PreviewImage,
    useAddToast,
    useToasts,
} from "../../../index";

const Wrapper = styled(Row)`
    /* display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem; */
`;

const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: column;
`;

const Dropzone = styled(StyledDropzone)`
    display: flex;
    width: 100%;
    /* min-height: 100%; */
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    flex-grow: 1;
`;

export const ImageUploader = ({
    name,
    value,
    onChange,
    type = "image",
    uploadUrl = `/api/v1/files`,
    imageMaxHeight = "300px",
}) => {
    const [newPreviewFile, setNewPreviewFile] = useState(null);

    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );

    const onDrop = (acceptedFiles) => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Image is being uploaded"),
        });

        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));

        const fd = new FormData();
        fd.append("files", acceptedFiles[0]);
        fd.append("type", type);
        fd.append("input_name", name);
        axios
            .post(uploadUrl, fd)
            .then(({ data }) => {
                onChange({ name, value: data.data });
                URL.revokeObjectURL(newPreviewFile);
                setNewPreviewFile(null);
            })
            .catch((e) => {
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred uploading your file");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            })
            .finally(() => {
                dispatch({ type: "removeToast", uuid: "uploadToast" });
            });
    };

    return (
        <Wrapper equaliseChildHeight={true} collapse={true}>
            {value && typeof value.url === "string" && value.url !== "" && (
                <Column medium={6} collapse={true}>
                    <h4>{trans("Current Image")}</h4>
                    <PreviewImage
                        src={value.url}
                        maxHeight={imageMaxHeight}
                        maxWidth="95%"
                    />
                </Column>
            )}
            {newPreviewFile ? (
                <FlexColumn medium={6}>
                    <h4>{trans("New Image")}</h4>
                    <Dropzone onDrop={onDrop}>
                        <PreviewImage
                            src={newPreviewFile}
                            maxHeight={imageMaxHeight}
                            maxWidth="95%"
                        />
                    </Dropzone>
                </FlexColumn>
            ) : (
                <FlexColumn medium={6} collapse={true}>
                    <h4>&nbsp;</h4>
                    <Dropzone onDrop={onDrop}>
                        <IconTextButton
                            style={{
                                textDecoration: "none",
                                paddingTop: "1rem",
                                paddingBottom: "0.8rem",
                                width: "100%",
                            }}
                            onClick={() => {
                                return false;
                            }}
                            icon={faUpload}
                            showCircle={false}
                            expanded={false}
                        >
                            <div style={{ fontWeight: "400" }}>
                                {trans("Upload new image")}
                            </div>
                        </IconTextButton>
                    </Dropzone>
                </FlexColumn>
            )}
        </Wrapper>
    );
};

export default ImageUploader;
