import React from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { Panel } from "@cortexglobal/rla-components";

const Wrapper = styled.dl`
    display: flex;
    flex-wrap: wrap;
`;
const ResultItemWrapper = styled(Panel)`
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem 1rem;
    min-width: 10rem;
    min-height: 10rem;
    max-width: 25%;
    text-align: center;
    aspect-ratio: 1;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
`;
const Value = styled.dd`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3em;
    margin: 0;
    small {
        font-size: 0.3em;
        display: block;
    }
`;
const Label = styled.dt`
    font-size: 1em;
`;
const ResultItem = ({ label, value, supplementaryValue }) => (
    <ResultItemWrapper>
        <Label>{label}</Label>
        <Value>
            {value} <small>{supplementaryValue}</small>
        </Value>
    </ResultItemWrapper>
);
const renderMediaSpecificResult = (result, mediaType) => {
    switch (mediaType) {
        case "email":
            return (
                <>
                    <ResultItem label={trans("Sent")} value={result.sent} />
                    <ResultItem
                        label={trans("Delivered")}
                        value={result.delivered}
                        supplementaryValue={`${Math.round(
                            (result.delivered / result.sent) * 100
                        )}%`}
                    />
                    <ResultItem
                        label={trans("Opened")}
                        value={result.opened}
                        supplementaryValue={`${Math.round(
                            (result.opened / result.sent) * 100
                        )}%`}
                    />
                    <ResultItem
                        label={trans("Clicked")}
                        value={result.clicked}
                        supplementaryValue={`${Math.round(
                            (result.clicked / result.sent) * 100
                        )}%`}
                    />
                    <ResultItem
                        label={trans("Bounced")}
                        value={result.bounced}
                        supplementaryValue={`${Math.round(
                            (result.bounced / result.sent) * 100
                        )}%`}
                    />
                    <ResultItem
                        label={trans("Unsubscribed")}
                        value={result.unsubscribes}
                        supplementaryValue={`${Math.round(
                            (result.unsubscribes / result.sent) * 100
                        )}%`}
                    />
                </>
            );
    }
};

export const FulfilmentResult = ({ result, mediaType }) => {
    // console.log(result, mediaType);
    return <Wrapper>{renderMediaSpecificResult(result, mediaType)}</Wrapper>;
};

export default FulfilmentResult;
