import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { ssoLogin } from "@cortexglobal/cortex-auth-redux";
import { Loading } from "@cortexglobal/rla-components";

class LoginSSO extends Component {
    state = { loggedIn: false };

    componentDidMount() {
        this.props.ssoLogin(this.props.match.params.token);
    }

    render() {
        const { redirectToReferer, loggedInChecked } = this.props;
        if (redirectToReferer && loggedInChecked) {
            const { from } = this.props.location.state || {
                from: { pathname: "/" }
            };

            return <Redirect to={from} />;
        } else {
            return <Loading />;
        }
    }
}

const mapStateToProps = state => {
    const { redirectToReferer, loggedInChecked } = state.auth;
    return { redirectToReferer, loggedInChecked };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ssoLogin
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LoginSSO);
