import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";

import SubmitButton from "./SubmitButton";

const SaveButton = ({ saving, ...props }) => {
    return (
        <SubmitButton
            submitting={saving}
            icon={<FontAwesomeIcon icon={faSave} />}
            submittingIcon={<FontAwesomeIcon icon={faCircleNotch} spin />}
            {...props}
        />
    );
};

SaveButton.displayName = "SaveButton";

SaveButton.propTypes = {
    saving: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

SaveButton.defaultProps = {
    saving: false,
    label: "Save"
};

export default SaveButton;
