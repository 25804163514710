import React from "react";

import { Table, ContentBlockLoader } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { format } from "@cortexglobal/cortex-utilities";

const AssetDetailAvailability = ({ asset, loaded }) => {
    return (
        <React.Fragment>
            <h3>{trans("Availability")}</h3>
            <Table tableType="noStripe">
                <thead>
                    <tr>
                        <th style={{ width: "33%" }}></th>
                        <th style={{ width: "33%" }}>{trans("Asset From")}</th>
                        <th style={{ width: "33%" }}>{trans("Asset To")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{trans("Available")}</th>
                        <td>
                            <ContentBlockLoader loaded={loaded}>
                                {asset.available_from
                                    ? format(
                                          new Date(asset.available_from),
                                          "PP"
                                      )
                                    : "-"}
                            </ContentBlockLoader>
                        </td>
                        <td>
                            <ContentBlockLoader loaded={loaded}>
                                {asset.available_to
                                    ? format(new Date(asset.available_to), "PP")
                                    : "-"}
                            </ContentBlockLoader>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default AssetDetailAvailability;
