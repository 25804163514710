import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Select,
    LoadingIconSmall,
    IconTextButton,
} from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const CampaignSelect = ({
    name,
    onChange,
    asset,
    value,
    campaignsPath = "/admin/campaigns",
}) => {
    const { can } = usePermissions();

    const intl = useIntl();
    const [loaded, setLoaded] = useState(false);
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        axios
            .get("/api/v1/campaigns", {
                params: {
                    optionList: true,
                },
            })
            .then((response) => {
                if (response.data) {
                    setCampaigns(response.data.data);
                    setLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    if (!loaded) {
        return <LoadingIconSmall />;
    }
    return (
        <>
            {campaigns.length > 0 ? (
                <Select
                    name={name}
                    onChange={onChange}
                    value={value}
                    emptyOption={intl.formatMessage({ id: "No Campaign" })}
                    options={[
                        {
                            value: "",
                            text: intl.formatMessage({ id: "No Campaign" }),
                        },
                        ...campaigns,
                    ]}
                />
            ) : (
                <p>
                    {trans("No Campaigns Available")}{" "}
                    {can("dam-campaign-create") && (
                        <IconTextButton
                            as={Link}
                            to={`${campaignsPath}/create`}
                            icon="plus"
                            showCircle={false}
                            target="_blank"
                        >
                            {trans("Create new campaign")}{" "}
                            <FontAwesomeIcon icon={faUpRightFromSquare} />
                        </IconTextButton>
                    )}
                </p>
            )}
        </>
    );
};

export default CampaignSelect;
