import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Equalizer from "react-equalizer";

const centerRow = (props) => {
    if (props.center === false) {
        return false;
    }
    return (
        props.center ||
        typeof props.theme.row === "undefined" ||
        props.theme.row.center === true
    );
};

const StyledRow = styled.div`
    margin: ${(props) => (centerRow(props) && !props.expanded ? "auto" : "0")};
    max-width: ${(props) =>
        props.expanded || props.theme.body.expanded
            ? "100%"
            : props.width + "px"};
    ${(props) =>
        !props.collapse &&
        css`
            padding: 0 ${(props) => props.theme.spacing.padding / 2}em;
        `};
`;

const Clear = styled.div`
    clear: both;
    height: 0 !important;
`;

class Row extends React.Component {
    render() {
        const { children, equaliseChildHeight, equaliseByRow, ...rest } =
            this.props;
        //NOTE - The ternary operator is being used below as the 'enabled' prop on the Equalizer didn't seem
        //to revert after being set back to false from true...
        return (
            <StyledRow {...rest}>
                {this.props.equaliseChildHeight ? (
                    <Equalizer byRow={equaliseByRow}>
                        {children}
                        <Clear />
                    </Equalizer>
                ) : (
                    <React.Fragment>
                        {children}
                        <Clear />
                    </React.Fragment>
                )}
            </StyledRow>
        );
    }
}

Row.displayName = "Row";

Row.propTypes = {
    /** The width of the row */
    center: PropTypes.bool,
    /** The width of the row */
    width: PropTypes.number,
    /** Boolean indicating whether the row should be 100% width */
    expanded: PropTypes.bool,
    /** Boolean indicating whether the row should have padding */
    collapse: PropTypes.bool,
    /** Boolean indicating whether the row should equalise the heights of child elements */
    equaliseChildHeight: PropTypes.bool,
    /** Boolean indicating whether child elements stacking should be considered when equalising (https://github.com/patrickgalbraith/react-equalizer#options) */
    equaliseByRow: PropTypes.bool,
};

Row.defaultProps = {
    width: 1400,
    expanded: false,
    collapse: false,
    equaliseChildHeight: false,
    equaliseByRow: true,
    center: true,
};

export default Row;
