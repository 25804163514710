import React, { useContext } from "react";

import { trans } from "@cortexglobal/rla-intl";
import { TextareaField, StyledTextarea } from "@cortexglobal/rla-components";

import SurveySubmissionContext from "../SurveySubmissionContext";

const AnswerTextarea = ({ answer, readonly }) => {
    const { surveySystem, handleAnswerChange } = useContext(
        SurveySubmissionContext
    );

    return (
        <TextareaField
            name={answer.question_uuid}
            onChange={(e) => handleAnswerChange(e.name, e.value)}
            disabled={!!readonly}
            value={answer.answer}
            error={surveySystem.questionErrors[answer.question_uuid]}
        />
    );
};

export default AnswerTextarea;
