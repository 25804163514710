import React, { useState } from "react";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    SubmitButton,
    FormRow,
    InputField,
    RadioField,
} from "@cortexglobal/rla-components";

const CreateNewDashboard = ({ saveDashboardToServer }) => {
    const intl = useIntl();
    const [name, setName] = useState("");
    const [startingPoint, setStartingPoint] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = (event) => {
        console.log(event);
        event.preventDefault();
        //Check values
        if (
            name === "" ||
            (startingPoint !== "blank" && startingPoint !== "current")
        ) {
            return;
        }

        //Submit to the server
        saveDashboardToServer(name, startingPoint).then((data) => {
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <h3>{trans("Create New Dashboard")}</h3>
            <form onSubmit={handleSubmit}>
                <FormRow
                    name="name"
                    label={trans("Name")}
                    helpText={trans("Required")}
                >
                    <InputField
                        name="name"
                        value={name}
                        onChange={({ value }) => setName(value)}
                    />{" "}
                </FormRow>

                <FormRow
                    name="startingPoint"
                    label={trans("Starting point")}
                    helpText={trans("Required")}
                >
                    <RadioField
                        name="startingPoint"
                        value={startingPoint}
                        options={[
                            {
                                text: intl.formatMessage({ id: "Blank" }),
                                value: "blank",
                            },
                            {
                                text: intl.formatMessage({
                                    id: "Current Dashboard",
                                }),
                                value: "current",
                            },
                        ]}
                        onChange={({ value }) => setStartingPoint(value)}
                    />{" "}
                </FormRow>

                <Row>
                    <Column medium={6}>&nbsp;</Column>
                    <Column medium={3} style={{ paddingBottom: "1rem" }}>
                        <SubmitButton
                            expanded
                            label={trans("Save")}
                            submitting={isSubmitting}
                            disabled={
                                isSubmitting ||
                                name === "" ||
                                startingPoint === ""
                            }
                            type="primary"
                        />
                    </Column>
                </Row>
            </form>
        </>
    );
};

export default CreateNewDashboard;
