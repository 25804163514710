import React, { useState, Children } from "react";
import { isEmpty } from "lodash";
import axios from "axios";
// @ts-ignore cortex
import { Loading } from "@cortexglobal/rla-components";
// @ts-ignore cortex
import { trans, useIntl } from "@cortexglobal/rla-intl";
import TransitionForm from "./TransitionForm";
import TransitionButton from "./TransitionButton";

const emptyItem = {
    workflow: {
        smartRef: "",
        transitions: [],
        assigned_to_group: null,
        assigned_to_user: null,
        assigned_to_role: null,
        assigned_to_team: null,
        isComplete: false,
    },
};
const TransitionButtons = ({
    item = { ...emptyItem },
    buttonType = "",
    loadingCallback = () => {},
    callback = () => {},
    errorCallback = () => {},
    actionCallbacks = {},
    children,
    childrenPosition = "top",
    containerStyle = {},
}: {
    item: {
        workflow: {
            smartRef: string;
            transitions: any[];
            assigned_to_group?: string | null;
            assigned_to_user?: string | null;
            assigned_to_role?: string | null;
            assigned_to_team?: string | null;
            isComplete?: boolean;
        };
    };
    buttonType: string;
    loadingCallback: any;
    callback: any;
    errorCallback: any;
    actionCallbacks: any;
    children: any;
    childrenPosition: "top" | "bottom";
    containerStyle: any;
}) => {
    const intl = useIntl();
    const [updating, setUpdating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showTransitionForm, setShowTransitionForm] = useState(null);
    const [transitionForm, setTransitionForm] = useState(null);
    const [errors, setErrors] = useState({});
    const {
        smartRef,
        transitions,
        assigned_to_group,
        assigned_to_user,
        assigned_to_role,
        assigned_to_team,
        isComplete,
    } = item.workflow;

    const performTransition = (smartRef, transition) => {
        if (loading) {
            return;
        }

        if (!transition.requires_form) {
            return submit(smartRef, transition);
        }

        setShowTransitionForm(transition.uuid);

        loadTransitionForm(smartRef, transition);
    };

    const hideConditions = () => {
        setShowTransitionForm(null);
    };

    const loadTransitionForm = (smartRef, transition) => {
        setLoading(true);
        setTransitionForm(null);

        axios
            .get(
                "/api/v1/workflow/" +
                    smartRef +
                    "/transition/" +
                    transition.uuid
            )
            .then(({ data }) => {
                const formData = data.data;

                setTransitionForm(formData);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setTransitionForm(null);
            });
    };

    const submit = (smartRef, transition, formData) => {
        if (transition.affects_state) {
            setUpdating(true);

            typeof loadingCallback === "function" && loadingCallback(smartRef);

            return axios
                .post(
                    "/api/v1/workflow/" +
                        smartRef +
                        "/transition/" +
                        transition.uuid,
                    formData
                )
                .then(({ data }) => {
                    const newWorkflow = data.data;

                    typeof callback === "function" &&
                        callback(smartRef, newWorkflow);

                    setUpdating(false);
                })
                .catch((e) => {
                    setUpdating(false);

                    typeof errorCallback === "function" &&
                        errorCallback(smartRef, e);

                    if (e.response !== undefined) {
                        const { status, data } = e.response;

                        if (status === 422 && data.errors !== undefined) {
                            setErrors(data.errors);
                        }
                    }
                });
        } else {
            const actionCallback = actionCallbacks[transition.alias];

            typeof actionCallback === "function" &&
                actionCallback(smartRef, transition);
        }
    };

    if (loading) {
        return <Loading />;
    }

    //Sometimes children is an empty array, or an array of "empty" items like [null, false]
    //so we need to check if it has children with content
    const childrenArray = Children.toArray(children);
    const hasChildrenWithContent =
        !isEmpty(childrenArray) && childrenArray.length > 0;

    //Generate a message if there are no transitions
    if (isEmpty(transitions) || transitions.length === 0) {
        let message = trans("No actions available");
        if (isComplete) {
            message = trans("This item is complete so no actions available");
        } else if (
            assigned_to_group ||
            assigned_to_user ||
            assigned_to_role ||
            assigned_to_team
        ) {
            message = intl.formatMessage({
                id: "You have no available actions, currently assigned to:",
            });
            message += ` ${
                assigned_to_user ||
                assigned_to_role ||
                assigned_to_team ||
                intl.formatMessage({ id: "Anyone" })
            }`;

            if (assigned_to_group) {
                message += `, ${assigned_to_group}`;
            }
        }
        return (
            <div style={containerStyle}>
                {!hasChildrenWithContent ? (
                    <p style={{ padding: "0 0.5rem", fontSize: "0.8rem" }}>
                        {message}
                    </p>
                ) : (
                    children
                )}
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            {childrenPosition === "top" && children}
            {transitions.map((transition) => {
                return (
                    <div key={transition.uuid}>
                        <TransitionButton
                            smartRef={smartRef}
                            transition={transition}
                            performTransition={performTransition}
                            disabled={updating}
                            buttonType={buttonType}
                        />
                        {transition.requires_form &&
                            transitionForm !== null &&
                            showTransitionForm === transition.uuid && (
                                <TransitionForm
                                    visible={
                                        showTransitionForm === transition.uuid
                                    }
                                    smartRef={smartRef}
                                    transition={transition}
                                    form={transitionForm}
                                    onClose={hideConditions}
                                    onSubmit={submit}
                                    errors={errors}
                                />
                            )}
                    </div>
                );
            })}
            {childrenPosition === "bottom" && children}
        </div>
    );
};

export default TransitionButtons;
