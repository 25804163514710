import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { foregroundColor } from "@cortexglobal/cortex-utilities";
import {
    darken,
    lighten,
    hexToRgbaString,
} from "@cortexglobal/cortex-utilities";
// import { RubberBand } from "animate-css-styled-components";

const background = (props) => {
    if (
        props.theme.button.types[props.type] &&
        props.theme.button.types[props.type].backgroundColor
    ) {
        return props.theme.button.types[props.type].backgroundColor;
    }
    return (
        props.theme.colors[props.type] ||
        props.theme.colors[props.theme.button.default]
    );
};

let PlainButton = styled.button.attrs({ role: "button" })`
    border: 0;
    padding: 0;
    width: ${(props) => (props.expanded ? "100%" : "auto")};
    font-family: inherit;
    background: none;
    cursor: pointer;
    font-size: ${(props) => props.theme.button.fontSize}px;
    text-align: ${({ textAlign }) => textAlign};
    text-transform: ${(props) => props.theme.button.textTransform};

    //  disabled button styles
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `};

    ${(props) =>
        !props.disabled &&
        css`
            &:hover,
            &:focus {
                color: ${(props) => lighten(background(props), 1.2)};
            }
        `};
`;

PlainButton.displayName = "PlainButton";

PlainButton.propTypes = {
    disabled: PropTypes.bool,
    margin: PropTypes.number,
    height: PropTypes.number,
    textAlign: PropTypes.string,
};

PlainButton.defaultProps = {
    disabled: false,
    height: 36,
    textAlign: "left",
};

export default PlainButton;
