import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withPanelData from "../withPanelData";
import { showModal } from "../../../dashboardActions";

import CommsTable from "./CommsTable";
import SetFollowedStatus from "./SetFollowedStatus";

class CampaignReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCommunication: null,
            selectedCommunicationIndex: null,
            communications: []
        };
    }

    componentWillMount() {
        this.setState({ communications: this.props.data });
    }

    previewCommunication = communication => {
        alert(
            `This communication to: ${
                communication.customerName
            } is a test, and no previews are available for test data`
        );
    };

    setFollowedStatus = (communication, index) => {
        console.log(communication);
        this.setState({
            selectedCommunication: communication,
            selectedCommunicationIndex: index
        });
        this.props.showModal("setFollowedStatus");
    };

    updateStatus = (status, selectedIndex) => {
        //console.log("status", status, "selectedIndex", selectedIndex);
        this.setState({
            communications: this.state.communications.map((item, index) => {
                if (index === selectedIndex) {
                    item.followed = status;
                }
                return item;
            }),
            selectedCommunication: null,
            selectedCommunicationIndex: null
        });
    };

    render() {
        //const { height, layout } = this.props;
        //console.log(this.state.communications);
        return (
            <div>
                <CommsTable
                    comms={this.state.communications}
                    setFollowedStatus={this.setFollowedStatus}
                    previewCommunication={this.previewCommunication}
                />

                {this.state.selectedCommunication && (
                    <SetFollowedStatus
                        communication={this.state.selectedCommunication}
                        communicationIndex={
                            this.state.selectedCommunicationIndex
                        }
                        updateStatus={this.updateStatus}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return { modal: state.modal };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ showModal }, dispatch);

CampaignReport = connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignReport);

export default withPanelData(
    CampaignReport,
    "/api/v1/central-communications/summary",
    []
);
