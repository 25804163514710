import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Row,
    Column,
    Modal,
    StyledDropzone
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { importPlan } from "../plannerActions";

class ImportPlanner extends React.Component {
    onDrop(files) {
        this.props.importPlan(files[0]);
    }

    render() {
        return (
            <Modal
                visible={this.props.modalName === "importPlan"}
                onClose={this.props.hideModal}
            >
                <Row>
                    <h2 className="modalTitle">{trans("Import Plan")} (csv)</h2>
                </Row>

                <div>
                    <form>
                        <fieldset>
                            <Row>
                                <Column>
                                    <StyledDropzone
                                        name="planUpload"
                                        onDrop={this.onDrop.bind(this)}
                                        className="recipientDropzone"
                                    >
                                        <div>
                                            <FontAwesomeIcon icon="file-alt" />
                                            <br />
                                            {trans(
                                                "Drop a CSV HERE to upload a new plan."
                                            )}
                                        </div>
                                    </StyledDropzone>
                                </Column>
                            </Row>
                        </fieldset>
                    </form>
                </div>
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            importPlan
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const { importing } = state.planner;
    return {
        importing
    };
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ImportPlanner);
