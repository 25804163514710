import React from "react";
import { useHistory } from "react-router-dom";

//@ts-ignore cortex
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Table,
    Pagination,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

export const CommunicationRuleActivityUserTable = ({
    items,
    loaded,
    path,
    pagination = null,
    onPageChange,
    onOrderBy,
    onPerPageChange,
}: CommunicationRuleTableProps) => {
    const intl = useIntl();
    const history = useHistory();

    const currentPage = pagination ? pagination.current_page : 1;

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>{trans("Name")}</th>
                        <th>{trans("Previous Internal Send Date")}</th>
                        <th>{trans("Next Internal Send Date")}</th>
                        <th>{trans("Previous Pop-up Send Date")}</th>
                        <th>{trans("Next Pop-up Send Date")}</th>
                        <th>{trans("Mailing List Join Date")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded
                        ? ""
                        : items.map((item) => {
                              return (
                                  <tr>
                                      <td>{item.users.name}</td>
                                      <td>
                                          {item.users.last_internal_send_date}
                                      </td>
                                      <td>
                                          {item.users.next_internal_send_date}
                                      </td>
                                      <td>
                                          {item.users.last_pop_up_send_date}
                                      </td>
                                      <td>
                                          {item.users.next_pop_up_send_date}
                                      </td>
                                      <td>
                                          {item.users.mailing_list_join_date}
                                      </td>
                                  </tr>
                              );
                          })}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
        </React.Fragment>
    );
};

interface CommunicationRuleTableProps {
    items: any;
    loaded: boolean;
    path: string;
    pagination?: any;
    onPageChange?: (number) => void;
    onOrderBy?: (string) => void;
    onPerPageChange?: (any) => void;
}

export default CommunicationRuleActivityUserTable;
