import React, { useState } from "react";
import styled from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { Row, Column, Pagination, Button } from "@cortexglobal/rla-components";
import set from "lodash/set";
import get from "lodash/get";

import Gallery from "../../gallery/frontend/Gallery";

import NavInputGroup from "./components/NavInputGroup";
import { onConfigurationChange } from "./components/sharedEditorFunctions";
/**
 * Recursively render the input groups for the editor
 * This also builds up the path back to the element
 * @param {*} tag
 * @param {*} pageName
 * @param {*} onChange
 * @param {*} autofill
 * @param {*} path
 * @param {*} index
 */
const renderInputGroupRecursive = ({
    pageOptions,
    tag,
    onChange,
    chooseImage,
    autofill,
    path = "",
    index = 0,
}) => {
    //If the tag type is array we need to recursively setup the contents of it's value
    if (tag.children && tag.children.length > 0) {
        //Prepend the index of the "array" tag to the path, and point to it's 'value' property
        path += `[${index}]`;
        return tag.map((subTag, tagIndex) => {
            //console.log(path, index, tagIndex, subTag.name);
            return renderInputGroupRecursive({
                pageOptions,
                tag: subTag,
                onChange,
                chooseImage,
                autofill,
                path,
                index: tagIndex,
            });
        });
    }
    return (
        <React.Fragment key={tag.name}>
            <NavInputGroup
                pageOptions={pageOptions}
                path={path + `[${index}]`}
                navItem={tag}
                onChange={onChange}
                chooseImage={chooseImage}
                autofill={autofill}
            />
        </React.Fragment>
    );
};

const NavigationEditor = ({ asset, standardOnChange }) => {
    const [selectedMenu, selectMenu] = useState("main");
    if (!asset || !asset.editable || !asset.microsite) {
        return null;
    }
    const { configuration, extracted_tags, autofill, uuid, asset_type } = asset;

    let currentConfiguration = configuration ? configuration : extracted_tags;
    if (!currentConfiguration) {
        return "No configuration has been extracted from this asset";
    }
    const pageOptions = Object.entries(currentConfiguration.pages).reduce(
        (pageOptionArray, [pageName, pageSetup]) => {
            const pageOption = {
                value: `/${pageName}`,
                text: pageSetup.name,
                hidden: pageSetup.hidden,
            };
            pageOptionArray.push(pageOption);
            return pageOptionArray;
        },
        []
    );
    //TODO - Handle multiple navigation items at the moment it's only doing the 'main' one
    const basePath = `nav[${selectedMenu}]`;
    //Get the nav menus or create an empty default
    const navMenus = currentConfiguration.nav
        ? currentConfiguration.nav
        : {
              main: [
                  {
                      url: "/",
                      name: "",
                      text: "Home",
                      color: null,
                      image: null,
                      children: [],
                      disabled: false,
                      internal: true,
                  },
              ],
          };
    const selectedMenuItems = navMenus[selectedMenu];
    return (
        <React.Fragment>
            <Row>
                <Column>
                    {selectedMenuItems.length > 0 ? (
                        selectedMenuItems.map((tag, elementIndex) => {
                            //Add the code to check if the tag is an array, and do the looping,
                            //moving the below out into it's own component would be good
                            return renderInputGroupRecursive({
                                pageOptions,
                                tag,
                                onChange: (value, path, event) => {
                                    onConfigurationChange(
                                        standardOnChange,
                                        asset,
                                        value,
                                        event,
                                        path
                                    );
                                },
                                chooseImage: (path, tag, event) => {
                                    //TODO - Set the correct image/tag in state to be used on the
                                    //update method
                                    return setChoosingImage(!choosingImage);
                                },
                                autofill,
                                path: basePath,
                                index: `${elementIndex}`,
                            });
                        })
                    ) : (
                        <span>No editable fields</span>
                    )}
                </Column>
            </Row>
        </React.Fragment>
    );
};

export default NavigationEditor;
