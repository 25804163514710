import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    faFolderPlus,
    faFolder,
    faFolderOpen
} from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    Modal,
    IconTextButton,
    FormikInput,
    SubmitButton,
    Column,
    Row,
    Button
} from "@cortexglobal/rla-components";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import AssetContext from "../AssetContext";

import { Folder } from "./Folder";
import { FolderBreadcrumb } from "./FolderBreadcrumb";
const FolderColumn = styled(Column)`
    margin-bottom: 0.2rem;
`;
const FolderTitle = styled.h4`
    display: inline-block;
    margin: 0 0.5rem 0 0;
`;

const FolderSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, "The folder name is too short")
        .max(255, "The folder name is too long")
        .required("Required")
});

const FavouriteFolderBrowser = ({
    favouriteFolderUuid = "",
    maxColumns = 3,
    onFolderUpdated,
    showFolderBrowser
}) => {
    const { config } = useContext(AssetContext);
    const initialState = {
        loaded: false,
        data: { uuid: "", name: "", children: [] }
    };
    const { can } = usePermissions();
    const [folder, setFolder] = useState(initialState);
    const [visible, setVisible] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [folderForEditing, setFolderForEditing] = useState({
        folder: {},
        editing: false
    });
    const [folderForDeleting, setFolderForDeleting] = useState({
        folder: {},
        deleting: false
    });
    useEffect(() => {
        if (config.options.multiple_favourites_enabled) {
            setVisible(true);
        } 

    }, [config]);

    useEffect(() => {
        if (!config.multiple_favourites_enabled) {
            loadFolders(favouriteFolderUuid);
        }
    }, [favouriteFolderUuid]);

    const loadFolders = favouriteFolderUuid => {
        setFolder({ ...folder, loaded: false });

        return axios
            .get(`api/v1/assets/favourite-folders${favouriteFolderUuid ? `/${favouriteFolderUuid}` : ""}`)
            .then(({ data }) => {
                setFolder({ loaded: true, data: data.data });
            });
    };

    const saveFolder = (values, { setErrors }) => {
        if (favouriteFolderUuid !== "") {
            values = { ...values, parent_uuid: favouriteFolderUuid };
        }
        axios
            .post(`api/v1/assets/favourite-folders`, values)
            .then(() => {
                loadFolders(favouriteFolderUuid);
                setShowCreateModal(false);
            })
            .catch(e => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });
            });

        return true;
    };

    const updateFolder = (values, { setErrors }) => {
        //Remove the parent_uuid if it is null
        if (values.parent_uuid === null) {
            delete values.parent_uuid;
        }
        axios
            .put(`api/v1/assets/favourite-folders/${values.uuid}`, values)
            .then(() => {
                loadFolders(favouriteFolderUuid);
                setFolderForEditing({ folder: {}, editing: false });
            })
            .catch(e => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });
            });

        return true;
    };

    const deleteFolder = uuid => {
        axios
            .delete(`api/v1/assets/favourite-folders/${uuid}`)
            .then(() => {
                loadFolders(favouriteFolderUuid);
                setFolderForDeleting({ folder: {}, deleting: false });
            })
            .catch(e => {});

        return true;
    };

    const selectFolderForEditing = selectedFolder => {
        setFolderForEditing({ folder: selectedFolder, editing: true });
    };
    const selectFolderForDeleting = selectedFolder => {
        setFolderForDeleting({ folder: selectedFolder, deleting: true });
    };

    const renderParents = (parent, parentArray = []) => {
        if (parent) {
            const isCurrent = parent.uuid === favouriteFolderUuid;
            return renderParents(parent.parent, [
                ...parentArray,
                <FolderBreadcrumb
                    key={parent.uuid}
                    folder={{ uuid: parent.uuid, name: parent.name }}
                    isCurrent={isCurrent}
                    onFolderUpdated={onFolderUpdated}
                />
            ]);
        }
        return parentArray;
    };

    const { data: current, loaded } = folder;
    if (!can("dam-folder-view")) {
        return null;
    }
    return (
        <>
            <Collapse isOpened={visible}>
                <Row style={{ margin: "0 1rem" }}>
                    <FolderTitle>{trans("Favourite Folders")}</FolderTitle>
                    {current.uuid !== "" && (
                        <FolderBreadcrumb
                            folder={{ uuid: "", name: "" }}
                            isCurrent={false}
                            onFolderUpdated={onFolderUpdated}
                        />
                    )}
                    {current.parent && renderParents(current.parent)}

                    <FolderBreadcrumb
                        folder={current}
                        isCurrent={current.uuid === favouriteFolderUuid}
                        onFolderUpdated={onFolderUpdated}
                    />
                </Row>
                <Row
                    style={{ margin: "1rem 1rem 1rem 0" }}
                    equaliseChildHeight={true}
                    equaliseByRow={false}
                >
                    {current.children.map(folder => {
                        return (
                            <FolderColumn
                                small={12}
                                medium={6}
                                large={4}
                                xlarge={maxColumns}
                            >
                                <Folder
                                    folder={folder}
                                    onFolderUpdated={onFolderUpdated}
                                    loaded={loaded}
                                    canEdit={can("dam-favourite-folder-edit")}
                                    canDelete={can("dam-favourite-folder-delete")}
                                    selectFolderForEditing={
                                        selectFolderForEditing
                                    }
                                    selectFolderForDeleting={
                                        selectFolderForDeleting
                                    }
                                />
                            </FolderColumn>
                        );
                    })}
                    {can("dam-folder-create") && (
                        <FolderColumn
                            small={12}
                            medium={6}
                            large={4}
                            xlarge={maxColumns}
                            style={{ display: "flex" }}
                        >
                            <IconTextButton
                                icon={faFolderPlus}
                                onClick={() => setShowCreateModal(true)}
                                showCircle={false}
                                expanded={true}
                                iconSize="20px"
                                style={{ padding: "1rem 0.5rem" }}
                            >
                                {trans("Create Favourite folder")}
                            </IconTextButton>
                        </FolderColumn>
                    )}
                </Row>
            </Collapse>
            {showCreateModal && (
                <Modal
                    visible={showCreateModal}
                    onClose={() => setShowCreateModal(false)}
                >
                    <Formik
                        initialValues={{
                            name: ""
                        }}
                        onSubmit={saveFolder}
                        validationSchema={FolderSchema}
                    >
                        {({ submitting }) => (
                            <Form className="form-group">
                                <FormikInput
                                    type="text"
                                    name="name"
                                    label="Folder Name"
                                />

                                <SubmitButton
                                    type="primary"
                                    label="Create"
                                    height={40}
                                    submitting={submitting}
                                />
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
            {folderForEditing.editing && (
                <Modal
                    visible={folderForEditing.editing}
                    onClose={() =>
                        setFolderForEditing({ folder: {}, editing: false })
                    }
                >
                    <Formik
                        initialValues={{
                            name: folderForEditing.folder.name,
                            uuid: folderForEditing.folder.uuid,
                            parent_uuid: folderForEditing.folder.parent
                                ? folderForEditing.folder.parent.uuid
                                : null
                        }}
                        onSubmit={updateFolder}
                        validationSchema={FolderSchema}
                    >
                        {({ submitting, values }) => (
                            <Form className="form-group">
                                <FormikInput
                                    type="text"
                                    name="name"
                                    label="Folder Name"
                                    value={values.name}
                                />

                                <SubmitButton
                                    type="primary"
                                    label="Update"
                                    height={40}
                                    submitting={submitting}
                                />
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
            {folderForDeleting.deleting && (
                <Modal
                    visible={folderForDeleting.deleting}
                    onClose={() =>
                        setFolderForDeleting({ folder: {}, deleting: false })
                    }
                >
                    <Row>
                        <Column>
                            <h3>
                                {trans(
                                    "Are you sure you want to delete this folder?"
                                )}
                            </h3>
                            <p>
                                {trans(
                                    "This will delete all the assets in this folder. It will also delete all sub folders and the assets within them."
                                )}
                            </p>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={6}>
                            <IconTextButton
                                onClick={() =>
                                    setFolderForDeleting({
                                        folder: {},
                                        deleting: false
                                    })
                                }
                                type="primary"
                            >
                                {trans("Cancel")}
                            </IconTextButton>
                        </Column>
                        <Column small={6}>
                            <Button
                                onClick={() =>
                                    deleteFolder(folderForDeleting.folder.uuid)
                                }
                                type="alert"
                            >
                                {trans("Delete")}
                            </Button>
                        </Column>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default FavouriteFolderBrowser;
