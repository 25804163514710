import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";

import styled from "styled-components";

import { useQueryString } from "@cortexglobal/cortex-utilities";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { trans } from "@cortexglobal/rla-intl";
import {
    Loading,
    DraftJSOutput,
    IconTextButton,
    Tooltip,
} from "@cortexglobal/rla-components";

import FaqContext from "../FaqContext";
import FaqQuestionEdit from "./FaqQuestionEdit";

const PageWrapper = styled.article`
    padding: 1rem;
    background: ${({ theme }) => theme.content.page.background};
    color: ${({ theme }) => theme.content.page.color};
    border-radius: ${({ theme }) => theme.content.page.radius}px;
`;

const PageHeaderFaq = styled.header`
    display: flex;
`;

const PageHeadingSummary = styled.section`
    flex: 1;
`;

const PageHeading = styled.h2``;

const AuthorSection = styled.aside`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const AuthorName = styled.span`
    flex: 1;
    margin-left: 1rem;
`;

const ProfileImg = styled.div`
    background: url(${(props) => props.avatar});
    background-size: cover;
    background-position: 80% 65%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin: 1rem auto;
`;

const ActionButtonWrapper = styled.aside`
    float: left;
    button:last-child {
        padding-right: 0;
    }
`;

export const FaqQuestionView = () => {
    const { can } = usePermissions();
    const { faqState, faqNavigate } = useContext(FaqContext);
    const { category_loading, category } = faqState;

    const {
        url,
        params: { question: questionSlug },
    } = useRouteMatch();

    const question = category.questions?.find((question) => {
        return question.slug === questionSlug;
    });

    // We determine if we're editing using a get variable and navigate to it with history.push
    const editing =
        !!useQueryString().get("editing") || (false && can("faq-manage-edit"));

    if (category_loading) {
        return <Loading />;
    }

    if (!question) {
        return <PageWrapper>{trans("Question not found")}</PageWrapper>;
    }

    return (
        <PageWrapper>
            <PageHeaderFaq>
                <PageHeadingSummary>
                    <PageHeading>
                        {category && `${category.text} > `}{" "}
                        {question?.question && (
                            <span
                                style={{
                                    fontStyle: "italic",
                                    fontWeight: "normal",
                                }}
                            >
                                {question?.question}
                            </span>
                        )}
                    </PageHeading>
                </PageHeadingSummary>
                {can("faq-manage-edit") && !editing && (
                    <ActionButtonWrapper>
                        <Tooltip text={trans("Edit")} showIcon={false}>
                            <IconTextButton
                                icon="edit"
                                showCircle={false}
                                onClick={() =>
                                    faqNavigate(`${url}?editing=true`)
                                }
                            />
                        </Tooltip>
                    </ActionButtonWrapper>
                )}
                {question.request_link && (
                    <ActionButtonWrapper>
                        <Tooltip
                            text={trans("Link To Pending Request")}
                            showIcon={false}
                        >
                            <IconTextButton
                                type="secondary"
                                expanded
                                as={"a"}
                                href={question.request_link}
                            ></IconTextButton>
                        </Tooltip>
                    </ActionButtonWrapper>
                )}
            </PageHeaderFaq>

            {editing ? (
                <section>
                    <FaqQuestionEdit question={question} />
                </section>
            ) : (
                <section>
                    {question?.answer && (
                        <DraftJSOutput>{question?.answer}</DraftJSOutput>
                    )}
                </section>
            )}
        </PageWrapper>
    );
};

export default FaqQuestionView;
