import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Layout } from "../interfaces/layout";
import { SlideWrapper } from "../components/slideComponents";
import MicrositeContext from "../MicrositeContext";

const SlideContainer = styled(SlideWrapper)`
    display: flex;
    flex-direction: column;
`;

const SlideTitle = styled(animated.h1)`
    display: flex;
    justify-content: center;
    flex: 2;
    align-items: flex-end;
    position: relative;
    z-index: 1;
`;

const Video = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;

const TitleVideo = ({ settings, content, links }: Layout) => {
    // console.log(settings, content, links);

    let {
        navigationState: { currentTransitionIndex },
        editing
    } = useContext(MicrositeContext);
    // console.log("currentTransitionIndex", currentTransitionIndex, content);
    if (editing) {
        currentTransitionIndex = 2;
    }

    return (
        <SlideContainer settings={settings}>
            <Video
                id="ytplayer"
                type="text/html"
                width="640"
                height="360"
                frameborder="0"
            />
            <SlideTitle
                style={useSpring({
                    opacity: currentTransitionIndex > 0 ? 1 : 0
                })}
            >
                {content.body_title.value}
            </SlideTitle>
        </SlideContainer>
    );
};

export default TitleVideo;
