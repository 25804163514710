import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconTextButton, Centred, Loading } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePanelData from "./usePanelData";
import { FlexPanel, FlexRow, FlexSection } from "../layoutComponents";

const NotificationsMessage = styled.p`
    text-align: center;
    margin-bottom: 0.8rem;
`;

const Exclamation = styled.div`
    color: red;
    font-size: 2rem;
    padding: 0.8rem;
`;

const NotificationsTotal = ({ panelId, height, user }) => {
    const panelData = usePanelData(panelId, `/api/v1/notifications`);
    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }
    const { data } = panelData;
    return (
        <FlexPanel>
            <FlexRow>
                {data.length > 0 && (
                    <Exclamation>
                        <FontAwesomeIcon icon="exclamation-circle" />
                    </Exclamation>
                )}
                <NotificationsMessage>
                    {trans("You have {count} new notifications", {
                        count: data.length
                    })}
                </NotificationsMessage>
            </FlexRow>
            <div>
                <IconTextButton as={Link} to={"/communications"} expanded>
                    {trans("View More")}
                </IconTextButton>
            </div>
        </FlexPanel>
    );
};

export default NotificationsTotal;
