import React from "react";
import { Row, Column } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const AttendeesSummary = ({ event, settings: { excludedOptionalFields } }) => {
    return (
        <React.Fragment>
            <Row>
                <Column medium={3}>
                    {trans("Predicted number of attendees")}:
                </Column>
                <Column medium={9}>{event.predicted_attendee_count}</Column>
            </Row>
            {!excludedOptionalFields.includes("staff_attendee_count") && (
                <Row>
                    <Column medium={3}>{trans("Staff attendees")}:</Column>
                    <Column medium={9}>{event.staff_attendee_count}</Column>
                </Row>
            )}
            <Row>
                <Column medium={3}>{trans("Target audience")}:</Column>
                <Column medium={9}>{event.target_audience}</Column>
            </Row>
        </React.Fragment>
    );
};

export default AttendeesSummary;
