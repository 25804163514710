import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import DashboardPanelListing from "./DashboardPanelListing";
import DashboardPanelCreate from "./DashboardPanelCreate";
import { DashboardPanelEdit } from "./DashboardPanelEdit";

export const DashboardPanelAdmin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <DashboardPanelListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <DashboardPanelCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:id`}
                render={(props) => {
                    const {
                        match: {
                            params: { id },
                        },
                    } = props;
                    return <DashboardPanelEdit path={path} id={id} />;
                }}
            />
        </Switch>
    );
};

export default DashboardPanelAdmin;
