import React, { Component } from "react";

import PageViewTotalConfiguration from "./PageViewTotalConfiguration";
import PageViewTotalOutput from "./PageViewTotalOutput";

class PageViewTotal extends Component {
    render() {
        if (this.props.configuring) {
            return <PageViewTotalConfiguration {...this.props} />;
        }
        return <PageViewTotalOutput {...this.props} />;
    }
}

export default PageViewTotal;
