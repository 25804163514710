import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import {
    Panel,
    Row,
    Column,
    Button,
    PageTitle,
    Loading,
} from "@cortexglobal/rla-components";
import PlannerTaskCategoryForm from "../forms/PlannerTaskCategoryForm";
import { trans } from "@cortexglobal/rla-intl";
import { HeaderEmphasis } from "../components/overviewComponents";

const EditTaskCategory = ({ categoryUuid, categories, viewPlanner, user }) => {
    const [categoryToEdit, setCategoryToEdit] = useState(null);
    useEffect(() => {
        const category = categories.data.find(
            (category) => category.uuid === categoryUuid
        );
        console.log(category, categories.data, categoryUuid);
        setCategoryToEdit(category);
    }, [categoryUuid, categories.data]);

    return (
        <React.Fragment>
            <PageTitle
                title={
                    categoryToEdit && (
                        <React.Fragment>
                            {trans("Editing Category - ")}{" "}
                            <HeaderEmphasis>
                                {categoryToEdit.name}
                            </HeaderEmphasis>
                        </React.Fragment>
                    )
                }
            ></PageTitle>

            <Row>
                <Column>
                    <Panel>
                        <Row>
                            {categoryToEdit && (
                                <PlannerTaskCategoryForm
                                    action="editTaskCategory"
                                    user={user}
                                    category={categoryToEdit}
                                    onGoBack={viewPlanner}
                                />
                            )}
                        </Row>
                    </Panel>
                </Column>
            </Row>
        </React.Fragment>
    );
};

EditTaskCategory.displayName = "EditTaskCategory";

EditTaskCategory.propTypes = {
    category: PropTypes.shape({
        name: PropTypes.string,
    }),
    viewPlanner: PropTypes.func,
};

EditTaskCategory.defaultProps = {};

export default EditTaskCategory;
