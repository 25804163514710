import React from "react";
import styled from "styled-components";
import { transparentize } from "@cortexglobal/cortex-utilities";

export const StyledTask = styled.div`
    cursor: pointer;
    border-radius: ${({ height }) => height / 2}px;
    display: table-cell;
    padding: 0 5px;
    position: absolute;
    /* z-index: 2; */
    transition: all 0.2s ease-in-out;
    margin-top: ${({ spacing }) => spacing}px;

    background: ${({ colors, type, calculated }) => {
        const baseColor = colors.tasks[type];
        if (typeof baseColor === "undefined") {
            return `rgba(0, 0, 0, ${calculated ? 0.9 : 0.9})`;
        }
        return colors.activities.taskBackgroundGenerator({
            baseColor: baseColor,
            transparentizedColor: transparentize(
                baseColor,
                calculated ? 0.3 : 0.1
            ),
        });
    }};
    color: white;
    /* text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5), 1px -1px 0 rgba(0, 0, 0, 0.5),
        -1px 1px 0 rgba(0, 0, 0, 0.5), 1px 1px 0 rgba(0, 0, 0, 0.5); */
    font-weight: bold;
    height: ${({ height }) => height}px;
    line-height: ${({ height }) => height}px;
    left: ${({ left }) => left}px;
    width: ${({ width }) => width}px;
    opacity: ${({ calculated }) => (calculated ? 0.3 : 0.9)};
    &:hover {
        opacity: ${({ calculated }) => (calculated ? 0.4 : 1)};
    }
`;

export default StyledTask;
