import {
    LOAD_DASHBOARD,
    LOADING_DASHBOARD,
    DASHBOARD_EDITED,
    LOAD_DASHBOARD_PANELS,
    CHOOSE_PANEL,
    ADD_PANEL,
    UPDATE_PANEL,
    DELETE_PANEL,
    STORE_ORIGINAL_DASHBOARD,
} from "./dashboardActions";

const initialState = {
    home: {
        loaded: false,
        edited: false,
        name: "",
        scope: "user",
        items: [],
        layouts: {},
        panels: {
            loaded: false,
            items: [],
        },
        selectedPanel: null,
        originalDashboard: { items: [], layouts: {} },
    },
    reporting: {
        loaded: false,
        edited: false,
        name: "",
        scope: "user",
        items: [],
        layouts: {},
        panels: {
            loaded: false,
            items: [],
        },
        selectedPanel: null,
        originalDashboard: { items: [], layouts: {} },
    },
    admin: {
        loaded: false,
        edited: false,
        name: "",
        scope: "user",
        items: [],
        layouts: {},
        panels: {
            loaded: false,
            items: [],
        },
        selectedPanel: null,
        originalDashboard: { items: [], layouts: {} },
    },
};

export default function dashboards(state = initialState, action) {
    switch (action.type) {
        case LOADING_DASHBOARD:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    loading: true,
                },
            };
        case LOAD_DASHBOARD:
            //If the dashboard scope is role, but one is not in the action.role
            //it's like just not been passed in so we'll retain the one we have
            let role = undefined;
            if (action.scope === "role") {
                role = action.role ? action.role : state[action.slug].role;
            }
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    loaded: action.loaded,
                    loading: action.loading,
                    uuid: action.uuid,
                    name: action.name,
                    scope: action.scope,
                    items: action.items,
                    layouts: action.layouts,
                    role: role,
                },
            };
        case STORE_ORIGINAL_DASHBOARD:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    originalDashboard: action.data,
                },
            };
        case DASHBOARD_EDITED:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    edited: action.edited,
                },
            };
        case LOAD_DASHBOARD_PANELS:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    panels: {
                        ...state[action.slug].panels,
                        loaded: action.loaded,
                        items: action.items,
                    },
                },
            };
        case CHOOSE_PANEL:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    selectedPanel: action.panel,
                },
            };
        case ADD_PANEL:
            const currentItemCount = state[action.slug].items.length;
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    items: [
                        ...state[action.slug].items,
                        {
                            key: action.panel.key,
                            props: action.panel.props,
                            title: action.panel.title,
                            component: action.panel.component,
                            configurable: action.panel.configurable,
                            lockAspectRatio: action.panel.lock_aspect_ratio,
                        },
                    ],
                    layouts: addToLayouts(
                        state[action.slug].layouts,
                        action.panel,
                        action.cols,
                        currentItemCount
                    ),
                },
            };
        case UPDATE_PANEL:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    items: state[action.slug].items.map((item) => {
                        if (item.key === action.panel.key) {
                            return { ...item, props: action.panel.props };
                        }
                        return item;
                    }),
                },
            };
        case DELETE_PANEL:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    items: state[action.slug].items.filter((item) => {
                        if (item.key === action.panel.key) {
                            return false;
                        }
                        return true;
                    }),
                    layouts: deleteFromLayouts(
                        state[action.slug].layouts,
                        action.panel
                    ),
                },
            };

        default:
            return state;
    }
}

const addToLayouts = (layouts, panel, cols, currentItemCount) => {
    return Object.keys(layouts).reduce((layoutObj, layoutKey) => {
        return {
            ...layoutObj,
            [layoutKey]: layouts[layoutKey].concat({
                i: panel.key,
                w: panel.initial_width,
                h: panel.initial_height,
                x: 0,
                y: currentItemCount + 1, // puts it at the bottom
                minW: panel.min_width,
                minH: panel.min_height,
            }),
        };
    }, {});
};
const deleteFromLayouts = (layouts, panel) => {
    return Object.keys(layouts).reduce((layoutObj, layoutKey) => {
        return {
            ...layoutObj,
            [layoutKey]: layouts[layoutKey].filter((item) => {
                if (item.i === panel.key) {
                    return false;
                }
                return true;
            }),
        };
    }, {});
};
