import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import {
    Breadcrumbs,
    // MainNav,
    SiteWrapper,
    MainContentWrapper,
    Loading
} from "@cortexglobal/rla-components";

import Header from "./PrivateHeader";
import MainNav from "./PrivateNav";
import Footer from "./PrivateFooter";

const Admin = lazy(() => import("../../modules/admin/Admin"));

const AdminApp = ({ navigation, role, location }) => {
    return (
        <SiteWrapper>
            <Header updateSection={() => {}} />
            {/* <div>Nav Here</div> */}
            <MainContentWrapper>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route path="/" component={Admin} />
                    </Switch>{" "}
                </Suspense>
            </MainContentWrapper>
            <Footer />
        </SiteWrapper>
    );
};

export default AdminApp;
