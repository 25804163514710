import React from "react";
import styled, { css, withTheme } from "styled-components";
import Icon from "../icon";

export const AccordionHeading = styled.h3`
    margin: 0;
    text-align: ${({ theme }) => theme.tabordion.accordion.textAlign};
    font-weight: ${({ theme }) => theme.tabordion.fontWeight};
    font-size: ${({ theme }) => theme.tabordion.heading.fontSize};
    /* cursor: pointer; */
    position: relative;
    ${({ isActive }) =>
        isActive &&
        css`
            border-color: ${({ theme }) => theme.tabordion.active.borderColor};
            border-width: ${({ theme }) => theme.tabordion.borderThickness};
        `};
`;
const AccordionItem = styled.div`
    padding: ${({ theme }) => theme.tabordion.padding / 2}rem
        ${({ theme }) => theme.tabordion.padding}rem;
    text-align: ${({ theme }) => theme.tabordion.accordion.textAlign};
    font-weight: ${({ theme }) => theme.tabordion.fontWeight};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    position: relative;
    ${({ isActive }) =>
        isActive
            ? css`
                  margin: 0 0 ${({ theme }) => theme.tabordion.padding / 2}rem 0;
                  color: ${({ theme }) => theme.tabordion.active.color};
                  background: ${({ theme }) =>
                      theme.tabordion.active.background};
                  border-color: ${({ theme }) =>
                      theme.tabordion.active.borderColor};
                  border-width: ${({ theme }) =>
                      theme.tabordion.borderThickness};
                  border-bottom-style: solid;
              `
            : css`
                  color: ${({ theme }) => theme.tabordion.default.color};
                  background: ${({ theme }) =>
                      theme.tabordion.default.background};
                  border-color: ${({ theme }) => theme.tabordion.borderColor};
                  border-width: ${({ theme }) =>
                      theme.tabordion.borderThickness};
                  border-bottom-style: solid;

                  &:hover,
                  &:focus,
                  &:active {
                      background: ${({ theme, disabled }) =>
                          disabled
                              ? theme.tabordion.default.background
                              : theme.tabordion.default.hover};
                  }
              `};
`;

const Twisty = styled.div`
    right: 0;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
`;

const AccordionHeader = ({
    children,
    allowFullyClosed = true,
    current,
    i,
    theme,
    ...rest
}) => {
    const isActive = current == i;
    return (
        <AccordionItem isActive={isActive} {...rest}>
            <Twisty isActive={isActive}>
                {!allowFullyClosed && isActive ? null : (
                    <Icon
                        name={
                            isActive
                                ? theme.tabordion.twisty.activeIcon
                                : theme.tabordion.twisty.nonActiveIcon
                        }
                    />
                )}
            </Twisty>
            {children}
        </AccordionItem>
    );
};

export default withTheme(AccordionHeader);
