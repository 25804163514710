import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import { logout } from "@cortexglobal/cortex-auth-redux";

class LogoutWrapper extends Component {
    render() {
        if (!this.props.loggedIn) {
            return (
                <Redirect
                    to={
                        this.props.redirect
                            ? this.props.redirect
                            : this.props.loginUrl
                    }
                />
            );
        }

        return (
            <React.Fragment>
                {this.props.children({
                    logout: this.props.logout.bind(this),
                })}
            </React.Fragment>
        );
    }
}

LogoutWrapper.propTypes = {
    loginUrl: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logout,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LogoutWrapper);
