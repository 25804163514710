import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Panel,
    Tabordion,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import { CategoryForm, Category } from "../../forms/CategoryForm";
import { HeaderEmphasis, TabordionGroup } from "../../components/index";
import CategoryTable from "./CategoryTable";

const deleteItem = (uuid, setItem, item) => {
    const area = item.data.area.toLowerCase();

    return axios
        .delete(`/api/v1/admin/${area}-category/${uuid}`)
        .then(({ data }) => {
            setItem({
                loaded: true,
                data: {
                    ...item.data,
                    children: item.data.children.filter(
                        (child) => child.uuid !== uuid
                    ),
                },
            });
            return data;
        })
        .catch();
};

export const CategoryEdit = ({ uuid, path, categoryArea }) => {
    const history = useHistory();
    const page = useQueryString().get("page");
    const area = categoryArea.toLowerCase();

    history.listen((location, action) => {
        // console.log('changed');
    });

    const [tab, setTab] = useState(0);
    const [item, setItem] = useState<{ loaded: boolean; data?: Category }>({
        loaded: false,
        data: null,
    });
    const getItem = () => {
        setTab(0);
        setItem({ loaded: false, data: null });
        axios.get(`/api/v1/admin/${area}-category/${uuid}`).then(({ data }) => {
            setItem({ loaded: true, data: data.data });
        });
    };

    useEffect(getItem, [uuid]);

    const submitForm = (values, setSubmitting) => {
        return axios
            .post(`/api/v1/admin/${area}-category/${uuid}`, values)
            .then(({ data }) => {
                // console.log(data);
                setSubmitting(false);
                setItem({
                    loaded: true,
                    data: data.data,
                });

                //As creation was successful move them on to the edit page
                // history.push(`${path}/edit/${data.uuid}`);

                return data;
            });
    };

    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Edit {categoryArea} Category", {
                            categoryArea,
                        })}{" "}
                        <HeaderEmphasis>
                            {item.data ? item.data.name : ""}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                {item.loaded && !!item.data.parent && (
                    <IconTextButton
                        as={Link}
                        to={`${path}/edit/${item.data.parent.uuid}`}
                        rotation={180}
                        showCircle={false}
                        expanded={false}
                    >
                        {trans("Back to Parent")}
                    </IconTextButton>
                )}
                <IconTextButton
                    as={Link}
                    to={`${path}?page=${page ? page : 1}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to Listing")}
                </IconTextButton>
            </PageTitle>
            {item.loaded ? (
                <Row>
                    <Panel style={{ paddingBottom: "0" }}>
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <CategoryForm
                                    item={item.data}
                                    submitForm={submitForm}
                                    area={area}
                                />
                            </TabordionGroup>
                            {categoryArea !== "Faq" && (
                                <TabordionGroup
                                    heading={
                                        <span>{trans("Sub Categories")}</span>
                                    }
                                    onTabClick={() => setTab(1)}
                                >
                                    <IconTextButton
                                        style={{
                                            margin: "1rem 1rem 2rem 1rem",
                                        }}
                                        as={Link}
                                        to={`${path}/create?parent_id=${item.data.id}`}
                                        rotation={180}
                                        showCircle={false}
                                        expanded={false}
                                        icon={faPlus}
                                    >
                                        {trans("Create new sub category")}
                                    </IconTextButton>
                                    <CategoryTable
                                        items={item.data.children}
                                        loaded={item.loaded}
                                        path={path}
                                        deleteItem={(uuid) =>
                                            deleteItem(uuid, setItem, item)
                                        }
                                    />
                                </TabordionGroup>
                            )}
                        </Tabordion>
                    </Panel>
                </Row>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default CategoryEdit;
