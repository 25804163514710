import React, { Component } from "react";

import AssetCategoriesConfiguration from "./AssetCategoriesConfiguration";
import AssetCategoriesOutput from "./AssetCategoriesOutput";

class AssetCategories extends Component {
    render() {
        if (this.props.configuring) {
            return (
                <AssetCategoriesConfiguration
                    displayType="listing"
                    {...this.props}
                />
            );
        }
        return <AssetCategoriesOutput {...this.props} />;
    }
}

export default AssetCategories;
