import React, { Component } from "react";
import styled from "styled-components";

import moment from "moment";
import {
    Row,
    Column,
    Button,
    IconTextButton,
} from "@cortexglobal/rla-components";
import {
    trans,
    FormattedNumber,
    FormattedCurrency,
} from "@cortexglobal/rla-intl";

const DetailList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-position: outside;
`;

const DetailListItem = styled.li`
    &:not(:last-child) {
        margin-bottom: 1.2rem;
    }
`;

const DetailsLabel = styled.div`
    font-weight: bold;
`;

const DetailsAnswer = styled.div``;

const RequestDetails = ({ request, user }) => {
    return (
        <Row>
            <Column>
                <DetailList>
                    {/* <DetailListItem>
                            <DetailsLabel>{trans("Requested")}</DetailsLabel>
                            <DetailsAnswer>
                                {moment(request.created).format("LL")}
                            </DetailsAnswer>
                        </DetailListItem> */}
                    {request.description && (
                        <DetailListItem>
                            <DetailsLabel>{trans("Description")}</DetailsLabel>
                            <DetailsAnswer>{request.description}</DetailsAnswer>
                        </DetailListItem>
                    )}

                    {user?.currency && request.quotation && (
                        <DetailListItem>
                            <DetailsLabel>{trans("Quotation")}</DetailsLabel>
                            <DetailsAnswer>
                                <FormattedCurrency
                                    value={request?.quotation?.value}
                                    currency={user?.currency?.iso_code || "GBP"}
                                />
                                {/* {request.quotation.value} */}
                            </DetailsAnswer>
                        </DetailListItem>
                    )}

                    {request.custom_form_field_answers.map(
                        (custom_form_field_answer) => {
                            return (
                                <DetailListItem>
                                    <DetailsLabel>
                                        {
                                            custom_form_field_answer
                                                .custom_form_field?.label
                                        }
                                    </DetailsLabel>
                                    {custom_form_field_answer.has_link ? (
                                        <DetailsAnswer
                                            style={{ marginTop: "0.5rem" }}
                                        >
                                            <IconTextButton
                                                type="secondary"
                                                expanded
                                                as={"a"}
                                                href={
                                                    custom_form_field_answer.has_link
                                                }
                                            >
                                                {
                                                    custom_form_field_answer.formatted_answer
                                                }
                                            </IconTextButton>
                                        </DetailsAnswer>
                                    ) : (
                                        <DetailsAnswer>
                                            {Array.isArray(
                                                custom_form_field_answer.formatted_answer
                                            )
                                                ? custom_form_field_answer.formatted_answer.join(
                                                      ", "
                                                  )
                                                : custom_form_field_answer.formatted_answer}
                                        </DetailsAnswer>
                                    )}
                                </DetailListItem>
                            );
                        }
                    )}
                </DetailList>
            </Column>
        </Row>
    );
};

export default RequestDetails;
