import React from "react";
import styled from "styled-components";
//@ts-ignore cortex
import { HelpText, FormLabel, Column } from "@cortexglobal/rla-components";

const RightAlignColumn = styled(Column)`
    text-align: right;
`;
type LabelProps = {
    name: string;
    // label: string | { [key: string]: any };
    // helpText?: string | { [key: string]: any };
    label: any;
    helpText?: any;
    columns?: number;
};
export const LabelColumn = ({
    name,
    label,
    helpText,
    columns = 3,
}: LabelProps) => (
    <RightAlignColumn medium={columns} marginBottom={"0.1rem"}>
        <FormLabel name={name}>{label}</FormLabel>{" "}
        {helpText && <HelpText>{helpText}</HelpText>}
    </RightAlignColumn>
);
