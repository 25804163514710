import React from "react";
import { Line } from "react-chartjs-2";
import { prepareArrayData } from "../formatters";
import { CortexChartArrayProps } from "../types/propTypes";

/**
 * @param {Object.<string, any>} props
 * @param {Object.<string, any>[]} props.data - This is the data for the chart.  It should be an array of objects with a "label" key for the label (or a different key set by the labelKey prop), and a "value" key for the value (or a different key as specified with the valueKey prop)
 */
export const CortexLine = ({
    data,
    labelKey = "label",
    valueKey = "value",
    colors = [],
    ...rest
}: CortexChartArrayProps) => {
    const preparedData = prepareArrayData({
        data,
        labelKey,
        valueKey,
        colors,
    });
    return <Line data={preparedData} {...rest} />;
};
