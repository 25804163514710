import React, { useRef, useState } from "react";
import Loading from "./loaders/Loading";
import styled, { css } from "styled-components";
const Wrapper = styled.div`
    position: relative;
`;
const LoadingWrapper = styled.div`
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
const StyledIframe = styled.iframe`
    border: none;
`;
const Iframe = ({
    src,
    fallbackContent = "<!DOCTYPE html><html><head></head><body><h1>...</h1></body></html>",
    ...rest
}) => {
    const iFrameElement = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const setHeight = (event) => {
        setLoaded(true);
        // console.log(event.target);
        // iFrameElement.current.height =
        //     iFrameElement.current.contentWindow.document.body.scrollHeight +
        //     "px";
    };
    return (
        <Wrapper>
            {!loaded && (
                <LoadingWrapper>
                    <Loading />
                </LoadingWrapper>
            )}
            <StyledIframe
                ref={iFrameElement}
                src={src}
                onLoad={(event) => setHeight(event)}
                // onLoad="this.height=this.contentWindow.document.body.scrollHeight;"
                {...rest}
            />
        </Wrapper>
    );
};

export default Iframe;
