import axios from "axios";
import { saveAs } from "file-saver";
import mimeDb from "mime-db";
import { trans } from "@cortexglobal/rla-intl";
import { useAddToast, useToasts } from "@cortexglobal/rla-components";

export const useDownloadUserAsset = () => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    /**
     *
     * @param {*} uuid
     * @param {*} filename
     * @param {*} downloadType The download type to be used in the api path.  Either 'download', 'download-pdf', 'download-pdf-zip', 'download-image-zip'
     * @param {*} queryString Options to be passed to the api in the query string
     */
    const downloadUserAssetFiles = (
        uuid,
        filename,
        downloadType = "download",
        queryString = ""
    ) => {
        addToast({
            uuid: "downloadToast",
            type: "loader",
            content: trans("Your asset is downloading")
        });
        axios
            .get(
                `/api/v1/user-assets/${downloadType}/${uuid}${
                    queryString !== "" ? `?${queryString}` : ""
                }`,
                {
                    responseType: "blob",
                    timeout: 30000
                }
            )
            .then(response => {
                dispatch({ type: "removeToast", uuid: "downloadToast" });

                //If the download type is just 'download' and it doesn't seem to have
                // an extension, then we don't know what type of
                //file will be returned (e.g. editable indesign would be an .indd
                //originally but would return a .pdf), so we need to setup the
                // filename using the response content-type
                //TODO - Can we check if the returned name has an extension and use that if present?
                if ((downloadType = "download")) {
                    //Extract the extension from the content-type header
                    let extension = "";
                    const contentType = response.headers["content-type"];
                    const mimeInfo = mimeDb[contentType];
                    if (mimeInfo) {
                        extension = mimeInfo.extensions[0];
                        filename = `${filename}${extension && `.${extension}`}`;
                    }
                }
                saveAs(new Blob([response.data]), filename);
            })
            .catch(e => {
                console.log(e);

                dispatch({ type: "removeToast", uuid: "downloadToast" });

                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred downloading your file");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000
                });
            });
    };

    return { downloadUserAssetFiles };
};
