import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled, { withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateBreadcrumbs } from "@cortexglobal/cortex-auth-redux";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Panel,
    Modal,
    Button,
    PageTitle,
    Dashboard,
    DashboardPanel,
    Loading,
    Centred,
    IconTextButton,
    SaveButton,
} from "@cortexglobal/rla-components";

import FeaturedItemCarousel from "./FeaturedItemCarousel";
import panels from "./panels/index";

import AddPanel from "./AddPanel";
import ConfigurePanel from "./ConfigurePanel";
import DeletePanel from "./DeletePanel";
import ManageDashboards from "./ManageDashboards";

import {
    loadDashboard,
    storeDashboard,
    saveDashboardToServer,
    choosePanel,
    addPanel,
    updatePanel,
    deletePanel,
} from "../dashboardActions";

// Resize assist will increase the height of the draggable area when resizing panels
const DashboardResizeAssist = styled.div`
    width: 100%;
    height: ${(props) => (props.isResizing ? props.rowHeight + "px" : "0px")};
    transition: height 250ms ease-in-out 0s;
`;

class DashboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalDashboard: { items: [], layouts: {} },
            savingRoleDashboard: false,
            managing: false,
            addingPanel: false,
            configuringPanel: false,
            deletingPanel: false,
            selectedPanel: null,
            columnCount: 6,
            breakpoint: "xlarge",
            isResizing: false,
        };

        this.props.updateBreadcrumbs(
            this.props.breadcrumbKey ? this.props.breadcrumbKey : []
        );
        this.dashboardEnd = React.createRef();
    }
    componentDidMount() {
        // if (!this.props.dashboard.loaded) {
        this.props.loadDashboard(this.props.slug);
        // }
    }

    componentWillUnmount() {
        if (
            this.props.dashboard.edited &&
            this.props.dashboard.scope === "user"
        ) {
            this.props.saveDashboardToServer(this.props.slug);
        }
    }

    breakPointChange(newBreakpoint, newCols) {
        //console.log(newBreakpoint);
        this.setState({ breakpoint: newBreakpoint, columnCount: newCols });
    }

    updateLayout = (layout, layouts) => {
        //console.log("layout", layout);
        this.props.storeDashboard(this.props.slug, {
            ...this.props.dashboard,
            layouts,
        });
    };

    onResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
        //Get the panel from the items for the extra settings (e.g.)
        const panel = this.props.dashboard.items.filter((item) => {
            return item.key === layoutItem.i;
        })[0];

        if (!panel) {
            return;
        }

        //TODO - Work out how to handle resizing using the info we have...
        if (panel.lockAspectRatio) {
            //console.log(panel, layoutItem, this.state.columnCount);
            //layoutItem.h = layoutItem.w * 4;
        }
    };

    onResizeStart = () => {
        this.setState({ isResizing: true });
        setTimeout(() => {
            window.scrollBy({
                top: this.props.rowHeight / 2,
                behavior: "smooth",
            });
        }, 200);
    };

    onResizeStop = () => {
        this.setState({ isResizing: false });
    };

    browsePanels = () => {
        this.setState((state) => ({
            addingPanel: true,
            selectedPanel: null,
        }));
    };

    addPanel = (panel, panelProps) => {
        //TODO - Add the panel to the dashboard
        //console.log("Adding panel", panel, panelProps);
        this.props.addPanel(
            this.props.slug,
            panel,
            panelProps,
            this.props.cols
        );
        this.setState((state) => ({
            addingPanel: false,
            selectedPanel: null,
        }));
        //TODO - Scroll to the bottom of the dashboard
        setTimeout(() => {
            this.dashboardEnd &&
                this.dashboardEnd.current &&
                this.dashboardEnd.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                });
        }, 1000);
    };

    configurePanel = (panel) => {
        //console.log("Configuring panel", panel);
        this.setState((state) => ({
            configuringPanel: true,
            selectedPanel: panel,
        }));
    };

    updatePanel = (panel, panelProps, panelId) => {
        //TODO - Update the panel via an action
        //console.log("Updating panel", panel, panelProps, panelId);

        this.props.updatePanel(this.props.slug, panel, panelProps, panelId);

        this.setState((state) => ({
            configuringPanel: false,
            selectedPanel: null,
        }));
    };

    deletePanelConfirmation = (panel) => {
        //console.log("Deleting panel", panel);
        this.setState((state) => ({
            deletingPanel: true,
            selectedPanel: panel,
        }));
    };
    deletePanel = (panel) => {
        //console.log("Delete this panel", panel);

        this.props.deletePanel(this.props.slug, panel);
        this.setState((state) => ({
            deletingPanel: false,
            selectedPanel: null,
        }));
    };

    closeModal = () => {
        this.props.choosePanel(this.props.slug, null);
        this.setState((state) => ({
            addingPanel: false,
            configuringPanel: false,
            deletingPanel: false,
            selectedPanel: null,
        }));
    };

    saveRoleDashboardToServer = () => {
        this.setState((state) => ({
            savingRoleDashboard: true,
        }));
        axios
            .put(
                `/api/v1/dashboard/${this.props.slug}/role/${this.props.dashboard?.role?.uuid}`,
                this.props.dashboard
            )
            .then((response) => {
                this.setState((state) => ({
                    savingRoleDashboard: false,
                }));
            });
    };

    getLayout(layouts, breakpoint, panelKey) {
        //console.log("filter", layouts, breakpoint, panelKey);

        //console.log(layouts[breakpoint]);
        if (breakpoint && layouts[breakpoint])
            for (let i = 0; i < layouts[breakpoint].length; i++) {
                if (layouts[breakpoint][i].i === panelKey) {
                    //console.log("layout", layouts[breakpoint][i]);
                    return layouts[breakpoint][i];
                }
            }
    }

    resetDashboard = () => {
        console.log(this.props.dashboard);
        this.props.storeDashboard(
            this.props.slug,
            this.props.dashboard.originalDashboard
        );
    };

    generateComponent = (panel) => {
        const { dashboard, user, customizable } = this.props;

        //console.log("panel", panel);
        //console.log(dashboard.layouts[this.state.breakpoint]);

        //Add a ref to the panel
        const ref = React.createRef();
        return (
            <DashboardPanel
                ref={ref}
                key={panel.key}
                panelkey={panel.key}
                panelProps={{
                    ...panel.props,
                    panelId: panel.key,
                    user: user,
                    layout: this.getLayout(
                        dashboard.layouts,
                        this.state.breakpoint,
                        panel.key
                    ),
                }}
                panelTitle={
                    panel.props && panel.props.title
                        ? panel.props.title
                        : panel.title
                }
                component={panel.component}
                configurable={panel.configurable}
                showHeader={panel.showHeader}
                panels={panels}
                customizable={customizable}
                configurePanel={this.configurePanel}
                deletePanelConfirmation={this.deletePanelConfirmation}
                configureIcon={<FontAwesomeIcon icon="cog" />}
                deleteIcon={<FontAwesomeIcon icon="times" />}
            />
        );
    };

    render() {
        const {
            dashboard,
            theme: { breakpoints, spacing, dashboard: dashboardTheme },
            cols,
            rowHeight,
            showTitle,
            showFeaturedItemCarousel,
            showSlideFor = 7000,
            slug,
            customizable,
            multiple,
            editRoleDashboards,
        } = this.props;

        const { managing } = this.state;
        if (!dashboard.loaded) {
            return (
                <Centred>
                    <Loading height="auto" />
                </Centred>
            );
        }

        return (
            <div>
                {showFeaturedItemCarousel && (
                    <FeaturedItemCarousel
                        perTile={1}
                        showSlideFor={showSlideFor}
                        arrows={false}
                        alias={slug}
                        showEmptyCarousel={true}
                    />
                )}
                {showTitle && (
                    <PageTitle
                        title={this.props.heading}
                        margin={showFeaturedItemCarousel ? 0 : spacing.margin}
                        background={dashboardTheme.pageTitle.background}
                    >
                        {customizable && (
                            <IconTextButton
                                onClick={this.browsePanels}
                                icon={faPlus}
                                showCircle={false}
                            >
                                {trans("Add Panel")}
                            </IconTextButton>
                        )}
                        {multiple && (
                            <ManageDashboards
                                slug={slug}
                                isOpened={managing}
                                dashboard={dashboard}
                                editRoleDashboards={editRoleDashboards}
                            />
                        )}
                    </PageTitle>
                )}
                <Row collapse>
                    <Dashboard
                        layouts={dashboard.layouts}
                        breakpoints={breakpoints}
                        cols={cols}
                        rowHeight={rowHeight}
                        width={1200}
                        isDraggable={customizable}
                        isResizable={customizable}
                        isBounded={true}
                        onLayoutChange={this.updateLayout}
                        onBreakpointChange={(newBreakpoint, newCols) =>
                            this.breakPointChange(newBreakpoint, newCols)
                        }
                        onResize={this.onResize}
                        onResizeStart={this.onResizeStart}
                        onResizeStop={this.onResizeStop}
                        draggableHandle=".dragHandle"
                        margin={[22, 22]}
                        childCount={
                            Array.isArray(dashboard.items)
                                ? dashboard.items.length
                                : 0
                        }
                    >
                        {dashboard.items &&
                            dashboard.items.map((panel) =>
                                this.generateComponent(panel)
                            )}
                    </Dashboard>
                    <DashboardResizeAssist
                        rowHeight={rowHeight}
                        isResizing={this.state.isResizing}
                    />
                </Row>
                <Modal
                    visible={this.state.addingPanel}
                    onClose={this.closeModal}
                    maxWidth="1200px"
                >
                    <AddPanel
                        slug={slug}
                        addPanel={this.addPanel}
                        breakpoint={this.state.breakpoint}
                    />
                </Modal>
                {this.state.selectedPanel && this.state.deletingPanel && (
                    <Modal
                        visible={this.state.deletingPanel}
                        onClose={this.closeModal}
                    >
                        <DeletePanel
                            deletePanel={this.deletePanel}
                            panel={this.state.selectedPanel}
                            panels={panels}
                        />
                    </Modal>
                )}
                {this.state.selectedPanel && this.state.configuringPanel && (
                    <Modal
                        visible={this.state.configuringPanel}
                        onClose={this.closeModal}
                    >
                        <ConfigurePanel
                            updatePanel={this.updatePanel}
                            panel={this.state.selectedPanel}
                            panels={panels}
                            creating={false}
                        />
                    </Modal>
                )}
                {!dashboard?.loading &&
                    editRoleDashboards &&
                    dashboard?.scope === "role" && (
                        <Panel
                            style={{
                                position: "fixed",
                                bottom: "1rem",
                                right: "1rem",
                                padding: "0.8rem",
                                margin: 0,
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ flexGrow: 1 }}>
                                    <h3 style={{ marginBottom: 0 }}>
                                        {trans(
                                            "Editing the {roleName} dashboard",
                                            {
                                                roleName: dashboard.name,
                                            }
                                        )}
                                    </h3>
                                </div>
                                <div>
                                    <IconTextButton
                                        icon={faTimes}
                                        showCircle={false}
                                        expanded={true}
                                        onClick={this.resetDashboard}
                                    >
                                        {trans("Cancel")}
                                    </IconTextButton>
                                </div>
                                <div>
                                    <SaveButton
                                        // icon={faSave}
                                        saving={this.state.savingRoleDashboard}
                                        expanded={true}
                                        onClick={this.saveRoleDashboardToServer}
                                    >
                                        {trans("Save")}
                                    </SaveButton>

                                    {/* <IconTextButton
                                        icon={faSave}
                                        showCircle={false}
                                        expanded={true}
                                        onClick={this.saveRoleDashboardToServer}
                                    >
                                        {trans("Save")}
                                    </IconTextButton> */}
                                </div>
                            </div>
                        </Panel>
                    )}

                <div
                    style={{ clear: "both", display: "flex" }}
                    ref={this.dashboardEnd}
                ></div>
            </div>
        );
    }
}

DashboardContainer.propTypes = {
    cols: PropTypes.shape({
        lg: PropTypes.number,
        md: PropTypes.number,
        sm: PropTypes.number,
        xs: PropTypes.number,
        xxs: PropTypes.number,
    }),
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    slug: PropTypes.string,
    breadcrumbKey: PropTypes.any,
    showTitle: PropTypes.bool,
};

DashboardContainer.defaultProps = {
    rowHeight: 200,
    cols: { xlarge: 6, large: 4, medium: 2, small: 2 },
    slug: "home",
    heading: "Welcome",
    breadcrumbKey: null,
    showTitle: true,
};

const mapStateToProps = (state, props) => {
    return {
        dashboard: state.dashboards[props.slug],
        user: state.auth ? state.auth.user : {},
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            loadDashboard,
            storeDashboard,
            saveDashboardToServer,
            choosePanel,
            addPanel,
            updatePanel,
            deletePanel,
            updateBreadcrumbs,
        },
        dispatch
    );

export default withTheme(
    connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
);
