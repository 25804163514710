import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExpandArrowsAlt,
    faCompressArrowsAlt
} from "@fortawesome/free-solid-svg-icons";

import { trans } from "@cortexglobal/rla-intl";

//import { Link } from "react-router-dom";
import {
    Row,
    Column,
    Table,
    IconTextButton,
    PlainButton,
    PageTitle,
    TranslationButton
} from "@cortexglobal/rla-components";
import ComparePages from "../components/ComparePages";
import {
    TableDataValue,
    ComparisonRow
} from "../components/comparisonComponents";

export const HeaderEmphasis = styled.em`
    font-weight: 100;
    margin-left: 0.3rem;
`;

const CompareAsset = ({ asset, history, assetUuid, versionUuid }) => {
    const [comparisonData, setComparisonData] = useState(null);
    const [showOnlyChanged, setShowOnlyChanged] = useState(true);
    useEffect(() => {
        axios
            .get(`/api/v1/assets/${assetUuid}/compare/${versionUuid}`)
            .then(({ data }) => {
                setComparisonData(data);
            });
    }, [assetUuid, versionUuid]);

    if (!comparisonData) {
        return null;
    }

    const attributes = comparisonData.attributes.filter(({ changed }) =>
        !showOnlyChanged ? true : changed
    );

    // const editable = comparisonData.editable.filter(({ changed }) =>
    //     !showOnlyChanged ? true : changed
    // );
    if (typeof comparisonData === "null") {
        return null;
    }

    const localeAttribute = comparisonData.attributes.filter(
        ({ attribute }) => attribute === "locale"
    );
    const localeObject =
        localeAttribute.length === 1
            ? {
                  this_value: localeAttribute[0].this_value,
                  other_value: localeAttribute[0].other_value
              }
            : { this_value: "", other_value: "" };
    console.log(localeAttribute[0]);
    return (
        <React.Fragment>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Comparing")} <HeaderEmphasis>{}</HeaderEmphasis>
                    </React.Fragment>
                }
            >
                {/* <Row style={{ width: "80%" }}> */}

                <IconTextButton
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    iconBefore={false}
                    icon={
                        showOnlyChanged
                            ? faExpandArrowsAlt
                            : faCompressArrowsAlt
                    }
                    onClick={e => setShowOnlyChanged(!showOnlyChanged)}
                >
                    {showOnlyChanged
                        ? trans("Show all fields")
                        : trans("Show only changed")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Column>
                    <h3>{trans("Attributes")}</h3>
                </Column>
                <Column>
                    <Table width="100%">
                        <thead>
                            <tr>
                                <th>{trans("Attribute")}</th>
                                <th>{trans("Current")}</th>
                                <th>{trans("Version")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attributes.map(
                                ({
                                    attribute,
                                    this_value,
                                    other_value,
                                    changed
                                }) => {
                                    return (
                                        <ComparisonRow
                                            key={attribute}
                                            changed={changed}
                                        >
                                            <td width="20%">{attribute}</td>
                                            <TableDataValue width="40%">
                                                {this_value}
                                            </TableDataValue>
                                            <TableDataValue width="40%">
                                                <TranslationButton
                                                    text={other_value}
                                                    from={
                                                        localeObject.other_value
                                                    }
                                                />
                                            </TableDataValue>
                                        </ComparisonRow>
                                    );
                                }
                            )}
                        </tbody>
                    </Table>
                </Column>
            </Row>
            {comparisonData.editable && (
                <ComparePages
                    pages={comparisonData.editable.pages}
                    showOnlyChanged={showOnlyChanged}
                    localeObject={localeObject}
                />
            )}
        </React.Fragment>
    );
};

export default CompareAsset;
