import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Styled component for the roundel
const Roundel = styled.div`
    background-color: ${({ theme }) =>
        theme.colors.accent}; /* Use theme's accent color */
    color: white; /* White icon */
    border-radius: 50%; /* Round shape */
    position: relative; /* Needed to position the icon inside */
    width: ${({ diameter }) => diameter}px; /* Set width of the roundel */
    height: ${({ diameter }) => diameter}px; /* Set height of the roundel */
    display: inline-flex; /* Aligns the icon inside the circle */
    justify-content: center;
    align-items: flex-end; /* Aligns the icon to the bottom */
    overflow: hidden; /* Prevent overflow */
`;

const StyledIcon = styled(FontAwesomeIcon)`
    width: ${({ diameter }) =>
        diameter *
        0.7}px; /* Adjust width of the icon relative to the roundel */
    height: auto; /* Maintain aspect ratio */
    position: absolute;
    bottom: -1px; /* Align to the bottom */
`;

const UserRoundel = ({ diameter = 50 }) => {
    return (
        <Roundel diameter={diameter}>
            <StyledIcon icon={faUser} diameter={diameter} />
        </Roundel>
    );
};

export default UserRoundel;
