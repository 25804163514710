import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

import styled from "styled-components";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    PageTitle,
    Error,
    SaveButton,
    SubmitButton,
    useAddToast,
    Panel,
    IconTextButton,
    DraftJSOutput,
} from "@cortexglobal/rla-components";

import SurveySubmissionContext from "../SurveySubmissionContext";
import SurveyLoading from "../components/SurveyLoading";
import SubmissionQuestion from "./SubmissionQuestion";
import cloneDeep from "lodash/cloneDeep";
import { ButtonRow } from "../forms/SurveyForm";
import format from "date-fns/format";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
/**
 * Styled Components here
 */
const SurveyButtons = styled.div`
    display: flex;
    gap: 1.2em;
    justify-content: flex-end;
    align-items: center;
`;

const ThankYouMessage = styled.p`
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
`;

const SubMessage = styled.p`
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1rem;
`;

const SubmissionForm = ({ path }) => {
    const addToast = useAddToast();

    const [surveyEntry, setSurveyEntry] = useState({});
    const [surveyAnswers, setSurveyAnswers] = useState([]);
    const [surveyAnswersData, setSurveyAnswersData] = useState([]);
    const { can } = usePermissions();

    const [surveySystem, setSurveySystem] = useState({
        loading: true,
        error: "",
        errorCode: null,
        isSubmitting: false,
        isSubmitted: false,
        unsavedChanges: false,
        disableSave: false,
        questionErrors: {},
    });

    const { alias } = useParams();

    useEffect(() => {
        loadSurvey(alias);
    }, [alias]);

    const {
        loading,
        error,
        errorCode,
        isSubmitting,
        isSubmitted,
        unsavedChanges,
        disableSave,
        questionErrors,
    } = surveySystem;
    /**
     * Loads a single survey and any saved answers
     *
     * @param {*} uuid
     * @returns
     */
    const loadSurvey = (alias) => {
        setSurveySystem({ ...surveySystem, loading: true, error: null });

        if (alias) {
            axios
                .get(`/api/v1/survey/${alias}`)
                .then(({ data }) => {
                    setSurveySystem({
                        ...surveySystem,
                        loading: false,
                        isSubmitted: data.data.submitted_at !== null,
                        error: null,
                    });
                    setSurveyEntry(data.data);
                    setSurveyAnswers(cloneDeep(data.data.answers));
                    setSurveyAnswersData(cloneDeep(data.data.answers));
                })
                .catch((e) => {
                    setSurveySystem({
                        ...surveySystem,
                        loading: false,
                        error:
                            e.response?.data?.message || e.response?.statusText,
                        errorCode: e.response?.status,
                    });
                    setSurveyEntry({});
                });
        } else {
            setSurveySystem({
                ...surveySystem,
                loading: false,
                error: trans("Survey alias missing"),
            });
        }
    };

    /**
     * Deals with the answers changing
     * Name: UUID of the question
     * Value: The answer
     */
    const handleAnswerChange = (name, value) => {
        let updatedSurveyAnswersData = cloneDeep(surveyAnswersData);
        const index = updatedSurveyAnswersData.findIndex(
            (answer) => answer.question_uuid === name
        );

        if (index > -1) {
            updatedSurveyAnswersData[index]["answer"] = value;
        } else {
            updatedSurveyAnswersData.push({
                question_uuid: name,
                answer: value,
            });
        }

        setSurveyAnswersData(updatedSurveyAnswersData);

        //Remove any errors for this question
        const { [name]: removedQuestion, ...newQuestionErrors } =
            questionErrors;

        setSurveySystem({
            ...surveySystem,
            questionErrors: newQuestionErrors,
        });
    };

    /**
     * Saves the answers to the database
     *
     * @param {*} uuid
     * @returns
     */
    const saveProgress = (values) => {
        const alias = surveyEntry.survey?.alias || surveyEntry.survey?.uuid;
        const activeElement = document.activeElement;
        setSurveySystem({
            ...surveySystem,
            disableSave: true,
            error: null,
        });
        axios
            .post(`/api/v1/survey/${alias}`, { submit: false, answers: values })
            .then(({ data }) => {
                setSurveySystem({
                    ...surveySystem,
                    disableSave: false,
                    isSubmitted: data.data.submitted_at !== null,
                    unsavedChanges: false,
                    error: null,
                });
                addToast({
                    type: "success",
                    content: trans("Your response has been saved."),
                    showFor: 5000,
                });
                setSurveyEntry(data.data);
                setSurveyAnswers(cloneDeep(data.data.answers));
                setSurveyAnswersData(cloneDeep(data.data.answers));
                activeElement.focus();
            })
            .catch((e) => {
                setSurveySystem({
                    ...surveySystem,
                    disableSave: false,
                    error: e.response?.statusText,
                });
                addToast({
                    type: "alert",
                    content: e.response?.statusText,
                    showFor: 5000,
                });
                activeElement.focus();
            });
    };

    /**
     * Saves the answers to the database and marks the answers as submitted.
     * The difference to saveProgress is we validate the data first
     *
     * @param {*} uuid
     * @returns
     */
    const submitAnswers = (values) => {
        const alias = surveyEntry.survey?.alias || surveyEntry.survey?.uuid;
        const activeElement = document.activeElement;

        let questionErrors = {};
        let hasQuestionErrors = false;
        surveyEntry.questions?.map((question) => {
            if (question.required) {
                const thisAnswer = values.find((answer) => {
                    return answer.question_uuid == question.uuid;
                });
                if (!thisAnswer || !thisAnswer.answer) {
                    hasQuestionErrors = true;
                    questionErrors[question.uuid] = trans(
                        "This question is required"
                    );
                }
            }
        });

        if (!hasQuestionErrors) {
            setSurveySystem({
                ...surveySystem,
                isSubmitting: true,
                disableSave: true,
                questionErrors: {},
                error: null,
            });
            axios
                .post(`/api/v1/survey/${alias}`, {
                    submit: true,
                    answers: values,
                })
                .then(({ data }) => {
                    setSurveySystem({
                        ...surveySystem,
                        isSubmitting: false,
                        isSubmitted: data.data.submitted_at !== null,
                        disableSave: false,
                        unsavedChanges: false,
                        questionErrors: {},
                        error: null,
                    });
                    addToast({
                        type: "success",
                        content: trans("Submission successful"),
                        showFor: 5000,
                    });
                    setSurveyEntry(data.data);
                    setSurveyAnswers(cloneDeep(data.data.answers));
                    setSurveyAnswersData(cloneDeep(data.data.answers));
                    activeElement.focus();
                })
                .catch((e) => {
                    const message =
                        e.response?.data?.message || trans("Submission failed");

                    setSurveySystem({
                        ...surveySystem,
                        isSubmitting: false,
                        disableSave: false,
                        questionErrors: {},
                        error: message,
                    });
                    addToast({
                        type: "alert",
                        content: message,
                        showFor: 5000,
                    });
                    activeElement.focus();
                });
        } else {
            setSurveySystem({
                ...surveySystem,
                isSubmitting: false,
                disableSave: false,
                questionErrors: questionErrors,
            });
            addToast({
                type: "alert",
                content: trans("Submission failed"),
                showFor: 5000,
            });
            return false;
        }
    };

    const renderButtons = () => (
        <>
            {!error && !loading && isOpen() && (
                <SurveyButtons>
                    <SaveButton
                        onClick={() => saveProgress(surveyAnswersData)}
                        saving={isSubmitting}
                        disabled={disableSave}
                        label={trans("Save")}
                    />
                    {!isSubmitted && (
                        <SubmitButton
                            onClick={() => submitAnswers(surveyAnswersData)}
                            disabled={isSubmitting || disableSave}
                            submitting={isSubmitting}
                            label={trans("Submit answers")}
                            type="primary"
                            icon={
                                unsavedChanges ? (
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />
                                ) : null
                            }
                        />
                    )}
                </SurveyButtons>
            )}
        </>
    );

    if (loading) {
        return <SurveyLoading />;
    }

    const isOpen = () => {
        if (surveyEntry.survey?.is_active && !surveyEntry.completed) {
            return true;
        }

        if (
            surveyEntry.survey?.is_active &&
            surveyEntry.completed &&
            surveyEntry.survey?.submitted_entries_are_editable
        ) {
            return true;
        }

        return false;
    };

    return (
        <SurveySubmissionContext.Provider
            value={{
                surveyEntry,
                surveyAnswers,
                surveyAnswersData,
                surveySystem,
                loadSurvey,
                saveProgress,
                submitAnswers,
                setSurveyAnswersData,
                setSurveySystem,
                handleAnswerChange,
            }}
        >
            <PageTitle
                title={
                    error || loading
                        ? trans("Survey")
                        : surveyEntry.survey?.name
                }
            >
                {/* {renderButtons()} */}
                {can("surveys-view") && (
                    <IconTextButton
                        rotation={180}
                        showCircle={false}
                        as={Link}
                        to={path}
                    >
                        {trans("Back to listing")}
                    </IconTextButton>
                )}
            </PageTitle>
            <Row>
                <Column medium={12}>
                    {error ? (
                        <>
                            {errorCode === 403 && can("guest") ? (
                                <Panel
                                    style={{
                                        paddingTop: "3rem",
                                        textAlign: "center",
                                    }}
                                >
                                    <h2>
                                        {trans(
                                            "This survey is not available unless you are logged in"
                                        )}
                                    </h2>
                                    <p>
                                        {trans(
                                            "Please login, and then revisit this link to continue."
                                        )}
                                    </p>
                                </Panel>
                            ) : (
                                <Error>{error}</Error>
                            )}
                        </>
                    ) : (
                        <Panel style={{ paddingTop: "3rem" }}>
                            {surveyEntry.survey?.description && (
                                <Column
                                    medium={8}
                                    centered={true}
                                    style={{ marginBottom: "3rem" }}
                                >
                                    <DraftJSOutput>
                                        {surveyEntry.survey?.description}
                                    </DraftJSOutput>
                                </Column>
                            )}
                            {isSubmitted && (
                                <>
                                    <ThankYouMessage>
                                        {trans("Thank you for your response.")}
                                    </ThankYouMessage>
                                    {isOpen() && surveyEntry.survey?.closes && (
                                        <SubMessage>
                                            {trans(
                                                "You can change your answers up until {date}.",
                                                {
                                                    date: format(
                                                        new Date(
                                                            surveyEntry.survey?.closes
                                                        ),
                                                        "PP"
                                                    ),
                                                }
                                            )}
                                        </SubMessage>
                                    )}
                                </>
                            )}

                            {surveyEntry.questions?.map((question, index) => {
                                const { isSubmitting, isSubmitted } =
                                    surveySystem;
                                return (
                                    <SubmissionQuestion
                                        readonly={!isOpen()}
                                        question={question}
                                        index={index}
                                    />
                                );
                            })}
                        </Panel>
                    )}
                </Column>
            </Row>
            {!error && (
                <Panel style={{ position: "sticky", bottom: 0, zIndex: 1 }}>
                    <ButtonRow style={{ textAlign: "right" }}>
                        {renderButtons()}
                    </ButtonRow>
                </Panel>
            )}
        </SurveySubmissionContext.Provider>
    );
};

export default SubmissionForm;
