import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { format } from "@cortexglobal/cortex-utilities";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    TableList,
    Error,
    IconTextButton,
    Loading,
    PageTitle,
    Pagination,
    Toggle,
} from "@cortexglobal/rla-components";

import {
    SurveyPanel,
    SurveyButtons,
} from "../components/SurveyFormStyledComponents.js";
import SurveyManagementContext from "../SurveyManagementContext";

const SortableTh = styled.th`
    cursor: pointer;
`;

const SortableTableHeading = ({
    name,
    orderBy,
    selected,
    direction,
    onClick,
    style,
}) => {
    return (
        <SortableTh
            onClick={() => {
                onClick(orderBy);
            }}
            style={style}
        >
            {name}{" "}
            {selected &&
                (direction === "asc" ? (
                    <FontAwesomeIcon icon={faCaretUp} size="lg" />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown} size="lg" />
                ))}
        </SortableTh>
    );
};

const MySurveys = ({ area }) => {
    const [surveys, setSurveys] = useState([]);
    const [surveyData, setSurveyData] = useState({});
    const [showArchived, setShowArchived] = useState(false);
    const [surveySystem, setSurveySystem] = useState({
        loading: true,
        error: "",
        isSubmitting: false,
        unsavedChanges: false,
        disableSave: false,
        disableEdit: false,
    });

    const { loading, error } = surveySystem;

    /**
     * Load all the surveys for the listing page
     *
     * @param {*} page
     * @param {*} perPage
     * @param {*} orderByKey
     * @param {*} orderByDirection
     */
    const loadSurveys = (page, perPage, orderByKey, orderByDirection) => {
        setSurveySystem({ ...surveySystem, loading: true, error: null });

        const preloadedPerPage = surveys.meta?.per_page;
        const preloadedOrderBy = surveys.meta?.order_by;
        const preloadedOrderByDir = surveys.meta?.order_by_direction;

        const params = {
            page: page || 1,
            per_page: perPage || preloadedPerPage || 20,
            order_by: orderByKey || preloadedOrderBy || "id",
            order_by_direction:
                orderByDirection || preloadedOrderByDir || "desc",
            show_archived: showArchived || false,
        };

        axios
            .get(`/api/v1/survey`, { params: params })
            .then(({ data }) => {
                setSurveySystem({
                    ...surveySystem,
                    loading: false,
                    error: null,
                });
                setSurveys(data);
            })
            .catch((e) => {
                setSurveySystem({
                    ...surveySystem,
                    loading: false,
                    error: e.response?.statusText,
                });
            });
    };

    useEffect(() => {
        const page = surveys.meta?.current_page || 1;
        const surveysData = surveys.data;

        if (!Array.isArray(surveysData) || !surveysData.length) {
            loadSurveys(page);
        }
    }, []);

    useEffect(() => {
        const page = surveys.meta?.current_page || 1;
        loadSurveys(page);
    }, [showArchived]);

    const changeOrderBy = (orderBy) => {
        const perPage = surveys.meta?.per_page || 20;
        const direction =
            surveys.meta?.order_by_direction === "asc" ? "desc" : "asc";

        loadSurveys(1, perPage, orderBy, direction);
    };

    const getActions = (survey) => {
        let actions = [];

        const { entry, status_alias } = survey;

        if (status_alias === "active" && !entry?.completed) {
            actions.push({
                name: trans("Respond"),
                url: `/${area}/${survey.uuid}`,
            });
        }

        if (entry?.completed === true) {
            actions.push({
                name: trans("Show"),
                url: `/${area}/${survey.uuid}`,
            });
        }

        return actions;
    };

    const renderTableContent = (surveys) => {
        if (loading) {
            return (
                <tr>
                    <td colSpan="100%">
                        <Loading />
                    </td>
                </tr>
            );
        }

        if (error) {
            return (
                <tr>
                    <td colSpan="100%">
                        <Error>{error}</Error>
                    </td>
                </tr>
            );
        }

        if (!surveys || !Array.isArray(surveys) || !surveys.length) {
            return (
                <tr>
                    <td colSpan="100%">{trans("No surveys available")}</td>
                </tr>
            );
        }

        return (
            <>
                {surveys.map((survey) => (
                    <tr key={survey.uuid}>
                        <td>{survey.id}</td>
                        <td>{survey.name}</td>
                        <td>
                            {survey.publish_at &&
                                format(new Date(survey.publish_at), "Pp")}
                        </td>
                        <td>
                            {survey.archived_at &&
                                format(new Date(survey.archived_at), "Pp")}
                        </td>
                        <td>
                            {survey.entry?.submitted_at &&
                                format(
                                    new Date(survey.entry.submitted_at),
                                    "Pp"
                                )}
                        </td>
                        <td>{survey.status}</td>
                        <td>
                            {getActions(survey).map(({ url, name, icon }) => (
                                <IconTextButton
                                    as={Link}
                                    to={url}
                                    showCircle={false}
                                    icon={icon}
                                >
                                    {name || ""}
                                </IconTextButton>
                            ))}
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    return (
        <SurveyManagementContext.Provider
            value={{
                surveys,
                surveyData,
                surveySystem,
                showArchived,
                loadSurveys,
                setSurveyData,
                setSurveySystem,
                setShowArchived,
            }}
        >
            <PageTitle title={trans("My Form Entries")} />
            <Row>
                <Column medium={12}>
                    <SurveyButtons>
                        <span>{trans("Show closed forms")}</span>
                        <Toggle
                            name="show_archived"
                            onChange={(e) => setShowArchived(!showArchived)}
                            checked={showArchived}
                            size="small"
                        />
                    </SurveyButtons>
                    <SurveyPanel padding="0">
                        <TableList style={{ marginBottom: "0" }}>
                            <thead>
                                <tr>
                                    <SortableTableHeading
                                        name="#"
                                        orderBy="id"
                                        selected={
                                            surveys.meta?.order_by === "id"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                    />
                                    <th>{trans("Title")}</th>
                                    <SortableTableHeading
                                        name={trans("Start Date")}
                                        orderBy="publish_at"
                                        selected={
                                            surveys.meta?.order_by ===
                                            "publish_at"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                    />
                                    <SortableTableHeading
                                        name={trans("End Date")}
                                        orderBy="archived_at"
                                        selected={
                                            surveys.meta?.order_by ===
                                            "archived_at"
                                        }
                                        direction={
                                            surveys.meta?.order_by_direction
                                        }
                                        onClick={changeOrderBy}
                                    />
                                    <th>{trans("Submitted")}</th>
                                    <th>{trans("Status")}</th>
                                    <th>{trans("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>{renderTableContent(surveys.data)}</tbody>
                        </TableList>
                    </SurveyPanel>
                    {!loading && surveys.meta?.current_page !== undefined && (
                        <Pagination
                            onPerPageChange={({ value }) => {
                                loadSurveys(surveys.meta.current_page, value);
                            }}
                            style={{ marginBottom: "1rem" }}
                            currentPage={surveys.meta.current_page - 1}
                            perPage={surveys.meta.per_page}
                            pageCount={surveys.meta.last_page}
                            onPageChange={({ selected }) =>
                                loadSurveys(++selected)
                            }
                            showSummary={true}
                            alwaysShow={true}
                            {...surveys.meta}
                        />
                    )}
                </Column>
            </Row>
        </SurveyManagementContext.Provider>
    );
};

export default MySurveys;
