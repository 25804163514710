import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Icon from "./icon";
// import {
//     ZoomIn,
//     ZoomOut,
//     FadeIn,
//     FadeOut
// } from "animate-css-styled-components";

import { isBrowser } from "@cortexglobal/cortex-utilities";
import CloseButton from "./closeButton";

// Set to animate in / out based on visibility
// let animation = {
//     fade: FadeIn,
//     zoom: ZoomIn
// };

const ModalWrapper = styled.div`
    /* display: ${({ visible }) => (visible ? "auto" : "none")}; */
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    padding: ${(props) =>
        props.fullScreen ? 0 : `0 ${props.theme.modal.padding}rem`};
    background: ${({ visible }) =>
        visible ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0)"};
    backdrop-filter: blur(8px);
    z-index: ${({ zIndex }) => zIndex};
    transition: all 0.5s ease-in-out;
`;
const ModalInner = styled.div`
    /* max-width: ${(props) => (props.fullScreen ? "100%" : props.maxWidth)};
    min-width: ${(props) => (props.fullScreen ? "98%" : props.minWidth)};
    margin: ${(props) => (props.fullScreen ? "2% auto" : "8rem auto")};
    min-height: ${(props) => (props.fullScreen ? "96%" : "auto")}; */
    min-width: ${(props) => (props.fullScreen ? "100%" : props.minWidth)};
    max-width: ${(props) => (props.fullScreen ? "100%" : props.maxWidth)};
    margin: ${(props) => (props.fullScreen ? "0" : "6rem auto")};
    max-height: ${(props) => (props.fullScreen ? "100vh" : "auto")};
    min-height: ${(props) => (props.fullScreen ? "100vh" : "auto")};
    position: relative;
    border-radius: ${(props) => props.theme.modal.radius}em;
    background: ${({ modalBackground }) => modalBackground};
    padding: ${(props) =>
        props.fullScreen ? 0 : `${props.theme.modal.padding}rem`};
    color: ${(props) => props.theme.modal.color};
`;

/** Modal  */
class Modal extends React.Component {
    constructor(props) {
        super(props);
        if (isBrowser()) {
            this.el = document.createElement("div");
        }
    }

    componentDidMount() {
        // init portal element
        if (isBrowser()) {
            try {
                document.getElementsByTagName("BODY")[0].appendChild(this.el);
                if (isBrowser()) {
                    document.documentElement.style.overflow = this.props.visible
                        ? "hidden"
                        : "unset";
                    document.documentElement.style.height = this.props.visible
                        ? "100vh"
                        : "100%";
                }
            } catch (err) {}
        }
    }

    componentWillUnmount() {
        // destroy portal element
        if (isBrowser()) {
            try {
                document.getElementsByTagName("BODY")[0].removeChild(this.el);
                document.documentElement.style.overflow = "auto";
                document.documentElement.style.height = "100%";
            } catch (err) {}
        }
    }

    componentDidUpdate() {
        if (isBrowser()) {
            document.documentElement.style.overflow = this.props.visible
                ? "hidden"
                : "unset";
            document.documentElement.style.height = this.props.visible
                ? "100vh"
                : "100%";
        }
    }

    onClose = () => {
        this.setState({ visible: false });
        this.props.onClose();
    };

    onWrapperClick = () => {
        if (this.props.closeOnWrapperClick === true) {
            this.onClose();
        }
    };

    renderModal = () => {
        const { theme } = this.props;
        const {
            maxWidth,
            zIndex,
            showCloseButton,
            fullScreen,
            minWidth,
            visible,
            modalBackground = theme.modal.background,
        } = this.props;
        return (
            <ModalWrapper
                duration="0.25s"
                zIndex={zIndex}
                onClick={this.onWrapperClick}
                fullScreen={fullScreen}
                visible={visible}
            >
                <ModalInner
                    duration="0.25s"
                    onClick={(e) => e.stopPropagation()}
                    maxWidth={maxWidth}
                    minWidth={minWidth}
                    fullScreen={fullScreen}
                    modalBackground={modalBackground}
                >
                    {showCloseButton && <CloseButton onClick={this.onClose} />}
                    {this.props.children}
                </ModalInner>
            </ModalWrapper>
        );
    };

    render() {
        var { theme, visible, onlyHideWithCss } = this.props;

        if ((visible || onlyHideWithCss) && isBrowser()) {
            return ReactDOM.createPortal(this.renderModal(), this.el);
        } else {
            return null;
        }
    }
}
Modal = withTheme(Modal);

Modal.displayName = "Modal";

Modal.propTypes = {
    /** Boolean indicating whether the modal should show */
    visible: PropTypes.bool.isRequired,
    /** The function to call when the modal is closed */
    onClose: PropTypes.func.isRequired,
    closeOnWrapperClick: PropTypes.bool,
    /** Whether to show the close button */
    showCloseButton: PropTypes.bool,
    maxWidth: PropTypes.string,
    minWidth: PropTypes.string,
    zIndex: PropTypes.number,
    onlyHideWithCss: PropTypes.bool,
};

Modal.defaultProps = {
    visible: false,
    closeOnWrapperClick: true,
    showCloseButton: true,
    maxWidth: "640px",
    minWidth: "0px",
    zIndex: 1000,
    fullScreen: false,
    onlyHideWithCss: false,
};

export default Modal;
