import axios from "axios";

export const DO_TRANSITION = "DO_TRANSITION";
export const DO_TRANSITION_ERROR = "DO_TRANSITION_ERROR";
export function doTransition(smartRef, transitionUuid) {
    return dispatch => {
        axios
            .post(
                "/api/v1/workflow/" + smartRef + "/transition/" + transitionUuid
            )
            .then(({ data }) => {
                dispatch({
                    type: DO_TRANSITION,
                    data: data.data
                });
            })
            .catch(e => {
                dispatch({
                    type: DO_TRANSITION_ERROR,
                    errorMessage: e.message
                });
            });
    };
}
