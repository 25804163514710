import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FolderLine extends React.Component {
    openFolder = item => {
        this.props.openFolder(item);
    };

    updateExpandList = item => {
        this.props.updateExpandList(item);
    };

    isExpanded = uuid => {
        return this.props.expandList.indexOf(uuid) < 0 ? false : true;
    };

    folderIcon = f => {
        if (this.isExpanded(f.uuid)) {
            return <FontAwesomeIcon icon="folder-open" />;
        } else {
            return <FontAwesomeIcon icon="folder" />;
        }
    };
    thisFolder(f) {
        let cf = this.props.currentFolder;
        return cf && f.uuid === cf.uuid ? true : false;
    }

    render() {
        const { folder } = this.props;
        const current = this.thisFolder(folder);

        return (
            <div>
                {folder.hasFolders > 0 && (
                    <a
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={this.updateExpandList.bind(this, folder)}
                    >
                        {this.folderIcon(folder)}
                    </a>
                )}
                {!folder.hasFolders && <FontAwesomeIcon icon="ellipsis-h" />}{" "}
                {!current && (
                    <a
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={this.openFolder.bind(this, folder)}
                    >
                        {folder.name}
                    </a>
                )}
                {current && (
                    <strong
                        style={{
                            color: "#d74540"
                        }}
                    >
                        {folder.name}
                    </strong>
                )}
            </div>
        );
    }
}

export default FolderLine;
