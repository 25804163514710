import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Column, Centred, Loading } from "@cortexglobal/rla-components";
import { CategoryResultItem } from "@cortexglobal/cortex-dam";

import {
    AssetGrid,
    AssetResultWrapper,
} from "../assetComponents/AssetResultItem";
import usePanelData from "../usePanelData";
// import CategoryResultItem from "../assetComponents/CategoryResultItem";

import { CortexBar, CortexPie, CortexLine } from "@cortexglobal/charts";

const DashboardChartsOutput = ({ panelId, chartType = "bar", panelSize }) => {
    return (
        <div
            style={{
                width: "95%",
                height: "95%",
                padding: "10px",
            }}
        >
            {chartType === "bar" && (
                <CortexBar
                    data={[
                        {
                            label: "Usage",
                            data: [
                                { name: "Email", usedCount: 5 },
                                { name: "Events", usedCount: 4 },
                                { name: "Press", usedCount: 6 },
                                { name: "Social", usedCount: 7 },
                                { name: "PPC", usedCount: 8 },
                                { name: "Direct Mail", usedCount: 0 },
                                { name: "Website", usedCount: 3 },
                                { name: "OOH", usedCount: 4 },
                                { name: "Radio", usedCount: 2 },
                                { name: "TV", usedCount: 3 },
                            ],
                        },
                    ]}
                    labelKey="name"
                    valueKey="usedCount"
                    colors={["rgba(33, 62, 143, 0.7)"]}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                    }}
                />
            )}
            {chartType === "line" && (
                <CortexLine
                    data={[
                        {
                            label: "Coverage",
                            data: [
                                { name: "January", usedCount: 5 },
                                { name: "February", usedCount: 4 },
                                { name: "March", usedCount: 6 },
                                { name: "April", usedCount: 7 },
                                { name: "May", usedCount: 8 },
                                { name: "June", usedCount: 0 },
                                { name: "July", usedCount: 3 },
                                { name: "August", usedCount: 4 },
                                { name: "September", usedCount: 2 },
                                { name: "October", usedCount: 3 },
                                { name: "November", usedCount: 3 },
                                { name: "December", usedCount: 3 },
                            ],
                        },
                        {
                            label: "Coverage",
                            data: [
                                { name: "January", usedCount: 1 },
                                { name: "February", usedCount: 1 },
                                { name: "March", usedCount: 2 },
                                { name: "April", usedCount: 3 },
                                { name: "May", usedCount: 4 },
                                { name: "June", usedCount: 5 },
                                { name: "July", usedCount: 5 },
                                { name: "August", usedCount: 5 },
                                { name: "September", usedCount: 8 },
                                { name: "October", usedCount: 11 },
                                { name: "November", usedCount: 11 },
                                { name: "December", usedCount: 15 },
                            ],
                        },
                    ]}
                    labelKey="name"
                    valueKey="usedCount"
                    colors={[
                        "rgba(255, 99, 132, 0.7)",
                        "rgba(53, 162, 235, 0.7)",
                    ]}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                    }}
                />
            )}
            {chartType === "pie" && (
                <CortexPie
                    data={[
                        { name: "London", usedCount: 263828 },
                        { name: "South West", usedCount: 65012 },
                        { name: "South East", usedCount: 105349 },
                        { name: "East", usedCount: 104938 },
                        { name: "West Midlands", usedCount: 79374 },
                        { name: "East Midlands", usedCount: 84728 },
                        { name: "Yorkshire & the Humber", usedCount: 104985 },
                        { name: "North West", usedCount: 40857 },
                        { name: "North East", usedCount: 69873 },
                    ]}
                    labelKey="name"
                    valueKey="usedCount"
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: "right",
                        },
                    }}
                />
            )}
        </div>
    );
};

export default DashboardChartsOutput;
