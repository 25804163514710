import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { EmptyRow } from "../../components/EmptyRow";

const PaddedDiv = styled.div`
    padding: 3em;
`;

const ParentRow = styled(Link)`
    font-size: 0.9em;
    display: block;
`;

export const GroupTable = ({
    items,
    loaded,
    path,
    pagination = null,
    deleteItem,
    onPageChange = (selected) => {},
    onOrderBy,
    onPerPageChange,
}) => {
    const intl = useIntl();
    const currentPage = pagination ? pagination.current_page : 1;

    const [deleteGroupModalShowing, setDeleteGroupModalShowing] =
        useState(false);
    const [deleteGroupName, setDeleteGroupName] = useState("");
    const [deleteGroupId, setDeleteGroupId] = useState("");

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>{trans("Id")}</th>
                        <th>{trans("Name")}</th>
                        <th>{trans("Type")}</th>
                        <th>{trans("Parent")}</th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <EmptyRow>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow>{trans("No items found")}</EmptyRow>
                    ) : (
                        items.map((item) => {
                            return (
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.groupType
                                            ? item.groupType.text
                                            : ""}
                                    </td>
                                    <td>
                                        {item.parents.map((parent) => {
                                            return (
                                                <ParentRow
                                                    to={`${path}/edit/${
                                                        parent.uuid
                                                    }?page=${
                                                        currentPage
                                                            ? currentPage
                                                            : 1
                                                    }`}
                                                >
                                                    {parent.name}
                                                </ParentRow>
                                            );
                                        })}
                                    </td>
                                    <td>
                                        <IconTextButton
                                            as={Link}
                                            to={`${path}/edit/${item.uuid}?page=${currentPage}`}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEdit}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            type="alert"
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                            onClick={(event) => {
                                                alert(
                                                    intl.formatMessage({
                                                        id: "Please contact support or raise a ticket to delete this group",
                                                    })
                                                );
                                                //TODO - Look at full group deletion capability
                                                // setDeleteGroupId(item.uuid);
                                                // setDeleteGroupModalShowing(
                                                //     true
                                                // );
                                                // setDeleteGroupName(item.name);
                                            }}
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {pagination && (
                <Pagination
                    onPerPageChange={onPerPageChange}
                    style={{ clear: "both" }}
                    currentPage={pagination.current_page - 1}
                    perPage={pagination.per_page}
                    pageCount={pagination.last_page}
                    onPageChange={({ selected }) => onPageChange(selected + 1)}
                    {...pagination}
                />
            )}
            {deleteGroupModalShowing && (
                <Modal
                    visible={deleteGroupModalShowing}
                    onClose={() => {
                        setDeleteGroupModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h1>You are about delete page: {deleteGroupName}</h1>

                        <p>Are you sure you want to proceed?</p>
                        <Button
                            type="hollow"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeleteGroupModalShowing(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                deleteItem(deleteGroupId);
                            }}
                        >
                            Delete
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default GroupTable;
