import React from "react";
import {
    TextareaField,
    MultiCheckbox,
    CheckboxTree,
    Select,
    SelectField,
    RadioField,
    StyledCheckbox,
    Range,
    InputField,
    CurrencyInput,
    Toggle,
    ColorField,
    TypeAhead,
    StyledDropzone,
    FormLabel,
} from "../../index";
import DatePicker from "./standardReturnElements/DatePicker";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import InputError from "./styledElements/inputError";

import { useGroups } from "@cortexglobal/cortex-auth-redux";

const Label = ({ children }) => {
    return (
        <FormLabel name="text-label" style={{ marginBottom: "1rem" }}>
            {children}
        </FormLabel>
    );
};

/**
 * Recursively disable options based on the user's groups
 * @param {*} options
 * @param {*} groups
 * @returns
 */
const disableOptions = (options, groups) => {
    return options.map((option) => {
        if (option.children) {
            option.children = disableOptions(option.children, groups);
        }

        if (option.value) {
            option.disabled = !groups.some(
                (group) => group.alias === option.value
            );
        }

        return option;
    });
};

/**
 *
 * @param {object} input Contains the configuration info for this input
 * @param {*} value The current value of the input
 * @param {function} onUpdate The function to be called when the input is updated
 * @param {function} onFileDrop The function to be called when a file is dropped
 * @param {integer} limit The number of items initially shown on inputs with "options"
 * @param {string} scope The place where the input is being used (e.g. 'admin', 'filters' etc.).  This is added in the "extra" section of the backend configuration
 * @returns
 */
export const renderStandReturnInput = (
    input,
    value,
    onUpdate,
    onFileDrop,
    limit = 100,
    scope = ""
) => {
    // console.log(input, scope, input?.extra?.[scope]?.selectParents);
    // console.log("single select", input?.extra?.[scope]?.singleSelect);
    // console.log("select parents", input?.extra?.[scope]?.selectParents);
    // console.log(
    //     "select only leaves",
    //     input?.extra?.[scope]?.selectOnlyLeafNodes
    // );
    const { groups } = useGroups();

    const intl = useIntl();

    switch (input.type) {
        case "Textarea":
            return (
                <TextareaField
                    {...input}
                    id={input.id}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    onChange={onUpdate}
                    placeholder={input.placeholder}
                />
            );
        case "TermsAndConditions":
            const terms = input.extra
                .map((extra) => {
                    if (extra.terms) {
                        return extra.terms;
                    }
                })
                .filter(Boolean);
            return (
                <>
                    <StyledCheckbox
                        {...input}
                        text={intl.formatMessage({ id: "Accept" })}
                        style={{ marginTop: "10px" }}
                        name={input.name}
                        checked={value ? value : false}
                        onChange={(event) => {
                            onUpdate({
                                name: input.name,
                                value: event.target.checked,
                            });
                        }}
                    />
                    {terms.length > 0 && (
                        <>
                            <h3>{trans("Terms")}</h3>
                            <TextareaField
                                {...input}
                                id={input.id}
                                name={input.name}
                                value={terms}
                                label={input.hideLabel ? null : input.text}
                                readOnly={true}
                                formLabel={input.text}
                                labelStyle={{ marginBottom: "1rem" }}
                                placeholder={input.placeholder}
                            />
                        </>
                    )}
                </>
            );
        case "MultiCheckBox":
            return (
                <MultiCheckbox
                    {...input}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    options={input.options}
                    onChange={onUpdate}
                    defaultColor="accent"
                    activeColor="primary"
                    marginBottom={0.5}
                    limit={limit}
                />
            );
        case "CheckboxTree":
            return (
                <CheckboxTree
                    // tabordionMode={true}
                    allowMultiple={
                        scope && input?.extra?.[scope]?.singleSelect
                            ? false
                            : true
                    }
                    selectParents={
                        scope && input?.extra?.[scope]?.selectParents
                            ? input?.extra?.[scope]?.selectParents
                            : false
                    }
                    selectOnlyLeafNodes={
                        scope && input?.extra?.[scope]?.selectOnlyLeafNodes
                            ? input?.extra?.[scope]?.selectOnlyLeafNodes
                            : false
                    }
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    options={input.options}
                    onChange={onUpdate}
                    defaultColor="accent"
                    activeColor="primary"
                    marginBottom={0.5}
                    {...input}
                />
            );
        case "UserGroupCheckboxTree":
            //Disable any options that are not in the user's groups
            const options = disableOptions(input.options, groups);

            return (
                <CheckboxTree
                    // tabordionMode={true}
                    allowMultiple={
                        scope && input?.extra?.[scope]?.singleSelect
                            ? false
                            : true
                    }
                    selectParents={
                        scope && input?.extra?.[scope]?.selectParents
                            ? input?.extra?.[scope]?.selectParents
                            : false
                    }
                    selectOnlyLeafNodes={
                        scope && input?.extra?.[scope]?.selectOnlyLeafNodes
                            ? input?.extra?.[scope]?.selectOnlyLeafNodes
                            : false
                    }
                    selectChildren={true}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    options={options}
                    onChange={onUpdate}
                    defaultColor="accent"
                    activeColor="primary"
                    marginBottom={0.5}
                    {...input}
                />
            );
        case "MultiSelect":
            return (
                <Select
                    {...input}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    options={input.options}
                    onChange={onUpdate}
                    multi={true}
                />
            );
        case "Select":
            return (
                <SelectField
                    {...input}
                    style={{ height: "40px" }}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    options={input.options}
                    onChange={onUpdate}
                />
            );
        case "RadioGroup":
            return (
                <RadioField
                    {...input}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    options={input.options}
                    onChange={onUpdate}
                />
            );
        case "TypeAheadSelect":
            return (
                <TypeAhead
                    {...input}
                    name={input.name}
                    value={value}
                    url={input.api_link}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    options={input.options}
                    onChange={onUpdate}
                />
            );
        case "Range":
            return (
                <Range
                    {...input}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    onChange={onUpdate}
                />
            );
        case "Currency":
            return (
                <CurrencyInput
                    id={input.id}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    onChange={onUpdate}
                    placeholder={input.placeholder}
                />
            );
        case "Number":
            return (
                <InputField
                    {...input}
                    id={input.id}
                    type="number"
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    onChange={onUpdate}
                />
            );
        case "Color":
            return (
                <ColorField
                    id={input.id}
                    type="number"
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    onChange={onUpdate}
                />
            );

        case "Toggle":
            return (
                <Toggle
                    style={{ marginBottom: "1.2rem" }}
                    name={input.name}
                    checked={value}
                    label={input.hideLabel ? null : input.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    onChange={(event) => {
                        onUpdate({
                            name: input.name,
                            value: event.target.checked,
                        });
                    }}
                />
            );
        case "Date":
            const update = ({ value: newValue }) => {
                //If the newValue is a Date object, convert it to ISOString
                if (newValue instanceof Date) {
                    newValue = newValue.toISOString();
                }
                onUpdate({ name: input.name, value: newValue });
            };

            return (
                <DatePicker
                    style={{ marginBottom: "1.2rem" }}
                    name={input.name}
                    value={value ? value : null}
                    label={input.hideLabel ? null : option.text}
                    labelStyle={{ marginBottom: "1rem" }}
                    onChange={(event) => update(event)}
                />
            );
        case "DateRange":
            let tempValue = Array.isArray(value)
                ? value
                : [new Date(), new Date()];

            const updateValues = ({ value: newValue }, index) => {
                tempValue[index] = newValue;
                onUpdate({ name: input.name, value: tempValue });
            };
            return (
                <>
                    {input.options.map((option, index) => {
                        return (
                            <DatePicker
                                key={index}
                                style={{ marginBottom: "1.2rem" }}
                                name={input.name}
                                value={
                                    tempValue[index]
                                        ? new Date(tempValue[index])
                                        : new Date()
                                }
                                label={input.hideLabel ? null : option.text}
                                labelStyle={{ marginBottom: "1rem" }}
                                onChange={(event) => updateValues(event, index)}
                            />
                        );
                    })}
                </>
            );
        case "Dropzone":
            if (value) {
                if (value instanceof FileList) {
                    value = Array.from(value);
                } else if (Array.isArray(value)) {
                    value = value.reduce(
                        (previous, file) =>
                            (previous ? previous + ", " : "") +
                            (file instanceof File ? file.name : file),
                        ""
                    );
                } else {
                    //If the value is anything else we need to blank it otherwise it causes
                    //an error when trying to render the value (there was an issue with the
                    //value being a SynthenticEvent, at least momentarily)
                    value = "";
                }
            }
            return (
                <>
                    {!input.hideLabel && <Label>{input.text}</Label>}
                    {value && <p>{value}</p>}
                    <StyledDropzone
                        showFiles={true}
                        name={input.name}
                        onDrop={(files) => {
                            onFileDrop({ name: input.name, files: files });
                        }}
                        type="default"
                        style={{
                            ...(!!input.error ? { borderColor: "red" } : {}),
                            textAlign: "center",
                            marginBottom: "1.2rem",
                        }}
                    >
                        <span>
                            <small>
                                {trans("Drop files here, or click to browse")}
                            </small>
                        </span>
                    </StyledDropzone>
                    {!!input.error && <InputError error={input.error} />}
                </>
            );
        case "File":
            if (value) {
                if (value instanceof FileList) {
                    value = Array.from(value);
                }

                if (Array.isArray(value)) {
                    value = value.reduce(
                        (previous, file) =>
                            (previous ? previous + ", " : "") +
                            (file instanceof File ? file.name : file),
                        ""
                    );
                }
            }
            return (
                <>
                    {!input.hideLabel && <Label>{input.text}</Label>}
                    {value && <p>{value}</p>}
                    <InputField
                        {...input}
                        id={input.id}
                        name={input.name}
                        onChange={(data, event) =>
                            onFileDrop({
                                name: data.name,
                                files: event.target.files,
                            })
                        }
                        type="file"
                        style={{
                            ...input.style,
                            paddingTop: "5px",
                        }}
                    />
                </>
            );
        default:
            return (
                <InputField
                    {...input}
                    id={input.id}
                    name={input.name}
                    value={value}
                    label={input.hideLabel ? null : input.text}
                    formLabel={input.text}
                    placeholder={input.placeholder}
                    onChange={onUpdate}
                />
            );
    }
};
