import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Modal,
    Loading,
    IconTextButton,
    PlainButton,
    Dropdown,
    useAddToast,
    useToasts,
} from "@cortexglobal/rla-components";

import AddPanel from "./AddPanel";

import {
    swapDashboard,
    storeDashboard,
    saveDashboardToServer,
} from "../dashboardActions";
import CreateNewDashboard from "./CreateNewDashboard";

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: flex;
        align-items: space-between;
    }
`;
const SelectButtonWrapper = styled.span`
    flex-grow: 1;
`;
const SelectButton = styled(PlainButton)`
    width: 100%;
    padding: 0.2rem 0;
    font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
    color: ${({ selected, theme }) =>
        selected ? theme.button.hoverColor : "inheritx"};
    &:hover {
        color: ${({ theme }) => theme.button.hoverColor};
    }
`;
const DeleteButton = styled(PlainButton)`
    &:hover {
        color: ${({ theme }) => theme.button.hoverColor};
    }
`;

const emptyDashboard = {
    items: [],
    layouts: {},
};
const ManageDashboards = ({
    slug,
    isOpened,
    dashboard,
    editRoleDashboards,
}) => {
    const dispatch = useDispatch();
    const [dashboards, setDashboards] = useState({
        loaded: false,
        data: [],
    });
    const [creatingNew, setCreatingNew] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownButton = useRef();
    const addToast = useAddToast();
    const [_, toastDispatch] = useToasts();

    useEffect(() => {
        loadDashboards();
    }, []);

    // useEffect(() => {
    //     if (dashboards.loaded) {
    //         console.log("dashboards loaded", dashboards.data);
    //         setDashboards({
    //             loaded: true,
    //             data: dashboards.data.map((currentDashboard) => {
    //                 if (currentDashboard.uuid === dashboard.uuid) {
    //                     return dashboard;
    //                 }
    //                 return currentDashboard;
    //             }),
    //         });
    //     }
    // }, [dashboard]);
    const loadDashboards = () => {
        return axios.get(`/api/v1/dashboard/${slug}`).then(({ data }) => {
            setDashboards({ loaded: true, data: data.data });
        });
    };
    const saveDashboardToServer = (name, startingPoint) => {
        //Set dashboard starting point
        let initialDashboard =
            startingPoint === "current" ? dashboard : emptyDashboard;
        initialDashboard.name = name;
        return axios
            .post(`api/v1/user-dashboard/${slug}`, initialDashboard)
            .then(({ data }) => {
                //Set as current dashboard using swapDashboard action
                addToast({
                    type: "success",
                    content: trans("Dashboard saved"),
                    showFor: 3000,
                });
                selectAndStoreDashboard(slug, {
                    ...data.data,
                    //Having to handle empty objects being returned as arrays by the backend
                    layouts:
                        startingPoint === "current" ? data.data.layouts : {},
                });
                setCreatingNew(false);
                loadDashboards();

                return data.data;
            });
    };

    const deleteDashboard = (selectedDashboard) => {
        //Make sure this isn't the last User dashboard.
        const userDashboards = dashboards?.data.filter(
            (tempDashboard) => tempDashboard.scope === "user"
        );

        if (!userDashboards || userDashboards.length === 1) {
            addToast({
                type: "alert",
                content: trans(
                    "This is your last dashboard, please create another before removing"
                ),
                showFor: 5000,
            });
            return;
        }

        setShowDropdown(false);
        addToast({
            uuid: "deleteToast",
            type: "loader",
            content: trans("Deleting Dashboard"),
        });
        //Optimistically remove the dashboard
        setDashboards({
            loaded: true,
            data: dashboards.data.filter(
                (tempDashboard) => tempDashboard.uuid !== selectedDashboard.uuid
            ),
        });
        return axios
            .delete(`api/v1/user-dashboard/${selectedDashboard.uuid}`)
            .then(({ data }) => {
                //If this was the current dashboard select another to use
                //as current dashboard using swapDashboard action
                if (selectedDashboard.uuid === dashboard.uuid) {
                    //Find an available user dashboard
                    selectAndStoreDashboard(userDashboards[0], slug);
                }
                loadDashboards();
                return data.data;
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans("Error deleting dashboard"),
                    showFor: 5000,
                });
            })
            .finally(() => {
                toastDispatch({ type: "removeToast", uuid: "deleteToast" });
            });
    };

    const selectDashboard = (slug, dashboard) => {
        dispatch(swapDashboard(slug, dashboard));
    };

    const selectAndStoreDashboard = (slug, dashboard) => {
        dispatch(storeDashboard(slug, dashboard));
    };

    return (
        <div style={{ position: "relative" }}>
            <IconTextButton
                ref={dropdownButton}
                onClick={() => setShowDropdown(!showDropdown)}
                icon={faTh}
                showCircle={false}
            >
                {trans("Manage Dashboards")}
            </IconTextButton>
            <Dropdown
                visible={showDropdown}
                hideDropdown={() => setShowDropdown(false)}
                toggleRef={dropdownButton.current}
                padding={0}
            >
                {dashboards.loaded && !dashboard.loading ? (
                    <Row
                        style={{
                            paddingTop: "1.2rem",
                            paddingBottom: "0.8rem",
                        }}
                    >
                        {editRoleDashboards && (
                            <Column
                                style={{
                                    paddingBottom: "1rem",
                                }}
                            >
                                <h4
                                    style={{
                                        marginBottom: "0.4rem",
                                    }}
                                >
                                    {trans("Shared Dashboards")}
                                </h4>
                                <List>
                                    {dashboards.data
                                        .filter(
                                            (dashboardItem) =>
                                                dashboardItem.scope === "role"
                                        )
                                        .map((dashboardItem) => {
                                            return (
                                                <li key={dashboardItem.name}>
                                                    <SelectButton
                                                        onClick={() =>
                                                            selectDashboard(
                                                                slug,
                                                                dashboardItem
                                                            )
                                                        }
                                                        expanded={true}
                                                    >
                                                        {dashboardItem.name
                                                            ? dashboardItem.name
                                                            : "N/A"}
                                                    </SelectButton>
                                                </li>
                                            );
                                        })}
                                </List>
                            </Column>
                        )}
                        <Column>
                            <h4
                                style={{
                                    marginBottom: "0.4rem",
                                }}
                            >
                                {trans("Your Dashboards")}
                            </h4>
                            <List>
                                {dashboards.data
                                    .filter(
                                        (dashboardItem) =>
                                            dashboardItem.scope === "user"
                                    )
                                    .map((dashboardItem) => {
                                        return (
                                            <li key={dashboardItem.uuid}>
                                                <SelectButtonWrapper>
                                                    <SelectButton
                                                        selected={
                                                            dashboardItem.uuid ===
                                                            dashboard.uuid
                                                        }
                                                        onClick={() =>
                                                            selectDashboard(
                                                                slug,
                                                                dashboardItem
                                                            )
                                                        }
                                                    >
                                                        {dashboardItem.name
                                                            ? dashboardItem.name
                                                            : trans("Unnamed")}
                                                    </SelectButton>
                                                </SelectButtonWrapper>
                                                <DeleteButton
                                                    onClick={() =>
                                                        deleteDashboard(
                                                            dashboardItem
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </DeleteButton>
                                            </li>
                                        );
                                    })}
                            </List>
                            <IconTextButton
                                icon={faPlus}
                                showCircle={false}
                                padding="0.2rem 0"
                                onClick={() => setCreatingNew(true)}
                            >
                                {trans("Create new")}
                            </IconTextButton>
                        </Column>
                    </Row>
                ) : (
                    <Loading />
                )}
            </Dropdown>
            <Modal visible={creatingNew} onClose={() => setCreatingNew(false)}>
                <CreateNewDashboard
                    dashboard={dashboard}
                    saveDashboardToServer={saveDashboardToServer}
                />
            </Modal>
        </div>
    );
};

export default ManageDashboards;
