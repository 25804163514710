import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { trans } from "@cortexglobal/rla-intl";
import {
    Column,
    IconTextButton,
    PageTitle,
    Panel,
    Row,
    Table,
    Tabordion,
    Loading,
} from "@cortexglobal/rla-components";
import { format } from "@cortexglobal/cortex-utilities";
import BookingsListing from "./BookingsListing";

const ViewInventoryItem = ({ path, uuid, settings }) => {
    const [item, setItem] = useState({});
    const [loadingState, setLoadingState] = useState("loading");

    const getInventoryItem = (uuid) => {
        setLoadingState("loading");

        axios
            .get(`/api/v1/events/inventory/${uuid}`)
            .then(({ data }) => {
                const item = data.data;
                setItem(item);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });
    };

    useEffect(() => getInventoryItem(uuid), [uuid]);
    console.log({ settings });
    if (loadingState === "loading") {
        return <Loading />;
    }
    let bookingType = null;
    if (settings?.bookingTypes && item?.current_booking?.booking_type) {
        bookingType =
            settings?.bookingTypes[item?.current_booking?.booking_type];
    }
    return (
        <>
            <PageTitle title={item?.name}>
                <IconTextButton
                    onClick={() => {}}
                    as={Link}
                    to={`${path}`}
                    showCircle={false}
                    icon={faChevronLeft}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <Panel>
                    <Tabordion current={0}>
                        <div heading={trans("Summary")}>
                            <Row>
                                <Column medium={6}>
                                    <h3>{trans("General Details")}</h3>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <th width="20%">
                                                    {trans("Category")}
                                                </th>
                                                <td>{item?.category?.name}</td>
                                            </tr>
                                            <tr>
                                                <th width="20%">
                                                    {trans("Description")}
                                                </th>
                                                <td>{item?.description}</td>
                                            </tr>
                                            <tr>
                                                <th width="20%">
                                                    {trans("Cost")}
                                                </th>
                                                <td>{item?.cost?.formatted}</td>
                                            </tr>
                                            <tr>
                                                <th width="20%">
                                                    {trans("Cost Basis")}
                                                </th>
                                                <td>{item?.cost_basis_text}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Column>
                                <Column medium={6}>
                                    <h3>{trans("Current Booking")}</h3>
                                    {item?.current_booking ? (
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <th width="20%">
                                                        {trans("Dates")}
                                                    </th>
                                                    <td>
                                                        {format(
                                                            new Date(
                                                                item?.current_booking.start_date
                                                            )
                                                        )}
                                                        {" - "}
                                                        {format(
                                                            new Date(
                                                                item?.current_booking.end_date
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">
                                                        {trans("Booking Type")}
                                                    </th>
                                                    <td>
                                                        {bookingType?.text
                                                            ? bookingType.text
                                                            : "-"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">
                                                        {trans("Booked By")}
                                                    </th>
                                                    <td>
                                                        {item?.current_booking
                                                            ?.created_by_group
                                                            ?.name || "-"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <p>
                                            {trans(
                                                "This item isn't currently booked"
                                            )}
                                        </p>
                                    )}
                                </Column>
                            </Row>
                        </div>
                        <div heading={trans("Future Bookings")}>
                            <BookingsListing
                                bookings={item.future_bookings}
                                settings={settings}
                            />
                        </div>
                        <div heading={trans("Past Bookings")}>
                            <BookingsListing
                                bookings={item.past_bookings}
                                settings={settings}
                                showReturnCondition={true}
                            />
                        </div>
                    </Tabordion>
                </Panel>
            </Row>
        </>
    );
};

export default ViewInventoryItem;
