import React from "react";
import { CSVLink } from "react-csv";

export const CsvDownload = ({
    data = [],
    headers = null,
    filename = "export.csv",
    children,
    ...rest
}) => {
    return (
        <CSVLink data={data} headers={headers} filename={filename} {...rest}>
            {children ? children : "Download"}
        </CSVLink>
    );
};

export default CsvDownload;
