import React, { useState } from "react";
import styled from "styled-components";
import { Row, Column, IconTextButton } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Entry = styled.div`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 0.6rem;
`;

export const Heading = styled.h4`
    font-size: 0.8rem;
`;

const AutofillFieldsTable = ({ listing, type = ["text"] }) => {
    const [copied, setCopied] = useState("");

    if (!listing) {
        return "none";
    }

    return (
        <Row collapse style={{ marginTop: "-2rem" }}>
            <Column>
                <Heading>{trans("Available Tags")}</Heading>
            </Column>
            {listing
                .filter(line => line.type.includes(type))
                .map((line, index) => {
                    return (
                        <Column key={line.value} medium={4}>
                            <CopyToClipboard
                                text={line.value}
                                onCopy={() => {
                                    setCopied(line.value);
                                }}
                            >
                                <Entry>
                                    {copied === line.value ? (
                                        <FontAwesomeIcon icon={faCheck} />
                                    ) : (
                                        <FontAwesomeIcon icon={faCopy} />
                                    )}{" "}
                                    {line.text}
                                </Entry>
                            </CopyToClipboard>
                        </Column>
                    );
                })}
        </Row>
    );
};

export default AutofillFieldsTable;
