import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, Row, Column, TextareaField, SaveButton } from "@cortexglobal/rla-components";
import { trans } from"@cortexglobal/rla-intl";

import { setFollowedUp } from "./commsActions";
import { hideModal } from "../../../dashboardActions";

class SetFollowedStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ""
        };
    }

    onChange = input => {
        this.setState({ [input.name]: input.value });
    };

    submitForm = e => {
        e.preventDefault();
        this.setState({ saving: true });
        this.props.setFollowedUp(
            this.props.communication,
            this.props.communication.followed === null ? true : false,
            this.state.message
        );
        //TODO - Remove the code below once updates are handled properly as explained in the 'setFollowedUp' action creator
        this.props.updateStatus(
            this.props.communication.followed === null ? true : false,
            this.props.communicationIndex
        );
    };

    render() {
        console.log("Modal", this.props);
        const { communication } = this.props;
        return (
            <Modal
                visible={this.props.modalName === "setFollowedStatus"}
                onClose={this.props.hideModal}
            >
                <Row>
                    <h2 className="modalTitle">
                        {communication.followed
                            ? trans("Remove followed up status")
                            : trans("Set as followed up")}
                    </h2>
                </Row>

                <div>
                    <form onSubmit={this.submitForm}>
                        {!communication.followed && (
                            <fieldset>
                                <Row>
                                    <Column>
                                        <TextareaField
                                            name="message"
                                            onChange={this.onChange}
                                        />
                                    </Column>
                                </Row>
                            </fieldset>
                        )}
                        <Row>
                            <Column medium={4}>
                                <SaveButton
                                    color="primary"
                                    disabled={this.state.startDate === ""}
                                    label={trans("Save")}
                                />
                            </Column>
                        </Row>
                    </form>
                </div>
            </Modal>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setFollowedUp,
            hideModal
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const { modalName } = state.modal;
    return { modalName };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    SetFollowedStatus
);
