import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";
import { IconTextButton, PageTitle } from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { useQueryString } from "@cortexglobal/cortex-utilities";

import SurveyForm from "../forms/SurveyForm";
import SurveyManagementContext from "../SurveyManagementContext";

const CreateSurvey = ({ path, loadSurvey }) => {
    const { can } = usePermissions();

    const [surveyData, setSurveyData] = useState({});

    const { surveySystem, setSurveySystem } = useContext(
        SurveyManagementContext
    );

    const [cloneSurveyName, setCloneSurveyName] = useState("");

    //Get the clone uuid from the query string
    const cloneUuid = useQueryString().get("clone");

    // Set the survey system state to be the initial state unless a clone uuid is present
    // in which case we will load the survey data from the clone uuid
    useEffect(() => {
        if (cloneUuid) {
            loadSurvey(cloneUuid).then((survey) => {
                setCloneSurveyName(survey.name);

                //remove the uuid from the clone so it's treated as a new survey
                delete survey.uuid;

                //remove the other unwanted info from the clone (generally not needed but cleaner)
                //TODO: Decide if we would be better off just using a new survey object and copying the required fields
                delete survey.id;
                delete survey.status_alias;
                delete survey.status;
                delete survey.created_at;
                delete survey.updated_at;
                delete survey.created_by_group;
                delete survey.created_by_user;
                delete survey.results;
                delete survey.smartRef;
                delete survey.survey_url;
                delete survey.total_entries;
                delete survey.total_finished_entries;
                delete survey.total_unfinished_entries;
                delete survey.is_active;

                //Add 'COPY' to the name
                survey.name = `${survey.name} (COPY)`;

                setSurveyData(survey);
            });
        } else {
            setSurveySystem({
                ...surveySystem,
                loading: false,
                loaded: true,
                error: null,
                disableEdit: false,
                hasEntries: false,
            });
        }
    }, [cloneUuid]);

    return (
        <>
            <PageTitle
                title={
                    cloneSurveyName
                        ? trans("Creating new clone of: {cloneSurveyName}", {
                              cloneSurveyName,
                          })
                        : trans("Create")
                }
            >
                {can("surveys-manage-list") && (
                    <IconTextButton
                        as={Link}
                        to={path}
                        rotation={180}
                        showCircle={false}
                    >
                        {trans("Back to Listing")}
                    </IconTextButton>
                )}
            </PageTitle>
            <SurveyForm
                path={path}
                surveyData={surveyData}
                setSurveyData={setSurveyData}
            />
        </>
    );
};

export default CreateSurvey;
