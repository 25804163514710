import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
// import styled from "styled-components";
import {
    Column,
    FormikCheckbox,
    FormikAdvancedSelect,
    Row,
    SubmitButton,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    canHaveBudget: Yup.boolean(),
    canManuallyOverrideBudget: Yup.boolean(),
    canUpdateImmediateChildBudget: Yup.boolean(),
    canUpdateAllChildBudgets: Yup.boolean(),
    limitBudget: Yup.array().of(Yup.string()),
});
export type BudgetContributor = ReturnType<typeof schema.validateSync>;

export const BudgetForm = ({ item, submitForm }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios
            .get(`/api/v1/categories?area=planner&optionList=true`)
            .then(({ data }) => {
                // console.log(data);
                setCategories(data.data);
            })
            .catch();
    }, []);

    const handleSubmit = (
        values: BudgetContributor,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting, "budget").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                canHaveBudget: item ? true : false,
                canManuallyOverrideBudget: item
                    ? item.can_update_own_budget
                    : false,
                canUpdateImmediateChildBudget: item
                    ? item.can_update_child_budgets
                    : false,
                canUpdateAllChildBudgets: item
                    ? item.can_update_all_child_budgets
                    : false,
                limitBudget: item ? item.limit_budget_to_category_ids : [],
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, errors }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="canHaveBudget"
                            label={trans("Can have budget?")}
                            helpText={trans("Optional")}
                            error={errors.canHaveBudget}
                        >
                            <FormikCheckbox
                                name="canHaveBudget"
                                value={values.canHaveBudget}
                            />{" "}
                        </FormRow>
                        {!!values.canHaveBudget && (
                            <>
                                <FormRow
                                    name="canManuallyOverrideBudget"
                                    label={trans(
                                        "Can manually override budget?"
                                    )}
                                    helpText={trans("Optional")}
                                    error={errors.canHaveBudget}
                                >
                                    <FormikCheckbox
                                        name="canManuallyOverrideBudget"
                                        value={values.canManuallyOverrideBudget}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="canUpdateImmediateChildBudget"
                                    label={trans(
                                        "Can update immediate child budget?"
                                    )}
                                    helpText={trans("Optional")}
                                    error={errors.canUpdateImmediateChildBudget}
                                >
                                    <FormikCheckbox
                                        name="canUpdateImmediateChildBudget"
                                        value={
                                            values.canUpdateImmediateChildBudget
                                        }
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="canUpdateAllChildBudgets"
                                    label={trans(
                                        "Can update all child budgets?"
                                    )}
                                    helpText={trans("Optional")}
                                    error={errors.canUpdateAllChildBudgets}
                                >
                                    <FormikCheckbox
                                        name="canUpdateAllChildBudgets"
                                        value={values.canUpdateAllChildBudgets}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="limitBudget"
                                    label={trans(
                                        "Limit budget to specific categories"
                                    )}
                                    helpText={trans("Optional")}
                                    error={errors.limitBudget}
                                >
                                    <FormikAdvancedSelect
                                        name="limitBudget"
                                        value={values.limitBudget}
                                        options={categories}
                                        multi={true}
                                    />{" "}
                                </FormRow>
                            </>
                        )}
                        <Row>
                            <Column medium={6}>&nbsp;</Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default BudgetForm;
