import React, { useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    FormikAdvancedSelect,
    Loading,
    Row,
    Column,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    parent_ids: Yup.array().nullable(),
    child_ids: Yup.array().nullable(),
});
export type GroupRelations = ReturnType<typeof schema.validateSync>;

export const GroupRelations = ({ item, submitForm }) => {
    const { data: group, loaded } = item;

    const [groups, setGroups] = useState({ loaded: false, data: [] });
    useEffect(() => {
        axios.get(`/api/v1/admin/group?optionList=true`).then(({ data }) => {
            setGroups({
                loaded: true,
                data: data.data.filter(
                    (groupOption) => groupOption.value !== group.id
                ),
            });
        });
    }, []);
    const handleSubmit = (
        values: GroupRelations,
        { setErrors, setSubmitting, ...rest }
    ) => {
        submitForm(values, setSubmitting, "group-relations").catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    if (!groups.loaded || !loaded) {
        return <Loading />;
    }

    return (
        <Formik
            initialValues={{
                parent_ids: group.parent_ids ? group.parent_ids : [],
                child_ids: group.child_ids ? group.child_ids : [],
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <FormRow
                            name="parent_ids"
                            label={trans("Parent Groups")}
                        >
                            <FormikAdvancedSelect
                                name="parent_ids"
                                value={values.parent_ids}
                                options={groups.data.filter(
                                    (groupOption) =>
                                        values.child_ids.indexOf(
                                            groupOption.value
                                        ) === -1
                                )}
                                showError={false}
                                multi={true}
                            />{" "}
                        </FormRow>
                        <FormRow name="child_ids" label={trans("Child Groups")}>
                            <FormikAdvancedSelect
                                name="child_ids"
                                value={values.child_ids}
                                options={groups.data.filter(
                                    (groupOption) =>
                                        values.parent_ids.indexOf(
                                            groupOption.value
                                        ) === -1
                                )}
                                showError={false}
                                multi={true}
                            />{" "}
                        </FormRow>
                        <Row>
                            <Column medium={6}>&nbsp;</Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default GroupRelations;
