import React, { useState, useContext, useEffect } from "react";
import { useHistory, useRouteMatch, Link, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import {
    Loading,
    Row,
    Column,
    SubmitButton,
    PageTitle,
    IconTextButton,
    useAddToast,
    useToasts,
    Tabordion,
    Panel,
    Button,
    HeaderWithErrorIndicated,
    useSectionErrors,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import {
    useAssetAdmin,
    UPDATE_ASSET_STATE,
    LOADED_DATA,
    SAVING_DATA,
    FINISH_SAVING_DATA,
} from "../hooks/useAssetAdmin";
import NamingAndGeneralSetup from "../forms/NamingAndGeneralSetup";
import Availability from "../forms/Availability";
import MetaDetails from "../forms/MetaDetails";
import FolderAdmin from "../forms/FolderAdmin";
import CategoryStructure from "../forms/CategoryStructure";
import Editor from "../forms/Editor";
import LinkSettings from "../forms/LinkSettings";
import ManageFiles from "../forms/ManageFiles";
import SiteSettingsEditor from "../forms/SiteSettingsEditor";
import SiteThemeEditor from "../forms/SiteThemeEditor";
import ManageRelatedAssets from "../forms/ManageRelatedAssets";
import AssetContext from "../AssetContext";
import VersionOptions from "../components/VersionOptions";
import VideoEditor from "../forms/VideoEditor";
import VideoEditorSettings from "../forms/VideoEditorSettings";
import OrderOptions from "../forms/OrderOptions";

export const HeaderEmphasis = styled.em`
    font-weight: 300;
    margin-left: 0.3rem;
`;

export const VersionNumber = styled.span`
    font-weight: 80;
    margin-left: 0.3rem;
    font-size: 0.9rem;
`;
export const ButtonRow = styled(Row)`
    width: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

const CenterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 600px;
`;

export const confirmHappyToLeave = (assetEdited, files_ready, event = null) => {
    return assetEdited || !files_ready
        ? confirm(
              assetEdited
                  ? "Are you sure you want to leave as your changes will be lost?"
                  : "Are you sure you want to leave as your files haven't been generated? Cancel this and press the Save Button to generate your files"
          )
            ? event
            : event === null
            ? false
            : event.preventDefault()
        : event;
};
const AdminAsset = ({
    assetUuid,
    assetsPath,
    filters,
    searchableMeta,
    groups,
    setRefreshSearch,
    campaignsPath = "/admin/campaigns",
}) => {
    const [errors, setErrors] = useState({});
    // const [sectionErrors, setSectionErrors] = useState([]);
    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        general: ["name", "library_area", "asset_type", "media_type", "locale"],
        meta: ["media-channel", "therapy-type"],
        categories: ["category-hierarchy"],
        availability: [
            "view_type",
            "available_from",
            "available_to",
            "terms",
            "certification_terms",
            "certification_ref",
            "certification_awarded_date",
            "expiration_date",
        ],
    });

    let history = useHistory();
    let match = useRouteMatch(`${assetsPath}/:assetUuid/admin/:step`);
    const addToast = useAddToast();
    const intl = useIntl();
    const [_, toastDispatch] = useToasts();

    const { can } = usePermissions();

    const [assetAdmin, dispatch] = useAssetAdmin(assetUuid, assetsPath);
    const [availableFilters, setAvailableFilters] = useState({
        loaded: false,
        data: [],
    });

    const { getAssetAutofillFields, autofill, config, libraryArea } =
        useContext(AssetContext);

    useEffect(() => {
        if (!autofill.loaded) {
            getAssetAutofillFields();
        }
    }, [autofill]);

    useEffect(() => {
        // if (availableFilters.loaded) {
        getFilters(assetAdmin?.asset?.library_area || libraryArea);
        // }
    }, [assetAdmin?.asset?.library_area, libraryArea]);

    const getFilters = (filterConfig) => {
        axios
            .get("/api/v1/assets/filters", {
                params: { filterConfig: filterConfig },
            })
            .then(({ data }) => {
                setAvailableFilters({
                    loaded: true,
                    data,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        e.response && e.response.data
                            ? e.response.data.message
                            : trans("An error has occurred"),
                    showFor: 5000,
                });
            });
    };

    const standardOnChange = ({ name, value }) => {
        dispatch({ type: UPDATE_ASSET_STATE, name, value });
        //Remove the field from the errors if it's there
        let { [name]: oldValue, ...newErrors } = errors;
        setErrors(newErrors);
        updateSectionErrors(newErrors);
    };

    const updateAsset = (asset) => {
        dispatch({
            type: LOADED_DATA,
            data: asset,
            assetsPath,
            fullAdmin: true,
        });
    };
    const saveUpdatedAsset = (event, previewOnly = false, rethrow = false) => {
        event.preventDefault();
        const generatingFiles = !assetAdmin.asset.files_ready && !previewOnly;
        if (generatingFiles) {
            addToast({
                uuid: "saveToast",
                type: "loader",
                content: trans("Saving your asset and generating files"),
            });
        }
        dispatch({
            type: SAVING_DATA,
        });
        return axios
            .put(`/api/v1/admin/assets/${assetUuid}`, {
                ...assetAdmin.asset,
                previewOnly,
            })
            .then(({ data: { data } }) => {
                if (generatingFiles) {
                    toastDispatch({ type: "removeToast", uuid: "saveToast" });
                }
                addToast({
                    type: "success",
                    content: trans("Asset saved"),
                    showFor: 5000,
                });

                dispatch({
                    type: LOADED_DATA,
                    data,
                    assetsPath,
                    fullAdmin: true,
                });

                setRefreshSearch(true);

                setErrors({});
                updateSectionErrors({});

                if (data.isVersioned && data.isLive) {
                    history.push(`${assetsPath}/view/${assetUuid}`);
                }

                return data;
            })
            .catch((e) => {
                if (e.response && e.response.data) {
                    if (e.response.status === 422) {
                        setErrors(e.response.data.errors);
                        const errorTabs = updateSectionErrors(
                            e.response.data.errors
                        );

                        const errorTab = tabs.findIndex((tab) => {
                            return errorTabs.includes(tab.alias);
                        });
                        history.push(
                            `${assetsPath}/${assetUuid}/admin/${
                                errorTab && tabs[errorTab]
                                    ? tabs[errorTab].alias
                                    : ""
                            }`
                        );
                    }
                    addToast({
                        type: "alert",
                        content:
                            e.response && e.response.data
                                ? e.response.data.message
                                : trans("An error has occurred"),
                        showFor: 5000,
                    });
                }

                dispatch({
                    type: FINISH_SAVING_DATA,
                });
                if (generatingFiles) {
                    toastDispatch({
                        type: "removeToast",
                        uuid: "saveToast",
                    });
                }
                if (rethrow) {
                    throw e;
                }
            });
    };

    const disableSaveButton = () => {
        const { asset, assetDeclarations, assetSaving } = assetAdmin;
        let status = asset.status;
        if (assetSaving) {
            return true;
        }

        if (
            assetDeclarations[status] &&
            assetDeclarations[status].mandatoryCheck
        ) {
            return (
                !(asset.terms && asset.terms[0] === "accepted") ||
                (asset.certification_terms &&
                    asset.certification_terms[0] === "accepted")
            );
        }

        return false;
    };

    const generateTabs = (assetAdmin, saveUpdatedAsset) => {
        const { asset } = assetAdmin;
        const showFolderAdmin =
            typeof config.use_folder_browser !== "undefined" &&
            config.use_folder_browser !== "never";

        const assetType =
            typeof asset.asset_type === "string"
                ? asset.asset_type
                : asset?.asset_type?.slug;

        let tabs = [
            {
                alias: "general",
                component: (
                    <NamingAndGeneralSetup
                        key="general"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Overview")}
                                section="general"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(`${assetsPath}/${asset.uuid}/admin`);
                        }}
                        assetAdmin={assetAdmin}
                        config={config}
                        libraryArea={libraryArea}
                        asset={assetAdmin.asset}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        errors={errors}
                        campaignsPath={campaignsPath}
                    />
                ),
            },
        ];

        tabs.push({
            alias: "files",
            component: (
                <ManageFiles
                    key="files"
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Files")}
                            section="files"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => {
                        history.push(`${assetsPath}/${asset.uuid}/admin/files`);
                    }}
                    assetAdmin={assetAdmin}
                    asset={assetAdmin.asset}
                    baseAsset={assetAdmin.asset}
                    standardOnChange={standardOnChange}
                    updateAsset={updateAsset}
                    errors={errors}
                />
            ),
        });
        if (asset.media_type_orderable) {
            tabs.push({
                alias: "output-options",
                component: (
                    <OrderOptions
                        key="output-options"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Output Options")}
                                section="output-options"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/output-options`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        editRouteMatch={match}
                        errors={errors}
                        saveUpdatedAsset={saveUpdatedAsset}
                        autofill={autofill.data}
                        config={config}
                    />
                ),
            });
        }
        if (assetType === "editableVideo") {
            tabs.push({
                alias: "templates",
                component: (
                    <VideoEditorSettings
                        key="templates"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Editor Settings")}
                                section="templates"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/templates`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        editRouteMatch={match}
                        errors={errors}
                        saveUpdatedAsset={saveUpdatedAsset}
                        autofill={autofill.data}
                        config={config}
                    />
                ),
            });
            tabs.push({
                alias: "editor",
                component: (
                    <VideoEditor
                        key="editor"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Editor")}
                                section="editor"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/editor`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        assetsPath={assetsPath}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        editRouteMatch={match}
                        errors={errors}
                        saveUpdatedAsset={saveUpdatedAsset}
                        autofill={autofill.data}
                        config={config}
                    />
                ),
            });
        }
        if (assetType === "link") {
            tabs.push({
                alias: "link-settings",
                component: (
                    <LinkSettings
                        key="link-settings"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Link Settings")}
                                section="link-settings"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/link-settings`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        errors={errors}
                    />
                ),
            });
        }
        if (asset.microsite) {
            tabs.push({
                alias: "site-settings",
                component: (
                    <SiteSettingsEditor
                        key="site-settings"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Global Settings")}
                                section="site-settings"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/site-settings`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        errors={errors}
                    />
                ),
            });
        }
        if (asset.microsite && can("dam-microsite-admin")) {
            tabs.push({
                alias: "site-theming",
                component: (
                    <SiteThemeEditor
                        key="site-theming"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Theming")}
                                section="site-theming"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/site-theming`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        errors={errors}
                        theming={true}
                    />
                ),
            });
        }
        if (asset.editable && asset.microsite) {
            tabs.push({
                alias: "editor",
                component: (
                    <Editor
                        key="editor"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Editor")}
                                section="editor"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/editor`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        baseAsset={assetAdmin.asset}
                        asset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        editRouteMatch={match}
                        errors={errors}
                        saveUpdatedAsset={saveUpdatedAsset}
                        autofill={autofill.data}
                    />
                ),
            });
        }

        if (
            asset.editable &&
            !asset.microsite &&
            assetType !== "editableVideo"
        ) {
            tabs.push({
                alias: "editor",
                component: (
                    <Editor
                        key="editor"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Editor")}
                                section="editor"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/editor`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        baseAsset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        fullAdmin={true}
                        editRouteMatch={match}
                        errors={errors}
                        saveUpdatedAsset={saveUpdatedAsset}
                        autofill={autofill.data}
                        assetsPath={assetsPath}
                        config={config}
                    />
                ),
            });
        }

        // if (asset.microsite) {
        //     tabs.push({
        //         alias: "navigation",
        //         component: (
        //             <NavigationEditor
        //                 heading={
        //                     <HeaderWithErrorIndicated
        //                         title={trans("Navigation")}
        //                         section="navigation"
        //                         sectionErrors={sectionErrors}
        //                     />
        //                 }
        //                 onTabClick={() => {
        //                     history.push(
        //                         `${assetsPath}/${asset.uuid}/admin/navigation`
        //                     );
        //                 }}
        //                 assetAdmin={assetAdmin}
        //                 asset={assetAdmin.asset}
        //                 standardOnChange={standardOnChange}
        //                 errors={errors}
        //             />
        //         )
        //     });
        // }

        //Add in the meta section
        tabs.push({
            alias: "meta",
            component: (
                <MetaDetails
                    key="meta"
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Meta Data")}
                            section="meta"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => {
                        history.push(`${assetsPath}/${asset.uuid}/admin/meta`);
                    }}
                    assetAdmin={assetAdmin}
                    asset={assetAdmin.asset}
                    standardOnChange={standardOnChange}
                    filters={availableFilters}
                    searchableMeta={searchableMeta}
                    errors={errors}
                />
            ),
        });
        //Add in the folder section
        if (showFolderAdmin) {
            tabs.push({
                alias: "folder",
                component: (
                    <FolderAdmin
                        key="folder"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Folder")}
                                section="folder"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/folder`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        errors={errors}
                    />
                ),
            });
        }

        //Add in the categories section
        //but only if it's an available option
        const assetHierarchyAvailable =
            filters.loaded &&
            filters.data.findIndex(
                (filter) => filter.name === "category-hierarchy"
            ) !== -1;
        assetHierarchyAvailable &&
            tabs.push({
                alias: "categories",
                component: (
                    <CategoryStructure
                        key="categories"
                        heading={
                            <HeaderWithErrorIndicated
                                title={trans("Categories")}
                                section="categories"
                                sectionErrors={sectionErrors}
                            />
                        }
                        onTabClick={() => {
                            history.push(
                                `${assetsPath}/${asset.uuid}/admin/categories`
                            );
                        }}
                        assetAdmin={assetAdmin}
                        asset={assetAdmin.asset}
                        standardOnChange={standardOnChange}
                        filters={filters}
                        errors={errors}
                    />
                ),
            });

        //Add the related assets section
        tabs.push({
            alias: "related",
            component: (
                <ManageRelatedAssets
                    key="related"
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Related Assets")}
                            section="related"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => {
                        history.push(
                            `${assetsPath}/${asset.uuid}/admin/related`
                        );
                    }}
                    assetAdmin={assetAdmin}
                    asset={assetAdmin.asset}
                    standardOnChange={standardOnChange}
                    filters={filters}
                    errors={errors}
                />
            ),
        });

        //Finalise tabs with the availability last
        tabs.push({
            alias: "availability",
            component: (
                <Availability
                    key="availability"
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Availability")}
                            section="availability"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => {
                        history.push(
                            `${assetsPath}/${asset.uuid}/admin/availability`
                        );
                    }}
                    assetAdmin={assetAdmin}
                    asset={assetAdmin.asset}
                    standardOnChange={standardOnChange}
                    groups={groups}
                    errors={errors}
                />
            ),
        });

        return tabs;
    };

    if (!assetAdmin.assetLoaded) {
        return (
            <div style={{ padding: "2rem" }}>
                <Loading align="center" padding="1rem" />
            </div>
        );
    }

    const tabs = generateTabs(assetAdmin, saveUpdatedAsset);
    //Work out what step we're on based on the path
    const tabIndex = match
        ? tabs.findIndex((tab) => tab.alias === match.params.step)
        : -1;
    const currentStep = tabIndex !== -1 ? tabIndex : 0;
    // console.log("sectionErrors", sectionErrors);

    const { page } = useParams();

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {process.env.REACT_APP_NAME} -{" "}
                    {intl.formatMessage({ id: "Editing" })}
                    {assetAdmin.asset.name ? `- ${assetAdmin.asset.name}` : ""}
                </title>
            </Helmet>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("Editing")}
                        <HeaderEmphasis>
                            {assetAdmin.asset.name}{" "}
                            {assetAdmin.asset.isVersioned && (
                                <VersionNumber>{`(v${assetAdmin.asset.versionNumber})`}</VersionNumber>
                            )}
                        </HeaderEmphasis>
                    </React.Fragment>
                }
            >
                {assetAdmin.asset.isVersioned && (
                    <VersionOptions asset={assetAdmin.asset} />
                )}
            </PageTitle>
            <Row style={{ position: "relative" }}>
                <Column>
                    <Tabordion
                        current={currentStep}
                        style={{ background: "none" }}
                    >
                        {tabs.map((tab) => tab.component)}
                    </Tabordion>
                </Column>
            </Row>
            <Panel style={{ position: "sticky", bottom: 0 }}>
                <ButtonRow style={{ textAlign: "right" }}>
                    {assetAdmin.assetEdited || !assetAdmin.asset.files_ready ? (
                        <Button
                            as={Link}
                            to={`${assetsPath}/view/${assetUuid}`}
                            type={"alert"}
                            onClick={(e) =>
                                confirmHappyToLeave(
                                    assetAdmin.assetEdited,
                                    assetAdmin.asset.files_ready,
                                    e
                                )
                            }
                        >
                            {trans("Cancel")}
                        </Button>
                    ) : (
                        <IconTextButton
                            as={Link}
                            to={`${assetsPath}/view/${assetUuid}`}
                            rotation={180}
                            showCircle={false}
                            expanded={false}
                        >
                            {trans("Back to asset")}
                        </IconTextButton>
                    )}
                    <SubmitButton
                        style={{ marginLeft: "1rem" }}
                        disabled={disableSaveButton()}
                        onClick={saveUpdatedAsset}
                        submitting={assetAdmin.assetSaving}
                    >
                        {trans("Save")}
                    </SubmitButton>
                    {assetAdmin.asset.campaign_uuid && (
                        <SubmitButton
                            style={{ marginLeft: "1rem" }}
                            disabled={disableSaveButton()}
                            onClick={(event) =>
                                saveUpdatedAsset(event, true, true)
                                    .then((data) => {
                                        history.push(
                                            `${campaignsPath}/view/${assetAdmin.asset.campaign_uuid}`
                                        );
                                    })
                                    .catch((e) => {})
                            }
                            submitting={assetAdmin.assetSaving}
                        >
                            {trans("Save and go to Campaign")}
                        </SubmitButton>
                    )}
                    <SubmitButton
                        style={{ marginLeft: "1rem" }}
                        disabled={disableSaveButton()}
                        onClick={(event) =>
                            saveUpdatedAsset(event, false, true)
                                .then((data) => {
                                    history.push(
                                        `${assetsPath}/view/${assetUuid}`
                                    );
                                })
                                .catch((e) => {})
                        }
                        submitting={assetAdmin.assetSaving}
                    >
                        {trans("Save and Finish")}
                    </SubmitButton>
                    {assetAdmin.asset.microsite &&
                        assetAdmin?.asset?.configuration?.pages &&
                        !!Object.keys(assetAdmin.asset.configuration.pages)
                            .length && (
                            <SubmitButton
                                style={{ marginLeft: "1rem" }}
                                disabled={disableSaveButton()}
                                onClick={(event) =>
                                    saveUpdatedAsset(event).then((data) => {
                                        const win = window.open(
                                            `/presentations/${assetUuid}/${
                                                page ? page : ""
                                            }`,
                                            "_blank"
                                        );
                                        if (win) {
                                            win.focus();
                                        }
                                    })
                                }
                                submitting={assetAdmin.assetSaving}
                            >
                                {trans("Save and Preview")}
                            </SubmitButton>
                        )}
                </ButtonRow>
            </Panel>
        </React.Fragment>
    );
};

export default AdminAsset;
