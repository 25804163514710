import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import {
    Row,
    Column,
    Button,
    InputField,
    Select,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const DashboardChartsConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    chartType,
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Dashboard Charts" }),
        chartType: chartType ? chartType : "bar",
    });

    const onChange = (input) => {
        setState({ ...state, [input.name]: input.value });
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder=""
                        onChange={onChange}
                    />
                    <Select
                        label={trans("Chart Type")}
                        name="chartType"
                        options={[
                            { value: "bar", text: "Bar" },
                            { value: "line", text: "Line" },
                            { value: "pie", text: "Pie" },
                        ]}
                        value={state.chartType}
                        onChange={onChange}
                    />
                    <Button type="submit">
                        {trans("Save Panel")}
                    </Button>
                </form>
            </Column>
        </Row>
    );
};

DashboardChartsConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default DashboardChartsConfiguration;
