import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";

import {
    FormRow,
    InputField,
    DatePicker,
    TextareaField,
} from "@cortexglobal/rla-components";

export const GeneralDetailsSection = ({ campaign, onChange, errors }) => {
    return (
        <>
            <FormRow
                name={"name"}
                label={trans("Name")}
                helpText={trans("Required")}
                required={true}
                error={errors.name}
            >
                <InputField
                    name="name"
                    value={campaign.name || ""}
                    onChange={onChange}
                    showError={false}
                />
            </FormRow>
            <FormRow
                name={"description"}
                label={trans("Description")}
                helpText={trans("Optional")}
                error={errors.description}
            >
                <TextareaField
                    name="description"
                    value={campaign.description || ""}
                    onChange={onChange}
                    showError={false}
                />
            </FormRow>
            <FormRow
                name={"start_date"}
                label={trans("Start Date")}
                helpText={trans("Required")}
                required={true}
                error={errors.start_date}
            >
                <DatePicker
                    name="start_date"
                    value={campaign.start_date || ""}
                    onChange={onChange}
                    showError={false}
                />
            </FormRow>
            <FormRow
                name={"end_date"}
                label={trans("End Date")}
                helpText={trans("Required")}
                required={true}
                error={errors.end_date}
            >
                <DatePicker
                    name="end_date"
                    value={campaign.end_date || ""}
                    onChange={onChange}
                    showError={false}
                />
            </FormRow>
        </>
    );
};

export default GeneralDetailsSection;
