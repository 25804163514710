import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { trans, useIntl } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    SimpleDropzone,
    Button,
    IconTextButton,
    Modal,
    useAddToast,
    Loading,
    Image,
} from "@cortexglobal/rla-components";
import { AssetImageResult } from "./AssetImageResult";
import { Uploader } from "./Uploader";

export const UserAssetImageBrowser = ({
    id,
    name,
    value,
    onDrop,
    onChange,
    asset,
    width,
    height,
}) => {
    const [userAssetFiles, setUserAssetFiles] = useState({
        loaded: false,
        data: [],
    });
    const intl = useIntl();

    const addToast = useAddToast();

    const getUserAssetFiles = () => {
        return axios
            .get(
                `/api/v1/user-asset-file?width=${
                    isNaN(width) ? 0 : width
                }&height=${isNaN(height) ? 0 : height}`
            )
            .then(({ data }) => {
                setUserAssetFiles({ loaded: true, data: data.data });
            });
    };

    useEffect(() => {
        getUserAssetFiles();
    }, [name]);

    const deleteUserAssetFile = (userAssetFileUuid) => {
        confirm(
            intl.formatMessage({
                id: "Are you sure you want to delete this file, other assets may be making use of it?",
            })
        ) &&
            axios
                .delete(`/api/v1/user-asset-file/${userAssetFileUuid}`)
                .then(() => {
                    getUserAssetFiles();
                });
        return false;
    };
    return (
        <React.Fragment>
            <Row>
                <h2>{trans("Choose one of your images")}</h2>
            </Row>
            <Row equaliseChildHeight={true}>
                <Column medium={3}>
                    <Uploader
                        onDrop={(acceptedFiles) =>
                            onDrop(
                                acceptedFiles,
                                "user-asset-file",
                                getUserAssetFiles
                            )
                        }
                    >
                        <div>
                            <FontAwesomeIcon icon={faPlus} size="4x" />
                        </div>
                        <p>
                            {trans(
                                // "Upload a new image more than {width}px wide and {height}px high",
                                // { width, height }
                                "Upload a new image"
                            )}
                        </p>
                    </Uploader>
                </Column>
                {userAssetFiles.loaded ? (
                    userAssetFiles.data.map((file) => {
                        if (!file.file) {
                            return null;
                        }
                        return (
                            <AssetImageResult
                                selected={file.file === value}
                                key={file.uuid}
                                file={file}
                                onChange={onChange}
                                onRemove={
                                    file.file === value
                                        ? null
                                        : deleteUserAssetFile
                                }
                            />
                        );
                    })
                ) : (
                    <Column medium={3}>
                        <Loading />
                    </Column>
                )}
            </Row>
        </React.Fragment>
    );
};

export default UserAssetImageBrowser;
