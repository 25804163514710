import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";

import NewsContext from "./NewsContext";
import NewsList from "./NewsList";
import NewsDetail from "./NewsDetail";
import NewsCreate from "./NewsCreate";
import NewsEdit from "./NewsEdit";

import { useNews } from "../../hooks/useNews";
type NewsRouterProps = {};
export const ContentNewsAdmin = ({}: NewsRouterProps) => {
    const { path } = useRouteMatch();
    const { news, dispatch, getNewsSummaries } = useNews();

    return (
        <NewsContext.Provider value={{ path, news, getNewsSummaries }}>
            <Switch>
                <Route
                    exact
                    path={`${path}`}
                    render={() => {
                        return (
                            <NewsList
                                path={path}
                                getNewsSummaries={getNewsSummaries}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${path}/create`}
                    render={props => {
                        return <NewsCreate />;
                    }}
                />
                <Route
                    exact
                    path={`${path}/:uuid`}
                    render={props => {
                        const {
                            match: {
                                params: { uuid }
                            }
                        } = props;
                        return <NewsDetail uuid={uuid} />;
                    }}
                />
                <Route
                    exact
                    path={`${path}/:uuid/edit`}
                    render={props => {
                        const {
                            match: {
                                params: { uuid }
                            }
                        } = props;
                        return <NewsEdit uuid={uuid} />;
                    }}
                />
            </Switch>
        </NewsContext.Provider>
    );
};

export default ContentNewsAdmin;
