import React, { Component } from "react";
import { trans } from "@cortexglobal/rla-intl";
import { v4 as uuid } from "uuid";

import {
    Row,
    InputField,
    Column,
    IconTextButton,
    StyledDropzone,
    Loading,
    Panel,
} from "@cortexglobal/rla-components";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import GalleryItemFileDetails from "./GalleryItemFileDetails";

class GalleryItemForm extends Component {
    constructor(props) {
        super(props);
        let folders = [];
        if (props.data.uuid) {
            folders = props.data.folders ? props.data.folders : [];
        } else {
            folders = [this.props.library.currentFolder];
        }
        this.state = {
            new: props.data.uuid ? false : true,
            formData: {
                uuid: props.data.uuid ? props.data.uuid : uuid(),
                name: props.data.name ? props.data.name : "",
                slug: props.data.slug ? props.data.slug : "",
                mediaFile: props.data.media ? props.data.media[0] : [],
                folders: folders,
            },
        };
    }

    onDrop(files) {
        this.props.uploadFile(files, this.state.formData);
    }

    onChange = (input) => {
        this.setState({
            formData: { ...this.state.formData, [input.name]: input.value },
        });
    };

    close = () => {
        this.props.close();
    };

    save = () => {
        this.props.save(this.state.formData);
    };

    downloadFile = () => {
        window.open(this.props.data.fileurl, "_blank");
    };

    bytesToSize(bytes) {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "n/a";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + " " + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    }

    render() {
        const mediaFile = this.props.data.media
            ? this.props.data.media[0]
            : this.state.formData;
        const { uploading } = this.props.library.current;

        return (
            <React.Fragment>
                <Row>
                    <Column medium={6}>
                        <Row>
                            <Column>
                                <h3>{trans("General")}</h3>
                            </Column>
                        </Row>
                        <Row>
                            <Column medium={4}>
                                <strong>{trans("Name")}</strong>
                            </Column>
                            <Column medium={8}>
                                <InputField
                                    type="text"
                                    name="name"
                                    inlineLabel={false}
                                    onChange={this.onChange}
                                    value={this.state.formData.name}
                                    expanded
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column medium={4}>
                                <strong>{trans("Slug")}</strong>
                            </Column>
                            <Column medium={8}>
                                <InputField
                                    type="text"
                                    name="slug"
                                    inlineLabel={false}
                                    onChange={this.onChange}
                                    value={this.state.formData.slug}
                                    expanded
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <StyledDropzone
                                    name="importUpload"
                                    onDrop={this.onDrop.bind(this)}
                                    style={
                                        uploading
                                            ? { display: "none" }
                                            : {
                                                  textAlign: "center",
                                                  color: "#444444",
                                                  backgroundColor: "#dddddd",
                                                  border: "3px dashed #777777",
                                              }
                                    }
                                >
                                    <span>
                                        Drop your file HERE or click to open
                                        file dialog
                                        <br />
                                    </span>
                                    <span>
                                        <small>
                                            Supported file extensions include:
                                            .png, .svg, .gif, .jpeg
                                        </small>
                                    </span>
                                </StyledDropzone>
                                {uploading && (
                                    <center>
                                        <Loading />
                                    </center>
                                )}
                            </Column>

                            <Row>
                                <Column>
                                    <IconTextButton
                                        onClick={this.save}
                                        showCircle={false}
                                        expanded={false}
                                        icon={faSave}
                                    >
                                        {trans("Update Record")}
                                    </IconTextButton>
                                    <IconTextButton
                                        onClick={this.close}
                                        showCircle={false}
                                        expanded={false}
                                        icon={faTimes}
                                    >
                                        {trans("Close")}
                                    </IconTextButton>
                                </Column>
                            </Row>
                        </Row>
                    </Column>
                    <Column medium={6}>
                        {this.props.data.fileurl ? (
                            <GalleryItemFileDetails
                                fileUrl={this.props.data.fileurl}
                                mediaFile={mediaFile}
                                uploading={uploading}
                                preview={this.props.data.thumbnail}
                            />
                        ) : (
                            <React.Fragment>
                                <h3>{trans("Preview")}</h3>
                                <Panel type="info">
                                    <center>
                                        <h3>
                                            {trans("Not currently available")}
                                        </h3>
                                        <p>
                                            {trans(
                                                "Please upload a file using the dropbox to the left"
                                            )}
                                        </p>
                                        <p>{trans("Thank you")}</p>
                                    </center>
                                </Panel>
                            </React.Fragment>
                        )}
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

export default GalleryItemForm;
