import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { trans } from "@cortexglobal/rla-intl";
import {
    PageTitle,
    IconTextButton,
    PlainButton,
    Loading,
    Row,
    Column
} from "@cortexglobal/rla-components";
import { CategoryResultItem } from "../..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const CrumbButton = styled(PlainButton)`
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0;
    color: ${({ theme, isCurrent }) =>
        isCurrent ? theme.body.color : "inherit"};
    &:hover {
        ${({ theme, isCurrent }) => (isCurrent ? theme.body.color : "inherit")}
    }
`;

// const processLeafAssetCounts = (category, totalLeafAssetCount = 0) => {
//     return category.children.reduce((currentLeafAssetCount, childCategory) => {
//         //If the child has children it's not a leaf so process it's children
//         if (
//             Array.isArray(childCategory.children) &&
//             childCategory.children.length > 0
//         ) {
//             currentLeafAssetCount += processLeafAssetCounts(
//                 childCategory,
//                 currentLeafAssetCount
//             );
//         }
//         currentLeafAssetCount += childCategory.asset_count;
//         return currentLeafAssetCount;
//     }, totalLeafAssetCount);
// };

// const processCategories = categories => {
//     return categories.map(category => {
//         if (Array.isArray(category.children) && category.children.length > 0) {
//             category.leafAssetCount = processLeafAssetCounts(category);
//             category.children = processCategories(category.children);
//         } else {
//             category.leafAssetCount = category.asset_count;
//         }
//         return category;
//     });
// };

const getCategories = (setCategories, setSelectedCategories) => {
    axios
        .get("/api/v1/assets/hierarchy", {
            params: {
                include_asset_count: true
                // selections: JSON.stringify(selections),
                // filterConfig: "assets-default"
            }
        })
        .then(({ data }) => {
            // console.log("data", data);
            // const processedCategories = processCategories(data.data.children);
            // console.log(processedCategories);
            setCategories({
                loaded: true,
                // data: { ...data.data, children: processedCategories },
                // children: processedCategories
                data: data.data,
                children: data.data.children
            });
            setSelectedCategories([data.data]);
        })
        .catch(e => console.log(e));
};

const AssetHierarchyBrowser = ({ assetPath }) => {
    const history = useHistory();
    //Used to load the categories based on the current level and selections
    const [categories, setCategories] = useState({
        loaded: false,
        data: {},
        children: []
    });

    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        // setSelections(urlSelections);
        // console.log(urlSelections, selections);
        getCategories(setCategories, setSelectedCategories);
    }, []);

    const chooseOption = (event, category) => {
        const { slug: selectedSlug, children } = category;
        if ((!children, children.length === 0)) {
            viewAssets(event, category.value);
        }
        setCategories({ ...categories, children });
        //Check if they are going back up the breadcrumbs
        const indexOfSelectedCategory = selectedCategories.findIndex(
            selectedCategory => {
                return selectedCategory.value === category.value;
            }
        );
        console.log(indexOfSelectedCategory);
        if (indexOfSelectedCategory !== -1) {
            setSelectedCategories(
                selectedCategories.slice(0, indexOfSelectedCategory + 1)
            );
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const viewAssets = (event, selectedSlug) => {
        event.preventDefault();
        history.push(
            `${assetPath}?filters={"category-hierarchy":["${selectedSlug}"]}`
        );
    };

    //Get the category slug list from the query string and shove it into state.  Load the last one as the current.
    // console.log(categories);
    // console.log(selections);

    //http://localhost:3020/resources/browse?categories={%22selections%22:[{%22filter%22:%22therapy-type%22,%22selection%22:%22respiritory%22},{%22filter%22:%22product%22,%22selection%22:%22privigen%22}]}

    if (!categories.loaded) {
        return (
            <div style={{ padding: "2rem" }}>
                <Loading />
            </div>
        );
    }
    // console.log(selectedCategories);
    const currentCategory =
        selectedCategories.length > 0 &&
        selectedCategories[selectedCategories.length - 1];
    // console.log(currentCategory);
    return (
        <React.Fragment>
            <PageTitle
                title={trans("Browse {category}", {
                    category: currentCategory
                        ? currentCategory.text
                        : categories.data.text
                })}
            >
                {currentCategory && (
                    <IconTextButton
                        onClick={event =>
                            viewAssets(event, currentCategory.value)
                        }
                    >
                        {trans("View assets")}
                    </IconTextButton>
                )}
            </PageTitle>
            <Row>
                <Column>
                    {selectedCategories.map((category, index) => {
                        const isCurrent =
                            index + 1 === selectedCategories.length;
                        return (
                            <CrumbButton
                                isCurrent={isCurrent}
                                iconBefore={false}
                                showCircle={false}
                                onClick={event => chooseOption(event, category)}
                                disabled={isCurrent}
                            >
                                {category.text}{" "}
                                {!isCurrent && (
                                    <React.Fragment>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                        />{" "}
                                    </React.Fragment>
                                )}
                            </CrumbButton>
                        );
                    })}
                </Column>
                {categories.children.map(category => {
                    return (
                        <Column
                            key={category.value}
                            small={12}
                            medium={6}
                            large={4}
                            xlarge={3}
                            style={{ marginBottom: "0.8rem", height: "290px" }}
                        >
                            <CategoryResultItem
                                onClick={event => chooseOption(event, category)}
                                loaded={categories.loaded}
                                category={category}
                                showAssetCount={true}
                                // permissions={permissions}
                            />
                        </Column>
                    );
                })}
            </Row>
        </React.Fragment>
    );
};

export default AssetHierarchyBrowser;
