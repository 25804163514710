import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import * as Sentry from "@sentry/react";

import rootReducer from "./reducers";

//Configure error tracking
if (process.env.REACT_APP_LOG_ERRORS_TO_SENTRY === "true") {
    const env = process.env.REACT_APP_ENV;

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: env ? env : "unknown",
        normalizeDepth: 5,
    });
}
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
});

export default function configureStore(initialState) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(ReduxThunk), sentryReduxEnhancer)
    );

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./reducers", () => {
                store.replaceReducer(rootReducer);
            });
        }
    }

    return store;
}
