import React from "react";
import { FormRow } from "@cortexglobal/rla-components";
import { get } from "lodash";
import { trans } from "@cortexglobal/rla-intl";
import { FormWrapper, YesNoSelector } from "./formLayoutComponents";

const ContractsForm = ({ loadingState, errors, params, onChange }) => {
    return (
        <FormWrapper>
            <FormRow
                helpText={trans("Required")}
                label={trans("Has the contract been issued?")}
                name={"contract_issued"}
                error={get(errors, "contract_issued")}
            >
                <YesNoSelector
                    name="contract_issued"
                    value={get(params, "contract_issued")}
                    onChange={onChange}
                    loading={loadingState === "loading"}
                />
            </FormRow>

            <FormRow
                helpText={trans("Required")}
                label={trans("Has the contract been signed off?")}
                name={"contract_signed_off"}
                error={get(errors, "contract_signed_off")}
            >
                <YesNoSelector
                    name="contract_signed_off"
                    value={get(params, "contract_signed_off")}
                    onChange={onChange}
                    loading={loadingState === "loading"}
                />
            </FormRow>
        </FormWrapper>
    );
};

export default ContractsForm;
