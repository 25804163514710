import React, { Component } from "react";

import ActiveUsersListingConfiguration from "./ActiveUsersListingConfiguration";
import ActiveUsersListingOutput from "./ActiveUsersListingOutput";

class ActiveUsersListing extends Component {
    render() {
        if (this.props.configuring) {
            return <ActiveUsersListingConfiguration {...this.props} />;
        }
        return <ActiveUsersListingOutput {...this.props} />;
    }
}

export default ActiveUsersListing;
