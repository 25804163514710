import React, { useState } from "react";
import styled from "styled-components";
import Image from "./Image";
import Modal from "./modal";

const StyledVideo = styled.video<{ maxHeight: string }>`
    max-height: ${({ maxHeight }) => maxHeight};
    max-width: 100%;
    cursor: pointer;
`;
const VideoWrapper = styled.div`
    display: flex;
    /* min-height: 80vh; */
    width: 100%;
    overflow: auto;
    justify-content: center;
`;
export const PreviewVideo = ({ src, maxHeight = "80px", ...rest }) => {
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <React.Fragment>
            <StyledVideo
                onClick={() => setModalVisible(true)}
                src={src}
                maxHeight={maxHeight}
                {...rest}
            />
            {modalVisible && (
                <>
                    {/* @ts-ignore cortex */}
                    <Modal
                        visible={modalVisible}
                        maxWidth="90%"
                        onClose={() => setModalVisible(false)}
                        showCloseButton={false}
                    >
                        <VideoWrapper onClick={() => setModalVisible(false)}>
                            <video controls>
                                <source src={src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </VideoWrapper>
                    </Modal>
                </>
            )}
        </React.Fragment>
    );
};

export default PreviewVideo;
