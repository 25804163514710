import React, { useContext } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import { trans } from "@cortexglobal/rla-intl";
import {
    FormRow,
    GroupAndUserSelector,
    DateTimePicker,
    InputField,
} from "@cortexglobal/rla-components";

import SurveyManagementContext from "../SurveyManagementContext";

const SurveyFormAvailability = ({
    handleChange,
    errors,
    groups,
    singleColumn = false,
    collapse = false,
    surveyData,
    setSurveyData,
}) => {
    const { surveySystem } = useContext(SurveyManagementContext);

    const { isSubmitting } = surveySystem;

    const updateSurveyGroup = ({ name, value }) => {
        handleChange(
            "availability",
            {
                ...(surveyData?.availability || {}),
                [name]: value,
            },
            false,
            `availability.${name}`
        );
    };

    return (
        <>
            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="max_entries"
                label={trans("Maximum Number of Entries")}
                required={false}
            >
                <div style={{ flexGrow: 1, maxWidth: "200px" }}>
                    <InputField
                        type="number"
                        min="0"
                        name="max_entries"
                        onChange={(e) => handleChange(e.name, e.value)}
                        disabled={isSubmitting}
                        value={surveyData?.max_entries}
                        style={{
                            flexGrow: 1,
                            maxWidth: "500px",
                        }}
                    />
                </div>
            </FormRow>

            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="publish_at"
                label={trans("Available From")}
                required={true}
                helpText={trans("Required")}
                error={errors?.publish_at}
            >
                <div style={{ flexGrow: 1, maxWidth: "200px" }}>
                    <DateTimePicker
                        name="publish_at"
                        onChange={(e) =>
                            handleChange(
                                e.name,
                                format(
                                    new Date(e.value),
                                    "yyyy-MM-dd'T'HH:mm:ssxxx"
                                )
                            )
                        }
                        disabled={isSubmitting}
                        value={surveyData?.publish_at}
                    />
                </div>
            </FormRow>

            <FormRow
                singleColumn={singleColumn}
                collapse={collapse}
                name="archived_at"
                label={trans("Available To")}
            >
                <div style={{ flexGrow: 1, maxWidth: "200px" }}>
                    <DateTimePicker
                        name="archived_at"
                        onChange={(e) =>
                            handleChange(
                                e.name,
                                format(
                                    new Date(e.value),
                                    "yyyy-MM-dd'T'HH:mm:ssxxx"
                                )
                            )
                        }
                        disabled={isSubmitting}
                        value={surveyData?.archived_at}
                    />
                </div>
            </FormRow>

            <FormRow
                label={
                    <div style={{ marginBottom: "1rem" }}>
                        {trans("Groups and Users")}
                    </div>
                }
            >
                {" "}
            </FormRow>

            <GroupAndUserSelector
                onChange={updateSurveyGroup}
                groups={groups}
                singleColumn={false}
                collapse={false}
                view_type={surveyData?.availability?.view_type}
                allowed_groups={surveyData?.availability?.allowed_groups}
                allowed_groups_individual={
                    surveyData?.availability?.allowed_groups_individual
                }
                allowed_users={surveyData?.availability?.allowed_users}
                errors={{ view_type: errors["availability.view_type"] }}
            />
        </>
    );
};

// GroupAndUserSelector expects an array of groups to be passed to it. This is set up using Redux in other areas of the
// application ergo we want to use redux here as well to retrieve the groups which should already be loaded instead of
// doing a custom ajax call
const mapStateToProps = (state) => {
    const {
        auth: { groups },
    } = state;

    return {
        groups,
    };
};

export default connect(mapStateToProps)(SurveyFormAvailability);
