import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import {
    ButtonDropdown,
    IconTextButton,
    InputField,
    PageTitle,
    Row,
    LoadingIconSmall,
    useAddToast,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import { faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UserTable } from "./UserTable";

const loadItems = (setItems, page, search, orderBy, orderByDir, perPage) => {
    setItems({
        loaded: false,
    });

    axios
        .get(`/api/v1/admin/user`, {
            params: {
                page: page,
                search: search ? search : null,
                sortDirection: orderByDir ? orderByDir : null,
                sortField: orderBy ? orderBy : null,
                perPage: perPage ? perPage : 10,
            },
        })
        .then(({ data }) => {
            // console.log(data);
            setItems({
                loaded: true,
                data: data.data,
                pagination: data.meta,
            });
        })
        .catch();
};

export const UserListing = ({ path }) => {
    const { can } = usePermissions();
    const history = useHistory();
    const addToast = useAddToast();

    const page = useQueryString().get("page") || 1;
    const searchParam = useQueryString().get("search") || "";
    const orderByParam = useQueryString().get("orderBy") || "";
    const dirParam = useQueryString().get("dir") || "ASC";
    const perPageParam = useQueryString().get("perPage") || 10;

    const [searchInput, setSearchInput] = useState(searchParam);
    const [search, setSearch] = useState(searchParam);

    const [orderBy, setOrderBy] = useState(orderByParam);
    const [orderByDirection, setOrderByDirection] = useState(dirParam);

    const [items, setItems] = useState({
        loaded: false,
        data: [],
        pagination: null,
    });

    const [bulkActionLoading, setBulkActionLoading] = useState(false);

    useEffect(() => {
        loadItems(
            setItems,
            page,
            search,
            orderBy,
            orderByDirection,
            perPageParam
        );
    }, [setItems]);

    const onSearch = () => {
        if (searchInput.length) {
            setSearch(searchInput);
            history.push(
                `?page=${page}&search=${searchInput}&orderBy=${orderBy}&dir=${orderByDirection}`
            );
            loadItems(
                setItems,
                page,
                searchInput,
                orderBy,
                orderByDirection,
                perPageParam
            );
        }
    };

    const onOrderBy = (data) => {
        let orderDir;

        if (orderBy === data) {
            setOrderByDirection(orderByDirection === "ASC" ? "DESC" : "ASC");
            orderDir = orderByDirection === "ASC" ? "DESC" : "ASC";
        } else {
            setOrderBy(data);
            setOrderByDirection("ASC");
            orderDir = "ASC";
        }

        history.push(
            `?page=${page}&orderBy=${data}&dir=${orderDir}&search=${searchInput}&perPage=${perPageParam}`
        );
        loadItems(setItems, page, search, data, orderDir, perPageParam);
    };

    const onClearSearch = () => {
        setSearch("");
        setSearchInput("");

        history.push(
            `?page=${page}&orderBy=${orderBy}&dir=${orderByDirection}&perPage=${perPageParam}`
        );
        loadItems(setItems, page, "", orderBy, orderByDirection, perPageParam);
    };

    const onPerPageChange = ({ value }: { value: string }) => {
        history.push(
            `?perPage=${value}&orderBy=${orderBy}&dir=${orderByDirection}&search=${searchInput}`
        );
        loadItems(setItems, page, search, orderBy, orderByDirection, value);
    };

    const refreshItems = () => {
        loadItems(
            setItems,
            page,
            search,
            orderBy,
            orderByDirection,
            perPageParam
        );
    };

    const activateUsers = () => {
        setBulkActionLoading(true);

        return axios
            .put(`/api/v1/manager/users/resend-activation`)
            .then(() => {
                addToast({
                    content: trans("All activation emails successfully resent"),
                    type: "success",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans(
                        "An error occurred whilst bulk resending activations"
                    ),
                    showFor: 5000,
                });
            })
            .finally(() => {
                setBulkActionLoading(false);
            });
    };

    const getBulkActions = () => {
        const actions = [];

        if (can("manage-users-activate")) {
            actions.push({
                name: "Resend All Activation Emails",
                onClick: () =>
                    activateUsers().then(() => {
                        refreshItems();
                    }),
            });
        }

        return actions;
    };

    return (
        <div>
            <PageTitle
                title={<React.Fragment>{trans("Users")}</React.Fragment>}
            >
                {search && (
                    <IconTextButton
                        onClick={onClearSearch}
                        showCircle={false}
                        icon={faTimes}
                    >
                        {trans("Clear search")}
                    </IconTextButton>
                )}
                <InputField
                    style={{ width: "300px", marginBottom: "0" }}
                    value={searchInput}
                    onChange={(item) => setSearchInput(item.value)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            onSearch();
                        }
                    }}
                />
                <IconTextButton
                    onClick={onSearch}
                    showCircle={false}
                    icon={faSearch}
                />

                <div style={{ position: "relative" }}>
                    {bulkActionLoading ? (
                        <LoadingIconSmall />
                    ) : (
                        <ButtonDropdown
                            name="primary"
                            actions={getBulkActions()}
                            onClick={() => {}}
                        >
                            {trans("Bulk Actions")}
                        </ButtonDropdown>
                    )}
                </div>

                <IconTextButton
                    as={Link}
                    to={`${path}/create`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                    icon={faPlus}
                >
                    {trans("Create new user")}
                </IconTextButton>
            </PageTitle>
            <Row>
                <UserTable
                    refreshItems={refreshItems}
                    onPerPageChange={onPerPageChange}
                    onOrderBy={onOrderBy}
                    items={items.data}
                    loaded={items.loaded}
                    path={path}
                    pagination={items.pagination}
                    onPageChange={(selected) => {
                        history.push(`?page=${selected}&search=${search}`);
                        loadItems(
                            setItems,
                            selected,
                            search,
                            orderBy,
                            orderByDirection,
                            perPageParam
                        );
                    }}
                />
            </Row>
        </div>
    );
};

export default UserListing;
