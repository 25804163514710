import React, { useContext } from "react";
import { useSpring } from "react-spring";

import { Layout } from "../interfaces/layout";
import {
    SlideContainer,
    SlideTitle,
    SlideBody,
    Subtitle,
} from "../components/slideComponents";
import { SequencedVideo } from "../components/SequencedVideo";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";
import styled from "styled-components";

const StyledSlideTitle = styled(SlideTitle)`
    text-align: center;
    justify-content: center;
`;

const TitleSequencedVideo = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationDispatch,
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);
    if (editing || disableSequencing) {
        currentTransitionIndex = 1;
    }
    const transitionNamesComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );
    const currentTransitionObject =
        transitions[
            currentTransitionIndex === 0 ? 0 : currentTransitionIndex - 1
        ];
    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            <SequencedVideo
                isCurrent={
                    currentTransitionObject &&
                    currentTransitionObject.name === "sequenced_video"
                }
                disableSequencing={!!(editing || disableSequencing)}
                setup={content.sequenced_video.value || {}}
                navigationDispatch={navigationDispatch}
                highlightColor={settings.highlightColor}
            />
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                style={useSpring({
                    opacity: 1,
                })}
            >
                <RenderContentBlock {...content.title} />
            </StyledSlideTitle>
            <SlideBody
                style={useSpring({
                    opacity: transitionNamesComplete.includes("sequenced_video")
                        ? 1
                        : 0,
                })}
            ></SlideBody>
        </SlideContainer>
    );
};

export default TitleSequencedVideo;
