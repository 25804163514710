import React from "react";
import styled from "styled-components";
import {
    GreedyCarousel,
    CarouselItem,
    Centred,
    Loading,
    Panel
} from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import AssetResultItem from "../assetComponents/AssetResultItem";

const FlexPanel = styled(Panel)`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
`;

const Carousel = ({ panelId, filterBy, total = 5, perSlide = 3 }) => {
    const panelData = usePanelData(panelId, `/api/v1/assets`, {
        filter_by: filterBy,
        per_page: total
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }
    // console.log("REST", rest);
    // const { total, title, description } = panelData.data;

    return (
        <GreedyCarousel perTile={perSlide} height="360px" background="none">
            {panelData.data.map(asset => {
                return (
                    <CarouselItem
                        key={asset.uuid}
                        background="none"
                        style={{ background: "none !important" }}
                    >
                        <AssetResultItem
                            margin="0rem 0.5rem"
                            loaded={panelData.loaded}
                            asset={asset}
                            // selectAsset={selectAsset}
                            key={asset.uuid}
                            // permissions={permissions}
                            assetsPath={"/resources"}
                        />
                    </CarouselItem>
                );
            })}
        </GreedyCarousel>
    );
};

export default Carousel;
