import React, { useContext } from "react";
import styled, { css, useTheme } from "styled-components";
import { useToasts } from "./ToastContext";
import { Toast } from "./Toast";
import { useTransition, animated } from "react-spring";

export const ToastListContainer = styled.ul`
    position: fixed;
    ${({ theme }) =>
        theme.toast.verticalPosition === "top"
            ? css`
                  top: 0;
              `
            : css`
                  bottom: 0;
              `};
    margin: 1rem 0 0 0;
    padding: 0;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: ${({ theme }) =>
        theme.toast.horizontalPosition === "left" ? "flex-start" : "flex-end"};
    z-index: 9999;
`;

export const ToastListItem = styled(animated.li)`
    margin: 0 1rem 1rem 1rem;
`;
export const ToastList = (props) => {
    const [toasts, dispatch] = useToasts();
    const theme = useTheme();
    const removeToast = (toastUuid) => {
        dispatch({ type: "removeToast", uuid: toastUuid });
    };
    const offset =
        theme.toast.horizontalPosition === "left"
            ? `-${theme.toast.width}`
            : `${theme.toast.width}`;
    const transitions = useTransition(toasts, (toast) => toast.uuid, {
        from: { transform: `translate3d(${offset},0,0)`, opacity: 0 },
        enter: { transform: `translate3d(0px,0,0)`, opacity: 1 },
        leave: { transform: `translate3d(${offset},0,0)`, opacity: 0 },
    });

    return (
        <ToastListContainer {...props}>
            {transitions.map(({ item, props, key }) => {
                return (
                    <ToastListItem key={key} style={props}>
                        <Toast {...item} removeToast={removeToast} />
                    </ToastListItem>
                );
            })}
        </ToastListContainer>
    );
};
