import React, { useContext } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { trans } from "@cortexglobal/rla-intl";

import {
    Badge,
    FileIcon,
    SearchResultWrapper,
    SearchResultImageWrapper,
    SearchResultImage,
    SearchResultButton,
    SearchResultTitle,
    SearchResultSummary,
    ShowMore,
} from "@cortexglobal/rla-components";

const StyledBadge = styled(Badge)`
    position: absolute;
    top: 0;
    right: 0;
`;

const InventoryResultItem = ({
    item,
    onItemClick,
    alreadySelected = false,
    theme,
}) => {
    const wrappersStyle = alreadySelected ? { opacity: "0.5" } : {};

    const handleClick = (e) => {
        e.preventDefault();
        onItemClick(item);
    };

    return (
        <SearchResultWrapper
            textAlign="center"
            padding={0}
            style={{ ...wrappersStyle, minHeight: "290px" }}
        >
            <Link onClick={handleClick} to={() => {}}>
                <SearchResultImageWrapper>
                    {item.thumbnail === null || !item.thumbnail ? (
                        <FileIcon colorBehind={theme.colors.gray200}>
                            {item.main_file_extension}
                        </FileIcon>
                    ) : (
                        <SearchResultImage
                            src={item.thumbnail}
                            alt={item.name}
                            objectFit="contain"
                        />
                    )}
                </SearchResultImageWrapper>
                {item.isAvailableToEvent === false && (
                    <StyledBadge rounded={false} type="secondary">
                        {trans("Not available for ths event")}
                    </StyledBadge>
                )}
            </Link>
            <SearchResultButton
                onClick={handleClick}
                showCircle={theme.search.icons.showCircle}
                iconBefore={false}
                doHover={false}
                icon={theme.search.icons.searchIcon}
                iconColor={theme.search.icons.color}
                iconSize={theme.search.icons.size}
            >
                <SearchResultTitle title={item.name}>
                    <ShowMore
                        style={{ lineBreak: "anywhere" }}
                        type={"tooltip"}
                        lines={2}
                    >
                        {item.name}
                    </ShowMore>
                </SearchResultTitle>
                <SearchResultSummary
                    title={item.category?.name}
                    style={{ margin: "0.2rem" }}
                >
                    {item.category?.name}
                </SearchResultSummary>
            </SearchResultButton>{" "}
        </SearchResultWrapper>
    );
};

export default withTheme(withRouter(InventoryResultItem));
