import React, { Component } from "react";
// import Raven from "raven-js";
import { trans } from "@cortexglobal/rla-intl";
import { Row, Column, Panel } from "@cortexglobal/rla-components";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }
    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error,
            errorInfo: info,
        });
        // Raven.captureException(error, { extra: info });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                // onClick={() =>
                //     Raven.lastEventId() && Raven.showReportDialog()
                // }
                >
                    <Row>
                        <Column>
                            <h1>{trans("Sorry")}</h1>
                            <p>{trans("An error has occurred")}</p>
                        </Column>
                    </Row>
                    {process.env.REACT_APP_ENV === "local" ||
                        (process.env.REACT_APP_DEBUG &&
                            this.state.error &&
                            this.state.errorInfo && (
                                <Row>
                                    <Panel>
                                        <Row>
                                            <Column>
                                                <h2>
                                                    {this.state.error.message}
                                                </h2>
                                                <h4>Stack</h4>
                                                {this.state.error.stack
                                                    .split("\n")
                                                    .map((str, index) => (
                                                        <p key={str + index}>
                                                            {str}
                                                        </p>
                                                    ))}
                                                <h4>Component Stack</h4>
                                                {this.state.errorInfo
                                                    .componentStack &&
                                                    this.state.errorInfo.componentStack
                                                        .split("\n")
                                                        .map((str, index) => (
                                                            <p
                                                                key={
                                                                    str + index
                                                                }
                                                            >
                                                                {str}
                                                            </p>
                                                        ))}
                                            </Column>
                                        </Row>
                                    </Panel>
                                </Row>
                            ))}
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
