import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import MySurveys from "./containers/MySurveys";
import SubmissionForm from "./submission/SubmissionForm";

const MySurveysApp = ({ area }) => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={path} exact={true}>
                <MySurveys area={area} path={path} />
            </Route>
            <Route path={`${path}/:alias?`}>
                <SubmissionForm area={area} path={path} />
            </Route>
        </Switch>
    );
};

export default MySurveysApp;
