import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useHistory, useRouteMatch } from "react-router-dom";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { Button } from "@cortexglobal/rla-components";
import MicrositeContext from "../MicrositeContext";
import { bookmarkSite } from "../micrositeFunctions";

const HeaderContainer = styled.header`
    position: absolute;
    top: 0;
    right: 0;
    transition: all 300ms ease;
`;

export const AdminHeader = ({
    editPath,
    showAdminBar
}: {
    editPath: string;
    showAdminBar: boolean;
}) => {
    if (!showAdminBar) {
        return null;
    }
    const [expanded, setExpanded] = useState(true);
    const { basePath, baseUrl, fullscreen } = useContext(MicrositeContext);
    const history = useHistory();
    //Grab the asset uuid and page from the path, used for the admin bar links

    let assetUuid = "";
    let pageSlug = "";
    const match = useRouteMatch(`${basePath}/:pageSlug?`);
    // console.log(match);
    if (match && match.params) {
        //@ts-ignore
        assetUuid = match.params.uuid ? match.params.uuid : "";
        //@ts-ignore
        pageSlug = match.params.pageSlug ? `/${match.params.pageSlug}` : "";
    }
    if (fullscreen) {
        return null;
    }
    return (
        <HeaderContainer>
            {expanded && (
                <React.Fragment>
                    <Button
                        onClick={() => {
                            bookmarkSite(history, baseUrl);
                        }}
                    >
                        {trans("Add to favourites")}
                    </Button>
                    {assetUuid && (
                        <Button
                            onClick={() =>
                                history.replace(`${editPath}${pageSlug}`)
                            }
                        >
                            {trans("Edit")}
                        </Button>
                    )}
                </React.Fragment>
            )}
            <Button onClick={() => setExpanded(!expanded)}>
                {expanded ? ">" : "<"}
            </Button>
        </HeaderContainer>
    );
};
