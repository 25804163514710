import React, { Component } from "react";

import DashboardChartsConfiguration from "./DashboardChartsConfiguration";
import DashboardChartsOutput from "./DashboardChartsOutput";

class DashboardCharts extends Component {
    render() {
        if (this.props.configuring) {
            return (
                <DashboardChartsConfiguration
                    displayType="listing"
                    {...this.props}
                />
            );
        }
        return <DashboardChartsOutput {...this.props} />;
    }
}

export default DashboardCharts;
