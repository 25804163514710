import React, { Component } from "react";
import { Helmet } from "react-helmet";

class HeadContent extends Component {
    render() {
        return (
            <Helmet>
                <title>{process.env.REACT_APP_NAME}</title>
                <meta
                    name="description"
                    content="This marketing portal is a powerful set of tools for marketing and budget analysis"
                />
            </Helmet>
        );
    }
}

export default HeadContent;
