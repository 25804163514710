import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    ContentBlockLoader,
    SubmitButton,
    FormikAdvancedSelect,
    FormikInput,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    LoadingIconSmall,
    useAddToast,
    useToasts,
    Toggle,
    IconTextButton,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

import { PreviewImage, LocaleSwitcher } from "../components/index";

const FILE_SIZE = 1160 * 1024 * 12;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = Yup.object().shape({
    locale: Yup.string().nullable(),
    name: Yup.string().required(trans("Required")),
    external_identifier: Yup.string().nullable(),
    description: Yup.string().nullable(),
    category_id: Yup.string().nullable(),
    // order: Yup.number(),
    // color: Yup.string().nullable(),
    thumbnail: Yup.lazy((value) => {
        switch (typeof value) {
            case "object":
                if (!value) {
                    return Yup.mixed();
                }
                return (
                    Yup.mixed()
                        // .required("A thumbnail is required")
                        .test(
                            "fileSize",
                            "File too large",
                            (value) => value.size <= FILE_SIZE
                        )
                        .test("fileFormat", "Unsupported Format", (value) =>
                            SUPPORTED_FORMATS.includes(value.type)
                        )
                ); // schema for object
            case "string":
                return Yup.string(); // schema for string
            default:
                return Yup.mixed(); // here you can decide what is the default
        }
    }),
});
// .required();

export type Product = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    slug?: string;
    i18n?: { [key: string]: any };
};

export const ProductForm = ({ item, submitForm }) => {
    const addToast = useAddToast();
    const [_, dispatch] = useToasts();

    const [newPreviewFile, setNewPreviewFile] = useState(null);

    const handleSubmit = (
        values: Product,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();
        Object.keys(values).forEach((key) => {
            const value = values[key];
            if (value === null) return;
            fd.append(key, values[key]);
        });
        addToast({
            uuid: "loadToast",
            type: "loader",
            content: trans("Saving"),
        });
        submitForm(fd, setSubmitting)
            .then((data) => {
                addToast({
                    content: trans("Product saved"),
                    type: "success",
                    showFor: 3000,
                });
            })
            .catch((e) => {
                if (e.response.data && e.response.data.message) {
                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                } else {
                    addToast({
                        type: "alert",
                        content: trans(
                            "An error occurred updating the product"
                        ),
                        showFor: 5000,
                    });
                }
                parseValidationErrors(e).then(({ errors }) => {
                    //console.log(setErrors, errors);
                    setErrors(errors);
                    setSubmitting(false);
                });
            })
            .finally(() => {
                dispatch({ type: "removeToast", uuid: "loadToast" });
            });
    };
    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(newPreviewFile);
        },
        [newPreviewFile]
    );
    const onDrop = useCallback((acceptedFiles, setFieldValue) => {
        setFieldValue("thumbnail", acceptedFiles[0]);
        setNewPreviewFile(URL.createObjectURL(acceptedFiles[0]));
    }, []);

    return (
        <Formik
            initialValues={{
                locale: item.locale ? item.locale : null,
                name: item.name,
                external_identifier: item.external_identifier,
                description: item.description,
                category_id: item.category_id,
                // order: item.order,
                // color: item.color,
                thumbnail: item.thumbnail,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                console.log(values);
                return (
                    <Form className="form-group">
                        <FormRow
                            name="locale"
                            label={trans("Locale")}
                            helpText={trans("Optional")}
                            error={errors.locale}
                        >
                            <LocaleSwitcher
                                item={item}
                                setFieldValue={setFieldValue}
                                //@ts-ignore
                                values={values}
                            />
                        </FormRow>
                        <FormRow
                            name="name"
                            label={trans("Name")}
                            helpText={trans("Required")}
                            error={errors.name}
                            required={true}
                        >
                            <FormikInput
                                name="name"
                                value={values.name}
                                placeholder={item.slug ? item.slug : ""}
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="external_identifier"
                            label={trans("External Identifier")}
                            helpText={trans(
                                "Optional, an external identifier for the product, possible a SKU, model number or similar"
                            )}
                            error={errors.external_identifier}
                        >
                            <FormikInput
                                name="external_identifier"
                                value={values.external_identifier}
                                placeholder={
                                    item.external_identifier
                                        ? item.external_identifier
                                        : ""
                                }
                                showError={false}
                            />{" "}
                        </FormRow>
                        <FormRow
                            name="description"
                            label={trans("Description")}
                            helpText={trans("Optional")}
                            error={errors.description}
                        >
                            <FormikInput
                                name="description"
                                value={values.description}
                                showError={false}
                            />{" "}
                        </FormRow>

                        <FormRow
                            name="thumbnail"
                            label={trans("Thumbnail")}
                            helpText={trans("Optional")}
                            error={errors.thumbnail}
                        >
                            <SimpleDropzone
                                style={{ marginBottom: "2.2rem" }}
                                onDrop={(acceptedFiles) =>
                                    onDrop(acceptedFiles, setFieldValue)
                                }
                                accept="image/*"
                                multiple={false}
                            >
                                <IconTextButton
                                    icon="upload"
                                    showCircle={false}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {values.thumbnail && values.thumbnail !== ""
                                        ? trans("Update thumbnail")
                                        : trans("Add thumbnail")}
                                </IconTextButton>
                            </SimpleDropzone>

                            {typeof values.thumbnail === "string" &&
                                values.thumbnail !== "" && (
                                    <PreviewImage src={values.thumbnail} />
                                )}
                            {newPreviewFile && (
                                <PreviewImage src={newPreviewFile} />
                            )}
                        </FormRow>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ProductForm;
