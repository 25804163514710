import axios from "axios";
//import storage from "local-storage-fallback";
//import uuid from "uuid/v4";

export const LOAD_PANEL_DATA = "LOAD_PANEL_DATA";

export const loadPanel = (id, url, params) => {
    // const panelFromStorage = storage.getItem(`${id}_panel`);

    // if (panelFromStorage) {
    //     const panelData = JSON.parse(panelFromStorage);
    //     return dispatch => {
    //         dispatch({
    //             type: LOAD_PANEL_DATA,
    //             id,
    //             loaded: true,
    //             data: panelData.data
    //         });
    //     };
    // }

    return dispatch => {
        axios
            .get(url, { params })
            .then(response => {
                //Handle the data.data issue if it has or has not gone through a response resource
                const data = response.data.hasOwnProperty("data")
                    ? response.data.data
                    : response.data;
                dispatch({
                    type: LOAD_PANEL_DATA,
                    id,
                    loaded: true,
                    data: data
                });
            })
            .catch(e => e);
    };
};

export const UNLOAD_PANEL_DATA = "UNLOAD_PANEL_DATA";

export const unLoadPanel = id => {
    return dispatch => {
        dispatch({
            type: UNLOAD_PANEL_DATA,
            id
        });
    };
};

export const SET_PANEL_DATA_FOR_REFRESH = "SET_PANEL_DATA_FOR_REFRESH";
export const SET_REFRESHING_PANEL_DATA = "SET_REFRESHING_PANEL_DATA";
