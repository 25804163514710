import React from "react";
import _ from "lodash";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Button,
    IconTextButton
} from "@cortexglobal/rla-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faFolder,
    faImage,
    faLevelUpAlt
} from "@fortawesome/free-solid-svg-icons";

class SubFolders extends React.Component {
    openFolder = item => {
        this.props.openFolder(item);
    };

    createNew = () => {
        this.props.createNew();
    };

    createNewFolder = () => {
        this.props.createNewFolder();
    };

    render() {
        const { folders, parents } = this.props;

        return (
            <Row>
                {parents && parents.length > 0 && (
                    <IconTextButton
                        style={{ margin: "5px" }}
                        onClick={this.openFolder.bind(this, parents[0])}
                        showCircle={false}
                        expanded={false}
                        icon={faLevelUpAlt}
                    >
                        ...
                    </IconTextButton>
                )}
                {folders &&
                    Object.keys(folders).map(index => {
                        const folder = folders[index];

                        return (
                            <IconTextButton
                                style={{ margin: "5px", float: "left" }}
                                onClick={this.openFolder.bind(this, folder)}
                                showCircle={false}
                                expanded={false}
                                icon={faFolder}
                            >
                                {folder.name}
                            </IconTextButton>
                        );
                    })}
                <IconTextButton
                    style={{ float: "right" }}
                    onClick={this.createNew}
                    showCircle={false}
                    expanded={false}
                    icon={faImage}
                >
                    {trans("new file")}
                </IconTextButton>
                <IconTextButton
                    style={{ float: "right" }}
                    onClick={this.createNewFolder}
                    showCircle={false}
                    expanded={false}
                    icon={faFolder}
                >
                    {trans("new folder")}
                </IconTextButton>
            </Row>
        );
    }
}

export default SubFolders;
