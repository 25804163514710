import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Pagination,
    Button,
    StyledCheckbox,
    FormLabel,
    Tabordion,
    InputField,
    IconTextButton,
    Modal,
    Select,
} from "@cortexglobal/rla-components";
import set from "lodash/set";
import get from "lodash/get";

import Gallery from "../../gallery/frontend/Gallery";

import PageNavigator from "./pageManagement/PageNavigator";
import PageCreator from "./pageManagement/PageCreator";
import PageEditor from "./components/PageEditor";
import BackgroundImageEditor from "./components/BackgroundImageEditor";

import StandardPageSettingsEditor from "./components/StandardPageSettingsEditor";
import TransitionEditor from "./components/TransitionEditor";
import AssetPreview from "./components/AssetPreview";
import EditorContext from "./EditorContext";
import AssetContext from "../AssetContext";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

import {
    TabordionContentWrapper,
    EditorColumn,
    PreviewColumn,
    FlexColumn,
    FlexRow,
    PreviewContainer,
} from "./components/editorComponents";
import {
    updateConfiguration,
    onConfigurationChange,
} from "./components/sharedEditorFunctions";
import { SectionHeading } from "./components/SectionHeading";

/*
//TODO - Remove this function once we are sure it's not needed anymore
const filterFootnotes = (footnotes, configuration) => {
    return footnotes
        .map((captionFootnote) => {
            if (configuration.footnotes && configuration.footnotes.values) {
                if (
                    configuration.footnotes.values.some(
                        ({ uuid }) => uuid === captionFootnote.uuid
                    )
                ) {
                    return [...configuration.footnotes.values].filter(
                        (configFootnote2) =>
                            configFootnote2.uuid === captionFootnote.uuid
                    )[0];
                }
            }
            return captionFootnote;
        })
        .filter((captionFootnote) => {
            if (configuration.footnotes && configuration.footnotes.values) {
                return configuration.footnotes.values.some(
                    ({ uuid }) => uuid === captionFootnote.uuid
                );
            }
        });
};
*/
/**
 * TODO - Remove this function once we are sure it's not needed anymore
 * Pass the updated configuration to the main asset change handler
 * @param {*} standardOnChange
 * @param {*} asset
 * @param {*} property
 * @param {*} event
 * @param {*} path
 */
/*
const onChange = (standardOnChange, asset, property, event, path) => {
    // console.log(standardOnChange, asset, property, event, path);
    let { value } = event;

    //Update the item in the configuration as it's all submitted
    let { configuration, extracted_tags } = asset;

    //If there's currently no configuration copy the original extracted tags in there
    if (!configuration) {
        configuration = extracted_tags;
    }

    // This function is used to update all the pages content footnotes to match the globally stored footnotes
    // Also deletes footnotes across all pages when deleted on one page
    if (event.name === "footnotes") {
        let newPages = { ...configuration.pages };
        Object.keys(newPages).map(function (key, index) {
            let pageContent = [...newPages[key].content];

            if (!!pageContent.length) {
                pageContent = pageContent.map((content) => {
                    // filter the footnotes in content of sequenced video
                    if (content.type === "sequenced-video") {
                        if (content.value && content.value.captions) {
                            content.value.captions.map((caption) => {
                                if (caption.footnotes) {
                                    caption.footnotes = filterFootnotes(
                                        caption.footnotes,
                                        configuration
                                    );
                                }

                                return caption;
                            });
                        }
                    }

                    // filter the footnotes in repeater fields
                    if (content.type === "repeater" && content.value) {
                        content.value.map((repeaterContent) => {
                            if (repeaterContent.footnotes) {
                                repeaterContent.footnotes = filterFootnotes(
                                    repeaterContent.footnotes,
                                    configuration
                                );
                            }

                            return repeaterContent;
                        });
                    }

                    // filter general footnotes
                    if (content.footnotes) {
                        content.footnotes = filterFootnotes(
                            content.footnotes,
                            configuration
                        );
                    }

                    return {
                        ...content,
                    };
                });
            }
            newPages[key].content = pageContent;
        });
    }

    standardOnChange({
        name: "configuration",
        value: updateConfiguration(configuration, path, property, value),
    });
};
*/
const hasAdminOption = (asset, $key) => {
    if (asset.admin_options && asset.admin_options.includes($key)) {
        return true;
    }
    return false;
};

const updatePages = (standardOnChange, asset, { selectedSlug, pages }) => {
    //Update the item in the configuration as it's all submitted
    let { configuration, extracted_tags } = asset;

    // //If there's currently no configuration copy the original extracted tags in there
    if (!configuration) {
        configuration = extracted_tags;
    }

    const newPages = pages.reduce((pagesObject, page, index) => {
        const newPage = {
            ...page[1],
            order: index,
        };
        pagesObject[page[0]] = newPage;
        return pagesObject;
    }, {});
    set(configuration, "pages", newPages);

    standardOnChange({
        name: "configuration",
        value: configuration,
    });

    return { newPages: pages, selectedSlug: selectedSlug };
};

/**
 * Setup the pages so that the different configuration structure of different assets is unified
 * @param {Object} configuration
 */
const setupPages = (configuration) => {
    let pages = Object.entries(configuration.pages);

    return pages.sort((a, b) => {
        if (a[1].order < b[1].order) {
            return -1;
        }
        if (a[1].order > b[1].order) {
            return 1;
        }
        return 0;
    });
};

const getPageIndexByName = (pages, pageName = "", configuration) => {
    const urlPageIndex = pages.findIndex(([pageKey, pageSetup]) => {
        return pageKey === pageName;
    });
    //If the page name was found return it's index
    if (urlPageIndex !== -1) {
        return urlPageIndex;
    }
    if (configuration.selectedPage) {
        const selectedPageIndex = pages.findIndex(([pageKey, pageSetup]) => {
            return pageKey === configuration.selectedPage;
        });
        if (selectedPageIndex !== -1) {
            return selectedPageIndex;
        }
    }
    return 0;
};
const getPageNameByIndex = (pages, pageIndex = 0) => {
    return pages[pageIndex][0];
};

const PaddedDiv = styled.div`
    padding: 3em;
`;

const CenterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 600px;
`;

const Editor = ({
    assetAdmin,
    asset,
    standardOnChange,
    fullAdmin,
    editRouteMatch,
    saveUpdatedAsset,
    baseAsset = null,
    autofill = [],
    editingUserAsset = false,
}) => {
    const { can } = usePermissions();
    let history = useHistory();

    const [editingPageName, setEditingPageName] = useState(false);
    const [choosingImage, setChoosingImage] = useState(false);
    const [showingPageNavigator, setShowingPageNavigator] = useState(false);
    const [showingPageCreator, setShowingPageCreator] = useState(
        asset.configuration &&
            Array.isArray(asset.configuration.pages) &&
            asset.configuration.pages.length === 0
    );

    const [deletePageModalShowing, setDeletePageModalShowing] = useState(false);
    const [deletePageSlug, setDeletePageSlug] = useState("");

    const groupSetting =
        asset.configuration && asset.configuration.globalSettings
            ? asset.configuration.globalSettings.filter(
                  ({ name }) => name === "groups"
              )
            : [];
    const groups = !!groupSetting.length ? groupSetting[0].value : [];

    //Handle setting up the current page from the URL
    let { page } = useParams();
    if (!asset || !asset.editable) {
        return null;
    }

    const { configuration, uuid, asset_type } = asset;

    const assetType =
        typeof asset_type === "string" ? asset_type : asset_type?.slug;

    if (!configuration) {
        return "No configuration has been extracted from this asset";
    }

    let pages = setupPages(configuration);

    //If the page is in the URL then try and set that as the current page, otherwise see
    //if there's a selectedPage in the configuration and use that, otherwise just use the
    //first one
    const initialPageIndex = getPageIndexByName(pages, page, configuration);

    const [currentPage, setCurrentPage] = useState(initialPageIndex);
    const [targetImagePath, setTargetImagePath] = useState(initialPageIndex);

    useEffect(() => {
        if (
            hasAdminOption(baseAsset, "dropdown_pagination") &&
            !asset?.configuration?.selectedPage
        ) {
            handlePagePaginationUpdate(
                pages,
                { selected: 0 },
                standardOnChange
            );
        }
    }, []);

    const onPageEdit = (value, path, event) => {
        // console.log(value, path, event);
        if (assetType === "inDesign") {
            standardOnChange({
                name: "files_ready",
                value: false,
            });
        }
        onConfigurationChange(standardOnChange, asset, value, event, path);
    };
    const chooseImage = (path, tag, event) => {
        //TODO - Set the correct image/tag in state to be used on the
        setTargetImagePath(path);
        //update method
        return setChoosingImage(!choosingImage);
    };
    const selectImage = (image) => {
        //(path, tag, event)
        //TODO - Push the image URL into the correct node of the JSON

        onConfigurationChange(
            standardOnChange,
            asset,
            "value",
            {
                name: "image",
                value: image.fileurl,
            },
            targetImagePath
        );
        // standardOnChange("value", path, image);
        setChoosingImage(false);
    };

    const handlePageNavigation = (pages, selectedPageSlug) => {
        setCurrentPage(getPageIndexByName(pages, selectedPageSlug));

        //TODO - Correctly push the current page into the URL...
        history.push(`${editRouteMatch.url}/${selectedPageSlug}`);
        // setTimeout(() => {
        //     this.setState({ resetZoom: false });
        // }, 50);
    };
    const handlePagePaginationUpdate = (
        pages,
        { selected },
        standardOnChange
    ) => {
        setCurrentPage(selected);
        // console.log(selected);
        const pageName = getPageNameByIndex(pages, selected);

        //TODO - Correctly push the current page into the URL...
        history.push(`${editRouteMatch.url}/${pageName}`);

        //Changing the selected page should update the configuration for editableVideo, but
        //not for other assets otherwise there's a false request to save to
        if (assetType === "editableVideo") {
            standardOnChange({
                name: "configuration",
                value: { ...configuration, selectedPage: pageName },
            });
        }
    };

    const deletePage = () => {
        const updatedPages = pages.filter((page) => page[0] !== deletePageSlug);
        const { newPages, selectedSlug } = updatePages(
            standardOnChange,
            asset,
            {
                pages: updatedPages,
                selectedSlug:
                    deletePageSlug === page ? updatedPages[0][0] : page,
            }
        );

        setDeletePageModalShowing(false);
        setDeletePageSlug("");

        handlePageNavigation(newPages, selectedSlug);
    };

    const handlePageDelete = (slug) => {
        setDeletePageModalShowing(true);
        setDeletePageSlug(slug);
    };

    if (
        Array.isArray(configuration.pages) &&
        configuration.pages.length === 0
    ) {
        return (
            <CenterDiv>
                <h3>{trans("You currently have no pages")}</h3>

                {can("dam-microsite-admin") && (
                    <Button onClick={() => setShowingPageCreator(true)}>
                        {trans("Add page")}
                    </Button>
                )}
                <PageCreator
                    groups={groups}
                    showing={showingPageCreator}
                    currentPage={0}
                    pages={[]}
                    onClose={() => setShowingPageCreator(false)}
                    onChange={(pages) => {
                        const { selectedSlug } = updatePages(
                            standardOnChange,
                            asset,
                            pages
                        );

                        history.push(`${editRouteMatch.url}/${selectedSlug}`);
                    }}
                />
            </CenterDiv>
        );
    }
    const [pageName, pageConfiguration] = pages[currentPage];
    const currentPageFullConfiguration = configuration.pages[pageName];

    // console.log(currentPageFullConfiguration);

    let basePath = `pages[${pageName}]`;

    let useFootnotes = false;
    const globalSettings = asset?.configuration?.globalSettings;
    if (Array.isArray(globalSettings)) {
        // console.log(globalSettings);
        const useFootnotesSettings = globalSettings.find((setting) => {
            return setting.name === "useFootnotes";
        });
        // console.log(useFootnotesSettings);
        if (useFootnotesSettings) {
            useFootnotes = useFootnotesSettings.value;
        }
    }

    if (choosingImage) {
        return (
            <React.Fragment>
                <Gallery chooseItem={selectImage} />
                <Button onClick={() => setChoosingImage(false)}>
                    {trans("Back to editor")}
                </Button>
            </React.Fragment>
        );
    }

    const hasSequencedVideo =
        currentPageFullConfiguration.content &&
        Array.isArray(currentPageFullConfiguration.content) &&
        currentPageFullConfiguration.content.some(
            ({ type }) => type === "sequenced-video"
        );
    // console.log(asset.configuration);
    return (
        <EditorContext.Provider
            value={{
                assetAdmin: assetAdmin,
                saveUpdatedAsset: saveUpdatedAsset,
            }}
        >
            {(hasAdminOption(baseAsset, "manageable_pages") ||
                pages.length > 1) && (
                <Row collapse style={{ display: "flex", margin: "0" }}>
                    <FlexColumn
                        collapse
                        expanded={true}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        {assetType !== "editableVideo" ? (
                            <>
                                {editingPageName ? (
                                    <>
                                        <div
                                            style={{
                                                width: "180px",
                                                marginRight: "1rem",
                                            }}
                                        >
                                            <Select
                                                marginBottom="0"
                                                label={trans("Section Name")}
                                                isDisabled={
                                                    !can("dam-microsite-admin")
                                                }
                                                name={`${pageName}_group`}
                                                value={
                                                    currentPageFullConfiguration.group
                                                }
                                                options={groups.map((group) => {
                                                    return {
                                                        text: group,
                                                        value: group,
                                                    };
                                                })}
                                                onChange={(event) => {
                                                    onConfigurationChange(
                                                        standardOnChange,
                                                        asset,
                                                        "group",
                                                        event,
                                                        `pages[${pageName}]`
                                                    );
                                                }}
                                            />
                                        </div>
                                        <InputField
                                            label={trans("Page Name")}
                                            style={{ marginBottom: "0" }}
                                            placeholder={trans("Page Name")}
                                            name={`${pageName}_name`}
                                            value={
                                                currentPageFullConfiguration.name
                                            }
                                            onChange={(event) => {
                                                onConfigurationChange(
                                                    standardOnChange,
                                                    asset,
                                                    "name",
                                                    event,
                                                    `pages[${pageName}]`
                                                );
                                            }}
                                        />
                                    </>
                                ) : (
                                    <SectionHeading>
                                        {pageConfiguration.group ? (
                                            <>{`${pageConfiguration.group}: `}</>
                                        ) : (
                                            <>{trans("Page: ")}</>
                                        )}

                                        {pageConfiguration.name ||
                                            currentPage + 1}
                                    </SectionHeading>
                                )}
                                {assetType !== "inDesign" && (
                                    <IconTextButton
                                        icon={faEdit}
                                        showCircle={false}
                                        onClick={() => {
                                            setEditingPageName(
                                                !editingPageName
                                            );
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <h3 style={{ marginBottom: "0" }}>
                                {pageConfiguration.displayName ||
                                    currentPage + 1}
                            </h3>
                        )}
                    </FlexColumn>

                    {hasAdminOption(baseAsset, "manageable_pages") ? (
                        <Column
                            medium={8}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {can("dam-microsite-admin") && (
                                <IconTextButton
                                    onClick={() =>
                                        setShowingPageCreator(
                                            !showingPageCreator
                                        )
                                    }
                                    showCircle={false}
                                    expanded={false}
                                    icon={faPlus}
                                >
                                    {trans("Add Page")}
                                </IconTextButton>
                            )}
                            <IconTextButton
                                onClick={() =>
                                    setShowingPageNavigator(
                                        !showingPageNavigator
                                    )
                                }
                                rotation={showingPageNavigator ? 270 : 90}
                                showCircle={false}
                                expanded={false}
                            >
                                {trans("Page Navigator")}
                            </IconTextButton>
                        </Column>
                    ) : (
                        <Column
                            large={6}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            collapse={true}
                        >
                            {hasAdminOption(
                                baseAsset,
                                "dropdown_pagination"
                            ) ? (
                                <Select
                                    value={currentPage}
                                    options={pages.map((page, index) => {
                                        const pageSettings = page[1];
                                        return {
                                            text: pageSettings.displayName
                                                ? pageSettings.displayName
                                                : pageSettings.name,
                                            value: index,
                                        };
                                    })}
                                    onChange={(event) =>
                                        handlePagePaginationUpdate(
                                            pages,
                                            {
                                                selected: !event.value
                                                    ? 0
                                                    : event.value,
                                            },
                                            standardOnChange
                                        )
                                    }
                                    width={"100%"}
                                />
                            ) : (
                                <>
                                    {pages.length > 1 ? (
                                        <Pagination
                                            currentPage={currentPage}
                                            // previousLabel={"<<"}
                                            // nextLabel={">>"}
                                            // breakLabel={"..."}
                                            pageCount={pages.length}
                                            onPageChange={(event) =>
                                                handlePagePaginationUpdate(
                                                    pages,
                                                    event,
                                                    standardOnChange
                                                )
                                            }
                                            margin="0"
                                        />
                                    ) : (
                                        <span>&nbsp;</span>
                                    )}
                                </>
                            )}
                        </Column>
                    )}
                </Row>
            )}
            {hasAdminOption(baseAsset, "manageable_pages") && (
                <React.Fragment>
                    <PageNavigator
                        showing={showingPageNavigator}
                        pages={pages}
                        currentPage={page}
                        onPageChange={(event) =>
                            handlePageNavigation(pages, event)
                        }
                        onPageDelete={(slug) => handlePageDelete(slug)}
                        onChange={(pages) => {
                            const { newPages, selectedSlug } = updatePages(
                                standardOnChange,
                                asset,
                                pages
                            );
                            handlePageNavigation(newPages, selectedSlug);
                        }}
                    />
                    <PageCreator
                        groups={groups}
                        showing={showingPageCreator}
                        currentPage={currentPage}
                        pages={pages}
                        onClose={(event) => setShowingPageCreator(false)}
                        onChange={(pages) => {
                            const { newPages, selectedSlug } = updatePages(
                                standardOnChange,
                                asset,
                                pages
                            );

                            handlePageNavigation(newPages, selectedSlug);
                        }}
                    />
                </React.Fragment>
            )}
            <FlexRow>
                <EditorColumn large={4}>
                    <Tabordion
                        current={0}
                        unmounts={false}
                        // alwaysShowTabs={false}
                    >
                        <TabordionContentWrapper
                            heading={<span>{trans("Content")}</span>}
                        >
                            <PageEditor
                                basePath={
                                    assetType !== "inDesign"
                                        ? `${basePath}.content`
                                        : `${basePath}`
                                }
                                asset={asset}
                                baseAsset={baseAsset}
                                onChange={onPageEdit}
                                fullAdmin={fullAdmin}
                                editingUserAsset={editingUserAsset}
                                pageElements={
                                    assetType !== "inDesign"
                                        ? pageConfiguration.content
                                        : pageConfiguration
                                }
                                chooseImage={chooseImage}
                                useFootnotes={useFootnotes}
                                autofill={autofill}
                            />
                        </TabordionContentWrapper>
                        {hasAdminOption(baseAsset, "sequencing") &&
                            !hasSequencedVideo && (
                                <TabordionContentWrapper
                                    heading={<span>{trans("Sequencing")}</span>}
                                >
                                    <TransitionEditor
                                        basePath={`${basePath}`}
                                        asset={asset}
                                        onChange={(event) => {
                                            onConfigurationChange(
                                                standardOnChange,
                                                asset,
                                                "transitions",
                                                event,
                                                `pages[${pageName}]`
                                            );
                                        }}
                                        fullAdmin={fullAdmin}
                                        transitions={
                                            pageConfiguration.transitions
                                        }
                                        content={pageConfiguration.content}
                                    >
                                        {" "}
                                        <Row>
                                            <Column>
                                                <FormLabel
                                                    name={`${pageName}_disableSequencing`}
                                                    inlineLabel={true}
                                                >
                                                    <StyledCheckbox
                                                        id={`${pageName}_disableSequencing`}
                                                        inline={true}
                                                        name={`${pageName}_disableSequencing`}
                                                        checked={
                                                            currentPageFullConfiguration.disableSequencing
                                                                ? currentPageFullConfiguration.disableSequencing
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            onConfigurationChange(
                                                                standardOnChange,
                                                                asset,
                                                                "disableSequencing",
                                                                {
                                                                    ...event,
                                                                    name: event
                                                                        .target
                                                                        .name,
                                                                    value: !!event
                                                                        .target
                                                                        .checked,
                                                                },
                                                                `pages[${pageName}]`
                                                            );
                                                        }}
                                                    />
                                                    {trans(
                                                        "Disable Sequencing"
                                                    )}
                                                </FormLabel>
                                            </Column>
                                        </Row>
                                    </TransitionEditor>
                                </TabordionContentWrapper>
                            )}
                        {hasAdminOption(baseAsset, "settings") && (
                            <TabordionContentWrapper
                                heading={<span>{trans("Settings")} </span>}
                            >
                                <PageEditor
                                    basePath={`${basePath}.settings`}
                                    asset={asset}
                                    onChange={onPageEdit}
                                    fullAdmin={fullAdmin}
                                    editingUserAsset={editingUserAsset}
                                    pageElements={pageConfiguration.settings}
                                    chooseImage={chooseImage}
                                    useFootnotes={false}
                                >
                                    {hasAdminOption(
                                        asset,
                                        "standard_page_settings"
                                    ) && (
                                        <StandardPageSettingsEditor
                                            pageName={pageName}
                                            currentPageFullConfiguration={
                                                currentPageFullConfiguration
                                            }
                                            onChange={onPageEdit}
                                        />
                                    )}
                                </PageEditor>
                            </TabordionContentWrapper>
                        )}
                        {hasAdminOption(
                            baseAsset,
                            "additional_information"
                        ) && (
                            <TabordionContentWrapper
                                heading={<span>{trans("Additional")}</span>}
                            >
                                <PageEditor
                                    basePath={`${basePath}.additionalInformation`}
                                    asset={asset}
                                    baseAsset={baseAsset}
                                    onChange={onPageEdit}
                                    fullAdmin={fullAdmin}
                                    editingUserAsset={editingUserAsset}
                                    pageElements={
                                        pageConfiguration.additionalInformation
                                    }
                                    chooseImage={chooseImage}
                                    useFootnotes={useFootnotes}
                                />
                            </TabordionContentWrapper>
                        )}
                        {hasAdminOption(baseAsset, "background_images") && (
                            <TabordionContentWrapper
                                heading={
                                    <span>{trans("Background Images")}</span>
                                }
                            >
                                <BackgroundImageEditor
                                    basePath={`${basePath}`}
                                    asset={asset}
                                    baseAsset={baseAsset}
                                    onChange={onPageEdit}
                                    fullAdmin={fullAdmin}
                                    editingUserAsset={editingUserAsset}
                                    pageElements={
                                        pageConfiguration.backgroundImages
                                    }
                                    chooseImage={chooseImage}
                                    useFootnotes={false}
                                />
                            </TabordionContentWrapper>
                        )}
                    </Tabordion>
                </EditorColumn>
                <PreviewColumn large={8}>
                    {editRouteMatch && (
                        <PreviewContainer>
                            <AssetPreview
                                asset={asset}
                                page={pageName}
                                configuration={configuration}
                                editRouteMatch={editRouteMatch}
                            />
                        </PreviewContainer>
                    )}
                </PreviewColumn>
            </FlexRow>
            {deletePageModalShowing && (
                <Modal
                    visible={deletePageModalShowing}
                    onClose={() => {
                        setDeletePageModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h2>
                            {trans("You are about to delete page")}:{" "}
                            {deletePageSlug}
                        </h2>

                        <p>{trans("Are you sure you want to proceed?")}</p>
                        <Button
                            type="linkStyle"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeletePageModalShowing(false);
                            }}
                        >
                            {trans("Cancel")}
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                deletePage();
                            }}
                        >
                            {trans("Delete")}
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}
        </EditorContext.Provider>
    );
};

export default Editor;
