import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { get } from "lodash";

import {
    Row,
    Column,
    Button,
    SubmitButton,
    InputField,
    TextareaField,
    useAddToast,
    CustomFormFields,
    FormRow,
    Panel,
    DraftJSOutput,
} from "@cortexglobal/rla-components";

import {
    objectToFormData,
    parseValidationErrors,
} from "@cortexglobal/cortex-utilities";

export const ButtonRow = styled(Row)`
    width: 100%;
    padding: 0 0.6rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

const RequestForm = ({
    selectedRequestType,
    request,
    setRequest = (request) => {},
    onGoBack,
    relatedRef,
    action,
    requestsPath,
    setLoading = (loading) => {},
}) => {
    const addToast = useAddToast();
    const [saving, setSaving] = useState(false);
    const [formValues, setFormValues] = useState({});

    useEffect(() => {}, [selectedRequestType]);

    useEffect(() => {
        let fv = {
            title: request?.title || null,
            description: request?.description || null,
            request_type_uuid:
                request?.type?.uuid || selectedRequestType?.uuid || null,
            request_uuid: request?.uuid || null,
            smart_ref: relatedRef || null,
            custom_form_uuids: [selectedRequestType?.custom_form?.uuid] || null,
        };

        const answers = request?.custom_form_field_answers;
        Array.isArray(answers) &&
            answers.map((fieldAnswer) => {
                const { field_name, value } = fieldAnswer;

                fv[field_name] = value;
            });

        setFormValues(fv);
    }, [request, selectedRequestType]);

    const [errors, setErrors] = useState({});

    const onChange = ({ name, value }) => {
        if (value instanceof FileList) {
            value = Array.from(value);
        }

        setFormValues({ ...formValues, [name]: value });
    };

    const form = get(selectedRequestType, "custom_form.form");

    const submitForm = () => {
        setSaving(true);
        setLoading(true);

        const formData = objectToFormData(formValues);

        if (action === "editRequest") {
            const requestUuid = request.uuid;

            axios
                .post(`/api/v1/requests/${requestUuid}`, formData)
                .then(({ data }) => {
                    const request = data.data;
                    setRequest(request);
                    onGoBack();
                })
                .catch((e) => {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    });

                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                })
                .finally(() => {
                    setSaving(false);
                    setLoading(false);
                });
        } else {
            axios
                .post("/api/v1/requests", formData)
                .then(({ data }) => {
                    const { uuid } = data.data;
                    window.location.href = `${requestsPath}/view/${uuid}`;
                })
                .catch((e) => {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    });

                    addToast({
                        type: "alert",
                        content: e.response.data.message,
                        showFor: 5000,
                    });
                })
                .finally(() => {
                    setSaving(false);
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Row>
                <h3>{selectedRequestType.name}</h3>
                {selectedRequestType.description && (
                    <DraftJSOutput>
                        {selectedRequestType.description}
                    </DraftJSOutput>
                )}
                <Panel>
                    <Row>
                        <Column>
                            <FormRow
                                name="title"
                                label={trans("Title")}
                                helpText={trans("Required")}
                                error={errors.title}
                                required={true}
                            >
                                <InputField
                                    name="title"
                                    value={formValues.title}
                                />
                            </FormRow>
                            <FormRow
                                name="description"
                                label={trans("Description")}
                                error={errors.description}
                            >
                                <TextareaField
                                    name="description"
                                    value={formValues.description}
                                />
                            </FormRow>

                            {form && (
                                <CustomFormFields
                                    form={form}
                                    values={formValues}
                                    errors={errors}
                                    onChange={onChange}
                                    disabled={saving}
                                />
                            )}
                        </Column>
                    </Row>
                </Panel>
            </Row>
            <Panel style={{ position: "sticky", bottom: 0 }}>
                <ButtonRow style={{ textAlign: "right" }}>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            onGoBack();
                        }}
                        type="alert"
                        disabled={saving}
                        style={{ margin: "0 1rem" }}
                    >
                        {trans("Cancel")}
                    </Button>

                    <SubmitButton
                        label={trans("Save")}
                        submitting={saving}
                        type="primary"
                        onClick={submitForm}
                    />
                </ButtonRow>
            </Panel>
        </>
    );
};

export default RequestForm;
