import React, { useState } from "react";

import {
    Modal,
    Row,
    Column,
    IconTextButton,
    Tooltip,
    MultiCheckbox,
    Loading,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { useDownloadAsset } from "../hooks/useDownloadAsset";

const DownloadModal = ({ asset, visible, onClose, messages, configLoaded }) => {
    const { downloadAssetFiles } = useDownloadAsset();
    const [termsAccepted, setTermsAccepted] = useState([]);
    const intl = useIntl();

    const getMessageKey = (key) => {
        return messages && messages[`${key}-download`]
            ? messages[`${key}-download`]
            : intl.formatMessage({
                  id: "DownloadDisclaimerText",
              });
    };

    const confirmationText = (key) => {
        return messages ? messages[`${key}-download-confirmation`] : false;
    };

    const onChange = (item) => {
        setTermsAccepted(item.value);
    };

    const disableButton = () => {
        if (confirmationText(asset.status) && termsAccepted.length === 0) {
            return true;
        }
        return false;
    };

    if (!configLoaded) {
        return <Loading />;
    }

    const assetType =
        typeof asset.asset_type === "string"
            ? asset.asset_type
            : asset?.asset_type?.slug;
    return (
        <Modal visible={visible} onClose={onClose} zIndex={0} maxWidth="90%">
            <Row>
                <Column>
                    <h3>{trans("Asset Download")}</h3>
                </Column>
            </Row>
            <Row>
                <Column>
                    <p>
                        {intl.formatMessage({
                            id: getMessageKey(asset.status),
                        })}
                    </p>
                </Column>
            </Row>
            {confirmationText(asset.status) && (
                <Row>
                    <Column>
                        <MultiCheckbox
                            name="accepted_terms"
                            options={[
                                {
                                    value: "accepted",
                                    name: "accepted_terms",
                                    text: intl.formatMessage({
                                        id: confirmationText(asset.status),
                                    }),
                                },
                            ]}
                            value={termsAccepted}
                            onChange={onChange}
                        />
                    </Column>
                </Row>
            )}

            <Row>
                {asset.downloadable && (
                    <Column large={6}>
                        <IconTextButton
                            type="secondary"
                            onClick={(event) => {
                                downloadAssetFiles(
                                    asset.uuid,
                                    asset.name +
                                        "." +
                                        asset.main_file_extension,
                                    "download"
                                );
                                onClose();
                            }}
                            disabled={disableButton()}
                        >
                            {trans("Download now")}
                        </IconTextButton>
                    </Column>
                )}
                {asset.pdfable && (
                    <React.Fragment>
                        {assetType !== "presentation" && (
                            <Column large={6}>
                                <IconTextButton
                                    type="secondary"
                                    expanded
                                    onClick={(event) =>
                                        downloadAssetFiles(
                                            asset.uuid,
                                            asset.name + ".pdf",
                                            "download-pdf",
                                            "quality=print"
                                        )
                                    }
                                    disabled={disableButton()}
                                >
                                    <Tooltip
                                        text={trans(
                                            "High quality PDF with crop marks, for use with commercial printers."
                                        )}
                                    >
                                        {trans(
                                            "Download Print Ready PDF (High-Res)"
                                        )}
                                    </Tooltip>
                                </IconTextButton>
                            </Column>
                        )}
                        <Column large={6}>
                            <IconTextButton
                                type="secondary"
                                expanded
                                onClick={(event) => {
                                    if (!asset.files_ready) {
                                        alert(
                                            "The PDF is not ready to download, please check back later"
                                        );
                                        return false;
                                    }
                                    downloadAssetFiles(
                                        asset.uuid,
                                        asset.name + ".pdf",
                                        "download-pdf",
                                        "quality=high"
                                    );
                                }}
                                disabled={disableButton()}
                            >
                                <Tooltip
                                    text={
                                        !asset.files_ready
                                            ? trans(
                                                  "PDF is still being created, please check back later"
                                              )
                                            : assetType === "presentation"
                                            ? trans("Download a PDF version")
                                            : trans("Download PDF (High-Res)")
                                    }
                                >
                                    {assetType === "presentation"
                                        ? trans("Download PDF")
                                        : trans("Download PDF (High-Res)")}
                                </Tooltip>
                            </IconTextButton>
                        </Column>
                    </React.Fragment>
                )}
                {assetType === "presentation" && asset.pdfable && (
                    <Column large={6}>
                        <IconTextButton
                            type="secondary"
                            expanded
                            onClick={(event) => {
                                if (!asset.files_ready) {
                                    alert(
                                        "Files are not ready to download, please check back later"
                                    );
                                    return false;
                                }
                                downloadAssetFiles(
                                    asset.uuid,
                                    asset.name + ".zip",
                                    "download-pdf-zip"
                                );
                            }}
                            disabled={disableButton()}
                        >
                            <Tooltip
                                text={
                                    asset.files_ready
                                        ? trans(
                                              "Download a bundle containing a PDF and supporting files, this can be quite large so the download may take some time"
                                          )
                                        : trans(
                                              "Files are not ready to download, please check back later"
                                          )
                                }
                            >
                                {trans("Export PDF Bundle")}
                            </Tooltip>
                        </IconTextButton>
                    </Column>
                )}
                {asset.imageZippable && (
                    <Column large={6}>
                        <IconTextButton
                            type="secondary"
                            expanded
                            onClick={(event) =>
                                downloadAssetFiles(
                                    asset.uuid,
                                    asset.name + ".zip",
                                    "download-image-zip"
                                )
                            }
                        >
                            {trans("Download Image Zip")}
                        </IconTextButton>
                    </Column>
                )}
            </Row>
        </Modal>
    );
};

export default DownloadModal;
