import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Row } from "@cortexglobal/rla-components";

export const FlexPanel = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
`;

export const FlexRow = styled(Row)`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;
export const FlexSection = styled.section`
    padding: 0.5rem 1rem;
    text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
`;

export const StickyRow = styled(Row)`
    position: sticky;
    bottom: 0;
    background-color: ${({ theme }) =>
        theme.dashboard.panel.content.background};
    padding-top: 0.5rem;
`;
