import React from "react";
import styled from "styled-components";

import {
    trans,
    FormattedCurrency,
    FormattedNumberWithLoader,
} from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Panel,
    StyledStarRating,
} from "@cortexglobal/rla-components";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import TaskResultsForm from "../forms/TaskResultsForm";
const Wrapper = styled.dl`
    margin: 0 1rem;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1rem;
`;
const ResultItemWrapper = styled(Panel)`
    display: flex;
    flex-direction: column;
    margin: 0;
    /* width: 32%; */
    padding: 1rem 0.8rem;
    text-align: center;
    aspect-ratio: 1;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
`;
const ActualValue = styled.dd`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3rem;
    color: ${({ difference, theme }) =>
        difference > 0 ? theme.colors.success : theme.colors.alert};

    margin: 0;
    small {
        font-size: 0.5em;
        display: block;
    }
`;
const EstimatedValue = styled.dd`
    font-size: 1em;
    margin: 0;
    small {
        display: block;
        font-weight: bold;
    }
`;
const Label = styled.dt`
    margin: 0 0.5rem;
    font-size: 1.4rem;
    /* flex-grow: 1; */
    width: 100%;
`;

const Value = ({ user, value, currency }) => {
    if (currency) {
        return (
            <FormattedCurrency
                value={value}
                currency={user.currency.iso_code || "GBP"}
            />
        );
    }
    return <FormattedNumberWithLoader value={value} loaded={true} />;
};
const ResultItem = ({
    title,
    label,
    actual,
    estimate,
    user,
    currency = true,
}) => {
    const difference = isNaN(actual) || isNaN(estimate) ? 0 : actual - estimate;

    return (
        <ResultItemWrapper>
            <Label>{title}</Label>
            <ActualValue difference={difference}>
                <Value currency={currency} user={user} value={actual || 0} />
                <small>
                    <Value currency={currency} user={user} value={difference} />
                </small>
            </ActualValue>
            <EstimatedValue>
                {label}{" "}
                <small>
                    <Value currency={currency} user={user} value={estimate} />
                </small>
            </EstimatedValue>
        </ResultItemWrapper>
    );
};

const Rating = ({ value }) => {
    if (value === null) {
        return <span>{trans("N/A")}</span>;
    }
    return <StyledStarRating editing={false} value={value} fontSize="26px" />;
};

const TaskResult = ({ task, onSave, onCancel, user }) => {
    const { can } = usePermissions();
    if (!task.results_submitted && can("dam-order-add-results")) {
        return (
            <TaskResultsForm task={task} onSave={onSave} onCancel={onCancel} />
        );
    }

    if (!task.results_submitted) {
        return (
            <Row>
                <Column style={{ margin: "2rem auto", paddingBottom: "2rem" }}>
                    <h2>{trans("No Results Available")}</h2>
                    <p>
                        {trans(
                            "If this is a recent order you will need for it to be fulfilled, otherwise contact your supplier to add the results"
                        )}
                    </p>
                </Column>
            </Row>
        );
    }
    return (
        <>
            <Row style={{ marginTop: "1.2rem" }}>
                <Column>
                    <h3>{trans("Campaign Performance")}</h3>
                </Column>
            </Row>
            <Wrapper>
                <ResultItem
                    title={trans("Marketing Spend")}
                    actual={task.actual_spend.value}
                    estimate={task.planned_spend.value}
                    label={trans("Planned")}
                    user={user}
                    currency={true}
                />
                {task.metrics.map((metric) => {
                    return (
                        <ResultItem
                            key={metric.metric_uuid}
                            title={metric.name}
                            actual={metric.actual_value}
                            estimate={metric.estimated_value || trans("N/A")}
                            user={user}
                            currency={metric.type === "currency"}
                            label={trans("Estimated")}
                        />
                    );
                })}
            </Wrapper>

            <Row style={{ marginTop: "1.2rem" }}>
                <Column>
                    <h3>{trans("Campaign Ratings")}</h3>
                </Column>
                <Column small={3}>
                    <h4>{trans("Customer Rating")}</h4>
                    <Rating value={task.group_customer_rating} />
                </Column>
                <Column small={3}>
                    <h4>{trans("Internal Rating")}</h4>
                    <Rating value={task.group_internal_rating} />
                </Column>
                <Column small={3}>
                    <h4>{trans("External Rating")}</h4>
                    <Rating value={task.group_external_rating} />
                </Column>
                <Column small={3}>
                    <h4>{trans("Head Office Rating")}</h4>
                    <Rating value={task.head_office_rating} />
                </Column>
            </Row>
        </>
    );
};

export default TaskResult;
