import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { trans } from "@cortexglobal/rla-intl";

import SurveyManagementContext from "../SurveyManagementContext";
import SurveyLoading from "../components/SurveyLoading";
import SurveyDetails from "../components/SurveyDetails";
import { Panel } from "@cortexglobal/rla-components";

const ViewSurvey = ({ path, loadSurvey }) => {
    const { uuid } = useParams();

    const { surveySystem } = useContext(SurveyManagementContext);
    const { loading, error } = surveySystem;

    const [surveyData, setSurveyData] = useState({});

    useEffect(() => {
        loadSurvey(uuid).then((survey) => {
            setSurveyData(survey);
        });
    }, [uuid]);

    if (loading) {
        return (
            <>
                <SurveyLoading showLinks={true} path={path} />
            </>
        );
    }

    return <SurveyDetails path={path} surveyData={surveyData} />;
};

export default ViewSurvey;
