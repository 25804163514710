import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Badge,
    Column,
    IconTextButton,
    LoadingIconSmall,
    Row,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { usePermissions } from "@cortexglobal/cortex-auth-redux";

const PlanPanel = ({ year, month }) => {
    const { can } = usePermissions();

    const [loading, setLoading] = useState(false);

    const [plan, setPlan] = useState({
        name: "...",
        readable_status: "...",
        status: "...",
    });

    useEffect(() => {
        getPlan(year, month);
    }, [year, month]);

    const getPlan = (year, month) => {
        setLoading(true);

        const { status } = plan;

        setPlan({
            ...plan,
            readable_status: "...",
        });

        axios
            .get(`/api/v1/planner/plan`, {
                params: {
                    year: year,
                    month: month,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setPlan(data.data);
            })
            .catch(() => {
                setLoading(false);

                setPlan({
                    ...plan,
                    readable_status: status,
                });
            });
    };

    const submitForApproval = (plan) => {
        setLoading(true);

        const { status } = plan;

        setPlan({
            ...plan,
            readable_status: "...",
        });

        axios
            .put(`/api/v1/planner/plan/${plan.uuid}/submit`)
            .then(({ data }) => {
                setLoading(false);
                setPlan(data.data);
            })
            .catch(() => {
                setLoading(false);

                setPlan({
                    ...plan,
                    readable_status: status,
                });
            });
    };

    const statusColor = (status) => {
        switch (status) {
            case "rejected":
                return "alert";
            case "approved":
                return "success";
            default:
                return "warning";
        }
    };

    if (loading === true) {
        return (
            <Row>
                <Column>
                    <LoadingIconSmall />
                </Column>
            </Row>
        );
    }

    return (
        <Row>
            <Column>
                <h6
                    style={{
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        marginBottom: "0rem",
                    }}
                >
                    Month
                </h6>
                <h3 style={{ marginBottom: "1rem" }}>
                    <small style={{ fontWeight: "normal" }}>
                        {plan.name}
                        <br />
                    </small>
                </h3>

                <h6
                    style={{
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        marginBottom: "0rem",
                    }}
                >
                    <b>Status</b>
                </h6>
                <Badge type={statusColor(plan.status)}>
                    {plan.readable_status}
                </Badge>
            </Column>

            {can("planner-submit-plans") ? (
                <Column style={{ marginTop: "1rem" }}>
                    {(plan.status === "draft" ||
                        plan.status === "rejected") && (
                        <IconTextButton
                            icon="check"
                            style={{ paddingLeft: 0 }}
                            expanded
                            onClick={() => submitForApproval(plan)}
                            showCircle={true}
                        >
                            {trans("Create")}
                        </IconTextButton>
                    )}

                    {plan.status === "awaiting-approval" && (
                        <p>
                            {trans("This plan is currently awaiting approval.")}
                        </p>
                    )}

                    {plan.status === "approved" && (
                        <p>{trans("This plan has been approved.")}</p>
                    )}
                </Column>
            ) : (
                <Column style={{ marginTop: "1rem" }}>
                    {plan.status === "awaiting-approval" && (
                        <p>
                            {trans("This plan is currently awaiting approval.")}
                        </p>
                    )}

                    {plan.status === "draft" && (
                        <p>{trans("This plan has not been submitted yet.")}</p>
                    )}

                    {plan.status === "approved" && (
                        <p>{trans("This plan has been approved.")}</p>
                    )}

                    {plan.status === "rejected" && (
                        <p>{trans("This plan has been rejected.")}</p>
                    )}
                </Column>
            )}
        </Row>
    );
};

export default PlanPanel;
