import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
    Column,
    PageTitle,
    Row,
    Loading,
    TableList,
    useAddToast,
    LoadingIconSmall,
    Button,
    Tabordion,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import FileUploader from "./components/FileUploader";
import moment from "moment";
import { saveAs } from "file-saver";
import mimeDb from "mime-db";

const TabordionGroup = ({ children, ...rest }) => {
    return <div {...rest}>{children}</div>;
};

const Imports = (props) => {
    const dateFormat = "DD/MM/YYYY HH:mm";

    const addToast = useAddToast();

    const [imports, setImports] = useState([]);
    const [loadingState, setLoadingState] = useState("loaded");
    const [importsLoadingState, setImportsLoadingState] = useState("loaded");
    const [tab, setTab] = useState(0);

    useEffect(() => {
        getImports();
    }, []);

    const getImports = () => {
        setImportsLoadingState("loading");

        axios
            .get(`/api/v1/data-imports`)
            .then(({ data: { data } }) => {
                setImports(data);

                setImportsLoadingState("loaded");
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const loadingCallback = () => {
        setLoadingState("loading");
    };

    const loadedCallback = () => {
        setLoadingState("loaded");
    };

    const successCallback = (data) => {
        addToast({
            type: "success",
            content: trans("File uploaded"),
            showFor: 5000,
        });

        getImports();
    };

    const errorCallback = (e) => {
        addToast({
            type: "alert",
            content:
                e.response && e.response.data
                    ? e.response.data.message
                    : trans("An error has occurred"),
            showFor: 5000,
        });
    };

    const download = (url, filename) => {
        axios
            .get(url, {
                responseType: "blob",
                timeout: 30000,
            })
            .then((response) => {
                //Extract the extension from the content-type header
                let extension = "";
                const contentType = response.headers["content-type"];
                const mimeInfo = mimeDb[contentType];
                const date = Date.now();

                if (mimeInfo) {
                    extension = mimeInfo.extensions[0];
                }

                saveAs(
                    new Blob([response.data]),
                    `${date}_${filename}${extension && `.${extension}`}`
                );
            })
            .catch((e) => console.log(e));
    };

    return (
        <div id="data-imports" style={{ marginBottom: "20px" }}>
            <PageTitle title={trans("Data Imports")}></PageTitle>

            <Row>
                <Column medium={12}>
                    <FileUploader
                        loadingCallback={loadingCallback}
                        loadedCallback={loadedCallback}
                        successCallback={successCallback}
                        errorCallback={errorCallback}
                    />
                </Column>
            </Row>

            {loadingState === "loading" && (
                <Row>
                    <Column medium={12}>
                        <LoadingIconSmall
                            style={{ display: "block", margin: "0 auto" }}
                        />
                        <p style={{ textAlign: "center" }}>Uploading...</p>
                    </Column>
                </Row>
            )}

            <Row>
                <Column medium={12}>
                    <Tabordion current={tab} unmounts={false}>
                        <TabordionGroup
                            heading={<span>{trans("Import Log")}</span>}
                            onTabClick={() => setTab(0)}
                        >
                            <TableList>
                                <thead>
                                    <tr>
                                        <th>{trans("File")}</th>
                                        <th>{trans("Type")}</th>
                                        <th>{trans("Uploaded")}</th>
                                        <th>{trans("Processed")}</th>
                                        <th>{trans("Status")}</th>
                                        <th>{trans("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {importsLoadingState === "loading" ? (
                                        <tr>
                                            <td colSpan="100%">
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : (
                                        imports.map((i) => {
                                            return (
                                                <tr key={i.uuid}>
                                                    <td>
                                                        {i.original_file_name}
                                                    </td>
                                                    <td>{i.import_type}</td>
                                                    <td>
                                                        {i.start_time
                                                            ? moment(
                                                                  i.start_time
                                                              ).format(
                                                                  dateFormat
                                                              )
                                                            : ""}
                                                    </td>
                                                    <td>
                                                        {i.end_time
                                                            ? moment(
                                                                  i.end_time
                                                              ).format(
                                                                  dateFormat
                                                              )
                                                            : ""}
                                                    </td>
                                                    <td>{i.status_wording}</td>
                                                    <td></td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </TableList>
                        </TabordionGroup>
                        {/** TODO : This downloads section needs to be config or db driven */}
                        {true && (
                            <TabordionGroup
                                heading={<span>{trans("Downloads")}</span>}
                                onTabClick={() => setTab(1)}
                            >
                                <TableList>
                                    <thead>
                                        <tr>
                                            <th>{trans("File")}</th>
                                            <th style={{ width: "180px" }}>
                                                {trans("Actions")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={1}>
                                            <td>
                                                {trans(
                                                    "Export Example User File"
                                                )}
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={() =>
                                                        window.open(
                                                            `${process.env.REACT_APP_BASE_URL}/downloads/cortex-user-import.xlsx`
                                                        )
                                                    }
                                                >
                                                    {trans("Download")}
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr key={2}>
                                            <td>
                                                {trans("Export All Assets")}
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={() =>
                                                        download(
                                                            "/api/v1/assets/import/download?include_all=1",
                                                            "all-asset-export"
                                                        )
                                                    }
                                                >
                                                    {trans("Download")}
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr key={3}>
                                            <td>
                                                {trans("Export Draft Assets")}
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={() =>
                                                        download(
                                                            "/api/v1/assets/import/download",
                                                            "draft-asset-export"
                                                        )
                                                    }
                                                >
                                                    {trans("Download")}
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr key={4}>
                                            <td>{trans("Export Groups")}</td>
                                            <td>
                                                <Button
                                                    onClick={() =>
                                                        download(
                                                            "/api/v1/groups/import/download",
                                                            "dealerships-export"
                                                        )
                                                    }
                                                >
                                                    {trans("Download")}
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </TableList>
                            </TabordionGroup>
                        )}
                    </Tabordion>
                </Column>
            </Row>
        </div>
    );
};

export default Imports;
