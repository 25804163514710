import React from "react";

import { Centred, Loading, TableList } from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import { trans } from "@cortexglobal/rla-intl";

const ActiveUsersListingOutput = ({ panelId, filterBy, perPage = 10 }) => {
    const panelData = usePanelData(panelId, `/api/v1/active-users`, {
        filter_by: filterBy,
        per_page: perPage
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }

    return (
        <div>
            <TableList className="dashboard-table">
                <thead>
                    {[
                        "active-users-by-region",
                        "active-users-in-group",
                        "active-users-by-region-gg",
                        "active-users-in-group-gg"
                    ].includes(filterBy) && (
                        <tr>
                            <th>{trans("Name")}</th>
                            <th>{trans("Active %")}</th>
                            <th>{trans("Not Active %")}</th>
                        </tr>
                    )}
                </thead>
                <tbody>
                    {panelData.data ? (
                        panelData.data.map((item, index) => {
                            if (
                                [
                                    "active-users-by-region",
                                    "active-users-in-group",
                                    "active-users-by-region-gg",
                                    "active-users-in-group-gg"
                                ].includes(filterBy)
                            ) {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.name}</td>
                                        <td>{item.activeUserCount}</td>
                                        <td>{item.inactiveUserCount}</td>
                                    </tr>
                                );
                            }
                        })
                    ) : (
                        <Loading align="center" padding="1rem" />
                    )}
                </tbody>
            </TableList>
        </div>
    );
};

export default ActiveUsersListingOutput;
