import React, { useState } from "react";
import PropTypes from "prop-types";

import {
    Row,
    Column,
    SelectField,
    Button,
    InputField,
    StyledDateRangePicker,
    Label,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

const AssetTotalsConfiguration = ({
    panel,
    panelId,
    submitPanel,
    title,
    timeFrame,
    timeValue,
    status,
    group,
}) => {
    const intl = useIntl();

    const [showXDays, setShowXDays] = useState(timeFrame === "last-x-days");
    const [showXMonths, setShowXMonths] = useState(
        timeFrame === "last-x-months"
    );
    const [showCustomDateRange, setShowCustomDateRange] = useState(
        timeFrame === "custom"
    );

    const [state, setState] = useState({
        title: title ? title : intl.formatMessage({ id: "Asset Totals" }),
        timeFrame: timeFrame ? timeFrame : "",
        timeValue: timeValue ? timeValue : "",
        status: status ? status : "",
        group: group ? group : "",
    });

    const timeFrameOptions = [
        { value: "all", text: intl.formatMessage({ id: "All Time" }) },
        {
            value: "this-week",
            text: intl.formatMessage({ id: "This Week" }),
        },
        {
            value: "this-month",
            text: intl.formatMessage({ id: "This Month" }),
        },
        {
            value: "this-quarter",
            text: intl.formatMessage({ id: "This Quarter" }),
        },
        {
            value: "this-year",
            text: intl.formatMessage({ id: "This Year" }),
        },
        {
            value: "last-week",
            text: intl.formatMessage({ id: "Last Week" }),
        },
        {
            value: "last-month",
            text: intl.formatMessage({ id: "Last Month" }),
        },
        {
            value: "last-quarter",
            text: intl.formatMessage({ id: "Last Quarter" }),
        },
        {
            value: "last-year",
            text: intl.formatMessage({ id: "Last Year" }),
        },
        {
            value: "last-x-days",
            text: intl.formatMessage({ id: "Last X Days" }),
        },
        {
            value: "last-x-months",
            text: intl.formatMessage({ id: "Last X Months" }),
        },
    ];

    const statusOptions = [
        { value: "downloaded", text: intl.formatMessage({ id: "Downloads" }) },
        { value: "viewed", text: intl.formatMessage({ id: "Views" }) },
        {
            value: "personalised",
            text: intl.formatMessage({ id: "Edits" }),
        },
        { value: "created", text: intl.formatMessage({ id: "Created" }) },
    ];

    const groupOptions = [
        { value: "all", text: intl.formatMessage({ id: "All Groups" }) },
        { value: "group", text: intl.formatMessage({ id: "My Group" }) },
        {
            value: "group-and-children",
            text: intl.formatMessage({ id: "My Group and Child Groups" }),
        },
    ];

    const onChange = ({ name, value }) => {
        setState({ ...state, [name]: value });
    };

    const onChangeTimeFrame = ({ value }) => {
        console.log(value);

        onChange({ name: "timeFrame", value });

        setShowXDays(false);
        setShowXMonths(false);
        setShowCustomDateRange(false);

        switch (value) {
            case "last-x-days":
                setShowXDays(true);
                break;
            case "last-x-months":
                setShowXMonths(true);
                break;
            case "custom":
                setShowCustomDateRange(true);
                break;
        }
    };

    return (
        <Row>
            <Column>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitPanel(panel, state, panelId);
                    }}
                >
                    <InputField
                        type="text"
                        name="title"
                        value={state.title}
                        label={trans("Custom Title")}
                        placeholder="Custom Title"
                        onChange={onChange}
                        labelStyle={{ marginBottom: "0" }}
                    />

                    <SelectField
                        name="timeFrame"
                        label={trans("Timeframe")}
                        value={state.timeFrame}
                        options={timeFrameOptions}
                        onChange={onChangeTimeFrame}
                        expanded
                    />

                    {showXDays && (
                        <InputField
                            type="number"
                            name="last_x_days"
                            value={state.timeValue}
                            label={trans("Number of Days")}
                            placeholder=""
                            onChange={(e) =>
                                onChange({ name: "timeValue", value: e.value })
                            }
                            labelStyle={{ marginBottom: "0" }}
                            defaultValue="7"
                        />
                    )}

                    {showXMonths && (
                        <InputField
                            type="number"
                            name="last_x_months"
                            value={state.timeValue}
                            label={trans("Number of Months")}
                            placeholder=""
                            onChange={(e) =>
                                onChange({ name: "timeValue", value: e.value })
                            }
                            labelStyle={{ marginBottom: "0" }}
                            defaultValue="6"
                        />
                    )}

                    {showCustomDateRange && (
                        <div>
                            <Label>
                                {trans("Custom Date Range")}
                                <div style={{ marginBottom: "1.2rem" }}>
                                    <StyledDateRangePicker
                                        name="date_range"
                                        onChange={(e) =>
                                            onChange({
                                                name: "timeValue",
                                                value: e.value,
                                            })
                                        }
                                        value={[
                                            new Date(state.from || null),
                                            new Date(state.to || null),
                                        ]}
                                        label={trans("Custom Date Range")}
                                        showUnitsSelector={false}
                                        showPeriodSelector={false}
                                        minDate={new Date()}
                                        maxDate={new Date()}
                                        units={"days"}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </Label>
                        </div>
                    )}

                    <SelectField
                        name="status"
                        label={trans("Metric")}
                        value={state.status}
                        options={statusOptions}
                        onChange={onChange}
                        expanded
                    />

                    <SelectField
                        name="group"
                        label={trans("Group Scope")}
                        value={state.group}
                        options={groupOptions}
                        onChange={onChange}
                        expanded
                    />
                    <Button type="submit">{trans("Save Panel")}</Button>
                </form>
            </Column>
        </Row>
    );
};

AssetTotalsConfiguration.propTypes = {
    submitPanel: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default AssetTotalsConfiguration;
