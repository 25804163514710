import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

const HeadContent = ({ settings }) => {
    return (
        <Helmet>
            {settings.title && <title>{settings.title}</title>}
            {settings.description && (
                <meta name="description" content={settings.description} />
            )}
        </Helmet>
    );
};

export default HeadContent;
