import React, { useState } from "react";
import axios from "axios";
import {
    Badge,
    Button,
    Column,
    FormRow,
    Modal,
    RadioField,
    Row,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const TaskActivationModal = ({
    visible,
    onClose,
    task,
    theme: plannerTheme,
    viewActivity,
    onSuccessCallback,
    ...props
}) => {
    const addToast = useAddToast();
    const intl = useIntl();

    const [submitting, setSubmitting] = useState(false);

    const [values, setValues] = useState(task.activities || []);

    const setStatus = (uuid, status) => {
        setValues([
            ...values.map((activity) => {
                if (activity.uuid !== uuid) {
                    return activity;
                }

                return {
                    ...activity,
                    status,
                };
            }),
        ]);
    };

    const onSubmit = () => {
        setSubmitting(true);

        axios
            .post(`/api/v1/planner/tasks/${task.uuid}/activate`, {
                activities: values,
            })
            .then(() => {
                addToast({
                    content: trans("Campaign successfully activated"),
                    type: "success",
                    showFor: 5000,
                });

                onSuccessCallback();
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: trans(
                        "An error occurred whilst activating the campaign. Please try again later."
                    ),
                    showFor: 5000,
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Modal visible={visible} onClose={() => onClose()} {...props}>
            <Row>
                <Column>
                    <h2>
                        <Badge
                            style={{
                                marginBottom: 0,
                                marginRight: "10px",
                            }}
                            backgroundColor={plannerTheme.colors.task.self}
                        >
                            {task.ref}
                        </Badge>
                        {trans("Activating campaign: {name}", task)}
                    </h2>
                </Column>
            </Row>

            {values.length > 0 && (
                <>
                    <Row>
                        <Column>
                            <p>
                                {trans(
                                    "Please choose whether you'd like to also change the status of the activities associated with this campaign."
                                )}
                            </p>
                        </Column>
                    </Row>

                    {values.map((activity) => (
                        <>
                            <Row>
                                <Column>
                                    <h3>
                                        <Badge
                                            backgroundColor={
                                                plannerTheme.colors.activities
                                                    .activity
                                            }
                                            style={{
                                                marginBottom: 0,
                                                marginTop: "10px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            {activity.ref}
                                        </Badge>
                                        <Link
                                            backgroundColor={
                                                plannerTheme.colors.activities
                                                    .activity
                                            }
                                            onClick={(e) => {
                                                viewActivity(task, activity);
                                            }}
                                        >
                                            {activity.name}
                                        </Link>
                                    </h3>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <FormRow
                                        name={`status[${activity.uuid}]`}
                                        label={trans("Status")}
                                        // error={errors.status[activity.uuid]}
                                    >
                                        <RadioField
                                            style={{ marginBottom: "1rem" }}
                                            name={`status[${activity.uuid}]`}
                                            onChange={(e) =>
                                                setStatus(
                                                    activity.uuid,
                                                    e.value
                                                )
                                            }
                                            disabled={submitting}
                                            value={activity.status}
                                            checked={
                                                activity.status === "draft"
                                            }
                                            inlineRadioButtons={true}
                                            options={[
                                                {
                                                    value: "draft",
                                                    text: intl.formatMessage({
                                                        id: "Draft",
                                                    }),
                                                },
                                                {
                                                    value: "planned",
                                                    text: intl.formatMessage({
                                                        id: "Planned",
                                                    }),
                                                },
                                                // {
                                                //     value: "complete",
                                                //     text: intl.formatMessage({
                                                //         id: "Complete",
                                                //     }),
                                                //     disabled: !isApproved,
                                                // },
                                            ]}
                                            showError={false}
                                        />
                                    </FormRow>
                                </Column>
                            </Row>
                        </>
                    ))}
                </>
            )}
            <Row>
                <Column>
                    <Button disabled={submitting} onClick={() => onClose()}>
                        {trans("Cancel")}
                    </Button>{" "}
                    <Button disabled={submitting} onClick={() => onSubmit()}>
                        {trans("Activate Now")}
                    </Button>
                </Column>
            </Row>
        </Modal>
    );
};

export default TaskActivationModal;
