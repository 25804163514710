import React, { useEffect, useState } from "react";
import axios from "axios";
import { withTheme } from "styled-components";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { format } from "@cortexglobal/cortex-utilities";

import {
    Row,
    Column,
    IconTextButton,
    SubmitButton,
    FormikInput,
    FormikCurrencyInput,
    FormikDatePicker,
    FormikAdvancedSelect,
    FormikTextarea,
    FormikRadioArray,
    FormRow,
    RadioField,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    addActivity,
    editActivity,
    startOrderProcess,
} from "../plannerActions";
import PlannerFormikMediaChannelSelect from "../components/PlannerFormikMediaChannelSelect";

const ActivitySchema = Yup.object().shape({
    start_date: Yup.date().required(trans("Please pick a start date")),
    end_date: Yup.date()
        .min(
            Yup.ref("start_date"),
            trans("End date must be after the start date")
        )
        .required(trans("Please pick an end date")),
    platform_asset: Yup.string().required(
        trans(
            "Please select whether you're choosing an asset from the platform"
        )
    ),
    fulfilment_type: Yup.string().when("platform_asset", {
        is: (platform_asset) => {
            return platform_asset === "yes";
        },
        then: Yup.string().required(trans("Please select a fulfilment type")),
        // then: Yup.mixed()
        //     .oneOf(["platform", "external"])
        //     .required(trans("Please select a fulfilment type"))
    }),
    cost: Yup.number(),
    planned_cost: Yup.number(),
    show_on_planner: Yup.string(),
    description: Yup.string().nullable(),
    media_channel: Yup.string().required(
        trans("Please choose a media channel")
    ),
    status: Yup.string().required(trans("Please choose a status")),
});

const PlannerActivityCreateForm = ({
    task,
    history,
    user,
    startDate,
    endDate,
    theme,
    addActivity,
    onGoBack,
    startOrderProcess,
    successCallback = (activity) => {},
}) => {
    const intl = useIntl();

    const campaignBudget =
        task?.planned_spend?.value ||
        Number(task.estimated_campaign_budget) ||
        0;

    const currentSpend = task.activities
        ? task.activities.reduce((actualTotal, activity) => {
              return actualTotal + (activity.cost?.value || 0);
          }, 0)
        : 0;

    const availableBudget = campaignBudget ? campaignBudget - currentSpend : 0;

    const availableAmountFormatted = intl.formatNumber(availableBudget, {
        style: "currency",
        currency: user?.currency?.iso_code || "GBP",
        currencyDisplay: "symbol",
        minimumFractionDigits: user.currency.decimal_places,
        maximumFractionDigits: user.currency.decimal_places,
    });

    const submitForm = (values, { setErrors, setSubmitting }) => {
        if (values["start_date"]) {
            values["start_date"] = format(values.start_date, "yyyy-MM-dd");
        }
        if (values["end_date"]) {
            values["end_date"] = format(values.end_date, "yyyy-MM-dd");
        }

        //Append the task uuid to the data, if there is one
        values["task_uuid"] = task?.uuid;

        addActivity(values)
            .then((data) => successCallback(data))
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    setSubmitting(false);
                });
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <>
            <Formik
                initialValues={{
                    start_date: task
                        ? new Date(task.start_date)
                        : new Date(startDate),
                    end_date: task
                        ? new Date(task.end_date)
                        : new Date(endDate),
                    planned_cost: "",
                    cost: "",
                    show_on_planner: "show",
                    description: "",
                    platform_asset: "no",
                    fulfilment_type: "",
                    status: "draft",
                    media_channel: "",
                }}
                onSubmit={submitForm}
                validationSchema={ActivitySchema}
            >
                {({ isSubmitting, values, errors }) => {
                    return (
                        <Form className="form-group">
                            <Row>
                                <Column>
                                    <FormRow
                                        name="media_channel"
                                        label={trans("Media Channel")}
                                        error={errors.media_channel}
                                        required={true}
                                    >
                                        <PlannerFormikMediaChannelSelect
                                            name="media_channel"
                                            value={values.media_channel}
                                            showError={false}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="start_date"
                                        label={trans("Start Date")}
                                        error={errors.start_date}
                                    >
                                        <FormikDatePicker
                                            name="start_date"
                                            value={values.start_date}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="end_date"
                                        label={trans("End Date")}
                                        error={errors.end_date}
                                    >
                                        <FormikDatePicker
                                            name="end_date"
                                            value={values.end_date}
                                        />
                                    </FormRow>
                                    {task.uuid && (
                                        <FormRow
                                            name="status"
                                            label={trans("Status")}
                                            error={errors.status}
                                        >
                                            <FormikRadioArray
                                                style={{ marginBottom: "1rem" }}
                                                name="status"
                                                value={values.status}
                                                options={[
                                                    {
                                                        value: "draft",
                                                        text: intl.formatMessage(
                                                            {
                                                                id: "Draft",
                                                            }
                                                        ),
                                                    },
                                                    {
                                                        value: "planned",
                                                        text: intl.formatMessage(
                                                            {
                                                                id: "Planned",
                                                            }
                                                        ),
                                                    },
                                                    {
                                                        value: "complete",
                                                        text: intl.formatMessage(
                                                            {
                                                                id: "Complete",
                                                            }
                                                        ),
                                                        disabled: true,
                                                    },
                                                    {
                                                        value: "cancelled",
                                                        text: intl.formatMessage(
                                                            {
                                                                id: "Cancelled",
                                                            }
                                                        ),
                                                        disabled: true,
                                                    },
                                                ]}
                                                showError={false}
                                            />
                                        </FormRow>
                                    )}
                                    <FormRow
                                        name="planned_cost"
                                        label={trans("Planned Cost")}
                                        error={errors.planned_cost}
                                        required={false}
                                    >
                                        <FormikCurrencyInput
                                            disabled={
                                                values.status === "complete"
                                            }
                                            name="planned_cost"
                                            value={values.planned_cost}
                                            showError={false}
                                        />
                                    </FormRow>
                                    {task.uuid && (
                                        <FormRow
                                            name="cost"
                                            helpText={trans(
                                                "Available budget in campaign {availableBudget}",
                                                {
                                                    availableBudget:
                                                        availableAmountFormatted,
                                                }
                                            )}
                                            label={trans("Actual Cost")}
                                            error={errors.cost}
                                            required={false}
                                        >
                                            <FormikCurrencyInput
                                                disabled={
                                                    values.status !== "complete"
                                                }
                                                style={{
                                                    color:
                                                        values.cost >
                                                        availableBudget
                                                            ? theme.colors.alert
                                                            : "inherit",
                                                }}
                                                name="cost"
                                                value={values.cost}
                                                showError={false}
                                            />
                                        </FormRow>
                                    )}
                                    <FormRow
                                        name="description"
                                        label={trans("Description")}
                                        error={errors.description}
                                    >
                                        <FormikTextarea
                                            name="description"
                                            value={values.description}
                                        />
                                    </FormRow>
                                    <FormRow
                                        name="show_on_planner"
                                        label={trans(
                                            "Show Activity On Planner"
                                        )}
                                        error={errors.show_on_planner}
                                    >
                                        <FormikRadioArray
                                            name="show_on_planner"
                                            value={values.show_on_planner}
                                            options={[
                                                {
                                                    value: "show",
                                                    text: intl.formatMessage({
                                                        id: "Show",
                                                    }),
                                                },
                                                {
                                                    value: "hide",
                                                    text: intl.formatMessage({
                                                        id: "Hide",
                                                    }),
                                                },
                                            ]}
                                            inlineRadioButtons
                                            showError={false}
                                        />
                                    </FormRow>
                                </Column>
                            </Row>

                            <Row>
                                <Column medium={6} centered>
                                    <Column
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <IconTextButton
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onGoBack(task);
                                            }}
                                            type="alert"
                                            icon="times"
                                            showCircle={false}
                                        >
                                            {trans("Cancel")}
                                        </IconTextButton>
                                        <SubmitButton
                                            label={trans("Save")}
                                            submitting={isSubmitting}
                                            type="primary"
                                        />
                                    </Column>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { startOrderProcess, addActivity, editActivity },
        dispatch
    );
}

function mapStateToProps(state) {
    const { startDate, endDate, filters } = state.planner;
    const { user } = state.auth;
    return {
        startDate,
        endDate,
        user,
        filters,
    };
}

export default withRouter(
    withTheme(
        compose(connect(mapStateToProps, mapDispatchToProps))(
            PlannerActivityCreateForm
        )
    )
);
