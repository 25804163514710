import React from "react";
import moment from "moment";
import { useTheme } from "styled-components";
import { trans } from "@cortexglobal/rla-intl";
import { Row, Column, Badge } from "@cortexglobal/rla-components";

import { DetailWrapper, Header } from "./tooltipComponents";

export const TaskTooltipContent = ({ task }) => {
    const theme = useTheme();
    return (
        <DetailWrapper>
            <Header>
                <h4>
                    <Badge
                        backgroundColor={
                            theme.planner.colors.task[task.owner_type]
                        }
                        style={{ marginRight: "0.4rem" }}
                    >
                        {task.ref}
                    </Badge>{" "}
                    <strong>{task.name}</strong>
                </h4>
            </Header>
            <Row className="detailRow">
                <Column small={4}>
                    <strong>{trans("Start Date")}</strong>:
                </Column>
                <Column small={8}>
                    {moment.parseZone(task.start_date).format("LL")}
                </Column>
            </Row>
            <Row className="detailRow">
                <Column small={4}>
                    <strong>{trans("End Date")}</strong>:
                </Column>
                <Column small={8}>
                    {moment.parseZone(task.end_date).format("LL")}
                </Column>
            </Row>
            {task?.user?.name && (
                <Row className="detailRow">
                    <Column small={4}>
                        <strong>{trans("Created By (User)")}</strong>:
                    </Column>
                    <Column small={8}>{task?.user?.name}</Column>
                </Row>
            )}
            {task?.group?.name && (
                <Row className="detailRow">
                    <Column small={4}>
                        <strong>{trans("Created By (Group)")}</strong>:
                    </Column>
                    <Column small={8}>{task?.group?.name}</Column>
                </Row>
            )}
        </DetailWrapper>
    );
};

export default TaskTooltipContent;
