import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import FormLabel from "../styledElements/label";
import "react-input-range/lib/css/index.css";
import InputError from "../styledElements/inputError";
import StyledRange, {
    rangePropType,
    valuePropType
} from "../styledElements/styledRange";
class FormikRange extends Component {
    render() {
        const { label, name, value, onChange, error, ...rest } = this.props;

        return (
            <React.Fragment>
                <Field name={name}>
                    {({
                        field: { name },
                        form: {
                            touched,
                            errors,
                            values,
                            setFieldTouched,
                            setFieldValue
                        }
                    }) => {
                        const error = !!touched[name] && errors[name];
                        const value = values[name];
                        return (
                            <React.Fragment>
                                {label && (
                                    <FormLabel
                                        name={name}
                                        label={label}
                                        {...this.props}
                                        marginBottom={1.2}
                                    >
                                        {label}
                                    </FormLabel>
                                )}

                                <StyledRange
                                    value={value}
                                    onChange={value => {
                                        setFieldValue(name, value);
                                        setFieldTouched(name, true);
                                    }}
                                    onBlur={() => {
                                        setFieldTouched(name, true);
                                    }}
                                    {...rest}
                                />
                            </React.Fragment>
                        );
                    }}
                </Field>
                <ErrorMessage name={name}>
                    {msg => <InputError error={msg} />}
                </ErrorMessage>
            </React.Fragment>
        );
    }
}

FormikRange.displayName = "FormikRange";

FormikRange.propTypes = {
    ariaLabelledby: PropTypes.string,
    ariaControls: PropTypes.string,
    classNames: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    draggableTrack: PropTypes.bool,
    formatLabel: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxValue: rangePropType,
    minValue: rangePropType,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onChangeStart: PropTypes.func,
    onChangeComplete: PropTypes.func,
    step: PropTypes.number,
    value: valuePropType,
    error: PropTypes.string
};

FormikRange.defaultProps = {
    error: ""
};

export default FormikRange;
