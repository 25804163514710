import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
//@ts-ignore cortex
import {
    SubmitButton,
    FormikInput,
    FormikTextarea,
    Tabordion,
    Row,
    Column,
    FormRow,
    GroupSelector,
    ContentBlockLoader,
    FormikSelect,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import { TabordionGroup, LocaleSwitcher } from "../components/index";

const schema = Yup.object().shape({
    name: Yup.string()
        .typeError("Name needs to be a string")
        .required("Required"),
    url: Yup.string()
        .typeError("URL needs to be a string")
        .required("Required"),
    description: Yup.string().nullable(),
});

export type ExternalDashboard = ReturnType<typeof schema.validateSync> & {
    id?: number;
    uuid?: number;
    locale?: string;
    name?: string;
    description?: string;
    groups?: any;
};

export const ExternalDashboardForm = ({ item, submitForm }) => {
    const [tab, setTab] = useState(0);

    const handleSubmit = (
        values: ExternalDashboard,
        { setErrors, setSubmitting, ...rest }
    ) => {
        const fd = new FormData();

        Object.keys(values).forEach((key) => {
            if (values[key]) {
                if (key === "groups") {
                    // Serialize the groups array to a JSON string
                    const groupsString = JSON.stringify(values[key]);
                    // Append the serialized string to FormData
                    fd.append(key, groupsString);
                } else {
                    // For other properties, directly append to FormData
                    fd.append(key, values[key]);
                }
            }
        });

        submitForm(fd, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                name: item.name,
                url: item.url,
                locale: item.locale,
                description: item.description,
                groups: item.groups,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                console.log(values);
                return (
                    <Form className="form-group">
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={<span>{trans("Details")}</span>}
                                onTabClick={() => setTab(0)}
                            >
                                <FormRow
                                    name="locale"
                                    label={trans("Locale")}
                                    helpText={trans("Optional")}
                                    error={errors.locale}
                                >
                                    <LocaleSwitcher
                                        item={item}
                                        setFieldValue={setFieldValue}
                                        //@ts-ignore
                                        values={values}
                                    />
                                </FormRow>

                                <FormRow
                                    name="groups"
                                    label={trans("Groups")}
                                    helpText={trans("Required")}
                                    error={errors.groups}
                                >
                                    <GroupSelector
                                        label={null}
                                        name="groups"
                                        value={values.groups}
                                        onChange={(e) =>
                                            setFieldValue("groups", e.value[0])
                                        }
                                        multi={true}
                                    />
                                </FormRow>
                                <FormRow
                                    name="name"
                                    label={trans("Name")}
                                    helpText={trans("Required")}
                                    error={errors.name}
                                >
                                    <FormikInput
                                        name="name"
                                        value={values.name}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="url"
                                    label={trans("URL")}
                                    helpText={trans("Required")}
                                    error={errors.url}
                                >
                                    <FormikInput
                                        name="url"
                                        value={values.url}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                                <FormRow
                                    name="description"
                                    label={trans("Description")}
                                    helpText={trans("Required")}
                                    error={errors.description}
                                >
                                    <FormikTextarea
                                        name="description"
                                        value={values.description}
                                        showError={false}
                                    />{" "}
                                </FormRow>
                            </TabordionGroup>
                        </Tabordion>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ExternalDashboardForm;
