import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

/**
 * AddNavUnderline adds on extra css to this styled component to show a coloured bar underneath a link in the top level menu
 *
 * @param {*} navUnderline
 * @returns
 */
const AddNavUnderline = (props) => {
    let styles = ``;
    const navUnderline = props.theme.navigation.navLink.navUnderline;
    if (navUnderline.enabled) {
        styles += `
            position: relative;

            &:after {
                content: ' ';
                position: absolute;
                height: ${navUnderline.height};
                bottom: ${navUnderline.bottomOffset};
                left: ${navUnderline.sideOffset};
                right: ${navUnderline.sideOffset};
                background-color: ${props.underlinecolor || navUnderline.color};
                transition: height 200ms ease-out;
            }

            &:hover:after {
                height: ${navUnderline.hoverHeight};
            }

            &.active:after {
                height: ${navUnderline.activeHeight};
            }
        `;
    }

    return css`
        ${styles}
    `;
};

const MainNavLink = styled(NavLink).attrs({
    // exact: true,
    activeClassName: "active",
})`
    display: inline-block;
    margin: 0 ${(props) => props.theme.navigation.navLink.margin}em;
    padding: ${(props) => props.theme.navigation.navLink.padding}em 0;
    text-decoration: none;
    color: ${(props) => props.theme.navigation.navLink.color};
    text-transform: ${(props) => props.theme.navigation.navLink.textTransform};
    position: relative;
    font-family: ${(props) => props.theme.navigation.navLink.fontFamily};
    font-weight: ${(props) => props.theme.navigation.navLink.fontWeight};
    font-size: ${(props) => props.theme.navigation.navLink.fontSize}px;
    text-rendering: optimizeLegibility;

    &.active,
    &:hover {
        color: ${(props) => props.theme.navigation.navLink.activeColor};
    }
    &.active {
        text-decoration: ${(props) =>
            props.theme.navigation.navLink.activeTextDecoration};
        font-weight: ${(props) =>
            props.theme.navigation.navLink.activeFontWeight};
    }

    &:hover {
        text-decoration: ${(props) =>
            props.theme.navigation.navLink.hoverTextDecoration};
        font-weight: ${(props) =>
            props.theme.navigation.navLink.hoverFontWeight};
    }

    ${(props) => AddNavUnderline(props)}
`;

MainNavLink.displayName = "MainNavLink";

MainNavLink.propTypes = {};

MainNavLink.defaultProps = {};
export default MainNavLink;
