import React from "react";
import styled from "styled-components";
import { SimpleDropzone } from "@cortexglobal/rla-components";

export const Uploader = styled(SimpleDropzone)`
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    border: ${({ theme }) => `1px ${theme.colors.accent} solid`};
`;

export default Uploader;
