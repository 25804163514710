import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import UserListing from "./UserListing";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";

export const UserAdmin = () => {
    const { path } = useRouteMatch();

    // console.log(items);
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <UserListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <UserCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:id`}
                render={props => {
                    const {
                        match: {
                            params: { id }
                        }
                    } = props;
                    return <UserEdit path={path} id={id} />;
                }}
            />
        </Switch>
    );
};

export default UserAdmin;
