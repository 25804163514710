import React, { useState, MouseEvent } from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { Formik, Form, FormikProps } from "formik";
import differenceInSeconds from "date-fns/differenceInSeconds";

import {
    Panel,
    IconTextButton,
    FormikInput,
    FormikTextarea,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { CommentSchema } from "./CommentSchema";

//@ts-ignore cortex
import { trans, FormattedRelativeTime } from "@cortexglobal/rla-intl";

const CommentContainer = styled(Panel)`
    position: relative;
    flex: 1;
    padding: 1rem;
    min-height: 120px;
`;

const CommentBody = styled.div`
    flex: 1;
`;

const CommentHeader = styled.header`
    display: flex;
    flex-direction: row;
`;

const CommentTitle = styled.h4`
    flex: 1;
`;

const CommentDate = styled.time`
    flex: 1;
    text-align: right;
`;

const EditedMessage = styled.small`
    display: block;
    position: absolute;
    padding: 0.2rem 0.4rem;
    bottom: 0;
    left: 0;
    border-radius: ${(props) => props.theme.panel.radius}px 0
        ${(props) => props.theme.panel.radius}px 0;
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.gray700};
`;

const CrudButtons = styled.div`
    position: absolute;
    padding: 0.2rem 0.4rem;
    bottom: 0;
    right: 0;
`;
interface CommentProps {
    comment: {
        uuid: string;
        comment: string;
        title: string;
        created_at: string;
        updated_at: string;
        user: {
            uuid: string;
            name: string;
            avatar: string;
        };
    };
    user: {
        uuid: string;
    };
    editComment: Function;
    deleteComment: Function;
    deletable: boolean;
    showTitle: boolean;
}
// Shape of form values
interface FormValues {
    title?: string;
    comment: string;
}

export const Comment = ({
    comment,
    user,
    editComment,
    deleteComment,
    deletable,
    showTitle,
}: CommentProps) => {
    const [editing, setEditing] = useState(false);
    const submitForm = (
        values: {},
        { setSubmitting }: FormikProps<FormValues>
    ) => {
        editComment(values, comment.uuid).then(() => {
            setEditing(false);
            setSubmitting(false);
        });
    };
    const performDelete = (event: MouseEvent) => {
        deleteComment(comment.uuid);
        event.preventDefault();
    };

    return (
        <Formik
            initialValues={{
                title: comment.title || "",
                comment: comment.comment || "",
            }}
            onSubmit={submitForm}
            validationSchema={CommentSchema}
        >
            {({ isSubmitting, values }) => {
                const titleLabel = trans("Comment Title");
                return (
                    <CommentContainer>
                        <Form className="form-group">
                            <CommentHeader>
                                {showTitle && (
                                    <React.Fragment>
                                        {editing ? (
                                            <FormikInput
                                                type="text"
                                                name="title"
                                                placeholder={titleLabel}
                                                value={values.title}
                                            />
                                        ) : (
                                            <CommentTitle>
                                                {comment.title}
                                            </CommentTitle>
                                        )}
                                    </React.Fragment>
                                )}
                                <CommentDate>
                                    <FormattedRelativeTime
                                        value={differenceInSeconds(
                                            new Date(comment.created_at),
                                            new Date()
                                        )}
                                        numeric="auto"
                                        updateIntervalInSeconds={10}
                                    />
                                </CommentDate>
                            </CommentHeader>
                            {editing ? (
                                <FormikTextarea
                                    name="comment"
                                    placeholder={trans("Comment")}
                                    value={values.comment}
                                />
                            ) : (
                                <CommentBody>{comment.comment}</CommentBody>
                            )}
                            {comment.created_at !== comment.updated_at && (
                                <EditedMessage>{trans("Edited")}</EditedMessage>
                            )}
                            {user && comment.user.uuid === user.uuid && (
                                <CrudButtons>
                                    {editing ? (
                                        <React.Fragment>
                                            <IconTextButton
                                                type="alert"
                                                size="tiny"
                                                onClick={(
                                                    event: MouseEvent
                                                ) => {
                                                    setEditing(false);
                                                    event.preventDefault();
                                                }}
                                            >
                                                {trans("Cancel")}
                                            </IconTextButton>
                                            <IconTextButton
                                                type="submit"
                                                size="tiny"
                                                disabled={isSubmitting}
                                            >
                                                {trans("Save")}
                                            </IconTextButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {deletable && (
                                                <IconTextButton
                                                    themeColor="alert"
                                                    icon={faTimes}
                                                    showCircle={false}
                                                    onClick={performDelete}
                                                >
                                                    {trans("Delete")}
                                                </IconTextButton>
                                            )}
                                            <IconTextButton
                                                size="tiny"
                                                icon={faPencilAlt}
                                                showCircle={false}
                                                onClick={(
                                                    event: MouseEvent
                                                ) => {
                                                    setEditing(true);
                                                    event.preventDefault();
                                                }}
                                            >
                                                {trans("Edit")}
                                            </IconTextButton>
                                        </React.Fragment>
                                    )}
                                </CrudButtons>
                            )}
                        </Form>
                    </CommentContainer>
                );
            }}
        </Formik>
    );
};
