import PropTypes from "prop-types";
import styled from "styled-components";

const MenuItem = styled.li`
    margin: 0;
    width: 100%;
    cursor: pointer;
    position: relative;
    /* padding: 0.5rem 1rem; */
    padding: ${(props) => {
        console.log(props.theme.menu);
        return props.theme.menu.padding;
    }};
    background: ${(props) => props.theme.menu.background};
    color: ${(props) => props.theme.menu.color};
    &:not(:last-child) {
        border-bottom: ${(props) => props.theme.menu.borderBottom};
    }
    &:hover {
        background: ${(props) => props.theme.menu.hoverBackground};
    }
`;

export default MenuItem;
