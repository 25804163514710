import React from "react";

const SurveyManagementContext = React.createContext({
    surveySystem: {
        loaded: false,
        loading: false,
        error: "",
        isSubmitting: false,
        unsavedChanges: false,
        disableSave: false,
        disableEdit: false,
    },
    deleteSurvey: (uuid) => {},
    setSurveySystem: (surveySystem) => {},
});

export default SurveyManagementContext;
