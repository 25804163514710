import React, { useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Layout } from "../interfaces/layout";
import {
    SlideContainer,
    Subtitle,
    SlideTitle,
} from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";

const StyledSlideTitle = styled(SlideTitle)`
    justify-content: center;
    flex: 2;
`;

const SlideBody = styled(animated.div)`
    display: flex;
    flex: 4;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    padding-top: 1em;
`;

const TitleContent = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);

    if (editing || disableSequencing) {
        currentTransitionIndex = 2;
    }
    const transitionsComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );

    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            backgroundImage={globalTheming?.backgroundImage}
            settings={settings}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                style={useSpring({
                    opacity: transitionsComplete.includes("body_title") ? 1 : 0,
                })}
            >
                <RenderContentBlock {...content.body_title} />
            </StyledSlideTitle>
            <SlideBody
                style={useSpring({
                    opacity: transitionsComplete.includes("body_content")
                        ? 1
                        : 0,
                })}
            >
                <RenderContentBlock {...content.body_content} />
            </SlideBody>
        </SlideContainer>
    );
};

export default TitleContent;
