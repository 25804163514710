import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
    Button,
    ContentBlockLoader,
    SubmitButton,
    HelpText,
    FormLabel,
    FormikSelect,
    FormikInput,
    FormikCheckbox,
    FormikCheckboxTree,
    Panel,
    Loading,
    Tabordion,
    Row,
    Column,
    SimpleDropzone,
    FormRow,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
///@ts-ignore cortex
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

const schema = Yup.object().shape({
    state_id: Yup.number().required("Required"),
    new_state_id: Yup.number().required("Required"),
});

// export type WorkflowState = ReturnType<typeof schema.validateSync> & {
//     id?: number;
//     uuid?: number;
//     alias?: string;
//     super_state_id?: number;
//     is_super?: boolean;
//     special_type?: string;
//     allow_edits: boolean;
// };

export const WorkflowTransitionForm = ({
    item,
    states,
    submitForm,
    workflow,
}) => {
    const [statesOptions, setStatesOptions] = useState([]);
    const [statesOptionsLoaded, setStatesOptionsLoaded] = useState(false);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        axios
            .get(`/api/v1/admin/workflow/${workflow.uuid}/states/options`)
            .then(({ data }) => {
                setStatesOptions(data);
                setStatesOptionsLoaded(true);
            });

        // axios
        //     .get(`/api/v1/admin/workflow/${item.uuid}/states/special-types`)
        //     .then(({ data }) => {
        //         console.log(data);
        //         setSpecialTypes(data.data);
        //         setSpecialTypesLoaded(true);
        //     });
    }, []);

    const handleSubmit = (values, { setErrors, setSubmitting, ...rest }) => {
        submitForm(values, setSubmitting).catch((e) => {
            parseValidationErrors(e).then(({ errors }) => {
                //console.log(setErrors, errors);
                setErrors(errors);
                setSubmitting(false);
            });
        });
    };

    return (
        <Formik
            initialValues={{
                state_id: item.state_id,
                new_state_id: item.new_state_id,
                display_order: item.display_order ? item.display_order : 0,
                sentiment: item.sentiment ? item.sentiment : "neutral",
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <h1>
                            {trans("Editing Transition")}: {item.name}
                        </h1>
                        <p>
                            {trans("Workflow")}: {workflow.name}
                        </p>{" "}
                        <FormRow
                            name="state_id"
                            label={trans("State")}
                            helpText={trans("Required")}
                            error={errors.state_id}
                        >
                            <ContentBlockLoader loaded={statesOptionsLoaded}>
                                <FormikSelect
                                    name="state_id"
                                    value={values.state_id}
                                    options={statesOptions}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="new_state_id"
                            label={trans("New State")}
                            helpText={trans("Optional")}
                            error={errors.new_state_id}
                        >
                            <ContentBlockLoader loaded={statesOptionsLoaded}>
                                <FormikSelect
                                    name="new_state_id"
                                    value={values.new_state_id}
                                    options={statesOptions}
                                />{" "}
                            </ContentBlockLoader>
                        </FormRow>
                        <FormRow
                            name="display_order"
                            label={trans("Display Order")}
                            helpText={trans("Optional")}
                            error={errors.display_order}
                        >
                            <FormikInput name="name" value={values.name} />{" "}
                        </FormRow>
                        <FormRow
                            name="sentiment"
                            label={trans("Sentiment")}
                            helpText={trans("Required")}
                            error={errors.sentiment}
                        >
                            <FormikSelect
                                name="sentiment"
                                value={values.sentiment}
                                options={[
                                    { value: "positive", text: "Positive" },
                                    { value: "neutral", text: "Neutral" },
                                    { value: "negative", text: "Negative" },
                                ]}
                            />{" "}
                        </FormRow>
                        <Row>
                            <Column medium={6} style={{ textAlign: "right" }}>
                                &nbsp;
                            </Column>
                            <Column
                                medium={3}
                                flex
                                style={{
                                    paddingBottom: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <SubmitButton
                                    label={trans("Save")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowTransitionForm;
