import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import TeamCreate from "./TeamCreate";
import { TeamEdit } from "./TeamEdit";
import TeamListing from "./TeamListing";

export const TeamAdmin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() => {
                    return <TeamListing path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/create`}
                render={() => {
                    return <TeamCreate path={path} />;
                }}
            />
            <Route
                exact
                path={`${path}/edit/:uuid`}
                render={(props) => {
                    const {
                        match: {
                            params: { uuid },
                        },
                    } = props;
                    return <TeamEdit path={path} uuid={uuid} />;
                }}
            />
        </Switch>
    );
};

export default TeamAdmin;
