import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import {
    PageTitle,
    Error,
    IconTextButton,
    Loading,
    TableList,
    Pagination,
    Row,
    Column,
    Select,
} from "@cortexglobal/rla-components";
import RequestRow from "../components/RequestRow";
import { trans } from "@cortexglobal/rla-intl";

const RequestsTable = ({
    requestsPath,
    requests: {
        loaded: requestsLoaded,
        error: requestsError,
        data: requests,
        meta: pagination,
    },
    getRequestsSummary,
    fetchData,
    setFilter,
    filters,
    groupFilter,
    statusFilter,
    typeFilter,
    types,
}) => {
    if (requestsError.length) {
        return <Error>{trans("Error loading requests")}</Error>; // TODO: swap for actual error component?
    }

    return (
        <React.Fragment>
            <TableList className="request-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{trans("Category")}</th>
                        <th>{trans("Type")}</th>
                        <th>{trans("Status")}</th>
                        <th>{trans("Assigned To")}</th>
                        <th>{trans("Owner")}</th>
                        <th>{trans("Created")}</th>
                        <th>{trans("Updated")}</th>
                        <th>{trans("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {requestsLoaded === true ? (
                        requests.length < 1 ? (
                            <tr>
                                <td colSpan="100%">
                                    {trans(
                                        "No requests found matching your criteria"
                                    )}
                                </td>
                            </tr>
                        ) : (
                            requests.map((request) => {
                                return (
                                    <RequestRow
                                        onUpdate={getRequestsSummary}
                                        requestsPath={requestsPath}
                                        request={request}
                                        key={request.uuid}
                                    />
                                );
                            })
                        )
                    ) : (
                        <tr>
                            <td colSpan="100%">
                                {requestsError.length > 0 ? (
                                    <p>{trans("Error")}</p>
                                ) : (
                                    <Loading />
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            </TableList>
            {requests.length > 0 && (
                <Row
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Column>
                        {requestsLoaded && !_.isEmpty(pagination) && (
                            <Pagination
                                style={{ marginBottom: "1rem" }}
                                currentPage={pagination.current_page - 1}
                                perPage={pagination.per_page}
                                pageCount={pagination.last_page}
                                onPageChange={({ selected }) =>
                                    fetchData(++selected)
                                }
                                showSummary={true}
                                {...pagination}
                            />
                        )}
                    </Column>
                </Row>
            )}
        </React.Fragment>
    );
};

export default RequestsTable;
