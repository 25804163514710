import React, { useState } from "react";
import ShowMoreText from "react-show-more-text";
import Truncate from "react-truncate";
import styled from "styled-components";
import Tooltip from "./tooltip";

const CssTruncate = styled.div<{ background?: string }>`
    /* styles for '...' */
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 3.6em;
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: left;
    /* place for '...' */
    margin-right: -1em;
    padding-right: 1em;

    /* create the ... */
    &:before {
        /* points in the end */
        content: "...";
        /* absolute position */
        position: absolute;
        /* set position to right bottom corner of block */
        right: 0;
        bottom: 0;
    }
    /* hide ... if we have text, which is less than or equal to max lines */
    &:after {
        /* points in the end */
        content: "";
        /* absolute position */
        position: absolute;
        /* set position to right bottom corner of text */
        right: 0;
        /* set width and height */
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        /* bg color = bg color under block */
        background: ${({ background, theme }) =>
            background || theme.colors.white};
    }
`;
type ShowMoreProps = {
    more: string;
    less: string;
    type: "css" | "expander" | "tooltip" | "title";
    lines: number;
    children: any;
    rest: any;
    background?: string;
};
export const ShowMore = ({
    more = ">>>",
    less = "<<<",
    type = "expander",
    lines = 1,
    children,
    ...rest
}: ShowMoreProps) => {
    // const intl = useIntl();
    const [isTruncated, setIsTruncated] = useState(false);
    switch (type) {
        case "css":
            return <CssTruncate {...rest}>{children}</CssTruncate>;
        case "expander":
            return (
                <ShowMoreText
                    more={more}
                    less={less}
                    lines={lines}
                    // more={more === "" ? intl.formatMessage({ id: "Show more" }) : more}
                    // less={less === "" ? intl.formatMessage({ id: "Show less" }) : less}
                    {...rest}
                >
                    {children}
                </ShowMoreText>
            );
        case "tooltip":
            return (
                <Tooltip
                    text={children}
                    showIcon={false}
                    useHover={isTruncated}
                >
                    <div>
                        <Truncate
                            lines={lines}
                            {...rest}
                            onTruncate={(truncated) =>
                                setIsTruncated(truncated)
                            }
                        >
                            {children}
                        </Truncate>
                    </div>
                </Tooltip>
            );
        case "title":
            return (
                <Truncate lines={lines} title={children} {...rest}>
                    {children}
                </Truncate>
            );
    }
};
export default ShowMore;
