import React from "react";
import axios from "axios";
import { trans } from "@cortexglobal/rla-intl";
import { StyledSelectAdvanced } from "@cortexglobal/rla-components";

const BrandSwitcher = ({ user }) => {
    const { available_brands, active_brand } = user;

    const handleChange = (e) => {
        e.preventDefault;

        const newBrandUuid = e.value;

        switchBrands(newBrandUuid);
    };

    const switchBrands = (newBrandUuid) => {
        const data = {
            brand_uuid: newBrandUuid,
        };

        return axios.post(`/api/v1/brand/switch`, data).then(() => {
            window.location.href = "/";
        });
    };

    return Array.isArray(available_brands) && available_brands.length > 1 ? (
        <StyledSelectAdvanced
            label={trans("Change Brand:")}
            style={{
                marginBottom: ".5rem",
            }}
            name="user-role"
            onChange={handleChange}
            options={[
                {
                    value: "",
                    text: "All",
                },
                ...available_brands.map((e) => ({
                    value: e.uuid,
                    text: e.name,
                })),
            ]}
            value={active_brand?.uuid || ""}
        />
    ) : (
        <></>
    );
};

export default BrandSwitcher;
