import React from "react";
import { MicrositeContext as MicrositeContextInterface } from "./interfaces/microsite";

const MicrositeContext = React.createContext<
    Partial<MicrositeContextInterface>
>({
    components: {},
    config: {
        nav: { main: [] },
        pages: [],
        theme: {},
        globalSettings: [
            {
                name: "useAsSlideshow",
                type: "checkbox",
                label: "Use as slideshow",
                value: false,
            },
            {},
        ],
    },
    globalSettings: {
        useAsSlideshow: {
            name: "useAsSlideshow",
            type: "checkbox",
            label: "Use as slideshow",
            value: false,
        },
    },
    links: { main: [] },
    basePath: "",
    baseUrl: "",
    editing: false,
    fullscreen: false,
    preview: false,
    slideSize: {
        width: "100vw",
        height: "75vw",
        fontSize: "2vw",
    },
    // setFullscreen
});

export const MicrositeProvider = MicrositeContext.Provider;
export const MicrositeConsumer = MicrositeContext.Consumer;

export default MicrositeContext;
