import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Measure from "react-measure";
import debounce from "lodash/debounce";
import get from "lodash/get";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSortUp,
    faSortDown,
    faEdit,
    faChevronDown,
    faPlus,
    faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "react-collapse";

import { trans, FormattedCurrency, useIntl } from "@cortexglobal/rla-intl";
import { format, getItem, setItem } from "@cortexglobal/cortex-utilities";
import {
    PlainButton,
    Select,
    ButtonDropdown,
    Row,
    Column,
    Table,
    IconTextButton,
    Tooltip,
} from "@cortexglobal/rla-components";

import {
    DataTable,
    DataColumn,
    Cell,
    TaskNameCell,
    CategoryCell,
    TableHeader,
    TaskLink,
    ArrowIcon,
} from "./taskListingComponents";
import ActivityTable from "./ActivityTable";
require("fixed-data-table-2/dist/fixed-data-table.min.css");

const sortTasks = (tasks, sortSettings) => {
    const { sortBy, sortDirection } = sortSettings;
    let sortedTasks = [];
    if (sortBy) {
        //Sort the tasks, within each category
        sortedTasks = tasks.sort((a, b) => {
            const aValue = get(a, sortBy);
            const bValue = get(b, sortBy);
            if (aValue === bValue) return 0;
            if (sortDirection === "asc") {
                return aValue < bValue ? -1 : 1;
            } else {
                return aValue > bValue ? -1 : 1;
            }
        });
    } else {
        sortedTasks = tasks;
    }

    return sortedTasks;
};
const addActivityCount = (tasks) => {
    return tasks.map((task) => {
        const activityCount = task?.activities?.length ?? 0;
        return {
            ...task,
            activityCount,
        };
    });
};

export const TaskList = ({
    user,
    tasks: allTasks,
    match,
    allowLinks = true,
    tableHeight = "65vh",
    // resetSelections,
}) => {
    const initialWidths = {
        name: 350,
        category: 200,
        start_date: 150,
        end_date: 150,
        planned_spend: 100,
        actual_spend: 100,
        "group.name": 150,
        "user.name": 150,
        "user.email": 250,
        goalI18n: 150,
        activityCount: 150,
    };
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();

    const [sortSettings, setSortSettings] = useState({
        sortBy: "name",
        sortDirection: "asc",
    });
    const [width, setWidth] = useState(1000);
    const [height, setHeight] = useState(1000);
    const [dataItems, setDataItems] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [showColumnSelect, setShowColumnSelect] = useState(false);
    const [columnWidths, setColumnWidths] = useState({ ...initialWidths });

    useEffect(() => {
        // dispatch(resetSelections());
        document.getElementsByTagName("BODY")[0].style[
            "overscroll-behavior-x"
        ] = "none";
        return () => {
            document.getElementsByTagName("BODY")[0].style[
                "overscroll-behavior-x"
            ] = "auto";
        };
    }, []);

    useEffect(() => {
        setDataItems(sortTasks(addActivityCount(allTasks), sortSettings));
    }, [allTasks]);

    const columns = {
        start_date: {
            label: trans("Start"),
            type: "date",
        },
        end_date: {
            label: trans("End"),
            type: "date",
        },
        planned_spend: {
            label: trans("Planned Spend"),
            type: "currency",
        },
        actual_spend: {
            label: trans("Actual Spend"),
            type: "currency",
        },
        "group.name": {
            label: trans("Group"),
        },
        "user.name": {
            label: trans("Created By User Name"),
        },
        "user.email": {
            label: trans("Created By User Email"),
        },
        goalI18n: {
            label: trans("Goal"),
        },
        activityCount: {
            label: trans("Number of Activities"),
        },
    };
    const defaultColumns = [
        "start_date",
        "end_date",
        "planned_spend",
        "actual_spend",
        "group.name",
        "user.name",
        "user.email",
    ];
    const columnsFromStorage = getItem(
        "planner_columns",
        JSON.stringify(defaultColumns)
    );
    const [selectedColumns, setSelectedColumns] = useState(
        JSON.parse(columnsFromStorage["planner_columns"])
    );
    const resize = (contentRect) => {
        setWidth(contentRect.bounds.width);
        setHeight(contentRect.bounds.height);
    };

    const renderContents = (props, column, columnData) => {
        const dataItem = get(dataItems, `[${props.rowIndex}]`);
        if (!dataItem) {
            return;
        }

        const value = get(dataItem, `${column}`);

        switch (columnData.type) {
            case "date":
                return <Cell {...props}>{format(new Date(value))}</Cell>;
            case "currency":
                return (
                    <Cell style={{ textAlign: "right" }} {...props}>
                        <FormattedCurrency
                            value={value.value}
                            currency={user?.currency?.iso_code || "GBP"}
                        />
                    </Cell>
                );
            default:
                return <Cell {...props}>{value}</Cell>;
        }
    };

    const handleColumnsChange = (value) => {
        setSelectedColumns(value);
        setItem("planner_columns", value);
    };

    const handleSortUpdate = (newSortSettings) => {
        setExpandedRows([]);
        setSortSettings(newSortSettings);
        setDataItems(sortTasks(dataItems, newSortSettings));
    };

    const onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
        setColumnWidths({
            ...columnWidths,
            [columnKey]: newColumnWidth,
        });
    };

    const getSubRowHeight = (rowIndex) => {
        if (!expandedRows.includes(rowIndex)) {
            return 0;
        }
        return Array.isArray(dataItems[rowIndex].activities) &&
            dataItems[rowIndex].activities.length > 0
            ? 72 + dataItems[rowIndex].activities.length * 60
            : 80;
    };

    const activityDetail = ({ rowIndex, width, height }) => {
        if (!expandedRows.includes(rowIndex)) {
            return null;
        }

        const task = dataItems[rowIndex];
        return (
            <ActivityTable
                task={task}
                match={match}
                allowLinks={allowLinks}
                user={user}
            />
        );
    };
    return (
        <>
            <Collapse isOpened={showColumnSelect}>
                <Row
                    style={{
                        position: "relative",
                        display: "flex",
                        padding: "0 1rem 0rem 1rem",
                        alignItems: "center",
                    }}
                >
                    <div style={{ flex: 1, zIndex: "998", marginTop: "1rem" }}>
                        <Select
                            name="columnSelect"
                            label={trans("Select which columns to display")}
                            // labelProps={{ label: "test" }}
                            multi={true}
                            value={selectedColumns}
                            onChange={({ value }) => handleColumnsChange(value)}
                            options={Object.keys(columns).map((key) => ({
                                text: columns[key].label,
                                value: key,
                            }))}
                        />
                    </div>
                </Row>
            </Collapse>
            <Row>
                <Measure bounds onResize={debounce(resize, 300)}>
                    {({ measureRef }) => (
                        <div
                            ref={measureRef}
                            className="taskList"
                            style={{
                                display: "flex",
                                height: tableHeight,
                                // margin: "0rem 1rem 0 1rem",
                            }}
                        >
                            <DataTable
                                rowsCount={dataItems.length}
                                rowHeight={40}
                                headerHeight={50}
                                width={width}
                                height={height}
                                subRowHeightGetter={getSubRowHeight}
                                rowExpanded={activityDetail}
                                onColumnResizeEndCallback={
                                    onColumnResizeEndCallback
                                }
                                isColumnResizing={false}
                            >
                                {/* <ColumnGroup
                                fixed={true}
                                header={<Cell>Name</Cell>}
                            > */}
                                <DataColumn
                                    header={(props) => (
                                        <TableHeader
                                            columnData={{
                                                label: trans("Name"),
                                            }}
                                            columnSlug="name"
                                            sortSettings={sortSettings}
                                            onChange={handleSortUpdate}
                                            {...props}
                                        ></TableHeader>
                                    )}
                                    isResizable={true}
                                    minWidth={250}
                                    maxWidth={600}
                                    fixed={true}
                                    width={columnWidths.name}
                                    columnKey="name"
                                    cell={(props) => {
                                        const dataItem =
                                            dataItems[props.rowIndex];
                                        if (!dataItem) {
                                            return;
                                        }

                                        const hasExpandedActivities =
                                            expandedRows.includes(
                                                props.rowIndex
                                            );
                                        return (
                                            <TaskNameCell {...props}>
                                                {allowLinks ? (
                                                    <TaskLink
                                                        to={`${match.path}/task/${dataItem.uuid}`}
                                                    >
                                                        {dataItem.name}{" "}
                                                    </TaskLink>
                                                ) : (
                                                    dataItem.name
                                                )}
                                                <section className="taskButtons">
                                                    {Array.isArray(
                                                        dataItem.activities
                                                    ) &&
                                                        dataItem.activities
                                                            .length > 0 && (
                                                            <PlainButton
                                                                style={{
                                                                    marginRight:
                                                                        "0.5rem",
                                                                }}
                                                                onClick={() => {
                                                                    if (
                                                                        hasExpandedActivities
                                                                    ) {
                                                                        setExpandedRows(
                                                                            expandedRows.filter(
                                                                                (
                                                                                    row
                                                                                ) =>
                                                                                    row !==
                                                                                    props.rowIndex
                                                                            )
                                                                        );
                                                                    } else {
                                                                        setExpandedRows(
                                                                            [
                                                                                ...expandedRows,
                                                                                props.rowIndex,
                                                                            ]
                                                                        );
                                                                    }
                                                                }}
                                                                title={intl.formatMessage(
                                                                    {
                                                                        id: "Show Activities",
                                                                    }
                                                                )}
                                                            >
                                                                <ArrowIcon
                                                                    icon={
                                                                        faChevronDown
                                                                    }
                                                                    rotation={
                                                                        hasExpandedActivities
                                                                            ? 180
                                                                            : 0
                                                                    }
                                                                />
                                                            </PlainButton>
                                                        )}{" "}
                                                </section>
                                            </TaskNameCell>
                                        );
                                    }}
                                />
                                <DataColumn
                                    header={(props) => (
                                        <TableHeader
                                            columnData={{
                                                label: trans("Category"),
                                            }}
                                            columnSlug="category_label"
                                            sortSettings={sortSettings}
                                            onChange={handleSortUpdate}
                                            {...props}
                                        ></TableHeader>
                                    )}
                                    cell={(props) => {
                                        const dataItem =
                                            dataItems[props.rowIndex];
                                        if (!dataItem) {
                                            return;
                                        }
                                        return (
                                            <Cell {...props}>
                                                {dataItem.category_label}{" "}
                                            </Cell>
                                        );
                                    }}
                                    isResizable={true}
                                    minWidth={200}
                                    maxWidth={600}
                                    fixed={true}
                                    width={columnWidths.category}
                                    columnKey="category"
                                />

                                {selectedColumns.map((column) => {
                                    const columnData = columns[column];
                                    return (
                                        <DataColumn
                                            key={column}
                                            header={(props) => (
                                                <TableHeader
                                                    columnData={columnData}
                                                    columnSlug={column}
                                                    sortSettings={sortSettings}
                                                    onChange={handleSortUpdate}
                                                    {...props}
                                                ></TableHeader>
                                            )}
                                            cell={(props) => {
                                                return renderContents(
                                                    props,
                                                    column,
                                                    columnData
                                                );
                                            }}
                                            width={columnWidths[column] || 150}
                                            isResizable={true}
                                            minWidth={initialWidths[column]}
                                            maxWidth={600}
                                            columnKey={column}
                                        />
                                    );
                                })}
                                <DataColumn
                                    fixedRight={true}
                                    header={(props) => (
                                        <TableHeader
                                            columnData={{}}
                                            columnSlug="add"
                                            sortSettings={sortSettings}
                                            onChange={handleSortUpdate}
                                            sortable={false}
                                            {...props}
                                        >
                                            <PlainButton
                                                style={{ color: "inherit" }}
                                                onClick={() =>
                                                    setShowColumnSelect(
                                                        !showColumnSelect
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    style={{ color: "inherit" }}
                                                    icon={
                                                        showColumnSelect
                                                            ? faMinus
                                                            : faPlus
                                                    }
                                                />
                                            </PlainButton>
                                        </TableHeader>
                                    )}
                                    cell={(props) => {
                                        return null;
                                    }}
                                    width={30}
                                />
                            </DataTable>
                        </div>
                    )}
                </Measure>
            </Row>
        </>
    );
};

export default TaskList;
