import React, { useEffect, useState } from "react";
import axios from "axios";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import InventoryListing from "./InventoryListing";
import CreateInventoryItem from "./CreateInventoryItem";
import EditInventoryItem from "./EditInventoryItem";
import ViewInventoryItem from "./ViewInventoryItem";
import { Loading } from "@cortexglobal/rla-components";

const InventoryManager = ({ settings: passedSettings }) => {
    const { path } = useRouteMatch();
    const [settings, setSettings] = useState(passedSettings);
    const [settingsLoadingState, setSettingsLoadingState] = useState("loading");

    useEffect(() => {
        if (!passedSettings) {
            getSettings();
        } else {
            setSettingsLoadingState("loaded");
            setSettings(passedSettings);
        }
    }, [passedSettings]);

    const getSettings = () => {
        setSettingsLoadingState("loading");

        axios
            .get(`/api/v1/events/settings`)
            .then(({ data }) => {
                const settings = data.data;
                setSettings(settings);
                setSettingsLoadingState("loaded");
            })
            .catch((e) => {});
    };

    if (settingsLoadingState === "loading") {
        return <Loading />;
    }

    return (
        <div id="inventory" style={{ marginBottom: "20px" }}>
            <Switch>
                <Route
                    path={`${path}/create`}
                    render={() => {
                        return (
                            <CreateInventoryItem
                                path={path}
                                settings={settings}
                            />
                        );
                    }}
                />
                <Route
                    path={`${path}/:uuid/edit`}
                    render={(props) => {
                        const {
                            match: {
                                params: { uuid },
                            },
                        } = props;
                        return (
                            <EditInventoryItem
                                path={path}
                                uuid={uuid}
                                settings={settings}
                            />
                        );
                    }}
                />
                <Route
                    path={`${path}/:uuid`}
                    render={(props) => {
                        const {
                            match: {
                                params: { uuid },
                            },
                        } = props;
                        return (
                            <ViewInventoryItem
                                path={path}
                                uuid={uuid}
                                settings={settings}
                            />
                        );
                    }}
                />
                <Route
                    path={path}
                    render={() => {
                        return (
                            <InventoryListing path={path} settings={settings} />
                        );
                    }}
                />
            </Switch>
        </div>
    );
};

export default InventoryManager;
