import React from "react";
import PropTypes from "prop-types";
import LoadingIcon from "react-loading";
import styled, { css, useTheme } from "styled-components";

const LoadingOuter = styled.div`
    position: relative;
    padding: ${(props) => props.padding};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    ${(props) =>
        props.align == "center" &&
        css`
            //margin: auto;
        `} ${(props) =>
        props.align == "right" &&
        css`
            float: right;
        `};
`;

const LoadingInner = styled.div`
    margin: auto;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    max-width: 100px;
    max-height: 100px;
    padding: 1em;
`;
const Loading = (props) => {
    const theme = useTheme();
    const color = props.color ? props.color : theme.loaders.color;
    return (
        <LoadingOuter>
            <LoadingInner>
                <LoadingIcon
                    type={props.type}
                    color={color}
                    width={props.width ? props.width : "100%"}
                    height={props.height ? props.height : "100%"}
                />
                {props.children}
            </LoadingInner>
        </LoadingOuter>
    );
};
Loading.displayName = "Loading";

Loading.propTypes = {
    /** A css color (using rgba will allow transparency) */
    color: PropTypes.string,
    /** One of the types provided by react-loading, e.g. currently ["blank",
        "balls",
        "bars",
        "bubbles",
        "cubes",
        "cylon",
        "spin",
        "spinningBubbles",
        "spokes"] */
    type: PropTypes.oneOf([
        "blank",
        "balls",
        "bars",
        "bubbles",
        "cubes",
        "cylon",
        "spin",
        "spinningBubbles",
        "spokes",
    ]),
    /** A css width */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** A css height */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** left, right or center */
    align: PropTypes.string,
    padding: PropTypes.string,
};

Loading.defaultProps = {
    type: "spin",
    width: "50px",
    height: "50px",
    align: "left",
    padding: "0",
};
export default Loading;
