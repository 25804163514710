import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import {
    Panel,
    Row,
    Column,
    Button,
    PageTitle,
    Loading,
    IconTextButton,
} from "@cortexglobal/rla-components";
import PlannerTaskCategoryForm from "../forms/PlannerTaskCategoryForm";
import { trans } from "@cortexglobal/rla-intl";
import { HeaderEmphasis } from "../components/overviewComponents";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";

class AddTaskCategory extends React.Component {
    render() {
        const { category, plannerPath, viewPlanner, user } = this.props;
        // console.log(category);
        // if (!category || Object.entries(category).length === 0) {
        //     return <Redirect to={`${plannerPath}`} />;
        // }
        return (
            <React.Fragment>
                <PageTitle
                    title={
                        category.name ? (
                            <React.Fragment>
                                {trans("Adding Category to")}{" "}
                                <HeaderEmphasis>{category.name}</HeaderEmphasis>
                            </React.Fragment>
                        ) : (
                            trans("Adding New Category")
                        )
                    }
                >
                    <IconTextButton
                        rotation={180}
                        onClick={() => viewPlanner()}
                        showCircle={false}
                    >
                        {trans("Cancel")}
                    </IconTextButton>
                </PageTitle>

                <Row>
                    <Column>
                        <Panel>
                            <Row>
                                <PlannerTaskCategoryForm
                                    action="addTaskCategory"
                                    user={user}
                                    category={category}
                                    onGoBack={viewPlanner}
                                />
                            </Row>
                        </Panel>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

AddTaskCategory.displayName = "AddTaskCategory";

AddTaskCategory.propTypes = {
    category: PropTypes.shape({
        name: PropTypes.string,
    }),
    viewPlanner: PropTypes.func,
};

AddTaskCategory.defaultProps = {};

export default AddTaskCategory;
