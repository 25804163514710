import React from "react";
import { get, isEmpty } from "lodash";
// import moment from "moment";
import { add } from "date-fns";
import {
    ContentBlockLoader,
    DatePicker,
    InputField,
    SelectField,
    TextareaField,
    FormRow,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FormWrapper, YesNoSelector } from "./formLayoutComponents";

const GeneralDetailForm = ({
    loadingState,
    errors,
    params,
    onChange,
    eventTypeOptions,
    excludedOptionalFields,
}) => {
    const startDate = get(params, "start_date", null);
    const endDate = get(params, "end_date", null);
    console.log("GeneralDetailForm", params, startDate, endDate, errors);

    return (
        <FormWrapper>
            <FormRow
                helpText={trans("Required")}
                label={trans("Event name")}
                name={"name"}
                error={get(errors, "name")}
                required={true}
            >
                <InputField
                    name="name"
                    value={get(params, "name", "")}
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    showError={false}
                />
            </FormRow>

            <FormRow
                label={trans("Description")}
                name={"description"}
                errors={errors}
                error={get(errors, "description")}
            >
                <TextareaField
                    name="description"
                    value={get(params, "description", "")}
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    showError={false}
                />
            </FormRow>

            <FormRow
                helpText={trans("Required")}
                label={trans("Event Start Date")}
                name={"start_date"}
                error={get(errors, "start_date")}
                required={true}
            >
                <DatePicker
                    name="start_date"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "start_date", "")}
                    minDate={add(new Date(), { days: 1 })}
                    showError={false}
                />
            </FormRow>

            <FormRow
                helpText={trans("Required")}
                label={trans("Event End Date")}
                name={"end_date"}
                error={get(errors, "end_date")}
                required={true}
            >
                <DatePicker
                    name="end_date"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "end_date", "")}
                    minDate={add(new Date(startDate), {
                        days: 1,
                    })}
                    showError={false}
                />
            </FormRow>

            {!excludedOptionalFields.includes("meeting_element") && (
                <FormRow
                    helpText={trans("Required")}
                    label={trans("Is there a meeting element?")}
                    name={"meeting_element"}
                    error={get(errors, "meeting_element")}
                >
                    <YesNoSelector
                        name="meeting_element"
                        value={get(params, "meeting_element", "")}
                        onChange={onChange}
                        disabled={loadingState === "loading"}
                    />
                </FormRow>
            )}

            <FormRow
                helpText={trans("Required")}
                label={trans("Event type")}
                name={"event_type"}
                error={get(errors, "event_type")}
                required={true}
            >
                <ContentBlockLoader loaded={!isEmpty(eventTypeOptions)}>
                    <SelectField
                        height={38}
                        name="event_type"
                        onChange={(e) => onChange(e.name, e.value)}
                        disabled={loadingState === "loading"}
                        options={eventTypeOptions}
                        value={get(params, "event_type", "")}
                        showError={false}
                    />
                </ContentBlockLoader>
            </FormRow>
        </FormWrapper>
    );
};

export default GeneralDetailForm;
