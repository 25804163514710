import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { Helmet } from "react-helmet-async";

import { trans } from "@cortexglobal/rla-intl";

import {
    PageTitle,
    IconTextButton,
    Row,
    Column,
    Toggle,
    Loading,
    Pagination,
    CardGrid,
    useAddToast,
} from "@cortexglobal/rla-components";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CampaignResultItem from "../components/CampaignResultItem";

export const ListCampaigns = ({ basePath, mainTitle, viewCampaign, can }) => {
    const theme = useTheme();
    const addToast = useAddToast();
    const history = useHistory();
    const [campaigns, setCampaigns] = useState({ loaded: false, data: [] });
    const [includeInactive, setIncludeInactive] = useState(false);
    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
    });
    const [search, setSearch] = useState("");

    useEffect(() => {
        viewCampaign(null);
        searchCampaigns(search);
    }, [pagination.current_page, pagination.per_page, search, includeInactive]);

    const searchCampaigns = (search) => {
        const params = {
            ...pagination,
            search,
            includeInactive,
        };

        axios
            .get("/api/v1/campaigns", {
                params,
            })
            .then((response) => {
                if (response.data) {
                    setCampaigns({
                        loaded: true,
                        data: response.data.data,
                    });
                    setPagination(response.data.meta);
                }
            })
            .catch((error) => {
                addToast({
                    type: "alert",
                    content: trans("Error loading campaigns"),
                    showFor: 5000,
                });
            });
    };
    const onPageChange = (page) => {
        setPagination({ ...pagination, current_page: page });
    };

    const onPerPageChange = ({ value }) => {
        setPagination({ ...pagination, per_page: value });
    };
    const addForm = () => {
        history.push(`${basePath}/create`);
    };

    const renderPageTitle = () => {
        // console.log({ activity });
        return (
            <PageTitle title={mainTitle} margin={0}>
                <>
                    {can("dam-campaign-edit") && (
                        <Toggle
                            onChange={(event) =>
                                setIncludeInactive(!includeInactive)
                            }
                            checked={includeInactive}
                            small={true}
                        >
                            {trans("Include non-active")}
                        </Toggle>
                    )}
                    {can("dam-campaign-create") && (
                        <IconTextButton
                            onClick={addForm}
                            showCircle={false}
                            expanded={false}
                            icon={faPlus}
                        >
                            {trans("Create Campaign")}
                        </IconTextButton>
                    )}
                </>
            </PageTitle>
        );
    };

    return (
        <>
            <Helmet>
                <title>
                    {process.env.REACT_APP_NAME} - {mainTitle}
                </title>
            </Helmet>
            {renderPageTitle()}
            {campaigns.loaded ? (
                <>
                    <Row expanded={theme.body.expanded ? true : false}>
                        {campaigns.data.length > 0 ? (
                            <CardGrid minColumnWidth={300}>
                                {campaigns.data.map((campaign) => {
                                    return (
                                        <CampaignResultItem
                                            campaign={campaign}
                                            onClick={viewCampaign}
                                        />
                                    );
                                })}
                            </CardGrid>
                        ) : (
                            <Column>
                                <h3
                                    style={{
                                        textAlign: "center",
                                        margin: "1rem",
                                    }}
                                >
                                    {trans("No campaigns found")}
                                </h3>
                            </Column>
                        )}
                    </Row>
                    <Row>
                        <Pagination
                            onPerPageChange={onPerPageChange}
                            style={{ clear: "both" }}
                            currentPage={pagination.current_page - 1}
                            perPage={pagination.per_page}
                            pageCount={pagination.last_page}
                            onPageChange={({ selected }) =>
                                onPageChange(selected + 1)
                            }
                            {...pagination}
                        />
                    </Row>
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default ListCampaigns;
