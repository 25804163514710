import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PanelList from "./PanelList";
import SelectedPanel from "./SelectedPanel";

import { loadPanels, choosePanel } from "../dashboardActions";

class AddPanel extends Component {
    componentDidMount() {
        if (!this.props.panels.loaded) {
            this.props.loadPanels(this.props.slug);
        }
    }

    choosePanel = panel => {
        this.props.choosePanel(this.props.slug, panel);
    };

    render() {
        if (this.props.selectedPanel !== null) {
            return (
                <SelectedPanel
                    panel={this.props.selectedPanel}
                    addPanel={this.props.addPanel}
                />
            );
        }
        return (
            <PanelList
                panels={this.props.panels}
                choosePanel={this.choosePanel}
                itemsLoaded={this.props.panels.loaded}
                breakpoint={this.props.breakpoint}
            />
        );
    }
}

AddPanel.propTypes = {
    slug: PropTypes.string
};

const mapStateToProps = (state, props) => {
    const { panels, selectedPanel } = state.dashboards[props.slug];
    return {
        panels,
        selectedPanel
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadPanels,
            choosePanel
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddPanel);
