import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Layout, Settings } from "../interfaces/layout";
import { SlideContainer, Subtitle } from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";

const StyledSlideContainer = styled(SlideContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledTitle = styled.h1`
    font-size: 2em;
`;

const CentredTitle = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName
}: Layout) => {
    let {
        // navigationState,
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig
    } = useContext(MicrositeContext);
    // console.log(navigationState, transitions);
    // let { currentTransitionIndex } = navigationState;
    // console.log("currentTransitionIndex", currentTransitionIndex, content);
    if (editing || disableSequencing) {
        currentTransitionIndex = 2;
    }
    const transitionsComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );
    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    // console.log("transitionsComplete", transitionsComplete);
    return (
        <StyledSlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledTitle
                style={useSpring({
                    opacity: transitionsComplete.includes("title") ? 1 : 0
                })}
            >
                <RenderContentBlock {...content.title} />
            </StyledTitle>
        </StyledSlideContainer>
    );
};

export default CentredTitle;
