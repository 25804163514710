import React, { Component } from "react";

import PropTypes from "prop-types";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";

import MyRequests from "./containers/MyRequests";
import CreateRequest from "./containers/CreateRequest";
import Request from "./containers/Request";

export class Requests extends Component {
    getRequest = (requestUuid) => {};

    render() {
        const {
            history,
            match,
            location,
            auth: { user },
            theme,
        } = this.props;

        const basePath = match.path;
        return (
            <Switch>
                <Route
                    exact
                    path={`${basePath}/create`}
                    render={() => {
                        return (
                            <CreateRequest
                                user={user}
                                theme={theme}
                                onGoBack={() => {
                                    history.push(`${basePath}`);
                                }}
                                requestsPath={basePath}
                                location={location}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${basePath}/view/:request_uuid`}
                    render={() => {
                        return (
                            <Request
                                user={user}
                                theme={theme}
                                requestsPath={basePath}
                            />
                        );
                    }}
                />
                <Route
                    exact
                    path={`${basePath}/:requestTypeAlias?`}
                    render={(route) => {
                        //TODO - Look at making a new component based on the MyRequest one
                        //that is more specifically designed for single request type
                        return (
                            <MyRequests
                                user={user}
                                theme={theme}
                                requestsPath={basePath}
                                // requestTypeAlias={
                                //     route?.match?.params?.requestTypeAlias
                                // }
                            />
                        );
                    }}
                />
            </Switch>
        );
    }
}

Requests.displayName = "Requests";

Requests.propTypes = {
    onViewChange: PropTypes.func,
};

Requests.defaultProps = {
    onViewChange: () => {},
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // getPlan,
            // generateDateRange,
            // getPlannerFilters,
            // getPlannerCategories,
            // selectCategory,
            // selectTask,
            // selectActivity,
            // showActivityOrder,
            // addOrderToPlan,
            // deleteTask,
            // deleteActivity
        },
        dispatch
    );
}

function mapStateToProps(state) {
    // const {
    //     filters,
    //     startDate,
    //     endDate,
    //     dates,
    //     categories,
    //     tasks,
    //     selections,
    //     loaded
    // } = state.planner;
    return {
        // filters,
        // startDate,
        // endDate,
        // dates,
        // categories,
        // tasks,
        // selections,
        // loaded
    };
}

export default withRouter(
    compose(connect(mapStateToProps, mapDispatchToProps))(Requests)
);
