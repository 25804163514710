import React, { useContext } from "react";
import styled from "styled-components";

//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { DraftJSOutput } from "@cortexglobal/rla-components";

import MicrositeContext from "../MicrositeContext";
import { Layout } from "../interfaces/layout";
import { RenderContentBlock } from "../components/RenderContentBlock";

import {
    SlideTitle,
    Subtitle,
    CloseButton,
} from "../components/slideComponents";
const StyledSlideTitle = styled(SlideTitle)`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    flex: 0;
`;
const AdditionalInformationContainer = styled.div<AdditionalInformationContainerProps>`
    background: ${(props) => {
        if (props?.backgroundImage) {
            return `url(${props?.backgroundImage})`;
            // return `url(${props?.backgroundImage}) no-repeat fixed center`;
        } else if (props?.backgroundColor) {
            return props.backgroundColor;
        }
        return "white";
    }};
    background-size: 100%;
    color: ${(props) => (props.textColor ? props.textColor : "white")};
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* font-size: 0.6em; */
`;

const TitleArea = styled.div`
    padding: 5vh 5vw 0;
`;

const ContentArea = styled.div`
    padding: 5vh 5vw 0;
`;

const AdditionalInformationImg = styled.img`
    flex-grow: 1;
    display: block;
    margin: auto;
    max-width: 100%;
`;

export const AdditionalInformation = ({
    content: content,
    groupName,
    pageName,
    settings,
}: Partial<Layout>) => {
    const {
        additionalInformationShowing,
        setAdditionalInformationShowing,
        setHasAdditionalInformation,
        globalSettings,
        globalTheming,
        // currentPageConfig,
    } = useContext(MicrositeContext);

    const additionalInfo = content?.additionalInformation?.value;
    const additionalInfoText = content?.additionalInformationText?.value;
    const additionalInfoTitleSize = content?.additionalInformationTitleSize;
    const additionalInfoTitleHidden = content?.additionalInfoTitleHidden?.value;
    const additionalInfoTitle = content?.additionalInformationTitle;
    const forceAdditional = useQueryString().get("force-additional");

    if (!additionalInfo && !additionalInfoText) {
        if (setHasAdditionalInformation) setHasAdditionalInformation(false);
        return null;
    } else {
        if (setHasAdditionalInformation) setHasAdditionalInformation(true);
    }

    const closeAdditionalInformation = () => {
        if (setAdditionalInformationShowing)
            setAdditionalInformationShowing(false);
    };
    const highlightColor = settings?.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    if (!additionalInformationShowing && !forceAdditional) {
        return null;
    }
    return (
        <AdditionalInformationContainer
            backgroundImage={globalTheming?.backgroundImage}
            backgroundColor={globalTheming?.backgroundColor}
            textColor={globalSettings?.textColor}
        >
            {additionalInfoTitleHidden ? (
                <CloseButton onClick={closeAdditionalInformation} />
            ) : (
                <TitleArea>
                    <CloseButton onClick={closeAdditionalInformation} />

                    <Subtitle
                        highlightColor={highlightColor}
                        groupName={groupName}
                    >
                        {pageName} {trans(" - Additional Information")}
                    </Subtitle>
                    <StyledSlideTitle
                        size={
                            additionalInfoTitleSize
                                ? additionalInfoTitleSize.value
                                : settings?.titleSize
                        }
                        hidden={
                            !additionalInfoTitle || !additionalInfoTitle.value
                        }
                    >
                        <RenderContentBlock {...additionalInfoTitle} />{" "}
                    </StyledSlideTitle>
                </TitleArea>
            )}
            {additionalInfo && (
                <AdditionalInformationImg src={additionalInfo} />
            )}
            {additionalInfoText && (
                <ContentArea>
                    <DraftJSOutput style={{ width: "100%" }}>
                        {additionalInfoText}
                    </DraftJSOutput>
                </ContentArea>
            )}
        </AdditionalInformationContainer>
    );
};

interface AdditionalInformationContainerProps {
    backgroundImage?: string;
    backgroundColor?: string;
    textColor?: string;
}
