import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import slugify from "slugify";
import { v4 as uuid4 } from "uuid";

import { trans } from "@cortexglobal/rla-intl";
import {
    Modal,
    InputField,
    Loading,
    RadioField,
    Image,
    Row,
    Column,
    PlainButton,
    Button,
    Error,
    Select,
} from "@cortexglobal/rla-components";

import { useAssetComponents } from "../../hooks/useAssetComponents";
import PageItem from "./PageItem";
import { setupNewTransitions } from "../components/TransitionEditor";
const TemplateThumbnail = styled(Image)`
    display: block;
    margin: 0 auto 10px auto;
    max-width: 100%;
`;

const TemplateColumn = styled(Column)`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing.padding / 2}rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
`;

const TemplateButton = styled(PlainButton)`
    border: ${({ isSelectedComponent, theme }) =>
            isSelectedComponent ? theme.colors.secondary : "none"}
        1px solid;
    outline: ${({ isSelectedComponent, theme }) =>
            isSelectedComponent ? theme.colors.secondary : "none"}
        auto 1px;
    padding: 0.7rem;
`;

const FlexRow = styled(Row)`
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
`;

const PaddedRow = styled(Row)`
    padding-top: 2em;
`;

const CenterDiv = styled.div`
    width: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const validate = (pageSetup) => {
    let errors = [];

    Object.entries(pageSetup).forEach(([property, value]) => {
        if (value === "") {
            errors.push(property);
        }
    });

    return errors;
};
const PageCreator = ({
    currentPage,
    pages,
    onChange,
    onClose,
    showing,
    groups = [],
}) => {
    const theme = useTheme();
    const [assetComponents] = useAssetComponents();
    const [pageSetup, updatePageSetup] = useState({
        name: "",
        component: "",
        insertIndex: currentPage + 1,
        errors: [],
        submitted: false,
        checked: false,
    });

    React.useEffect(() => {
        if (pageSetup.submitted && !pageSetup.checked) {
            updatePageSetup({
                ...pageSetup,
                errors: validate(pageSetup),
                checked: true,
            });
        }
    }, [pageSetup]);

    React.useEffect(() => {
        updatePageSetup({
            name: "",
            component: "",
            insertIndex: currentPage + 1,
            errors: [],
            submitted: false,
            checked: false,
        });
    }, [showing]);

    const processAddPage = () => {
        //Check all the parts have been selected, return an error if they have
        const errors = validate(pageSetup);

        if (errors.length > 0) {
            updatePageSetup({ ...pageSetup, errors: errors, submitted: true });
            return;
        }
        //If all is good process the page details and insert the page into the pages where requested
        const newPageSlug = slugify(pageSetup.name, {
            lower: true,
            remove: /[*+~.()'"!:@]/g,
        });
        //TODO - Check if this slug is already in the pages, if so append a number

        //Process the component information into a valid pageSetup
        const newPage = [
            newPageSlug,
            {
                ...pageSetup.component,
                name: pageSetup.name,
                group: pageSetup.group,
                content: [
                    ...pageSetup.component.content.map((contentItem) => {
                        return { ...contentItem, uuid: uuid4() };
                    }),
                ],
            },
        ];
        newPage[1].transitions = setupNewTransitions(newPage[1].content);
        let newPages = [...pages];
        newPages.splice(pageSetup.insertIndex, 0, [...newPage]);
        //Pass the updated pages to the onChange and close the dialog
        // console.log("newPages", newPages);
        onChange({ pages: newPages, selectedSlug: newPageSlug });
        onClose();
    };
    // console.log(pageSetup);

    const usePageGrouping = groups.length > 0;
    return (
        <Modal visible={showing} onClose={onClose}>
            <Row>
                <Column>
                    <h3 style={{ marginBottom: "1rem" }}>
                        {trans("Add new page")}
                    </h3>
                </Column>
            </Row>
            <Row>
                {usePageGrouping && (
                    <Column large={6}>
                        <Select
                            onChange={(event) => {
                                updatePageSetup({
                                    ...pageSetup,
                                    checked: false,
                                    group: event.value,
                                });
                            }}
                            value={pageSetup.group}
                            label={trans("Section Name")}
                            width="100%"
                            marginBottom="0"
                            name="group"
                            options={groups.map((group) => {
                                return {
                                    text: group,
                                    value: group,
                                };
                            })}
                            error={
                                pageSetup.errors.includes("group") &&
                                trans("Please add a Group Name")
                            }
                        />
                    </Column>
                )}
                <Column large={usePageGrouping ? 6 : 12}>
                    <InputField
                        label={trans("Page Name")}
                        name={`name`}
                        value={pageSetup.name}
                        onChange={(event) => {
                            updatePageSetup({
                                ...pageSetup,
                                checked: false,
                                name: event.value,
                            });
                        }}
                        error={
                            pageSetup.errors.includes("name") &&
                            trans("Please add a Page Name")
                        }
                    />
                </Column>
            </Row>
            <FlexRow style={{ maxHeight: "200px", overflow: "scroll" }}>
                {assetComponents.loaded ? (
                    assetComponents.data.map((componentSetup) => {
                        const isSelectedComponent =
                            pageSetup.component !== "" &&
                            pageSetup.component.component ===
                                componentSetup.component;
                        return (
                            <TemplateColumn
                                small={3}
                                key={componentSetup.component}
                            >
                                <TemplateButton
                                    onClick={(event) => {
                                        updatePageSetup({
                                            ...pageSetup,
                                            checked: false,
                                            component: componentSetup,
                                        });
                                        validate(pageSetup);
                                    }}
                                    isSelectedComponent={isSelectedComponent}
                                >
                                    <TemplateThumbnail
                                        src={`${process.env.REACT_APP_BASE_URL}/img/${componentSetup.component}.png`}
                                    />
                                    {componentSetup.name}
                                </TemplateButton>
                            </TemplateColumn>
                        );
                    })
                ) : (
                    <CenterDiv>
                        <div style={{ width: "50px", height: "50px" }}>
                            <Loading></Loading>
                        </div>
                    </CenterDiv>
                )}
            </FlexRow>
            {pageSetup.errors.includes("component") && (
                <Row>
                    <Column>
                        <Error>
                            {trans("Please ensure you've picked a template")}
                        </Error>
                    </Column>
                </Row>
            )}

            {pages.length > 0 && (
                <Row>
                    <Column>
                        <RadioField
                            name="insertIndex"
                            label="Insert Location"
                            inlineRadioButtons
                            value={parseInt(pageSetup.insertIndex)}
                            onChange={(event) => {
                                updatePageSetup({
                                    ...pageSetup,
                                    checked: false,
                                    insertIndex: event.value,
                                });
                            }}
                            options={[
                                { text: "Final Page", value: pages.length },
                                {
                                    text: "After Current Page",
                                    value: currentPage + 1,
                                },
                                {
                                    text: "Before Current Page",
                                    value: currentPage,
                                },
                            ]}
                            error={
                                pageSetup.errors.includes("insertIndex")
                                    ? trans(
                                          "Please choose where to insert the page"
                                      )
                                    : ""
                            }
                        />
                    </Column>
                </Row>
            )}
            <PaddedRow style={{ textAlign: "right" }}>
                <Button
                    style={{ marginRight: "1rem" }}
                    type="linkStyle"
                    $hollow={true}
                    onClick={onClose}
                >
                    {trans("Cancel")}
                </Button>
                <Button onClick={(event) => processAddPage()}>
                    {trans("Add Page")}
                </Button>
            </PaddedRow>
            {/* {pageSetup.errors.length > 0 && (
                <Row>
                    <Column>
                        <Error>
                            {trans(
                                "Please ensure you've selected all the options"
                            )}
                        </Error>
                    </Column>
                </Row>
            )} */}
        </Modal>
    );
};

export default PageCreator;
