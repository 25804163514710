import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { usePermissions } from "@cortexglobal/cortex-auth-redux";
import { faMessage } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";
import {
    SurveyPanel,
    SurveyButtons,
} from "../components/SurveyFormStyledComponents.js";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    IconTextButton,
    PageTitle,
    Loading,
    Panel,
} from "@cortexglobal/rla-components";

import SurveyManagementContext from "../SurveyManagementContext";
import SurveyResults from "./SurveyResults";
import { NotifyButton } from "@cortexglobal/communication";
import SurveyForm from "../forms/SurveyForm";

/**
 * Styled Components here
 */

const TabordionGroup = styled.div``;
const TabTitle = styled.h3`
    margin: ${(props) => `${props.theme.spacing.margin}rem 0`};
`;

const SurveyDetails = ({ path, surveyData }) => {
    const { can } = usePermissions();

    const { surveySystem, setSurveySystem } = useContext(
        SurveyManagementContext
    );

    useEffect(() => {
        setSurveySystem({
            ...surveySystem,
            disableEdit: surveyData.total_finished_entries > 0,
        });
    }, [surveyData]);

    const { loading, loaded } = surveySystem;

    const renderButtons = () => (
        <SurveyButtons>
            {can("admin-automated-communication-rules-create") && (
                <IconTextButton
                    as={Link}
                    to={`/admin/communication-rules/create/${surveyData.smartRef}`}
                    icon={faMessage}
                    showCircle={false}
                >
                    {trans("Create Rule")}
                </IconTextButton>
            )}
            <NotifyButton
                title={trans("Notify users about: {surveyName}", {
                    surveyName: surveyData.name,
                })}
                smartRef={surveyData.smartRef}
            />
            {can("surveys-manage-list") && (
                <IconTextButton
                    as={Link}
                    to={`${path}`}
                    rotation={180}
                    showCircle={false}
                >
                    {trans("Back to Listing")}
                </IconTextButton>
            )}
        </SurveyButtons>
    );

    return (
        <>
            <PageTitle
                title={
                    surveyData?.name
                        ? trans("View: {name}", surveyData)
                        : trans("View")
                }
            >
                {renderButtons()}
            </PageTitle>

            {loading || !loaded ? (
                <Loading />
            ) : (
                <Row>
                    <Column medium={12}>
                        <Panel style={{ padding: "2rem" }}>
                            <SurveyResults surveyData={surveyData} />
                        </Panel>
                    </Column>
                </Row>
            )}
        </>
    );
};

export default SurveyDetails;
