import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { foregroundColor } from "@cortexglobal/cortex-utilities";
import { darken, luminanace, hexToRgb } from "@cortexglobal/cortex-utilities";

const background = (props) =>
    props.backgroundColor ||
    props.theme.colors[props.type] ||
    props.theme.colors[props.theme.alert.default];

const color = (props) =>
    foregroundColor(
        background(props),
        0.75,
        props.theme.lightColor,
        props.theme.darkColor
    );

const isHollow = (props) => Boolean(props.hollow);

const outline = (props) => `inset 0 0 0 ${props.theme.alert.borderWidth}px
${background(props)}`;

const Badge = styled.span`
    border: none;
    border-radius: ${(props) =>
        props.rounded ? props.theme.badge.borderRadius : 0}rem;
    /* margin-bottom: ${(props) =>
        props.margin != undefined
            ? props.margin
            : props.theme.spacing.margin}rem; */
    margin-bottom: ${(props) =>
        props.margin != undefined ? props.margin : 0}rem;
    transition: background-color 0.25s ease;
    box-shadow: ${(props) => (props.showBorder ? outline(props) : "none")};
    padding: ${(props) =>
            props.small ? 0.25 : props.theme.spacing.padding / 4}rem
        ${(props) => (props.small ? 0.4 : props.theme.spacing.padding / 2)}rem;
    font-weight: bold;
    display: ${(props) => props.theme.badge.display};
    font-size: ${(props) =>
        props.small ? props.fontSize / 1.3 : props.fontSize}px;
    line-height: ${({ theme }) => theme.badge.lineHeight};
    text-transform: ${(props) => (props.uppercase ? "uppercase" : "normal")};
    background-color: ${(props) =>
        !isHollow(props) ? background(props) : "transparent"};
    color: ${(props) => (isHollow(props) ? background(props) : color(props))};
    text-align: center;
    min-width: 1.2rem;
    /* align-self: flex-start; */
`;

Badge.displayName = "Badge";

Badge.propTypes = {
    backgroundColor: PropTypes.string,
    fontSize: PropTypes.number,
    rounded: PropTypes.bool,
    uppercase: PropTypes.bool,
    small: PropTypes.bool,
};

Badge.defaultProps = {
    fontSize: 12,
    rounded: true,
    uppercase: true,
    type: "primary",
    small: false,
};

export default Badge;
