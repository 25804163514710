import React, { useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Footnote, Layout } from "../interfaces/layout";
import {
    SlideContainer,
    Subtitle,
    SlideTitle,
} from "../components/slideComponents";
import { RenderContentBlock } from "../components/RenderContentBlock";
import MicrositeContext from "../MicrositeContext";
import { getCompletedTransitionNames } from "../micrositeFunctions";
import { Caption } from "../components/videoComponents";

const StyledSlideTitle = styled(SlideTitle)`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    flex: 1;
`;

const SlideBody = styled.div`
    display: flex;
    flex: 4;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 1em;
    font-size: 0.7em;
`;

const SlideColumn = styled(animated.div)`
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;

    &:first-of-type {
        margin-right: 1rem;
    }
`;

const SlideColumnSection = styled(animated.div)``;

const Image = styled.img`
    width: 100%;
    margin-bottom: 0.5rem;
`;

const TitleTwoImageColumn = ({
    settings,
    content,
    transitions,
    disableSequencing,
    pageName,
    groupName,
}: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        currentPageConfig,
    } = useContext(MicrositeContext);
    if (editing || disableSequencing) {
        currentTransitionIndex = 6;
    }
    const transitionsComplete = getCompletedTransitionNames(
        transitions,
        currentTransitionIndex
    );
    const highlightColor = settings.highlightColor
        ? settings.highlightColor
        : globalTheming?.highlightColor;

    return (
        <SlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
        >
            {!currentPageConfig?.hide_page_name && (
                <Subtitle highlightColor={highlightColor} groupName={groupName}>
                    {pageName}
                </Subtitle>
            )}
            <StyledSlideTitle
                size={settings.titleSize}
                hidden={!content.body_title || !content.body_title.value}
                style={useSpring({
                    opacity: transitionsComplete.includes("body_title") ? 1 : 0,
                })}
            >
                <RenderContentBlock {...content.body_title} />
            </StyledSlideTitle>
            <SlideBody>
                <SlideColumn>
                    {content.column_1_image && content.column_1_image.value && (
                        <Image src={content.column_1_image.value} alt="image" />
                    )}
                    <SlideColumnSection
                        style={useSpring({
                            opacity: transitionsComplete.includes("column_1")
                                ? 1
                                : 0,
                        })}
                    >
                        {!!content.column_1 &&
                            Array.isArray(content.column_1.value) && (
                                <>
                                    {content.column_1.value.map(
                                        (column: Content, index: number) => {
                                            return (
                                                <Caption
                                                    highlightColor={
                                                        highlightColor
                                                    }
                                                    key={index}
                                                    showing={true}
                                                    bulletPoint={
                                                        !!column.bullet
                                                    }
                                                >
                                                    <RenderContentBlock
                                                        type="text"
                                                        value={column.value}
                                                        footnotes={
                                                            column.footnotes ||
                                                            []
                                                        }
                                                    />
                                                </Caption>
                                            );
                                        }
                                    )}
                                </>
                            )}
                    </SlideColumnSection>
                </SlideColumn>
                <SlideColumn>
                    {content.column_2_image && content.column_2_image.value && (
                        <Image src={content.column_2_image.value} alt="image" />
                    )}
                    <SlideColumnSection
                        style={useSpring({
                            opacity: transitionsComplete.includes("column_2")
                                ? 1
                                : 0,
                        })}
                    >
                        {!!content.column_2 &&
                            Array.isArray(content.column_2.value) && (
                                <>
                                    {content.column_2.value.map(
                                        (column: Content, index: number) => {
                                            return (
                                                <Caption
                                                    highlightColor={
                                                        highlightColor
                                                    }
                                                    key={index}
                                                    showing={true}
                                                    bulletPoint={
                                                        !!column.bullet
                                                    }
                                                >
                                                    <RenderContentBlock
                                                        type="text"
                                                        value={column.value}
                                                        footnotes={
                                                            column.footnotes ||
                                                            []
                                                        }
                                                    />
                                                </Caption>
                                            );
                                        }
                                    )}
                                </>
                            )}
                    </SlideColumnSection>
                </SlideColumn>
            </SlideBody>
        </SlideContainer>
    );
};

export type Content = {
    value: string;
    bullet?: boolean;
    footnotes?: Footnote[];
};

export default TitleTwoImageColumn;
