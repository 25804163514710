import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import styled from "styled-components";

import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Error,
    IconTextButton,
    SubmitButton,
    Tabordion,
    Panel,
    useAddToast,
    HeaderWithErrorIndicated,
    useSectionErrors,
    Loading,
    FormRow,
    InputField,
} from "@cortexglobal/rla-components";

import { SurveyPanel } from "../components/SurveyFormStyledComponents.js";
import SurveyManagementContext from "../SurveyManagementContext";
import SurveyFormDetails from "./SurveyFormDetails";
import SurveyFormAvailability from "./SurveyFormAvailability";
import SurveyQuestionList from "./SurveyQuestionList";
import { isEmpty } from "lodash";
import SurveyNotificationsForm from "./SurveyNotificationsForm";

/**
 * Styled Components here
 */
export const ButtonRow = styled(Row)`
    width: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;
const TabordionGroup = styled.div``;

const TabTitle = styled.h3`
    margin: ${(props) => `${props.theme.spacing.margin}rem 0`};
`;

const SurveyForm = ({ path, surveyData, setSurveyData }) => {
    const addToast = useAddToast();
    const history = useHistory();

    const { surveySystem, setSurveySystem } = useContext(
        SurveyManagementContext
    );

    const { disableSave, isSubmitting, loading } = surveySystem;

    const [isDragging, setIsDragging] = useState(false);
    const [tab, setTab] = useState(0);
    const [autosave, setAutosave] = useState(false);
    const [errors, setErrors] = useState({});

    const { sectionErrors, updateSectionErrors } = useSectionErrors([], {
        details: ["form_type", "title", "alias"],
        availability: ["availability.view_type", "publish_at", "archived_at"],
        notifications: [
            "completed_communication_enabled",
            "completed_communication_email_enabled",
            "completed_communication_subject",
            "completed_communication_message",
            "completed_communication_message_html",
        ],
    });

    // useEffect(() => {
    //     setSurveySystem({
    //         ...surveySystem,
    //         disableEdit: (surveyData?.total_finished_entries || 0) > 0,
    //     });
    // }, [surveyData]);

    const updateSurvey = (surveyValues) => {
        const activeElement = document.activeElement;

        setSurveySystem({
            ...surveySystem,
            isSubmitting: true,
            error: null,
            disableEdit: false,
        });

        if (!surveyValues.uuid || surveyValues.uuid === "undefined") {
            axios
                .post(`/api/v1/surveys`, surveyValues)
                .then(({ data }) => {
                    setSurveySystem({
                        ...surveySystem,
                        disableSave: false,
                        isSubmitting: false,
                        unsavedChanges: false,
                        error: null,
                        disableEdit: data.data.total_finished_entries > 0,
                    });
                    setSurveyData(data.data);
                    history.push(`${path}/${data.data.uuid}/edit`);
                    addToast({
                        type: "success",
                        content: trans("Survey created successfully."),
                        showFor: 4000,
                    });
                })
                .catch((error) => {
                    handleError(error);
                });
        } else {
            axios
                .put(`/api/v1/surveys/${surveyValues.uuid}`, surveyValues)
                .then(({ data }) => {
                    setSurveySystem({
                        ...surveySystem,
                        disableSave: false,
                        isSubmitting: false,
                        unsavedChanges: false,
                        error: null,
                        disableEdit: data.data.total_finished_entries > 0,
                    });
                    setSurveyData(data.data);
                    activeElement.focus();
                    addToast({
                        type: "success",
                        content: trans("Survey updated successfully."),
                        showFor: 4000,
                    });
                })
                .catch((error) => {
                    handleError(error);
                });
        }
    };

    const handleError = (error) => {
        if (!error.response) {
            addToast({
                type: "alert",
                content: error.message,
                showFor: 4000,
            });
            return;
        }

        const { response } = error;

        setSurveySystem({
            ...surveySystem,
            disableSave: false,
            isSubmitting: false,
            error: response?.statusText,
        });

        if (response?.status === 422) {
            updateSectionErrors(response.data.errors);
            setErrors(response.data.errors);
            addToast({
                content: trans(
                    "Please check you have filled in all the required information"
                ),
                type: "alert",
                showFor: 4000,
            });
        } else {
            addToast({
                content: trans("An error occurred, please try again later"),
                type: "alert",
                showFor: 4000,
            });
        }

        activeElement.focus();
    };

    const handleChange = (
        propertyName,
        value,
        boolToInt = false,
        errorKey = ""
    ) => {
        if (boolToInt) {
            value = value ? 1 : 0;
        }

        setSurveyData({
            ...surveyData,
            [propertyName]: value,
        });

        //An error key can be passed in to remove the error from the errors object
        //required for the availability section as it has a nested object
        if (errorKey !== "") {
            propertyName = errorKey;
        }

        //Remove the field from the errors if it's there
        let { [propertyName]: oldValue, ...newErrors } = errors;

        setErrors(newErrors);
        updateSectionErrors(newErrors);
    };

    const render = () => {
        if (loading || !surveyData) {
            return <Loading />;
        }

        return (
            <Tabordion
                current={tab}
                unmounts={true}
                style={{ background: "none" }}
            >
                <TabordionGroup
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Details")}
                            section="details"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => setTab(0)}
                >
                    <SurveyPanel>
                        <SurveyFormDetails
                            survey={surveyData}
                            setSurveyData={setSurveyData}
                            handleChange={handleChange}
                            errors={errors}
                        />
                    </SurveyPanel>
                </TabordionGroup>

                <TabordionGroup
                    heading={<span>{trans("Questions")}</span>}
                    onTabClick={() => setTab(1)}
                >
                    <SurveyPanel style={{ padding: "2rem 3rem" }}>
                        <SurveyQuestionList
                            surveyData={surveyData}
                            setSurveyData={setSurveyData}
                            setIsDragging={setIsDragging}
                        />
                    </SurveyPanel>
                </TabordionGroup>

                <TabordionGroup
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Notifications")}
                            section="notifications"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => setTab(2)}
                >
                    <SurveyPanel>
                        <SurveyNotificationsForm
                            handleChange={handleChange}
                            errors={errors}
                            survey={surveyData}
                            setSurveyData={setSurveyData}
                        />
                    </SurveyPanel>
                </TabordionGroup>

                <TabordionGroup
                    heading={
                        <HeaderWithErrorIndicated
                            title={trans("Availability")}
                            section="availability"
                            sectionErrors={sectionErrors}
                        />
                    }
                    onTabClick={() => setTab(3)}
                >
                    <SurveyPanel>
                        <SurveyFormAvailability
                            handleChange={handleChange}
                            errors={errors}
                            surveyData={surveyData}
                            setSurveyData={setSurveyData}
                        />
                    </SurveyPanel>
                </TabordionGroup>
            </Tabordion>
        );
    };

    return (
        <>
            <Row>
                <Column medium={12}>{render()}</Column>
            </Row>
            <Panel style={{ position: "sticky", bottom: 0 }}>
                <ButtonRow style={{ textAlign: "right" }}>
                    <SubmitButton
                        onClick={() => updateSurvey(surveyData)}
                        disabled={isSubmitting || disableSave}
                        submitting={isSubmitting}
                        label={trans("Save")}
                        type="primary"
                    />
                </ButtonRow>
            </Panel>
        </>
    );
};

export default SurveyForm;
