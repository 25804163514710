import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const ListWrapper = styled.div`
    dl {
        border-top: 1px solid ${({ theme }) => theme.table.thBackground};
        margin: 0;
        padding: 10px;
        background: ${({ theme }) => theme.table.background};
        &:nth-child(even) {
            background: ${({ theme }) => theme.table.backgroundAlt};
        }
    }
`;
const List = styled.dl`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;
const Term = styled.dt`
    /* float: left; */
`;
const Definition = styled.dd`
    /* float: right; */
`;

class GridList extends React.Component {
    render() {
        const { items, headers } = this.props;

        return (
            <ListWrapper>
                {items.map((item, itemIndex) => {
                    return (
                        <List key={itemIndex}>
                            {item.map((property, propertyIndex) => {
                                return (
                                    <React.Fragment key={propertyIndex}>
                                        <Term>{headers[propertyIndex]}</Term>
                                        <Definition>{property}</Definition>
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    );
                })}
            </ListWrapper>
        );
    }
}

GridList.displayName = "GridList";

GridList.propTypes = {
    item: PropTypes.array,
    headers: PropTypes.array
};

export default GridList;
