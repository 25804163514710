import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { faTrash, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    Button,
    Loading,
    Table,
    Pagination,
    Modal,
    Row,
    Column,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

import { EmptyRow } from "../../../components/EmptyRow";
import { WorkflowTransitionForm } from "../../../forms/WorkflowTransitionForm";

const PaddedDiv = styled.div`
    padding: 3em;
`;

export const WorkflowTransitionsTable = ({
    items,
    loaded,
    workflow,
    states,
}) => {
    //const currentPage = pagination ? pagination.current_page : 1;

    const [deleteEntityModalShowing, setDeleteEntityModalShowing] =
        useState(false);
    const [deleteEntityName, setDeleteEntityName] = useState("");
    const [deleteEntityId, setDeleteEntityId] = useState(null);

    const [editEntityModalShowing, setEditEntityModalShowing] = useState(false);
    const [editEntity, setEditEntity] = useState(null);

    return (
        <React.Fragment>
            <Row
                collapse
                style={{
                    marginBottom: 0,
                    display: "flex",
                    alignItems: "flex-end",
                }}
            >
                <Column medium={12} style={{ textAlign: "right" }} collapse>
                    <IconTextButton
                        icon={faPlus}
                        showCircle={false}
                        onClick={() => {}}
                        style={{ paddingRight: "1rem" }}
                    >
                        {trans("Add Transition")}
                    </IconTextButton>
                </Column>
            </Row>
            <Table>
                <thead>
                    <tr>
                        <th>Transition Name</th>
                        <th>Original State</th>
                        <th>New State</th>
                        <th>Sort Order</th>
                        <th>Sentiment</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {!loaded ? (
                        <EmptyRow colSpan={3}>
                            <Loading />
                        </EmptyRow>
                    ) : !items || items.length === 0 ? (
                        <EmptyRow colSpan={3}>
                            {trans("No items found")}
                        </EmptyRow>
                    ) : (
                        items.map((item) => {
                            console.log(item);
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item?.state?.i18n ? item.state.i18n.name : item.state?.alias}</td>
                                    <td>{item.new_state ? (item.new_state.i18n ? item.new_state.i18n.name : item.new_state.alias) : "-"}</td>
                                    <td>{item.display_order}</td>
                                    <td>{item.sentiment}</td>
                                    <td>
                                        <IconTextButton
                                            as={Link}
                                            showCircle={false}
                                            expanded={false}
                                            icon={faEdit}
                                            onClick={(event) => {
                                                setEditEntity(item);
                                                setEditEntityModalShowing(true);
                                            }}
                                        >
                                            {trans("Edit")}
                                        </IconTextButton>
                                        <IconTextButton
                                            type="alert"
                                            showCircle={false}
                                            expanded={false}
                                            icon={faTrash}
                                            onClick={(event) => {
                                                setDeleteEntityId(item.uuid);
                                                setDeleteEntityModalShowing(
                                                    true
                                                );
                                                setDeleteEntityName(item.name);
                                            }}
                                        >
                                            {trans("Delete")}
                                        </IconTextButton>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>

            {deleteEntityModalShowing && (
                <Modal
                    visible={deleteEntityModalShowing}
                    onClose={() => {
                        setDeleteEntityModalShowing(false);
                    }}
                >
                    <PaddedDiv>
                        <h1>
                            {trans("You are about delete transition")}:{" "}
                            {deleteEntityName}
                        </h1>

                        <p>{trans("Are you sure you want to proceed?")}</p>
                        <Button
                            type="hollow"
                            style={{ marginRight: "1em" }}
                            onClick={() => {
                                setDeleteEntityModalShowing(false);
                            }}
                        >
                            {trans("Cancel")}
                        </Button>
                        <Button
                            type="alert"
                            onClick={() => {
                                // deleteItem(deleteEntityId).catch(e =>
                                //     setDeleteEntityModalShowing(false)
                                // );
                            }}
                        >
                            {trans("Delete")}
                        </Button>
                    </PaddedDiv>
                </Modal>
            )}

            {editEntityModalShowing && (
                <Modal
                    visible={editEntityModalShowing}
                    onClose={() => {
                        setEditEntityModalShowing(false);
                    }}
                >
                    <WorkflowTransitionForm
                        submitForm={() => {}}
                        states={states}
                        workflow={workflow}
                        item={editEntity}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};

// interface WorkflowStatesTableProps {
//     items: State[];
//     loaded: boolean;
//     pagination?: any;
//     deleteItem?: (number) => Promise;
//     onPageChange?: (number) => void;
//     onOrderBy?: (string) => void;
//     onPerPageChange?: ({ value: string }) => void;
// }

export default WorkflowTransitionsTable;
