import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Field, ErrorMessage } from "formik";
import StyledRadio from "../styledElements/styledRadio";
import InputError from "../styledElements/inputError";
const Container = styled.div`
    margin-bottom: ${(props) =>
        props.marginBottom ? props.marginBottom : "1.2rem"};
`;
const FormikRadioArray = ({
    type,
    name,
    label,
    options,
    labelWidth,
    labelAlign,
    inlineRadioButtons,
    showError,

    ...rest
}) => {
    return (
        <Container>
            {options.map((option) => {
                return (
                    <Field name={name} key={option.value}>
                        {({ field: { onChange }, form: { values } }) => {
                            return (
                                <StyledRadio
                                    {...rest}
                                    {...option}
                                    name={name}
                                    checked={values[name] === option.value}
                                    text={option.text}
                                    inlineRadioButtons={inlineRadioButtons}
                                    value={option.value}
                                    onChange={onChange}
                                />
                            );
                        }}
                    </Field>
                );
            })}

            {showError && (
                <ErrorMessage name={name}>
                    {(msg) => (
                        <InputError
                            errorStyle={{ marginTop: "0.2rem" }}
                            error={msg}
                        />
                    )}
                </ErrorMessage>
            )}
        </Container>
    );
};

FormikRadioArray.displayName = "FormikRadioArray";

FormikRadioArray.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    inlineRadioButtons: PropTypes.bool,
    showError: PropTypes.bool,
};

FormikRadioArray.defaultProps = {
    size: "default",
    type: "text",
    readOnly: false,
    height: 30,
    inlineRadioButtons: false,
    showError: false,
};

export default FormikRadioArray;
