import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Collapse } from "react-collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import { trans } from "@cortexglobal/rla-intl";
import {
    PlainButton,
    Column,
    renderStandReturnInput,
    Tree,
    Badge,
} from "../../index";

const FilterHeading = styled(PlainButton)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size: ${({ large }) => (large ? "1.2rem" : "1rem")};
    color: ${({ theme }) => theme.filters.panel.color};
    cursor: ${(singleColumn) => (singleColumn ? "pointer" : "default")};
`;

export const FilterTitle = styled.h4`
    font-weight: bold;
    font-size: ${({ large }) => (large ? "1.2rem" : "1rem")};
    color: ${({ theme }) => theme.filters.panel.color};
    flex-grow: 1;
`;

const FilterColumn = styled(Column)`
    margin: 0.5rem 0;
    max-width: ${({ singleColumn }) => (singleColumn ? "100%" : "25%")};
`;
const ExpandArrow = styled(FontAwesomeIcon)``;

let FilterSection = ({
    filter,
    selectedFilters,
    onUpdate,
    onApply,
    singleColumn,
}) => {
    const [collapsed, setCollapsed] = useState(!!filter?.extra?.collapsed);
    if (filter.type === "LinkTree") {
        return (
            <FilterColumn singleColumn={singleColumn}>
                <FilterHeading
                    onClick={() => singleColumn && setCollapsed(!collapsed)}
                    singleColumn={singleColumn}
                >
                    <FilterTitle>
                        {trans("Browse {label}", {
                            label: filter.label,
                        })}
                    </FilterTitle>
                    {singleColumn && (
                        <ExpandArrow
                            icon={faChevronRight}
                            rotation={!collapsed ? 270 : 90}
                        />
                    )}
                </FilterHeading>
                <Collapse isOpened={!collapsed}>
                    <Tree
                        tabordionMode={true}
                        options={filter.options}
                        onClick={(event, choice) => {
                            onApply({
                                name: filter.name,
                                value: choice,
                            });
                        }}
                        selectedChoices={selectedFilters[filter.name]}
                        paddingTop="0.8rem"
                    />
                </Collapse>
            </FilterColumn>
        );
    }

    return (
        <FilterColumn singleColumn={singleColumn}>
            <FilterHeading
                onClick={() => singleColumn && setCollapsed(!collapsed)}
                singleColumn={singleColumn}
            >
                <FilterTitle>
                    {/* {trans("Filter by {label}", {
                        label: filter.label
                    })} */}
                    {filter.label}{" "}
                    {singleColumn &&
                        Array.isArray(selectedFilters[filter.name]) &&
                        selectedFilters[filter.name].length > 0 && (
                            <Badge
                                small={true}
                                style={{ padding: "0.25rem 0 0 0" }}
                            >
                                {selectedFilters[filter.name].length}
                            </Badge>
                        )}
                </FilterTitle>
                {singleColumn && (
                    <ExpandArrow
                        icon={faChevronRight}
                        rotation={!collapsed ? 270 : 90}
                    />
                )}
            </FilterHeading>
            <Collapse isOpened={!collapsed}>
                {typeof filter.options === "undefined" ||
                (Array.isArray(filter.options) && filter.options.length > 0) ? (
                    renderStandReturnInput(
                        { ...filter, hideLabel: true },
                        selectedFilters[filter.name],
                        onUpdate,
                        () => {},
                        filter?.extra?.limit
                            ? filter.extra.limit
                            : singleColumn
                            ? 8
                            : 40,
                        "filters"
                    )
                ) : (
                    <span>{trans("No filter options available")}</span>
                )}
            </Collapse>
        </FilterColumn>
    );
};

FilterSection.propTypes = {
    /** Filters: Need to be of a certain shape, but this needs to be worked out.*/
    filter: PropTypes.object.isRequired,
    // filters: PropTypes.object.isRequired,
    selectedFilters: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default FilterSection;
