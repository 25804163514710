export const isTouchDevice = () => {
    if (
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
        return true;
    }

    const mq = query => {
        return window.matchMedia(query).matches;
    };
    const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
        ""
    );
    return mq(query);
};

export default isTouchDevice;
