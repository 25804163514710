import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
//@ts-ignore cortex
import { PageTitle, IconTextButton, Panel } from "@cortexglobal/rla-components";
import NewsContext from "./NewsContext";
import NewsForm from "../../forms/NewsForm";

const NewsCreate = ({}) => {
    const history = useHistory();
    const { path } = useContext(NewsContext);

    const createNewsStory = (values) => {
        return axios
            .post("/api/v1/admin/news", values)
            .then(({ data: { data } }) => {
                history.push(`${path}/${data.uuid}/edit`);
                return data;
            });
    };

    return (
        <>
            <PageTitle title={trans("Create a News Story")}>
                <IconTextButton
                    as={Link}
                    to={`${path}`}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>
            <Panel>
                <NewsForm
                    onSubmit={createNewsStory}
                    story={{
                        title: "",
                        excerpt: "",
                        thumbnail: "",
                        body: "",
                        status: "active",
                        uuid: "",
                    }}
                />
            </Panel>
        </>
    );
};

export default NewsCreate;
