import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Measure from "react-measure";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { TaskTooltipContent } from "@cortexglobal/linear-calendar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faChevronDown,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { trans, FormattedCurrency, useIntl } from "@cortexglobal/rla-intl";
import { format, getItem, setItem } from "@cortexglobal/cortex-utilities";
import {
    PlainButton,
    Select,
    Row,
    Column,
    Table,
    IconTextButton,
    Tooltip,
    ShowMore,
} from "@cortexglobal/rla-components";

import {
    DataTable,
    DataColumn,
    Cell,
    TaskNameCell,
    CategoryCell,
    TableHeader,
    TaskLink,
    ArrowIcon,
    DateCellContents,
    DateHeader,
    SmallDate,
    FlexCell,
} from "./taskListingComponents";
import ActivityTable from "./ActivityTable";

require("fixed-data-table-2/dist/fixed-data-table.min.css");

const sortTasks = (tasks, sortSettings, categories) => {
    const { sortBy, sortDirection } = sortSettings;
    let sortedTasks = [];
    if (sortBy) {
        //Sort the tasks, within each category
        sortedTasks = tasks.sort((a, b) => {
            const aCategory = a.category_label;
            const bCategory = b.category_label;
            const aValue = get(a, sortBy);
            const bValue = get(b, sortBy);

            if (aCategory === bCategory) {
                if (aValue === bValue) return 0;
                if (sortDirection === "asc") {
                    return aValue < bValue ? -1 : 1;
                } else {
                    return aValue > bValue ? -1 : 1;
                }
            }

            if (aCategory === bCategory) return 0;
            return aCategory < bCategory ? -1 : 1;
        });
    } else {
        sortedTasks = tasks.sort((a, b) => {
            const aCategory = a.category_label;
            const bCategory = b.category_label;

            if (aCategory === bCategory) return 0;
            return aCategory < bCategory ? -1 : 1;
        });
    }

    //Sort the Flatten the categories
    let categoriesAndTasks = [];

    //First sort and add the top level categories
    categories
        .filter((category) => category.type === "category")
        .sort((a, b) => {
            //If categories have the same order, sort by label
            if (
                a.order === b.order &&
                typeof a.label === "string" &&
                typeof b.label === "string"
            ) {
                return a.label.localeCompare(b.label);
                // return  a.label < b.label ? -1 : 1;
            }
            return a.order > b.order ? -1 : 1;
        })
        .map((category) => {
            categoriesAndTasks.push({
                ...category,
                categoryType: "calendarCategory",
                meta: {
                    ...category.meta,
                    expanded: true,
                },
            });
        });

    //Add the task categories under the correct top level category
    categories
        .filter((category) => category.type === "task")
        .sort((a, b) => {
            //If categories have the same order, sort by label
            if (
                a.order === b.order &&
                typeof a.label === "string" &&
                typeof b.label === "string"
            ) {
                return a.label.localeCompare(b.label);
                // return  a.label < b.label ? -1 : 1;
            }
            return a.order > b.order ? -1 : 1;
        })
        .map((category) => {
            //Insert each sorted task category under the correct top level category

            //Find the correct top level category
            const calendarCategory = categoriesAndTasks.find(
                (calendarCategory) =>
                    calendarCategory.uuid === category.parent_uuid
            );

            //If we can't find the top level category, just push it in
            if (!calendarCategory) {
                return categoriesAndTasks.push({
                    ...category,
                    categoryType: "taskCategory",
                    meta: {
                        ...category.meta,
                        expanded: true,
                    },
                });
            }

            //Otherwise, push it in under the correct top level category
            const index = categoriesAndTasks.indexOf(calendarCategory);
            categoriesAndTasks.splice(index + 1, 0, {
                ...category,
                categoryType: "taskCategory",
                meta: {
                    ...category.meta,
                    expanded: true,
                },
            });
        });

    //Add the tasks under the correct task category
    sortedTasks.map((task) => {
        //Find the correct task category
        const taskCategory = categoriesAndTasks.find(
            (taskCategory) => taskCategory.uuid === task.primary_category_uuid
        );

        //If we can't find the task category, just push it in
        if (!taskCategory) {
            return categoriesAndTasks.push(task);
        }

        //Otherwise, push it in under the correct task category
        const index = categoriesAndTasks.indexOf(taskCategory);
        categoriesAndTasks.splice(index + 1, 0, task);
    });

    // console.log({ categoriesAndTasks });

    return categoriesAndTasks;
};

const getDateFormatting = (units) => {
    let formattingUnits = "dd/MM/yyyy";
    if (units === "quarters") {
        formattingUnits = "Qq yyyy";
    }
    if (units === "months") {
        formattingUnits = "MMM yyyy";
    }
    if (units === "weeks") {
        formattingUnits = "ww yyyy";
    }
    return formattingUnits;
};

const processKpiData = (kpiData, units) => {
    const formattingUnits = getDateFormatting(units);
    let processedKpiData = [];
    if (!kpiData || kpiData.length <= 0) {
        return processedKpiData;
    }
    //Push in a "fake" category
    processedKpiData.push({
        uuid: "kpi",
        name: "KPIs",
        type: "category",
        categoryType: "kpiCategory",
        meta: {
            expanded: true,
        },
    });

    return [
        ...processedKpiData,
        ...kpiData.reduce((summarisedKpiData, kpi) => {
            //Set the formatted date which is used as the key
            const formattedDate = format(new Date(kpi.date), formattingUnits);
            //Get the index of the KPI metric in the summarised data

            const index = summarisedKpiData.findIndex(
                (summarisedKpi) => summarisedKpi.metric_id === kpi.metric_id
            );
            //If this KPI metric is not in the summarised data, add it
            if (index < 0) {
                summarisedKpiData.push({
                    ...kpi,
                    type: "kpi",
                    data: [
                        {
                            date: formattedDate,
                            value: kpi.value,
                        },
                    ],
                });
                return summarisedKpiData;
            }
            //Otherwise, add the value to the existing data
            //If the date is already in the data, add it to the current value, otherwise add it to the data
            const dateIndex = summarisedKpiData[index].data.findIndex(
                (data) => data.date === formattedDate
            );
            if (dateIndex >= 0) {
                summarisedKpiData[index].data[dateIndex].value += kpi.value;
            } else {
                summarisedKpiData[index].data.push({
                    date: formattedDate,
                    value: kpi.value,
                });
            }
            return summarisedKpiData;
        }, []),
    ];
};

const TaskTable = ({
    dates,
    // onShowTasks,
    addActivity,
    taskClick,
    theme,
    categories,
    user,
    tasks: allTasks,
    match,
    resetSelections,
    units,
    allowLinks = true,
    kpiData,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const [sortSettings, setSortSettings] = useState({
        sortBy: "name",
        sortDirection: "asc",
    });
    const [width, setWidth] = useState(1000);
    const [height, setHeight] = useState(1000);
    const [dataItems, setDataItems] = useState([]);
    const [filteredDataItems, setFilteredDataItems] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [taskHovered, setTaskHovered] = useState("");
    const [columnWidths, setColumnWidths] = useState({
        name: 380,
    });
    const [dateCellWidth, setDateCellWidth] = useState(30);

    useEffect(() => {
        dispatch(resetSelections());
        document.getElementsByTagName("BODY")[0].style[
            "overscroll-behavior-x"
        ] = "none";
        return () => {
            document.getElementsByTagName("BODY")[0].style[
                "overscroll-behavior-x"
            ] = "auto";
        };
    }, []);

    useEffect(() => {
        //If there is KPI data, we're going to add a KPI category and then merge the KPI data into the tasks
        const processedKpiData = processKpiData(kpiData, units);
        // console.log({ processedKpiData });

        //Sort the data items
        const sortedTasks = sortTasks(allTasks, sortSettings, categories);

        //Merge the KPI data into the tasks
        setDataItems([...processedKpiData, ...sortedTasks]);
    }, [allTasks]);

    useEffect(() => {
        setFilteredDataItems(filterCollapsedItems(dataItems));
    }, [dataItems]);

    // const units = getItem("planner_units", "days")["planner_units"];
    useEffect(() => {
        let dateCellWidth = 30;
        if (units === "quarters") {
            dateCellWidth = 90;
        }
        if (units === "months") {
            dateCellWidth = 70;
        }
        if (units === "weeks") {
            dateCellWidth = 70;
        }
        setDateCellWidth(dateCellWidth);
    }, [units]);

    const columns = {
        start_date: {
            label: trans("Start"),
            type: "date",
        },
        end_date: {
            label: trans("End"),
            type: "date",
        },
        planned_spend: {
            label: trans("Planned"),
            type: "currency",
            width: 100,
        },
        actual_spend: {
            label: trans("Actual"),
            type: "currency",
            width: 100,
        },
        "group.name": {
            label: trans("Group"),
        },
        "user.name": {
            label: trans("User Name"),
        },
        "user.email": {
            label: trans("User Email"),
        },
    };
    const defaultColumns = [
        "start_date",
        "end_date",
        "planned_spend",
        "actual_spend",
        "group.name",
        "user.name",
        "user.email",
    ];
    const columnsFromStorage = getItem(
        "planner_columns",
        JSON.stringify(defaultColumns)
    );
    const [selectedColumns, setSelectedColumns] = useState(
        JSON.parse(columnsFromStorage["planner_columns"])
    );
    const resize = (contentRect) => {
        setWidth(contentRect.bounds.width);
        setHeight(contentRect.bounds.height);
    };

    // const renderContents = (props, column, columnData) => {
    //     const dataItem = get(filteredDataItems, `[${props.rowIndex}]`);
    //     if (!dataItem) {
    //         return;
    //     }
    //     if (dataItem.categoryType) {
    //         return (
    //             <CategoryCell
    //                 $categoryTheme={theme.colors[dataItem.categoryType]}
    //                 {...props}
    //             />
    //         );
    //     }

    //     const value = get(dataItem, `${column}`);

    //     switch (columnData.type) {
    //         case "date":
    //             return <Cell {...props}>{format(new Date(value))}</Cell>;
    //         case "currency":
    //             return (
    //                 <Cell style={{ textAlign: "right" }} {...props}>
    //                     <FormattedCurrency
    //                         value={value}
    //                         currency={user.currency || "GBP"}
    //                     />
    //                 </Cell>
    //             );
    //         default:
    //             return <Cell {...props}>{value}</Cell>;
    //     }
    // };

    const renderDateHeader = (props, date, isFirst, isLast) => {
        if (units === "quarters") {
            return (
                <DateHeader {...props}>
                    Q{date.format("Q")}
                    <SmallDate>{date.format("YYYY")}</SmallDate>
                </DateHeader>
            );
        }
        if (units === "months") {
            return (
                <DateHeader {...props}>
                    {date.format("MMM")}
                    <SmallDate>{date.format("YYYY")}</SmallDate>
                </DateHeader>
            );
        }
        if (units === "weeks") {
            return (
                <DateHeader {...props}>
                    {isFirst
                        ? date.format("DD")
                        : date.clone().startOf("week").format("DD")}
                    {/* {" - "} */}
                    {"-"}
                    {isLast
                        ? date.format("DD")
                        : date.clone().endOf("week").format("DD")}
                    <SmallDate>{date.format("MMM")}</SmallDate>
                </DateHeader>
            );
        }

        return (
            <DateHeader {...props}>
                {date.format("D")}
                <SmallDate>{date.format("MMM")}</SmallDate>
            </DateHeader>
        );
    };

    const renderDateContents = (props, date) => {
        const dataItem = get(filteredDataItems, `[${props.rowIndex}]`);
        if (!dataItem) {
            return;
        }
        if (dataItem.categoryType) {
            return (
                <CategoryCell
                    $categoryTheme={theme.colors[dataItem.categoryType]}
                    {...props}
                />
            );
        }

        if (dataItem.type === "kpi") {
            //Change the moment date to the correct format
            const formattedDateKey = format(
                date.toDate(),
                getDateFormatting(units)
            );
            const kpi = dataItem.data.find(
                (kpi) => kpi.date === formattedDateKey
            );
            if (!kpi) {
                return (
                    <Cell
                        style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}
                        {...props}
                    />
                );
            }

            return (
                <Cell
                    style={{
                        borderRight: "1px solid rgba(0,0,0,0.1)",
                        textAlign: "right",
                    }}
                    {...props}
                >
                    {kpi.value}
                </Cell>
            );
        }

        //Is the date the first date of the task
        const isStartDate = date.isSame(new Date(dataItem.start_date), units);
        //Is the date the last date of the task
        const isEndDate = date.isSame(new Date(dataItem.end_date), units);

        //Is the task happening on the date?
        const isTaskOnDate = date.isBetween(
            dataItem.start_date,
            dataItem.end_date,
            units,
            "[]"
        );

        if (isTaskOnDate) {
            return (
                <FlexCell
                    style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}
                    {...props}
                    onMouseEnter={() => {
                        setTaskHovered(dataItem.uuid);
                    }}
                    onMouseLeave={() => {
                        setTaskHovered("");
                    }}
                >
                    <DateCellContents
                        task={dataItem}
                        beingHovered={dataItem.uuid === taskHovered}
                        plannerTheme={theme}
                        isStartDate={isStartDate}
                        isEndDate={isEndDate}
                        onClick={(event) => {
                            return taskClick({
                                category: categories.find(
                                    (category) =>
                                        category.id ===
                                        dataItem.primary_category_id
                                ),
                                task: dataItem,
                                left: 0,
                                width: 20,
                                event,
                            });
                        }}
                    >
                        <Tooltip
                            tagName="div"
                            text={<TaskTooltipContent task={dataItem} />}
                            zIndex={10000}
                            showIcon={false}
                            setMaxWidth={false}
                            direction="bottom"
                            hoverDelay={300}
                        >
                            <span>&nbsp;</span>
                        </Tooltip>
                    </DateCellContents>
                </FlexCell>
            );
        } else {
            return (
                <Cell
                    style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}
                    {...props}
                />
            );
        }
    };

    const handleColumnsChange = (value) => {
        setSelectedColumns(value);
        setItem("planner_columns", value);
    };

    const handleSortUpdate = (newSortSettings) => {
        setExpandedRows([]);
        setSortSettings(newSortSettings);

        //If there is KPI data, we're going to add a KPI category and then merge the KPI data into the tasks
        const processedKpiData = processKpiData(kpiData, units);

        //Sort the data items
        const sortedTasks = sortTasks(allTasks, newSortSettings, categories);

        //Merge the KPI data into the tasks
        setDataItems([...processedKpiData, ...sortedTasks]);
    };

    const toggleCategory = (category) => {
        setDataItems(
            dataItems.map((dataItem) => {
                if (dataItem.categoryType && dataItem.uuid === category.uuid) {
                    const expanded = dataItem.meta
                        ? !dataItem.meta.expanded
                        : true;
                    //LOOK AT WHETHER WE SHOULD USE LOCAL STORAGE TO STORE THE EXPANDED STATE
                    //LIKE IN CSL'S PLANNER
                    // onShowTasks(expanded, category);
                    dataItem.meta = {
                        ...dataItem.meta,
                        expanded,
                    };
                }
                return dataItem;
            })
        );
    };

    const filterCollapsedItems = (dataItems) => {
        return dataItems.filter((dataItem) => {
            //Keep all calendar categories as they are needed to "re-expand" any child items
            if (
                dataItem.categoryType === "calendarCategory" ||
                dataItem.categoryType === "kpiCategory"
            ) {
                return true;
            }
            //Handle the task categories
            if (dataItem.categoryType === "taskCategory") {
                const calendarCategory = dataItems.find(
                    (item) => item.uuid === dataItem.parent_uuid
                );
                if (
                    calendarCategory &&
                    calendarCategory.meta &&
                    !calendarCategory.meta.expanded
                ) {
                    return false;
                } else {
                    return true;
                }
            }

            //Handle the KPIs
            if (dataItem.type === "kpi") {
                const kpiCategory = dataItems.find(
                    (item) => item.categoryType === "kpiCategory"
                );
                if (!kpiCategory || !kpiCategory.meta.expanded) {
                    return false;
                } else {
                    return true;
                }
            }

            //Handle the tasks
            const taskCategory = dataItems.find(
                (item) =>
                    item.categoryType === "taskCategory" &&
                    item.id === dataItem.primary_category_id
            );

            //If this tasks category is collapsed
            if (
                !taskCategory ||
                (taskCategory.meta && !taskCategory.meta.expanded)
            ) {
                return false;
            }
            const calendarCategory = dataItems.find(
                (item) =>
                    item.categoryType === "calendarCategory" &&
                    item.id === dataItem.primary_planner_category_id
            );
            //If this tasks category is collapsed
            if (
                !calendarCategory ||
                (calendarCategory.meta && !calendarCategory.meta.expanded)
            ) {
                return false;
            }

            return true;
        });
    };

    const onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
        setColumnWidths({
            ...columnWidths,
            [columnKey]: newColumnWidth,
        });
    };

    const getSubRowHeight = (rowIndex) => {
        if (!expandedRows.includes(rowIndex)) {
            return 0;
        }
        return Array.isArray(dataItems[rowIndex].activities) &&
            dataItems[rowIndex].activities.length > 0
            ? 72 + dataItems[rowIndex].activities.length * 60
            : 80;
    };

    const activityDetail = ({ rowIndex, width, height }) => {
        if (!expandedRows.includes(rowIndex)) {
            return null;
        }

        const task = dataItems[rowIndex];
        return (
            <ActivityTable
                task={task}
                match={match}
                allowLinks={allowLinks}
                user={user}
            />
        );
    };
    return (
        <>
            <Row>
                <Measure bounds onResize={debounce(resize, 300)}>
                    {({ measureRef }) => (
                        <div
                            ref={measureRef}
                            className="taskList"
                            style={{
                                display: "flex",
                                height: "65vh",
                                margin: "1rem 0 0 0",
                            }}
                        >
                            <DataTable
                                rowsCount={filteredDataItems.length}
                                rowHeight={40}
                                headerHeight={50}
                                width={width}
                                height={height}
                                subRowHeightGetter={getSubRowHeight}
                                rowExpanded={activityDetail}
                                onColumnResizeEndCallback={
                                    onColumnResizeEndCallback
                                }
                                isColumnResizing={false}
                                type="planner"
                            >
                                {/* <ColumnGroup
                                fixed={true}
                                header={<Cell>Name</Cell>}
                            > */}
                                <DataColumn
                                    header={(props) => (
                                        <TableHeader
                                            columnData={{
                                                label: trans("Name"),
                                            }}
                                            columnSlug="name"
                                            sortSettings={sortSettings}
                                            onChange={handleSortUpdate}
                                            sortable={false}
                                            {...props}
                                        ></TableHeader>
                                    )}
                                    isResizable={true}
                                    minWidth={300}
                                    maxWidth={600}
                                    fixed={true}
                                    width={columnWidths.name}
                                    columnKey="name"
                                    cell={(props) => {
                                        const dataItem =
                                            filteredDataItems[props.rowIndex];
                                        if (!dataItem) {
                                            return;
                                        }
                                        if (dataItem.categoryType) {
                                            return (
                                                <CategoryCell
                                                    $categoryTheme={
                                                        theme.colors[
                                                            dataItem
                                                                .categoryType
                                                        ]
                                                    }
                                                    {...props}
                                                >
                                                    <PlainButton
                                                        style={{
                                                            color: "inherit",
                                                        }}
                                                        onClick={() =>
                                                            toggleCategory(
                                                                dataItem
                                                            )
                                                        }
                                                    >
                                                        {dataItem.meta &&
                                                        !dataItem.meta
                                                            .expanded ? (
                                                            <ArrowIcon
                                                                icon={
                                                                    faChevronDown
                                                                }
                                                            />
                                                        ) : (
                                                            <ArrowIcon
                                                                icon={
                                                                    faChevronDown
                                                                }
                                                                rotation={180}
                                                            />
                                                        )}{" "}
                                                        <span>
                                                            {dataItem.name}
                                                        </span>
                                                    </PlainButton>
                                                    {dataItem &&
                                                        dataItem.can_admin_category && (
                                                            <PlainButton
                                                                className="plannerTaskCategoryEditLink"
                                                                as={Link}
                                                                to={`${match.path}/task-category/${dataItem.uuid}`}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEdit
                                                                    }
                                                                    transform="shrink-2"
                                                                />
                                                            </PlainButton>
                                                        )}
                                                </CategoryCell>
                                            );
                                        }
                                        const hasExpandedActivities =
                                            expandedRows.includes(
                                                props.rowIndex
                                            );
                                        return (
                                            <TaskNameCell {...props}>
                                                <TaskLink
                                                    to={`${match.path}/task/${dataItem.uuid}`}
                                                >
                                                    <ShowMore type="tooltip">
                                                        {dataItem.name}{" "}
                                                        {dataItem.status ===
                                                        "draft"
                                                            ? trans("(Draft)")
                                                            : ""}
                                                    </ShowMore>
                                                </TaskLink>
                                                <small>
                                                    {Array.isArray(
                                                        dataItem.activities
                                                    ) &&
                                                        dataItem.activities
                                                            .length > 0 && (
                                                            <>
                                                                <PlainButton
                                                                    style={{
                                                                        marginRight:
                                                                            "0.5rem",
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            hasExpandedActivities
                                                                        ) {
                                                                            setExpandedRows(
                                                                                expandedRows.filter(
                                                                                    (
                                                                                        row
                                                                                    ) =>
                                                                                        row !==
                                                                                        props.rowIndex
                                                                                )
                                                                            );
                                                                        } else {
                                                                            setExpandedRows(
                                                                                [
                                                                                    ...expandedRows,
                                                                                    props.rowIndex,
                                                                                ]
                                                                            );
                                                                        }
                                                                    }}
                                                                    title={intl.formatMessage(
                                                                        {
                                                                            id: "Show Activities",
                                                                        }
                                                                    )}
                                                                >
                                                                    <ArrowIcon
                                                                        icon={
                                                                            faChevronDown
                                                                        }
                                                                        rotation={
                                                                            hasExpandedActivities
                                                                                ? 180
                                                                                : 0
                                                                        }
                                                                    />
                                                                </PlainButton>
                                                                (
                                                                {trans(
                                                                    "{count, plural, =0 {no activities} one {# activity} other {# activities}}",
                                                                    {
                                                                        count: dataItem
                                                                            .activities
                                                                            .length,
                                                                    }
                                                                )}
                                                                )
                                                            </>
                                                        )}{" "}
                                                </small>{" "}
                                                <section className="taskButtons">
                                                    {dataItem.can_admin_details && (
                                                        <PlainButton
                                                            onClick={(event) =>
                                                                addActivity(
                                                                    dataItem
                                                                )
                                                            }
                                                            title={intl.formatMessage(
                                                                {
                                                                    id: "Add Activity",
                                                                }
                                                            )}
                                                        >
                                                            <ArrowIcon
                                                                icon={faPlus}
                                                            />
                                                        </PlainButton>
                                                    )}
                                                </section>
                                            </TaskNameCell>
                                        );
                                    }}
                                />
                                {/* {selectedColumns.map((column) => {
                                const columnData = columns[column];
                                return (
                                    <DataColumn
                                        fixed={true}
                                        header={(props) => (
                                            <TableHeader
                                                columnData={columnData}
                                                columnSlug={column}
                                                sortSettings={sortSettings}
                                                onChange={handleSortUpdate}
                                                {...props}
                                            ></TableHeader>
                                        )}
                                        cell={(props) => {
                                            return renderContents(
                                                props,
                                                column,
                                                columnData
                                            );
                                        }}
                                        width={columnData.width || 200}
                                    />
                                );
                            })} */}

                                {dates.map((date, index) => {
                                    // console.log(date);
                                    const isFirst = index === 0;
                                    const isLast = index === dates.length - 1;
                                    return (
                                        <DataColumn
                                            key={date}
                                            header={(props) => {
                                                return renderDateHeader(
                                                    props,
                                                    date,
                                                    isFirst,
                                                    isLast
                                                );
                                            }}
                                            cell={(props) => {
                                                return renderDateContents(
                                                    props,
                                                    date
                                                );
                                            }}
                                            width={dateCellWidth}
                                        />
                                    );
                                })}
                            </DataTable>
                        </div>
                    )}
                </Measure>
            </Row>
        </>
    );
};

export default TaskTable;
