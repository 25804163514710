import styled from "styled-components";

export const ErrorIndicator = styled.span`
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.alert};
    font-size: 1.2rem;
    line-height: 0; //Fixing sizing issue
    &::after {
        content: "*";
    }
`;
