import React, { useState } from "react";
import {
    IconTextButton,
    SubmitButton,
    Button,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";

const TransitionButton = ({
    transition,
    disabled,
    buttonType = "",
    performTransition,
    smartRef,
}: {
    transition: any;
    disabled: any;
    buttonType: string;
    performTransition: any;
    smartRef: any;
}) => {
    let type = "secondary";

    switch (transition.sentiment) {
        case "positive":
            type = "success";
            break;
        case "negative":
            type = "alert";
            break;
        default:
            type = "black";
    }

    if (buttonType == "Button") {
        return (
            <Button
                $expanded
                $themeColor={type}
                onClick={() => performTransition(smartRef, transition)}
                disabled={!!disabled}
                $disabledOpacity={0.8}
            >
                {transition.name}
            </Button>
        );
    }

    if (buttonType == "SubmitButton") {
        return (
            <SubmitButton
                expanded
                themeColor={type}
                onClick={() => performTransition(smartRef, transition)}
                disabled={!!disabled}
            >
                {transition.name}
            </SubmitButton>
        );
    }

    return (
        <IconTextButton
            icon={transition.icon || "chevron-right"}
            // style={{ paddingLeft: 0 }}
            expanded
            themeColor={type}
            onClick={() => performTransition(smartRef, transition)}
            disabled={!!disabled}
            showCircle={transition.icon ? false : true}
        >
            {transition.name}
        </IconTextButton>
    );
};
export default TransitionButton;
