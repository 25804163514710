import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
//@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Loading,
    Row,
    Panel,
    useAddToast,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import { NavigationForm, Navigation } from "../../forms/NavigationForm";
import { HeaderEmphasis } from "../../components/index";

export const NavigationCreate = ({ path }) => {
    const history = useHistory();
    //parent_id will be set if it's in the URL otherwise it's null which is what we want
    const parent_id = useQueryString().get("parent_id")
        ? parseInt(useQueryString().get("parent_id"))
        : null;
    const addToast = useAddToast();

    const item: Navigation = {
        locale: "",
        name: "",
        slug: "",
        path: "",
        parent_id: parent_id,
        position: 0,
        underline_color: null,
        show_in_nav: true,
        level: 0,
        permissions: null,
        permissions_application: null,
        guest_permission: false,
    };

    const submitForm = (values) => {
        return axios
            .post(`/api/v1/admin/navigation`, values)
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: trans("New navigation item saved"),
                    showFor: 5000,
                });
                history.push(`${path}/edit/${data.data.uuid}`);
            });
    };
    return (
        <div>
            <PageTitle title={trans("New Navigation")}>
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <NavigationForm item={item} submitForm={submitForm} />
                </Panel>
            </Row>
        </div>
    );
};

export default NavigationCreate;
