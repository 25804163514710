import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { isEmpty } from "lodash";

import {
    PageTitle,
    Error,
    IconTextButton,
    Row,
    Column,
    Tabordion,
    Badge,
    Select,
    Toggle,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

import {
    getRequestsSummary,
    getRequests,
    getRequestTypes,
} from "../requestsActions";
import RequestsTable from "../components/RequestsTable";

const MyRequests = ({
    requestsPath,
    requests,
    summary: { loaded: summaryLoaded, error: summaryError, data: summary },
    filters,
    types: { loaded: typesLoaded, data: types },
    getRequestsSummary,
    getRequestTypes,
    getRequests,
    // requestTypeAlias = "",
}) => {
    const intl = useIntl();

    const [state, setState] = useState({
        list: "my-assigned",
        groupFilter: "",
        statusFilter: "",
        typeFilter: "",
        includeClosed: false,
    });

    useEffect(() => {
        // populates the lists - dont want this to change when updating the list/filters
        getRequestsSummary(state.includeClosed);

        if (!requests.loaded /*&& !requestTypeAlias*/) {
            fetchData();
        }

        if (!typesLoaded) {
            getRequestTypes();
            // .then((types) => {
            // if (requestTypeAlias) {
            //     const type = types.find(
            //         (type) => type.alias === requestTypeAlias
            //     );
            //     if (type) {
            //         setState({ ...state, typeFilter: [type.uuid] });
            //     } else {
            //         fetchData();
            //     }
            // }
            // });
        }
    }, []);

    useEffect(() => {
        getRequestsSummary(state.includeClosed);
    }, [state.includeClosed]);

    useEffect(() => {
        fetchData();
    }, [state]);

    const fetchData = (page = 1) => {
        const { list, groupFilter, statusFilter, typeFilter, includeClosed } =
            state;
        return getRequests(
            page,
            list,
            groupFilter,
            statusFilter,
            typeFilter,
            includeClosed
        );
    };

    const setFilter = ({ name, value }) => {
        setState({ ...state, [name]: value });
    };
    const formattedFilterOptions = (options) => {
        if (isEmpty(options)) {
            return [];
        }
        return options.map((info) => {
            return {
                value: info.uuid,
                // text: `${info.name}${info.total ? `: ${info.total}` : ""}`,
                text: `${info.name}`,
            };
        });
    };

    const { groupFilter, statusFilter, typeFilter, includeClosed } = state;
    if (requests.error.length || summaryError.length) {
        return <Error>{trans("Error loading requests")}</Error>; // TODO: swap for actual error component?
    }

    //Common RequestTable props, grouped here to save repetition later
    const commonRequestTableProps = {
        requestsPath: requestsPath,
        requests: requests,
        fetchData: fetchData,
        getRequestsSummary: getRequestsSummary,
        setFilter: setFilter,
        filters: filters,
        groupFilter,
        statusFilter,
        typeFilter,
        types,
    };

    // Check if there are any types that can be created, if so, we'll show the create button
    const createableTypesAvailable =
        typesLoaded &&
        Array.isArray(types) &&
        types.filter((type) => {
            return type.automatic_creation_only === false;
        }).length > 0;

    const listTypes = [
        {
            text: trans("Available to action: {count}", {
                count: !summaryLoaded ? "..." : summary.available_to_me ?? 0,
            }),
            value: "my-available",
        },
        {
            text: trans("Assigned to me: {count}", {
                count: !summaryLoaded ? "..." : summary.assigned_to_me ?? 0,
            }),
            value: "my-assigned",
        },
        {
            text: trans("Created by me: {count}", {
                count: !summaryLoaded ? "..." : summary.created_by_me ?? 0,
            }),
            value: "my-created",
        },
        {
            text: trans("All: {count}", {
                count: !summaryLoaded ? "..." : summary.all ?? 0,
            }),
            value: "all",
        },
    ];

    return (
        <React.Fragment>
            <PageTitle title={trans("My Requests")}>
                {createableTypesAvailable && (
                    <IconTextButton
                        as={Link}
                        to={`${requestsPath}/create`}
                        showCircle={false}
                        icon={faPlus}
                    >
                        {trans("Create a new Request")}
                    </IconTextButton>
                )}
            </PageTitle>
            <Row style={{ marginTop: "1.2rem", display: "flex" }} collapse>
                <Row style={{ flex: 1 }} collapse>
                    <Column medium={3}>
                        <Select
                            name="list"
                            onChange={setFilter}
                            value={state.list}
                            options={listTypes}
                            emptyOption={intl.formatMessage({
                                id: "Queue",
                            })}
                        />
                    </Column>
                    <Column medium={3}>
                        <Select
                            name="groupFilter"
                            onChange={setFilter}
                            value={groupFilter}
                            multi={true}
                            options={formattedFilterOptions(
                                filters.data.by_group
                            )}
                            emptyOption={intl.formatMessage({
                                id: "Filter by assigned organisation",
                            })}
                        />
                    </Column>
                    <Column medium={3}>
                        <Select
                            name="statusFilter"
                            onChange={setFilter}
                            value={statusFilter}
                            multi={true}
                            options={formattedFilterOptions(
                                filters.data.by_status
                            )}
                            emptyOption={intl.formatMessage({
                                id: "Filter by status",
                            })}
                        />
                    </Column>
                    <Column medium={3}>
                        <Select
                            name="typeFilter"
                            onChange={setFilter}
                            value={typeFilter}
                            multi={true}
                            emptyOption={intl.formatMessage({
                                id: "Filter by request type",
                            })}
                            options={formattedFilterOptions(types)}
                        />
                    </Column>
                </Row>
                <label
                    htmlFor="includeClosed"
                    style={{ fontSize: "0.7rem", marginRight: "0.8rem" }}
                >
                    {trans("Show closed")}
                    <Toggle
                        style={{ display: "block" }}
                        id="includeClosed"
                        name="includeClosed"
                        checked={includeClosed}
                        onChange={(e) => {
                            console.log(e);
                            e.preventDefault();
                            setFilter({
                                name: "includeClosed",
                                value: e.target.checked,
                            });
                        }}
                        size="medium"
                        // small={true}
                    />
                </label>
            </Row>
            <Row>
                {/* <Tabordion current={0} unmounts={false}> */}
                <RequestsTable
                    // onTabClick={() => setList("my-available")}
                    // heading={
                    //     <div>
                    //         {trans("Available to action ")}
                    //         {summary.available_to_me > 0 && (
                    //             <Badge small={true}>
                    //                 {summary.available_to_me}
                    //             </Badge>
                    //         )}
                    //     </div>
                    // }
                    {...commonRequestTableProps}
                />
                {/* <RequestsTable
                        onTabClick={() => setList("my-assigned")}
                        heading={
                            <div>
                                {trans("Assigned to me")}{" "}
                                {summary.assigned_to_me > 0 && (
                                    <Badge small={true}>
                                        {summary.assigned_to_me}
                                    </Badge>
                                )}
                            </div>
                        }
                        {...commonRequestTableProps}
                    />
                    <RequestsTable
                        onTabClick={() => setList("my-created")}
                        heading={
                            <div>
                                {trans("Created by me ")}
                                {summary.created_by_me > 0 && (
                                    <Badge small={true}>
                                        {summary.created_by_me}
                                    </Badge>
                                )}
                            </div>
                        }
                        {...commonRequestTableProps}
                    />
                    <RequestsTable
                        heading={
                            <div onClick={() => setList("all")}>
                                {trans("All ")}{" "}
                                {summary.all > 0 && (
                                    <Badge small={true}>{summary.all}</Badge>
                                )}
                            </div>
                        }
                        {...commonRequestTableProps}
                    /> */}
                {/* </Tabordion> */}
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { myRequests, summary, filters, types } = state.requests;
    return {
        requests: myRequests,
        summary,
        filters,
        types,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRequestsSummary,
            getRequests,
            getRequestTypes,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests);
