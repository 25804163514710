import React, { useContext } from "react";
import MicrositeConsumer from "./MicrositeContext";
//import * as Respreeza from "./templates/respreeza";
import isString from "lodash/isString";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";
import { Route, Switch, RouteProps } from "react-router-dom";
import { makeProps, prepareContent } from "./micrositeFunctions";
import { Pages } from "./interfaces/microsite";
import { Footnotes } from "./components/Footnotes";
import { AdditionalInformation } from "./components/AdditionalInformation";
import { PrescribingInfo } from "./components/PrescribingInfo";

const RenderPages = ({
    pages,
    components,
    basePath,
    globalSettings,
    globalTheming,
}: {
    pages: Pages;
    components: {
        [key: string]: any;
    };
    basePath: string;
    globalSettings: {
        [key: string]: any;
    };
    globalTheming: { [key: string]: any };
}) => {
    // Reduce allows opportunity to not return a page
    const routes = pages.reduce(
        (generatedRoutes: any[], [path, pageSetup], index: number) => {
            // Ensure component is valid AND config is valid
            if (!isObject(pageSetup) || !isString(path)) {
                return generatedRoutes;
            }

            // const path = pageSetup[0].replace("_", "-");
            const { component: key } = pageSetup;
            const Component = components[key];

            if (!isFunction(Component)) {
                return generatedRoutes;
            }

            const settings = makeProps(pageSetup.settings);
            const content = prepareContent(pageSetup.content);
            const additionalInformation = pageSetup.additionalInformation
                ? prepareContent(pageSetup.additionalInformation)
                : [];

            let updatedRoutes = [
                ...generatedRoutes,
                <Route
                    exact
                    // path={index === 0 ? `${basePath}/` : `${basePath}/${path}`}
                    path={`${basePath}/${path}`}
                    key={path}
                    render={() => {
                        return (
                            <>
                                <Component
                                    groupName={pageSetup.group}
                                    pageName={pageSetup.name}
                                    settings={settings}
                                    content={content}
                                    disableSequencing={
                                        pageSetup.disableSequencing
                                    }
                                    transitions={
                                        pageSetup.transitions
                                            ? pageSetup.transitions
                                            : []
                                    }
                                />
                                <Footnotes
                                    content={{
                                        ...content,
                                        ...additionalInformation,
                                    }}
                                    globalSettings={globalSettings}
                                    globalTheming={globalTheming}
                                    groupName={pageSetup.group}
                                    pageName={pageSetup.name}
                                />
                                <AdditionalInformation
                                    groupName={pageSetup.group}
                                    pageName={pageSetup.name}
                                    content={additionalInformation}
                                    settings={settings}
                                />
                                <PrescribingInfo />
                            </>
                        );
                    }}
                />,
            ];

            //Assign the first page to be the index route (as well as it's own route)
            if (index === 0) {
                updatedRoutes = [
                    <Route
                        exact
                        path={`${basePath}/`}
                        key="indexPath"
                        render={() => {
                            return (
                                <>
                                    <Component
                                        settings={settings}
                                        content={content}
                                        disableSequencing={
                                            pageSetup.disableSequencing
                                        }
                                        transitions={
                                            pageSetup.transitions
                                                ? pageSetup.transitions
                                                : []
                                        }
                                    />
                                    <Footnotes
                                        content={{
                                            ...content,
                                            ...additionalInformation,
                                        }}
                                        globalSettings={globalSettings}
                                        globalTheming={globalTheming}
                                    />
                                    <AdditionalInformation
                                        groupName={pageSetup.group}
                                        content={additionalInformation}
                                        settings={settings}
                                    />
                                    <PrescribingInfo />
                                </>
                            );
                        }}
                    />,
                    ...updatedRoutes,
                ];
            }

            return updatedRoutes;
        },
        []
    );

    // console.log(routes);

    return <Switch>{routes}</Switch>;
};

export default RenderPages;
