import React, { useState } from "react";
import axios from "axios";
import { ErrorMessage, Form, Formik } from "formik";
import {
    FormikInput,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import * as Yup from "yup";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

const Schema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Must be more than 2 characters")
        .max(255, "Must be less than 255 characters")
        .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    job_description: Yup.string()
        .min(2, "Must be more than 2 characters")
        .max(255, "Must be less than 255 characters")
        .required("Required"),
    phone_number: Yup.string()
        .min(10, "Must be more than 10 characters")
        .max(12, "Must be less than 12 characters")
        .required("Required"),
    dealer_group_name: Yup.string().required("Required"),
    dealer_name: Yup.string().required("Required"),
    dealer_postcode: Yup.string().required("Required"),
});

const NewDealerForm = ({ successCallback }) => {
    const addToast = useAddToast();
    const [newDealerLoadingState, setNewDealerLoadingState] = useState(false);
    const [newDealerError, setNewDealerError] = useState(false);

    const onSubmit = (values, { setErrors }) => {
        setNewDealerLoadingState(true);
        setNewDealerError(false);

        axios
            .post(`/api/v1/new-dealer`, values)
            .then((response) => {
                successCallback();

                addToast({
                    type: "success",
                    content:
                        "Request to add a new dealership has been sent to the team",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                parseValidationErrors(e)
                    .then(({ errors }) => {
                        setErrors(errors);
                    })
                    .catch((e) => {
                        if (
                            e.response &&
                            e.response.status &&
                            e.response.status === 403
                        ) {
                            this.setState((state) => ({
                                tokenError: e.response.data.data.response_trans,
                            }));
                        }
                    });

                setNewDealerError(true);
            })
            .finally(() => {
                setNewDealerLoadingState(false);
            });
    };

    return (
        <Formik
            initialValues={{
                name: "",
                email: "",
                job_description: "",
                phone_number: "",
                dealer_group_name: "",
                dealer_name: "",
                dealer_postcode: "",
            }}
            onSubmit={onSubmit}
            validationSchema={Schema}
        >
            {({ values, setFieldValue, errors }) => {
                return (
                    <Form className="form-group">
                        <FormikInput
                            type="text"
                            name="name"
                            label="Name"
                            placeholder="Joe"
                            value={values.name}
                        />
                        <FormikInput
                            type="text"
                            name="email"
                            label="Email"
                            placeholder="user@domain.com"
                            value={values.email}
                        />
                        <FormikInput
                            type="text"
                            name="job_description"
                            label={trans("Job Description")}
                            value={values.job_description}
                        />
                        <FormikInput
                            type="text"
                            name="phone_number"
                            label={trans("Phone Number")}
                            value={values.phone_number}
                        />
                        <FormikInput
                            type="text"
                            name="dealer_group_name"
                            label="Dealer Group Name"
                            value={values.dealer_group_name}
                        />
                        <FormikInput
                            type="text"
                            name="dealer_name"
                            label="Dealership Name"
                            value={values.dealer_name}
                        />
                        <FormikInput
                            type="text"
                            name="dealer_postcode"
                            label="Dealership Postcode"
                            value={values.dealer_postcode}
                        />
                        {newDealerError === true && (
                            <ErrorMessage>
                                {trans(
                                    "There was an error submitting your form."
                                )}
                            </ErrorMessage>
                        )}
                        <SubmitButton
                            expanded
                            label="Submit Request"
                            submitting={newDealerLoadingState === true}
                            disabled={newDealerLoadingState === true}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default NewDealerForm;
