// import wysiwygCSS from "!!raw-loader!react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import wysiwygCSS from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function globalCss(theme) {
    return `
        @import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,500,700,900&amp;display=swap');
        //Fonts
        // ${theme.fonts}
        // @font-face { font-family: "EuclidCircularA-Light"; src: url("/fonts/EuclidCircularA-Light.eot?") format("eot"), url("/fonts/EuclidCircularA-Light.woff2") format("woff2"), url("/fonts/EuclidCircularA-Light.woff") format("woff"), url("/fonts/EuclidCircularA-Light.otf") format("opentype"); }
        // @font-face { font-family: "EuclidCircularA-Bold"; src: url("/fonts/EuclidCircularA-Bold.eot?") format("eot"), url("/fonts/EuclidCircularA-Bold.woff2") format("woff2"), url("/fonts/EuclidCircularA-Bold.woff") format("woff"), url("/fonts/EuclidCircularA-Bold.otf") format("opentype"); }
        // @font-face { font-family: "EuclidCircularA-Medium"; src: url("/fonts/EuclidCircularA-Medium.eot?") format("eot"), url("/fonts/EuclidCircularA-Medium.woff2") format("woff2"), url("/fonts/EuclidCircularA-Medium.woff") format("woff"), url("/fonts/EuclidCircularA-Medium.otf") format("opentype"); }
        // @font-face { font-family: "EuclidCircularA-Regular"; src: url("/fonts/EuclidCircularA-Regular.eot?") format("eot"), url("/fonts/EuclidCircularA-Regular.woff2") format("woff2"), url("/fonts/EuclidCircularA-Regular.woff") format("woff"), url("/fonts/EuclidCircularA-Regular.otf") format("opentype"); }
        // @font-face { font-family: "EuclidCircularA-SemiBold"; src: url("/fonts/EuclidCircularA-SemiBold.eot?") format("eot"), url("/fonts/EuclidCircularA-SemiBold.woff2") format("woff2"), url("/fonts/EuclidCircularA-SemiBold.woff") format("woff"), url("/fonts/EuclidCircularA-SemiBold.otf") format("opentype"); }

        html, body, #root{
            height: 100%;
        }
        
        body {

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;


            font-size: 14px;
            // background: ${theme.asset.preview.background};
            color: ${theme.body.color};
            font-family: ${theme.body.fontFamily};
            margin: 0;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            

        }
        *{
            -webkit-font-smoothing: inherit;
            box-sizing: inherit;
        }
        h1, h2, h3, h4, h5, h6{
            margin: 0 0 ${theme.typography.margin}rem;
        }
        h1 {
            font-size: 2em;
        }
        p{
            margin: 0 0 ${theme.typography.margin}rem;
        }
        a{
            color: ${theme.anchor.color};
            font-weight: bold;
        }
        fieldset{
            margin: 0;
            padding: 0;
            border: none;
        }
        
        
        .checklist ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        .checklist ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0,0,0,.5);
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        }

        .ReactCollapse--collapse {
            transition: height 500ms;
        }

        // Table style
        table.dashboard-table{
            width: 100%;
            border-spacing: 0;
            margin-bottom: ${theme.spacing.margin}em;
            caption{
                text-align: left;
                font-weight: bold;
                text-transform: uppercase;
                background: ${theme.colors.secondary};
                padding: 10px;
            }
            th{
                font-size: 12px
                text-transform: uppercase;
                font-weight: bold;
                background: ${theme.table.thBackground};
                text-align: left;
                padding: 10px;
                vertical-align: bottom;
            }
            td{
                text-align: left;
                padding: 10px;                
            }
            tr:not(:last-child) td{
                border-bottom: 1px solid ${theme.table.tdLineColor};
            }
            th, td {
                &:first-child{
                    padding-left: ${theme.spacing.padding}em;
                }
                &:first-child{
                    padding-left: ${theme.spacing.padding}em;
                }
            }
        }

        table.dashboard-table--black{
            border-radius: ${theme.spacing.radius}px;
            background ${theme.colors.black};
        }

        table.vertical-table{
            width: 100%;
            border-spacing: 0;
            margin-bottom: ${theme.spacing.margin}em;
            th{
                text-transform: uppercase;
                color: ${theme.colors.gray200};
            }
            th, td{
                padding: 10px;
                text-align: left;
                vertical-align: top;
            }
            tr:not(:last-child){
                th, td{
            
                    border-bottom: 1px solid ${theme.colors.secondary};
                }
            }
        }

        // React grid style
        .react-grid-item.react-grid-placeholder{
            background: #000000;
            filter: blur(5px);
        }

        // Progress Bar style
        #nprogress{
            .bar{
                background: ${theme.colors.primary};
            }
            .peg{
                box-shadow: 0 0 10px ${theme.colors.primary}, 0 0 5px ${theme.colors.primary};
            }
            .spinner-icon{
                border-top-color: ${theme.colors.primary};
                border-left-color: ${theme.colors.primary};
            }
        }

        // Input range style
        .input-range__slider{
            background: ${theme.colors.primary};
            border: 1px solid ${theme.colors.primary};
        }

        .input-range__track--active{
            background: ${theme.colors.primary};
        }

        // Custom select style
        .Select-control{
            background: ${theme.select.background} !important;
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            border: none;
        }
        .Select-arrow{
            border-top-color: ${theme.select.color};
           
        }
        .Select-arrow-zone:hover > .Select-arrow{
            border-top-color: ${theme.select.color} !important;
            opacity: 0.5;
        }
        .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, 
        .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label{
            color: ${theme.select.color};
        }
        .Select-menu-outer, .Select-option{
            background: ${theme.select.background};
            color: ${theme.select.color};
            border-color: ${theme.select.hover};
        }
        .Select-option.is-selected{
            background: ${theme.select.highlighted}
        }
        .Select-option:hover,
        .Select-option.is-focused{
            background: ${theme.select.hover};
            color: ${theme.select.color};
        }
        .Select-input input{
            color: ${theme.select.color};
        }

        // Injectable Tooltip style
        .injectableTooltip__body{
            background: ${theme.colors.black};
            color: ${theme.colors.white};
        }
        .injectableTooltip__arrow:after{
            background: ${theme.colors.black};
        }

        .pageTitle div{
            padding: 0px,
        }

        .pageTitle h1{
            font-size: 12pt,
        }

        //Include the DraftJS CSS (fixed issues when rendering in an iframe)
        ${wysiwygCSS}

    `;
}

export default globalCss;
