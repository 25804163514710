import React, { useContext } from "react";
import styled from "styled-components";

// @ts-ignore
import { trans } from "@cortexglobal/rla-intl";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
import { DraftJSOutput } from "@cortexglobal/rla-components";

import { Layout } from "../interfaces/layout";
import { SlideContainer, Subtitle } from "../components/slideComponents";
import MicrositeContext from "../MicrositeContext";
import {
    Footnote,
    FootnoteWrapper,
    generateFootnotes,
    StyledA,
} from "../components/Footnotes";

const StyledSlideContainer = styled(SlideContainer)`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ printing }) =>
        printing &&
        `
        overflow: unset;
    `}
`;

const References = ({ settings, transitions, disableSequencing }: Layout) => {
    let {
        navigationState: { currentTransitionIndex },
        editing,
        slideSize,
        globalSettings,
        globalTheming,
        config: { pages },
    } = useContext(MicrositeContext);

    const printing = useQueryString().get("printing");

    const references = pages
        .reduce((references: any, currentValue: any) => {
            const newFootnotes = generateFootnotes(currentValue[1].content);

            return [...newFootnotes, ...references];
        }, [])
        .reduce((references: any, currentValue: any) => {
            if (
                references.some(
                    (reference: any) => reference.number === currentValue.number
                )
            )
                return references;
            return [currentValue, ...references];
        }, [])
        .sort((a: any, b: any) => {
            return a.number - b.number;
        });
    const highlightColor = globalTheming?.highlightColor;

    return (
        <StyledSlideContainer
            backgroundImage={globalSettings?.backgroundImage}
            settings={settings}
            slideWidth={slideSize?.width}
            slideHeight={slideSize?.height}
            fontSize={slideSize?.fontSize}
            printing={printing}
        >
            <FootnoteWrapper
                style={
                    printing
                        ? { height: "auto", maxHeight: "unset" }
                        : { overflowY: "auto" }
                }
            >
                <Subtitle highlightColor={highlightColor}>
                    {trans("References")}
                </Subtitle>

                {references.map((reference: any) => {
                    // let footnotePrefix = index > 0 ? "," : "";
                    return (
                        <Footnote key={`${reference.link}-${reference.text}`}>
                            <StyledA href={reference.link} target="_blank">
                                {/* {footnotePrefix} */}
                                <span style={{ marginRight: "1rem" }}>
                                    {reference.number}
                                </span>
                                -
                                <DraftJSOutput
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    {reference.text}
                                </DraftJSOutput>
                            </StyledA>
                        </Footnote>
                    );
                })}
            </FootnoteWrapper>
        </StyledSlideContainer>
    );
};

export default References;
