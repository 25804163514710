import React from "react";

import { Modal, Button } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
export const ConfirmationDialog = ({
    open,
    title,
    variant,
    description,
    onSubmit,
    onClose,
}) => {
    return (
        <Modal visible={open} onClose={onClose}>
            <h3 id="alert-dialog-title">{title}</h3>
            <div>{description}</div>
            <div>
                {variant === "danger" && (
                    <React.Fragment>
                        <Button
                            style={{ margin: "1rem 1rem 0 0" }}
                            type="alert"
                            onClick={onSubmit}
                        >
                           {trans("Yes, I agree")}
                        </Button>
                        <Button
                            style={{ margin: "1rem 0 0" }}
                            type="alert"
                            onClick={onClose}
                            autoFocus
                        >
                             {trans("Cancel")}
                        </Button>
                    </React.Fragment>
                )}

                {variant === "info" && (
                    <Button color="alert" onClick={onSubmit}>
                        {trans("OK")}
                    </Button>
                )}
            </div>
        </Modal>
    );
};
