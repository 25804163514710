import React, { useContext } from "react";
import { Table } from "@cortexglobal/rla-components";
import AssetContext from "../../AssetContext";
const flattenOptions = (
    options,
    flattenedOptions = [],
    key = "children",
    parentText = ""
) => {
    options.forEach((option) => {
        //Add the parent text to the front of the option text
        if (parentText === "") {
            // console.log("Adding parent", `${parentText} > ${option.originalText}`);
            option.parentText = `${option.originalText}`;
        } else {
            option.parentText = `${parentText} > ${option.originalText}`;
        }
        flattenedOptions.push(option);
        // console.log("option", option);
        if (option[key] && Array.isArray(option[key])) {
            // console.log(
            //     "children",
            //     option[key],
            //     flattenedOptions,
            //     key,
            //     option.originalText
            // );

            flattenOptions(
                option[key],
                flattenedOptions,
                key,
                option.parentText
            );
        }
    });
    return flattenedOptions;
};
const AssetDetailsClassification = ({ asset }) => {
    const { filters } = useContext(AssetContext);
    return (
        <>
            {asset["category-hierarchy"] &&
                asset["category-hierarchy"].map((slug) => {
                    const categoryHierarchy = filters.data.find(
                        (filterBlock) => {
                            return filterBlock.name === "category-hierarchy";
                        }
                    );

                    let category = null;
                    if (categoryHierarchy) {
                        const options = flattenOptions([
                            ...categoryHierarchy.options,
                        ]);
                        category = options.find((option) => {
                            return option.value === slug;
                        });
                    }

                    if (!category?.label) return null;

                    return (
                        <tr key={slug}>
                            <th style={{ width: "33%" }}>{category?.label}</th>
                            <td>{category?.text}</td>
                        </tr>
                    );
                })}
            {Object.entries(asset.categories_by_parent_readable).map(
                ([category, value]) => {
                    return (
                        <tr key={category}>
                            <th style={{ width: "33%" }}>{category}</th>
                            <td>{value}</td>
                        </tr>
                    );
                }
            )}
        </>
    );
};

export default AssetDetailsClassification;
