import React, { Component } from "react";
import { trans } from "@cortexglobal/rla-intl";

import {
    Row,
    InputField,
    Column,
    Button,
    IconTextButton
} from "@cortexglobal/rla-components";
import {
    faSearch,
    faImages,
    faSitemap
} from "@fortawesome/free-solid-svg-icons";

class MediaSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchForm: { search: "" }
        };
    }

    onChange = input => {
        let updatedSearchForm = {
            ...this.state.searchForm,
            [input.name]: input.value
        };
        this.setState({ searchForm: updatedSearchForm });
    };

    onKeyPress = e => {
        if (e.key === "Enter") {
            this.search();
        }
    };

    search = () => {
        //this.props.clearSearch();
        this.props.search(this.state.searchForm);
    };

    showFolders = () => {
        this.props.showFolders();
    };

    fetchAll = () => {
        this.props.search({ fetchAll: true });
    };

    render() {
        return (
            <Row>
                <Column medium="2">
                    <h3>Search / Find :</h3>
                </Column>
                <Column medium={6}>
                    <InputField
                        type="text"
                        name="search"
                        inlineLabel={false}
                        placeholder={this.props.placeholder}
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        value={this.state.searchForm.search}
                        expanded
                    />
                </Column>

                <Column medium={1}>
                    <IconTextButton
                        color="primary"
                        onClick={this.search}
                        showCircle={false}
                        expanded={false}
                        icon={faSearch}
                    >
                        {trans("Search")}
                    </IconTextButton>
                </Column>

                {this.props.fetchAll && (
                    <Column medium={1}>
                        <IconTextButton
                            color="primary"
                            onClick={this.fetchAll}
                            showCircle={false}
                            expanded={false}
                            icon={faImages}
                        >
                            {trans("Fetch All")}
                        </IconTextButton>
                    </Column>
                )}

                <Column medium={1}>
                    <IconTextButton
                        type="secondary"
                        onClick={this.showFolders}
                        showCircle={false}
                        expanded={false}
                        icon={faSitemap}
                    >
                        {trans("Browse Folders")}
                    </IconTextButton>
                </Column>
            </Row>
        );
    }
}

export default MediaSearch;
