import React from "react";
import { get } from "lodash";
import {
    InputField,
    FormRow,
    TextareaField,
    CurrencyInput,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import { FormWrapper, YesNoSelector } from "./formLayoutComponents";

const VenueForm = ({ loadingState, errors, params, onChange }) => {
    return (
        <FormWrapper>
            <FormRow
                helpText={trans("Required")}
                label={trans("Venue name")}
                name={"venue_name"}
                error={get(errors, "venue_name")}
                required
            >
                <InputField
                    name="venue_name"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "venue_name", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Venue reference")}
                name={"venue_reference"}
                error={get(errors, "venue_reference")}
            >
                <InputField
                    name="venue_reference"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "venue_reference", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Venue contact name")}
                name={"venue_primary"}
                error={get(errors, "venue_primary")}
            >
                <InputField
                    name="venue_primary"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "venue_primary", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Venue phone number")}
                name={"venue_phone"}
                error={get(errors, "venue_phone")}
            >
                <InputField
                    name="venue_phone"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "venue_phone", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Venue email address")}
                name={"venue_email"}
                error={get(errors, "venue_email")}
            >
                <InputField
                    name="venue_email"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "venue_email", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Venue cost")}
                name={"venue_cost"}
                error={get(errors, "venue_cost")}
            >
                <CurrencyInput
                    name="venue_cost"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "venue_cost", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Is the event indoors?")}
                name={"indoor"}
                error={get(errors, "indoor")}
            >
                <YesNoSelector
                    name="indoor"
                    value={get(params, "indoor")}
                    onChange={onChange}
                    loading={loadingState === "loading"}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Venue Building")}
                name={"address_building"}
                error={get(errors, "address_building")}
            >
                <InputField
                    name="address_building"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "address_building", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Address Street")}
                name={"address_street"}
                error={get(errors, "address_street")}
            >
                <InputField
                    name="address_street"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "address_street", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Address Neighbourhood")}
                name={"address_neighbourhood"}
                error={get(errors, "address_neighbourhood")}
            >
                <InputField
                    name="address_neighbourhood"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "address_neighbourhood", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Address Suburb")}
                name={"address_suburb"}
                error={get(errors, "address_suburb")}
            >
                <InputField
                    name="address_suburb"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "address_suburb", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Required")}
                label={trans("Address City")}
                name={"address_city"}
                error={get(errors, "address_city")}
                required
            >
                <InputField
                    name="address_city"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "address_city", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Address State")}
                name={"address_state"}
                error={get(errors, "address_state")}
            >
                <InputField
                    name="address_state"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "address_state", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Required")}
                label={trans("Venue Postcode")}
                name={"postcode"}
                error={get(errors, "postcode")}
                required
            >
                <InputField
                    name="postcode"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "postcode", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Venue Country")}
                name={"country"}
                error={get(errors, "country")}
            >
                <InputField
                    name="country"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "country", "")}
                />
            </FormRow>

            <FormRow
                helpText={trans("Optional")}
                label={trans("Additional venue notes")}
                name={"venue_notes"}
                error={get(errors, "venue_notes")}
            >
                <TextareaField
                    name="venue_notes"
                    onChange={(e) => onChange(e.name, e.value)}
                    error={get(errors, "venue_notes")}
                    disabled={loadingState === "loading"}
                    value={get(params, "venue_notes", "")}
                />
            </FormRow>
        </FormWrapper>
    );
};

export default VenueForm;
