import React from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
///@ts-ignore cortex
import { useQueryString } from "@cortexglobal/cortex-utilities";
//@ts-ignore cortex
///@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import {
    IconTextButton,
    PageTitle,
    Row,
    Panel,
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
import CategoryForm, { Category } from "../../forms/CategoryForm";

export const CategoryCreate = ({ path, categoryArea }) => {
    const history = useHistory();
    //parent_id will be set if it's in the URL otherwise it's null which is what we want, if it is set well parse it to an integer
    let parent_id = useQueryString().get("parent_id")
        ? parseInt(useQueryString().get("parent_id"))
        : null;

    const area = categoryArea.toLowerCase();

    const item: Category = {
        category_id: null,
        parent_id: parent_id,
        label_category_id: null,
        name: "",
        slug: "",
        type: "",
        description: null,
        thumbnail: "",
        order: 0,
        locale: null,
        color: null,
    };

    const submitForm = (values) => {
        return axios
            .post(`/api/v1/admin/${area}-category`, values)
            .then(({ data }) => {
                console.log(data);

                if (parent_id !== null) {
                    history.goBack();
                }
                //As creation was successful move them on to the appropriate listing page
                history.push(`${path}`);

                return data;
            });
    };
    return (
        <div>
            <PageTitle
                title={
                    <React.Fragment>
                        {trans("New {categoryArea} Category", { categoryArea })}{" "}
                        {/* <HeaderEmphasis>{assetAdmin.asset.name}</HeaderEmphasis> */}
                    </React.Fragment>
                }
            >
                <IconTextButton
                    as={Link}
                    to={path}
                    rotation={180}
                    showCircle={false}
                    expanded={false}
                >
                    {trans("Back to Listing")}
                </IconTextButton>
            </PageTitle>

            <Row>
                <Panel>
                    <CategoryForm
                        item={item}
                        submitForm={submitForm}
                        area={area}
                    />
                </Panel>
            </Row>
        </div>
    );
};

export default CategoryCreate;
