import React, { MouseEvent } from "react";
import { Formik, Form, FormikActions } from "formik";
import {
    Row,
    Column,
    Button,
    SubmitButton,
    IconTextButton,
    FormikInput,
    FormikTextarea
    //@ts-ignore cortex
} from "@cortexglobal/rla-components";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";
import { CommentSchema } from "./CommentSchema";

interface CommentFormProps {
    smartRef: string;
    addComment: Function;
    setShowForm: Function;
    alwaysShowForm: boolean;
    showTitle: boolean;
}

interface Values {
    title: string;
    comment: string;
}

export const CommentForm = ({
    smartRef,
    addComment,
    setShowForm,
    alwaysShowForm,
    showTitle
}: CommentFormProps) => {
    const submitForm = (values: {}, { resetForm }: FormikActions<Values>) => {
        addComment(values, smartRef).then(() => {
            resetForm();
        });
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    title: "",
                    comment: ""
                }}
                onSubmit={submitForm}
                validationSchema={CommentSchema}
            >
                {({ isSubmitting, values, ...rest }) => {
                    return (
                        <Form className="form-group" id="cortex-comment-form">
                            {showTitle && (
                                <Column collapse>
                                    <FormikInput
                                        type="text"
                                        name="title"
                                        label={trans("Comment Title")}
                                        value={values.title}
                                    />
                                </Column>
                            )}
                            <Column collapse>
                                <FormikTextarea
                                    name="comment"
                                    label={trans("Comment")}
                                    style={{ marginBottom: ".5rem" }}
                                    value={values.comment}
                                />
                            </Column>
                            <Column medium={6}>
                                {!alwaysShowForm && (

                            <IconTextButton
                                onClick={(event: MouseEvent) => {
                                    setShowForm(false);
                                    event.preventDefault;
                                }}
                            > {trans("Cancel")}
                            </IconTextButton>
                                )}
                                &nbsp;
                            </Column>
                            <Column
                                medium={6}
                                style={{
                                    paddingBottom: "1rem",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    textAlign:"right"
                                }}
                            >
                                <SubmitButton
                                    label={trans("Add Comment")}
                                    submitting={isSubmitting}
                                    type="primary"
                                />
                            </Column>
                            
                        </Form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};
