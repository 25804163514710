import { useSelector } from "react-redux";
import { get } from "lodash";

const usePermissions = () => {
    const permissions = useSelector(state => {
        return get(state, "auth.role.permissions", []);
    });

    const can = permission => {
        return !!permissions.find(p => p.alias == permission);
    };

    return { can, permissions };
};

export default usePermissions;
