import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { get } from "lodash";

import { Row, Column, IconTextButton } from "@cortexglobal/rla-components";
import { FormattedCurrency, trans } from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";
import EquipmentAvailability from "./EquipmentAvailability";

const InventoryItemDetails = ({
    item,
    date = null,
    showCreateEvent = false,
    eventsPath,
    settings,
}) => {
    const { user } = useUser();

    return (
        <Row>
            <h2>{item.name}</h2>
            <p>{item.description}</p>
            {/* <p>
                <FormattedCurrency
                    value={item?.cost?.value || 0}
                    currency={user.currency?.iso_code || "GBP"}
                />
            </p> */}
            <EquipmentAvailability
                item={item}
                date={date}
                settings={settings}
            />
            {showCreateEvent && eventsPath && (
                <Column
                    style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <IconTextButton
                        icon="plus"
                        as={Link}
                        to={`${eventsPath}/planner/`}
                        showCircle={false}
                    >
                        {trans("Create an Event")}
                    </IconTextButton>
                </Column>
            )}
        </Row>
    );
};

export default InventoryItemDetails;
