import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled, { withTheme } from "styled-components";
import { Row, Column, Logo } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

const HeaderLogo = styled(Logo)`
    width: 80%;
    margin: 0 auto;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    display: block;
`;

const Tagline = styled.h2`
    text-align: center;
    margin: 1rem auto;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1rem;
`;

class PublicHeader extends Component {
    render() {
        const { theme } = this.props;
        return (
            <>
                <HeaderLogo height={theme.logo.height} src={theme.logo.src} />
                <Tagline>{trans("Dealer Marketing Platform")}</Tagline>
            </>
        );
    }
}

const mapStateToProps = (state) => ({ loggedIn: state.auth.loggedIn });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withTheme(
    connect(mapStateToProps, mapDispatchToProps)(PublicHeader)
);
