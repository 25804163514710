import React from "react";
import { trans } from "@cortexglobal/rla-intl";
import {
    Row,
    Column,
    Tabordion,
    PageTitle,
    Panel,
} from "@cortexglobal/rla-components";
import EditProfile from "./EditProfile";
import ResetPasswordForm from "./ResetPasswordForm";
const Profile = () => {
    return (
        <>
            <PageTitle title={trans("My Profile")} />
            <Row>
                <Panel>
                    <EditProfile heading={trans("General")} />
                </Panel>
            </Row>
        </>
    );
    // return (
    //     <Tabordion current={0} unmounts={false}>
    //         <EditProfile heading={trans("General")} />
    //         <Row
    //             heading={trans("Reset Password")}
    //             style={{ paddingBottom: "2.2rem" }}
    //         >
    //             <Column>
    //                 <ResetPasswordForm />
    //             </Column>
    //         </Row>
    //     </Tabordion>
    // );
};

export default Profile;
