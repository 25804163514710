import React, { useState, useEffect } from "react";
import { get } from "lodash";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    TableList,
    Button,
    SubmitButton,
    CurrencyInput,
    useAddToast,
    TextareaField,
    FormRow,
    IconTextButton,
} from "@cortexglobal/rla-components";
import { trans, FormattedCurrency } from "@cortexglobal/rla-intl";
import { useUser } from "@cortexglobal/cortex-auth-redux";

const FirstRow = styled.tr`
    td {
        border-bottom: none !important;
    }
`;
const EventCompletionForm = ({ event, cancel, callback = () => {} }) => {
    const [params, setParams] = useState({});
    const [errors, setErrors] = useState({});
    const [loadingState, setLoadingState] = useState("loaded");
    const { user } = useUser();
    const addToast = useAddToast();

    const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

    useEffect(() => {
        const params = {
            actual_budget: isNumeric(event.actual_budget)
                ? event.actual_budget
                : event.total_budget,
            actual_venue_cost: isNumeric(event.actual_venue_cost)
                ? event.actual_venue_cost
                : event.venue_cost,
            budgetary_comments: event.budgetary_comments,
        };

        event.bookingItems.forEach((i) => {
            if (isNumeric(i.actual_cost)) {
                // not not a number = numeric
                params["actual_item_cost|" + i.booking_uuid] = i.actual_cost;
            } else {
                params["actual_item_cost|" + i.booking_uuid] = i.predicted_cost;
            }
            params["return_condition|" + i.booking_uuid] = i.return_condition;
        });

        setParams(params);
    }, []);

    const onChange = (name, value) => {
        setParams({ ...params, [name]: value });
    };

    const onSubmit = () => {
        const { uuid } = event;
        setLoadingState("loading");

        axios
            .post(`/api/v1/events/${uuid}/actual-spend`, params)
            .then(({ data }) => {
                setLoadingState("loaded");

                callback(data.data);

                addToast({
                    type: "success",
                    content: trans("Saved"),
                    showFor: 5000,
                });

                setErrors([]);
                cancel();
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setErrors(data.errors);
                    }

                    addToast({
                        type: "alert",
                        content:
                            e.response && e.response.data
                                ? e.response.data.message
                                : trans("An error has occurred"),
                        showFor: 5000,
                    });
                }

                setLoadingState("loaded");
            });

        return;
    };

    return (
        <>
            <h3>{trans("Totals")}</h3>
            <TableList>
                <thead>
                    <tr>
                        <th></th>
                        <th width="200px">{trans("Budgeted Cost")}</th>
                        <th width="250px">{trans("Final Actual Spend")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{trans("Total Budget")}</td>
                        <td>
                            <FormattedCurrency
                                value={get(event, "total_budget", "")}
                                currency={user?.currency?.iso_code || "GBP"}
                            />
                        </td>
                        <td>
                            <CurrencyInput
                                name="actual_budget"
                                onChange={(e) => onChange(e.name, e.value)}
                                error={get(errors, "actual_budget")}
                                disabled={loadingState === "loading"}
                                value={get(params, "actual_budget")}
                                placeholder={"£"}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>{trans("Venue Cost")}</td>
                        <td>
                            <FormattedCurrency
                                value={get(event, "venue_cost", "")}
                                currency={user?.currency?.iso_code || "GBP"}
                            />
                        </td>
                        <td>
                            <CurrencyInput
                                name="actual_venue_cost"
                                onChange={(e) => onChange(e.name, e.value)}
                                error={get(errors, "actual_venue_cost")}
                                disabled={loadingState === "loading"}
                                value={get(params, "actual_venue_cost")}
                                placeholder={"£"}
                            />
                        </td>
                    </tr>
                </tbody>
            </TableList>
            <h3>{trans("Inventory Costs")}</h3>
            <TableList>
                <thead>
                    <tr>
                        <th>{trans("Item")}</th>
                        <th>{trans("Quantity/Days")}</th>
                        <th width="200px">{trans("Budgeted Cost")}</th>
                        <th width="250px">{trans("Final Actual Spend")}</th>
                    </tr>
                </thead>
                <tbody>
                    {event.bookingItems.length > 0 && (
                        <>
                            {event.bookingItems.map((item) => {
                                return (
                                    <React.Fragment key={item.booking_uuid}>
                                        <FirstRow key={item.booking_uuid}>
                                            <td>{item.name}</td>
                                            {/* <td>{item.display_name}</td> */}
                                            <td>{item.quantity}</td>

                                            <td>
                                                {"£" +
                                                    get(
                                                        item,
                                                        "predicted_cost",
                                                        0
                                                    )}
                                            </td>
                                            <td>
                                                <CurrencyInput
                                                    name={`actual_item_cost|${item.booking_uuid}`}
                                                    onChange={(e) =>
                                                        onChange(
                                                            e.name,
                                                            e.value
                                                        )
                                                    }
                                                    error={get(
                                                        errors,
                                                        `actual_item_cost|${item.booking_uuid}`
                                                    )}
                                                    disabled={
                                                        loadingState ===
                                                        "loading"
                                                    }
                                                    value={get(
                                                        params,
                                                        `actual_item_cost|${item.booking_uuid}`
                                                    )}
                                                />
                                            </td>
                                        </FirstRow>
                                        <tr
                                            style={{ border: "none" }}
                                            key={item.booking_uuid}
                                        >
                                            <td colSpan={4}>
                                                <TextareaField
                                                    label={trans(
                                                        "Return Condition"
                                                    )}
                                                    name={`return_condition|${item.booking_uuid}`}
                                                    onChange={(e) =>
                                                        onChange(
                                                            e.name,
                                                            e.value
                                                        )
                                                    }
                                                    error={get(
                                                        errors,
                                                        `return_condition|${item.booking_uuid}`
                                                    )}
                                                    disabled={
                                                        loadingState ===
                                                        "loading"
                                                    }
                                                    value={get(
                                                        params,
                                                        `return_condition|${item.booking_uuid}`
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                        </>
                    )}
                </tbody>
            </TableList>

            {/* <FormRow
                helpText={trans(
                    "Optional. Add some comments if it the event has gone over/under budget."
                )}
                label={trans("Comments")}
                name={"budgetary_comments"}
                error={errors.budgetary_comments}
                showErrors={false}
            >
                <TextareaField
                    name="budgetary_comments"
                    onChange={(e) => onChange(e.name, e.value)}
                    disabled={loadingState === "loading"}
                    value={get(params, "budgetary_comments", "")}
                />
            </FormRow> */}

            <Row collapse>
                <Column style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconTextButton
                        type="alert"
                        onClick={(event) => {
                            event.preventDefault();
                            cancel();
                        }}
                        showCircle={false}
                        icon="times"
                        disabled={loadingState === "loading"}
                    >
                        {trans("Cancel")}
                    </IconTextButton>

                    <SubmitButton
                        label="Save"
                        onClick={() => onSubmit()}
                        submitting={loadingState === "loading"}
                    />
                </Column>
            </Row>
        </>
    );
};

export default EventCompletionForm;
