import React, { Component } from "react";
import { withTheme } from "styled-components";

import {
    GreedyCarousel,
    Centred,
    CircularProgress,
} from "@cortexglobal/rla-components";
import withPanelData from "../withPanelData";
import DetailSummary from "../components/DetailSummary";

class PerformanceSummaryOutput extends Component {
    renderDetails = (data) => {
        switch (data.type) {
            case "summary":
                return (
                    <DetailSummary
                        title={data.title}
                        description={data.details.description}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        const { height, layout, theme } = this.props;
        return (
            <GreedyCarousel layout={layout}>
                <Centred
                    style={{
                        width: "100%",
                        maxWidth: height,
                        padding: 20,
                    }}
                >
                    <CircularProgress
                        backgroundPadding={5}
                        strokeWidth={14}
                        value={this.props.data.value}
                        initialAnimation={true}
                        strokeColor={this.props.theme.colors.primary}
                        trailColor={this.props.theme.colors.background}
                    />
                </Centred>

                <Centred style={{ width: "100%" }}>
                    {this.renderDetails(this.props.data)}
                </Centred>
            </GreedyCarousel>
        );
    }
}

export default withTheme(
    withPanelData(PerformanceSummaryOutput, "/api/v1/performance/summary", [
        "title",
        "metric",
    ])
);
