import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { SubmitButton, FormikInput, Panel } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { forgotten } from "@cortexglobal/cortex-auth-redux";

const ForgottenSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
});

class ForgottenPassword extends Component {
    state = {
        submitted: false,
        message: "",
    };
    submitForm = (values, { setErrors }) => {
        this.props.forgotten(values).then((data) => {
            console.log(data);
            this.setState({
                submitted: true,
                message: data.data.response_trans,
            });
        });
        return true;
    };

    render() {
        const { submitted, message } = this.state;
        return (
            <div>
                {submitted && (
                    <p
                        style={{
                            textAlign: "center",
                            paddingBottom: "1rem",
                        }}
                    >
                        {message}
                    </p>
                )}
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={this.submitForm}
                    validationSchema={ForgottenSchema}
                >
                    {({ submitting }) => (
                        <Form className="form-group">
                            <FormikInput
                                type="text"
                                name="email"
                                label={trans("Email")}
                                placeholder="joe@bloggs.com"
                            />

                            <Link
                                style={{
                                    display: "block",
                                    margin: "0rem 0 1.2rem 0",
                                    textAlign: "right",
                                }}
                                to="/auth/login"
                            >
                                {trans("Cancel")}
                            </Link>

                            <SubmitButton
                                expanded
                                centered
                                label={trans("Send me a password reset link")}
                                submitting={submitting}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            forgotten,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);
