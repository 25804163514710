import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme, css } from "styled-components";
import { shade } from "@cortexglobal/cortex-utilities";
import Row from "./row";
import Column from "./column";
import { Link } from "react-router-dom";

const Container = styled.header`
    background: ${props => props.theme.header.background};
    color: ${props => props.theme.header.color};
`;

const HeaderFlex = styled.div`
    display: flex;
    min-height: 80px;
    align-items: center;
    justify-content: flex-start;
    div:last-of-type {
        margin-left: auto;
    }
`;

export const HeaderAlign = styled.div`
    color: initial;
    flex-basis: ${props => props.flexBasis};
    ${props =>
        props.align &&
        function() {
            const side = props.align === "left" ? "right" : "left";
            return css`margin-${side}: 0;`;
        }}
`;

HeaderAlign.propTypes = {
    flexBasis: PropTypes.string,
    align: PropTypes.oneOf(["left", "right", undefined])
};

HeaderAlign.defaultProps = {
    flexBasis: "200px",
    align: undefined
};

export const LogoImg = styled.img`
    height: ${props => props.height || 20}px;
    margin-right: 2%;
    flex-shrink: 1;
`;

export const Logo = ({ ...props }) => (
    <Link to="/">
        <LogoImg {...props} />
    </Link>
);

export const UserInfo = styled.div`
    flex-basis: 200px;
    align-self: stretch;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding: ${props => props.theme.spacing.padding}em;
    background: ${props => shade(props.theme.header.background, -10)};
`;

export const UserInfoTitle = styled.p`
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 0.2em;
`;

export const UserInfoText = styled.p`
    margin-bottom: 0;
    font-size: 0.8em;
    opacity: 0.5;
`;

const Icon = styled.a`
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: inherit;
    font-size: 1em;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
`;

const Notification = styled.div`
    border-radius: 14px;
    position: absolute;
    font-weight: bold;
    text-align: center;
    top: -0.5em;
    right: -0.5em;
    min-width: 1.2em;
    font-size: 0.7em;
    background: ${props => shade(props.theme.colors.alert, -10)};
`;

export const UserInfoIcons = styled.div``;

export const UserInfoIcon = ({ children, notifications, ...rest }) => (
    <Icon {...rest}>
        {children}
        {notifications && <Notification>{notifications}</Notification>}
    </Icon>
);

const Header = ({ children, ...props }) => {
    return (
        <Container>
            <Row expanded={props.theme.body.expanded ? true : false}>
                <Column>
                    <HeaderFlex>{children}</HeaderFlex>
                </Column>
            </Row>
        </Container>
    );
};

Header.displayName = "Header";

Header.propTypes = {};

Header.defaultProps = {};

export default withTheme(Header);
