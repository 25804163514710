import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { createPopper } from "@popperjs/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
//@ts-ignore cortex
import { trans } from "@cortexglobal/rla-intl";

//import { Link } from "react-router-dom";
//@ts-ignore cortex
import { PlainButton, Error } from "@cortexglobal/rla-components";

const StyledTranslationButton = styled(PlainButton)`
    &:focus {
        outline: none;
    }
`;
const OriginalText = styled.span`
    display: inline-block;
    margin-right: 0.5rem;
`;

const TranslationWrapper = styled.div`
    background-color: ${({ theme }) => theme.body.background};
    padding: 1rem;
    margin: 0 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    max-width: 80vw;
`;
const TranslationButton = ({ text, from = "", to = "" }: TranslationButton) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [translation, setTranslation] = useState("");

    const [popper, setPopper] = useState<any>();
    const triggerRef = React.createRef<any>();
    const popupRef = React.createRef<any>();
    const wrapperRef = React.createRef<any>();

    const [isTranslationShowing, setIsTranslationShowing] = useState(false);
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setIsTranslationShowing(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        setPopper(createPopper(
            triggerRef.current,
            popupRef.current,
            {
                placement: 'bottom-start',
                strategy: 'fixed',
            }
        ));
    }, []);

    const translateText = (text, from = "", to = "") => {
        setIsTranslationShowing(true);
        setLoading(true);

        return axios
            .post("/api/v1/translate", {
                text,
                from,
                to
            })
            .then(({ data }) => {
                // console.log(data);
                setLoading(false);
                setTranslation(data.data.translation);
            })
            .catch(({ response }) => {
                setLoading(false);
                setError(response.data.message);
            });
    };
    // console.log(translation, error);
    return (
        <React.Fragment>
            <StyledTranslationButton
                onClick={() => translateText(text, from, to)}
                ref={triggerRef}
            >
                <OriginalText>{text}</OriginalText>{" "}
                <FontAwesomeIcon icon={faLanguage} transform="grow-12" />
                {/* {text} <Badge>T</Badge> */}
            </StyledTranslationButton>
            {isTranslationShowing && (
                <div
                    style={{ position: 'absolute' }}
                    ref={popupRef}
                >
                    <TranslationWrapper ref={wrapperRef}>
                        {loading ? (
                            "..."
                        ) : error ? (
                            <Error>{error}</Error>
                        ) : (
                            translation
                        )}
                    </TranslationWrapper>
                </div>
            )}
        </React.Fragment>
    );
};

interface TranslationButton {
    text: string;
    from: string;
    to: string;
}

export default TranslationButton;
