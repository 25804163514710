import { useSelector } from "react-redux";
import { get } from "lodash";

const useRole = () => {
    let role = useSelector((state) => {
        return get(state, "auth.role", {});
    });

    return { role };
};

export default useRole;
