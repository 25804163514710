import React, { useState, useReducer, useContext } from "react";
import { v4 as uuid4 } from "uuid";
import { Collapse } from "react-collapse";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSubscript,
    faMinus,
    faEdit,
    faTrash,
    faUpload,
} from "@fortawesome/free-solid-svg-icons";

import {
    Row,
    Column,
    Modal,
    PlainButton,
    InputField,
    Button,
    RadioField,
    FormLabel,
    IconTextButton,
    SimpleDropzone,
    useAddToast,
    useToasts,
    Select,
    Toggle,
    DraftJSField,
    DraftJSOutput,
    PlainTextOutput,
} from "@cortexglobal/rla-components";
import { trans, useIntl } from "@cortexglobal/rla-intl";

import RelatedAssetBrowser from "../../components/RelatedAssetBrowser";
import AssetContext from "../../AssetContext";

import axios from "axios";

const FootnoteButton = styled(PlainButton)`
    margin-top: 0;
    float: right;
`;
const FlexRow = styled(Row)`
    display: flex;
    // align-content: flex-end;
`;

const SearchColumn = styled(Column)`
    display: flex;
    align-items: center;
`;

function footnoteReducer(state, action) {
    switch (action.type) {
        case "addFootnote":
            // console.log(state.footnotes, action.footnote);
            const oldFootnotes =
                state.footnotes instanceof Array ? state.footnotes : [];
            return { footnotes: [...oldFootnotes, action.footnote] };
        case "updateFootnote":
            return {
                footnotes: state.footnotes.map((footnote) => {
                    if (footnote.uuid === action.uuid) {
                        return { ...footnote, [action.name]: action.value };
                    }
                    return footnote;
                }),
            };
        case "deleteFootnote":
            return {
                footnotes: state.footnotes.filter((footnote) => {
                    return footnote.uuid !== action.uuid;
                }),
            };
        default:
            throw new Error();
    }
}

const FootnoteTableData = styled.td`
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    padding: 1rem 1rem 1rem 0;

    &:first-of-type {
        padding-left: 1rem;
        border-left: 1px solid #eaeaea;
    }

    &:last-of-type {
        border-right: 1px solid #eaeaea;
    }
`;

const FootnoteTableHeader = styled.th`
    padding-bottom: 0.5rem;

    &:first-of-type {
        padding-left: 1rem;
    }
`;

const RelatedAssetBrowserContainer = styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    height: auto;
    z-index: 1;
    border-radius: 3px;
    padding: 3rem;
`;

const FootnoteInput = ({
    footnote,
    dispatch,
    active,
    isBeingEdited,
    setFootnoteBeingEdited,
    currentDispatch,
    isUniqueNumber,
    hasDuplicateNumbers,
    asset,
}) => {
    return (
        <tr>
            <FootnoteTableData style={{ width: "100px" }}>
                <Toggle
                    onChange={(event) => {
                        if (event.target.checked) {
                            currentDispatch({
                                type: "addFootnote",
                                footnote: {
                                    text: footnote.text,
                                    link: footnote.link,
                                    uuid: footnote.uuid,
                                    type: footnote.type,
                                    number: footnote.number,
                                },
                            });
                        } else {
                            currentDispatch({
                                type: "deleteFootnote",
                                uuid: footnote.uuid,
                            });
                        }
                    }}
                    checked={active}
                />
            </FootnoteTableData>
            <FootnoteTableData style={{ width: "100px" }}>
                {footnote.number || "-"}
            </FootnoteTableData>
            <FootnoteTableData style={{ maxWidth: "300px" }}>
                {footnote.text ? (
                    <DraftJSOutput>{footnote.text}</DraftJSOutput>
                ) : (
                    <>'-'</>
                )}
            </FootnoteTableData>
            <FootnoteTableData style={{ width: "125px" }}>
                {footnote.type}
            </FootnoteTableData>
            <FootnoteTableData>
                <div style={{ maxWidth: "300px", overflowWrap: "anywhere" }}>
                    {footnote.link || "-"}
                </div>
            </FootnoteTableData>
            <FootnoteTableData style={{ width: "100px" }}>
                <IconTextButton
                    style={{ padding: "0 1rem 0 0" }}
                    onClick={() => setFootnoteBeingEdited(footnote.uuid)}
                    icon={faEdit}
                    showCircle={false}
                    expanded={false}
                />
                <IconTextButton
                    style={{ padding: "0" }}
                    onClick={() => {
                        dispatch({
                            type: "deleteFootnote",
                            uuid: footnote.uuid,
                        });
                        if (active) {
                            currentDispatch({
                                type: "deleteFootnote",
                                uuid: footnote.uuid,
                            });
                        }
                    }}
                    icon={faTrash}
                    showCircle={false}
                    expanded={false}
                />
            </FootnoteTableData>
        </tr>
    );
};

export const ManageFootnotesModal = ({
    asset,
    path,
    tag,
    onChange,
    showModal,
    setShowModal,
    index,
}) => {
    const [footnoteBeingEdited, setFootnoteBeingEdited] = useState(0);

    //TODO - Look at removing this as I can't see an index being passed in anywhere in the code
    //so I think we can just use the path and tag directly
    const pathValue = index !== undefined ? `${path}.value[${index}]` : path;
    const tagValue = index !== undefined ? tag.value[index] : tag;

    const [state, dispatch] = useReducer(footnoteReducer, {
        footnotes: asset.configuration.footnotes
            ? asset.configuration.footnotes.values
            : [],
    });
    const [currentState, currentDispatch] = useReducer(footnoteReducer, {
        footnotes: tagValue.footnotes || [],
    });

    const intl = useIntl();

    const addToast = useAddToast();
    const [_, toastDispatch] = useToasts();

    const [showRelatedAssetBrowser, setShowRelatedAssetBrowser] =
        useState(false);

    const onFootnoteSave = () => {
        setFootnoteBeingEdited(undefined);
        onChange("values", "footnotes", {
            value: state.footnotes,
        });
        onChange("footnotes", pathValue, {
            name: "footnotes",
            value: currentState.footnotes,
        });
        setShowModal(false);
    };

    const handleFootnoteBeingEdited = (index) => {
        setFootnoteBeingEdited(
            footnoteBeingEdited === index ? undefined : index
        );
    };

    const isUniqueNumber = (num) => {
        return (
            state.footnotes.filter(
                (footnote) => parseInt(footnote.number) === parseInt(num)
            ).length <= 1
        );
    };

    const onDrop = (acceptedFiles) => {
        addToast({
            uuid: "uploadToast",
            type: "loader",
            content: trans("Asset is being uploaded"),
        });

        const fd = new FormData();
        fd.append("file", acceptedFiles[0]);
        fd.append("type", "pdf");
        fd.append("asset_input_name", name);
        axios
            .post(`/api/v1/asset-file/${asset.uuid}`, fd)
            .then(({ data }) => {
                toastDispatch({ type: "removeToast", uuid: "uploadToast" });

                dispatch({
                    type: "updateFootnote",
                    uuid: footnoteBeingEdited,
                    value: data,
                    name: `link`,
                });

                addToast({
                    type: "success",
                    content: trans("Reference uploaded"),
                    showFor: 5000,
                });
            })
            .catch((e) => {
                console.log(e.response);
                const message = e?.response?.data?.errors?.file
                    ? e.response.data.errors.file
                    : trans("An error occurred uploading your file");
                addToast({
                    type: "alert",
                    content: message,
                    showFor: 5000,
                });
            });
    };

    const currentFootnote = !!state.footnotes.length
        ? state.footnotes.filter(
              (footnote) => footnote.uuid === footnoteBeingEdited
          )[0]
        : {};

    return (
        <React.Fragment>
            <Modal
                maxWidth="1000px"
                visible={showModal}
                onClose={onFootnoteSave}
            >
                <Row style={{ maxWidth: "1000px" }}>
                    <Column>
                        <h3>{trans("Manage References")}</h3>
                        <PlainButton
                            onClick={() => {
                                const uuid = uuid4();
                                const nextHighestNumber =
                                    state.footnotes.length &&
                                    state.footnotes.some(
                                        (footnote) =>
                                            !!footnote.number ||
                                            footnote.number === 0
                                    )
                                        ? Math.max(
                                              ...state.footnotes
                                                  .map((footnote) =>
                                                      parseInt(footnote.number)
                                                  )
                                                  .filter(
                                                      (footnote) =>
                                                          !!footnote ||
                                                          footnote === 0
                                                  )
                                          ) + 1
                                        : 1;

                                dispatch({
                                    type: "addFootnote",
                                    footnote: {
                                        text: "",
                                        link: "",
                                        uuid: uuid,
                                        type: "Text",
                                        number: nextHighestNumber,
                                    },
                                });
                                currentDispatch({
                                    type: "addFootnote",
                                    footnote: {
                                        text: "",
                                        link: "",
                                        uuid: uuid,
                                        type: "Text",
                                        number: nextHighestNumber,
                                    },
                                });
                                setFootnoteBeingEdited(uuid);
                            }}
                        >
                            <FontAwesomeIcon
                                icon="plus"
                                title={intl.formatMessage({
                                    id: "Add footnote",
                                })}
                            />{" "}
                            {trans("Add reference")}
                        </PlainButton>
                    </Column>

                    {!!footnoteBeingEdited && (
                        <Modal
                            style={{ marginTop: "3rem" }}
                            maxWidth="900px"
                            visible={footnoteBeingEdited}
                            onClose={() => {
                                const { number, uuid, text, link } =
                                    currentFootnote;
                                if (isUniqueNumber(number)) {
                                    setFootnoteBeingEdited(null);
                                }
                                if (text === "" && link === "") {
                                    dispatch({
                                        type: "deleteFootnote",
                                        uuid: uuid,
                                    });
                                    currentDispatch({
                                        type: "deleteFootnote",
                                        uuid: uuid,
                                    });
                                }
                            }}
                        >
                            <Row>
                                <Column style={{ padding: "1rem 0" }}>
                                    <h3>Reference: {currentFootnote.number}</h3>

                                    <InputField
                                        label="Number"
                                        style={
                                            !isUniqueNumber(
                                                currentFootnote.number
                                            )
                                                ? { borderColor: "red" }
                                                : {}
                                        }
                                        type="number"
                                        placeholder="Number"
                                        marginBottom="0"
                                        value={currentFootnote.number}
                                        name="footnote_number"
                                        onChange={(event) => {
                                            dispatch({
                                                type: "updateFootnote",
                                                uuid: footnoteBeingEdited,
                                                value: event.value,
                                                name: "number",
                                            });
                                            if (
                                                currentState.footnotes.some(
                                                    (tagFootnote) =>
                                                        tagFootnote.uuid ===
                                                        footnoteBeingEdited
                                                )
                                            ) {
                                                currentDispatch({
                                                    type: "updateFootnote",
                                                    uuid: footnoteBeingEdited,
                                                    value: event.value,
                                                    name: "number",
                                                });
                                            }
                                        }}
                                    />

                                    <br />

                                    {!showRelatedAssetBrowser && (
                                        <DraftJSField
                                            toolbar={{
                                                options: [
                                                    "inline",
                                                    "blockType",
                                                    "fontSize",
                                                    "list",
                                                    "textAlign",
                                                    "history",
                                                ],
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                            }}
                                            stripPastedStyles={true}
                                            label="Content"
                                            placeholder="Name"
                                            name="footnote_text"
                                            value={currentFootnote.text}
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "updateFootnote",
                                                    uuid: footnoteBeingEdited,
                                                    value: event.value,
                                                    name: "text",
                                                });
                                                dispatch({
                                                    type: "updateFootnote",
                                                    uuid: footnoteBeingEdited,
                                                    value: PlainTextOutput(
                                                        event.value
                                                    ),
                                                    name: "plainText",
                                                });
                                                if (
                                                    currentState.footnotes.some(
                                                        (tagFootnote) =>
                                                            tagFootnote.uuid ===
                                                            footnoteBeingEdited
                                                    )
                                                ) {
                                                    currentDispatch({
                                                        type: "updateFootnote",
                                                        uuid: footnoteBeingEdited,
                                                        value: event.value,
                                                        name: "text",
                                                    });
                                                    currentDispatch({
                                                        type: "updateFootnote",
                                                        uuid: footnoteBeingEdited,
                                                        value: PlainTextOutput(
                                                            event.value
                                                        ),
                                                        name: "plainText",
                                                    });
                                                }
                                            }}
                                        />
                                    )}
                                    <br />
                                    <Select
                                        label="Type"
                                        width="100px"
                                        marginBottom="0"
                                        name="footnote_type"
                                        options={[
                                            {
                                                text: "Text",
                                                value: "Text",
                                            },
                                            {
                                                text: "Internal",
                                                value: "Internal",
                                            },
                                            {
                                                text: "Upload",
                                                value: "Upload",
                                            },
                                        ]}
                                        value={currentFootnote.type}
                                        onChange={(event) => {
                                            dispatch({
                                                type: "updateFootnote",
                                                uuid: footnoteBeingEdited,
                                                value: event.value,
                                                name: "type",
                                            });
                                            if (
                                                currentState.footnotes.some(
                                                    (tagFootnote) =>
                                                        tagFootnote.uuid ===
                                                        footnoteBeingEdited
                                                )
                                            ) {
                                                currentDispatch({
                                                    type: "updateFootnote",
                                                    uuid: footnoteBeingEdited,
                                                    value: event.value,
                                                    name: "type",
                                                });
                                            }
                                        }}
                                        multi={false}
                                    />
                                    <br />
                                    {currentFootnote.type === "Text" && (
                                        <InputField
                                            placeholder="Value"
                                            marginBottom="0"
                                            value={currentFootnote.link}
                                            name="footnote_link"
                                            onChange={(event) => {
                                                dispatch({
                                                    type: "updateFootnote",
                                                    uuid: footnoteBeingEdited,
                                                    value: event.value,
                                                    name: "link",
                                                });
                                                if (
                                                    currentState.footnotes.some(
                                                        (tagFootnote) =>
                                                            tagFootnote.uuid ===
                                                            footnoteBeingEdited
                                                    )
                                                ) {
                                                    currentDispatch({
                                                        type: "updateFootnote",
                                                        uuid: footnoteBeingEdited,
                                                        value: event.value,
                                                        name: "link",
                                                    });
                                                }
                                            }}
                                        />
                                    )}
                                    {currentFootnote.type === "Upload" && (
                                        <SimpleDropzone onDrop={onDrop}>
                                            <IconTextButton
                                                style={{
                                                    textDecoration: "none",
                                                    paddingTop: "1rem",
                                                    paddingBottom: "0.8rem",
                                                }}
                                                onClick={() => {
                                                    return false;
                                                }}
                                                icon={faUpload}
                                                showCircle={false}
                                                expanded={false}
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    {trans("Upload")}
                                                </div>
                                            </IconTextButton>
                                        </SimpleDropzone>
                                    )}
                                    {currentFootnote.type === "Internal" && (
                                        <>
                                            <Button
                                                onClick={() =>
                                                    setShowRelatedAssetBrowser(
                                                        !showRelatedAssetBrowser
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon icon="search" />
                                            </Button>
                                            {showRelatedAssetBrowser && (
                                                <RelatedAssetBrowserContainer>
                                                    <RelatedAssetBrowser
                                                        asset={asset}
                                                        onSelect={(
                                                            event,
                                                            selectedAsset
                                                        ) => {
                                                            event.preventDefault();
                                                            // console.log(selectedAsset);
                                                            dispatch({
                                                                type: "updateFootnote",
                                                                uuid: footnoteBeingEdited,
                                                                // value: `${process.env.REACT_APP_BASE_URL}/api/v1/microsite/asset/${selectedAsset.uuid}/download`,
                                                                value: `${process.env.REACT_APP_BASE_URL}/api/v1/microsite/asset/${selectedAsset.uuid}/${selectedAsset.main_file_original_name}`,
                                                                name: `link`,
                                                            });

                                                            setShowRelatedAssetBrowser(
                                                                false
                                                            );
                                                            setFootnoteBeingEdited(
                                                                0
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        style={{
                                                            marginTop: "1rem",
                                                        }}
                                                        onClick={() =>
                                                            setShowRelatedAssetBrowser(
                                                                false
                                                            )
                                                        }
                                                    >
                                                        {trans("Close")}
                                                    </Button>
                                                </RelatedAssetBrowserContainer>
                                            )}
                                        </>
                                    )}
                                    <div
                                        style={{
                                            textAlign: "right",
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <Button
                                            onClick={() =>
                                                setFootnoteBeingEdited(null)
                                            }
                                            disabled={
                                                !isUniqueNumber(
                                                    currentFootnote.number
                                                )
                                            }
                                        >
                                            {trans("Save Reference")}
                                        </Button>
                                    </div>
                                </Column>
                            </Row>
                        </Modal>
                    )}
                </Row>

                {state.footnotes.length <= 0 ? (
                    <Row>
                        <Column>
                            <p style={{ marginTop: "1rem" }}>
                                {trans("No current references")}
                            </p>
                        </Column>
                    </Row>
                ) : (
                    <table
                        style={{
                            width: "100%",
                            textAlign: "left",
                            marginTop: "2rem",
                            marginBottom: "1rem",
                            borderCollapse: "collapse",
                        }}
                    >
                        <tbody>
                            <tr>
                                <FootnoteTableHeader>
                                    {trans("Active")}
                                </FootnoteTableHeader>
                                <FootnoteTableHeader>
                                    {trans("Number")}
                                </FootnoteTableHeader>
                                <FootnoteTableHeader>
                                    {trans("Name")}
                                </FootnoteTableHeader>
                                <FootnoteTableHeader>
                                    {trans("Type")}
                                </FootnoteTableHeader>
                                <FootnoteTableHeader>
                                    {trans("Value")}
                                </FootnoteTableHeader>
                                <FootnoteTableHeader />
                            </tr>
                            {state.footnotes.map((footnote, index) => (
                                <FootnoteInput
                                    isUniqueNumber={isUniqueNumber}
                                    setFootnoteBeingEdited={
                                        handleFootnoteBeingEdited
                                    }
                                    index={index}
                                    isBeingEdited={
                                        footnoteBeingEdited === footnote.uuid
                                    }
                                    active={currentState.footnotes.some(
                                        (tagFootnote) =>
                                            tagFootnote.uuid === footnote.uuid
                                    )}
                                    key={footnote.uuid}
                                    footnote={footnote}
                                    dispatch={dispatch}
                                    currentDispatch={currentDispatch}
                                    asset={asset}
                                    heading={
                                        <span>
                                            {footnote.text
                                                ? footnote.text
                                                : trans("Reference")}
                                        </span>
                                    }
                                />
                            ))}
                        </tbody>
                    </table>
                )}

                <Row>
                    <Column>
                        <Button
                            style={{ float: "right", marginTop: "1.5rem" }}
                            onClick={onFootnoteSave}
                        >
                            {trans("Save references")}
                        </Button>
                    </Column>
                </Row>
            </Modal>
        </React.Fragment>
    );
};

export default ManageFootnotesModal;
