import React from "react";

//@ts-ignore cortex
import { Assets } from "@cortexglobal/cortex-dam";
//@ts-ignore cortex
import { useIntl } from "@cortexglobal/rla-intl";

export const AssetAdmin = () => {
    const intl = useIntl();

    return (
        <Assets
            libraryArea="assets-admin"
            includeAllLibraryAreas={true}
            userAssetPath="/resources/my-media"
            libraryTitle={intl.formatMessage({ id: "Asset Admin" })}
        />
    );
};

export default AssetAdmin;
