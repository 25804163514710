import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faDesktop } from "@fortawesome/free-solid-svg-icons";

const Roundel = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .user-image {
        width: ${({ diameter }) => diameter}px;
        height: ${({ diameter }) => diameter}px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors["primary"]};
        color: ${({ theme }) => theme.colors["white"]};
    }

    div.user-image {
        width: ${({ diameter }) => diameter}px;
        height: ${({ diameter }) => diameter}px;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 700;
    }
`;

const UserRoundel = ({ user, diameter = 80, ...props }) => {
    return (
        <Roundel
            className={"roundel " + props.className}
            diameter={diameter}
            {...props}
        >
            {/* {user.avatar ? (
                <img
                    className="user-image"
                    src={user.avatar}
                    alt={user.initials}
                />
            ) : (
                <div className="user-image">System</div>
            )} */}
            {user?.name && user.name !== "System" ? (
                <FontAwesomeIcon icon={faUser} size="2x" />
            ) : (
                <FontAwesomeIcon icon={faDesktop} size="2x" />
            )}
        </Roundel>
    );
};

export default UserRoundel;
