import React from "react";
import styled from "styled-components";
import {
    GreedyCarousel,
    CarouselItem,
    Centred,
    Loading,
    Panel,
    Row,
    Column,
} from "@cortexglobal/rla-components";
import usePanelData from "../usePanelData";
import AssetResultItem from "../assetComponents/AssetResultItem";
import { CortexBar } from "@cortexglobal/charts";
import LinkResultItem from "./LinkResultItem";

import {
    AssetGrid,
    AssetResultWrapper,
} from "../assetComponents/AssetResultItem";

const FlexPanel = styled(Panel)`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
`;

const LinksOutput = ({ panelId, linkUuids, panelSize }) => {
    const panelData = usePanelData(panelId, `/api/v1/links`, {
        uuid: linkUuids,
    });

    if (!panelData || !panelData.loaded) {
        return (
            <Centred>
                <Loading type="spin" align="center" height="auto" />
            </Centred>
        );
    }

    return (
        <AssetGrid panelSize={panelSize} minColumnWidth={300}>
            {/* <pre> {JSON.stringify(panelData.data, null, 2)}</pre> */}

            {panelData.data ? (
                panelData.data.map((link) => (
                    <AssetResultWrapper key={link.uuid}>
                        <LinkResultItem {...link} />
                    </AssetResultWrapper>
                ))
            ) : (
                <Loading align="center" padding="1rem" />
            )}
        </AssetGrid>
    );

    // return (
    //     <Row collapse={true} equaliseChildHeight={true} equaliseByRow={true}>
    //         {/* <pre> {JSON.stringify(panelData.data, null, 2)}</pre> */}
    //         {panelData.data.map(link => (
    //             <Column
    //                 key={link.uuid}
    //                 small={12}
    //                 medium={6}
    //                 large={4}
    //                 xlarge={3}
    //                 style={{ marginBottom: "1rem" }}
    //             >
    //                 <LinkResultItem {...link} />
    //             </Column>
    //         ))}
    //     </Row>
    // );
};

export default LinksOutput;
